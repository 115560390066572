import { useTranslationSSR } from "@vp/i18n-helper";
import { UploadsPanelMessages } from "@shared/features/UploadsAndAssets";
import { uploadStatusTypes } from "./uploadStatusTypes";

export function useUploadStatusStyle(allPagesComplete: boolean, uploadStatus: uploadStatusTypes) {
    const { t } = useTranslationSSR();
    let style = "";
    if (allPagesComplete) {
        style = "dcl-asset__upload-complete dcl-asset__processing-complete";
    } else {
        const uploadComplete = uploadStatus >= uploadStatusTypes.fileTransferCompleted;
        if (uploadComplete) {
            style += " dcl-asset__upload-complete";
        }
        const processingComplete = uploadStatus >= uploadStatusTypes.postProcessingComplete;
        if (processingComplete) {
            style += " dcl-asset__processing-complete";
        }
    }

    const statusText: string | undefined =
        uploadStatus >= uploadStatusTypes.pdfInspection ? t(UploadsPanelMessages.processing.id) : undefined;

    return { style, statusText };
}
