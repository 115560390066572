import React from "react";
import { CMYK } from "@design-stack-ct/utility-core";
import { Rgb } from "@presentational";
import { updateSelectedItems } from "@utilities";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import type { Designer, ItemSelection } from "src/easel/designer-suite/@types/designer";
import { colorToString } from "@shared/features/ColorPicker";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { getIsStrokeColorCompatible } from "../../../../easel/designer-suite/color/colorUtil";
import { isSingleColor } from "../../../../easel/designer-suite/util";
import { COLOR_PICKER_PANEL_TYPES } from "../ColorPicker/colorPickerPanelTypes";

const KEY = "strokeColor";

const messages = defineMessages({
    strokeColorTitle: {
        id: "studio.components.panels.strokeColorpickerPanel.title",
        defaultMessage: "Shape outline color",
        description: {
            note: "This is the title for a panel that allows the user to change the outline color of a shape"
        }
    }
});

function onApply(
    designer: Designer | undefined,
    selection: ItemSelection,
    oldValue: Rgb | CMYK | string | undefined,
    newValue: Rgb | CMYK | string
) {
    const onSingleColor = isSingleColor(selection[0]);
    if (onSingleColor) {
        ColorPicker.onApply(designer, selection, oldValue, newValue, KEY);
        return;
    }
    const newColor = colorToString(newValue);
    updateSelectedItems(designer, selection, item => item._itemViewModel.model.set("strokeColor", newColor));
}

function getFirstColor(designer: Designer, selection: ItemSelection) {
    return ColorPicker.getFirstColor(designer, selection, KEY);
}

function getCurrentColor(designer: Designer, selection: ItemSelection) {
    return ColorPicker.getCurrentColor(designer, selection, KEY);
}

const listenEvents = ColorPicker.getListenEvents(KEY);

interface Props {
    /** For overriding styles */
    className?: string;
    /** Whether the button should always be shown, even if it is disabled.
     * @default false
     */
    alwaysShow?: boolean;
    /** Whether to only return the color picker content
     * @default false
     */
    contentOnly?: boolean;
    /**
     * Show the custom color picker (wheel/sliders).
     * @default true
     */
    allowCustom?: boolean;
}

/**
 * Responsible for changing the stroke color on shapes
 */

export function StrokeColorPickerPanel({ className, alwaysShow, contentOnly, allowCustom = true }: Props) {
    const { t } = useTranslationSSR();

    return (
        <ColorPicker
            apply={onApply}
            className={className}
            itemAttributeKey={KEY}
            getIsCompatible={getIsStrokeColorCompatible}
            title={t(messages.strokeColorTitle.id)}
            alwaysShow={alwaysShow}
            contentOnly={contentOnly}
            allowCustom={allowCustom}
            panelType={COLOR_PICKER_PANEL_TYPES.SHAPE_OUTLINE_COLOR}
        />
    );
}
StrokeColorPickerPanel.displayName = "StrokeColorPickerPanel";

StrokeColorPickerPanel.getListenEvents = listenEvents;
StrokeColorPickerPanel.getFirstColor = getFirstColor;
StrokeColorPickerPanel.getCurrentColor = getCurrentColor;
StrokeColorPickerPanel.onApply = onApply;
