import React, { HTMLProps, useRef, useState, useLayoutEffect } from "react";
import classNames from "classnames";
import { Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { SheetHorizontalScrollRightButton, SheetHorizontalScrollLeftButton } from "@shared/features/StudioChrome";
import { SheetHorizontalScrollButton } from "./SheetHorizontalScrollButton";
import * as styles from "./SheetHorizontalScroller.module.scss";

const messages = defineMessages({
    scrollRight: {
        id: "easel.ui.sheet.horizontal.scroll.scrollRight",
        defaultMessage: "Scroll Right",
        description: {
            note: "Accessibility text for the scroll right button for scrolling buttons at the bottom of a sheet on mobile"
        }
    },
    scrollLeft: {
        id: "easel.ui.sheet.horizontal.scroll.scrollLeft",
        defaultMessage: "Scroll Left",
        description: {
            note: "Accessibility text for the scroll left button for scrolling buttons at the bottom of a sheet on mobile"
        }
    }
});

interface Props extends HTMLProps<HTMLDivElement> {
    /**
     * Reuces the height of the horizontal scroller by
     * removing padding from the scrollable button container
     * and reducing the padding on the scroller
     * @default false
     * */
    reducedHeight?: boolean;
}

export function SheetHorizontalScroller({ children, className, reducedHeight = false, ...restOfProps }: Props) {
    const { t } = useTranslationSSR();

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [showLeft, setShowLeft] = useState<boolean>(false);
    const [showRight, setShowRight] = useState<boolean>(false);

    // we're storing these widths to state instead of relying on refs because it was causing some issues
    // when changing between selected items. re-mounting didn't always pick up the updated refs.
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [contentsWidth, setContentsWidth] = useState<number>(0);
    const [arrowWidth, setArrowWidth] = useState<number>(0);

    useLayoutEffect(() => {
        // on load determine if there's enough content to show the right scroll button
        setShowRight(containerWidth < contentsWidth);
    }, [containerWidth, contentsWidth]);

    const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        setShowLeft(event.currentTarget.scrollLeft > 0);
        // -1 to account for weirdness at 1240-1244 screen width, off by 1px
        setShowRight(event.currentTarget.scrollLeft + event.currentTarget.clientWidth < contentsWidth - 1);
    };

    const handleScroll = (moveToRight: boolean) => {
        if (containerRef.current) {
            const scroll = window.innerWidth - 2 * arrowWidth;

            containerRef.current.scrollBy({
                top: 0,
                left: (moveToRight ? 1 : -1) * scroll,
                behavior: "smooth"
            });
        }
    };

    const onClickLeft = () => handleScroll(false);
    const onClickRight = () => handleScroll(true);

    // this isn't inlined so that containerRef is mutable without casting it.
    const setContainerRef = (current: HTMLDivElement | null) => {
        containerRef.current = current;
        setContainerWidth(current?.clientWidth || 0);
    };

    return (
        <div className={styles.sheetHorizontalScrollerContainer}>
            <div
                ref={setContainerRef}
                className={classNames("scrollable-button-container", styles.scrollableButtonContainer, {
                    [styles.scrollableButtonContainerReducedHeight]: reducedHeight
                })}
                onScroll={onScroll}
            >
                <div
                    ref={current => {
                        setArrowWidth(current?.clientWidth || 0);
                    }}
                    className={classNames(
                        "sheet-horizontal-scroll-indicator",
                        styles.sheetHorizontalScrollIndicator,
                        styles.sheetLeftHorizontalScrollIndicator,
                        {
                            [styles.sheetHorizontalScrollShow]: showLeft
                        }
                    )}
                >
                    <SheetHorizontalScrollButton onClick={onClickLeft}>
                        <SheetHorizontalScrollLeftButton />
                        <Typography visuallyHidden>{t(messages.scrollLeft.id)}</Typography>
                    </SheetHorizontalScrollButton>
                </div>
                <div
                    ref={current => {
                        setContentsWidth(current?.scrollWidth || 0);
                    }}
                    className={classNames("sheet-horizontal-scroller", styles.sheetHorizontalScroller, className, {
                        [styles.sheetHorizontalScrollerReducedHeight]: reducedHeight
                    })}
                    data-dcl-prevent-canvas-items-deselection
                    {...restOfProps}
                >
                    {children}
                </div>
                <div
                    className={classNames(
                        "sheet-horizontal-scroll-indicator",
                        styles.sheetHorizontalScrollIndicator,
                        styles.sheetRightHorizontalScrollIndicator,
                        {
                            [styles.sheetHorizontalScrollShow]: showRight
                        }
                    )}
                >
                    <SheetHorizontalScrollButton onClick={onClickRight}>
                        <SheetHorizontalScrollRightButton />
                        {/* This element is hidden but the white color shuts up a11y scanners about contrast ratios */}
                        <Typography textColor="white" visuallyHidden>
                            {t(messages.scrollRight.id)}
                        </Typography>
                    </SheetHorizontalScrollButton>
                </div>
            </div>
        </div>
    );
}

SheetHorizontalScroller.displayName = "SheetHorizontalScroller";
