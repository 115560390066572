import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { setError, showLoader, useAppDispatch, useAppSelector } from "@shared/redux";
import { isCareAgentEditingCareAgentDocument } from "@shared/utils/Care";
import { newRelicWrapper, handleError, ERROR_CODES } from "@shared/utils/Errors";
import { getAction, fireImpressionsForContexts } from "@shared/utils/SiteFlow";
import { fireGenericTrackingEvent, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { saveWorkInSession } from "@shared/utils/Work";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useCallback } from "react";
import { useCartContext } from "../Cart";
import { SaveDesignWithSaveDocumentOmitted } from "../Save";

const navigationSteps = {
    configureTeamsQuantity: "configureteamsquantity"
};

export const messages = defineMessages({
    navigateToTeamsPage: {
        id: "studio.components.next.navigateToTeamsPage",
        defaultMessage: "Getting your design ready..."
    }
});

interface Props {
    save: SaveDesignWithSaveDocumentOmitted;
}

export function useNavigateToTeamsPage({ save }: Props) {
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const { auth, identity } = useIdentityContext();
    const authToken = auth?.getToken();
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const { t } = useTranslationSSR();

    return useCallback(async () => {
        try {
            const startTime = performance.now();

            // @ts-ignore type inference from Action is incorrect
            dispatch(setError({}));
            dispatch(showLoader(t(messages.navigateToTeamsPage.id)));

            const work = await save({ authToken, identity });
            saveWorkInSession(work);

            if (isCareAgentEditingCareAgentDocument()) {
                // eslint-disable-next-line no-alert
                alert("Document has been saved successfully");
                return;
            }

            newRelicWrapper.logPageAction("studio-exit");
            fireGenericTrackingEvent({
                event: "Studio Continue",
                eventDetail: "Continue Button Clicked",
                label: "Next Button",
                extraData: () => ({
                    workId: work.workId,
                    editFromCartFlow: isItemInCart
                })
            });

            // get next step url to teams page
            const nextStepUrlToTeamsPage = await getAction(
                productKey,
                productVersion!,
                studioSelectedProductOptions,
                locale,
                navigationSteps.configureTeamsQuantity
            );
            if (!nextStepUrlToTeamsPage.url) {
                newRelicWrapper.logPageAction("studio-no-next-step-url", {
                    nextStep: JSON.stringify(nextStepUrlToTeamsPage)
                });
            }
            await fireImpressionsForContexts(nextStepUrlToTeamsPage.context);
            window.location.href = nextStepUrlToTeamsPage?.url?.replace("{workId}", work.workId);

            const endTime = performance.now();
            const timeElapsed = endTime - startTime;
            fireUserInteractionTrackingEvent("Click Next to Teams Page", timeElapsed, {
                editFromCartFlow: isItemInCart
            });
        } catch (e) {
            newRelicWrapper.logPageAction("studio-fail-add-to-cart", { cartError: JSON.stringify(e) });
            handleError(e, ERROR_CODES.NAVIGATE_TO_TEAMS);
        }
    }, [
        authToken,
        dispatch,
        identity,
        isItemInCart,
        locale,
        productKey,
        productVersion,
        save,
        studioSelectedProductOptions,
        t
    ]);
}
