import React, { useCallback } from "react";
import { useSelection, useDesigner } from "@designer-suite";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { getTrackingDataForSelection, selectedItemsAreOfTypes } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { FontSizeInput } from "@shared/features/ContextualToolbar";

export interface FontSizeInputProps {
    min: number;
    max: number;
    current: number;
    /** When a user picks a value call this method
     * User is responsible for connecting this up to a Store / something external that updates the selected parameter
     */
    onChange: (value: number) => void;

    /** For overriding styles */
    className?: string;

    autoFocus?: boolean;
}

export function FontSizeInputStudio5({
    min,
    max,
    current,
    onChange = () => {},
    className,
    autoFocus
}: FontSizeInputProps) {
    const designer = useDesigner();
    const selection = useSelection();
    const isWordArt = selectedItemsAreOfTypes(selection, [ItemTypes.WORDART]);

    const handleChange = useCallback(
        (value: number) => {
            designer?.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.SIZE_DROPDOWN_INPUT_ENTERED,
                getTrackingDataForSelection(selection)
            );
            onChange(value);
        },
        [designer, selection, onChange]
    );

    const handleDetectValidInput = useCallback(() => isWordArt, [isWordArt]);

    return (
        <FontSizeInput
            initialValue={current}
            min={min}
            max={max}
            className={className}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            detectValidInput={handleDetectValidInput}
            onChange={handleChange}
        />
    );
}
FontSizeInputStudio5.displayName = "FontSizeInputStudio5";
