// extracted by mini-css-extract-plugin
export var backgroundColorIcon = "LeftSidebar-module--background-color-icon--a275a";
export var colorDrop = "LeftSidebar-module--color-drop--dbcf5";
export var flexibilityIcon = "LeftSidebar-module--flexibility-icon--4d87f";
export var floatingSidebarDivider = "LeftSidebar-module--floating-sidebar-divider--a82e0";
export var leftSidebar = "LeftSidebar-module--left-sidebar--68a90";
export var moreIcon = "LeftSidebar-module--more-icon--04fd7";
export var previewIcon = "LeftSidebar-module--preview-icon--5385a";
export var productColor = "LeftSidebar-module--product-color--83d79";
export var rectColor = "LeftSidebar-module--rect-color--73aed";
export var selected = "LeftSidebar-module--selected--eb0dd";
export var sidebarButton = "LeftSidebar-module--sidebar-button--d83da";
export var sidebarButtonIcon = "LeftSidebar-module--sidebar-button-icon--6f8e4";
export var sidebarButtonTables = "LeftSidebar-module--sidebar-button-tables--6e685";
export var sidebarButtons = "LeftSidebar-module--sidebar-buttons--a39fb";
export var sidebarButtonsShown = "LeftSidebar-module--sidebar-buttons-shown--b6e89";
export var sidebarPanels = "LeftSidebar-module--sidebar-panels--775e8";
export var sidebarPanelsShown = "LeftSidebar-module--sidebar-panels-shown--9e77a";
export var triangle = "LeftSidebar-module--triangle--50d2c";