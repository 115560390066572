// @ts-ignore
const maxBy = (arr, func) => {
    const max = Math.max(...arr.map(func));
    // @ts-ignore
    return arr.find(item => func(item) === max);
};

// @ts-ignore
const minBy = (arr, func) => {
    const min = Math.min(...arr.map(func));
    // @ts-ignore
    return arr.find(item => func(item) === min);
};

// Logic for bringForward + sendBackward largely lifted from designer
// See app\core\commands\ChangeZIndex.js in the designer codebase
//  designer code base requires a selection + isn't easy to access.
//  the designer-suite code does not work as needed.

/**
 * Swap an item with the item just after it in the zIndex order
 * @param {Item[]} itemsOnCanvas - List of items on the canvas that the item is on
 * @param {Item} itemToUpdate - An item to send forward by one position zIndex wise
 */
export function bringForward(itemsOnCanvas: Item[], itemToUpdate: Item) {
    const selectedZIndex = itemToUpdate._itemViewModel.model.get("zIndex");
    const higherItems = itemsOnCanvas.filter(
        // Regular bringForward respects zIndexLock and this would have a check:
        //  !item._itemViewModel.model.get("zIndexLock") &&
        item => item._itemViewModel.model.get("zIndex") > selectedZIndex
    );
    // @ts-ignore
    const itemToSwapWith = minBy(higherItems, item => item._itemViewModel.model.get("zIndex"));
    if (higherItems.length === 0) {
        itemToUpdate._itemViewModel.model.set("zIndex", 1);
    } else {
        const swapZIndex = itemToSwapWith._itemViewModel.model.get("zIndex");
        itemToSwapWith._itemViewModel.model.set("zIndex", selectedZIndex);
        itemToUpdate._itemViewModel.model.set("zIndex", swapZIndex); // Todo: designers method has a bug
    }
}

/**
 * Swap an item with the item just before it in the zIndex order
 * @param {Item[]} itemsOnCanvas - List of items on the canvas that the item is on
 * @param {Item} itemToUpdate - An item to send backward by one position zIndex wise
 */
export function sendBackward(itemsOnCanvas: Item[], itemToUpdate: Item) {
    const selectedZIndex = itemToUpdate._itemViewModel.model.get("zIndex");
    const lowerItems = itemsOnCanvas.filter(
        // Regular sendBackward respects zIndexLock and this would have a check:
        //  !item._itemViewModel.model.get("zIndexLock") &&
        item => item._itemViewModel.model.get("zIndex") < selectedZIndex
    );
    // Find the item in the list just before the item to update
    // @ts-ignore
    const itemToSwapWith = maxBy(lowerItems, item => item._itemViewModel.model.get("zIndex"));
    if (itemToSwapWith) {
        const swapZIndex = itemToSwapWith._itemViewModel.model.get("zIndex");
        itemToSwapWith._itemViewModel.model.set("zIndex", selectedZIndex);
        itemToUpdate._itemViewModel.model.set("zIndex", swapZIndex); // Todo: designers method has a bug
    }
}

/**
 * Get the zIndex of the item to help with sorting the list
 * @param {Item} item
 * @returns {number} zIndex number
 */
function getItemSortRank(item: Item) {
    return item._itemViewModel.model.get("zIndex");
}

/**
 * Sort a list of items by zIndex order - descending order
 * @param {Item[]} items to sort by zIndex, assumed to be on the same canvas.
 * @returns {Item[]} items sorted by zIndex
 */
export function getItemsSortedByZIndex(items: Item[]) {
    return items.sort((itemA, itemB) => getItemSortRank(itemB) - getItemSortRank(itemA));
}

/**
 * Check if a preview image exists
 * @param {Item} item - item to get the previewUrl from
 * @returns {boolean} - true if it exists, false otherwise
 */
export function hasPreviewUrl(item: Item) {
    return item._itemViewModel.model.has("previewUrl");
}

/**
 * Get the preview Url for an image
 * @param {Item} item - item to get the previewUrl from
 * @returns {string} - url of the preview
 */
export function getPreviewUrl(item: Item) {
    return item._itemViewModel.model.get("previewUrl");
}

/**
 * Check if a print url exists
 * @param {Item} item - item to get the printUrl from
 * @returns {boolean} - true if it exists, false otherwise
 */
export function hasPrintUrl(item: Item) {
    return item._itemViewModel.model.has("printUrl");
}

/**
 * Get the Print Url for an image
 * @param {Item} item - item to get the printUrl from
 * @returns {string} - url of the preview
 */
export function getPrintUrl(item: Item) {
    return item._itemViewModel.model.get("printUrl");
}

/**
 * Get the original Url for an image
 * @param {Item} item - item to get the originalUrl from
 * @returns {string} - url of the preview
 */
export function getOriginalUrl(item: Item) {
    return item._itemViewModel.model.get("originalUrl");
}
