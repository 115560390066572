import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";
import { TemplatePanelDesignAvailableColors } from "./TemplatePanelAvailableColors";
import { TemplatePanelDesignTile } from "./TemplatePanelDesignTile";
import { useOnDesignTileClick } from "./useOnDesignTileClick";
import { ElementsScroller } from "../../../xerox/features/Elements/ElementsScroller";
import { ElementsRowArrowButton } from "../../../xerox/features/Elements/ElementsRowArrowButton";

const messages = defineMessages({
    templatePanelContentViewAllLabel: {
        id: "studio.panels.template.main.viewAll.label",
        defaultMessage: "View All",
        description: {
            note: "text that will display on the view all button"
        }
    },
    recentDesignLabel: {
        id: "studio.panels.template.recent.design",
        defaultMessage: "Select recent design",
        description: {
            note: "fallback accessability text for recent designs items"
        }
    }
});

export const RecentTemplates = () => {
    const { addToStack, currentDesign, setSelectedDesign, recentlyUsedTemplates } = useTemplatePanel();
    const { onDesignTileClick } = useOnDesignTileClick();
    const { t } = useTranslationSSR();

    return (
        <ElementsScroller className="recent-template-scroller-list" scrollSnap>
            {recentlyUsedTemplates
                .filter(design => design.designId !== currentDesign?.designId)
                .map(design => {
                    const onClick = () => {
                        if (design.colorSwatches?.length) {
                            addToStack(TemplatePanelContentTypes.Color);
                            setSelectedDesign(design);
                        } else {
                            onDesignTileClick(design);
                        }
                    };
                    return (
                        <button
                            key={`recent-${design.designId}-tile`}
                            className="recent-template-panel-gallery-tile"
                            onClick={onClick}
                            aria-label={design?.altText ?? t(messages.recentDesignLabel.id)}
                        >
                            <TemplatePanelDesignTile design={design} />
                            <TemplatePanelDesignAvailableColors design={design} />
                        </button>
                    );
                })}
            {recentlyUsedTemplates.length > 4 && (
                <ElementsRowArrowButton
                    onClick={() => {
                        fireDesignToolTrackingEvent({
                            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_RECENTLY_USED_VIEW_ALL,
                            label: "Click template panel recently used view all",
                            extraData: () => ({
                                recentlyUsedTemplates: recentlyUsedTemplates.length
                            })
                        });
                        addToStack(TemplatePanelContentTypes.Recent);
                    }}
                    aria-label={t(messages.templatePanelContentViewAllLabel.id)}
                />
            )}
        </ElementsScroller>
    );
};

RecentTemplates.displayName = "RecentTemplates";
