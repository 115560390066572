import React from "react";
import { DraggableShape } from "./DraggableShape";

const shapes = [{ module: "Rectangle" }];

export const DraggableRectangle = () => {
    return (
        <DraggableShape shapes={shapes}>
            <svg viewBox="0 0 32 32" fill="none">
                <rect
                    x="0.347561"
                    y="0.347561"
                    width="31.3049"
                    height="31.3049"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.695122"
                />
            </svg>
        </DraggableShape>
    );
};

DraggableRectangle.displayName = "DraggableRectangle";
