/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}

export function UndoIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M5.28261 10.0922C5.32829 10.1428 5.38269 10.183 5.44266 10.2104C5.50263 10.2378 5.56698 10.2519 5.63197 10.2519C5.69696 10.2519 5.76131 10.2378 5.82128 10.2104C5.88125 10.183 5.93565 10.1428 5.98132 10.0922C6.07175 9.98831 6.12081 9.84942 6.11772 9.706C6.11463 9.56258 6.05964 9.42637 5.96484 9.32731L3.94782 7.2276H10.869C11.4372 7.2276 11.9821 7.47466 12.3838 7.91444C12.7856 8.35422 13.0113 8.95069 13.0113 9.57264C13.0113 10.1946 12.7856 10.791 12.3838 11.2308C11.9821 11.6706 11.4372 11.9177 10.869 11.9177C10.7379 11.9177 10.6121 11.9747 10.5194 12.0762C10.4267 12.1777 10.3746 12.3153 10.3746 12.4588C10.3746 12.6024 10.4267 12.74 10.5194 12.8415C10.6121 12.943 10.7379 13 10.869 13C11.6994 13 12.4958 12.6389 13.0829 11.9962C13.6701 11.3534 14 10.4816 14 9.57264C14 8.66364 13.6701 7.79188 13.0829 7.14913C12.4958 6.50637 11.6994 6.14527 10.869 6.14527H3.91156L5.97144 3.91207C6.05925 3.80995 6.10742 3.67449 6.10578 3.53426C6.10415 3.39402 6.05284 3.25995 5.96267 3.16031C5.8725 3.06067 5.75052 3.00325 5.62243 3.00013C5.49433 2.99702 5.37014 3.04847 5.27602 3.14362L2.35924 6.28598L2 6.67561L5.28261 10.0922Z"
                fill="currentColor"
            />
        </svg>
    );
}
UndoIcon.displayName = "UndoIcon";
