import React from "react";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FlexBox, Typography } from "@vp/swan";
import { messages } from "./messages";

interface props {
    numberOfProjects: number;
}

export const MyProjectsHeader = ({ numberOfProjects }: props) => {
    const { t } = useTranslationSSR();
    const { myProjectsUrlPath } = usePageContext();

    return (
        <FlexBox alignItems="center">
            {numberOfProjects ? (
                <Typography fontWeight="bold" mr={3}>
                    {t(messages.myProjectsPanelCount.id, { numberOfProjects })}
                </Typography>
            ) : null}
            <a
                rel="noreferrer"
                target="_blank"
                aria-label={t(messages.myProjectsPanelSeeAllLabel.id)}
                href={myProjectsUrlPath}
                onClick={() => {
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MY_PROJECTS_SEE_ALL,
                        label: "My Projects See All"
                    });
                }}
            >
                {t(messages.myProjectsPanelSeeAll.id)}
            </a>
        </FlexBox>
    );
};

MyProjectsHeader.displayName = "MyProjectsHeader";
