import { useCallback } from "react";
import { DesignTileCallbackProps } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@shared/utils/DSS";
import { FULLBLEED_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import { type DTR } from "@vp/types-ddif";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";

interface SelectTemplateExternalParameters extends DesignTileCallbackProps {
    templateToken: string;
    designType?: string;
    colorMatchedDocument?: DTR.EditDocV2;
}

export const useSelectTemplateCallback = () => {
    const { getDocument, loadNewDesign, fireInteractionTimedEvent } = useDesignDialog();
    /**
     * This function is a callback that when called, will convert the panel with panelName to the template passed in
     * @param templateToken - template id to convert the panel name too
     * @param panelName - panel to be converted
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel) meaning the user would be able to start from the template again
     * @returns callback
     */
    return useCallback(
        ({
            templateToken,
            panelName,
            // returned from calcifer/DTeC as generic or bespoke
            designType,
            resetting = false,
            colorMatchedDocument
        }: SelectTemplateExternalParameters) => {
            return async (dispatch: any, getState: any) => {
                const { surfaceUpsells } = getState();
                const surfaceUpsell = surfaceUpsells[panelName];
                const isFullbleed = templateToken === FULLBLEED_SELECTED_TEMPLATE;
                return applyDesignOption({
                    dispatch,
                    getState,
                    panelName,
                    templateDesignType: designType,
                    resetting,
                    newDocumentSourceType: isFullbleed
                        ? documentSourceTypeMap.FULLBLEED
                        : documentSourceTypeMap.TEMPLATE_TOKEN,
                    newDocumentSourceData: isFullbleed ? "" : templateToken,
                    newOptions: surfaceUpsell
                        ? {
                            [surfaceUpsell.optionName]: surfaceUpsell.colorOption
                        }
                        : undefined,
                    fireInteractionTimedEvent,
                    colorMatchedDocument,
                    existingDocument: await getDocument(),
                    loadNewDesign
                });
            };
        },
        [getDocument, loadNewDesign, fireInteractionTimedEvent]
    );
};
