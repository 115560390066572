import { useAppSelector } from "@shared/redux";
import { useDesignRequirementsContext } from "../../Product";
import { useAvailablePremiumFinishesOnCanvas } from "./useAvailablePremiumFinishesOnCanvas";
import { SUPPORTED_FINISHES, PF_INSTRUCTIONS_TYPE } from "../constants";

export function usePremiumFinishReviewInstructionsRequired(activeCanvasName?: string) {
    const designRequirements = useDesignRequirementsContext();
    const { enabledCanvasFinish } = useAvailablePremiumFinishesOnCanvas(activeCanvasName);

    const reviewInstructions = useAppSelector(state => state.reviewInstructions);
    const isFullBleed = useAppSelector(state => state.isFullBleed);

    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;

    if (!(activeCanvasName && finishType && SUPPORTED_FINISHES.includes(finishType) && isFullBleed)) {
        return null;
    }

    const activeCanvasId = designRequirements?.getPanelByName(activeCanvasName)?.id;

    const panelFullBleedInstructions = reviewInstructions.find(
        instr => instr.instructionType === PF_INSTRUCTIONS_TYPE && instr.panelId === activeCanvasId
    );

    if (!panelFullBleedInstructions || !panelFullBleedInstructions.finishOption) {
        return true;
    }
    return false;
}
