import React, { useCallback } from "react";
import { Button } from "@vp/swan";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { UploadButtonPresentational, useUploadComponents } from "@shared/features/UploadsAndAssets";
import { useDesigner } from "../../designer/DesignerProvider";
import { useActiveCanvas } from "../../designer/useActiveCanvas";
import { getUploadFrom } from "./UploadUtilities";

interface Props extends React.ComponentProps<typeof Button> {
    buttonMessage?: string;
    isImageUpload?: boolean;
    isUploadModalAutoLoaded?: boolean;
}

const UploadButton = ({ children, isImageUpload, isUploadModalAutoLoaded, ...rest }: Props) => {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const uploadComponents = useUploadComponents();

    const onClick = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            designer && designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_IMAGEPANEL_UPLOAD);
            // add tracking for upload button click
            canvas &&
                isUploadModalAutoLoaded &&
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_UPLOAD_BUTTON.replace("[CanvasSide]", canvas.name),
                    label: "General",
                    extraData: () =>
                        getUploadFrom({
                            isImageUpload: isImageUpload || false,
                            activeCanvas: canvas.name
                        })
                } as any);
            // The logic below is painfully janky. The reason for this is that this is how Designer does it
            // In order to get control over the button, we're just taking their logic and doing it here.
            // Eventually, we should come up with a more elegant solution.
            // Upload Components uses a different uploads button so we don't need to explicity click in that case
            if (!uploadComponents) {
                (document.getElementsByClassName("dcl-upload-input")[0] as HTMLElement).click();
            }
        },
        [canvas, designer, isImageUpload, isUploadModalAutoLoaded, uploadComponents]
    );

    return (
        <UploadButtonPresentational onClick={onClick} {...rest}>
            {children}
        </UploadButtonPresentational>
    );
};
UploadButton.displayName = "UploadButton";

export { UploadButton };
