import { useSidebarNavConfiguration } from "@five/components/Redesign/FloatingSidebar/useSidebarNavConfiguration";
import { useSidebarKeyboardNavigation } from "@five/components/Redesign/FloatingSidebar/utils/useSidebarKeyboardNavigation";
import { useActiveDialog } from "@shared/features/ActiveDialog";
import { FlexBox } from "@vp/swan";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import * as style from "./SideBarNav.module.scss";

const NAV_BUTTON_HEIGHT = 70;
const DIVIDER_HEIGHT = 20;

const SkeletonSideBarButton = () => {
    return (
        <FlexBox
            flexDirection="column"
            alignItems="center"
            style={{ width: "100%", height: NAV_BUTTON_HEIGHT, opacity: 0.5 }}
        >
            <Skeleton width={32} height={32} />
            <Skeleton width={40} height={10} />
        </FlexBox>
    );
};
SkeletonSideBarButton.displayName = "SkeletonSideBarButton";

const SkeletonSideBarNav = () => {
    return (
        <div className={classNames(style.buttons)}>
            <SkeletonSideBarButton />
            <SkeletonSideBarButton />
            <SkeletonSideBarButton />
            <SkeletonSideBarButton />
        </div>
    );
};
SkeletonSideBarNav.displayName = "SkeletonSideBarNav";

export const useSidebarNavBackgroundPosition = () => {
    const { sidebarPanelButtonConfiguration } = useSidebarNavConfiguration();
    const { currentActiveDialog } = useActiveDialog();

    let top = 0;
    let foundButton = false; // Add the heights until the active button is found
    sidebarPanelButtonConfiguration.forEach(section => {
        section.forEach(button => {
            if (!foundButton && button.rendered) {
                if (button.contentType === currentActiveDialog) {
                    foundButton = true;
                } else {
                    top += NAV_BUTTON_HEIGHT;
                }
            }
        });
        if (!foundButton) {
            top += DIVIDER_HEIGHT;
        }
    });

    return foundButton ? top : -NAV_BUTTON_HEIGHT;
};

export const SideBarNav = ({ children }: PropsWithChildren<{}>) => {
    // Calculate the position of the back box and animate it when the active panel id changes
    const top = useSidebarNavBackgroundPosition();
    const { sidebarButtonsRef } = useSidebarKeyboardNavigation();
    const { defaultPanelDialogType } = useSidebarNavConfiguration();

    return (
        <div className={style.root} role="none">
            <div className={style.background} style={{ top, height: NAV_BUTTON_HEIGHT }} role="none" />
            {defaultPanelDialogType ? (
                <div className={classNames(style.buttons)} role="tablist" ref={sidebarButtonsRef}>
                    {children}
                </div>
            ) : (
                <SkeletonSideBarNav />
            )}
        </div>
    );
};

SideBarNav.displayName = "SideBarNav";
