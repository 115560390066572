import { useDesigner } from "@designer-suite";
import { useEffect, useState } from "react";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { fireGenericTrackingEvent } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import type { Designer } from "src/easel/designer-suite/@types/designer";

let totalWaited = 0;
const MAX_WAIT = 10000;
const WAIT_INCREMENT = 500;

const checkForAllLoaded = (designer: Designer, template: string) => {
    // wait till the items have their dimensions, needed for several validations
    const stillLoading = designer.api.design.canvases[0].items.find(
        item =>
            item._itemViewModel.attributes?.previewBoundingBox?.height === 0 &&
            item._itemViewModel.attributes?.previewBoundingBox?.width === 0
    );
    if (stillLoading && totalWaited < MAX_WAIT) {
        totalWaited += WAIT_INCREMENT;
        setTimeout(() => checkForAllLoaded(designer, template), WAIT_INCREMENT);
    } else if (stillLoading && totalWaited >= MAX_WAIT) {
        fireGenericTrackingEvent({
            event: "Studio Diagnostic",
            eventDetail: "Template Validation Timeout",
            // @ts-ignore
            label: "Template Validation Timeout"
        });
    } else {
        // we still have to wait a second because the validations take a little bit to show up
        setTimeout(() => {
            designer.validationManager.models.forEach(validation => {
                const item = validation.has("data")
                    ? designer.api.design.canvases[0].items.find(item => item.id === validation.get("data")?.itemId)
                    : undefined;
                fireGenericTrackingEvent({
                    event: "Studio Validation Shown",
                    eventDetail: "Template Validation",
                    // @ts-ignore
                    label: "Template Validation Shown",
                    // @ts-ignore
                    extraData: () => ({
                        validationName: validation.get("name"),
                        validationSeverity: validation.get("severity"),
                        template,
                        itemType: item?.itemType
                    })
                });
            });
        }, 1000);
    }
};

export function useReportTemplateValidations() {
    const designer = useDesigner();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const workId = useAppSelector(state => state.workId);
    const [startedCheck, setStartedCheck] = useState(false);

    useEffect(() => {
        //  @ts-ignore
        const { template } = getQueryParams();
        if (designer && easelLoaded && !workId && !startedCheck && template) {
            checkForAllLoaded(designer, template);
            setStartedCheck(true);
        }
    }, [designer, easelLoaded, startedCheck, workId]);
}
