import { tryFetch } from "@shared/utils/Network";
import { getStudioUniqueSessionId } from "@shared/utils/Tracking";

const host = DTR_URL;
const entityCode = 45;

/**
 * Will realize a token into a cimDoc
 * @param {string} token Design transformation token
 * @returns cimDoc
 */
export async function realizeToken(token: string) {
    const dtrUrl = `${host}/api/v3/realizeToken/?requestor=${REQUESTOR}&fillDefaultText=false`;
    return tryFetch({
        url: dtrUrl,
        options: {
            method: "POST",
            body: JSON.stringify(token),
            headers: {
                "Content-Type": "application/json",
                "x-caller-id": REQUESTOR,
                "x-correlation-id": getStudioUniqueSessionId()
            }
        },
        moduleFunction: "dtr:getRealizeToken",
        friendlyDescription: "get document from realization token",
        entityCode
    });
}

/**
 * Creates a DTR token from a DTI json object
 * @param {object} input Design Transformation Instructions object to be tokenified
 * @param {string} correlationId transformation correlation id for auditing purposes
 * @returns cimDoc
 */
export async function createToken(input: any, correlationId: string) {
    const dtrUrl = `${host}/api/v1/dti/createToken?requestor=${REQUESTOR}&correlationId=${correlationId}`;
    return tryFetch({
        url: dtrUrl,
        options: {
            method: "POST",
            body: JSON.stringify(input),
            headers: {
                "Content-Type": "application/json",
                "x-caller-id": REQUESTOR,
                "x-correlation-id": correlationId
            }
        },
        moduleFunction: "dtr:createToken",
        friendlyDescription: "create realization token",
        entityCode
    });
}
