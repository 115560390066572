/* eslint-disable max-len */
import React from "react";

export function TeamsGuideLabelStep1(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 40 40" fill="none" {...props}>
            <circle cx="20" cy="20" r="19.5" fill="white" stroke="black" />
            <path d="M19.294 26H21.706V13.13H19.564L16.576 15.254V17.36L19.294 15.506V26Z" fill="black" />
        </svg>
    );
}
TeamsGuideLabelStep1.displayName = "TeamsGuideLabelStep1";
