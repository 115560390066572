import React, { useCallback } from "react";
import { MaximizeIcon, MinimizeIcon, Button, ExpandingButton } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography, FlexBox } from "@vp/swan";
import { openMobileKeyboard } from "@shared/features/WebBrowser";
import { type useFontSizeSelector } from "@shared/features/ContextualToolbar";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import classNames from "classnames";
import { fontSizeMessages } from "./fontSizeMessages";
import * as styles from "./FontSizeSelector.module.scss";

export type FontSizeSelectorMobileDockProps = ReturnType<typeof useFontSizeSelector> & {
    //* * The font size currently set by the component using this selector */
    initialFontSize: number;

    /** The smallest font size allowed */
    min: number;

    /** The largest font size allowed */
    max: number;

    isDisabled?: boolean;
};

export function FontSizeSelectorMobileDock(props: React.PropsWithChildren<FontSizeSelectorMobileDockProps>) {
    const { t } = useTranslationSSR();
    const { startCounter, stopCounter, initialFontSize, min, max, children, isDisabled } = props;
    return (
        <>
            <Typography fontSize="1" fontWeight="bold" className={styles.mobileDockFontSizeText}>
                {t(fontSizeMessages.fontSizeSelectorTitle.id)}
            </Typography>
            <FlexBox
                alignItems="center"
                className={classNames("font-size-selector-container", styles.mobileDockFontSizeSelectorContainer)}
            >
                <Button
                    onMouseDown={() => startCounter(-1)}
                    onMouseUp={() => stopCounter(-1)}
                    onMouseLeave={() => stopCounter(-1)}
                    onPointerLeave={() => stopCounter(-1)}
                    title={t(fontSizeMessages.decreaseFontSizeLabel.id)}
                    className={classNames(styles.incrementButton, styles.mobileDockIncrementButton)}
                    isDisabled={initialFontSize - 1 < min || isDisabled}
                    aria-label={t(fontSizeMessages.decreaseFontSizeLabel.id)}
                >
                    <MinimizeIcon />
                </Button>
                {children}
                <Button
                    onMouseDown={() => startCounter(1)}
                    onMouseUp={() => stopCounter(1)}
                    onMouseLeave={() => stopCounter(1)}
                    onPointerLeave={() => stopCounter(1)}
                    title={t(fontSizeMessages.increaseFontSizeLabel.id)}
                    className={classNames(styles.incrementButton, styles.mobileDockIncrementButton)}
                    isDisabled={initialFontSize + 1 > max || isDisabled}
                    aria-label={t(fontSizeMessages.increaseFontSizeLabel.id)}
                >
                    <MaximizeIcon />
                </Button>
            </FlexBox>
        </>
    );
}
FontSizeSelectorMobileDock.displayName = "FontSizeSelectorMobileDock";

type FontSizeSelectorDefaultProps = FontSizeSelectorMobileDockProps & {
    /**
     * @default false
     */
    expandable?: boolean;

    content: JSX.Element;

    isDisabled?: boolean;
};

export function FontSizeSelectorDefault(props: React.PropsWithChildren<FontSizeSelectorDefaultProps>) {
    const { t } = useTranslationSSR();
    const { setCurrentActiveDialog } = useActiveDialog();
    const handleOpenMobile = useCallback(
        () => setCurrentActiveDialog(DialogType.FontSizeSelector),
        [setCurrentActiveDialog]
    );

    const { startCounter, stopCounter, initialFontSize, min, max, expandable, content, children, isDisabled } = props;
    return (
        <>
            <div className={styles.verticalDivider} />
            <FlexBox alignItems="center" className="font-size-selector-container">
                <Button
                    onMouseDown={() => startCounter(-1)}
                    onMouseUp={() => stopCounter(-1)}
                    onMouseLeave={() => stopCounter(-1)}
                    onPointerLeave={() => stopCounter(-1)}
                    title={t(fontSizeMessages.decreaseFontSizeLabel.id)}
                    className={styles.incrementButton}
                    isDisabled={initialFontSize - 1 < min || isDisabled}
                    aria-label={t(fontSizeMessages.decreaseFontSizeLabel.id)}
                >
                    <MinimizeIcon />
                </Button>
                {expandable ? (
                    <ExpandingButton
                        content={content}
                        title={t(fontSizeMessages.fontSizeSelectorTitle.id)}
                        displayType="singlerow"
                        className={classNames(styles.expandingButton, "font-size-selector")}
                        buttonClassName={styles.expandingButtonInnerButton}
                        isDisabled={isDisabled}
                    >
                        {children}
                    </ExpandingButton>
                ) : (
                    <Button
                        onClick={() => {
                            handleOpenMobile();
                            openMobileKeyboard(".mobile-dock-font-size-input input");
                        }}
                        className={styles.fontSizeSelectorMobileSheet}
                        aria-label={t(fontSizeMessages.fontSizeSelectorTitle.id)}
                        isDisabled={isDisabled}
                    >
                        {initialFontSize}
                    </Button>
                )}
                <Button
                    onMouseDown={() => startCounter(1)}
                    onMouseUp={() => stopCounter(1)}
                    onMouseLeave={() => stopCounter(1)}
                    onPointerLeave={() => stopCounter(1)}
                    title={t(fontSizeMessages.increaseFontSizeLabel.id)}
                    className={styles.incrementButton}
                    isDisabled={initialFontSize + 1 > max || isDisabled}
                    aria-label={t(fontSizeMessages.increaseFontSizeLabel.id)}
                >
                    <MaximizeIcon />
                </Button>
                <div className={styles.verticalDivider} />
            </FlexBox>
        </>
    );
}

FontSizeSelectorDefault.displayName = "FontSizeSelectorDefault";
