import React, { Ref } from "react";
import classnames from "classnames";
import {
    LegacyListbox,
    LegacyListboxList,
    LegacyListboxButton,
    LegacyListboxOption,
    LegacyListboxPopover
} from "@vp/swan";

import * as styles from "./ZoomDropdownTool.module.scss";

interface Props {
    id: string;
    value: number;
    options: number[];
    label: string;
    onChange: (value: string) => void;
    className?: string;
}

export const ZoomDropdownTool = React.forwardRef(
    ({ id, className, value, options, onChange, label }: Props, ref: Ref<HTMLDivElement>) => {
        return (
            <>
                <label id={`${id}-label`} className="swan-visually-hidden">
                    {label}
                </label>
                <LegacyListbox
                    id={id}
                    aria-labelledby={`${id}-label`}
                    value={value.toString()}
                    className={classnames(className, styles.zoomDropdownTool, "zoom-dropdown")}
                    onChange={onChange}
                >
                    <LegacyListboxButton
                        ref={ref}
                        className={classnames(
                            "text-size-7",
                            "zoom-dropdown-current-zoom",
                            styles.zoomDropdownToolCurrentZoom
                        )}
                    >
                        {value}%
                    </LegacyListboxButton>
                    <LegacyListboxPopover
                        className={classnames("zoom-dropdown-popover", styles.zoomDropdownToolPopover)}
                        portal={false}
                    >
                        {/* @ts-expect-error: Issue with SWAN types: https://vistaprint.slack.com/archives/CB9FVD94J/p1670967014616639  */}
                        <LegacyListboxList className={classnames("zoom-dropdown-list", styles.zoomDropdownToolList)}>
                            {options.map(option => (
                                <LegacyListboxOption
                                    key={option}
                                    className={classnames(
                                        "text-size-7",
                                        "zoom-dropdown-list-item",
                                        styles.zoomDropdownToolListItem,
                                        { [styles.listItemIsActive]: value === option }
                                    )}
                                    value={option.toString()}
                                >
                                    {option}%
                                </LegacyListboxOption>
                            ))}
                        </LegacyListboxList>
                    </LegacyListboxPopover>
                </LegacyListbox>
            </>
        );
    }
);

ZoomDropdownTool.displayName = "ZoomDropdownTool";
