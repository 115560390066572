import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ToggleSwitch, ToggleSwitchOnLabel, ToggleSwitchOffLabel, FlexBox, Typography } from "@vp/swan";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";

const messages = defineMessages({
    removeBackground: {
        id: "studio.panel.editImage.removeBackground",
        defaultMessage: "Remove background",
        description: {
            note: "Label for toggle switch to remove image background"
        }
    },
    backgroundRemoved: {
        id: "studio.panel.editImage.backgroundRemoved",
        defaultMessage: "Background removed",
        description: {
            note: "Label for toggle switch when image background has been removed"
        }
    },
    removingBackground: {
        id: "studio.panel.editImage.removingBackground",
        defaultMessage: "Removing background...",
        description: {
            note: "Label for toggle switch while the image background is being removed"
        }
    },
    restoringBackground: {
        id: "studio.panel.editImage.restoringBackground",
        defaultMessage: "Restoring background...",
        description: {
            note: "Label for toggle switch while the image background is being restored"
        }
    },
    enhance: {
        id: "studio.panel.editImage.enhance",
        defaultMessage: "Auto enhance",
        description: {
            note: "Label for toggle switch to enhance image"
        }
    },
    enhanced: {
        id: "studio.panel.editImage.enhanced",
        defaultMessage: "Image enhanced",
        description: {
            note: "Label for toggle switch when image has been enhanced"
        }
    },
    enhancing: {
        id: "studio.panel.editImage.enhancing",
        defaultMessage: "Enhancing image...",
        description: {
            note: "Label for toggle switch while image is being enhanced"
        }
    },
    revertingEnhanced: {
        id: "studio.panel.editImage.revertingEnhanced",
        defaultMessage: "Reverting enhance...",
        description: {
            note: "Label for toggle switch while image is reverting enhanced"
        }
    },
    backgroundRemoveDisabled: {
        id: "studio.panel.editImage.backgroundRemoveDisabled",
        defaultMessage: "Background removal is not available for this image.",
        description: {
            note: "Label for background remove toggle when it is disabled"
        }
    },
    enhanceDisabled: {
        id: "studio.panel.editImage.enhanceDisabled",
        defaultMessage: "Auto enhance is not available for this image.",
        description: {
            note: "Label for enhance image toggle when it is disabled"
        }
    }
});

export enum ToolType {
    RemoveBackground = "RemoveBackground",
    Enhance = "Enhance"
}

const onString = {
    [ToolType.RemoveBackground]: messages.backgroundRemoved.id,
    [ToolType.Enhance]: messages.enhanced.id
};

const offString = {
    [ToolType.RemoveBackground]: messages.removeBackground.id,
    [ToolType.Enhance]: messages.enhance.id
};

const onLoadingString = {
    [ToolType.RemoveBackground]: messages.restoringBackground.id,
    [ToolType.Enhance]: messages.revertingEnhanced.id
};

const offLoadingString = {
    [ToolType.RemoveBackground]: messages.removingBackground.id,
    [ToolType.Enhance]: messages.enhancing.id
};

const disabledTooltip = {
    [ToolType.RemoveBackground]: messages.backgroundRemoveDisabled.id,
    [ToolType.Enhance]: messages.enhanceDisabled.id
};

interface Props {
    toolType: ToolType;
    onClick: () => void;
    isActivated: boolean;
    isLoading: boolean;
    disabled: boolean;
    className?: string;
}

export const EditImageToggle = (props: Props) => {
    const { isActivated, onClick, isLoading, disabled, toolType, className } = props;
    const { t } = useTranslationSSR();

    return (
        <ToggleSwitch
            className={className}
            activated={isActivated}
            onRequestActivatedChange={onClick}
            disabled={disabled || isLoading}
            size="mini"
            title={disabled ? t(disabledTooltip[toolType]) : undefined}
        >
            <ToggleSwitchOnLabel fontSize={-1}>
                {isLoading ? (
                    <FlexBox alignItems="center">
                        <LoadingSpinner variant={SpinnerVariant.Small} />
                        <Typography marginLeft={2}>{t(onLoadingString[toolType])}</Typography>
                    </FlexBox>
                ) : (
                    <Typography>{t(onString[toolType])}</Typography>
                )}
            </ToggleSwitchOnLabel>
            <ToggleSwitchOffLabel fontSize={-1}>
                {isLoading ? (
                    <FlexBox alignItems="center">
                        <LoadingSpinner variant={SpinnerVariant.Small} />
                        <Typography marginLeft={2}>{t(offLoadingString[toolType])}</Typography>
                    </FlexBox>
                ) : (
                    <Typography>{t(offString[toolType])}</Typography>
                )}
            </ToggleSwitchOffLabel>
        </ToggleSwitch>
    );
};

EditImageToggle.displayName = "EditImageToggle";
