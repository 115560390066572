import React from "react";
import { useAppSelector } from "@shared/redux";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    FluidImage,
    SquareImageContainer,
    Carousel,
    CarouselSlide,
    Column,
    GridContainer,
    Row,
    Typography
} from "@vp/swan";
import { substrateColorAttributeKey } from "@shared/features/Product";
import { AITemplate, useAITemplates } from "./AITemplatesProvider";

export interface AITemplatesRecentlyUsedProps {
    onClick: (
        template: AITemplate,
        index: number,
        isPreviouslyUsedTemplate: boolean,
        isRecentlyUsedTemplate: boolean
    ) => void;
}

export function AITemplatesRecentlyUsed({ onClick }: AITemplatesRecentlyUsedProps) {
    const { t } = useTranslationSSR();
    const { recentlyUsedTemplates, aiTemplatesPanelMessages } = useAITemplates();
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const background = customerSelectedProductOptions?.[substrateColorAttributeKey];
    return (
        <>
            {recentlyUsedTemplates.length > 0 && (
                <>
                    <Typography fontSize="1" textColor="gray-700" ml={1} data-testid="aiTemplatesRecentlyUsedTitle">
                        {t(aiTemplatesPanelMessages.recentlyUsedAITemplates.id)}
                    </Typography>
                    {recentlyUsedTemplates.length < 3 ? (
                        <GridContainer marginY={3}>
                            <Row>
                                {recentlyUsedTemplates.map((template, index) => {
                                    return (
                                        <Column span={5} spanXs={5} paddingLeft={1} key={template.id}>
                                            <SquareImageContainer>
                                                <FluidImage
                                                    alt={`${t(
                                                        aiTemplatesPanelMessages.recentlyUsedAITemplateImageAltText.id
                                                    )} ${index + 1}`}
                                                    src={template.metadata.previewUrl}
                                                    onClick={() => onClick(template, index, true, true)}
                                                    data-testid={`aiTemplatesRecentlyUsed-${index + 1}`}
                                                    style={{ background, cursor: "pointer" }}
                                                />
                                            </SquareImageContainer>
                                        </Column>
                                    );
                                })}
                            </Row>
                        </GridContainer>
                    ) : (
                        <Carousel
                            accessibleTextForPrevious={t(aiTemplatesPanelMessages.recentlyUsedAITemplatesPrevious.id)}
                            accessibleTextForNext={t(aiTemplatesPanelMessages.recentlyUsedAITemplatesNext.id)}
                            slidesToShow={2.4}
                            marginY={3}
                            marginX={2}
                            infinite={false}
                            skin="full"
                        >
                            {recentlyUsedTemplates.map((template, index) => {
                                return (
                                    <CarouselSlide paddingX={1} key={template.id}>
                                        <SquareImageContainer>
                                            <FluidImage
                                                alt={`${t(
                                                    aiTemplatesPanelMessages.recentlyUsedAITemplateImageAltText.id
                                                )} ${index + 1}`}
                                                src={template.metadata.previewUrl}
                                                onClick={() => onClick(template, index, true, true)}
                                                data-testid={`aiTemplatesRecentlyUsed-${index + 1}`}
                                                style={{
                                                    background:
                                                        customerSelectedProductOptions?.[substrateColorAttributeKey],
                                                    cursor: "pointer"
                                                }}
                                            />
                                        </SquareImageContainer>
                                    </CarouselSlide>
                                );
                            })}
                        </Carousel>
                    )}
                </>
            )}
        </>
    );
}

AITemplatesRecentlyUsed.displayName = "AITemplatesRecentlyUsed";
