import { defineMessages } from "@vp/i18n-helper";

export const changeTemplateMessages = defineMessages({
    desktopModalHeader: {
        id: "studio.components.changeTemplate.desktopModalHeader",
        defaultMessage: "Change template",
        description: {
            note: "Text for change template modal header"
        }
    },

    modalSubheader: {
        id: "studio.components.changeTemplate.modalSubheader",
        defaultMessage: "Choose a new template to start editing.",
        description: {
            note: "The subheader for the change template modal"
        }
    },

    changeTemplateConfirmationModalHeader: {
        id: "studio.components.changeTemplate.changeTemplateConfirmationModalHeader",
        defaultMessage: "Change to this template?",
        description: {
            note: "Modal asking for confirmation before switching to a new template"
        }
    },

    changeTemplateConfirmationModalMobileTitle: {
        id: "studio.components.changeTemplate.changeTemplateConfirmationModalMobileTitle",
        defaultMessage: "Change template",
        description: {
            note: "Title for modal asking for confirmation before switching to a new template"
        }
    },

    changeTemplateConfirmationModalBody: {
        id: "studio.components.changeTemplate.changeTemplateConfirmationModalBody",
        defaultMessage: "Edits made to the previous template are saved in My Projects.",
        description: {
            note: "Text in the confirmation modal informing the user that their previous template has been saved"
        }
    },

    changeTemplateConfirmationModalButton: {
        id: "studio.components.changeTemplate.changeTemplateConfirmationModalButton",
        defaultMessage: "Use this template",
        description: {
            note: "A button that confirms the user wants to switch to a new template"
        }
    },

    changeTemplateConfirmationImage: {
        id: "studio.components.changeTemplate.changeTemplateConfirmationImage",
        defaultMessage: "Change template confirmation preview image",
        description: {
            note: "Descriptive alt label for preview of change template confirmation Image"
        }
    },

    changingTemplate: {
        id: "studio.components.changeTemplate.changingTemplate",
        defaultMessage: "Changing template...",
        description: {
            note: "Text for a loading screen while studio loads with a new template"
        }
    },

    changeTemplateNoSearchResults: {
        id: "studio.components.changeTemplate.changeTemplateNoSearchResults",
        defaultMessage: "No templates match this search.",
        description: {
            note: "Text to show when templatesearch for change template yeilds no results"
        }
    },

    previousDesignSaved: {
        id: "studio.components.changeTemplate.previousDesignSaved",
        defaultMessage: "The previous design is saved in My Projects.",
        description: {
            note: "A toast notification telling the user that their previous design has been saved"
        }
    },

    mobileModalHeader: {
        id: "studio.components.changeTemplate.mobileModalHeader",
        defaultMessage: "Templates",
        description: {
            note: "This is a header for the mobile template selector"
        }
    },

    closeModal: {
        id: "studio.components.changeTemplate.close",
        defaultMessage: "Close Change Template Modal",
        description: {
            note: "aria-label for Button to close the change template modal"
        }
    },

    designTilePreviewAlt: {
        id: "studio.components.changeTemplate.designTilePreviewAlt",
        defaultMessage: "Change template",
        description: {
            note: "Alt text for the template preview in the design tile"
        }
    },

    noFilterResults: {
        id: "studio.components.changeTemplate.noFilterResults",
        defaultMessage: "We couldn't find any results using your current filters.",
        description: {
            note: "Text to show when no templates match the selected filters"
        }
    },
    exploreUnfiltered: {
        id: "studio.components.changeTemplate.exploreUnfiltered",
        defaultMessage: "Explore unfiltered results",
        description: {
            note: "Label for template results with no filters applied"
        }
    },
    colorSwatchLabelText: {
        id: "studio.components.changeTemplate.colorSwatchLabelText",
        defaultMessage: "Hex Color",
        description: {
            note: "Text that precedes a description of a color's value for accessibility. Hex as in Hexadecimal Color"
        }
    },

    currentTemplate: {
        id: "studio.components.changeTemplate.currentTemplate",
        defaultMessage: "Current template",
        description: {
            note: "Label for the selected template"
        }
    },
    applyTemplateChangeButtonLabel: {
        id: "studio.components.changeTemplate.applyTemplateChangeButtonLabel",
        defaultMessage: "Apply changes",
        description: {
            note: "Label for apply template change button"
        }
    }
});
