import React, { ReactNode, useCallback, useRef } from "react";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { FloppyDiskIcon, Button } from "@shared/features/StudioChrome";
import { Typography } from "@vp/swan";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, showDesignReview, showEditInCartModal } from "@shared/redux";
import { EDIT_FROM_CART_MODAL_TYPE } from "@shared/utils/Cart";
import { useCartContext } from "@shared/features/Cart";
import { Save, UseSaveParams } from "./useSave";

const messages = defineMessages({
    mobileFileSheetSave: {
        id: "studio.components.myprojects.mobilefilesheet.save",
        defaultMessage: "Save changes",
        description: {
            note: "Button to save project"
        }
    }
});

interface Props {
    className?: string;
    onSave: () => void;
    icon?: ReactNode;
    useSave: (params: Omit<UseSaveParams, "saveDesign">) => Save;
}

export function SaveButtonSmallScreen({ className, onSave, icon, useSave }: Props) {
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const { t } = useTranslationSSR();
    const { changesUpdatedModalSeen } = useAppSelector(state => state.editInCartModal);
    const startTimeRef = useRef<number>(0);
    const setStartTime = (startTime: number) => {
        startTimeRef.current = startTime;
    };
    const trackSaveTime = () => {
        const endTime = performance.now();
        fireUserInteractionTrackingEvent("Save", endTime - startTimeRef.current);
    };
    const save = useSave({
        onSaveCallback: () => {
            trackSaveTime();
            onSave();
        },
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVE_BUTTON
    });

    const onSaveClick = useCallback(
        e => {
            e.stopPropagation();
            const startTime = performance.now();
            setStartTime(startTime);
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SAVE_BUTTON,
                label: "Save Button"
            });

            // if item is already in cart, the first time user saves, show changes updated modal
            if (isItemInCart && !changesUpdatedModalSeen) {
                dispatch(
                    showEditInCartModal({
                        showModal: true,
                        modalType: EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED,
                        callback: () => dispatch(showDesignReview({ show: true })),
                        changesUpdatedModalSeen: true
                    })
                );
            }

            save();
        },
        [dispatch, isItemInCart, save, changesUpdatedModalSeen]
    );

    return (
        <Button className={classNames("save-changes-btn", className)} onClick={onSaveClick}>
            {icon || <FloppyDiskIcon aria-hidden="true" />}
            <Typography className="button-label">{t(messages.mobileFileSheetSave.id)}</Typography>
        </Button>
    );
}

SaveButtonSmallScreen.displayName = "SaveButtonSmallScreen";
