import React, { FC } from "react";
import classNames from "classnames";
import { useOnKeyDown } from "@shared/features/Accessibility";
import * as styles from "./CanvasTileButton.module.scss";

type Props = {
    isActive: boolean;
    className?: string;
    ariaLabel?: string;
    onClick: () => void;
};

export const CanvasTileButton: FC<Props> = props => {
    const { isActive, ariaLabel, children, className, onClick } = props;

    return (
        <button
            className={classNames(className, styles.canvasTileButton, "canvas-tile-button", {
                selected: isActive,
                [styles.selected]: isActive
            })}
            data-testid="canvasTileButton"
            onClick={onClick}
            onKeyDown={useOnKeyDown(onClick)}
            aria-label={ariaLabel}
            role="radio"
            aria-checked={isActive}
        >
            {children}
        </button>
    );
};
CanvasTileButton.displayName = "CanvasTileButton";
