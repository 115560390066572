import { getSecondaryCalciferData } from "@shared/features/StudioBootstrap";
import { useAppSelector, useAppDispatch } from "@shared/redux";
import { getStudioConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { switchContext } from "@utilities";
import { useCallback } from "react";
import { LoadNewDesign } from "@shared/features/ChangeTemplate";

export function useLoadNewDesignForChangeTemplate() {
    const dispatch = useAppDispatch();
    const { useColorGenericBacksides } = useAppSelector(state => state.studioConfiguration) ?? false;

    return useCallback<LoadNewDesign>(
        async props => {
            const {
                productKey,
                productVersion,
                studioSelectedProductOptions,
                customerSelectedProductOptions,
                template,
                designDocument,
                locale
            } = props;
            const [productConfigs] = await Promise.all([
                getStudioConfigFromCalciferV2(
                    productKey,
                    undefined,
                    productVersion,
                    customerSelectedProductOptions,
                    studioSelectedProductOptions,
                    null,
                    locale,
                    null,
                    false,
                    null
                ),
                getSecondaryCalciferData({
                    designDocument,
                    productKey,
                    productVersion: productVersion!,
                    studioSelectedProductOptions,
                    template,
                    isFullBleed: false,
                    locale,
                    isColorMatchingBacksideEnabled: useColorGenericBacksides,
                    dispatch
                })
            ]);

            const { designViews, scenesConfiguration: easelScenesConfiguration, studioConfiguration } = productConfigs;

            await switchContext(
                {
                    productKey,
                    productOptions: studioSelectedProductOptions,
                    designDocument,
                    views: designViews.designViews,
                    studioConfiguration,
                    easelScenesConfiguration
                },
                false
            );
        },
        [dispatch, useColorGenericBacksides]
    );
}
