import { defineMessages } from "@vp/i18n-helper";

export const singleColorModalMessages = defineMessages({
    closeModalAriaLabel: {
        id: "easel.ui.components.singleColorModal.closeModalAriaLabel",
        defaultMessage: "Close",
        description: {
            note: "Accessibility label for the modal close button"
        }
    },
    modalContentAriaLabel: {
        id: "easel.ui.components.singleColorModal.modalContentAriaLabel",
        defaultMessage: "Single color modal",
        description: {
            note: "Accessibility label for the modal close button"
        }
    },
    apply: {
        id: "easel.ui.components.singleColorModal.apply",
        defaultMessage: "Apply",
        description: {
            note: "Text for button that applies changes made to the contrast"
        }
    },
    cancel: {
        id: "easel.ui.components.singleColorModal.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Text for button that closes the modal without making changes"
        }
    },
    invertColors: {
        id: "easel.ui.components.singleColorModal.invertColors",
        defaultMessage: "Invert colors",
        description: {
            note: "Button that inverts the image's colors"
        }
    },
    modalTitle: {
        id: "easel.ui.components.singleColorModal.modalTitle",
        defaultMessage: "Adjust image contrast",
        description: {
            note: "Title text for the modal"
        }
    },
    contrastSlider: {
        id: "easel.ui.components.singleColorModal.contrastSlider",
        defaultMessage: "Move the slider until you’re satisfied with the contrast of your image.",
        description: {
            note: "Text for a slider that adjusts the image's color contrast"
        }
    },
    invertColorsLoading: {
        id: "easel.ui.components.singleColorModal.invertColorsLoading",
        defaultMessage: "Processing image...",
        description: {
            note: "This shows while a new inverted image is loading"
        }
    },
    buttonText: {
        id: "easel.ui.components.singleColorModal.buttonText",
        defaultMessage: "Adjust contrast",
        description: {
            note: "Text for the button that opens the modal"
        }
    },
    chooseColor: {
        id: "easel.ui.components.singleColorModal.chooseColor",
        defaultMessage: "Choose a different color",
        description: {
            note: "Accessibility label for the button to open the dropdown to choose a new color"
        }
    },
    previewColor: {
        id: "easel.ui.components.singleColorModal.previewColor",
        defaultMessage: "Single color preview",
        description: {
            note: "Descriptive alt label for preview of single color image"
        }
    }
});
