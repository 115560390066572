import React, { useState, useEffect, useRef, useReducer, useCallback, useLayoutEffect } from "react";
import {
    Button,
    Box,
    Divider,
    FlexBox,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogButtons,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    Typography
} from "@vp/swan";
import classNames from "classnames";
import { useTranslationSSR } from "@vp/i18n-helper";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import debounce from "lodash/debounce";
import once from "lodash/once";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setLoginReason, setShowMyProjects } from "@shared/redux";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { WesSortOptions, getSortedWorks, getWorkCountInfo, getWorksSearchResults, hideWork } from "@shared/utils/Work";
import {
    LoadingSpinner,
    PanelContent,
    PanelTitle,
    SpinnerVariant,
    StickyActionBar
} from "@shared/features/StudioChrome";
import { MyProjectsSortDropdown } from "./MyProjectsSortDropdown";
import { messages } from "./messages";
import { MyProjectsList } from "./MyProjectsList";
import type { LoadProjectCallback, Project } from "./types";
import { MyProjectsSearchBar } from "./MyProjectsSearchBar";
import { MyProjectsLoginButton } from "./MyProjectsLoginButton";
import { useMyProjectsPanelContext } from "./MyProjectsPanelContext";
import { MyProjectsErrorToast } from "./MyProjectsErrorToast";
import { MyProjectsHeader } from "./MyProjectsHeader";
import * as styles from "./MyProjectsLeftSidebarPanel.module.scss";

export const WORKS_TO_FETCH = 10;
const fireInitialUserInteractionOnce = once(fireUserInteractionTrackingEvent);
const fireSubsequentUserInteractionOnce = once(fireUserInteractionTrackingEvent);
const fireLoadMoreUserInteractionOnce = once(fireUserInteractionTrackingEvent);

interface Props {
    isOpen: boolean;
    loadProject: LoadProjectCallback;
}

interface InitialProjectState {
    initialProjectsLoaded: boolean;
    allProjectsReceived: boolean;
    fetchAdditionalProjects: boolean;
    fetchingAdditionalProjects: boolean;
    sortedProjects: Project[];
    numberOfProjects: number;
}

interface Action {
    type: string;
    [key: string]: any;
}

const initialProjectState = {
    initialProjectsLoaded: false,
    allProjectsReceived: false,
    fetchAdditionalProjects: false,
    fetchingAdditionalProjects: false,
    sortedProjects: [],
    numberOfProjects: 0
};

function reducer(state: InitialProjectState, action: Action) {
    switch (action.type) {
        case "fetching_initial_projects":
            return { ...state, allProjectsReceived: false, initialProjectsLoaded: false };
        case "initial_projects_received":
            return {
                ...state,
                sortedProjects: action.initialProjects,
                initialProjectsLoaded: true,
                numberOfProjects: action.numberOfProjects || state.numberOfProjects
            };
        case "fetch_additional_projects":
            return { ...state, fetchAdditionalProjects: true };
        case "fetching_additional_projects":
            return { ...state, fetchingAdditionalProjects: true };
        case "additional_projects_received":
            return {
                ...state,
                sortedProjects: [...state.sortedProjects, ...action.additionalProjects],
                allProjectsReceived: !action.additionalProjects.length,
                fetchAdditionalProjects: false,
                fetchingAdditionalProjects: false
            };
        case "additional_projects_failed":
            return {
                ...state,
                fetchingAdditionalProjects: false
            };
        case "prepend_project":
            return {
                ...state,
                sortedProjects: [action.project, ...state.sortedProjects],
                numberOfProjects: state.numberOfProjects + 1
            };
        case "search_projects":
            return {
                ...state,
                sortedProjects: action.searchResults,
                initialProjectsLoaded: true
            };
        case "remove_project":
            return {
                ...state,
                sortedProjects: [...state.sortedProjects].filter(project => {
                    return project.workId !== action.project.workId;
                }),
                numberOfProjects: state.numberOfProjects - 1
            };
        case "reset_projects":
            return initialProjectState;
        default:
            throw new Error();
    }
}

export function MyProjectsLeftSidebarPanel({ isOpen, loadProject }: Props) {
    const {
        previousSort,
        previousScrolledToWorkId,
        previousLoadedProjectsCount,
        previousProjectsCount,
        previousSearchTerm,
        updateMyProjectsState
    } = useMyProjectsPanelContext();
    const reduxDispatch = useAppDispatch();
    const [state, dispatch] = useReducer(reducer, initialProjectState);
    const {
        initialProjectsLoaded,
        allProjectsReceived,
        fetchAdditionalProjects,
        fetchingAdditionalProjects,
        sortedProjects,
        numberOfProjects
    } = state;
    const { t } = useTranslationSSR();
    const { identity, auth } = useIdentityContext();
    const startTime = useAppSelector(state => state.showMyProjects?.startTime);
    const loginReason = useAppSelector(state => state.loginReason);
    const locale = useAppSelector(state => state.locale);
    const [error, setError] = useState({ showToast: false, message: "", errorCode: "" });
    const [sortBy, setSortBy] = useState<WesSortOptions>(previousSort);
    const isAnonymousUser = useIsAnonymousUser();
    const [searchTerm, setSearchTerm] = useState(previousSearchTerm);
    const [previousStateLoaded, setPreviousStateLoaded] = useState(false);
    const [previousProjectScrolled, setPreviousProjectScrolled] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState<{
        showModal: boolean;
        project: Project | null;
    }>({
        showModal: false,
        project: null
    });
    const [sortStartTime, setSortStartTime] = useState<number | null>(null);
    const [searchStartTime, setSearchStartTime] = useState<number | null>(null);

    const topScrollRef = useRef<HTMLHeadingElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const modalNavRef = useRef<HTMLDivElement>(null);
    const myProjectsListRef = useRef<HTMLDivElement>(null);

    // User interaction tracking for actions that load initial projects (open panel, sort, search)
    useEffect(() => {
        if (initialProjectsLoaded) {
            const endTime = performance.now();

            if (sortStartTime && sortBy === WesSortOptions.LAST_CREATED) {
                fireUserInteractionTrackingEvent("Sort My Projects Date Created", endTime - sortStartTime, {
                    totalProjectsCount: numberOfProjects
                });
                setSortStartTime(null);
            } else if (sortStartTime && sortBy === WesSortOptions.LAST_MODIFIED) {
                fireUserInteractionTrackingEvent("Sort My Projects Last Edited", endTime - sortStartTime, {
                    totalProjectsCount: numberOfProjects
                });
                setSortStartTime(null);
            } else if (searchStartTime) {
                fireUserInteractionTrackingEvent("Search My Projects", endTime - searchStartTime, {
                    totalProjectsCount: numberOfProjects
                });
                setSearchStartTime(null);
            } else if (previousProjectsCount === 0) {
                fireInitialUserInteractionOnce(
                    "Load Initial My Projects",
                    startTime ? endTime - startTime : undefined,
                    {
                        totalProjectsCount: numberOfProjects
                    }
                );
            } else if (previousProjectsCount > 0) {
                fireSubsequentUserInteractionOnce(
                    "Load Previously Loaded My Projects",
                    startTime ? endTime - startTime : undefined,
                    {
                        totalProjectsCount: numberOfProjects,
                        previousLoadedProjectsCount
                    }
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialProjectsLoaded]);

    useEffect(() => {
        async function getSortedProjects() {
            dispatch({ type: "fetching_initial_projects" });
            const authToken = auth.getToken();
            let worksToFetch = WORKS_TO_FETCH;
            const projectCount = await getWorkCountInfo(identity.shopperId, authToken);

            // If the panel was open in the past, figure out how many projects we should get
            // based on the previous amount loaded plus any projects created since
            if (!previousStateLoaded && previousProjectsCount > 0) {
                worksToFetch = projectCount.ownedWorks - previousProjectsCount + previousLoadedProjectsCount;
                setPreviousStateLoaded(true);
            }
            const callsToMake = Math.ceil(worksToFetch / 50); // WES only allows fetching of 50 at a time
            const sortedWorks: Project[] = [];
            for (let i = 0; i < callsToMake; i++) {
                const offset = i * 50;
                const worksToFetchThisLoop = Math.min(50, worksToFetch - i * 50);
                const sortedWorksPromise = searchTerm
                    ? getWorksSearchResults(
                          identity.shopperId,
                          authToken,
                          searchTerm,
                          sortBy,
                          worksToFetchThisLoop,
                          offset
                      )
                    : getSortedWorks(identity.shopperId, authToken, locale, sortBy, worksToFetchThisLoop, sortedWorks);
                // eslint-disable-next-line no-await-in-loop
                const newSortedWorks: Project[] = await sortedWorksPromise;
                sortedWorks.push(...newSortedWorks);
            }
            dispatch({
                type: "initial_projects_received",
                initialProjects: sortedWorks,
                numberOfProjects: projectCount.ownedWorks
            });
        }

        getSortedProjects();

        // exclude searchTerm from dependencies to prevent searching on every character entered
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, isOpen, identity.shopperId, sortBy, locale]);

    // Todo: Handle this for left sidebar panel DT-13088
    // If loading the previous state, scroll to project user was previously scrolled to before they closed the panel
    useLayoutEffect(() => {
        if (!previousProjectScrolled && previousScrolledToWorkId && isOpen && initialProjectsLoaded) {
            const element = document.getElementById(previousScrolledToWorkId);
            if (element && contentRef.current) {
                contentRef.current.scrollBy({ top: element.offsetTop, behavior: "smooth" });
            }
            setPreviousProjectScrolled(true);
        }
        // We don't want to run this again when previousStateLoaded changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousScrolledToWorkId, initialProjectsLoaded, isOpen]);

    useEffect(() => {
        async function getAdditionalProjects() {
            try {
                const additionalProjectsStartTime = performance.now();
                const authToken = auth.getToken();
                const projectOffset = sortedProjects.length;
                const additionalProjects = searchTerm
                    ? await getWorksSearchResults(
                          identity.shopperId,
                          authToken,
                          searchTerm,
                          sortBy,
                          WORKS_TO_FETCH,
                          projectOffset
                      )
                    : await getSortedWorks(
                          identity.shopperId,
                          authToken,
                          locale,
                          sortBy,
                          WORKS_TO_FETCH,
                          sortedProjects
                      );
                const additionalProjectsEndTime = performance.now();
                fireLoadMoreUserInteractionOnce(
                    "Load More My Projects",
                    additionalProjectsEndTime - additionalProjectsStartTime,
                    { totalProjectsCount: numberOfProjects }
                );
                dispatch({ type: "additional_projects_received", additionalProjects });
            } catch {
                dispatch({ type: "additional_projects_failed" });
            }
        }
        if (!allProjectsReceived && initialProjectsLoaded && !fetchingAdditionalProjects && fetchAdditionalProjects) {
            dispatch({ type: "fetching_additional_projects" });
            getAdditionalProjects();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialProjectsLoaded, fetchAdditionalProjects, auth, identity.shopperId, isOpen, sortBy, locale]);

    const performSearch = useCallback(
        async (currentSearchTerm: string) => {
            dispatch({ type: "fetching_initial_projects" });
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.SEARCH_PROJECTS,
                label: "Search projects"
            });
            const searchStartTime = performance.now();
            setSearchStartTime(searchStartTime);
            const authToken = auth.getToken();
            const searchResults = await getWorksSearchResults(
                identity.shopperId,
                authToken,
                currentSearchTerm,
                sortBy,
                WORKS_TO_FETCH
            );
            dispatch({ type: "search_projects", searchResults });
        },
        [auth, identity.shopperId, sortBy]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(
        debounce(currentSearchTerm => performSearch(currentSearchTerm), 500),
        [performSearch]
    );

    const resetSearch = async () => {
        setSearchTerm("");
        dispatch({ type: "fetching_initial_projects" });
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLEAR_SEARCH_PROJECTS,
            label: "Clear search projects"
        });
        const authToken = auth.getToken();
        const sortedWorks = await getSortedWorks(identity.shopperId, authToken, locale, sortBy, WORKS_TO_FETCH);
        dispatch({
            type: "initial_projects_received",
            initialProjects: sortedWorks
        });
    };

    const handleOnChange = (event: { target: HTMLInputElement }) => {
        const currentSearchTerm = event.target.value;
        if (currentSearchTerm === "") {
            resetSearch();
        } else {
            setSearchTerm(currentSearchTerm);
            if (currentSearchTerm.length >= 3) {
                debounceSearch(currentSearchTerm);
            }
        }
    };

    const prependProject = (project: Project) => {
        dispatch({ type: "prepend_project", project });
        if (topScrollRef.current) {
            elementScrollIntoView(topScrollRef.current, { behavior: "smooth", block: "end" });
        }
    };

    const deleteProject = (project: Project) => {
        dispatch({ type: "remove_project", project });
        if (sortedProjects.length <= 6) {
            dispatch({ type: "fetch_additional_projects" });
        }
    };

    const showDeleteConfirmation = (project: Project) => {
        setShowDeleteModal({ showModal: true, project });
    };

    const showMyProjectsErrorToast = (message: string, errorCode: string) => {
        setError({ showToast: true, message, errorCode });
    };

    const clearMyProjectsErrorToast = () => {
        setError({ showToast: false, message: "", errorCode: "" });
    };

    // Return the workId of the project the user is currently scrolled to
    const findScrolledToWorkId = () => {
        const nodeList = myProjectsListRef.current?.querySelectorAll("[data-item]");
        if (nodeList) {
            // This is a nodeList so this is a browser safe way to iterate through it
            const element = Array.prototype.find.call(nodeList, (listItem: Element) => {
                if (modalNavRef && modalNavRef.current) {
                    // 17px to account for 16px padding and 1px border of a project
                    return (
                        listItem.getBoundingClientRect().y >= modalNavRef.current.getBoundingClientRect().height - 17
                    );
                }
                return false;
            });
            return element ? element.id : null;
        }
        return null;
    };

    // ToDo handle resetting the state of projects in leftsidebar
    const handleClose = () => {
        // Store in context which project user is scrolled to along with various other existing states
        updateMyProjectsState({
            updatedLoadedProjectsCount: sortedProjects.length,
            updatedSort: sortBy,
            updatedScrolledToWorkId: findScrolledToWorkId(),
            updatedProjectsCount: numberOfProjects,
            updatedSearchTerm: searchTerm
        });
        setPreviousStateLoaded(false);
        setPreviousProjectScrolled(false);
        dispatch({ type: "reset_projects" });
        reduxDispatch(setShowMyProjects({ show: false }));
        if (loginReason) {
            reduxDispatch(setLoginReason(null));
        }
    };

    const onDelete = async () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_DELETE_MY_PROJECTS,
            label: "My Projects Delete Button"
        });
        clearMyProjectsErrorToast();
        const { project } = showDeleteModal;
        if (project) {
            try {
                const authToken = auth.getToken();
                await hideWork(authToken, project.workId);
                deleteProject(project);
            } catch (err) {
                handleError(err, ERROR_CODES.DELETE_PROJECT, false, true, true);
                showMyProjectsErrorToast(messages.myProjectsDeleteError.id, "15-23");
            }
        }
        setShowDeleteModal({ showModal: false, project: null });
    };

    const onSort = (sortValue: WesSortOptions) => {
        const sortStartTime = performance.now();
        setSortStartTime(sortStartTime);
        setSortBy(sortValue);
        dispatch({ type: "reset_projects" });
    };

    useEffect(() => {
        clearMyProjectsErrorToast();
        handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleScroll = () => {
        if (contentRef.current && initialProjectsLoaded) {
            const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
            const nearBottom = scrollHeight - scrollTop < clientHeight * 2;
            if (nearBottom && !state.fetchAdditionalProjects) {
                dispatch({ type: "fetch_additional_projects" });
            }
        }
    };
    return (
        <>
            <PanelContent
                aria-label={t(messages.myProjectModalAriaLabel.id)}
                className={styles.container}
                stickyHeader={
                    <Box className={styles.panelNav}>
                        <PanelTitle>{t(messages.myProjectsPanelTitle.id)}</PanelTitle>
                        <MyProjectsErrorToast
                            error={error}
                            setError={() => setError({ showToast: false, message: "", errorCode: "" })}
                        />
                        <FlexBox
                            justifyContent="space-between"
                            alignItems="center"
                            className={classNames(styles.tools, {
                                [styles.toolsDisabled]: !sortedProjects.length
                            })}
                        >
                            <MyProjectsHeader numberOfProjects={numberOfProjects} />
                            <MyProjectsSortDropdown sortBy={sortBy} onSort={onSort} />
                        </FlexBox>
                        <Divider className={styles.divider} marginTop={4} />
                        {numberOfProjects >= 6 ? (
                            <MyProjectsSearchBar
                                searchTerm={searchTerm}
                                handleOnChange={handleOnChange}
                                resetSearch={resetSearch}
                                performSearch={performSearch}
                            />
                        ) : null}
                    </Box>
                }
            >
                <div
                    id="my-projects-list"
                    className={classNames(styles.dialogBody, styles.myProjectsScrollList)}
                    ref={contentRef}
                    onScroll={handleScroll}
                >
                    <span ref={topScrollRef} />
                    {initialProjectsLoaded ? (
                        <MyProjectsList
                            sortedProjects={sortedProjects}
                            prependProject={prependProject}
                            removeProject={deleteProject}
                            onClose={handleClose}
                            showMyProjectsErrorToast={showMyProjectsErrorToast}
                            clearMyProjectsErrorToast={clearMyProjectsErrorToast}
                            showDeleteConfirmation={showDeleteConfirmation}
                            searchTerm={searchTerm}
                            resetSearch={resetSearch}
                            loadProject={loadProject}
                        />
                    ) : (
                        <div className={`${styles.loadingSpinner} swan-m-11`}>
                            <LoadingSpinner variant={SpinnerVariant.Large} centering />
                            <Typography fontSize="-1" textAlign="center" fontWeight="normal">
                                {t(messages.loadingProjects.id)}
                            </Typography>
                        </div>
                    )}
                    {fetchingAdditionalProjects && (
                        <div className="swan-m-11">
                            <LoadingSpinner variant={SpinnerVariant.Large} centering />
                        </div>
                    )}
                    {isAnonymousUser && (
                        <StickyActionBar className={styles.stickyLogin}>
                            <MyProjectsLoginButton />
                        </StickyActionBar>
                    )}
                </div>
            </PanelContent>
            <LegacyModalDialog
                bodyWidth="capped"
                isOpen={showDeleteModal.showModal}
                onRequestDismiss={() => setShowDeleteModal({ showModal: false, project: null })}
            >
                <LegacyModalDialogContent aria-labelledby={t(messages.myProjectsDeleteModalAriaLabel.id)}>
                    <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.closeModalAriaLabel.id)} />
                    <LegacyModalDialogHeader>
                        <LegacyModalDialogTitle id={t(messages.myProjectsDeleteModalAriaLabel.id)}>
                            {t(messages.myProjectsDeletePanelTitle.id)}
                        </LegacyModalDialogTitle>
                    </LegacyModalDialogHeader>
                    <LegacyModalDialogBody>{t(messages.myProjectsDeletePanelSubject.id)} </LegacyModalDialogBody>
                    <LegacyModalDialogButtons>
                        <Button skin="primary" onClick={onDelete}>
                            {t(messages.myProjectsDelete.id)}
                        </Button>
                        <Button
                            skin="secondary"
                            onClick={() => {
                                setShowDeleteModal({ showModal: false, project: null });
                            }}
                        >
                            {t(messages.myProjectsPanelCancel.id)}
                        </Button>
                    </LegacyModalDialogButtons>
                </LegacyModalDialogContent>
            </LegacyModalDialog>
        </>
    );
}
MyProjectsLeftSidebarPanel.displayName = "MyProjectsLeftSidebarPanel";
