import { update, get, keys } from "idb-keyval";
import { windowExists } from "@shared/utils/WebBrowser";

const MAX_TEMPLATES_TO_KEEP = 25;
const currentVersion = 1;

export interface RecentlyUsedTemplateData {
    designId: string;
    selectedColorSwatchDesignId: string;
}

const compareElements = (a: RecentlyUsedTemplateData, b: RecentlyUsedTemplateData) => a.designId === b.designId;

export function addTemplateToRecent<T>(arr: T[] | undefined, entry: T, compare: (a: T, b: T) => boolean) {
    if (arr) {
        const index = arr.findIndex(element => compare(element, entry));
        if (index !== -1) {
            arr.splice(index, 1);
        }
        arr.unshift(entry);
        if (arr.length > MAX_TEMPLATES_TO_KEEP) {
            return arr.slice(arr.length - MAX_TEMPLATES_TO_KEEP, arr.length);
        }
        return arr;
    }
    return [entry];
}

const RECENTLY_USED_DESIGNS_KEY = "recentlyUsedDesigns";
const createProductVersionKey = (productKey: string, productVersion: number) => `${productKey}+${productVersion}`;

// When an an images is used on the canvas add it to the list (or create the list if it doesn't exist
export const updateRecentlyUsedTemplatesList = (
    design: RecentlyUsedTemplateData,
    productKey: string,
    productVersion: number
) => {
    const storageKey = createProductVersionKey(productKey, productVersion);
    update(RECENTLY_USED_DESIGNS_KEY, val => {
        if (!val) {
            return {
                version: currentVersion,
                data: { [storageKey]: addTemplateToRecent(undefined, design, compareElements) }
            };
        }
        return {
            ...val,
            data: { ...val.data, [storageKey]: addTemplateToRecent(val.data[storageKey], design, compareElements) }
        };
    });
};

// Get a comma separated list of recently used images
export const getRecentlyUsedTemplates = async (productKey: string, productVersion: number) => {
    const previous = await get(RECENTLY_USED_DESIGNS_KEY);
    if (!previous) {
        return undefined;
    }
    const storageKey = createProductVersionKey(productKey, productVersion);
    return previous.data[storageKey];
};

// This function checks idb to see if the key is present
export const getShowRecentlyUsedDesigns = async () => {
    if (!windowExists()) {
        return false;
    }
    const keyList = await keys();
    return keyList.includes(RECENTLY_USED_DESIGNS_KEY);
};
