import React, { FC, useContext, createContext, ReactNode } from "react";

const draggableUploadsPanelContext = createContext<boolean>(false);

export function useDraggableUploadsPanel() {
    return useContext(draggableUploadsPanelContext);
}

type Props = {
    isDraggable: boolean;
    children: ReactNode | ReactNode[];
};

export const DraggableUploadsPanelProvider: FC<Props> = ({ isDraggable, children }) => {
    const { Provider } = draggableUploadsPanelContext;
    // Holds the object with all the current uploads progresses
    return <Provider value={isDraggable}>{children}</Provider>;
};
DraggableUploadsPanelProvider.displayName = "DraggableUploadsPanelProvider";
