import { tryFetch } from "@shared/utils/Network";
import { ImagemindReportingInput } from "@shared/redux/slices/selectedAITemplateReportingData";
import { SimtagSearchInput } from "../components/Panels/AITemplates/AITemplatesProvider";

const host = IMAGEMIND_URL;

export async function getAITemplates(authToken: string, templateSearchRequest: SimtagSearchInput) {
    const simtagUrl = `${host}/imagemind/simtag`;

    const aiTemplates = await tryFetch({
        url: simtagUrl,
        options: {
            method: "POST",
            body: JSON.stringify(templateSearchRequest),
            headers: {
                Authorization: `Bearer ${authToken}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "imagemindClient:getAITemplates",
        friendlyDescription: "get AI templates"
    });

    return { id: aiTemplates.id, results: aiTemplates.output.similarImages[0].results };
}

export async function reportAITemplateUsage(templateUsageReport: ImagemindReportingInput, authToken: string) {
    const feedbackUrl = `${host}/imagemind/feedback`;

    try {
        await tryFetch({
            url: feedbackUrl,
            options: {
                method: "POST",
                body: JSON.stringify(templateUsageReport),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            },
            moduleFunction: "imagemindClient:reportAITemplateUsage",
            friendlyDescription: "report AI template usage"
        });
    } catch (err) {
        // Note: if this feedback call fails, functionality of Studio should not be impacted
    }
}
