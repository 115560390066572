type Props = {
    event: KeyboardEvent;
    activeTab: number;
    ref: any;
};

export function tabControlWithArrow({ event, ref, activeTab }: Props) {
    let tabFocus = activeTab;
    if (
        ref.current &&
        (event.key === "ArrowUp" ||
            event.key === "ArrowRight" ||
            event.key === "ArrowLeft" ||
            event.key === "ArrowDown" ||
            event.key === "Home" ||
            event.key === "End" ||
            event.key === "Enter")
    ) {
        if (event.key !== "Enter") {
            event.preventDefault();
        }
        event.stopImmediatePropagation();
        const tabs = ref.current.querySelectorAll('[role="tab"]');
        // @ts-ignore
        tabs[tabFocus].setAttribute("tabindex", -1);

        if (event.key === "Enter") {
            const currentActiveTab = Array.from(tabs).findIndex(
                (tabItem: EventTarget | null) => tabItem === event.target
            );
            tabFocus = Number(currentActiveTab);
        } else if (event.key === "Home") {
            tabFocus = 0;
        } else if (event.key === "End") {
            tabFocus = tabs.length - 1;
        } else if (event.key === "ArrowDown" || event.key === "ArrowRight") {
            tabFocus += 1;
            // If we're at the end, go to the start
            if (tabFocus >= tabs.length) {
                tabFocus = 0;
            }
        } else if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
            tabFocus -= 1;
            // If we're at the start, move to the end
            if (tabFocus < 0) {
                tabFocus = tabs.length - 1;
            }
        }
        // @ts-ignore
        tabs[tabFocus].setAttribute("tabindex", 0);
        (tabs[tabFocus] as HTMLElement)?.focus();
    }

    return tabFocus;
}
