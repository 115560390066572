import React, { HTMLAttributes } from "react";
import classnames from "classnames";
import * as styles from "./FooterBar.module.scss";

type FooterBarProps = HTMLAttributes<HTMLDivElement>;

export const FooterBarLeftContent = ({ className, children, ...props }: FooterBarProps) => {
    return (
        <div className={classnames("bottom-bar-contents-left", styles.contentsLeft, className)} {...props}>
            {children}
        </div>
    );
};

export const FooterBar = ({ children, className, ...props }: FooterBarProps) => {
    return (
        <div className={classnames("bottom-bar-contents", styles.footerBarContents, className)} {...props}>
            {children}
        </div>
    );
};

FooterBar.displayName = "FooterBar";
FooterBarLeftContent.displayName = "FooterBarLeftContent";
