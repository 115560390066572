import React from "react";
import { ArrowShape, ShapeTypes } from "@cimpress-technology/svg-path";
import { DraggableCurve } from "./DraggableCurve";

const metadata: ArrowShape = {
    type: ShapeTypes.DoubleSidedArrow,
    tailWidth: 28,
    headWidth: 50
};

// Used to scale the height of the arrow to match the prior version after a breaking change to the default SVG path
const HEIGHT_SCALE_FACTOR = 0.42;

export const DraggableDoubleArrow = () => {
    return <DraggableCurve metadata={metadata} scaleY={HEIGHT_SCALE_FACTOR} />;
};

DraggableDoubleArrow.displayName = "DraggableDoubleArrow";
