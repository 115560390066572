import { DSS } from "@vp/types-ddif";

export function cleanseCimDocForTransformation(designDocument: DSS.DesignDocument) {
    // delete transformationRealization token
    // @ts-ignore
    if (designDocument?.metadata?.transformationRealization) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        delete designDocument.metadata.transformationRealization;
    }

    // @ts-ignore
    if (designDocument?.metadata?.artworkgeneration) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        delete designDocument.metadata.artworkgeneration;
    }
}

export function transferTemplates(targetDoc: any, templateDoc: any) {
    targetDoc.document.panels.forEach((panel: any) => {
        // find the panel in the template doc with the same name
        const matchedTemplatePanel = templateDoc.document.panels.find(
            (targetPanel: any) => targetPanel.name.toLowerCase() === panel.name.toLowerCase()
        );
        // Can match the panels up and there are documentSources to try to fill change
        if (matchedTemplatePanel && templateDoc.metadata.documentSources.panels) {
            const templateSource = templateDoc.metadata.documentSources.panels.find(
                (panelSource: any) => panelSource.id === matchedTemplatePanel.id
            );
            const targetSource = targetDoc.metadata.documentSources.panels.find(
                (panelSource: any) => panelSource.id === panel.id
            );
            if (templateSource && targetSource) {
                targetSource.data = templateSource.data;
            }
        }
    });
}

export function transferUserAddedItems(targetDocument: any, designDocument: any) {
    return targetDocument.document.panels.map(
        (doc: { images: Array<any>; itemReferences: any; shapes: any; textAreas: any }, key: string | number) => {
            if (
                designDocument.document.panels[key]?.colorMode?.toLowerCase() === "blank" &&
                targetDocument.document?.panels[key].colorMode?.toLowerCase() !== "blank"
            ) {
                return designDocument.document.panels[key];
            }
            let { textAreas, shapes, itemReferences, images } = targetDocument.document?.panels[key] || {};
            const userUploadedImages = designDocument.document.panels[key]?.images?.filter((image: { id: string }) => {
                const templateItem = designDocument.metadata.template.find(
                    (item: { id: String; originalTemplateElementId?: string }) => item.id === image.id
                );
                return !templateItem || !templateItem.originalTemplateElementId;
            });
            if (userUploadedImages?.length > 0) {
                images =
                    doc.images && Array.isArray(doc.images)
                        ? [...doc.images, ...userUploadedImages]
                        : userUploadedImages;
            }
            const userAddedRefs = designDocument.document.panels[key]?.itemReferences?.filter(
                (itemReference: { id: string }) => {
                    const templateItem = designDocument.metadata.template.find(
                        (item: { id: String; originalTemplateElementId?: string }) => item.id === itemReference.id
                    );
                    return !templateItem || !templateItem.originalTemplateElementId;
                }
            );
            if (userAddedRefs?.length > 0) {
                itemReferences =
                    doc.itemReferences && Array.isArray(doc.itemReferences)
                        ? [...doc.itemReferences, ...userAddedRefs]
                        : userAddedRefs;
            }
            const userAddedShapes = designDocument.document.panels[key]?.shapes?.filter((shape: { id: string }) => {
                const templateItem = designDocument.metadata.template.find(
                    (item: { id: String; originalTemplateElementId?: string }) => item.id === shape.id
                );
                return !templateItem || !templateItem.originalTemplateElementId;
            });
            if (userAddedShapes?.length > 0) {
                shapes =
                    doc.shapes && Array.isArray(doc.shapes) ? [...doc.shapes, ...userAddedShapes] : userAddedShapes;
            }
            const userAddedTextareas = designDocument.document.panels[key]?.textAreas?.filter(
                (textArea: { id: string }) => {
                    const templateItem = designDocument.metadata.template.find(
                        (item: { id: String; originalTemplateElementId?: string }) => item.id === textArea.id
                    );
                    return !templateItem || !templateItem.originalTemplateElementId;
                }
            );
            if (userAddedTextareas?.length > 0) {
                textAreas =
                    doc.textAreas && Array.isArray(doc.textAreas)
                        ? [...doc.textAreas, ...userAddedTextareas]
                        : userAddedTextareas;
            }

            const returnDoc = {
                ...doc,
                images,
                itemReferences,
                shapes,
                textAreas
            };
            return returnDoc;
        }
    );
}
