import { ValidationsPopover } from "@design-stack-vista/smart-validations-ui";
import { useDesigner } from "@designer-suite";
import { ValidationIcon, GenericHeaderButton } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useAppDispatch, setShowValidations } from "@shared/redux";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import {
    PanelOpenedActionOrigin,
    SmartValidationEvents,
    trackSmartValidationAlertImpression,
    trackSmartValidationAlertPopoverImpression,
    trackSmartValidationEvent
} from "./trackingClient";
import { useValidations } from "./ValidationProvider";
import { getActiveCanvasId } from "../../utilities/canvasUtilities";
import { checkIssuesInCanvasById, getFirstCanvasOrdinalWithIssues } from "./ValidationUtils";
import { useTriggerCanvasChange } from "../../hooks/useTriggerCanvasChange";
import messages from "./messages";
import "./smartValidationButton.scss";

interface Props {
    className?: string;
}

export const SmartValidationsButton = ({ className }: Props) => {
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const { setCurrentActiveDialog } = useActiveDialog();
    const { validationCount, containsErrors, canvasValidations } = useValidations();
    const [isPanelOpened, setIsPanelOpened] = useState(false);
    const [isPopoverAppeared, setIsPopoverAppeared] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const { elementToFocusOnCloseRef } = useSmartValidations();
    const triggerCanvasChange = useTriggerCanvasChange();
    const designRequirements = useDesignRequirementsContext();

    const labelContent = t(messages.desktopButtonTitle.id);
    const popoverTitle = t(messages.desktopButtonPopoverTitle.id);
    const popoverDescription = t(messages.desktopButtonPopoverDescription.id);
    const popoverButtonTitle = t(messages.desktopButtonPopoverButtonTitle.id);

    const suffix = containsErrors ? "error" : "warning";
    const validationsIcon = (
        <div className="header-validation-badge">
            <ValidationIcon className={`validation-badge-icon validation-badge-icon-${suffix}`} />
            <Typography className="header-validation-badge-count">{validationCount}</Typography>
        </div>
    );

    const onClick = (popoverClick: boolean) => {
        if (!checkIssuesInCanvasById(canvasValidations, getActiveCanvasId()) && designRequirements) {
            const canvasOrdinal = getFirstCanvasOrdinalWithIssues(designer);
            if (canvasOrdinal) {
                triggerCanvasChange(designRequirements.panels[canvasOrdinal - 1].name);
            }
        }
        dispatch(setShowValidations({ showPanel: true, isContinueToCheckout: false }));
        // close the left panel
        setCurrentActiveDialog(DialogType.None);
        setIsPanelOpened(true);
        trackSmartValidationEvent(SmartValidationEvents.PanelOpened, {
            extraData: {
                trigger: popoverClick ? PanelOpenedActionOrigin.ButtonPopover : PanelOpenedActionOrigin.Button
            }
        });
    };

    // This will prevent the button "flickering" when zooming the product which can cause the designer
    // to fire false positive validation events during the design re-rendering
    useEffect(() => {
        const nextShowButtonState = validationCount > 0;
        const timerRef = setTimeout(() => {
            // To avoid unnecessary re-renders
            if (showBtn !== nextShowButtonState) {
                setShowBtn(nextShowButtonState);
            }
        }, 500);
        return () => clearTimeout(timerRef);
    }, [validationCount, showBtn]);

    const isPopoverVisible = validationCount >= 2 && showBtn && !isPanelOpened && !isPopoverAppeared;

    useEffect(() => {
        const timeout = setTimeout(() => isPopoverVisible && setIsPopoverAppeared(true), 5000);
        if (isPopoverVisible) trackSmartValidationAlertPopoverImpression();
        return () => clearTimeout(timeout);
    }, [isPopoverVisible]);

    if (validationCount) {
        trackSmartValidationAlertImpression();
    }

    return (
        <CSSTransition classNames="validations-popover" in={isPopoverVisible} timeout={300}>
            <div
                className={classNames(className, "header-validation-badge-container", {
                    "animate-show": showBtn,
                    "animate-hide": !showBtn
                })}
            >
                <ValidationsPopover
                    expanded={isPopoverVisible}
                    popoverTitle={popoverTitle}
                    popoverDescription={popoverDescription}
                    popoverButtonTitle={popoverButtonTitle}
                    onClick={() => onClick(true)}
                >
                    <GenericHeaderButton
                        icon={validationsIcon}
                        label={labelContent}
                        ref={elementToFocusOnCloseRef}
                        onClick={() => onClick(false)}
                    />
                </ValidationsPopover>
            </div>
        </CSSTransition>
    );
};

SmartValidationsButton.displayName = "SmartValidationsButton";
