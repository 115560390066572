import React, { useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { isWordArt } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { defaultDocumentText } from "@shared/utils/CimDoc";
import { ImageContent, OtherContent, ItemContent } from "@shared/features/AdvancedLayerTool";
import { useAsyncEffect } from "@design-stack-ct/utility-react";
import { hasPreviewUrl, getPreviewUrl } from "./utils";

const messages = defineMessages({
    sizeInfo: {
        id: "easel.ui.layers.content.sizeInfo",
        defaultMessage: "width: [[width]] height: [[height]]",
        description: {
            note: "Hover over text summarizing the size of the image"
        }
    }
});

interface ItemContentInformationProps {
    item: Item;
}

export const ItemContentInformation = ({ item }: ItemContentInformationProps) => {
    const { t } = useTranslationSSR();
    const [url, setUrl] = useState("");

    useAsyncEffect(
        helpers => {
            if (item._itemViewModel.has("url")) {
                item._itemViewModel.get("url").then((previewUrl: React.SetStateAction<string>) => {
                    helpers.runIfMounted(() => setUrl(previewUrl));
                });
            }
        },
        [item._itemViewModel]
    );

    if (item.itemType === ItemTypes.TEXT) {
        const content = (item as TextItem).value.join(" ").trim();
        let label = content || "";

        if (!label && item._itemViewModel.model.has("placeholder")) {
            label = content || item._itemViewModel.model.get("placeholder");

            if (label === "defaultPlaceholder") {
                label = t(defaultDocumentText.textualPlaceholders.id);
            }
        }

        return <ItemContent>{label}</ItemContent>;
    }

    if (isWordArt(item)) {
        // Open question: Will all Word Art fields have item.templateMetaData.placeholder?
        try {
            // Populate with placehold text initially - use template metadata if available
            let content = item.templateMetaData
                ? item.templateMetaData.placeholder
                : t(defaultDocumentText.textualPlaceholders.id);

            // Try to populate the content if available
            content = (item as WordArtItem).data.content.length > 0 ? (item as WordArtItem).data.content : content;

            return <ItemContent>{content}</ItemContent>;
        } catch (e) {
            return <ItemContent>???</ItemContent>;
        }
    }

    if (item.itemType === ItemTypes.IMAGE) {
        const { width, height } = item._itemViewModel.get("pixelDimensions");
        const sizeInfo = t(messages.sizeInfo.id, { width, height }); // `width: ${width} height: ${height}`;

        if (hasPreviewUrl(item)) {
            const previewUrl = getPreviewUrl(item);
            return <ImageContent imageSourceUrl={previewUrl} sizeInfo={sizeInfo} />;
        }

        return <ImageContent imageSourceUrl={url} sizeInfo={sizeInfo} />;
    }

    return <OtherContent previewUrl={url} />;
};

ItemContentInformation.displayName = "ItemContentInformation";
