import { useDesignRequirementsContext } from "@shared/features/Product";
import { getQueryParams, removeQueryParam } from "@shared/utils/WebBrowser";
import { useRef } from "react";
import { DialogType } from "../ActiveDialog";

/**
 * QUAD redirect user to Studio for teams feature
 * @returns boolean
 */
export const useIsTeamsEnabledDefault = (): boolean => {
    const designRequirements = useDesignRequirementsContext();
    const { activeDialogPanel, workId } = getQueryParams();
    const showOnce = useRef<boolean>(false);

    // either design requirement or work id is required to show panel
    if (!designRequirements || !workId) {
        return false;
    }

    // show teams as default panel only once
    // if activeDialogPanel is present in URL it opens teams panel on every page reload
    if (showOnce.current) {
        window.history.replaceState("update-url", "", removeQueryParam(window.location.href, "activeDialogPanel"));
    }

    const panelLength = designRequirements.panels.length;

    if (panelLength > 0 && activeDialogPanel === DialogType.GroupsAndTeams) {
        showOnce.current = true;
    }

    return showOnce.current;
};
