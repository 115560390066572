import { STUDIO_DIAGNOSTIC_EVENTS } from "@shared/utils/Tracking";
import { getItemStudioMetadataProperty, classifyImage } from "@utilities";

export default ({ api }: { api: DesignerAPI }) => [
    {
        eventType: api.events.eventTypes.ITEMS_ADDED,
        itemType: api.design.constants.itemTypes.shape,
        getEventProperties: (item: ShapeItem) => {
            const shapeMetadata = getItemStudioMetadataProperty(item, "shapeMetadata");
            const rotation = item._itemViewModel.model.get("rotation");
            // When doing an undo, the shape gets re-added to the canvas.
            // To track this, we add a boolean to the item model to indicate it was already on the canvas
            // Check if we have the attribute
            const alreadyAdded = item._itemViewModel.model.has("alreadyAdded");

            // Set that we're already added to the canvas.
            item._itemViewModel.model.set("alreadyAdded", true);
            return {
                label: STUDIO_DIAGNOSTIC_EVENTS.SHAPE.eventLabel,
                eventDetail: alreadyAdded
                    ? STUDIO_DIAGNOSTIC_EVENTS.SHAPE.events.RE_ADD
                    : STUDIO_DIAGNOSTIC_EVENTS.SHAPE.events.ADD,
                extraData: () => ({
                    itemType: api.design.constants.itemTypes.shape,
                    shapeType: shapeMetadata ? shapeMetadata.type : item.shapeType,
                    shapeMetadata,
                    rotation
                })
            };
        }
    },
    {
        eventType: api.events.eventTypes.ITEMS_ADDED,
        itemType: api.design.constants.itemTypes.image,
        getEventProperties: (item: ImageItem) => {
            const imageInfo = classifyImage(item);
            return {
                label: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.eventLabel,
                eventDetail:
                    imageInfo.imageType === "noun-icon"
                        ? STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.ADD_ICON
                        : STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.ADD_IMAGE,
                extraData: () => ({
                    itemType: api.design.constants.itemTypes.image,
                    mimeType: item.mimeType,
                    ...imageInfo
                })
            };
        }
    }
];
