import React from "react";
import { ArrowShape, ShapeTypes } from "@cimpress-technology/svg-path";

import { DraggableCurve } from "./DraggableCurve";

const metadata: ArrowShape = {
    type: ShapeTypes.Arrow,
    headWidth: 28,
    tailWidth: 28
};

type Props = {
    rotationAngle?: number;
};

// Used to scale the height of the arrow to match the prior version after a breaking change to the default SVG path
const HEIGHT_SCALE_FACTOR = 0.42;

export const DraggableArrow = (props: Props) => {
    const { rotationAngle } = props;
    return <DraggableCurve metadata={metadata} rotationAngle={rotationAngle} scaleY={HEIGHT_SCALE_FACTOR} />;
};

DraggableArrow.displayName = "DraggableArrow";
