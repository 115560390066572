import { useDesigner } from "@designer-suite";
import { useKeyboardShortcuts, keyboardShortcutEvents } from "@shared/features/KeyboardShortcuts";
import { useEffect } from "react";

export const useZoomKeyboardShortcuts = () => {
    const { on, off } = useKeyboardShortcuts();
    const designer = useDesigner();

    useEffect(() => {
        const zoomToFit = () => {
            if (!designer) {
                return;
            }
            designer?.eventBus.trigger(designer.eventBus.events.zoomSet, 1);
        };

        const zoomIn = () => {
            if (!designer) {
                return;
            }
            designer?.eventBus.trigger(designer.eventBus.events.zoomIn);
        };

        const zoomOut = () => {
            if (!designer) {
                return;
            }
            designer?.eventBus.trigger(designer.eventBus.events.zoomOut);
        };

        // disable designer canvas zoom control and enable browser default zoom
        const zoomEventListener = (e: KeyboardEvent) => {
            const ctrlPressed = e.ctrlKey || e.metaKey;
            const altPressed = e.altKey;
            switch (e.code) {
                case "Digit0":
                case "Numpad0":
                    if (ctrlPressed && !e.shiftKey) {
                        e.stopPropagation();
                        zoomToFit();
                    }
                    break;
                case "Equal":
                    if (ctrlPressed) {
                        e.stopPropagation();
                    }
                    break;
                case "NumpadAdd":
                    if (ctrlPressed) {
                        e.stopPropagation();
                    }
                    if (altPressed) {
                        zoomIn();
                    }
                    break;
                case "NumpadSubtract":
                    if (ctrlPressed && !e.shiftKey) {
                        e.stopPropagation();
                    }
                    if (altPressed && !e.shiftKey) {
                        zoomOut();
                    }
                    break;
                case "Minus":
                    if (ctrlPressed && !e.shiftKey) {
                        e.stopPropagation();
                    }
                    break;
                default:
                    break;
            }
        };

        on(keyboardShortcutEvents.zoomToFit, zoomToFit);
        on(keyboardShortcutEvents.zoomIn, zoomIn);
        on(keyboardShortcutEvents.zoomOut, zoomOut);
        window.addEventListener("keydown", zoomEventListener, true);

        return () => {
            off(keyboardShortcutEvents.zoomToFit, zoomToFit);
            off(keyboardShortcutEvents.zoomIn, zoomIn);
            off(keyboardShortcutEvents.zoomOut, zoomOut);
            window.removeEventListener("keydown", zoomEventListener);
        };
    }, [on, off, designer]);
};
