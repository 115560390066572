import { useEffect, useState } from "react";
import { useAppSelector } from "@shared/redux";
import { useToolFlags } from "@shared/features/StudioConfiguration";

import { useProcessType } from "@utilities";
import { verifyTeamsProduct } from "../clients/teamsProductVerifierClient";

export function useIsTeamsEnabled() {
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const locale = useAppSelector(state => state.locale);
    const processType = useProcessType();
    const { shouldShowTeams } = useToolFlags(processType);
    const [isTeamsProduct, setIsTeamsProduct] = useState(false);

    useEffect(() => {
        if (shouldShowTeams) {
            const verifyIfTeamsProduct = async () => {
                try {
                    const isTeamsProduct = await verifyTeamsProduct(
                        productKey,
                        productVersion,
                        locale,
                        customerSelectedProductOptions
                    );
                    setIsTeamsProduct(isTeamsProduct);
                } catch {
                    setIsTeamsProduct(false);
                }
            };
            verifyIfTeamsProduct();
        }
    }, [shouldShowTeams, productKey, productVersion, locale, customerSelectedProductOptions]);

    return isTeamsProduct;
}
