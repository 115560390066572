import React, { useContext, createContext, ReactNode, useState, useMemo } from "react";

interface Props {
    children: ReactNode | ReactNode[];
    deleteUpload: (uploadUrl: string) => void;
}

type ContextData = {
    deleteUpload: (uploadUrl: string) => void;
    uploadsInitialized: boolean;
    setUploadsInitialized: (v: boolean) => void;
};

const Context = createContext<ContextData | undefined>(undefined);

export const useUploadsContext = () => {
    const result = useContext(Context);
    if (!result) {
        throw Error("Please call this within an UploadsProvider");
    }
    return result;
};

export function UploadsProvider({ children, deleteUpload }: Props) {
    const { Provider } = Context;
    const [uploadsInitialized, setUploadsInitialized] = useState(false);

    const contextObject = useMemo(
        () => ({
            deleteUpload,
            uploadsInitialized,
            setUploadsInitialized
        }),
        [deleteUpload, uploadsInitialized, setUploadsInitialized]
    );

    return <Provider value={contextObject}>{children}</Provider>;
}

UploadsProvider.displayName = "UploadsProvider";
