/**
ShortcutMappings
* */
export enum keyboardShortcutEvents {
    increaseFont = "increase-font",
    decreaseFont = "decrease-font",
    zoomToFit = "zoom-to-fit",
    zoomIn = "zoomIn",
    zoomOut = "zoomOut",
    bold = "bold",
    italics = "italics",
    addText = "add-text",
    underline = "underline",
    duplicate = "duplicate",
    addRectangle = "add-rectangle",
    addLine = "add-line",
    addCircle = "add-circle",
    toggleItemLock = "toggleItemLock",
    toggleFontBrowser = "toggle-font-browser",
    arrangeForward = "arrangeForward",
    arrangeBackward = "arrangeBackward",
    arrangeToFront = "arrangeToFront",
    arrangeToBack = "arrangeToBack",
    undo = "undo",
    redo = "redo",
    copy = "copy",
    paste = "paste",
    cut = "cut",
    delete = "delete",
    backspace = "backspace",
    save = "save",
    selectAll = "selectAll",
    deselect = "deselect",
    nudgeLeft = "nudgeLeft",
    nudgeUp = "nudgeUp",
    nudgeDown = "nudgeDown",
    nudgeRight = "nudgeRight",
    nudgeLeftBig = "nudgeStepLeft",
    nudgeUpBig = "nudgeStepUp",
    nudgeDownBig = "nudgeStepDown",
    nudgeRightBig = "nudgeStepRight"
}

export type OS = {
    mac: string;
    windows: string;
};
export type HotKeys = {
    OS?: Record<string, string>;
};

export type HotKeysToEventMap = {
    OS?: { [key: string]: keyboardShortcutEvents };
};

// edit it to capture more hotkeys and use it in keyboardShortcutsConfig to map keys with events.
export const hotKeys = {
    mac: {
        shiftCommandDot: "shift+cmd+.",
        shiftCommandComma: "shift+cmd+,",
        commandZero: "cmd+0",
        commandB: "cmd+B",
        commandI: "cmd+I",
        optionT: "option+T",
        commandU: "cmd+U",
        commandD: "cmd+D",
        optionR: "option+R",
        optionL: "option+L",
        optionC: "option+C",
        shiftCommandF: "shift+cmd+F",
        commandSquareCloseBracket: "cmd+]",
        commandSquareOpenBracket: "cmd+[",
        optionCommandSquareCloseBracket: "option+cmd+]",
        optionCommandSquareOpenBracket: "option+cmd+[",
        shiftCommandL: "shift+cmd+L",
        optionPlus: "option+=",
        optionNumPlus: "option-+",
        optionMinus: "option+-",
        commandMinus: "cmd+-",
        commandZ: "cmd+Z",
        commandY: "cmd+Y",
        commandC: "cmd+C",
        commandV: "cmd+V",
        commandX: "cmd+X",
        delete: "delete",
        backspace: "backspace",
        commandS: "cmd+S",
        commandA: "cmd+A",
        escape: "esc",
        commandLeft: "cmd+left",
        commandUp: "cmd+up",
        commandDown: "cmd+down",
        commandRight: "cmd+right",
        left: "left",
        up: "up",
        down: "down",
        right: "right"
    },
    windows: {
        shiftControlDot: "shift+ctrl+.",
        shiftControlComma: "shift+ctrl+,",
        controlZero: "ctrl+0",
        controlB: "ctrl+B",
        controlI: "ctrl+I",
        altT: "alt+T",
        controlU: "ctrl+U",
        controlD: "ctrl+D",
        altR: "alt+R",
        altL: "alt+L",
        altC: "alt+C",
        shiftControlF: "shift+ctrl+F",
        controlSquareCloseBracket: "ctrl+]",
        controlSquareOpenBracket: "ctrl+[",
        altControlSquareCloseBracket: "alt+ctrl+]",
        altControlSquareOpenBracket: "alt+ctrl+[",
        shiftControlL: "shift+ctrl+L",
        altNumPlus: "alt-+",
        altPlus: "alt+=",
        altMinus: "alt+-",
        controlMinus: "ctrl+-",
        controlZ: "ctrl+Z",
        controlY: "ctrl+Y",
        controlC: "ctrl+C",
        controlV: "ctrl+V",
        controlX: "ctrl+X",
        delete: "delete",
        backspace: "backspace",
        controlS: "ctrl+S",
        controlA: "ctrl+A",
        escape: "esc",
        controlLeft: "ctrl+left",
        controlUp: "ctrl+up",
        controlDown: "ctrl+down",
        controlRight: "ctrl+right",
        left: "left",
        up: "up",
        down: "down",
        right: "right",
        shift: "shift"
    }
};

/** edit below to map keys to events.
 * provider picks events from this map. If events are not present here they wont be registered.
 * refer to useOSBasedHotKeys hook (generated based on this config) to find reverse mapping for keyboardShortcutsConfig.
 * DUPLICATE KEY OR VALUE NOT ALLOWED as it is used to generate a reverse mapping.
 */

export const keyboardShortcutsConfig = {
    mac: {
        [hotKeys.mac.shiftCommandDot]: keyboardShortcutEvents.increaseFont,
        [hotKeys.mac.shiftCommandComma]: keyboardShortcutEvents.decreaseFont,
        [hotKeys.mac.commandZero]: keyboardShortcutEvents.zoomToFit,
        [hotKeys.mac.commandB]: keyboardShortcutEvents.bold,
        [hotKeys.mac.commandI]: keyboardShortcutEvents.italics,
        [hotKeys.mac.optionT]: keyboardShortcutEvents.addText,
        [hotKeys.mac.commandU]: keyboardShortcutEvents.underline,
        [hotKeys.mac.commandD]: keyboardShortcutEvents.duplicate,
        [hotKeys.mac.optionR]: keyboardShortcutEvents.addRectangle,
        [hotKeys.mac.optionL]: keyboardShortcutEvents.addLine,
        [hotKeys.mac.optionC]: keyboardShortcutEvents.addCircle,
        [hotKeys.mac.shiftCommandF]: keyboardShortcutEvents.toggleFontBrowser,
        [hotKeys.mac.commandSquareCloseBracket]: keyboardShortcutEvents.arrangeForward,
        [hotKeys.mac.commandSquareOpenBracket]: keyboardShortcutEvents.arrangeBackward,
        [hotKeys.mac.optionCommandSquareCloseBracket]: keyboardShortcutEvents.arrangeToFront,
        [hotKeys.mac.optionCommandSquareOpenBracket]: keyboardShortcutEvents.arrangeToBack,
        [hotKeys.mac.shiftCommandL]: keyboardShortcutEvents.toggleItemLock,
        [hotKeys.mac.optionPlus]: keyboardShortcutEvents.zoomIn,
        [hotKeys.mac.optionMinus]: keyboardShortcutEvents.zoomOut,
        [hotKeys.mac.commandZ]: keyboardShortcutEvents.undo,
        [hotKeys.mac.commandY]: keyboardShortcutEvents.redo,
        [hotKeys.mac.commandC]: keyboardShortcutEvents.copy,
        [hotKeys.mac.commandV]: keyboardShortcutEvents.paste,
        [hotKeys.mac.commandX]: keyboardShortcutEvents.cut,
        [hotKeys.mac.delete]: keyboardShortcutEvents.delete,
        [hotKeys.mac.backspace]: keyboardShortcutEvents.backspace,
        [hotKeys.mac.commandS]: keyboardShortcutEvents.save,
        [hotKeys.mac.commandA]: keyboardShortcutEvents.selectAll,
        [hotKeys.mac.escape]: keyboardShortcutEvents.deselect,
        [hotKeys.mac.left]: keyboardShortcutEvents.nudgeLeft,
        [hotKeys.mac.up]: keyboardShortcutEvents.nudgeUp,
        [hotKeys.mac.down]: keyboardShortcutEvents.nudgeDown,
        [hotKeys.mac.right]: keyboardShortcutEvents.nudgeRight,
        [hotKeys.mac.commandLeft]: keyboardShortcutEvents.nudgeLeftBig,
        [hotKeys.mac.commandUp]: keyboardShortcutEvents.nudgeUpBig,
        [hotKeys.mac.commandDown]: keyboardShortcutEvents.nudgeDownBig,
        [hotKeys.mac.commandRight]: keyboardShortcutEvents.nudgeRightBig
    },
    windows: {
        [hotKeys.windows.shiftControlDot]: keyboardShortcutEvents.increaseFont,
        [hotKeys.windows.shiftControlComma]: keyboardShortcutEvents.decreaseFont,
        [hotKeys.windows.controlZero]: keyboardShortcutEvents.zoomToFit,
        [hotKeys.windows.controlB]: keyboardShortcutEvents.bold,
        [hotKeys.windows.controlI]: keyboardShortcutEvents.italics,
        [hotKeys.windows.altT]: keyboardShortcutEvents.addText,
        [hotKeys.windows.controlU]: keyboardShortcutEvents.underline,
        [hotKeys.windows.controlD]: keyboardShortcutEvents.duplicate,
        [hotKeys.windows.altR]: keyboardShortcutEvents.addRectangle,
        [hotKeys.windows.altL]: keyboardShortcutEvents.addLine,
        [hotKeys.windows.altC]: keyboardShortcutEvents.addCircle,
        [hotKeys.windows.shiftControlF]: keyboardShortcutEvents.toggleFontBrowser,
        [hotKeys.windows.controlSquareCloseBracket]: keyboardShortcutEvents.arrangeForward,
        [hotKeys.windows.controlSquareOpenBracket]: keyboardShortcutEvents.arrangeBackward,
        [hotKeys.windows.altControlSquareCloseBracket]: keyboardShortcutEvents.arrangeToFront,
        [hotKeys.windows.altControlSquareOpenBracket]: keyboardShortcutEvents.arrangeToBack,
        [hotKeys.windows.shiftControlL]: keyboardShortcutEvents.toggleItemLock,
        [hotKeys.windows.altPlus]: keyboardShortcutEvents.zoomIn,
        [hotKeys.windows.altMinus]: keyboardShortcutEvents.zoomOut,
        [hotKeys.windows.controlZ]: keyboardShortcutEvents.undo,
        [hotKeys.windows.controlY]: keyboardShortcutEvents.redo,
        [hotKeys.windows.controlC]: keyboardShortcutEvents.copy,
        [hotKeys.windows.controlV]: keyboardShortcutEvents.paste,
        [hotKeys.windows.controlX]: keyboardShortcutEvents.cut,
        [hotKeys.windows.delete]: keyboardShortcutEvents.delete,
        [hotKeys.windows.backspace]: keyboardShortcutEvents.backspace,
        [hotKeys.windows.controlS]: keyboardShortcutEvents.save,
        [hotKeys.windows.controlA]: keyboardShortcutEvents.selectAll,
        [hotKeys.windows.escape]: keyboardShortcutEvents.deselect,
        [hotKeys.windows.left]: keyboardShortcutEvents.nudgeLeft,
        [hotKeys.windows.up]: keyboardShortcutEvents.nudgeUp,
        [hotKeys.windows.down]: keyboardShortcutEvents.nudgeDown,
        [hotKeys.windows.right]: keyboardShortcutEvents.nudgeRight,
        [hotKeys.windows.controlLeft]: keyboardShortcutEvents.nudgeLeftBig,
        [hotKeys.windows.controlUp]: keyboardShortcutEvents.nudgeUpBig,
        [hotKeys.windows.controlDown]: keyboardShortcutEvents.nudgeDownBig,
        [hotKeys.windows.controlRight]: keyboardShortcutEvents.nudgeRightBig
    }
};
