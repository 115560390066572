/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect, useCallback, useMemo, useRef, ReactNode } from "react";
import {
    StudioLiveContext,
    ConnectionState,
    SessionState,
    events,
    useChangeHistoryFirestoreQuery,
    sessionExistsForWork
} from "@vp/dragon";
import isEqual from "lodash/isEqual";
import { usePrevious } from "@design-stack-ct/utility-react";
import { getDesignDocumentFromDesigner, useDesigner } from "@easel";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { handleError, ERROR_CODES, newRelicWrapper } from "@shared/utils/Errors";
import { useAppSelector, useAppDispatch, setSaveSuccess, setWorkRevisionId } from "@shared/redux";
import { studioLiveErrorCodes, topLevelStudioLiveErrorCodes, STUDIO_LIVE_ENTITY_CODE } from "@shared/utils/StudioLive";
import { BLANK_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { applyDesignOption, getDocumentSourceFromSelectedTemplate } from "@shared/features/DesignPanel";
import { saveStudio5Design } from "../../clients/saveClient";
import { useLoadNewDesignForApplyOptionStudio5 } from "../../hooks/useLoadNewDesignForApplyOptionStudio5";

interface Props {
    studioLiveEnabled: boolean;
    children: ReactNode;
}

enum StudioLiveActionType {
    EventSent = "studio-live-event-sent",
    EventReceived = "studio-live-event-received",
    SessionInitializing = "studio-live-session-initializing",
    SessionJoined = "studio-live-session-joined"
}

enum StudioLiveEventType {
    DocumentChanged = "document-changed",
    SurfaceUpsellsChanged = "surface-upsells-changed",
    RevisonSaved = "revision-saved"
}

/**
 * Manages session state for studio live session, including subscriptions between cimpress designer and the multiplayer infrastructure.
 *
 */
export const StudioDesignerLiveContextProvider = ({ studioLiveEnabled, children }: Props) => {
    const designer = useDesigner();
    const workId = useAppSelector(state => state.workId);
    const workRevisionId = useAppSelector(state => state.workRevisionId);
    const previousWorkRevisionId = usePrevious(workRevisionId);
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { identity, auth, isIdentityInitialized } = useIdentityContext();
    // TODO: should be MultiplayerSessionManager, once we import it from @vp/dragon
    const liveSessionManager = useRef<any | null>(null);
    // eslint-disable-next-line no-unused-vars
    const [allowedPlayers, setAllowedPlayers] = useState({});
    const [sessionStatus, setSessionStatus] = useState(SessionState.CheckForSession);
    const [allowedPlayersWithPresence, setAllowedPlayersWithPresence] = useState({});
    const [selectedTemplates, setSelectedTemplates] = useState();
    const surfaceUpsellsLoaded = useAppSelector(state => state.surfaceUpsellsLoadedState);
    const surfaceUpsells = useAppSelector(state => state.surfaceUpsells);
    const dispatch = useAppDispatch();
    const [sessionStartTime, setSessionStartTime] = useState(-1);
    const [canBeDisconnected, setCanBeDisconnected] = useState(false);
    const loadNewDesignForApplyOption = useLoadNewDesignForApplyOptionStudio5();

    const getEventProcessor = useCallback(() => {
        const commandExecutedProcessor = (commands: any[]) => {
            commands.forEach(remoteCommand => {
                try {
                    designer!.commandDispatcher.replay(remoteCommand.payload);

                    newRelicWrapper.logPageAction(StudioLiveActionType.EventReceived, {
                        studioLiveEventType: StudioLiveEventType.DocumentChanged
                    });
                } catch (error) {
                    const err = {
                        ...error,
                        errorMessage: `An error occurred attempting to apply remote changes: ${error.toString()}`,
                        errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.APPLY_DESIGNER_CHANGES}`
                    };
                    handleError(err, topLevelStudioLiveErrorCodes.STUDIO_LIVE_SESSION);
                }
            });
        };

        const incomingCimdocChangeCallback = (lastChange: {
            [x: string]: { panelName: string; selectedTemplate: string };
        }) => {
            Object.keys(lastChange).forEach(key => {
                if (lastChange[key].panelName && lastChange[key].selectedTemplate) {
                    const surfaceUpsell = surfaceUpsells[lastChange[key].panelName];
                    // BEWARE.  State doesn't change here - I don't know why.
                    // maybe something screwy with studio live?
                    // surfaceUpsells will never be updated so only rely on fields that won't change
                    dispatch((dispatch, getState) =>
                        applyDesignOption({
                            dispatch,
                            getState,
                            panelName: lastChange[key].panelName,
                            resetting: false,
                            ...getDocumentSourceFromSelectedTemplate(lastChange[key].selectedTemplate),
                            newOptions: {
                                [surfaceUpsell.optionName]:
                                    lastChange[key].selectedTemplate === BLANK_SELECTED_TEMPLATE
                                        ? surfaceUpsell.blankOption ?? ""
                                        : surfaceUpsell.colorOption ?? ""
                            },
                            existingDocument: getDesignDocumentFromDesigner(false),
                            loadNewDesign: loadNewDesignForApplyOption
                        })
                    );
                }
                newRelicWrapper.logPageAction(StudioLiveActionType.EventReceived, {
                    studioLiveEventType: StudioLiveEventType.SurfaceUpsellsChanged
                });
            });
        };
        const cimdocEventProcessor = events.getProcessCimdocChangeCollectionCallback(incomingCimdocChangeCallback);

        const saveProcessor = (commands: any[]) => {
            commands.forEach(command => {
                // Only update anything if the revision received differs from the one we have now
                if (command.payload && command.payload.workRevisionId !== workRevisionId) {
                    dispatch(setWorkRevisionId(command.payload.workRevisionId));

                    newRelicWrapper.logPageAction(StudioLiveActionType.EventReceived, {
                        studioLiveEventType: StudioLiveEventType.RevisonSaved
                    });
                }
            });
        };

        return events.getEventProcessor(saveProcessor, cimdocEventProcessor, commandExecutedProcessor);
    }, [designer, surfaceUpsells, dispatch, loadNewDesignForApplyOption, workRevisionId]);

    const theQuery = useMemo(() => {
        if (sessionStatus !== SessionState.Connected || !workId || !workRevisionId || !studioLiveEnabled) return null;
        return liveSessionManager.current?.registerIncomingCallback(workId, workRevisionId, null);
    }, [studioLiveEnabled, workId, workRevisionId, sessionStatus, liveSessionManager]);

    useChangeHistoryFirestoreQuery(theQuery, getEventProcessor());

    const subscribeDesignerCommandExecuted = useCallback(() => {
        if (liveSessionManager.current && workId && workRevisionId) {
            return designer?.api?.events?.subscribe(
                designer.api.events.eventTypes.COMMAND_EXECUTED,
                (eventData: EventData) => {
                    newRelicWrapper.logPageAction(StudioLiveActionType.EventSent, {
                        studioLiveEventType: StudioLiveEventType.DocumentChanged
                    });

                    liveSessionManager.current.getBroadcastCommandExecutedEvent(workId, workRevisionId)(eventData);
                }
            );
        }
        return () => {};
    }, [workId, workRevisionId, designer, liveSessionManager]);

    useEffect(() => {
        if (!studioLiveEnabled || !workId || sessionStartTime === -1 || canBeDisconnected === false) return;
        const onUpdate = (data: any) => {
            const isDisconnected = data.disconnected !== undefined && data.disconnected !== null;
            if (isDisconnected) {
                const disconnectedTime = data.disconnected.seconds;
                // Only check if disconnect occured after user joined
                if (disconnectedTime > sessionStartTime) {
                    setSessionStatus(SessionState.Disconnected);
                    liveSessionManager.current?.disconnectFromMultiplayer(workId);
                }
            }
        };

        const unsubscribeDisconnected = liveSessionManager.current?.useAllowedPlayers(workId, onUpdate);

        // eslint-disable-next-line consistent-return
        return () => {
            if (unsubscribeDisconnected) unsubscribeDisconnected();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workId, sessionStartTime, canBeDisconnected, studioLiveEnabled]);

    // Check for a session when the page loads.
    useEffect(() => {
        if (
            !studioLiveEnabled ||
            !easelLoaded ||
            sessionStatus !== SessionState.CheckForSession ||
            !isIdentityInitialized ||
            !auth
        )
            return;
        if (!workId) {
            // no workId, multiplayer stays disconnected
            setSessionStatus(SessionState.Disconnected);
            return;
        }
        const asyncCheckForSession = async () => {
            const sessionExists = await sessionExistsForWork(workId, auth.getToken);
            if (!sessionExists) {
                // no session, multiplayer stays disconnected
                setSessionStatus(SessionState.Disconnected);
                return;
            }
            if (liveSessionManager.current === null) {
                const { MultiplayerSessionManager } = await import("@vp/dragon/dist/studioLiveSessionManager.es");
                liveSessionManager.current = new MultiplayerSessionManager();
            }
            try {
                await liveSessionManager.current!.signIntoMultiplayer(auth.getToken, workId);
            } catch (error) {
                setSessionStatus(SessionState.Disconnected);

                const err = {
                    ...error,
                    errorMessage: `An error occurred while signing into studio live multiplayer: ${error.toString()}`,
                    errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.SIGN_INTO_MULTIPLAYER}`
                };
                handleError(err, topLevelStudioLiveErrorCodes.START_STUDIO_LIVE);
            }

            try {
                try {
                    const permissed = await liveSessionManager.current!.requestPermission(workId, auth.getToken);
                    if (permissed) {
                        setSessionStatus(SessionState.Initializing);
                        // set that this user can be disconnected
                        setCanBeDisconnected(true);
                        const d = new Date();
                        const seconds = d.getTime() / 1000;
                        setSessionStartTime(seconds);

                        newRelicWrapper.logPageAction(StudioLiveActionType.SessionJoined);
                    } else {
                        const err = {
                            errorMessage: `A user attempted to join a StudioLive session they were not authorized to access`,
                            errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.NOT_AUTHORIZED_TO_JOIN_SESSION}`
                        };
                        handleError(err, topLevelStudioLiveErrorCodes.JOIN_STUDIO_LIVE_SESSION);
                        setSessionStatus(SessionState.Disconnected);
                    }
                } catch (error) {
                    const err = {
                        ...error,
                        errorMessage: `An error occurred while checking if the user is authorized to join a studio live session in progress`,
                        errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.CHECK_IF_AUTHORIZED_FOR_STUDIO_LIVE_SESSION}`
                    };
                    handleError(err, topLevelStudioLiveErrorCodes.JOIN_STUDIO_LIVE_SESSION);
                    setSessionStatus(SessionState.Disconnected);
                }
            } catch (error) {
                const err = {
                    ...error,
                    errorMessage: `An error occurred while checking if a studio live session exists ${error.toString()}`,
                    errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.CHECK_IF_STUDIO_LIVE_SESSION_EXISTS}`
                };
                handleError(err, topLevelStudioLiveErrorCodes.JOIN_STUDIO_LIVE_SESSION);
            }
        };
        asyncCheckForSession();
    }, [
        studioLiveEnabled,
        workId,
        workRevisionId,
        sessionStatus,
        liveSessionManager,
        isIdentityInitialized,
        auth,
        easelLoaded
    ]);

    // Creating session
    useEffect(() => {
        if (
            !workId ||
            sessionStatus !== SessionState.Creating ||
            !auth ||
            !isIdentityInitialized ||
            !designer ||
            !liveSessionManager.current ||
            !studioLiveEnabled
        ) {
            return;
        }
        async function asyncCreate() {
            try {
                await liveSessionManager.current!.startSession(workId!, workRevisionId!, auth.getToken);
                setSessionStatus(SessionState.Initializing);
            } catch (error) {
                const err = {
                    ...error,
                    errorMessage: `An error occurred while starting studio live session: ${error.toString()}`,
                    errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.START_STUDIO_LIVE_SESSION}`
                };
                handleError(err, topLevelStudioLiveErrorCodes.START_STUDIO_LIVE);
            }
        }
        asyncCreate();
    }, [
        workId,
        workRevisionId,
        liveSessionManager,
        sessionStatus,
        auth,
        isIdentityInitialized,
        designer,
        studioLiveEnabled
    ]);

    // Initializing session
    useEffect(() => {
        if (
            !workId ||
            sessionStatus !== SessionState.Initializing ||
            !auth ||
            !isIdentityInitialized ||
            !designer?.api?.events ||
            !studioLiveEnabled
        )
            return;
        async function asyncStart() {
            setSessionStatus(SessionState.Connected);

            newRelicWrapper.logPageAction(StudioLiveActionType.SessionInitializing);
        }
        asyncStart();
    }, [workId, sessionStatus, auth, isIdentityInitialized, designer, studioLiveEnabled]);

    /**
     * On workRevisionUpdate,
     * push new RevisionId to firebase
     */
    useEffect(() => {
        if (
            !workId ||
            !workRevisionId ||
            !liveSessionManager.current ||
            sessionStatus !== SessionState.Connected ||
            !studioLiveEnabled
        )
            return;
        async function asyncPutRevision() {
            try {
                if (previousWorkRevisionId !== workRevisionId) {
                    await liveSessionManager.current!.putRevision(workId!, workRevisionId!);
                    // Broadcast the save event to the old revision
                    const broadcastSaveEvent = liveSessionManager.current!.getBroadcastSaveEvent(
                        workId!,
                        previousWorkRevisionId!
                    );
                    // Tell the other players what revision to use now
                    broadcastSaveEvent({ workRevisionId });

                    newRelicWrapper.logPageAction(StudioLiveActionType.EventSent, {
                        studioLiveEventType: StudioLiveEventType.RevisonSaved
                    });
                }
            } catch (error) {
                const err = {
                    ...error,
                    errorMessage: `An error occurred while pushing new revisionId to firebase: ${error.toString()}`,
                    errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.PUSH_WORK_REVISION}`
                };
                handleError(err, topLevelStudioLiveErrorCodes.STUDIO_LIVE_SESSION);
            }
        }
        asyncPutRevision();
    }, [workId, workRevisionId, previousWorkRevisionId, liveSessionManager, sessionStatus, studioLiveEnabled]);

    // Send designer changes to multiplayer session
    useEffect(() => {
        if (!workId || sessionStatus !== SessionState.Connected || !studioLiveEnabled) return;
        const unsubscribe = subscribeDesignerCommandExecuted();

        // eslint-disable-next-line consistent-return
        return () => {
            // unsubscribe here
            if (unsubscribe) unsubscribe();
        };
    }, [workId, workRevisionId, sessionStatus, subscribeDesignerCommandExecuted, designer, studioLiveEnabled]);

    useMemo(async () => {
        if (!workId || sessionStatus !== SessionState.Connected || !liveSessionManager.current || !studioLiveEnabled)
            return;

        const onUpdate = (data: any) => {
            // This is where we map from the document shape of SESSIONPERMISSION to the collection of player uids.
            const players = (data && data.players) || {};
            setAllowedPlayers(players);
        };

        const unsubscribeAllowedPlayers = await liveSessionManager.current.useAllowedPlayers(workId, onUpdate);

        // eslint-disable-next-line consistent-return
        return () => {
            unsubscribeAllowedPlayers();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStatus, workId, studioLiveEnabled]);

    useMemo(() => {
        if (
            !workId ||
            sessionStatus !== SessionState.Connected ||
            !allowedPlayers ||
            !liveSessionManager.current ||
            !studioLiveEnabled
        ) {
            return;
        }

        const allowedPlayerIds = Object.keys(allowedPlayers);

        const onChangeHelper = (doc: { id: string | number }, isAdd: boolean) => {
            /* Copies are used here because without them, Dragon was not detecting values
            for the 'connected' field */
            const playerCopy = JSON.parse(JSON.stringify(allowedPlayers[doc.id]));
            playerCopy.connected = isAdd ? ConnectionState.Connected : ConnectionState.Disconnected;
            const playerPresenceCopy = JSON.parse(JSON.stringify(allowedPlayersWithPresence));
            playerPresenceCopy[doc.id] = playerCopy;
            setAllowedPlayersWithPresence(playerPresenceCopy);
        };

        const onAdd = (doc: { id: string | number }) => {
            onChangeHelper(doc, true);
        };

        const onRemove = (doc: { id: string | number }) => {
            onChangeHelper(doc, false);
        };
        const unsubscribePresence = liveSessionManager.current.usePlayerPresence(
            allowedPlayerIds,
            onAdd,
            onRemove,
            workId
        );

        // eslint-disable-next-line consistent-return
        return () => {
            unsubscribePresence();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedPlayers, sessionStatus, workId, studioLiveEnabled]);

    // Send backside changes to multiplayer session
    useEffect(() => {
        if (!surfaceUpsellsLoaded || !liveSessionManager.current || !studioLiveEnabled) {
            return;
        }
        if (surfaceUpsellsLoaded && surfaceUpsells) {
            const newSelectedTemplates = Object.keys(surfaceUpsells).reduce((acc, panelName) => {
                return { [panelName]: surfaceUpsells[panelName].selectedTemplate, ...acc };
            }, {} as any);

            if (newSelectedTemplates) {
                if (!selectedTemplates) {
                    // Load the initial state
                    setSelectedTemplates(newSelectedTemplates);
                } else if (!isEqual(newSelectedTemplates, selectedTemplates)) {
                    // Something probably changed!  Broadcast the good news!
                    setSelectedTemplates(newSelectedTemplates);

                    if (!workId || sessionStatus !== SessionState.Connected) {
                        return;
                    }

                    const broadcastCimdocChanged = liveSessionManager.current.getBroadcastCimdocEvent(
                        workId,
                        workRevisionId,
                        "cimdoc:surfaceupsell"
                    );

                    Object.keys(selectedTemplates).forEach(panelName => {
                        if (
                            newSelectedTemplates[panelName] &&
                            selectedTemplates[panelName] !== newSelectedTemplates[panelName]
                        ) {
                            broadcastCimdocChanged(panelName, newSelectedTemplates[panelName]);

                            newRelicWrapper.logPageAction(StudioLiveActionType.EventSent, {
                                studioLiveEventType: StudioLiveEventType.SurfaceUpsellsChanged
                            });
                        }
                    });
                }
            }
        }
    }, [
        surfaceUpsells,
        surfaceUpsellsLoaded,
        selectedTemplates,
        liveSessionManager,
        workId,
        workRevisionId,
        sessionStatus,
        studioLiveEnabled
    ]);

    const startSession = useCallback(async () => {
        if (liveSessionManager.current === null) {
            const MultiplayerSessionManager = await import("@vp/dragon/dist/studioLiveSessionManager.es");
            liveSessionManager.current = new MultiplayerSessionManager.MultiplayerSessionManager();
        }
        if (sessionStatus !== SessionState.Disconnected) return;
        const saveAndGetWorkId = async () => {
            try {
                const content = await saveStudio5Design({
                    authToken: auth.getToken(),
                    identity,
                    savedProjectTrackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_HELP_BUTTON
                });

                return content.workId;
            } catch (e) {
                dispatch(setSaveSuccess({ saveSuccess: false }));
                handleError(e, ERROR_CODES.SAVE_DOCUMENT);
                return null;
            }
        };

        const currentWorkId = await saveAndGetWorkId();
        try {
            const getAuthToken = auth.getToken;
            await liveSessionManager.current.signIntoMultiplayer(getAuthToken, currentWorkId);
            setCanBeDisconnected(false);
        } catch (error) {
            const err = {
                ...error,
                errorMessage: `An error occurred while starting studio live session: ${error.toString()}`,
                errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.START_STUDIO_LIVE_SESSION}`
            };
            handleError(err, topLevelStudioLiveErrorCodes.START_STUDIO_LIVE);

            setSessionStatus(SessionState.Disconnected);
        }
        setSessionStatus(SessionState.Creating);
    }, [auth, identity, liveSessionManager, sessionStatus, dispatch]);

    const endSession = useCallback(() => {
        if (!liveSessionManager.current) {
            return;
        }
        try {
            liveSessionManager.current.endSession(workId, workRevisionId);
            // sets user to offline
            liveSessionManager.current.disconnectFromMultiplayer(workId);
        } catch (error) {
            const err = {
                ...error,
                errorMessage: `An error occurred while ending studio live session: ${error.toString()}`,
                errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.END_STUDIO_LIVE_SESSION}`
            };
            handleError(err, topLevelStudioLiveErrorCodes.END_STUDIO_LIVE);
        }
        setSessionStatus(SessionState.Disconnected);
    }, [liveSessionManager, workId, workRevisionId]);

    const value = useMemo(
        () => ({ sessionStatus, players: allowedPlayersWithPresence, startSession, endSession, workId }),
        [sessionStatus, allowedPlayersWithPresence, startSession, endSession, workId]
    );

    return <StudioLiveContext.Provider value={value}>{children}</StudioLiveContext.Provider>;
};

StudioDesignerLiveContextProvider.displayName = "StudioDesignerLiveContextProvider";
