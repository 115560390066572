import { useCallback } from "react";
import { useDesigner } from "@designer-suite";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";

function useAddText() {
    const designer = useDesigner();
    const { setCurrentActiveDialog } = useActiveDialog();

    const addText = useCallback(
        (onDone?: (arg0?: any) => any) => {
            if (!designer) {
                return;
            }
            setCurrentActiveDialog(DialogType.Text);
            designer.eventBus.trigger(designer.eventBus.events.addText);
            onDone?.();
        },
        [designer, setCurrentActiveDialog]
    );

    return { addText };
}

export default useAddText;
