import React, { useState } from "react";
import { Typography, Link } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { messages } from "./messages";
import { MyProjectsCard } from "./MyProjectsCard";
import type { LoadProjectCallback, Project } from "./types";
import * as styles from "./MyProjectsList.module.scss";

interface Props {
    sortedProjects: Project[];
    prependProject: (project: Project) => void;
    removeProject: (project: Project) => void;
    onClose: () => void;
    showMyProjectsErrorToast: (message: string, errorCode: string) => void;
    clearMyProjectsErrorToast: () => void;
    searchTerm: string;
    resetSearch: () => void;
    showDeleteConfirmation: (project: Project) => void;
    loadProject: LoadProjectCallback;
}

export function MyProjectsList({
    sortedProjects,
    prependProject,
    removeProject,
    onClose,
    showMyProjectsErrorToast,
    clearMyProjectsErrorToast,
    searchTerm,
    resetSearch,
    showDeleteConfirmation,
    loadProject
}: Props) {
    const { t } = useTranslationSSR();
    const [focusedProject, setFocusedProject] = useState<Project | null>(null);

    // eslint-disable-next-line no-nested-ternary
    return sortedProjects.length ? (
        <>
            {sortedProjects.map((project: Project) => {
                const isFocusedProject = !!focusedProject && focusedProject === project;
                return (
                    <MyProjectsCard
                        key={project.workId}
                        project={project}
                        prependProject={prependProject}
                        removeProject={removeProject}
                        onClose={onClose}
                        setFocusedProject={setFocusedProject}
                        isFocusedProject={isFocusedProject}
                        showMyProjectsErrorToast={showMyProjectsErrorToast}
                        clearMyProjectsErrorToast={clearMyProjectsErrorToast}
                        showDeleteConfirmation={showDeleteConfirmation}
                        loadProject={loadProject}
                    />
                );
            })}
        </>
    ) : searchTerm ? (
        <div className={styles.noSearchResults}>
            <Typography fontSize="3" fontWeight="bold" marginTop={4} marginBottom={2}>
                {t(messages.myProjectsNoProjectsFound.id, { searchTerm })}
            </Typography>
            <Link component="button" onClick={resetSearch}>
                {t(messages.myProjectsClearSearch.id)}
            </Link>
        </div>
    ) : (
        <Typography>{t(messages.panelNoProjects.id)}</Typography>
    );
}

MyProjectsList.displayName = "MyProjectsList";
