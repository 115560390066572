import shuffle from "lodash/shuffle";
import random from "lodash/random";

export const defaultIndustryIds = Object.freeze({
    "Retail - Aparrel & Accessories, Home Goods": [
        453047840, 167391954, 453046516, 160091246, 163443106, 157116020, 188872306, 182996662, 169560736, 164925356,
        160634808
    ],
    "Professional Service - IT, Professional Upskilling, Business Services": [
        241912936, 209178312, 209198088, 184743828, 316711738, 202390426, 357619786, 188122920, 188124822, 227031842
    ],
    "Arts, Crafts, & Design - Craft Makers & Artists": [
        170886552, 143865951, 174641708, 479085286, 479089100, 174748864, 208506178, 208507586, 158556178, 176927986,
        204791866, 193054564
    ],
    "Food & Bev - Bakeries, Restaurants, Coffee Shop": [
        373009620, 262979168, 152303140, 185007706, 161946222, 153030238, 328067686, 184994036, 158930184, 160869770
    ],
    "Beauty and Spa": [
        163790018, 414163420, 414162236, 163789992, 161543420, 231322638, 202858898, 202859498, 161543788
    ],
    "Fitness, Nutrition, and Wellness": [
        153036126, 146686071, 153034286, 153032778, 167627542, 225406004, 201591872, 280439574, 201471778, 201477646,
        177609946, 334235082
    ],
    Nature: [
        207676446, 198369738, 188103150, 213244388, 178407474, 196269534, 221950576, 285884654, 184702242, 213239518,
        315115544, 197769578, 188103624, 210465876, 182240286, 204160564, 243388532, 243388400
    ],
    Consumer: [
        188143068, 214153666, 462449442, 456245138, 150538926, 226083152, 270649320, 193685066, 162200256, 219471866,
        474321810
    ]
});

export const getDefaultImages = () => {
    // get one random element from each brand
    let restOfImages: number[] = [];
    const defaultImages: number[] = Object.values(defaultIndustryIds).map(images => {
        const randomIndex = random(images.length - 1);
        const randomImage = images[randomIndex];
        images.splice(randomIndex, 1);
        restOfImages = [...restOfImages, ...images];
        return randomImage;
    });
    return [...defaultImages, ...shuffle(restOfImages)].join();
};
