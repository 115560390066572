import React, { Fragment } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { UnsharpenIcon, SharpenIcon, ToolbarButton } from "@shared/features/StudioChrome";
import classNames from "classnames";
import { SimpleButtonProps } from "../PresentationalToolbarItems/types";

const messages = defineMessages({
    isSharpenedLabel: {
        id: "easel.designerSuite.sharpenButton.isSharpenedLabel",
        defaultMessage: "Unsharpen",
        description: {}
    },
    isNotSharpenedLabel: {
        id: "easel.designerSuite.sharpenButton.isNotSharpenedLabel",
        defaultMessage: "Sharpen",
        description: {}
    }
});

interface SharpenButtonProps extends SimpleButtonProps {
    /** Whether or not the image is sharpened */
    isSharpened: boolean;
    /** Whether or not the image is being processed to be sharpened */
    isLoading: boolean;
}

/**
 * This button is responsible for sharpening an image
 */
export function SharpenButton({ isSharpened, disabled = true, showIcon, ...rest }: SharpenButtonProps) {
    const { t } = useTranslationSSR();
    const sharpenedText = t(messages.isSharpenedLabel.id);
    const unsharpenedText = t(messages.isNotSharpenedLabel.id);

    return (
        <ToolbarButton isDisabled={disabled} {...rest}>
            {isSharpened ? (
                <Fragment>
                    {showIcon && <UnsharpenIcon aria-hidden={true} />}
                    <div className={classNames("dcl-ctx-button-label")}>{sharpenedText}</div>
                </Fragment>
            ) : (
                <Fragment>
                    {showIcon && <SharpenIcon aria-hidden={true} />}
                    <div className={classNames("dcl-ctx-button-label")}>{unsharpenedText}</div>
                </Fragment>
            )}
        </ToolbarButton>
    );
}

SharpenButton.displayName = "SharpenButton";
