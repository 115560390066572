import React from "react";
import classNames from "classnames";
import { FlexBox, FlexBoxProps } from "@vp/swan";
import * as styles from "./IconsPanelContainer.module.scss";

interface IconsPanelContainerProps extends FlexBoxProps {
    searchTerm: string;
}

export function IconsPanelContainer({ className, children, searchTerm, ...rest }: IconsPanelContainerProps) {
    return (
        <FlexBox
            flexDirection="column"
            justifyContent="space-between"
            className={classNames(
                "icons-panel-container",
                className,
                {
                    [styles.shapeIconPanelAdjust]: searchTerm
                },
                styles.iconsPanelContainer
            )}
            {...rest}
        >
            <div className={classNames("icons-scrollable-content-container", styles.iconsScrollableContentContainer)}>
                <FlexBox
                    flexDirection="column"
                    justifyContent="space-between"
                    className={classNames("icons-scrollable-content", styles.iconsScrollableContent)}
                >
                    {children}
                </FlexBox>
            </div>
        </FlexBox>
    );
}

IconsPanelContainer.displayName = "IconsPanelContainer";
