import React, { useCallback, useEffect, useRef } from "react";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import {
    useAppSelector,
    useAppDispatch,
    setSaveStatus,
    setShowSaveAs,
    showDesignReview,
    showEditInCartModal
} from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";
import { EDIT_FROM_CART_MODAL_TYPE } from "@shared/utils/Cart";
import { useCartContext } from "@shared/features/Cart";
import { SaveStatusButton as SharedSaveStatusButton } from "@shared/features/Save";
import { useStudio5Save } from "../../hooks/useStudio5Save";

export function SaveStatusButton() {
    const reduxDispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();

    const workId = useAppSelector(state => state.workId);
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { changesUpdatedModalSeen } = useAppSelector(state => state.editInCartModal);

    const startTimeRef = useRef<number>(0);
    const setStartTime = (startTime: number) => {
        startTimeRef.current = startTime;
    };
    const trackSaveTime = () => {
        const endTime = performance.now();
        fireUserInteractionTrackingEvent("Save", endTime - startTimeRef.current);
    };
    const save = useStudio5Save({
        onSaveCallback: trackSaveTime,
        hideSaveToast: !!(isItemInCart && !changesUpdatedModalSeen), // when showing changes updated modal, hide save toast
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVE_STATUS_BUTTON
    });

    const onSaveClick = useCallback(async () => {
        const startTime = performance.now();
        setStartTime(startTime);
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SAVE_STATUS_BUTTON,
            // @ts-ignore
            label: "Save Status Button",
            // @ts-ignore
            extraData: () => ({ editFromCartFlow: isItemInCart })
        });

        // if item is already in cart, the first time user saves, show changes updated modal
        if (isItemInCart && !changesUpdatedModalSeen) {
            reduxDispatch(
                showEditInCartModal({
                    showModal: true,
                    modalType: EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED,
                    callback: () => reduxDispatch(showDesignReview({ show: true })),
                    changesUpdatedModalSeen: true
                })
            );
        }

        if (workId) {
            save();
        } else {
            reduxDispatch(setShowSaveAs({ showSaveAs: true }));
        }
    }, [isItemInCart, workId, reduxDispatch, save, changesUpdatedModalSeen]);

    useEffect(() => {
        if (easelLoaded) {
            if (workId) {
                reduxDispatch(setSaveStatus(SAVE_STATUS.CHANGES_SAVED));
            } else {
                reduxDispatch(setSaveStatus(SAVE_STATUS.NO_CHANGES));
            }
        }
    }, [easelLoaded, workId, reduxDispatch]);

    return <SharedSaveStatusButton onSaveClick={onSaveClick} />;
}

SaveStatusButton.displayName = "SaveStatusButton";
