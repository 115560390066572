import { useIdentityContext } from "@design-stack-vista/identity-provider";
// eslint-disable-next-line import/no-restricted-paths
import { reportAITemplateUsage } from "@five/clients/imagemindClient";
import { useCartContext } from "@shared/features/Cart";
import { useDesignDialog, useOnCanvasClick, DesignPanelType } from "@shared/features/DesignPanel";
import { usePremiumFinishReviewInstructionsRequired } from "@shared/features/PremiumFinish";
import { usePreviewsContext } from "@shared/features/Previews";
import {
    useIsBlankAndHasNoUpsells,
    useNeedAddButtonContext,
    useDesignRequirementsContext
} from "@shared/features/Product";
import { SaveDesignWithSaveDocumentOmitted } from "@shared/features/Save";
import { useNavigateToTeamsPage } from "@shared/features/Teams";
import {
    useAppSelector,
    useAppDispatch,
    setShowFullBleedPremiumFinishErrorModal,
    showDesignReview,
    setShowBlankDocumentAlert
} from "@shared/redux";
import { ImagemindReportingInput } from "@shared/redux/slices/selectedAITemplateReportingData";
import { GetDocument } from "@shared/utils/CimDoc";
import { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { newRelicWrapper } from "@shared/utils/Errors";
import { fireReviewPageTrackingEvent } from "@shared/utils/Tracking";
import cloneDeep from "lodash/cloneDeep";
import { useCallback } from "react";
import { validateBlankDocument } from "@design-stack-vista/vista-validations";

type NextStepActionType = "addDialog" | "selectCanvas" | "reviewPage" | "teamDetailsPage" | "blankDocument";

type NextStepAction = {
    action: NextStepActionType;
    canvasName: string;
    canvasIndex: number;
};

interface Props {
    activeCanvasName: string | undefined;
    getDocument: GetDocument;
    triggerCanvasChange: TriggerCanvasChange;
    save: SaveDesignWithSaveDocumentOmitted;
    checkValidations: () => boolean;
}

export function useHandleNextStepClick({
    activeCanvasName,
    getDocument,
    triggerCanvasChange,
    save,
    checkValidations
}: Props) {
    const teamsPlaceholders = useAppSelector(state => state.teamsPlaceholders);
    const selectedAITemplateReportingData = useAppSelector(state => state.selectedAITemplateReportingData);
    const { designPanelType, dialogCanvasName, getHasDialogBeenSeen } = useDesignDialog();
    const isBlankAndHasNoUpsells = useIsBlankAndHasNoUpsells();
    const needAddButton = useNeedAddButtonContext();
    const { isItemInCart } = useCartContext();

    const dispatch = useAppDispatch();

    const premiumFinishReviewInstructionsRequired = usePremiumFinishReviewInstructionsRequired(activeCanvasName);
    const { setDesignPanelType, setDialogCanvas } = useDesignDialog();
    const { canvasSelectorUrls } = usePreviewsContext();
    const onCanvasClick = useOnCanvasClick({
        activeCanvasName,
        getDocument,
        triggerCanvasChange,
        fromNextStep: true
    });
    const designRequirements = useDesignRequirementsContext();
    const navigateToTeamsPage = useNavigateToTeamsPage({ save });
    const { auth } = useIdentityContext();
    const authToken = auth?.getToken();
    const { isBlankDocumentValidationEnabled: isBlankDocumentValidationEnabledOnProduct } = useAppSelector(
        state => state.productGroupConfiguration
    );

    return useCallback(async () => {
        /**
         * Take a look at the canvas and find what the next action should be
         */
        const getNextStepAction = async (): Promise<NextStepAction> => {
            // start with the current canvasOrdinal.
            if (designRequirements && activeCanvasName) {
                let canvasIndex = designRequirements.getPanelIndexByName(activeCanvasName);

                // check if the document is blank or not only for enabled products e.g. PPAG
                if (isBlankDocumentValidationEnabledOnProduct) {
                    try {
                        const cimDoc = await getDocument();
                        const blankDocument = await validateBlankDocument({ cimDoc });
                        if (!blankDocument.isValid) {
                            return { canvasName: "", canvasIndex, action: "blankDocument" };
                        }
                    } catch (err) {
                        newRelicWrapper.noticeError(new Error("Error in validating blank document", err));
                    }
                }

                // always go directly to design review for items in cart, excluding items with teams experience enabled
                if (isItemInCart && !teamsPlaceholders?.length) {
                    return { canvasName: "", canvasIndex, action: "reviewPage" };
                }
                if (designPanelType === DesignPanelType.CheckoutAdd && designRequirements) {
                    canvasIndex = designRequirements.getPanelIndexByName(dialogCanvasName);
                }

                // We want to start on the next one so we add one to it.
                canvasIndex += 1;

                // Subtract 1 here to make it 0 based
                while (canvasIndex < designRequirements.numberOfPanels) {
                    // Get the specific canvas this is 0 based, so we need to take one off the index.
                    const panel = designRequirements.panels[canvasIndex];
                    // don't navigate to non-designable canvases
                    if (!isBlankAndHasNoUpsells(panel)) {
                        // Does the canvas show the add button?
                        if (needAddButton(panel, panel.name)) {
                            // If so, have we already seen the dialog?
                            if (!getHasDialogBeenSeen(panel.name)) {
                                // If not create the action for us to do
                                return { canvasName: panel.name, canvasIndex, action: "addDialog" };
                            }
                            // Nope... move along
                        } else {
                            // We want to select the canvas
                            return { canvasIndex, canvasName: panel.name, action: "selectCanvas" };
                        }
                    }

                    // Go to the next canvas
                    canvasIndex += 1;
                }
            }
            // Go to the Team Details Page
            if (teamsPlaceholders?.length) {
                return { canvasName: "", canvasIndex: 0, action: "teamDetailsPage" };
            }
            // Go to the review page
            return { canvasName: "", canvasIndex: 0, action: "reviewPage" };
        };

        if (premiumFinishReviewInstructionsRequired) {
            dispatch(setShowFullBleedPremiumFinishErrorModal(true));
        } else if (checkValidations()) {
            const { canvasIndex, action } = await getNextStepAction();
            switch (action) {
                case "blankDocument":
                    dispatch(setShowBlankDocumentAlert(true));
                    break;
                case "addDialog":
                    setDesignPanelType(DesignPanelType.CheckoutAdd);
                    setDialogCanvas(canvasSelectorUrls[canvasIndex]);
                    break;
                case "selectCanvas":
                    setDesignPanelType(DesignPanelType.None);
                    setDialogCanvas(undefined);
                    onCanvasClick(canvasSelectorUrls[canvasIndex]);
                    break;
                case "teamDetailsPage":
                    navigateToTeamsPage();
                    break;
                case "reviewPage":
                default: {
                    const startTime = performance.now();
                    setDesignPanelType(DesignPanelType.None);
                    setDialogCanvas(undefined);
                    window.history.pushState(null, "Title");
                    dispatch(showDesignReview({ show: true, startTime }));
                    fireReviewPageTrackingEvent({
                        extraData: () => ({
                            editFromCartFlow: isItemInCart
                        })
                    });
                    newRelicWrapper.logPageAction("studio-review");
                    break;
                }
            }
        }

        if (selectedAITemplateReportingData) {
            const selectedTemplateId = selectedAITemplateReportingData.input?.feedback?.userActivity?.assetsClicked[0];
            if (selectedTemplateId) {
                const nextStepAITemplateReportingData: ImagemindReportingInput = cloneDeep(
                    selectedAITemplateReportingData
                );
                nextStepAITemplateReportingData.input.feedback.userActivity.assetsClicked = [];
                nextStepAITemplateReportingData.input.feedback.userActivity.selectedAssetsForNextPage = [
                    selectedTemplateId
                ];
                reportAITemplateUsage(nextStepAITemplateReportingData, authToken);
            }
        }
    }, [
        premiumFinishReviewInstructionsRequired,
        checkValidations,
        selectedAITemplateReportingData,
        designRequirements,
        activeCanvasName,
        teamsPlaceholders?.length,
        isBlankDocumentValidationEnabledOnProduct,
        isItemInCart,
        designPanelType,
        getDocument,
        dialogCanvasName,
        isBlankAndHasNoUpsells,
        needAddButton,
        getHasDialogBeenSeen,
        dispatch,
        setDesignPanelType,
        setDialogCanvas,
        canvasSelectorUrls,
        onCanvasClick,
        navigateToTeamsPage,
        authToken
    ]);
}
