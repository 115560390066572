import { useEventCallback } from "@design-stack-ct/utility-react";
import { tabControlWithArrow } from "@five/utilities/tabControlUtilities";
import { useRef, useEffect, useState } from "react";

export const useSidebarKeyboardNavigation = () => {
    const sidebarButtonsRef = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState(0);

    const handleKeyDown = useEventCallback(
        (event: KeyboardEvent) => {
            const tabFocus = tabControlWithArrow({ event, ref: sidebarButtonsRef, activeTab });
            setActiveTab(tabFocus);
        },
        [sidebarButtonsRef]
    );

    useEffect(() => {
        if (sidebarButtonsRef.current) {
            sidebarButtonsRef.current.addEventListener("keydown", handleKeyDown, false);
        }
    }, [sidebarButtonsRef, handleKeyDown]);

    return { sidebarButtonsRef };
};
