// eslint-disable-next-line import/no-extraneous-dependencies
import type { DSS } from "@vp/types-ddif";
import { convertDocumentSourceType, DocumentSourceType } from "@shared/utils/DSS";
import type { Panel } from "@design-stack-ct/cdif-types";

export function findSourcesForNewTemplate(
    designDocument: DSS.DesignDocument,
    template: string,
    calciferResponse?: any
): DSS.DocumentPanelSource[] {
    return designDocument.document.panels.map((panel: Panel, index: number) => {
        const panelSource = designDocument.metadata?.documentSources?.panels.find(s => s.id === panel.id);
        if (panelSource?.id === designDocument.document.panels[0].id || index === 0) {
            return {
                id: panel.id,
                data: template,
                source: convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN)
            };
        }
        if (panel.colorMode === "blank") {
            return {
                id: panel.id,
                data: "",
                source: convertDocumentSourceType(DocumentSourceType.NONE)
            };
        }
        // No source for a non-index-zero panel, just copy it over
        if (!panelSource) {
            return {
                id: panel.id,
                data: "",
                source: convertDocumentSourceType(DocumentSourceType.EMPTY)
            };
        }
        // Not updating non-template panels
        if (panelSource.source !== convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN)) {
            return { ...panelSource };
        }
        const documentPanel = designDocument.document.panels.find((panel: Panel) => panel.id === panelSource.id);
        const matchingTemplate = calciferResponse?.templates.find(
            (calciferTemplate: any) =>
                calciferTemplate.panelName === documentPanel?.name &&
                calciferTemplate.templateToken === panelSource.data
        );
        // if we have a matching template in the new array of templates then we don't need to change anything
        // this must be a generic template and its the exact same one we already used
        if (matchingTemplate) {
            return { ...panelSource };
        }
        // otherwise we need to choose a new template since this one probably matched the previous template
        // we choose the first one that matches - we don't have a better way, and its the same thing calcifer does
        // I'M CHEATING HERE.  If we ever allow users to change physical specifications via gallery filters this won't work for generic templates
        // The new generic templates from calcifer will be different (a different DPS I think?) and there won't be matches
        // If that happens we'll need a better way of determining whether this was generic or not and then finding the same generic one to match to
        const newTemplate = calciferResponse?.templates.find(
            (calciferTemplate: any) => calciferTemplate.panelName === documentPanel?.name
        );
        if (newTemplate) {
            return {
                id: panel.id,
                data: newTemplate.templateToken,
                source: convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN)
            };
        }

        // Couldn't find anything
        // (this shouldn't happen as the above check only fail when we don't have a calcifer response and, if we have templates to match, we should have a response)
        return panelSource;
    });
}
