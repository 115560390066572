import { produce } from "immer";
import cloneDeep from "lodash/cloneDeep";
import { addQueryStringData } from "@shared/utils/WebBrowser";
import { getStudioUniqueSessionId } from "@shared/utils/Tracking";
import { createDocumentPanelSource, documentSourceTypeMap, V2 } from "@shared/utils/DSS";
import { FAKE_INSTRUCTIONS } from "@shared/utils/Previews";
import { encodeForRenderingApis, saveTransientDocument } from "@shared/utils/DocumentStorage";
import { Store } from "@shared/redux";
import { mapDesignAttributeToProductOption, mapProductOptionValueToDesignAttributeValue } from "@shared/utils/Product";
import type { DSS } from "@vp/types-ddif";
import { isScene, isView, View } from "@shared/utils/Previews/purcsFallbackUtilities";
import { isString } from "@design-stack-ct/utility-core";
import { applyPlaceholderTextToDocument } from "@shared/utils/CimDoc";
import type { Panel } from "@design-stack-ct/cdif-types";

// magic number copied from cimpress designer
const MAX_SIZE = 1500;

// Default transient preview width
export const TRANSIENT_PREVIEW_WIDTH = 400;

// Handle max url length
const TRANSIENT_INSTRUCTIONS_SIZE_LIMIT = 7000;

interface Size {
    height: number;
    width: number;
}

interface Layer {
    type: string;
    uri: string;
}

function getSceneSize(size: Size) {
    const aspectRatio = size.height / size.width;

    return aspectRatio >= 1
        ? /* this is tall */ { height: MAX_SIZE, width: Math.round(MAX_SIZE / aspectRatio) }
        : /* this is wide */ { height: Math.round(MAX_SIZE * aspectRatio), width: MAX_SIZE };
}

// These projection IDs are built-in projections supported by Cimpress Designer
// https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/298844816/Panel+Projections
const simpleProjectionIds = [
    "ROTATE_90",
    "ROTATE_270",
    "90_270_EVEN_ODD",
    "90_270_ODD_EVEN",
    "270_90_EVEN_ODD",
    "270_90_ODD_EVEN"
];

/**
 * Checks to see if a projection ID is one of the ones supported by
 * Cimpress Designer out of the box.
 * @param {string} projectionId
 */
export const isSimpleProjectionId = (projectionId?: string) => {
    if (!projectionId) {
        return false;
    }
    return simpleProjectionIds.includes(projectionId);
};

/**
 * Work arround rendering dropping things without printUrls
 * @param {*} cimDoc
 * @returns cimDoc
 */
export const applyImagePrintPlaceholdersToDoc = (cimDoc: DSS.DesignDocument): DSS.DesignDocument => {
    return produce(cimDoc, draft => {
        if (draft.document && draft.document.panels) {
            draft.document.panels.forEach(panel => {
                if (panel.images) {
                    panel.images.forEach(image => {
                        if (image.previewUrl && !image.printUrl) {
                            // eslint-disable-next-line no-param-reassign
                            image.printUrl = image.previewUrl;
                        }
                    });
                }
            });
        }
    });
};

async function getSimpleScene(page: number, size: Size, backgroundImage?: string) {
    const sceneSize = getSceneSize(size);

    const data = {
        width: sceneSize.width,
        height: sceneSize.height,
        page,
        layers: [] as Layer[]
    };

    if (backgroundImage) {
        data.layers.push({
            type: "underlay",
            uri: backgroundImage
        });
    }

    // do not provide product data.  This breaks projected products because the scene service doesn't know about the
    // complex projection

    return addQueryStringData(`${CIMPRESS_SCENE_URL}/transient`, {
        data: await encodeForRenderingApis(data)
    });
}

function getPreviewInstructions(cimDocUrl: string) {
    return `${UDS_INSTRUCTIONS_V3_URL}/instructions:preview?documentUri=${encodeURIComponent(cimDocUrl)}`;
}

interface getTemporaryPreviewInstructionsUrlParams {
    designDocument: DSS.DesignDocument;
    authToken: string;
}

export async function getTemporaryPreviewInstructionsUrl({
    designDocument: originalDesignDocument,
    authToken
}: getTemporaryPreviewInstructionsUrlParams) {
    const newDesignDocument = {
        document: cloneDeep(originalDesignDocument.document),
        fontRepositoryUrl: originalDesignDocument.fontRepositoryUrl,
        version: originalDesignDocument.version,
        projectionId: originalDesignDocument.projectionId
    };

    const encodedDoc = await encodeForRenderingApis(newDesignDocument);

    const transientInstructionsUrl = getPreviewInstructions(encodedDoc);
    const transientInstructionsLength = encodeURIComponent(transientInstructionsUrl).length;

    if (transientInstructionsLength < TRANSIENT_INSTRUCTIONS_SIZE_LIMIT) {
        return transientInstructionsUrl;
    }

    const cimDocUrl = await (await saveTransientDocument(newDesignDocument, authToken))._links.documentRevision.href;
    return getPreviewInstructions(cimDocUrl);
}

/**
 * Takes a document and page number to generate a transientPreviewURL of specified width
 * (See example usage in CanvasSelector)
 * @param {DDIF} document design document containing the panel to be rendered
 * @param {Number} width desired with of preview
 * @param {Number} page analagous to the surface ordinal of the canvas this preview belongs to
 *        - Strongly recommended providing a page number so service can map the panel to the right scene
 * @returns {String} transient scene preview URL
 */
export async function getTransientPreviewURL({
    designDocument,
    width = TRANSIENT_PREVIEW_WIDTH,
    page,
    scene,
    authToken
}: {
    designDocument: DSS.DesignDocument;
    width?: number;
    page: number;
    scene?: View | string;
    authToken: string;
    noScene?: boolean;
}) {
    const currentState = Store.getState();
    const { studioSelectedProductOptions, designAttributeMappings } = currentState;
    // don't need metadata here
    const newDocument = applyImagePrintPlaceholdersToDoc({
        document: designDocument.document,
        fontRepositoryUrl: designDocument.fontRepositoryUrl,
        version: designDocument.version,
        projectionId:
            scene && isSimpleProjectionId(designDocument.projectionId) ? designDocument.projectionId : undefined
    });

    const transientDocumentURI = await getTemporaryPreviewInstructionsUrl({ designDocument: newDocument, authToken });

    // Fold lines on folded vertical products do not renderer with the correct orientation
    const foldedProductOption = mapDesignAttributeToProductOption(designAttributeMappings, "Fold");
    if (studioSelectedProductOptions[foldedProductOption]) {
        if (
            mapProductOptionValueToDesignAttributeValue(
                designAttributeMappings,
                foldedProductOption,
                studioSelectedProductOptions[foldedProductOption]
            ) === "Folded"
        ) {
            return `${CIMPRESS_RENDERING_URL}/preview?&width=${width}&category=studio&instructions_uri=${encodeURIComponent(
                `${transientDocumentURI}&surfaceOrdinals=${page}`
            )}`;
        }
    }

    const panelToApply: Panel | undefined = newDocument.document.panels[page - 1] ?? newDocument.document.panels[0];
    const { scenesConfiguration } = currentState;
    const canvasScene = scenesConfiguration?.underlay.find(
        underlay => underlay.name.toLowerCase() === panelToApply?.name.toLowerCase()
    );

    const sceneToUse = canvasScene?.sceneInfo?.documentSlot && !designDocument.projectionId ? canvasScene : scene;

    if (sceneToUse && isView(sceneToUse)) {
        // we got a transient scene from purcs
        const renderingUrl = sceneToUse._links.image.href.replace(
            FAKE_INSTRUCTIONS,
            encodeURIComponent(transientDocumentURI)
        );
        return renderingUrl;
    }

    let sceneURL;
    if (sceneToUse && isScene(sceneToUse)) {
        sceneURL = sceneToUse.url;
    } else if (isString(sceneToUse)) {
        sceneURL = sceneToUse;
    } else {
        sceneURL = await getSimpleScene(page, {
            height: parseInt(panelToApply?.height, 10),
            width: parseInt(panelToApply?.width, 10)
        });
    }

    return `${CIMPRESS_RENDERING_URL}/preview?scene=${encodeURIComponent(
        sceneURL
    )}&width=${width}&category=studio&instructions_uri=${encodeURIComponent(transientDocumentURI)}`;
}

/**
 * This function is used to preview backside/inside upsell a full bleed image placeholder
 */
export const getTransientPreviewUrlWithFullBleed = async (
    selectedOptions: Record<string, string>,
    width = TRANSIENT_PREVIEW_WIDTH,
    page: number,
    transientScene: View,
    authToken: string
) => {
    const { productKey, productVersion, locale } = Store.getState();

    if (productVersion === null) {
        throw Error("Product version is required for transient preview URL with full bleed");
    }

    const designDoc = await V2.getDesignDocForFullbleed(productKey, productVersion, selectedOptions, locale);

    return getTransientPreviewURL({ designDocument: designDoc, width, page, scene: transientScene, authToken });
};

/**
 * This function is used to preview backside/inside upsell templates
 */
export const getTransientPreviewUrlWithTemplateToken = async (
    blankDocument: DSS.DesignDocument,
    templateToken: string,
    selectedOptions: Record<string, string>,
    width = TRANSIENT_PREVIEW_WIDTH,
    page: number,
    transientScene: View,
    authToken: string
) => {
    const { productKey, productVersion, locale } = Store.getState();

    if (productVersion === null) {
        throw Error("Product version is required for transient URL with template token");
    }

    const documentSources = {
        panels: [
            createDocumentPanelSource(
                blankDocument.document.panels[page - 1].id,
                documentSourceTypeMap.TEMPLATE_TOKEN,
                templateToken
            )
        ]
    };

    // Apply the placeholder text for view in the preview and retrieve the design doc from DSS
    const designDoc = applyPlaceholderTextToDocument(
        await V2.getDesignDocumentWithSources(
            productKey,
            productVersion,
            selectedOptions,
            blankDocument,
            documentSources,
            undefined,
            [],
            getStudioUniqueSessionId(),
            undefined,
            locale
        )
    );

    return getTransientPreviewURL({ designDocument: designDoc, width, page, scene: transientScene, authToken });
};

async function getPreview(page: number, designDocument: DSS.DesignDocument, authToken: string, transientScene?: View) {
    return getTransientPreviewURL({
        designDocument,
        width: TRANSIENT_PREVIEW_WIDTH,
        page,
        scene: transientScene,
        authToken
    });
}

export async function getPreviews(designDocument: DSS.DesignDocument, transientScenes: View[], authToken: string) {
    const previewPromises = designDocument.document.panels.map((panel, index) =>
        getPreview(
            index + 1,
            designDocument,
            authToken,
            transientScenes.find(scene => scene.name && scene.name.toLowerCase() === panel.name.toLowerCase())
        )
    );
    return Promise.all(previewPromises);
}

export async function getTransientPreviewWithTransparentBG({
    cimDoc,
    width = TRANSIENT_PREVIEW_WIDTH
}: {
    cimDoc: DSS.DesignDocument;
    width?: number;
}): Promise<string> {
    const base64Document = await encodeForRenderingApis(cimDoc);
    const instructions = `${UDS_INSTRUCTIONS_V3_URL}/instructions:preview?documentUri=${encodeURIComponent(
        base64Document
    )}`;
    return `${CIMPRESS_RENDERING_URL}/v1/cse/preview?width=${width}&format=webp&instructions_uri=${encodeURIComponent(
        instructions
    )}`;
}
