// This code has been generated via svgr
/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function UploadThumbnailIcon(props: Props) {
    return (
        <svg viewBox="0 0 125 127" fill="none" {...props}>
            <rect x="0" y="7" width="120" height="120" rx="7.5" fill="currentColor" stroke="currentColor" />
            <rect x="5" y="0" width="120" height="120" rx="7.5" fill="currentColor" stroke="currentColor" />
        </svg>
    );
}
UploadThumbnailIcon.displayName = "UploadThumbnailIcon";

export default UploadThumbnailIcon;
