function getSizes(sizes: number[]) {
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    const total = sizes.reduce(reducer);
    const normalized = sizes.map((size: number) => (size * 100) / total);
    return normalized;
}

export function getRowHeights(table: TableItem): number[] {
    const { rowHeights } = table._itemViewModel.get("metaData");
    const heights = rowHeights.map((rowHeight: string) => parseFloat(rowHeight));
    return getSizes(heights);
}

export function getColumnWidths(columns: TableItemColumn[]): number[] {
    const widths = columns.map((column: TableItemColumn) => parseFloat(column.width as string));
    return getSizes(widths);
}

export function removeNorthWestRotationHandle(containerDivId: string) {
    const containerDiv = document.querySelector(`#${containerDivId}`);
    containerDiv?.childNodes.forEach((childNode: HTMLElement) => {
        if (childNode.classList.contains("dcl-rotation-angle-nw")) {
            childNode.remove();
        }
    });
}
