import classNames from "classnames";
import React, { HTMLProps } from "react";
import * as styles from "./HorizontalDivider.module.scss";

export function HorizontalDivider(props: HTMLProps<HTMLHRElement>) {
    const { className, ...rest } = props;
    return (
        <hr
            {...rest}
            className={classNames(className, styles.horizontalDividerDividerStyle, "horizontalDivider-dividerStyle")}
        />
    );
}
HorizontalDivider.displayName = "HorizontalDivider";
