import type { DSS } from "@vp/types-ddif";
import { Dispatch } from "redux";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import { getQueryParams, addQueryParam } from "@shared/utils/WebBrowser";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import {
    easelFinishedLoading,
    hideLoader,
    setTemplate,
    showLoader,
    setAlerts,
    autoClosableAlert,
    setIsChangingTemplate
} from "@shared/redux";
import type { PlaceholderImage, TemplateItem, TemplateTextItem } from "@design-stack-ct/cdif-types";
import { cleanBlankPanels, getTransformedDocumentForChangeTemplate } from "@shared/utils/ProductTransformation";

interface LoadNewDesignProps {
    productKey: string;
    productVersion: number | undefined;
    studioSelectedProductOptions: Record<string, string>;
    customerSelectedProductOptions: Record<string, string>;
    designDocument: DSS.DesignDocument;
    locale: string;
    template: string;
}

export type LoadNewDesign = (props: LoadNewDesignProps) => Promise<void>;

interface ChangeTemplateOptions {
    productKey: string;
    productVersion: number | undefined;
    studioSelectedProductOptions: Record<string, string>;
    customerSelectedProductOptions: Record<string, string>;
    template: string;
    dispatch: Dispatch<any>;
    loadingMessage: string;
    handleSave: () => Promise<void>;
    shouldSaveOnChange: boolean;
    designDocument: DSS.DesignDocument;
    locale: string;
    eventType: string;
    loadNewDesign: LoadNewDesign;
}

export async function changeTemplateInStudio({
    productKey,
    productVersion,
    studioSelectedProductOptions,
    customerSelectedProductOptions,
    template,
    dispatch,
    loadingMessage,
    handleSave,
    shouldSaveOnChange,
    designDocument,
    locale,
    eventType,
    loadNewDesign
}: ChangeTemplateOptions) {
    let success = false;

    try {
        dispatch(setIsChangingTemplate(true));
        dispatch(showLoader(loadingMessage));
        const startTime = performance.now();

        if (shouldSaveOnChange) {
            await handleSave();
        }

        await loadNewDesign({
            productKey,
            productVersion,
            studioSelectedProductOptions,
            customerSelectedProductOptions,
            designDocument,
            locale,
            template
        });

        // @ts-ignore
        if (!getQueryParams().workId) {
            window.history.replaceState("update-url", "", addQueryParam(window.location.href, "template", template));
        }

        dispatch(hideLoader());
        dispatch(setTemplate(template));

        if (shouldSaveOnChange) {
            dispatch(
                setAlerts({
                    alerts: [
                        {
                            key: "studio.components.changeTemplate.previousDesignSaved",
                            skin: "positive"
                        }
                    ]
                })
            );
            dispatch(autoClosableAlert());
        }

        fireDesignToolTrackingEvent({
            eventDetail:
                eventType === "template color"
                    ? STUDIO_TRACKING_EVENTS.CHANGE_TEMPLATE_COLOR_SUCCESS
                    : STUDIO_TRACKING_EVENTS.CHANGE_TEMPLATE_SUCCESS,
            label: `Change ${eventType} success`
        });

        const endTime = performance.now();
        fireUserInteractionTrackingEvent(`Change ${eventType}`, endTime - startTime);

        success = true;
    } catch (err) {
        fireDesignToolTrackingEvent({
            eventDetail:
                eventType === "template color"
                    ? STUDIO_TRACKING_EVENTS.CHANGE_TEMPLATE_COLOR_FAILURE
                    : STUDIO_TRACKING_EVENTS.CHANGE_TEMPLATE_FAILURE,
            label: `Change ${eventType} failure`
        });

        success = false;
        handleError(err, ERROR_CODES.CHANGE_TEMPLATE, true, true);
    } finally {
        dispatch(easelFinishedLoading());
        dispatch(setIsChangingTemplate(false));
    }

    return success;
}

// This doesn't work with DTR as replace image placeholders are no longer treated as placeholders
// Hopefully the transfer customization endpoint behaves better
function getNumberOfImagePlaceholders(document: DSS.DesignDocument) {
    const frontPanel = document.document.panels[0];
    const templateMetadata = document.metadata?.template;
    return (
        frontPanel.images?.reduce((acc, image) => {
            if (
                templateMetadata
                    // @ts-ignore
                    ?.filter(metadata => !!metadata.originalTemplateElementId && metadata.placeholder)
                    .find(metadata => metadata.id === image.id)
            ) {
                return acc + 1;
            }
            return acc;
        }, 0) || 0
    );
}

function getListOfPurposes(document: DSS.DesignDocument) {
    const frontPanel = document.document.panels[0];
    const templateMetadata = document.metadata?.template;

    const listOfTextFields = frontPanel.textAreas?.map(textArea => ({ id: textArea.id })) || [];
    const listOfWordartFields =
        frontPanel.itemReferences
            ?.filter(itemReference => itemReference.type === ItemReferenceTypes.WORD_ART)
            .map(wordArt => ({ id: wordArt.id })) || [];
    const listOfPurposeFields = [...listOfTextFields, ...listOfWordartFields];

    return (
        listOfPurposeFields.reduce((acc, field) => {
            const matchingMetadata = templateMetadata
                ?.filter(metadata => !!metadata.originalTemplateElementId && metadata.purpose)
                .find(metadata => metadata.id === field.id);
            if (matchingMetadata) {
                return [...acc, matchingMetadata.purpose];
            }
            return acc;
        }, []) || []
    );
}

function checkTransferResultsInLostData(sourceDocument: DSS.DesignDocument, newDocument: DSS.DesignDocument) {
    const numberOfSourceImagePlaceholders = getNumberOfImagePlaceholders(sourceDocument);
    const numberOfNewImagePlaceholders = getNumberOfImagePlaceholders(newDocument);

    const sourcePurposes = getListOfPurposes(sourceDocument);
    const newPurposes = getListOfPurposes(newDocument);

    return (
        numberOfNewImagePlaceholders < numberOfSourceImagePlaceholders ||
        !sourcePurposes.every(purpose => newPurposes.includes(purpose))
    );
}

interface GenerateDocumentForNewTemplateOptions {
    productKey: string;
    locale: string;
    productVersion: number;
    studioSelectedProductOptions: Record<string, string>;
    template: string;
    dispatch: Dispatch<any>;
    authToken: string;
    loadingMessage: string;
    isCurrentlyFullBleed: boolean;
    removeBackground?: boolean;
    isColorMatchingBacksideEnabled: boolean;
    getDocument: () => Promise<DSS.DesignDocument>;
}

export interface GenerateDocumentForNewTemplateReturn {
    designDocument?: DSS.DesignDocument;
    requiresConfirmation?: boolean;
}

export async function generateDocumentForNewTemplate({
    productKey,
    productVersion,
    locale,
    studioSelectedProductOptions,
    template,
    dispatch,
    authToken,
    loadingMessage,
    isCurrentlyFullBleed,
    removeBackground,
    isColorMatchingBacksideEnabled,
    getDocument
}: GenerateDocumentForNewTemplateOptions): Promise<GenerateDocumentForNewTemplateReturn> {
    try {
        dispatch(showLoader(loadingMessage));

        const sourceDocument = await getDocument();

        if (isCurrentlyFullBleed && sourceDocument.metadata?.template) {
            // Remove any fullbleed placeholders.
            const {
                document: { panels },
                metadata: { template }
            } = sourceDocument;
            panels.forEach((panel: any) => {
                const { images } = panel;
                if (images) {
                    images.forEach(({ id }: { id: string }, index: number) => {
                        const imageIndex = template.findIndex((t: any) => t.id === id);
                        if (imageIndex !== -1) {
                            const imageMetadata = template[imageIndex];
                            if (isPlaceholder(imageMetadata)) {
                                images.splice(index, 1);
                                template.splice(imageIndex, 1);
                            }
                        }
                    });
                }
            });
        }

        // Remove background if necessary
        if (removeBackground && sourceDocument.metadata?.template) {
            const {
                document: { panels },
                metadata: { template }
            } = sourceDocument;

            const backgrounds = template.filter((t: any) => t.background === true);

            if (backgrounds.length > 0) {
                panels.forEach((panel: any) => {
                    const { shapes } = panel;
                    if (shapes) {
                        backgrounds.forEach((bg: any) => {
                            const bgIndex = shapes.findIndex((shape: any) => shape.id === bg.id);
                            if (bgIndex > -1) {
                                shapes.splice(bgIndex, 1);
                                const templateIndex = template.findIndex((t: any) => t.id === bg.id);
                                template.splice(templateIndex, 1);
                            }
                        });
                    }
                });
            }
        }

        const designDocument = await getTransformedDocumentForChangeTemplate({
            locale,
            productKey,
            productVersion,
            selections: studioSelectedProductOptions,
            authToken,
            template,
            sourceDocument,
            isColorMatchingBacksideEnabled
        });

        if (checkTransferResultsInLostData(sourceDocument, designDocument)) {
            return { designDocument, requiresConfirmation: true };
        }

        // Some of the previous code sometimes carries over items into
        //  blank backsides. However clearing it earlier was breaking generic backsides.
        //  This is a short term fix while the test is ongoing & to stop the bleeding
        //  for manifacturing.
        cleanBlankPanels(designDocument);

        return { designDocument };
    } catch (err) {
        handleError(err, ERROR_CODES.CHANGE_TEMPLATE, true, true);
    } finally {
        dispatch(hideLoader());
    }

    return {};
}

function isPlaceholder(templateItem: TemplateItem): templateItem is PlaceholderImage | TemplateTextItem {
    return (templateItem as PlaceholderImage).placeholder;
}
