import { useActiveDialog } from "@shared/features/ActiveDialog";
import { useState, useEffect } from "react";

export const useFloatingSidebarHighlightShowing = () => {
    const { showHighlight, setShowHighlight } = useActiveDialog();
    const [highlightShowing, setHighlightShowing] = useState(false);

    useEffect(() => {
        if (showHighlight) {
            const cancelHighlightShowing = setTimeout(() => {
                setHighlightShowing(true);
            }, 500);
            const cancelHighlightHiding = setTimeout(() => {
                setHighlightShowing(false);
                setShowHighlight(false);
            }, 5500);

            return () => {
                clearTimeout(cancelHighlightShowing);
                clearTimeout(cancelHighlightHiding);
            };
        }
        setHighlightShowing(false);

        return () => {};
    }, [setShowHighlight, showHighlight]);

    return highlightShowing;
};
