import React from "react";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getTextAndWordArtFieldNumber } from "@utilities";
import { useDesigner } from "@designer-suite";
import { ItemRow } from "@presentational";
import { RichTextFieldWrapper } from "./RichTextFieldWrapper";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /** What item this richTextFieldRow is hooked up to */
    item: TextItem;
    /** React component to render at right of the textfield */
    icon?: React.ReactNode;
    /** For overriding styles */
    className?: string;
    /** Do we want to show the delete button * */
    showDeleteButtons?: boolean;
    /** For tracking usage of left panel editing */
    leftPanel?: boolean;
}

/**
 * A connected RichTextFieldRow. This uses the RichTextField and hooks up the delete button and premium finish icon
 */
export function RichTextFieldRow({ item, icon, className, showDeleteButtons = true }: Props) {
    const designer = useDesigner();
    const fieldNumber = getTextAndWordArtFieldNumber(item);
    // When the delete icon is clicked, delete this item
    const onDelete = showDeleteButtons
        ? () => {
              if (!designer) {
                  return;
              }
              designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_LEFT_PANEL_DELETE);
              designer.api.design.updateItem(item.id, (mutableItem: MutableItem) => {
                  mutableItem.remove();
              });
          }
        : undefined;

    // When the delete icon is focused through tabbing, deselect the text field on canvas
    const onTabDelete = showDeleteButtons
        ? () => {
              if (!designer) {
                  return;
              }
              designer.selectionManager.select([]);
          }
        : undefined;

    return (
        <ItemRow
            className={className}
            editor={
                <RichTextFieldWrapper item={item} icon={icon} selectItemOnFocus leftPanel fieldNumber={fieldNumber} />
            }
            onClickDelete={onDelete}
            onTabDelete={onTabDelete}
            fieldNumber={fieldNumber}
        />
    );
}
RichTextFieldRow.displayName = "RichTextFieldRow";
