import React from "react";

import { SharpenButton as SharpenButtonComponent } from "@shared/features/ContextualToolbar";
import { STUDIO_TRACKING_EVENTS, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { isSingleColorCanvas, selectedItemsAreOfTypes, useDecorationTechnology } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { UploadTypes } from "@shared/features/UploadsAndAssets";
import { EditImageToggle, ToolType } from "@five/xerox/features/EditImage/EditImageToggle";
import { useSelection } from "../designer/useSelection";
import { useDesigner } from "../designer/DesignerProvider";
import { itemIsIcon, itemIsImageUnreplacedPlaceholder, itemIsSharpenable } from "../util";

interface Props {
    /** For overriding styles */
    className?: string;
    /** Whether the button should always be shown, even if it is disabled.
     * @default false
     */
    alwaysShow?: boolean;
    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
    useToggle?: boolean;
}

export function SharpenTool({ className = "tool-icon", alwaysShow, showIcon, useToggle }: Props) {
    const currentDecorationTech = useDecorationTechnology();
    const designer = useDesigner();
    const selection = useSelection(
        "model:change:appliedProcesses change:processesInProgress change:sharpenable model:change:originalUrl"
    );

    const item = selection[0] as ImageItem;

    if (designer === undefined || selection.length !== 1) {
        return null;
    }

    // only allowed for print, but not for single color
    // sharpening & thresholding don't mix well, DT-9343
    // we shouldn't show the button at all for these deco techs
    const allowedForProcessType =
        currentDecorationTech === DecorationTechnologiesSimple.PRINT && !isSingleColorCanvas(designer);
    if (!allowedForProcessType) {
        return null;
    }

    const isImage = selectedItemsAreOfTypes(selection, [ItemTypes.IMAGE]);

    const cannotSharpen = itemIsImageUnreplacedPlaceholder(item) || !isImage || !itemIsSharpenable(designer, item);

    if (cannotSharpen && !alwaysShow) {
        return null;
    }

    const isSharpened = isImage && item._itemViewModel.isProcessApplied("sharpen");
    const isSharpenInProgress = !cannotSharpen && item._itemViewModel.isProcessRunning("sharpen");

    const onClick = () => {
        designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_SHARPEN, {
            isSharpened,
            imageType: itemIsIcon(item) ? UploadTypes.ICON : UploadTypes.IMAGE
        });
        if (!isSharpened) {
            // only track the time for actually performing a sharpen, not un-sharpening
            const timeStartedSharpening = performance.now();
            item._itemViewModel.once("model:change:appliedProcesses", e => {
                if (e.isProcessApplied("sharpen")) {
                    const timeToProcess = performance.now() - timeStartedSharpening;
                    // don't want to track re-sharpens, so if it happens too quick ignore it
                    if (timeToProcess > 500) {
                        const dimensions = item._itemViewModel.model.get("naturalDimensions");
                        fireUserInteractionTrackingEvent("Sharpen Image", timeToProcess, {
                            imageResolution: `${dimensions?.width}x${dimensions?.height}`
                        });
                    }
                }
            });
        }
        item._itemViewModel.toggleProcess("sharpen");
    };

    return useToggle ? (
        <EditImageToggle
            toolType={ToolType.Enhance}
            className={className}
            onClick={onClick}
            isActivated={isSharpened}
            isLoading={isSharpenInProgress}
            disabled={cannotSharpen && !isSharpened}
        />
    ) : (
        <SharpenButtonComponent
            className={className}
            onClick={onClick}
            isSharpened={isSharpened}
            isLoading={isSharpenInProgress}
            disabled={cannotSharpen && !isSharpened}
            showIcon={showIcon}
        />
    );
}
SharpenTool.displayName = "SharpenTool";
