import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { UploadsPanelMessages } from "@shared/features/UploadsAndAssets";
import { StudioMetadataItem } from "@shared/utils/Metadata";
import useAddDraggable from "./useAddDraggable";
import type { Page } from "../../@types/page";
import type { Upload } from "../../@types/upload";

interface Props {
    // Upload page as presented in designer.uploadManager
    page: Page;

    className?: string;

    isDraggable?: boolean;

    upload: Upload;

    onImageLoaded?: () => void;

    style?: any;

    imageUrl?: string;

    studioMetadata?: StudioMetadataItem;

    onStart?: (event: any) => void;
    onStop?: (event: any) => void;

    maxCanvasSize?: number;
}

const isOfType = (fileType: string, typeToCheck: string) =>
    typeof fileType === "string" && fileType.toLowerCase() === typeToCheck;

function UploadImage({
    page,
    upload,
    className,
    isDraggable = true,
    onImageLoaded,
    style,
    imageUrl,
    studioMetadata,
    onStart,
    onStop,
    maxCanvasSize
}: Props) {
    const [objectUrl, setObjectUrl] = useState<string>();
    const file = page.get("file");
    const [previewUrl, setPreviewUrl] = useState(imageUrl || page.get("previewUrl"));
    const isPdf = isOfType(page.get("fileType"), "application/pdf");
    const isHeic = isOfType(page.get("fileType"), "image/heic");
    const imageRef = useRef<HTMLImageElement>(null);
    const { t } = useTranslationSSR();
    useAddDraggable(imageRef, page, isDraggable, studioMetadata, onStart, onStop, maxCanvasSize);

    useEffect(() => {
        const changePreviewUrl = (pageModel: Page) => {
            setPreviewUrl(pageModel.get("previewUrl"));
        };
        page.on("change:previewUrl", changePreviewUrl);
        return () => {
            page.off("change:previewUrl", changePreviewUrl);
        };
    }, [page]);

    useEffect(() => {
        if (previewUrl || !file || !file.name || isPdf || isHeic) {
            return;
        }

        setObjectUrl(URL.createObjectURL(file));
        // eslint-disable-next-line consistent-return
        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        };
        // Disabling this error as we don't want Object URL to be a dependancy. It will cause infinite renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file, previewUrl]);

    // Some of the upload pages don't have widths and heights. This code, once the image is loaded check to see if the page is missing it
    // If it is, it will add it to the page's attributes.
    const onLoad = useCallback(
        (event: SyntheticEvent<HTMLImageElement, Event>) => {
            const {
                currentTarget: { width, height }
            } = event;
            if (!page.has("width")) {
                page.set("width", width);
            }
            if (!page.has("height")) {
                page.set("height", height);
            }
            if (onImageLoaded) {
                onImageLoaded();
            }
        },
        [page, onImageLoaded]
    );

    if (!previewUrl && !objectUrl) {
        return null;
    }

    return (
        <img
            style={style}
            onLoad={onLoad}
            ref={imageRef}
            key={page.cid}
            className={className}
            src={previewUrl || objectUrl}
            alt={upload.get("fileName") || t(UploadsPanelMessages.uploading.id)}
        />
    );
}

UploadImage.displayName = "UploadImage";

export { UploadImage };
