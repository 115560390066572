/* eslint-disable max-len */
import React from "react";
import { withPrefix } from "gatsby";

export function TeamsGuideIconStep1(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 145 186" fill="none" {...props}>
            <image xlinkHref={`${ASSET_PREFIX + withPrefix(`/teamsImages/step1.png`)}`} height="186" />
        </svg>
    );
}
TeamsGuideIconStep1.displayName = "TeamsGuideIconStep1";
