import React, { ReactNode, Ref } from "react";
import classnames from "classnames";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./LeftSidebar.module.scss";

export interface SidebarButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    // For overriding styles
    className?: string;
    // whether or not if button is selected
    isSelected?: boolean;
    children?: ReactNode | ReactNode[];
    onClick?: () => void;
}

export const SidebarButton = React.forwardRef((props: SidebarButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { className, isSelected = false, children, ...rest } = props;

    return (
        <button
            ref={ref}
            className={classnames(className, styles.sidebarButton, isSelected ? styles.selected : "")}
            tabIndex={0}
            {...rest}
        >
            {children}
        </button>
    );
});

SidebarButton.displayName = "SidebarButton";
