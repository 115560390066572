/* eslint-disable max-len */
import React from "react";

export function IdeaPanelIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.12066 15H12.0622C12.6764 15 13.1517 14.8239 13.4881 14.4717C13.8294 14.1195 14 13.5961 14 12.9015V6.93606C14 6.24144 13.8221 5.71803 13.4662 5.36583C13.1152 5.01363 12.5887 4.83753 11.8867 4.83753H4.12066C3.41377 4.83753 2.88239 5.01363 2.52651 5.36583C2.1755 5.71803 2 6.24144 2 6.93606V12.9015C2 13.601 2.1755 14.1244 2.52651 14.4717C2.88239 14.8239 3.41377 15 4.12066 15ZM4.13528 13.9948C3.77453 13.9948 3.49665 13.8969 3.30165 13.7013C3.11152 13.5056 3.01645 13.2219 3.01645 12.8501V6.99476C3.01645 6.6181 3.11152 6.33438 3.30165 6.14361C3.49665 5.94794 3.77453 5.85011 4.13528 5.85011H11.8647C12.2255 5.85011 12.5034 5.94794 12.6984 6.14361C12.8934 6.33438 12.9909 6.6181 12.9909 6.99476V12.8501C12.9909 13.2219 12.9007 13.5056 12.7203 13.7013C12.5399 13.8969 12.3157 13.9948 12.0475 13.9948H4.13528ZM3.46252 3.69287H12.5448C12.496 3.40915 12.3961 3.18903 12.245 3.03249C12.0938 2.87596 11.8623 2.79769 11.5503 2.79769H4.44973C4.13772 2.79769 3.90615 2.87596 3.75503 3.03249C3.6039 3.18903 3.5064 3.40915 3.46252 3.69287ZM4.63985 1.8218H11.3601C11.3455 1.55765 11.2602 1.35465 11.1042 1.21279C10.9531 1.07093 10.7337 1 10.4461 1H5.55393C5.2663 1 5.04449 1.07093 4.88848 1.21279C4.73736 1.35465 4.65448 1.55765 4.63985 1.8218Z"
                fill="currentColor"
            />
        </svg>
    );
}
IdeaPanelIcon.displayName = "IdeaPanelIcon";
