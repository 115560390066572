import { isVatInclusive } from "./pricingServiceClient";
import { ChoiceGroupResponse, PricingType, PricingChoiceGroup, PricingApiResponse, Pricing } from "./types";

export function formatPrice(price: number, locale: string, currency: string) {
    const formatter = new Intl.NumberFormat(locale, {
        currency,
        minimumFractionDigits: 2,
        style: "currency"
    });
    return formatter.format(price);
}

export const Sign = {
    POSITIVE: "+",
    NEGATIVE: "-"
};

export const DEFAULT_FRACTION_DIGITS = 2;

// save only relevant choice group values from the api response
export const formatChoiceGroupsPrice = (
    choiceGroups: Record<string, ChoiceGroupResponse>,
    priceToUse: PricingType
): Record<string, PricingChoiceGroup> => {
    return Object.entries(choiceGroups).reduce((acc, [choiceName, choiceValue]) => {
        const { totalListPrice, totalDiscountPrice, differentialListPrice, differentialDiscountPrice } = choiceValue;
        return {
            ...acc,
            [choiceName]: {
                totalListPrice: totalListPrice[priceToUse],
                totalDiscountPrice: totalDiscountPrice[priceToUse],
                differentialListPrice: differentialListPrice[priceToUse],
                differentialDiscountPrice: differentialDiscountPrice[priceToUse]
            }
        };
    }, {});
};

// format api response before saving
export const formatPricingData = (differentialPricingData: PricingApiResponse): Pricing => {
    const { baseSelectionsDiscountedPrice, baseSelectionsListPrice, choiceGroups, currency } = differentialPricingData;
    const priceToUse = isVatInclusive() ? PricingType.TAXED : PricingType.UNTAXED;

    const formattedChoiceGroups = formatChoiceGroupsPrice(choiceGroups, priceToUse);

    const formattedPricing = {
        currency,
        baseSelectionsDiscountedPrice: baseSelectionsDiscountedPrice[priceToUse],
        baseSelectionsListPrice: baseSelectionsListPrice[priceToUse],
        choiceGroups: formattedChoiceGroups
    };
    return formattedPricing;
};
