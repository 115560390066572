import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { getQueryParams, adjustGalleryPreviewUrl } from "@shared/utils/WebBrowser";
import { isDebugMode } from "@shared/utils/Debug";
import { useAppSelector } from "@shared/redux";
import { LoadingImagePreviewIcon } from "./LoadingImagePreviewIcon";

const duration = 1000;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-out`,
    opacity: 1
};

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
};

const timeout = {
    appear: 0,
    enter: 0,
    exit: duration
};

const enabledPreviewMpvs = ["standardBusinessCards"];

const isEnabledOptions = (options: any) => {
    if (options["Product Orientation"] === "Horizontal" && options.Size === "Standard (85 x 55 mm)") {
        return true;
    }
    return false;
};

const messages = defineMessages({
    loading: {
        id: "studio5.components.loader.loading",
        defaultMessage: "Loading..."
    }
});

function LoadingImagePreview() {
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const [loadingPreviewUrl, setLoadingPreviewUrl] = useState<string>();
    const { previewUrl, mpvId, selectedOptions } = getQueryParams();
    const { t } = useTranslationSSR();
    const debugMode = isDebugMode();

    useEffect(() => {
        if (previewUrl && enabledPreviewMpvs.includes(mpvId) && isEnabledOptions(selectedOptions)) {
            const adjustedPreviewUrl = adjustGalleryPreviewUrl(previewUrl);
            // Hack to handle failing images that return an !
            fetch(adjustedPreviewUrl).then(e => {
                if (e.status === 500) {
                    return;
                }
                setLoadingPreviewUrl(adjustedPreviewUrl);
            });
        }
    }, [previewUrl, mpvId, selectedOptions]);

    return (
        <Transition in={!easelLoaded} timeout={timeout} unmountOnExit>
            {state => (
                <div
                    className={classNames("loading-design-preview", { "loading-design-preview-with-debug": debugMode })}
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    {loadingPreviewUrl ? (
                        <img className="loading-preview-pulse" src={loadingPreviewUrl} alt={t(messages.loading.id)} />
                    ) : (
                        <LoadingImagePreviewIcon />
                    )}
                </div>
            )}
        </Transition>
    );
}

LoadingImagePreview.displayName = "LoadingImagePreview";

export default LoadingImagePreview;
