import React from "react";
import { useAppSelector } from "@shared/redux";
import { WarningToast, ErrorToast, AlertToast, SaveSuccessToast } from "@shared/features/SystemMessages";
import AutoRotatedToast from "./AutoRotatedToast";
import "./toast.scss";

export default function Toaster() {
    const showErrorPage = useAppSelector(state => state.showErrorPage);

    return (
        <div className="toast" aria-live="assertive" aria-atomic="true">
            {!showErrorPage && (
                <>
                    <AlertToast />
                    <SaveSuccessToast />
                    <ErrorToast />
                    <WarningToast />
                    <AutoRotatedToast />
                </>
            )}
        </div>
    );
}
Toaster.displayName = "Toaster";
