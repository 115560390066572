import React, { useContext, createContext, ReactNode, useMemo, useCallback } from "react";
import { getQueryParams, addQueryParam } from "@shared/utils/WebBrowser";
import { getDesignDocumentFromDesigner, switchContext } from "@utilities";
import { useDesigner } from "@designer-suite";
import type { MCP } from "@vp/types-ddif";
import { getStudioConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { EaselSceneConfiguration } from "@shared/utils/Scene";
import { useAppDispatch, setDefaultState, setIsProductSwitched } from "@shared/redux";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useIdeaTabEnabled } from "@five/hooks/useIdeaTabEnabled";
import { useStudio5Save } from "../../hooks/useStudio5Save";

interface Props {
    children: ReactNode | ReactNode[];
}
export interface ProductOptions {
    productKey: string;
    locale: string;
    productVersion: number | undefined;
    quantity: number;
    customerSelectedProductOptions: Record<string, string> | undefined;
    workId?: string;
    template?: string;
    targetDocument?: any;
    saveWork?: boolean;
}

interface switchedProductDetails {
    views: Array<MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface>;
    easelScenesConfiguration: EaselSceneConfiguration;
}

type UpdateUrlProps = {
    key?: string;
    productVersion?: number;
    mpvId?: string | null;
    selectedOptions: string | undefined;
    workId: string | undefined;
    template?: string;
    targetDocument?: any;
};

type switchMSProps = {
    productOptions: ProductOptions;
    isMailingPostCard: (v: string) => boolean;
};

type ContextData = {
    switchMSProduct: (info: switchMSProps) => Promise<switchedProductDetails>;
    switchProduct: (info: ProductOptions) => Promise<void>;
};

const Context = createContext<ContextData | undefined>(undefined);

export const useSwitchProducts = () => {
    const result = useContext(Context);

    if (!result) {
        throw Error("Please call this within a SwitchProductsProvider");
    }
    return result;
};

function updateUrl(props: UpdateUrlProps) {
    Object.entries(props).forEach(([key, val]) => {
        window.history.replaceState("update-url", "", addQueryParam(window.location.href, key, val));
    });
}

export function SwitchProductsProvider({ children }: Props) {
    const { Provider } = Context;
    const dispatch = useAppDispatch();
    const designer = useDesigner();
    const showIdeaTab = useIdeaTabEnabled();
    const save = useStudio5Save({
        onSaveCallback: () => {
            dispatch(setIsProductSwitched(true));
        },
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_SWITCH_PRODUCTS,
        allowAnonymousUser: showIdeaTab,
        hideSaveToast: showIdeaTab
    });

    // This was pulled out for flexibility into useLoadNewProductForFlexibility
    // I'd like to consolidate the product switching further (if possible in studio5, its a mess)
    const switchProduct = useCallback(
        async ({
            productKey,
            locale,
            productVersion,
            quantity: qty,
            customerSelectedProductOptions,
            template: newtemplateToken,
            targetDocument,
            saveWork
        }: ProductOptions) => {
            if (!designer) {
                return;
            }

            const productConfigs = await getStudioConfigFromCalciferV2(
                productKey,
                undefined,
                productVersion,
                customerSelectedProductOptions,
                undefined,
                qty,
                locale,
                null,
                false,
                newtemplateToken
            );

            const {
                designViews,
                mpvId,
                customerSelectedOptions,
                selectedOptions,
                scenesConfiguration: easelScenesConfiguration,
                studioConfiguration,
                designs
            } = productConfigs;

            await switchContext({
                locale,
                productKey,
                productOptions: selectedOptions,
                designDocument: targetDocument,
                views: designViews.designViews,
                easelScenesConfiguration,
                studioConfiguration,
                template: newtemplateToken || undefined
            });

            dispatch(
                setDefaultState({
                    productKey,
                    productVersion,
                    customerSelectedProductOptions: customerSelectedOptions || {},
                    studioSelectedProductOptions: selectedOptions || {},
                    template: newtemplateToken || undefined,
                    studioConfiguration,
                    hasDesigns: designs > 0
                })
            );

            // @ts-ignore
            if (!saveWork || !getQueryParams().workId) {
                updateUrl({
                    key: productKey,
                    productVersion,
                    selectedOptions: JSON.stringify(selectedOptions),
                    mpvId,
                    template: newtemplateToken || undefined,
                    workId: undefined
                });
                dispatch(setIsProductSwitched(true));
            } else {
                save();
            }
        },
        [designer, dispatch, save]
    );

    const switchMSProduct = useCallback(
        async ({ productOptions, isMailingPostCard }: switchMSProps) => {
            const {
                productKey,
                locale,
                productVersion,
                quantity: qty,
                customerSelectedProductOptions
            } = productOptions;
            let ordinal;
            const designDocument = getDesignDocumentFromDesigner(false);
            const productConfigs = await getStudioConfigFromCalciferV2(
                productKey,
                undefined,
                productVersion,
                customerSelectedProductOptions,
                undefined,
                qty,
                locale,
                null,
                false,
                null
            );

            const {
                quantity,
                mpvId,
                designViews,
                customerSelectedOptions,
                selectedOptions,
                scenesConfiguration: easelScenesConfiguration,
                studioConfiguration,
                designs
            } = productConfigs;
            if (
                isMailingPostCard(productKey) &&
                designViews.designViews.length === 2 &&
                designViews.designViews[1].colorType &&
                designViews.designViews[1].colorType === "color"
            ) {
                ordinal = 2;
            }

            await switchContext(
                {
                    productKey,
                    productOptions: customerSelectedProductOptions,
                    designDocument,
                    views: designViews.designViews,
                    easelScenesConfiguration,
                    studioConfiguration
                },
                true,
                ordinal
            );

            updateUrl({
                key: productKey,
                productVersion,
                selectedOptions: JSON.stringify(selectedOptions),
                mpvId,
                workId: undefined
            });

            dispatch(
                setDefaultState({
                    productKey,
                    productVersion,
                    customerSelectedProductOptions: customerSelectedOptions || {},
                    studioSelectedProductOptions: selectedOptions || {},
                    quantity,
                    mpvId,
                    locale,
                    studioConfiguration,
                    hasDesigns: designs > 0
                })
            );
            dispatch(setIsProductSwitched(true));
            return { views: designViews.designViews, easelScenesConfiguration };
        },
        [dispatch]
    );

    const contextObject = useMemo(() => {
        return {
            switchMSProduct,
            switchProduct
        };
    }, [switchMSProduct, switchProduct]);

    return <Provider value={contextObject}>{children}</Provider>;
}

SwitchProductsProvider.displayName = "SwitchProductsProvider";
