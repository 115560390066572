import React from "react";
import { Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { RenameProjectIcon } from "@shared/features/StudioChrome";
import { messages } from "./messages";
import { Project } from "./types";
import * as styles from "./MyProjectsRenameButton.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    project: Project;
    onClick: () => void;
}

export function MyProjectsRenameButton(props: Props) {
    const { project, onClick, ...rest } = props;
    const { t } = useTranslationSSR();
    const projectName = project.workName;
    const title = t(messages.myProjectsRenameProject.id, { projectName });

    return (
        <Button
            className={styles.button}
            skin="unstyled"
            buttonShape="round"
            size="mini"
            onClick={onClick}
            title={title}
            aria-label={title}
            {...rest}
        >
            <RenameProjectIcon />
        </Button>
    );
}

MyProjectsRenameButton.displayName = "MyProjectsRenameButton";
