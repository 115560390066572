// This code has been generated via svgr
/* eslint-disable max-len */
import React from "react";

function UploadDeleteIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx="12" cy="12" r="12" fill="currentColor" />
            <path
                d="M13.7813 15.9997H10.2178C9.72147 15.9997 9.35938 15.6212 9.35938 15.2037V10.291H14.6397V15.2023C14.6397 15.6215 14.2773 15.9997 13.7813 15.9997Z"
                stroke="currentColor"
            />
            <path
                d="M13.3121 8.23248L13.1185 7.74077C13.0621 7.59573 12.922 7.5 12.7659 7.5H11.2341C11.078 7.5 10.9379 7.59573 10.8801 7.74077L10.6864 8.23248H8.25V8.98236H15.75V8.23248H13.3121Z"
                fill="currentColor"
            />
        </svg>
    );
}
UploadDeleteIcon.displayName = "UploadDeleteIcon";

export { UploadDeleteIcon };
