import React from "react";
import "./gridlines.scss";

export function GridOverlay() {
    return (
        <div className="grid-container">
            {/* Render the grid quadrants */}
            <div className="grid-quad a" />
            <div className="grid-quad b" />
            <div className="grid-quad c" />
            <div className="grid-quad d" />

            {/* Render the horizontal and vertical center dividers */}
            <div className="center-line horizontal" />
            <div className="center-line vertical" />
        </div>
    );
}
GridOverlay.displayName = "GridOverlay";
