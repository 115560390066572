import React, { useEffect } from "react";
import { SidebarPanelButtonProps, SidebarPanelButton } from "@designer-suite";
import { SidebarButtonIcon, SidebarButtonLabel } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";

export interface ISidebarPanelButtonItem extends SidebarPanelButtonProps {
    messageId: string;
    icon: React.ReactNode;
    renderEventDetail?: string;
    clickEventDetailType?: string;
}

export const SidebarPanelButtonItem = React.forwardRef<HTMLButtonElement, ISidebarPanelButtonItem>(
    ({ contentType, messageId, icon, className, renderEventDetail, clickEventDetailType, ...rest }, ref) => {
        const { t } = useTranslationSSR();

        useEffect(() => {
            if (renderEventDetail) {
                fireDesignToolTrackingEvent({
                    eventDetail: renderEventDetail,
                    label: `show sidebar ${clickEventDetailType || contentType} button`
                });
            }
        }, [contentType, renderEventDetail, clickEventDetailType]);

        return (
            <SidebarPanelButton
                ref={ref}
                contentType={contentType}
                className={className}
                clickEventDetailType={clickEventDetailType}
                {...rest}
            >
                <SidebarButtonIcon>{icon}</SidebarButtonIcon>
                <SidebarButtonLabel>{t(messageId)}</SidebarButtonLabel>
            </SidebarPanelButton>
        );
    }
);

SidebarPanelButtonItem.displayName = "SidebarPanelButtonItem";
