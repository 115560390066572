import { useMemo } from "react";
import { mapDesignAttributeToProductOption } from "@shared/utils/Product";
import { useAppSelector } from "@shared/redux";

export const substrateColorAttributeKey = "Substrate Color";

export const useHasSubstrateColor = () => {
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const compatibleOptions = useAppSelector(state => state.compatibleProductOptions);

    return useMemo(() => {
        // decide whether to show substrate color button based on compatible options
        // Will show when 'Substrate Color' option exists and has more than 1 choice
        const substrateColorDesignAttribute = mapDesignAttributeToProductOption(
            designAttributeMappings,
            substrateColorAttributeKey
        );
        const colorOption = compatibleOptions?.find((option: any) => option.name === substrateColorDesignAttribute);

        return colorOption?.values?.length && colorOption?.values?.length > 1;
    }, [designAttributeMappings, compatibleOptions]);
};
