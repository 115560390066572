/* eslint-disable max-len */
import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    className?: string;
}

export function ErrorIcon(props: Props) {
    return (
        <svg className="error_icon" viewBox="0 0 238.84 208.58" fill="none" {...props}>
            <polygon points="62.1 203.58 4.77 104.29 62.1 5 176.75 5 234.07 104.29 176.75 203.58 62.1 203.58"></polygon>
            <path
                fill="currentColor"
                d="M128.92,128.85v18.63h-19V128.85Zm-5.32-7.26H115l-4.6-37.27V61.09h17.91V84.32Z"
            ></path>
            <path
                fill="currentColor"
                d="M127,128.85v18.63l1.88-1.87h-19l1.88,1.88V128.85l-1.87,1.88h19a1.88,1.88,0,0,0,0-3.75h-19a1.9,1.9,0,0,     0-1.87,1.88v18.63a1.9,1.9,0,0,0,1.88,1.88h19a1.9,1.9,0,0,0,1.88-1.87V128.85A1.88,1.88,0,0,0,127,128.85Z"
            ></path>
            <path
                fill="currentColor"
                d="M123.6,119.72H115l1.88,1.88L112.73,88c-1.09-8.86-.45-18-.45-26.86L110.41,63h17.91l-1.87-1.87c0,8.91.66,18-.46,26.86l-4.26,     33.64c-.3,2.39,3.45,2.37,3.75,0L129.73,88c1.12-8.85.46-18,.46-26.86a1.9,1.9,0,0,0-1.87-1.87H110.41a1.9,1.9,0,0,0-1.87,1.88c0,8.91-.65,18,     .45,26.86l4.15,33.64a1.92,1.92,0,0,0,1.88,1.88h8.59A1.88,1.88,0,0,0,123.6,119.72Z"
            ></path>
            <path
                fill="currentColor"
                d="M57.78,2.48,6.94,90.54c-2.1,3.64-4.78,7.32-6.46,11.19-1.82,4.2,2,8.59,4,12.12l29.33,50.8L57.78,206.1a5,5,0,     0,0,4.32,2.48H176.75a5,5,0,0,0,4.32-2.48L231.91,118c2.1-3.64,4.78-7.32,6.46-11.19,1.82-4.2-2-8.59-4-12.12L205,43.93,     181.06,2.48A5,5,0,0,0,176.75,0H62.1c-6.43,0-6.45,10,0,10H176.75l-4.32-2.48L222.6,94.41l7.16,12.4v-5l-50.17,86.89-7.16,     12.4,4.32-2.48H62.1l4.32,2.48L16.25,114.16l-7.16-12.4v5L59.26,19.92l7.16-12.4C69.64,1.94,61-3.1,57.78,2.48Z"
            ></path>
        </svg>
    );
}

ErrorIcon.displayName = "ErrorIcon";
