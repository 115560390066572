import React from "react";
import classNames from "classnames";
import type { Page } from "../../../@types/page";
import type { Upload } from "../../../@types/upload";
import { UploadThumbnail } from "./UploadThumbnail";
import { UploadUsage } from "../useDocumentUploads";
import "./uploadsPanel.scss";
import { PlacementStrategyCallback } from "../UploadStrategies";

interface Props {
    onUploadClicked?: PlacementStrategyCallback;
    onUploadDoubleClicked?: PlacementStrategyCallback;
    upload: Upload;
    className?: string;
    isPageOnDocument: (page: Page) => UploadUsage;
    shouldPagesBeShown: (pages: Page[]) => boolean;
    allowHiding: boolean;
    onImageLoaded?: () => void;
}

function UploadMultipageBox(props: Props) {
    const {
        onUploadClicked,
        onUploadDoubleClicked,
        upload,
        className,
        isPageOnDocument,
        shouldPagesBeShown,
        allowHiding
    } = props;
    const pages = upload.get("pages");

    return (
        <div className={classNames("easel-thumbnail-box dcl-pdf-thumb__box dcl-pdf-thumb__box--with-arrow", className)}>
            <div className="dcl-pdf-thumb__page-wrapper">
                {pages.map((page: Page) => {
                    if (shouldPagesBeShown([page]) || !allowHiding) {
                        return (
                            <UploadThumbnail
                                key={page.cid}
                                upload={upload}
                                page={page}
                                onClick={onUploadClicked}
                                onDoubleClick={onUploadDoubleClicked}
                                uploadUsage={isPageOnDocument(page)}
                                isMultiPage={true}
                                onImageLoaded={props.onImageLoaded}
                            />
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
}

UploadMultipageBox.displayName = "UploadMultipageBox";

export { UploadMultipageBox };
