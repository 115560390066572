import { Typography, Hidden } from "@vp/swan";
import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { ExpandingButton } from "@shared/features/StudioChrome";
import type { ExpandingButtonHandles } from "@shared/features/StudioChrome";
import { ProjectNameDropdown } from "./ProjectNameDropdown";
import "./projectNameButton.scss";

interface Props {
    className?: string;
    setFocus?: (ref: HTMLButtonElement) => void;
}

export function ProjectNameButton({ className }: Props) {
    const workName = useAppSelector(state => state.workName);
    const productName = useAppSelector(state => state.productName);

    const nameToShow = workName || productName;
    const buttonRef = useRef<ExpandingButtonHandles>(null);

    const { isTransitionComplete, isEventActive } = useAppSelector(state => state.trackModalTransitionEvent);

    const buttonCallback = useCallback(
        btnRef => {
            const timer: NodeJS.Timeout = setTimeout(() => {
                if (isEventActive && isTransitionComplete && btnRef) {
                    btnRef.focus();
                }
                clearTimeout(timer);
            }, 500); // time is set twice the modal close animation duration
        },
        [isEventActive, isTransitionComplete]
    );

    return (
        <ExpandingButton
            className={classNames("project-name-button", className)}
            ref={buttonRef}
            title={nameToShow}
            content={<ProjectNameDropdown onSave={() => buttonRef.current?.close()} />}
            showArrow={true}
            displayType="multirow"
            overridePopperAlignment="start"
            onOpen={() => {
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_OPEN_PROJECT_DROPDOWN,
                    label: "Open Project Dropdown",
                    pageNameBase: undefined,
                    extraData: undefined
                });
            }}
            buttonCallback={buttonCallback}
        >
            <Hidden sm className="project-name-button__dash">
                <Typography fontSize="1">–</Typography>
            </Hidden>
            <Typography fontSize="1" className="project-name-button__workname">
                {nameToShow}
            </Typography>
        </ExpandingButton>
    );
}

ProjectNameButton.displayName = "ProjectNameButton";
