import { useEffect, useState } from "react";
import { wordartHasSpotColor } from "@utilities";
import { PremiumFinishSpotColors } from "@shared/features/PremiumFinish";

/**
 * A hook that provides information about premium finishes on WordArt items
 * @returns boolean - true if the item has a premium finish applied
 */
export function usePremiumFinishWordArt(item: WordArtItem) {
    const [premiumFinish, setPremiumFinish] = useState(wordartHasSpotColor([item]));

    useEffect(() => {
        const changePremiumFinish = (_newModel: undefined, changes: { overprints: string[] }) => {
            /*
            Changes come as example:
            {
                fontFamily: "GoldenStateJF",
                fontStyle: "Normal,Normal",
                content: "H H H",
                color: "rgb(#EB3300)",
                focus: "center",
                curve: {height: 0.2681159, angle: -125, radius: 1.34058},
                overprints: ["spot(RaisedInk)"]
            }
            */
            // eslint-disable-next-line no-useless-escape
            const spotRegex = /^spot\(([^\)]+)\)$/;

            let premiumOption = null;

            // currently, only one item in the overprints array is supported
            if (changes.overprints && changes.overprints[0]) {
                // Assume changes.overprints[0] is a string.
                const match = changes.overprints[0].match(spotRegex);
                premiumOption = match && match[1];
            }

            if (premiumOption) {
                setPremiumFinish(PremiumFinishSpotColors[premiumOption] !== null);
            } else {
                setPremiumFinish(false);
            }
        };

        changePremiumFinish(undefined, item._itemViewModel.model.get("data"));
        item._itemViewModel.model.on("change:data", changePremiumFinish);
        return () => {
            item._itemViewModel.model.off("change:data", changePremiumFinish);
        };
        // only re-run the effect if the item has changed, not the item object itself,
        // since the designer API creates a new object each time
    }, [item._itemViewModel.model]);

    return premiumFinish;
}
