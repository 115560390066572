import { useState, useEffect } from "react";
import { useActiveCanvas } from "@designer-suite";
import { PremiumFinishSpotColors } from "@shared/features/PremiumFinish";
import { itemHasPremiumFinishSpotColor } from "../util";
import { getPremiumFinishes } from "../../utilities/utils-premium-finishes";

export function usePremiumFinishText(item: TextItem) {
    const [premiumFinish, setPremiumFinish] = useState<boolean>(itemHasPremiumFinishSpotColor(item));
    const canvas = useActiveCanvas();

    useEffect(() => {
        if (!canvas) {
            return () => null;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const changePremiumFinish = (_newModel: any, changes: any) => {
            const newPossibleFinish = changes[0] && (changes[0].color || changes[0]);
            // change may be attributes, so [{color: "", etc}]
            // or changes may come as example ["spot(RaisedInk)"]
            if (newPossibleFinish && newPossibleFinish.indexOf("spot") > -1) {
                const premiumOption = newPossibleFinish.replace("spot(", "").replace(")", "");
                setPremiumFinish(PremiumFinishSpotColors[premiumOption] !== null);
            } else {
                setPremiumFinish(false);
            }
        };

        changePremiumFinish(undefined, item._itemViewModel.model.get("overprints"));
        const supportedFinishes = getPremiumFinishes(canvas);
        const isRaisedFoil = supportedFinishes.some(finish => finish.color.includes("RaisedFoil"));
        const listenEvents = isRaisedFoil ? "change:content change:overprints" : "change:overprints";

        item._itemViewModel.model.on(listenEvents, changePremiumFinish);
        return () => {
            item._itemViewModel.model.off(listenEvents, changePremiumFinish);
        };
        // only re-run the effect if the item has changed, not the item object itself, since the designer API creates a new object each time
    }, [item._itemViewModel.model, canvas]);

    return premiumFinish;
}
