import { usePageContext } from "@shared/features/StudioConfiguration";
import { FlexBox, Link } from "@vp/swan";
import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { messages } from "@five/components/Redesign/GlobalToolbar/MiniLogo";
import * as styles from "./Logo.module.scss";

// This replaces MiniLogo.tsx
// Before rolling out followup with Sonar on the correct way to get this logo rather than the hardcoded link
// https://vistaprint.slack.com/archives/CHWCBMDU2/p1675967210013989
export const Logo = () => {
    const { bookendsHeader }: any = usePageContext();
    const { t } = useTranslationSSR();
    const linkAriaLabel = t(messages.headerIcon.id);

    return (
        <FlexBox className={styles.logo} alignItems="center" justifyContent="center">
            <Link skin="unstyled" href={bookendsHeader.url} aria-label={linkAriaLabel}>
                {/* TODO translate alt */}
                <img
                    src="https://cms.cloudinary.vpsvc.com/image/upload/page_icons/em_vistaprint_mark_color.svg"
                    alt="logo"
                />
            </Link>
        </FlexBox>
    );
};

Logo.displayName = "Logo";
