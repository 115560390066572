import React, { useRef, useState } from "react";
import { Sheet, useClickOutside } from "@shared/features/StudioChrome";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { SheetPanelWrapper } from "@shared/features/GlobalHeader";
import { MobileSidebarButton } from "../Sidebar";
import { useMobileSidebarOptions } from "../../hooks/useMobileSidebarOptions";
import "./MobileMoreOptionsSheet.scss";

export function MobileMoreOptionsSheet() {
    const { moreOptions = [] } = useMobileSidebarOptions();
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const [sheetHeight, setSheetHeight] = useState(0);
    const sheetRef = useRef<HTMLDivElement>(null);

    useClickOutside(
        sheetRef,
        async () => {
            if (sheetHeight) {
                setCurrentActiveDialog(DialogType.None);
            }
        },
        [sheetHeight]
    );

    const reportSizeChanged = (rect: DOMRect) => {
        if (rect) {
            setSheetHeight(rect.height);
        }
    };

    return (
        <div className="more-options-sheet-panel" ref={sheetRef}>
            <Sheet
                limitHeight={false}
                reportSizeChange={reportSizeChanged}
                hideSheet={currentActiveDialog !== DialogType.MobileMore}
            >
                <SheetPanelWrapper contentType={DialogType.MobileMore} hidebackButton>
                    {moreOptions.map((option, index) => {
                        return (
                            <>
                                <MobileSidebarButton key={index} {...option}>
                                    <option.icon aria-hidden="true" />
                                    <label>{option.label}</label>
                                </MobileSidebarButton>
                                <hr className="swan-divider" />
                            </>
                        );
                    })}
                </SheetPanelWrapper>
            </Sheet>
        </div>
    );
}

MobileMoreOptionsSheet.displayName = "MobileMoreOptionsSheet";
