// Safari does not allow autofocus to open keyboards, so we must use this workaround
// https://stackoverflow.com/questions/12204571/mobile-safari-javascript-focus-method-on-inputfield-only-works-with-click/55652503#55652503
// create invisible dummy input to receive the focus first
export function openMobileKeyboard(selector: string) {
    const fakeInput = document.createElement("input");
    fakeInput.setAttribute("type", "text");
    fakeInput.style.position = "absolute";
    fakeInput.style.opacity = "0";
    fakeInput.style.height = "0";
    fakeInput.style.fontSize = "16px"; // disable auto zoom

    // you may need to append to another element depending on the browser's auto
    // zoom/scroll behavior
    document.body.prepend(fakeInput);

    // focus so that subsequent async focus will work
    fakeInput.focus();

    setTimeout(() => {
        const elementToFocus = document.querySelector<HTMLElement>(selector);
        elementToFocus?.click();
        elementToFocus && moveCursorToEndOfInput(elementToFocus);

        // cleanup
        fakeInput.remove();
    }, 200);
}

function moveCursorToEndOfInput(element: HTMLElement) {
    // wordart input is a textarea
    const textAreaElements = element?.getElementsByTagName("textarea");
    if (textAreaElements?.length) {
        const textArea = textAreaElements.item(0) as HTMLTextAreaElement;
        textArea.setSelectionRange(textArea.value.length, textArea.value.length);
    }

    // text input is a contenteditable div
    const editableContent = element?.querySelector<HTMLElement>("[contenteditable=true]");
    if (editableContent) {
        setEndOfContenteditable(editableContent);
    }
}

// Move the cursor to the end of the contenteditable element
// https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity
function setEndOfContenteditable(contentEditableElement: HTMLElement) {
    const range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    const selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection?.removeAllRanges(); // remove any selections already made
    selection?.addRange(range); // make the range you have just created the visible selection
}

export function blurInput(selector: string) {
    const elementToBlur = document.querySelector<HTMLElement>(selector);
    elementToBlur?.blur();
}
