import { useActiveCanvas, useDesigner } from "@designer-suite";
import { NO_COLOR_VALUE } from "@shared/features/ColorPicker";
import { useEffect, useState } from "react";
import { useAppSelector } from "@shared/redux";
import { useToolFlags } from "@shared/features/StudioConfiguration";
import { useProcessType } from "@utilities";

// What percent of an item is of total canvas surface area counts as blocking
const BLOCKING_THRESHOLD = 0.95;

function checkIfItemsBlockBackground(items: CanvasItem[], canvasWidth: number, canvasHeight: number) {
    let itemBlockingBackground = false;
    let i = 0;
    while (!itemBlockingBackground && i < items.length) {
        const item = items[i];
        // Only check images and non-transparent shapes
        if (
            item.itemType === "IMAGE" ||
            (item.itemType === "SHAPE" &&
                item._itemViewModel.model.get("fillColor") !== NO_COLOR_VALUE &&
                item._itemViewModel.model.get("isBackground") !== true)
        ) {
            const itemWidthOnCanvas =
                Math.min(item.pxDimensions.width + item.pxPosition.left, canvasWidth) -
                Math.max(item.pxPosition.left, 0);
            const itemHeightOnCanvas =
                Math.min(item.pxDimensions.height + item.pxPosition.top, canvasHeight) -
                Math.max(item.pxPosition.top, 0);

            const itemSurfaceArea = itemWidthOnCanvas * itemHeightOnCanvas;
            const canvasSurfaceArea = canvasWidth * canvasHeight;

            itemBlockingBackground = itemSurfaceArea / canvasSurfaceArea > BLOCKING_THRESHOLD;
        }
        i += 1;
    }
    return itemBlockingBackground;
}

export function useEnableBackgroundColor() {
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const designer = useDesigner();
    const [backgroundColorEnabled, setBackgroundColorEnabled] = useState(false);
    const activeCanvas = useActiveCanvas();
    const processType = useProcessType();
    const { shouldShowBackgroundColor } = useToolFlags(processType);

    // Check if current active canvas has items blocking background
    useEffect(() => {
        if (!easelLoaded || !activeCanvas) {
            return;
        }
        if (!shouldShowBackgroundColor) {
            setBackgroundColorEnabled(false);
            return;
        }

        const itemBlockingBackground = checkIfItemsBlockBackground(
            activeCanvas.items,
            activeCanvas.pxDimensions.width,
            activeCanvas.pxDimensions.height
        );
        setBackgroundColorEnabled(!itemBlockingBackground);
    }, [activeCanvas, easelLoaded, shouldShowBackgroundColor]);

    // Listen for items added, removed, or changed on canvas
    useEffect(() => {
        if (!designer || !easelLoaded || !activeCanvas) return;
        if (!shouldShowBackgroundColor) {
            setBackgroundColorEnabled(false);
            return;
        }

        const checkItems = (event: EventData) => {
            // Only check if any items that were added, changed, or removed are of type image or non-transparent shape
            if (
                event.items.some(item => {
                    return item.itemType === "IMAGE" || item.itemType === "SHAPE";
                })
            ) {
                const itemBlockingBackground = checkIfItemsBlockBackground(
                    // Have to check all items everytime in case there's another item that's blocking canvas
                    activeCanvas.items,
                    activeCanvas.pxDimensions.width,
                    activeCanvas.pxDimensions.height
                );
                setBackgroundColorEnabled(!itemBlockingBackground);
            }
        };

        const unsubscribeUpdate = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_CHANGED,
            checkItems
        );
        const unsubscribeDelete = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_DELETED,
            checkItems
        );
        // eslint-disable-next-line consistent-return
        return () => {
            unsubscribeUpdate();
            unsubscribeDelete();
        };
    }, [activeCanvas, designer, easelLoaded, shouldShowBackgroundColor]);

    return backgroundColorEnabled;
}
