import React from "react";
import ReactDOM from "react-dom";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Button, Icon, FlexBox, Carousel, CarouselSlide, GridContainer, Row, Column } from "@vp/swan";
import { useDesigner } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector, useAppDispatch, setShowTeamsGuideModal } from "@shared/redux";
import {
    TeamsGuideIconStep1,
    TeamsGuideIconStep2,
    TeamsGuideIconStep3,
    TeamsGuideLabelStep1,
    TeamsGuideLabelStep2,
    TeamsGuideLabelStep3,
    TeamsGuideLine
} from "./Icons";
import "./TeamsGuideModal.scss";

const messages = defineMessages({
    teamsPlaceholderGuideModalTitle: {
        id: "studio.components.panels.groupsAndTeamsPanel.guideModalTitle",
        defaultMessage: "Design once and personalize for the entire team."
    },
    teamsPlaceholderGuideModalBodyStep1: {
        id: "studio.components.panels.groupsAndTeamsPanel.guideModalBodyStep1",
        defaultMessage: "Decide where you want to position the placeholders."
    },
    teamsPlaceholderGuideModalBodyStep2: {
        id: "studio.components.panels.groupsAndTeamsPanel.guideModalBodyStep2",
        defaultMessage: "On the next page, provide the details of each member of your team."
    },
    teamsPlaceholderGuideModalBodyStep3: {
        id: "studio.components.panels.groupsAndTeamsPanel.guideModalBodyStep3",
        defaultMessage: "Review every personalized item before adding to cart."
    },
    teamsPlaceholderGuideModalStepNumber: {
        id: "studio.components.panels.groupsAndTeamsPanel.GuideModalStepNumber",
        defaultMessage: "Step {stepNumber} of {totalSteps}"
    },
    teamsPlaceholderGuideButtonTitle: {
        id: "studio.components.panels.groupsAndTeamsPanel.teamsPlaceholdersGuideButtonTitle",
        defaultMessage: "Get started",
        description: {
            note: "Appears on guide modal, Get started button will close the guide modal"
        }
    }
});

const TeamsDesktopGuideDialog = () => {
    const { t } = useTranslationSSR();
    return (
        <GridContainer pt={3}>
            <FlexBox justifyContent="center">
                <TeamsGuideLabelStep1 width="6%" />
                <TeamsGuideLine width="calc(100% / 3 - 6%)" />
                <TeamsGuideLabelStep2 width="6%" />
                <TeamsGuideLine width="calc(100% / 3 - 6%)" />
                <TeamsGuideLabelStep3 width="6%" />
            </FlexBox>
            <Row py={4}>
                <Column span={4}>
                    <TeamsGuideIconStep1 height="20vh" />
                </Column>
                <Column span={4}>
                    <TeamsGuideIconStep2 height="20vh" />
                </Column>
                <Column span={4}>
                    <TeamsGuideIconStep3 height="20vh" />
                </Column>
            </Row>
            <Row>
                <Column span={4}>
                    <Typography>{t(messages.teamsPlaceholderGuideModalBodyStep1.id)}</Typography>
                </Column>
                <Column span={4}>
                    <Typography>{t(messages.teamsPlaceholderGuideModalBodyStep2.id)}</Typography>
                </Column>
                <Column span={4}>
                    <Typography>{t(messages.teamsPlaceholderGuideModalBodyStep3.id)}</Typography>
                </Column>
            </Row>
        </GridContainer>
    );
};

const TeamsMobileGuideDialog = () => {
    const { t } = useTranslationSSR();
    const getStepLabelWithTotal = (stepNumber: number, totalSteps: number): string => {
        return `${t(messages.teamsPlaceholderGuideModalStepNumber.id)
            .replace("{stepNumber}", String(stepNumber))
            .replace("{totalSteps}", String(totalSteps))}`;
    };
    return (
        <Carousel
            slidesToShow={1}
            progressIndicator="dots"
            // TODO-atom
            accessibleTextForPrevious={""}
            accessibleTextForNext={""}
            accessibleTextForDots={[]}
        >
            <CarouselSlide>
                <TeamsGuideIconStep1 height="30vh" />
                <Typography fontSize="1" textColor="dark-grey" py={2}>
                    {getStepLabelWithTotal(1, 3)}
                </Typography>
                <Typography>{t(messages.teamsPlaceholderGuideModalBodyStep1.id)}</Typography>
            </CarouselSlide>
            <CarouselSlide>
                <TeamsGuideIconStep2 height="30vh" />
                <Typography fontSize="1" textColor="dark-grey" py={2}>
                    {getStepLabelWithTotal(2, 3)}
                </Typography>
                <Typography>{t(messages.teamsPlaceholderGuideModalBodyStep2.id)}</Typography>
            </CarouselSlide>
            <CarouselSlide>
                <TeamsGuideIconStep3 height="30vh" />
                <Typography fontSize="1" textColor="dark-grey" py={2}>
                    {getStepLabelWithTotal(3, 3)}
                </Typography>
                <Typography>{t(messages.teamsPlaceholderGuideModalBodyStep3.id)}</Typography>
            </CarouselSlide>
        </Carousel>
    );
};

export function TeamsGuideModal() {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const showTeamsGuideModal = useAppSelector(state => state.showTeamsGuideModal);

    const TeamsGuideModalClose = () => {
        dispatch(setShowTeamsGuideModal(false));
    };

    if (!designer) {
        return null;
    }

    const handleElement = document.getElementById(isSmall ? "mobile-teams-guide-modal" : "teams-guide-modal");

    if (!(showTeamsGuideModal && handleElement)) {
        return null;
    }

    return (
        <>
            {ReactDOM.createPortal(
                <>
                    <div className="teams-guide-modal-overlay" />
                    <div className="teams-guide-modal">
                        <div className="teams-guide-modal__close">
                            <Button onClick={TeamsGuideModalClose} skin="unstyled">
                                <Icon skin="standard" iconType="close" />
                            </Button>
                        </div>
                        <div className="teams-guide-modal__content">
                            <Typography fontSize="4" pb={5} px={5}>
                                {t(messages.teamsPlaceholderGuideModalTitle.id)}
                            </Typography>
                            {isSmall ? <TeamsMobileGuideDialog /> : <TeamsDesktopGuideDialog />}
                        </div>
                        <div className="teams-guide-modal__button">
                            <Button skin="primary" size="mini" onClick={TeamsGuideModalClose}>
                                {t(messages.teamsPlaceholderGuideButtonTitle.id)}
                            </Button>
                        </div>
                    </div>
                </>,
                handleElement
            )}
        </>
    );
}

TeamsDesktopGuideDialog.displayName = "TeamsDesktopGuideDialog";
TeamsMobileGuideDialog.displayName = "TeamsMobileGuideDialog";
TeamsGuideModal.displayName = "TeamsGuideModal";
