import { useCallback } from "react";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { useUploadSuccessCallback } from "@design-stack-vista/upload-components";
import { setAlerts, useAppDispatch, useAppSelector } from "@shared/redux";
import { onUploadErrorEvent } from "@shared/features/UploadsAndAssets";
import { useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { PlacementStrategyCallback, useActiveCanvas, useDesigner } from "../../../../../easel";
import { addImageCallToAction } from "./utils";

export const useUploadToDesignerSuccessCallback = (
    successCallback?: PlacementStrategyCallback,
    failureCallback?: () => void
) => {
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const canvas = useActiveCanvas();
    const isUploadModalAutoLoaded = useAppSelector(state => state.isUploadModalAutoLoaded);

    const addToDesignerCallback = useCallback(
        async (asset: VistaAsset) => {
            if (!successCallback) {
                return;
            }
            try {
                await addImageCallToAction({
                    designer,
                    asset,
                    pageNumber: 1,
                    callback: successCallback
                });

                // Fire tracking event for when upload is complete from an autoloaded modal
                // and added to the canvas
                isUploadModalAutoLoaded &&
                    canvas &&
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.UPLOAD_COMPLETE.replace("[CanvasSide]", canvas.name),
                        label: "General"
                    } as any);
            } catch (e) {
                // mimic the pre-upload-components behavior with designer
                dispatch(
                    setAlerts({
                        alerts: [
                            {
                                key: `UploadFailed`,
                                skin: "error",
                                message: t("easel.designer.translation.errors.general")
                            }
                        ]
                    })
                );
                onUploadErrorEvent(asset, e.message);
                failureCallback?.();
            }
        },
        [successCallback, failureCallback, designer, dispatch, t, canvas, isUploadModalAutoLoaded]
    );

    useUploadSuccessCallback(successCallback ? addToDesignerCallback : undefined);
};
