import type { Panel } from "@design-stack-ct/cdif-types";
import type { DSS } from "@vp/types-ddif";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";

interface PreservedTextPurpose {
    panelId: string;
    purpose: string;
    value: string;
}

const preservedTextPurposes: PreservedTextPurpose[] = [];

export function extractEditedTextPlaceholders(designDocument: DSS.DesignDocument) {
    const flattenedTextFields = designDocument.document.panels.reduce((allTextFields, panel) => {
        const panelTextFields = panel.textAreas?.map(text => {
            return {
                panelId: panel.id,
                itemId: text.id,
                // this is a bit simplistic.  Complicated fields (like bullets/lists) won't be copied
                // I doubt those fields are placeholders or have purposes so I don't think its an issue
                // @ts-ignore
                textContent: text.content.reduce((acc, content) => {
                    if (typeof content?.content === "string") {
                        return acc + content.content;
                    }
                    return acc;
                }, "")
            };
        });
        const panelWordartFields: any = panel.itemReferences
            ?.filter(itemReference => itemReference.type === ItemReferenceTypes.WORD_ART)
            .map(itemReference => {
                return {
                    panelId: panel.id,
                    itemId: itemReference.id,
                    // @ts-ignore
                    textContent: itemReference.data?.content
                };
            });
        return [...allTextFields, ...(panelTextFields || []), ...(panelWordartFields || [])];
    }, []);
    designDocument.metadata?.template?.forEach(templateMetadata => {
        const item = flattenedTextFields.find(tf => tf.itemId === templateMetadata.id);
        if (item && item.textContent && item.textContent?.length > 0) {
            // @ts-ignore
            const itemPurpose: string = templateMetadata.purpose;
            const existingItem = preservedTextPurposes.find(
                ptp => ptp.panelId === item.panelId && ptp.purpose === itemPurpose
            );
            // Only do this when the text field actually is a placeholder
            if (templateMetadata.placeholder) {
                if (existingItem) {
                    existingItem.value = item.textContent;
                } else {
                    preservedTextPurposes.push({
                        panelId: item.panelId,
                        purpose: itemPurpose,
                        value: item.textContent
                    });
                }
            }
        }
    });
}

function getTextContent(item: any) {
    if (item.content && item.content.length === 1) {
        // @ts-ignore
        return item.content[0];
    }
    if (item.textFields && item.textFields.length === 1) {
        // @ts-ignore
        return item.textFields[0];
    }
    if (item.type === ItemReferenceTypes.WORD_ART) {
        // @ts-ignore
        return item.data;
    }
    return undefined;
}

function replaceContentWhenEmpty(item: any, panel: Panel, designDocument: DSS.DesignDocument) {
    const textContent = getTextContent(item);
    if (textContent?.content === "") {
        const itemTemplateMetadata = designDocument.metadata?.template?.find(temp => temp.id === item.id);
        // @ts-ignore
        const templatePurpose = itemTemplateMetadata?.purpose;
        // Only do this when the text field actually is a placeholder
        if (templatePurpose && itemTemplateMetadata?.placeholder) {
            const previousTextContent = preservedTextPurposes.find(
                prev => prev.panelId === panel.id && prev.purpose === templatePurpose
            );
            if (previousTextContent) {
                textContent.content = previousTextContent.value;
            }
        }
    }
}

export function hydratePreviouslyEditedTextPlaceholders(designDocument: DSS.DesignDocument) {
    designDocument.document.panels.forEach(panel => {
        panel.textAreas?.forEach(textArea => replaceContentWhenEmpty(textArea, panel, designDocument));
        panel.itemReferences?.forEach(itemReference => replaceContentWhenEmpty(itemReference, panel, designDocument));
    });
}
