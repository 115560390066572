import React, { useState } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Button } from "@vp/swan";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { usePopper } from "react-popper";
import { useAppSelector } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";
import { SaveStatusTooltip } from "./SaveStatusTooltip";
import * as styles from "./SaveStatusButton.module.scss";

export const messages = defineMessages({
    allChangesSaved: {
        id: "studio.components.globalToolbar.allChangesSaved",
        defaultMessage: "All changes saved",
        description: {
            note: "Text for save button after clicking save"
        }
    },
    saveChanges: {
        id: "studio.components.globalToolbar.saveChanges",
        defaultMessage: "Save changes",
        description: {
            note: "Text for save button when there are changes to be saved"
        }
    },
    saving: {
        id: "studio.components.globalToolbar.saving",
        defaultMessage: "Saving...",
        description: {
            note: "Text for save button while saving"
        }
    }
});

export interface SaveStatusButtonProps {
    onSaveClick: () => void;
}

export function SaveStatusButton({ onSaveClick }: SaveStatusButtonProps) {
    const saveStatus = useAppSelector(state => state.saveStatus);

    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const popper = usePopper(referenceElement, popperElement, {
        placement: "bottom-start"
    });
    const [tooltipVisible, setTooltipVisible] = useState(false);

    function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Escape") {
            setTooltipVisible(false);
        }
    }

    return (
        <>
            <div
                className={styles.saveStatusButtonContainer}
                ref={setReferenceElement}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
                onFocus={() => setTooltipVisible(true)}
                onBlur={() => setTooltipVisible(false)}
                onKeyDown={onKeyDown}
                aria-describedby="save-changes-tooltip"
                role="button"
                tabIndex={0}
                aria-expanded={tooltipVisible}
                aria-controls="save-changes-tooltip"
            >
                <CSSTransition
                    in={saveStatus === SAVE_STATUS.CAN_BE_SAVED || saveStatus === SAVE_STATUS.NO_CHANGES}
                    timeout={500}
                    classNames={styles.saveStatusButton}
                    unmountOnExit
                >
                    <SaveStatusButtonInstance
                        className={styles.canBeSaved}
                        messageId={messages.saveChanges.id}
                        onSaveClick={onSaveClick}
                        aria-describedby="save-changes-tooltip"
                    />
                </CSSTransition>
                <CSSTransition
                    in={saveStatus === SAVE_STATUS.CURRENTLY_SAVING}
                    timeout={200}
                    classNames={styles.saveStatusButton}
                    unmountOnExit
                >
                    <SaveStatusButtonInstance
                        className={styles.currentlySaving}
                        messageId={messages.saving.id}
                        aria-describedby="save-changes-tooltip"
                    />
                </CSSTransition>
                <CSSTransition
                    in={saveStatus === SAVE_STATUS.CHANGES_SAVED}
                    timeout={200}
                    classNames={styles.saveStatusButton}
                    unmountOnExit
                >
                    <SaveStatusButtonInstance
                        className={styles.changesSaved}
                        messageId={messages.allChangesSaved.id}
                        aria-describedby="save-changes-tooltip"
                    />
                </CSSTransition>
                <CSSTransition
                    in={saveStatus === SAVE_STATUS.LOADING}
                    timeout={200}
                    classNames={styles.saveStatusButton}
                    unmountOnExit
                >
                    <SaveStatusButtonInstance
                        className={styles.loading}
                        messageId={messages.allChangesSaved.id}
                        aria-describedby="save-changes-tooltip"
                    />
                </CSSTransition>
            </div>
            <SaveStatusTooltip
                popperAttributes={popper.attributes.popper}
                popperStyles={popper.styles.popper}
                ref={setPopperElement}
                isVisible={tooltipVisible}
            />
        </>
    );
}

SaveStatusButton.displayName = "SaveStatusButton";

interface SaveStatusButtonInstanceProps {
    className: string;
    messageId: string;
    onSaveClick?: () => void;
}

function SaveStatusButtonInstance({ className, messageId, onSaveClick }: SaveStatusButtonInstanceProps) {
    const { t } = useTranslationSSR();

    return (
        <Button
            size={"standard"}
            component={onSaveClick ? "button" : "span"}
            aria-disabled={!onSaveClick}
            onClick={onSaveClick}
            tabIndex={0}
            className={classNames(styles.saveStatusButton, className)}
        >
            <div className={styles.swatch} />
            <Typography
                fontSize="-1"
                className={styles.label}
                data-translationid={messageId}
                data-testid="saveButtonPulldown"
            >
                {t(messageId)}
            </Typography>
        </Button>
    );
}

SaveStatusButtonInstance.displayName = "SaveStatusButtonInstance";
