import { useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { PremiumFinishSpotColors } from "@shared/features/PremiumFinish";
import { useCompatibleFlexibilityDesignAttributes } from "../common/hooks/useCompatibleFlexibilityDesignAttributes";
import { FlexibilityDesignAttributes } from "../constants";

export const usePremiumFinishCompatibleFlexibilityDesignAttributes = () => {
    const designAttributes = useCompatibleFlexibilityDesignAttributes(FlexibilityDesignAttributes.PremiumFinish);
    const templateAvailableFinishes = useAppSelector(state => state.secondaryConfig)?.templateAvailableFinishes;
    const availableDesignerAttributes = useMemo(() => {
        if (designAttributes) {
            const filteredDesignerAttributes = designAttributes.filter(option =>
                option !== PremiumFinishSpotColors.None
                    ? ((templateAvailableFinishes as string[]) ?? []).includes(option)
                    : true
            );
            const noneIndex = filteredDesignerAttributes.findIndex(option => option === PremiumFinishSpotColors.None);
            if (noneIndex !== -1) {
                filteredDesignerAttributes.splice(noneIndex, 1);
                filteredDesignerAttributes.push(PremiumFinishSpotColors.None);
            }
            return filteredDesignerAttributes;
        }
        return [];
    }, [designAttributes, templateAvailableFinishes]);

    return availableDesignerAttributes;
};
