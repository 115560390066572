import { switchContext } from "@easel";
import { newRelicWrapper, handleError, ERROR_CODES } from "@shared/utils/Errors";
import {
    fireGenericTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import {
    prepareStudioCimDoc,
    initializeStudioConfigV2,
    getCimdocMetadata,
    getSecondaryCalciferData
} from "@shared/features/StudioBootstrap";
import { buildHistoryUrl } from "@shared/utils/WebBrowser";
import { WorkEntity } from "@shared/utils/Work";
import { Identity } from "@shared/utils/Identity";
import { Store, AppDispatch, easelFinishedLoading, setSwitchingProject, setDefaultState } from "@shared/redux";
import { isSingleColorCanvas } from "@utilities";

export async function loadProjectInStudio({
    project,
    auth,
    identity,
    locale,
    dispatch,
    loadingMessage,
    isColorMatchingBacksideEnabled,
    useDraggablePlaceholders
}: {
    project: WorkEntity;
    auth: string;
    identity: Identity;
    locale: string;
    dispatch: AppDispatch;
    loadingMessage: string;
    isColorMatchingBacksideEnabled: boolean;
    useDraggablePlaceholders: boolean;
}) {
    let success = false;
    let error = {};

    const { workId } = project;
    try {
        const startTime = performance.now();
        window.history.pushState("update-workId", "Title", buildHistoryUrl({ workId }));

        const studioConfig = await initializeStudioConfigV2({ project, auth, identity, locale, dispatch }, true);

        if (studioConfig) {
            dispatch(setSwitchingProject({ switching: true, loadingMessage }));

            const { initialState } = studioConfig;
            const {
                productKey,
                productVersion,
                studioSelectedProductOptions,
                template,
                isFullBleed,
                studioConfiguration,
                scenesConfiguration
            } = initialState;

            if (!productKey) {
                throw Error("Product key is not defined");
            }
            if (studioSelectedProductOptions === undefined) {
                throw Error("Studio selected product options are not defined");
            }

            Store.dispatch(
                setDefaultState({
                    ...initialState
                })
            );

            const easelConfig = studioConfig.initialEaselConfig;
            const designDocument = easelConfig.cimDocOverride;

            if (productVersion === null || productVersion === undefined) {
                throw Error("Product version is not defined");
            }

            if (!designDocument) {
                throw Error("No design document available");
            }

            const updatedDesignDocument = await prepareStudioCimDoc(
                designDocument,
                easelConfig.designSpecification,
                productKey,
                productVersion,
                studioSelectedProductOptions,
                locale,
                useDraggablePlaceholders
            );
            getCimdocMetadata(updatedDesignDocument, dispatch);

            await getSecondaryCalciferData({
                designDocument: updatedDesignDocument,
                productKey,
                productVersion,
                studioSelectedProductOptions,
                dispatch,
                template,
                isFullBleed: Boolean(isFullBleed),
                locale,
                isColorMatchingBacksideEnabled
            });

            await switchContext(
                {
                    productKey,
                    productOptions: studioSelectedProductOptions,
                    designDocument: updatedDesignDocument,
                    views: easelConfig.designSpecification.views,
                    easelScenesConfiguration: scenesConfiguration ?? undefined,
                    studioConfiguration: studioConfiguration!
                },
                false
            );

            fireGenericTrackingEvent({
                event: "Project Edited",
                eventDetail: STUDIO_TRACKING_EVENTS.PROJECT_LOADED_FROM_MY_PROJECTS,
                label: "Project Edited",
                extraData: () => ({
                    workId
                })
            });

            const endTime = performance.now();
            fireUserInteractionTrackingEvent(`ChangeProject`, endTime - startTime);

            // reload the page when switching to a single color product
            // a bug in designer prevents spot colors from loading properly when switching products
            if (isSingleColorCanvas(window.designer)) {
                window.location.reload();
            }

            dispatch(setSwitchingProject({ switching: false, loadingMessage: null }));
        }

        success = true;
    } catch (err) {
        success = false;
        error = err;
        handleError(err, ERROR_CODES.LOAD_PROJECT, true, true);
    } finally {
        dispatch(easelFinishedLoading());
    }

    newRelicWrapper.logPageAction("studio-work-loaded", { success: success.toString(), error });
    return success;
}
