/* This defines the initial settings for the 5 different curve types the user can choose
 *curveMaxTextHeight, curveRadius and curveAngle are all passed on to the wordart attributes
 *curveWidth and curveHeight are used to calculate the new height of the wordart */

export default {
    sharpConvex: {
        curveMaxTextHeight: 0.2681159,
        curveRadius: 1.34058,
        curveAngle: 125,
        curveWidth: 532,
        curveHeight: 186
    },
    convex: {
        curveMaxTextHeight: 0.4660706299065421,
        curveRadius: 4.260037071028037,
        curveAngle: 58,
        curveWidth: 489,
        curveHeight: 107
    },
    straight: {
        curveMaxTextHeight: 0,
        curveRadius: 0,
        curveAngle: 0,
        curveWidth: 500,
        curveHeight: 80
    },
    concave: {
        curveMaxTextHeight: 0.4660706299065421,
        curveRadius: 4.260037071028037,
        curveAngle: -58,
        curveWidth: 489,
        curveHeight: 107
    },
    sharpConcave: {
        curveMaxTextHeight: 0.2681159,
        curveRadius: 1.34058,
        curveAngle: -125,
        curveWidth: 532,
        curveHeight: 186
    }
};
