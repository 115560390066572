import { defineMessages } from "@vp/i18n-helper";

export const changeTemplateColorMessages = defineMessages({
    changeTemplateColorPanelTitle: {
        id: "studio.components.changeTemplateColor.changeTemplateColorPanelTitle",
        defaultMessage: "Template color",
        description: {
            note: "Text for sidebar change template color panel title"
        }
    },
    changeMaterialColorPanelTitle: {
        id: "studio.components.changeTemplateColor.changeMaterialColorPanelTitle",
        defaultMessage: "Material color",
        description: {
            note: "Text for sidebar change material color panel title"
        }
    },
    changeTemplateSelectedColor: {
        id: "studio.components.changeTemplateColor.changeTemplateSelectedColor",
        defaultMessage: "Selected color: ",
        description: {
            note: "Label for sidebar change template color panel selected color"
        }
    },
    changeColorPanelTitle: {
        id: "studio.components.changeTemplateColor.changeColorPanelTitle",
        defaultMessage: "Color",
        description: {
            note: "Sidebar change template & color text for button and panel title"
        }
    },
    changeColorDescriptionText: {
        id: "studio.components.changeTemplateColor.changeColorDescriptionText",
        defaultMessage: "Select the color of your design or material.",
        description: {
            note: "Description text for change template color panel"
        }
    },
    defaultChangeColorOptionLabel: {
        id: "studio.components.changeTemplateColor.defaultChangeColorOptionLabel",
        defaultMessage: "Color Option",
        description: {
            note: "default label for color swatch"
        }
    },
    designTitle: {
        id: "studio.components.changeTemplateColor.designTitle",
        defaultMessage: "Design",
        description: {
            note: "Text for sidebar change template color panel design section title"
        }
    },
    materialTitle: {
        id: "studio.components.changeTemplateColor.materialTitle",
        defaultMessage: "Material",
        description: {
            note: "Text for sidebar change template color panel material section title"
        }
    },
    substrateColorPanelSelectedColorLabel: {
        id: "studio.ui.component.substrateColorPanelV2.label",
        defaultMessage: "Selected color: ",
        description: {
            note: "Label for selected color"
        }
    },
    substrateColorPanelDefaultSelectedColor: {
        id: "studio.ui.component.substrateColorPanelV2.default",
        defaultMessage: "Color Name",
        description: {
            note: "default Label for selected color"
        }
    },
    updating: {
        id: "studio.components.spinners.updating",
        defaultMessage: "Updating...",
        description: {
            note: "Spinner text shown with full-screen spinner while updating product color (for PPAG)"
        }
    }
});
