import React, { forwardRef, Ref, ReactNode } from "react";
import classnames from "classnames";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";

type Props = {
    contentType: DialogType;
    children?: ReactNode | ReactNode[];
    className?: string;
    ref?: Ref<HTMLDivElement>;
};

export const ContentPanel = forwardRef(({ children, className, contentType }: Props, ref: Ref<HTMLDivElement>) => {
    const { currentActiveDialog } = useActiveDialog();

    return (
        <div
            data-dcl-prevent-canvas-items-deselection
            id="content-panel"
            ref={ref}
            className={classnames(className, currentActiveDialog === contentType ? "selected" : "")}
        >
            {children}
        </div>
    );
});

ContentPanel.displayName = "ContentPanel";
