import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { initialSelection, useTableSubselection } from "@shared/features/Tables";
import { useSelection, useActiveCanvas } from "../../designer-suite";
import { tableFilter } from "../../utilities";
import TableOverlay from "./TableOverlay";
import DragOverlay from "./DragOverlay";
import { removeNorthWestRotationHandle } from "./tableUtilities";

export function TableOverlayCanvasDecorator() {
    const selection = useSelection("change:resizing model:change:data change:metaData");
    const { selection: selectionData, updateSelection } = useTableSubselection();
    const { tableItems, otherItems } = tableFilter(selection);
    const activeCanvas = useActiveCanvas("change:zoomFactor");

    const clearSubselection = useCallback(() => updateSelection(initialSelection), [updateSelection]);

    useEffect(() => {
        if (tableItems.length !== 1 || otherItems.length) {
            if (selectionData.type !== initialSelection.type) {
                updateSelection(initialSelection);
            }
        }
        const selectedItem = tableItems[0] as Item;
        if (selectedItem) {
            // avoids rotation handle overlapping with draggable icon
            removeNorthWestRotationHandle(`${selectedItem.id}-control`);
        }
    }, [tableItems, otherItems, selectionData.type, updateSelection]);

    // Clear table subselection when it goes out of range;
    // this can happen when undoing a column/row addition
    useEffect(() => {
        if (tableItems.length !== 1 || otherItems.length) {
            return;
        }

        const {
            data: { rows, columns }
        } = tableItems[0] as TableItem;

        if (selectionData.columnIndex >= columns.length || selectionData.rowIndex >= rows.length) {
            updateSelection(initialSelection);
        }
    }, [tableItems, otherItems, selectionData.columnIndex, selectionData.rowIndex, updateSelection]);

    /**
     * We will not support editing of multiple tables.  Once this is a multi-selection, the editor should go away
     */
    if (tableItems.length === 1 && !otherItems.length) {
        const selectedItem = tableItems[0] as Item;
        const overrideStyles = `
            .dcl-targets{
                overflow: unset;
            }
            .dcl-decorations{
                z-index: 6;
                pointer-events: none;
            }
            #${selectedItem.id}-decoration{
                pointer-events: none;
            }
            #${selectedItem.id}-handle{
                pointer-events: none;
            }
            #${selectedItem.id}-control{
                pointer-events: none;
            }
        `;
        const portalElement = document.getElementById(`${selectedItem.id}-decoration`);
        const handleElement = document.getElementById(`${selectedItem.id}-handle`);
        const tableItem: TableItem = tableItems[0];
        if (!tableItem._itemViewModel.has("metaData")) {
            return null;
        }

        return (
            portalElement &&
            handleElement && (
                <>
                    <style>{overrideStyles}</style>
                    {ReactDOM.createPortal(
                        <div
                            data-dcl-prevent-canvas-items-deselection
                            style={{
                                width: "100%",
                                height: "100%",
                                pointerEvents: "all"
                            }}
                        >
                            <TableOverlay
                                item={tableItem}
                                zoomFactor={activeCanvas?._canvasViewModel.get("zoomFactor")}
                            />
                        </div>,
                        portalElement
                    )}
                    {ReactDOM.createPortal(<DragOverlay onClick={clearSubselection} />, handleElement)}
                </>
            )
        );
    }

    return null;
}

TableOverlayCanvasDecorator.displayName = "TableOverlayCanvasDecorator";
