import React, { useMemo } from "react";
import { Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useTemplatePanel } from "./TemplatePanelProvider";
import { TemplatePanelContentOption } from "./TemplatePanelContentOption";

const messages = defineMessages({
    templatePanelOptionsDescription: {
        id: "studio.panels.template.options.description",
        defaultMessage: "Choose a color option created by one of our designers. You can customize it after.",
        description: {
            note: "Description for the template panel options content"
        }
    }
});

export const TemplatePanelContentOptions = () => {
    const { selectedDesign, currentTemplateColorSwatch } = useTemplatePanel();
    const { t } = useTranslationSSR();
    const currentColorSwatch = useMemo(
        () =>
            selectedDesign?.colorSwatches.find(
                ({ color, designId }) =>
                    designId === currentTemplateColorSwatch?.designId && color === currentTemplateColorSwatch?.color
            ),
        [currentTemplateColorSwatch, selectedDesign]
    );

    if (!selectedDesign) {
        return null;
    }

    const eventType = currentColorSwatch ? "template color" : undefined;

    return (
        <>
            <Typography fontSize="1" mt={2} mb={5}>
                {t(messages.templatePanelOptionsDescription.id)}
            </Typography>
            <div className="template-panel-content-options-previews-container">
                {currentColorSwatch && (
                    <TemplatePanelContentOption colorSwatch={currentColorSwatch} isCurrentColor eventType={eventType} />
                )}
                {selectedDesign.colorSwatches
                    .filter(design => design !== currentColorSwatch)
                    .map(design => (
                        <TemplatePanelContentOption
                            key={`${design.designId}-tile`}
                            colorSwatch={design}
                            eventType={eventType}
                        />
                    ))}
            </div>
        </>
    );
};

TemplatePanelContentOptions.displayName = "TemplatePanelContentOptions";
