import { useEffect, useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useDesigner } from "@designer-suite";
import { useActiveCanvasPremiumFinishItems, useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { FinishMessages } from "@shared/features/PremiumFinish";
import { useAppSelector } from "@shared/redux";
import { Errors } from "@shared/features/Validations";

export const premiumFinishErrorMessages = defineMessages({
    cta: {
        id: "easel.ui.noPremiumFinishErrorModal.cta",
        defaultMessage: "Go back"
    }
});

export function usePremiumFinishErrors() {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const { enabledCanvasFinish } = useStudio5AvailablePremiumFinishesOnCanvas();
    const { premiumFinishItems, fetchingPremiumFinishItems } = useActiveCanvasPremiumFinishItems();
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;
    const [designErrors, setDesignErrors] = useState<Errors[]>([]);

    useEffect(() => {
        if (!designer || isFullBleed) return;
        const premiumFinishErrors: Errors[] = [];
        if (finishType && !fetchingPremiumFinishItems.length && !premiumFinishItems.length) {
            premiumFinishErrors.push({
                header: t(FinishMessages.noPremiumFinishHeader.id, {
                    finishType: t(FinishMessages[finishType].id)
                }),
                body: t(FinishMessages.noPremiumFinishBody.id, {
                    finishType: t(FinishMessages[finishType].id)
                }),
                cta: t(premiumFinishErrorMessages.cta.id)
            });
        }

        setDesignErrors(premiumFinishErrors);
    }, [designer, premiumFinishItems, fetchingPremiumFinishItems, finishType, t, isFullBleed]);

    return designErrors;
}
