import React, { forwardRef, Ref, ElementType } from "react";
import classnames from "classnames";
import { i18nColorName } from "@design-stack-vista/i18n-color-name";
import { useAppSelector } from "@shared/redux";
import { SelectableColor } from "../types";
import * as styles from "./ColorSwatch.module.scss";

interface Props {
    className?: string;
    color: SelectableColor;
    /** Function called every time a color has been selected */
    toggleColor(color: SelectableColor): void;

    /** Type of element the color swatch should be
     * @default button
     */
    component?: ElementType;
    isSelected?: boolean;
}

export const ColorSwatch = forwardRef(
    ({ color, toggleColor, className, component: Component = "button", isSelected }: Props, ref: Ref<HTMLElement>) => {
        const locale = useAppSelector(state => state.locale);
        const colorName: string = i18nColorName(color.cssBackground, { culture: locale, colorPalette: "gallery" });

        return (
            <Component
                ref={ref}
                className={classnames(
                    styles.colorSwatch,
                    {
                        [styles.selected]: isSelected
                    },
                    className
                )}
                onClick={() => toggleColor(color)}
                style={{ background: `${color.cssBackground}`, color: `${color.cssBackground}` }}
                title={colorName}
            >
                <div className="swan-visually-hidden">{colorName}</div>
            </Component>
        );
    }
);

ColorSwatch.displayName = "ColorSwatch";
