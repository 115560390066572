import React, { useEffect } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Events } from "@shared/utils/Tracking";
import { useTrackingClient } from "@shared/features/Tracking";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome/Framework";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { AITemplatesSearch } from "./AITemplatesSearch";
import { useAITemplates } from "./AITemplatesProvider";
import { AITemplatesResults } from "./AITemplatesResults";
import { AITemplatesConfirmationModal } from "./AITemplatesConfirmationModal";
import { AITemplatesPanelProps } from "./AITemplatesPanelContainer";
import { useAITemplateTracking } from "./useAITemplateTracking";
import "./aiTemplatesPanel.scss";

export function AITemplatesPanel({ onDone, fullHeight = false }: AITemplatesPanelProps) {
    const { t } = useTranslationSSR();
    const { defaultTemplatesTextSearch, loadRecentlyUsedTemplates, searchSimtagAITemplates, aiTemplatesPanelMessages } =
        useAITemplates();
    const { isSmall } = useStudioLayout();
    const { isXerox } = useXerox();
    const trackingClient = useTrackingClient();
    const { tracking } = useAITemplateTracking();

    useEffect(() => {
        searchSimtagAITemplates(defaultTemplatesTextSearch, true);
        loadRecentlyUsedTemplates();
        if (!isSmall) {
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: tracking.openPanel,
                label: tracking.label
            });
        }
        // Should execute only on first load, not when dependencies change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PanelContent
            className={isXerox ? "xerox-studio-ai-templates-panel-content" : "studio-ai-templates-panel-content"}
        >
            <PanelTitle data-testid="aiTemplatesDesignsPanelTitle">{t(aiTemplatesPanelMessages.title.id)}</PanelTitle>
            <AITemplatesSearch />
            <AITemplatesResults onDone={onDone} fullHeight={fullHeight} />
            <AITemplatesConfirmationModal />
        </PanelContent>
    );
}

AITemplatesPanel.displayName = "AITemplatesPanel";
