import React, { ReactNode, useEffect, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { UploadsPanelMessages } from "@shared/features/UploadsAndAssets";
import { useUploadsProgress } from "./UploadsProgressProvider";
import { uploadStatusTypes } from "./uploadStatusTypes";
import type { Upload } from "../../@types/upload";

type Props = {
    children?: ReactNode | ReactNode[];
    upload: Upload;
    statusText: string | undefined;
    showPercentageComplete?: boolean;
};

const UploadStatus = (props: Props) => {
    const { upload, children, statusText, showPercentageComplete = true } = props;
    const { t } = useTranslationSSR();
    const { uploadsProgress, processingProgress } = useUploadsProgress();
    const [uploadStatus, setUploadStatus] = useState(upload.get("status"));

    const getPercentComplete = () => {
        let percent = 100;
        if (upload.id !== undefined && processingProgress[upload.id] !== 100) {
            if (uploadStatus <= uploadStatusTypes.fileTransferCompleted) {
                percent = uploadsProgress[upload.id] || 0;
            } else if (uploadStatus <= uploadStatusTypes.postProcessingComplete) {
                percent = processingProgress[upload.id] || 0;
            }
        }
        return `${percent}%`;
    };

    useEffect(() => {
        const uploadStatusChanged = (uploadModel: Upload) => {
            setUploadStatus(uploadModel.get("status"));
        };

        upload.on("change:status", uploadStatusChanged);
        return () => {
            upload.off("change:status", uploadStatusChanged);
        };
    }, [upload]);

    return (
        <div className="dcl-thumb__loading">
            {showPercentageComplete && (
                <div className="dcl-thumb__loading-container">
                    <span className="dcl-uploading-percentage">{getPercentComplete()}</span>
                </div>
            )}
            <div className="dcl-uploading-text">{statusText || t(UploadsPanelMessages.uploading.id)}</div>
            <div className="dcl-thumb__loading-container">
                <div className="dcl-spinner">
                    <svg className="dcl-spinner__svg">
                        <use className="dcl-icon__use" xlinkHref="#dcl-icon-spinner"></use>
                    </svg>
                </div>
            </div>
            {children}
        </div>
    );
};

UploadStatus.displayName = "UploadStatus";

export default UploadStatus;
