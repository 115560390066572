import React, { useCallback } from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { IconsSearchProvider, ComposedIconSearchBar } from "@shared/features/IconAssetLibrary";
import IconsPanel from "./Redesign/Icons/IconsPanel";

type Props = {
    onDone?: () => void;
};

export function ShapesIconsPanelContents({ onDone }: Props) {
    const { isSmall } = useStudioLayout();

    const onUploadAdded = useCallback(() => {
        if (isSmall && onDone) {
            onDone();
        }
    }, [isSmall, onDone]);

    return (
        <>
            <IconsSearchProvider>
                <ComposedIconSearchBar />
                <IconsPanel onUploadAdded={onUploadAdded} className="shapes-and-icons-panel" />
            </IconsSearchProvider>
        </>
    );
}
ShapesIconsPanelContents.displayName = "ShapesIconsPanel";
