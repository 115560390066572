import React, { useCallback, useEffect, useRef, useState } from "react";
import { Sheet, useClickOutside } from "@shared/features/StudioChrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useTrackEvents } from "@shared/features/Tracking";
import { getDesignDocumentFromDesigner } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useStudioFlexibility, useActiveFlexibilityOptions, FlexibilityPanel } from "@shared/features/Flexibility";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { ChangeTemplateAndSubstrateColorPanel } from "@shared/features/ChangeTemplate";
import { useLoadNewDesignForSubstrateChange } from "@five/hooks/useLoadNewSubstrateColor";
import { useTeamsNameItemReferences } from "@five/hooks/useTeamsNameItemReferences";
import { useDesignDialog } from "@shared/features/DesignPanel";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { SheetPanelWrapper } from "@shared/features/GlobalHeader";
import { TextPanel } from "../Panels/Redesign/TextPanel";
import { AddQRCodePanelStudio5 } from "../Panels/AddQRCodePanelStudio5";
import { ImagePanel, TablePanel, ShapesIconsPanel, GroupsAndTeamsPanel, AITemplatesPanelContainer } from "../Panels";
import { GenerateIdeasPanelContainer } from "../Panels/GenerateIdeas/GenerateIdeasPanelContainer";
import { ViewSheet } from "./ViewSheet";
import { FullBleedPremiumFinishPanelStudio5 } from "../Panels/Redesign/FullBleedPremiumFinishPanel";
import { BackgroundColorPanel } from "../Panels/Redesign/BackgroundColorPanel";
import { useLoadNewDesignForChangeTemplate } from "../../hooks/useLoadNewDesignForChangeTemplate";
import { useStudio5Save } from "../../hooks/useStudio5Save";
import { useIdeaTabEnabled } from "../../hooks/useIdeaTabEnabled";
import { TemplatePanel } from "../Panels/TemplatePanel/TemplatePanel";
import { IMAGE_SEARCH_FILTER_PORTAL_ID } from "../Panels/Images/ImageSearchFiltersPanel";
import { TEMPLATE_SEARCH_FILTER_PORTAL_ID } from "../Panels/TemplatePanel/TemplateSearchFiltersPanel";
import "./MobileSidebarSheet.scss";

const messages = defineMessages({
    images: {
        id: "studio.components.addSheet.images.label",
        defaultMessage: "Images",
        description: {
            note: "Label for the add sheet images button"
        }
    },
    viewPanelHeader: {
        id: "studio.components.panels.panel.guidespanel.viewPanelHeader",
        defaultMessage: "View",
        description: {
            note: "This message provides the header text at the top of the view sheet"
        }
    },
    backgroundColorTitle: {
        id: "studio.components.addSheet.backgroundcolorpickerpanel.title",
        defaultMessage: "Background color"
    },
    shapesTitle: {
        id: "studio.components.panels.studiopanels.elementsPanel.panelTitle",
        defaultMessage: "Shapes",
        description: {
            note: "Title for the elements panel"
        }
    }
});

export function MobileSidebarSheet() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { t } = useTranslationSSR();
    const { isCanvasBlank } = useDesignDialog();
    const sheetRef = useRef<HTMLDivElement>(null);
    const [sheetHeight, setSheetHeight] = useState(0);
    const { trackEvent } = useTrackEvents();
    const { isMileStone1Enabled, isMileStone2Enabled } = useStudioFlexibility();
    const { isAnyEnabled } = useActiveFlexibilityOptions();
    const mobileTemplatesEnabled = useIsFlagEnabled("mobileTemplates");
    const showDesignTemplatesPanel = useIdeaTabEnabled();

    const aiContentType = showDesignTemplatesPanel ? DialogType.IdeaPanel : DialogType.AITemplates;
    const AIContainerComponent = showDesignTemplatesPanel ? GenerateIdeasPanelContainer : AITemplatesPanelContainer;

    useTeamsNameItemReferences();

    const loadNewDesign = useLoadNewDesignForChangeTemplate();
    const loadNewSubstateColor = useLoadNewDesignForSubstrateChange();
    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(false), []);
    const saveForChangeTemplate = useStudio5Save({
        allowAnonymousUser: true,
        hideSaveToast: true,
        showLoader: false,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_CHANGE_TEMPLATE
    });

    const onClick = (type: DialogType) => {
        trackEvent({ eventDetail: `${STUDIO_TRACKING_EVENTS.CLICK_ADD_SHEET}${type}`, label: "General" });
        setCurrentActiveDialog(type);
    };

    const reportSizeChanged = (rect: DOMRect) => {
        if (rect) {
            setSheetHeight(rect.height);
        }
    };

    const fullHeight =
        currentActiveDialog === DialogType.Images ||
        currentActiveDialog === DialogType.FullBleedPremiumFinish ||
        currentActiveDialog === DialogType.Graphics ||
        currentActiveDialog === DialogType.Template ||
        currentActiveDialog === DialogType.AITemplates ||
        currentActiveDialog === DialogType.IdeaPanel;
    // We don't want any panels to show up when we first hit the add sheet
    useEffect(() => {
        setCurrentActiveDialog(DialogType.None);
    }, [setCurrentActiveDialog]);

    useClickOutside(
        sheetRef,
        async () => {
            // don't close sheets that are full-height for 'outside' clicks like open modals or the upload button
            if (sheetHeight && !fullHeight) {
                setCurrentActiveDialog(DialogType.None);
            }
        },
        [sheetHeight, fullHeight]
    );

    const templateComponent = useCallback(() => {
        if (!isMileStone1Enabled || mobileTemplatesEnabled) {
            return (
                <SheetPanelWrapper hidebackButton contentType={DialogType.Template} className="template-sheet-panel">
                    <TemplatePanel />
                    <div id={TEMPLATE_SEARCH_FILTER_PORTAL_ID} />
                </SheetPanelWrapper>
            );
        }
        return null;
    }, [isMileStone1Enabled, mobileTemplatesEnabled]);

    const setCurrentActiveDialogToNone = useCallback(
        () => setCurrentActiveDialog(DialogType.None),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div className="add-sheet-panel" ref={sheetRef}>
            <Sheet
                fullHeight={fullHeight}
                reportSizeChange={reportSizeChanged}
                hideSheet={currentActiveDialog === DialogType.None}
                noOverflow={
                    currentActiveDialog === DialogType.Graphics || currentActiveDialog === DialogType.AITemplates
                }
                data-testid={`sheet-${currentActiveDialog}`}
            >
                {!isCanvasBlank && (
                    <>
                        {templateComponent()}
                        <SheetPanelWrapper hidebackButton contentType={DialogType.Text}>
                            <TextPanel onDone={() => onClick(DialogType.None)} />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={aiContentType}>
                            <AIContainerComponent onDone={setCurrentActiveDialogToNone} fullHeight={fullHeight} />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={DialogType.Images} className="image-sheet-panel">
                            <ImagePanel onDone={() => onClick(DialogType.None)} fullHeight={fullHeight} />
                            <div id={IMAGE_SEARCH_FILTER_PORTAL_ID} />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={DialogType.Color}>
                            <ChangeTemplateAndSubstrateColorPanel
                                loadNewDesignForTemplateChange={loadNewDesign}
                                loadNewDesignForSubstrateChange={loadNewSubstateColor}
                                getDocument={getDocument}
                                save={saveForChangeTemplate}
                            />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={DialogType.Graphics}>
                            <ShapesIconsPanel onDone={setCurrentActiveDialogToNone} />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={DialogType.QRCodes}>
                            <AddQRCodePanelStudio5 />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={DialogType.Tables}>
                            <TablePanel colsAndRowsStartNumber={2} />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={DialogType.FullBleedPremiumFinish}>
                            <FullBleedPremiumFinishPanelStudio5 />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper hidebackButton contentType={DialogType.GroupsAndTeams}>
                            <GroupsAndTeamsPanel />
                            <div id="mobile-teams-guide-modal" />
                        </SheetPanelWrapper>
                        <SheetPanelWrapper
                            contentType={DialogType.BackgroundColorPicker}
                            sheetTitle={t(messages.backgroundColorTitle.id)}
                        >
                            <BackgroundColorPanel />
                        </SheetPanelWrapper>
                        {isMileStone2Enabled && isAnyEnabled && (
                            <SheetPanelWrapper contentType={DialogType.Flexibility} hidebackButton>
                                <FlexibilityPanel />
                            </SheetPanelWrapper>
                        )}
                    </>
                )}
                <SheetPanelWrapper
                    backContentType={DialogType.File}
                    contentType={DialogType.Guides}
                    sheetTitle={t(messages.viewPanelHeader.id)}
                >
                    <ViewSheet />
                </SheetPanelWrapper>
            </Sheet>
        </div>
    );
}

MobileSidebarSheet.displayName = "MobileSidebarSheet";
