/* eslint-disable consistent-return */
/**
 * Why is there so much stuff in this file?  This is a temporary (we hope) remediation for v1 failures that can be fixed by fileprep
 * The way this is integrated with Design should not be long term.  There is current work to make filePrep faster, which would allow us to use
 * it directly for all uploads and avoid this approach.
 * Therefore, no code in this file should be replicated, re-used, or assumed to be the correct way to do this
 */
import { useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useDesigner } from "@designer-suite";
import { useAppDispatch, autoClosableAlert, setAlerts, AlertSkin } from "@shared/redux";
import type { Page } from "src/easel/designer-suite/@types/page";
import type { Upload } from "src/easel/designer-suite/@types/upload";
import { FilePdfResponse, getFilePrep } from "../clients/prepressClient";

// import getTestData from "./useFallbackResultTest";

const MM_TO_PT = 2.83465;
const pt2mm = (pt: number) => {
    return pt / MM_TO_PT;
};

export function buildPdfProperties(filePrepOutput: {
    IsPdfCorrupt: any;
    IsPdfEncrypted: any;
    AreUnembeddedFontsPresent: any;
}) {
    return {
        isCorrupt: filePrepOutput.IsPdfCorrupt,
        isEncrypted: filePrepOutput.IsPdfEncrypted,
        hasUnembeddedFonts: filePrepOutput.AreUnembeddedFontsPresent
    };
}

export function buildAdvancedPdfInfo(
    filePrepOutput: { FontsEmbedded: any; AreNonCmykElementsPresent: any; AreFontsBelowMinimumSize: any },
    pageInfo: { Inks: any; Counter: { ImageResulutionInfo: { Color: { Min: any } } }; HasElementTransparency: any }
) {
    return {
        colors: convertFilePrepInks(pageInfo.Inks),
        hasAutoEmbeddedFonts: filePrepOutput.FontsEmbedded,
        hasColorspaceConversion: filePrepOutput.AreNonCmykElementsPresent,
        minimumDpi:
            pageInfo.Counter.ImageResulutionInfo &&
            pageInfo.Counter.ImageResulutionInfo.Color &&
            pageInfo.Counter.ImageResulutionInfo.Color.Min,
        hasSmallFont: filePrepOutput.AreFontsBelowMinimumSize,
        hasElementTransparency: pageInfo.HasElementTransparency
    };
}

const colorExists = (color: { IsBlank: any }) => !color.IsBlank;
const createColor = (color: { ColorantName: any }) => ({ name: color.ColorantName });

/**
 * Convert inks from FilePrep into a simple color object
 * @param {FilePrepInk} inks
 * @returns {PageInk[]}
 */
export function convertFilePrepInks(inks: any[]) {
    return inks.filter(colorExists).map(createColor);
}

/**
 * convertFilePrepPageBoxes
 * */

const convertBoxName = (name: string) => {
    const withoutBox = name.substring(0, name.length - 3);
    // lowercase the first character
    return withoutBox[0].toLowerCase() + withoutBox.substring(1);
};

const pageIsRotated = (originalPdfRotation: string) =>
    originalPdfRotation.toLowerCase() === "rotate90" || originalPdfRotation.toLowerCase() === "rotate270";

/**
 * A PDF page box from FilePrep
 * @typedef {Object} FilePrepPageBox
 * @property {Object} UpperRight
 * @property {number} UpperRight.X - the upper right x position in points
 * @property {number} UpperRight.Y - the upper right y position in points
 * @property {Object} LowerLeft
 * @property {number} LowerLeft.X - the upper left x position in points
 * @property {number} LowerLeft.Y - the upper left y position in points
 */

/**
 * A PDF page box in our business model
 * @typedef {Object} PageBox
 * @property {number} upperRightX - the upper right x position in mm
 * @property {number} upperRightY - the upper right x position in mm
 * @property {number} lowerLeftX - the lower left x position in mm
 * @property {number} lowerLeftY - the lower left y position in mm
 */

/**
 * Converts the page box from file prep into our business model
 * @param {Object} uploadSize
 * @param {FilePrepPageBox} box
 * @param {string} originalPdfRotation
 * @returns {PageBox}
 */
function convertFilePrepPageBox(
    uploadSize: { height: number },
    box: {
        UpperLeft: { Y: any; X: any };
        LowerRight: { Y: any; X: any };
        UpperRight: { X: any; Y: any };
        LowerLeft: { X: any; Y: any };
    },
    originalPdfRotation: any
) {
    if (!box) {
        return;
    }

    if (pageIsRotated(originalPdfRotation)) {
        /*
            PageBoxes by prepress are defined by path points (like an SVG). So they use the following structure:
                - upperRightX = upper right path to location top position from the bottom
                - upperRightY = upper right path to location right position from the left
                - lowerLeftX = lower left path start location
                - lowerLeftY = lower bottom path start location
            For a trim page box of 150mm by 300mm (3mm margin with bleed box), we receive the following data from Prepress
                - upperRightX = 153
                - upperRightY = 303
                - lowerLeftX = 3
                - lowerLeftY = 3
            when the PDF has a rotation value (rotated from its original rotation) The pagebox data should replicate the same values,
            so upperRight and lowerLeft top, left position swap to represent the same path as mentioned above.
        */
        return {
            upperRightX: pt2mm(box.UpperLeft.Y),
            upperRightY: uploadSize.height - pt2mm(box.UpperLeft.X),
            lowerLeftX: pt2mm(box.LowerRight.Y),
            lowerLeftY: uploadSize.height - pt2mm(box.LowerRight.X)
        };
    }

    return {
        upperRightX: pt2mm(box.UpperRight.X),
        upperRightY: pt2mm(box.UpperRight.Y),
        lowerLeftX: pt2mm(box.LowerLeft.X),
        lowerLeftY: pt2mm(box.LowerLeft.Y)
    };
}

/**
 * Converts PDF page boxes from file prep's model to the business model
 * @param {Object} uploadSize
 * @param {{Object.<string, FilePrepPageBox>}} boxes - a dictionary of PDF page boxes from FilePrep
 * @param {string} originalPdfRotation
 * @returns {{Object.<string, PageBox>}}
 */
export default function convertFilePrepPageBoxes(
    uploadSize: { width: number; height: number },
    boxes: { [x: string]: any },
    originalPdfRotation: any
) {
    return Object.keys(boxes).reduce((result, boxName) => {
        const newResult = { ...result };
        newResult[convertBoxName(boxName)] = convertFilePrepPageBox(uploadSize, boxes[boxName], originalPdfRotation);
        return result;
    }, {});
}

const appendFilePrepResponse = (upload: Upload, filePrepResponse: FilePdfResponse) => {
    if (!filePrepResponse) {
        return null;
    }
    const { originalUrl } = upload;

    const updatedUpload: Upload = { ...upload };

    const uploadPages: Page[] = [];
    updatedUpload.filePrepResponse = filePrepResponse;
    updatedUpload.pdfProperties = buildPdfProperties(filePrepResponse);
    updatedUpload.printUrl = filePrepResponse.PrintFileUrl;

    if (filePrepResponse.Previews && filePrepResponse.Previews.length) {
        filePrepResponse.Previews.forEach(({ Url: previewUrl, PageNumber: pageNumber }) => {
            const pageInfo = filePrepResponse.PdfInfo.Pages[pageNumber - 1];
            const { SizeInfo } = pageInfo;
            const width = pt2mm(SizeInfo.WidthPt);
            const height = pt2mm(SizeInfo.HeightPt);
            const uploadSize = { width, height };
            const originalPdfRotation = SizeInfo.Rotation;

            const newPage = {
                pageNumber,
                width,
                height,
                originalPdfRotation,
                originalUrl,
                printUrl: updatedUpload.printUrl,
                previewUrl,
                pdfHighResImageUrl: previewUrl,
                status: 50, // postProcessingComplete
                fileType: "application/pdf", // fileTypes.pdf
                percentComplete: updatedUpload.percentComplete,
                physicalDimensions: { width, height },
                pageBoxes: convertFilePrepPageBoxes(uploadSize, pageInfo.Boxes, originalPdfRotation),
                advancedPdfInfo: buildAdvancedPdfInfo(filePrepResponse, pageInfo)
            };

            const page = uploadPages.find((uploadPage: Page) => uploadPage.pageNumber === newPage.pageNumber);
            if (page) {
                Object.assign(page, newPage);
            } else {
                // @ts-ignore will be replaced by sherbert upload manager?
                uploadPages.push(newPage);
            }
        });
    }

    updatedUpload.pages = uploadPages;
    return updatedUpload;
};

const alertRetryingUpload = (dispatch: any, message: string, skin: AlertSkin) => {
    dispatch(autoClosableAlert());
    dispatch(
        setAlerts({
            alerts: [
                {
                    key: message,
                    skin
                }
            ]
        })
    );
};

const messages = defineMessages({
    alertRetry: {
        id: "studio.ui.hook.useUploadFallback.retryMessage",
        defaultMessage: "Please wait while we retry your failed upload.",
        description: {
            note: "Message to indicate that a retry is happening for an upload that recently reported as failed."
        }
    }
});

export const UPLOAD_FALLBACK_INITIATOR = "studio-upload-fallback";

export const useUploadFallback = () => {
    const designer = useDesigner();
    const { auth } = useIdentityContext();
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();

    useEffect(() => {
        if (!designer) {
            return () => {};
        }
        const onFailure = async (upload: { get: (arg0: string) => any; attributes: Upload; destroy: () => void }) => {
            if (
                upload.attributes.fileType !== "application/pdf" ||
                upload.attributes.clientInitiator === UPLOAD_FALLBACK_INITIATOR
            ) {
                // while this event only results from rasterization, explicitly only do PDFs (for now)
                return;
            }
            // eslint-disable-next-line no-param-reassign
            upload.attributes.clientInitiator = UPLOAD_FALLBACK_INITIATOR; // tag this so we can distinguish from designer upload.
            try {
                await new Promise(resolve => {
                    setTimeout(resolve, 500);
                    alertRetryingUpload(dispatch, t(messages.alertRetry.id), "positive");
                });
                designer.uploadManager.add(upload); // add it back while we process it.
                designer.eventBus.trigger(designer.eventBus.events.uploadStarted, upload);
                const result = await getFilePrep({
                    authToken: auth.getToken(),
                    fileUrl: upload.get("originalUrl"),
                    pageNumber: null
                });
                const newUpload = appendFilePrepResponse(upload.attributes, result);
                designer.uploadManager.update(newUpload);
                designer.eventBus.trigger(designer.eventBus.events.uploadComplete, upload);
            } catch (error) {
                // prevent a retry loop
                designer.eventBus.off(designer.eventBus.events.uploadFailed, onFailure);
                designer.eventBus.trigger(designer.eventBus.events.uploadFailed, upload, error);
                designer.eventBus.trigger(designer.eventBus.events.uploadOverallFailure, upload, error);
                alertRetryingUpload(dispatch, t("easel.designer.translation.errors.general"), "error");
                upload?.destroy();
                designer.eventBus.on(designer.eventBus.events.uploadFailed, onFailure);
            }
        };

        designer.eventBus.on(designer.eventBus.events.uploadFailed, onFailure);

        return () => {
            designer.eventBus.off(designer.eventBus.events.uploadFailed);
        };
    }, [auth, designer, dispatch, t]);
};
