/* eslint-disable max-len */
import React from "react";

export function TeamsIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 18 12" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.025 3.63029C14.025 5.08295 12.8474 6.26057 11.3947 6.26057C9.94203 6.26057 8.76441 5.08295 8.76441 3.63029C8.76441 2.17762 9.94203 1 11.3947 1C12.8474 1 14.025 2.17762 14.025 3.63029ZM12.8878 6.94028C14.148 6.37091 15.025 5.10301 15.025 3.63029C15.025 1.62533 13.3996 0 11.3947 0C9.38975 0 7.76441 1.62533 7.76441 3.63029C7.76441 5.10303 8.6414 6.37095 9.90163 6.9403C7.19288 7.62169 5.83695 10.069 5.46859 11.3582C5.39272 11.6237 5.54647 11.9005 5.81199 11.9764C6.0775 12.0522 6.35425 11.8985 6.43011 11.633C6.80614 10.3169 8.33295 7.76026 11.3948 7.76026C14.4566 7.76026 15.9834 10.3169 16.3594 11.633C16.4353 11.8985 16.712 12.0522 16.9776 11.9764C17.2431 11.9005 17.3968 11.6237 17.321 11.3582C16.9526 10.0689 15.5966 7.62163 12.8878 6.94028Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.43364 4.11257C5.43364 4.89692 4.7978 5.53276 4.01345 5.53276C3.2291 5.53276 2.59326 4.89692 2.59326 4.11257C2.59326 3.32822 3.2291 2.69238 4.01345 2.69238C4.7978 2.69238 5.43364 3.32822 5.43364 4.11257ZM5.20037 6.22222C5.93653 5.80716 6.43364 5.01792 6.43364 4.11257C6.43364 2.77594 5.35009 1.69238 4.01345 1.69238C2.67682 1.69238 1.59326 2.77594 1.59326 4.11257C1.59326 5.13395 2.22596 6.00754 3.1208 6.36282C2.36692 6.61277 1.7612 7.06258 1.29406 7.57031C0.58972 8.33586 0.183368 9.24824 0.0202796 9.8722C-0.0495514 10.1394 0.110421 10.4126 0.377588 10.4824C0.644755 10.5522 0.917946 10.3923 0.987777 10.1251C1.11601 9.63449 1.45252 8.87502 2.02998 8.24738C2.59859 7.62935 3.38308 7.15496 4.43684 7.15496C5.30461 7.15496 5.98978 7.47668 6.52216 7.93539C6.7708 7.71619 7.04317 7.51089 7.34026 7.32741C6.79152 6.80806 6.08213 6.38159 5.20037 6.22222Z"
                fill="currentColor"
            />
        </svg>
    );
}
TeamsIcon.displayName = "TeamsIcon";
