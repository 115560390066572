import { useState, useEffect } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";

export function useIsAnonymousUser() {
    const [anonymousUser, setAnonymousUser] = useState(true);
    const { identity } = useIdentityContext();

    // This will only run once and if user login it will require refresh of browser
    useEffect(() => {
        setAnonymousUser(identity.isAnonymousUser);
    }, [identity]);

    return anonymousUser;
}
