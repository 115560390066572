import React, { useCallback, useRef } from "react";
import debounce from "lodash/debounce";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";

import { useAppSelector } from "@shared/redux";
import { useActiveCanvas } from "@designer-suite";
import { saveStudio5Design } from "@five/clients/saveClient";
import { useCheckValidationsForNextStep } from "@five/components/nextStepHelpers";
import { useTriggerCanvasChange } from "@five/hooks/useTriggerCanvasChange";
import { nextStepButtonMessages, useHandleNextStepClick } from "@shared/features/GlobalHeader";
import { getDesignDocumentFromDesigner } from "@utilities";

interface NextStepButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    customLabel?: string;
}

export function NextButton(props: NextStepButtonProps) {
    const { customLabel } = props;
    const { t } = useTranslationSSR();
    const buttonRef = useRef(null);

    const activeCanvas = useActiveCanvas();
    const triggerCanvasChange = useTriggerCanvasChange();
    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(), []);

    const checkValidations = useCheckValidationsForNextStep(buttonRef);
    const handleNextClick = useHandleNextStepClick({
        checkValidations,
        activeCanvasName: activeCanvas?.name,
        triggerCanvasChange,
        getDocument,
        save: saveStudio5Design
    });
    const debouncedHandleNextClick = debounce(handleNextClick, 250);

    const easelLoaded = useAppSelector(state => state.easelLoaded);

    return (
        <Button
            data-button-id="next-step-button__desktop"
            skin="primary"
            width="standard"
            size="mini"
            onClick={() => {
                // TODO-xerox-rollout
                // Only allow clicking next if easel is already loaded
                // Don't show as disabled because it causes a layout shift
                // Ideally, the next action should show a loading spinner and handle the case of easel not loaded
                // Refactor this before rolling out
                easelLoaded && debouncedHandleNextClick();
            }}
            data-testid="nextButton"
            data-translationid={nextStepButtonMessages.nextButton.id}
            paddingLeft="7"
            paddingRight="7"
            marginLeft="1"
            ref={buttonRef}
        >
            {customLabel || t(nextStepButtonMessages.nextButton.id)}
        </Button>
    );
}
NextButton.displayName = "NextButton";
