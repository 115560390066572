import React, { useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector, useAppDispatch, resetStatus } from "@shared/redux";
import classNames from "classnames";
import * as styles from "./StatusMessages.module.scss";

/**
 * a11y: This component helps in adding messages related to toolabar events to an visually-hidden area with role="status"
 *
 *  **How to use**
 *
 *  dispatch `setStatus` or `resetStatus` to clear or add a new status
 *
 *  **Shape** :
 *  ```javascript
 *  {
 *       messages: [
            {
                key: `the key to the trasaltion id`,
            }
        ]
    }
```
    Example: Search for `studio.components.Status.deleteItem`
 *
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const messages = defineMessages({
    deleteItem: {
        id: "studio.components.Status.deleteItem",
        defaultMessage: "[[statusMessage]] deleted",
        description: {
            note: "Status message for delete button on the toolbar"
        }
    },
    duplicateItem: {
        id: "studio.components.Status.duplicateItem",
        defaultMessage: "[[statusMessage]] duplicated",
        description: {
            note: "Status message for delete button on the toolbar"
        }
    },
    rotateItem: {
        id: "studio.components.Status.rotateItem",
        defaultMessage: "[[statusMessage]] rotated",
        description: {
            note: "Status message for rotate button on the toolbar"
        }
    },
    alignLeftItem: {
        id: "studio.components.Status.alignLeftItem",
        defaultMessage: "[[statusMessage]] aligned to left",
        description: {
            note: "Status message for left align button on the toolbar"
        }
    },
    alignCenterItem: {
        id: "studio.components.Status.alignCenterItem",
        defaultMessage: "[[statusMessage]] aligned at center",
        description: {
            note: "Status message for center align button on the toolbar"
        }
    },
    alignRightItem: {
        id: "studio.components.Status.alignRightItem",
        defaultMessage: "[[statusMessage]] aligned to right",
        description: {
            note: "Status message for right align button on the toolbar"
        }
    },
    bringToFrontItem: {
        id: "studio.components.Status.bringToFrontItem",
        defaultMessage: "[[statusMessage]] brought to front",
        description: {
            note: "Status message for bring to front button on the toolbar"
        }
    },
    bringForwardItem: {
        id: "studio.components.Status.bringForwardItem",
        defaultMessage: "[[statusMessage]] brought forward",
        description: {
            note: "Status message for bring forward button on the toolbar"
        }
    },
    sendBackwardItem: {
        id: "studio.components.Status.sendBackwardItem",
        defaultMessage: "[[statusMessage]] sent backward",
        description: {
            note: "Status message for send backward button on the toolbar"
        }
    },
    sendToBackItem: {
        id: "studio.components.Status.sendToBackItem",
        defaultMessage: "[[statusMessage]] sent to back",
        description: {
            note: "Status message for send to back button on the toolbar"
        }
    }
});

export function StatusMessages() {
    const { t } = useTranslationSSR();
    const statusArray = useAppSelector(state => state.status);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let returnFn;
        if (statusArray.length > 0) {
            const closeAlertToast = setTimeout(() => {
                dispatch(resetStatus());
            }, 1000);
            returnFn = () => clearTimeout(closeAlertToast);
        }
        return returnFn;
    }, [statusArray, dispatch]);

    return (
        <div
            role="status"
            aria-atomic="false"
            className={classNames(styles.statusMessageContainer, "swan-visually-hidden")}
        >
            {statusArray.map(status => (
                <div key={status.key}>{t(status.key, { statusMessage: status.statusMessage })}</div>
            ))}
        </div>
    );
}

StatusMessages.displayName = "StatusMessages";
