import { defineMessages } from "@vp/i18n-helper";

export const GenerateIdeasPanelMessages = defineMessages({
    title: {
        id: "studio.components.panels.generateIdeas.title",
        defaultMessage: "Ideas",
        description: {
            note: "Title for Generate Ideas panel"
        }
    },
    replaceAITemplateModalTitle: {
        id: "studio.components.panels.aiTemplatesPanel.replaceAITemplateModalTitle",
        defaultMessage: "Change to this Design?",
        description: {
            note: "Heading for changing AI templates modal"
        }
    },
    replaceAITemplateModalDescription: {
        id: "studio.components.panels.aiTemplatesPanel.replaceAITemplateModalDescription",
        defaultMessage:
            "Adding this design will remove everything on this side of the product. Do you want to continue?",
        description: {
            note: "Description for changing AI template modal"
        }
    },
    replaceAITemplateModalConfirm: {
        id: "studio.components.panels.aiTemplatesPanel.replaceAITemplateModalConfirm",
        defaultMessage: "Yes, apply new design",
        description: {
            note: "Confirm button for changing AI template modal"
        }
    },
    replaceAITemplateModalCancel: {
        id: "studio.components.panels.aiTemplatesPanel.replaceAITemplateModalCancel",
        defaultMessage: "No, keep existing design",
        description: {
            note: "Cancel button for changing AI template modal"
        }
    },
    replaceAITemplateModalClose: {
        id: "studio.components.panels.aiTemplatesPanel.replaceAITemplateModalClose",
        defaultMessage: "Close",
        description: {
            note: "Close button for changing AI template modal"
        }
    },
    failedSearch: {
        id: "studio.components.panels.aiTemplatesPanel.failedSearch",
        defaultMessage: "Sorry, we couldn't access our designs. Please try searching again.",
        description: {
            note: "Error message for failed search of AI templates"
        }
    },
    searchPlaceholderText: {
        id: "studio.components.panels.aiTemplatesPanel.searchPlaceholderText",
        defaultMessage: "Search terms like pets, food...",
        description: {
            note: "This is the placeholder text inside of a search bar"
        }
    },
    noMatch: {
        id: "studio.components.panels.aiTemplatesPanel.noMatch",
        defaultMessage: "Sorry, we couldn't find a match.",
        description: {
            note: "Error message for search term without a match"
        }
    },
    exceedSearchText: {
        id: "studio.components.panels.aiTemplatesPanel.exceedSearchText",
        defaultMessage: "Search term cannot exceed 100 character limit.",
        description: {
            note: "Error message when the search term is greater than 100 characters"
        }
    },
    aiTemplateSearchResetButtonLabel: {
        id: "studio.components.panels.aiTemplatesPanel.aiTemplateSearchResetButtonLabel",
        defaultMessage: "Reset Search",
        description: {
            note: "Label for a reset AI templates search button"
        }
    },
    searchAITemplatesButtonLabel: {
        id: "studio.components.panels.aiTemplatesPanel.searchAITemplatesButtonLabel",
        defaultMessage: "Search designs",
        description: {
            note: "Label for a search AI templates button"
        }
    },
    aiTemplatesPanelLoaderLabel: {
        id: "studio.components.panels.aiTemplatesPanel.aiTemplatesPanelLoaderLabel",
        defaultMessage: "loading",
        description: {
            note: "Text for loader"
        }
    },
    loadAITemplateOnProduct: {
        id: "studio.components.panels.aiTemplatesPanel.loadAITemplateOnProduct",
        defaultMessage: "Loading design...",
        description: {
            note: "Text for loader"
        }
    },
    recentlyUsedAITemplates: {
        id: "studio.components.panels.aiTemplatesPanel.recentlyUsedAITemplates",
        defaultMessage: "Recently used designs",
        description: {
            note: "Heading for Recently used designs"
        }
    },
    recentlyUsedAITemplatesPrevious: {
        id: "studio.components.panels.aiTemplatesPanel.recentlyUsedAITemplatesPrevious",
        defaultMessage: "Go to previous template",
        description: {
            note: "Accessible text for previous button"
        }
    },
    recentlyUsedAITemplatesNext: {
        id: "studio.components.panels.aiTemplatesPanel.recentlyUsedAITemplatesNext",
        defaultMessage: "Go to next template",
        description: {
            note: "Accessible text for next button"
        }
    },
    allAITemplates: {
        id: "studio.components.panels.aiTemplatesPanel.allAITemplates",
        defaultMessage: "All designs",
        description: {
            note: "Heading for All designs"
        }
    },
    aiTemplatesImageSearchAltText: {
        id: "studio.components.panels.aiTemplatesPanel.aiTemplatesImageSearchAltText",
        defaultMessage: "design",
        description: {
            note: "alt text for templates"
        }
    },
    recentlyUsedAITemplateImageAltText: {
        id: "studio.components.panels.aiTemplatesPanel.recentlyUsedAITemplateImageAltText",
        defaultMessage: "Recently used design",
        description: {
            note: "alt text for recently used templates"
        }
    },
    primaryTextFormPlaceholder: {
        id: "studio.components.panels.aiTemplatesPanel.primaryTextFormPlaceholder",
        defaultMessage: "Headline",
        description: {
            note: "default placeholder for primary text"
        }
    },
    secondaryTextFormPlaceholder: {
        id: "studio.components.panels.aiTemplatesPanel.secondaryTextFormPlaceholder",
        defaultMessage: "Your text here",
        description: {
            note: "default placeholder for secondary text"
        }
    }
});
