import React from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { MyProjectsButton } from "@shared/features/MyProjects";
import { DownloadButton } from "@shared/features/GlobalHeader";
import { DuplicateProjectButton, RenameProjectButton, SaveButtonSmallScreen } from "@shared/features/Save";
import { useStudio5Save } from "@five/hooks/useStudio5Save";
import { saveStudio5Design } from "../../../clients/saveClient";

export function ProjectNameDropdown({ onSave }: { onSave: () => void }) {
    const { isMedium } = useStudioLayout();

    return (
        <>
            {isMedium && (
                <SaveButtonSmallScreen
                    className="project-name-dropdown-button"
                    onSave={onSave}
                    useSave={useStudio5Save}
                />
            )}
            <MyProjectsButton
                className="project-name-dropdown-button"
                enableModalTransitionEvent
                saveDesign={saveStudio5Design}
            />
            <RenameProjectButton
                className="project-name-dropdown-button"
                saveDesign={saveStudio5Design}
                useSave={useStudio5Save}
            />
            <DuplicateProjectButton
                className="project-name-dropdown-button"
                saveDesign={saveStudio5Design}
                useSave={useStudio5Save}
            />
            <DownloadButton className="project-name-dropdown-button" skin="unstyled" />
        </>
    );
}

ProjectNameDropdown.displayName = "ProjectNameDropdown";
