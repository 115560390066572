import React from "react";
import { getTrackBackground, Range } from "react-range";
import classnames from "classnames";

import * as styles from "./ZoomSliderTool.module.scss";

export interface ZoomToolbarProps {
    onChange: (values: number[]) => void;
    onRelease: (values: number[]) => void;
    currentValue: number;
    minZoom: number;
    maxZoom: number;
    values: number[];
    altText?: string;
}

export function ZoomSliderTool({
    values,
    currentValue,
    minZoom,
    maxZoom,
    onChange,
    onRelease,
    altText
}: ZoomToolbarProps) {
    return (
        <Range
            values={values}
            step={1}
            min={minZoom}
            max={maxZoom}
            onChange={onChange}
            onFinalChange={onRelease}
            renderTrack={({ props: trackProps, children }) => (
                // the slider thumb has the appropriate attributes,
                // this div is just for users clicking or touching on the track
                /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
                <div
                    onMouseDown={trackProps.onMouseDown}
                    onTouchStart={trackProps.onTouchStart}
                    className={classnames("zoom-toolbar-track-container", styles.zoomSliderTrackContainer)}
                    style={trackProps.style}
                >
                    <div
                        ref={trackProps.ref}
                        className={classnames("zoom-toolbar-track", styles.zoomSliderTrack)}
                        style={{
                            background: getTrackBackground({
                                values: [currentValue],
                                colors: ["#000", "#DFDFDF"],
                                min: minZoom,
                                max: maxZoom
                            })
                        }}
                    >
                        {children}
                    </div>
                </div>
            )}
            renderThumb={({ props: thumbProps }) => (
                <div
                    {...thumbProps}
                    className={classnames("zoom-toolbar-thumb", styles.zoomSliderThumb)}
                    aria-label={altText}
                    style={thumbProps.style}
                ></div>
            )}
        />
    );
}

ZoomSliderTool.displayName = "ZoomSliderTool";
