import React, { useCallback, useEffect, useState } from "react";
import { CMYK, HSL, HSV, RGB } from "@design-stack-ct/utility-core";
import { EyeDropper, useDesigner } from "@designer-suite";
import {
    ColorPickerPanelContent,
    ColorSpace,
    NO_COLOR_VALUE,
    convertToRgb,
    convertToSelectableColor,
    parseColor,
    defaultStateColorObject,
    calculateColorValues,
    isHsv,
    type SelectableColor,
    type StateColorObject
} from "@shared/features/ColorPicker";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useRecentColorsContext } from "@presentational";
import type { Designer, ItemSelection } from "../../../../easel/designer-suite/@types/designer";

interface EyeDropperConfig {
    /** Function to pass to the eye dropper tool for selection compatibility */
    isCompatibleWithSelection: (selection: ItemSelection) => boolean;
    /** Events that eye dropper tool should listen for */
    listenEvents: string;
    /** Function to pass to the eye dropper tool when a color is selected */
    onClick: (color: string) => void;
    /** Size of eye dropper button */
    buttonSize?: "mini" | "standard";
    /** Optional function to call when the button to open eye dropper is clicked */
    onEyeDropClick?: () => void;
}

interface Props {
    /**
     * Currently selected color
     */
    value?: string | CMYK | RGB | HSV | HSL;
    /**
     * Array of recommended colors from the design. See the ColorPalette component
     */
    recentColors?: SelectableColor[];
    /**
     * Array of standard colors. See ColorPalette component
     */
    paletteColors: SelectableColor[];
    /**
     * Show the custom color picker (wheel/sliders).
     * @default true
     */
    allowCustom?: boolean;
    /**
     * Show the 'No color' option.
     * @default false
     */
    allowTransparency?: boolean;
    /**
     * Function to call when color changes
     */
    onChange?: (value: RGB | CMYK | string, applyClick?: boolean) => void;
    /**
     * If set, eye dropper will be shown with these configs
     */
    eyeDropperConfig?: EyeDropperConfig;
    /**
     * Id of the currently selected item that color picker applies to.
     * Useful for reinitializing this panel with a new color when item or canvas changes
     */
    selectionId?: string;
    /**
     * Panel type for tracking
     */
    panelType?: string;
    /**
     * Function to update the current color in the panel
     * Used for responding to undo and redo
     */
    updateCurrentColor?: () => void;
    className?: string;
}

export function ColorPickerPanelWrapper(props: Props) {
    const {
        value,
        selectionId,
        eyeDropperConfig,
        updateCurrentColor,
        paletteColors,
        panelType,
        onChange = () => {}
    } = props;
    const { updateLastSelectedColor } = useRecentColorsContext();
    const designer = useDesigner();
    const [currentColorValues, setCurrentColorValues] = useState<StateColorObject>(defaultStateColorObject);
    const [initialized, setInitialized] = useState(false);
    const [currentSelectionId, setCurrentSelectionId] = useState(selectionId);

    const onEyeDropColorChoice = useCallback(
        (designer: Designer, selection: ItemSelection, newColor: string) => {
            // We don't have an example of a multi-color product that uses pantone colors - they're all single-color
            // If we ever do allow multiple pantone colors this logic will need to be updated to support pantone as well as rgb
            const updatedColors = calculateColorValues(convertToRgb(newColor), ColorSpace.RGB);
            updateLastSelectedColor(convertToSelectableColor(updatedColors.rgb));
            setCurrentColorValues(updatedColors);

            if (eyeDropperConfig?.onClick) {
                eyeDropperConfig.onClick(newColor);
            }
        },
        [eyeDropperConfig, updateLastSelectedColor]
    );

    const onColorChange = useCallback(
        (newColor: string | CMYK | RGB | HSV, colorSpace: ColorSpace) => {
            const updatedColors = calculateColorValues(newColor, colorSpace);

            if (
                colorSpace !== ColorSpace.NoColor &&
                !(colorSpace === ColorSpace.HEX && (newColor as string).length < 6)
            ) {
                updateLastSelectedColor(
                    updatedColors.other
                        ? convertToSelectableColor(
                              updatedColors.other,
                              paletteColors.find(c => updatedColors.other === c.value)?.cssBackground
                          )
                        : convertToSelectableColor(
                              colorSpace === ColorSpace.CMYK ? updatedColors.cmyk : updatedColors.rgb
                          )
                );
            }
            setCurrentColorValues(updatedColors);
            onChange(isHsv(newColor) || colorSpace === ColorSpace.HEX ? updatedColors.rgb : newColor);
        },
        [setCurrentColorValues, onChange, updateLastSelectedColor, paletteColors]
    );

    const trackComponentUsage = (component: string) => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_COLOR_PICKER_PANEL,
            label: `Click ${component} to change color`,
            extraData: () => ({ panelType, component })
        });
    };

    const renderEyeDropper = () => {
        if (!eyeDropperConfig) {
            return null;
        }
        return (
            <EyeDropper
                update={onEyeDropColorChoice}
                isCompatibleWithSelection={eyeDropperConfig.isCompatibleWithSelection}
                listenEvents={eyeDropperConfig.listenEvents}
                buttonSize={eyeDropperConfig.buttonSize}
                trackUsage={trackComponentUsage}
            />
        );
    };

    useEffect(() => {
        // Only do this on initial load of the panel or if the selection changes
        if (value && (!initialized || selectionId !== currentSelectionId)) {
            const parsedColor = parseColor(value);
            if (parsedColor && parsedColor.value) {
                if (value === NO_COLOR_VALUE) {
                    parsedColor.value = NO_COLOR_VALUE;
                    parsedColor.colorSpace = ColorSpace.NoColor;
                }

                const updatedColors = calculateColorValues(parsedColor.value, parsedColor.colorSpace);
                setCurrentColorValues(updatedColors);
            }
            setInitialized(true);
            setCurrentSelectionId(selectionId);
        }

        if (!designer) {
            return;
        }

        // Update the color panel on undo and redo
        const updateColorInPanel = () => {
            setInitialized(false);
            updateCurrentColor && updateCurrentColor();
        };
        designer.eventBus.on(designer.eventBus.events.undo, updateColorInPanel);
        designer.eventBus.on(designer.eventBus.events.redo, updateColorInPanel);
        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(designer.eventBus.events.undo, updateColorInPanel);
            designer.eventBus.off(designer.eventBus.events.redo, updateColorInPanel);
        };
    }, [selectionId, value, initialized, currentSelectionId, designer, updateCurrentColor, setCurrentColorValues]);

    return (
        <ColorPickerPanelContent
            {...props}
            onColorChange={onColorChange}
            currentColorValues={currentColorValues}
            renderEyeDropper={renderEyeDropper}
            trackComponentUsage={trackComponentUsage}
        />
    );
}

ColorPickerPanelWrapper.displayName = "ColorPickerPanelWrapper";
