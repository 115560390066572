import React, { useEffect, useState } from "react";
import { TextInput } from "@vp/swan";
import classNames from "classnames";
import { hex2rgb } from "@design-stack-ct/utility-core";
import { ColorSpace } from "@shared/features/ColorPicker";
import * as styles from "./HexColorInput.module.scss";

interface Props {
    /** Currently selected color */
    value?: string;
    /** Function called when a valid hex has been entered */
    onChange?: (newValue: string, colorSpace: ColorSpace) => void;
    /** Optional class names to add to component */
    className?: string;
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
}

export function HexColorInput({ onChange = () => {}, value = "000000", className, trackUsage }: Props) {
    const [hexValue, setHexValue] = useState(value);

    const onHexValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.currentTarget.value.replace("#", "");
        if (!/^[0-9A-F]{0,6}$/i.test(input)) {
            return;
        }
        setHexValue(input);

        const updatedHex = e.currentTarget.value.startsWith("#") ? e.currentTarget.value : `#${e.currentTarget.value}`;

        if (updatedHex.match(/^#([a-f0-9]{3}|[a-f0-9]{6})$/i)) {
            const newRgb = hex2rgb(updatedHex);
            if (newRgb) {
                onChange(updatedHex, ColorSpace.HEX);
                if (trackUsage) {
                    trackUsage("Hex Input", updatedHex);
                }
            }
        }
    };

    const removeHash = (val: string) => {
        if (val.startsWith("#")) {
            return val.substring(1);
        }
        return val;
    };

    useEffect(() => {
        if (/^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(value)) {
            setHexValue(value);
        }
    }, [value]);

    return (
        <TextInput
            className={classNames(styles.hexColorInput, className)}
            size="mini"
            value={`#${removeHash(hexValue)}`}
            onChange={onHexValueChange}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                event.target.select();
            }}
        />
    );
}

HexColorInput.displayName = "HexColorInput";
