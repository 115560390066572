import React, { useCallback, useEffect, useState } from "react";
import Gallery from "react-photo-gallery";
import { FlexBox, Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages, Trans } from "@vp/i18n-helper";
import { DEFAULT_PAGE_SIZE, Status, StockImagesProvider } from "@design-stack-vista/image-library-react";
import { InfiniteScrollList, LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { ImageSecondGallery } from "@five/components/Panels/Images/ImageSecondGallery";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector } from "@shared/redux";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { renderImage } from "../../../components/Panels/Images/ImageRenderer";
import { useElementsPanel } from "./ElementsPanelProvider";
import * as styles from "./ElementsPanel.module.scss";

const messages = defineMessages({
    noResultsImages: {
        id: "studio.features.elements.noResultsImages",
        defaultMessage: `Sorry, we couldn't find any matches for <bold>"[[term]]"</bold>.`,
        description: {
            note: "Message shown when no images are found that match search term"
        }
    },
    noResultsImagesDescription: {
        id: "studio.features.elements.noResultsImagesDescription",
        defaultMessage: `Please check your spelling or try searching with a different word.`,
        description: {
            note: "Description shown when no images are found that match search term"
        }
    },
    errorImages: {
        id: "studio.features.elements.errorImages",
        defaultMessage: `We couldn't load the images - please try again.`,
        description: {
            note: "Message shown when there is an error loading images"
        }
    },
    resultsNoneWithFilters: {
        id: "studio.features.elements.noResultsWithFilters",
        defaultMessage: `We couldn't find results for <bold>"[[term]]"</bold> using your current filters.`,
        description: {
            note: "Message shown in results when no images are found and a filter is applied"
        }
    }
});

type onNextCallback = (() => void) | undefined;

export const ElementsPanelContentImages = () => {
    const {
        imageGalleryItems,
        imageStatus,
        currentElementsPanelSearchTerm,
        hasMoreItems,
        performNextSearch,
        currentFilterOptions
    } = useElementsPanel();
    const { t } = useTranslationSSR();
    const [secondGalleryCount, setSecondGalleryCount] = useState<number>(0);
    const [secondaryGetNext, setSecondaryGetNext] = useState<onNextCallback>(undefined);
    const [secondaryHasMore, setSecondaryHasMore] = useState<boolean>(false);
    const { auth } = useIdentityContext();
    const locale = useAppSelector(state => state.locale);
    const { isXerox } = useXerox();

    const onNext = () => {
        performNextSearch();
    };

    const getSecondGalleryInfo = useCallback((count: number, hasMore: boolean) => {
        setSecondGalleryCount(count);
        setSecondaryHasMore(hasMore);
    }, []);

    useEffect(() => {
        setSecondGalleryCount(0);
        setSecondaryHasMore(false);
        setSecondaryGetNext(undefined);
    }, [currentFilterOptions, currentElementsPanelSearchTerm]);

    const usingSecondGallery = !!currentFilterOptions && !hasMoreItems && imageStatus === Status.Idle && isXerox;

    return (
        <InfiniteScrollList
            count={imageGalleryItems.length + secondGalleryCount}
            id="elements-image-gallery"
            className={styles.elementsGallery}
            onNext={() => {
                if (hasMoreItems) {
                    onNext();
                } else if (secondaryHasMore) {
                    secondaryGetNext?.();
                }
            }}
            hasMore={hasMoreItems || secondaryHasMore}
        >
            {imageStatus === Status.Error && (
                <Typography textAlign="center" fontSize="1" paddingTop={3} paddingX={6} paddingBottom={2}>
                    {t(messages.errorImages.id)}
                </Typography>
            )}
            {imageStatus === Status.Loading && imageGalleryItems.length === 0 && (
                <FlexBox flexDirection="column" pt={5} pb={2}>
                    <LoadingSpinner variant={SpinnerVariant.Large} centering />
                </FlexBox>
            )}
            {imageGalleryItems.length > 0 && (
                <Gallery
                    photos={imageGalleryItems}
                    direction={"column"}
                    columns={2}
                    renderImage={renderImage}
                    margin={5}
                />
            )}
            {imageGalleryItems.length === 0 &&
                imageStatus === Status.Idle &&
                (currentFilterOptions ? (
                    <>
                        <Typography textAlign="center" fontSize="1">
                            <Trans
                                t={t}
                                i18nKey={messages.resultsNoneWithFilters.id}
                                components={{ bold: <strong /> }}
                                values={{ term: currentElementsPanelSearchTerm }}
                            />
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography textAlign="center" fontSize="1">
                            <Trans
                                t={t}
                                i18nKey={messages.noResultsImages.id}
                                components={{ bold: <strong /> }}
                                values={{ term: currentElementsPanelSearchTerm }}
                            />
                        </Typography>
                        <Typography mt={1} textAlign="center" fontSize="-1" paddingBottom={2}>
                            {t(messages.noResultsImagesDescription.id)}
                        </Typography>
                    </>
                ))}
            {usingSecondGallery && (
                <>
                    <StockImagesProvider authToken={auth.getToken()} pageSize={DEFAULT_PAGE_SIZE} locale={locale}>
                        <ImageSecondGallery
                            searchTerm={currentElementsPanelSearchTerm}
                            setGalleryItemsCount={getSecondGalleryInfo}
                            setPeformNextSearch={setSecondaryGetNext}
                        />
                    </StockImagesProvider>
                </>
            )}
        </InfiniteScrollList>
    );
};

ElementsPanelContentImages.displayName = "ElementsPanelContentImages";
