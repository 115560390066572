import { useState, useEffect } from "react";
import { useAppSelector } from "@shared/redux";

export interface ColorSwatch {
    color: string;
    designId: string;
}

const removeDuplicates = (colorSwatches: ColorSwatch[]) => {
    return colorSwatches.filter((v, i, a) => a.findIndex(v2 => v2.color === v.color) === i);
};

export function useTemplateColorVariations() {
    const templateColorVariations = useAppSelector(state => state.secondaryConfig?.templateColorVariations);
    const [colorSwatches, setColorSwatches] = useState<ColorSwatch[]>([]);
    const [hasTemplateColors, setHasTemplateColors] = useState(false);
    const activeTemplate = useAppSelector(state => state.template);

    useEffect(() => {
        const mappedColors = removeDuplicates(
            (templateColorVariations || [])
                .filter(item => item.color !== null)
                .map(item => ({
                    color: item.color,
                    designId: item.templateToken
                }))
        );

        setColorSwatches(mappedColors);
        setHasTemplateColors(mappedColors.length > 0);
    }, [templateColorVariations]);

    return { templateColorVariations, colorSwatches, hasTemplateColors, activeTemplate };
}
