/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}
export function ResetIcon(props: Props) {
    return (
        <svg {...props} viewBox="0 0 16 16" fill="none">
            <path d="M8.00016 6.44785L5.84766 4.49785L8.00016 2.54785" stroke="currentColor" strokeLinecap="round" />
            <path
                d="M3.73242 8.76555C3.73242 9.61305 3.97992 10.438 4.45242 11.1355C4.92492 11.8405 5.58492 12.3805 6.36492 12.7105C7.14492 13.033 7.99992 13.1155 8.83242 12.9505C9.65742 12.7855 10.4224 12.3805 11.0149 11.7805C11.6149 11.1805 12.0199 10.423 12.1849 9.59805C12.3499 8.77305 12.2674 7.91055 11.9449 7.13055C11.6224 6.35055 11.0749 5.68305 10.3699 5.21805C9.67242 4.74555 8.84742 4.49805 7.99992 4.49805H5.84742"
                stroke="currentColor"
                strokeLinecap="round"
            />
        </svg>
    );
}
ResetIcon.displayName = "ResetIcon";
