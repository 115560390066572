import React, { useEffect } from "react";
import { useSidebarNavConfiguration } from "@five/components/Redesign/FloatingSidebar/useSidebarNavConfiguration";
import { useActiveDialog } from "@shared/features/ActiveDialog";
import { useAppSelector } from "@shared/redux";
import { useDesignDialog } from "@shared/features/DesignPanel";
import { SideBarNav } from "./SideBarNav";
import { SideBarNavButton } from "./SideBarNavButton";
import { SideBarPanel } from "./SideBarPanel";
import * as style from "./SideBar.module.scss";

export const SideBar = () => {
    const { sidebarPanelButtonConfiguration, defaultPanelDialogType } = useSidebarNavConfiguration();
    const { setCurrentActiveDialog } = useActiveDialog();
    const { isCanvasBlank } = useDesignDialog();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const disableButtons = isCanvasBlank || !easelLoaded;

    // Select the default panel dialog if there is no dialog set
    useEffect(() => {
        if (defaultPanelDialogType) {
            setCurrentActiveDialog(defaultPanelDialogType);
        }
    }, [defaultPanelDialogType, setCurrentActiveDialog]);

    return (
        <div className={style.container}>
            <SideBarNav>
                {sidebarPanelButtonConfiguration.map((buttonSection, index) => (
                    <React.Fragment key={`${buttonSection.map(button => button.contentType).join()}`}>
                        {index > 0 && <div className={style.divider} />}
                        {buttonSection.map(({ rendered, disabled, ...buttonProps }) => {
                            return (
                                rendered && (
                                    <SideBarNavButton
                                        key={buttonProps.contentType}
                                        disabled={disableButtons || disabled}
                                        {...buttonProps}
                                    />
                                )
                            );
                        })}
                    </React.Fragment>
                ))}
            </SideBarNav>
            <SideBarPanel className={style.panelContainer} />
        </div>
    );
};

SideBar.displayName = "SideBar";
