import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Hidden, List, ListItem, Button } from "@vp/swan";
import {
    FinishMessages,
    FlexibilityFeatEnabledFinishMessages,
    PremiumFinishSpotColors,
    PremiumFinishWarning
} from "@shared/features/PremiumFinish";
import { StickyActionBar } from "@shared/features/StudioChrome";
import { useAppSelector } from "@shared/redux";
import { useStudioFlexibility } from "@shared/features/Flexibility";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { PremiumFinishSelectionElement } from "./PremiumFinishSelectionElement";
import { PremiumFinishBackButton } from "./PremiumFinishBackButton";
import sharedMessages from "./messages";
import { usePremiumFinishWarning } from "./usePremiumFinishFilter";
import "./premiumFinishSelection.scss";

const messages = defineMessages({
    doneButton: {
        id: "studio.components.premiumFinish.doneButton",
        defaultMessage: "Done",
        description: {
            note: "label of bottom button in premium finish update drawer"
        }
    }
});

type Props = {
    finishType: string;
    onClose: () => void;
};

const PremiumFinishDescriptionList = ({
    finishType,
    isFlexibilityFeatureEnabled
}: {
    finishType: string;
    isFlexibilityFeatureEnabled: boolean;
}) => {
    const { t } = useTranslationSSR();
    const ProductFinishMessages = isFlexibilityFeatureEnabled ? FlexibilityFeatEnabledFinishMessages : FinishMessages;

    switch (finishType) {
        case PremiumFinishSpotColors.Metallic:
            return (
                <>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem1.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem2.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem3.id)}</ListItem>
                </>
            );
        case PremiumFinishSpotColors.RaisedInk:
            return (
                <>
                    <ListItem>{t(ProductFinishMessages.RaisedInkFinishDescriptionListItem1.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.RaisedInkFinishDescriptionListItem2.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.RaisedInkFinishDescriptionListItem3.id)}</ListItem>
                </>
            );
        case PremiumFinishSpotColors.RaisedFoilGold:
            return (
                <>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem1.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem2.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem3.id)}</ListItem>
                </>
            );
        case PremiumFinishSpotColors.RaisedFoilSilver:
            return (
                <>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem1.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem2.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem3.id)}</ListItem>
                </>
            );
        case PremiumFinishSpotColors.RaisedFoilGlitterSilver:
            return (
                <>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem1.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem2.id)}</ListItem>
                    <ListItem>{t(ProductFinishMessages.MetallicFinishDescriptionListItem3.id)}</ListItem>
                </>
            );
        default:
            return (
                <>
                    <ListItem>{t(FinishMessages.defaultFinishDescriptionListItem1.id)}</ListItem>
                    <ListItem>{t(FinishMessages.defaultFinishDescriptionListItem2.id)}</ListItem>
                    <ListItem>{t(FinishMessages.defaultFinishDescriptionListItem3.id)}</ListItem>
                </>
            );
    }
};

export function PremiumFinishSelection({ finishType, onClose }: Props) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const premiumFinishModalOpenContext = useAppSelector(state => state.premiumFinishModalOpenContext);
    const { isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isFlexibilityFeatureEnabled = isMileStone2Enabled || isMileStone3Enabled;
    const warning = usePremiumFinishWarning();

    return (
        <div className="premium-finish-selection">
            <div className="premium-finish-selection-content">
                <PremiumFinishBackButton />
                <Hidden xs md lg xl>
                    <Typography fontSize="5" textAlign="left" mb={5} mt={4} fontWeight="normal">
                        {t(FinishMessages[finishType].id)}
                    </Typography>
                </Hidden>
                <Hidden sm md lg xl>
                    <Typography fontSize="4" textAlign="left" mb={2} mt={3} fontWeight="normal">
                        {t(FinishMessages[finishType].id)}
                    </Typography>
                </Hidden>
                <Hidden xs sm>
                    <PremiumFinishWarning
                        showWarning={warning}
                        message={t(sharedMessages.errorMessage.id, { finishType: t(FinishMessages[finishType].id) })}
                    />
                </Hidden>
                <Hidden xs sm>
                    <Typography
                        fontSize="4"
                        textAlign="left"
                        mb={5}
                        mt={premiumFinishModalOpenContext ? 6 : 4}
                        fontWeight="normal"
                    >
                        {t(FinishMessages[finishType].id)}
                    </Typography>
                </Hidden>
                <List className="premium-finish-selection__desc" mb={5} skin="standard">
                    <PremiumFinishDescriptionList
                        finishType={finishType}
                        isFlexibilityFeatureEnabled={isFlexibilityFeatureEnabled}
                    />
                </List>
                <PremiumFinishSelectionElement />
            </div>
            {isFlexibilityFeatureEnabled && (
                <StickyActionBar className="premium-finish-done-btn">
                    <Button skin="primary" size={isSmall ? "mini" : "standard"} width="full-width" onClick={onClose}>
                        {t(messages.doneButton.id)}
                    </Button>
                </StickyActionBar>
            )}
        </div>
    );
}

PremiumFinishSelection.displayName = "PremiumFinishSelection";
PremiumFinishDescriptionList.displayName = "PremiumFinishDescriptionList";
