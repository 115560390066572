import { useEffect, useState } from "react";
import { useActiveCanvas } from "@designer-suite";
import { convertPxToMm, getMaskByPathType } from "@utilities";
import debounce from "lodash/debounce";
import { useIsMailingPostCard } from "@shared/features/MailingServices";

type MousePositionProps = {
    offsetX: number;
    offsetY: number;
};

type BleedPath = {
    endpointX: number;
    endpointY: number;
    ordinal: number;
};

const useMousePositionOnUSPSArea = () => {
    const canvas = useActiveCanvas();
    const [mousePosition, setMousePosition] = useState<MousePositionProps>({
        offsetX: 0,
        offsetY: 0
    });
    const [isMouseOnUSPSArea, setIsMouseOnUSPSArea] = useState<boolean>();
    const isMailingPostCard = useIsMailingPostCard();

    useEffect(() => {
        if (!canvas || !isMailingPostCard) {
            return;
        }
        const {
            mmDimensions: { height, width },
            _canvasViewModel,
            name
        } = canvas;

        if (name !== "Back") {
            setIsMouseOnUSPSArea(false);
            return;
        }

        const handleMouseMoveOnCanvas = debounce((mouseEvent: MouseEvent) => {
            const zoomFactor = _canvasViewModel.get("zoomFactor");
            const bleedPaths = getMaskByPathType({ model: _canvasViewModel.model, pathType: "BLEED" });
            const { offsetX, offsetY } = mouseEvent;
            const mousePositionXinMM = convertPxToMm(offsetX, zoomFactor);
            const mousePositionYinMM = convertPxToMm(offsetY, zoomFactor);
            const currentPath =
                bleedPaths &&
                bleedPaths.find(({ endpointY, ordinal }: BleedPath) => {
                    return endpointY > mousePositionYinMM || bleedPaths.length === ordinal;
                });

            if (
                currentPath &&
                mousePositionXinMM > currentPath.endpointX &&
                Math.round(mousePositionXinMM) <= width &&
                Math.round(mousePositionYinMM) <= height
            ) {
                setIsMouseOnUSPSArea(true);
                setMousePosition({ offsetX: width * zoomFactor - offsetX, offsetY });
            } else {
                setIsMouseOnUSPSArea(false);
            }
        }, 2000);

        window.addEventListener("mousemove", e => {
            setIsMouseOnUSPSArea(false);
            handleMouseMoveOnCanvas(e);
        });
        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener("mousemove", handleMouseMoveOnCanvas);
        };
    }, [canvas, isMailingPostCard]);

    return { isMouseOnUSPSArea, mousePosition };
};

export { useMousePositionOnUSPSArea };
useMousePositionOnUSPSArea.displayName = "useMousePositionOnUSPSArea";
