import React, { useCallback, useEffect, useState } from "react";
import Gallery from "react-photo-gallery";
import { Typography } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { DraggableUploadsPanelProvider, useDesigner } from "@designer-suite";
import { useStockImages } from "@design-stack-vista/image-library-react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAsync } from "react-async-hook";
import { InfiniteScrollList, LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";
import {
    calculatePanelHeight,
    createGalleryItems,
    DiscoveryPhotoItem,
    getRecentlyUsedImages,
    getShowRecentlyUsedImages
} from "./ImagePanelUtils";
import { renderImage } from "./ImageRenderer";
import { getDefaultImages } from "./DefaultImagesIds";

interface ImagePanelProps {
    onDone?: () => void;
    fullHeight: boolean;
}

const defaultImages = getDefaultImages();
let initialShowRecentImages = false;
getShowRecentlyUsedImages().then(show => {
    initialShowRecentImages = show;
});
interface PanelHeight extends React.CSSProperties {
    "--default-container-height": string;
}

export const ImageDefaultPanel = (props: ImagePanelProps) => {
    const { onDone, fullHeight } = props;
    const { t } = useTranslationSSR();
    const [galleryItems, setGalleryItems] = useState<DiscoveryPhotoItem[]>([]);
    const designer = useDesigner();
    const { performInitialSearch, imageItems } = useStockImages();
    const { isSmall } = useStudioLayout();
    useEffect(() => {
        if (!designer) {
            return;
        }
        setGalleryItems(createGalleryItems(imageItems, designer, onDone));
    }, [designer, onDone, imageItems]);

    const [showRecentImages, setShowRecentImages] = useState(initialShowRecentImages);

    const getImageDataFromDP = useCallback(
        (items: string) => {
            performInitialSearch(items);
        },
        [performInitialSearch]
    );

    useAsync(async () => {
        let useRecent = showRecentImages;
        if (!useRecent) {
            useRecent = await getShowRecentlyUsedImages();
            setShowRecentImages(useRecent);
        }
        if (useRecent) {
            getRecentlyUsedImages().then(getImageDataFromDP);
            return;
        }
        getImageDataFromDP(defaultImages);
    }, [showRecentImages, getImageDataFromDP]);

    const height = calculatePanelHeight({
        isFullHeight: fullHeight,
        isMobile: isSmall,
        isDefaultPanel: true,
        isRecentlyUsed: showRecentImages
    });

    const style: PanelHeight = {
        "--default-container-height": `${height}px`
    };

    return (
        <div className="default-panel-content-container" style={style}>
            {galleryItems.length === 0 ? (
                <LoadingSpinner className="my-3" variant={SpinnerVariant.Large} centering />
            ) : (
                <DraggableUploadsPanelProvider isDraggable={!isSmall}>
                    <InfiniteScrollList count={galleryItems.length} id={"default-image-gallery"}>
                        {showRecentImages ? (
                            <Typography
                                textAlign="left"
                                fontSize="1"
                                paddingTop={3}
                                paddingBottom={3}
                                textColor="dark-grey"
                            >
                                {t(imageLibraryPanelMessages.searchResultsRecentHeading.id)}
                            </Typography>
                        ) : (
                            <Typography textAlign="center" fontSize="1" paddingTop={3} paddingBottom={5} paddingX={6}>
                                {t(imageLibraryPanelMessages.intro.id)}
                            </Typography>
                        )}
                        <Gallery
                            photos={galleryItems}
                            direction={"column"}
                            columns={2}
                            renderImage={renderImage}
                            margin={5}
                        />
                    </InfiniteScrollList>
                </DraggableUploadsPanelProvider>
            )}
        </div>
    );
};

ImageDefaultPanel.displayName = "ImageDefaultPanel";
