import { type SelectableColor, ColorSwatch } from "@shared/features/ColorPicker";
import { Typography } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { scrollToRef } from "@shared/utils/WebBrowser";
import { HorizontalScroller } from "@shared/features/StudioChrome";
import { singleColorModalMessages } from "./singleColorMessages";
import { isInViewport } from "../../../utilities/Utilities";
import "./mobileSingleColorPalette.scss";

interface Props {
    paletteColors: SelectableColor[];
    currentColor?: SelectableColor;
    onChange: (color: string) => void;
}
export function MobileSingleColorPalette({ paletteColors, currentColor, onChange }: Props) {
    const { t } = useTranslationSSR();
    const currentSwatchRef = useRef<HTMLElement>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const [rowsNeeded, setRowsNeeded] = useState(3);

    useEffect(() => {
        if (currentSwatchRef && currentSwatchRef.current && currentColor) {
            if (!isInViewport(currentSwatchRef.current)) {
                scrollToRef(parentRef, currentSwatchRef, 0, false, "smooth");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentColor, currentSwatchRef, currentSwatchRef.current]);

    useLayoutEffect(() => {
        if (parentRef && parentRef.current) {
            // The swatches are 40px wide.  There is an additional 11px margin on the left
            setRowsNeeded(Math.min(Math.ceil((paletteColors.length * 40 - 11) / parentRef.current.clientWidth), 3));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paletteColors.length, parentRef, parentRef.current]);

    return (
        <>
            <div className="mobile-single-color-palette__container">
                <Typography fontSize="-1">{t(singleColorModalMessages.chooseColor.id)}</Typography>
            </div>
            {/* @ts-ignore */}
            <div className="mobile-single-color-palette__palette-swatches" style={{ "--rows-needed": rowsNeeded }}>
                <HorizontalScroller ref={parentRef}>
                    {paletteColors?.map(color => {
                        return (
                            <ColorSwatch
                                ref={color.value === currentColor?.value ? currentSwatchRef : undefined}
                                className="mobile-single-color-palette__color-swatch"
                                key={color.value}
                                isSelected={color.value === currentColor?.value}
                                color={color}
                                toggleColor={(color: SelectableColor) => {
                                    onChange(color.value);
                                }}
                            />
                        );
                    })}
                </HorizontalScroller>
            </div>
        </>
    );
}

MobileSingleColorPalette.displayName = "MobileSingleColorPalette";
