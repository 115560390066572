import React from "react";
import { Button } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setDocumentUrl, setShowSaveAs } from "@shared/redux";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { DuplicateProjectIcon } from "@shared/features/StudioChrome";
import { projectNamingRelatedMessages } from "@shared/features/MyProjects";
import { Save, SaveDesignWithSaveDocumentOmitted, UseSaveParams } from "./useSave";

const duplicateMessages = defineMessages({
    duplicateDesign: {
        id: "studio.components.globalToolbar.duplicateDesign",
        defaultMessage: "Duplicate design",
        description: {
            note: "Label for button to duplicate a project from the project dropdown"
        }
    }
});

interface Props {
    className?: string;
    saveDesign: SaveDesignWithSaveDocumentOmitted;
    useSave: (props: Omit<UseSaveParams, "saveDesign">) => Save;
}

export function DuplicateProjectButton({ className, saveDesign, useSave }: Props) {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const isAnonymousUser = useIsAnonymousUser();
    const { auth, identity } = useIdentityContext();
    const workId = useAppSelector(state => state.workId);
    const productname = useAppSelector(state => state.productName);
    const save = useSave({
        onSaveCallback: () => dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: true })),
        hideSaveToast: true,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_DUPLICATE_PROJECT
    });

    const handleClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_DUPLICATE_DESIGN,
            label: "Duplicate Project Button"
        });
        const defaultProjectName = t(projectNamingRelatedMessages.projectName.id, { productname });
        if (workId) {
            dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: true }));
            // have to remove any existing document url so that we don't save to the same root work
            dispatch(setDocumentUrl(null));
        } else if (isAnonymousUser) {
            saveDesign({ authToken: auth.getToken(), identity, newWorkName: defaultProjectName }).then(() => {
                dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: true }));
            });
        } else {
            save(defaultProjectName);
        }
    };

    return (
        <Button className={className} skin="unstyled" onClick={handleClick}>
            <DuplicateProjectIcon aria-hidden={true} />
            {t(duplicateMessages.duplicateDesign.id)}
        </Button>
    );
}

DuplicateProjectButton.displayName = "DuplicateProjectButton";
