// https://medium.com/tamman-inc/create-a-reusable-focus-lock-in-react-to-improve-user-experience-and-accessibility-90829426fae2

import React, { useEffect, useRef, HTMLAttributes } from "react";

const TAB_KEY = 9;
const ESC_KEY = 27;
const F6_KEY = 117;

interface Props extends HTMLAttributes<HTMLDivElement> {
    isLocked?: boolean;
    handleClose?: () => void;
    focusToggleElement?: HTMLElement | null;
}

export function FocusLock({ isLocked = true, handleClose, focusToggleElement, children, ...otherProps }: Props) {
    const rootNode = useRef<HTMLDivElement>(null);
    const focusableItems = useRef<NodeListOf<HTMLElement> | []>([]);

    useEffect(() => {
        if (!rootNode.current) {
            return;
        }

        const updateFocusableItems = () => {
            focusableItems.current =
                rootNode.current?.querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video'
                ) || [];
        };

        const observer = new MutationObserver(() => {
            updateFocusableItems();
        });
        updateFocusableItems();
        observer.observe(rootNode.current, { childList: true });
        // eslint-disable-next-line consistent-return
        return () => {
            observer.disconnect();
        };
    }, [rootNode, isLocked]);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (!focusableItems.current || !isLocked) return;

            const { keyCode, shiftKey } = event;
            const { length, 0: firstItem, [length - 1]: lastItem } = focusableItems.current;

            if (keyCode === TAB_KEY) {
                // If only one item then prevent tabbing when locked
                if (length === 1) {
                    event.preventDefault();
                    return;
                }

                // If focused on last item then focus on first item when tab is pressed
                if (!shiftKey && document.activeElement === lastItem) {
                    event.preventDefault();
                    firstItem.focus();
                    return;
                }

                // If focused on first item then focus on last item when shift + tab is pressed
                if (shiftKey && document.activeElement === firstItem) {
                    event.preventDefault();
                    lastItem.focus();
                }
            }

            if (keyCode === ESC_KEY) {
                handleClose?.();
            }

            // toggle focus between dialog and content when F6 is pressed
            if (keyCode === F6_KEY && focusToggleElement) {
                if (rootNode.current?.contains(document.activeElement)) {
                    // if focus is inside the dialog, move focus to an element outside the dialog
                    focusToggleElement?.focus();
                } else {
                    // if focus is no currently inside the dialog, move it to the first item
                    event.preventDefault();
                    firstItem?.focus();
                }
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [isLocked, focusableItems, handleClose, focusToggleElement]);

    return (
        <div {...otherProps} ref={rootNode}>
            {children}
        </div>
    );
}

FocusLock.displayName = "FocusLock";
