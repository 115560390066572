import React, { useRef, useState, useCallback } from "react";
import {
    useClickOutside,
    PanelTitle,
    PanelDescription,
    ViewIcon,
    CopyIcon,
    Sheet,
    Button,
    HorizontalDivider
} from "@shared/features/StudioChrome";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setShowSaveAs } from "@shared/redux";
import { MyProjectsButton } from "@shared/features/MyProjects";
import { useSmartValidations } from "@shared/features/SmartValidations";
import {
    useStudioFlexibility,
    useActiveFlexibilityOptions,
    FlexibilityDesignAttributes,
    ChangeSizeButton,
    isShowChangeSizeBtnEnabled
} from "@shared/features/Flexibility";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { ChangeTemplateButton, useShowChangeTemplate } from "@shared/features/ChangeTemplate";
import { PreviewButtonWithSkeleton } from "@shared/features/Previews";
import { DownloadButton, SheetPanelWrapper } from "@shared/features/GlobalHeader";
import { SaveButtonSmallScreen } from "@shared/features/Save";
import { useStudio5Save } from "@five/hooks/useStudio5Save";
import { SpecsAndTemplatesButton, useProductHasSpecsAndTemplates } from "@shared/features/SpecsAndTemplates";
import { redesignMessages } from "../Redesign/redesignMessages";
import { MailingButton } from "../MailingServices/MailingButton";
import { SmartValidationsButtonMobile } from "../Validation/SmartValidationButtonMobile";
import { useValidations } from "../Validation/ValidationProvider";
import { saveStudio5Design } from "../../clients/saveClient";
import "./FileSheet.scss";

export const messages = defineMessages({
    mobileFileSheetProjectName: {
        id: "studio.components.myprojects.mobilefilesheet.mobileFileSheetProjectName",
        defaultMessage: "My [[productName]] design",
        description: {
            note: "Used for default project name when. For example - My Business card design, My leaflet and Flyover design"
        }
    },
    mobileFileSheetSaveAs: {
        id: "studio.components.myprojects.mobilefilesheet.saveAs",
        defaultMessage: "Duplicate design",
        description: {
            note: "Button to save a copy of the project"
        }
    }
});

export function FileSheet() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const [sheetHeight, setSheetHeight] = useState(0);
    const sheetRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const workName = useAppSelector(state => state.workName);
    const productName = useAppSelector(state => state.productName);
    const { isMileStone1Enabled, isMileStone2Enabled } = useStudioFlexibility();
    const { isDesignAttributeActive } = useActiveFlexibilityOptions();
    const isChangeSizeActive =
        isDesignAttributeActive(FlexibilityDesignAttributes.Size) && isShowChangeSizeBtnEnabled();
    const changeTemplate = useShowChangeTemplate();
    const { isSmartValidationsEnabled } = useSmartValidations();

    const { validationCount } = useValidations();
    const hasSpecsAndTemplate = useProductHasSpecsAndTemplates();

    const onSaveAsClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SAVE_AS_BUTTON,
            label: "Save As Button"
        });
        dispatch(setShowSaveAs({ showSaveAs: true }));
    };

    const generateNewWorkName = useCallback((): string | Array<String> => {
        let newWorkName = workName;
        if (workName === productName || !workName) {
            newWorkName = t(messages.mobileFileSheetProjectName.id, { productName });
            return newWorkName ?? "";
        }
        return newWorkName ?? "";
    }, [workName, productName, t]);
    const generatedWorkName = generateNewWorkName();

    useClickOutside(
        sheetRef,
        async () => {
            if (sheetHeight) {
                setCurrentActiveDialog(DialogType.None);
            }
        },
        [sheetHeight]
    );

    const reportSizeChanged = (rect: DOMRect) => {
        if (rect) {
            setSheetHeight(rect.height);
        }
    };

    return (
        <div className="file-sheet-panel" ref={sheetRef}>
            <Sheet
                limitHeight={false}
                reportSizeChange={reportSizeChanged}
                hideSheet={currentActiveDialog !== DialogType.File}
            >
                <SheetPanelWrapper contentType={DialogType.File} hidebackButton>
                    <div className="file-sheet-header">
                        <PanelTitle className="panel-title">{generatedWorkName}</PanelTitle>
                        <PanelDescription>{productName}</PanelDescription>
                    </div>
                    <ul className="file-sheet-buttons">
                        {isSmartValidationsEnabled && validationCount > 0 && (
                            <li>
                                <SmartValidationsButtonMobile className="sheet-panel-button" />
                                <HorizontalDivider />
                            </li>
                        )}
                        <li>
                            <SaveButtonSmallScreen
                                className="sheet-panel-button"
                                onSave={() => setCurrentActiveDialog(DialogType.None)}
                                useSave={useStudio5Save}
                            />
                        </li>
                        <li>
                            <Button className="sheet-panel-button" onClick={onSaveAsClick}>
                                <CopyIcon aria-hidden="true" />
                                <Typography>{t(messages.mobileFileSheetSaveAs.id)}</Typography>
                            </Button>
                        </li>
                        <li>
                            <MyProjectsButton className="sheet-panel-button" saveDesign={saveStudio5Design} />
                        </li>
                        <li>
                            <PreviewButtonWithSkeleton className="sheet-panel-button" />
                        </li>
                        <li>{hasSpecsAndTemplate && <SpecsAndTemplatesButton className="sheet-panel-button" />}</li>
                        <li>
                            <DownloadButton className="sheet-panel-button" />
                        </li>
                        <li>
                            <MailingButton className="sheet-panel-button" />
                        </li>
                        {!isMileStone2Enabled && isChangeSizeActive && (
                            <li>
                                <ChangeSizeButton className="sheet-panel-button" />
                            </li>
                        )}
                        {!isMileStone1Enabled && changeTemplate && (
                            <li>
                                <ChangeTemplateButton className="sheet-panel-button" />
                            </li>
                        )}
                        <li>
                            <Button
                                className="sheet-panel-button"
                                onClick={() => setCurrentActiveDialog(DialogType.Guides)}
                            >
                                <ViewIcon aria-hidden={true} />
                                <Typography>{t(redesignMessages.viewPanel.id)}</Typography>
                            </Button>
                        </li>
                    </ul>
                </SheetPanelWrapper>
            </Sheet>
        </div>
    );
}

FileSheet.displayName = "FileSheet";
