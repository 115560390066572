/* eslint-disable no-param-reassign */
import type { DSS } from "@vp/types-ddif";
import { FINISH_OPTION } from "@shared/features/PremiumFinish";
import type {
    AnyTextContent,
    CurveItem,
    CurveItemV2,
    EllipseItem,
    Item,
    LineItem,
    Panel,
    RectangleItem,
    TextAreaContent
} from "@design-stack-ct/cdif-types";
import { getTableFontColor, setTableFontColor } from "@shared/utils/Tables";
import { isRectangleItem } from "@design-stack-ct/cimdoc-state-manager";
import { Measurement } from "@design-stack-ct/utility-core";
import { MinFontSize, getMinMaxFontSizes } from "@shared/utils/StudioConfiguration";
import { isEmbroidery } from "@shared/features/Embroidery";

const DEFAULT_CROP_FRACTION = { bottom: "0", top: "0", left: "0", right: "0" };

// Checks the cimdoc (panels and saved panels) for the for a key/value matching the parameters
export function checkForString(cimDoc: DSS.DesignDocument, keyMatch: RegExp, valueMatch: RegExp) {
    if (!cimDoc) {
        return false;
    }
    return Object.keys(cimDoc)
        .map((key: string): any => {
            if (typeof cimDoc[key] === "object") {
                return checkForString(cimDoc[key], keyMatch, valueMatch);
            }
            if (
                !!key &&
                typeof key === "string" &&
                key.match(keyMatch) &&
                cimDoc[key] &&
                typeof cimDoc[key] === "string" &&
                cimDoc[key].match(valueMatch)
            ) {
                return true;
            }
            return false;
        })
        .some(results => results === true);
}

function recursivelyRemoveColorForRaisedFoil(content: AnyTextContent[]) {
    content.forEach((field: TextAreaContent) => {
        if (field.overprints && field.overprints.length > 0 && field.overprints[0].includes("RaisedFoil")) {
            field.overprints = field.overprints.slice(0, 1);
            // @ts-ignore (cdif types define color as a required field)
            delete field.color;
        }
        if (field.content && Array.isArray(field.content)) {
            recursivelyRemoveColorForRaisedFoil(field.content);
        }
    });
}

export function updateCimdocForRaisedFoilColor(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas.forEach(textArea => {
                // @ts-ignore
                recursivelyRemoveColorForRaisedFoil(textArea.content || textArea.textFields);
            });
        }
    });
}

export function fixTableUndefinedColor(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.itemReferences) {
            panel.itemReferences.forEach(itemReference => {
                const currentFontColor = getTableFontColor(itemReference?.data);
                if (!currentFontColor || currentFontColor === "undefined") {
                    setTableFontColor(itemReference.data, "rgb(0,0,0)");
                }
            });
        }
    });
}

// If the shape doesn't have a color on it, Designer created it to be a shape with no fill but a border
// We need to add the no color string to it so it doesn't break Studio
export function fixFillColorUndefined(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.shapes) {
            panel.shapes.forEach(shape => {
                if (isShapeWithColor(shape) && !shape.color) {
                    shape.color = "cmyka(0,0,0,0,0)";
                }
            });
        }
    });
}

export function replaceChooseForMeInstructions(cimDoc: DSS.DesignDocument) {
    if (cimDoc?.metadata?.reviewInstructions) {
        cimDoc?.metadata?.reviewInstructions.forEach(instr => {
            if (instr.finishOption === "Choose For Me") {
                instr.finishOption = FINISH_OPTION.TextAndImage;
            }
        });
    }
}

function isShapeWithColor(
    shape: RectangleItem | EllipseItem | CurveItem | CurveItemV2 | LineItem
): shape is RectangleItem | EllipseItem | CurveItem | CurveItemV2 {
    return shape.type !== "line";
}

function getItemsSortedByZOrder(panel: Panel): Item[] {
    const itemsSortedByZOrder = [];
    if (panel.images) {
        itemsSortedByZOrder.push(...panel.images);
    }
    if (panel.itemReferences) {
        itemsSortedByZOrder.push(...panel.itemReferences);
    }
    if (panel.textAreas) {
        itemsSortedByZOrder.push(...panel.textAreas);
    }
    if (panel.shapes) {
        itemsSortedByZOrder.push(...panel.shapes);
    }
    itemsSortedByZOrder.sort((a, b) => a.zIndex - b.zIndex);
    return itemsSortedByZOrder;
}

const FUZZ_FACTOR = 0.01;

// Check if the shape is full bleed.  We use a fuzz factor because close is good enough for us and horseshoes
// For each positional data we're fine if the shape extends wayyyyy beyond the bleed.  It doesn't need to kiss it.
function isFullBleed(item: RectangleItem, panel: Panel) {
    return (
        new Measurement(item.position.x).mm < FUZZ_FACTOR &&
        new Measurement(item.position.y).mm < FUZZ_FACTOR &&
        new Measurement(item.position.height).mm - new Measurement(panel.height).mm > -FUZZ_FACTOR &&
        new Measurement(item.position.width).mm - new Measurement(panel.width).mm > -FUZZ_FACTOR
    );
}

// Templates have full-bleed shapes at the lowest z-index that are not configured as backgrounds
// That can be confusing in studio where we do have backgrounds
// Update the shape so that we treat it as a background
export function fixBackgroundFromTemplate(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        // Only compare template items
        // When changing template, the items already on the canvas end up behind the background shape
        const itemsSortedByZOrder = getItemsSortedByZOrder(panel).filter(item => {
            const templateMetadataForItem = cimDoc.metadata?.template?.find(
                templateMetadata => templateMetadata.id === item.id
            );
            // We only want to run this for actual template items
            // Converting a shape that a user dragged to cover the background might be too confusing for them
            const isOriginalTemplateItem = templateMetadataForItem?.originalTemplateElementId;
            // If the element is hard locked than we can include those too.  The user didn't add them and cannot edit them.
            const isHardLocked =
                templateMetadataForItem?.locks &&
                "edit" in templateMetadataForItem.locks &&
                templateMetadataForItem.locks.edit;
            return isOriginalTemplateItem || isHardLocked;
        });
        if (itemsSortedByZOrder.length > 0) {
            const lowestItem = itemsSortedByZOrder[0];
            if (isRectangleItem(lowestItem) && isFullBleed(lowestItem, panel)) {
                const templateMetadataForItem = cimDoc.metadata?.template?.find(
                    templateMetadata => templateMetadata.id === lowestItem.id
                );

                if (templateMetadataForItem) {
                    // the z-index for background items
                    lowestItem.zIndex = -1001;
                    // @ts-expect-error background isn't defined our template metadata
                    templateMetadataForItem.background = true;
                }
            }
        }
    });
}

export function updateCimDocMinMaxFontSizes(cimDoc: DSS.DesignDocument, productMinFontSize?: MinFontSize) {
    const { decorationTechnology } = cimDoc.document.panels[0];
    const { minFontSize, maxFontSize } = getMinMaxFontSizes(decorationTechnology, productMinFontSize);

    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas.forEach(textArea => {
                const { fontSize, content } = textArea.content[0];
                const fontSizeNumerical = Number(parseFloat(fontSize));

                // only update to use minmax font sizes for empty fields
                // to prevent changing size for previously edited fields
                if (content.length === 0) {
                    if (fontSizeNumerical < minFontSize) {
                        textArea.content[0].fontSize = `${minFontSize}pt`;
                    } else if (fontSizeNumerical > maxFontSize) {
                        textArea.content[0].fontSize = `${maxFontSize}pt`;
                    }
                }
            });
        }
    });
}

// Crop settings for images in Studio5 and Studio6 for embroidery are stored in different places in the document,
// so to ensure compatibility, we need to convert the crop values to a format supported by Studio5
export function updateCimDocImagesCrop(cimDoc: DSS.DesignDocument) {
    if (!isEmbroidery(cimDoc.document.panels[0].decorationTechnology)) {
        return;
    }

    cimDoc.document.panels.forEach(panel => {
        panel.images?.forEach(({ cropFractions, id: imageId }) => {
            const imageDclMetadata = cimDoc.metadata?.dclMetadata?.find(({ id }) => id === imageId);
            if (cropFractions && imageDclMetadata && Object.values(cropFractions).some(value => value !== "0")) {
                Object.assign(imageDclMetadata, { crop: { ...cropFractions } });
                Object.assign(cropFractions, DEFAULT_CROP_FRACTION);
            }
        });
    });
}
