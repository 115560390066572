import React, { useMemo } from "react";
import type { ColorSwatch, Design } from "@shared/utils/Gallery";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { TemplatePanelImage } from "./TemplatePanelImage";

interface Props {
    design: Design;
}

export const TemplatePanelDesignTile = (props: Props) => {
    const { design } = props;
    const { colorSwatches, selectedColorSwatchDesignId } = design;
    const selectedColorSwatch = colorSwatches.find(
        ({ designId }: ColorSwatch) => designId === selectedColorSwatchDesignId
    ) as ColorSwatch;
    const { isXerox } = useXerox();
    const { isSmall } = useStudioLayout();

    const imageSize = useMemo(() => {
        if (isXerox) {
            return 143;
        }
        if (isSmall) {
            return 173;
        }
        return 136;
    }, [isXerox, isSmall]);

    return <TemplatePanelImage animateOnHover design={selectedColorSwatch || design} width={imageSize} />;
};

TemplatePanelDesignTile.displayName = "TemplatePanelDesignTile";
