/**
 * Add additional keyboard behaviors beyond what is built in
 * or add back in behaviors that we explicitely are not using (save)
 */
import { useEffect } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { updateSelectedItems } from "@utilities";
import { addQueryParam } from "@shared/utils/WebBrowser";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { Identity } from "@shared/utils/Identity";
import {
    Store,
    useAppSelector,
    useAppDispatch,
    setSaveSuccess,
    showDesignReview,
    showEditInCartModal
} from "@shared/redux";
import { EDIT_FROM_CART_MODAL_TYPE } from "@shared/utils/Cart";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useCartContext } from "@shared/features/Cart";
import { saveStudio5Design } from "../clients/saveClient";

function wrapKeyDownExtensions(auth: any, identity: Identity, callback?: () => void) {
    /**
     * Add additional / studio specific behavior for some keyDown events
     * @param {Event} event - a key down event
     */
    return function keyDownExtensions(event: KeyboardEvent) {
        const ctrlPressed = event.ctrlKey || event.metaKey;
        const selection = window.designer?.selectionManager.models.filter(model => model.attributes.selected);

        switch (event.which) {
            // Save the document on a <ctrl>s on pc or <metaKey>s on mac
            case 83: // s
                if (ctrlPressed) {
                    if (window && window.designer) {
                        saveStudio5Design({
                            authToken: auth.getToken(),
                            identity,
                            savedProjectTrackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_KEYBOARD
                        })
                            .then(() => {
                                if (!identity.isSignedIn) {
                                    window.history.replaceState(
                                        "update-workId",
                                        "Title",
                                        addQueryParam(window.location.href, "saveToast", "true")
                                    );
                                    auth.signIn();
                                } else if (callback) {
                                    callback();
                                } else {
                                    Store.dispatch(setSaveSuccess({ saveSuccess: true }));
                                }
                            })
                            .catch(e => {
                                Store.dispatch(setSaveSuccess({ saveSuccess: false }));
                                handleError(e, ERROR_CODES.SAVE_DOCUMENT);
                            });
                    }
                    event.preventDefault();
                }
                break;
            case 8: // backspace
            case 46: // delete
                if (window && window.designer && selection) {
                    // Quit deleting items when we're just using input or textareas
                    if (
                        (event.target as HTMLElement).tagName.toLowerCase() === "input" ||
                        (event.target as HTMLElement).tagName.toLowerCase() === "textarea"
                    ) {
                        break;
                    }
                    if (
                        selection.length > 1 ||
                        (selection[0]?.attributes?.module !== "TextFieldViewModel" &&
                            selection[0]?.model.get("type") !== "Table" &&
                            selection[0]?.model.get("type") !== "QR Code" &&
                            selection[0]?.model.get("type") !== "Word Art")
                    )
                        // if multiple items selected or 1 item selected that is not text or table or wordart
                        // remove items completely
                        // prevents keys from deleting item completely when editing text
                        updateSelectedItems(window.designer, selection, (item: MutableItem) => {
                            item.remove();
                        });
                }
                break;
            // no default
        }
    };
}

/**
 * Setup the hook for the extra keyboard behaviors
 */
export default function useKeyboardExtensions() {
    const dispatch = useAppDispatch();
    const { identity, auth } = useIdentityContext();
    const { isItemInCart } = useCartContext();
    const changesUpdatedModalSeen = useAppSelector(state => state.editInCartModal.changesUpdatedModalSeen);

    // Potentionally could also add "keyup" and "keypress" extensions
    useEffect(() => {
        let eventListener: (event: KeyboardEvent) => void;

        // if item is already in cart, the first time user saves, show changes updated modal
        if (isItemInCart && !changesUpdatedModalSeen) {
            const callback = () => {
                dispatch(
                    showEditInCartModal({
                        showModal: true,
                        modalType: EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED,
                        callback: () => dispatch(showDesignReview({ show: true })),
                        changesUpdatedModalSeen: true
                    })
                );
            };

            eventListener = wrapKeyDownExtensions(auth, identity, callback);
        } else {
            eventListener = wrapKeyDownExtensions(auth, identity);
        }
        document.addEventListener("keydown", eventListener);
        return () => {
            document.removeEventListener("keydown", eventListener);
        };
    }, [auth, identity, isItemInCart, dispatch, changesUpdatedModalSeen]);
}
