import { useUnlockReplacementImages } from "./hooks/behaviors/useUnlockReplacementImages";
import { usePreventItemAdditionToBlankCanvas } from "./hooks/behaviors/usePreventItemAdditionToBlankCanvas";
import { useCropOverride } from "./hooks/behaviors/useCropOverride";
import { usePresentationFolderDropzone } from "./hooks/behaviors/usePresentationFolderDropzone";
import { useFontColorDefaults } from "./hooks/behaviors/useFontColorDefaults";
import { useItemsAttributesMetadata } from "./hooks/behaviors/useItemsAttributesMetadata";
import { useDefaultTextPremiumFinish } from "./hooks/behaviors/useDefaultTextPremiumFinish";
import { useScrollToItemAdded } from "./hooks/behaviors/useScrollToItemAdded";
import { useResizeItems } from "../../studioFive/hooks/useResizeItems";

export function EaselBehaviorOverrides() {
    // enable behaviors for easel
    useItemsAttributesMetadata();
    useDefaultTextPremiumFinish();
    useScrollToItemAdded();
    useUnlockReplacementImages();
    usePreventItemAdditionToBlankCanvas();
    useCropOverride();
    usePresentationFolderDropzone();
    useFontColorDefaults();
    useResizeItems();
    return null;
}

EaselBehaviorOverrides.displayName = "EaselBehaviorOverrides";
