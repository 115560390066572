import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useMemo,
    useState
} from "react";

interface Data {
    canvasZoomScale: number;
    setCanvasZoomScale: Dispatch<SetStateAction<number>>;
    canvasZoomFactor: number;
    setCanvasZoomFactor: Dispatch<SetStateAction<number>>;
}

const ZoomManagerContext = createContext<Data | undefined>(undefined);

export function useZoomManager() {
    const result = useContext(ZoomManagerContext);
    if (!result) {
        throw Error("Missing context.  This must be called within a ZoomManagerProvider");
    }
    return result;
}

export const ZoomManagerProvider = ({ children }: PropsWithChildren<{}>) => {
    const [canvasZoomScale, setCanvasZoomScale] = useState(1);
    const [canvasZoomFactor, setCanvasZoomFactor] = useState(1);

    const value = useMemo(
        () => ({
            canvasZoomScale,
            setCanvasZoomScale,
            canvasZoomFactor,
            setCanvasZoomFactor
        }),
        [canvasZoomScale, setCanvasZoomScale, canvasZoomFactor, setCanvasZoomFactor]
    );

    return <ZoomManagerContext.Provider value={value}>{children}</ZoomManagerContext.Provider>;
};

ZoomManagerProvider.displayName = "ZoomManagerProvider";
