import { useEffect } from "react";
import { useDesigner } from "@designer-suite";

/**
 * A hook that sets up a callback that unlocks a replacement image for a placeholder
 * when the item:replaceimage event occurs.
 */
export function useUnlockReplacementImages() {
    const designer = useDesigner();
    useEffect(() => {
        if (!designer) {
            return;
        }

        type unlockParams = { dropViewModel: ItemViewModel };
        function unlockReplacementImage({ dropViewModel }: unlockParams): void {
            if (dropViewModel.model.get("placeholder")) {
                dropViewModel.model.set("locked", false);
            }
        }

        designer.eventBus.on(designer.eventBus.events.replaceImage, unlockReplacementImage);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(designer.eventBus.events.replaceImage, unlockReplacementImage);
        };
    }, [designer]);
}
