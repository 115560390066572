import React from "react";
import { useAppSelector } from "@shared/redux";

export function StudioIsInteractiveSwitch({
    children
}: {
    children: [elementWhenInteractive: JSX.Element, elementWhenNotInteractive: JSX.Element];
}) {
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    return <>{easelLoaded ? children[0] : children[1]}</>;
}

StudioIsInteractiveSwitch.displayName = "StudioIsInteractiveSwitch";
