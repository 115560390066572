import React, { FormEvent, useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Box, Button, StandardForm } from "@vp/swan";
import { PanelContent, PanelDescription, PanelTitle, StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import { QRCodeURLInput, QRCodeMessages as messages } from "@shared/features/QRCodes";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import * as styles from "./AddQRCodePanel.module.scss";

interface Props {
    onAddQRCode: (url: string) => void;
}

export const AddQRCodePanel = (props: Props) => {
    const { onAddQRCode } = props;
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const [url, setUrl] = useState<string>("");
    const [error, setError] = useState(false);

    const onUrlChange = (newUrl: string) => {
        setUrl(newUrl);
    };

    const onSubmit = useCallback(
        (e: FormEvent<HTMLElement>) => {
            e.preventDefault();

            if (url) {
                onAddQRCode(url);
            }
        },
        [url, onAddQRCode]
    );

    return (
        <PanelContent className={styles.addQrCodesPanel}>
            <PanelTitle>{t(messages.panelTitle.id)}</PanelTitle>
            <PanelDescription>{t(messages.addQRInstructions.id)}</PanelDescription>
            <StudioIsInteractiveSwitch>
                <StandardForm onSubmit={onSubmit}>
                    <Box mt={isSmall ? 3 : 2} className={styles.addQRCodeInput}>
                        <QRCodeURLInput url={url} onUrlChange={onUrlChange} error={error} setError={setError} />
                    </Box>
                    <Button
                        disabled={error || !url}
                        title={t(messages.buttonTitle.id)}
                        skin="primary"
                        width="full-width"
                        size="mini"
                        type="submit"
                    >
                        {t(messages.buttonTitle.id)}
                    </Button>
                </StandardForm>
                <>
                    <Skeleton width={"100%"} height={50} />
                    <Skeleton width={"100%"} height={50} style={{ marginBottom: "25px", borderRadius: "25px" }} />
                </>
            </StudioIsInteractiveSwitch>
        </PanelContent>
    );
};

AddQRCodePanel.displayName = "AddQRCodePanel";
