import { useOnAddClick, useOnCanvasClick } from "@shared/features/DesignPanel";
import { usePreviewsContext } from "@shared/features/Previews";
import { useDesignRequirementsContext, useNeedAddButtonContext } from "@shared/features/Product";
import { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { useEffect, useRef } from "react";
import { useCanvasSelectorPreviews } from "@shared/features/CanvasSelectors";
import { useStartingCanvas } from "@shared/features/StudioBootstrap";
import { GetDocument } from "@shared/utils/CimDoc";
import { useAppSelector } from "@shared/redux";

interface Props {
    activeCanvasName?: string;
    getDocument: GetDocument;
    triggerCanvasChange: TriggerCanvasChange;
}

export const useTriggerStartingCanvas = ({ activeCanvasName, getDocument, triggerCanvasChange }: Props) => {
    const canvasSelectorPreviewsData = useCanvasSelectorPreviews();
    const { canvasSelectorUrls } = usePreviewsContext();
    const designRequirements = useDesignRequirementsContext();
    const needAddButton = useNeedAddButtonContext();
    const onAddClick = useOnAddClick();
    const onCanvasClick = useOnCanvasClick({ activeCanvasName, getDocument, triggerCanvasChange });
    const startingCanvas = useStartingCanvas();
    const surfaceUpsellsLoaded = useAppSelector(state => state.surfaceUpsellsLoadedState);

    const startingCanvasInitialized = useRef(false);

    useEffect(() => {
        if (!designRequirements) {
            return;
        }

        if (startingCanvas === 1) {
            return;
        }

        if (!canvasSelectorPreviewsData) {
            return;
        }

        const canvasData = canvasSelectorUrls[startingCanvas - 1];
        if (!canvasData) {
            return;
        }

        if (!surfaceUpsellsLoaded) {
            return;
        }

        const panel = designRequirements.panels[startingCanvas - 1];
        if (!startingCanvasInitialized.current && panel) {
            startingCanvasInitialized.current = true;
            if (needAddButton(panel, panel.name)) {
                onAddClick(canvasData);
            } else {
                onCanvasClick(canvasData);
            }
        }
    }, [
        canvasSelectorPreviewsData,
        canvasSelectorUrls,
        designRequirements,
        needAddButton,
        onAddClick,
        onCanvasClick,
        startingCanvas,
        surfaceUpsellsLoaded
    ]);
};
