/* eslint-disable max-len */
import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {}

export function ExpandArrowIcon(props: Props) {
    return (
        <svg viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M10.6631 15.3252C10.8454 15.3252 10.998 15.2637 11.1211 15.1406C11.2441 15.0176 11.3057 14.8626 11.3057 14.6758V14.1699L11.1689 12.0371L12.7754 13.7188L14.6895 15.6533C14.8125 15.7809 14.9674 15.8447 15.1543 15.8447C15.3548 15.8447 15.5189 15.7832 15.6465 15.6602C15.7786 15.5371 15.8447 15.3753 15.8447 15.1748C15.8447 15.0791 15.8265 14.9925 15.79 14.915C15.7581 14.833 15.7103 14.7601 15.6465 14.6963L13.7188 12.7617L12.0371 11.1689L14.1836 11.2988H14.6963C14.8877 11.2988 15.0449 11.2396 15.168 11.1211C15.2956 11.0026 15.3594 10.8477 15.3594 10.6562C15.3594 10.4648 15.2956 10.3076 15.168 10.1846C15.0449 10.0615 14.8877 10 14.6963 10H11.1143C10.7588 10 10.4831 10.098 10.2871 10.2939C10.0957 10.4899 10 10.7611 10 11.1074V14.6758C10 14.8581 10.0615 15.013 10.1846 15.1406C10.3122 15.2637 10.4717 15.3252 10.6631 15.3252ZM17.6904 22.4004H21.2793C21.6348 22.4004 21.9082 22.3024 22.0996 22.1064C22.2956 21.915 22.3936 21.6439 22.3936 21.293V17.7314C22.3936 17.5446 22.3298 17.3896 22.2021 17.2666C22.0791 17.139 21.9219 17.0752 21.7305 17.0752C21.5436 17.0752 21.3887 17.1367 21.2656 17.2598C21.1426 17.3828 21.0811 17.54 21.0811 17.7314V18.2373L21.2246 20.3701L19.6182 18.6885L17.6973 16.7539C17.5788 16.6217 17.4261 16.5557 17.2393 16.5557C17.0387 16.5557 16.8724 16.6195 16.7402 16.7471C16.6126 16.8701 16.5488 17.0319 16.5488 17.2324C16.5488 17.3236 16.5648 17.4102 16.5967 17.4922C16.6286 17.5742 16.6764 17.6471 16.7402 17.7109L18.6748 19.6387L20.3564 21.2383L18.21 21.1084H17.6904C17.5036 21.1084 17.3464 21.1676 17.2188 21.2861C17.0957 21.4046 17.0342 21.5573 17.0342 21.7441C17.0342 21.9355 17.0957 22.0928 17.2188 22.2158C17.3464 22.3389 17.5036 22.4004 17.6904 22.4004Z"
                fill="white"
            />
        </svg>
    );
}
ExpandArrowIcon.displayName = "ExpandArrowIcon";
