import { directImageReplacementEvent } from "@designer-suite";
import { getTrackingDataForImageSelection } from "@utilities";
import { newRelicWrapper } from "@shared/utils/Errors";
import { STUDIO_DIAGNOSTIC_EVENTS } from "@shared/utils/Tracking";

/**
 {
     id: {
        uploadStarted,
        uploadProcessingStarted
    }
}
*/
const uploadTiming = {};

// The data being processed comes from a huge set of possible eventTrigger
// callback data, any is the best type description
function extractUploadMetadata(data: any) {
    const analysis = data?.attributes?.pages?.length > 0 && data.attributes.pages.models[0].attributes.analysis;
    return {
        fileType: data.attributes?.fileType || data.type,
        id: data.attributes?.id,
        size: data.attributes?.file?.size || data.size,
        clientInitiator: data.attributes?.clientInitiator || "designer",
        isLogo: analysis?.isLogo === "True",
        isPhoto: analysis?.isPhoto === "True",
        isVector: analysis?.isVector === "True",
        lineartness: analysis?.lineartness
    };
}

export default function events() {
    return [
        // *** Clipboard ***
        //  Note these will not fire when its not actually using the
        // designer clipboard (some cut and paste of text)
        {
            designerEvent: window.designer!.eventBus.events.copyItems,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.CLIPBOARD.events.COPY,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.CLIPBOARD.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.cutItems,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.CLIPBOARD.events.CUT,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.CLIPBOARD.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.pasteItems,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.CLIPBOARD.events.PASTE,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.CLIPBOARD.eventLabel,
            processData: () => ({})
        },
        // *** Command ***
        // specifically not doing these -
        //  its possible with a non-generic handler something useful can be gotten:
        //  designer.eventBus.events.executeCommand
        //  designer.eventBus.events.undoCommand
        // *** Eye dropper ***
        {
            designerEvent: window.designer!.eventBus.events.eyeDropperStart,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.events.START,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.eyeDropperEnd,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.events.END,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.EYEDROPPER.eventLabel,
            processData: () => ({})
        },
        // *** Image Processing ***
        {
            designerEvent: window.designer!.eventBus.events.imageProcessingStart,
            eventName: "Image Processing Start",
            labelName: "ImageProcessing",
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.imageProcessingFinished,
            eventName: "Image Processing Finished",
            labelName: "ImageProcessing",
            processData: () => ({})
        },
        // *** Item ***
        {
            designerEvent: window.designer!.eventBus.events.addText,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.TEXT.events.ADD_TEXT,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.TEXT.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.autoPlace,
            eventName: "Autoplaced",
            labelName: "Item",
            processData: () => ({})
        },
        // Only fires for delete via keyboard. Is not firing for toolbar.
        {
            designerEvent: window.designer!.eventBus.events.deleteItem,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.ITEM.events.DELETE,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.ITEM.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.replaceImage,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.REPLACE_IMAGE,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.eventLabel,
            processData: (data: any) => {
                const analysis = data?.image?.analysis;
                return {
                    isLogo: analysis?.isLogo === "True" || false,
                    isPhoto: analysis?.isPhoto === "True" || false
                };
            }
        },
        {
            designerEvent: directImageReplacementEvent,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.REPLACE_IMAGE,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.eventLabel,
            processData: (data: any) => {
                let isLogo = false;
                let isPhoto = false;
                if (data?.items?.[0]?._itemViewModel) {
                    ({ isLogo, isPhoto } = getTrackingDataForImageSelection(data.items));
                }
                return { isLogo, isPhoto };
            }
        },
        // Not working - leaving out: window.designer!.eventBus.events.select
        {
            designerEvent: window.designer!.eventBus.events.selectAll,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.ITEM.events.SELECT_ALL,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.ITEM.eventLabel,
            processData: () => ({})
        },
        // *** History ***
        // Leaving out as the more specific history events are more useful:
        //  designer.eventBus.events.historyChanged
        {
            designerEvent: window.designer!.eventBus.events.undo,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.events.UNDO,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.redo,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.events.REDO,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.HISTORY.eventLabel,
            processData: () => ({})
        },
        // *** Message ***
        // We should do this for our own toast notifications
        {
            designerEvent: window.designer!.eventBus.events.createToastNotification,
            eventName: "Toast Notification",
            labelName: "General",
            processData: (data: any) => {
                const { message, type } = data;
                if (typeof message === "string") {
                    return { message, type };
                }
                if (message?.el?.innerText) {
                    return { message: message.el.innerText, type };
                }
                return { message: "Unknown message", type };
            }
        },
        // *** Modal Dialogs ***
        {
            designerEvent: window.designer!.eventBus.events.openAddOrReplaceImageDialog,
            eventName: "Open Add Replace Dialog",
            labelName: "Image",
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.premiumFinish,
            eventName: "Premium Finish Dialog",
            labelName: "Item",
            processData: () => ({})
        },
        // *** Transform ***
        //  More can probably do non-generic versions that record what
        //  type of item(s) are being acted on
        // A non-generic handler is being used (identifies item type(s)):
        //  designer.eventBus.events.dragStart
        //  designer.eventBus.events.dragStop
        //  designer.eventBus.events.resizeStart
        //  designer.eventBus.events.resizeStop
        // A non-generic handler is being used (identifies item type:
        //  designerEvent: window.designer!.eventBus.events.rotateStart
        //  designer.eventBus.events.rotateStop
        // *** Validation ***
        {
            designerEvent: window.designer!.eventBus.events.addValidation,
            eventName: "Validation Add",
            labelName: "Validation",
            processData: () => ({})
        },
        // Specifically not doing: jointValidationChange
        {
            designerEvent: window.designer!.eventBus.events.removeValidation,
            eventName: "Validation Remove",
            labelName: "Validation",
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.fileValidationPluginValidationsRan,
            eventName: "Validation File",
            labelName: "Validation",
            processData: () => ({})
        },
        // *** Upload ***
        {
            designerEvent: window.designer!.eventBus.events.uploadStarted,
            eventName: "Upload Started",
            labelName: "Image",
            processData: (data: any) => {
                const { id, ...rest } = extractUploadMetadata(data);
                uploadTiming[id] = { uploadStarted: performance.now() };
                return { ...rest };
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadFailed,
            eventName: "Upload Failed",
            labelName: "Image",
            processData: (data: any) => {
                const { id, ...rest } = extractUploadMetadata(data);
                const timeToUpload = uploadTiming[id] && performance.now() - uploadTiming[id].uploadStarted;
                return { ...rest, timeToUpload };
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadComplete,
            eventName: "Upload Complete",
            labelName: "Image",
            processData: (data: any) => {
                const { id, ...rest } = extractUploadMetadata(data);
                const timeToUpload = uploadTiming[id] && performance.now() - uploadTiming[id].uploadStarted;
                return { ...rest, timeToUpload };
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadProcessingStarted,
            eventName: "Upload Processing Started",
            labelName: "Image",
            processData: (data: any) => {
                const { id, ...rest } = extractUploadMetadata(data);
                uploadTiming[id] = {
                    ...uploadTiming[id],
                    uploadProcessingStarted: performance.now()
                };
                return { ...rest };
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadProcessFailed,
            eventName: "Upload Processing Failed",
            labelName: "Image",
            processData: (data: any) => {
                const { id, ...rest } = extractUploadMetadata(data);
                const totalUploadTime = uploadTiming[id] && performance.now() - uploadTiming[id].uploadStarted;
                const timeToProcessUpload =
                    uploadTiming[id] && performance.now() - uploadTiming[id].uploadProcessingStarted;
                return { ...rest, totalUploadTime, timeToProcessUpload };
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadProcessingComplete,
            eventName: "Upload Processing Complete",
            labelName: "Image",
            processData: (data: any) => {
                const { id, ...rest } = extractUploadMetadata(data);
                const totalUploadTime = uploadTiming[id] && performance.now() - uploadTiming[id].uploadStarted;
                const timeToProcessUpload =
                    uploadTiming[id] && performance.now() - uploadTiming[id].uploadProcessingStarted;
                return { ...rest, totalUploadTime, timeToProcessUpload };
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadOverallFailure,
            eventName: "Upload Failed: Overall",
            labelName: "Image",
            processData: (data: any, other: any[]) => {
                const { id, ...rest } = extractUploadMetadata(data);
                const timeToUpload = uploadTiming[id] && performance.now() - uploadTiming[id].uploadStarted;
                const error = other[0];
                let errorMessage = `${error.status}:  `;
                if (error.jqXHR) {
                    errorMessage += error.jqXHR.responseJSON.Message;
                } else if (error.responseJSON) {
                    errorMessage += error.responseJSON.Message;
                } else {
                    errorMessage += error.statusText;
                }
                // notice this so we can fire alerts
                newRelicWrapper.logPageAction("studio-onUpload-failed", {
                    fileType: rest.fileType,
                    errorMessage
                });
                return { ...rest, timeToUpload, errorMessage };
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadFailedSizeValidation,
            eventName: "Upload Failed: Size",
            labelName: "Image",
            processData: (data: any) => {
                return extractUploadMetadata(data);
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadFailedTypeValidation,
            eventName: "Upload Failed: Type",
            labelName: "Image",
            processData: (data: any) => {
                return extractUploadMetadata(data);
            }
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadHasPremiumFinish,
            eventName: "Upload HasPremiumFinish",
            labelName: "Image",
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.uploadSetUsedFlag,
            eventName: "Upload Used",
            labelName: "Image",
            processData: (data: any) => ({ usedOnCanvas: data.usedOnCanvas })
        },
        // This isn't getting fired when text is inserted
        // {
        //     designerEvent: window.designer!.eventBus.events.insertText,
        //     eventName: "Text Added",
        //     labelName: "TextField",
        //     processData: () => ({})
        // },
        // *** Zoom ***
        // More specific events more useful - not doing:
        //  designer.eventBus.events.zoom
        {
            designerEvent: window.designer!.eventBus.events.zoomIn,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.ZOOM.events.ZOOM_IN,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.ZOOM.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.zoomOut,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.ZOOM.events.ZOOM_OUT,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.ZOOM.eventLabel,
            processData: () => ({})
        },
        {
            designerEvent: window.designer!.eventBus.events.zoomReset,
            eventName: STUDIO_DIAGNOSTIC_EVENTS.ZOOM.events.ZOOM_RESET,
            labelName: STUDIO_DIAGNOSTIC_EVENTS.ZOOM.eventLabel,
            processData: () => ({})
        },
        // {
        //     designerEvent: window.designer!.eventBus.events.zoomSet,
        //     eventName: "Zoom Set",
        //     labelName: "Zoom",
        //     processData: () => ({})
        // },
        // *** Uncategorized ***
        // We track saving elsewhere
        // {
        //     designerEvent: window.designer!.eventBus.events.documentSaved,
        //     eventName: "Document Saved",
        //     labelName: "General",
        //     processData: () => ({})
        // },
        {
            designerEvent: window.designer!.eventBus.events.modifyImage,
            eventName: "Change Color",
            labelName: "Image",
            processData: () => ({})
        }
    ];
}
