import { setWorkId, Store } from "@shared/redux";
import { type WorkEntity } from "../types";

const WORK_ID_EXPIRATION = 2 * 60 * 1000;

const isWorkEntityFresh = (workEntity: { timestamp: string | number | Date }) => {
    const workAge = new Date().valueOf() - new Date(workEntity.timestamp).valueOf();
    return workAge < WORK_ID_EXPIRATION;
};

export function getWorkEntityFromSession(workId: string) {
    try {
        if (window && window.sessionStorage) {
            const sessionWorkText = sessionStorage.getItem(`workId:${workId}`);
            sessionStorage.removeItem(`workId:${workId}`);
            const sessionWork = sessionWorkText !== null ? JSON.parse(sessionWorkText) : null;
            if (sessionWork && sessionWork.work && sessionWork.timestamp && isWorkEntityFresh(sessionWork)) {
                Store.dispatch(setWorkId(workId));
                return sessionWork.work;
            }
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return null;
}

export function setWorkEntityInSession(work: WorkEntity) {
    try {
        if (window && window.sessionStorage) {
            const sessionWork = {
                work,
                timestamp: new Date().valueOf()
            };
            sessionStorage.setItem(`workId:${work.workId}`, JSON.stringify(sessionWork));
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
}
