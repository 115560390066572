import { useDesignRequirementsContext } from "@shared/features/Product";
import { getPremiumFinishes, hasPremiumFinishes } from "../utilities";

interface PremiumFinishData {
    /** true if current canvas supports premium finishes, false otherwise */
    hasPremiumFinishesCurrentCanvas: boolean;
    /** array with a single entry which is the active finish */
    enabledCanvasFinish: string[];
}

const defaultPremiumFinishData: PremiumFinishData = {
    hasPremiumFinishesCurrentCanvas: false,
    enabledCanvasFinish: []
};

export function useAvailablePremiumFinishesOnCanvas(activeCanvasName?: string) {
    const designRequirements = useDesignRequirementsContext();

    if (!activeCanvasName) {
        return defaultPremiumFinishData;
    }
    const activeCanvas = designRequirements?.getPanelByName(activeCanvasName);
    if (!activeCanvas) {
        return defaultPremiumFinishData;
    }
    // adding a check to enable premium finish only for 'front' canvas for a premium finish supported document.
    // we only really support finishes for the 'front' of documents.
    // this check prevents getting a message on the back of folded foil holiday cards that the user needs to add a finish
    const isActiveCanvasFrontCanvas = designRequirements?.getPanelIndexByName(activeCanvasName) === 0;

    if (!isActiveCanvasFrontCanvas) {
        return defaultPremiumFinishData;
    }
    const hasPremiumFinishesCurrentCanvas = hasPremiumFinishes(activeCanvas);
    const enabledCanvasFinish = hasPremiumFinishesCurrentCanvas ? [getPremiumFinishes(activeCanvas)[0]] : [];
    return {
        hasPremiumFinishesCurrentCanvas,
        enabledCanvasFinish
    };
}
