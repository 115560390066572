import { getRecommendedColors, useDesigner, useSelection } from "@designer-suite";
import React, { createContext, ReactNode, useState, useMemo, useContext, useEffect, useCallback } from "react";
import { areSelectableColorsEqual, convertToSelectableColor, SelectableColor } from "@shared/features/ColorPicker";
import { useAppSelector } from "@shared/redux";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { CMYK, RGB } from "@design-stack-ct/utility-core";

interface ContextProps {
    recentColors: SelectableColor[];
    updateRecentColors: (Color?: string | RGB | CMYK) => void;
    lastSelectedColor?: SelectableColor;
    updateLastSelectedColor: (Color?: SelectableColor) => void;
}

export const RecentColorsContext = createContext<ContextProps | undefined>(undefined);

export const useRecentColorsContext = () => {
    const result = useContext(RecentColorsContext);
    if (!result) {
        throw Error("Missing context.  This must be called within a RecentColorsProvider");
    }
    return result;
};

interface Props {
    /* Any components that need Colors should be wrapped here */
    children: ReactNode;
}
export const RecentColorsProvider = ({ children }: Props) => {
    const designer = useDesigner();
    const selection = useSelection();
    const [recentColors, setRecentColors] = useState<SelectableColor[]>([]);
    const [lastSelectedColor, setLastSelectedColor] = useState<SelectableColor | undefined>();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { currentActiveDialog } = useActiveDialog();

    useEffect(() => {
        if (easelLoaded && designer) {
            const mostUsedColors = getRecommendedColors(designer).filter(color => !color.value.includes("spot"));
            setRecentColors(mostUsedColors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [easelLoaded, designer]);

    useEffect(() => {
        if (
            selection.length > 0 &&
            currentActiveDialog !== DialogType.BackgroundColorPicker &&
            recentColors.length === 0 &&
            designer
        ) {
            setRecentColors(getRecommendedColors(designer).filter(color => !color.value.includes("spot")));
        } else if (lastSelectedColor) {
            setRecentColors(
                [lastSelectedColor, ...recentColors.filter(e => !areSelectableColorsEqual(e, lastSelectedColor))].slice(
                    0,
                    7
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection, designer, currentActiveDialog]);

    const updateRecentColors = useCallback(
        (color?: string) => {
            const colorToUpdate = (color && convertToSelectableColor(color)) || lastSelectedColor;
            if (colorToUpdate) {
                setRecentColors(current => {
                    return [colorToUpdate, ...current.filter(e => !areSelectableColorsEqual(e, colorToUpdate))].slice(
                        0,
                        7
                    );
                });
                setLastSelectedColor(undefined);
            }
        },
        [setRecentColors, lastSelectedColor, setLastSelectedColor]
    );

    const contextObject = useMemo(
        () => ({
            updateRecentColors,
            recentColors,
            updateLastSelectedColor: setLastSelectedColor
        }),
        [recentColors, updateRecentColors]
    );

    return <RecentColorsContext.Provider value={contextObject}>{children}</RecentColorsContext.Provider>;
};

RecentColorsProvider.displayName = "RecentColorsProvider";
