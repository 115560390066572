import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useVisibilityAutoSaveEffect } from "@shared/features/Save";
import { useStudio5Save } from "./useStudio5Save";

export function usePageVisibleAutoSave() {
    const save = useStudio5Save({
        allowAnonymousUser: true,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_AUTO_SAVE
    });

    useVisibilityAutoSaveEffect(save);
}
