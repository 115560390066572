import { useGetSubstrateColorsWithStockInfo } from "@shared/features/Product";
import { useGetCurrentTemplateColor } from "../../ChangeTemplate";
import type { SimplifiedEaselConfig } from "../useEaselConfig";
import { useGetCimdocMetadata } from "./useGetCimdocMetadata";
import { useGetSecondaryCalciferData } from "./useGetSecondaryCalciferData";

export function useLoadSecondaryData(config?: SimplifiedEaselConfig) {
    useGetCurrentTemplateColor();
    useGetSubstrateColorsWithStockInfo(config);
    useGetCimdocMetadata(config?.cimDoc);
    useGetSecondaryCalciferData(config);
}
