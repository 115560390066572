import { useAppDispatch, setShowFullBleedPremiumFinishErrorModal, showStudioErrorDialog } from "@shared/redux";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import { usePremiumFinishReviewInstructionsRequired } from "@shared/features/PremiumFinish";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { Errors } from "./validationMessages";

interface CanvasValidations {
    validateCanvasErrors: () => boolean;
}

const CanvasValidationsContext = React.createContext<CanvasValidations>({ validateCanvasErrors: () => false });

export function useCanvasValidations() {
    if (CanvasValidationsContext === undefined) {
        throw new Error("useCanvasValidations must be used within a CanvasValidationsProvider");
    }
    return useContext(CanvasValidationsContext);
}

export function CanvasValidationsProvider({
    children,
    designErrors,
    activeCanvasName
}: {
    children: ReactNode | ReactNode[];
    designErrors: Errors[];
    activeCanvasName: string | undefined;
}) {
    const { isSmartValidationsEnabled } = useSmartValidations();
    const premiumFinishReviewInstructionsRequired = usePremiumFinishReviewInstructionsRequired(activeCanvasName);
    const dispatch = useAppDispatch();

    const validateCanvasErrors = useCallback(() => {
        if (premiumFinishReviewInstructionsRequired) {
            dispatch(setShowFullBleedPremiumFinishErrorModal(true));
            return false;
        }
        if (designErrors.length && !isSmartValidationsEnabled) {
            dispatch(showStudioErrorDialog(true));
            return false;
        }
        return true;
    }, [dispatch, designErrors, premiumFinishReviewInstructionsRequired, isSmartValidationsEnabled]);

    const contextObject = useMemo(
        () => ({
            validateCanvasErrors
        }),
        [validateCanvasErrors]
    );

    return <CanvasValidationsContext.Provider value={contextObject}>{children}</CanvasValidationsContext.Provider>;
}

CanvasValidationsProvider.displayName = "CanvasValidationsProvider";
