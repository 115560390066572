import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { itemIsIcon, useSelection } from "@designer-suite";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { EditImageTools } from "./EditImageTools";
import { EditImageColor } from "./EditImageColor";
import * as styles from "./EditImagePanel.module.scss";

const messages = defineMessages({
    imageTitle: {
        id: "studio.panel.editImage.imageTitle",
        defaultMessage: "Edit Image",
        description: {
            note: "Title for panel to edit image color and other options"
        }
    },
    iconTitle: {
        id: "studio.panel.editImage.iconTitle",
        defaultMessage: "Edit icon",
        description: {
            note: "Title for panel to edit icon color and other options"
        }
    }
});

export function EditImagePanel() {
    const { t } = useTranslationSSR();
    const selection = useSelection();

    if (selection.length !== 1) {
        return null;
    }

    const isIcon = itemIsIcon(selection[0]);

    return (
        <PanelContent className={styles.editImagePanelContent}>
            <PanelTitle>{isIcon ? t(messages.iconTitle.id) : t(messages.imageTitle.id)}</PanelTitle>
            <EditImageTools />
            <EditImageColor isIcon={isIcon} />
        </PanelContent>
    );
}
EditImagePanel.displayName = "EditImagePanel";
