import React from "react";
import { SearchResetIcon, SearchIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { TextInput, TextInputWithButtonInset, Button } from "@vp/swan";
import { messages } from "./messages";
import * as styles from "./MyProjectsSearchBar.module.scss";

type Props = {
    searchTerm: string;
    handleOnChange: React.ChangeEventHandler<HTMLInputElement>;
    resetSearch: () => void;
    performSearch: (currentSearchTerm: string) => void;
};

export const MyProjectsSearchBar = (props: Props) => {
    const { searchTerm, handleOnChange, resetSearch, performSearch } = props;
    const { t } = useTranslationSSR();

    return (
        <TextInputWithButtonInset className={styles.searchBar} skin="searchbar" marginTop={5}>
            <TextInput
                role="searchbox"
                value={searchTerm}
                onChange={handleOnChange}
                placeholder={t(messages.myProjectsSearch.id)}
                className={styles.searchInput}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === "Enter") {
                        performSearch((event.target as HTMLInputElement).value);
                    }
                }}
            />
            {searchTerm && (
                <Button onClick={resetSearch} aria-label={t(messages.myProjectSearchResetButtonLabel.id)}>
                    <SearchResetIcon className={styles.searchIcon} />
                </Button>
            )}
            <span className={styles.searchIconContainer}>
                <SearchIcon className={styles.searchIcon} />
            </span>
        </TextInputWithButtonInset>
    );
};

MyProjectsSearchBar.displayName = "MyProjectsSearchBar";
