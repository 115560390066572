import React, { HTMLProps } from "react";
import { DragIcon } from "@shared/features/StudioChrome";

export default function DragOverlay({ ...rest }: HTMLProps<HTMLDivElement>) {
    return (
        <div className="tableOverlay-drag" {...rest}>
            <DragIcon />
        </div>
    );
}

DragOverlay.displayName = "DragOverlay";
