import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useRecentColorsContext } from "@presentational";
import { useDesigner, useSelection } from "@designer-suite";
import { EyeDropperButton } from "@shared/features/StudioChrome";
import { colorToString } from "@shared/features/ColorPicker";
import type { Designer, ItemSelection } from "../../@types/designer";

const messages = defineMessages({
    eyedropperAriaLabel: {
        id: "studio.contextualToolbar.eyedropperAriaLabel",
        defaultMessage: "Eyedropper Color Tool"
    }
});

interface Props {
    /** Checks if the current selection is compatible with the eyedropper */
    isCompatibleWithSelection: (selection: ItemSelection) => boolean;
    /** Update the selection using the color selected, using the normalized color string */
    update: (designer: Designer, selection: ItemSelection, normalizedColor: string) => void;
    /** Any additional events that the useSelection should listen too */
    listenEvents?: string;
    /** Size variant of the button */
    buttonSize?: "mini" | "standard";
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
}

/**
 * Responsible for providing an eye dropper
 */
export function EyeDropper(props: Props) {
    const { t } = useTranslationSSR();
    const { isCompatibleWithSelection, update, listenEvents = "", buttonSize = "standard", trackUsage } = props;
    const designer = useDesigner();
    const selection = useSelection(listenEvents);
    const { updateRecentColors } = useRecentColorsContext();

    if (!designer || !isCompatibleWithSelection(selection)) {
        return null;
    }

    const {
        eventBus: { events },
        eventBus
    } = designer;

    function onClick() {
        if (!designer) {
            return;
        }
        if (trackUsage) {
            trackUsage("Eye Dropper");
        }
        eventBus.trigger(events.eyeDropperStart, (color: string) => {
            const newColor = colorToString(color);
            updateRecentColors(newColor);
            update(designer, selection, newColor);
        });
    }

    return <EyeDropperButton size={buttonSize} onClick={onClick} ariaLabel={t(messages.eyedropperAriaLabel.id)} />;
}
EyeDropper.displayName = "EyeDropper";
