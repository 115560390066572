import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector } from "@shared/redux";
import React, { useContext, createContext, useEffect, useState, useMemo, ReactNode } from "react";
import { getProductSurfaceDimensions, ProductSurfaceDimensionsResult } from "@shared/utils/SurfaceSpecifications";

type ContextData = {
    surfaceSpecs: ProductSurfaceDimensionsResult;
    isSurfaceSpecsLoading: boolean;
};

const context = createContext<ContextData | undefined>(undefined);

export function useSurfaceSpecs() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a SurfaceSpecsProvider");
    }
    return result;
}

type Props = {
    children: ReactNode | ReactNode[];
};

export const SurfaceSpecsProvider = ({ children }: Props) => {
    const { Provider } = context;
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const productOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const { auth } = useIdentityContext();
    const authToken = auth?.getToken();

    const [surfaceSpecs, setSurfaceSpecs] = useState<ProductSurfaceDimensionsResult>({
        mcpSku: "",
        surfaceGroups: []
    });

    const [isSurfaceSpecsLoading, setIsSurfaceSpecsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!productKey || !productOptions || !productVersion || !authToken) {
            return;
        }
        async function productSurfaceDimensions() {
            if (!productVersion) {
                return;
            }
            setIsSurfaceSpecsLoading(true);
            try {
                const surfaceSpecificationData = await getProductSurfaceDimensions(
                    productKey,
                    productVersion,
                    productOptions,
                    authToken
                );
                setSurfaceSpecs(surfaceSpecificationData);
                setIsSurfaceSpecsLoading(false);
            } catch (e) {
                // do nothing
            }
        }
        productSurfaceDimensions();
    }, [productKey, productOptions, authToken, productVersion]);

    const contextObject = useMemo(
        () => ({
            surfaceSpecs,
            isSurfaceSpecsLoading
        }),
        [surfaceSpecs, isSurfaceSpecsLoading]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

SurfaceSpecsProvider.displayName = "SurfaceSpecsProvider";
