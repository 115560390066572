import React, { useContext, useState, createContext, useEffect, ReactNode } from "react";
import { useDecorationTechnology } from "@utilities";
import { getFontCategories } from "@shared/utils/Fonts";
import type { DecoTechFontFamilies } from "@shared/utils/Fonts";
import { useDesigner } from "./DesignerProvider";
import { getSpriteSheet } from "./FontAdapter";
import { Font, FontSpriteSheet } from "./fontTypes";

interface Props {
    /* Any components that need fonts should be wrapped here */
    children: ReactNode;

    fontCategories: DecoTechFontFamilies;
}

interface Cache {
    fonts: string[];
    decorationTechnology: string;
    fontRepositoryUrl: string;
    fontData: Font[];
}

const fontCache = [] as Cache[];

const fontContext = createContext<Font[]>([]);

export function useFonts() {
    return useContext(fontContext);
}

/**
 * The Font Selector Provider that retrieves fonts from designer and sprite sheets.
 */
export function FontProvider({ fontCategories, children }: Props) {
    const [mappedFonts, setMappedFonts] = useState<Font[]>([]);
    const designer = useDesigner();
    const decorationTechnology = useDecorationTechnology();

    useEffect(() => {
        if (!designer) {
            return undefined;
        }
        const { fontRepositoryUrl } = designer.clients.font;
        const fontCategory = fontCategories[decorationTechnology][0];
        const fontsToShow = getFontCategories()[decorationTechnology][0];
        const fonts = fontCategory.fontFamilies.filter(family => fontsToShow.fontFamilies.includes(family));

        const cacheResult = fontCache.find(
            cache =>
                cache.fonts === fonts &&
                cache.decorationTechnology === decorationTechnology &&
                cache.fontRepositoryUrl === fontRepositoryUrl
        );
        if (cacheResult) {
            setMappedFonts(cacheResult.fontData);
            return undefined;
        }

        // Set the fonts without sprite sheets to start
        setMappedFonts(
            fonts.map(font => ({
                family: font,
                label: font,
                fontFamilyOverride:
                    fontCategory && fontCategory.fontFamilyOverrides && fontCategory.fontFamilyOverrides[font]
            }))
        );
        let cancel;
        const cancelPromise = new Promise((resolve, reject) => {
            cancel = reject;
        });
        const spritePromise = getSpriteSheet(fonts, fontRepositoryUrl, decorationTechnology);

        Promise.race([cancelPromise, spritePromise])
            .then((fontSprites: FontSpriteSheet) => {
                const fontData = fonts.map(font => ({
                    family: font,
                    label: font,
                    backgroundUrl: fontSprites[font].url,
                    backgroundSize: fontSprites[font].backgroundSize,
                    fontFamilyOverride:
                        fontCategory && fontCategory.fontFamilyOverrides && fontCategory.fontFamilyOverrides[font],
                    spriteOffset: {
                        width: fontSprites[font].width,
                        height: fontSprites[font].height,
                        offset: fontSprites[font].offset
                    }
                }));

                fontCache.push({ fontData, fonts, fontRepositoryUrl, decorationTechnology });
                setMappedFonts(fontData);
            })
            .catch(() => {}); // If canceled or doesn't get the sprite sheet properly, just silently fail.

        return cancel;
    }, [decorationTechnology, designer, fontCategories]);

    const { Provider } = fontContext;

    return <Provider value={mappedFonts}>{children}</Provider>;
}
FontProvider.displayName = "FontProvider";
