import React, { FC, useContext, useState, createContext, useMemo, useCallback } from "react";

import { DialogType } from "./DialogType";

type Data = {
    currentActiveDialog: DialogType;
    setCurrentActiveDialog: (
        ActiveDialog: DialogType | ((current: DialogType) => DialogType),
        highlight?: boolean
    ) => void;
    addButtonOffset: number;
    setAddButtonOffset: (height: number) => void;
    showHighlight: boolean;
    setShowHighlight: React.Dispatch<React.SetStateAction<Boolean>>;
};

const context = createContext<Data | undefined>(undefined);

export function useActiveDialog() {
    const result = useContext(context);
    if (!result) {
        throw Error("No Content found");
    }
    return result;
}

export const ActiveDialogProvider: FC = ({ children }) => {
    const [currentActiveDialog, setCurrentActiveDialog] = useState<DialogType>(DialogType.None);
    const [showHighlight, setShowHighlight] = useState<boolean>(false);
    const [addButtonOffset, setAddButtonOffset] = useState<number>(0);

    const { Provider } = context;

    const setActiveDialogAndFrom = useCallback(
        (ActiveDialog: DialogType | ((current: DialogType) => DialogType), highlight: boolean = false) => {
            setCurrentActiveDialog(ActiveDialog);
            setShowHighlight(highlight);
        },
        [setCurrentActiveDialog, setShowHighlight]
    );

    const value = useMemo(() => {
        return {
            currentActiveDialog,
            setCurrentActiveDialog: setActiveDialogAndFrom,
            addButtonOffset,
            setAddButtonOffset,
            showHighlight,
            setShowHighlight
        };
    }, [addButtonOffset, currentActiveDialog, showHighlight, setActiveDialogAndFrom]);

    return <Provider value={value}>{children}</Provider>;
};
ActiveDialogProvider.displayName = "ActiveDialogProvider";
