import {
    type AppDispatch,
    setCompatibleProductOptions,
    setProductAttributeAndValues,
    setSecondaryConfig,
    useAppDispatch,
    useAppSelector,
    setDesignAttributeMappings,
    setIsQuantityPageEnabled
} from "@shared/redux";
import { getSecondaryConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import type { DSS, DTeC } from "@vp/types-ddif";
import { useAsync } from "react-async-hook";
import { getSurfaceUpsellsAndPricing } from "@shared/features/Product";
import { batch } from "react-redux";
import type { SimplifiedEaselConfig } from "../useEaselConfig";

function capitalizeFirstLetter(string: DTeC.FinishType): Capitalize<DTeC.FinishType> {
    return (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<DTeC.FinishType>;
}

export interface GetSecondaryCalciferDataParams {
    designDocument: DSS.DesignDocument;
    productKey: string;
    productVersion: number;
    studioSelectedProductOptions: Record<string, string>;
    template: string | undefined | null;
    isFullBleed: boolean;
    locale: string;
    isColorMatchingBacksideEnabled: boolean;
    dispatch: AppDispatch;
}

// Exporting this to support Studio5 My Projects loading
export async function getSecondaryCalciferData({
    designDocument,
    productKey,
    productVersion,
    studioSelectedProductOptions,
    template,
    isFullBleed,
    locale,
    isColorMatchingBacksideEnabled,
    dispatch
}: GetSecondaryCalciferDataParams) {
    try {
        const {
            surfaceUpsells,
            templates,
            templateColorVariations,
            isQuantityPageEnabled,
            templateAvailableFinishes,
            primaryTemplateToken,
            designAttributes,
            compatibleOptions,
            productAttributeAndValues
        } = await getSecondaryConfigFromCalciferV2(
            productKey,
            productVersion,
            studioSelectedProductOptions,
            locale,
            isFullBleed,
            template,
            isColorMatchingBacksideEnabled
        );

        batch(() => {
            dispatch(
                setSecondaryConfig({
                    surfaceUpsells,
                    templates,
                    templateColorVariations,
                    templateAvailableFinishes: templateAvailableFinishes?.map(capitalizeFirstLetter),
                    primaryTemplateToken
                })
            );
            dispatch(setDesignAttributeMappings(designAttributes ?? []));
            dispatch(setCompatibleProductOptions(compatibleOptions));
            dispatch(setProductAttributeAndValues(productAttributeAndValues));
            dispatch(setIsQuantityPageEnabled(isQuantityPageEnabled));
        });
        dispatch(
            getSurfaceUpsellsAndPricing(
                designDocument,
                surfaceUpsells,
                studioSelectedProductOptions,
                productKey,
                productVersion
            )
        );
    } catch (e) {
        handleError(e, ERROR_CODES.LOAD_REDUX);
    }
}

export function useGetSecondaryCalciferData(config?: SimplifiedEaselConfig) {
    const dispatch = useAppDispatch();
    const { useColorGenericBacksides } = useAppSelector(state => state.studioConfiguration) ?? false;
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const template = useAppSelector(state => state.template);
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const quantity = useAppSelector(state => state.quantity);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const pricingInitialized = useAppSelector(state => state.pricingInitialized);

    useAsync(async () => {
        if (config?.cimDoc && productVersion && pricingInitialized) {
            getSecondaryCalciferData({
                designDocument: config?.cimDoc,
                productKey,
                productVersion,
                studioSelectedProductOptions,
                template,
                isFullBleed,
                locale,
                isColorMatchingBacksideEnabled: useColorGenericBacksides,
                dispatch
            });
        }
    }, [config, quantity, pricingInitialized]);
}
