import React, { useState } from "react";
import { useAsync } from "react-async-hook";
import type { EaselConfig } from "@shared/features/StudioBootstrap";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { useStudioFlexibility } from "@shared/features/Flexibility";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useUploadComponents } from "@shared/features/UploadsAndAssets";
import { init, start, getDefaultConfiguration } from "../utilities";
import { EaselBehaviorOverrides } from "./EaselBehaviorOverrides";
import EaselProvider from "./EaselProvider";
import { ENTITY_CODE, errorCodes } from "../utilities/ErrorCodes";

/**
 * Get the designer options from the given Easel configuration, to pass into DesignerProvider
 * @param {config} Easel Configuraiton
 */
const useDesignerOptions = (
    config: EaselConfig | undefined,
    errorCallBack: undefined | ((error: { errorMessage: string; errorCodeStack: string }) => void)
) => {
    const [designerOptions, setDesignerOptions] = useState();
    const { isSmartValidationsEnabled } = useSmartValidations();
    const { isMileStone1Enabled } = useStudioFlexibility();
    const uploadComponents = useUploadComponents();
    const { t } = useTranslationSSR();

    /**
     * Get the new designer options. Intentionally not using the return state of useAsync,
     * down stream components are properly handle designerOptions being undefined initially.
     */
    useAsync(async () => {
        try {
            if (!config) return;

            // TODO DT-976: getDefaultConfiguration has side effects such that subsequent calls with the same arguments yield different configs
            let newDefaultConfiguration = getDefaultConfiguration(
                config.designerAPIKey,
                config.merchantID,
                config.uploadUrl,
                config.isMobile,
                config.isTablet,
                config.locale,
                config.productKey,
                config.productOptions,
                config.studioConfiguration,
                config.cimDoc,
                { enabledValidationOverlays: !isSmartValidationsEnabled, enableDesignerDropZone: !uploadComponents },
                isMileStone1Enabled,
                t
            );

            if (config.designerConfigOverrides) {
                newDefaultConfiguration = config.designerConfigOverrides(newDefaultConfiguration);
            }

            const newDesignerOptions = await init({ ...config, configuration: newDefaultConfiguration });
            setDesignerOptions(newDesignerOptions);
        } catch (e) {
            let stack = `${ENTITY_CODE}-${errorCodes.INIT_EASEL}`;
            if (e.errorCodeStack) {
                stack = `${stack}-${e.errorCodeStack}`;
            }

            const err = {
                errorMessage: `An error occurred while initializing Easel: ${e.originalMessage || e.message}`,
                errorCodeStack: stack,
                moduleFunction: e.moduleFunction
            };
            if (errorCallBack) {
                errorCallBack(err);
            }
        }
    }, [config]);

    return designerOptions;
};

/**
 * There should be one easelProvider per page
 * This is being exported here so that Studio can wrap its EaselUI components in a single designerProvider
 * */
export function Easel({
    config,
    children,
    onStart,
    errorCallBack
}: {
    config: EaselConfig | undefined;
    children?: React.ReactNode;
    onStart: () => void;
    errorCallBack?: (error: { errorMessage: string; errorCodeStack: string }) => void;
}) {
    const designerOptions = useDesignerOptions(config, errorCallBack);

    return (
        <EaselProvider
            designerOptions={designerOptions}
            onStart={() => {
                try {
                    start(designerOptions);
                    onStart();
                } catch (e) {
                    let stack = `${ENTITY_CODE}-${errorCodes.START_DESIGNER}`;
                    if (e.errorCodeStack) {
                        stack = `${stack}-${e.errorCodeStack}`;
                    }

                    const err = {
                        ...e,
                        errorMessage: `An error occurred while starting Easel: ${e.originalMessage || e.message}`,
                        errorCodeStack: stack
                    };
                    if (errorCallBack) {
                        errorCallBack(err);
                    }
                }
            }}
            errorCallBack={errorCallBack}
        >
            {children}
            <EaselBehaviorOverrides />
        </EaselProvider>
    );
}
Easel.displayName = "Easel";
