import React, { useRef, useEffect, ReactNode } from "react";
import { selectedItemsAreOfTypes } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { RichTextRef } from "./TextList/RichTextField";
import { useSelection } from "./designer/useSelection";
import { useDesigner } from "./designer/DesignerProvider";
import "./selectedRichTextField.scss";
import { RichTextFieldWrapper } from "./TextList/RichTextFieldWrapper";

interface Props {
    /** React component to render at right of the textfield */
    icon?: ReactNode;
    /** For overriding styles */
    className?: string;
}

/**
 * A connected RichTextField to the selection. This is a simple shallow wrapper.
 */
export function SelectedRichTextField({ icon, className }: Props) {
    const designer = useDesigner();
    const selection = useSelection();
    const rtfRef = useRef<RichTextRef>(null);

    useEffect(() => {
        if (!designer) {
            return undefined;
        }

        const insertTextHandler = (e: KeyboardEvent) => {
            if (rtfRef.current && e.key !== "Enter") {
                e.preventDefault();
                rtfRef.current.insertText(e.key);
            }
        };

        designer.eventBus.on(designer.eventBus.events.insertText, insertTextHandler);
        return () => designer.eventBus.off(designer.eventBus.events.insertText, insertTextHandler);
    }, [designer, rtfRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!designer || selection.length !== 1 || !selectedItemsAreOfTypes(selection, [ItemTypes.TEXT])) {
        return null;
    }

    return (
        <RichTextFieldWrapper
            richTextRef={rtfRef}
            className={`${className || ""} easel-selected-rich-text-field`}
            item={selection[0] as TextItem}
            icon={icon}
            selectItemOnFocus
        />
    );
}
SelectedRichTextField.displayName = "SelectedRichTextField";
