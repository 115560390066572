import React, { AnimationEventHandler } from "react";
import { AlertBox, AlertBoxDismissButton, Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./Toast.module.scss";

export const messages = defineMessages({
    autoRotatedTextMessage: {
        id: "studio.components.Toast.autoRotatedText",
        defaultMessage: "We just rotated this text for you - it will appear right side up after printing and assembly.",
        description: {
            note: "Message that informs customers that we automatically rotated the text or word art so that it prints correctly"
        }
    },
    autoRotatedImageMessage: {
        id: "studio.components.Toast.autoRotatedImage",
        defaultMessage:
            "We just rotated this image for you - it will appear right side up after printing and assembly.",
        description: {
            note: "Message that informs customers that we automatically rotated the image so that it prints correctly"
        }
    },
    autoRotatedShapeMessage: {
        id: "studio.components.Toast.autoRotatedShape",
        defaultMessage:
            "We just rotated this shape for you - it will appear right side up after printing and assembly.",
        description: {
            note: "Message that informs customers that we automatically rotated the shape so that it prints correctly"
        }
    },
    autoRotatedRefrenceMessage: {
        id: "studio.components.Toast.autoRotatedReference",
        defaultMessage: "We just rotated this for you - it will appear right side up after printing and assembly.",
        description: {
            note: "Message that informs customers that we automatically rotated a canvas item so that it prints correctly"
        }
    },
    closeButton: {
        id: "studio.components.Toast.autoRotatedCloseButton",
        defaultMessage: "Close message",
        description: {
            note: "Accessibility label for the close button on the auto-rotated toast"
        }
    }
});

export function chooseMessageType(rotatedItemType: ItemTypes) {
    switch (rotatedItemType) {
        case "TEXT":
        case "WORDART":
            return messages.autoRotatedTextMessage.id;
        case "IMAGE":
            return messages.autoRotatedImageMessage.id;
        case "SHAPE":
            return messages.autoRotatedShapeMessage.id;
        case "REFERENCE":
        default:
            return messages.autoRotatedRefrenceMessage.id;
    }
}

type Props = {
    show: boolean;
    render: boolean;
    setRender: React.Dispatch<React.SetStateAction<boolean>>;
    toastMessage: string;
    onAnimationEnd: AnimationEventHandler;
};

export function AutoRotatedToastBox({ show, render, setRender, toastMessage, onAnimationEnd }: Props) {
    const { t } = useTranslationSSR();

    return (
        <AlertBox
            dismissed={!show}
            onRequestDismiss={() => setRender(false)}
            onAnimationEnd={onAnimationEnd}
            className={render ? styles.fadeIn : styles.fadeOut}
        >
            <div className={styles.toastMessageContainer}>
                <Typography>{toastMessage}</Typography>
            </div>
            <AlertBoxDismissButton visuallyHiddenLabel={t(messages.closeButton.id)} />
        </AlertBox>
    );
}
AutoRotatedToastBox.displayName = "AutoRotatedToastBox";
