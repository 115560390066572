import { useState, useRef, useLayoutEffect, useCallback } from "react";
import { useDesigner } from "./DesignerProvider";
import type { ItemSelection } from "../@types/designer";

export function useIsMounted() {
    const ref = useRef(true);
    useLayoutEffect(() => {
        return () => {
            ref.current = false;
        };
    }, []);
    return useCallback(() => ref.current, []);
}

export function useSimpleSelection(events: string) {
    const designer = useDesigner();
    const [selection, setSelection] = useState<ItemSelection>([]);
    const isMounted = useIsMounted();

    useLayoutEffect(() => {
        if (!designer) return;
        const updateSelection = () => {
            const allItems = designer.api.design.canvases.reduce<ItemSelection>(
                (acc, curr) => [...acc, ...curr.items],
                []
            );
            const selectedIds = designer.selectionManager.models.map(model => model.id);
            const selectedItems = allItems.filter(item => selectedIds.includes(item.id));
            selectedItems.sort((a, b) => selectedIds.indexOf(a.id) - selectedIds.indexOf(b.id));
            // Don't update state if the component is not mounted
            if (isMounted()) {
                setSelection(selectedItems);
            }
        };
        designer.selectionManager.on(events, updateSelection);
        updateSelection();
        return () => designer.selectionManager.off(events, updateSelection); // eslint-disable-line consistent-return
    }, [designer, events, isMounted]);

    return selection;
}

export function useSelection(events?: string) {
    return useSimpleSelection(`add remove change:locked ${events}`);
}
