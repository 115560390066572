import React, { useCallback, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, P } from "@vp/swan";
import { addTable } from "@easel";
import { STUDIO_TRACKING_EVENTS, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { useDefaultTableStyle, useDesigner } from "@designer-suite";
import { useTrackEvents } from "@shared/features/Tracking";
import {
    ColumnIcon,
    RowIcon,
    PanelContent,
    PanelDescription,
    PanelTitle,
    StudioIsInteractiveSwitch
} from "@shared/features/StudioChrome";
import { NumberIncrementer as Counter } from "@presentational";
import { MobileExperience, DesktopExperience } from "@shared/features/ResponsiveDesign";

import "./tablePanel.scss";

const messages = defineMessages({
    tablePanelHeader: {
        id: "studio.components.redesign.panels.tablepanel.tableHeader",
        defaultMessage: "Tables"
    },
    tablePanelInfo: {
        id: "studio.components.redesign.panels.tablepanel.tablePanelInfo",
        defaultMessage: "Create your table below, then select it to update text, color, layout and more."
    },
    columnInfo: {
        id: "studio.components.redesign.panels.tablepanel.tableColumn",
        defaultMessage: "Columns"
    },
    columnLabel: {
        id: "studio.components.redesign.panels.tablepanel.Column",
        defaultMessage: "Column Configuration"
    },
    rowInfo: {
        id: "studio.components.redesign.panels.tablepanel.tableRow",
        defaultMessage: "Rows"
    },
    rowLabel: {
        id: "studio.components.redesign.panels.tablepanel.Row",
        defaultMessage: "Row Configuration"
    },
    addTableHeader: {
        id: "studio.components.redesign.panels.tablepanel.addTableHeader",
        defaultMessage: "Add Table"
    }
});

interface Props {
    handleCounter: (counterValue: number) => void;
    icon: JSX.Element;
    label: string;
    startNumber: number;
    ariaLabel: string;
}
export const CounterContainer = ({ handleCounter, icon, label, startNumber, ariaLabel }: Props) => {
    return (
        <div className="counter-container">
            {icon}
            <MobileExperience>
                <P className="counter-container-label" my={2} ml={2} fontSize="2">
                    {label}
                </P>
            </MobileExperience>
            <DesktopExperience>
                <P className="counter-container-label" my={4} ml={2} fontSize="2">
                    {label}
                </P>
            </DesktopExperience>
            <Counter handleCounter={handleCounter} startNumber={startNumber} ariaLabel={ariaLabel} />
        </div>
    );
};
CounterContainer.displayName = "CounterContainer";

interface TablePanelProps {
    /**
     * Columns and Rows Counter start number.
     * @default 1
     * */
    colsAndRowsStartNumber: number;
}

export function TablePanel({ colsAndRowsStartNumber = 1 }: TablePanelProps) {
    const [columns, setColumns] = useState(colsAndRowsStartNumber);
    const [rows, setRows] = useState(colsAndRowsStartNumber);
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const usedStyles = useDefaultTableStyle;
    const { trackEvent } = useTrackEvents();

    const startTime = useRef<number | null>(null);

    const onAdd = useCallback(() => {
        if (!designer) {
            return;
        }
        startTime.current = performance.now();

        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ADD_TABLE });
        addTable(usedStyles(designer.api.design.canvases), rows, columns);
    }, [rows, columns, usedStyles, trackEvent, designer]);

    // Send interaction timing after table is added to canvas by designer
    useEffect(() => {
        if (!designer) return;
        const logTiming = (event: EventData) => {
            const eventType = event.items[0]._itemViewModel.model.get("type");
            if (eventType === "Table" && startTime.current !== null) {
                const endTime = performance.now();
                fireUserInteractionTrackingEvent("Generate Table", endTime - startTime.current);
                startTime.current = null;
            }
        };

        const unsubscribeAdd = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, logTiming);
        /* eslint-disable consistent-return */
        return () => {
            unsubscribeAdd();
        };
    }, [designer, startTime]);

    const columnIcon = <ColumnIcon aria-hidden="true" />;
    const columnLabel = t(messages.columnInfo.id);
    const rowIcon = <RowIcon aria-hidden="true" />;
    const rowLabel = t(messages.rowInfo.id);

    return (
        <PanelContent className="studio-tables-panel-content">
            <PanelTitle>{t(messages.tablePanelHeader.id)}</PanelTitle>
            <PanelDescription>{t(messages.tablePanelInfo.id)}</PanelDescription>
            <div className="table-config-container">
                <CounterContainer
                    handleCounter={setColumns}
                    icon={columnIcon}
                    label={columnLabel}
                    startNumber={columns}
                    ariaLabel={t(messages.columnLabel.id)}
                />
                <CounterContainer
                    handleCounter={setRows}
                    icon={rowIcon}
                    label={rowLabel}
                    startNumber={rows}
                    ariaLabel={t(messages.rowLabel.id)}
                />
            </div>
            <StudioIsInteractiveSwitch>
                <Button skin="primary" width="full-width" size="mini" onClick={onAdd}>
                    {t(messages.addTableHeader.id)}
                </Button>
                <Skeleton width={"100%"} height={60} style={{ borderRadius: "110px", marginTop: "40px" }} />
            </StudioIsInteractiveSwitch>
        </PanelContent>
    );
}

TablePanel.displayName = "TablePanel";
