import * as React from "react";

export const LoadingImagePreviewIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 105" {...props}>
        <path d="M0-.25v106.5h135.37V-.25H0zM124.47 94.7H11.55V11.3h112.92v83.4z" />
        <path d="M17.71 86.86 41.09 62.3l8.76 3.79 27.63-29.43 10.88 13.02 4.9-2.97 26.57 40.15z" />
        <circle r={10.21} cy={33.42} cx={44.72} />
    </svg>
);

LoadingImagePreviewIcon.displayName = "LoadingImagePreviewIcon";
