import React, { PointerEvent } from "react";
import classNames from "classnames";
import { OffsetBarRow } from "../offsetBarRow";
import { OffsetBarColumn } from "../offsetBarColumn";
import { OffsetBarOverlayItem } from "../offsetBarOverlayItem";
import { useTableOverlayControls } from "../../TableOverlayControlsProvider";
import { SubselectionType } from "../../TableSubselectionProvider";

import * as styles from "./OffsetBar.module.scss";

interface OffsetBarProps {
    type: SubselectionType;
    offset: "left" | "top";
    onPointerDown?: (event: PointerEvent) => void;
}

export function OffsetBar({ type, offset, onPointerDown }: OffsetBarProps) {
    const { relativeRowHeights, relativeColumnWidths, fontSizeInPx } = useTableOverlayControls();

    const fontSize = fontSizeInPx ? `${fontSizeInPx}px` : "inherit";

    return (
        <>
            <div
                className={classNames("offset-bar", `offset-bar-${type}`, `offset-bar-${offset}`, styles.offsetBar, {
                    [styles.rowBar]: type === SubselectionType.Row,
                    [styles.columnBar]: type === SubselectionType.Column
                })}
                style={{ fontSize, lineHeight: fontSize }}
                onPointerDown={onPointerDown}
            >
                {type === SubselectionType.Row
                    ? relativeRowHeights.map((rowHeight, index) => {
                          return <OffsetBarRow style={{ height: `${rowHeight}%` }} key={index} rowIndex={index} />;
                      })
                    : relativeColumnWidths.map((columnWidth, index) => {
                          return (
                              <OffsetBarColumn style={{ width: `${columnWidth}%` }} key={index} columnIndex={index} />
                          );
                      })}
            </div>
            <div
                className={classNames(`${type}-overlay-items`, {
                    [styles.rowOverlayItems]: type === SubselectionType.Row,
                    [styles.columnOverlayItems]: type === SubselectionType.Column
                })}
            >
                {type === SubselectionType.Row
                    ? relativeRowHeights.map((rowHeight, index) => {
                          return (
                              <OffsetBarOverlayItem
                                  style={{ height: `${rowHeight}%` }}
                                  key={index}
                                  index={index}
                                  type={type}
                              />
                          );
                      })
                    : relativeColumnWidths.map((columnWidth, index) => {
                          return (
                              <OffsetBarOverlayItem
                                  style={{ width: `${columnWidth}%` }}
                                  key={index}
                                  index={index}
                                  type={type}
                              />
                          );
                      })}
            </div>
        </>
    );
}

OffsetBar.displayName = "OffsetBar";
