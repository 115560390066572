import { useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { mapDesignAttributeToProductOption, mapProductOptionValueToDesignAttributeValue } from "@shared/utils/Product";
import { FlexibilityDesignAttributes } from "../../constants";

export const useCompatibleFlexibilityDesignAttributes = (featureName: FlexibilityDesignAttributes) => {
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const compatibleOptions = useAppSelector(state => state.compatibleProductOptions);

    // Convert the production option values to design attribute
    const designAttributes: string[] = useMemo(() => {
        // Convert the design attribute name to the product option setting name
        const productOptionSettingName = mapDesignAttributeToProductOption(designAttributeMappings, featureName);

        // Get all the values from the compatible product options
        const compatibleProductOptionValues = compatibleOptions.find(
            (options: any) => options.name === productOptionSettingName
        )?.values;
        return (
            compatibleProductOptionValues?.map((name: any) =>
                mapProductOptionValueToDesignAttributeValue(designAttributeMappings, productOptionSettingName, name)
            ) ?? []
        );
    }, [compatibleOptions, designAttributeMappings, featureName]);

    return designAttributes;
};
