import React, { HTMLProps } from "react";
import classNames from "classnames";
import * as styles from "./IconGallery.module.scss";

interface IconGalleryProps extends HTMLProps<HTMLDivElement> {
    galleryImages: { upload: { id: string }; src: string }[];
    thumbnailComponent: (item: any) => JSX.Element;
    className?: string;
    draggableShapes?: { (props: any): JSX.Element; displayName: string }[];
}

export const IconGallery = ({ galleryImages, thumbnailComponent, className, draggableShapes }: IconGalleryProps) => {
    return (
        <ul className={classNames(styles.iconGallery, className)}>
            {/* show shapes on first page in xerox elements panel */}
            {draggableShapes?.map(element => (
                <li key={element.displayName} className={classNames("icon-list-item", styles.iconGalleryListItem)}>
                    {element({})}
                </li>
            ))}

            {galleryImages.map(item => (
                <li key={item.upload.id} className={classNames("icon-list-item", styles.iconGalleryListItem)}>
                    {thumbnailComponent(item)}
                </li>
            ))}
        </ul>
    );
};

IconGallery.displayName = "IconGallery";
