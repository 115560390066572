import React, { createContext, FC, ReactNode, RefObject, useContext, useMemo } from "react";
import useScrollbarSize from "react-scrollbar-size";
import { useClientRect } from "@shared/features/ResponsiveDesign";
import classNames from "classnames";

interface Data {
    leftRect: DOMRect;
    rightRect: DOMRect;
    bottomRect: DOMRect;
    topRect: DOMRect;
    contextualRect: DOMRect;
    canvasRef: RefObject<HTMLElement>;
}

const context = createContext<Data | undefined>(undefined);

export function useLayoutManager() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a LayoutManager");
    }
    return result;
}

type Props = {
    leftRef: RefObject<HTMLElement>;
    rightRef: RefObject<HTMLElement>;
    bottomRef: RefObject<HTMLElement>;
    topRef: RefObject<HTMLElement>;
    contextualRef: RefObject<HTMLElement>;
    canvasRef: RefObject<HTMLElement>;
    children: ReactNode;
    className?: string;
};

interface AppStyle extends React.CSSProperties {
    "--browser-scrollbar-width": string;
    "--browser-scrollbar-height": string;
    "--top-container-height": string;
    "--bottom-container-height": string;
    "--contextual-container-height": string;
    "--left-container-width": string;
}

export const LayoutManager: FC<Props> = props => {
    const { children, leftRef, rightRef, bottomRef, topRef, contextualRef, canvasRef, className } = props;
    const leftRect = useClientRect(leftRef);
    const rightRect = useClientRect(rightRef);
    const bottomRect = useClientRect(bottomRef);
    const topRect = useClientRect(topRef);
    const contextualRect = useClientRect(contextualRef);

    const { Provider } = context;
    const value = useMemo(
        () => ({
            leftRect,
            rightRect,
            bottomRect,
            topRect,
            contextualRect,
            canvasRef
        }),
        [leftRect, rightRect, bottomRect, topRect, contextualRect, canvasRef]
    );

    const { height, width } = useScrollbarSize();

    const style: AppStyle = {
        "--browser-scrollbar-width": `${width}px`,
        "--browser-scrollbar-height": `${height}px`,
        "--top-container-height": `${topRect.height}px`,
        "--bottom-container-height": `${bottomRect.height}px`,
        "--contextual-container-height": `${contextualRect.height}px`,
        "--left-container-width": `${leftRect.width}px`,
        width: "fit-content"
    };
    return (
        <Provider value={value}>
            <div className={classNames("easel-contents", className)} style={style}>
                {children}
            </div>
        </Provider>
    );
};

LayoutManager.displayName = "LayoutManager";
