import React from "react";
import classNames from "classnames";
import { Button } from "@vp/swan";
import { EyeDropperIcon } from "@shared/features/StudioChrome";
import * as styles from "./EyeDropperButton.module.scss";

interface EyeDropperButtonProps {
    /** Click handler */
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

    /** Size of button */
    size?: "mini" | "standard";
    className?: string;
    ariaLabel?: string;
    isOpen?: boolean;
}

export function EyeDropperButton({ onClick, size = "standard", className, ariaLabel, isOpen }: EyeDropperButtonProps) {
    return (
        <Button
            buttonShape="round"
            title={ariaLabel}
            className={classNames(styles.eyedropperButton, className, {
                [styles.eyedropperButtonOpen]: isOpen
            })}
            onClick={onClick}
            size={size}
        >
            <EyeDropperIcon />
            <div className="swan-visually-hidden">{ariaLabel}</div>
        </Button>
    );
}
EyeDropperButton.displayName = "EyedropperButton";
