import React from "react";
import classNames from "classnames";
import * as styles from "./ImageContent.module.scss";
import { ItemContent } from "./ItemContent";

interface ImageContentProps {
    imageSourceUrl: string;
    sizeInfo: string;
}

export function ImageContent({ imageSourceUrl, sizeInfo }: ImageContentProps) {
    return (
        <ItemContent>
            <img
                src={imageSourceUrl}
                title={sizeInfo}
                alt={sizeInfo}
                aria-label={sizeInfo}
                className={classNames("easel-layer-image", styles.imageContentImage)}
            />
        </ItemContent>
    );
}

ImageContent.displayName = "ImageContent";
