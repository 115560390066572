import React, { useEffect } from "react";
import { ChangeTemplateIcon, GenericHeaderButton } from "@shared/features/StudioChrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

export const messages = defineMessages({
    buttonTitle: {
        id: "studio.components.changeTemplate.buttonTitle",
        defaultMessage: "Change template",
        description: {
            note: "Text for change template header button "
        }
    }
});

interface Props {
    className?: string;
}

export const ChangeTemplateButton = ({ className }: Props) => {
    const { t } = useTranslationSSR();
    const { setCurrentActiveDialog } = useActiveDialog();
    const changeTemplateIcon = <ChangeTemplateIcon aria-hidden={true} />;
    const labelContent = t(messages.buttonTitle.id);

    const onClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE_BUTTON,
            label: "Click button to open change template dialog"
        });
        setCurrentActiveDialog(DialogType.ChangeTemplate);
    };

    useEffect(() => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_TEMPLATE_BUTTON,
            label: "show change template button on compatible products"
        });
    }, []);

    return (
        <GenericHeaderButton icon={changeTemplateIcon} label={labelContent} className={className} onClick={onClick} />
    );
};

ChangeTemplateButton.displayName = "ChangeTemplateButton";
