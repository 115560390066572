import React, { ReactNode, useCallback, useMemo, useRef } from "react";
import { defineMessages } from "@vp/i18n-helper";
import { useActiveCanvas } from "@designer-suite";
import { useInitIcons } from "@shared/features/IconAssetLibrary";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { useStudio5AvailablePremiumFinishesOnCanvas, useDecorationTechnology, useProcessType } from "@utilities";
import { isCareAgent } from "@shared/utils/Care";
import {
    TextIcon,
    ImagesIcon,
    ChangeTemplateIcon,
    LayersIcon,
    TablesIcon,
    PremiumFinishIcon,
    QRCodeIcon,
    GraphicsIcon,
    BucketColorIcon,
    BackgroundColorIcon,
    TeamsIcon,
    FlexibilityIcon,
    UploadCloudIcon,
    FolderIcon,
    AITemplatesIcon,
    IdeaPanelIcon
} from "@shared/features/StudioChrome";
import { DialogType } from "@shared/features/ActiveDialog";
import { useTemplateColorVariations } from "@shared/features/Templates";
import { useCartContext } from "@shared/features/Cart";
import { useAppSelector, useAppDispatch, setIsPremiumFinishModalOpen, setShowMyProjects } from "@shared/redux";
import { saveStudio5Design } from "@five/clients/saveClient";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useDesignRequirementsContext, useHasSubstrateColor } from "@shared/features/Product";
import {
    useStudioFlexibility,
    useActiveFlexibilityOptions,
    FlexibilityDesignAttributes
} from "@shared/features/Flexibility";
import { changeTemplateColorMessages, useShowChangeTemplate } from "@shared/features/ChangeTemplate";
import { useStudioLayout } from "@shared/features/ResponsiveDesign/hooks/useStudioLayout";
import { useSave } from "@shared/features/Save";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { getCanvasOrdinal } from "@five/utilities/canvasUtilities";
import { useTriggerCanvasChange } from "@five/hooks/useTriggerCanvasChange";
import { useToolFlags } from "@shared/features/StudioConfiguration";
import { useIsTeamsEnabledDefault } from "@shared/features/StudioBootstrap/useIsTeamsEnabledDefault";
import { useEnableBackgroundColor } from "../../../hooks/useEnableBackgroundColor";
import { shapesIconsMessages as ShapesIconMessages } from "../../Panels";
import { useFullBleedPremiumFinish } from "../../../hooks/useFullBleedPremiumFinish";
import { useIsTeamsEnabled } from "../../../hooks/useIsTeamsEnabled";
import { useIsAITemplatesEnabled } from "../../../hooks/useIsAITemplatesEnabled";
import { useIdeaTabEnabled } from "../../../hooks/useIdeaTabEnabled";
import { TemplatePanelContentTypes, useTemplatePanel } from "../../Panels/TemplatePanel/TemplatePanelProvider";
import { GenerateIdeasPanelMessages } from "../../Panels/GenerateIdeas";
import { useProcessingEmbroideryImage } from "./utils/useProcessingEmbroideryImage";
import { useShowTeamsModalCallback } from "./utils/useShowTeamsModalCallback";
import {
    useElementsPanel,
    ElementsPanelContentTypes,
    messages as ElementsPanelMessages
} from "../../../xerox/features/Elements/ElementsPanelProvider";

const messages = defineMessages({
    templates: {
        id: "studio.components.sidebar.templates.label",
        defaultMessage: "Templates",
        description: {
            note: "Label for the sidebar templates button"
        }
    },
    text: {
        id: "studio.components.sidebar.text.label",
        defaultMessage: "Text",
        description: {
            note: "Label for the sidebar text button"
        }
    },
    images: {
        id: "studio.components.sidebar.images.label",
        defaultMessage: "Images",
        description: {
            note: "Label for the sidebar images button"
        }
    },
    shapes: {
        id: "studio.components.sidebar.shapes.label",
        defaultMessage: "Shapes",
        description: {
            note: "Label for the sidebar shapes button"
        }
    },
    tables: {
        id: "studio.components.sidebar.tables.label",
        defaultMessage: "Tables",
        description: {
            note: "Label for the sidebar tables button"
        }
    },
    qrCodes: {
        id: "studio.components.sidebar.qrCodes.label",
        defaultMessage: "QR Codes",
        description: {
            note: "Label for the sidebar qrCodes button"
        }
    },
    layers: {
        id: "studio.components.sidebar.layer.label",
        defaultMessage: "Layers",
        description: {
            note: "Label for the sidebar layers button"
        }
    },
    panelCloseButton: {
        id: "studio.components.sidebar.panel.close.label",
        defaultMessage: "Close panel",
        description: {
            note: "Label for the close panel button"
        }
    },
    substrateColor: {
        id: "studio.components.sidebar.substrateColor.label",
        defaultMessage: "Color",
        description: {
            note: "Lable for the substrate color selector button"
        }
    },
    substrateColorV2: {
        id: "studio.components.sidebar.substrateColorV2.label",
        defaultMessage: "Product Color",
        description: {
            note: "Lable for the substrate color selector button"
        }
    },
    groupsAndTeams: {
        id: "studio.components.sidebar.groupsAndTeams.label",
        defaultMessage: "Names",
        description: {
            note: "Label for the groups and teams button"
        }
    },
    premiumFinishes: {
        id: "studio.components.sidebar.premiumFinishes.label",
        defaultMessage: "Finishes",
        description: {
            note: "Label for the premium finishes selector button"
        }
    },
    backgroundColor: {
        id: "studio.components.sidebar.backgroundColor.label",
        defaultMessage: "Background",
        description: {
            note: "Label for the background color button"
        }
    },
    myProjects: {
        id: "studio.components.sidebar.myprojects.label",
        defaultMessage: "My Projects",
        description: {
            note: "Label for themy projects button"
        }
    },
    productOptions: {
        id: "studio.components.sidebar.productOptions.label",
        defaultMessage: "Product Options",
        description: {
            note: "Label for the product options button"
        }
    },
    uploads: {
        id: "studio.components.sidebar.uploads.label",
        defaultMessage: "Uploads",
        description: {
            note: "Label for the sidebar uploads button"
        }
    },
    aiTemplates: {
        id: "studio.components.sidebar.aiTemplates.label",
        defaultMessage: "Designs",
        description: {
            note: "Label for PPAG AI Templates"
        }
    }
});

type SidebarPanelButtonConfiguration = {
    rendered: boolean;
    contentType: DialogType;
    messageId: string;
    icon: ReactNode;
    onClickCB?: () => void;
    disabled?: boolean;
    renderEventDetail?: string;
    clickEventDetailType?: string;
    "data-testid"?: string;
};

// This hook returns the sidebar buttons configurations
// Logic about whether or not to render a panel should be extracted into hooks outside of this configuration
// For now this is left as is but as you are working here please pull out business logic into custom hooks like `useEnableBackgroundColor`
export const useSidebarNavConfiguration = () => {
    const dispatch = useAppDispatch();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const isFullBleed = useAppSelector(state => state.isFullBleed);

    const showBackgroundColorButton = useEnableBackgroundColor();
    const processType = useProcessType();
    const { shouldShowQRCodes, shouldShowImages, shouldShowGraphics, shouldShowTables } = useToolFlags(processType);
    const { isLarge } = useStudioLayout();
    const canvas = useActiveCanvas();
    const canvasOrdinal = canvas && getCanvasOrdinal(canvas);
    const triggerCanvasChange = useTriggerCanvasChange();
    const designRequirements = useDesignRequirementsContext();
    const { isDesignAttributeActive, onClickFlexibilityOptions } = useActiveFlexibilityOptions();

    // templates
    const changeTemplate = useShowChangeTemplate();

    //  ------- Graphics/Elements  -------
    const decorationTechnology = useDecorationTechnology();
    const isEmbroidery = decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY;
    const initIcons = useInitIcons();
    const { isXerox, isXeroxOnTop } = useXerox();
    // graphics
    const graphicsCallback = useCallback(() => {
        initIcons();
    }, [initIcons]);
    // elements
    const { jumpToInStack: jumpToInStackElements } = useElementsPanel();
    const elementsCallback = useCallback(() => {
        initIcons();
        jumpToInStackElements(ElementsPanelContentTypes.Main);
    }, [jumpToInStackElements, initIcons]);

    //  ------- Layers  -------
    const advancedTools = useIsFlagEnabled("advancedTools");
    const allowLayers = isCareAgent() || advancedTools;

    //  ------- Flexibility and Xerox/Baselining  -------
    const { isMileStone1Enabled, isMileStone2Enabled } = useStudioFlexibility();
    const { jumpToInStack } = useTemplatePanel();
    const { isAnyEnabled } = useActiveFlexibilityOptions();

    //  ------- PremiumFinishes -------
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();
    const { isButtonVisible: isFullBleedPremiumFinishButtonVisible } = useFullBleedPremiumFinish();
    function setShowPremiumFinishModal(payload: boolean) {
        if (hasPremiumFinishesCurrentCanvas) {
            dispatch(setIsPremiumFinishModalOpen({ isPremiumFinishModalOpen: payload }));
        } else {
            onClickFlexibilityOptions(
                STUDIO_TRACKING_EVENTS.CLICK_DESIGN_ATTRIBUTE_ON_PRODUCT_OPTIONS_PANEL,
                "click on premium finish",
                FlexibilityDesignAttributes.PremiumFinish
            );
        }
    }
    const isMilestone1EnabledForPossiblePremiumFinishProduct =
        isMileStone1Enabled && isDesignAttributeActive(FlexibilityDesignAttributes.PremiumFinish);
    const isNonFullBleedPremiumFinishProduct =
        hasPremiumFinishesCurrentCanvas && !isFullBleedPremiumFinishButtonVisible;

    //  ------- Teams Panel -------
    const teamsModalCallback = useShowTeamsModalCallback();
    const isTeamsEnabled = useIsTeamsEnabled() || false;
    const isTeamsEnabledDefault = useIsTeamsEnabledDefault();

    //  ------- AI Templates Panel -------
    const showDesignTemplatesPanel = useIdeaTabEnabled();
    const isAITemplatesEnabled = useIsAITemplatesEnabled() || false;
    const showAITemplatesPanel = !showDesignTemplatesPanel && isAITemplatesEnabled;
    const { isItemInCart } = useCartContext();

    // ------- Colors Panel -------
    const hasSubstrateColors = useHasSubstrateColor();
    const { hasTemplateColors } = useTemplateColorVariations();
    const processingEmbroideryImage = useProcessingEmbroideryImage();
    const showColorsPanel = useMemo(() => {
        return !isXerox && (hasSubstrateColors || (!isMileStone1Enabled && hasTemplateColors)); // Will expand on this later to show has templateColors
    }, [hasSubstrateColors, hasTemplateColors, isMileStone1Enabled, isXerox]);

    // My projects panel
    const startTimeRef = useRef<number>(0);

    const showChangeTemplate = useMemo(
        () => !isXerox && isMileStone1Enabled && !!changeTemplate && !showDesignTemplatesPanel,
        [changeTemplate, isMileStone1Enabled, isXerox, showDesignTemplatesPanel]
    );

    const save = useSave({
        onSaveCallback: () => {
            dispatch(setShowMyProjects({ show: true, startTime: startTimeRef.current }));
        },
        hideSaveToast: true,
        allowAnonymousUser: true,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_MY_PROJECTS,
        saveDesign: saveStudio5Design
    });

    const colorButtonData = useMemo(() => {
        if (!easelLoaded) {
            return { messageId: "" };
        }
        if (hasSubstrateColors && hasTemplateColors) {
            return {
                messageId: changeTemplateColorMessages.changeColorPanelTitle.id,
                clickEventDetailType: "templateAndMaterialColor",
                renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_TEMPLATE_COLOR_BUTTON_BOTH_TEMPLATE_AND_MATERIAL
            };
        }
        if (hasSubstrateColors) {
            return {
                messageId: changeTemplateColorMessages.changeMaterialColorPanelTitle.id,
                clickEventDetailType: "materialColor"
            };
        }
        return {
            messageId: changeTemplateColorMessages.changeTemplateColorPanelTitle.id,
            clickEventDetailType: "templateColor",
            renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_TEMPLATE_COLOR_BUTTON_ONLY_TEMPLATE
        };
    }, [easelLoaded, hasSubstrateColors, hasTemplateColors]);

    const templateConfig = useCallback(
        (positionCheck: boolean): SidebarPanelButtonConfiguration => ({
            rendered: !!changeTemplate && isLarge && !isFullBleed && positionCheck,
            contentType: DialogType.Template,
            messageId: messages.templates.id,
            icon: <ChangeTemplateIcon aria-hidden="true" />,
            onClickCB: () => {
                if (canvas && designRequirements && canvasOrdinal !== 1) {
                    triggerCanvasChange(designRequirements.getDefaultPanelName());
                }
                jumpToInStack(TemplatePanelContentTypes.Main);
            }
        }),
        [
            canvas,
            canvasOrdinal,
            changeTemplate,
            designRequirements,
            isFullBleed,
            isLarge,
            jumpToInStack,
            triggerCanvasChange
        ]
    );

    // This configuration returns groups of buttons to show in the sidebar panel
    const sidebarPanelButtonsConfiguration: SidebarPanelButtonConfiguration[][] = [
        [
            templateConfig(isXeroxOnTop),
            {
                rendered: true,
                contentType: DialogType.Text,
                messageId: messages.text.id,
                icon: <TextIcon aria-hidden="true" />,
                "data-testid": "textPanel"
            },
            {
                rendered: isTeamsEnabled,
                contentType: DialogType.GroupsAndTeams,
                messageId: messages.groupsAndTeams.id,
                icon: <TeamsIcon aria-hidden="true" />,
                onClickCB: teamsModalCallback
            },
            {
                rendered: showAITemplatesPanel,
                contentType: DialogType.AITemplates,
                messageId: messages.aiTemplates.id,
                icon: <AITemplatesIcon aria-hidden="true" />,
                "data-testid": "aiTemplatesPanelButtonDesktop"
            },
            {
                rendered: showDesignTemplatesPanel,
                contentType: DialogType.IdeaPanel,
                messageId: GenerateIdeasPanelMessages.title.id,
                icon: <IdeaPanelIcon aria-hidden="true" />
            },
            {
                rendered: shouldShowImages,
                contentType: DialogType.Images,
                messageId: isXerox ? messages.uploads.id : messages.images.id,
                icon: isXerox ? <UploadCloudIcon aria-hidden="true" /> : <ImagesIcon aria-hidden="true" />
            },
            {
                rendered: shouldShowGraphics && !isXerox,
                contentType: DialogType.Graphics,
                messageId: isEmbroidery ? ShapesIconMessages.iconPanelTabTitle.id : messages.shapes.id,
                icon: <GraphicsIcon aria-hidden="true" />,
                onClickCB: graphicsCallback
            },
            {
                rendered: shouldShowGraphics && isXerox,
                contentType: DialogType.Elements,
                messageId: ElementsPanelMessages.elementsTitle.id,
                icon: <GraphicsIcon aria-hidden="true" />,
                onClickCB: elementsCallback
            },
            {
                rendered: shouldShowQRCodes,
                contentType: DialogType.QRCodes,
                messageId: messages.qrCodes.id,
                icon: <QRCodeIcon aria-hidden="true" />
            },
            {
                rendered: shouldShowTables,
                contentType: DialogType.Tables,
                messageId: messages.tables.id,
                icon: <TablesIcon aria-hidden="true" />
            }
        ],
        [
            // This finish button only shows for full bleed premium finish products
            {
                rendered: isFullBleedPremiumFinishButtonVisible,
                contentType: DialogType.FullBleedPremiumFinish,
                messageId: messages.premiumFinishes.id,
                icon: <PremiumFinishIcon aria-hidden="true" />
            },
            // This finish shows for template finish products, or products that could have a PF when flexibility is enabled
            {
                rendered: isMilestone1EnabledForPossiblePremiumFinishProduct || isNonFullBleedPremiumFinishProduct,
                contentType: DialogType.PremiumFinishes,
                messageId: messages.premiumFinishes.id,
                icon: <PremiumFinishIcon aria-hidden="true" />,
                onClickCB: () => setShowPremiumFinishModal(true)
            },
            {
                rendered: allowLayers,
                contentType: DialogType.Layers,
                messageId: messages.layers.id,
                icon: <LayersIcon aria-hidden="true" />
            },
            {
                rendered: showChangeTemplate,
                contentType: DialogType.ChangeTemplate,
                messageId: messages.templates.id,
                icon: <ChangeTemplateIcon className="change-template-icon" aria-hidden={true} />,
                renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_TEMPLATE_BUTTON
            },
            {
                rendered: showColorsPanel,
                contentType: DialogType.Color,
                messageId: colorButtonData.messageId,
                icon: <BucketColorIcon aria-hidden={true} className="product-color" />,
                disabled: processingEmbroideryImage,
                renderEventDetail: colorButtonData.renderEventDetail,
                clickEventDetailType: colorButtonData.clickEventDetailType
            },
            {
                rendered: showBackgroundColorButton,
                contentType: DialogType.BackgroundColorPicker,
                messageId: messages.backgroundColor.id,
                icon: <BackgroundColorIcon className="background-color-icon" aria-hidden={true} />,

                renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_BACKGROUND_COLOR_BUTTON
            },
            {
                rendered: isXerox,
                contentType: DialogType.MyProjects,
                messageId: messages.myProjects.id,
                icon: <FolderIcon aria-hidden={true} />,
                onClickCB: () => save()
            },
            {
                rendered: isMileStone2Enabled && isAnyEnabled,
                contentType: DialogType.Flexibility,
                messageId: messages.productOptions.id,
                icon: <FlexibilityIcon className="flexibility-icon" aria-hidden={true} />,
                renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_PRODUCT_OPTIONS_BUTTON
            }
        ]
    ];
    const resolveDefaultDialogType = () => {
        if (showDesignTemplatesPanel && !isItemInCart) {
            return DialogType.IdeaPanel;
        }
        if (showAITemplatesPanel) {
            return DialogType.AITemplates;
        }

        if (isTeamsEnabled && isTeamsEnabledDefault) {
            return DialogType.GroupsAndTeams;
        }

        return sidebarPanelButtonsConfiguration.flat().filter(button => button.rendered)[0].contentType;
    };

    // Set the AI Templates panel as the default panel if it exists
    // Otherwise, set the first rendered panel as the default panel
    // For eg if templatePanel is rendered above Text panel, then that should be the default
    // Wait for changeTemplate to resolve so that we know whether or not to show template panel
    const defaultPanelDialogType = easelLoaded && changeTemplate !== undefined ? resolveDefaultDialogType() : undefined;

    return {
        defaultPanelDialogType,
        sidebarPanelButtonConfiguration: sidebarPanelButtonsConfiguration
    };
};
