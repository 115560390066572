import { useDesigner } from "@designer-suite";
import { useClassifyPanel } from "@five/hooks/useClassifyPanel";
import { useActiveDialog, DialogType, shouldOpenPanel } from "@shared/features/ActiveDialog";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import React, { useState, useEffect } from "react";

export const useOpenItemPanel = () => {
    const designer = useDesigner();
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const [selectedItemId, setSelectedItemId] = useState();
    // This stores the last left non-colorpicker panel to remember which tab to return to when the colorpicker closes
    const [previousLeftPanelTab, setPreviousLeftPanelTab] = useState<DialogType>();

    // Does this do anything?
    const textPanelRef = React.useRef<HTMLButtonElement>(null);

    const { isSmall } = useStudioLayout();
    const isSmartValidationsOpen = useAppSelector(state => state.showValidations.showPanel);
    const { classifyPanel } = useClassifyPanel();

    // Handles which panel is open based on item selection
    useEffect(() => {
        if (!designer) {
            return;
        }

        const itemColorpickers = [
            DialogType.TextColorPicker,
            DialogType.WordArtColorPicker,
            DialogType.FillColorPicker,
            DialogType.StrokeColorPicker,
            DialogType.SingleColorImageColorPicker,
            DialogType.TableBackgroundColorPicker,
            DialogType.TableFontColorPicker,
            DialogType.TeamsFontColorPicker,
            DialogType.EditImage
        ];

        if (!itemColorpickers.includes(currentActiveDialog)) {
            setPreviousLeftPanelTab(currentActiveDialog);
        }

        const openPanel = (selectedItem: any) => {
            // don't open when SmartValidationsPanel is open
            if (selectedItem.models?.length > 0 && !isSmartValidationsOpen) {
                setSelectedItemId(selectedItem.models[0].id);

                const selectedContentType = classifyPanel(selectedItem.models[0]);

                if (shouldOpenPanel(currentActiveDialog, selectedContentType, isSmall)) {
                    setCurrentActiveDialog(selectedContentType);
                    textPanelRef?.current?.focus();
                } else if (
                    itemColorpickers.includes(currentActiveDialog) &&
                    previousLeftPanelTab &&
                    (selectedItemId !== selectedItem.models[0].id || selectedItem.models.length > 1)
                ) {
                    // Color panel should switch to previous panel if item clicked was not text
                    // or the item whose color was being edited
                    setCurrentActiveDialog(previousLeftPanelTab);
                }
            } else if (itemColorpickers.includes(currentActiveDialog) && previousLeftPanelTab) {
                // Handle clicking on the canvas or deleting the selected item
                setCurrentActiveDialog(previousLeftPanelTab);
            }
        };

        designer.selectionManager.on("select", openPanel);
        designer.selectionManager.on("remove", openPanel);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.selectionManager.off("select", openPanel);
            designer.selectionManager.off("remove", openPanel);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designer, setCurrentActiveDialog, currentActiveDialog, textPanelRef, isSmartValidationsOpen]);
};
