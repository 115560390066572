import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useClickOutside } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";

interface Props {
    title: string;
    setShowState: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string;
}

const messages = defineMessages({
    canvasSelectorChangePopoverTitle: {
        id: "studio.components.design.canvasSelector.canvasSelectorChangePopoverTitle",
        defaultMessage: "Explore more [[sideName]] options!",
        description: {
            note: "title text for canvas selector change popover"
        }
    },
    canvasSelectorChangePopoverDescription: {
        id: "studio.components.design.canvasSelector.canvasSelectorChangePopoverDescription",
        defaultMessage: "Choose from a variety of pre-designed templates.",
        description: {
            note: "description text for canvas selector change popover"
        }
    }
});

export const CanvasSelectorChangePopover = (props: Props) => {
    const { title, setShowState, className } = props;
    const { t } = useTranslationSSR();
    const containerRef = useRef<HTMLDivElement>(null);
    const { isSmall, isMedium } = useStudioLayout();

    useEffect(() => {
        setTimeout(() => {
            return setShowState(false);
        }, 10000);
    }, [setShowState]);

    useClickOutside(
        containerRef,
        async () => {
            setTimeout(() => {
                return setShowState(false);
            }, 2000);
        },
        [setShowState]
    );

    return (
        <>
            <div className={classNames(className, "fade-in")} ref={containerRef}>
                <Typography fontSize={4}>
                    {t(messages.canvasSelectorChangePopoverTitle.id, { sideName: title.toLowerCase() })}
                </Typography>
                <Typography fontSize={1} pt={3}>
                    {t(messages.canvasSelectorChangePopoverDescription.id)}
                </Typography>
                {isSmall || isMedium ? <div className="arrow-bottom" /> : <div className="arrow-right" />}
            </div>
        </>
    );
};

CanvasSelectorChangePopover.displayName = "CanvasSelectorChangePopover";
