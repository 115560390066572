import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { WesSortOptions } from "@shared/utils/Work";
import { MyProjectsSortDropdownOption } from "./MyProjectsSortDropdownOption";
import * as styles from "./MyProjectsSortDropdownContent.module.scss";
import { messages } from "./messages";

interface Props {
    onSortOptionClick: (value: WesSortOptions, title: string) => void;
    selectedOption: string;
}

export function MyProjectsSortDropdownContent({ onSortOptionClick, selectedOption }: Props) {
    const { t } = useTranslationSSR();

    return (
        <div role="radiogroup">
            <MyProjectsSortDropdownOption
                className={styles.option}
                value={WesSortOptions.LAST_MODIFIED}
                title={t(messages.myProjectsLastEdited.id)}
                onClick={onSortOptionClick}
                selectedOption={selectedOption}
            />
            <MyProjectsSortDropdownOption
                className={styles.option}
                value={WesSortOptions.LAST_CREATED}
                title={t(messages.myProjectsDateCreated.id)}
                onClick={onSortOptionClick}
                selectedOption={selectedOption}
            />
        </div>
    );
}
MyProjectsSortDropdownContent.displayName = "MyProjectsSortDropdownContent";
