import React, { useState } from "react";
import classNames from "classnames";
import { Link } from "@vp/swan";
import { PlacementStrategyCallback, UploadImage } from "@designer-suite";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { ExpandArrowIcon } from "@shared/features/StudioChrome";
import { useAppDispatch, setClipartGalleryPreview } from "@shared/redux";
import { ImageThumbnailContainer } from "@shared/features/StudioChrome/IconPanel";
import { StudioMetadataItem } from "@shared/utils/Metadata";
import { SIMTAG_PREFIX } from "@five/xerox/features/Elements";
import type { Upload } from "../../../../easel/designer-suite/@types/upload";
import { DP_IMAGE_PREFIX } from "./ImagePanelUtils";
import { useImageGalleryPreview } from "./ImageGalleryPreviewProvider";

interface Props {
    // Upload as presented in designer.uploadManager
    upload: Upload;
    onClick?: PlacementStrategyCallback;
    onDoubleClick?: PlacementStrategyCallback;
    style?: any;
    imageUrl?: string;
    showPreviewButton?: boolean;
    studioMetadata?: StudioMetadataItem;
    onStart?: (event: any) => void;
    onStop?: (event: any) => void;
    maxCanvasSize?: number;
    svgUrl?: string;
    width?: number;
    height?: number;
    className?: string;
    updateRecentElements?: () => void;
}

export const ImageThumbnail = (props: Props) => {
    const {
        upload,
        onClick,
        onDoubleClick,
        style,
        imageUrl,
        showPreviewButton = true,
        studioMetadata,
        onStart,
        onStop,
        maxCanvasSize,
        svgUrl,
        width,
        height,
        className
    } = props;
    const { trackEvent } = useTrackEvents();
    const [loaded, setLoaded] = useState(false);
    const dispatch = useAppDispatch();
    const pages = upload.get("pages");
    const { setImageGalleryPreviewData } = useImageGalleryPreview();
    if (!pages || !pages.at(0)) {
        return null;
    }
    const page = pages.at(0);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { key } = event;
        if (key && (key === "Enter" || key === " ")) {
            if (onClick) {
                onClick({ upload, page });
            } else if (onDoubleClick) {
                onDoubleClick({ upload, page });
            }
        }
    };

    const previewUrl = svgUrl || imageUrl || page.get("previewUrl");

    return (
        <ImageThumbnailContainer
            onClick={() => onClick?.({ upload, page })}
            onDoubleClick={() => onDoubleClick?.({ upload, page })}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => onKeyDown(event)}
            className={classNames({ "swan-bgc-loading-shimmer": !loaded })}
        >
            <UploadImage
                className={classNames("image-thumbnail-image", className)}
                style={style}
                page={page}
                upload={upload}
                imageUrl={imageUrl}
                studioMetadata={studioMetadata}
                onStart={onStart}
                onStop={onStop}
                maxCanvasSize={maxCanvasSize}
                onImageLoaded={() => setLoaded(true)}
            />
            {showPreviewButton && (
                <div className="image-thumbnail-preview" style={{ ...style }}>
                    <Link
                        component="button"
                        onClick={(evt: React.MouseEvent) => {
                            evt.stopPropagation();
                            if (upload.id?.startsWith(DP_IMAGE_PREFIX)) {
                                trackEvent({
                                    eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_GALLERY_IMAGE_OPEN,
                                    extraData: () => ({ previewUrl })
                                });
                                setImageGalleryPreviewData({ upload, page, previewUrl });
                            }
                            if (upload.id?.startsWith(SIMTAG_PREFIX)) {
                                if (!width || !height || !imageUrl) {
                                    return;
                                }
                                dispatch(
                                    setClipartGalleryPreview({
                                        id: upload.id,
                                        src: imageUrl,
                                        previewUrl,
                                        width,
                                        height,
                                        printUrl: page.get("printUrl"),
                                        printPixelWidth: page.get("width"),
                                        printPixelHeight: page.get("height")
                                    })
                                );
                            }
                        }}
                        onDoubleClick={(evt: React.MouseEvent) => {
                            evt.stopPropagation();
                        }}
                        className="image-thumbnail-preview-button"
                    >
                        <ExpandArrowIcon role="img" />
                    </Link>
                </div>
            )}
        </ImageThumbnailContainer>
    );
};

ImageThumbnail.displayName = "ImageThumbnail";
