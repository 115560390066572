import { useEffect } from "react";
import { useDesigner, useActiveCanvas } from "@designer-suite";
import {
    getLastFontColorChosen,
    onApply
} from "../../../../studioFive/components/Panels/Redesign/WordArtAndTextColorPickerPanel";
import { useRichTextSet } from "../../designer/useRichTextSet";

function getCommonFontColors(activeCanvas: Canvas, itemIDsToIgnore: string[]) {
    const usedColors = {};
    activeCanvas.items
        .filter(item => item.itemType === "TEXT")
        .filter(item => !itemIDsToIgnore.includes(item.id))
        .forEach((item: TextItem) => {
            if (item.color) {
                usedColors[item.color] = (usedColors[item.color] || 0) + 1;
            } else {
                // find the unique colors used by this field.  Solution we're solving for is text that switches
                // between red, white, and blue for "Happy July 4th".  We want to count each color once, not 4 times.
                Array.from(new Set(item.content.map(field => field.color))).forEach(color => {
                    usedColors[color] = (usedColors[color] || 0) + 1;
                });
            }
        });

    let highestCount = 0;
    let mostUsedColors: string[] = [];
    Object.keys(usedColors).forEach(color => {
        if (usedColors[color] > highestCount) {
            mostUsedColors = [color];
            highestCount = usedColors[color];
        } else if (usedColors[color] === highestCount) {
            mostUsedColors.push(color);
        }
    });

    return mostUsedColors;
}

/**
 * A hook that sets up a callback that selects a font color for a new textfield
 * based on common colors or the most recently user-selected font color
 */
export function useFontColorDefaults() {
    const designer = useDesigner();
    const activeCanvas = useActiveCanvas();
    const { setWithSideEffects } = useRichTextSet();
    useEffect(() => {
        if (!designer) {
            return;
        }

        function setFontColor({ items }: EventData): void {
            if (!activeCanvas) {
                return;
            }
            const newTextItems = items.filter(item => item.itemType === "TEXT").filter((item: TextItem) => !item.value);
            if (newTextItems) {
                const commonColors = getCommonFontColors(
                    activeCanvas,
                    newTextItems.map(item => item.id)
                );
                const lastFontColorChosen = getLastFontColorChosen();
                let defaultColor = null;
                // if we have one common color, use that
                if (commonColors.length === 1) {
                    // eslint-disable-next-line prefer-destructuring
                    defaultColor = commonColors[0];
                    // if no common color, or there is a tie, then use the last chosen color
                } else if (lastFontColorChosen) {
                    defaultColor = lastFontColorChosen;
                    // if we don't have a last chosen color, and we had a tie for common colors, then use the first common color
                } else if (commonColors.length > 0) {
                    // eslint-disable-next-line prefer-destructuring
                    defaultColor = commonColors[0];
                }
                // if we didn't choose a default color, then we are using the default from cimpress designer
                // they handle things like product restrictions, possibly using the scene colors, etc in ColorManager.js
                if (defaultColor) {
                    onApply(designer, setWithSideEffects, newTextItems, defaultColor, false);
                }
            }
        }

        const unsubscribeFn = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, setFontColor);

        // eslint-disable-next-line consistent-return
        return unsubscribeFn;
    }, [designer, activeCanvas, setWithSideEffects]);
}
