import { useCallback } from "react";
import { useGetUnreplacedImagePlaceholders } from "@designer-suite";
import { Events, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackingClient } from "@shared/features/Tracking";

export const useTrackAutoPlacement = () => {
    const trackingClient = useTrackingClient();
    const unreplacedImagePlaceholders = useGetUnreplacedImagePlaceholders();

    const unreplacedPlaceholdersCount = unreplacedImagePlaceholders.length;

    return useCallback(
        (imagesCount = 1) => {
            if (unreplacedPlaceholdersCount === 0) {
                return;
            }

            trackingClient.track(Events.StudioDiagnostic, {
                eventDetail: STUDIO_TRACKING_EVENTS.AUTO_PLACEMENT_IMAGES,
                label: "Image",
                extraData: () => ({
                    imagesCount,
                    placedImagesCount: imagesCount,
                    unreplacedPlaceholdersCount
                })
            });
        },
        [trackingClient, unreplacedPlaceholdersCount]
    );
};
