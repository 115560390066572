import React, { forwardRef, Ref, useCallback } from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { useActiveCanvas } from "@designer-suite";
import { getDesignDocumentFromDesigner } from "@utilities";
import { CanvasSelector } from "@shared/features/CanvasSelectors";
import { useTriggerCanvasChange } from "../../hooks/useTriggerCanvasChange";
import "./EaselContentsRightContainer.scss";

export const EaselContentsRightContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const { isSmall, isMedium } = useStudioLayout();
    const activeCanvas = useActiveCanvas();
    const multiPanelDesign = useAppSelector(state => state.designDocumentHasMultiplePanels);
    const minWidth = !isSmall && !isMedium && multiPanelDesign ? "116px" : undefined;

    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(false), []);
    const triggerCanvasChange = useTriggerCanvasChange();

    return (
        <div ref={ref} style={{ minWidth }} className="right-container">
            {!isSmall && (
                <CanvasSelector
                    className="canvas-selectors"
                    activeCanvasName={activeCanvas?.name}
                    getDocument={getDocument}
                    triggerCanvasChange={triggerCanvasChange}
                />
            )}
        </div>
    );
});

EaselContentsRightContainer.displayName = "EaselContentsRightContainer";
