/* eslint-disable max-len */
import React from "react";

export function TeamsGuideLabelStep3(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 40 40" fill="none" {...props}>
            <circle cx="20" cy="20" r="19.5" fill="white" stroke="black" />
            <path
                d="M19.472 26.18C22.424 26.18 24.296 24.704 24.296 22.328C24.296 20.618 23.306 19.556 21.812 19.214C23.054 18.746 23.9 17.864 23.9 16.406C23.9 14.498 22.334 12.95 19.652 12.95C16.898 12.95 15.224 14.66 15.098 16.892H17.222C17.366 15.596 18.248 14.768 19.616 14.768C20.948 14.768 21.614 15.542 21.614 16.586C21.614 17.792 20.948 18.476 19.472 18.476H18.554V20.186H19.544C21.11 20.186 21.866 20.888 21.866 22.292C21.866 23.57 21.092 24.38 19.418 24.38C17.69 24.38 16.934 23.372 16.808 22.022H14.63C14.738 24.488 16.412 26.18 19.472 26.18Z"
                fill="black"
            />
        </svg>
    );
}
TeamsGuideLabelStep3.displayName = "TeamsGuideLabelStep3";
