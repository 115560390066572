import React, { useContext, createContext, ReactNode } from "react";

export interface Theme {
    buttonFontFamily: string;
    buttonFontSize: string;
    buttonFontWeight: string | number;
}

export const defaultTheme: Theme = {
    buttonFontFamily: "Graphik, sans-serif",
    buttonFontSize: "16px",
    buttonFontWeight: 600
};

const themeContext = createContext<Theme>(defaultTheme);

export function useTheme() {
    return useContext(themeContext);
}

interface Props {
    /** The theme */
    theme?: Theme;
    /** All designer components should be nested underneath this provider */
    children: ReactNode | ReactNode[];
}

/**
 * The Provider of the component theme. If this Provider is not used, the default theme would be used instead.
 */
export function ThemeProvider({ theme, children }: Props) {
    const { Provider } = themeContext;

    return <Provider value={theme || defaultTheme}>{children}</Provider>;
}
ThemeProvider.displayName = "ThemeProvider";
