import React, { useRef, useLayoutEffect, useEffect, useState, ReactNode } from "react";
import classNames from "classnames";
import { useStudioLayout, useClientRect } from "@shared/features/ResponsiveDesign";
import { getCanvasPagePosition, getTopItemPageBoundingBox } from "./util";
import { useDesigner } from "./designer/DesignerProvider";
import { useSelection } from "./designer/useSelection";
import { useActiveCanvas } from "./designer/useActiveCanvas";
import { DEFAULT_MIN_DISTANCE_TO_ITEMS, ITEM_ROTATE_HEIGHT } from "../utilities/constants";
import "./selectedItemToolbox.scss";

interface Props {
    /**
        The distance between the top item and the toolbox
        @default 10
    */
    minDistanceToItems?: number;
    /**
        The closest that the toolbox could be positioned to the top of the container.
        @default 0
    */
    maxTop?: number;
    /* Child elements to be positioned */
    children: ReactNode | ReactNode[];
    /** For overriding styles */
    className?: string;
    /**
        If false SelectedItemToolbox will no longer be positioned above the selected item
        @default true
    */
    autoPosition?: boolean;
    /**
        If false then SelectedItemToolbox will not disappear on drag, resize, or rotate
        @default true
    */
    shouldToggleToolbox?: boolean;

    offsetForValidation?: boolean;
}

const useIsRotating = () => {
    const [isRotating, setIsRotating] = useState(false);
    const designer = useDesigner();

    useEffect(() => {
        if (!designer) return;

        designer?.eventBus.on("transform:rotatestart", () => setIsRotating(true));
        designer?.eventBus.on("transform:rotatestop", () => setIsRotating(false));
        // eslint-disable-next-line consistent-return
        return () => {
            designer?.eventBus.off("transform:rotatestart");
            designer?.eventBus.off("transform:rotatestop");
        };
    }, [designer]);

    return isRotating;
};

/*
    This component will position above the currently selected item.
*/
export function SelectedItemToolBox({
    children,
    minDistanceToItems = DEFAULT_MIN_DISTANCE_TO_ITEMS,
    className,
    maxTop = 0,
    autoPosition = true,
    shouldToggleToolbox = true,
    offsetForValidation = false
}: Props) {
    const designer = useDesigner();
    const selection = useSelection(
        "change:handleBoundingBox change:selecting change:rotating change:resizing change:dragging"
    );

    const canvas = useActiveCanvas("change:offsetPosition");
    const toolBoxRef = useRef<HTMLDivElement>(null);
    const containerRect = useClientRect(toolBoxRef);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [showToolbar, setShowToolbar] = useState<boolean>(false);
    const { isSmall } = useStudioLayout();
    const scrollTop = window?.scrollY;
    const scrollLeft = window?.scrollX;

    useLayoutEffect(() => {
        if (typeof window === "undefined" || !designer || !selection.length || !canvas || !autoPosition) {
            return;
        }

        // Determine the base case position
        const itemBox = getTopItemPageBoundingBox(selection, canvas, !isSmall);
        if (!itemBox || !itemBox.top || Number.isNaN(itemBox.top) || !itemBox.left || Number.isNaN(itemBox.left)) {
            return;
        }
        let top = itemBox.top - containerRect.height - minDistanceToItems;
        let left = itemBox.left + itemBox.width / 2 - containerRect.width / 2;

        // If rotated upside down, shift it a little to allow for rotation
        if (itemBox.rotation < 220 && itemBox.rotation > 140) {
            top -= 35;
        } else if (itemBox.rotation < 230 && itemBox.rotation > 130) {
            top -= 20;
        }

        const canvasPosition = getCanvasPagePosition(canvas);

        if (!isSmall) {
            // if item gets close to top/left, lock it at `minDistanceToItems` from the top/left of the canvas
            if (top - scrollTop <= minDistanceToItems)
                top = itemBox.top + itemBox.height + minDistanceToItems + ITEM_ROTATE_HEIGHT;

            // if item gets close to top/left, lock it at `minDistanceToItems` from the right of the canvas
            const itemBoxRight = left + containerRect.width;
            const canvasWidth = Math.round(canvas.pxDimensions.width);
            const canvasRight = canvasWidth - minDistanceToItems;
            if (itemBoxRight >= canvasRight) left = canvasWidth - containerRect.width - minDistanceToItems;

            // Check the right first because if the item doesn't fit on both side,
            // we prefer that the field covers the canvas selector
            if (left - scrollLeft <= minDistanceToItems) left = minDistanceToItems + scrollLeft;
        } else {
            // If off the canvas at the top, shift to be, at most, a padding away from the top of the canvas
            if (top < canvasPosition.top - minDistanceToItems - containerRect.height) {
                top = canvasPosition.top - minDistanceToItems - containerRect.height;
            }

            // If off the left of the screen or the right, bring it back in
            if (left < 0) {
                left = 0;
            } else if (left > window.innerWidth - containerRect.width / 2) {
                left = window.innerWidth - containerRect.width / 2;
            }
        }

        if (offsetForValidation) {
            top -= 15;
        }

        // Set the final calculated position
        setPosition({
            top,
            left
        });
    }, [
        designer,
        selection,
        canvas,
        containerRect,
        isSmall,
        minDistanceToItems,
        autoPosition,
        scrollTop,
        scrollLeft,
        offsetForValidation
    ]);

    useEffect(() => {
        if (!designer || !selection.length || !canvas) return;
        const dragging = !!selection[0]._itemViewModel.get("dragging");
        const resizing = !!selection[0]._itemViewModel.get("resizing");
        const rotating = !!selection[0]._itemViewModel.get("rotating");
        const selecting = !!selection[0]._itemViewModel.get("selecting");
        if (!shouldToggleToolbox) {
            // Open toolbar initialy
            setShowToolbar(true);
        } else if (dragging || resizing || rotating || selecting) {
            // After dragging, resizing, and rotating the tool box is hidden,
            // even if it was already open.
            setShowToolbar(false);
        } else {
            setShowToolbar(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    const isRotating = useIsRotating();

    const hideToolbar = !showToolbar || (autoPosition && !position.top);

    return (
        <div
            className={classNames(
                className,
                "easel-selected-item-toolbox",
                { "easel-selected-item-toolbox-autoposition": autoPosition },
                { "easel-selected-item-toolbox-hidden": hideToolbar || isRotating }
            )}
            ref={toolBoxRef}
            style={{ ...position }}
        >
            {children}
        </div>
    );
}
SelectedItemToolBox.displayName = "SelectedItemToolBox";
