import { defineMessages } from "@vp/i18n-helper";

export const imageLibraryPanelMessages = defineMessages({
    discoverTab: {
        id: "studio.components.panels.images.imageLibraryPanel.tab.title",
        defaultMessage: "Discover",
        description: {
            note: "Tab title shown for the image library tab"
        }
    },
    uploadTab: {
        id: "studio.components.panels.images.uploads.tab.title",
        defaultMessage: "Upload",
        description: {
            note: "Tab title shown for the uploads tab"
        }
    },
    searchPlaceholder: {
        id: "studio.components.panels.images.imageLibraryPanel.search.placeholder.text",
        defaultMessage: "Search for photos...",
        description: {
            note: "Placeholder text shown in the search text field, before a user starts to type"
        }
    },
    intro: {
        id: "studio.components.panels.images.imageLibraryPanel.intro",
        defaultMessage: "Professional photos for every project - for free.",
        description: {
            note: "Description for search results when no recent photos were used"
        }
    },
    colorHeading: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.color.heading",
        defaultMessage: "Color",
        description: {
            note: "Heading for a section in a filter panel to filter images by color"
        }
    },
    peopleHeading: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.people.heading",
        defaultMessage: "People",
        description: {
            note: "Heading for a section in a filter panel to filter images by people"
        }
    },
    peopleOptionAny: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.people.choice.any",
        defaultMessage: "Any",
        description: {
            note: "Option choice for people filter that allows any people"
        }
    },
    peopleOptionPeopleOnly: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.people.choice.peopleOnly",
        defaultMessage: "People only",
        description: {
            note: "Option choice for people filter that allows only people"
        }
    },
    peopleOptionExcludePeople: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.people.choice.excludePeople",
        defaultMessage: "Exclude people",
        description: {
            note: "Option choice for people filter that exclude people"
        }
    },
    peopleOptionNoFace: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.people.choice.noFace",
        defaultMessage: "No face",
        description: {
            note: "Option choice for people filter that includes no face"
        }
    },
    orientationHeading: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.orientation.heading",
        defaultMessage: "Orientation",
        description: {
            note: "Heading for a section in a filter panel to filter images by orientation"
        }
    },
    orientationOptionAny: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.orientation.choice.all",
        defaultMessage: "All",
        description: {
            note: "Option choice for orientation filter that allows any orientation"
        }
    },
    orientationOptionLandscape: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.orientation.choice.landscape",
        defaultMessage: "Landscape",
        description: {
            note: "Option choice for orientation filters to Landscape"
        }
    },
    orientationOptionPortrait: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.orientation.choice.portrait",
        defaultMessage: "Portrait",
        description: {
            note: "Option choice for orientation filters to Portrait"
        }
    },
    orientationOptionSquare: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.orientation.choice.square",
        defaultMessage: "Square",
        description: {
            note: "Option choice for orientation filters to Square"
        }
    },
    applyFilter: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.cta.applyFilter",
        defaultMessage: "Apply filters",
        description: {
            note: "Closes filter dialog with current filter applied"
        }
    },
    resultsNoneWithFilters: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.results.none",
        defaultMessage: `We couldn't find results for <bold>"[[term]]"</bold> using your current filters.`,
        description: {
            note: "Message shown in results when no images are found and a filter is applied"
        }
    },
    resultsNoneNoFilters: {
        id: "studio.components.panels.images.imageLibraryPanel.search.results.none",
        defaultMessage: `Sorry, we couldn't find a match for <bold>"[[term]]"</bold>.`,
        description: {
            note: "Message shown in results when no images are found and no filter is applied"
        }
    },
    resultsNoneNoFiltersDescription: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.results.noneDescription",
        defaultMessage: `Please check your spelling or try searching with a different word.`,
        description: {
            note: "Description shown in results when no images are found and no filter is applied"
        }
    },
    unfilteredResults: {
        id: "studio.components.panels.images.imageLibraryPanel.unfilteredResults",
        defaultMessage: `Unfiltered results for <bold>"[[term]]"</bold>`,
        description: {
            note: "Description for unfiltered results after all filtered results have been shown"
        }
    },
    resultsClearAllFilters: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.results.clearAllFilters",
        defaultMessage: "Clear all",
        description: {
            note: "Clears all filters from the search results"
        }
    },
    resultsClearSearch: {
        id: "studio.components.panels.images.imageLibraryPanel.filter.results.clearSearch",
        defaultMessage: "Clear search",
        description: {
            note: "Clears the search term"
        }
    },
    searchRecentHeading: {
        id: "studio.components.panels.images.imageLibraryPanel.search.recent.heading",
        defaultMessage: "Recent searches:",
        description: {
            note: "Heading / title to list of recent search terms"
        }
    },
    searchResultsRecentHeading: {
        id: "studio.components.panels.images.imageLibraryPanel.results.recent.used.heading",
        defaultMessage: "Recently used photos",
        description: {
            note: "Heading / title of search results when recently used photos are shown"
        }
    },
    searchClear: {
        id: "studio.components.panels.images.imageLibraryPanel.search.clear.button",
        defaultMessage: "Clear",
        description: {
            note: "Aria text for a (x) graphic button to clear the current search term"
        }
    },
    searchFilter: {
        id: "studio.components.panels.images.imageLibraryPanel.search.filter.button",
        defaultMessage: "Filter",
        description: {
            note: "Aria text for a graphic button to open the filter dialog"
        }
    },
    searchFilterApplied: {
        id: "studio.components.panels.images.imageLibraryPanel.search.filter.applied",
        defaultMessage: "Filter applied",
        description: {
            note: "Aria text for a graphic checkmark that shows if a filter is applied"
        }
    },
    searchSuggested: {
        id: "studio.components.panels.images.imageLibraryPanel.search.suggested",
        defaultMessage: "Suggested",
        description: {
            note: "Title for suggestion popover"
        }
    },
    addToDesignButtonLabel: {
        id: "studio.components.panels.images.imageLibraryPanel.preview.addToDesignButtonLabel",
        defaultMessage: "Add to design",
        description: {
            note: "Label for the add to design button on the image gallery preview panel"
        }
    },
    filterSheetTitle: {
        id: "studio.components.panels.images.imageLibraryPanel.filterSheet.title",
        defaultMessage: "Filters",
        description: {
            note: "Title for mobile filter sheet"
        }
    },
    closeModalAriaLabel: {
        id: "studio.components.panels.images.imageLibraryPanel.tab.closeModalAriaLabel",
        defaultMessage: "Close",
        description: {
            note: "Image library panel close button aria label"
        }
    },
    imageGalleryModalAriaLabel: {
        id: "studio.components.panels.images.imageLibraryPanel.tab.imageGalleryModalAriaLabel",
        defaultMessage: "Image gallery preview modal",
        description: {
            note: "Image gallery preview modal aria label"
        }
    },
    imageSearchButtonLabel: {
        id: "studio.components.panels.images.imageLibraryPanel.filterSheet.imageSearchButtonLabel",
        defaultMessage: "Search image",
        description: {
            note: "Label for a image search button"
        }
    },
    imageSearchResetButtonLabel: {
        id: "studio.components.panels.images.imageLibraryPanel.filterSheet.imageSearchResetButtonLabel",
        defaultMessage: "Reset Search",
        description: {
            note: "Label for a reset image search button"
        }
    },
    imageSearchFilterButtonLabel: {
        id: "studio.components.panels.images.imageLibraryPanel.filterSheet.imageSearchFilterButtonLabel",
        defaultMessage: "Filter Image",
        description: {
            note: "Label for a filter image search button"
        }
    }
});
