import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import {
    ItemList,
    usePremiumFinishText,
    usePremiumFinishWordArt,
    PremiumFinishWordArtAndTextEditorIcon,
    useDesigner
} from "@designer-suite";
import { getLargestTextFieldNumber, isWordArt, updatedTextAndWordArtFieldNumber } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { WordArtFieldRow } from "./WordArtFieldRow";
import { RichTextFieldRow } from "./RichTextFieldRow";
import "./textEditor.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Do we want to allow the user to be able to delete text items from the line
     * @default true
     * */
    showDeleteButtons?: boolean;
    className?: string;
}

export function TextList({ showDeleteButtons = true, className }: Props) {
    const numRef = useRef(0);
    const designer = useDesigner();
    useEffect(() => {
        numRef.current = getLargestTextFieldNumber(designer);
    }, [designer]);
    const resolveComponent = (item: TextItem | WordArtItem) => {
        if (item._itemViewModel.model.get("restricted")) {
            return null;
        }
        if (item.itemType === ItemTypes.TEXT) {
            if (updatedTextAndWordArtFieldNumber(item, numRef.current + 1)) {
                numRef.current += 1;
            }
            return (
                <RichTextFieldRow
                    item={item as TextItem}
                    icon={<PremiumFinishWordArtAndTextEditorIcon item={item} usePremiumFinish={usePremiumFinishText} />}
                    showDeleteButtons={showDeleteButtons}
                />
            );
        }

        if (isWordArt(item)) {
            if (updatedTextAndWordArtFieldNumber(item, numRef.current + 1)) {
                numRef.current += 1;
            }
            return (
                <WordArtFieldRow
                    item={item as WordArtItem}
                    icon={
                        <PremiumFinishWordArtAndTextEditorIcon item={item} usePremiumFinish={usePremiumFinishWordArt} />
                    }
                    showDeleteButtons={showDeleteButtons}
                />
            );
        }

        return null;
    };

    return (
        <div className={classNames("studio-text-list", className)}>
            <ItemList resolveComponent={resolveComponent} className="studio-text-editor" />
        </div>
    );
}
TextList.displayName = "TextList";
