import {
    type AppDispatch,
    setDesignDocument,
    setReviewInstructions,
    setTeamsPlaceholders,
    useAppDispatch
} from "@shared/redux";
import type { DSS } from "@vp/types-ddif";
import { useEffect } from "react";

// exporting this to support studio5 My Projects loading
export async function getCimdocMetadata(designDocument: DSS.DesignDocument, dispatch: AppDispatch) {
    const reviewInstructions = designDocument.metadata?.reviewInstructions;
    dispatch(setReviewInstructions(reviewInstructions ?? []));

    const newTeamsPlaceholders = designDocument.metadata?.teamsPlaceholders;
    if (newTeamsPlaceholders) {
        dispatch(setTeamsPlaceholders(newTeamsPlaceholders ?? []));
    }

    dispatch(setDesignDocument(designDocument));
}

export function useGetCimdocMetadata(designDocument: DSS.DesignDocument | undefined) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (designDocument) {
            getCimdocMetadata(designDocument, dispatch);
        }
    }, [designDocument, dispatch]);
}
