import React, { useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import classNames from "classnames";
import { ResizeIcon } from "@shared/features/StudioChrome";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTableOverlayControls } from "../../TableOverlayControlsProvider";

import * as styles from "./ColumnResizer.module.scss";

interface ColumnResizerProps {
    columnIndex: number;
}

// This is a percent of the this/next column that we can drag to the left or the right
const MAXIMUM_CHANGE_ALLOWED = 0.8;
const HANDLE_WIDTH = 24;

export function ColumnResizer({ columnIndex }: ColumnResizerProps) {
    const [drawLine, setDrawLine] = useState(false);
    const { trackEvent } = useTrackEvents();
    const {
        relativeColumnWidths,
        tableDimensionsInPx: { width, height },
        onColumnResize
    } = useTableOverlayControls();

    const columnsWidthsInPx = relativeColumnWidths.map(percent => width * (percent / 100));

    const handleStart = () => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.USE_TABLE_OVERLAY_COLUMN_RESIZE });
        setDrawLine(true);
    };

    const handleStop = (e: DraggableEvent, draggableData: DraggableData) => {
        setDrawLine(false);

        const percentDelta = draggableData.lastX / columnsWidthsInPx[columnIndex];
        onColumnResize(columnIndex, percentDelta);
    };

    return (
        <Draggable
            axis="x"
            bounds={{
                left: -columnsWidthsInPx[columnIndex] * MAXIMUM_CHANGE_ALLOWED + HANDLE_WIDTH,
                right: columnsWidthsInPx[columnIndex + 1] * MAXIMUM_CHANGE_ALLOWED
            }}
            defaultPosition={{ x: 0, y: 0 }}
            handle=".table-column-resize-handle"
            position={{ x: 0, y: 0 }}
            grid={[1, 0]}
            scale={1}
            onStart={handleStart}
            onStop={handleStop}
        >
            <div className={classNames("table-column-resize-handle", styles.columnResizeHandle)}>
                <ResizeIcon />
                {drawLine && (
                    <div
                        style={{ height }}
                        className={classNames("table-column-resize-marker", styles.columnResizeMarker)}
                    />
                )}
            </div>
        </Draggable>
    );
}

ColumnResizer.displayName = "ColumnResizer";
