import React from "react";
import { TemplateIcon, GenericHeaderButton } from "@shared/features/StudioChrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppDispatch, setShowSpecsAndTemplateModal, setSpecsAndTemplateModalViewed } from "@shared/redux";

interface Props {
    className?: string;
}

const messages = defineMessages({
    defaultButtonText: {
        id: "studio.components.specsAndTemplates.defaultButtonText",
        defaultMessage: "Specs & Templates",
        description: {
            note: "Label for the specs and templates button"
        }
    }
});
export const SpecsAndTemplatesButton = ({ className }: Props) => {
    const { t } = useTranslationSSR();
    const labelContent = t(messages.defaultButtonText.id);
    const templateIcon = <TemplateIcon aria-hidden={true} />;
    const dispatch = useAppDispatch();

    const onClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SPECS_AND_TEMPLATES_BUTTON,
            label: "Specs and Templates"
        });
        dispatch(setShowSpecsAndTemplateModal(true));
        dispatch(setSpecsAndTemplateModalViewed(true));
    };

    return <GenericHeaderButton icon={templateIcon} label={labelContent} className={className} onClick={onClick} />;
};
SpecsAndTemplatesButton.displayName = "SpecsAndTemplatesButton";
