import React from "react";
import { useDragLayer } from "react-dnd";
import { DraggableTypes } from "./Constants";

import "./customDragLayer.scss";

export const CustomDragLayer = () => {
    const { itemType, isDragging, item, currentOffset } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging()
    }));

    function renderItem() {
        if (!item) {
            return null;
        }
        switch (itemType) {
            case DraggableTypes.UploadedImage: {
                const { asset, pageNumber } = item;
                const urlOptions = { pageNum: pageNumber };
                return (
                    <img
                        style={{ maxWidth: 75, maxHeight: 75, transform: `translate(-50%,-50%)` }}
                        alt={asset.data?.info?.storage?.fileName ?? ""}
                        src={asset.thumbnail.getUrl(urlOptions)}
                    />
                );
            }
            default:
                return null;
        }
    }

    if (!isDragging || !currentOffset) {
        return null;
    }

    const { x, y } = currentOffset;
    return (
        <div className={"custom-drag-layer"}>
            <div className="dragPreviewContainer" style={{ transform: `translate(${x}px, ${y}px)` }}>
                {renderItem()}
            </div>
        </div>
    );
};

CustomDragLayer.displayName = "CustomDragLayer";
