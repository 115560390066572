import React, { useEffect, useState } from "react";
import { useOnKeyDown } from "@shared/features/Accessibility";
import type { Upload } from "../../../@types/upload";
import { UploadImage } from "../UploadImage";
import { useUploadsProgress } from "../UploadsProgressProvider";
import UploadStatus from "../UploadStatus";
import { useUploadStatusStyle } from "../useUploadStatusStyle";
import { UploadThumbnailIcon } from "./Icons";
import { ImageDeleteButton } from "./ImageDeleteButton";

interface Props {
    onClick: () => void;

    expanded: boolean;

    upload: Upload;

    onImageLoaded?: () => void;
}

function UploadMultipageThumbnail(props: Props) {
    const { onClick, expanded, upload } = props;
    const [uploadStatus, setUploadStatus] = useState(upload.get("status"));
    const { processingProgress } = useUploadsProgress();
    const { style, statusText } = useUploadStatusStyle(
        upload.id !== undefined && processingProgress[upload.id] === 100,
        uploadStatus
    );
    const processingImgClass = `easel-image-thumbnail mobile-image-container dcl-thumb__pdf ${style}`;
    const page = upload.get("pages").at(0);

    useEffect(() => {
        const onChangeStatus = (uploadModel: Upload) => {
            setUploadStatus(uploadModel.get("status"));
        };
        upload.on("change:status", onChangeStatus);
        return () => {
            upload.off("change:status", onChangeStatus);
        };
    }, [upload]);

    return (
        <div
            className={processingImgClass}
            onClick={onClick}
            onKeyDown={useOnKeyDown(onClick)}
            role="button"
            tabIndex={0}
        >
            <div className={`dcl-pdf-thumb__image ${expanded ? "dcl-pdf-thumb__image--selected" : ""}`}>
                <UploadStatus upload={upload} statusText={statusText}>
                    <span className="dcl-pdf-thumb__uploading-background">
                        <UploadThumbnailIcon className="dcl-pdf-thumb__hover" />
                    </span>
                </UploadStatus>

                <UploadThumbnailIcon className="dcl-thumb__multipage-icon" />
                <UploadImage
                    upload={upload}
                    page={page}
                    className="dcl-pdf-thumb__icon-image dcl-thumb__image"
                    isDraggable={false}
                    onImageLoaded={props.onImageLoaded}
                />
                <div className="dcl-pdf-thumb__actions-icon">
                    <span className="dcl-pdf-thumb__delete-wrapper">
                        <ImageDeleteButton uploadId={upload.id} />
                    </span>
                </div>
            </div>
        </div>
    );
}

UploadMultipageThumbnail.displayName = "UploadMultipageThumbnail";

export { UploadMultipageThumbnail };
