/* eslint-disable max-len */
import React from "react";

export function ChangeSizeIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 18 18" fill="none" {...props}>
            <path
                d="M3.66309 8.3252C3.84538 8.3252 3.99805 8.26367 4.12109 8.14062C4.24414 8.01758 4.30566 7.86263 4.30566 7.67578V7.16992L4.16895 5.03711L5.77539 6.71875L7.68945 8.65332C7.8125 8.78092 7.96745 8.84473 8.1543 8.84473C8.35482 8.84473 8.51888 8.7832 8.64648 8.66016C8.77865 8.53711 8.84473 8.37533 8.84473 8.1748C8.84473 8.0791 8.8265 7.99251 8.79004 7.91504C8.75814 7.83301 8.71029 7.76009 8.64648 7.69629L6.71875 5.76172L5.03711 4.16895L7.18359 4.29883H7.69629C7.8877 4.29883 8.04492 4.23958 8.16797 4.12109C8.29557 4.0026 8.35938 3.84766 8.35938 3.65625C8.35938 3.46484 8.29557 3.30762 8.16797 3.18457C8.04492 3.06152 7.8877 3 7.69629 3H4.11426C3.75879 3 3.48307 3.09798 3.28711 3.29395C3.0957 3.48991 3 3.76107 3 4.10742V7.67578C3 7.85807 3.06152 8.01302 3.18457 8.14062C3.31217 8.26367 3.47168 8.3252 3.66309 8.3252ZM10.6904 15.4004H14.2793C14.6348 15.4004 14.9082 15.3024 15.0996 15.1064C15.2956 14.915 15.3936 14.6439 15.3936 14.293V10.7314C15.3936 10.5446 15.3298 10.3896 15.2021 10.2666C15.0791 10.139 14.9219 10.0752 14.7305 10.0752C14.5436 10.0752 14.3887 10.1367 14.2656 10.2598C14.1426 10.3828 14.0811 10.54 14.0811 10.7314V11.2373L14.2246 13.3701L12.6182 11.6885L10.6973 9.75391C10.5788 9.62174 10.4261 9.55566 10.2393 9.55566C10.0387 9.55566 9.8724 9.61947 9.74023 9.74707C9.61263 9.87012 9.54883 10.0319 9.54883 10.2324C9.54883 10.3236 9.56478 10.4102 9.59668 10.4922C9.62858 10.5742 9.67643 10.6471 9.74023 10.7109L11.6748 12.6387L13.3564 14.2383L11.21 14.1084H10.6904C10.5036 14.1084 10.3464 14.1676 10.2188 14.2861C10.0957 14.4046 10.0342 14.5573 10.0342 14.7441C10.0342 14.9355 10.0957 15.0928 10.2188 15.2158C10.3464 15.3389 10.5036 15.4004 10.6904 15.4004Z"
                fill="currentColor"
            />
        </svg>
    );
}
ChangeSizeIcon.displayName = "ChangeSizeIcon";
