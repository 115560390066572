import { updateSelectedItems } from "@designer-suite";
import { getItemBoundingBox, updateItemBoundingBox, wordArtFilter } from "@utilities";
import CurveReferenceData from "../../InitialCurveConfig";
import { Designer, ItemSelection } from "../../@types/designer";

// Valid curve types: sharpConvex, convex, straight, concave, sharpConcave

export function updateCurve(designer: Designer, selection: ItemSelection, curveType: string) {
    updateSelectedItems(designer, selection, (item: MutableReferenceItem) => {
        const curveData = CurveReferenceData[curveType];
        // Assumes that the wordart is not internally rotated (vs rotated via designers rotation)
        const boundingBox = getItemBoundingBox(item);
        // Note this is using the same sizing alogorithm as Studio4
        //  This results in the box being larger if you go straight to a curve and back to straight
        boundingBox.height = (boundingBox.width / curveData.curveWidth) * curveData.curveHeight;
        updateItemBoundingBox(item, boundingBox);
        item.setData({
            ...item.data,
            curve: {
                height: curveData.curveMaxTextHeight,
                angle: curveData.curveAngle,
                radius: curveData.curveRadius
            }
        });
    });
}

export function getSelectedCurve(selection: ItemSelection): CurveType {
    const angle = (selection[0] as WordArtItem).data?.curve?.angle;
    if (angle === undefined) {
        return "";
    }
    const selectedCurveReference = Object.entries(CurveReferenceData).find(
        curveReference => curveReference[1].curveAngle === angle
    );
    if (!selectedCurveReference) {
        return "";
    }
    return selectedCurveReference[0] as CurveType;
}

export function getIsWordArtCompatible(designer: Designer | undefined, selection: ItemSelection | undefined) {
    if (!designer || !selection) return false;

    const { wordArtItems, otherItems } = wordArtFilter(designer, selection);
    if (otherItems.length || !wordArtItems.length) return false;

    return wordArtItems.length > 0;
}
