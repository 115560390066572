import { useCanvasValidations } from "@shared/features/Validations";
import { useCallback } from "react";
import { useHasAvailableUpsell } from "@shared/features/Product";
import { useDesignDialog, DialogCanvasData, DesignPanelType } from "../DesignDialogProvider";

export function useOnContinueClick() {
    const { setDesignPanelType, setDialogCanvas } = useDesignDialog();
    const { validateCanvasErrors } = useCanvasValidations();
    const hasAvailableUpsell = useHasAvailableUpsell();

    return useCallback(
        (canvasIndex: number, canvasData: DialogCanvasData, onSelection?: () => void) => {
            if ((canvasIndex !== 0 || hasAvailableUpsell(canvasData.name)) && validateCanvasErrors()) {
                setDialogCanvas(canvasData);
                setDesignPanelType(DesignPanelType.Change);
                onSelection && onSelection();
            }
        },
        [setDesignPanelType, setDialogCanvas, validateCanvasErrors, hasAvailableUpsell]
    );
}
