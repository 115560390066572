import React, { useMemo } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    Button,
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogButtons,
    LegacyModalDialogCloseButton,
    LegacyModalDialogContent,
    LegacyModalDialogHeader,
    LegacyModalDialogTitle
} from "@vp/swan";
import { useTrackingClient } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS, Events, getStudioUniqueSessionId } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { useActiveCanvas } from "@designer-suite";
import { getDesignDocumentFromDesigner } from "@utilities";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useIdeaTabEnabled } from "@five/hooks/useIdeaTabEnabled";
import { useAITemplates } from "./AITemplatesProvider";
import { getTransformedDocumentWithAITemplate } from "./AITemplatesPanelHelpers";
import { useAITemplateTracking } from "./useAITemplateTracking";

export function AITemplatesConfirmationModal() {
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const quantity = useAppSelector(state => state.quantity);
    const { t } = useTranslationSSR();
    const {
        addNewRecentlyUsedTemplate,
        changeDocument,
        currentlySelectedTemplate,
        setCurrentlySelectedTemplate,
        documentForSelectedContent,
        setDocumentForSelectedContent,
        isConfirmationModalOpen,
        setIsConfirmationModalOpen,
        reportClickedTemplate,
        aiTemplatesPanelMessages
    } = useAITemplates();
    const trackingClient = useTrackingClient();
    const { tracking } = useAITemplateTracking();
    const activeCanvas = useActiveCanvas();
    const canvasName = activeCanvas?.name;
    const { auth } = useIdentityContext();
    const showIdeaTab = useIdeaTabEnabled();

    const placeholderConfig = useMemo(
        () =>
            showIdeaTab
                ? {
                      primaryText: {
                          label: t(aiTemplatesPanelMessages.primaryTextFormPlaceholder.id),
                          purpose: "primarytext"
                      },
                      secondaryText: {
                          label: t(aiTemplatesPanelMessages.secondaryTextFormPlaceholder.id),
                          purpose: "secondarytext"
                      }
                  }
                : undefined,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showIdeaTab, t]
    );

    const cancelAddContent = () => {
        setDocumentForSelectedContent(undefined);
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.REPLACE_DESIGN_MODAL_CLOSED,
            label: tracking.label
        });

        setIsConfirmationModalOpen(false);
    };

    const addContentToCanvas = async () => {
        setIsConfirmationModalOpen(false);

        const existingDocument = await getDesignDocumentFromDesigner(true);
        const sessionId = getStudioUniqueSessionId();
        const transformedDocument = await getTransformedDocumentWithAITemplate(
            existingDocument,
            documentForSelectedContent,
            productKey,
            productVersion,
            studioSelectedProductOptions,
            sessionId,
            locale,
            auth,
            canvasName,
            placeholderConfig
        );
        await changeDocument(
            transformedDocument,
            productKey,
            locale,
            productVersion,
            quantity,
            studioSelectedProductOptions
        );
        if (currentlySelectedTemplate) {
            addNewRecentlyUsedTemplate(currentlySelectedTemplate);
            reportClickedTemplate(currentlySelectedTemplate);
        }

        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: tracking.selectedTemplate,
            label: tracking.label
        });
        setCurrentlySelectedTemplate(undefined);
    };

    return (
        <LegacyModalDialog isOpen={isConfirmationModalOpen} onRequestDismiss={cancelAddContent}>
            <LegacyModalDialogContent aria-labelledby="ai-templates-panel-title" padding={6} fullBleed>
                <LegacyModalDialogCloseButton
                    visuallyHiddenLabel={t(aiTemplatesPanelMessages.replaceAITemplateModalClose.id)}
                    data-testid="aiTemplatesConfirmationModalClose"
                />
                <LegacyModalDialogHeader>
                    <LegacyModalDialogTitle
                        id="ai-templates-panel-title"
                        data-testid="aiTemplatesConfirmationModalTitle"
                    >
                        {t(aiTemplatesPanelMessages.replaceAITemplateModalTitle.id)}
                    </LegacyModalDialogTitle>
                </LegacyModalDialogHeader>
                <LegacyModalDialogBody>
                    {t(aiTemplatesPanelMessages.replaceAITemplateModalDescription.id)}
                </LegacyModalDialogBody>
                <LegacyModalDialogButtons>
                    <Button
                        skin="secondary"
                        onClick={cancelAddContent}
                        data-testid="aiTemplatesConfirmationModalCancel"
                    >
                        {t(aiTemplatesPanelMessages.replaceAITemplateModalCancel.id)}
                    </Button>
                    <Button
                        skin="primary"
                        onClick={addContentToCanvas}
                        data-testid="aiTemplatesConfirmationModalConfirm"
                    >
                        {t(aiTemplatesPanelMessages.replaceAITemplateModalConfirm.id)}
                    </Button>
                </LegacyModalDialogButtons>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

AITemplatesConfirmationModal.displayName = "AITemplatesConfirmationModal";
