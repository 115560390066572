import React, { useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { LeftSidebar, SidebarButtonContainer, SidebarPanelContainer } from "@shared/features/StudioChrome";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { Divider } from "@vp/swan";
import { useAppSelector } from "@shared/redux";
import { useDesignDialog } from "@shared/features/DesignPanel";
import { FloatingSidebarPanel } from "../FloatingSidebarPanel";
import { SidebarPanelButtonItem } from "../SidebarPanelButtonItem/SidebarPanelButtonItem";
import * as styles from "./floatingSidebar.module.scss";
import { useSidebarNavConfiguration } from "./useSidebarNavConfiguration";
import { useSidebarKeyboardNavigation } from "./utils/useSidebarKeyboardNavigation";
import { useOpenItemPanel } from "./utils/useOpenItemPanel";
import { IMAGE_SEARCH_FILTER_PORTAL_ID } from "../../Panels/Images/ImageSearchFiltersPanel";
import { TEMPLATE_SEARCH_FILTER_PORTAL_ID } from "../../Panels/TemplatePanel/TemplateSearchFiltersPanel";

const messages = defineMessages({
    sidebar: {
        id: "studio.components.sidebar.label",
        defaultMessage: "Sidebar",
        description: {
            note: "Label sidebar panel"
        }
    }
});

const useSetInitialActiveDialog = () => {
    const { defaultPanelDialogType } = useSidebarNavConfiguration();
    const { setCurrentActiveDialog } = useActiveDialog();
    useEffect(() => {
        if (defaultPanelDialogType) {
            setCurrentActiveDialog(defaultPanelDialogType);
        }
    }, [defaultPanelDialogType, setCurrentActiveDialog]);
};

export const FloatingSidebar = () => {
    const { t } = useTranslationSSR();
    const { currentActiveDialog } = useActiveDialog();

    const { isCanvasBlank } = useDesignDialog();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const disableButtons = isCanvasBlank || !easelLoaded;

    useOpenItemPanel();
    const { sidebarButtonsRef } = useSidebarKeyboardNavigation();
    const { sidebarPanelButtonConfiguration } = useSidebarNavConfiguration();
    useSetInitialActiveDialog();

    return (
        <LeftSidebar visible={easelLoaded} aria-label={t(messages.sidebar.id)} className={styles.floatingSidebarZindex}>
            <SidebarButtonContainer visible={easelLoaded} ref={sidebarButtonsRef}>
                {sidebarPanelButtonConfiguration.map((buttonSection, index) => (
                    <React.Fragment key={`${buttonSection.map(button => button.contentType).join()}`}>
                        {index > 0 && <Divider className={styles.floatingSidebarDivider} marginY={4} aria-hidden />}
                        {buttonSection.map(({ rendered, disabled, ...buttonProps }) => {
                            return (
                                rendered && (
                                    <SidebarPanelButtonItem
                                        key={buttonProps.contentType}
                                        disabled={disableButtons || disabled}
                                        {...buttonProps}
                                    />
                                )
                            );
                        })}
                    </React.Fragment>
                ))}
            </SidebarButtonContainer>
            {currentActiveDialog !== DialogType.None &&
                !isCanvasBlank &&
                currentActiveDialog !== DialogType.PremiumFinishes && (
                    <SidebarPanelContainer
                        data-panel
                        visible={easelLoaded}
                        role="tabpanel"
                        aria-labelledby={`tab-${currentActiveDialog}`}
                        id={`sidebar-panel-${currentActiveDialog}`}
                    >
                        <FloatingSidebarPanel className={`panel-${currentActiveDialog}`} />
                        {/* Portal for the floating image filter panel */}
                        <div id={IMAGE_SEARCH_FILTER_PORTAL_ID}></div>
                        {/* Portal for the floating templatee filter panel */}
                        <div id={TEMPLATE_SEARCH_FILTER_PORTAL_ID}></div>
                    </SidebarPanelContainer>
                )}
        </LeftSidebar>
    );
};

FloatingSidebar.displayName = "FloatingSidebar";
