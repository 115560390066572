import React from "react";
import { FontSizeSelectorStudio5, useActiveCanvas, useDesigner, useSelection } from "@designer-suite";
import { getTrackingDataForSelection, updateSelectedItems, wordArtFilter } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useWordArtFontSize } from "./useWordArtFontSize";
import { getSelectedCurve, updateCurve } from "../CurveTool/curveToolUtilities";
import type { Designer, ItemSelection } from "../../@types/designer";

interface Props {
    className?: string;
    expandable?: boolean;
    //* * Is this the docked mobile tool */
    mobileDock?: boolean;
}

type CalcProps = {
    originalBoundingBox: Rectangle;
    fontSize: number;
    canvas: Canvas;
};

const calculateNewBoundingBox = function calcNewBox({ originalBoundingBox, fontSize, canvas }: CalcProps): Rectangle {
    const newWidth = (canvas.mmDimensions.width * fontSize) / 100;
    const newHeight = ((canvas.mmDimensions.height / 2) * fontSize) / 100;

    return {
        height: newHeight,
        width: newWidth,
        left: originalBoundingBox.left - (newWidth - originalBoundingBox.width) / 2,
        top: originalBoundingBox.top
    };
};

function updateFontSizeForWordArt(designer: Designer, selection: ItemSelection, sizeDelta: number, canvas: Canvas) {
    if (!designer) {
        return;
    }
    const fontSize = sizeDelta;

    updateSelectedItems(designer, selection, (item: CanvasItem): void => {
        const originalBoundingBox = {
            top: item.mmPosition.top,
            left: item.mmPosition.left,
            width: item.mmDimensions.width,
            height: item.mmDimensions.height
        };
        const newBoundingBox = calculateNewBoundingBox({ originalBoundingBox, fontSize, canvas });

        item._itemViewModel.model.set(newBoundingBox);
        item._itemViewModel.set("temporaryPositionDelta", {
            top: originalBoundingBox.top - newBoundingBox.top,
            left: originalBoundingBox.left - newBoundingBox.left
        });
    });

    const selectedCurve = getSelectedCurve(selection);
    updateCurve(designer, selection, selectedCurve);
}

export default function WordArtFontSizeButtonContainer({ className, expandable, mobileDock }: Props) {
    const designer = useDesigner();
    const { wordArtItems, otherItems } = wordArtFilter(designer, useSelection());
    const { current, onChange } = useWordArtFontSize();
    const canvas = useActiveCanvas();

    if (!designer || !wordArtItems.length || otherItems.length > 0) {
        return null;
    }

    const handleFontUpdate = (value: number) => {
        onChange(value);

        if (designer && canvas) {
            updateFontSizeForWordArt(designer, wordArtItems, value, canvas);
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_WORD_ART_FONT_SIZE, {
                ...getTrackingDataForSelection(wordArtItems)
            });
        }
    };

    return (
        <FontSizeSelectorStudio5
            onChange={handleFontUpdate}
            initialFontSize={current}
            min={10}
            max={150}
            className={className}
            expandable={expandable}
            mobileDock={mobileDock}
        />
    );
}

WordArtFontSizeButtonContainer.displayName = "WordArtFontSizeButtonContainer";
