/* eslint-disable react/jsx-no-duplicate-props */
import React, { forwardRef, HTMLProps, Ref } from "react";
import Measure from "react-measure";
import classNames from "classnames";
import * as styles from "./Sheet.module.scss";

interface Props extends HTMLProps<HTMLDivElement> {
    /** Whether the sheet should be displayed or not */
    hideSheet?: boolean;

    reportSizeChange?: (rect: DOMRect) => void;

    /** Whether it should has rounded borders */
    removeRoundedBorder?: boolean;
    /**
     * Limit the height of the sheet
     * @default true
     * */
    limitHeight?: boolean;
    /**
     * Take up the full screen
     * @default false
     * */
    fullHeight?: boolean;
    /**
     * Remove overflow from sheet
     * Used to prevent double scrollbar when inner content is scrollable
     * @default false
     * */
    noOverflow?: boolean;
}

const Sheet = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
    const {
        reportSizeChange,
        children,
        hideSheet,
        className,
        removeRoundedBorder,
        limitHeight,
        fullHeight,
        noOverflow,
        ...restOfProps
    } = props;

    return (
        <Measure
            bounds
            onResize={contentRect => {
                if (reportSizeChange) {
                    reportSizeChange(contentRect.entry);
                }
            }}
        >
            {({ measureRef }) => (
                <div
                    ref={measureRef}
                    className={classNames(
                        "sheet-component",
                        styles.sheetComponent,
                        {
                            [styles.sheetComponentFullHeight]: fullHeight,
                            "sheet-component-full-height": fullHeight,
                            [styles.sheetComponentLimitHeight]: limitHeight && !fullHeight,
                            "sheet-component-limit-height": limitHeight && !fullHeight,
                            [styles.sheetComponentHidden]: hideSheet,
                            "sheet-component-hidden": hideSheet,
                            [styles.sheetComponentNoBorderRadius]: removeRoundedBorder,
                            "sheet-component-no-border-radius": removeRoundedBorder,
                            [styles.noOverflow]: noOverflow,
                            "no-overflow": noOverflow
                        },
                        className
                    )}
                    data-dcl-prevent-canvas-items-deselection
                    data-testid="sheet"
                    {...restOfProps}
                >
                    {children}
                </div>
            )}
        </Measure>
    );
});

export { Sheet };
Sheet.displayName = "Sheet";
