import React, {
    useRef,
    useEffect,
    useCallback,
    useImperativeHandle,
    forwardRef,
    Ref,
    useState,
    CSSProperties,
    ReactNode
} from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import throttle from "lodash/throttle";
import { useDesigner, focusMobileDock, flatContent, findNodeByContent } from "@designer-suite";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { useMobileText } from "../MobileTextEditor/MobileTextProvider";
import { mapFontSize } from "./TextUtils";
import "./richTextField.scss";

const messages = defineMessages({
    defaultTitle: {
        id: "easel.components.richtextfield.defaultTitle",
        defaultMessage: "Text Field"
    },
    titleWithIndex: {
        id: "easel.components.richtextfield.titleWithIndex",
        defaultMessage: "Text Field [[index]]"
    }
});

export interface RichTextRef {
    insertText: (keyCode: string) => void;
}

const BACKSPACE = "Back-space";
const ENTER = "Enter";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /** What item this richTextField is hooked up to */
    item: TextItem;
    /** For overriding styles */
    className?: string;
    /** React component to render at right of the textfield */
    icon?: ReactNode;
    /** Whether or not to select the item on focus */
    selectItemOnFocus?: boolean;
    /** For tracking usage of left panel editing */
    leftPanel?: boolean;
    fieldNumber?: string;
}

/**
 * A connected RichTextField. This hooks up the presentational component to the RichTextContext
 */
// eslint-disable-next-line react/display-name
export const RichTextField = forwardRef(
    (
        { item, className, icon, selectItemOnFocus = false, leftPanel, fieldNumber = "" }: Props,
        ref: Ref<RichTextRef>
    ) => {
        const { t } = useTranslationSSR();
        const [editorNode, setEditorNode] = useState<HTMLDivElement | undefined>();
        const [elementHeight, setElementHeight] = useState<number | null>();
        const designer = useDesigner();
        const ignoreNextUpdateRef = useRef<boolean>(false);
        const itemId = (item || {}).id;
        const rtfRef = useRef<CimDocRTF>();
        const { setIsTextInputFocused } = useMobileText();
        const { isSmall } = useStudioLayout();
        const { setCurrentActiveDialog } = useActiveDialog();
        const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();

        const onBlur = useCallback(() => {
            item._itemViewModel.unset("over");
        }, [item._itemViewModel]);

        useEffect(() => {
            if (editorNode && designer) {
                const { richTextManager, selectionManager } = designer;
                const initialTextFields = item ? item.content : undefined;

                const rtf = richTextManager.createRichTextField(editorNode, {
                    applyToWholeTextFieldWithNoSelection: true
                });

                if (item) {
                    rtf.updatePlaceholder(item.templateProperties.placeholderText);
                }

                if (initialTextFields && Array.isArray(initialTextFields) && initialTextFields.length > 0) {
                    rtf.setTextAreaContent(initialTextFields);
                    if (editorNode) {
                        setElementHeight(editorNode.scrollHeight);
                    }
                }

                rtfRef.current = rtf;

                // Due to a bug in Quill, we need to manage the height of the text field explicitly with respect to the placeholder
                const checkHeight = (content: CimDocTextField[]) => {
                    if (content.length === 1 && content[0].content.length === 0 && editorNode) {
                        // Text is empty, set the height based on the scroll height which includes the placeholder
                        setElementHeight(editorNode.scrollHeight);
                    } else {
                        // Text is not empty, height can be calculated naturally
                        setElementHeight(null);
                    }
                };

                const contentChange = throttle(({ content }: { content: CimDocTextField[] }) => {
                    // flag that the model's text fields are changing because this component changed them
                    ignoreNextUpdateRef.current = true;
                    const newContent = mapFontSize(content);

                    designer.api.design.updateItem(item.id, (mutableItem: MutableTextItem) => {
                        if (content && content.length > 0 && mutableItem) {
                            mutableItem._itemViewModel.model.set("content", newContent);
                        }
                    });
                    checkHeight(newContent);
                }, 200);

                const focus = () => {
                    richTextManager.setActiveRichTextEditor(rtf);

                    if (
                        selectItemOnFocus &&
                        (selectionManager.length !== 1 || selectionManager.first() !== item._itemViewModel)
                    ) {
                        !isSmall && selectionManager.select([item._itemViewModel]);
                        rtf.focus(); // Selecting the current item causes this text field to be blurred, refocus it here
                    }
                };

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const changeTextFields = (_newModel: any, newTextFields: CimDocTextField[]) => {
                    if (ignoreNextUpdateRef.current) {
                        // don't update the rtf if this component is the reason the text changed
                        ignoreNextUpdateRef.current = false;
                        return;
                    }

                    // don't update this if we are not providing any textFields
                    if (newTextFields && Array.isArray(newTextFields) && newTextFields.length > 0) {
                        rtf.setTextAreaContent(newTextFields);
                        checkHeight(newTextFields);
                        // this setTimeout is a workaround for the contextual text editor when adding a new text field
                        // we want new text fields to default to the commonly used text color (as defined in useFontColorDefaults)
                        // but there is some squirelly behavior where the left panel works fine, and the placeholder text is the correct color
                        // but if the user enters text immediately into the contextual text editor it reverts to being black
                        setTimeout(() => {
                            rtf.setTextAreaContent(newTextFields);
                        }, 0);
                    }
                };

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const updatePlaceholder = (_: any, placeholderText: string) => {
                    rtf.updatePlaceholder(placeholderText);
                };

                // update the font size attribute after resizing so the correct value appears in the font size input
                const updateFontSize = (viewModels: ItemViewModel[]) => {
                    const newAttributes = viewModels[0].model.attributes;
                    if (
                        newAttributes.id === item.id &&
                        newAttributes?.content &&
                        newAttributes.content.length === 1 &&
                        newAttributes.content[0].fontSize !== newAttributes.fontSize
                    ) {
                        rtf.setAttribute("fontSize", newAttributes.content[0].fontSize);
                    }
                };

                // Add event listeners to text field, and cleanup
                rtf.addEventListener("content-change", contentChange);
                rtf.addEventListener("focus", focus);
                item._itemViewModel.model.on("change:content", changeTextFields);
                item._itemViewModel.model.on("change:placeholder", updatePlaceholder);
                designer.eventBus.on("transform:resizestop", updateFontSize);
                return () => {
                    rtf.removeEventListener("content-change", contentChange);
                    rtf.removeEventListener("focus", focus);
                    richTextManager.deactivateRichTextEditor(rtf);
                    item._itemViewModel.model.off("change:content", changeTextFields);
                    item._itemViewModel.model.off("change:placeholder", updatePlaceholder);
                    designer.eventBus.off("transform:resizestop", updateFontSize);
                };
            }
            return undefined;
            // only re-run the effect if the item has changed, not the item object itself, since the designer API creates a new object each time
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [editorNode, selectItemOnFocus, itemId, designer, leftPanel]);

        useImperativeHandle(
            ref,
            () => ({
                insertText(keyCode: string) {
                    const rtf = rtfRef.current;
                    if (!rtf || !designer) {
                        return;
                    }

                    const { richTextManager } = designer;
                    richTextManager.setActiveRichTextEditor(rtf);

                    if (keyCode === BACKSPACE) {
                        // Special case, since backspace is prevented to stop silly browser navigation
                        rtf.delete();
                    } else if (keyCode !== ENTER) {
                        // note we don't insert the enter key, as it ends up breaking the formatting in cimdoc-rtf
                        // so we ignore enters but still focus and set the selection
                        rtf.insert(keyCode);
                    }

                    const length = rtf.getLength();
                    rtf.focus();
                    rtf.setSelection({ index: length, length: 0 });
                }
            }),
            [designer]
        ); // eslint-disable-line react-hooks/exhaustive-deps

        if (!designer) {
            return null;
        }

        // reset style on font change
        if (rtfRef.current) {
            flatContent(item.content)
                .filter(content => content.fontStyle?.includes("italic") || content.fontStyle?.includes("bold"))
                .forEach(content => {
                    Array.prototype.forEach.call(
                        findNodeByContent(content.content as string, rtfRef.current?.el),
                        (el: HTMLElement) => {
                            const fontOption = designer.clients.font.fontMapping[content.fontFamily?.toLowerCase()];
                            if (
                                (!fontOption?.Variants.Bold && content.fontStyle.includes("bold")) ||
                                (!fontOption?.Variants.Italic && content.fontStyle.includes("italic"))
                            ) {
                                el.classList.add("unsupported-style");
                            } else {
                                el.classList.remove("unsupported-style");
                            }
                        }
                    );
                });
        }

        const style: CSSProperties = {};
        if (elementHeight) {
            style.height = elementHeight + 2; // extra 2px for the width of the border
        }

        const { label } = item.templateProperties;
        const defaultAriaLabel = fieldNumber
            ? t(messages.titleWithIndex.id, { index: fieldNumber })
            : t(messages.defaultTitle.id);
        const title = label && label.length > 0 ? label : defaultAriaLabel;

        const onClickHandle = () => {
            if (designer) {
                const { selectionManager } = designer;
                selectionManager.select([item._itemViewModel]);
                focusMobileDock();
                setIsTextInputFocused(true);
                setCurrentActiveDialog(DialogType.TextInput);
            }
        };
        if (leftPanel && isSmall) {
            return (
                <>
                    <button
                        className="dscl-rich-text-field studio-rich-text-field"
                        aria-label={title}
                        title={title}
                        onClick={onClickHandle}
                    >
                        <div
                            className={classNames(className, "swan-textarea dscl-rich-text-field__editor", {
                                "swan-textarea dscl-rich-text-field__editor backside-canvas":
                                    hasPremiumFinishesCurrentCanvas
                            })}
                            // @ts-ignore
                            ref={setEditorNode}
                            title={title}
                            aria-label={title}
                        />
                    </button>
                    {icon}
                </>
            );
        }

        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                    className="dscl-rich-text-field studio-rich-text-field"
                    onBlur={onBlur}
                    onClick={() => {
                        rtfRef.current?.focus();
                    }}
                    data-dcl-prevent-canvas-items-deselection
                >
                    <div
                        className={classNames(className, "dscl-rich-text-field__editor", {
                            "dscl-rich-text-field__editor backside-canvas": hasPremiumFinishesCurrentCanvas,
                            "swan-textarea": isSmall || leftPanel
                        })}
                        data-dcl-prevent-canvas-items-deselection
                        // @ts-ignore
                        ref={setEditorNode}
                        title={title}
                        aria-label={title}
                        aria-multiline="true"
                        role="textbox"
                        onFocus={() => {
                            setIsTextInputFocused(true);
                        }}
                        onBlur={() => {
                            rtfRef.current?.blur();
                            setTimeout(() => setIsTextInputFocused(false), 0);
                        }}
                    />
                </div>

                {icon}
            </>
        );
    }
);
