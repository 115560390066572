import { defineMessages } from "@vp/i18n-helper";

const messages = defineMessages({
    applyToYourText: {
        id: "studio.components.modals.premiumfinish.applyToYourText",
        defaultMessage: "Apply to your text",
        description: {
            note: "Appears premium finish text selection heading"
        }
    },
    applyToYourImage: {
        id: "studio.components.modals.premiumfinish.applyToYourImage",
        defaultMessage: "Apply to your image",
        description: {
            note: "Appears premium finish image selection heading"
        }
    },
    previewInfo: {
        id: "studio.components.modals.premiumfinish.previewInfo",
        defaultMessage: "Drag to see the brilliant finish",
        description: {
            note: "Preview info text"
        }
    },
    ariaCloseLabel: {
        id: "studio.components.modals.premiumfinish.modal.ariaCloseLabel",
        defaultMessage: "Close premium finish modal"
    },
    ariaModalLabel: {
        id: "studio.components.modals.premiumfinish.modal.ariaModalLabel",
        defaultMessage: "Provide instructions for designers to apply a premium finish"
    },
    errorMessage: {
        id: "studio.components.modals.premiumfinish.modal.errormessage",
        defaultMessage: "[[finishType]] needs to be applied to at least one text field or image in your design.",
        description: {
            note: "this happens when the user tries to  unselect all the applied premium finishes"
        }
    },
    premiumFinishButtonTitle: {
        id: "studio.components.modals.premiumfinish.modal.premiumFinishButton.title",
        defaultMessage: "Finishes",
        description: {
            note: "Finishes button"
        }
    }
});

export default messages;
