/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function DownloadIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <path d="M2.24731 14.8203H14.2473" stroke="currentColor" strokeLinecap="round" />
            <path d="M8.30688 10.5361L8.30819 2.73122" stroke="currentColor" strokeLinecap="round" />
            <path
                d="M5.58057 8.50586L8.30713 11.4882L11.0337 8.50586"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
DownloadIcon.displayName = "DownloadIcon";
