import React, { forwardRef, Ref } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import Skeleton from "react-loading-skeleton";
import { Button, ButtonProps, Responsive, StyleSpacing0to13WithAuto } from "@vp/swan";
import { MobileExperience, DesktopExperience } from "@shared/features/ResponsiveDesign";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";

export const nextStepButtonMessages = defineMessages({
    nextButton: {
        id: "studio.components.next.nextButton",
        defaultMessage: "Next"
    }
});

export interface NextStepButtonProps extends ButtonProps {
    margin?: Responsive<StyleSpacing0to13WithAuto>;
    customLabel?: string;
}

export const NextStepButton = forwardRef((props: NextStepButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { className, margin, width, customLabel, size, ...rest } = props;
    const { t } = useTranslationSSR();

    return (
        <StudioIsInteractiveSwitch>
            <>
                <DesktopExperience>
                    <Button
                        data-button-id="next-step-button__desktop"
                        skin="primary"
                        width={width || "full-width"}
                        size={size || "standard"}
                        data-testid="nextButton"
                        data-translationid={nextStepButtonMessages.nextButton.id}
                        margin={margin}
                        ref={ref}
                        {...rest}
                    >
                        {customLabel || t(nextStepButtonMessages.nextButton.id)}
                    </Button>
                </DesktopExperience>
                <MobileExperience>
                    <Button
                        data-button-id="next-step-button__mobile"
                        width={width}
                        skin="primary"
                        size="mini"
                        data-testid="nextButtonMobile"
                        margin={margin}
                        ref={ref}
                        {...rest}
                    >
                        {customLabel || t(nextStepButtonMessages.nextButton.id)}
                    </Button>
                </MobileExperience>
            </>

            <DesktopExperience>
                <Skeleton width={125} height={60} style={{ borderRadius: "25px" }} />
            </DesktopExperience>
        </StudioIsInteractiveSwitch>
    );
});
NextStepButton.displayName = "NextStepButton";
