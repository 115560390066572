import React, { ReactNode } from "react";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import classNames from "classnames";

import * as styles from "./ViewPanelContainer.module.scss";

interface ViewPanelContainerProps {
    className?: string;
    children: ReactNode | ReactNode[];
    title: string;
}

export function ViewPanelContainer({ className, children, title }: ViewPanelContainerProps) {
    return (
        <PanelContent className={classNames(className, styles.studioGuidesPanelContent)}>
            <PanelTitle>{title}</PanelTitle>
            {children}
        </PanelContent>
    );
}

ViewPanelContainer.displayName = "ViewPanel";
