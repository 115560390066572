import React, { useContext, createContext, ReactNode, useState, useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { POSTCARD_PRDS } from "./postcardPRDs";
import { useCompetitiveBaselining } from "../CompetitiveBaselining";

interface Props {
    children: ReactNode | ReactNode[];
}

type CompatibleProps = {
    productKey: string;
};

type ContextData = {
    isMailingPostCard: (productKey?: string) => boolean;
    isNonMailingPostCard: (productKey: string) => boolean;
    isPostCard: (productKey: string) => boolean;
    isMailingServicesCompatible: (value: CompatibleProps) => void;

    // State for options modal it is available only for non-mailing product to switch.
    isMailingServicesModalOpen: boolean;
    setIsMailingServicesModalOpen: (v: boolean) => void;

    // State for guide modal will give an information of back panel mailing overlay.
    isMailingServicesGuideModalOpen: boolean;
    setIsMailingServicesGuideModalOpen: (value: boolean) => void;

    // State for confirmation modal when switching from non mailing to mailing product.
    isMailingServicesConfirmationModalOpen: boolean;
    setIsMailingServicesConfirmationModalOpen: (value: boolean) => void;

    // State for FAQ modal it is available only for mailing product to switch.
    isMailingServicesFAQModalOpen: boolean;
    setIsMailingServicesFAQModalOpen: (value: boolean) => void;

    // state for showing different text in guide modal
    isShowingGuideModalFromOptionsModal: boolean;
    setIsShowingGuideModalFromOptionsModal: (value: boolean) => void;
};

const Context = createContext<ContextData | undefined>(undefined);

export const useMailingServicesContext = () => {
    const result = useContext(Context);

    if (!result) {
        throw Error("Please call this within an MailingServicesProvider");
    }
    return result;
};

export function useIsMailingPostCard() {
    const productKey = useAppSelector(state => state.productKey);
    const { isMailingPostCard } = useMailingServicesContext();
    return isMailingPostCard(productKey);
}

export function useIsNonMailingPostCard() {
    const productKey = useAppSelector(state => state.productKey);
    const { isNonMailingPostCard } = useMailingServicesContext();
    return isNonMailingPostCard(productKey);
}

// includes Mailing Services postcards,Non Mailing Services postcards which can be switched to a Mailing Services postcards
export function useIsCompatiblePostCard() {
    const productKey = useAppSelector(state => state.productKey);
    const { isPostCard, isMailingServicesCompatible } = useMailingServicesContext();
    return isPostCard(productKey) && isMailingServicesCompatible({ productKey });
}

export function MailingServicesProvider({ children }: Props) {
    const { Provider } = Context;
    const [isMailingServicesModalOpen, setIsMailingServicesModalOpen] = useState(false);
    const [isMailingServicesGuideModalOpen, setIsMailingServicesGuideModalOpen] = useState(false);
    const [isShowingGuideModalFromOptionsModal, setIsShowingGuideModalFromOptionsModal] = useState(false);
    const [isMailingServicesFAQModalOpen, setIsMailingServicesFAQModalOpen] = useState(false);
    const [isMailingServicesConfirmationModalOpen, setIsMailingServicesConfirmationModalOpen] = useState(false);
    const { isCompatibleForMailingService } = useAppSelector(state => state.studioConfiguration);
    const { isTestActive: isCompetitiveBaseliningTestActive } = useCompetitiveBaselining();

    const contextObject = useMemo(() => {
        function isMailingPostCard(inputProductKey: string) {
            return POSTCARD_PRDS.MAILING === inputProductKey;
        }
        function isNonMailingPostCard(productKey: string) {
            return POSTCARD_PRDS.NON_MAILING === productKey;
        }
        function isPostCard(productKey: string) {
            return Object.values(POSTCARD_PRDS).includes(productKey);
        }
        // includes postcards which can be switched to a Mailing Services postcards
        function isMailingServicesCompatible({ productKey }: CompatibleProps) {
            if (
                isNonMailingPostCard(productKey) &&
                (!isCompatibleForMailingService || isCompetitiveBaseliningTestActive)
            ) {
                return false;
            }
            return true;
        }
        return {
            isMailingPostCard,
            isNonMailingPostCard,
            isPostCard,
            isMailingServicesCompatible,
            isMailingServicesModalOpen,
            isShowingGuideModalFromOptionsModal,
            setIsMailingServicesModalOpen,
            isMailingServicesGuideModalOpen,
            setIsMailingServicesGuideModalOpen,
            setIsShowingGuideModalFromOptionsModal,
            isMailingServicesConfirmationModalOpen,
            setIsMailingServicesConfirmationModalOpen,
            isMailingServicesFAQModalOpen,
            setIsMailingServicesFAQModalOpen
        };
    }, [
        isMailingServicesModalOpen,
        isShowingGuideModalFromOptionsModal,
        isMailingServicesGuideModalOpen,
        isMailingServicesConfirmationModalOpen,
        isMailingServicesFAQModalOpen,
        isCompatibleForMailingService,
        isCompetitiveBaseliningTestActive
    ]);

    return <Provider value={contextObject}>{children}</Provider>;
}

MailingServicesProvider.displayName = "MailingServicesProvider";
