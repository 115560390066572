/* eslint-disable max-len */
import React from "react";

export function ChangeTrimIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 18 18" fill="none" {...props}>
            <path
                d="M2.8 6C2.8 4.23269 4.23269 2.8 6 2.8H15.2V12C15.2 13.7673 13.7673 15.2 12 15.2H2.8V6Z"
                stroke="currentColor"
                strokeWidth="1.6"
            />
        </svg>
    );
}
ChangeTrimIcon.displayName = "ChangeTrimIcon";
