import React, { FC, useMemo, useContext, useState } from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { blurInput, openMobileKeyboard } from "@shared/features/WebBrowser";

export const focusMobileDock = () => openMobileKeyboard(".studio-mobile-dock .dscl-rich-text-field");
export const blurMobileDock = () => blurInput(".studio-mobile-dock .dscl-rich-text-field");

type MobileTextContextProps = {
    setIsTextInputFocused: (isFocused: boolean) => void;
    setAnimatingOut: (isAnimating: boolean) => void;
    isTextInputFocused: boolean;
    animatingOut: boolean;
};

export const MobileTextContext = React.createContext<MobileTextContextProps | undefined>(undefined);

export const useMobileText = () => {
    const context = useContext(MobileTextContext);
    if (context === undefined) {
        throw new Error("You must wrap your component in the MobileTextProvider");
    }
    return context;
};

export const MobileTextProvider: FC = props => {
    const [isTextInputFocused, setIsTextInputFocused] = useState<boolean>(false);
    const [animatingOut, setAnimatingOut] = useState(false);
    const { isSmall } = useStudioLayout();

    const value = useMemo(
        () => ({
            isTextInputFocused: isTextInputFocused && isSmall,
            setIsTextInputFocused,
            setAnimatingOut,
            animatingOut
        }),
        [isTextInputFocused, setIsTextInputFocused, isSmall, setAnimatingOut, animatingOut]
    );

    return <MobileTextContext.Provider value={value}>{props.children}</MobileTextContext.Provider>;
};

MobileTextProvider.displayName = "MobileTextProvider";
