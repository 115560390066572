import React, { useCallback, useRef } from "react";
import { Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButtonHandles, ExpandingButton } from "@shared/features/StudioChrome";
import { useAppSelector } from "@shared/redux";
import { FileDropDownContents } from "./FileDropDownContents";

import * as styles from "./FileDropDown.module.scss";

const messages = defineMessages({
    fileButton: {
        id: "studio.features.file.buttonText",
        defaultMessage: "File",
        description: {
            note: "Text to be used for file button"
        }
    }
});

export const FileDropDown = () => {
    const buttonRef = useRef<ExpandingButtonHandles>(null);
    const { t } = useTranslationSSR();

    // Refocuses the button once the modal transitions are complete
    const { isTransitionComplete, isEventActive } = useAppSelector(state => state.trackModalTransitionEvent);
    const buttonCallback = useCallback(
        btnRef => {
            const timer: NodeJS.Timeout = setTimeout(() => {
                if (isEventActive && isTransitionComplete && btnRef) {
                    btnRef.focus();
                }
                clearTimeout(timer);
            }, 500); // time is set twice the modal close animation duration
        },
        [isEventActive, isTransitionComplete]
    );

    return (
        <ExpandingButton
            className={styles.expandingButton}
            ref={buttonRef}
            title={t(messages.fileButton.id)}
            content={<FileDropDownContents onClose={() => buttonRef.current?.close()} />}
            showArrow={true}
            displayType="multirow"
            overridePopperAlignment="start"
            buttonCallback={buttonCallback}
        >
            <Typography fontSize="1">{t(messages.fileButton.id)}</Typography>
        </ExpandingButton>
    );
};

FileDropDown.displayName = "FileDropDown";
