import { usePreviewButton } from "@shared/features/Previews";
import { useAppSelector } from "@shared/redux";
import { Button } from "@vp/swan";
import React from "react";

export const PreviewButton = () => {
    const { label, handlePreviewClick } = usePreviewButton();
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    return (
        <Button
            onClick={() => {
                // TODO-xerox-rollout
                // Only allow clicking preview if easel is already loaded
                // Don't show as disabled because it causes a layout shift
                // Ideally, the preview popup should show a loading spinner and handle the case of easel not loaded
                // Refactor this before rolling out
                easelLoaded && handlePreviewClick();
            }}
            skin={"secondary"}
            size="mini"
            paddingLeft="6"
            paddingRight="6"
            marginLeft="1"
            marginRight="1"
        >
            {label}
        </Button>
    );
};

PreviewButton.displayName = "PreviewButton";
