import React from "react";
import { PanelContent } from "@shared/features/StudioChrome";
import { ViewPanelContent } from "../Panels/ViewPanelContent";

export function ViewSheet() {
    return (
        <PanelContent>
            <ViewPanelContent />
        </PanelContent>
    );
}
ViewSheet.displayName = "ViewSheet";
