import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { ViewButton, ViewPanelContainer } from "@shared/features/ViewPanel";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import { ViewPanelContent } from "./ViewPanelContent";

const messages = defineMessages({
    viewPanel: {
        id: "studio.ui.component.guidesPanel.viewPanel",
        defaultMessage: "View",
        description: {
            note: "Title for the view panel"
        }
    },
    viewButton: {
        id: "studio.components.sidebar.viewButton.label",
        defaultMessage: "View",
        description: {
            note: "Label for the sidebar view button"
        }
    }
});

export function ViewPanel() {
    const { t } = useTranslationSSR();

    return (
        <StudioIsInteractiveSwitch>
            <ViewButton
                title={t(messages.viewButton.id)}
                content={
                    <ViewPanelContainer title={t(messages.viewPanel.id)}>
                        <ViewPanelContent />
                    </ViewPanelContainer>
                }
            />
            <Skeleton width="48px" height="18px" style={{ marginLeft: 16 }} />
        </StudioIsInteractiveSwitch>
    );
}

ViewPanel.displayName = "ViewPanel";
