import React from "react";
import { PremiumFinishSelectionItem } from "./PremiumFinishSelectionItem";
import { PremiumFinishTextSelection } from "./PremiumFinishTextSelection";

type Props = {
    items: Array<CanvasItem>;
};

export function PremiumFinishSelectionElementTexts({ items }: Props) {
    return (
        <>
            {items.map(item => (
                <div className="premium-finish-selection__item" key={item.id}>
                    <PremiumFinishSelectionItem item={item}>
                        <PremiumFinishTextSelection item={item} />
                    </PremiumFinishSelectionItem>
                </div>
            ))}
        </>
    );
}

PremiumFinishSelectionElementTexts.displayName = "PremiumFinishSelectionElementTexts";
