import { Severity } from "@design-stack-vista/smart-validations-ui";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import {
    getOutOfBoundsFix,
    GetOutOfBoundsFixProps,
    getOverlapFix,
    GetOverlapFixProps
} from "@design-stack-ct/smart-fixes";
import type { DSS } from "@vp/types-ddif";
import { type DefaultTFunction } from "@vp/i18n-helper";
import messages from "./validationDictionaryMessages";

export enum ValidationName {
    betweenBoundaries = "betweenBoundaries",
    imageResolution = "imageResolution",
    outsideBounds = "outsideBounds",
    outsideMargins = "outsideMargins",
    overlap = "overlap"
}

// Maps out which smart fix function should be used for each validation type
const validationToSmartFixMap = {
    outsideBounds: async (props: GetOutOfBoundsFixProps) => {
        return getOutOfBoundsFix(props);
    },
    outsideMargins: async (props: GetOutOfBoundsFixProps) => {
        return getOutOfBoundsFix(props);
    },
    overlap: async (props: GetOverlapFixProps) => {
        return getOverlapFix(props);
    }
};

export function getValidationGroupDictionaryInfo(
    validationName: ValidationName,
    severity: Severity,
    t: DefaultTFunction
) {
    if (validationName) {
        const title = t(messages[`${validationName}-${severity}-title`].id);
        const itemMessage = t(messages[`${validationName}-${severity}-message`].id);
        return {
            title,
            itemMessage
        };
    }

    return {
        title: t(messages.unknownValidationTitle.id)
    };
}

export function getValidationDictionaryInfo(
    studioValidation: DSS.StudioValidation,
    itemType: ItemTypes | undefined,
    t: DefaultTFunction
) {
    const { validationName, severity } = studioValidation;

    let translatedItemType = "";

    if (itemType === ItemTypes.TEXT || itemType === ItemTypes.WORDART) {
        translatedItemType = t(messages[`itemTypes-text`].id);
    } else if (
        itemType === ItemTypes.IMAGE ||
        itemType === ItemTypes.SHAPE ||
        itemType === ItemTypes.TABLE ||
        itemType === ItemTypes.QR_CODE
    ) {
        translatedItemType = t(messages[`itemTypes-${itemType.toLowerCase()}`].id);
    } else {
        translatedItemType = t(messages[`itemTypes-item`].id);
    }

    let message = t(messages.unknownValidationMessage.id);
    let smartFix;

    if (ValidationName[validationName]) {
        // Get translated message based on validation type and severity while passing in the item type
        message = t(messages[`${validationName}-${severity}-message`].id, {
            itemType: translatedItemType
        });

        // Get smart fix based on validation type
        smartFix = validationToSmartFixMap[validationName];
    }

    return {
        message,
        smartFix
    };
}
