import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useDesigner, useUploadsContext } from "@designer-suite";
import { useUploadManager } from "@design-stack-vista/upload-components";
import { formatUpload, Upload } from "@shared/features/UploadsAndAssets";
import { useAppSelector } from "@shared/redux";

export const SherbertUploadsManager = observer(() => {
    const {
        assetStore: { assets },
        fetchingStatus
    } = useUploadManager();
    const designer = useDesigner();
    const { setUploadsInitialized, uploadsInitialized } = useUploadsContext();

    const easelLoaded = useAppSelector(state => state.easelLoaded);

    useEffect(() => {
        if (uploadsInitialized || fetchingStatus !== "ready" || !easelLoaded || !designer) {
            return;
        }

        try {
            // Clear local storage of any uploads
            localStorage.removeItem("uploads");
            // eslint-disable-next-line no-empty
        } catch {}

        // If designer loaded with any images in the list, we want to clear the list.
        designer.uploadManager.models = [];

        // Do this to clear the listeners for the uploads manager
        designer.uploadManager.disable();

        // Reset the listeners and as a side effect, reset the uploads to an empty array
        // (as we don't want to pull them from local storage anymore)
        designer.uploadManager.enable();

        // Tell designer that this is the list of uploads.
        // Note because we are doing this here, studio can still upload new images while we wait for this to come back
        // const previousUploads = assets.map((asset: any) => ({ ...asset, previousUpload: true }));
        const results = assets
            .map(asset => {
                const { data } = asset;
                // Only run this logic on known non-pdf uploads
                if (data?.info?.image?.format === "pdf" || data?.info?.image?.format === "unknown") {
                    return null;
                }
                const analysisIsLogo =
                    (data?.properties.analysisIsLogo as string) || (data?.info?.image?.isLogo ? "True" : "False");
                const analysisIsPhoto =
                    (data?.properties.analysisIsPhoto as string) || (data?.info?.image?.isPhoto ? "True" : "False");
                const analysisIsVector =
                    (data?.properties.analysisIsVector as string) || (data?.info?.image?.isVector ? "True" : "False");
                // the linenartness has to be a string
                const analysisLineartness =
                    (data?.properties.analysisLineartness as string) || `${data?.info?.image?.lineartness}`;

                const uploadedFileName = (data?.properties.uploadedFileName as string) || data?.info?.storage?.fileName;
                const originalFileContentType =
                    (data?.properties.thumbFileContentType as string) || data?.info?.storage?.contentType;
                const originalFileSize =
                    (data?.properties.originalFileSize as string) || data?.info?.storage?.fileSizeBytes;

                const convertedData = new Date(0);
                convertedData.setMilliseconds(data?.createdMs || 0);
                const processingTimeEnd = (data?.properties.processingTimeEnd as string) || convertedData.toISOString();
                const uploadObject: Upload = {
                    ...data?.properties,
                    originalFileContentType,
                    processingTimeEnd,
                    originalFileSize,
                    uploadedFileName,
                    analysisIsLogo,
                    analysisIsPhoto,
                    analysisIsVector,
                    analysisLineartness,
                    uploadId: data?.id,
                    requestId: data?.id,
                    url: asset.getUrl(),
                    printUrl: asset.print.getUrl(),
                    previewUrl: asset.webPreview.getUrl(),
                    printPixelHeight:
                        (data?.properties.printPixelHeight as string) || data?.info?.image?.height?.toString() || "0",
                    printPixelWidth:
                        (data?.properties.printPixelWidth as string) || data?.info?.image?.width?.toString() || "0"
                };

                const formattedUpload = {
                    ...formatUpload(uploadObject),
                    asset,
                    previousUpload: true,
                    createdMs: data?.createdMs
                };
                return formattedUpload;
            })
            .filter(asset => asset !== null);

        designer.uploadManager.add(results);

        // Tell studio we're done downloading!
        setUploadsInitialized(true);
    }, [easelLoaded, designer, assets, fetchingStatus, setUploadsInitialized, uploadsInitialized]);
    return <></>;
});

SherbertUploadsManager.displayName = "SherbertUploadsManager";
