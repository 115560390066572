import { DialogType } from "./DialogType";

export const shouldOpenPanel = (
    currentActiveDialog: DialogType,
    selectedContentType: DialogType,
    isMobile: boolean
) => {
    if (!selectedContentType) {
        return false;
    }

    // ordering of If is important as we do not want to select text panel when layers is selected.
    if (currentActiveDialog === DialogType.Layers) {
        return false;
    }
    if (selectedContentType === DialogType.Text && !isMobile) {
        // do not open panel when current selection is already a Text Panel.
        // avoids unnecessary refresh
        if (currentActiveDialog !== DialogType.Text) {
            return true;
        }
    }
    if (selectedContentType === DialogType.GroupsAndTeams) {
        // do not open panel when current selection is already a Teams Panel.
        // avoids unnecessary refresh
        if (currentActiveDialog !== DialogType.GroupsAndTeams) {
            return true;
        }
    }
    return false;
};
