import React, { useState, useCallback } from "react";
import { Accordion, BasicCollapsible } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useDocumentItems } from "@designer-suite";
import {
    useTemplatePremiumFinishImages,
    getValidPremiumFinishImages,
    getValidPremiumFinishTextsWithPlaceHolder
} from "./usePremiumFinishFilter";
import { PremiumFinishSelectionElementImages } from "./PremiumFinishSelectionElementImages";
import { PremiumFinishSelectionElementTexts } from "./PremiumFinishSelectionElementTexts";
import messages from "./messages";

export function PremiumFinishSelectionElement() {
    const items = useDocumentItems(true);
    const { t } = useTranslationSSR();
    const validPremiumFinishTextsWithPlaceHolder = getValidPremiumFinishTextsWithPlaceHolder(items);
    const premiumFinishImages = getValidPremiumFinishImages(items);
    const templatePremiumFinishImages = useTemplatePremiumFinishImages(items);
    const validPremiumFinishImages = [...premiumFinishImages, ...templatePremiumFinishImages];

    const [expandedCollapsibles, setExpandedCollapsibles] = useState({
        [ItemTypes.TEXT]: true,
        [ItemTypes.IMAGE]: true
    });

    const onRequestExpandedChange = useCallback((collapsibleId: string, expanded: boolean) => {
        setExpandedCollapsibles(prevValue => ({
            ...prevValue,
            [collapsibleId]: expanded
        }));
    }, []);

    return (
        <>
            {(validPremiumFinishImages.length > 0 || validPremiumFinishTextsWithPlaceHolder.length > 0) && (
                <Accordion
                    className="premium-finish-selection__element"
                    expandedCollapsibles={expandedCollapsibles}
                    onRequestExpandedChange={onRequestExpandedChange}
                >
                    {validPremiumFinishTextsWithPlaceHolder.length > 0 && (
                        <BasicCollapsible collapsibleId={ItemTypes.TEXT} heading={t(messages.applyToYourText.id)}>
                            <PremiumFinishSelectionElementTexts items={validPremiumFinishTextsWithPlaceHolder} />
                        </BasicCollapsible>
                    )}
                    {validPremiumFinishImages.length > 0 && (
                        <BasicCollapsible
                            collapsibleId={ItemTypes.IMAGE}
                            pt={4}
                            heading={t(messages.applyToYourImage.id)}
                        >
                            <PremiumFinishSelectionElementImages items={validPremiumFinishImages} />
                        </BasicCollapsible>
                    )}
                </Accordion>
            )}
        </>
    );
}

PremiumFinishSelectionElement.displayName = "PremiumFinishSelectionElement";
