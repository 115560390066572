import React from "react";
import { Typography, Button, Icon, FlexBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";

const messages = defineMessages({
    studioPanelImagesIconsPage: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsPage",
        defaultMessage: "Page"
    },
    studioPanelImagesIconsPreviousPage: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsPreviousIconPage",
        defaultMessage: "Previous page"
    },
    studioPanelImagesIconsNextPage: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsPreviousIconPage",
        defaultMessage: "Next page"
    }
});

export type IconsPaginatorProps = {
    selectedPage: number;
    handlePreviousClick: () => void;
    handleNextClick: () => void;
    noMoreResults: boolean;
};

export const IconsPaginator = ({
    selectedPage,
    handlePreviousClick,
    handleNextClick,
    noMoreResults
}: IconsPaginatorProps) => {
    const { t } = useTranslationSSR();

    return (
        <FlexBox mb={3} justifyContent="center" alignItems="center">
            <Button onClick={handlePreviousClick} buttonShape="round" size="mini" disabled={selectedPage <= 1}>
                <Icon iconType="chevronLeft" alt={t(messages.studioPanelImagesIconsPreviousPage.id)} />
            </Button>
            <FlexBox justifyContent="center" alignItems="center" margin={2}>
                <Typography fontSize="1" fontWeight="bold" textColor="dark-grey">
                    {t(messages.studioPanelImagesIconsPage.id)} {selectedPage}
                </Typography>
            </FlexBox>
            <Button
                aria-label={t(messages.studioPanelImagesIconsNextPage.id)}
                onClick={handleNextClick}
                buttonShape="round"
                size="mini"
                disabled={noMoreResults}
            >
                <Icon iconType="chevronRight" />
            </Button>
        </FlexBox>
    );
};

IconsPaginator.displayName = "IconsPaginator";
