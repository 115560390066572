import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
// eslint-disable-next-line import/no-extraneous-dependencies
import type { DSS } from "@vp/types-ddif";
import { useCallback } from "react";
import { removeQueryParam, removeGalleryUrlData } from "@shared/utils/WebBrowser";
import { useAppSelector, useAppDispatch, setDocumentUrl, setIsFullBleed } from "@shared/redux";
import { duplicateProjectName } from "@shared/utils/Work";
import { projectNamingRelatedMessages } from "@shared/features/MyProjects";
import type { Save } from "@shared/features/Save";
import { savingMessages } from "@shared/utils/Save";
import { changeTemplateInStudio, generateDocumentForNewTemplate, LoadNewDesign } from "./changeTemplateClient";

const messages = defineMessages({
    loadingMessage: {
        id: "studio.hooks.useChangeTemplate.loading",
        defaultMessage: "Updating template...",
        description: {
            note: "Loading message while changing templates"
        }
    }
});

interface Props {
    eventType?: string;
    getDocument: () => Promise<DSS.DesignDocument>;
    loadNewDesign: LoadNewDesign;
    save: Save;
}

export function useChangeTemplate({ eventType = "template", getDocument, loadNewDesign, save }: Props) {
    const { t } = useTranslationSSR();
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const locale = useAppSelector(state => state.locale);
    const workName = useAppSelector(state => state.workName);
    const productname = useAppSelector(state => state.productName);
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const dispatch = useAppDispatch();
    const { auth } = useIdentityContext();

    const shouldSaveOnChange = useAppSelector(state => state.shouldSaveOnChange);

    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const { useColorGenericBacksides } = useAppSelector(state => state.studioConfiguration);

    const changeTemplate = useCallback(
        async (
            template: string,
            studioSelectedProductOptions: Record<string, string>,
            designDocument: DSS.DesignDocument
        ) => {
            const handleSave = async () => {
                let currentWorkName;
                if (workName && workName.length > 0) {
                    currentWorkName = workName;
                    await save();
                } else {
                    const defaultWorkName = t(projectNamingRelatedMessages.projectName.id, { productname });
                    currentWorkName = defaultWorkName;
                    await save(defaultWorkName);
                }

                const duplicatedProjectName = duplicateProjectName(
                    currentWorkName,
                    `${t(savingMessages.appendToName.id)}`
                );
                // have to remove any existing document url so that we don't save to the same root work
                dispatch(setDocumentUrl(null));
                await save(duplicatedProjectName);
            };

            // // Remove the fullbleedElected from the url.
            if (isFullBleed) {
                window.history.replaceState(
                    "update-url",
                    "",
                    removeQueryParam(window.location.href, "fullBleedElected")
                );
                dispatch(setIsFullBleed(false));
            }

            // Remove gallery metadata and quickStudioAnalytics when changing template
            removeGalleryUrlData();

            return changeTemplateInStudio({
                productKey,
                productVersion: productVersion ?? undefined,
                studioSelectedProductOptions,
                customerSelectedProductOptions,
                template,
                dispatch,
                loadingMessage: t(messages.loadingMessage.id),
                handleSave,
                shouldSaveOnChange,
                designDocument,
                locale,
                eventType,
                loadNewDesign
            });
        },
        [
            isFullBleed,
            productKey,
            productVersion,
            dispatch,
            t,
            shouldSaveOnChange,
            locale,
            eventType,
            loadNewDesign,
            workName,
            save,
            productname,
            customerSelectedProductOptions
        ]
    );

    const generateNewDocument = useCallback(
        async (template: string, studioSelectedProductOptions: Record<string, string>, removeBackground?: boolean) => {
            if (productVersion === null) {
                throw Error("Product version is not defined");
            }
            const authToken = auth.getToken();
            return generateDocumentForNewTemplate({
                productKey,
                productVersion,
                locale,
                studioSelectedProductOptions,
                template,
                dispatch,
                loadingMessage: t(messages.loadingMessage.id),
                authToken,
                isCurrentlyFullBleed: isFullBleed,
                removeBackground,
                isColorMatchingBacksideEnabled: useColorGenericBacksides,
                getDocument
            });
        },
        [productVersion, auth, productKey, locale, dispatch, t, isFullBleed, useColorGenericBacksides, getDocument]
    );

    return { changeTemplate, generateNewDocument };
}
