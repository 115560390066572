import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { LockIconPremiumFinish } from "@shared/features/StudioChrome";
import { FinishMessages } from "../messages";
import * as styles from "./PremiumFinishLocked.module.scss";

export function PremiumFinishLocked() {
    const { t } = useTranslationSSR();

    return (
        <div className={styles.container} role="button" tabIndex={0}>
            <LockIconPremiumFinish className={styles.lockIcon} />
            <div className={styles.text}>{t(FinishMessages.finishOn.id)}</div>
        </div>
    );
}

PremiumFinishLocked.displayName = "PremiumFinishLocked";
