import React from "react";
import { PremiumFinishToggleSwitch, PremiumFinishPlaceholderWarning } from "@shared/features/PremiumFinish";
import { usePremiumFinishWordArtAndTextToggle } from "@easel";
import { isPlaceholderText } from "./usePremiumFinishFilter";

type Props = {
    item: CanvasItem;
};

export function PremiumFinishTextSelection({ item }: Props) {
    const { hasFinish, onChange } = usePremiumFinishWordArtAndTextToggle({ selection: [item] });

    return (
        <div className="premium-finish-text">
            {isPlaceholderText(item) && <PremiumFinishPlaceholderWarning />}
            <PremiumFinishToggleSwitch activated={hasFinish} onRequestActivatedChange={onChange} />
        </div>
    );
}

PremiumFinishTextSelection.displayName = "PremiumFinishTextSelection";
