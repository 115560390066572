import React from "react";
import { ItemContent } from "./ItemContent";
import * as styles from "./OtherContent.module.scss";

interface OtherContentProps {
    previewUrl: string;
}

export function OtherContent({ previewUrl }: OtherContentProps) {
    return (
        <ItemContent>
            <img src={previewUrl} className={styles.otherContentImage} alt="layer-preview" />
        </ItemContent>
    );
}

OtherContent.displayName = "OtherContent";
