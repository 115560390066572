import React, { useCallback, useEffect, useRef } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { addVCQRCode } from "@presentational";
import { useDesigner } from "@designer-suite";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { AddQRCodePanel } from "@shared/features/QRCodes";

export function AddQRCodePanelStudio5() {
    const designer = useDesigner();
    const startTime = useRef<number | null>(null);
    const identityContext = useIdentityContext();
    const locale = useSelector((state: RootStateOrAny) => state.locale);

    const onAddQRCode = useCallback(
        (url: string) => {
            startTime.current = performance.now();

            if (url) {
                addVCQRCode(locale, url, identityContext);

                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ADD_QRCODE,
                    label: "Click Add QR Code"
                });
            }
        },
        [identityContext, locale]
    );

    // Send interaction timing after qr code is added to canvas by designer
    useEffect(() => {
        if (!designer) return;
        const logTiming = (event: EventData) => {
            const eventType = event.items[0]._itemViewModel.model.get("type");
            if (eventType === "QR Code" && startTime.current !== null) {
                const endTime = performance.now();
                fireUserInteractionTrackingEvent("Generate QR Code", endTime - startTime.current);
                startTime.current = null;
            }
        };

        const unsubscribeAdd = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, logTiming);
        /* eslint-disable consistent-return */
        return () => {
            unsubscribeAdd();
        };
    }, [designer, startTime]);

    return <AddQRCodePanel onAddQRCode={onAddQRCode} />;
}
AddQRCodePanelStudio5.displayName = "AddQRCodePanelStudio5";
