import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import classNames from "classnames";
import { PremiumFinishIcon } from "@shared/features/StudioChrome";
import { usePreviewsContext, useIsVortexEnabled, type StudioVortexPreviewProps } from "@shared/features/Previews";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import * as styles from "./PremiumFinishPreview.module.scss";

const StudioVortexPreview = loadable<
    StudioVortexPreviewProps,
    { StudioVortexPreview: React.ComponentType<StudioVortexPreviewProps> }
>(() => loadableRetry(() => import("@shared/features/Previews")), {
    resolveComponent: components => components.StudioVortexPreview
});

const messages = defineMessages({
    previewInfo: {
        id: "studio.components.modals.premiumfinish.previewInfo",
        defaultMessage: "Drag to see the brilliant finish",
        description: {
            note: "Preview info text"
        }
    }
});

export interface PremiumFinishPreviewProps {
    isPremiumFinishPreviewSticky: Boolean;
    activeCanvasName?: string;
}

export const PremiumFinishPreview = React.forwardRef<HTMLDivElement, PremiumFinishPreviewProps>(
    ({ isPremiumFinishPreviewSticky, activeCanvasName }, ref) => {
        const { t } = useTranslationSSR();
        const isVortexEnabled = useIsVortexEnabled();
        const { previewUrls, previewInstructionsUrl } = usePreviewsContext();
        const previewIndex = previewUrls.findIndex(url => url.title === activeCanvasName);

        return (
            <div
                ref={ref}
                className={classNames(styles.premiumFinishPreview, {
                    [styles.dropShadow]: isPremiumFinishPreviewSticky
                })}
            >
                {!isPremiumFinishPreviewSticky && isVortexEnabled && (
                    <div className={styles.premiumFinishPreviewInfo}>
                        <PremiumFinishIcon aria-hidden="true" />
                        <span className={styles.infoText}>{t(messages.previewInfo.id)}</span>
                    </div>
                )}
                <div className={styles.premiumFinishPreviewImage}>
                    <StudioVortexPreview
                        vortexClassName={styles.vortexPreview}
                        fallbackComponent={() => (
                            <img src={previewUrls.length > 0 ? previewUrls[0].src : ""} alt="previewImage" />
                        )}
                        showControls={false}
                        previewInstructionsUrl={previewInstructionsUrl}
                        previewCount={previewUrls.length}
                        previewIndex={previewIndex}
                    />
                </div>
            </div>
        );
    }
);

PremiumFinishPreview.displayName = "PremiumFinishPreview";
