import React from "react";
import { Button } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";

const messages = defineMessages({
    templateGallerySelect: {
        id: "studio.panels.template.main.gallery.selectButton",
        defaultMessage: `Select`,
        description: {
            note: "Hover button that appears for selecting templates"
        }
    }
});

export const TemplateSelectButton = () => {
    const { t } = useTranslationSSR();
    // as "div" since otherwise this would be a button within a button
    // which is not allowed good html
    return (
        <Button as="div" className="template-panel-hover-button" size={"mini"}>
            {t(messages.templateGallerySelect.id)}
        </Button>
    );
};

TemplateSelectButton.displayName = "TemplateSelectButton";
