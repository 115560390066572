import { useStylesInUse } from "./useStylesInUse";
import type { AttributeCount } from "./useStylesInUse";

export const useDefaultTableStyle = (canvases: Canvas[]): TableItemPredefinedStyle => {
    const { tableAttributes, textAttributes, wordArtAttributes } = useStylesInUse(canvases);

    const bestValue = (list: AttributeCount, key: string) => {
        const attributes = list.getCount(key);
        if (!attributes) {
            return undefined;
        }

        const keys = Object.keys(attributes);
        keys.sort((a, b) => attributes[a] - attributes[b]);
        return keys[0];
    };

    const getValue = (key: string) => {
        return (
            bestValue(tableAttributes, key) ||
            bestValue(textAttributes, key) ||
            bestValue(wordArtAttributes, key) ||
            undefined
        );
    };

    const backgroundColor = getValue("backgroundColor");
    const fontColor = getValue("fontColor");
    const fontFamily = getValue("fontFamily");
    const fontSize = getValue("fontSize") || 12;
    const fontStyle = getValue("fontStyle");
    const styleId = getValue("styleId");
    const styleIdAsNumber = styleId ? +styleId : 1; // StyleId is a number, and causes type errors if it is handled as a string

    return {
        styleId: styleIdAsNumber,
        backgroundColor: backgroundColor || "rgb(0,0,0)",
        fontFamily: fontFamily || "Arimo",
        fontStyle: fontStyle || "Normal,Normal",
        fontSize: `${fontSize}pt`,
        fontColor: fontColor || "rgb(0,0,0)"
    };
};
