import React from "react";
import { AlertBox, AlertBoxDismissButton, Box } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import type { Error } from "./types";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./MyProjectsLeftSidebarPanel.module.scss";

interface prop {
    error: Error;
    setError: () => void;
}

export const MyProjectsErrorToast = ({ error, setError }: prop) => {
    const { t } = useTranslationSSR();

    return (
        <Box className={styles.messageArea}>
            {error.showToast && (
                <AlertBox skin="error" onRequestDismiss={() => setError()}>
                    {`${t(error.message)} Error code: ${error.errorCode}`}
                    <AlertBoxDismissButton visuallyHiddenLabel={t(error.message)} />
                </AlertBox>
            )}
        </Box>
    );
};

MyProjectsErrorToast.displayName = "MyProjectsErrorToast";
