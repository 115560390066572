import React from "react";

import { DialogType } from "@shared/features/ActiveDialog";
import { AITemplatesProvider } from "../AITemplates/AITemplatesProvider";
import { AITemplatesPanel } from "../AITemplates/AITemplatesPanel";

import "../AITemplates/aiTemplatesPanel.scss";

export interface AITemplatesPanelProps {
    onDone?: () => void;
    fullHeight?: boolean;
}

export function GenerateIdeasPanelContainer({ onDone, fullHeight = false }: AITemplatesPanelProps) {
    return (
        <AITemplatesProvider type={DialogType.IdeaPanel}>
            <AITemplatesPanel onDone={onDone} fullHeight={fullHeight} />
        </AITemplatesProvider>
    );
}

GenerateIdeasPanelContainer.displayName = "GenerateIdeasPanelContainer";
