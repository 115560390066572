import { useEffect, useState } from "react";
import { useAppSelector } from "@shared/redux";

// Specs and Template information not available for these products
const ExcludedPRDsList = [
    "PRD-TKFIQQ2WD", // 18 Oz. James Glass Bottle
    "PRD-GQE5EMKEV" // NA Custom Water Bottles
];

export const useProductHasSpecsAndTemplates = () => {
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const productKey = useAppSelector(state => state.productKey);
    const [hasSpecsAndTemplate, setHasSpecsAndTemplate] = useState<boolean>(false);

    useEffect(() => {
        if (!isFullBleed || ExcludedPRDsList.indexOf(productKey) > 0) {
            return;
        }
        setHasSpecsAndTemplate(true);
    }, [productKey, isFullBleed]);

    return hasSpecsAndTemplate;
};
