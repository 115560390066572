import React, { useContext, useState, createContext, useMemo, useCallback, ReactNode, useEffect, useRef } from "react";
import { useAsync } from "react-async-hook";
import { defineMessages } from "@vp/i18n-helper";
import { useDesigner } from "@designer-suite";
import { Status, useClipart, useIcons, useStockImages } from "@design-stack-vista/image-library-react";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { useAppSelector } from "@shared/redux";
import { getDefaultImages } from "../../../components/Panels/Images/DefaultImagesIds";
import {
    createDesignerModeledElements,
    ElementItem,
    ElementUploadItem,
    getRecentlyUsedElements
} from "./ElementsPanelUtils";

export enum ElementsPanelContentTypes {
    Main = "main",
    Shapes = "shapes",
    Icons = "icons",
    Images = "images",
    Clipart = "clipart",
    Recent = "recent"
}

export const DEFAULT_SEARCH_ELEMENTS = "defaultSearchElements";
const ICON = "icon";

export const messages = defineMessages({
    elementsTitle: {
        id: "studio.features.graphics.graphicsTitle",
        defaultMessage: "Graphics",
        description: {
            note: "Title for the graphics panel"
        }
    },
    shapesTitle: {
        id: "studio.features.elements.shapesTitle",
        defaultMessage: "Shapes",
        description: {
            note: "Title for the shapes section of the elements panel"
        }
    },
    iconsTitle: {
        id: "studio.features.elements.iconsTitle",
        defaultMessage: "Icons",
        description: {
            note: "Title for the icons section of the elements panel"
        }
    },
    imagesTitle: {
        id: "studio.features.elements.imagesTitle",
        defaultMessage: "Images",
        description: {
            note: "Title for the images section of the elements panel"
        }
    },
    clipartTitle: {
        id: "studio.features.elements.clipartTitle",
        defaultMessage: "Clipart",
        description: {
            note: "Title for the clipart section of the elements panel"
        }
    },
    recentlyUsedTitle: {
        id: "studio.features.elements.recentlyUsedTitle",
        defaultMessage: "Recents",
        description: {
            note: "Title for the recently used images section of the elements panel"
        }
    }
});

export const panelTitle: Record<ElementsPanelContentTypes, string> = {
    [ElementsPanelContentTypes.Main]: messages.elementsTitle.id,
    [ElementsPanelContentTypes.Shapes]: messages.shapesTitle.id,
    [ElementsPanelContentTypes.Icons]: messages.shapesTitle.id,
    [ElementsPanelContentTypes.Images]: messages.imagesTitle.id,
    [ElementsPanelContentTypes.Clipart]: messages.clipartTitle.id,
    [ElementsPanelContentTypes.Recent]: messages.recentlyUsedTitle.id
};

type Data = {
    stack: ElementsPanelContentTypes[];
    addToStack: (panelContentType: ElementsPanelContentTypes) => void;
    jumpToInStack: (panelContentType: ElementsPanelContentTypes) => void;
    removeFromStack: () => void;
    imageGalleryItems: ElementUploadItem[];
    clipartGalleryItems: ElementUploadItem[];
    icons: ElementUploadItem[];
    iconStatus: Status;
    iconActivePage: number;
    searchTerm: string; // value in the input field
    setSearchTerm: (searchTerm: string) => void;
    performSearchElements: (searchTerm: string, filterOptions?: string) => void;
    currentElementsPanelSearchTerm: string; // string that is used for search
    hasMoreItems: boolean;
    performNextSearch: () => void;
    imageStatus: Status;
    clipartStatus: Status;
    currentFilterOptions: string | undefined;
    recentStatus: Status;
    recentlyUsedItems: ElementUploadItem[];
};

const context = createContext<Data | undefined>(undefined);
const { Provider } = context;

export function useElementsPanel() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within an ElementsPanelProvider");
    }
    return result;
}

interface ElementsPanelProviderProps {
    children: ReactNode | ReactNode[];
}

const defaultImages = getDefaultImages();

export const ElementsPanelProvider = ({ children }: ElementsPanelProviderProps) => {
    const designer = useDesigner();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { currentActiveDialog } = useActiveDialog();
    const [stack, setStack] = useState<ElementsPanelContentTypes[]>([ElementsPanelContentTypes.Main]);

    const {
        performInitialSearch: performSearchImages,
        imageItems: imageResults,
        hasMoreItems,
        performNextSearch,
        status: imageStatus,
        clearSearch,
        currentFilterOptions
    } = useStockImages();
    const {
        imageResults: iconResults,
        status: iconStatus,
        performSearch: performSearchIcons,
        activePage: iconActivePage
    } = useIcons();
    const { clipartItems, performSearch: performSearchClipart, status: clipartStatus } = useClipart();

    const [imageGalleryItems, setImageGalleryItems] = useState<ElementUploadItem[]>([]);
    const [icons, setIcons] = useState<ElementUploadItem[]>([]);
    const [clipartGalleryItems, setClipartGalleryItems] = useState<ElementUploadItem[]>([]);

    const [recentlyUsedItems, setRecentlyUsedItems] = useState<ElementUploadItem[]>([]);
    const [recentStatus, setRecentStatus] = useState(Status.Loading);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentElementsPanelSearchTerm, setCurrentElementsPanelSearchTerm] = useState<string>("");

    const initialImageSearchPerformed = useRef(false);
    const initialClipartSearchPerformed = useRef(false);

    const addToStack = useCallback(
        (panelContentType: ElementsPanelContentTypes) =>
            setStack(current => {
                return [...current, panelContentType];
            }),
        []
    );

    const jumpToInStack = useCallback((panelContentType: ElementsPanelContentTypes) => {
        setStack(current => {
            const index = current.findIndex(panel => panel === panelContentType);
            return current.slice(0, index + 1);
        });
    }, []);

    const removeFromStack = useCallback(() => {
        setStack(current => {
            return current.slice(0, -1);
        });
    }, []);

    const performSearchElements = useCallback(
        (newSearchTerm: string, newFilterOptions?: string) => {
            if (
                (newSearchTerm === currentElementsPanelSearchTerm || !newSearchTerm) &&
                newFilterOptions === currentFilterOptions
            ) {
                return;
            }

            // Images has a weird thing where you have to perform a blank search in order to 'set' the filter
            // This use case is you have the default images showing and you are selecting filters
            // We can't filter those default images but we need to save our filter
            if (!newSearchTerm && !currentElementsPanelSearchTerm && newFilterOptions !== currentFilterOptions) {
                performSearchImages(newSearchTerm, newFilterOptions);
                return;
            }

            // clean up the old search before performing a new one
            // otherwise we can get abandoned dom nodes or other weird behavior
            setImageGalleryItems([]);
            setIcons([]);
            setClipartGalleryItems([]);
            clearSearch();

            if (newSearchTerm === DEFAULT_SEARCH_ELEMENTS) {
                setCurrentElementsPanelSearchTerm("");
                setSearchTerm("");

                performSearchImages(defaultImages, newFilterOptions ?? currentFilterOptions);
                performSearchClipart("");
                performSearchIcons(ICON);
                return;
            }
            setCurrentElementsPanelSearchTerm(newSearchTerm);
            performSearchImages(newSearchTerm, newFilterOptions ?? currentFilterOptions);
            performSearchClipart(newSearchTerm);
            performSearchIcons(newSearchTerm);
        },
        [
            currentElementsPanelSearchTerm,
            performSearchImages,
            performSearchClipart,
            performSearchIcons,
            clearSearch,
            currentFilterOptions
        ]
    );

    // Recently Used
    useAsync(async () => {
        if (!designer) {
            return;
        }
        if (currentActiveDialog === DialogType.Elements) {
            const recentlyUsed = await getRecentlyUsedElements();
            const newItems = recentlyUsed?.map((item: any) => {
                return {
                    ...item,
                    svgUrl: item.svgUrl || item.printUrl
                };
            });
            if (newItems) {
                const formattedRecentlyUsed = createDesignerModeledElements(newItems, designer);
                setRecentlyUsedItems(formattedRecentlyUsed);
                setRecentStatus(Status.Idle);
            }
        }
        // udpate recents when searchTerm is cleared or when navigating back to ElementsMain
    }, [setRecentlyUsedItems, currentActiveDialog, currentElementsPanelSearchTerm, stack]);

    // Images
    useEffect(() => {
        if (
            currentActiveDialog === DialogType.Elements &&
            !initialImageSearchPerformed.current &&
            imageGalleryItems.length === 0
        ) {
            const defaultImages = getDefaultImages();
            performSearchImages(defaultImages);
            initialImageSearchPerformed.current = true;
        }
    }, [currentActiveDialog, imageGalleryItems.length, performSearchImages]);
    useEffect(() => {
        if (!designer) {
            return;
        }
        if (imageResults) {
            const newItems = createDesignerModeledElements(imageResults as ElementItem[], designer);
            setImageGalleryItems(newItems);
        }
    }, [designer, imageResults]);

    // Clipart
    useEffect(() => {
        if (currentActiveDialog === DialogType.Elements && !initialClipartSearchPerformed.current) {
            // retrieve the 'default' set of clipart when this component first renders
            performSearchClipart("");
            initialClipartSearchPerformed.current = true;
        }
    }, [currentActiveDialog, performSearchClipart]);
    useEffect(() => {
        async function getImages() {
            if (clipartItems) {
                if (!designer) {
                    return;
                }
                const clipartResults = await Promise.all(
                    clipartItems.map(async item => {
                        return {
                            id: item.id,
                            src: item.metadata.thumbnailUrl,
                            previewUrl: item.metadata.thumbnailUrl, // thumbnail previewUrl
                            width: item.metadata.thumbnailInfo?.width, // thumbnail width
                            height: item.metadata.thumbnailInfo?.height, // thumbnail height
                            printUrl: item.metadata.imageUrl, // cimdoc printUrl
                            svgUrl: item.metadata.svgUrl,
                            printPixelHeight: item.metadata.imageInfo?.height, // printUrl height used by designer
                            printPixelWidth: item.metadata.imageInfo?.width // printUrl width used by designer
                        };
                    })
                );
                const newItems = createDesignerModeledElements(clipartResults, designer);
                setClipartGalleryItems(newItems);
            }
        }
        getImages();
    }, [clipartItems, designer]);

    // Icons
    useEffect(() => {
        if (!easelLoaded || !designer || !iconResults) {
            return;
        }
        const ICON_SIZE = "1200";

        const newIcons = createDesignerModeledElements(
            iconResults.map(icon => ({
                ...icon,
                src: icon.previewUrl,
                width: ICON_SIZE,
                height: ICON_SIZE,
                type: "image",
                extension: "png"
            })),
            designer
        );

        setIcons(newIcons);
    }, [designer, easelLoaded, iconResults]);

    const value = useMemo(() => {
        return {
            stack,
            addToStack,
            jumpToInStack,
            removeFromStack,
            imageGalleryItems,
            clipartGalleryItems,
            icons,
            iconStatus,
            iconActivePage,
            searchTerm,
            setSearchTerm,
            performSearchElements,
            currentElementsPanelSearchTerm,
            hasMoreItems,
            performNextSearch,
            imageStatus,
            clipartStatus,
            currentFilterOptions,
            recentlyUsedItems,
            recentStatus
        };
    }, [
        stack,
        addToStack,
        jumpToInStack,
        removeFromStack,
        imageGalleryItems,
        clipartGalleryItems,
        icons,
        iconStatus,
        iconActivePage,
        searchTerm,
        setSearchTerm,
        performSearchElements,
        currentElementsPanelSearchTerm,
        hasMoreItems,
        performNextSearch,
        imageStatus,
        clipartStatus,
        currentFilterOptions,
        recentlyUsedItems,
        recentStatus
    ]);

    return <Provider value={value}>{children}</Provider>;
};

ElementsPanelProvider.displayName = "ElementsPanelProvider";
