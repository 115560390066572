/* eslint-disable max-len */
import React from "react";

export function SelectPhotosMobileIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 73" fill="none" {...props}>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M21.239 64.25v0c.409 0 .74.336.74.75 0 .415-.331.75-.74.75v0a.745.745 0 0 1-.74-.75v0c0-.414.331-.75.74-.75"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M7.92 71C4.65 71 2 68.314 2 65V8c0-3.314 2.65-6 5.92-6h26.639c3.269 0 5.92 2.686 5.92 6v57c0 3.314-2.651 6-5.92 6H7.919Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M40.478 58.999H2M40.478 14H2"
            />
            <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16.8 8.002h8.879" />
            <rect width={12.072} height={11.859} x={7.497} y={21.801} stroke="#000" rx={1} />
            <rect width={12.072} height={11.859} x={23.018} y={21.801} stroke="#000" rx={1} />
            <rect width={12.072} height={11.859} x={7.497} y={38.743} stroke="#000" rx={1} />
            <rect width={12.072} height={11.859} x={23.018} y={38.743} stroke="#000" rx={1} />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11.161 27.422 12.837 29l3.821-3.6"
            />
        </svg>
    );
}
SelectPhotosMobileIcon.displayName = "SelectPhotosMobileIcon";
