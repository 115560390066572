import React, { FormEvent } from "react";
import {
    TextInput,
    FormError,
    FormField,
    FormInputGroup,
    TextInputWithFloatingLabel,
    TextInputFloatingLabel
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import isURL from "validator/lib/isURL";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { QRCodeMessages as messages } from "@shared/features/QRCodes";

function validURL(str: string) {
    return isURL(str, { protocols: ["http", "https"], require_protocol: false, require_valid_protocol: true });
}

type Props = {
    onUrlChange: (url: string) => void;
    url: string;
    error: boolean;
    setError: React.Dispatch<React.SetStateAction<boolean>>;
    autoFocusInput?: boolean;
};

export const QRCodeURLInput = (props: Props) => {
    const { onUrlChange, url, autoFocusInput, error, setError } = props;
    const { t } = useTranslationSSR();
    const textImageSelection = useIsFlagEnabled("textImageSelection");

    const onChange = (event: FormEvent<HTMLInputElement>) => {
        const newText = event.currentTarget.value;
        onUrlChange(newText);
        if (!validURL(newText)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    return (
        <>
            <FormField>
                <FormInputGroup paddingBottom={error ? 1 : 5} marginBottom={"0"}>
                    <TextInputWithFloatingLabel>
                        <TextInput
                            placeholder={t(messages.url.id)}
                            value={url}
                            onChange={onChange}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={autoFocusInput}
                        />
                        {!textImageSelection && (
                            <TextInputFloatingLabel>{t(messages.example.id)}</TextInputFloatingLabel>
                        )}
                        {textImageSelection && (
                            <TextInputFloatingLabel>{t(messages.exampleForQrCode.id)}</TextInputFloatingLabel>
                        )}
                        {error ? <FormError>{t(messages.invalidURL.id)}</FormError> : <div></div>}
                    </TextInputWithFloatingLabel>
                </FormInputGroup>
            </FormField>
        </>
    );
};

QRCodeURLInput.displayName = "QRCodeUrlInput";
