import React from "react";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Typography, Link } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTemplatePanel } from "./TemplatePanelProvider";

const messages = defineMessages({
    clearFilters: {
        id: "studio.components.template.panel.clearFilters",
        defaultMessage: "Clear all",
        description: {
            note: "Text for button to clear all selected filters when browsing templates in the left template panel"
        }
    }
});

export function TemplatePanelClearFiltersButton() {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const { anyFiltersSelected, clearSelectedFilters } = useTemplatePanel();

    return (
        <Link
            className={classNames("clear-filters-button", {
                "clear-filters-button--hidden": !anyFiltersSelected
            })}
            component="button"
            onClick={() => {
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_TEMPLATE_FILTERS_CLEAR_ALL,
                    label: "Click clear all filters in template panel filters dialog"
                });
                clearSelectedFilters();
            }}
        >
            <Typography textSize={isSmall ? 6 : 7} weight={isSmall ? "standard" : "bold"}>
                {t(messages.clearFilters.id)}
            </Typography>
        </Link>
    );
}

TemplatePanelClearFiltersButton.displayName = "TemplatePanelClearFiltersButton";
