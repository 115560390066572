import React from "react";
import { PipeSeparator } from "@vp/swan";
import { UndoRedoButtonBarDesktop, ZoomToolbar } from "@designer-suite";
import { loadableRetry } from "@shared/utils/Network";
import loadable from "@loadable/component";
import { useAppSelector } from "@shared/redux";
import { FooterBar, FooterBarLeftContent } from "@shared/features/Layout";
import * as styles from "./BottomBar.module.scss";
import { ViewPanel } from "./Panels";

const DesignHelp = loadable(() => loadableRetry(() => import("./DesignHelp")));

export const BottomBar = () => {
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    return (
        <>
            <FooterBar className={styles.bottomBarPositioning} data-block-outside-panel-click-to-close>
                <FooterBarLeftContent>
                    <ZoomToolbar className="canvas-zoom-toolbar" />
                    <PipeSeparator />
                    <ViewPanel />
                </FooterBarLeftContent>
                <UndoRedoButtonBarDesktop />
                {/* Add a div here to take up the space of the old design help panel because it just needs to look nice in studio5 */}
                <div style={{ width: "250px" }} />
            </FooterBar>
            {easelLoaded && <DesignHelp className={styles.designHelpOverride} />}
        </>
    );
};

BottomBar.displayName = "BottomBar";

export default BottomBar;
