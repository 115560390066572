import React from "react";
import { isRgb, isCmyk, colorToString } from "@shared/features/ColorPicker";

import { useDesigner, useSelection, useRichTextAttribute, useRichTextSet } from "@designer-suite";
import {
    updateSelectedItems,
    wordArtFilter,
    getReferenceData,
    isSingleColorCanvas,
    getTrackingDataForSelection
} from "@utilities";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import type { Designer, ItemSelection } from "src/easel/designer-suite/@types/designer";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { CMYK, RGB } from "@design-stack-ct/utility-core";
import { useRecentColorsContext } from "@presentational";
import {
    getIsTextColorPickerCompatible,
    listenEvents
} from "../../../../easel/designer-suite/ToolbarComponents/Buttons/WordArtAndTextColorPicker";
import { addModelsToSelection, areCustomColorsAllowed, getColorPalette } from "../../../../easel/designer-suite/util";
import { ColorPickerPanelWrapper } from "../ColorPicker/ColorPickerPanelWrapper";
import { COLOR_PICKER_PANEL_TYPES } from "../ColorPicker/colorPickerPanelTypes";

const messages = defineMessages({
    textColorTitle: {
        id: "studio.components.panels.textColorpickerPanel.title",
        defaultMessage: "Text color",
        description: {
            note: "This is the title for a panel that allows the user to change their text's color"
        }
    }
});

let lastFontColorChosen: string | RGB | CMYK;

export function getLastFontColorChosen() {
    return lastFontColorChosen;
}

export function onApply(
    designer: Designer | undefined,
    setAttribute: (items: ItemViewModel[], attributeName: string, value: string) => void,
    selection: ItemSelection,
    value: RGB | CMYK | string,
    tracking = true
) {
    if (!designer) {
        return;
    }
    if (isRgb(value)) {
        setAttribute(addModelsToSelection(selection), "color", `rgb(${value.r},${value.g},${value.b})`);
        tracking &&
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.FONT_RGB_APPLIED, getTrackingDataForSelection(selection));
    } else if (isCmyk(value)) {
        setAttribute(addModelsToSelection(selection), "color", `cmyk(${value.c},${value.m},${value.y},${value.k})`);
        tracking &&
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.FONT_CMYK_APPLIED, getTrackingDataForSelection(selection));
    } else {
        setAttribute(addModelsToSelection(selection), "color", value);
        tracking &&
            designer.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.FONT_COLORTILE_APPLIED,
                getTrackingDataForSelection(selection)
            );
    }
}

// Generally return empty string for color when select includes multiple different colors
function getSelectionsWordArtColor(designer: Designer, wordArtItems: any[]) {
    if (!designer || !wordArtItems || wordArtItems.length === 0) {
        return "";
    }

    const firstItemColor = getReferenceData(wordArtItems[0]).color;
    const allEqual = wordArtItems.every((item: any) => getReferenceData(item).color === firstItemColor);
    return allEqual ? firstItemColor : "";
}

function getSelectionColor(designer: Designer, wordArtItems: any[], textColor: string) {
    // Will be undefined if their is no current color selected for text
    if (wordArtItems.length === 0) {
        return textColor;
    }
    const wordArtColor = getSelectionsWordArtColor(designer, wordArtItems);
    return wordArtColor;
}

export function WordArtAndTextColorPickerPanel({ allowCustom = true, contentOnly = false }) {
    const designer = useDesigner();
    const selection = useSelection(`model:change:data ${listenEvents}`);
    const { setWithSideEffects } = useRichTextSet();
    const { wordArtItems, otherItems } = wordArtFilter(designer, selection);
    const textColorValue = useRichTextAttribute("common", "color", "");
    const { recentColors } = useRecentColorsContext();
    const { t } = useTranslationSSR();

    if (
        (!wordArtItems.length && !otherItems.length) ||
        !getIsTextColorPickerCompatible(designer, otherItems) ||
        (!wordArtItems.length && !textColorValue)
    ) {
        return null;
    }

    const value = getSelectionColor(designer, wordArtItems, textColorValue);
    // selection is used to look up current canvas - doesn't matter what the type is
    const paletteColors = getColorPalette(designer, selection[0]);
    const onlySingleColorAllowed = isSingleColorCanvas(designer);

    function onChange(newValue: string | RGB | CMYK) {
        lastFontColorChosen = newValue;
        if (!designer) {
            return;
        }
        if (otherItems.length > 0) {
            onApply(designer, setWithSideEffects, otherItems, newValue);
        }
        const newColor = colorToString(newValue);
        // Note: If a drop shadow exists it is intentionally not updated with a new color
        //  this replicates behavior from studio4.
        updateSelectedItems(designer, wordArtItems, (mutableItem: WordArtItem) => {
            const updatedData: WordArtData = { ...mutableItem.data, color: newColor };
            // Can't have both raised foil and a color - this shouldn't be possible but just in case, remove the overprint
            if (mutableItem.data.overprints?.some(overprint => overprint.toLowerCase().includes("raisedfoil"))) {
                updatedData.overprints = [];
            }

            mutableItem._itemViewModel.model.set("data", {
                ...mutableItem.data,
                ...updatedData
            });
        });
        if (onlySingleColorAllowed) {
            const viewModels = designer.documentRepository.getActiveCanvasViewModel().itemViewModels;
            // // @ts-ignore I am faking a backbone collection by adding a first function
            designer.commandDispatcher.changeColors({
                viewModels,
                colorChanges: [{ key: newValue.toString(), newColor: newValue, currentColor: value }]
            });
        }
    }

    const allowCustomColors = allowCustom && areCustomColorsAllowed(designer, selection[0]);

    const eyeDropperConfig = {
        isCompatibleWithSelection: () => {
            return allowCustom && areCustomColorsAllowed(designer, selection[0]);
        },
        listenEvents: "model:change:data",
        buttonSize: "mini" as const,
        onClick: onChange
    };

    return contentOnly ? (
        <ColorPickerPanelWrapper
            value={value}
            recentColors={recentColors}
            paletteColors={paletteColors}
            onChange={onChange}
            allowCustom={allowCustomColors}
            eyeDropperConfig={eyeDropperConfig}
            panelType={COLOR_PICKER_PANEL_TYPES.TEXT_COLOR}
        />
    ) : (
        <PanelContent className="studio-color-panel-content" data-dcl-prevent-canvas-items-deselection>
            <DesktopExperience>
                <div className="color-panel__sticky-nav">
                    <PanelTitle>{t(messages.textColorTitle.id)}</PanelTitle>
                </div>
            </DesktopExperience>
            <div className="studio-wordart-text-color-picker">
                <ColorPickerPanelWrapper
                    value={value}
                    recentColors={recentColors}
                    paletteColors={paletteColors}
                    onChange={onChange}
                    allowCustom={allowCustomColors}
                    eyeDropperConfig={eyeDropperConfig}
                    panelType={COLOR_PICKER_PANEL_TYPES.TEXT_COLOR}
                />
            </div>
        </PanelContent>
    );
}

WordArtAndTextColorPickerPanel.displayName = "WordArtAndTextColorPickerPanel";
