import React from "react";
import classNames from "classnames";
import { SelectionSet, SelectionSetInput, SelectionSetLabel } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { fontSizeMessages } from "./fontSizeMessages";
import * as styles from "./FontSizeDropdown.module.scss";

interface FontSizeDropdownProps {
    onChange: (value: number) => void;
    className?: string;
    fontSizes: number[];
    isDisabled?: boolean;
}

export function FontSizeDropdown(props: FontSizeDropdownProps) {
    const { t } = useTranslationSSR();
    const { onChange, className, fontSizes, isDisabled } = props;

    const handleSelectedValueChange = (selectedValue: string) => {
        const value = parseInt(selectedValue, 10);
        onChange(value);
    };

    const options = fontSizes.map(value => ({ value: `${value}`, name: `${value}` }));

    return (
        <SelectionSet
            className={classNames(className, styles.dropdown)}
            variant="single-select"
            onSelectedValueChange={handleSelectedValueChange}
            aria-label={t(fontSizeMessages.fontSizeSelectorTitle.id)}
        >
            {options.map(option => {
                return (
                    <SelectionSetInput
                        key={option.value}
                        value={option.value}
                        aria-label={option.value}
                        disabled={isDisabled}
                    >
                        <SelectionSetLabel className={styles.label}>{option.name}</SelectionSetLabel>
                    </SelectionSetInput>
                );
            })}
        </SelectionSet>
    );
}
FontSizeDropdown.displayName = "FontSizeDropdown";
