import React, { useState } from "react";
import { itemGetPremiumFinish, toggleFinish, useFinishType } from "@utilities";
import {
    PremiumFinishLocked,
    PremiumFinishLockedTooltip,
    PremiumFinishToggleSwitch,
    PremiumFinishUpdatingLabel
} from "@shared/features/PremiumFinish";
import { isUserUploadedImage, usePremiumFinishWarning } from "./usePremiumFinishFilter";

type Props = {
    item: ImageItem;
};
export function PremiumFinishImageSelection({ item }: Props) {
    const hasFinish = itemGetPremiumFinish(item);
    const [warning, setWarning] = useState<boolean>(false);
    const [prevHasFinish, setPrevHasFinish] = useState(hasFinish);
    const isTemplateImage = !isUserUploadedImage(item) || undefined;
    const itemWarning = usePremiumFinishWarning();
    const finishType = useFinishType();
    const userImagesNotAllowedFinishes = ["RaisedFoilGlitterSilver", "RaisedFoilSilver", "RaisedFoilGold"];
    const finishToggleNotAllowed = !finishType || userImagesNotAllowedFinishes.includes(finishType);

    function onChange() {
        toggleFinish(item);
        setPrevHasFinish(value => !value);
    }

    function showWarningMessage(status: boolean) {
        if (hasFinish && (isTemplateImage || itemWarning)) {
            setWarning(status);
        }
    }

    const isLoading = hasFinish !== prevHasFinish;
    return (
        <div
            className="premium-finish-image"
            onMouseOver={() => showWarningMessage(true)}
            onFocus={() => showWarningMessage(true)}
            onMouseLeave={() => setWarning(false)}
            role="button"
            tabIndex={0}
        >
            {hasFinish && (itemWarning || isTemplateImage) && <PremiumFinishLocked />}
            {isLoading && <PremiumFinishUpdatingLabel />}
            <PremiumFinishToggleSwitch
                disabled={isTemplateImage || finishToggleNotAllowed}
                activated={hasFinish}
                onRequestActivatedChange={onChange}
            />
            {warning && <PremiumFinishLockedTooltip />}
        </div>
    );
}

PremiumFinishImageSelection.displayName = "PremiumFinishImageSelection";
