/* eslint-disable max-len */
import React from "react";

export function TeamsGuideLabelStep2(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 40 40" fill="none" {...props}>
            <circle cx="20" cy="20" r="19.5" fill="white" stroke="black" />
            <path
                d="M14.27 26H23.576V24.146H18.104L20.966 21.356C22.496 19.88 23.486 18.422 23.486 16.748C23.486 14.48 21.902 12.95 19.238 12.95C16.52 12.95 14.828 14.57 14.666 17.378H16.862C17.078 15.56 17.888 14.822 19.166 14.822C20.408 14.822 21.092 15.578 21.092 16.784C21.092 18.008 20.354 19.142 19.13 20.438L14.27 25.568V26Z"
                fill="black"
            />
        </svg>
    );
}
TeamsGuideLabelStep2.displayName = "TeamsGuideLabelStep2";
