import { useDesigner, useUploads } from "@designer-suite";
import { GetAltText } from "@shared/features/Previews";
import { useCallback } from "react";
import { Upload } from "src/easel/designer-suite/@types/upload";

export function useGetAltTextForPreviews() {
    const designer = useDesigner();
    const userUploads = useUploads();

    return useCallback<GetAltText>(
        (selectedIndex: number) => {
            const imagesFileNameArray: string[] = [];

            if (!designer) {
                return null;
            }

            const canvasImageItems =
                designer &&
                designer.api.design.canvases[selectedIndex]?.items.filter(item => item.itemType === "IMAGE");

            const uploadImageFileData = userUploads.filter(uploadImageData => {
                return canvasImageItems?.some(
                    allImages =>
                        allImages._itemViewModel.model.attributes.printUrl === uploadImageData.attributes.printUrl
                );
            });

            uploadImageFileData.forEach((urlData: Upload) =>
                // @ts-ignore
                imagesFileNameArray.push(urlData.attributes?.fileName)
            );

            if (!uploadImageFileData || !canvasImageItems) {
                return null;
            }

            const altText = imagesFileNameArray.join(", ");
            return altText;
        },
        [designer, userUploads]
    );
}
