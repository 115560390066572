import { fireGenericTrackingEvent } from "@shared/utils/Tracking";

export enum SmartValidationEvents {
    AlertImpression = "Smart Validations Alert Displayed",
    AlertPopoverImpression = "Smart Validations Alert Popover Displayed",
    PanelOpened = "Smart Validations Panel Opened",
    SmartFixApplied = "Smart Validations Fix Applied",
    AlertDismissed = "Smart Validations Alert Dismissed",
    AllAlertsDismissed = "Smart Validations All Alerts Dismissed",
    SmartFixUndone = "Smart Validations Fix Undone"
}
export enum SmartFixActionOrigin {
    Popover = "design panel popover",
    Panel = "smart validations panel"
}

export enum PanelOpenedActionOrigin {
    Popover = "design panel popover",
    Button = "design issues button",
    ButtonPopover = "design issues button popover",
    DesignReview = "design review component"
}

type SmartValidationEventTrackProps = {
    extraData?: any;
};

function mapValidationType(designerValidationType: string): string | undefined {
    switch (designerValidationType) {
        case "overlap":
            return "Overlapping with another object";
        case "outsideBounds":
            return "Outside the Bleed line";
        case "outsideMargins":
            return "Outside the Safety Area";
        default:
            return undefined;
    }
}

export function trackSmartValidationEvent(event: SmartValidationEvents, extras: SmartValidationEventTrackProps = {}) {
    const { extraData } = extras;
    const postProcessedData = extraData
        ? {
              ...extraData,
              validationType: mapValidationType(extraData.validationType)
          }
        : undefined;
    fireGenericTrackingEvent({
        // events bucket name
        event: "Studio Smart Validations",
        // event name
        eventDetail: event,
        extraData: () => postProcessedData
    });
}

let wasSmartValidationAlertImpressionTracked = false;
export function trackSmartValidationAlertImpression() {
    if (wasSmartValidationAlertImpressionTracked) return;
    trackSmartValidationEvent(SmartValidationEvents.AlertImpression);
    wasSmartValidationAlertImpressionTracked = true;
}

let wasSmartValidationAlertPopoverImpressionTracked = false;
export function trackSmartValidationAlertPopoverImpression() {
    if (wasSmartValidationAlertPopoverImpressionTracked) return;
    trackSmartValidationEvent(SmartValidationEvents.AlertPopoverImpression);
    wasSmartValidationAlertPopoverImpressionTracked = true;
}
