import qs from "qs";
import { tryFetch } from "@shared/utils/Network";

const URL = `https://print.prepress.cimpress.io`;
const entityCode = 102;

const PREPRESS_DELAY = 1000;

interface PrepressResponseCreating {
    ResultUrl: string;
    Status: "Creating";
}

interface PrepressResponseCompleted {
    ResultUrl: string;
    Status: "Completed";
    Output: string;
}

interface PrepressResponseFailed {
    ResultUrl: string;
    Status: "Failed";
    Exception: { Message: string };
}

export interface FilePdfResponse {
    PdfInfo: any;
    Previews: [];
    PrintFileUrl: any;
    IsPdfCorrupt: boolean;
    IsPdfEncrypted: boolean;
    AreUnembeddedFontsPresent: boolean;
    FontsEmbedded: boolean;
    AreNonCmykElementsPresent: boolean;
    AreFontsBelowMinimumSize: boolean;
}

type PrepressResponse = PrepressResponseCreating | PrepressResponseCompleted | PrepressResponseFailed;

const delay = (milliseconds: number | undefined) => {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
};

const requestJSON = <T>(url: any): Promise<T> => {
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                From: "studio"
            }
        },
        moduleFunction: "prepressClient:filePrep",
        friendlyDescription: "process using file prep",
        entityCode,
        retryCount: 0
    });
};

const waitForJob = (url: any): Promise<PrepressResponseCompleted> =>
    requestJSON<PrepressResponse>(url).then(response => {
        if (response.Status === "Completed") {
            return response;
        }
        if (response.Status === "Failed") {
            if (response.Exception && response.Exception.Message.includes("NotFound")) {
                // throw new FilePrepUploadRetrievalFailureError();
                throw new Error("FilePrep Exception Encountered");
            }
            const errorMessage = `Prepress failure: ${response.Exception && response.Exception.Message}`;
            throw Error(errorMessage);
        }
        return delay(PREPRESS_DELAY).then(() => waitForJob(url));
    });

const makePollingRequest = async (
    url: string,
    authToken: any,
    data: { fileUrl: any; pageNumber: any; parametersUrl: string }
): Promise<PrepressResponseCompleted> => {
    const query = qs.stringify({ asynchronous: true, noRedirect: true, withoutRetry: true });
    const response = await tryFetch({
        url: `${url}?${query}`,
        options: {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
                From: "studio"
            },
            body: JSON.stringify(data)
        },
        moduleFunction: "prepressClient:filePrep",
        friendlyDescription: "process using file prep",
        entityCode,
        retryCount: 0
    });
    return waitForJob(response.ResultUrl);
    // return tryFetch(`${url}?${query}`, token, data)
    //     .then(response => waitForJob(response.ResultUrl));
};

export const getFilePrep = ({
    authToken,
    fileUrl,
    pageNumber
}: {
    authToken: string;
    fileUrl: string;
    pageNumber: number | null;
}): Promise<FilePdfResponse> => {
    const data = {
        fileUrl,
        pageNumber,
        parametersUrl:
            "https://uploads.documents.cimpress.io/v1/uploads/021b90c6-0cf3-4c84-a26a-168f86312f96~110?tenant=designtechprod"
    };

    const url = `${URL}/v2/file-prep`;

    return makePollingRequest(url, authToken, data).then(response => fetch(response.Output).then(data => data.json()));
};
