import classNames from "classnames";
import React from "react";
import { SelectableColor, ColorSwatch } from "@shared/features/ColorPicker";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { useStockImages } from "@design-stack-vista/image-library-react";
import { filterColors } from "./ImageSearchFilterColors";

const colors = filterColors.map(color => ({ value: color.name, cssBackground: color.hex }));

interface Props {
    performSearchForFilter: (filterOptions: string) => void;
}

export function ImagesSearchColorFilter({ performSearchForFilter }: Props) {
    const { currentFilterOptions } = useStockImages();
    const { trackEvent } = useTrackEvents();

    const onSelectFilterColor = (color: SelectableColor) => {
        const filter = (currentFilterOptions?.length && JSON.parse(currentFilterOptions)) || {};
        if (filter?.color === color.value) {
            // if clicking on current selected -- unselect
            trackEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_FILTER_SELECT_COLOR,
                extraData: () => ({
                    deselectedColor: color.value
                })
            });
            delete filter.color;
        } else {
            trackEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_FILTER_SELECT_COLOR,
                extraData: () => ({
                    selectedColor: color.value
                })
            });
            const hexColor = color.value;
            filter.color = hexColor;
        }

        if (Object.keys(filter).length !== 0) {
            performSearchForFilter(JSON.stringify(filter));
        } else {
            performSearchForFilter("");
        }
    };

    return (
        <>
            <div className={classNames("color_swatches")}>
                {colors.map(color => {
                    const selected =
                        !!currentFilterOptions &&
                        currentFilterOptions?.length > 0 &&
                        color?.value === JSON.parse(currentFilterOptions)?.color;
                    return (
                        <ColorSwatch
                            key={color.value}
                            color={color}
                            isSelected={selected}
                            toggleColor={onSelectFilterColor}
                            className={"image-filter-color-swatch"}
                        />
                    );
                })}
            </div>
        </>
    );
}

ImagesSearchColorFilter.displayName = "ImagesSearchColorFilter";
