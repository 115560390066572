import { defineMessages } from "@vp/i18n-helper";

export const QRCodeMessages = defineMessages({
    panelTitle: {
        id: "easel.components.qrcodes.qrcodePanel.title",
        defaultMessage: "QR Code",
        description: {
            note: "Add QR Code Panel Title"
        }
    },
    editSheetTitle: {
        id: "easel.components.qrcodes.qrcodeSheet.title",
        defaultMessage: "QR Code",
        description: {
            note: "Edit QR Code Panel Title"
        }
    },
    editQrCodeSheetTitle: {
        id: "easel.components.qrcodes.qrcodeSheet.editQrCodeSheetTitle",
        defaultMessage: "Edit QR Code",
        description: {
            note: "Edit QR Code Panel Title"
        }
    },
    buttonTitle: {
        id: "easel.components.qrcodes.qrcodePanel.buttonTitle",
        defaultMessage: "Add QR Code",
        description: {
            note: "Add QR Code button text"
        }
    },
    addQRInstructions: {
        id: "easel.components.qrcodes.qrcodePanel.addQRInstructions",
        defaultMessage: "Enter a valid URL and Click the add button.",
        description: {
            note: "Instructions for adding a QR code"
        }
    },
    editQRInstructions: {
        id: "easel.components.qrcodes.qrcodePanel.editQRInstructions",
        defaultMessage: "Update the link for your QR code below.",
        description: {
            note: "Instructions for editing a QR code"
        }
    },
    url: {
        id: "easel.components.qrcodes.qrcodeForm.url",
        defaultMessage: "URL",
        description: {
            note: "label for the URL field"
        }
    },
    example: {
        id: "easel.components.qrcodes.qrcodeForm.example",
        defaultMessage: "https://www.example.com/",
        description: {
            note: "Example input"
        }
    },
    exampleForQrCode: {
        id: "easel.components.qrcodes.qrcodeForm.exampleForQrCode",
        defaultMessage: "QR code URL",
        description: {
            note: "Example input"
        }
    },
    required: {
        id: "easel.components.qrcodes.qrcodeForm.required",
        defaultMessage: "(Required)",
        description: {
            note: "additional message for URL field to indicate it is required"
        }
    },
    optional: {
        id: "easel.components.qrcodes.qrcodeDialog.optional",
        defaultMessage: "(Optional)",
        description: {
            note: "additional message for Label field to indicate it is optional"
        }
    },
    invalidURL: {
        id: "easel.components.qrcodes.qrcodeDialog.invalidURL",
        defaultMessage: "A valid URL is required",
        description: {
            note: "error message when an invalid URL is entered for the QR code"
        }
    },
    edit: {
        id: "easel.components.qrcodes.qrcodeEditButton.text",
        defaultMessage: "Edit",
        description: {
            note: "text on the edit button to edit a QR Code"
        }
    },
    cancel: {
        id: "easel.components.qrcodes.qrcodeCancelButton.text",
        defaultMessage: "Cancel",
        description: {
            note: "text on the cancel button on the QR Code Edit Dialog"
        }
    },
    done: {
        id: "easel.components.qrcodes.qrcodeDoneButton.text",
        defaultMessage: "Done",
        description: {
            note: "text on the done button on the QR Code Edit Dialog"
        }
    },
    dialogHeader: {
        id: "easel.components.qrcodes.qrCodeEditor.header",
        defaultMessage: "Edit your QR Code:",
        description: {
            note: "header on the edit QR Code dialog"
        }
    },
    dialogAriaLable: {
        id: "easel.components.qrcodes.qrCodeEditor.dialogAriaLable",
        defaultMessage: "Edit your QR Code dialog",
        description: {
            note: "Aria lable for edit QR Code dialog"
        }
    },
    ariaCloseButton: {
        id: "easel.components.qrCodes.qrCodeEditor.ariaCloseButton",
        defaultMessage: "Close QR Code Edit dialog"
    }
});
