import React, { forwardRef, Ref } from "react";
import { EaselContentsDesktop } from "@five/components/StudioLayout/EaselContentsDesktop";
import { ToastContainer } from "@five/components/Toasts/ToastContainer";
import "./XeroxFloatingToolsContainer.module.scss";

// This is just a wrapper around easelContentsDesktop but the styles included here override the contextual toolbar layout
export const XeroxFloatingToolsContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    return (
        <>
            <EaselContentsDesktop ref={ref} />;
            <ToastContainer />
        </>
    );
});

XeroxFloatingToolsContainer.displayName = "XeroxFloatingToolsContainer";
