import React, {
    useContext,
    createContext,
    useMemo,
    FC,
    ReactNode,
    useEffect,
    useState,
    useReducer,
    useCallback,
    useRef
} from "react";
import cloneDeep from "lodash/cloneDeep";
import { useStudioFlexibility } from "@shared/features/Flexibility";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import {
    getGalleryName,
    getGalleryFilters,
    buildCategoryFilterOptions,
    getGalleryContent,
    TEMPLATES_PER_PAGE,
    type AttributeFilter,
    type CategoryFilter,
    type CategoryFilterWithSort,
    type ColorSwatch,
    type Design,
    type Filter
} from "@shared/utils/Gallery";
import {
    ChangeTemplateFilterAction,
    changeTemplateFiltersReducer,
    ChangeTemplateFiltersState,
    defaultChangeTemplateFiltersState
} from "./Filters/changeTemplateFiltersReducer";
import {
    getSupportedAttributeFilters,
    getAttributeSelections,
    getCategorySelections,
    isAttributeFilter,
    isCategoryFilter
} from "./Filters/filterUtilities";
import { STARTING_PAGE_NUMBER } from "./Pagination/templatePaginationUtilities";

interface ContextData {
    designs: Design[];
    allDesigns: Design[];
    defaultDesigns: Design[];
    numberOfPages: number;
    hasMore: boolean;
    dispatchPagination: (action: TemplatePaginationAction) => void;
    currentPage: number;
    filters: (CategoryFilter | AttributeFilter)[];
    expandedCollapsibles: { [collapsibleId: string]: boolean };
    setDefaultExpandedCollapsibles: () => void;
    updateExpandedCollapsibles: (collapsibleId: string, collapsed: boolean) => void;
    changeTemplateSearchTerm: string;
    updateSearchTerm: (searchTerm: string) => void;
    dispatchTemplateFilters: (action: ChangeTemplateFilterAction) => void;
    templateFilters: ChangeTemplateFiltersState;
    results?: number;
    loading: boolean;
    currentDesign: Design | undefined;
    currentTemplateColorSwatch: ColorSwatch | undefined;
    setCurrentDesign: (value: React.SetStateAction<Design>) => void;
    setCurrentTemplateColorSwatch: (value: React.SetStateAction<ColorSwatch | undefined>) => void;
}

interface GalleryProviderProps {
    children: ReactNode;
    isLive: boolean;
}

interface PrevPageAction {
    type: TemplatePaginationActionTypes.PREV_PAGE;
}

interface NextPageAction {
    type: TemplatePaginationActionTypes.NEXT_PAGE;
}

interface GoToPageAction {
    type: TemplatePaginationActionTypes.GO_TO_PAGE;
    pageNumber: number;
}

interface InitializeAction {
    type: TemplatePaginationActionTypes.INITIALIZE;
}

interface AddDesignsAction {
    type: TemplatePaginationActionTypes.ADD_DESIGNS;
    designs: Design[];
    numFound: number;
}

type TemplatePaginationAction = PrevPageAction | NextPageAction | GoToPageAction | InitializeAction | AddDesignsAction;

interface TemplatePaginationState {
    currentPage: number;
    offset: number;
    numFound: number;
    numberOfPages: number;
    paginatedDesigns: Record<string, Design[]>;
}
const defaultTemplatePaginationState: TemplatePaginationState = {
    currentPage: 1,
    numberOfPages: 1,
    numFound: 0,
    offset: 0,
    paginatedDesigns: {}
};

export enum TemplatePaginationActionTypes {
    PREV_PAGE = "previous_page",
    NEXT_PAGE = "next_page",
    GO_TO_PAGE = "go_to_page",
    INITIALIZE = "initialize_pages",
    ADD_DESIGNS = "add_designs"
}

function reducer(state: TemplatePaginationState, action: TemplatePaginationAction) {
    switch (action.type) {
        case TemplatePaginationActionTypes.PREV_PAGE:
            return {
                ...state,
                currentPage: state.currentPage - 1,
                offset: (state.currentPage - 2) * TEMPLATES_PER_PAGE
            };
        case TemplatePaginationActionTypes.NEXT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage + 1,
                offset: state.currentPage * TEMPLATES_PER_PAGE
            };
        case TemplatePaginationActionTypes.GO_TO_PAGE:
            return {
                ...state,
                currentPage: action.pageNumber,
                offset: action.pageNumber === STARTING_PAGE_NUMBER ? 0 : (action.pageNumber - 1) * TEMPLATES_PER_PAGE
            };
        case TemplatePaginationActionTypes.ADD_DESIGNS: {
            const numberOfPages = Math.ceil(action.numFound / TEMPLATES_PER_PAGE);
            const currentPage = numberOfPages < state.currentPage ? STARTING_PAGE_NUMBER : state.currentPage;
            return {
                ...state,
                numFound: action.numFound,
                numberOfPages,
                currentPage,
                paginatedDesigns: { ...state.paginatedDesigns, [state.currentPage]: action.designs }
            };
        }
        case TemplatePaginationActionTypes.INITIALIZE: {
            return defaultTemplatePaginationState;
        }
        default:
            return state;
    }
}

const Context = createContext<ContextData | undefined>(undefined);

export const useGallery = () => {
    const result = useContext(Context);

    if (!result) {
        throw Error("Please call this within a GalleryProvider");
    }
    return result;
};

const emptyList: any[] = [];

// I think this could provide gallery data.  Possibly a prop could provide filtering
// Change Template would filter to non-product filters
// Change Template color would filter to only color filter
export const GalleryProvider: FC<GalleryProviderProps> = ({ children, isLive }: GalleryProviderProps) => {
    const { Provider } = Context;
    const productKey = useAppSelector(state => state.productKey);
    const mpvId = useAppSelector(state => state.mpvId);
    const locale = useAppSelector(state => state.locale);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);

    const [galleryName, setGalleryName] = useState("");
    const abortController = useRef(new AbortController());
    const [changeTemplateSearchTerm, setChangeTemplateSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<number>();
    const [filters, setFilters] = useState<(CategoryFilter | AttributeFilter)[]>([]);
    const [expandedCollapsibles, setExpandedCollapsibles] = useState<{ [collapsibleId: string]: boolean }>({});
    const [defaultTemplates, setDefaultTemplates] = useState<Design[]>([]);
    const [{ currentPage, offset, numberOfPages, paginatedDesigns, numFound }, dispatchPagination] = useReducer(
        reducer,
        defaultTemplatePaginationState
    );
    const designs = paginatedDesigns[currentPage] ?? emptyList;
    const allDesigns = Object.values(paginatedDesigns).flat();
    const hasMore = allDesigns.length < numFound;

    const [templateFilters, dispatchTemplateFilters] = useReducer(
        changeTemplateFiltersReducer,
        defaultChangeTemplateFiltersState
    );
    const [currentDesign, setCurrentDesign] = useState<Design>();
    const [currentTemplateColorSwatch, setCurrentTemplateColorSwatch] = useState<ColorSwatch>();
    const template = useAppSelector(state => state.template);
    const { isMileStone1Enabled } = useStudioFlexibility();

    const showSortedCurrentTemplate = isMileStone1Enabled && template;

    useEffect(() => {
        if (isLive && galleryName)
            getGalleryContent({
                galleryName,
                locale,
                mpvId,
                studioSelectedProductOptions,
                offset,
                abortSignal: abortController.current.signal
            }).then(defaultContents => {
                setDefaultTemplates(defaultContents.content);
            });
    }, [galleryName, isLive, locale, mpvId, offset, studioSelectedProductOptions]);

    useEffect(() => {
        dispatchPagination({
            type: TemplatePaginationActionTypes.INITIALIZE
        });
    }, [templateFilters, changeTemplateSearchTerm]);

    useEffect(() => {
        if (isLive && productKey && mpvId && locale) {
            (async () => {
                const galleryName = await getGalleryName(productKey, mpvId, locale);
                setGalleryName(galleryName ?? "");
            })();
        }
    }, [isLive, locale, mpvId, productKey]);

    // Basic fetch gallery content effect
    useEffect(() => {
        if (isLive && galleryName.length > 0) {
            if (loading) {
                abortController.current.abort("overridding request with new gallery information");
            }
            setLoading(true);

            (async () => {
                const categories = getCategorySelections(
                    templateFilters,
                    filters.filter(isCategoryFilter) as CategoryFilter[]
                );
                const attributes = getAttributeSelections(
                    templateFilters,
                    filters.filter(isAttributeFilter) as AttributeFilter[]
                );
                try {
                    const galleryContents = await getGalleryContent({
                        galleryName,
                        locale,
                        mpvId,
                        studioSelectedProductOptions,
                        offset,
                        searchTerm: changeTemplateSearchTerm,
                        categories,
                        attributes,
                        abortSignal: abortController.current.signal
                    });
                    if (galleryContents.numFound) {
                        dispatchPagination({
                            type: TemplatePaginationActionTypes.ADD_DESIGNS,
                            designs: galleryContents.content,
                            numFound: galleryContents.numFound
                        });
                    } else {
                        fireDesignToolTrackingEvent({
                            eventDetail: STUDIO_TRACKING_EVENTS.NO_TEMPLATE_RESULTS,
                            label: "No results found in change template",
                            extraData: () => ({
                                templateFilters: [...categories, ...attributes],
                                templateSearch: changeTemplateSearchTerm
                            })
                        });

                        // without this, we call default terms when filters have removed all content
                        if (changeTemplateSearchTerm || templateFilters) {
                            setResults(0);
                            return;
                        }
                    }
                    setResults(galleryContents.numFound);
                } catch (e) {
                    /* no-op for aborts */
                } finally {
                    setLoading(false);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        galleryName,
        isLive,
        locale,
        mpvId,
        offset,
        studioSelectedProductOptions,
        changeTemplateSearchTerm,
        templateFilters
    ]);

    useEffect(() => {
        if (isLive && galleryName.length > 0) {
            (async () => {
                const galleryFilters = await getGalleryFilters(galleryName, locale);
                const studioAttributeFilters = getSupportedAttributeFilters(galleryFilters.attributeFilters);

                // We only want to use categoryFilters, as other filters will change the product options
                const categoryFiltersWithOptions = await buildCategoryFilterOptions(
                    galleryFilters.categoryFilters,
                    locale.toLowerCase()
                );

                // add sort order to category filters
                const categoryFiltersWithSort: CategoryFilterWithSort[] = categoryFiltersWithOptions.map(
                    (categoryFilter: CategoryFilter): CategoryFilterWithSort => ({
                        ...categoryFilter,
                        sortOrder:
                            galleryFilters.filters.find((o: Filter) => o.name === categoryFilter.name)?.sortOrder || 99
                    })
                );

                // sort both category and studio attribute filters by sort order
                const sortedFilters = [...categoryFiltersWithSort, ...studioAttributeFilters].sort(
                    (a, b) => a.sortOrder - b.sortOrder
                );

                setFilters(sortedFilters);
            })();
        }
    }, [galleryName, isLive, locale]);

    // find a matching design based on existing template on the canvas
    const getMatchingDesign = useCallback(
        (designs: Design[]) => {
            if (template) {
                let colorSwatch: ColorSwatch | undefined;
                // finds the selected design and its selected color swatch
                const selectedDesignIndex = designs.findIndex(design => {
                    const { previewInfo, colorSwatches, selectedColorSwatchDesignId } = design;
                    // match the template token incase no swatch color present
                    if (!selectedColorSwatchDesignId) {
                        return previewInfo.templateToken === template;
                    }
                    if (template.includes(previewInfo.dps)) {
                        colorSwatch = colorSwatches.find(
                            ({ previewInfo }: ColorSwatch) => previewInfo.templateToken === template
                        );
                    }
                    return Boolean(colorSwatch);
                });
                const selectedDesign = designs[selectedDesignIndex];
                if (selectedDesign) {
                    return { design: selectedDesign, colorSwatch, designIndex: selectedDesignIndex };
                }
            }
            return null;
        },
        [template]
    );

    // fetch all designs and finds the current selected design
    useEffect(() => {
        if (
            showSortedCurrentTemplate &&
            results &&
            currentPage === 1 &&
            (!currentDesign || !template.includes(currentDesign.previewInfo.dps))
        ) {
            (async () => {
                setLoading(true);
                try {
                    const allDesigns = await getGalleryContent({
                        galleryName,
                        locale,
                        mpvId,
                        studioSelectedProductOptions,
                        offset,
                        limit: results
                    });

                    const matchingDesign = getMatchingDesign(allDesigns.content);

                    if (matchingDesign) {
                        const { design, colorSwatch } = matchingDesign;
                        setCurrentDesign(design);
                        setCurrentTemplateColorSwatch(colorSwatch);
                    }
                } catch (e) {
                    /* no-op for aborts */
                }
                setLoading(false);
            })();
        }
    }, [
        template,
        designs,
        showSortedCurrentTemplate,
        currentPage,
        currentDesign,
        currentTemplateColorSwatch,
        results,
        galleryName,
        locale,
        mpvId,
        studioSelectedProductOptions,
        offset,
        getMatchingDesign
    ]);

    useEffect(() => {
        if (showSortedCurrentTemplate) {
            dispatchPagination({
                type: TemplatePaginationActionTypes.GO_TO_PAGE,
                pageNumber: 1
            });
        }
    }, [currentDesign, showSortedCurrentTemplate]);

    // moves the selected design to first position incase of first page
    const sortedDesigns = useMemo(() => {
        if (showSortedCurrentTemplate && currentDesign) {
            let updateDesigns: Design[] = cloneDeep(designs);
            const matchingDesign = getMatchingDesign(designs);
            // removing duplicate entry on the same page
            if (matchingDesign) {
                const { designIndex } = matchingDesign;
                updateDesigns.splice(designIndex, 1); // remove selected design from existing index
            }
            if (currentPage === 1) {
                updateDesigns = [currentDesign, ...updateDesigns];
            }
            return updateDesigns;
        }
        return designs;
    }, [designs, showSortedCurrentTemplate, currentDesign, currentPage, getMatchingDesign]);

    const sortedAllDesigns = useMemo(() => {
        if (showSortedCurrentTemplate && currentDesign) {
            let updateDesigns: Design[] = cloneDeep(allDesigns);
            const matchingDesign = getMatchingDesign(allDesigns);
            // removing duplicate entry on the same page
            if (matchingDesign) {
                const { designIndex } = matchingDesign;
                updateDesigns.splice(designIndex, 1); // remove selected design from existing index
            }
            if (currentPage === 1) {
                updateDesigns = [currentDesign, ...updateDesigns];
            }
            return updateDesigns;
        }
        return allDesigns;
    }, [allDesigns, currentDesign, currentPage, getMatchingDesign, showSortedCurrentTemplate]);

    const updateExpandedCollapsibles = useCallback(
        (filterId: string, expanded: boolean) => {
            if (isLive) {
                const newExpandedCollapsibles = { ...expandedCollapsibles };
                newExpandedCollapsibles[filterId] = expanded;
                setExpandedCollapsibles(newExpandedCollapsibles);
            }
        },
        [expandedCollapsibles, isLive]
    );

    const updateSearchTerm = useCallback(
        (searchTerm: string) => {
            if (isLive) {
                setChangeTemplateSearchTerm(searchTerm);
            }
        },
        [isLive]
    );

    const setDefaultExpandedCollapsibles = useCallback(() => {
        if (isLive) {
            const newExpandedCollapsibles = {};
            filters.forEach(filter => {
                newExpandedCollapsibles[filter.name] = !filter.collapsed;
            });
            setExpandedCollapsibles(newExpandedCollapsibles);
        }
    }, [filters, isLive]);

    const contextObject = useMemo(() => {
        return {
            designs: sortedDesigns,
            allDesigns: sortedAllDesigns,
            defaultDesigns: defaultTemplates,
            filters,
            expandedCollapsibles,
            setDefaultExpandedCollapsibles,
            updateExpandedCollapsibles,
            numberOfPages,
            currentPage,
            dispatchPagination,
            changeTemplateSearchTerm,
            updateSearchTerm,
            dispatchTemplateFilters,
            templateFilters,
            results,
            loading,
            currentDesign,
            currentTemplateColorSwatch,
            setCurrentDesign,
            setCurrentTemplateColorSwatch,
            hasMore
        };
    }, [
        sortedDesigns,
        sortedAllDesigns,
        defaultTemplates,
        filters,
        expandedCollapsibles,
        setDefaultExpandedCollapsibles,
        updateExpandedCollapsibles,
        numberOfPages,
        currentPage,
        changeTemplateSearchTerm,
        updateSearchTerm,
        templateFilters,
        results,
        loading,
        currentDesign,
        currentTemplateColorSwatch,
        hasMore
    ]);
    return <Provider value={contextObject}>{children}</Provider>;
};

GalleryProvider.displayName = "GalleryProvider";
