import { useEffect, useState } from "react";
import { useDesigner, ProcessType, useActiveCanvas } from "@designer-suite";
import { getProcessType } from "@utilities";

/**
 * A hook that provides the process type for the design
 * @returns {
 *  processType: ProcessType
 * }
 */
export function useProcessType() {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const [processType, setProcessType] = useState(designer && canvas ? getProcessType(designer) : ProcessType.unknown);

    useEffect(() => {
        if (!designer || !canvas) return;
        const canvasProcessType = getProcessType(designer);

        setProcessType(canvasProcessType);
    }, [designer, canvas]);

    return processType;
}
