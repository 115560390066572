import React, { useEffect } from "react";
import { ChangeProductSizeIcon, GenericHeaderButton } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { changeProductSizeMessages } from "./messages";
import { useActiveFlexibilityOptions } from "../ActiveFlexibilityProvider";
import { FlexibilityDesignAttributes } from "../constants";

interface Props {
    className?: string;
}

export const ChangeSizeButton = ({ className }: Props) => {
    const { t } = useTranslationSSR();
    const { setDisplayedDesignAttributeName } = useActiveFlexibilityOptions();

    useEffect(() => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_SIZE_BUTTON_ON_VISIBLE_PRD,
            label: "show change product size button on compatible products"
        } as any);
    }, []);

    const onClickOfHeaderChangeSize = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_PRODUCT_SIZE_HEADER_BUTTON,
            label: "change product size header button"
        } as any);
        setDisplayedDesignAttributeName(FlexibilityDesignAttributes.Size);
    };

    return (
        <GenericHeaderButton
            label={t(changeProductSizeMessages.changeProductSizeButtonTitle.id)}
            icon={<ChangeProductSizeIcon aria-hidden={true} />}
            onClick={onClickOfHeaderChangeSize}
            className={className}
        />
    );
};

ChangeSizeButton.displayName = "ChangeSizeButton";
