export const STRETCH_HANDLE_BAR = {
    MAX_WIDTH: 66,
    MIN_WIDTH: 36,
    MAX_HEIGHT: 66,
    MIN_HEIGHT: 36,
    HORIZONTAL_MAX_WIDTH: 24,
    HORIZONTAL_MIN_WIDTH: 12,
    HORIZONTAL_HEIGHT: 4,
    VERTICAL_WIDTH: 4,
    VERTICAL_MIN_HEIGHT: 12,
    VERTICAL_MAX_HEIGHT: 24
};

export const VALIDATION_DISMISS_DELAY = 500;
