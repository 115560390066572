import React, { PropsWithChildren } from "react";
import classnames from "classnames";
import Skeleton from "react-loading-skeleton";
import { useFloatingSidebarPanelConfiguration } from "@five/components/Redesign/FloatingSidebarPanel/useFloatingSidebarPanelConfiguration";
import { useOpenItemPanel } from "@five/components/Redesign/FloatingSidebar/utils/useOpenItemPanel";
import { useActiveDialog, useFloatingSidebarHighlightShowing } from "@shared/features/ActiveDialog";
import { Box, FlexBox } from "@vp/swan";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";

import * as styles from "./SideBarPanel.module.scss";
import { useSideBarPanelAnimationClassName } from "./useSideBarPanelAnimationClassName";
import { IMAGE_SEARCH_FILTER_PORTAL_ID } from "../../../components/Panels/Images/ImageSearchFiltersPanel";
import { TEMPLATE_SEARCH_FILTER_PORTAL_ID } from "../../../components/Panels/TemplatePanel/TemplateSearchFiltersPanel";

type SideBarPanelProps = {
    className?: string;
};

export const SideBarPanel = ({ className }: PropsWithChildren<SideBarPanelProps>) => {
    const { currentActiveDialog } = useActiveDialog();
    const { dialogTypeComponents } = useFloatingSidebarPanelConfiguration();
    const Component = dialogTypeComponents[currentActiveDialog];

    const animationClassName = useSideBarPanelAnimationClassName(styles);
    const highlightShowing = useFloatingSidebarHighlightShowing();
    useOpenItemPanel();

    return (
        <Box
            data-dcl-prevent-canvas-items-deselection
            className={classnames(animationClassName, className, {
                "floating-sidebar-panel-highlight": highlightShowing
            })}
        >
            <StudioIsInteractiveSwitch>
                {Component}
                <FlexBox flexDirection="column" padding={4}>
                    <Skeleton width={"100%"} height={40} style={{ marginBottom: 10 }} />
                    {[...Array(5)].map((_n, i) => (
                        <Skeleton key={`skeleton-${i}`} width={"100%"} height={30} />
                    ))}
                </FlexBox>
            </StudioIsInteractiveSwitch>
            <div id={IMAGE_SEARCH_FILTER_PORTAL_ID}></div>
            <div id={TEMPLATE_SEARCH_FILTER_PORTAL_ID}></div>
        </Box>
    );
};

SideBarPanel.displayName = "SideBarPanel";
