import React from "react";
import Gallery from "react-photo-gallery";
import classNames from "classnames";
import { FlexBox, Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages, Trans } from "@vp/i18n-helper";
import { Status } from "@design-stack-vista/image-library-react";
import { InfiniteScrollList, LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { renderImage } from "../../../components/Panels/Images/ImageRenderer";
import { useElementsPanel } from "./ElementsPanelProvider";
import * as styles from "./ElementsPanel.module.scss";

const messages = defineMessages({
    noResultsClipart: {
        id: "studio.features.elements.noResultsClipart",
        defaultMessage: `Sorry, we couldn't find any matches for <bold>"[[term]]"</bold>.`,
        description: {
            note: "Message shown when no clipart are found that match search term"
        }
    },
    noResultsClipartDescription: {
        id: "studio.features.elements.noResultsClipartDescription",
        defaultMessage: `Please check your spelling or try searching with a different word.`,
        description: {
            note: "Description shown when no clipart are found that match search term"
        }
    },
    errorClipart: {
        id: "studio.features.elements.errorClipart",
        defaultMessage: `We couldn't load the clipart - please try again.`,
        description: {
            note: "Message shown when there is an error loading clipart"
        }
    }
});

export const ElementsPanelContentClipart = () => {
    const { clipartGalleryItems, clipartStatus, currentElementsPanelSearchTerm } = useElementsPanel();
    const { t } = useTranslationSSR();

    const xeroxFilteredClipartItems = currentElementsPanelSearchTerm
        ? clipartGalleryItems
        : clipartGalleryItems.slice(1, clipartGalleryItems.length);

    return (
        <InfiniteScrollList
            count={xeroxFilteredClipartItems.length}
            id="elements-clipart-gallery"
            className={classNames(styles.elementsGallery, styles.clipartGallery)}
        >
            {clipartStatus === Status.Error && (
                <Typography textAlign="center" fontSize="1" paddingTop={3} paddingX={6} paddingBottom={2}>
                    {t(messages.errorClipart.id)}
                </Typography>
            )}
            {clipartStatus === Status.Loading && (
                <FlexBox flexDirection="column" pt={5} pb={2}>
                    <LoadingSpinner variant={SpinnerVariant.Large} centering />
                </FlexBox>
            )}
            {clipartStatus === Status.Idle &&
                (xeroxFilteredClipartItems.length > 0 ? (
                    <Gallery
                        photos={xeroxFilteredClipartItems}
                        direction={"column"}
                        columns={2}
                        margin={5}
                        renderImage={renderImage}
                    />
                ) : (
                    <>
                        <Typography textAlign="center" fontSize="1">
                            <Trans
                                t={t}
                                i18nKey={messages.noResultsClipart.id}
                                components={{ bold: <strong /> }}
                                values={{ term: currentElementsPanelSearchTerm }}
                            />
                        </Typography>
                        <Typography mt={1} textAlign="center" fontSize="-1" paddingBottom={2}>
                            {t(messages.noResultsClipartDescription.id)}
                        </Typography>
                    </>
                ))}
        </InfiniteScrollList>
    );
};

ElementsPanelContentClipart.displayName = "ElementsPanelContentClipart";
