import { useEffect, useState } from "react";
import { isSingleColorCanvas } from "@utilities";
import { StudioMetadataItem } from "@shared/utils/Metadata";
import type { Designer } from "../../@types/designer";
import type { Page } from "../../@types/page";
import { NP_IMAGE_PREFIX } from "../../../../studioFive/components/Panels/Images/ImagePanelUtils";
import { useDesigner } from "../../designer/DesignerProvider";
import { useDraggableUploadsPanel } from "./DraggableUploadsPanelProvider";
import { uploadStatusTypes } from "./uploadStatusTypes";
import { updatedInitialSize } from "./UploadStrategies";

/**
 * This hook is used to add draggability to a image.
 * It is using some jquery logic to be compatilble with the system used in designer
 * If we were to not use it, we would've had to create whole new dragging and dropping logic
 */

// TODO: Get this from configuration
const DRAGGABLE_SCALE_FACTOR = 1.2;

const getImageData = (page: Page, designer: Designer, maxCanvasSize?: number, studioMetadata?: StudioMetadataItem) => {
    let initialWidth = page.get("initialWidth");
    let initialHeight = page.get("initialHeight");
    if (designer && maxCanvasSize) {
        const newSize = updatedInitialSize(designer, page, maxCanvasSize);
        if (newSize) {
            initialWidth = newSize.width;
            initialHeight = newSize.height;
        }
    }

    // skip designer processing for images but not icons on single color canvas
    const isSegmented = isSingleColorCanvas(designer) && !page.get("requestId")?.startsWith(NP_IMAGE_PREFIX);

    return {
        ...page.attributes,
        initialWidth,
        initialHeight,
        studioMetadata,
        isSegmented
    };
};

const useAddDraggable = (
    imageRef: React.RefObject<HTMLElement>,
    page: Page,
    isDraggable: boolean,
    studioMetadata?: StudioMetadataItem,
    onStart?: (event: any) => void,
    onStop?: (event: any) => void,
    maxCanvasSize?: number
) => {
    const designer = useDesigner();
    const [draggable, setDraggable] = useState<Draggable | undefined>();
    const [overDroppable, setOverDroppable] = useState<boolean>();
    const { current } = imageRef;
    const isOnDraggablePanel = useDraggableUploadsPanel();

    useEffect(() => {
        if (!isDraggable || !isOnDraggablePanel) {
            return;
        }
        if (!designer && !current) {
            return;
        }

        // Ignoring the next error.  This is due to the $image being a jquery object
        // @ts-ignore
        const addDraggable = $image => {
            if (!designer) {
                return;
            }

            const {
                utilities: { Draggable }
            } = designer;
            const newDraggable = new Draggable($image.parent(), {
                data: {
                    image: getImageData(page, designer, maxCanvasSize, studioMetadata)
                },
                start: onStart,
                stop: onStop,
                helper() {
                    const $clone = $image.clone().addClass("dcl-dragging-repository-image");

                    const width = $image.width() * DRAGGABLE_SCALE_FACTOR;
                    const height = $image.height() * DRAGGABLE_SCALE_FACTOR;

                    $clone.css({
                        left: 0,
                        top: 0,
                        width: `${width}px`,
                        height: `${height}px`
                    });

                    return $clone;
                },
                onOverActiveDropTarget(droppable: Droppable) {
                    if (
                        designer.documentRepository
                            .getAllItemViewModelsCollection()
                            .has(droppable.$el.data().viewModelId)
                    ) {
                        setOverDroppable(true);
                    }
                },
                onLeaveActiveDropTarget() {
                    setOverDroppable(false);
                }
            });
            setDraggable(newDraggable);
        };

        const onChangeStatus = (pageModel: Page) => {
            if (pageModel.get("status") >= uploadStatusTypes.postProcessingComplete) {
                // Ignoring this because we don't have types for jquery logic
                // @ts-ignore
                const $image = window.$(current);
                addDraggable($image);
            }
        };

        onChangeStatus(page);

        page.on("change:status", onChangeStatus);
        // eslint-disable-next-line consistent-return
        return () => {
            page.off("change:status", onChangeStatus);
        };
    }, [page, designer, current, isDraggable, isOnDraggablePanel, onStop, onStart, maxCanvasSize, studioMetadata]);

    useEffect(() => {
        if (draggable && draggable.$helper) {
            if (overDroppable) {
                draggable.$helper.addClass("dcl-dragging-repository-image--over-placeholder");
            } else {
                draggable.$helper.removeClass("dcl-dragging-repository-image--over-placeholder");
            }
        }
    }, [draggable, overDroppable]);
};

useAddDraggable.displayName = "useAddDraggable";
export default useAddDraggable;
