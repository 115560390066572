import React from "react";
import classNames from "classnames";
import { Button, Icon } from "@vp/swan";
import * as styles from "./VerticalCanvasCarouselButton.module.scss";

type ButtonProps = {
    iconType: React.ComponentProps<typeof Icon>["iconType"];
    label: string;
    onClick: () => void;
    className: string;
    isDisabled?: boolean;
};

export const VerticalCanvasCarouselButton = (props: ButtonProps) => {
    const { iconType, onClick, label, className, isDisabled = false } = props;
    return (
        <div className={classNames(className, styles.button)}>
            <Button
                tabIndex={-1}
                aria-label={label}
                buttonShape="round"
                size="mini"
                onClick={onClick}
                disabled={isDisabled}
            >
                <Icon iconType={iconType} />
            </Button>
        </div>
    );
};

VerticalCanvasCarouselButton.displayName = "VerticalCarouselButton";
