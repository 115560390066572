import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useDesigner } from "@designer-suite";
import { CellOverlay, OffsetBar, SubselectionType, TableOverlayControlsProvider } from "@shared/features/Tables";
import { getTableFontSize, updateTableItemData } from "@utilities";
import DragOverlay from "./DragOverlay";
import { getColumnWidths, getRowHeights } from "./tableUtilities";

import "./TableOverlay.scss";

// ratio of points to mm
const PT2MM = 0.35278;

export default function TableOverlay({ item, zoomFactor }: { item: TableItem; zoomFactor: number }) {
    const designer = useDesigner();
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        if (!designer) {
            return;
        }

        const startDragging = () => setDragging(true);
        const stopDragging = () => setDragging(false);

        designer.eventBus.on(designer.eventBus.events.dragStart, startDragging);
        designer.eventBus.on(designer.eventBus.events.dragStop, stopDragging);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(designer.eventBus.events.dragStart, startDragging);
            designer.eventBus.off(designer.eventBus.events.dragStop, stopDragging);
        };
    }, [designer]);

    const {
        pxDimensions: tableDimensionsInPx,
        data: { columns }
    } = item;

    const relativeRowHeights = getRowHeights(item);
    const relativeColumnWidths = getColumnWidths(columns);
    const fontSize = getTableFontSize(item.data);
    const fontSizeInPx = fontSize ? parseFloat(fontSize) * PT2MM * zoomFactor : undefined;

    const onColumnResize = (columnIndex: number, percentDelta: number): void => {
        if (!designer) {
            return;
        }

        designer.api.design.updateItem<TableItem>(item.id, mutableItem => {
            const newData = cloneDeep(item.data);
            const deltaInMm = parseFloat(newData.columns[columnIndex].width as string) * percentDelta;

            newData.columns[columnIndex].width = `${
                parseFloat(newData.columns[columnIndex].width as string) + deltaInMm
            }mm`;
            newData.columns[columnIndex + 1].width = `${
                parseFloat(newData.columns[columnIndex + 1].width as string) - deltaInMm
            }mm`;

            updateTableItemData(mutableItem, newData);
        });
    };

    return (
        <TableOverlayControlsProvider
            relativeRowHeights={relativeRowHeights}
            relativeColumnWidths={relativeColumnWidths}
            fontSizeInPx={fontSizeInPx}
            tableDimensionsInPx={tableDimensionsInPx}
            onColumnResize={onColumnResize}
        >
            <div className="table-overlay" data-block-outside-panel-click-to-close>
                <DragOverlay style={{ pointerEvents: "none" }} />
                {!dragging && (
                    <>
                        <OffsetBar offset="left" type={SubselectionType.Row} />
                        <OffsetBar offset="top" type={SubselectionType.Column} />
                        <CellOverlay />
                    </>
                )}
            </div>
        </TableOverlayControlsProvider>
    );
}

TableOverlay.displayName = "TableOverlay";
