import React, { useState, useEffect } from "react";
import { HistoryTools } from "@shared/features/DesignTools";
import useUndoRedo from "./useUndoRedo";

export const UndoRedoButtonBarDesktop = () => {
    const { undo, redo, undoDisabled, redoDisabled } = useUndoRedo();
    const [inProp, setInProp] = useState(false);
    const [redoInProp, setRedoInProp] = useState(false);

    useEffect(() => {
        setInProp(!(undoDisabled && redoDisabled));
    }, [undoDisabled, redoDisabled]);

    useEffect(() => {
        setRedoInProp(!redoDisabled);
    }, [redoDisabled]);

    return (
        <HistoryTools
            undo={undo}
            redo={redo}
            canRedo={!redoDisabled}
            canUndo={!undoDisabled}
            transitionIn={inProp}
            transitionRedoIn={redoInProp}
        />
    );
};

UndoRedoButtonBarDesktop.displayName = "UndoRedoButtonBarDesktop";
