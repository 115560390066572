import React, { ReactNode } from "react";
import { Typography } from "@vp/swan";

type SidebarButtonLabelProps = {
    children: ReactNode | ReactNode[];
};

export const SidebarButtonLabel = (props: SidebarButtonLabelProps) => {
    const { children } = props;
    return <Typography fontSize="-1">{children}</Typography>;
};

SidebarButtonLabel.displayName = "SidebarButtonLabel";
