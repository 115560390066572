import {
    Button,
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogButtons,
    LegacyModalDialogCloseButton,
    LegacyModalDialogContent,
    LegacyModalDialogHeader
} from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import classNames from "classnames";
import React, { ReactNode } from "react";

const messages = defineMessages({
    header: {
        id: "easel.ui.designErrorModal.header",
        defaultMessage: "Design Error"
    },
    errorModalAriaLabel: {
        id: "easel.ui.designErrorModal.errorModalAriaLabel",
        defaultMessage: "Design error modal",
        description: {
            note: "Text message is aria lable of Error modal aria label"
        }
    },
    body: {
        id: "easel.ui.designErrorModal.body",
        defaultMessage: "You have an error with your design.",
        description: {
            note: "Default error explaining that there is an issue with the user's design"
        }
    },
    cta: {
        id: "easel.ui.designErrorModal.cta",
        defaultMessage: "Go back & edit"
    },
    ctaMobile: {
        id: "easel.ui.designErrorModal.mobile.cta",
        defaultMessage: "Go back"
    },
    ariaCloseButton: {
        id: "easel.ui.designErrorModal.ariaCloseButton",
        defaultMessage: "Close design error dialog"
    },
    seeFinishOptions: {
        id: "easel.ui.designErrorModal.seeFinishOptions",
        defaultMessage: "Choose finish options"
    }
});

type Props = {
    buttonText?: string;
    className?: string;
    headerText: string | ReactNode;
    bodyText: string | ReactNode;

    openPremiumFinishModal: any;
    onClickClose: () => void;

    isSmall: boolean;
    showClose: boolean;
    hasPremiumFinishesCurrentCanvas: boolean;
    isOpen: boolean;
};

export function DesignErrorModalDialog({
    headerText,
    buttonText,
    className,
    bodyText,
    onClickClose,
    openPremiumFinishModal,
    isSmall,
    showClose,
    hasPremiumFinishesCurrentCanvas,
    isOpen
}: Props) {
    const { t } = useTranslationSSR();
    return (
        <LegacyModalDialog
            isOpen={isOpen}
            variant="standard"
            onRequestDismiss={onClickClose}
            className={classNames({ className })}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent
                style={{ maxWidth: isSmall ? "95vw" : "35vw" }}
                className="modal-dialog-content"
                aria-label={t(messages.errorModalAriaLabel.id)}
            >
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.ariaCloseButton.id)} />
                <LegacyModalDialogHeader data-dcl-prevent-canvas-items-deselection>
                    {headerText || t(messages.header.id)}
                </LegacyModalDialogHeader>
                <LegacyModalDialogBody data-dcl-prevent-canvas-items-deselection>
                    {bodyText || t(messages.body.id)}
                </LegacyModalDialogBody>
                <LegacyModalDialogButtons>
                    {showClose && (
                        <Button width={isSmall ? "full-width" : "standard"} onClick={onClickClose}>
                            {buttonText || (!isSmall ? t(messages.cta.id) : t(messages.ctaMobile.id))}
                        </Button>
                    )}
                    {hasPremiumFinishesCurrentCanvas && (
                        <Button
                            skin="primary"
                            width={isSmall ? "full-width" : "standard"}
                            onClick={openPremiumFinishModal}
                        >
                            {t(messages.seeFinishOptions.id)}
                        </Button>
                    )}
                </LegacyModalDialogButtons>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
DesignErrorModalDialog.displayName = "DesignErrorModalDialog";
