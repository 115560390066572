import { saveStudio5Design } from "@five/clients/saveClient";
import { DownloadButton } from "@shared/features/GlobalHeader";
import { MyProjectsButton } from "@shared/features/MyProjects";
import { SaveCloudIcon } from "@shared/features/StudioChrome/Icons/SaveCloudIcon";
import classNames from "classnames";
import React from "react";
import { useStudio5Save } from "@five/hooks/useStudio5Save";
import { DuplicateProjectButton, RenameProjectButton, SaveButtonSmallScreen } from "@shared/features/Save";
import { ResizeButton } from "../Resize";
import * as styles from "./FileDropDownContents.module.scss";

type FileDropDownContentsProps = {
    onClose?: () => void;
};

export const FileDropDownContents = ({ onClose }: FileDropDownContentsProps) => {
    return (
        <div className={styles.dropdownContainer}>
            <SaveButtonSmallScreen
                icon={<SaveCloudIcon />}
                className={classNames(styles.dropdownButton, styles.saveButtonOverride)}
                onSave={() => onClose?.()}
                useSave={useStudio5Save}
            />
            <MyProjectsButton
                className={classNames(styles.dropdownButton, styles.myProjectsButtonOverride)}
                enableModalTransitionEvent
                saveDesign={saveStudio5Design}
                onPanelOpen={() => onClose?.()}
            />
            <RenameProjectButton
                className={styles.projectNameButton}
                saveDesign={saveStudio5Design}
                useSave={useStudio5Save}
            />
            <DuplicateProjectButton
                className={styles.projectNameButton}
                saveDesign={saveStudio5Design}
                useSave={useStudio5Save}
            />
            <ResizeButton className={styles.dropdownButton} skin="inline" />
            <DownloadButton
                className={classNames(styles.dropdownButton, styles.downloadButtonOverride)}
                skin="unstyled"
            />
        </div>
    );
};

FileDropDownContents.displayName = "FileDropDownContents";
