import { findRgbDifference } from "@design-stack-ct/utility-core";
import { selectedItemsAreOfTypes } from "@utilities";
import { Hsl, rgbToHsl, hexToRgb, Rgb } from "@presentational";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { getCurrentCanvas } from "../util";
import type { Designer, ItemSelection } from "../@types/designer";

export function getIsColorpickerCompatible(designer: Designer | undefined, selection: ItemSelection): boolean {
    if (designer === undefined) {
        return false;
    }
    const currentCanvas = getCurrentCanvas(designer, selection[0]);
    return currentCanvas && currentCanvas.processType !== "laserEngraving";
}

export function getIsFillColorCompatible(
    designer: Designer | undefined,
    selection: ItemSelection
): designer is Designer {
    return (
        getIsColorpickerCompatible(designer, selection) &&
        selectedItemsAreOfTypes(selection, [ItemTypes.SHAPE]) &&
        selection.every((item: ShapeItem) => item.shapeType !== designer?.api.design.constants.shapeTypes.line)
    );
}

export function getIsStrokeColorCompatible(
    designer: Designer | undefined,
    selection: ItemSelection
): designer is Designer {
    return (
        getIsColorpickerCompatible(designer, selection) &&
        selectedItemsAreOfTypes(selection, [ItemTypes.SHAPE]) &&
        (!selection[0] || !selection[0]._itemViewModel.get("onSingleColorCanvas"))
    );
}

// next 3 functions copied from design-stack
export function getHslForImage(data: Uint8ClampedArray) {
    const hslValues = [];

    for (let i = 0; i < data.length; i += 4) {
        const hsl = rgbToHsl({ r: data[i], g: data[i + 1], b: data[i + 2] });
        hslValues[i / 4] = hsl;
    }
    return hslValues;
}

export function calculateChannelValue(value: number, tmp1: number, tmp2: number) {
    if (6 * value < 1) {
        return tmp2 + (tmp1 - tmp2) * 6 * value;
    }

    if (2 * value < 1) {
        return tmp1;
    }

    if (3 * value < 2) {
        return tmp2 + (tmp1 - tmp2) * (2 / 3 - value) * 6;
    }

    return tmp2;
}

function transformSrcHslToRgb(
    srcHsl: Hsl,
    hueOffset: number,
    hueMultiplier: number,
    saturationMultiplier: number,
    lightnessMultiplier: number,
    lightnessOffset: number
) {
    let r: number;
    let g: number;
    let b: number;
    let tmp1: number;
    let tmp2: number;
    let tmpR: number;
    let tmpG: number;
    let tmpB: number;

    const computeHslLightness = lightnessOffset > 0 && (srcHsl.l === 0 || lightnessMultiplier === 0);
    let h = srcHsl.h * hueMultiplier + hueOffset;
    let s = srcHsl.s * saturationMultiplier;
    let { l } = srcHsl;
    if (s > 1) {
        s = 1;
    }

    // If the lightness must be computed in HSL space do that now, otherwise defer it
    if (computeHslLightness) {
        l = lightnessOffset;
    }

    if (s === 0) {
        r = l;
        g = l;
        b = l;
    } else {
        if (l < 0.5) {
            tmp1 = l * (1 + s);
        } else {
            tmp1 = l + s - l * s;
        }

        tmp2 = 2 * l - tmp1;

        h /= 360;

        tmpR = h + 1 / 3;
        tmpG = h;
        tmpB = h - 1 / 3;

        if (tmpR > 1) {
            tmpR -= 1;
        } else if (tmpR < 0) {
            tmpR += 1;
        }
        if (tmpG > 1) {
            tmpG -= 1;
        } else if (tmpG < 0) {
            tmpG += 1;
        }
        if (tmpB > 1) {
            tmpB -= 1;
        } else if (tmpB < 0) {
            tmpB += 1;
        }

        r = calculateChannelValue(tmpR, tmp1, tmp2);
        g = calculateChannelValue(tmpG, tmp1, tmp2);
        b = calculateChannelValue(tmpB, tmp1, tmp2);
    }

    // Keep lightness in HSL space and convert to RGB
    if (computeHslLightness) {
        return {
            r: r * 255,
            g: g * 255,
            b: b * 255
        };
    }

    // Otherwise apply the lightness multipliers and offsets in RGB space to avoid chroma issues
    const lm = lightnessMultiplier;
    const lo = lightnessOffset;
    return {
        r: Math.min(255 * (r * lm + lo), 255),
        g: Math.min(255 * (g * lm + lo), 255),
        b: Math.min(255 * (b * lm + lo), 255)
    };
}

export function drawImage(
    modificationCanvas: HTMLCanvasElement,
    visibleCanvas: HTMLCanvasElement,
    hslValues: Array<{ h: number; s: number; l: number }>,
    hueOffset: number,
    hueMultiplier: number,
    saturationMultiplier: number,
    inputLightnessMulti: number
) {
    const visibleContext = visibleCanvas.getContext("2d")!;
    const modificationContext = modificationCanvas.getContext("2d")!;
    const { width, height } = modificationCanvas;

    const imageData = modificationContext.getImageData(0, 0, width, height);

    let lightnessOffset = 0;
    let lightnessMultiplier = inputLightnessMulti;
    // If the lightness multiplier is greater than 1, we reduce the multiplier and set an offset instead.
    // This is because when the lightness multiplier is greater than 1, the results from the API don't look great in some instances,
    // but this math makes it always look pretty good.
    if (lightnessMultiplier > 1) {
        lightnessOffset = lightnessMultiplier - 1;
        lightnessMultiplier = 2 - lightnessMultiplier;
    }

    // Loop through every pixel in the image, and modify its color given the offsets and multipliers above.
    for (let i = 0; i < imageData.data.length; i += 4) {
        const { h, s, l } = hslValues[i / 4];

        const { r, g, b } = transformSrcHslToRgb(
            { h, s, l },
            hueOffset,
            hueMultiplier,
            saturationMultiplier,
            lightnessMultiplier,
            lightnessOffset
        );

        imageData.data[i] = r;
        imageData.data[i + 1] = g;
        imageData.data[i + 2] = b;
    }

    modificationContext.putImageData(imageData, 0, 0);
    visibleContext.clearRect(0, 0, width, height);
    visibleContext.drawImage(modificationCanvas, 0, 0, width, height);
}

export function isLightColor(hexCode: string) {
    if (hexCode) {
        const rgb = hexToRgb(hexCode);
        if (rgb) {
            const { r, g, b } = rgb;
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            if (brightness < 128) {
                return false;
            }
        }
    }
    return true;
}

// taken from designer
/*
 * Takes an rgb value and an array of colors and tries to find the closest match
 * @param {Object} targetRgb - rgb value that is trying to be matched
 * @param {Object[]} colors - an array of mapped color objects
 * @returns {string} hex value of closest matching color
 */
export function searchForSimilarColor(targetColor: Rgb, colors: Color[]) {
    let bestMatch: { color: Color; colorDifference: number } | undefined;

    colors.forEach(color => {
        const colorDifference = findRgbDifference(targetColor, color.rgb);

        if (!bestMatch || bestMatch.colorDifference > colorDifference) {
            bestMatch = { colorDifference, color };
        }
    });

    return bestMatch?.color;
}
