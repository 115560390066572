import { useDesignRequirementsContext } from "@shared/features/Product";
import { isEmbroidery } from "@shared/features/Embroidery";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";

export function useGetDecorationTechnology() {
    const designRequirements = useDesignRequirementsContext();

    return (
        (designRequirements &&
            designRequirements.panels.length > 0 &&
            designRequirements.panels[0].decorationTechnology) ||
        undefined
    );
}

export function useGetDecorationTechnologySimple() {
    const currentDecoTech = useGetDecorationTechnology();

    if (!currentDecoTech) {
        return undefined;
    }

    return toDecoTechSimple(currentDecoTech);
}

export function toDecoTechSimple(currentDecoTech: string) {
    return isEmbroidery(currentDecoTech) ? DecorationTechnologiesSimple.EMBROIDERY : DecorationTechnologiesSimple.PRINT;
}
