import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useCanvasSelectorPreviews } from "@shared/features/CanvasSelectors";
import { FlexibilityPanelContent } from "./FlexibilityPanelContent";
import * as styles from "./FlexibilityPanel.module.scss";

const messages = defineMessages({
    panelTitle: {
        id: "studio.components.panels.flexibilityPanel.header",
        defaultMessage: "Product options"
    }
});
export function FlexibilityPanel() {
    const { t } = useTranslationSSR();
    const canvasSelectorPreviewsData = useCanvasSelectorPreviews();
    const designRequirements = useDesignRequirementsContext();

    if (!designRequirements || !canvasSelectorPreviewsData) {
        return null;
    }

    const { canvasSelectorUrls } = canvasSelectorPreviewsData;
    const backIndex = canvasSelectorUrls.findIndex((canvas: any) => canvas.name === "Back");
    const canvasData = canvasSelectorUrls[backIndex];
    if (!canvasData) {
        return null;
    }

    return (
        <PanelContent className={styles.flexibilityPanelContent}>
            <PanelTitle>{t(messages.panelTitle.id)}</PanelTitle>
            <FlexibilityPanelContent />
        </PanelContent>
    );
}
FlexibilityPanel.displayName = "FlexibilityPanel";
