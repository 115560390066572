const sherbertAPI = 'https://api.sherbert.cimpress.io/v2';

async function getFolderId({
    shopperId,
    tenant,
    authToken,
    abortSignal
}: {
    shopperId: string;
    tenant: string;
    authToken: string;
    abortSignal?: AbortSignal
}): Promise<string> {
    const response = await fetch(
        `${sherbertAPI}/folders?folderName=${shopperId}&scopeToUserFolders=true&tenant=${tenant}`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            signal: abortSignal
        },
    );
    const responseJson = await response.json();
    // eslint-disable-next-line no-underscore-dangle
    return responseJson._embedded?.item?.[0]?.id;
}

async function createFolder({
    shopperId,
    tenant,
    authToken,
    abortSignal
}: {
    shopperId: string;
    tenant: string;
    authToken: string;
    abortSignal?: AbortSignal
}): Promise<string> {
    const createFolderBody = {
        tenant,
        // the assets sdk doesn't appear to provide any properties
        properties: {},
        name: shopperId,
        info: {
            asset: {
                folder: {
                    isUserFolder: true,
                },
            },
        },
    };
    const response = await fetch(`${sherbertAPI}/folders`, {
        method: 'POST',
        body: JSON.stringify(createFolderBody),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        signal: abortSignal
    });
    const folder = await response.json();
    return folder.id;
}

export async function fetchFolderIdForShopper({
    shopperId,
    tenant,
    authToken,
    abortSignal
}: {
    shopperId: string;
    tenant: string;
    authToken: string;
    abortSignal?: AbortSignal
}): Promise<string> {
    const folderId = await getFolderId({ shopperId, tenant, authToken, abortSignal });
    if (!folderId || folderId.length === 0) {
        return createFolder({ shopperId, tenant, authToken, abortSignal });
    }
    return folderId;
}
