/* eslint-disable max-len */
import { defineMessages } from "@vp/i18n-helper";

export const redesignMessages = defineMessages({
    desktopSupportMessage: {
        id: "easel.components.globalToolbar.desktopSupportMessage",
        defaultMessage: "Need Help?",
        description: {
            note: "Message for desktop button to contact support"
        }
    },
    mobileSupportMessage: {
        id: "easel.components.globalToolbar.mobileSupportMessage",
        defaultMessage: "Help",
        description: {
            note: "Message for mobile button to contact support"
        }
    },
    tablePanelLabel: {
        id: "easel.components.sideBar.tablePanel.tablePanelLabel",
        defaultMessage: "Tables",
        description: {
            note: "Label for table panel of sidebar"
        }
    },
    addTable: {
        id: "easel.components.sideBar.tablePanel.addTable",
        defaultMessage: "Add table",
        description: {
            note: "Message for add table button on table panel of sidebar"
        }
    },
    newTextField: {
        id: "easel.components.sideBar.textPanel.newTextField",
        defaultMessage: "New text field",
        description: {
            note: "Message for new text field button on the text panel of sidebar"
        }
    },
    addBackSide: {
        id: "easel.components.backSide.addBackSide",
        defaultMessage: "Add back",
        description: {
            note: "Message to add backside to product"
        }
    },
    previewPanelDescription: {
        id: "easel.components.sideBar.previewPanel.previewPanelDescription",
        defaultMessage:
            "Make sure your document looks right before you continue. Select “View” to see how it will look in the real world. Select “Download” for a PDF proof to print or share.",
        description: {
            note: "Description for preview panel of sidebar"
        }
    },
    previewPanelView: {
        id: "easel.components.sideBar.previewPanel.view",
        defaultMessage: "View",
        description: {
            note: "Message for the view button on the preview panel of sidebar"
        }
    },
    previewPanelDownload: {
        id: "easel.components.sideBar.previewPanel.download",
        defaultMessage: "Download",
        description: {
            note: "Message for the download button on the preview panel of sidebar"
        }
    },
    guides: {
        id: "studio.components.addSheet.guides.label",
        defaultMessage: "Guides",
        description: {
            note: "Label for the add sheet guides button"
        }
    },
    viewPanel: {
        id: "studio.components.addSheet.viewPanel.label",
        defaultMessage: "View",
        description: {
            note: "Label for the add sheet view button"
        }
    }
});
