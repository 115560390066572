import { useState, useEffect } from "react";
import { useDocumentItems } from "../../designer/useDocumentItems";
import type { ItemSelection } from "../../@types/designer";

/**
 * Returns an array of document items
 * @param activeCanvasOnly whether to return items for the whole document or the active canvas only
 */
export function useDocumentImages(activeCanvasOnly = false) {
    const documentItems = useDocumentItems(activeCanvasOnly);
    const [documentImages, setDocumentImages] = useState<ItemSelection>([]);

    useEffect(() => {
        const isDocumentImage = (model: CanvasItem) => {
            return model.itemType === "IMAGE";
        };

        const newImages = documentItems.filter(isDocumentImage);
        setDocumentImages(newImages);
    }, [documentItems]);

    return documentImages;
}
