import React from "react";
import { SingleColorImageColorPickerButton } from "@shared/features/ContextualToolbar";
import { useDesigner, useSelection } from "@designer-suite";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { SingleColorImageColorPickerPanel } from "@five/components/Panels/Redesign/SingleColorImageColorPickerPanel";

export function SingleColorImageColorPicker({ contentOnly, className }: { contentOnly: boolean; className: string }) {
    const designer = useDesigner();
    const selection = useSelection("model:change:colorAdjustment");
    const { setCurrentActiveDialog } = useActiveDialog();

    if (!selection.length || !designer) {
        return null;
    }

    return contentOnly ? (
        <SingleColorImageColorPickerPanel contentOnly className={className} />
    ) : (
        <SingleColorImageColorPickerButton
            onClick={() => {
                setCurrentActiveDialog(DialogType.SingleColorImageColorPicker);
            }}
            isSelected={false}
            disabled={false}
        />
    );
}

SingleColorImageColorPicker.displayName = "SingleColorImageColorPicker";
