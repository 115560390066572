import React, { useEffect, useCallback } from "react";
import { loadableRetry } from "@shared/utils/Network";
import loadable from "@loadable/component";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { newRelicWrapper } from "@shared/utils/Errors";
import { firePerformanceTrackingEvent } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, hideLoader } from "@shared/redux";
import { ErrorPage } from "@shared/features/Errors";
import { useBeforeAfterHydrationCheck } from "@shared/features/ResponsiveDesign";
import EaselContainer from "../components/EaselContainer";
import StudioFiveDebugTools from "../components/Debug/StudioFiveDebugTools";

const FidoLoaderWrapper = loadable(() => loadableRetry(() => import("../components/FidoLoaderWrapper")));

function StudioFive() {
    const showErrorPage = useAppSelector(state => state.showErrorPage);

    const dispatch = useAppDispatch();
    const { identity, isIdentityInitialized } = useIdentityContext();
    const isAfterHydration = useBeforeAfterHydrationCheck();
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    const reloadWorkEvent = useCallback(e => {
        if (e.state === "update-workId") {
            e.preventDefault();
            window.location.href = window.location.href;
        }
    }, []);

    useEffect(() => {
        window.addEventListener("popstate", reloadWorkEvent);
        return () => {
            window.removeEventListener("popstate", reloadWorkEvent);
        };
    }, [reloadWorkEvent]);

    useEffect(() => {
        firePerformanceTrackingEvent({
            eventDetail: "Studio Loading Started",
            label: "Studio Loading Started"
        });
        newRelicWrapper.logPageAction("studio-onready");
        window.addEventListener("beforeunload", () => {
            newRelicWrapper.logPageAction("studio-beforeunload");
        });
    }, []);

    useEffect(() => {
        if (isAfterHydration) {
            dispatch(hideLoader());
        }
    }, [isAfterHydration, dispatch]);

    useEffect(() => {
        const was = identity && identity.was;

        newRelicWrapper.logPageAction(
            "UserIdentityUpdate",
            identity && {
                identitySignedIn: identity.isSignedIn,
                identityCanonicalID: identity.shopperId || identity.anonymousUserId || identity.cimpressADFSUserId,
                identityAnonymous: identity.isAnonymousUser,
                identityShopper: identity.isShopper,
                isIdentityInitialized,
                identityWas: was && Array.isArray(was) && was.length > 0 && was[0]
            }
        );
    }, [identity, isIdentityInitialized]);

    return (
        <>
            {easelLoaded ? <FidoLoaderWrapper /> : null}
            {showErrorPage ? <ErrorPage DebugToolbar={StudioFiveDebugTools} /> : <EaselContainer />}
        </>
    );
}

StudioFive.displayName = "StudioFive";

export default StudioFive;
