import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Typography, Link } from "@vp/swan";
import classnames from "classnames";
import * as styles from "./SkipToMainLink.module.scss";

const messages = defineMessages({
    linkTitle: {
        id: "studio.components.skipLink.linkTitle",
        defaultMessage: "Skip to main",
        description: {
            note: "Title for skip link"
        }
    }
});

export const SkipToMainLink = ({ skipToElementId, onClick }: { skipToElementId: string; onClick: () => void }) => {
    const { t } = useTranslationSSR();
    return (
        <Link
            className={classnames(styles.skipToMain)}
            href={`#${skipToElementId}`}
            onClick={(event: React.MouseEvent) => onClick()}
        >
            <Typography fontSize="-1" fontWeight="normal">
                {t(messages.linkTitle.id)}
            </Typography>
        </Link>
    );
};

SkipToMainLink.displayName = "SkipToMainLink";
