import React, { useState, useEffect, useRef } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    LegacyComboboxOption,
    LegacyComboboxInput,
    LegacyComboboxList,
    LegacyComboboxPopover,
    LegacyComboboxPopoverTitle,
    LegacyCombobox
} from "@vp/swan";
import { useStockImages } from "@design-stack-vista/image-library-react";
import {
    Button,
    SearchIcon,
    SearchFilterIcon,
    SearchFilterSelectedIcon,
    SearchResetIcon
} from "@shared/features/StudioChrome";

import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { useAppSelector, useAppDispatch, setShowImageSearchFiltersPanel } from "@shared/redux";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";
import { getRecentlySearchedTerms, updateRecentlySearchTerm } from "./ImagePanelUtils";
import { ImageSearchFiltersPanel } from "./ImageSearchFiltersPanel";

type Props = {
    clearList: () => void;
};

const MAX_SEARCH_TERMS_TO_KEEP = 100;

export const ImagesSearchBar = (props: Props) => {
    const { clearList } = props;
    const { currentFilterOptions, performInitialSearch, clearSearch, currentSearchTerm, totalImageCount, imageItems } =
        useStockImages();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [recentlySearchedTerms, setRecentlySearchedTerms] = useState<string[]>([]);
    const { trackEvent } = useTrackEvents();
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const panelOpen = useAppSelector(state => state.showImageSearchFiltersPanel);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleFilterPanel = () => {
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_OPEN_FILTERS,
            extraData: () => ({
                openingPanel: !panelOpen
            })
        });
        dispatch(setShowImageSearchFiltersPanel(!panelOpen));
    };

    const resetSearchInputField = () => {
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_CLEAR_SEARCH_TERM
        });
        clearList();
        setSearchTerm("");
        clearSearch();
    };

    const handleOnChange = (event: { target: HTMLInputElement }) => {
        const { value } = event.target as HTMLInputElement;
        setSearchTerm(value);
    };

    const handleSearch = (overrideSearchTerm?: string, newFilters?: string) => {
        const newSearchTerm = overrideSearchTerm || searchTerm;
        if (newSearchTerm || newFilters !== currentFilterOptions) {
            if (newSearchTerm !== currentSearchTerm || newFilters !== currentFilterOptions) {
                clearList();
                performInitialSearch(newSearchTerm, newFilters ?? currentFilterOptions);
                setRecentlySearchedTerms((current: string[]) => {
                    current.includes(newSearchTerm) && current.splice(current.indexOf(newSearchTerm), 1);
                    current.unshift(newSearchTerm);
                    let result = current;
                    if (MAX_SEARCH_TERMS_TO_KEEP && result.length > MAX_SEARCH_TERMS_TO_KEEP) {
                        result = result.slice(result.length - MAX_SEARCH_TERMS_TO_KEEP, result.length);
                    }
                    updateRecentlySearchTerm(result);
                    return result;
                });
            }
        } else {
            resetSearchInputField();
        }
    };

    useEffect(() => {
        // This won't work correctly if the totalImageCount is the same between searches,
        //  but with the items currently available on useStockImages this seemed like a good compromise
        if (searchTerm.length > 0) {
            trackEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.SEARCH_IMAGES,
                extraData: () => ({
                    searchTerm: searchTerm.toLowerCase().trim(),
                    currentFilterOptions,
                    totalImageCount,
                    imageItemsCount: imageItems?.length
                })
            });
        }
        // Intentionally only triggering on count changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalImageCount]);

    useEffect(() => {
        getRecentlySearchedTerms().then(result => {
            result && setRecentlySearchedTerms(result);
        });
    }, []);

    const filteredRecentlySearchedTerms = recentlySearchedTerms.filter(term => term.includes(searchTerm));
    const showRecentlySearchedTerms =
        filteredRecentlySearchedTerms.length > 1 ||
        (filteredRecentlySearchedTerms.length === 1 && filteredRecentlySearchedTerms[0] !== searchTerm);

    return (
        <LegacyCombobox
            openOnFocus
            aria-labelledby={t(imageLibraryPanelMessages.searchPlaceholder.id)}
            className="image-discovery-search-combobox"
            onSelect={value => {
                trackEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_SELECT_RECENTLY_USED,
                    extraData: () => ({
                        searchTerm: value
                    })
                });
                setSearchTerm(value);
                handleSearch(value);
            }}
        >
            <LegacyComboboxInput
                value={searchTerm}
                onChange={handleOnChange}
                placeholder={t(imageLibraryPanelMessages.searchPlaceholder.id)}
                title={t(imageLibraryPanelMessages.searchPlaceholder.id)}
                onKeyPress={(event: { key: string }) => {
                    if (event.key === "Enter") handleSearch();
                }}
                className="search-input search-input-text"
            />
            <>
                {searchTerm && (
                    <Button
                        onClick={resetSearchInputField}
                        className="image-discovery-panel-search-reset-button"
                        aria-label={t(imageLibraryPanelMessages.imageSearchResetButtonLabel.id)}
                    >
                        <SearchResetIcon />
                    </Button>
                )}
                <Button
                    ref={buttonRef}
                    onClick={toggleFilterPanel}
                    className="image-discovery-panel-filters-button filters-button"
                    isSelected={panelOpen}
                    aria-label={t(imageLibraryPanelMessages.imageSearchFilterButtonLabel.id)}
                >
                    {currentFilterOptions ? (
                        <SearchFilterSelectedIcon className="filters-button-selected-icon" />
                    ) : (
                        <SearchFilterIcon className="filters-button-icon" />
                    )}
                </Button>
                <ImageSearchFiltersPanel
                    buttonRef={buttonRef}
                    performSearchForFilter={(filterOptions: string) => handleSearch(searchTerm, filterOptions)}
                />
            </>
            <Button
                onClick={() => handleSearch()}
                className="search-image-button"
                aria-label={t(imageLibraryPanelMessages.imageSearchButtonLabel.id)}
            >
                <SearchIcon />
            </Button>
            {showRecentlySearchedTerms && (
                <LegacyComboboxPopover
                    className="image-discovery-panel-search-popover"
                    data-dcl-prevent-canvas-items-deselection
                >
                    <LegacyComboboxPopoverTitle>
                        {t(imageLibraryPanelMessages.searchRecentHeading.id)}
                    </LegacyComboboxPopoverTitle>
                    <LegacyComboboxList>
                        {filteredRecentlySearchedTerms.map((term: string) => {
                            return <LegacyComboboxOption value={term} key={`${term}-option`} />;
                        })}
                    </LegacyComboboxList>
                </LegacyComboboxPopover>
            )}
        </LegacyCombobox>
    );
};

ImagesSearchBar.displayName = "ImagesSearchBar";
