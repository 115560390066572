import React, { forwardRef, Ref } from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { FloatingSidebar } from "../Redesign/FloatingSidebar";

export const EaselContentsLeftContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const { isSmall } = useStudioLayout();

    return (
        <div ref={ref} className="left-container">
            {!isSmall && <FloatingSidebar />}
        </div>
    );
});

EaselContentsLeftContainer.displayName = "EaselContentsLeftContainer";
