import React, { useState, useEffect, useCallback } from "react";
import { HslRecolorizationSliders, HslRecolorizationExpandingButton } from "@shared/features/ContextualToolbar";
import { SliderType } from "@shared/features/ColorPicker";
import { getTrackingDataForImageSelection, getItemStudioMetadataProperty } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { HSL } from "@design-stack-ct/utility-core";
import type { DSS } from "@vp/types-ddif";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { updateSelectedItems } from "../util";
import { RecolorizerFilter } from "./RecolorizerFilter";

function checkEqualityOfColorAdjustment(
    oldColorAdjustment: ColorAdjustment | undefined,
    newColorAdjustment: ColorAdjustment | undefined
) {
    return (
        oldColorAdjustment &&
        newColorAdjustment &&
        oldColorAdjustment.hueOffset === newColorAdjustment.hueOffset &&
        oldColorAdjustment.saturationMultiplier === newColorAdjustment.saturationMultiplier &&
        oldColorAdjustment.lightnessMultiplier === newColorAdjustment.lightnessMultiplier &&
        oldColorAdjustment.lightnessOffset === newColorAdjustment.lightnessOffset
    );
}

function decodeHueOffsetFromSlider(modelHue: number, dominantHue: number) {
    return (modelHue - dominantHue + 360) % 360;
}
function encodeHueOffsetForSlider(modelHue: number, dominantHue: number) {
    return (modelHue + dominantHue + 360) % 360;
}

const defaultHueOffset = 0;
const defaultHueMultiplier = 1;
const defaultSaturationMultiplier = 1;
const defaultlightnessMultiplier = 1;

interface Props {
    /** For overriding styles */
    className?: string;
    /**
     * display just the content instead of the expanding button (for sheet usage)
     * @default false
     */
    contentOnly: boolean;
    /** Whether the button should return the icon, text, or both  */
    displayType: "iconOnly" | "textOnly" | "both";
}

export function HslRecolorization({ className = "", contentOnly = false, displayType }: Props) {
    const designer = useDesigner();
    const selection = useSelection("model:change:colorAdjustment");
    const [initialTrackingSent, setInitialTrackingSent] = useState(false);
    const [sliding, setSliding] = useState(false);
    const [hueMultiplier, setHueMultiplier] = useState(defaultHueMultiplier);
    const [hueOffset, setHueOffset] = useState(defaultHueOffset);
    const [saturationMultiplier, setSaturationMultiplier] = useState(defaultSaturationMultiplier);
    const [lightnessMultiplier, setLightnessMultiplier] = useState(defaultlightnessMultiplier);
    /** *
     * We recolorize one of two ways.
     * For multi-color photos, we use "hue specify".  When changing the hue, we alter the image's pixel hsl values to be set to a specific hue
     * An image will be changed to be shades of blue, for example.
     * This is done by setting  the hue multiplier to 0 (ignoring the actual hue for that pixel) and adding the offset ('specifying' a hue value)
     * For other multi-color images we use "hue offset".  When changing the hue, we apply an offset to the current hue value
     * We keep the hue multiplier at 1 (keeping the current hue value) and then we add the offset to it
     *
     * Generally we would only need to set the hue multiplier above but photos have special behavior
     * We want position the hue slider on the dominant color of the image, giving the hue slider a value.  We  don't want to apply that value to the photo!
     * We also don't want the  hue multiplier for an unmodified  image to be 0 - that would throw away pixel hue value when recolorizing
     * If we always set the hue multiplier to 0, then an unmodified image would be shades of the dominant color
     * (pixel hue value) * 0 + hue offset (the dominant hue value)
     * Thus for unmodified images we set the hue multiplier to 1 and leave the hue offset at 0 - ignoring the hue slider value
     *
     * This is why we have hue offset.  Here we determine whether the image should use hue offset _when the hue has been modified_
     * We can't trust the hue multiplier - that changes for photos depending on whether the user has changed the hue slider or not
     * This is our way of storing the actual recolorization behavior of the image
     ** */
    const [isHueOffset, setIsHueOffset] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (selection.length !== 1) {
            return;
        }
        const item = selection[0] as ImageItem;
        const imageViewModel = item._itemViewModel;
        const colorAdjustment = imageViewModel.model.get("colorAdjustment");

        const getImageHslData = (shouldBeHueOffset: boolean) => {
            // these should normally be a noop, but for undo/redo we have to reset these
            setHueMultiplier(defaultHueMultiplier);
            setHueOffset(defaultHueOffset);
            setSaturationMultiplier(defaultSaturationMultiplier);
            setLightnessMultiplier(defaultlightnessMultiplier);
            if (colorAdjustment) {
                if (colorAdjustment.hueOffset !== undefined) {
                    setHueOffset(colorAdjustment.hueOffset);
                    setHueMultiplier(shouldBeHueOffset ? 1 : 0);
                }

                if (colorAdjustment.saturationMultiplier !== undefined) {
                    setSaturationMultiplier(colorAdjustment.saturationMultiplier);
                }

                if (colorAdjustment.lightnessOffset > 0) {
                    setLightnessMultiplier(colorAdjustment.lightnessOffset + 1);
                } else if (colorAdjustment.lightnessMultiplier !== undefined) {
                    setLightnessMultiplier(colorAdjustment.lightnessMultiplier);
                }
            }
        };

        const getIsHueOffset = () => {
            if (colorAdjustment && colorAdjustment.hueMultiplier !== undefined) {
                return colorAdjustment.hueMultiplier === 1;
            }
            if (imageViewModel.model.attributes.analysis) {
                return imageViewModel.model.attributes.analysis.isPhoto !== "True";
            }

            const metadata = getItemStudioMetadataProperty(item, "analysis") as DSS.StudioMetadata;

            // eslint-disable-next-line dot-notation
            if (metadata?.["isPhoto"] !== undefined) {
                // eslint-disable-next-line dot-notation
                return metadata["isPhoto"] !== "True";
            }

            // Default to hueSpecifiy
            return true;
        };

        const shouldBeHueOffset = getIsHueOffset();
        setIsHueOffset(shouldBeHueOffset);
        getImageHslData(shouldBeHueOffset);
    }, [selection, contentOnly, designer]);

    useEffect(() => {
        if (selection.length !== 1 || !designer) {
            return;
        }

        setShowPreview(true);

        if (sliding) {
            return;
        }

        const updateImageModel = () => {
            const imageItem = selection[0] as ImageItem;
            const imageViewModel = imageItem._itemViewModel;

            let lightnessOffset = 0;
            let newLightnessMutliplier = lightnessMultiplier;
            if (lightnessMultiplier > 1) {
                lightnessOffset = lightnessMultiplier - 1;
                newLightnessMutliplier = 2 - lightnessMultiplier;
            }

            let newColorAdjustment: ColorAdjustment | undefined;
            if (hueOffset === 0 && saturationMultiplier === 1 && newLightnessMutliplier === 1) {
                newColorAdjustment = undefined;
            } else {
                newColorAdjustment = {
                    hueMultiplier,
                    hueOffset,
                    saturationMultiplier,
                    lightnessMultiplier: newLightnessMutliplier,
                    lightnessOffset
                };
            }

            if (newColorAdjustment && hueMultiplier && !isHueOffset) {
                // hue specify gets a hue multipler of 1 when changing other sliders and keeping hue default.
                // we don't want to save this because it'll scrrew up future sessions of studio
                // (it'll use hue offset instead of specify because the multiplier will be 1 and the analysis data has been lost)
                delete newColorAdjustment.hueMultiplier;
                delete newColorAdjustment.hueOffset;
            }

            const colorAdjustment = imageViewModel.model.get("colorAdjustment");

            if (!checkEqualityOfColorAdjustment(newColorAdjustment, colorAdjustment)) {
                designer.eventBus.listenToOnce(imageViewModel, "loadimage", () => {
                    setShowPreview(false);
                });
                updateSelectedItems(designer, selection, (item: MutableImageItem) => {
                    item._itemViewModel.model.set("colorAdjustment", newColorAdjustment);
                });
            } else {
                setShowPreview(false);
            }
        };

        updateImageModel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hueOffset, saturationMultiplier, lightnessMultiplier, sliding]);

    const handleSliding = useCallback(
        (isSliding: boolean, sliderType: SliderType) => {
            setSliding(isSliding);
            if (!isSliding && designer) {
                switch (sliderType) {
                    case "hue":
                        designer.eventBus.trigger(
                            STUDIO_TRACKING_EVENTS.USE_HUE_SLIDER,
                            getTrackingDataForImageSelection(selection)
                        );
                        break;
                    case "saturation":
                        designer.eventBus.trigger(
                            STUDIO_TRACKING_EVENTS.USE_SATURATION_SLIDER,
                            getTrackingDataForImageSelection(selection)
                        );
                        break;
                    case "lightness":
                        designer.eventBus.trigger(
                            STUDIO_TRACKING_EVENTS.USE_LIGHTNESS_SLIDER,
                            getTrackingDataForImageSelection(selection)
                        );
                        break;
                    default:
                        break;
                }
            }
        },
        [selection, designer]
    );

    if (selection.length !== 1 || designer === undefined) {
        return null;
    }

    function trackOpen() {
        designer?.eventBus.trigger(
            STUDIO_TRACKING_EVENTS.IMAGE_COLOR_TOOL_SELECTION,
            getTrackingDataForImageSelection(selection)
        );
    }

    if (contentOnly && !initialTrackingSent) {
        trackOpen();
        setInitialTrackingSent(true);
    }
    const imageViewModel = (selection[0] as ImageItem)._itemViewModel;
    const originalHue = imageViewModel.get("originalHue");

    const onChange = (value: HSL) => {
        // The major issue is we only want to update hue *if* hue is actually changing - don't update hue if lightness changes
        // The issue is photos - we want to make sure that we keep the hue multiplier at 1 when the hue has not been modified
        // otherwise the photo will be recolorized because, if the hue multiplier is 0, it will use hue specify even though we have not made a change
        // (non-photos don't have this problem as the hue offset will be 0 and the hue multiplier is 1, which leaves the hue unmodified)
        // the other photo issue is that, once we begin modifying the hue, we don't want the hue to disable itself when we drag the slider back to the original hue
        // we still want to specify the hue at that point.
        if (
            value.h !== hueOffset &&
            (isHueOffset || (!isHueOffset && (hueMultiplier === 0 || value.h !== originalHue)))
        ) {
            const newHueOffset = isHueOffset ? decodeHueOffsetFromSlider(value.h, originalHue) : value.h;
            const newHueMultiplier = isHueOffset ? 1 : 0;
            setHueOffset(newHueOffset);
            setHueMultiplier(newHueMultiplier);
        }

        if (value.s !== saturationMultiplier) {
            setSaturationMultiplier(value.s);
        }

        if (value.l !== lightnessMultiplier) {
            setLightnessMultiplier(value.l);
        }
    };

    const onReset = (adjustmentType: string) => {
        switch (adjustmentType) {
            case "hueOffset":
                setHueOffset(defaultHueOffset);
                designer.eventBus.trigger(
                    STUDIO_TRACKING_EVENTS.CLICK_HUE_RESET,
                    getTrackingDataForImageSelection(selection)
                );
                if (!isHueOffset) {
                    // as mentioned in the comment at the beginning of the component
                    // images that have not had their hue modified should have a hue multiplier of 1
                    // and a hue offset of 0 - that way the hue  will not be recolorized
                    // if we set the hue to the default (0) and left the hue multiplier at 0,
                    // then the image would be recolorized as shades of red
                    // (pixel hue value) * 0 + 0 = 0 (hue of 0 is red)
                    setHueMultiplier(defaultHueMultiplier);
                }
                break;
            case "saturationMultiplier":
                setSaturationMultiplier(defaultSaturationMultiplier);
                designer.eventBus.trigger(
                    STUDIO_TRACKING_EVENTS.CLICK_SATURATION_RESET,
                    getTrackingDataForImageSelection(selection)
                );
                break;
            case "lightnessMultiplier":
                setLightnessMultiplier(defaultlightnessMultiplier);
                designer.eventBus.trigger(
                    STUDIO_TRACKING_EVENTS.CLICK_LIGHTNESS_RESET,
                    getTrackingDataForImageSelection(selection)
                );

                break;
            default:
                break;
        }
    };

    let sliderHueValue = isHueOffset ? encodeHueOffsetForSlider(hueOffset, originalHue) : hueOffset;

    if (!isHueOffset && hueOffset === defaultHueOffset) {
        // if this is a photo, and we don't have a hue offset set,
        // then the slider should show in the position of the dominant hue
        // otherwise the slider will just show red, when we want the slider to show the dominant hue
        sliderHueValue = originalHue;
    }

    return (
        <>
            {contentOnly ? (
                <HslRecolorizationSliders
                    value={{ h: sliderHueValue, s: saturationMultiplier, l: lightnessMultiplier }}
                    onChange={onChange}
                    onReset={onReset}
                    setSliding={handleSliding}
                    className={className}
                />
            ) : (
                <HslRecolorizationExpandingButton
                    value={{ h: sliderHueValue, s: saturationMultiplier, l: lightnessMultiplier }}
                    onChange={onChange}
                    onReset={onReset}
                    setSliding={handleSliding}
                    displayType={displayType}
                    className={className}
                />
            )}

            {showPreview && (
                <RecolorizerFilter
                    imageViewModel={imageViewModel}
                    hueOffset={hueOffset}
                    hueMultiplier={hueMultiplier}
                    saturationMultiplier={saturationMultiplier}
                    lightnessMultiplier={lightnessMultiplier}
                />
            )}
        </>
    );
}
HslRecolorization.displayName = "HslRecolorization";
