import React, { ReactNode, useCallback } from "react";
import { BrickStateProvider, SmartValidationsConfigProvider } from "@design-stack-vista/smart-validations-ui";
import {
    UploadsProvider,
    MobileTextProvider,
    useActiveCanvas,
    getDesignDocumentFromDesigner,
    HistoryProvider
} from "@easel";
import { TrackEventsProvider } from "@shared/features/Tracking";
import {
    ImageLibraryProvider,
    StockImagesProvider,
    ClipartProvider,
    setImageLibraryUrl,
    DEFAULT_PAGE_SIZE
} from "@design-stack-vista/image-library-react";
import { RecentFontsProvider, RecentColorsProvider } from "@presentational";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { deleteUpload as deleteUploadItem, parseIdFromUrl } from "@shared/features/UploadsAndAssets";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { CanvasValidationsProvider } from "@shared/features/Validations";
import { MailingServicesProvider } from "@shared/features/MailingServices";
import { ZoomManagerProvider } from "src/easel/designer-suite/ZoomToolbar/ZoomManagerContext";
import { SurfaceSpecsProvider } from "@shared/features/SurfaceSpecifications";
import { PricingProvider } from "@shared/features/Pricing";
import { DesignDialogProvider } from "@shared/features/DesignPanel";
import { UpsellProvider } from "@shared/features/Product";
import { KeyboardShortcutsProvider } from "@shared/features/KeyboardShortcuts";
import { GalleryProvider } from "@shared/features/ChangeTemplate";
import { ElementsPanelProvider } from "../xerox/features/Elements";
import { SwitchProductsProvider } from "./contexts/SwitchProductsContext";
import { StudioLiveLocaleRestrictionProvider } from "./contexts/StudioLiveLocaleRestrictionProvider";
import { ValidationProvider } from "./Validation/ValidationProvider";
import { SherbertUploadsWrapper } from "./SherbertUploadsWrapper";
import { TemplatePanelProvider } from "./Panels/TemplatePanel/TemplatePanelProvider";
import { Studio5DesignRequirementsProvider } from "./Studio5DesignRequirementsProvider";
import { useTriggerCanvasChange } from "../hooks/useTriggerCanvasChange";
import { useDesignErrors } from "../hooks/useDesignErrors";
import { Studio5PreviewsContextAdapter } from "./contexts/Studio5PreviewsContextAdapter";
import { useLoadNewDesignForApplyOptionStudio5 } from "../hooks/useLoadNewDesignForApplyOptionStudio5";
import { ActiveFlexibilityProviderStudio5 } from "./contexts/ActiveFlexibilityProviderStudio5";
import { ImageGalleryPreviewProvider } from "./Panels/Images/ImageGalleryPreviewProvider";

type Props = {
    children: ReactNode | ReactNode[];
};

const { imageLibraryUrl } = getQueryParams();
if (imageLibraryUrl) {
    setImageLibraryUrl(`${imageLibraryUrl}/v1`);
}

export const StudioGlobalProvider = (props: Props) => {
    const { children } = props;
    const locale = useAppSelector(state => state.locale);
    const { auth } = useIdentityContext();
    const activeCanvas = useActiveCanvas();
    const triggerCanvasChange = useTriggerCanvasChange();
    const designErrors = useDesignErrors();
    const hasDesigns = useAppSelector(state => state.hasDesigns);

    const deleteUpload = useCallback(
        (uploadUrl): void => {
            const authToken = auth.getToken();
            deleteUploadItem(authToken, parseIdFromUrl(uploadUrl));
        },
        [auth]
    );

    const getDocumentWithPlaceholders = useCallback(async () => getDesignDocumentFromDesigner(false), []);
    const loadNewDesignForApplyOption = useLoadNewDesignForApplyOptionStudio5();

    return (
        <Studio5DesignRequirementsProvider>
            <TrackEventsProvider fireEventTracker={fireDesignToolTrackingEvent}>
                <SmartValidationsConfigProvider config={{ hasSelectedBrickStyle: false }}>
                    <ValidationProvider>
                        <BrickStateProvider>
                            <UpsellProvider>
                                <DesignDialogProvider
                                    activeCanvasName={activeCanvas?.name}
                                    triggerCanvasChange={triggerCanvasChange}
                                    getDocument={getDocumentWithPlaceholders}
                                    loadNewDesign={loadNewDesignForApplyOption}
                                >
                                    <Studio5PreviewsContextAdapter>
                                        <ImageLibraryProvider locale={locale}>
                                            <StockImagesProvider
                                                authToken={auth && auth.getToken()}
                                                locale={locale}
                                                pageSize={DEFAULT_PAGE_SIZE}
                                            >
                                                <ClipartProvider authToken={auth && auth.getToken()} locale={locale}>
                                                    <UploadsProvider deleteUpload={deleteUpload}>
                                                        <MobileTextProvider>
                                                            <StudioLiveLocaleRestrictionProvider>
                                                                <RecentFontsProvider>
                                                                    <RecentColorsProvider>
                                                                        <MailingServicesProvider>
                                                                            <SwitchProductsProvider>
                                                                                <KeyboardShortcutsProvider>
                                                                                    <SurfaceSpecsProvider>
                                                                                        <PricingProvider>
                                                                                            <ActiveFlexibilityProviderStudio5>
                                                                                                <ImageGalleryPreviewProvider>
                                                                                                    <SherbertUploadsWrapper>
                                                                                                        <GalleryProvider
                                                                                                            isLive={
                                                                                                                hasDesigns
                                                                                                            }
                                                                                                        >
                                                                                                            <TemplatePanelProvider>
                                                                                                                <CanvasValidationsProvider
                                                                                                                    designErrors={
                                                                                                                        designErrors
                                                                                                                    }
                                                                                                                    activeCanvasName={
                                                                                                                        activeCanvas?.name
                                                                                                                    }
                                                                                                                >
                                                                                                                    <ElementsPanelProvider>
                                                                                                                        <ZoomManagerProvider>
                                                                                                                            <HistoryProvider>
                                                                                                                                {
                                                                                                                                    children
                                                                                                                                }
                                                                                                                            </HistoryProvider>
                                                                                                                        </ZoomManagerProvider>
                                                                                                                    </ElementsPanelProvider>
                                                                                                                </CanvasValidationsProvider>
                                                                                                            </TemplatePanelProvider>
                                                                                                        </GalleryProvider>
                                                                                                    </SherbertUploadsWrapper>
                                                                                                </ImageGalleryPreviewProvider>
                                                                                            </ActiveFlexibilityProviderStudio5>
                                                                                        </PricingProvider>
                                                                                    </SurfaceSpecsProvider>
                                                                                </KeyboardShortcutsProvider>
                                                                            </SwitchProductsProvider>
                                                                        </MailingServicesProvider>
                                                                    </RecentColorsProvider>
                                                                </RecentFontsProvider>
                                                            </StudioLiveLocaleRestrictionProvider>
                                                        </MobileTextProvider>
                                                    </UploadsProvider>
                                                </ClipartProvider>
                                            </StockImagesProvider>
                                        </ImageLibraryProvider>
                                    </Studio5PreviewsContextAdapter>
                                </DesignDialogProvider>
                            </UpsellProvider>
                        </BrickStateProvider>
                    </ValidationProvider>
                </SmartValidationsConfigProvider>
            </TrackEventsProvider>
        </Studio5DesignRequirementsProvider>
    );
};

StudioGlobalProvider.displayName = "StudioGlobalProvider";
