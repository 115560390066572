import { defineMessages } from "@vp/i18n-helper";

export const defaultDocumentText = defineMessages({
    textualPlaceholders: {
        id: "easel.ui.wordArtField.placeholderText",
        defaultMessage: "Type text here",
        description: {
            note: "Prompt for user to enter text"
        }
    }
});
