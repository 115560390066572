import { useState, RefObject, useLayoutEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export function useClientRect(ref: RefObject<HTMLElement>) {
    const [rect, setRect] = useState<DOMRect>({
        x: 0,
        y: 0,
        left: 0,
        top: 0,
        bottom: 0,
        height: 0,
        right: 0,
        width: 0,
        toJSON: () => ""
    });

    useLayoutEffect(() => {
        let resizeObserver: ResizeObserver;
        if (ref.current) {
            resizeObserver = new ResizeObserver(entries => {
                const element = entries.find(entry => entry.target === ref.current);
                if (element) {
                    setRect(element.target.getBoundingClientRect());
                }
            });

            resizeObserver.observe(ref.current);
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [ref, ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

    return rect;
}
