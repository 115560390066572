import React, { useCallback, useRef } from "react";
import classnames from "classnames";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { Icon } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useActiveDialog, DialogType, useFloatingSidebarHighlightShowing } from "@shared/features/ActiveDialog";
import { ContentPanel } from "../../../../easel/designer-suite/Sidebar";
import "./floatingSidebarPanel.scss";
import { useFloatingSidebarPanelConfiguration } from "./useFloatingSidebarPanelConfiguration";

type Props = {
    className?: string;
};

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: "studio.floatingSidebarPanel.closeButtonAriaLabel",
        defaultMessage: "Close Sidebar Panel.",
        description: {
            note: "Aria label for the close panel for the sidebar panel."
        }
    }
});

export const FloatingSidebarPanel = (props: Props) => {
    const { className } = props;
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { isMedium } = useStudioLayout();
    const { t } = useTranslationSSR();

    const handleCloseClick = useCallback(() => {
        setCurrentActiveDialog(DialogType.None);
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLOSE_SIDEBAR_PANEL,
            // @ts-ignore
            label: "Close Sidebar Panel"
        });
    }, [setCurrentActiveDialog]);

    const highlightShowing = useFloatingSidebarHighlightShowing();

    const { dialogTypeComponents } = useFloatingSidebarPanelConfiguration();

    const Component = dialogTypeComponents[currentActiveDialog];

    /**
     * Change close button styles when scroll panel content
     */

    const panelRef = useRef<HTMLDivElement>(null);

    return (
        <ContentPanel
            ref={panelRef}
            className={classnames(className, "floating-sidebar-panel", "button-icon-visibility", {
                "floating-sidebar-panel-highlight": highlightShowing
            })}
            contentType={currentActiveDialog}
        >
            {isMedium && (
                <button
                    aria-label={t(messages.closeButtonAriaLabel.id)}
                    className={"floating-sidebar-panel-bt-close"}
                    onClick={handleCloseClick}
                >
                    <Icon size="10p" iconType="closeSmall" />
                </button>
            )}
            {Component}
        </ContentPanel>
    );
};

FloatingSidebarPanel.displayName = "FloatingSidebarPanel";
