import React from "react";
import { RemoveBackgroundButton } from "@shared/features/ContextualToolbar";

interface Props {
    /** Click handler for knocking background out */
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    /** Whether or not the image is being processed to be knocked out */
    isLoading: boolean;
    isBackgroundRemoved: boolean;
    /** Whether the button should show up in the disabled state or not */
    disabled: boolean;
    /** For overriding styles */
    className?: string;
    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

/**
 * This button is responsible for knocking the background out of an image
 */
export function KnockoutButton({ onClick, isLoading, isBackgroundRemoved, className, showIcon, disabled }: Props) {
    return (
        <RemoveBackgroundButton
            showIcon={showIcon}
            onClick={onClick}
            isLoading={isLoading}
            className={className}
            isDisabled={disabled}
            isBackgroundRemoved={isBackgroundRemoved}
        />
    );
}
KnockoutButton.displayName = "KnockoutButton";
