import React from "react";
import { useRecentColorsContext } from "@presentational";

import { useDesigner, useSelection } from "@designer-suite";
import { updateSelectedItems, getReferenceData, teamsPlaceholderFilter, updateTeamsItemData } from "@utilities";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import type { Designer } from "src/easel/designer-suite/@types/designer";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { colorToString } from "@shared/features/ColorPicker";
import { areCustomColorsAllowed, getColorPalette } from "../../../../easel/designer-suite/util";
import { ColorPickerPanelWrapper } from "../ColorPicker/ColorPickerPanelWrapper";
import { COLOR_PICKER_PANEL_TYPES } from "../ColorPicker/colorPickerPanelTypes";

const messages = defineMessages({
    textColorTitle: {
        id: "studio.components.panels.textColorpickerPanel.title",
        defaultMessage: "Text color",
        description: {
            note: "This is the title for a panel that allows the user to change their text's color"
        }
    }
});

function getSelectionTeamsColor(designer: Designer, teamsPlaceholderItems: Item[]) {
    if (!designer || !teamsPlaceholderItems || teamsPlaceholderItems.length === 0) {
        return "";
    }

    const firstItemColor = getReferenceData(teamsPlaceholderItems[0]).color;
    const allEqual = teamsPlaceholderItems.every(item => getReferenceData(item).color === firstItemColor);
    return allEqual ? firstItemColor : "";
}

export function TeamsFontColorPickerPanel({ allowCustom = true, contentOnly = false }) {
    const designer = useDesigner();
    const selection = useSelection(`model:change:data`);
    const { teamsPlaceholderItems, otherItems } = teamsPlaceholderFilter(selection);
    const { recentColors } = useRecentColorsContext();
    const { t } = useTranslationSSR();

    if (!designer || !teamsPlaceholderItems.length || otherItems.length) {
        return null;
    }

    const value = getSelectionTeamsColor(designer, teamsPlaceholderItems);
    // selection is used to look up current canvas - doesn't matter what the type is
    const paletteColors = getColorPalette(designer, selection[0]);

    function onChange(newValue: string) {
        const newColor = colorToString(newValue);

        updateSelectedItems(designer, teamsPlaceholderItems, teamsPlaceholder =>
            updateTeamsItemData(teamsPlaceholder, { color: newColor })
        );
    }

    const allowCustomColors = allowCustom && areCustomColorsAllowed(designer, selection[0]);

    const eyeDropperConfig = {
        isCompatibleWithSelection: () => {
            return allowCustom && areCustomColorsAllowed(designer, selection[0]);
        },
        listenEvents: "model:change:data",
        buttonSize: "mini" as const,
        onClick: onChange
    };

    return contentOnly ? (
        <ColorPickerPanelWrapper
            value={value}
            recentColors={recentColors}
            paletteColors={paletteColors}
            onChange={onChange}
            allowCustom={allowCustomColors}
            eyeDropperConfig={eyeDropperConfig}
            panelType={COLOR_PICKER_PANEL_TYPES.TEAMS_TEXT_COLOR}
        />
    ) : (
        <PanelContent className="studio-color-panel-content" data-dcl-prevent-canvas-items-deselection>
            <DesktopExperience>
                <div className="color-panel__sticky-nav">
                    <PanelTitle>{t(messages.textColorTitle.id)}</PanelTitle>
                </div>
            </DesktopExperience>
            <div className="studio-wordart-text-color-picker">
                <ColorPickerPanelWrapper
                    value={value}
                    recentColors={recentColors}
                    paletteColors={paletteColors}
                    onChange={onChange}
                    allowCustom={allowCustomColors}
                    eyeDropperConfig={eyeDropperConfig}
                    panelType={COLOR_PICKER_PANEL_TYPES.TEAMS_TEXT_COLOR}
                />
            </div>
        </PanelContent>
    );
}

TeamsFontColorPickerPanel.displayName = "TeamsFontColorPickerPanel";
