import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { TextInput, TextInputWithButtonInset } from "@vp/swan";
import { Button, SearchIcon, SearchResetIcon } from "@shared/features/StudioChrome";
import { useTrackingClient } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS, Events } from "@shared/utils/Tracking";
import { useAITemplates } from "./AITemplatesProvider";
import * as styles from "./AITemplateSearchBar.module.scss";
import { useAITemplateTracking } from "./useAITemplateTracking";

export function AITemplatesSearch() {
    const { t } = useTranslationSSR();
    const {
        searchTerm,
        setSearchTerm,
        searchSimtagAITemplates,
        setIsSearchTextLimitExceeded,
        showSearchLimitErrorState,
        aiTemplatesPanelMessages
    } = useAITemplates();
    const { tracking } = useAITemplateTracking();

    const trackingClient = useTrackingClient();
    const handleOnSearchChange = (event: { target: HTMLInputElement }) => {
        const { value } = event.target as HTMLInputElement;
        setSearchTerm(value);
    };

    const searchTemplates = (searchTerm: string) => {
        if (searchTerm.length > 100) {
            showSearchLimitErrorState();
        } else if (searchTerm) {
            setIsSearchTextLimitExceeded(false);
            searchSimtagAITemplates(searchTerm, false);
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: STUDIO_TRACKING_EVENTS.SUBMIT_AI_TEMPLATE_SEARCH_TERM.replace("SEARCH_TERM", searchTerm),
                label: tracking.label
            });
        } else {
            setSearchTerm("");
        }
    };

    const handleSearch = () => {
        searchTemplates(searchTerm);
    };

    const resetSearchInputField = () => {
        setSearchTerm("");
    };

    return (
        <TextInputWithButtonInset skin="searchbar" mb={4}>
            <TextInput
                value={searchTerm}
                placeholder={t(aiTemplatesPanelMessages.searchPlaceholderText.id)}
                title={t(aiTemplatesPanelMessages.searchPlaceholderText.id)}
                onKeyPress={(event: { key: string }) => {
                    if (event.key === "Enter") handleSearch();
                }}
                onChange={handleOnSearchChange}
                className={styles.textInput}
                data-testid="aiTemplatesSearchInput"
            />
            {searchTerm && (
                <Button
                    onClick={resetSearchInputField}
                    aria-label={t(aiTemplatesPanelMessages.aiTemplateSearchResetButtonLabel.id)}
                    data-testid="aiTemplatesSearchClear"
                    className={styles.aiSearchResetButton}
                >
                    <SearchResetIcon />
                </Button>
            )}
            <Button
                onClick={() => handleSearch()}
                className={styles.aiSearchButton}
                aria-label={t(aiTemplatesPanelMessages.searchAITemplatesButtonLabel.id)}
                data-testid="aiTemplatesSearchSubmit"
            >
                <SearchIcon />
            </Button>
        </TextInputWithButtonInset>
    );
}

AITemplatesSearch.displayName = "AITemplatesSearch";
