import React from "react";
import { PolygonShape, ShapeTypes } from "@cimpress-technology/svg-path";

import { DraggableCurve } from "./DraggableCurve";

const metadata: PolygonShape = {
    type: ShapeTypes.Polygon,
    numberOfSides: 3,
    borderRadius: 0
};

export const DraggableTriangle = () => {
    return <DraggableCurve metadata={metadata} />;
};

DraggableTriangle.displayName = "DraggableTriangle";
