import { useAppSelector, useAppDispatch, AlertSkin, resetAlerts, autoClosableAlert, setAlerts } from "@shared/redux";
import { formatPrice, Sign } from "@shared/utils/Pricing";
import { BLANK_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import React, { createContext, useCallback, useContext } from "react";
import { StudioPanelDesignRequirements } from "../DesignRequirementsProvider";

interface PisplayPricingToastsConfig {
    canvasName: string;
    newDocumentSourceType: string;
    oldDocumentSourceType: string;
}

const GetCanvasUpsellContext = createContext<
    | ((canvasName: string) =>
          | {
                listPrice: number;
                discountPrice: number;
            }
          | undefined)
    | undefined
>(undefined);

export function useGetCanvasUpsellContext() {
    const context = useContext(GetCanvasUpsellContext);
    if (context === undefined) {
        throw new Error("useGetCanvasUpsellContext must be used within an UpsellProvider");
    }
    return context;
}

const ShowPricingChangesContext = createContext<((config: PisplayPricingToastsConfig) => void) | undefined>(undefined);

export function useShowPricingChangesContext() {
    const context = useContext(ShowPricingChangesContext);
    if (context === undefined) {
        throw new Error("useShowPricingChangesContext must be used within an UpsellProvider");
    }
    return context;
}

const IsPaidUpsellContext = createContext<((canvasName: string) => boolean) | undefined>(undefined);

export function useIsPaidUpsellContext() {
    const context = useContext(IsPaidUpsellContext);
    if (context === undefined) {
        throw new Error("useIsPaidUpsellContext must be used within an UpsellProvider");
    }
    return context;
}

const NeedAddButtonContext = createContext<
    ((panel: StudioPanelDesignRequirements, name: string) => boolean) | undefined
>(undefined);

export function useNeedAddButtonContext() {
    const context = useContext(NeedAddButtonContext);
    if (context === undefined) {
        throw new Error("useNeedAddButtonContext must be used within an UpsellProvider");
    }
    return context;
}

export const UpsellProvider = (props: React.PropsWithChildren<{}>) => {
    const surfaceUpsells = useAppSelector(state => state.surfaceUpsells);
    const locale = useAppSelector(state => state.locale);
    const currency = useAppSelector(state => state.currency);
    const dispatch = useAppDispatch();

    const getUpsellsByCanvasName = useCallback(
        (canvasName: string) => {
            const dialogSurfaceUpsell = surfaceUpsells[canvasName];
            if (!dialogSurfaceUpsell) {
                return undefined;
            }
            const { pricing: allPricing, colorOption } = dialogSurfaceUpsell;
            if (!allPricing || !colorOption) {
                return undefined;
            }
            const pricing = allPricing[colorOption];
            if (!pricing) {
                return undefined;
            }
            return {
                listPrice: pricing.differentialListPrice,
                discountPrice: pricing.differentialDiscountPrice
            };
        },
        [surfaceUpsells]
    );

    const isPaidUpsell = useCallback(
        (canvasName: string) => {
            const surfaceUpsell: any = surfaceUpsells[canvasName];
            if (surfaceUpsell) {
                const { pricing } = surfaceUpsell;
                if (pricing) {
                    const currentColorPricing = pricing[surfaceUpsell.colorOption];
                    if (currentColorPricing) {
                        return currentColorPricing.differentialListPrice !== 0;
                    }
                }
            }
            return false;
        },
        [surfaceUpsells]
    );

    const displayPricingToasts = useCallback(
        (config: PisplayPricingToastsConfig) => {
            const { canvasName: panelName, newDocumentSourceType, oldDocumentSourceType: oldSourceType } = config;
            const discountPrice = getUpsellsByCanvasName(panelName)?.discountPrice;

            if (discountPrice) {
                const makeAlert = (key: string, sign: string, skin: AlertSkin) => ({
                    alerts: [
                        {
                            key,
                            variables: {
                                pricing: `${sign}${formatPrice(discountPrice, locale, currency)}`
                            },
                            skin
                        }
                    ]
                });

                dispatch(resetAlerts());
                dispatch(autoClosableAlert());
                const isBackSide = panelName === "Back";
                // When the state transitions from a blank design to a template, custom or a fullbleed
                if (
                    newDocumentSourceType !== BLANK_SELECTED_TEMPLATE &&
                    (oldSourceType === BLANK_SELECTED_TEMPLATE || !oldSourceType)
                ) {
                    dispatch(
                        setAlerts(
                            makeAlert(
                                isBackSide
                                    ? "studio.components.Toast.upsellMessageForBackSide"
                                    : "studio.components.Toast.upsellMessageForDefault",
                                Sign.POSITIVE,
                                "positive"
                            )
                        )
                    );
                }
                // When the state transitions from a template, custom or a fullbleed to blank design
                if (
                    newDocumentSourceType === BLANK_SELECTED_TEMPLATE &&
                    oldSourceType &&
                    oldSourceType !== BLANK_SELECTED_TEMPLATE
                ) {
                    dispatch(
                        setAlerts(
                            makeAlert(
                                isBackSide
                                    ? "studio.components.Toast.upsellMessageForBlankBackSide"
                                    : "studio.components.Toast.upsellMessageForBlankDefault",
                                Sign.NEGATIVE,
                                "positive"
                            )
                        )
                    );
                }
            }
        },
        [currency, dispatch, getUpsellsByCanvasName, locale]
    );

    const needAddButton = useCallback(
        (panel: StudioPanelDesignRequirements, name: string) => {
            const surfaceUpsell = surfaceUpsells[name];
            const isCanvasBlank = panel.colorMode === "blank";
            return isCanvasBlank && surfaceUpsell && isPaidUpsell(name);
        },
        [isPaidUpsell, surfaceUpsells]
    );

    return (
        <GetCanvasUpsellContext.Provider value={getUpsellsByCanvasName}>
            <ShowPricingChangesContext.Provider value={displayPricingToasts}>
                <IsPaidUpsellContext.Provider value={isPaidUpsell}>
                    <NeedAddButtonContext.Provider value={needAddButton}>
                        {props.children}
                    </NeedAddButtonContext.Provider>
                </IsPaidUpsellContext.Provider>
            </ShowPricingChangesContext.Provider>
        </GetCanvasUpsellContext.Provider>
    );
};

UpsellProvider.displayName = "UpsellProvider";
