import React, { useEffect, useState, useMemo, useRef } from "react";
import classNames from "classnames";
import {
    Typography,
    Button,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogTitle,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter
} from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    StudioModalDialogContent,
    ScanQRCodeIcon,
    SelectPhotosMobileIcon,
    UseThemHereIcon,
    ScanQRModalArrowIcon,
    LoadingSpinner
} from "@shared/features/StudioChrome";
import * as uploadQRModalStyles from "./UploadQRModal.module.scss";

const messages = defineMessages({
    closeButton: {
        id: "easel.components.dialog.uploadQRDialog.closeButton",
        defaultMessage: "Close Upload photos from phone Dialog"
    },
    modalDialogTitle: {
        id: "easel.components.dialog.uploadQRDialog.modalDialogTitle",
        defaultMessage: "Add photos from your phone"
    },
    scanQRInfo: {
        id: "easel.components.dialog.uploadQRDialog.scanQRInfo",
        defaultMessage: "Scan the QR Code below"
    },
    selectPhotosInfo: {
        id: "easel.components.dialog.uploadQRDialog.selectPhotosInfo",
        defaultMessage: "Upload photos from your phone"
    },
    useThemHereInfo: {
        id: "easel.components.dialog.uploadQRDialog.useThemHereInfo",
        defaultMessage: "Use them on this design"
    },
    expireText: {
        id: "easel.components.dialog.uploadQRDialog.expireText",
        defaultMessage: "Your upload link expires in [M]m [S]s"
    },
    regenerateButton: {
        id: "easel.components.dialog.uploadQRDialog.regenerateButton",
        defaultMessage: "Regenerate QR"
    },
    confirmButton: {
        id: "easel.components.dialog.uploadQRDialog.confirmButton",
        defaultMessage: "Confirm"
    },
    generatingQRText: {
        id: "easel.components.dialog.uploadQRDialog.generatingQRText",
        defaultMessage: "Creating new QR Code"
    }
});

type Props = {
    requestGenerateQR: () => void;
    qrCodeData?: {
        qrCode: string;
        expiry: string;
    };
    showUploadQRModal?: boolean;
    requestCloseModal?: () => void;
};

export const UploadQRModal = ({ requestGenerateQR, qrCodeData, showUploadQRModal, requestCloseModal }: Props) => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const autoRegenrateQR = useRef(true);
    const handleCloseModal = () => {
        requestCloseModal && requestCloseModal();
    };

    const [time, setTime] = useState(0);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;
        if (qrCodeData?.expiry) {
            const expirtyMs = +new Date(qrCodeData?.expiry) - +new Date();
            if (expirtyMs > 0) {
                setReady(true);
                interval = setInterval(() => {
                    if (qrCodeData.qrCode) {
                        setTime((+new Date(qrCodeData?.expiry) - +new Date()) / 1000);
                    } else {
                        setTime(0);
                    }
                }, 1000);
            }
        }
        return () => {
            clearInterval(interval);
        };
    }, [qrCodeData?.expiry, qrCodeData?.qrCode, setTime]);

    useEffect(() => {
        if (showUploadQRModal && autoRegenrateQR.current && !qrCodeData?.expiry) {
            autoRegenrateQR.current = false;
            setReady(false);
            requestGenerateQR();
        }
        if (!showUploadQRModal && !qrCodeData?.expiry) {
            autoRegenrateQR.current = true;
        }
    }, [showUploadQRModal, requestGenerateQR, qrCodeData?.expiry]);

    const formatedTime = useMemo(
        () => ({
            min: Math.floor(time / 60)
                .toString()
                .padStart(2, "0"),
            sec: Math.floor(time % 60)
                .toString()
                .padStart(2, "0")
        }),
        [time]
    );

    return (
        <>
            <LegacyModalDialog
                isOpen={showUploadQRModal}
                onRequestDismiss={handleCloseModal}
                variant={isSmall ? "panel-bottom" : "standard"}
                className={classNames({
                    "light-back-modal": showUploadQRModal
                })}
                data-dcl-prevent-canvas-items-deselection
            >
                <StudioModalDialogContent
                    fullBleed={false}
                    aria-labelledby="uploadQRModal"
                    data-dcl-prevent-canvas-items-deselection
                    className={uploadQRModalStyles.modalContent}
                >
                    <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.closeButton.id)} />
                    <LegacyModalDialogHeader>
                        <LegacyModalDialogTitle>
                            <Typography fontSize={4} weight={"bold"}>
                                {t(messages.modalDialogTitle.id)}
                            </Typography>
                        </LegacyModalDialogTitle>
                    </LegacyModalDialogHeader>
                    <LegacyModalDialogBody>
                        <div className={uploadQRModalStyles.infoIconsBox}>
                            <div className={uploadQRModalStyles.infoIcon}>
                                <div className={uploadQRModalStyles.infoIconContainer1}>
                                    <ScanQRCodeIcon />
                                </div>
                                <Typography fontSize={1} textAlign={"center"}>
                                    {t(messages.scanQRInfo.id)}
                                </Typography>
                            </div>
                            <div className={uploadQRModalStyles.infoArrow}>
                                <ScanQRModalArrowIcon />
                            </div>
                            <div className={uploadQRModalStyles.infoIcon}>
                                <div className={uploadQRModalStyles.infoIconContainer1}>
                                    <SelectPhotosMobileIcon />
                                </div>
                                <Typography fontSize={1} textAlign={"center"}>
                                    {t(messages.selectPhotosInfo.id)}
                                </Typography>
                            </div>
                            <div className={uploadQRModalStyles.infoArrow}>
                                <ScanQRModalArrowIcon />
                            </div>
                            <div className={uploadQRModalStyles.infoIcon}>
                                <div className={uploadQRModalStyles.infoIconContainer2}>
                                    <UseThemHereIcon />
                                </div>
                                <Typography fontSize={1} textAlign={"center"}>
                                    {t(messages.useThemHereInfo.id)}
                                </Typography>
                            </div>
                        </div>
                        <div className={uploadQRModalStyles.qrSpace}>
                            {ready &&
                                (qrCodeData?.qrCode ? (
                                    <img
                                        className={uploadQRModalStyles.qrImage}
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCodeData.qrCode)}`}
                                        alt="qrcode"
                                    />
                                ) : (
                                    <Button
                                        skin="primary"
                                        size="mini"
                                        onClick={() => {
                                            requestGenerateQR();
                                            setReady(false);
                                        }}
                                        className="py-3 px-5 m-0 ml-7"
                                    >
                                        <Typography fontSize={1}>{t(messages.regenerateButton.id)}</Typography>
                                    </Button>
                                ))}
                            {!ready && (
                                <>
                                    <LoadingSpinner className="embroidery-image-color-modal__loading-spinner" />
                                    <Typography fontSize={1} marginTop={2} textAlign={"center"}>
                                        {t(messages.generatingQRText.id)}
                                    </Typography>
                                </>
                            )}
                        </div>
                    </LegacyModalDialogBody>
                    <LegacyModalDialogFooter>
                        {(parseInt(formatedTime?.sec, 10) > 0 || parseInt(formatedTime?.min, 10) > 0) && ready && (
                            <Typography fontSize={1} marginBottom={2} marginTop={2} textAlign={"center"}>
                                {t(messages.expireText.id)
                                    .replace("[M]", formatedTime.min)
                                    .replace("[S]", formatedTime.sec)}
                            </Typography>
                        )}
                    </LegacyModalDialogFooter>
                </StudioModalDialogContent>
            </LegacyModalDialog>
        </>
    );
};

UploadQRModal.displayName = "UploadQRModal";
