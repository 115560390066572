import React, { useCallback, useEffect, useState } from "react";
import { ContextualTextEditor, useDesigner } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { getActiveCanvas } from "../../utilities/canvasUtilities";
import { useLayoutManager } from "./LayoutManager";
import { getTopContainerOffset } from "./Canvas";

export const ContextualTextEditorContainer = () => {
    const { leftRect, topRect, contextualRect, canvasRef } = useLayoutManager();
    const designer = useDesigner();
    const [maxTop, setMaxTop] = useState<number>();
    const { isSmall } = useStudioLayout();
    const canvasRect = canvasRef?.current?.getBoundingClientRect();

    const onScroll = useCallback(() => {
        const delta = window.scrollY;
        const newTop = delta > 0 ? delta : undefined;
        setMaxTop(newTop);
    }, []);

    useEffect(() => {
        document.onscroll = onScroll;
    }, [onScroll]);

    if (!designer) {
        return null;
    }

    const activeCanvas = getActiveCanvas();

    if (!activeCanvas) {
        return null;
    }

    const canvas = document.getElementById(activeCanvas.id);
    const rect = canvas?.getBoundingClientRect();

    const canvasElementTop = rect?.top || 0;
    const canvasElementLeft = rect?.left || 0;

    const top = canvasElementTop - (canvasRect?.top || 0);
    const left = canvasElementLeft - (canvasRect?.left || 0);

    const topContainerOffset = getTopContainerOffset(isSmall);

    return (
        <div
            style={{
                left: leftRect.width + left,
                top: contextualRect.height + topRect.height + topContainerOffset + top,
                position: "absolute"
            }}
        >
            <ContextualTextEditor
                toolbarId="studio-contextual-toolbar-container"
                autoPosition={true}
                autoMaxTop={false}
                maxTop={maxTop}
            />
        </div>
    );
};
ContextualTextEditorContainer.displayName = "ContextualTextEditorContainer";
