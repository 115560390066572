import React, { useState, ReactElement, useCallback, useRef } from "react";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { TrashCanIcon } from "@shared/features/StudioChrome";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { useContextualToolbar } from "../designer-suite/ContextualToolbarProvider";
import "./itemRow.scss";

const messages = defineMessages({
    deleteText: {
        id: "studio.ui.itemrow.deleteText",
        defaultMessage: "Delete Text Field"
    },
    toolbarLabel: {
        id: "studio.ui.itemrow.toolbarLabel",
        defaultMessage: "Format text",
        description: {
            note: "Label for toolbar"
        }
    },
    deleteTextWithIndex: {
        id: "studio.ui.itemrow.deleteTextWithIndex",
        defaultMessage: "Delete Text Field [[index]]"
    }
});

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /** What text editor to use in this component */
    editor: ReactElement;
    /** What title the row should have */
    title?: string;
    /** Whether or not to show the trash icon and the callback for it */
    onClickDelete?: () => void;
    /** Callback to deselect text on canvas when tabbing to trash icon */
    onTabDelete?: () => void;
    /** Whether or not to show the finishes icon */
    showFinishesIcon?: boolean;
    /** For overriding styles */
    className?: string;
    fieldNumber?: string;
}

const StudioContextualToolbarFloating = loadable(
    () => loadableRetry(() => import("../designer-suite/ContextualToolbarFloating")),
    {
        resolveComponent: components => components.ContextualToolbarFloating
    }
);

// Renders an Item Row with the given editor
export function ItemRow({
    editor,
    title,
    onClickDelete,
    onTabDelete,
    showFinishesIcon = false,
    className,
    fieldNumber = "",
    ...rest
}: Props) {
    const { t } = useTranslationSSR();
    const [isFocused, setIsFocused] = useState(false);
    const [toolbarFocus, setToolbarFocus] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const toobarRef = useRef<HTMLDivElement>(null);
    const { activeInputField, setActiveInputField } = useContextualToolbar();
    /*
        Since the focus event we are listening to is a child element (RichTextFieldRow, WordArtFieldRow, etc.)
        within the easel-components-item-row-editor div, timeouts are needed for controlling when we cancel
        the blur/on mouse out event of that child in favor for the delete action here.
    */
    let blurTimeout: ReturnType<typeof setTimeout>;
    let hoverTimeout: ReturnType<typeof setTimeout>;

    function onFocus() {
        if (blurTimeout) {
            clearTimeout(blurTimeout);
        }
        setIsFocused(true);
        setToolbarFocus(true);
        setActiveInputField(Number(fieldNumber));
    }
    function onBlur() {
        blurTimeout = setTimeout(() => setIsFocused(false), 100);
    }

    function onMouseOver() {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }
        setIsHovered(true);
    }

    function onMouseOut() {
        hoverTimeout = setTimeout(() => setIsHovered(false), 50);
    }

    function getItemRowEditorContainerStyle() {
        const itemRowEditorContainerStyle = "studio-components-item-row-editor-container";
        return className ? `${itemRowEditorContainerStyle} ${className}` : itemRowEditorContainerStyle;
    }

    const onKeyDownDelete = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        const { key } = e;
        if (key && key === "Enter" && onClickDelete) {
            onClickDelete();
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onKeyUpHandler = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const { key } = e;
            if (isFocused && key === "Tab") {
                setIsFocused(false);
            }
            if (key === "Tab" && !isFocused) {
                setToolbarFocus(true);
                toobarRef.current?.focus();
            }
        },
        [isFocused]
    );
    const ariaLabel = fieldNumber
        ? t(messages.deleteTextWithIndex.id, { index: fieldNumber })
        : t(messages.deleteText.id);
    return (
        <div className={classNames(getItemRowEditorContainerStyle(), "mx-0")} {...rest}>
            {title}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                className={classNames("studio-components-item-row-editor", {
                    "studio-components-item-row-editor-show-delete-button":
                        activeInputField === Number(fieldNumber) && (isFocused || isHovered || toolbarFocus)
                })}
                onFocus={onFocus}
                onBlur={onBlur}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onKeyUp={onKeyUpHandler}
                data-editor-row
            >
                {editor}
                {toolbarFocus && activeInputField === Number(fieldNumber) && (
                    <div
                        className="toolbar"
                        role="toolbar"
                        ref={toobarRef}
                        tabIndex={0}
                        aria-label={t(messages.toolbarLabel.id)}
                    >
                        <StudioContextualToolbarFloating />
                    </div>
                )}
                {onClickDelete && (
                    <button
                        type="button"
                        aria-label={ariaLabel}
                        onClick={onClickDelete}
                        onKeyDown={onKeyDownDelete}
                        onFocus={onTabDelete}
                        className="editor-delete-text-button"
                    >
                        <TrashCanIcon />
                    </button>
                )}
            </div>
        </div>
    );
}
ItemRow.displayName = "ItemRow";
