import { useDesigner } from "@designer-suite";
import { SHAPE_SIZE_FACTOR } from "@utilities";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "@shared/redux";
import type { DesignerShape } from "src/easel/designer-suite/@types/shapes";
/**
 * This hook is used to add draggability to a shape.
 * It is using some jquery logic to be compatible with the system used in designer
 * If we were to not use it, we would've had to create whole new dragging and dropping logic
 * The $ variable names are to indicate that this was the result of a jquery operation.
 * The code for creating the draggable was lifted from ShapeIconView in designer.
 */
export const useShapeDraggable = (
    itemRef: React.RefObject<HTMLElement>,
    shapes: DesignerShape[],
    isDraggable: boolean,
    onStop?: (event: any) => void
) => {
    const designer = useDesigner();
    const { current } = itemRef;
    const [draggable, setDraggable] = useState<Draggable | undefined>();
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    const addDraggable = useCallback(
        $shape => {
            if (!designer) {
                return;
            }

            const {
                utilities: { Draggable }
            } = designer;
            const newDraggable = new Draggable($shape, {
                data: { shapes },
                stop: onStop,
                helper() {
                    const $clone = $shape.clone().addClass("draggable-shape");

                    const canvasViewModel = designer.documentRepository.getActiveCanvasViewModel();
                    const safeArea = canvasViewModel.get("zoomedSafeArea");

                    const maxSize = Math.min(safeArea.width, safeArea.height);

                    $clone.css({
                        width: `${maxSize * SHAPE_SIZE_FACTOR}px`,
                        height: `${maxSize * SHAPE_SIZE_FACTOR}px`,
                        display: "none"
                    });

                    return $clone;
                },
                updateHelper(options: any) {
                    const height = options.helper.height();
                    const width = options.helper.width();
                    const top = options.inputPosition.top - height / 2;
                    const left = options.inputPosition.left - width / 2;
                    setPosition({ top, left });
                }
            });
            setDraggable(newDraggable);
        },
        [designer, onStop, shapes]
    );

    useEffect(() => {
        if (!easelLoaded || !current || !isDraggable) {
            return;
        }
        // Ignoring the $ below.
        // @ts-ignore
        const $shape = window && window.$ && window.$(current);
        addDraggable($shape);
    }, [easelLoaded, current, addDraggable, isDraggable]);

    useEffect(() => {
        if (draggable && draggable.$helper) {
            const { top, left } = position;
            draggable.$helper.css({
                display: "block",
                transform: `translate(${left}px, ${top}px)`
            });
        }
    }, [draggable, position]);
};
