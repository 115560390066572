import React from "react";
import { ShapeTypes, StarburstShape } from "@cimpress-technology/svg-path";
import { DraggableCurve } from "./DraggableCurve";

const metadata: StarburstShape = {
    type: ShapeTypes.StarBurst,
    numberOfPoints: 5,
    height: 50
};

export const DraggableStarBurst = () => {
    return <DraggableCurve metadata={metadata} />;
};

DraggableStarBurst.displayName = "DraggableStarBurst";
