import React, { HTMLProps, useCallback, useRef } from "react";
import { Shape } from "@cimpress-technology/svg-path";
import { useOnKeyDown } from "@shared/features/Accessibility";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { SvgButton } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

import type { DesignerShape } from "src/easel/designer-suite/@types/shapes";
import { useShapeDraggable } from "../useShapeDraggable";
import { useAddShape } from "../../../hooks/useAddShape";

interface Props extends HTMLProps<HTMLDivElement> {
    shapes: DesignerShape[];
    metadata?: Shape;
    className?: string;
}

export function getRandomPositionInArea(
    { width, height }: Dimensions,
    { width: itemWidth, height: itemHeight }: Dimensions
) {
    return {
        top: (height - itemHeight) * Math.random() + itemHeight / 2,
        left: (width - itemWidth) * Math.random() + itemWidth / 2
    };
}

export const DraggableShape = (props: Props) => {
    const { children, shapes, metadata, className } = props;
    const ref = useRef<HTMLDivElement>(null);
    const { isSmall } = useStudioLayout();
    const { addShape } = useAddShape();

    const onClick = useCallback(() => {
        ref?.current?.focus();
        addShape({ shapes, metadata });
    }, [addShape, shapes, metadata]);
    const onKeyDown = useOnKeyDown(() => onClick());

    const onStop = useCallback(() => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.DRAG_SHAPE_ADD,
            label: "Drag shape"
        });
    }, []);

    useShapeDraggable(ref, shapes, !isSmall, onStop);
    return (
        <SvgButton ref={ref} tabIndex={0} onClick={onClick} onKeyDown={onKeyDown} className={className}>
            {children}
        </SvgButton>
    );
};

DraggableShape.displayName = "DraggableShape";
