import React, { useCallback, useEffect, useState } from "react";
import Gallery from "react-photo-gallery";
import { useTranslationSSR, Trans } from "@vp/i18n-helper";
import {
    DEFAULT_PAGE_SIZE,
    Status,
    StockImagesProvider,
    useStockImages
} from "@design-stack-vista/image-library-react";
import { Typography, FlexBox } from "@vp/swan";
import { DraggableUploadsPanelProvider, useDesigner } from "@designer-suite";
import { LoadingSpinner, SpinnerVariant, InfiniteScrollList } from "@shared/features/StudioChrome";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { useActiveDialog } from "@shared/features/ActiveDialog";
import { ImagesSearchBar } from "./ImagesSearchBar";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";
import { ImageDefaultPanel } from "./ImagesDefaultPanel";
import {
    calculatePanelHeight,
    createGalleryItems,
    DiscoveryPhotoItem,
    updateRecentlyUsedList
} from "./ImagePanelUtils";
import { renderImage } from "./ImageRenderer";
import { ImageSecondGallery } from "./ImageSecondGallery";

interface ImagePanelProps {
    onDone?: () => void;
    fullHeight: boolean;
}

interface PanelHeight extends React.CSSProperties {
    "--discovery-container-height": string;
}

type onNextCallback = (() => void) | undefined;

export const ImageDiscoveryPanel = ({ onDone, fullHeight }: ImagePanelProps) => {
    const [galleryImages, setGalleryImages] = useState<DiscoveryPhotoItem[]>([]);
    const [secondGalleryCount, setSecondGalleryCount] = useState<number>(0);
    const [secondaryGetNext, setSecondaryGetNext] = useState<onNextCallback>(undefined);
    const [secondaryHasMore, setSecondaryHasMore] = useState<boolean>(false);
    const { isSmall } = useStudioLayout();
    const { currentActiveDialog } = useActiveDialog();
    const {
        currentFilterOptions,
        currentSearchTerm,
        performNextSearch,
        status,
        clearSearch,
        imageItems,
        hasMoreItems
    } = useStockImages();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const locale = useAppSelector(state => state.locale);
    const [usingSecondGallery, setUsingSecondGallery] = useState(false);
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const { auth } = useIdentityContext();

    useEffect(() => {
        setGalleryImages([]);
        clearSearch();
    }, [currentActiveDialog, clearSearch]);

    useEffect(() => {
        clearList();
    }, [currentFilterOptions]);

    useEffect(() => {
        if (!designer) {
            return;
        }
        const onUpdate = (options: any) => {
            updateRecentlyUsedList(options.image.requestId);
        };
        designer.eventBus.on(designer.eventBus.events.addImage, onUpdate);
        designer.eventBus.on(designer.eventBus.events.replaceImage, onUpdate);
        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(designer.eventBus.events.addImage, onUpdate);
            designer.eventBus.off(designer.eventBus.events.replaceImage, onUpdate);
        };
    }, [designer]);

    useEffect(() => {
        if (!easelLoaded || !designer || status !== Status.Idle) {
            return;
        }
        let newItems: DiscoveryPhotoItem[] = [];
        if (imageItems?.length) {
            newItems = createGalleryItems(imageItems, designer, onDone);
        }
        setGalleryImages(newItems);
        setUsingSecondGallery(!(!currentFilterOptions || hasMoreItems));
    }, [designer, easelLoaded, onDone, imageItems, status, hasMoreItems, currentFilterOptions]);

    const clearList = () => {
        setGalleryImages([]);
        setSecondGalleryCount(0);
        setSecondaryHasMore(false);
        setSecondaryGetNext(undefined);
        setUsingSecondGallery(false);
    };

    const height = calculatePanelHeight({ isFullHeight: fullHeight, isMobile: isSmall });

    const style: PanelHeight = {
        "--discovery-container-height": `${height}px`
    };

    const resolveEmptyResults = useCallback(() => {
        if (!hasMoreItems && status === Status.Idle) {
            return !currentFilterOptions ? (
                <>
                    <Typography textAlign="center" fontSize="1">
                        <Trans
                            t={t}
                            i18nKey={imageLibraryPanelMessages.resultsNoneNoFilters.id}
                            components={{ bold: <strong /> }}
                            values={{ term: currentSearchTerm }}
                        />
                    </Typography>
                    <Typography mt={1} textAlign="center" fontSize="-1" className="no-search-result-description">
                        {t(imageLibraryPanelMessages.resultsNoneNoFiltersDescription.id)}
                    </Typography>
                </>
            ) : (
                <Typography textAlign="center" fontSize="1">
                    <Trans
                        t={t}
                        i18nKey={imageLibraryPanelMessages.resultsNoneWithFilters.id}
                        components={{ bold: <strong /> }}
                        values={{ term: currentSearchTerm }}
                    />
                </Typography>
            );
        }
        return (
            <FlexBox flexDirection="column">
                <LoadingSpinner className="my-3" variant={SpinnerVariant.Large} centering />
            </FlexBox>
        );
    }, [currentSearchTerm, hasMoreItems, status, currentFilterOptions, t]);

    const onNext = () => {
        performNextSearch();
    };
    const getSecondGalleryInfo = useCallback((count: number, hasMore: boolean) => {
        setSecondGalleryCount(count);
        setSecondaryHasMore(hasMore);
    }, []);

    return (
        <div style={style}>
            <ImagesSearchBar clearList={clearList} />
            <DraggableUploadsPanelProvider isDraggable={!isSmall}>
                {galleryImages.length === 0 && !currentSearchTerm ? (
                    <StockImagesProvider authToken={auth.getToken()} pageSize={DEFAULT_PAGE_SIZE} locale={locale}>
                        <ImageDefaultPanel onDone={onDone} fullHeight={fullHeight} />
                    </StockImagesProvider>
                ) : (
                    <InfiniteScrollList
                        count={galleryImages.length + secondGalleryCount}
                        onNext={() => {
                            if (hasMoreItems) {
                                onNext();
                            } else if (secondaryHasMore) {
                                secondaryGetNext?.();
                            }
                        }}
                        hasMore={hasMoreItems || secondaryHasMore}
                        id={"discover-image-gallery"}
                    >
                        {galleryImages.length === 0 ? (
                            <div className="no-contents-container">{resolveEmptyResults()}</div>
                        ) : (
                            <Gallery
                                photos={galleryImages}
                                direction={"column"}
                                columns={2}
                                renderImage={renderImage}
                                margin={5}
                            />
                        )}
                        {usingSecondGallery && (
                            <>
                                <StockImagesProvider
                                    authToken={auth.getToken()}
                                    pageSize={DEFAULT_PAGE_SIZE}
                                    locale={locale}
                                >
                                    <ImageSecondGallery
                                        searchTerm={currentSearchTerm}
                                        setGalleryItemsCount={getSecondGalleryInfo}
                                        setPeformNextSearch={setSecondaryGetNext}
                                        onDone={onDone}
                                    />
                                </StockImagesProvider>
                            </>
                        )}
                    </InfiniteScrollList>
                )}
            </DraggableUploadsPanelProvider>
        </div>
    );
};

ImageDiscoveryPanel.displayName = "ImageDiscoveryPanel";
