import React, { useRef, useState } from "react";
import { Typography, Dropdown, DropdownOption } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton } from "@shared/features/StudioChrome";
import type { ExpandingButtonHandles } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { DesktopExperience, MobileExperience } from "@shared/features/ResponsiveDesign";
import { WesSortOptions } from "@shared/utils/Work";
import { messages } from "./messages";
import { MyProjectsSortDropdownContent } from "./MyProjectsSortDropdownContent";
import * as styles from "./MyProjectsSortDropdown.module.scss";

interface Props {
    onSort: (sortOption: WesSortOptions) => void;
    sortBy: WesSortOptions;
}
export function MyProjectsSortDropdown({ onSort, sortBy }: Props) {
    const { t } = useTranslationSSR();
    const [selectedSortOption, setSelectedSortOption] = useState<WesSortOptions>(sortBy);
    const [selectedSortTitle, setSelectedSortTitle] = useState<string>(
        sortBy === WesSortOptions.LAST_MODIFIED
            ? t(messages.myProjectsLastEdited.id)
            : t(messages.myProjectsDateCreated.id)
    );

    const buttonRef = useRef<ExpandingButtonHandles>(null);
    const onSortOptionClick = (sortOption: WesSortOptions, sortTitle: string): void => {
        if (selectedSortOption !== sortOption) {
            onSort(sortOption);
            setSelectedSortOption(sortOption);
            setSelectedSortTitle(sortTitle);
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MY_PROJECTS_SORT_OPTION,
                label: "My Projects Sort Option Selected",
                extraData: () => ({ sortTitle })
            });
        }
        buttonRef.current?.close();
    };

    return (
        <>
            <DesktopExperience>
                <ExpandingButton
                    className={styles.dropdown}
                    ref={buttonRef}
                    title={t(messages.myProjectsSortBy.id)}
                    content={
                        <MyProjectsSortDropdownContent
                            onSortOptionClick={onSortOptionClick}
                            selectedOption={selectedSortOption}
                        />
                    }
                    showArrow={true}
                    displayType="multirow"
                    overridePopperAlignment="start"
                    onOpen={() => {
                        fireDesignToolTrackingEvent({
                            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MY_PROJECTS_SORT_DROPDOWN,
                            label: "My Projects Sort Dropdown Clicked"
                        });
                    }}
                >
                    <Typography fontSize="1" className={styles.workname}>
                        {selectedSortTitle}
                    </Typography>
                </ExpandingButton>
            </DesktopExperience>
            <MobileExperience>
                <Dropdown
                    size="mini"
                    value={sortBy}
                    onClick={() => {
                        fireDesignToolTrackingEvent({
                            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MY_PROJECTS_SORT_DROPDOWN,
                            label: "My Projects Sort Dropdown Clicked"
                        });
                    }}
                    onChange={(event: { target: { value: React.SetStateAction<string> } }) => {
                        onSort(event.target.value as WesSortOptions);
                        fireDesignToolTrackingEvent({
                            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MY_PROJECTS_SORT_OPTION,
                            label: "My Projects Sort Option Selected",
                            extraData: () => ({ sortOption: event.target.value })
                        });
                    }}
                    className={styles.dropdown}
                >
                    <DropdownOption value={WesSortOptions.LAST_MODIFIED}>
                        {t(messages.myProjectsLastEdited.id)}
                    </DropdownOption>
                    <DropdownOption value={WesSortOptions.LAST_CREATED}>
                        {t(messages.myProjectsDateCreated.id)}
                    </DropdownOption>
                </Dropdown>
            </MobileExperience>
        </>
    );
}

MyProjectsSortDropdown.displayName = "MyProjectsSortDropdown";
