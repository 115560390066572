import React, { ReactNode, useContext, useState, createContext, useEffect, useMemo } from "react";
import { useDesigner } from "./designer/DesignerProvider";

interface Data {
    undoDisabled: boolean;
    redoDisabled: boolean;
}

interface History {
    index: number;
    total: number;
}

const context = createContext<Data>({
    undoDisabled: false,
    redoDisabled: false
});

export function useHistory() {
    return useContext(context);
}

const { Provider } = context;

type Props = {
    children: ReactNode | ReactNode[];
};

export function HistoryProvider({ children }: Props) {
    const designer = useDesigner();
    const [history, setHistory] = useState<History>({ index: 0, total: 0 });

    useEffect(() => {
        designer?.eventBus.on(designer.eventBus.events.historyChanged, setHistory);
        return () => {
            designer?.eventBus.off(designer.eventBus.events.historyChanged, setHistory);
        };
    }, [designer]);

    const { index, total } = history;
    const value = useMemo(() => ({ undoDisabled: index <= 0, redoDisabled: total <= index }), [index, total]);
    return <Provider value={value}>{children}</Provider>;
}
HistoryProvider.displayName = "HistoryProvider";
