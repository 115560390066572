import React, { useEffect } from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { Box, TabContent, TabHeader, Tabs, TabsContents, TabsHeaders } from "@vp/swan";
import { STUDIO_TRACKING_EVENTS, fireLoginTrackingEvent } from "@shared/utils/Tracking";
import { UploadLoginButton, useDesigner, useSelection } from "@designer-suite";
import { useTrackEvents } from "@shared/features/Tracking";
import { addQueryParam } from "@shared/utils/WebBrowser";
import { useAppSelector, useAppDispatch, setCurrentImageTab } from "@shared/redux";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { PanelContent, PanelTitle, StickyActionBar } from "@shared/features/StudioChrome";
import { useUploadComponents } from "@shared/features/UploadsAndAssets";
import { fireLoginExperimentTrackingEvent } from "@shared/utils/Tracking/trackingClient";
import { saveStudio5Design } from "../../../clients/saveClient";
import { ImageUploadPanel } from "./ImageUploadPanel";
import { ImageDiscoveryPanel } from "./ImageDiscoveryPanel";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";
import { ImageDrawerPanel } from "./DrawerPanel/ImageDrawerPanel";
import "./imagePanel.scss";

const DISCOVER = "DISCOVERY";
const UPLOADS = "UPLOADS";

const messages = defineMessages({
    imagePanelInfo: {
        id: "studio.components.panels.studiopanels.imagePanelInfo",
        defaultMessage: "Once you've uploaded your image, click and drag it on to your design.",
        description: {
            note: "Information that shows up on the desktop panel of the add images dialog."
        }
    },
    imagePanelInfoMobile: {
        id: "studio.components.panels.studiopanels.imagePanelInfoMobile",
        defaultMessage: "Once you've uploaded your image, simply tap on it to add it to your design.",
        description: {
            note: "Information that shows up on the mobile panel of the add images dialog."
        }
    },
    imagePanelHeader: {
        id: "studio.components.panels.studiopanels.imageHeader",
        defaultMessage: "Images"
    },
    uploadsPanelHeader: {
        id: "studio.components.panels.studiopanels.uploadsHeader",
        defaultMessage: "Uploads",
        description: {
            note: "Header for panel that contains user-uploaded images"
        }
    }
});

interface ImagePanelProps {
    onDone?: () => void;
    fullHeight?: boolean;
}

export function ImagePanel({ onDone, fullHeight = false }: ImagePanelProps) {
    const isAnonymousUser = useIsAnonymousUser();
    const { auth, identity } = useIdentityContext();
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection("change:imageresolution-warning change:imageresolution-error");
    const { trackEvent } = useTrackEvents();
    const currentImageTab = useAppSelector(state => state.currentImageTab);
    const dispatch = useAppDispatch();
    const showLoginButton = isAnonymousUser && currentImageTab === UPLOADS;
    const uploadComponents = useUploadComponents();
    const { isXerox } = useXerox();

    useEffect(() => {
        if (!designer || !selection.length) {
            return;
        }

        if (selection[0]._itemViewModel.get("imageresolution-warning")) {
            trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.LOW_RESOLUTION_IMAGE_WARNING, label: "General" });
        }

        if (selection[0]._itemViewModel.get("imageresolution-error")) {
            trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.LOW_RESOLUTION_IMAGE_ERROR, label: "General" });
        }
    }, [designer, selection, trackEvent]);

    function handleLogin(experimental = false) {
        const loginReason = "Image Panel";
        (async () => {
            await saveStudio5Design({
                authToken: auth.getToken(),
                identity,
                savedProjectTrackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_IMAGE_LOGIN
            });
            if (experimental) {
                fireLoginExperimentTrackingEvent({
                    eventDetail: "click:experimentalloginbutton",
                    label: "General"
                });
            }
            fireLoginTrackingEvent({
                eventDetail: "click:loginbutton",
                label: "General",
                extraData: () => ({
                    loginReason
                }),
                pageNameBase: undefined
            });
            window.history.replaceState(
                "add-loginReason",
                "Title",
                addQueryParam(window.location.href, "loginReason", loginReason)
            );
            await auth.signIn();
        })();
    }

    return (
        <>
            <PanelContent
                className={classNames("studio-image-panel-content", {
                    "image-panel-discovery": currentImageTab === DISCOVER,
                    "studio-image-panel-full-height": fullHeight,
                    "studio-image-panel-content-with-login": showLoginButton
                })}
            >
                <PanelTitle>{isXerox ? t(messages.uploadsPanelHeader.id) : t(messages.imagePanelHeader.id)}</PanelTitle>
                {isXerox ? (
                    <Box
                        className={classNames("image-tab-content", {
                            "image-drawer-tab-content": uploadComponents
                        })}
                    >
                        {uploadComponents ? <ImageDrawerPanel onDone={onDone} /> : <ImageUploadPanel onDone={onDone} />}
                    </Box>
                ) : (
                    <Tabs
                        defaultSelectedTabId={currentImageTab}
                        onRequestTabChange={requestedTabId => dispatch(setCurrentImageTab(requestedTabId))}
                        className="image-panel-tabs"
                    >
                        <TabsHeaders>
                            <TabHeader tabId={UPLOADS}>{t(imageLibraryPanelMessages.uploadTab.id)}</TabHeader>
                            <TabHeader tabId={DISCOVER}>{t(imageLibraryPanelMessages.discoverTab.id)}</TabHeader>
                        </TabsHeaders>
                        <TabsContents>
                            <TabContent tabId={DISCOVER} className="discover-tab-content">
                                <ImageDiscoveryPanel onDone={onDone} fullHeight={fullHeight} />
                            </TabContent>

                            <TabContent
                                tabId={UPLOADS}
                                className={classNames("image-tab-content", {
                                    "image-drawer-tab-content": uploadComponents
                                })}
                            >
                                {uploadComponents ? (
                                    <ImageDrawerPanel onDone={onDone} />
                                ) : (
                                    <ImageUploadPanel onDone={onDone} />
                                )}
                            </TabContent>
                        </TabsContents>
                    </Tabs>
                )}
            </PanelContent>
            {showLoginButton && (
                <StickyActionBar className="image-panel-sticky-login">
                    <UploadLoginButton onClick={handleLogin} />
                </StickyActionBar>
            )}
        </>
    );
}
ImagePanel.displayName = "ImagePanel";
