import React from "react";
import { Button } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import classNames from "classnames";
import { CMYK } from "@design-stack-ct/utility-core";
import { ColorSpace } from "../types";
import * as styles from "./NoColorSwatch.module.scss";

const messages = defineMessages({
    noColor: {
        id: "easel.components.colorpicker.noColor",
        defaultMessage: "No Color",
        description: {
            note: "Label to select no color/transparent"
        }
    }
});

interface Props {
    /** Is the no color option selected */
    noColorSelected: boolean;
    /** Function called when the No Color button has been clicked */
    onChange?: (value: CMYK, colorSpace: ColorSpace) => void;
    /** Optional class names to add to component */
    className?: string;
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
}

export function NoColorSwatch({ onChange = () => {}, noColorSelected, className, trackUsage }: Props) {
    const { t } = useTranslationSSR();
    const noColorCmyk = { c: 0, m: 0, y: 0, k: 0, a: 0 };

    const buttonClicked = () => {
        onChange(noColorCmyk, ColorSpace.NoColor);
        if (trackUsage) {
            trackUsage("No Color", noColorCmyk.toString());
        }
    };

    return (
        <Button
            buttonShape="round"
            size="mini"
            aria-label={t(messages.noColor.id)}
            className={classNames(styles.noColor, {
                [styles.selected]: noColorSelected
            })}
            onClick={() => buttonClicked()}
        />
    );
}

NoColorSwatch.displayName = "NoColorSwatch";
