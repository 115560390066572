// extracted by mini-css-extract-plugin
export var container = "MyProjectsLeftSidebarPanel-module--container--a61d1";
export var dialogBody = "MyProjectsLeftSidebarPanel-module--dialogBody--e0a74";
export var divider = "MyProjectsLeftSidebarPanel-module--divider--83ddc";
export var loadingSpinner = "MyProjectsLeftSidebarPanel-module--loadingSpinner--1cf53";
export var messageArea = "MyProjectsLeftSidebarPanel-module--messageArea--9db80";
export var myProjectsScrollList = "MyProjectsLeftSidebarPanel-module--my-projects-scroll-list--af0c4";
export var panelNav = "MyProjectsLeftSidebarPanel-module--panelNav--e27ba";
export var stickyLogin = "MyProjectsLeftSidebarPanel-module--stickyLogin--5acc3";
export var tools = "MyProjectsLeftSidebarPanel-module--tools--1a2c5";
export var toolsDisabled = "MyProjectsLeftSidebarPanel-module--tools-disabled--172eb";