import React, { useCallback, useMemo } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import {
    INITIAL_ICONS_MAX_PERCENT_OF_CANVAS,
    PlacementStrategyParams,
    startUploadingImage,
    UploadStrategies,
    useDesigner,
    useHandleImagePlacement
} from "@designer-suite";
import { reportIconUsed } from "@design-stack-vista/image-library-react";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { updateRecentlyUsedElements } from "@five/xerox/features/Elements";
import type { Upload } from "../../../../easel/designer-suite/@types/upload";
import type { Designer } from "../../../../easel/designer-suite/@types/designer";
import { constructNounProjectMetadata, isPointOnCanvas, NOUN_PROJECT_SOURCE, NP_IMAGE_PREFIX } from "./ImagePanelUtils";
import { ImageThumbnail } from "./ImageThumbnail";

type Props = {
    src: string;
    upload: Upload;
    id?: string;
    width?: number;
    height?: number;
    imageSource?: string;
    onDone?: () => void;
    className?: string;
    style?: Record<string, any>;
};

const uploadIconImage = (id: string | undefined, url: string, designer: Designer) => {
    if (!id?.startsWith(NP_IMAGE_PREFIX) || !designer) {
        return;
    }
    let unsubscribeImageAdded: () => void;
    let unsubscribeReplaceImage: () => void;
    const startUpload = (event: EventData) => {
        if (unsubscribeImageAdded) {
            unsubscribeImageAdded();
        }
        if (unsubscribeReplaceImage) {
            unsubscribeReplaceImage();
        }
        const itemViewModel = event.items[0]._itemViewModel;

        if (id === itemViewModel.model.get("requestId")) {
            startUploadingImage(itemViewModel, url, designer);
        }
    };
    unsubscribeImageAdded = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, startUpload);
    unsubscribeReplaceImage = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_CHANGED, startUpload);
};

export const IconThumbnail = (props: Props) => {
    const { src, id, width, height, upload, imageSource, onDone, style, className } = props;

    const nounProjectId = upload?.id?.replace(NP_IMAGE_PREFIX, "");

    const { auth } = useIdentityContext();
    const authToken = auth.getToken();
    const designer = useDesigner();

    const { isXerox } = useXerox();

    const updateRecentsForCBElements = useCallback(() => {
        if (!upload || !upload.id || !width || !height || !id) {
            return;
        }
        const pages = upload.get("pages");
        const uploadHeight = pages.models[0].get("height");
        const uploadWidth = pages.models[0].get("width");
        const printPixelHeight = typeof uploadHeight === "number" ? uploadHeight : parseInt(uploadHeight, 10);
        const printPixelWidth = typeof uploadWidth === "number" ? uploadWidth : parseInt(uploadWidth, 10);
        // update recents for competitive baselining elements panel
        updateRecentlyUsedElements({
            id,
            src,
            width,
            height,
            previewUrl: src,
            printUrl: upload.get("printUrl"),
            printPixelHeight,
            printPixelWidth,
            imageSource
        });
    }, [height, id, imageSource, src, upload, width]);

    const onUploadAdded = useCallback(() => {
        if (onDone) {
            onDone();
        }
        if (isXerox) {
            updateRecentsForCBElements();
        }
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ICON,
            label: "Click Icon"
        });
    }, [isXerox, onDone, updateRecentsForCBElements]);

    const { onImageClicked, onImageDoubleClicked } = useHandleImagePlacement({
        placementStrategy: UploadStrategies.PlaceOnCanvasAndUpload,
        onUploadAdded,
        maxPercentOfCanvas: INITIAL_ICONS_MAX_PERCENT_OF_CANVAS
    });

    const reportUsed = useCallback(() => {
        if (nounProjectId) {
            reportIconUsed(nounProjectId, NOUN_PROJECT_SOURCE, authToken);
        }
    }, [authToken, nounProjectId]);

    const onStart = useCallback(
        (event: any) => {
            if (!designer || !upload) {
                return;
            }
            uploadIconImage(upload.id, upload.get("originalUrl"), designer);
        },
        [designer, upload]
    );

    const onStop = useCallback(
        (event: any): void => {
            if (designer) {
                const overCanvas = isPointOnCanvas({ top: event.clientY, left: event.clientX }, designer);
                if (overCanvas) {
                    reportUsed();
                }
            }
            if (isXerox) {
                updateRecentsForCBElements();
            }

            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.DRAG_ICON_ADD,
                label: "Drag icon"
            });
        },
        [designer, isXerox, reportUsed, updateRecentsForCBElements]
    );

    const studioMetadata = useMemo(() => constructNounProjectMetadata(nounProjectId), [nounProjectId]);
    return (
        <ImageThumbnail
            imageUrl={src}
            key={`image-${upload?.id}}`}
            upload={upload}
            onClick={(params: PlacementStrategyParams) => {
                onImageClicked(params);
                reportUsed();
            }}
            onDoubleClick={(params: PlacementStrategyParams) => {
                onImageDoubleClicked(params);
                reportUsed();
            }}
            showPreviewButton={false}
            studioMetadata={studioMetadata}
            onStart={onStart}
            onStop={onStop}
            maxCanvasSize={INITIAL_ICONS_MAX_PERCENT_OF_CANVAS}
            className={className}
            style={style}
        />
    );
};

IconThumbnail.displayName = "IconThumbnail";
