import React, { useCallback, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { Status, useIcons } from "@design-stack-vista/image-library-react";
import { DEFAULT_SEARCH_TERM } from "@shared/features/UploadsAndAssets";
import { fireUserInteractionTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { usePrevious } from "@design-stack-ct/utility-react";
import { useTrackEvents } from "@shared/features/Tracking";
import { useIconsSearch } from "./IconsSearchProvider";
import { IconSearchBar } from "./IconSearchBar";

type Props = {
    className?: string;
};

const debounceFireUserInteractionTracking = debounce(fireUserInteractionTrackingEvent, 1000);

export const ComposedIconSearchBar = (props: Props) => {
    const { className } = props;
    const { performSearch, status, activePage } = useIcons();
    const { trackEvent } = useTrackEvents();
    const { setSearchTerm, searchTerm } = useIconsSearch();
    const previousSearchTerm = usePrevious(searchTerm);
    const previousStatus = usePrevious(status);
    const startTime = useRef(0);

    /* used for tracking user interaction events */
    useEffect(() => {
        if (previousSearchTerm && searchTerm && previousSearchTerm === searchTerm && activePage === 1) {
            if (previousStatus === Status.Idle && status === Status.Loading) {
                startTime.current = performance.now();
            }
            if (previousStatus === Status.Loading && status === Status.Idle) {
                const endTime = performance.now();
                const timeElapsed = endTime - startTime.current;
                debounceFireUserInteractionTracking("Search Icons", timeElapsed);
            }
        }
    }, [previousSearchTerm, searchTerm, activePage, previousStatus, status]);

    const search = useCallback(
        (term: string) => {
            if (term.length >= 2) {
                trackEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.SEARCH_ICON,
                    extraData: () => ({ searchTerm: term.toLowerCase().trim() })
                });
                performSearch(term, 1);
            }
        },
        [performSearch, trackEvent]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(debounce(search, 500), [performSearch, trackEvent]);

    const resetSearchInputField = () => {
        setSearchTerm("");
        performSearch(DEFAULT_SEARCH_TERM);
    };

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const currentSearchTerm = (event.target as HTMLInputElement).value;
        if (currentSearchTerm === "") {
            resetSearchInputField();
        } else {
            setSearchTerm(currentSearchTerm);
            debounceSearch(currentSearchTerm);
        }
    };

    const handleSearch = () => {
        if (searchTerm) {
            trackEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.SEARCH_ICON,
                extraData: () => ({ searchTerm: searchTerm.toLowerCase().trim() })
            });
            performSearch(searchTerm, 1);
        } else {
            resetSearchInputField();
        }
    };

    return (
        <IconSearchBar
            onChange={handleOnChange}
            onClearSearch={resetSearchInputField}
            onSearch={handleSearch}
            searchTerm={searchTerm}
            className={className}
        />
    );
};

ComposedIconSearchBar.displayName = "ComposedIconSearchBar";
