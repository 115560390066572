/* eslint-disable max-len */
import React from "react";

export function ScanQRModalArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 25" {...props}>
            <path
                fill="#707070"
                d="M2 10.5a1.5 1.5 0 0 0 0 3v-3Zm43.06 2.56a1.5 1.5 0 0 0 0-2.12l-9.545-9.547a1.5 1.5 0 1 0-2.122 2.122L41.88 12l-8.486 8.485a1.5 1.5 0 1 0 2.122 2.122l9.546-9.546ZM2 13.5h42v-3H2v3Z"
            />
        </svg>
    );
}
ScanQRModalArrowIcon.displayName = "ScanQRModalArrowIcon";
