import React from "react";
import { PremiumFinishSelectionItem } from "./PremiumFinishSelectionItem";
import { PremiumFinishImageSelection } from "./PremiumFinishImageSelection";

type Props = {
    items: Array<ImageItem>;
};

export function PremiumFinishSelectionElementImages({ items }: Props) {
    return (
        <>
            {items.map(item => (
                <div className="premium-finish-selection__item" key={item.id}>
                    <PremiumFinishSelectionItem item={item}>
                        <PremiumFinishImageSelection item={item} />
                    </PremiumFinishSelectionItem>
                </div>
            ))}
        </>
    );
}

PremiumFinishSelectionElementImages.displayName = "PremiumFinishSelectionElementImages";
