import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import {
    getStudioUniqueSessionId,
    fireDesignToolTrackingEvent,
    STUDIO_TRACKING_EVENTS,
    fireUserInteractionTrackingEvent
} from "@shared/utils/Tracking";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { V2, createDocumentPanelSource, documentSourceTypeMap, DocumentSourceType } from "@shared/utils/DSS";
import { Store, AppDispatch, TemplateType, hideLoader, showLoader } from "@shared/redux";
import {
    BLANK_SELECTED_TEMPLATE,
    CUSTOM_SELECTED_TEMPLATE,
    DUPLICATE_FIRST_PANEL_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE
} from "@shared/utils/Templates/panelUtilities";
import type { DSS, DTR } from "@vp/types-ddif";
import type { LoadNewDesignForApplyOption } from "@shared/utils/DesignPanel";
import type { PreviewUrl } from "@shared/features/Previews";
import { cleanupTemplateDocument } from "@shared/features/StudioBootstrap";
import { TextAreaItem, ImageItem, ShapeItem, ItemReference, Item } from "@design-stack-ct/cdif-types";
import { setNewStudioAndCustomerSelectedOptions } from "./actions/setNewStudioAndCustomerSelectedOptions";
import { setSelectedSurfaceUpsellTemplate } from "./actions/setSelectedSurfaceUpsellTemplate";

export const getSelectedTemplateFromDocumentSource = (
    documentSource: DSS.DocumentPanelSource
): TemplateType | string | undefined => {
    if (documentSource.source === documentSourceTypeMap.NONE.toString()) {
        return BLANK_SELECTED_TEMPLATE;
    }
    if (documentSource.source === documentSourceTypeMap.EMPTY.toString()) {
        return CUSTOM_SELECTED_TEMPLATE;
    }
    if (documentSource.source === documentSourceTypeMap.DUPLICATE.toString()) {
        return DUPLICATE_FIRST_PANEL_TEMPLATE;
    }
    if (documentSource.source === documentSourceTypeMap.FULLBLEED.toString()) {
        return FULLBLEED_SELECTED_TEMPLATE;
    }
    return documentSource.data;
};

export const getDocumentSourceFromSelectedTemplate = (selectedTemplate: string) => {
    const isBlankTemplate = selectedTemplate === BLANK_SELECTED_TEMPLATE;
    const isCustomTemplate = selectedTemplate === CUSTOM_SELECTED_TEMPLATE;
    const isDuplicateFront = selectedTemplate === DUPLICATE_FIRST_PANEL_TEMPLATE;
    const isFullBleed = selectedTemplate === FULLBLEED_SELECTED_TEMPLATE;
    let newDocumentSourceType;
    let newDocumentSourceData = "";
    if (isBlankTemplate) {
        newDocumentSourceType = documentSourceTypeMap.NONE;
    } else if (isCustomTemplate) {
        newDocumentSourceType = documentSourceTypeMap.EMPTY;
    } else if (isFullBleed) {
        newDocumentSourceType = documentSourceTypeMap.FULLBLEED;
    } else if (isDuplicateFront) {
        newDocumentSourceType = documentSourceTypeMap.DUPLICATE;
    } else {
        newDocumentSourceType = documentSourceTypeMap.TEMPLATE_TOKEN;
        newDocumentSourceData = selectedTemplate;
    }

    return { newDocumentSourceType, newDocumentSourceData };
};

export function shouldUpdatePanelForSource(
    designDocument: DSS.DesignDocument,
    panelName: string,
    newDocumentSource: DSS.DocumentPanelSource
) {
    const targetPanelColorMode = designDocument.document.panels.find(
        (panel: any) => panel.name.toLowerCase() === panelName.toLowerCase()
    )!.colorMode;
    // Just return if the target panel already has the requested design applied
    const targetTemplateIsAlreadyApplied = !!designDocument.metadata?.documentSources?.panels.find(
        (documentSource: any) => isEqual(documentSource, newDocumentSource)
    );

    // we only want the early out if the target template is applied and the colormode/product option are the expected value
    let shouldUpdate = true;
    if (targetTemplateIsAlreadyApplied) {
        // if the source we'd applying is not NONE, so its duplicate or template or....
        // and if the panel mode is already something other than blank (so its color)
        if (
            newDocumentSource.source !== documentSourceTypeMap.NONE.toString() &&
            targetPanelColorMode?.toLowerCase() !== "blank"
        ) {
            // then we can early exit because we have a non-none source that is already applied and a non-blank panel
            shouldUpdate = false;
        }
        if (
            newDocumentSource.source === documentSourceTypeMap.NONE.toString() &&
            targetPanelColorMode?.toLowerCase() === "blank"
        ) {
            // then we can early exit because we have a none source that is already applied and a blank panel
            shouldUpdate = false;
        }
        // otherwise we have a non-none source being applied to a blank panel
        // or a none source being applied to a color panel
        // something is wrong (these do not match) and we should update the document
    }
    return shouldUpdate;
}

// If template metadata exists on the original document from DSS then we need to update the colormatched document to match
// otherwise we create fake template metadata for the items on the colormatched document so that we treat them as template items and not user-added
function updateOrCreateColormatchedTemplateItems(item: Item, designDocument: DSS.DesignDocument) {
    const templateMetadata = designDocument.metadata?.template?.find(
        (temp: any) => temp.originalTemplateElementId === item.id
    );
    if (templateMetadata) {
        // eslint-disable-next-line no-param-reassign
        item.id = templateMetadata.id;
    } else {
        designDocument.metadata?.template?.push({
            id: item.id,
            originalTemplateElementId: item.id,
            // cimpress designer dies if this object isn't provided
            locks: {}
        });
    }
}

type applyDesignOptionParameters = {
    dispatch: AppDispatch;
    getState: typeof Store.getState;
    // panel name design option is being applied to
    panelName: string;
    // whether or not we are resetting
    resetting: boolean;
    // document source type to create new document source
    newDocumentSourceType: DocumentSourceType;
    // document source data to create new document source
    newDocumentSourceData: string;
    // template design type used to check if the template is generic
    templateDesignType?: string;
    // an array of transformations (currently used to support arrow flipping)
    transformations?: any[];
    newOptions?: Record<string, string>;
    fireInteractionTimedEvent?: () => void;
    // document from Artwork generation api
    colorMatchedDocument?: DTR.EditDocV2;
    // The current document.  Must have all content including placeholders
    existingDocument: DSS.DesignDocument;
    // Call to load the new product.  We do some stuff with color matching documents so document retrieval is a bit custom
    loadNewDesign: LoadNewDesignForApplyOption;
};

export const applyDesignOption = async ({
    dispatch,
    getState,
    panelName,
    resetting,
    newDocumentSourceType,
    newDocumentSourceData,
    templateDesignType = "",
    newOptions = undefined,
    transformations = undefined,
    fireInteractionTimedEvent,
    colorMatchedDocument,
    existingDocument,
    loadNewDesign
}: applyDesignOptionParameters) => {
    try {
        const {
            productKey,
            productVersion,
            studioSelectedProductOptions,
            customerSelectedProductOptions,
            locale,
            quantity,
            mpvId,
            isFullBleed,
            template
        } = getState();
        if (productVersion === null) {
            throw Error("Product version is not defined");
        }
        const ignoreSavedPanelIds: string[] = [];
        const getEventDetail = () => {
            if (newDocumentSourceType === documentSourceTypeMap.TEMPLATE_TOKEN) {
                return templateDesignType === "generic"
                    ? `${STUDIO_TRACKING_EVENTS.CLICK_TEMPLATE}generic`
                    : `${STUDIO_TRACKING_EVENTS.CLICK_TEMPLATE}matching`;
            }
            return `${STUDIO_TRACKING_EVENTS.CLICK_TEMPLATE}${newDocumentSourceType}:${newDocumentSourceData}`;
        };
        fireDesignToolTrackingEvent({
            eventDetail: getEventDetail(),
            label: "Template",
            extraData: () => ({
                panelName,
                backTemplate: newDocumentSourceData
            })
        });

        // Find the target panel id to create a new document source
        const targetPanelId = existingDocument.document.panels.find(
            (panel: any) => panel.name.toLowerCase() === panelName.toLowerCase()
        )!.id;

        const newDocumentSource = createDocumentPanelSource(
            targetPanelId,
            newDocumentSourceType,
            newDocumentSourceData
        );

        const shouldUpdate = shouldUpdatePanelForSource(existingDocument, panelName, newDocumentSource);

        if (!resetting && !shouldUpdate) {
            return;
        }

        if (resetting) {
            ignoreSavedPanelIds.push(targetPanelId);
        }

        // Start loading in new document
        dispatch(showLoader(undefined));

        let newStudioOptions = studioSelectedProductOptions;
        let newCustomerOptions = customerSelectedProductOptions;
        let dispatchProductOptions;
        if (newOptions) {
            const { newStudioSelectedProductOptions, dispatchNewProductOptions, newCustomerSelectedProductOptions } =
                dispatch(setNewStudioAndCustomerSelectedOptions(newOptions));
            newStudioOptions = newStudioSelectedProductOptions;
            newCustomerOptions = newCustomerSelectedProductOptions;
            dispatchProductOptions = dispatchNewProductOptions;
        }

        /**
         * Create the document sources that should be applied to the existing document
         * If the new document configuration is blank, we should not pass in any documentSources to
         * "downgrade" it to blank
         */
        const source = newDocumentSource;
        let documentSources: DSS.DocumentSources | undefined;
        if (newDocumentSourceType !== documentSourceTypeMap.NONE) {
            documentSources = {
                panels: [source]
            };
        }

        await loadNewDesign({
            panelName,
            productKey,
            productVersion,
            studioSelectedOptions: newStudioOptions,
            customerSelectedOptions: newCustomerOptions,
            locale,
            template,
            isFullBleed,
            quantity,
            mpvId,
            getDocument: async () => {
                const newDesignDoc = await V2.getDesignDocumentWithSources(
                    productKey,
                    productVersion,
                    newStudioOptions,
                    existingDocument,
                    documentSources,
                    undefined,
                    ignoreSavedPanelIds,
                    getStudioUniqueSessionId(),
                    transformations,
                    locale
                );

                // target panel index for color matching documents
                const backPanelIndex = newDesignDoc.document.panels.findIndex(
                    panel => panel.name.toLowerCase() === "back"
                );
                const targetPanelIndex = newDesignDoc.document.panels.findIndex(
                    panel => panel.name.toLowerCase() === panelName.toLowerCase()
                );

                if (colorMatchedDocument && backPanelIndex >= 0) {
                    const styledDocument = cleanupTemplateDocument(cloneDeep(colorMatchedDocument));
                    const styleBackPanel = styledDocument.document.panels?.[0];

                    // match colormatched elements to original template metadata ids
                    styleBackPanel.textAreas?.forEach((textArea: TextAreaItem) => {
                        if (textArea.content && textArea.content.length > 0) {
                            updateOrCreateColormatchedTemplateItems(textArea, newDesignDoc);
                        }
                    });
                    styleBackPanel?.images?.forEach((image: ImageItem) => {
                        updateOrCreateColormatchedTemplateItems(image, newDesignDoc);
                    });
                    styleBackPanel?.shapes?.forEach((shape: ShapeItem) => {
                        updateOrCreateColormatchedTemplateItems(shape, newDesignDoc);
                    });
                    styleBackPanel?.itemReferences?.forEach((itemReference: ItemReference) => {
                        updateOrCreateColormatchedTemplateItems(itemReference, newDesignDoc);
                    });

                    // merge color matched panel with new document's back panel
                    newDesignDoc.document.panels[backPanelIndex] = {
                        ...styleBackPanel,
                        id: newDesignDoc.document.panels[backPanelIndex].id,
                        height: newDesignDoc.document.panels[backPanelIndex].height,
                        width: newDesignDoc.document.panels[backPanelIndex].width,
                        name: newDesignDoc.document.panels[backPanelIndex].name,
                        colorMode: newDesignDoc.document.panels[backPanelIndex].colorMode
                    };
                }

                // Force the current panel to be color (if grayscale), as grayscale is not supported.
                //  This prevents some additional wierdness when selecting different backside
                //  were sometime grayscale was retained, other times it converted to color.
                //  This makes it consistently convert to color if a different backside is selected.
                if (targetPanelIndex >= 0 && newDesignDoc.document.panels[targetPanelIndex].colorMode === "grayscale") {
                    newDesignDoc.document.panels[targetPanelIndex].colorMode = "color";
                }
                return newDesignDoc;
            }
        });

        if (dispatchProductOptions) {
            dispatchProductOptions();
        }
        const newSelection = getSelectedTemplateFromDocumentSource(newDocumentSource);
        if (newSelection) {
            dispatch(setSelectedSurfaceUpsellTemplate(panelName, newSelection));
        }

        dispatch(hideLoader());
        if (fireInteractionTimedEvent) {
            fireInteractionTimedEvent();
        }
    } catch (e) {
        handleError(e, ERROR_CODES.SURFACE_UPSELL_CHANGE);
    }
};

export enum DesignPanelAction {
    Default = "Default",
    ShowFirstFullColor = "ShowFirstFullColor",
    OpenUpsellModal = "OpenUpsellModal"
}

export const getDesignPanelAction = (
    openFirstFullColorSurface: boolean,
    firstFullColorCanvasIndex: number,
    everyCanvasIsBlank: boolean,
    canvasPreviewUrl?: PreviewUrl
) => {
    if (openFirstFullColorSurface && firstFullColorCanvasIndex >= 0) {
        return DesignPanelAction.ShowFirstFullColor;
    }
    if (everyCanvasIsBlank && canvasPreviewUrl) {
        return DesignPanelAction.OpenUpsellModal;
    }
    return DesignPanelAction.Default;
};

export const generateDesignTileFireInteractionTimedEvent = (selectedOption: string, dialogCanvasName: string) => {
    const startTime = performance.now();
    let actualSelectedOption = "";
    let templateToken: string | undefined;
    switch (selectedOption) {
        case CUSTOM_SELECTED_TEMPLATE:
            actualSelectedOption = "Custom";
            break;
        case BLANK_SELECTED_TEMPLATE:
            actualSelectedOption = "Blank";
            break;
        case FULLBLEED_SELECTED_TEMPLATE:
            actualSelectedOption = "Upload Design";
            break;
        case DUPLICATE_FIRST_PANEL_TEMPLATE:
            actualSelectedOption = "Duplicate";
            break;
        default:
            actualSelectedOption = "Template";
            templateToken = selectedOption;
    }
    return () =>
        fireUserInteractionTrackingEvent(`Click Design Tile - ${actualSelectedOption}`, performance.now() - startTime, {
            templateToken,
            side: dialogCanvasName
        });
};
