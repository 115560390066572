interface UploadFromProps {
    isImageUpload: boolean;
    activeCanvas: string;
}

interface UploadFromReturn {
    from: string;
    activeCanvas: string | undefined;
}

export function getUploadFrom({ isImageUpload, activeCanvas }: UploadFromProps): UploadFromReturn {
    return {
        from: isImageUpload ? "Left Panel" : "Upload Modal",
        activeCanvas
    };
}
