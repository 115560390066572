/* eslint-disable max-len */
import React from "react";

export function AITemplatesIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M3 2C2.44772 2 2 2.44772 2 3V8C2 8.55228 2.44772 9 3 9H9C9.55228 9 10 8.55228 10 8V3C10 2.44772 9.55228 2 9 2H3ZM8 4H4V7H8V4Z"
                fill="currentColor"
            ></path>
            <path
                fillRule="evenodd"
                d="M3 11C2.44772 11 2 11.4477 2 12V21C2 21.5523 2.44772 22 3 22H9C9.55228 22 10 21.5523 10 21V12C10 11.4477 9.55228 11 9 11H3ZM8 13H4V20H8V13Z"
                fill="currentColor"
            ></path>
            <path
                fillRule="evenodd"
                d="M13 15C12.4477 15 12 15.4477 12 16V21C12 21.5523 12.4477 22 13 22H21C21.5523 22 22 21.5523 22 21V16C22 15.4477 21.5523 15 21 15H13ZM20 17H14V20H20V17Z"
                fill="currentColor"
            ></path>
            <path
                fillRule="evenodd"
                d="M13 2C12.4477 2 12 2.44771 12 3V12C12 12.5523 12.4477 13 13 13H21C21.5523 13 22 12.5523 22 12V3C22 2.44772 21.5523 2 21 2H13ZM20 4H14V11H20V4Z"
                fill="currentColor"
            ></path>
        </svg>
    );
}
AITemplatesIcon.displayName = "AITemplatesIcon";
