import Cookies from "js-cookie";

export const useCookie = (
    cookieKey: string,
    optionsFn: () => Cookies.CookieAttributes
): { cookieValue: string; setCookieValue: (value: string) => void } => {
    // First we want to check the shopperId (as that's the logged in person)
    // Then we check anonymous if they aren't logged in.
    const cookieValue = Cookies.get(cookieKey) || "";

    return {
        cookieValue,
        setCookieValue: value => {
            if (cookieValue !== value) {
                Cookies.set(cookieKey, value, optionsFn());
            }
        }
    };
};
