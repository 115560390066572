import React from "react";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { UndoRedoButtonBarMobile } from "@designer-suite";
import { useAppSelector } from "@shared/redux";
import { FullBleedPremiumFinishButton } from "../Redesign/FullBleedPremiumFinish/FullBleedPremiumFinishButton";
import { PremiumFinishButton } from "../Redesign/PremiumFinish/PremiumFinishButton";
import { AddButton } from "../Buttons";
import { MobileSidebarSheet, FileSheet } from "../Sheets";
import "../globalStyles/studio.scss";
import { MobileMoreOptionsSheet } from "../Sheets/MobileMoreOptionsSheet";

const StudioContextualToolSheet = loadable(
    () => loadableRetry(() => import("../../../easel/designer-suite/ToolSheets/ContextualToolSheet")),
    {
        resolveComponent: components => components.ContextualToolSheet
    }
);

export const EaselContentsMobile = () => {
    const productDataLoadSuccessful = useAppSelector(state => state.productDataLoadSuccessful);

    return (
        <>
            <div className="premium-finish-button-container">
                <PremiumFinishButton />
                <FullBleedPremiumFinishButton />
            </div>

            <div className="undo-redo-bar-container" data-testid="messageArea">
                <UndoRedoButtonBarMobile />
            </div>

            <MobileSidebarSheet />
            <FileSheet />
            <MobileMoreOptionsSheet />
            {productDataLoadSuccessful && (
                <>
                    <AddButton />
                    <StudioContextualToolSheet />
                </>
            )}
        </>
    );
};

EaselContentsMobile.displayName = "EaselContentsMobile";
