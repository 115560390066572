import React from "react";
import classNames from "classnames";
import { Box } from "@vp/swan";
import * as styles from "./PanelContent.module.scss";

interface PanelContentProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    className?: string;
    stickyHeader?: React.ReactNode;
}

export const PanelContent = React.forwardRef(
    ({ children, className, stickyHeader, ...rest }: PanelContentProps, ref: any) => {
        return (
            <Box
                ref={ref}
                className={classNames("studio-panel-content", styles.studioPanelContent, className)}
                {...rest}
            >
                {stickyHeader && <Box className={styles.studioPanelContentStickyHeader}>{stickyHeader}</Box>}
                {children}
            </Box>
        );
    }
);

PanelContent.displayName = "PanelContent";
