// This code has been generated via svgr
/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function UnsharpenIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M16 0H0V16H16V0Z" fill="none" />
            <path
                d="M11.49 7.28027L13.19 10.4003C13.55 11.0703 13.07 11.8803 12.31 11.8803H9.08997"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M6.17002 11.8802H3.68002C2.92002 11.8802 2.44002 11.0702 2.80002 10.4002L7.12002 2.49016C7.50002 1.80016 8.50002 1.80016 8.88002 2.49016L10.08 4.70016"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path d="M4.40002 15.1095L11.95 1.26953" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
}
UnsharpenIcon.displayName = "UnsharpenIcon";
