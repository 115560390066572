import React from "react";
import { Button, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import classNames from "classnames";
import { loadableRetry } from "@shared/utils/Network";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import loadable from "@loadable/component";
import { useDesigner } from "@easel";
import { PremiumFinishIcon } from "@shared/features/StudioChrome";
import { SUPPORTED_FINISHES } from "@shared/features/PremiumFinish";
import { useAppSelector, useAppDispatch, setIsPremiumFinishModalOpen } from "@shared/redux";
import { MobileExperience } from "@shared/features/ResponsiveDesign";
import messages from "./messages";
import "./premiumFinishModal.scss";

const PremiumFinishModal = loadable(() => loadableRetry(() => import("./PremiumFinishModal")), {
    resolveComponent: components => components.PremiumFinishModal
});

type Props = {
    className?: string;
};

export function PremiumFinishButton({ className }: Props) {
    const { t } = useTranslationSSR();
    const { enabledCanvasFinish } = useStudio5AvailablePremiumFinishesOnCanvas();
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const isFullBleed = useAppSelector(state => state.isFullBleed);

    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;

    if (!(designer && finishType && SUPPORTED_FINISHES.includes(finishType) && !isFullBleed)) {
        return null;
    }
    function setShowModal() {
        dispatch(setIsPremiumFinishModalOpen({ isPremiumFinishModalOpen: true }));
    }

    return (
        <FlexBox className={classNames("premium-finish", className)}>
            <MobileExperience>
                <Button skin="secondary" onClick={setShowModal}>
                    <PremiumFinishIcon className="premium-finish-button-icon" />
                    {t(messages.premiumFinishButtonTitle.id)}
                </Button>
            </MobileExperience>
            <PremiumFinishModal />
        </FlexBox>
    );
}
PremiumFinishButton.displayName = "PremiumFinishButton";
