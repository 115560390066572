import React, { useCallback, useEffect, useState } from "react";
import { Drawer, InfiniteScrollContainer, useUploadManager } from "@design-stack-vista/upload-components";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { Divider, H6 } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import {
    UploadsPanelMessages,
    RedesignedUploadsPanelMessages,
    AcceptedUploadFormats
} from "@shared/features/UploadsAndAssets";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { useQRUrlGenerator, UploadQRModal, UploadQRButton, useUploadQRABTest } from "@shared/features/QRUrlUploads";
import { STUDIO_TRACKING_EVENTS, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { AcceptedUploadFormatsExperiment } from "@shared/features/UploadsAndAssets/AcceptedUploadFormatsExperiment";
import { useTrackAutoPlacement } from "@five/components/Panels/Images/useTrackAutoPlacement";
import {
    UploadButton,
    UploadStrategies,
    useDesigner,
    useHandleImagePlacement,
    useActiveCanvas
} from "../../../../../easel";
import { DraggableUploadedImage } from "../../../DragAndDrop/DraggableUploadedImage";
import { useAssetsOnCanvas } from "./useAssetsOnCanvas";
import { addImageCallToAction } from "./utils";
import { useUploadToDesignerSuccessCallback } from "./useUploadToDesignerSuccessCallback";
import * as galleryStyles from "./ImageDrawerPanel.module.scss";

interface Props {
    onDone?: () => void;
}

export const ImageDrawerPanel = (props: Props) => {
    const { onDone } = props;
    const isAnonymousUser = useIsAnonymousUser();
    const showReplaceImageModal = useAppSelector(state => state.showReplaceImageModal);
    const { hasAssets, refreshAssets, assetStore } = useUploadManager();
    const { isSmall, isMedium } = useStudioLayout();
    const { isQRCodeImageUploadABEnabled, trackImpression } = useUploadQRABTest();
    const designer = useDesigner();
    const placementStrategy = isSmall ? UploadStrategies.AutofillAndPlace : UploadStrategies.AutofillSinglePlaceholder;
    const { usedAssets } = useAssetsOnCanvas();
    const canvas = useActiveCanvas();
    const [showQRModal, setShowQRModal] = useState(false);

    const { requestGenerateQR, qrCodeData, assetsRefreshed } = useQRUrlGenerator({
        refreshAssetsCallback: refreshAssets,
        assetStore,
        activeCanvasName: canvas?.name
    });
    const onTrackEvent = useTrackAutoPlacement();

    const onUploadAdded = useCallback(() => {
        if (isSmall && onDone) {
            onDone();
        }
    }, [isSmall, onDone]);

    const { onImageClicked, onImageDoubleClicked, onImageUploaded } = useHandleImagePlacement({
        placementStrategy,
        onUploadAdded
    });

    const onDoubleClick = (asset: VistaAsset, pageNumber: number) => {
        onTrackEvent();
        addImageCallToAction({ designer, asset, pageNumber, callback: onImageDoubleClicked });
    };

    const onClick = (asset: VistaAsset, pageNumber: number) => {
        onTrackEvent();
        addImageCallToAction({ designer, asset, pageNumber, callback: onImageClicked });
    };

    const { t } = useTranslationSSR();
    useUploadToDesignerSuccessCallback(!showReplaceImageModal ? onImageUploaded : undefined, onUploadAdded);

    let uploadButtonText = t(UploadsPanelMessages.uploadButton.id);
    if (isQRCodeImageUploadABEnabled) {
        uploadButtonText = t(UploadsPanelMessages.uploadButtonWithExperiment.id);
        if (isSmall) {
            uploadButtonText = t(UploadsPanelMessages.uploadButtonMobile.id);
        } else if (isMedium) {
            uploadButtonText = t(UploadsPanelMessages.uploadButtonTablet.id);
        }
    }

    useEffect(() => {
        if (trackImpression) trackImpression();
    }, [trackImpression]);

    const getDrawerStyleOptions = useCallback(() => {
        if (isSmall) {
            return { thumbnailSize: 105, flexboxGap: 10 };
        }
        if (isMedium) {
            return { thumbnailSize: 90, flexboxGap: 8 };
        }
        return { thumbnailSize: 80, flexboxGap: 10 };
    }, [isMedium, isSmall]);

    useEffect(() => {
        if (assetsRefreshed) {
            setShowQRModal(false);
        }
    }, [assetsRefreshed]);

    return (
        <InfiniteScrollContainer infiniteScrollId="image-drawer-panel-scroll-component">
            {!isQRCodeImageUploadABEnabled && <AcceptedUploadFormats isImageUpload={true} showTitle />}
            <UploadButton isImageUpload={true} aria-describedby="upload-image-allowed-formats">
                {uploadButtonText}
            </UploadButton>
            {isQRCodeImageUploadABEnabled && !isSmall && (
                <UploadQRButton
                    aria-describedby="open-qr-modal-for-upload"
                    onClick={() => {
                        setShowQRModal(true);
                        canvas &&
                            fireDesignToolTrackingEvent({
                                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_QR_MOBILE_UPLOAD_BUTTON.replace(
                                    "[CanvasSide]",
                                    canvas.name
                                ),
                                label: "General"
                            } as any);
                    }}
                    className={galleryStyles.uploadQrButton}
                >
                    {t(UploadsPanelMessages.uploadFromMobileButton.id)}
                </UploadQRButton>
            )}
            {isQRCodeImageUploadABEnabled && <AcceptedUploadFormatsExperiment isImageUpload={true} showTitle />}
            {hasAssets && <Divider mb={4} />}
            {hasAssets && (
                <H6 className="recent-upload-title-text" textColor="dark-grey" fontSize="-1" marginBottom={2}>
                    {t(RedesignedUploadsPanelMessages.recentlyUploaded.id)}
                </H6>
            )}
            <Drawer
                emptyResultsElement={
                    !isAnonymousUser ? <>{t(RedesignedUploadsPanelMessages.noImagesFound.id)}</> : undefined
                }
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                usedAssets={usedAssets}
                deleteOption="confirm"
                render={(children, { isMultiPage }) => {
                    return (
                        <DraggableUploadedImage isPageInMultiBox={isMultiPage} key="draggable-image">
                            {children}
                        </DraggableUploadedImage>
                    );
                }}
                styleOptions={getDrawerStyleOptions()}
            />
            <UploadQRModal
                requestGenerateQR={requestGenerateQR}
                qrCodeData={qrCodeData}
                showUploadQRModal={showQRModal}
                requestCloseModal={() => {
                    setShowQRModal(false);
                }}
            />
        </InfiniteScrollContainer>
    );
};

ImageDrawerPanel.displayName = "ImageDrawerPanel";
