import React, { useState, useEffect } from "react";
import { AlertBoxDismissButton, AlertBox } from "@vp/swan";
import classNames from "classnames";
import * as styles from "./PremiumFinishWarning.module.scss";

export interface PremiumFinishWarningProps {
    className?: string;
    message: string;
    showWarning: boolean;
}

export function PremiumFinishWarning({ className, message, showWarning }: PremiumFinishWarningProps) {
    const [dismissed, setDismissed] = useState(false);

    useEffect(() => {
        setDismissed(!showWarning);
    }, [showWarning]);

    return (
        <AlertBox
            skin="standard"
            className={classNames(styles.premiumFinishWarning, "premium-finish-warning", className)}
            dismissed={dismissed}
            onRequestDismiss={() => setDismissed(true)}
        >
            {message}
            <AlertBoxDismissButton visuallyHiddenLabel="Dismiss alert" />
        </AlertBox>
    );
}

PremiumFinishWarning.displayName = "PremiumFinishWarning";
