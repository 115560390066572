import React, { useEffect } from "react";
import { Easel } from "@easel";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { useEaselConfig } from "@shared/features/StudioBootstrap";
import { useLoadSecondaryData } from "@shared/features/StudioBootstrap/secondaryDataLoading/useLoadSecondaryData";
import { useAppSelector } from "@shared/redux";
import { fireStudioTrackingEvent } from "@shared/utils/Tracking";
import { useFetchMatchingTemplates } from "@shared/features/Templates";
import useKeyboardExtensions from "../hooks/useKeyboardExtensions";
import { usePageVisibleAutoSave } from "../hooks/usePageVisibleAutoSave";
import { useGuidesVisibility } from "../hooks/useGuidesVisibility";
import { useOnEaselStart } from "../hooks/useOnEaselStart";
import { EaselContents } from "./StudioLayout/EaselContents";
import "./globalStyles/studio.scss";
import "./designerCanvasCustomizations.scss";

function EaselContainer() {
    const quantity = useAppSelector(state => state.quantity);
    const { onEaselStart } = useOnEaselStart();

    useKeyboardExtensions();
    usePageVisibleAutoSave();
    useGuidesVisibility();

    const config = useEaselConfig();
    const designDocumentInConfig = config?.cimDoc;

    useLoadSecondaryData(config);
    useFetchMatchingTemplates();

    useEffect(() => {
        if (config && quantity) {
            fireStudioTrackingEvent();
        }
    }, [config, quantity]);

    function updateEaselError(error: { errorMessage: string; errorCodeStack: string }) {
        handleError(error, ERROR_CODES.START_STUDIO, true);
    }

    return (
        <Easel
            config={config}
            onStart={() => {
                onEaselStart(designDocumentInConfig, window.designer!);
            }}
            errorCallBack={updateEaselError}
        >
            <EaselContents />
        </Easel>
    );
}
EaselContainer.displayName = "EaselContainer";

export default EaselContainer;
