import { useCallback } from "react";
import { useDesigner, useHistory } from "@easel";

export function useUndoRedo() {
    const designer = useDesigner();
    const { undoDisabled, redoDisabled } = useHistory();

    const undo = useCallback(() => {
        designer?.eventBus.trigger(designer.eventBus.events.undo);
    }, [designer]);

    const redo = useCallback(() => {
        designer?.eventBus.trigger(designer.eventBus.events.redo);
    }, [designer]);

    return {
        undo,
        redo,
        undoDisabled,
        redoDisabled
    };
}

export default useUndoRedo;
