/* eslint-disable max-len */
import { Icon } from "@design-stack-ct/dex-icon-library";
import React, { DetailedHTMLProps, SVGAttributes } from "react";

export const tablesIconString = `<svg viewBox="0 0 22 22">
<path fill="currentColor" d="M2.095 9.952h7.857V2.095H2.095v7.857zm0 2.096v7.857h7.857v-7.857H2.095zm17.81-2.096V2.095h-7.857v7.857h7.857zm0 2.096h-7.857v7.857h7.857v-7.857zM22 0v22H0V0h22z" />
</svg>`;

export function TablesIcon(props: DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>) {
    return <Icon {...props} content={tablesIconString} size="medium" />;
}

TablesIcon.displayName = "TablesIcon";
