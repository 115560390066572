import React, { useEffect, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { ImageItem, Status } from "@design-stack-vista/image-library-react";
import { Typography } from "@vp/swan";
import { DraggableUploadsPanelProvider, useDesigner } from "@designer-suite";
import { LoadingSpinner, SpinnerVariant, IconGallery } from "@shared/features/StudioChrome";
import { useTrackEvents } from "@shared/features/Tracking";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { useDecorationTechnology } from "@utilities";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { useElementsPanel } from "../../../../xerox/features/Elements/ElementsPanelProvider";
import { iconsPanelMessages } from "./IconsPanel";
import { IconThumbnail } from "../../Images/IconThumbnail";
import {
    DraggableEllipse,
    DraggableRectangle,
    DraggableTriangle,
    DraggablePolygon,
    DraggableLine,
    DraggableArrow,
    DraggableDoubleArrow,
    DraggableStarBurst
} from "../../Shapes";
import { DiscoveryPhotoItem, createGalleryItems } from "../../Images/ImagePanelUtils";

export type IconSelectionSetProps = {
    icons: ImageItem[];
    searchStatus: string;
    showNoMoreIconsNotice?: boolean;
    ariaLabel: string;
    onDone?: () => void;
    activePage?: number;
};

export const draggableShapes = [
    DraggableRectangle,
    DraggableEllipse,
    DraggableTriangle,
    DraggablePolygon,
    DraggableLine,
    DraggableArrow,
    DraggableDoubleArrow,
    DraggableStarBurst
];

const ICON_SIZE = "1200";

export const IconSelectionSet = ({
    icons,
    searchStatus,
    showNoMoreIconsNotice = false,
    ariaLabel,
    onDone,
    activePage
}: IconSelectionSetProps) => {
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const [galleryImages, setGalleryImages] = useState<DiscoveryPhotoItem[]>([]);
    const { isSmall } = useStudioLayout();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const designer = useDesigner();
    const decorationTechnology = useDecorationTechnology();
    const isEmbroidery = decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY;
    const { currentActiveDialog } = useActiveDialog();
    const { currentElementsPanelSearchTerm, icons: elementsIcons } = useElementsPanel();
    const { isXerox } = useXerox();

    useEffect(() => {
        if (showNoMoreIconsNotice) {
            // Track when users hit next and view a page with no icons
            trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.LOADED_NO_ICONS_PAGE, label: "Image" });
        }
    }, [showNoMoreIconsNotice, trackEvent]);

    useEffect(() => {
        if (!easelLoaded || !designer || searchStatus !== Status.Idle) {
            return;
        }
        let newIcons: DiscoveryPhotoItem[] = [];
        if (icons?.length) {
            newIcons = createGalleryItems(
                icons.map(icon => ({ ...icon, width: ICON_SIZE, height: ICON_SIZE, type: "image", extension: "png" })),
                designer,
                onDone
            );
        }
        setGalleryImages(newIcons);
    }, [searchStatus, icons, easelLoaded, designer, onDone]);

    if (searchStatus === "error") {
        return (
            <Typography fontSize="1" textAlign="center" margin={0} mt={3} paddingX={6}>
                {t(iconsPanelMessages.studioPanelImagesIconsError.id)}
            </Typography>
        );
    }

    if (searchStatus === "loading") {
        return (
            <LoadingSpinner
                className="mt-l"
                variant={SpinnerVariant.Large}
                centering
                aria-label={t(iconsPanelMessages.studioPanelImagesIconsLoaderLabel.id)}
            />
        );
    }

    if (showNoMoreIconsNotice) {
        return (
            <Typography fontSize="1" textAlign="center" fontWeight="bold" margin={0} mt={6}>
                {t(iconsPanelMessages.studioPanelImagesIconsNoMoreIcon.id)}
            </Typography>
        );
    }

    if (!galleryImages || galleryImages.length === 0) {
        return (
            <Typography fontSize="1" textAlign="center" fontWeight="bold" margin={0} mt={6}>
                {t(iconsPanelMessages.studioPanelImagesIconsNoResults.id)}
            </Typography>
        );
    }

    const iconThumbnail = (item: any) => {
        const { src, id, width, height, upload, imageSource, onDone } = item;
        return (
            <IconThumbnail
                upload={upload}
                src={src}
                id={id}
                width={item.imageSource === "NounProject" ? width : undefined}
                height={height}
                imageSource={imageSource}
                onDone={onDone}
            />
        );
    };

    // flag for showing shapes in xerox ElementsPanelContentShapes
    // only when Elements panel open, on first page of pagination, no current search term
    // and don't show for embroidery products
    const showShapes =
        currentActiveDialog === DialogType.Elements &&
        activePage === 1 &&
        !currentElementsPanelSearchTerm &&
        !isEmbroidery;

    return (
        <DraggableUploadsPanelProvider isDraggable={!isSmall}>
            <IconGallery
                // @ts-ignore
                galleryImages={isXerox ? elementsIcons : galleryImages}
                thumbnailComponent={iconThumbnail}
                draggableShapes={showShapes ? draggableShapes : undefined}
            />
        </DraggableUploadsPanelProvider>
    );
};

IconSelectionSet.displayName = "IconSelectionSet";
