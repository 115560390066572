import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { SUPPORTED_FINISHES, useAvailablePremiumFinishesForSurfaces } from "@shared/features/PremiumFinish";
import { useAppSelector } from "@shared/redux";

export const useFullBleedPremiumFinish = (): {
    isButtonVisible: boolean;
    enabledCanvasFinish: string[];
    isFullBleedPremiumFinish: boolean;
} => {
    const { hasPremiumFinishesCurrentCanvas, enabledCanvasFinish } = useStudio5AvailablePremiumFinishesOnCanvas();
    const { hasPremiumFinishesAvailable } = useAvailablePremiumFinishesForSurfaces();
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;

    return {
        isButtonVisible: Boolean(
            isFullBleed && hasPremiumFinishesCurrentCanvas && finishType && SUPPORTED_FINISHES.includes(finishType)
        ),
        enabledCanvasFinish,
        isFullBleedPremiumFinish: isFullBleed && hasPremiumFinishesAvailable
    };
};
