import React, { useCallback } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { ChangeTemplateAndSubstrateColorPanel } from "@shared/features/ChangeTemplate";
import { loadProjectInStudio } from "@five/clients/loadProjectClient";
import {
    TextPanel,
    ImagePanel,
    ShapesIconsPanel,
    GroupsAndTeamsPanel,
    TablePanel,
    AddQRCodePanelStudio5,
    LayersPanel,
    AITemplatesPanelContainer
} from "@five/components/Panels";
import { BackgroundColorPanel } from "@five/components/Panels/Redesign/BackgroundColorPanel";
import { FillColorPickerPanel } from "@five/components/Panels/Redesign/FillColorPickerPanel";
import { FullBleedPremiumFinishPanelStudio5 } from "@five/components/Panels/Redesign/FullBleedPremiumFinishPanel";
import { SingleColorImageColorPickerPanel } from "@five/components/Panels/Redesign/SingleColorImageColorPickerPanel";
import { StrokeColorPickerPanel } from "@five/components/Panels/Redesign/StrokeColorPickerPanel";
import { TableBackgroundColorPickerPanel } from "@five/components/Panels/Redesign/TableBackgroundColorPickerPanel";
import { TableFontColorPickerPanel } from "@five/components/Panels/Redesign/TableFontColorPickerPanel";
import { TeamsFontColorPickerPanel } from "@five/components/Panels/Redesign/TeamsFontColorPickerPanel";
import { WordArtAndTextColorPickerPanel } from "@five/components/Panels/Redesign/WordArtAndTextColorPickerPanel";
import { TemplatePanel } from "@five/components/Panels/TemplatePanel/TemplatePanel";
import { useLoadNewDesignForChangeTemplate } from "@five/hooks/useLoadNewDesignForChangeTemplate";
import { useStudio5Save } from "@five/hooks/useStudio5Save";
import { ElementsPanel } from "@five/xerox/features/Elements";
import { DialogType } from "@shared/features/ActiveDialog";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { LoadProjectCallback, MyProjectsLeftSidebarPanel } from "@shared/features/MyProjects";
import { useAppSelector, useAppDispatch } from "@shared/redux";
import { isCareAgent } from "@shared/utils/Care";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getDesignDocumentFromDesigner } from "@utilities";
import { useLoadNewDesignForSubstrateChange } from "@five/hooks/useLoadNewSubstrateColor";
import { useTeamsNameItemReferences } from "@five/hooks/useTeamsNameItemReferences";
import { EditImagePanel } from "@five/xerox/features/EditImage/EditImagePanel";
import { FlexibilityPanel } from "@shared/features/Flexibility";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { GenerateIdeasPanelContainer } from "@five/components/Panels/GenerateIdeas/GenerateIdeasPanelContainer";
import { PremiumFinishModal } from "../PremiumFinish/PremiumFinishModal";

export const useFloatingSidebarPanelConfiguration = () => {
    const advancedTools = useIsFlagEnabled("advancedTools");
    const allowLayers = isCareAgent() || advancedTools;
    const locale = useAppSelector(state => state.locale);
    const dispatch = useAppDispatch();
    const { auth, identity } = useIdentityContext();
    const { useColorGenericBacksides } = useAppSelector(state => state.studioConfiguration);
    const { isXerox } = useXerox();
    const showMyProjects = useAppSelector(state => state.showMyProjects.show) && isXerox;
    const { isSmall } = useStudioLayout();

    useTeamsNameItemReferences();

    const loadNewDesign = useLoadNewDesignForChangeTemplate();
    const loadNewDesignForSubstrateChange = useLoadNewDesignForSubstrateChange();
    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(false), []);
    const saveForChangeTemplate = useStudio5Save({
        allowAnonymousUser: true,
        hideSaveToast: true,
        showLoader: false,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_CHANGE_TEMPLATE
    });

    const loadProject = useCallback<LoadProjectCallback>(
        async params => {
            return loadProjectInStudio({
                ...params,
                locale,
                dispatch,
                auth,
                identity,
                isColorMatchingBacksideEnabled: useColorGenericBacksides,
                useDraggablePlaceholders: !isSmall
            });
        },
        [locale, dispatch, auth, identity, useColorGenericBacksides, isSmall]
    );

    const dialogTypeComponents = {
        [DialogType.Text]: <TextPanel />,
        [DialogType.Images]: <ImagePanel />,
        [DialogType.Graphics]: <ShapesIconsPanel />,
        [DialogType.Elements]: <ElementsPanel />,
        [DialogType.Color]: (
            <ChangeTemplateAndSubstrateColorPanel
                getDocument={getDocument}
                loadNewDesignForTemplateChange={loadNewDesign}
                loadNewDesignForSubstrateChange={loadNewDesignForSubstrateChange}
                save={saveForChangeTemplate}
            />
        ),
        [DialogType.GroupsAndTeams]: <GroupsAndTeamsPanel />,
        [DialogType.Tables]: <TablePanel colsAndRowsStartNumber={2} />,
        [DialogType.QRCodes]: <AddQRCodePanelStudio5 />,
        [DialogType.Layers]: allowLayers ? <LayersPanel /> : null,
        [DialogType.FullBleedPremiumFinish]: <FullBleedPremiumFinishPanelStudio5 />,
        [DialogType.PremiumFinishes]: <PremiumFinishModal />,
        [DialogType.BackgroundColorPicker]: <BackgroundColorPanel />,
        [DialogType.Flexibility]: <FlexibilityPanel />,
        [DialogType.TextColorPicker]: <WordArtAndTextColorPickerPanel />,
        [DialogType.FillColorPicker]: <FillColorPickerPanel />,
        [DialogType.StrokeColorPicker]: <StrokeColorPickerPanel />,
        [DialogType.SingleColorImageColorPicker]: <SingleColorImageColorPickerPanel />,
        [DialogType.TableFontColorPicker]: <TableFontColorPickerPanel />,
        [DialogType.TableBackgroundColorPicker]: <TableBackgroundColorPickerPanel />,
        [DialogType.TeamsFontColorPicker]: <TeamsFontColorPickerPanel />,
        [DialogType.Template]: <TemplatePanel />,
        [DialogType.MyProjects]: <MyProjectsLeftSidebarPanel isOpen={showMyProjects} loadProject={loadProject} />,
        [DialogType.AITemplates]: <AITemplatesPanelContainer />,
        [DialogType.IdeaPanel]: <GenerateIdeasPanelContainer />,
        [DialogType.EditImage]: <EditImagePanel />
    };

    return { dialogTypeComponents };
};
