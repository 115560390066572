/* eslint-disable max-len */
import React from "react";

export function EditDesignIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 14 13" fill="none" {...props}>
            <path
                d="M1.37496 12.0966L1.21121 9.51047L9.72161 1.00007L12.3077 3.5862L3.79734 12.0966L1.37496 12.0966Z"
                stroke="currentColor"
                strokeWidth="1.2"
            />
        </svg>
    );
}
EditDesignIcon.displayName = "EditDesignIcon";
