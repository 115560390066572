import React from "react";
import { useStudioLayout } from "../hooks/useStudioLayout";

/**
 * Used to wrap controls that should only appear for mobile
 * Note: mobile does not include 'tablet' screensizes
 */
export const MobileExperience = ({ children }: React.PropsWithChildren<{}>) => {
    const { isSmall } = useStudioLayout();

    return isSmall ? <>{children}</> : null;
};
MobileExperience.displayName = "MobileExperience";
