import React from "react";
import { FlexBox } from "@vp/swan";
import { addTeamsPlaceholder } from "@presentational";
import { ProcessType } from "@designer-suite";
import { useTrackEvents } from "@shared/features/Tracking";
import { useProcessType } from "@utilities";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector } from "@shared/redux";
import { mapDesignAttributeToProductOption } from "@shared/utils/Product";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { substrateColorAttributeKey } from "@shared/features/Product";
import { isLightColor } from "../../../../easel/designer-suite/color/colorUtil";
import "./teamsPlaceholderButtons.scss";
import { TeamsPlaceholderButton, TeamsPlaceholderButtonConfig } from "./TeamsPlaceholderButton";

const messages = defineMessages({
    addNameButton: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNameButton",
        defaultMessage: "Name placeholder"
    },
    addNumberButton: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNumberButton",
        defaultMessage: "Number placeholder"
    },
    addNamePlaceholder: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNamePlaceholder",
        defaultMessage: "Name"
    },
    addNumberPlaceholder: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNumberPlaceholder",
        defaultMessage: "Number"
    },
    addNameButtonTitle: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNameButtonTitle",
        defaultMessage: "Name",
        description: {
            note: "Title for Name placeholder button"
        }
    },
    addNameButtonDetails: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNameButtonDetails",
        defaultMessage: "Give everyone their own personal item"
    },
    addRoleButton: {
        id: "studio.components.panels.groupsAndTeamsPanel.addRoleButton",
        defaultMessage: "Role/Job title placeholder"
    },
    addRoleButtonTitle: {
        id: "studio.components.panels.groupsAndTeamsPanel.addRoleButtonTitle",
        defaultMessage: "Role/job title",
        description: {
            note: "Title for Role/job placeholder button"
        }
    },
    addRoleButtonDetails: {
        id: "studio.components.panels.groupsAndTeamsPanel.addRoleButtonDetails",
        defaultMessage: "Ideal for your staff, colleagues and personnel"
    },
    addNumberButtonTitle: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNumberButtonTitle",
        defaultMessage: "Number",
        description: {
            note: "Title for Number placeholder button"
        }
    },
    addNumberButtonDetails: {
        id: "studio.components.panels.groupsAndTeamsPanel.addNumberButtonDetails",
        defaultMessage: "Perfect to give your sports team the pro look it deserves"
    },
    addTextPlaceholderButton: {
        id: "studio.components.panels.groupsAndTeamsPanel.addTextPlaceholderButton",
        defaultMessage: "Text placeholder"
    },
    addTextPlaceholderButtonTitle: {
        id: "studio.components.panels.groupsAndTeamsPanel.addTextPlaceholderButtonTitle",
        defaultMessage: "Add your own placeholder",
        description: {
            note: "Title for Text placeholder button"
        }
    },
    addTextPlaceholderButtonDetails: {
        id: "studio.components.panels.groupsAndTeamsPanel.addTextPlaceholderButtonDetails",
        defaultMessage: "You can use this for phone numbers, emails and more"
    },
    addRolePlaceholder: {
        id: "studio.components.panels.groupsAndTeamsPanel.addRolePlaceholder",
        defaultMessage: "Role",
        description: {
            note: "Role placeholder name displayed on the canvas"
        }
    },
    addTextPlaceholder: {
        id: "studio.components.panels.groupsAndTeamsPanel.addTextPlaceholder",
        defaultMessage: "Placeholder",
        description: {
            note: "Text placeholder name displayed on the canvas as Placeholder 1, Placeholder 2, Placeholder 3 ..."
        }
    }
});

export function TeamsPlaceholderButtons() {
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const processType = useProcessType();
    const teamsPlaceholders = useAppSelector(state => state.teamsPlaceholders);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const localizedTextPlaceholderName = `${t(messages.addTextPlaceholder.id)}`;

    const substrateColorOptionName = mapDesignAttributeToProductOption(
        designAttributeMappings,
        substrateColorAttributeKey
    );

    const isLightSubstrateColor = isLightColor(studioSelectedProductOptions[substrateColorOptionName]);

    // Abel is preferred font for Teams Placeholder, but is not supported by embroidery products
    const fontFamily =
        processType === ProcessType.embroideryFlat || processType === ProcessType.embroideryCylinder ? "Arial" : "Abel";

    const getTextPlaceholderSuffix = () => {
        const indices = teamsPlaceholders
            .filter(placeholder =>
                placeholder.name?.toLowerCase().startsWith(localizedTextPlaceholderName.toLowerCase())
            )
            .map(placeholder =>
                parseInt(placeholder.name?.split(" ")[placeholder.name?.split(" ").length - 1] as string, 10)
            )
            .sort((indexA, indexB) => indexA - indexB);

        if (indices.length === 0) return 1;

        for (const [index, value] of indices.entries()) {
            // always returns the next available index in ascending order
            if (value - 1 !== index) {
                return index + 1;
            }
        }
        return indices.length + 1;
    };

    const nameButtonConfig: TeamsPlaceholderButtonConfig = {
        buttonTitle: t(messages.addNameButtonTitle.id),
        buttonAccessibilityContent: t(messages.addNameButton.id),
        buttonDetails: t(messages.addNameButtonDetails.id),
        buttonImageSrc: "/teamsImages/teamsName.png",
        placeholderKey: t(messages.addNamePlaceholder.id),
        placeholderContent: t(messages.addNamePlaceholder.id),
        placeholderType: "Name",
        trackingEvent: STUDIO_TRACKING_EVENTS.CLICK_TEAMS_NAME_PLACEHOLDER_BUTTON
    };

    const roleButtonConfig: TeamsPlaceholderButtonConfig = {
        buttonTitle: t(messages.addRoleButtonTitle.id),
        buttonAccessibilityContent: t(messages.addRoleButton.id),
        buttonDetails: t(messages.addRoleButtonDetails.id),
        buttonImageSrc: "/teamsImages/teamsRole.png",
        placeholderKey: t(messages.addRolePlaceholder.id),
        placeholderContent: t(messages.addRolePlaceholder.id),
        placeholderType: "Role",
        trackingEvent: STUDIO_TRACKING_EVENTS.CLICK_TEAMS_ROLE_PLACEHOLDER_BUTTON
    };

    const numberButtonConfig: TeamsPlaceholderButtonConfig = {
        buttonTitle: t(messages.addNumberButtonTitle.id),
        buttonAccessibilityContent: t(messages.addNumberButton.id),
        buttonDetails: t(messages.addNumberButtonDetails.id),
        buttonImageSrc: "/teamsImages/teamsNumber.png",
        placeholderKey: t(messages.addNumberPlaceholder.id),
        placeholderContent: "00",
        placeholderType: "Number",
        trackingEvent: STUDIO_TRACKING_EVENTS.CLICK_TEAMS_NUMBER_PLACEHOLDER_BUTTON
    };

    const textButtonConfig: TeamsPlaceholderButtonConfig = {
        buttonTitle: t(messages.addTextPlaceholderButtonTitle.id),
        buttonAccessibilityContent: t(messages.addTextPlaceholderButton.id),
        buttonDetails: t(messages.addTextPlaceholderButtonDetails.id),
        buttonImageSrc: "/teamsImages/teamsTextPlaceholder.png",
        placeholderKey: t(messages.addTextPlaceholder.id),
        placeholderContent: t(messages.addTextPlaceholder.id),
        placeholderType: "Text",
        trackingEvent: STUDIO_TRACKING_EVENTS.CLICK_TEAMS_TEXT_PLACEHOLDER_BUTTON
    };

    const teamsPlaceholderButtonsConfig: TeamsPlaceholderButtonConfig[] = [
        nameButtonConfig,
        roleButtonConfig,
        numberButtonConfig,
        textButtonConfig
    ];

    const onTeamsButtonClick = (
        placeholderKey: string,
        placeholderContent: string,
        placeholderType: TeamsPlaceholderType,
        trackingEvent: string
    ) => {
        return () => {
            let key = placeholderKey;
            let content = placeholderContent;
            if (placeholderType === "Text") {
                // Placeholder 1, Placeholder 2, Placeholder 3, Placeholder 4, etc.
                key = `${t(messages.addTextPlaceholder.id)} ${getTextPlaceholderSuffix() || ""}`;
                content = key;
            }

            let isNumber = false;
            if (placeholderType === "Number") {
                isNumber = true;
            }

            addTeamsPlaceholder(key, content, placeholderType, fontFamily, isNumber, isLightSubstrateColor);
            trackEvent({ eventDetail: trackingEvent });
        };
    };

    return (
        <FlexBox
            justifyContent="space-between"
            alignItems="center"
            className="teams-buttons"
            flexDirection="column"
            flexWrap="wrap"
        >
            {teamsPlaceholderButtonsConfig.map(config => (
                <TeamsPlaceholderButton
                    key={config.placeholderKey}
                    buttonConfig={config}
                    onClick={onTeamsButtonClick(
                        config.placeholderKey,
                        config.placeholderContent,
                        config.placeholderType,
                        config.trackingEvent
                    )}
                />
            ))}
        </FlexBox>
    );
}

TeamsPlaceholderButtons.displayName = "TeamsPlaceholderButtons";
