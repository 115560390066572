import { useState, useEffect, useCallback } from "react";
import type { Page } from "../../@types/page";
import { useHideUsedUploads } from "./HideUploadsProvider";
import type { ItemSelection } from "../../@types/designer";

type UploadInfo = {
    pageNumber: number;
    originalUrl: string;
    printUrl: string;
    premiumFinishUrl: string;
};

export type UploadUsage = {
    isOnPage: boolean;
    hasPremiumFinish: boolean;
};

/**
 * Returns callbacks for testing pages usage on documents and on the image panel.
 * @param documentImages list of images to be considered to be in use. (This is usually the ones on the current document)
 * @returns an object with isPageOnDocument and shouldPagesBeShown callbacks.
 */
export function useDocumentUploads(documentImages: ItemSelection) {
    const [uploadData, setUploadData] = useState<UploadInfo[]>([]);
    const { hideUsedUploads } = useHideUsedUploads();

    useEffect(() => {
        const data = documentImages.map(canvasItem => {
            const {
                _itemViewModel: { model }
            } = canvasItem;
            return {
                pageNumber: model.get("pageNumber"),
                originalUrl: model.get("orignalUrl"),
                printUrl: model.get("printUrl"),
                premiumFinishUrl: model.get("premiumFinishMaskPreviewUrl")
            };
        });
        setUploadData(current => (JSON.stringify(data) !== JSON.stringify(current) ? data : current));
    }, [documentImages]);

    const isPageOnDocument = useCallback(
        (page: Page): UploadUsage => {
            const originalUrl = page.get("originalUrl");
            const printUrl = page.get("printUrl");
            const pageNumber = page.get("pageNumber");

            const matchesPage = (uploadInfo: UploadInfo) => {
                const matchesOriginalUrl = originalUrl && originalUrl === uploadInfo.originalUrl;
                const matchesPrintUrl = printUrl && printUrl === uploadInfo.printUrl;
                const matchesPageNumber = pageNumber === uploadInfo.pageNumber;
                const matchesThumbnail = matchesOriginalUrl || matchesPrintUrl;
                return matchesThumbnail && matchesPageNumber;
            };

            const data = uploadData.find(matchesPage);
            const isOnPage = data !== undefined;
            const hasPremiumFinish = (isOnPage && data && data.premiumFinishUrl !== undefined) || false;
            return { isOnPage, hasPremiumFinish };
        },
        [uploadData]
    );

    const shouldPagesBeShown = useCallback(
        (pages: Page[]) => {
            const areAllPagesOnDocument = pages.every((page: Page) => isPageOnDocument(page).isOnPage);
            return !hideUsedUploads || (hideUsedUploads && !areAllPagesOnDocument);
        },
        [hideUsedUploads, isPageOnDocument]
    );

    return { isPageOnDocument, shouldPagesBeShown };
}
