import React from "react";
import { Slider } from "@shared/features/ColorPicker";
import classNames from "classnames";

interface Props {
    threshold: number;
    updateThreshold: (threshold: number) => void;
    onRelease: () => void;
    disabled: boolean;
}

export function SingleColorImageSlider({ threshold, updateThreshold, onRelease, disabled }: Props) {
    return (
        <div
            className={classNames("single-color-image-slider-container", {
                "single-color-image-slider-container--disabled": disabled
            })}
        >
            <Slider
                value={threshold}
                onChange={updateThreshold}
                onRelease={onRelease}
                disabled={disabled}
                min={0}
                max={100}
                barStyle={{
                    background: disabled
                        ? "#dfdfdf"
                        : `linear-gradient(to right, #000 0%, #000 ${threshold}%, #dfdfdf ${threshold}%, #dfdfdf 100%)`
                }}
            />
        </div>
    );
}

SingleColorImageSlider.displayName = "SingleColorImageSlider";
