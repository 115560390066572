import { v5 as uuidV5 } from "uuid";
import { tryFetch } from "@shared/utils/Network";
import { DSS } from "@vp/types-ddif";
import { getTransientPreviewWithTransparentBG } from "@shared/utils/Previews/transientPreviewUtilities";

const apiKey = "fe2CE1b587c944DA8fDC3ba691x75418DDb";

export interface InspireGenerateText {
    content: string;
    type: "primary" | "secondary";
    isPlaceholder: boolean;
}

export interface InspireGenerateInput {
    inspireGeneratePayload: {
        themeQuery?: string;
        texts: InspireGenerateText[];
        surfaceSpecificationUrl: string;
        substrateColor: string;
        useImage: boolean;
        colorPalette?: {
            primary: string;
            secondary: string;
        };
        generateOptions?: {
            iconCollections: string[];
        };
    };
    locale: string;
}

interface InspireGenerateCimDocMetadata extends DSS.Metadata {
    artworkGeneration: {
        assets: {
            layoutName: string;
            iconUrl?: string;
            colorPalette: {
                primary: string;
                secondary: string;
            };
        };
    };
}

export interface InspirationGenerateCimDoc extends DSS.DesignDocument {
    metadata: InspireGenerateCimDocMetadata;
}

export type InspireGenerateDesignInfo = {
    id: string;
    metadata: {
        document: DSS.DesignDocument;
        previewUrl: string;
        consumerSegment: string;
        locale: string;
    };
    confidence: number;
};

export interface InspireGenerateOutput {
    id?: string;
    results: InspireGenerateDesignInfo[];
}

export type GenerateDesigns = (data: InspireGenerateInput) => Promise<InspireGenerateOutput>;

export const generateDesignVariationId = (key: string): string => uuidV5(key, uuidV5.DNS);

export const generateDesigns: GenerateDesigns = async ({ inspireGeneratePayload, locale }) => {
    try {
        const url = `${CUSTOMIZATION_TRANSFER_URL}api/v3/inspiration:generate`;

        const response = await tryFetch({
            url,
            retryCount: 3,
            options: {
                method: "POST",
                body: JSON.stringify({ ...inspireGeneratePayload, apiKey }),
                headers: {
                    "content-type": "application/json"
                }
            }
        });

        const resultPromise = response.map(async (document: InspirationGenerateCimDoc, index: number) => {
            const {
                metadata: {
                    artworkGeneration: {
                        assets: { layoutName, iconUrl }
                    }
                }
            } = document;
            const key = `${layoutName}${iconUrl ?? ""}`;

            return {
                id: generateDesignVariationId(key),
                metadata: {
                    document,
                    previewUrl: await getTransientPreviewWithTransparentBG({ cimDoc: document }),
                    consumerSegment: "consumer",
                    locale
                },
                confidence: index + 1
            };
        });

        const results = await Promise.all(resultPromise);

        return { id: response.id, results };
    } catch (error) {
        // Todo proper error handling as per studio
        // eslint-disable-next-line no-console
        console.error(error);
        return { id: undefined, results: [] };
    }
};
