// This code has been generated via svgr
/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function SpinnerIcon(props: Props) {
    return (
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="spinner_svg__uil-ring-alt" {...props}>
            <path fill="none" className="spinner_svg__bk" d="M0 0h100v100H0z" />
            <circle cx={50} cy={50} r={40} stroke="#c4cdd6" fill="none" strokeWidth={10} />
            <circle cx={50} cy={50} r={40} stroke="#188AA8" fill="none" strokeWidth={10}>
                <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from={0} to={-502} />
                <animate
                    attributeName="stroke-dasharray"
                    dur="2s"
                    repeatCount="indefinite"
                    values="75.3 175.7;1 250;75.3 175.7"
                />
            </circle>
        </svg>
    );
}
SpinnerIcon.displayName = "SpinnerIcon";
