import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { useStudioFlexibility } from "@shared/features/Flexibility";
import { StatusMessages } from "@shared/features/SystemMessages";
import { useLayoutManager } from "../StudioLayout/LayoutManager";
import Toaster from "./Toaster";

export function ToastContainer() {
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isFlexibilityFeatureEnabled = isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled;
    const { topRect } = useLayoutManager();
    let portalElement;
    if (typeof document !== "undefined") {
        portalElement = document.getElementsByClassName(`swan-site-content`)?.[0];
    }

    const Toast = useCallback(
        ({ className }: { className?: string }) => {
            return (
                <div className={classNames("toast-container", className)} style={{ top: `${topRect.height}px` }}>
                    <StatusMessages />
                    <Toaster />
                </div>
            );
        },
        [topRect.height]
    );

    return (
        <>
            {isFlexibilityFeatureEnabled && portalElement ? (
                <>{ReactDOM.createPortal(<Toast className="flexibility-enabled-toast" />, portalElement)}</>
            ) : (
                <Toast />
            )}
        </>
    );
}

ToastContainer.displayName = "ToastContainer";
