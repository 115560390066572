import * as React from "react";
import { Link, ProgressiveImage } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";

export const messages = defineMessages({
    headerIcon: {
        id: "studio.toolbar.headerIcon",
        defaultMessage: "Homepage",
        description: {
            note: "alt text for icon to return to the homepage"
        }
    }
});

export const MiniLogo = ({ logo, url, className }: any) => {
    const { t } = useTranslationSSR();
    const { image, alt = "Mini logo" } = logo;

    if (!image) {
        return null;
    }

    const linkAriaLabel = t(messages.headerIcon.id);

    if ("contents" in image) {
        return (
            <Link skin="unstyled" href={url} className={className} aria-label={linkAriaLabel}>
                <span dangerouslySetInnerHTML={{ __html: image.contents }}></span>
            </Link>
        );
    }
    if (logo.lazy) {
        return (
            <Link skin="unstyled" href={url} className={className} aria-label={linkAriaLabel}>
                <ProgressiveImage src={image.url} trigger={"inView"}>
                    {(theImg, isLoading) => <img alt={alt} style={{ opacity: isLoading ? 0.1 : 1 }} src={theImg.src} />}
                </ProgressiveImage>
            </Link>
        );
    }
    return (
        <Link skin="unstyled" href={url} className={className} aria-label={linkAriaLabel}>
            <img alt={alt} src={image.url} />
        </Link>
    );
};

MiniLogo.displayName = "MiniLogo";
