import { AppDispatch, Store, setMatchingTemplates, setMatchingTemplatesLoaded } from "@shared/redux";
import { TemplateInfo, SurfaceUpsell } from "@shared/utils/Calcifer";
import { V2 } from "@shared/utils/DSS";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import {
    getTransientPreviewUrlWithFullBleed,
    getTransientPreviewURL,
    getTransientPreviewUrlWithTemplateToken
} from "@shared/utils/Previews";
import { FULLBLEED_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import cloneDeep from "lodash/cloneDeep";

export const prefetchMatchingPreviews =
    (
        templates: TemplateInfo[],
        surfaceUpsells: SurfaceUpsell[] | undefined,
        authToken: string,
        transientScenes: any[]
    ) =>
    async (dispatch: AppDispatch, getState: typeof Store.getState) => {
        try {
            const { productKey, productVersion, studioSelectedProductOptions, locale } = getState();
            if (productVersion === null) {
                throw Error("Product version is not defined");
            }

            // Clone selected options as to not mutate redux state
            const transientPreviewSelectedOptions = cloneDeep(studioSelectedProductOptions);

            // set everything to color!
            // eslint-disable-next-line no-unused-expressions
            surfaceUpsells?.forEach(upsell => {
                transientPreviewSelectedOptions[upsell.optionName] = upsell.colorOption ?? "";
            });

            // get a full color blank document to use
            const blankDocument = await V2.getDesignDocFromTemplateToken(
                productKey,
                productVersion,
                transientPreviewSelectedOptions,
                "",
                locale
            );

            const templatesAndFullBleed = [
                ...templates,
                // we want one fullbleed option per surface upsell.  this also drops the fullbleed options at the end which is what we currently want
                ...(surfaceUpsells?.map(upsell => {
                    return { panelName: upsell.panelName, templateToken: FULLBLEED_SELECTED_TEMPLATE };
                }) ?? [])
            ];

            const matchingTemplatesWithPreviewUrls = await Promise.all(
                templatesAndFullBleed
                    .filter(
                        template =>
                            !!template.panelName &&
                            blankDocument.document.panels.some(panel => {
                                return (
                                    template.panelName!.toLowerCase() === panel.name?.toLowerCase() &&
                                    panel.colorMode !== "blank"
                                );
                            })
                    )
                    .map(async template => {
                        let previewUrl = null;
                        switch (template.templateToken) {
                            case FULLBLEED_SELECTED_TEMPLATE:
                                previewUrl = await getTransientPreviewUrlWithFullBleed(
                                    transientPreviewSelectedOptions,
                                    400, // Width
                                    blankDocument.document.panels.findIndex(panel => {
                                        return template.panelName!.toLowerCase() === panel.name?.toLowerCase();
                                    }) + 1,
                                    transientScenes.find(
                                        scene =>
                                            scene.name && scene.name.toLowerCase() === template.panelName!.toLowerCase()
                                    ),
                                    authToken
                                );
                                break;
                            default:
                                if ((template as any).colorMatchedDocument) {
                                    const { colorMatchedDocument } = template as any;
                                    previewUrl = await getTransientPreviewURL({
                                        designDocument: colorMatchedDocument,
                                        page: 1,
                                        // Note we are using the "front" scene for the backside generic previews
                                        // because artwork generation only returns a document with one panel
                                        // (also happens to be named front for some reason, if they update the id of the panel
                                        // to be anything but "front", this logic will break)
                                        scene: transientScenes.find(
                                            scene =>
                                                scene.name &&
                                                scene.name.toLowerCase() ===
                                                    colorMatchedDocument.document.panels[0].id.toLowerCase()
                                        ),
                                        authToken
                                    });
                                } else {
                                    previewUrl = await getTransientPreviewUrlWithTemplateToken(
                                        blankDocument,
                                        template.templateToken,
                                        transientPreviewSelectedOptions,
                                        400, // Width
                                        blankDocument.document.panels.findIndex(panel => {
                                            return template.panelName!.toLowerCase() === panel.name?.toLowerCase();
                                        }) + 1,
                                        transientScenes.find(
                                            scene =>
                                                scene.name &&
                                                scene.name.toLowerCase() === template!.panelName!.toLowerCase()
                                        ),
                                        authToken
                                    );
                                }
                        }
                        return {
                            ...template,
                            previewUrl
                        };
                    })
            );

            dispatch(setMatchingTemplates(matchingTemplatesWithPreviewUrls));
            dispatch(setMatchingTemplatesLoaded());
        } catch (e) {
            handleError(e, ERROR_CODES.MATCHING_TEMPLATE_LOAD);
        }
    };
