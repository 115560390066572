import React, { useState } from "react";
import { Typography, Icon, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { EditDesignIcon } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { FULLBLEED_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import {
    StudioPanelDesignRequirements,
    useDesignRequirementsContext,
    useIsBlankAndHasNoUpsells,
    useNeedAddButtonContext
} from "@shared/features/Product";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import type { GetDocument } from "@shared/utils/CimDoc";
import { useOnAddClick, useOnCanvasClick, useOnContinueClick } from "@shared/features/DesignPanel";
import { usePreviewsContext } from "@shared/features/Previews";
import { CanvasSelectorChangePopover } from "./CanvasSelectorChangePopover";
import { CanvasSelectorPanel } from "./CanvasSelectorPanel";
import { CanvasTileButton } from "./CanvasTileButton";
import { canvasSelectorMessages } from "./canvasSelectorMessages";
import { CounterButton } from "./CounterButton";
import * as styles from "./CanvasSelectorMini.module.scss";
import { useTriggerStartingCanvas } from "./hooks/useTriggerStartingCanvas";

const MANY_SIDE_THRESHOLD = 4;

interface Props {
    activeCanvasName?: string;
    getDocument: GetDocument;
    triggerCanvasChange: TriggerCanvasChange;
}

export function CanvasSelectorMini({ activeCanvasName, getDocument, triggerCanvasChange }: Props) {
    const { t } = useTranslationSSR();

    const surfaceUpsellsLoaded = useAppSelector(state => state.surfaceUpsellsLoadedState);
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const isBlankAndHasNoUpsells = useIsBlankAndHasNoUpsells();
    const needAddButton = useNeedAddButtonContext();
    const onAddClick = useOnAddClick();
    const onCanvasClick = useOnCanvasClick({ activeCanvasName, getDocument, triggerCanvasChange });
    const onContinueClick = useOnContinueClick();
    const { canvasSelectorUrls } = usePreviewsContext();
    const [showPanel, setShowPanel] = useState(false);
    const { isSmall, isMedium } = useStudioLayout();
    const matchingTemplates = useAppSelector(state => state.matchingTemplates);
    const [showChangePopover, setShowChangePopover] = useState(true);
    const { useCanvasChangePopover } = useAppSelector(state => state.studioConfiguration);
    const designRequirements = useDesignRequirementsContext();

    useTriggerStartingCanvas({ activeCanvasName, getDocument, triggerCanvasChange });

    const shouldShowChangePopover = showChangePopover && useCanvasChangePopover;

    if (
        !designRequirements ||
        !surfaceUpsellsLoaded ||
        !canvasSelectorUrls ||
        !easelLoaded ||
        (!isSmall && !isMedium) ||
        !activeCanvasName
    ) {
        return null;
    }

    if (designRequirements.numberOfPanels === 1) {
        return null;
    }

    const designableCanvases = designRequirements.panels.filter(
        (panel: StudioPanelDesignRequirements, index: number) =>
            // @ts-ignore
            !isBlankAndHasNoUpsells(panel, canvasSelectorUrls[index]?.name)
    );
    if (designableCanvases.length < 2) {
        return null;
    }

    const manyCanvasSelector = designRequirements.numberOfPanels >= MANY_SIDE_THRESHOLD;
    const activeCanvasIndex = designRequirements.getPanelIndexByName(activeCanvasName);

    return (
        <div className={styles.positioningContainer}>
            <FlexBox className={styles.container} role="radiogroup">
                {designRequirements.panels.map((panel: StudioPanelDesignRequirements, panelIndex: number) => {
                    if (!canvasSelectorUrls[panelIndex]) {
                        return null;
                    }
                    const canvasData = canvasSelectorUrls[panelIndex];
                    const isActive = activeCanvasName === panel.name;
                    if (manyCanvasSelector && !isActive) {
                        return null;
                    }
                    const { name, title } = canvasData;

                    if (isBlankAndHasNoUpsells(panel)) {
                        return null;
                    }

                    return (
                        <div className={styles.canvasTileWrapper} key={panelIndex}>
                            {needAddButton(panel, name) ? (
                                <CanvasTileButton
                                    isActive={panel.name === activeCanvasName}
                                    className={styles.canvasButton}
                                    onClick={() => onAddClick(canvasData)}
                                >
                                    <FlexBox alignItems="center">
                                        <Icon skin="standard" iconType="plus" />
                                        <Typography fontSize="-1" textAlign="center" marginLeft={2}>
                                            {t(canvasSelectorMessages.sideselectorAdd.id, { sideName: title })}
                                        </Typography>
                                    </FlexBox>
                                </CanvasTileButton>
                            ) : (
                                <CanvasTileButton
                                    isActive={panel.name === activeCanvasName}
                                    onClick={() => {
                                        panelIndex !== activeCanvasIndex
                                            ? onCanvasClick(canvasData)
                                            : onContinueClick(panelIndex, canvasData);
                                    }}
                                    className={styles.canvasButton}
                                >
                                    <FlexBox alignItems="center">
                                        <Typography fontSize="-1" textAlign="left">
                                            {title}
                                        </Typography>
                                        {
                                            // Doing this check to not show the change button on front side
                                            // also to only show this for the active canvasß
                                            panelIndex !== 0 && activeCanvasName && panel.name === activeCanvasName && (
                                                <div className={styles.selectorChangeContainer}>
                                                    {shouldShowChangePopover &&
                                                        (matchingTemplates ?? []).filter(
                                                            (t: any) =>
                                                                t.panelName === name &&
                                                                t.templateToken !== FULLBLEED_SELECTED_TEMPLATE
                                                        ).length > 2 && (
                                                            <CanvasSelectorChangePopover
                                                                title={title as string}
                                                                setShowState={setShowChangePopover}
                                                                className={styles.changePopover}
                                                            />
                                                        )}
                                                    <div className={styles.tileEditIcon}>
                                                        <EditDesignIcon
                                                            onClick={() => onContinueClick(panelIndex, canvasData)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </FlexBox>
                                </CanvasTileButton>
                            )}
                        </div>
                    );
                })}
                {manyCanvasSelector && (
                    <CounterButton onClick={() => setShowPanel(true)} count={designRequirements.numberOfPanels - 1} />
                )}
            </FlexBox>
            {manyCanvasSelector && (
                <CanvasSelectorPanel
                    isOpen={showPanel}
                    onClose={() => setShowPanel(false)}
                    activeCanvasName={activeCanvasName}
                    getDocument={getDocument}
                    triggerCanvasChange={triggerCanvasChange}
                />
            )}
        </div>
    );
}
CanvasSelectorMini.displayName = "CanvasSelectorMini";
