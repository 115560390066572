import React, { CSSProperties, forwardRef, useEffect, useLayoutEffect, RefObject, useRef } from "react";
import { clamp } from "@design-stack-ct/utility-core";
import { isSafari } from "react-device-detect";
import { usePinch } from "@use-gesture/react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    useStudioFlexibility,
    useActiveFlexibilityOptions,
    FlexibilityDesignAttributes,
    CanvasFlexibilityButton
} from "@shared/features/Flexibility";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import classNames from "classnames";
import { usePrevious } from "@design-stack-ct/utility-react";
import { GridOverlay, GridPortal, useActiveCanvas, useDesigner } from "@designer-suite";
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM } from "@easel";
import { ChangeSizeIcon } from "@shared/features/StudioChrome";
import { useAppSelector } from "@shared/redux";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useZoomManager } from "src/easel/designer-suite/ZoomToolbar/ZoomManagerContext";
import { useLayoutManager } from "./LayoutManager";
import { MailingServicesUSPSTooltip } from "../MailingServices";
import PremiumFinishPreviewLayer from "../PhotonFinishRenderer/PremiumFinishPreviewLayer";
import "./canvas.scss";

export const getTopContainerOffset = (isMobile: boolean) => (isMobile ? 0 : 33);

const getDOMRectCenter = ({ x, y, width, height }: DOMRect): { x: number; y: number } => {
    return { x: x + width / 2, y: y + height / 2 };
};

const messages = defineMessages({
    changeTrim: {
        id: "studio.canvas.trimButton",
        defaultMessage: "Trim: ",
        description: {
            note: "Appears as a label on change trim button"
        }
    },
    changeSize: {
        id: "studio.canvas.sizeButton",
        defaultMessage: "Size: ",
        description: {
            note: "Appears as a label on change size button"
        }
    }
});

export const Canvas = forwardRef((_props, ref: RefObject<HTMLDivElement>) => {
    const { leftRect, rightRect, contextualRect, topRect, bottomRect } = useLayoutManager();
    const { canvasZoomScale, setCanvasZoomScale, setCanvasZoomFactor } = useZoomManager();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const activeCanvas = useActiveCanvas("change:currentZoom");
    const currentZoomFactor = activeCanvas?._canvasViewModel.get("currentZoom");
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const { isMedium, isSmall } = useStudioLayout();
    const { isMileStone1Enabled } = useStudioFlexibility();
    const {
        isDesignAttributeActive,
        onClickFlexibilityOptions,
        getTranslatedOptionName,
        getCurrentTrimIcon,
        getProductSize
    } = useActiveFlexibilityOptions();
    const trimButtonRef = useRef<HTMLButtonElement>(null);
    const sizeButtonRef = useRef<HTMLButtonElement>(null);
    const trackingLabelOnCanvasTrim = "click on change trim button on canvas";
    const trackingLabelOnCanvasSize = "click on change size button on canvas";
    const isSizeAttributeActive = isDesignAttributeActive(FlexibilityDesignAttributes.Size);

    const topContainerOffset = getTopContainerOffset(isSmall); // Height of the Bleed indicator minus the 20px margin on top of the panel
    const bottomContainerOffset = isSmall ? 109 : 36;
    let widthOffset = isMedium ? 15 : 0;
    const topTrimButtonOffset = 45;
    const bottomSizeButtonOffset = 15;
    const canvasTopMargin = isSmall ? 100 : topRect.height + contextualRect.height + topContainerOffset;
    const canvasBottomMargin = isSmall ? 200 : bottomRect.height + bottomContainerOffset;
    // It’s used to take up more padding of the canvas when certain conditions happen.
    // So we zoom in we’re taking 15 pixels off width to allow it to shrink in the page
    if (currentZoomFactor > 1) {
        widthOffset += 15;
    }

    // We want the width and height of the canvas to always start within the bounds of our UI
    // Width is the full width of the screen minus the width of the left container, the right container and a little extra
    const canvasWidth = `calc(100vw - ${leftRect.width + widthOffset + rightRect.width}px)`;

    // The height of the container is full screen minus an offset for the bottom and top, plus the height of the top, bottom and contextual toolbar.
    const canvasHeight = `calc(100vh - ${
        bottomContainerOffset + topContainerOffset + topRect.height + bottomRect.height + contextualRect.height + 30 // TODO-atom figure out this magic 30
    }px)`;
    let style: CSSProperties = {
        width: canvasWidth,
        height: canvasHeight
    };
    const canvasButtonStyle: CSSProperties = {
        position: "absolute",
        padding: "6px 15px",
        minHeight: "30px",
        fontSize: "12px"
    };
    if (currentZoomFactor > 1) {
        style = {
            minWidth: canvasWidth,
            minHeight: canvasHeight
        };
    }

    // Need previous versions of these constants to calculate its previous center point for panning.
    const prevCanvasRect = usePrevious(ref?.current?.getBoundingClientRect());
    const prevZoomFactor = usePrevious(currentZoomFactor);

    // This effect will pan the window to the center point of the zoomed canvas when zoomed in greater than 100%.
    // Does not need to pan the window below 100% because canvas will always be centered to the area.
    useLayoutEffect(() => {
        if (!easelLoaded || !prevCanvasRect || !prevZoomFactor || !ref || !ref.current || !designer) {
            return;
        }
        const canvasRect = ref.current.getBoundingClientRect();
        // @ts-ignore
        const innerCanvasRect = window.$(".dcl-canvas--active")[0]?.getBoundingClientRect();
        if (innerCanvasRect && canvasRect) {
            if (trimButtonRef && trimButtonRef.current) {
                trimButtonRef.current.style.left = `${innerCanvasRect.x - canvasRect.x}px`;
                trimButtonRef.current.style.top = `${innerCanvasRect.y - canvasRect.y - topTrimButtonOffset}px`;
            }
            if (sizeButtonRef && sizeButtonRef.current) {
                sizeButtonRef.current.style.top = `${
                    innerCanvasRect.y - canvasRect.y + innerCanvasRect.height + bottomSizeButtonOffset
                }px`;
            }
        }
        const prevCenter = getDOMRectCenter(prevCanvasRect);
        const currentCenter = getDOMRectCenter(canvasRect);

        const deltaZoomFactor = currentZoomFactor - prevZoomFactor;
        const deltaX = currentCenter.x - prevCenter.x;
        const deltaY = currentCenter.y - prevCenter.y;

        // Check for zoom greater than 100% and required to check the delta zoomFactor because of the Range component
        // of the zoom toolbar firing the zoom function twice.
        if (currentZoomFactor > 1 && deltaZoomFactor !== 0) {
            window.scrollTo(window.scrollX + deltaX, window.scrollY + deltaY);
            if (isSmall || isMedium) {
                const element = document.getElementsByClassName("easel-contents")[0];
                element.scrollTo(Math.abs(element.scrollLeft + deltaX), Math.abs(element.scrollTop + deltaY));
            }
        }
    }, [currentZoomFactor, ref, prevCanvasRect, prevZoomFactor, isSmall, isMedium, easelLoaded, designer]);

    // Help force the initial / window resized version to zoom to fit
    useEffect(() => {
        if (currentZoomFactor <= 1) {
            designer?.eventBus.trigger(designer?.eventBus.events.canvasContainerResize);
        }
        // Intentionally not triggering when currentZoomFactor changes,
        //  causes problems with keyboard commands
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leftRect.width, designer]);

    useEffect(() => {
        const onResize = () => {
            // The new canvas wrapper is fluid and can't hold the window resize with the
            // current zoom because the Cimpress render will be forcing the canvas size
            // to increase even more.Keeping on 100 % makes the experience smooth
            designer?.eventBus.trigger(designer?.eventBus.events.canvasContainerResize);
            designer?.eventBus.trigger(designer?.eventBus.events.zoomSet, 1);
        };

        window?.addEventListener("resize", onResize);
        return () => window?.removeEventListener("resize", onResize);
    }, [designer]);

    usePinch(
        state => {
            const {
                dragging,
                last,
                movement: [scale]
            } = state;

            if (dragging) {
                return;
            }

            const newZoom = clamp(currentZoomFactor * scale, DEFAULT_MIN_ZOOM, DEFAULT_MAX_ZOOM);
            const clampedScale = newZoom / currentZoomFactor;

            if (last) {
                setCanvasZoomScale(1);
                setCanvasZoomFactor(newZoom);
                designer?.eventBus.trigger(designer?.eventBus.events.zoomSet, newZoom);
            } else {
                setCanvasZoomScale(clampedScale);
                setCanvasZoomFactor(newZoom);
            }
        },
        {
            enabled: easelLoaded,
            eventOptions: { passive: false },
            pointer: { touch: true },
            target: ref
        }
    );

    return (
        <>
            <div
                id="canvases"
                className={classNames("easel-contents-canvas", {
                    "move-canvas-info-icons": isMileStone1Enabled
                })}
                ref={ref}
                style={{
                    transform: `scale(${canvasZoomScale})`,
                    marginLeft: leftRect.width + widthOffset,
                    marginTop: canvasTopMargin,
                    marginBottom: canvasBottomMargin,
                    marginRight: rightRect.width,
                    ...style
                }}
            >
                {isMileStone1Enabled && easelLoaded && (
                    <>
                        {
                            <CanvasFlexibilityButton
                                ref={sizeButtonRef}
                                icon={<ChangeSizeIcon />}
                                style={canvasButtonStyle}
                                title={`${t(messages.changeSize.id)}${getProductSize()}`}
                                readOnly={!isSizeAttributeActive}
                                onClick={() => {
                                    if (isSizeAttributeActive) {
                                        onClickFlexibilityOptions(
                                            STUDIO_TRACKING_EVENTS.CLICK_SIZE_ON_CANVAS_BUTTON,
                                            trackingLabelOnCanvasSize,
                                            FlexibilityDesignAttributes.Size
                                        );
                                    }
                                }}
                            />
                        }
                        {isDesignAttributeActive(FlexibilityDesignAttributes.Trim) && (
                            <CanvasFlexibilityButton
                                ref={trimButtonRef}
                                icon={getCurrentTrimIcon()}
                                style={canvasButtonStyle}
                                title={`${t(messages.changeTrim.id)}${getTranslatedOptionName(
                                    FlexibilityDesignAttributes.Trim
                                )}`}
                                onClick={() =>
                                    onClickFlexibilityOptions(
                                        STUDIO_TRACKING_EVENTS.CLICK_TRIM_ON_CANVAS_BUTTON,
                                        trackingLabelOnCanvasTrim,
                                        FlexibilityDesignAttributes.Trim
                                    )
                                }
                            />
                        )}
                    </>
                )}
                {easelLoaded && <MailingServicesUSPSTooltip canvasWidth={ref.current?.clientWidth ?? 0} />}
            </div>
            {easelLoaded && (
                <>
                    {!isSafari && <PremiumFinishPreviewLayer />}
                    <GridPortal>
                        <GridOverlay />
                    </GridPortal>
                </>
            )}
        </>
    );
});

Canvas.displayName = "Canvas";
