import { DesignAttributeMapping, CompatibleProductOption, MappedProductOption } from "./types";

// map product options with design attributes
export const mapProductOptionsToDesignAttributes = (
    designAttributeMappings: DesignAttributeMapping[],
    compatibleProductOptions: CompatibleProductOption[]
): MappedProductOption[] => {
    const mappedAttributes: MappedProductOption[] = [];
    compatibleProductOptions.forEach((productOption: CompatibleProductOption) => {
        const { name: productOptionName, values: productOptionValues } = productOption;
        productOptionValues.forEach((productOptionValue: string) => {
            const designAttribute = designAttributeMappings.find(
                (mapping: DesignAttributeMapping) => mapping.productOptionValue === productOptionValue
            );
            if (designAttribute) {
                // add matching design attribute if available
                mappedAttributes.push(designAttribute);
            } else {
                // add product option in case no matching design attribute available
                mappedAttributes.push({ productOptionName, productOptionValue });
            }
        });
    });
    return mappedAttributes;
};
