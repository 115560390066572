import { useState, useEffect } from "react";
import { useDesigner } from "./DesignerProvider";

export function useActiveCanvas(events?: string) {
    const designer = useDesigner();
    const [canvas, setCanvas] = useState<Canvas | undefined>(
        designer
            ? designer.api.design.canvases.find(can => can._canvasViewModel.get("active")) ||
                  designer.api.design.canvases[0]
            : undefined
    );

    useEffect(() => {
        // useEffects are fully isolated and maintains the local state
        let mounted = true;
        if (!designer) return;
        const updateCanvas = () => {
            if (!mounted) return;
            const { canvases } = designer.api.design;
            const activeCanvas = canvases.find(can => can._canvasViewModel.get("active")) || canvases[0];
            setCanvas(activeCanvas);
        };
        const listenEvents = `add remove change:active ${events}`;
        designer.documentRepository.getViewModelRepository().on(listenEvents, updateCanvas);
        updateCanvas();

        /* eslint-disable consistent-return */
        return () => {
            mounted = false;
            designer.documentRepository.getViewModelRepository().off(listenEvents, updateCanvas);
        };
    }, [designer, events]);

    return canvas;
}
