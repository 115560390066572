import React from "react";
import { Helmet } from "react-helmet";
import { useAppSelector } from "@shared/redux";
import { usePreviewsContext } from "@shared/features/Previews";

export function PreviewCacher() {
    const { previewUrls } = usePreviewsContext();
    const showPreview = useAppSelector(state => state.previewOpen);
    const showDesignReview = useAppSelector(state => state.designReviewState.show);

    if (!previewUrls || previewUrls.length === 0) {
        return null;
    }

    const show = showPreview || showDesignReview;
    return (
        <Helmet>
            {(show ? previewUrls : [previewUrls[0]]).map(preview => {
                return <link rel="preload" as="image" key={preview.name} href={preview.src} />;
            })}
        </Helmet>
    );
}

PreviewCacher.displayName = "PreviewCacher";
