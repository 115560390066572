import { Issue, Severity, useSmartValidationsConfig } from "@design-stack-vista/smart-validations-ui";
import { useDesigner } from "@designer-suite";
import cloneDeep from "lodash/cloneDeep";
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { DSS } from "@vp/types-ddif";
import type { Designer } from "src/easel/designer-suite/@types/designer";
import { useTranslationSSR } from "@vp/i18n-helper";
import type { StudioValidationRecord } from "./StudioValidationTypes";
import { SmartValidationEvents, trackSmartValidationEvent } from "./trackingClient";
import { getValidationInfo, saveCanvasInfo } from "./ValidationMetadata";
import { convertDesignerValidation, issuesReducer } from "./ValidationUtils";

export type CanvasValidationRecord = Record<string, StudioValidationRecord>;
interface Data {
    itemValidations: StudioValidationRecord;
    canvasValidations: CanvasValidationRecord;
    validationCount: number;
    containsErrors: boolean;
    updateSmartFixedItems: (studioValidation: DSS.StudioValidation) => void;
    dismissValidation: (studioValidation: DSS.StudioValidation) => void;
    lastResolvedItemId?: string;
    hasPanelBeenOpen: boolean;
    setHasPanelBeenOpen: (value: boolean) => void;
    dismissAllValidations: () => void;
    getReducedIssues: () => Issue[];
    startDismissValidation: (studioValidation: DSS.StudioValidation) => void;
}

interface ValidationProviderProps {
    children: ReactNode | ReactNode[];
}

interface ValidationLists {
    canvasValidations: CanvasValidationRecord;
    itemValidations: StudioValidationRecord;
}

const context = createContext<Data | undefined>(undefined);
const { Provider } = context;

export function useValidations() {
    const result = useContext(context);
    if (!result) {
        throw Error("No validation context found, please call this within a validation provider.");
    }
    return result;
}

const modifyList = (
    list: StudioValidationRecord = {},
    listKey: string,
    mode: string,
    validation: DSS.StudioValidation
) => {
    const listData = list[listKey] || [];

    if (mode === "add") {
        // Check if it exists in case it was just smart fixed but not removed
        // If it exists, replace it, otherwise add it
        const index = listData.findIndex(data => data.id === validation.id);
        index !== -1 ? listData.splice(index, 1, validation) : listData.push(validation);
    } else if (mode === "change") {
        const index = listData.findIndex(data => data.id === validation.id);
        listData.splice(index, 1, validation);
    } else if (mode === "remove") {
        const index = listData.findIndex(data => data.id === validation.id);
        listData.splice(index, 1);
    }

    if (listData.length) {
        // eslint-disable-next-line no-param-reassign
        list[listKey] = listData;
    } else {
        // eslint-disable-next-line no-param-reassign
        delete list[listKey];
    }
    if (Object.keys(list).length) {
        return list;
    }
    return undefined;
};

export const flattenValidations = (validations: StudioValidationRecord): DSS.StudioValidation[] => {
    return Object.values(validations).flatMap((validations: DSS.StudioValidation[]) => validations);
};

export const ValidationProvider = ({ children }: ValidationProviderProps) => {
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const { hasSelectedBrickStyle } = useSmartValidationsConfig();
    const [validationLists, setValidationLists] = useState<ValidationLists>({
        itemValidations: {},
        canvasValidations: {}
    });
    const [dismissedValidationsByIds, setDismissedValidationsByIds] = useState<Map<string, string>>(new Map());
    const [lastSmartFixedItems, setLastSmartFixedItems] = useState<
        { itemId: string; validationName: string; canvasId: string }[]
    >([]);
    // State properties used to calculate and select next validation in the SmartValidationPanel
    const [lastResolvedItemId, setLastResolvedItemId] = useState<string | undefined>();
    const [hasPanelBeenOpen, setHasPanelBeenOpen] = useState(false);

    // Remove last validation that was smart fixed from both validation lists.
    // There should only ever be 1 validation with smartFixed: true
    const clearAllSmartFixes = useCallback(() => {
        if (lastSmartFixedItems.length > 0) {
            const oldestSmartFixedData = lastSmartFixedItems[0];
            setValidationLists(current => {
                const targetItemValidation = current.itemValidations[oldestSmartFixedData.itemId]?.find(
                    v => v.itemId === oldestSmartFixedData.itemId && v.smartFixed
                );
                const targetCanvasValidation = current.canvasValidations[oldestSmartFixedData.canvasId][
                    oldestSmartFixedData.validationName
                ]?.find(v => v.itemId === oldestSmartFixedData.itemId && v.smartFixed);

                // If neither lists have the target validation, just return the current list
                if (!targetItemValidation && !targetCanvasValidation) {
                    return current;
                }

                const copy = cloneDeep(current);

                // Go through canvasValidations list and remove the validation that has smartFixed:true
                if (targetCanvasValidation) {
                    const newList = modifyList(
                        copy.canvasValidations[oldestSmartFixedData.canvasId],
                        oldestSmartFixedData.validationName,
                        "remove",
                        targetCanvasValidation
                    );
                    if (newList) {
                        copy.canvasValidations[oldestSmartFixedData.canvasId] = newList;
                    } else {
                        delete copy.canvasValidations[oldestSmartFixedData.canvasId];
                    }
                }

                // Go through itemValidations list and remove the validation that has smartFixed:true
                if (targetItemValidation) {
                    const newList = modifyList(
                        copy.itemValidations,
                        oldestSmartFixedData.itemId,
                        "remove",
                        targetItemValidation
                    );
                    copy.itemValidations = newList || {};
                }

                return copy;
            });

            setLastSmartFixedItems(current => {
                const copy = [...current];
                if (copy.length > 0) {
                    copy.splice(0, 1);
                }
                return copy;
            });
        }
    }, [lastSmartFixedItems]);

    const getItemHash = (canvasId: string, itemId: string, designer?: Designer) => {
        const item = designer?.api.design.canvases
            .find(canvas => canvas.id === canvasId)
            ?.items.find(item => item.id === itemId);

        // items attributes are not updated when dragging the item
        // so we should reset the hash on this behaviour
        if (item?._itemViewModel.get("dragging")) return "";

        return JSON.stringify({
            pxDimensions: item?.pxDimensions,
            pxPosition: item?.pxPosition,
            rotation: item?.rotation
        });
    };

    const dismissValidation = useCallback(
        (studioValidation: DSS.StudioValidation) => {
            if (hasSelectedBrickStyle) setLastResolvedItemId(studioValidation.itemId);
            const itemHash = getItemHash(studioValidation.canvasId, studioValidation.itemId, designer);
            setDismissedValidationsByIds(prevState => {
                const newState = new Map(prevState);
                return newState.set(studioValidation.id, itemHash);
            });
            setValidationLists(current => {
                const copy = cloneDeep(current);
                const canvasValidationsCopy = copy.canvasValidations;
                const itemValidationsCopy = copy.itemValidations;

                // Remove validation from canvas validation list
                const canvas = canvasValidationsCopy[studioValidation.canvasId];
                if (canvas) {
                    const targetCanvasValidation = canvas[studioValidation.validationName]?.find(
                        validation =>
                            validation.itemId === studioValidation.itemId &&
                            validation.validationName === studioValidation.validationName
                    );
                    if (targetCanvasValidation) {
                        targetCanvasValidation.dismissed = true;

                        const newList = modifyList(
                            canvas,
                            studioValidation.validationName,
                            "change",
                            targetCanvasValidation
                        );
                        if (newList) {
                            canvasValidationsCopy[studioValidation.canvasId] = newList;
                        } else {
                            delete canvasValidationsCopy[studioValidation.canvasId];
                        }
                    }
                }

                // Remove validation from item validation list
                const targetItemValidation = itemValidationsCopy[studioValidation.itemId]?.find(
                    val => val.validationName === studioValidation.validationName
                );
                if (targetItemValidation) {
                    targetItemValidation.dismissed = true;
                    const newList = modifyList(
                        itemValidationsCopy,
                        studioValidation.itemId,
                        "change",
                        targetItemValidation
                    );
                    copy.itemValidations = newList || {};
                }
                return copy;
            });
        },
        [designer, hasSelectedBrickStyle]
    );

    const startDismissValidation = useCallback((studioValidation: DSS.StudioValidation) => {
        setValidationLists(current => {
            const copy = cloneDeep(current);
            const canvasValidationsCopy = copy.canvasValidations;
            const itemValidationsCopy = copy.itemValidations;

            // Remove validation from canvas validation list
            const canvas = canvasValidationsCopy[studioValidation.canvasId];
            if (canvas) {
                const targetCanvasValidation = canvas[studioValidation.validationName]?.find(
                    validation =>
                        validation.itemId === studioValidation.itemId &&
                        validation.validationName === studioValidation.validationName
                );
                if (targetCanvasValidation) {
                    targetCanvasValidation.dismissing = true;

                    const newList = modifyList(
                        canvas,
                        studioValidation.validationName,
                        "change",
                        targetCanvasValidation
                    );
                    if (newList) {
                        canvasValidationsCopy[studioValidation.canvasId] = newList;
                    } else {
                        delete canvasValidationsCopy[studioValidation.canvasId];
                    }
                }
            }

            // Remove validation from item validation list
            const targetItemValidation = itemValidationsCopy[studioValidation.itemId]?.find(
                val => val.validationName === studioValidation.validationName
            );
            if (targetItemValidation) {
                targetItemValidation.dismissing = true;
                const newList = modifyList(
                    itemValidationsCopy,
                    studioValidation.itemId,
                    "change",
                    targetItemValidation
                );
                copy.itemValidations = newList || {};
            }
            return copy;
        });
    }, []);

    // Adds info of the currently smart fixed item to the lastSmartFixedItems array
    // and updates the validation to be smartFixed: true
    const updateSmartFixedItems = useCallback(
        (studioValidation: DSS.StudioValidation) => {
            const { canvasId, itemId, validationName } = studioValidation;
            if (hasSelectedBrickStyle) setLastResolvedItemId(itemId);
            setLastSmartFixedItems(current => {
                const copy = [...current];
                copy.push({
                    itemId,
                    validationName,
                    canvasId
                });
                return copy;
            });

            setValidationLists(current => {
                const copy = cloneDeep(current);
                const canvasValidationsCopy = copy.canvasValidations;
                const itemValidationsCopy = copy.itemValidations;

                // Update canvas validation to set smart fixed to true for the smart fixed validation
                const canvas = canvasValidationsCopy[canvasId];
                if (canvas) {
                    const targetCanvasValidation = canvas[studioValidation.validationName]?.find(
                        validation =>
                            validation.itemId === studioValidation.itemId &&
                            validation.validationName === studioValidation.validationName
                    );
                    if (targetCanvasValidation) {
                        targetCanvasValidation.smartFixed = true;
                        const newList = modifyList(
                            canvas,
                            studioValidation.validationName,
                            "change",
                            targetCanvasValidation
                        );
                        if (newList) {
                            canvasValidationsCopy[canvasId] = newList;
                        } else {
                            delete canvasValidationsCopy[canvasId];
                        }
                    }
                }

                // Update item validation to set smart fixed to true for the smart fixed validation
                const targetItemValidation = itemValidationsCopy[studioValidation.itemId]?.find(
                    val => val.validationName === studioValidation.validationName
                );
                if (targetItemValidation) {
                    targetItemValidation.smartFixed = true;
                    const newList = modifyList(
                        itemValidationsCopy,
                        studioValidation.itemId,
                        "change",
                        targetItemValidation
                    );
                    copy.itemValidations = newList || {};
                }
                return copy;
            });
        },
        [hasSelectedBrickStyle]
    );

    const updateValidation = useCallback(
        (validation: any, mode: string) => {
            if (!designer) return;
            const { canvasId } = validation.get("data");
            let convertedValidation = convertDesignerValidation(validation);
            const { id, itemId, validationName } = convertedValidation;

            setValidationLists(({ canvasValidations, itemValidations }) => {
                const canvas = canvasValidations[canvasId];
                const isRecentlySmartFixedItemCanvasValidation =
                    (canvas && canvas[validationName]?.find(v => v.itemId === itemId)?.smartFixed) ?? false;

                const currentValidation = itemValidations[itemId]?.find(v => v.validationName === validationName);
                const isRecentlySmartFixedItemValidation = currentValidation?.smartFixed ?? false;
                let isDismissedValidation = currentValidation?.dismissed ?? false;

                const dismissedValidationByIdHash = dismissedValidationsByIds.get(id);
                if (dismissedValidationByIdHash) {
                    isDismissedValidation = true;
                    const itemHash = getItemHash(canvasId, itemId, designer);
                    if (itemHash !== dismissedValidationByIdHash) {
                        isDismissedValidation = false;
                        setDismissedValidationsByIds(prevState => {
                            const newState = new Map(prevState);
                            newState.delete(id);
                            return newState;
                        });
                    }
                }

                convertedValidation = {
                    ...convertedValidation,
                    dismissed: isDismissedValidation
                };

                const modeIsRemove = mode === "remove";

                if (
                    (!modeIsRemove && !isDismissedValidation) ||
                    (modeIsRemove && !isRecentlySmartFixedItemCanvasValidation && !isRecentlySmartFixedItemValidation)
                ) {
                    const handleCanvasValidations = (canvasVal: CanvasValidationRecord) => {
                        const updatedCanvasList = modifyList(canvas, validationName, mode, convertedValidation);
                        if (updatedCanvasList) {
                            return {
                                ...canvasVal,
                                [canvasId]: updatedCanvasList
                            };
                        }

                        const copyCanvasValidations = { ...canvasVal };
                        delete copyCanvasValidations[canvasId];
                        return copyCanvasValidations;
                    };

                    const handleItemValidation = (itemValidations: StudioValidationRecord) => {
                        const newItemList = modifyList(itemValidations, itemId, mode, convertedValidation);
                        if (newItemList) {
                            return {
                                ...itemValidations,
                                ...newItemList
                            };
                        }

                        const copyCanvasValidations = { ...itemValidations };
                        delete copyCanvasValidations[itemId];
                        return copyCanvasValidations;
                    };

                    return {
                        canvasValidations: handleCanvasValidations(canvasValidations),
                        itemValidations: handleItemValidation(itemValidations)
                    };
                }

                return { canvasValidations, itemValidations };
            });
        },
        [designer, dismissedValidationsByIds]
    );

    const dismissAllValidations = useCallback(() => {
        Object.values(validationLists.itemValidations).forEach(item => {
            item.forEach(
                validation => !validation.smartFixed && !validation.dismissed && dismissValidation(validation)
            );
        });
        trackSmartValidationEvent(SmartValidationEvents.AllAlertsDismissed);
    }, [dismissValidation, validationLists]);

    const getReducedIssues = useCallback(() => {
        if (!designer) return [];
        const initialValueReducedIssues: Record<string, Issue> = {};
        const { canvases } = designer.api.design;
        const reducedIssues = canvases.reduce((prev, curr) => {
            if (curr._canvasViewModel.get("active")) {
                const flattenedValidations = flattenValidations(validationLists.itemValidations);
                const untouchedValidations = flattenedValidations.filter(
                    validation => !validation.smartFixed && !validation.dismissed
                );
                untouchedValidations.forEach(validation => {
                    // eslint-disable-next-line no-param-reassign
                    prev[validation.validationName] = issuesReducer(validation, t, prev[validation.validationName]);
                });
            } else {
                // If it's not the active canvas get the count from the info on the side.
                const info = getValidationInfo(curr);
                const validations =
                    info?.validations && !Array.isArray(info.validations)
                        ? JSON.parse(info.validations)
                        : info?.validations;
                if (validations) {
                    validations.forEach((validation: DSS.StudioValidation) => {
                        if (!validation.dismissed && !validation.smartFixed) {
                            // eslint-disable-next-line no-param-reassign
                            prev[validation.validationName] = issuesReducer(
                                validation,
                                t,
                                prev[validation.validationName]
                            );
                        }
                    });
                }
            }
            return prev;
        }, initialValueReducedIssues);

        return Object.values(reducedIssues);
    }, [designer, validationLists.itemValidations, t]);

    useEffect(() => {
        if (!designer) {
            return;
        }

        const addValidation = (designerValidation: any) => {
            updateValidation(designerValidation, "add");
        };

        const changeValidation = (validation: any) => {
            updateValidation(validation, "change");
        };

        const removeValidation = (designerValidation: any) => {
            updateValidation(designerValidation, "remove");
        };

        designer.validationManager.models.forEach(addValidation);
        designer.validationManager.on("add", addValidation);
        designer.validationManager.on("change", changeValidation);
        designer.validationManager.on("remove", removeValidation);
        // eslint-disable-next-line consistent-return
        return () => {
            designer.validationManager.off("add", addValidation);
            designer.validationManager.off("change", changeValidation);
            designer.validationManager.off("remove", removeValidation);
        };
    }, [designer, updateValidation]);

    // Remove smart fixed validations when history changes because you can no longer undo
    useEffect(() => {
        if (!designer) {
            return;
        }

        designer?.eventBus.on(designer.eventBus.events.historyChanged, clearAllSmartFixes);
        // eslint-disable-next-line consistent-return
        return () => {
            designer?.eventBus.off(designer.eventBus.events.historyChanged, clearAllSmartFixes);
        };
    }, [clearAllSmartFixes, designer]);

    const containsErrors = useMemo(() => {
        if (!designer) {
            return false;
        }
        const { canvases } = designer.api.design;
        return canvases.some((canvas: Canvas) => {
            if (canvas._canvasViewModel.get("active")) {
                const flattenedValidations = flattenValidations(validationLists.itemValidations);
                return flattenedValidations.some(validation => validation.severity === Severity.ERROR);
            }
            // If it's not the active canvas check the info on the side.
            const info = getValidationInfo(canvas);
            return info?.containsError;
        });
    }, [validationLists, designer]);

    const validationCount = useMemo(() => {
        if (!designer) {
            return 0;
        }
        const { canvases } = designer.api.design;
        let count = 0;
        canvases.forEach((canvas: Canvas) => {
            if (canvas._canvasViewModel.get("active")) {
                const flattenedValidations = flattenValidations(validationLists.itemValidations);
                // Don't count validations that have been dismissed or smartFixed
                const untouchedValidations = flattenedValidations.filter(
                    validation => !validation.smartFixed && !validation.dismissed
                );
                count += untouchedValidations.length;
            } else {
                // If it's not the active canvas get the count from the info on the side.
                const info = getValidationInfo(canvas);
                count += info ? info.count : 0;
            }
        });
        return count;
    }, [validationLists, designer]);

    // We want to save the active canvas data to the cimdoc.
    // We need to save different validation information in metadata due to designer not being able to validate panels
    // without being on the panel and that we need this info for the validation badges, smart validation panel, analytics.
    // We need to do it here, because canvasValidations contain all validation's properties including e.g. dismissed.
    useEffect(() => {
        const activeCanvas = designer?.api.design.canvases.find(can => can._canvasViewModel.get("active")) as Canvas;

        if (activeCanvas) {
            const activeCanvasViewModel = activeCanvas._canvasViewModel;

            const activeId = activeCanvasViewModel.id;
            const canvasValidations = validationLists.canvasValidations[activeId]
                ? flattenValidations(validationLists.canvasValidations[activeId])
                : undefined;
            saveCanvasInfo(activeCanvas, canvasValidations);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationLists.canvasValidations]);

    const value = useMemo(() => {
        return {
            itemValidations: validationLists.itemValidations,
            canvasValidations: validationLists.canvasValidations,
            containsErrors,
            validationCount,
            updateSmartFixedItems,
            startDismissValidation,
            dismissValidation,
            lastResolvedItemId,
            hasPanelBeenOpen,
            setHasPanelBeenOpen,
            dismissAllValidations,
            getReducedIssues
        };
    }, [
        validationLists,
        containsErrors,
        validationCount,
        updateSmartFixedItems,
        startDismissValidation,
        dismissValidation,
        lastResolvedItemId,
        hasPanelBeenOpen,
        dismissAllValidations,
        getReducedIssues
    ]);

    return <Provider value={value}>{children}</Provider>;
};
ValidationProvider.displayName = "ValidationProvider";
