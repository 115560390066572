import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function ChangeTemplateIcon(props: Props) {
    return (
        <svg viewBox="0 0 14 17" fill="none" {...props}>
            <path
                /* eslint-disable max-len */
                d="M2.26562 17.4531H10.75C11.4062 17.4531 11.9141 17.2656 12.2734 16.8906C12.638 16.5156 12.8203 15.9583 12.8203 15.2188V6.86719C12.8203 6.1276 12.6302 5.57031 12.25 5.19531C11.875 4.82031 11.3125 4.63281 10.5625 4.63281H2.26562C1.51042 4.63281 0.942708 4.82031 0.5625 5.19531C0.1875 5.57031 0 6.1276 0 6.86719V15.2188C0 15.9635 0.1875 16.5208 0.5625 16.8906C0.942708 17.2656 1.51042 17.4531 2.26562 17.4531ZM2.28125 16.3828C1.89583 16.3828 1.59896 16.2786 1.39062 16.0703C1.1875 15.862 1.08594 15.5599 1.08594 15.1641V6.92969C1.08594 6.52865 1.1875 6.22656 1.39062 6.02344C1.59896 5.8151 1.89583 5.71094 2.28125 5.71094H10.5391C10.9245 5.71094 11.2214 5.8151 11.4297 6.02344C11.638 6.22656 11.7422 6.52865 11.7422 6.92969V15.1641C11.7422 15.5599 11.6458 15.862 11.4531 16.0703C11.2604 16.2786 11.0208 16.3828 10.7344 16.3828H2.28125ZM1.5625 3.41406H11.2656C11.2135 3.11198 11.1068 2.8776 10.9453 2.71094C10.7839 2.54427 10.5365 2.46094 10.2031 2.46094H2.61719C2.28385 2.46094 2.03646 2.54427 1.875 2.71094C1.71354 2.8776 1.60938 3.11198 1.5625 3.41406ZM2.82031 1.42188H10C9.98438 1.14062 9.89323 0.924479 9.72656 0.773438C9.5651 0.622396 9.33073 0.546875 9.02344 0.546875H3.79688C3.48958 0.546875 3.2526 0.622396 3.08594 0.773438C2.92448 0.924479 2.83594 1.14062 2.82031 1.42188Z"
                fill="currentColor"
            />
        </svg>
    );
}
ChangeTemplateIcon.displayName = "ChangeTemplateIcon";
