import type { SimplifiedEaselConfig } from "@shared/features/StudioBootstrap";
import { setCompatibleColorsWithStockInfo, useAppDispatch, useAppSelector } from "@shared/redux";
import { getSubstrateColorsWithStockInfoFromCalciferV2 } from "@shared/utils/Calcifer";
import { mapDesignAttributeToProductOption, mapProductOptionValueToDesignAttributeValue } from "@shared/utils/Product";
import { useAsync } from "react-async-hook";
import { substrateColorAttributeKey, useHasSubstrateColor } from "./useHasSubstrateColors";

export function useGetSubstrateColorsWithStockInfo(config?: SimplifiedEaselConfig) {
    const dispatch = useAppDispatch();
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const mpvId = useAppSelector(state => state.mpvId);
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const hasSubstrateColors = useHasSubstrateColor();

    useAsync(async () => {
        if (productVersion && mpvId && hasSubstrateColors) {
            try {
                const substrateColorOptionName = mapDesignAttributeToProductOption(
                    designAttributeMappings,
                    substrateColorAttributeKey
                );
                const substrateColorsWithStockInfo = await getSubstrateColorsWithStockInfoFromCalciferV2(
                    mpvId,
                    locale,
                    productKey,
                    productVersion,
                    studioSelectedProductOptions,
                    substrateColorOptionName
                );

                const mutatedColors = {};
                Object.entries(substrateColorsWithStockInfo)?.forEach(([key, value]) => {
                    const convertedPrimaryColor = mapProductOptionValueToDesignAttributeValue(
                        designAttributeMappings,
                        substrateColorOptionName,
                        value.primaryColor
                    );
                    let convertedSecondaryColor = "";
                    if (value.secondaryColor) {
                        convertedSecondaryColor = mapProductOptionValueToDesignAttributeValue(
                            designAttributeMappings,
                            substrateColorOptionName,
                            value.secondaryColor
                        );
                    }
                    mutatedColors[key] = {
                        ...value,
                        primaryColor: convertedPrimaryColor,
                        secondaryColor: convertedSecondaryColor
                    };
                });
                dispatch(
                    setCompatibleColorsWithStockInfo({
                        substrateColorsWithStockInfo: mutatedColors,
                        substrateColorOptionName
                    })
                );
            } catch {
                // Failure, should it happen is ok.
            }
        }
    }, [config, hasSubstrateColors]);
}
