import React, { useMemo } from "react";
import Gallery, { PhotoProps, RenderImageProps } from "react-photo-gallery";
import { useTranslationSSR } from "@vp/i18n-helper";
import { getDocumentItems, itemIsImagePlaceholder, useActiveCanvas } from "@designer-suite";
import { Typography, FluidImage, SquareImageContainer } from "@vp/swan";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useTrackingClient } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS, Events, getStudioUniqueSessionId } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { LoadingSpinner, SpinnerVariant, InfiniteScrollList } from "@shared/features/StudioChrome";
import { useIdeaTabEnabled } from "@five/hooks/useIdeaTabEnabled";
import { getDesignDocumentFromDesigner } from "@utilities";
import { substrateColorAttributeKey } from "@shared/features/Product";
import { AITemplate, useAITemplates } from "./AITemplatesProvider";
import { AITemplatesPanelErrors } from "./AITemplatesPanelErrors";
import { AITemplatesRecentlyUsed } from "./AITemplatesRecentlyUsed";
import { AITemplatesPanelProps } from "./AITemplatesPanelContainer";
import { getTransformedDocumentWithAITemplate, updateAITemplateDocForProduct } from "./AITemplatesPanelHelpers";
import { useAITemplateTracking } from "./useAITemplateTracking";

export function AITemplatesResults({ onDone, fullHeight = false }: AITemplatesPanelProps) {
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const quantity = useAppSelector(state => state.quantity);
    const trackingClient = useTrackingClient();
    const { t } = useTranslationSSR();
    const { auth } = useIdentityContext();
    const activeCanvas = useActiveCanvas();
    const canvasName = activeCanvas?.name;
    const {
        addNewRecentlyUsedTemplate,
        aiTemplates,
        areAITemplatesLoading,
        changeDocument,
        setCurrentlySelectedTemplate,
        setDocumentForSelectedContent,
        setIsConfirmationModalOpen,
        recentlyUsedTemplates,
        getNextGalleryBatch,
        getGalleryImages,
        hasMoreGalleryImages,
        hasGalleryImages,
        gallerySize,
        searchTerm,
        reportClickedTemplate,
        aiTemplatesPanelMessages
    } = useAITemplates();
    const showIdeaTab = useIdeaTabEnabled();
    const { tracking } = useAITemplateTracking();

    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const background = customerSelectedProductOptions?.[substrateColorAttributeKey];

    const getHasNonPlaceholderItemsOnCanvas = (activeCanvas: Canvas) => {
        const documentItems = getDocumentItems([activeCanvas]);
        if (documentItems.length > 0) {
            if (documentItems.length === 1 && itemIsImagePlaceholder(documentItems[0])) {
                return false;
            }
            return true;
        }

        return false;
    };

    const placeholderConfig = useMemo(
        () =>
            showIdeaTab
                ? {
                      primaryText: {
                          label: t(aiTemplatesPanelMessages.primaryTextFormPlaceholder.id),
                          purpose: "primarytext"
                      },
                      secondaryText: {
                          label: t(aiTemplatesPanelMessages.secondaryTextFormPlaceholder.id),
                          purpose: "secondarytext"
                      }
                  }
                : undefined,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showIdeaTab, t]
    );

    const addTemplate = async (
        template: AITemplate,
        index: number,
        isPrevious = false,
        isRecentlyUsedTemplate = false
    ) => {
        const updatedTemplate = template;
        if (isRecentlyUsedTemplate) {
            updatedTemplate.isRecentlyUsed = true;
        }

        let updatedAITemplateDocument;
        try {
            updatedAITemplateDocument = await updateAITemplateDocForProduct(
                updatedTemplate,
                productKey,
                productVersion as number,
                studioSelectedProductOptions,
                locale,
                auth,
                canvasName
            );

            const eventDetail = (
                isPrevious
                    ? STUDIO_TRACKING_EVENTS.CLICK_PREVIOUS_AI_TEMPLATE
                    : STUDIO_TRACKING_EVENTS.CLICK_AI_TEMPLATE
            )
                .replace("TEMPLATE_ID", updatedTemplate.id)
                .replace("TEMPLATE_INDEX", `${index + 1}`);

            if (searchTerm) {
                trackingClient.track(Events.SearchResultClicked, {
                    eventDetail,
                    label: `${tracking.label} Search: ${searchTerm}`
                });
            } else {
                trackingClient.track(Events.DesignToolUsed, {
                    eventDetail,
                    label: tracking.label
                });
            }

            if (activeCanvas) {
                if (getHasNonPlaceholderItemsOnCanvas(activeCanvas)) {
                    setDocumentForSelectedContent(updatedAITemplateDocument);
                    setCurrentlySelectedTemplate(updatedTemplate);
                    setIsConfirmationModalOpen(true);
                } else {
                    const existingDocument = await getDesignDocumentFromDesigner(true);
                    const sessionId = getStudioUniqueSessionId();
                    const transformedDocumentWithTemplate = await getTransformedDocumentWithAITemplate(
                        existingDocument,
                        updatedAITemplateDocument,
                        productKey,
                        productVersion,
                        studioSelectedProductOptions,
                        sessionId,
                        locale,
                        auth,
                        canvasName,
                        placeholderConfig
                    );

                    await changeDocument(
                        transformedDocumentWithTemplate,
                        productKey,
                        locale,
                        productVersion,
                        quantity,
                        studioSelectedProductOptions,
                        onDone
                    );
                    setDocumentForSelectedContent(undefined);
                    addNewRecentlyUsedTemplate(updatedTemplate);
                    reportClickedTemplate(updatedTemplate);
                }
            }
        } catch (e) {
            handleError(e, ERROR_CODES.AI_TEMPLATES_RESIZE_DOC);
            if (onDone) onDone();
        }
    };

    const onClickAITemplate = async (photo: PhotoProps) => {
        const filteredAITemplates = aiTemplates.filter((aiTemplate: AITemplate) => aiTemplate.id === photo.key);
        if (
            filteredAITemplates.length === 1 &&
            (filteredAITemplates[0]?.metadata?.cimDocUrl || filteredAITemplates[0]?.metadata?.document)
        ) {
            await addTemplate(filteredAITemplates[0], aiTemplates.indexOf(filteredAITemplates[0]));
        } else {
            handleError(
                "Could not match selected PPAG AI Template with SimTag API response",
                ERROR_CODES.AI_TEMPLATES_SELECTION_NOT_FOUND
            );
            if (onDone) onDone();
        }
    };

    const renderImage = (props: RenderImageProps) => {
        const { photo } = props;

        return (
            <div
                className="ai-templates-image-container"
                style={{ height: photo?.height, width: photo?.width, background, margin: "0px 5px" }}
                key={photo?.key}
            >
                <SquareImageContainer>
                    <FluidImage
                        alt={photo?.alt}
                        src={photo?.src}
                        onClick={() => onClickAITemplate(photo)}
                        data-testid={photo?.["data-testid"]}
                    />
                </SquareImageContainer>
            </div>
        );
    };

    return (
        <InfiniteScrollList
            hasMore={hasMoreGalleryImages}
            onNext={() => {
                trackingClient.track(Events.DesignToolUsed, {
                    eventDetail: STUDIO_TRACKING_EVENTS.SHOW_MORE_AI_TEMPLATES,
                    label: "AI Templates"
                });
                getNextGalleryBatch();
            }}
            count={gallerySize}
            id="ai-template-panel-scroll-list"
            disableAutoSearch
            spinnerClassName="ai-template-panel-scroll-list-spinner"
        >
            <AITemplatesRecentlyUsed onClick={addTemplate} />
            {recentlyUsedTemplates.length > 0 && (
                <Typography fontSize="1" textColor="gray-700" ml={1} mb={2}>
                    {t(aiTemplatesPanelMessages.allAITemplates.id)}
                </Typography>
            )}
            <AITemplatesPanelErrors />
            {areAITemplatesLoading && (
                <LoadingSpinner
                    className="mt-l"
                    variant={SpinnerVariant.Large}
                    centering
                    aria-label={t(aiTemplatesPanelMessages.aiTemplatesPanelLoaderLabel.id)}
                    data-testid="aiTemplatesLoader"
                />
            )}
            {hasGalleryImages && (
                <Gallery
                    photos={getGalleryImages()}
                    direction="column"
                    columns={2}
                    margin={5}
                    renderImage={renderImage}
                />
            )}
        </InfiniteScrollList>
    );
}

AITemplatesResults.displayName = "AITemplatesResults";
