import { DecorationTechnology } from "@shared/utils/CimDoc";
import { useAppSelector } from "@shared/redux";

export interface ToolFlags {
    shouldShowQRCodes: boolean;
    shouldShowImages: boolean;
    shouldShowGraphics: boolean;
    shouldShowTables: boolean;
    shouldShowBackgroundColor: boolean;
    shouldShowTeams: boolean;
}

// Prevent any process / deco tech specific tool restrictions
export function useToolFlags(decorationTechnology: string | undefined): ToolFlags {
    const { shouldShowQRCodes, shouldShowImages, shouldShowGraphics, shouldShowTables, shouldShowBackgroundColor } =
        useAppSelector(state => state.studioConfiguration);
    switch (decorationTechnology) {
        case DecorationTechnology.ScreenPrint:
        case DecorationTechnology.PadPrint:
            return {
                shouldShowQRCodes: false,
                shouldShowImages,
                shouldShowGraphics,
                shouldShowTables: false,
                shouldShowBackgroundColor: false,
                shouldShowTeams: false
            };
        case DecorationTechnology.LaserEngraving:
        case DecorationTechnology.EmbroideryCylinder:
        case DecorationTechnology.EmbroideryFlat:
            return {
                shouldShowQRCodes: false,
                shouldShowImages,
                shouldShowGraphics,
                shouldShowTables: false,
                shouldShowBackgroundColor: false,
                shouldShowTeams: true
            };
        case DecorationTechnology.Unknown:
            return {
                shouldShowQRCodes,
                shouldShowImages,
                shouldShowGraphics,
                shouldShowTables,
                // setting this to true can cause the button to flash and screw up the a/b test
                // since this section is just a fallback (since "unknown" is a misconfiguration) we'll just disable this
                shouldShowBackgroundColor: false,
                shouldShowTeams: false
            };
        case DecorationTechnology.DirectToGarment:
            return {
                // QR codes will not print correctly on direct to garment (https://vistaprint.slack.com/archives/C019L6KGWVA/p1675964085913519)
                shouldShowQRCodes: false,
                shouldShowImages,
                shouldShowGraphics,
                shouldShowTables,
                shouldShowBackgroundColor,
                shouldShowTeams: true
            };
        case DecorationTechnology.Digital:
        case DecorationTechnology.HeatTransfer:
        case DecorationTechnology.InkJet:
        case DecorationTechnology.Sublimation:
            return {
                shouldShowQRCodes,
                shouldShowImages,
                shouldShowGraphics,
                shouldShowTables,
                shouldShowBackgroundColor,
                shouldShowTeams: true
            };
        case DecorationTechnology.None:
        case DecorationTechnology.Offset:
        case DecorationTechnology.OffsetOrDigital:
        case DecorationTechnology.Thermography:
        default:
            return {
                shouldShowQRCodes,
                shouldShowImages,
                shouldShowGraphics,
                shouldShowTables,
                shouldShowBackgroundColor,
                shouldShowTeams: false
            };
    }
}
