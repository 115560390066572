import React from "react";
import { Typography, Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { addQueryParam } from "@shared/utils/WebBrowser";
import { fireLoginTrackingEvent } from "@shared/utils/Tracking";
import { messages } from "./messages";
import * as styles from "./MyProjectsLoginButton.module.scss";

export function MyProjectsLoginButton() {
    const { t } = useTranslationSSR();
    const { auth } = useIdentityContext();
    const { isSmall } = useStudioLayout();

    function handleLogin() {
        const loginReason = "myProjectsPanel";
        (async () => {
            // skipping login since you have to login to get to the my projects panel anyway
            // @ts-ignore calling a js file without type
            fireLoginTrackingEvent({
                eventDetail: "click:loginbutton",
                label: "General",
                extraData: () => ({
                    loginReason
                })
            });
            window.history.replaceState(
                "add-loginReason",
                "Title",
                addQueryParam(window.location.href, "loginReason", loginReason)
            );
            await auth.signIn();
        })();
    }

    return (
        <div className={styles.button}>
            <Typography textAlign="center" fontWeight="normal" className={styles.buttonText}>
                {t(messages.myProjectsLoginTitle.id)}
            </Typography>
            <Button
                onClick={handleLogin}
                data-translationid={messages.myProjectsLogin.id}
                skin="primary"
                width="full-width"
                size={isSmall ? "mini" : "standard"}
            >
                {t(messages.myProjectsLogin.id)}
            </Button>
        </div>
    );
}

MyProjectsLoginButton.displayName = "MyProjectsLoginButton";
