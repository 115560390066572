import React from "react";
import {
    FontSizeSelectorStudio5,
    truncatedFontSize,
    useActiveCanvas,
    useFonts,
    getMinMaxFontSizeInterval,
    multipleFontSizeExist
} from "@designer-suite";
import {
    useDecorationTechnology,
    getTableSelectedFont,
    getTableSelectedFontSize,
    updateTableFontSize
} from "@utilities";
import { updateSelectedItems } from "../util";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { useTableSelectionFilter } from "../hooks/useTableSelectionFilter";

interface FontSizeProps {
    /** For overriding styles */
    className?: string;

    /** Whether to use an expandable or just the content */
    expandable?: boolean;

    //* * Is this the docked mobile tool */
    mobileDock?: boolean;
}

function getFontSizeValue(selectedFontSizes: number[], differentFontSizesPresent: boolean): number {
    const selectedFontSize = differentFontSizesPresent ? Math.min(...selectedFontSizes) : selectedFontSizes[0];
    const fontSize = truncatedFontSize(selectedFontSize);

    return fontSize;
}

// when sheetUpdates1C is rolled out, stop treating font size vaue as a string - just a number
export function TableFontSizeSelector({ className, expandable = true, mobileDock = false }: FontSizeProps) {
    const designer = useDesigner();
    const fonts = useFonts();
    const decorationTechnology = useDecorationTechnology();
    const canvas = useActiveCanvas();
    const selection = useSelection("model:change:data");
    const { tableItems, otherItems } = useTableSelectionFilter(selection);

    if (!tableItems.length || otherItems.length || !selection.length || !canvas) {
        return null;
    }

    const selectedFontSizes = getTableSelectedFontSize(tableItems).map((fontSize: string) => parseInt(fontSize, 10));
    const selectedFontFamily = getTableSelectedFont(tableItems);

    if (!selectedFontSizes || !selectedFontFamily || !designer) {
        return null;
    }

    const defaultFontConfig = designer.clients.font.fontSizeConfig[decorationTechnology];
    const differentFontSizesPresent = multipleFontSizeExist(selectedFontSizes);

    const { minLimit, maxLimit } = getMinMaxFontSizeInterval(
        designer,
        canvas,
        defaultFontConfig,
        fonts,
        selectedFontFamily
    );

    // Set fontSize dropdown selection label and value
    const fontSize = getFontSizeValue(selectedFontSizes, differentFontSizesPresent);

    const onChange = (value: number) => {
        updateSelectedItems(designer, tableItems, table => {
            updateTableFontSize(table, `${value}pt`);
        });
    };
    return (
        <FontSizeSelectorStudio5
            onChange={onChange}
            initialFontSize={fontSize}
            min={minLimit}
            max={maxLimit}
            expandable={expandable}
            className={className}
            mobileDock={mobileDock}
        />
    );
}
TableFontSizeSelector.displayName = "TableFontSizeSelector";
