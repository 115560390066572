import React, { forwardRef, Ref } from "react";
import StudioFiveDebugTools from "@five/components/Debug/StudioFiveDebugTools";
import { editFromCartMessages, useCartContext } from "@shared/features/Cart";
import { isDebugMode } from "@shared/utils/Debug";
import { FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { UndoRedoButtonBarDesktop } from "@designer-suite";
import { SaveStatusButton } from "@five/components/Save/SaveStatusButton";
import { useAppSelector } from "@shared/redux";
import { FlexSpacer } from "src/shared/xerox/features/FlexSpacer";
import { Logo } from "../Logo";
import { FileDropDown } from "../File";
import { ProjectName } from "../ProjectName";
import { ResizeButton } from "../Resize";
import { NextButton } from "../NextButton";
import { PreviewButton } from "../Preview";
import * as style from "./XeroxTopContainer.module.scss";

export const XeroxTopContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslationSSR();
    const { isItemInCart } = useCartContext();
    const teamsPlaceholders = useAppSelector(state => state.teamsPlaceholders);

    return (
        <FlexBox flexDirection="column" ref={ref} className={style.container}>
            {isDebugMode() && <StudioFiveDebugTools />}
            <FlexBox className={style.globalToolbar} flexDirection="row">
                <Logo />

                <FlexBox alignItems="center" className={style.contents}>
                    <FileDropDown />
                    <ResizeButton />
                    <UndoRedoButtonBarDesktop />

                    <FlexSpacer />

                    <ProjectName />
                    <SaveStatusButton />

                    <PreviewButton />
                    {/* Any other buttons would get added here */}
                    <NextButton
                        customLabel={
                            isItemInCart && !teamsPlaceholders?.length
                                ? t(editFromCartMessages.updateCartButton.id)
                                : undefined
                        }
                    />
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
});

XeroxTopContainer.displayName = "XeroxTopContainer";
