import { tryFetch } from "@shared/utils/Network";
import { getStudioUniqueSessionId } from "@shared/utils/Tracking";

const host = DTC_URL;
const entityCode = 44;

export interface CatalogSearchResult {
    key: string;
    tokenId: string;
    designTransformationTokenBase64: string;
    quality: number;
    editQuality: number;
    metadata: {
        matcherId: string;
        isSkeleton: boolean;
    };
}

/**
 * Fetch all available matching designs of current source product to new product
 * @param {string} locale Locale
 * @param {any} documentUrl CimDocURL
 * @param {any} targetSpecifications Response from target specifications
 * @returns An array of all matching designs
 */
export async function getMatchingDesigns(
    locale: string,
    documentUrl: any,
    targetSpecifications: any
): Promise<CatalogSearchResult[]> {
    const body = JSON.stringify({
        culture: locale,
        _links: {
            document: {
                href: documentUrl
            }
        },
        assets: {},
        sourceSpecification: {
            ducIds: targetSpecifications.data.targetSpecifications.ducIds,
            isEligibleForPhotoTransfer: false,
            isEligibleForLogoTransfer: false
        },
        targetSpecifications: [targetSpecifications.data.targetSpecifications]
    });

    return tryFetch({
        url: `${host}/api/v5/catalogEntries:search`,
        options: {
            method: "POST",
            body,
            headers: {
                "Content-Type": "application/json",
                "x-caller-id": REQUESTOR,
                "x-correlation-id": getStudioUniqueSessionId()
            }
        },
        moduleFunction: "dtcClient:getSearch",
        friendlyDescription: "get any available matches based on specifications",
        entityCode
    });
}
