import React, { memo, useEffect } from "react";

interface Props {
    onReady: () => void;
}

/**
 * This component handles loading and unloading the designer.js and designer.css files from the DOM
 * Wrapping the component in memo() makes it a pureComponent. react-helmet v5 has a bug that requires this to be pure.
 * https://github.com/nfl/react-helmet/issues/373
 */
// eslint-disable-next-line react/display-name
export default memo(({ onReady }: Props) => {
    function cycleUntilReady() {
        if (!window.designer) {
            setTimeout(cycleUntilReady, 100);
        } else {
            onReady();
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            cycleUntilReady();
        }
        return () => {
            if (typeof window !== "undefined") {
                delete window.designer;
            }
        }; // on unmount, set designer to undefined
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
});
