import React, { useMemo } from "react";
import { Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR, Trans } from "@vp/i18n-helper";
import { Status } from "@design-stack-vista/image-library-react";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { useIsEmbroidery } from "@shared/features/Embroidery";
import { ElementsPanelContentTypes, useElementsPanel } from "./ElementsPanelProvider";
import { ElementsRow } from "./ElementsRow";
import { ElementUploadItem } from "./ElementsPanelUtils";
import { draggableShapes } from "../../../components/Panels/Redesign/Icons/IconSelectionSet";
import * as styles from "./ElementsPanelContentMain.module.scss";

const getRowItems = (items: Partial<ElementUploadItem>[]) => {
    return items.slice(0, 10);
};

const messages = defineMessages({
    noResults: {
        id: "studio.features.elements.noResults",
        defaultMessage: `Sorry, we couldn't find any matches for <bold>"[[term]]"</bold>.`,
        description: {
            note: "Message shown when no elements are found that match search term"
        }
    },
    noResultsDescription: {
        id: "studio.features.elements.noResultsDescription",
        defaultMessage: `Please check your spelling or try searching with a different word.`,
        description: {
            note: "Description shown when no elements are found that match search term"
        }
    },
    errorAll: {
        id: "studio.features.elements.errorAll",
        defaultMessage: `We couldn't load the elements - please try again.`,
        description: {
            note: "Message shown when there is an error loading all elements"
        }
    }
});

export const ElementsPanelContentMain = () => {
    const {
        imageGalleryItems,
        clipartGalleryItems,
        icons,
        imageStatus,
        iconStatus,
        clipartStatus,
        recentStatus,
        currentElementsPanelSearchTerm,
        recentlyUsedItems
    } = useElementsPanel();
    const { isXerox } = useXerox();
    const isEmbroidery = useIsEmbroidery();

    const { t } = useTranslationSSR();

    const showNoSearchResults =
        currentElementsPanelSearchTerm &&
        imageGalleryItems.length === 0 &&
        clipartGalleryItems.length === 0 &&
        icons.length === 0 &&
        imageStatus === Status.Idle &&
        iconStatus === Status.Idle &&
        clipartStatus === Status.Idle;

    const showError = imageStatus === Status.Error && iconStatus === Status.Error && clipartStatus === Status.Error;

    const shapes = useMemo(() => {
        return draggableShapes?.map(element => ({
            component: (
                <div key={element.displayName} className={styles.shapeRowIcon}>
                    {element({})}
                </div>
            )
        }));
    }, []);

    // show the no results message if there are no results for the search term for any of the element types
    if (showNoSearchResults) {
        return (
            <>
                <Typography textAlign="center" fontSize="1">
                    <Trans
                        t={t}
                        i18nKey={messages.noResults.id}
                        components={{ bold: <strong /> }}
                        values={{ term: currentElementsPanelSearchTerm }}
                    />
                </Typography>
                <Typography mt={1} textAlign="center" fontSize="-1" className={styles.noResultsDescription}>
                    {t(messages.noResultsDescription.id)}
                </Typography>
            </>
        );
    }

    // show error message if there are errors for all element types
    if (showError) {
        return (
            <Typography textAlign="center" fontSize="1" paddingX={6} paddingBottom={2}>
                {t(messages.errorAll.id)}
            </Typography>
        );
    }

    return (
        <>
            {isXerox && !currentElementsPanelSearchTerm && recentlyUsedItems.length > 0 && (
                <ElementsRow
                    contentType={ElementsPanelContentTypes.Recent}
                    items={getRowItems(recentlyUsedItems)}
                    status={recentStatus}
                />
            )}
            {/* We're going to use the same status for shapes as icons */}
            {!currentElementsPanelSearchTerm && !isEmbroidery && (
                <ElementsRow
                    contentType={ElementsPanelContentTypes.Shapes}
                    items={getRowItems(shapes)}
                    status={iconStatus}
                />
            )}
            <ElementsRow contentType={ElementsPanelContentTypes.Icons} items={getRowItems(icons)} status={iconStatus} />
            <ElementsRow
                contentType={ElementsPanelContentTypes.Images}
                items={getRowItems(imageGalleryItems)}
                status={imageStatus}
            />
            <ElementsRow
                contentType={ElementsPanelContentTypes.Clipart}
                items={clipartGalleryItems.slice(1, 11)}
                status={clipartStatus}
            />
        </>
    );
};

ElementsPanelContentMain.displayName = "ElementsPanelContentMain";
