import React from "react";
import { IconsSearchProvider } from "@shared/features/IconAssetLibrary/IconsSearchProvider";
import IconsPanel from "../../../components/Panels/Redesign/Icons/IconsPanel";
import * as styles from "./ElementsPanelContentShapes.module.scss";

export const ElementsPanelContentShapes = () => {
    return (
        <IconsSearchProvider>
            <div className="studio-shapes-icons-panel-content">
                <IconsPanel className={styles.shapesView} />
            </div>
        </IconsSearchProvider>
    );
};

ElementsPanelContentShapes.displayName = "ElementsPanelContentShapes";
