import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStockImages } from "@design-stack-vista/image-library-react";
import { Typography, Button, FlexBox, Link } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useClickOutside, StickyActionBar, StandardSheetHeader } from "@shared/features/StudioChrome";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { useAppSelector, useAppDispatch, setShowImageSearchFiltersPanel } from "@shared/redux";
import classNames from "classnames";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";
import { ImageSearchPeopleFilter } from "./ImageSearchPeopleFilter";
import { ImageSearchOrientationFilter } from "./ImageSearchOrientationFilter";
import { ImagesSearchColorFilter } from "./ImageSearchColorFilter";
import "./imageFilterPanel.scss";

type Props = {
    buttonRef: React.RefObject<HTMLButtonElement>;
    panelClassName?: string;
    arrowClassName?: string;
    performSearchForFilter: (filterOptions: string) => void;
};

export const IMAGE_SEARCH_FILTER_PORTAL_ID = "floating-image-filter-portal";

export const ImageSearchFiltersPanel = (props: Props) => {
    const { buttonRef, panelClassName, arrowClassName, performSearchForFilter } = props;
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const contentRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const { performInitialSearch, currentSearchTerm, clearSearch } = useStockImages();
    const { isSmall } = useStudioLayout();
    const panelOpen = useAppSelector(state => state.showImageSearchFiltersPanel);

    const isOpen = useAppSelector(state => state.showImageSearchFiltersPanel);
    useClickOutside(
        [contentRef, buttonRef],
        async () => {
            if (panelOpen) {
                trackEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_CLOSE_FILTERS_OUTSIDE
                });
                dispatch(setShowImageSearchFiltersPanel(false));
            }
        },
        [panelOpen]
    );

    const onClick = () => {
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_CLOSE_FILTERS_APPLY
        });
        // just closes filters panel (since filter is supposed to be already applied)
        dispatch(setShowImageSearchFiltersPanel(false));
    };

    const onClickClearFilters = () => {
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_CLEAR_ALL_FILTERS
        });
        clearSearch();
        performInitialSearch(currentSearchTerm);
    };

    const handleElement = document.getElementById(IMAGE_SEARCH_FILTER_PORTAL_ID);

    if (!isOpen || !handleElement) {
        return null;
    }

    return (
        <>
            {ReactDOM.createPortal(
                <>
                    <div className={classNames("arrow-left", arrowClassName)} />
                    <div className={classNames("image-filter-panel", panelClassName)} ref={contentRef}>
                        {isSmall && (
                            <StandardSheetHeader
                                sheetTitle={t(imageLibraryPanelMessages.filterSheetTitle.id)}
                                onBackClick={() => {
                                    dispatch(setShowImageSearchFiltersPanel(false));
                                }}
                            />
                        )}
                        <div className="image-filter-panel-content">
                            <FlexBox justifyContent="space-between" flexDirection="row" pb={3}>
                                <Typography fontSize="1" fontWeight="bold">
                                    {t(imageLibraryPanelMessages.colorHeading.id)}
                                </Typography>
                                <Link component="button" onClick={onClickClearFilters}>
                                    <Typography fontSize="1">
                                        {t(imageLibraryPanelMessages.resultsClearAllFilters.id)}
                                    </Typography>
                                </Link>
                            </FlexBox>
                            <ImagesSearchColorFilter performSearchForFilter={performSearchForFilter} />
                            <ImageSearchOrientationFilter performSearchForFilter={performSearchForFilter} />
                            <ImageSearchPeopleFilter performSearchForFilter={performSearchForFilter} />
                        </div>
                        <StickyActionBar className="image-filter-panel-sticky-bar">
                            <Button onClick={onClick} skin="primary" width="full-width" size="mini">
                                {t(imageLibraryPanelMessages.applyFilter.id)}
                            </Button>
                        </StickyActionBar>
                    </div>
                </>,
                handleElement
            )}
        </>
    );
};

ImageSearchFiltersPanel.displayName = "ImageSearchFiltersPanel";
