import React from "react";

export function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M9.2 14.4C12.0719 14.4 14.4 12.0719 14.4 9.2C14.4 6.32812 12.0719 4 9.2 4C6.32812 4 4 6.32812 4 9.2C4 12.0719 6.32812 14.4 9.2 14.4Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.0016 16.0001L13.1016 13.1001"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
SearchIcon.displayName = "SearchIcon";
