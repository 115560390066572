import React, { forwardRef, Ref } from "react";
import { Typography, Button, Link } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import classNames from "classnames";
import { messages } from "./messages";
import { type Project } from "./types";
import * as styles from "./MyProjectsEditButton.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    project: Project;
    studioEditable: boolean;
    handleEditClick: () => {};
}

export const MyProjectsEditButton = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
    const { project, studioEditable, handleEditClick, children, className, ...rest } = props;
    const { t } = useTranslationSSR();

    const nonStudioEditableLink =
        project.design && project.design.editUrl ? (
            // @ts-expect-error: TODO-brian
            <Link marginRight={2} target="_blank" href={project.design.editUrl} skin="unstyled" {...rest}>
                <Typography className={styles.buttonText} fontSize="-1" fontWeight="bold">
                    {project.workName}
                </Typography>
            </Link>
        ) : null;

    return studioEditable ? (
        <Button
            skin="unstyled"
            onClick={handleEditClick}
            title={t(messages.myProjectsEditProject.id)}
            ref={ref}
            className={classNames(styles.button, className)}
            {...rest}
        >
            <Typography className={styles.buttonText} fontSize="-1" fontWeight="bold">
                {project.workName}
            </Typography>
        </Button>
    ) : (
        nonStudioEditableLink
    );
});

MyProjectsEditButton.displayName = "MyProjectsEditButton";
