import React from "react";
import loadable from "@loadable/component";
import { FlexBox, PipeSeparator, Hidden } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import classnames from "classnames";
import { loadableRetry } from "@shared/utils/Network";
import { useStudioLayout, DesktopExperience } from "@shared/features/ResponsiveDesign";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { isCareAgent } from "@shared/utils/Care";
import Skeleton from "react-loading-skeleton";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { useAppSelector } from "@shared/redux";
import { useCartContext, editFromCartMessages } from "@shared/features/Cart";
import { MyProjectsButton } from "@shared/features/MyProjects";
import { useSmartValidations } from "@shared/features/SmartValidations";
import {
    useStudioFlexibility,
    useActiveFlexibilityOptions,
    FlexibilityDesignAttributes,
    ChangeSizeButton,
    isShowChangeSizeBtnEnabled
} from "@shared/features/Flexibility";
import { useIsCompatiblePostCard } from "@shared/features/MailingServices";
import { ChangeTemplateButton, useShowChangeTemplate } from "@shared/features/ChangeTemplate";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import { PreviewButtonWithSkeleton } from "@shared/features/Previews";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { FileButton } from "@shared/features/GlobalHeader";
import { SpecsAndTemplatesButton, useProductHasSpecsAndTemplates } from "@shared/features/SpecsAndTemplates";
import { NextStepButtonStudio5 } from "../../NextStepButton";
import { ProjectNameButton } from "./ProjectNameButton";
import { SaveStatusButton } from "../../Save/SaveStatusButton";
import { MiniLogo } from "./MiniLogo";
import { MailingButton } from "../../MailingServices/MailingButton";
import { SmartValidationsButton } from "../../Validation/SmartValidationsButton";
import { saveStudio5Design } from "../../../clients/saveClient";
import { useIdeaTabEnabled } from "../../../hooks/useIdeaTabEnabled";

import "./globalToolbar.scss";

const CopyDesignButton = loadable(() => loadableRetry(() => import("../../CopyDesign/CopyDesignButton")), {
    resolveComponent: components => components.CopyDesignButton
});
const DesignHelp = loadable(() => loadableRetry(() => import("../../DesignHelp")));

export const GlobalToolbar = () => {
    const { t } = useTranslationSSR();
    const { bookendsHeader }: any = usePageContext();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const teamsPlaceholders = useAppSelector(state => state.teamsPlaceholders);
    const hiddenOnLoadClass = easelLoaded ? "fade-in" : "transparent";
    const { isSmall, isMedium } = useStudioLayout();
    const allowCopyDesign = useIsFlagEnabled("advancedTools") || isCareAgent();
    const changeTemplate = useShowChangeTemplate();
    const { isDesignAttributeActive } = useActiveFlexibilityOptions();
    const isChangeSizeActive = isDesignAttributeActive(FlexibilityDesignAttributes.Size);
    const { isXerox } = useXerox();
    const showDesignTemplatesPanel = useIdeaTabEnabled();
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isAnyFlexibilityFeatureEnabled = isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled;
    const showChangeSizeBtn = !isAnyFlexibilityFeatureEnabled && isChangeSizeActive && isShowChangeSizeBtnEnabled();
    const showChangeTemplateBtn = !isXerox && !isMileStone1Enabled && changeTemplate && !showDesignTemplatesPanel;

    const { isItemInCart } = useCartContext();
    const isCompatiblePostCard = useIsCompatiblePostCard();
    const { isSmartValidationsEnabled } = useSmartValidations();
    const hasSpecsAndTemplate = useProductHasSpecsAndTemplates();

    return (
        <div
            data-testid="globalToolbar"
            className={classnames("global-toolbar-V2", "global-toolbar-V2-hide-header-links")}
        >
            {/* If the slim header is not in the global toolbar, it will not be shown */}
            {/* TODO: Add the abTestData prop when when top level testing is unlocked in Bookends. */}
            <div className="global-toolbar-V2-left-aligned-tools">
                <FileButton className="global-toolbar-V2-file-button" />
                {isSmall && easelLoaded && <DesignHelp />}
            </div>
            <MiniLogo logo={bookendsHeader.logo} url={bookendsHeader.url} className={"global-toolbar-V2__mini-logo"} />
            <DesktopExperience>
                <>
                    <div className="global-toolbar-v2-title-container">
                        <FlexBox alignItems="center">
                            {!isSmall && (
                                <StudioIsInteractiveSwitch>
                                    <>
                                        <Hidden sm>
                                            <MyProjectsButton hideIcon saveDesign={saveStudio5Design} />
                                        </Hidden>
                                        <ProjectNameButton />
                                    </>
                                    <Skeleton width="120px" height="20px" style={{ borderRadius: "25px" }} />
                                </StudioIsInteractiveSwitch>
                            )}
                            {!isMedium && (
                                <>
                                    <div className="global-toolbar-vertical-divider"></div>
                                    <StudioIsInteractiveSwitch>
                                        <SaveStatusButton />
                                        <DesktopExperience>
                                            <Skeleton width={90} height={20} style={{ borderRadius: "25px" }} />
                                        </DesktopExperience>
                                    </StudioIsInteractiveSwitch>
                                </>
                            )}
                        </FlexBox>
                    </div>

                    {/* adding a container to avoid design clash with current global toolbar */}
                    <div className={classnames(`global-toolbar-V2-tools ${hiddenOnLoadClass} global-toolbar-tools`)}>
                        <div className="global-toolbar-V2-tools-button">
                            <MailingButton />
                        </div>
                        <div className="global-toolbar-V2-tools-button">
                            {showChangeSizeBtn && <ChangeSizeButton />}
                        </div>
                        <div className="global-toolbar-V2-tools-button">
                            {showChangeTemplateBtn && <ChangeTemplateButton />}
                        </div>
                        <div className="global-toolbar-V2-tools-button">
                            {showChangeTemplateBtn ||
                                ((showChangeSizeBtn || isCompatiblePostCard) && (
                                    <PipeSeparator className="pipe-divider-for-button" />
                                ))}
                        </div>
                        <div className="global-toolbar-V2-tools-button">
                            <PreviewButtonWithSkeleton className="global-toolbar-preview-button" />
                        </div>
                        <div className="global-toolbar-V2-tools-button">
                            {hasSpecsAndTemplate && <SpecsAndTemplatesButton />}
                        </div>
                        {isSmartValidationsEnabled && (
                            <div className="global-toolbar-V2-tools-button">
                                <SmartValidationsButton />
                            </div>
                        )}
                        {allowCopyDesign && (
                            <div className="global-toolbar-V2-tools-button">
                                <CopyDesignButton />
                            </div>
                        )}
                    </div>
                </>
            </DesktopExperience>
            <div
                className={
                    isSmall
                        ? classnames(`swan-mr-4 mobile-next-step-button ${hiddenOnLoadClass}`, {
                              "mobile-next-step-button--update-cart": isItemInCart
                          })
                        : `global-toolbar-V2-tools-button swan-pr-6 ${hiddenOnLoadClass}`
                }
            >
                <NextStepButtonStudio5
                    customLabel={
                        isItemInCart && !teamsPlaceholders?.length
                            ? t(editFromCartMessages.updateCartButton.id)
                            : undefined
                    }
                />
            </div>
        </div>
    );
};
GlobalToolbar.displayName = "GlobalToolbarV2";
