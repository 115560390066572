import { useEffect } from "react";
import { useDesigner } from "@designer-suite";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useAppSelector, RootState, useAppDispatch, addTeamsPlaceholder, removeTeamsPlaceholder } from "@shared/redux";

const namePlaceholderValidations = [
    {
        message: "Maximum 20 characters",
        type: "regex",
        value: "^.{0,20}$"
    }
];

const numberPlaceholderValidations = [
    {
        message: "Maximum 20 numeric characters",
        type: "regex",
        value: "^[0-9]{0,20}$"
    }
];

function isTeamsPlaceholderData(data: AttributeData): data is TeamsPlaceholderData {
    return (data as TeamsPlaceholderData).placeholderKey !== undefined;
}

const selectNameItemReferenceIds = (state: RootState): string[] => {
    return state.teamsPlaceholders
        .filter(placeholder => placeholder.itemReferenceType === ItemReferenceTypes.TEAMS_NAME)
        .map(placeholder => placeholder.key);
};

export function useTeamsNameItemReferences() {
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const nameItemReferenceIds = useAppSelector(selectNameItemReferenceIds);

    useEffect(() => {
        if (!designer) return;

        const unsubscribeAdd = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_ADDED,
            (eventData: EventData) => {
                eventData.items.forEach(item => {
                    const itemModel = item._itemViewModel.model;
                    const itemAttributes = itemModel.attributes;
                    if (
                        item.itemType !== ItemTypes.ITEM_REFERENCE ||
                        itemAttributes.type !== ItemReferenceTypes.TEAMS_NAME
                    ) {
                        return;
                    }
                    if (itemAttributes.data && isTeamsPlaceholderData(itemAttributes.data)) {
                        const { placeholderKey, placeholderType } = itemAttributes.data;

                        dispatch(
                            addTeamsPlaceholder({
                                key: itemModel.get("id"),
                                itemReferenceType: itemModel.get("type"),
                                name: placeholderKey,
                                type: placeholderType!,
                                validations:
                                    placeholderType === "Number"
                                        ? numberPlaceholderValidations
                                        : namePlaceholderValidations
                            })
                        );
                    }
                });
            }
        );

        const unsubscribeDelete = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_DELETED,
            (eventData: EventData) => {
                eventData.items.forEach(item => {
                    const itemModel = item._itemViewModel.model;
                    if (itemModel.get("type") !== ItemReferenceTypes.TEAMS_NAME) {
                        return;
                    }

                    dispatch(removeTeamsPlaceholder(itemModel.get("id")));
                });
            }
        );

        // TODO: Handle item changes such as changing position, font, etc.

        /* eslint-disable consistent-return */
        return () => {
            unsubscribeAdd();
            unsubscribeDelete();
        };
    }, [designer, dispatch]);

    return nameItemReferenceIds;
}
