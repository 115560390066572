import React, { useState, useCallback } from "react";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { FlexBox, TabContent, TabHeader, Tabs, TabsContents, TabsHeaders } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { SheetHorizontalScroller } from "@shared/features/StudioChrome";
import { DesktopExperience, MobileExperience } from "@shared/features/ResponsiveDesign";
import { CMYK, HSV, RGB } from "@design-stack-ct/utility-core";
import { colorToString, StateColorObject } from "../utilities";
import { ColorSpace, type SelectableColor } from "../types";
import { CustomHsvColorPicker } from "../CustomHsvColorPicker";
import { HexColorInput } from "../HexColorInput";
import { NoColorSwatch } from "../ColorSwatch/NoColorSwatch";
import { ColorPalette } from "../ColorSwatch/ColorPalette";
import { CustomCmykColorPicker } from "../CustomCmykColorPicker";
import * as styles from "./ColorPickerPanelContent.module.scss";

const messages = defineMessages({
    custom: {
        id: "studio.components.colorpicker.custom",
        defaultMessage: "Custom",
        description: {
            note: "Label for tab to select a custom color"
        }
    },
    swatches: {
        id: "studio.components.colorpicker.swatches",
        defaultMessage: "Swatches",
        description: {
            note: "Label for tab to view and select a color swatch"
        }
    }
});

interface EyeDropperConfig {
    /** Optional function to call when the button to open eye dropper is clicked */
    onEyeDropClick?: () => void;
}
interface Props {
    /**
     * Array of recommended colors from the design. See the ColorPalette component
     */
    recentColors?: SelectableColor[];
    /**
     * Array of standard colors. See ColorPalette component
     */
    paletteColors: SelectableColor[];
    /**
     * Show the custom color picker (wheel/sliders).
     * @default true
     */
    allowCustom?: boolean;
    /**
     * Show the 'No color' option.
     * @default false
     */
    allowTransparency?: boolean;
    /**
     * If set, eye dropper will be shown with these configs
     */
    eyeDropperConfig?: EyeDropperConfig;
    className?: string;
    onColorChange: (newColor: string | CMYK | RGB | HSV, colorSpace: ColorSpace) => void;
    currentColorValues: StateColorObject;
    renderEyeDropper: () => JSX.Element | null;
    trackComponentUsage?: (component: string) => void;
}

enum TabTypes {
    Swatches = "Swatches",
    Custom = "Custom",
    CMYK = "CMYK"
}

export function ColorPickerPanelContent({
    recentColors,
    paletteColors,
    allowCustom = true,
    allowTransparency = false,
    eyeDropperConfig,
    className,
    onColorChange,
    currentColorValues,
    renderEyeDropper,
    trackComponentUsage
}: Props) {
    const { t } = useTranslationSSR();
    const [selectedTabId, setSelectedTabId] = useState<string | null>("Swatches");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSliderChange = useCallback(debounce(onColorChange, 200), [onColorChange]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedCMYKSliderChange = useCallback(debounce(onColorChange, 200), [onColorChange]);

    if (allowCustom) {
        return (
            <>
                <DesktopExperience>
                    <div className={classNames(styles.colorPickerPanel, className)}>
                        <CustomHsvColorPicker
                            onChange={debouncedSliderChange}
                            value={currentColorValues.hsv}
                            trackUsage={trackComponentUsage}
                        />
                        <FlexBox
                            alignItems="center"
                            justifyContent="space-between"
                            className={styles.colorPickerMidRibbon}
                        >
                            <HexColorInput
                                onChange={onColorChange}
                                value={currentColorValues.hex}
                                trackUsage={trackComponentUsage}
                            />
                            {eyeDropperConfig ? (
                                <span
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={eyeDropperConfig.onEyeDropClick}
                                    onClick={eyeDropperConfig.onEyeDropClick}
                                >
                                    {renderEyeDropper()}
                                </span>
                            ) : (
                                <>{renderEyeDropper()}</>
                            )}
                            {allowTransparency && (
                                <NoColorSwatch
                                    noColorSelected={currentColorValues.noColor}
                                    onChange={onColorChange}
                                    trackUsage={trackComponentUsage}
                                />
                            )}
                        </FlexBox>
                        <Tabs
                            defaultSelectedTabId={selectedTabId}
                            onRequestTabChange={requestedTabId => setSelectedTabId(requestedTabId)}
                        >
                            <TabsHeaders>
                                <TabHeader tabId={TabTypes.Swatches}>{`${t(messages.swatches.id)}`}</TabHeader>
                                <TabHeader tabId={TabTypes.CMYK}>CMYK</TabHeader>
                            </TabsHeaders>
                            <TabsContents>
                                <TabContent tabId={TabTypes.Swatches}>
                                    <ColorPalette
                                        selectedColor={colorToString(currentColorValues.rgb)}
                                        paletteColors={paletteColors.slice(0, 28)}
                                        recentColors={recentColors?.slice(0, 7)}
                                        onSelectColor={onColorChange}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                                <TabContent tabId={TabTypes.CMYK}>
                                    <CustomCmykColorPicker
                                        onChange={debouncedCMYKSliderChange}
                                        value={currentColorValues.cmyk}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                            </TabsContents>
                        </Tabs>
                    </div>
                </DesktopExperience>
                <MobileExperience>
                    <div className={styles.colorPickerPanel}>
                        <FlexBox
                            alignItems="center"
                            justifyContent="space-between"
                            className={styles.colorPickerMidRibbon}
                            marginY={0}
                        >
                            <HexColorInput
                                onChange={onColorChange}
                                value={currentColorValues.hex}
                                trackUsage={trackComponentUsage}
                            />
                            {allowTransparency && (
                                <NoColorSwatch
                                    noColorSelected={currentColorValues.noColor}
                                    onChange={onColorChange}
                                    trackUsage={trackComponentUsage}
                                />
                            )}
                        </FlexBox>
                        <Tabs
                            defaultSelectedTabId={selectedTabId}
                            onRequestTabChange={requestedTabId => setSelectedTabId(requestedTabId)}
                            mt={0}
                            className={styles.smallScreenTabs}
                        >
                            <SheetHorizontalScroller>
                                <TabsHeaders>
                                    <TabHeader tabId={TabTypes.Swatches}>{`${t(messages.swatches.id)}`}</TabHeader>
                                    <TabHeader tabId={TabTypes.Custom}>{`${t(messages.custom.id)}`}</TabHeader>
                                    <TabHeader tabId={TabTypes.CMYK}>CMYK</TabHeader>
                                </TabsHeaders>
                            </SheetHorizontalScroller>
                            <TabsContents>
                                <TabContent tabId={TabTypes.Swatches}>
                                    <ColorPalette
                                        selectedColor={colorToString(currentColorValues.rgb)}
                                        paletteColors={paletteColors.slice(0, 28)}
                                        recentColors={recentColors?.slice(0, 7)}
                                        onSelectColor={onColorChange}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                                <TabContent tabId={TabTypes.Custom}>
                                    <CustomHsvColorPicker
                                        onChange={debouncedSliderChange}
                                        value={currentColorValues.hsv}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                                <TabContent tabId={TabTypes.CMYK}>
                                    <CustomCmykColorPicker
                                        onChange={debouncedCMYKSliderChange}
                                        value={currentColorValues.cmyk}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                            </TabsContents>
                        </Tabs>
                    </div>
                </MobileExperience>
            </>
        );
    }

    return (
        <ColorPalette
            selectedColor={colorToString(currentColorValues.other ?? currentColorValues.rgb)}
            paletteColors={paletteColors}
            recentColors={recentColors}
            onSelectColor={onColorChange}
            trackUsage={trackComponentUsage}
            className={styles.colorPickerSingleColor}
        />
    );
}

ColorPickerPanelContent.displayName = "ColorPickerPanelContent";
