import React, { useState, useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import { useDesigner } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { EASEL_EVENTS, useDecorationTechnology, useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { DesignErrorModal } from "@shared/features/Modals";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";

const messages = defineMessages({
    header: {
        id: "easel.ui.designErrorModal.embroidery.header",
        defaultMessage: "Tips for uploading an image for embroidery",
        description: {
            note: "Title for a modal about image guidelines for embroidered products"
        }
    },
    letteringTitle: {
        id: "easel.ui.designErrorModal.body.letteringTitle",
        defaultMessage: "Large text is best",
        description: {
            note: "Tip that larger text works best on embroidery"
        }
    },
    letteringDescription: {
        id: "easel.ui.designErrorModal.body.letteringDescription",
        defaultMessage: 'Fonts should be at least 1/4" in height',
        description: {
            note: "Fonts should be at least a quarter inch tall"
        }
    },
    imageTitle: {
        id: "easel.ui.designErrorModal.body.imageTitle",
        defaultMessage: "Photographs don't work well",
        description: {
            note: "Suggestion to not use photos"
        }
    },
    imageDescription: {
        id: "easel.ui.designErrorModal.body.imageDescription",
        defaultMessage: "Uploads with photos or gradients won't come out great",
        description: {
            note: "User's should not upload photos or images with gradients"
        }
    },
    logoTitle: {
        id: "easel.ui.designErrorModal.body.logoTitle",
        defaultMessage: "Logos work great!",
        description: {
            note: "Logos work well on embroidery"
        }
    },
    logoDescription: {
        id: "easel.ui.designErrorModal.body.logoDescription",
        defaultMessage: "Large and clear graphics will render to embroidery the best!",
        description: {
            note: "Description of graphics that will work on embroidery"
        }
    },
    cta: {
        id: "easel.ui.embroideryDesignErrorModal.cta",
        defaultMessage: "Okay"
    }
});

/**
 * This modal warns the user about quality uploads for embroidery.  It will only be shown once.
 */
export function EmbroideryUploadWarningModal() {
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const [hasBeenShown, setHasBeenShown] = useState(false);
    const [show, setShow] = useState(false);
    const [onClose, setOnClose] = useState(() => () => {});
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const decorationTechnology = useDecorationTechnology();
    const { isSmall } = useStudioLayout();
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();

    useEffect(() => {
        if (
            decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY &&
            !hasBeenShown &&
            currentActiveDialog === DialogType.Images
        ) {
            setShow(true);
            setOnClose(() => () => {
                setCurrentActiveDialog(DialogType.Images);
            });
        }
    }, [currentActiveDialog, setCurrentActiveDialog, hasBeenShown, decorationTechnology]);

    useEffect(() => {
        if (!designer) {
            return;
        }

        const respondToEvent = (onCloseFromEvent?: () => {}) => {
            if (!hasBeenShown) {
                setShow(true);
                onCloseFromEvent && setOnClose(onCloseFromEvent);
            } else {
                onCloseFromEvent && onCloseFromEvent();
            }
        };

        designer.eventBus.on(EASEL_EVENTS.EMBROIDERY_UPLOAD_OPEN, respondToEvent);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(EASEL_EVENTS.EMBROIDERY_UPLOAD_OPEN, respondToEvent);
        };
    }, [designer, hasBeenShown]);

    const textSize = isSmall ? 6 : 5;

    const body = (
        // TODO: Use Swan `List` component here instead
        <ul className="swan-list swan-list-skin-checkmark">
            <li className="my-s">
                <Typography fontWeight="bold">{t(messages.letteringTitle.id)}</Typography>
                <Typography textSize={textSize}>{t(messages.letteringDescription.id)}</Typography>
            </li>
            <li className="my-s">
                <Typography fontWeight="bold">{t(messages.imageTitle.id)}</Typography>
                <Typography textSize={textSize}>{t(messages.imageDescription.id)}</Typography>
            </li>
            <li className="my-s">
                <Typography fontWeight="bold">{t(messages.logoTitle.id)}</Typography>
                <Typography textSize={textSize}>{t(messages.logoDescription.id)}</Typography>
            </li>
        </ul>
    );

    return (
        <DesignErrorModal
            onClose={() => {
                setHasBeenShown(true);
                setShow(false);
                onClose && onClose();
            }}
            show={show}
            headerText={t(messages.header.id)}
            buttonText={t(messages.cta.id)}
            bodyText={body}
            hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
        />
    );
}

EmbroideryUploadWarningModal.displayName = "EmbroideryUploadWarningModal";
