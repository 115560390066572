import React, { forwardRef, Ref } from "react";
import { Typography, Button } from "@vp/swan";
import classnames from "classnames";

import * as styles from "./CanvasFlexibilityButton.module.scss";

interface CanvasFlexibilityButtonProps {
    title: string;
    icon?: JSX.Element;
    style?: any;
    ref?: Ref<HTMLButtonElement>;
    onClick: () => void;
    readOnly?: boolean;
}

export const CanvasFlexibilityButton = forwardRef(
    ({ title, icon, style, onClick, readOnly }: CanvasFlexibilityButtonProps, ref: Ref<HTMLButtonElement>) => {
        return (
            <Button
                ref={ref}
                size="mini"
                width="standard"
                buttonShape="standard"
                className={classnames(styles.canvasFlexibilityButton, { [styles.readOnly]: readOnly })}
                onClick={onClick}
                style={{ ...style }}
            >
                {icon && <div className={styles.buttonIcon}>{icon}</div>}
                <Typography>{title}</Typography>
            </Button>
        );
    }
);

CanvasFlexibilityButton.displayName = "CanvasFlexibilityButton";
