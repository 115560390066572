import React from "react";

import { useDesigner } from "@designer-suite";
import { getTextAndWordArtFieldNumber } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { ItemRow } from "@presentational";
import { WordArtField } from "./WordArtField";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Do we want to allow the user to be able to delete text items from the line
     * @default true
     * */
    showDeleteButtons?: boolean;
    /** What item this WordArtFieldRow is hooked up to */
    item: WordArtItem;
    /** React component to render at right of the textfield */
    icon?: React.ReactNode;
}

/**
 * A connected WordArtFieldRow. This uses the WordArtField and hooks up the delete button and premium finish icon
 */
export function WordArtFieldRow({ item, icon, showDeleteButtons = true }: Props) {
    const designer = useDesigner();
    const fieldNumber = getTextAndWordArtFieldNumber(item);
    // When the delete icon is clicked, delete this item
    const onDelete = showDeleteButtons
        ? () => {
              if (!designer) {
                  return;
              }
              designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_LEFT_PANEL_WORD_ART_DELETE);
              designer.api.design.updateItem(item.id, mutableItem => {
                  mutableItem.remove();
              });
          }
        : undefined;

    // When the delete icon is focused through tabbing, deselect the text field on canvas
    const onTabDelete = showDeleteButtons
        ? () => {
              if (!designer) {
                  return;
              }
              designer.selectionManager.select([]);
          }
        : undefined;

    return (
        <ItemRow
            editor={<WordArtField item={item} icon={icon} selectOnFocus={true} leftPanel fieldNumber={fieldNumber} />}
            onClickDelete={onDelete}
            onTabDelete={onTabDelete}
            fieldNumber={fieldNumber}
        />
    );
}
WordArtFieldRow.displayName = "WordArtFieldRow";
