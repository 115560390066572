import { CimDoc, Panel } from "@design-stack-ct/cdif-types";
import { V2 } from "@shared/utils/DSS";
import { getDocument, getTransientDocumentUrl } from "@shared/utils/DocumentStorage";
import { getTransferedDocument } from "@shared/utils/ProductTransformation";
import { DSS, DTR } from "@vp/types-ddif";
import { getDesignDocumentFromDesigner } from "@utilities";
import { getResizedDocument } from "@shared/utils/ProductTransformation/productTransformationUtilites";
import { AITemplate } from "./AITemplatesProvider";

interface PlaceholderConfig {
    [key: string]: {
        label: string;
        purpose: string;
    };
}

export const updateAITemplateDocForProduct = async (
    updatedTemplate: AITemplate,
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string>,
    locale: string,
    auth: any,
    canvasName?: string
) => {
    const aiTemplateDoc = updatedTemplate.metadata.document
        ? updatedTemplate.metadata.document
        : await getDocument(updatedTemplate.metadata.cimDocUrl, auth);
    const aiTemplateDocCopy = aiTemplateDoc;
    const { panels } = aiTemplateDocCopy.document;
    const panelWithTemplate = panels[0]; // design will always be on the first panel

    const existingDesignDocument = await getDesignDocumentFromDesigner();
    const existingPanels = existingDesignDocument.document.panels;

    const updatedTemplatePanels = [] as Panel[];
    existingPanels.forEach(existingPanel => {
        if (canvasName?.toLowerCase() === existingPanel.name.toLowerCase()) {
            updatedTemplatePanels.push({
                ...panelWithTemplate,
                id: existingPanel.id,
                colorMode: existingPanel.colorMode,
                decorationTechnology: existingPanel.decorationTechnology,
                height: panelWithTemplate.height,
                width: panelWithTemplate.width,
                name: existingPanel.name
            });
        } else {
            updatedTemplatePanels.push({
                id: existingPanel.id,
                colorMode: existingPanel.colorMode,
                decorationTechnology: existingPanel.decorationTechnology,
                height: existingPanel.height,
                width: existingPanel.width,
                name: existingPanel.name
            });
        }
    });

    const updatedAITemplateDoc = { ...aiTemplateDocCopy, document: { panels: updatedTemplatePanels } };

    const resizedAITemplateDocument = await getResizedDocument({
        designDocument: updatedAITemplateDoc,
        productKey,
        productVersion,
        selectedOptions,
        authToken: auth,
        locale
    });

    return resizedAITemplateDocument;
};

export const getTransformedDocumentWithAITemplate = async (
    existingDocument: CimDoc,
    aiTemplateDocument: DSS.DesignDocument | undefined,
    productKey: string,
    productVersion: number | null,
    selectedOptions: Record<string, string>,
    sessionId: string,
    locale: string,
    auth: any,
    canvasName?: string,
    placeholderConfig?: PlaceholderConfig
) => {
    const ignoreSavedPanelIds: string[] = [];
    if (canvasName) {
        const targetPanelId = existingDocument.document.panels.find(
            (panel: Panel) => panel.name.toLowerCase() === canvasName.toLowerCase()
        )!.id;

        ignoreSavedPanelIds.push(targetPanelId);
    }

    const newDesignDoc = await V2.getDesignDocumentWithSources(
        productKey,
        productVersion as number,
        selectedOptions,
        existingDocument,
        undefined,
        undefined,
        ignoreSavedPanelIds,
        sessionId,
        undefined,
        locale
    );

    // Transfer customizations v1 endpoint does not support transforming documents with different sized panels-
    // this requires manual document merge.
    // There is a v2 endpoint of transfer customizations that will cover this use case.
    // `mergeDocumentWithTransferCustomizations` function can be used once studio is updated to transfer customizations v2 endpoint

    let updatedAiTemplateDocument = aiTemplateDocument;
    if (aiTemplateDocument && placeholderConfig) {
        updatedAiTemplateDocument = removePlaceholderContent(aiTemplateDocument, placeholderConfig);
    }
    let transformedDocument = mergeDocumentManually(newDesignDoc, updatedAiTemplateDocument, canvasName);

    transformedDocument = maintainDocumentSources(existingDocument, transformedDocument);
    if (updatedAiTemplateDocument && placeholderConfig) {
        transformedDocument = addTemplatePlaceholderMetadata(
            updatedAiTemplateDocument,
            transformedDocument,
            placeholderConfig
        );
    }

    return transformedDocument;
};

const maintainDocumentSources = (originalDocument: CimDoc, newDocument: CimDoc) => {
    return {
        ...newDocument,
        metadata: {
            ...newDocument.metadata,
            documentSources: originalDocument?.metadata?.documentSources
        }
    };
};

function createPlaceholderTemplateMetadata(itemId: string, label: string, purpose: string): DTR.TemplateMetadata {
    return {
        availableFinishes: [],
        id: itemId,
        label,
        locks: {},
        placeholder: label,
        purpose
    };
}

const addTemplatePlaceholderMetadata = (
    aiTemplateDocument: DSS.DesignDocument,
    document: CimDoc,
    placeholderConfig: PlaceholderConfig
) => {
    const templatePlaceholderMetadata = [];
    const textPlaceholderMetadata = aiTemplateDocument.metadata?.textPlaceholder;

    for (const key in textPlaceholderMetadata) {
        // create the placeholder metadata for each placeholder item
        if (textPlaceholderMetadata[key]) {
            const placeholderItemIds = textPlaceholderMetadata[key];
            const placeholderData = placeholderConfig[key];
            const templateMetaData = placeholderItemIds.map((itemId: string) =>
                createPlaceholderTemplateMetadata(itemId, placeholderData.label, placeholderData.purpose)
            );
            templatePlaceholderMetadata.push(...templateMetaData);
        }
    }
    return {
        ...document,
        metadata: {
            ...document.metadata,
            template: templatePlaceholderMetadata
        }
    };
};

const removePlaceholderContent = (aiTemplateDocument: DSS.DesignDocument, placeholderConfig: PlaceholderConfig) => {
    const sourceDocumentPanels = aiTemplateDocument.document.panels;

    const sourceMetadata = aiTemplateDocument.metadata as unknown as { textPlaceholder: { [key: string]: string[] } };

    // textPlaceholder is an object which tells us which item is a place holder.
    const textPlaceholderMetadata = sourceMetadata ? sourceMetadata.textPlaceholder : {};
    // Remove the content for item which is placeholder and place holder label and placeholder text is same.
    const updatedSourcePanels = sourceDocumentPanels?.map(sourceDocumentPanel => {
        for (const key in textPlaceholderMetadata) {
            if (textPlaceholderMetadata[key]) {
                const placeholderItemIds = textPlaceholderMetadata[key];
                const placeholderData = placeholderConfig[key];

                placeholderItemIds.forEach((itemId: string) => {
                    // for each item which is a placeholder
                    // and the content is not changed by the user, then remove the content from cimdoc
                    const textItemReference = sourceDocumentPanel.itemReferences?.find(
                        itemReference => itemReference.id === itemId
                    );

                    if (textItemReference) {
                        if (textItemReference.data.content === placeholderData.label) {
                            textItemReference.data.content = "";
                        }
                    } else {
                        const textArea = sourceDocumentPanel.textAreas?.find(textArea => textArea.id === itemId);

                        if (textArea) {
                            if (textArea.content && textArea.content[0].content === placeholderData.label) {
                                textArea.content[0].content = "";
                            }
                        }
                    }
                });
            }
        }

        return sourceDocumentPanel;
    });
    return {
        ...aiTemplateDocument,
        document: {
            ...aiTemplateDocument.document,
            panels: updatedSourcePanels
        }
    };
};

// This function is currently unused- it doesn't support transforming documents with different sized panels
// it can be used instead of manual merge when studio is updated to use the v2 endpoint of transfer customizations is available in studio
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mergeDocumentWithTransferCustomizations = async (
    designDocument: DSS.DesignDocument,
    aiTemplateDocument: DSS.DesignDocument | undefined,
    auth: any
) => {
    const source = await getTransientDocumentUrl(designDocument, auth);
    const target = await getTransientDocumentUrl(aiTemplateDocument as DSS.DesignDocument, auth);

    const transformedDocument = await getTransferedDocument(source, target);
    return transformedDocument;
};

const mergeDocumentManually = (
    designDocument: DSS.DesignDocument,
    aiTemplateDocument: DSS.DesignDocument | undefined,
    canvasName?: string
) => {
    const updatedTemplatePanels = [] as Panel[];
    const aiTemplateDesignPanel = aiTemplateDocument?.document.panels.find(
        panel => panel.name.toLowerCase() === canvasName?.toLowerCase()
    );
    designDocument.document.panels.forEach((designDocPanel: Panel) => {
        if (canvasName?.toLowerCase() === designDocPanel.name.toLowerCase() && aiTemplateDesignPanel) {
            updatedTemplatePanels.push(aiTemplateDesignPanel);
        } else {
            updatedTemplatePanels.push(designDocPanel);
        }
    });

    const mergedDocument = {
        ...designDocument,
        document: { panels: updatedTemplatePanels }
    };
    return mergedDocument;
};
