import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FinishMessages } from "../messages";
import * as styles from "./PremiumFinishLockedTooltip.module.scss";

export function PremiumFinishLockedTooltip() {
    const { t } = useTranslationSSR();

    return <div className={styles.lockedTooltipMessage}>{t(FinishMessages.finishOnMessage.id)}</div>;
}

PremiumFinishLockedTooltip.displayName = "PremiumFinishLockedTooltip";
