import { useEffect, useState } from "react";
import { useDesigner, useActiveCanvas, getDecorationTechnologyForCanvas } from "@designer-suite";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";

/**
 * A hook that provides the decoration technology for the design
 * @returns {
 *  decorationTechnology: DecorationTechnology
 * }
 */
export function useDecorationTechnology() {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const [decorationTechnology, setDecorationTechnology] = useState(
        designer && canvas ? getDecorationTechnologyForCanvas(designer, canvas) : DecorationTechnologiesSimple.PRINT
    );

    useEffect(() => {
        if (!designer || !canvas) return;
        const canvasDecorationTechnology = getDecorationTechnologyForCanvas(designer, canvas);

        setDecorationTechnology(canvasDecorationTechnology);
    }, [designer, canvas]);

    return decorationTechnology;
}
