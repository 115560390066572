import React, { HTMLProps } from "react";
import classNames from "classnames";
import { SheetPanel } from "@shared/features/StudioChrome";
import { FocusLock } from "@shared/features/Accessibility";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";

interface Props extends HTMLProps<HTMLDivElement> {
    contentType: DialogType;
    hidebackButton?: boolean;
    backContentType?: DialogType;
    sheetTitle?: string;
}

export const SheetPanelWrapper = (props: Props) => {
    const { children, contentType, className, backContentType, hidebackButton, sheetTitle } = props;
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { trackEvent } = useTrackEvents();

    const onCloseClick = () => {
        setCurrentActiveDialog(DialogType.None);
    };

    const onBackClick = () => {
        trackEvent({ eventDetail: `${STUDIO_TRACKING_EVENTS.CLICK_BACK_SHEET}${currentActiveDialog}` });
        setCurrentActiveDialog(backContentType || DialogType.None);
    };

    return (
        <FocusLock
            isLocked={currentActiveDialog === contentType}
            className={classNames("sheet-panel-focus-lock", {
                "sheet-panel-focus-lock--visible": currentActiveDialog === contentType
            })}
            data-state={currentActiveDialog === contentType ? "visible" : "hidden"}
        >
            <SheetPanel
                className={className}
                isSelected={currentActiveDialog === contentType}
                onCloseClick={onCloseClick}
                onBackClick={hidebackButton ? undefined : onBackClick}
                sheetTitle={sheetTitle}
            >
                {children}
            </SheetPanel>
        </FocusLock>
    );
};

SheetPanelWrapper.displayName = "SheetPanelWrapper";
