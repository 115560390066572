import React, { useCallback, HTMLProps } from "react";
import classNames from "classnames";
import { useOnKeyDown } from "@shared/features/Accessibility";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTableSubselection, SubselectionType } from "../../TableSubselectionProvider";

import * as styles from "./OffsetBarRow.module.scss";

interface OffsetBarRowProps extends HTMLProps<HTMLDivElement> {
    rowIndex: number;
}

export function OffsetBarRow({ rowIndex, ...rest }: OffsetBarRowProps) {
    const { selection, updateSelection } = useTableSubselection();
    const { trackEvent } = useTrackEvents();

    const handleFocus = useCallback(() => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_TABLE_OVERLAY_ROW });
        updateSelection({ ...selection, rowIndex, columnIndex: -1, type: SubselectionType.Row });
    }, [rowIndex, selection, updateSelection, trackEvent]);

    const onKeyDown = useOnKeyDown(handleFocus);

    const isSelected = selection.type === SubselectionType.Row && selection.rowIndex === rowIndex;

    return (
        <div
            {...rest}
            className={classNames(
                "offset-bar-items",
                {
                    "offset-bar-row-item-selected": isSelected
                },
                styles.offsetBarRow,
                {
                    [styles.rowSelected]: isSelected
                }
            )}
            onFocus={handleFocus}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
            aria-pressed={isSelected}
        >
            <div>{rowIndex + 1}</div>
        </div>
    );
}

OffsetBarRow.displayName = "OffsetBarRow";
