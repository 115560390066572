import React, { useEffect, useState } from "react";
import { PremiumFinishIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Divider, Link, FluidImage } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { FULLBLEED_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import {
    StudioPanelDesignRequirements,
    useDesignRequirementsContext,
    useNeedAddButtonContext,
    useHasAvailableUpsell,
    useIsBlankAndHasNoUpsells
} from "@shared/features/Product";
import { useAvailablePremiumFinishesForSurfaces } from "@shared/features/PremiumFinish";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import type { GetDocument } from "@shared/utils/CimDoc";
import { DialogCanvasData, useOnAddClick, useOnCanvasClick, useOnContinueClick } from "@shared/features/DesignPanel";
import classNames from "classnames";
import { CanvasSelectorChangePopover } from "./CanvasSelectorChangePopover";
import { CanvasTileButton } from "./CanvasTileButton";
import { useCanvasSelectorPreviews } from "./hooks/useCanvasSelectorPreviews";
import { canvasSelectorMessages } from "./canvasSelectorMessages";
import { VerticalCanvasCarousel } from "./VerticalCarousel/VerticalCanvasCarousel";
import * as styles from "./CanvasSelector.module.scss";
import { useTriggerStartingCanvas } from "./hooks/useTriggerStartingCanvas";

const PremiumFinishIconContainer = () => {
    return (
        <div className={styles.premiumFinishIconContainer}>
            <PremiumFinishIcon className={styles.premiumFinishIcon} role="img" />
        </div>
    );
};

const getVerticalModelsToShow = (canvasesLength: number) => {
    if (canvasesLength > 2) {
        if (window.innerHeight <= 760) {
            return 1;
        }
        if (window.innerHeight < 950 && window.innerHeight > 760) {
            return 2;
        }
        return 3;
    }
    return window.innerHeight < 635 ? 1 : 2;
};

interface Props {
    activeCanvasName?: string;
    getDocument: GetDocument;
    triggerCanvasChange: TriggerCanvasChange;
    className?: string;
}

export function CanvasSelector({ activeCanvasName, getDocument, triggerCanvasChange, className }: Props) {
    const [showSparkleIcon, setShowSparkleIcon] = useState(false);
    const canvasSelectorPreviewsData = useCanvasSelectorPreviews();
    const { isMedium } = useStudioLayout();
    const isBlankAndHasNoUpsells = useIsBlankAndHasNoUpsells();
    const needAddButton = useNeedAddButtonContext();
    const hasAvailableUpsell = useHasAvailableUpsell();
    const onAddClick = useOnAddClick();
    const onCanvasClick = useOnCanvasClick({ activeCanvasName, getDocument, triggerCanvasChange });
    const onContinueClick = useOnContinueClick();
    const { t } = useTranslationSSR();
    const { hasPremiumFinishesAvailable } = useAvailablePremiumFinishesForSurfaces();
    const hasNoPremiumFinishItem = useAppSelector(state => state.isPremiumFinishWarningOn);
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const matchingTemplates = useAppSelector(state => state.matchingTemplates);
    const [showChangePopover, setShowChangePopover] = useState(true);
    const { useCanvasChangePopover } = useAppSelector(state => state.studioConfiguration);
    const designRequirements = useDesignRequirementsContext();
    useTriggerStartingCanvas({ activeCanvasName, getDocument, triggerCanvasChange });

    const shouldShowChangePopover = showChangePopover && useCanvasChangePopover;

    useEffect(() => {
        setShowSparkleIcon(!!(hasPremiumFinishesAvailable && !hasNoPremiumFinishItem));
    }, [setShowSparkleIcon, hasPremiumFinishesAvailable, hasNoPremiumFinishItem]);

    if (!canvasSelectorPreviewsData || !designRequirements || !activeCanvasName) {
        return null;
    }
    const { canvasSelectorUrls } = canvasSelectorPreviewsData;

    const designablePanels = designRequirements.panels.filter(
        (panel: StudioPanelDesignRequirements, panelIndex: number) => !isBlankAndHasNoUpsells(panel)
    );

    if (canvasSelectorUrls.length < 2 || designablePanels.length < 2 || !easelLoaded || isMedium) {
        return null;
    }

    // Checking canvases length for better experience with products with more than 3 sides.
    const verticalModelsToShow = getVerticalModelsToShow(designRequirements.numberOfPanels);

    // check if the add pannel functionality needed on tile change
    const onCanvasTileBtnClick = (panel: StudioPanelDesignRequirements, canvasData: DialogCanvasData, name: string) => {
        if (needAddButton(panel, name)) {
            onAddClick(canvasData);
        } else {
            onCanvasClick(canvasData);
        }
    };

    return (
        <VerticalCanvasCarousel
            className={classNames(styles.container, className)}
            modelsToShow={verticalModelsToShow}
            sideBySideArrows={true}
            activeCanvasName={activeCanvasName}
        >
            {designRequirements.panels.map((panel: StudioPanelDesignRequirements, panelIndex: number) => {
                const canvasData = canvasSelectorUrls[panelIndex];
                if (!canvasData) {
                    return null;
                }
                const { name, title, src } = canvasData;
                if (isBlankAndHasNoUpsells(panel)) {
                    return null;
                }
                return (
                    <div className={styles.pagination} key={panelIndex}>
                        {/* show sparkle icon only for front canvas having atleast one premium finish item */}
                        {panelIndex === 0 && showSparkleIcon && <PremiumFinishIconContainer />}
                        <CanvasTileButton
                            isActive={panel.name === activeCanvasName}
                            ariaLabel={title}
                            onClick={() => onCanvasTileBtnClick(panel, canvasData, name)}
                        >
                            <FluidImage
                                p={3}
                                className={styles.canvasImage}
                                alt={t(canvasSelectorMessages.designPanelPreview.id)}
                                src={src}
                            />
                            <div className={styles.canvasLabel}>
                                <Typography fontWeight="bold" fontSize="-1" textAlign="left">
                                    {title}
                                </Typography>
                                {/*
                                    Show the 'change' button for canvases other than the front so users can always choose to duplicate front, start from blank, etc
                                    In some rare cases we have upsells available for the front.
                                    Normally showing 'change' for the first canvas is unwanted - the user chose their template and doesn't need to change
                                    But for those rare products where any surface is optional we want to make sure we include the 'change' button
                                */}
                                {!needAddButton(panel, name) &&
                                    activeCanvasName &&
                                    (panelIndex !== 0 || hasAvailableUpsell(name)) &&
                                    panel.name === activeCanvasName && (
                                        <>
                                            <Divider marginY={2} />
                                            <div className={styles.changeContainer}>
                                                {shouldShowChangePopover &&
                                                    matchingTemplates &&
                                                    matchingTemplates.filter(
                                                        (t: any) =>
                                                            t.panelName === name &&
                                                            t.templateToken !== FULLBLEED_SELECTED_TEMPLATE
                                                    ).length > 2 && (
                                                        <CanvasSelectorChangePopover
                                                            title={title as string}
                                                            setShowState={setShowChangePopover}
                                                            className={styles.changePopover}
                                                        />
                                                    )}
                                                <Link onClick={() => onContinueClick(panelIndex, canvasData)}>
                                                    <Typography fontSize="-1" textAlign="left">
                                                        {t(canvasSelectorMessages.sideselectorChange.id)}
                                                    </Typography>
                                                </Link>
                                            </div>
                                        </>
                                    )}
                            </div>
                        </CanvasTileButton>
                    </div>
                );
            })}
        </VerticalCanvasCarousel>
    );
}
CanvasSelector.displayName = "CanvasSelector";
PremiumFinishIconContainer.displayName = "PremiumFinishIconContainer";
