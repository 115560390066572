import { useStudioFlexibility } from "@shared/features/Flexibility";
import { useMemo } from "react";
import { getProductQuantityWithPricing } from "@shared/utils/Calcifer";
import { useAppSelector } from "@shared/redux";

export const useGetQuantityAttributes = async () => {
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const { isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();

    const quantityAttributes = await useMemo(async () => {
        if (!isMileStone2Enabled && !isMileStone3Enabled) {
            return undefined;
        }

        if (!productKey || !productVersion) {
            return undefined;
        }

        const quantityData = await getProductQuantityWithPricing(
            productKey,
            productVersion,
            studioSelectedProductOptions,
            locale
        );
        return quantityData;
    }, [isMileStone2Enabled, isMileStone3Enabled, locale, productKey, productVersion, studioSelectedProductOptions]);

    return quantityAttributes;
};
