import React, { HTMLProps } from "react";
import classNames from "classnames";
import { StandardSheetHeader, ContentPanel } from "@shared/features/StudioChrome";

interface Props extends HTMLProps<HTMLDivElement> {
    hidebackButton?: boolean;
    isSelected: boolean;
    onBackClick?: () => void;
    onCloseClick: () => void;
    sheetTitle?: string;
}

export const SheetPanel = (props: Props) => {
    const { children, className, isSelected, onCloseClick, sheetTitle, onBackClick } = props;

    return (
        <ContentPanel className={classNames("sheet-panel", className)} isSelected={isSelected}>
            <StandardSheetHeader
                className="sheet-panel-header"
                pb={4}
                onBackClick={onBackClick}
                onCloseClick={onCloseClick}
                sheetTitle={sheetTitle}
            />
            {children}
        </ContentPanel>
    );
};

SheetPanel.displayName = "SheetPanel";
