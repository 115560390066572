import { useActiveCanvas } from "@designer-suite";
import { isImageUnreplacedPlaceholder } from "@utilities";

/**
 * Returns an array of unreplaced placeholders(except QR code placeholders)
 */
export const useGetUnreplacedImagePlaceholders = (): ImageItem[] => {
    const canvas = useActiveCanvas();

    return canvas?.items.filter(isImageUnreplacedPlaceholder) || [];
};
