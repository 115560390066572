import { fetchWorkEntity, WorkEntityToCreate, postWorkEntity } from "@shared/utils/Work";
import { cloneDocument } from "@shared/utils/DocumentStorage";
import { cleanStudioUrl } from "@shared/utils/WebBrowser";

export async function duplicateDesign(workId: string, udsTenant: string, newName: string, authToken: string) {
    const workEntityToClone: WorkEntityToCreate = await fetchWorkEntity(authToken, workId);

    delete workEntityToClone.workId;
    delete workEntityToClone.workRevisionId;
    if (workEntityToClone.design?.docRefUrl) {
        // most pages etc do not update this
        delete workEntityToClone.design.docRefUrl;
    }

    const documentInfo = await cloneDocument(workEntityToClone.design.designUrl!, udsTenant, authToken);

    workEntityToClone.workName = newName;
    workEntityToClone.design.editUrl = `${cleanStudioUrl(window.location.pathname)}?workId=\${workId}`;
    workEntityToClone.design.designUrl = documentInfo.documentRevisionUrl;
    workEntityToClone.design.displayUrl = documentInfo.previewInstructionSourceUrl;
    workEntityToClone.design.manufactureUrl = documentInfo.instructionSourceUrl;

    return postWorkEntity({ workEntity: workEntityToClone, authToken });
}
