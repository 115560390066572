import React from "react";
import classNames from "classnames";
import * as styles from "./SheetHorizontalScrollButton.module.scss";

export function SheetHorizontalScrollButton({
    children,
    className,
    onClick,
    ...restOfProps
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            className={classNames("sheet-horizontal-scroll-button", styles.sheetHorizontalScrollButton, className)}
            onClick={onClick}
            {...restOfProps}
        >
            {children}
        </button>
    );
}

SheetHorizontalScrollButton.displayName = "SheetHorizontalScrollButton";
