/* eslint-disable max-len */
import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

export function ColumnIcon(props: Props) {
    return (
        <svg {...props} viewBox="0 0 16 16" fill="none">
            <path opacity="0.6" d="M2.5 13V2.5H8.1875L8 13H2.5Z" fill="#666666" />
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.5" stroke="currentColor" />
            <path d="M8 2.5V13.5" stroke="currentColor" />
            <path d="M13.5 8L2.5 8" stroke="currentColor" />
        </svg>
    );
}
ColumnIcon.displayName = "ColumnIcon";
