import type { ChoiceGroup as BaseChoiceGroup, DifferentialPriceDescription } from "@vp/wrangler-data-source/dist/types";

export interface ChoiceGroupResponse extends Omit<BaseChoiceGroup, "totalDiscountedPrice"> {
    totalDiscountPrice: number;
}

export interface PricingApiResponse extends Omit<DifferentialPriceDescription, "choiceGroups"> {
    productKey: string;
    productVersion: string;
    fractionDigits: string;
    currency: string;
    baseSelections: Record<string, string>;
    choiceGroups: Record<string, ChoiceGroupResponse>;
}

export interface PricingChoiceGroup {
    totalListPrice: number;
    totalDiscountPrice: number;
    differentialListPrice: number;
    differentialDiscountPrice: number;
}
export interface Pricing {
    currency: string;
    baseSelectionsDiscountedPrice: number;
    baseSelectionsListPrice: number;
    choiceGroups: Record<string, PricingChoiceGroup>;
}

export const enum PricingType {
    TAXED = "taxed",
    UNTAXED = "untaxed"
}
