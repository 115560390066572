/* eslint-disable max-len */
import React from "react";

export function UseThemHereIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 63" fill="none" {...props}>
            <rect
                width={38.274}
                height={7.655}
                x={14.478}
                y={54.345}
                fill="#fff"
                stroke="#000"
                strokeWidth={3}
                rx={2}
            />
            <mask id="a" fill="#fff">
                <rect width={13.779} height={16.84} x={26.726} y={42.098} rx={1} />
            </mask>
            <rect
                width={13.779}
                height={16.84}
                x={26.726}
                y={42.098}
                fill="#fff"
                stroke="#000"
                strokeWidth={4}
                mask="url(#a)"
                rx={1}
            />
            <rect width={62.769} height={41.336} x={2.231} y={2.293} fill="#fff" stroke="#000" strokeWidth={3} rx={3} />
            <path stroke="#000" strokeWidth={1.5} d="M39 26h4v4h-4zM22 26h4v4h-4zM39 9h4v4h-4zM22 9h4v4h-4z" />
            <path stroke="#000" strokeWidth={1.5} d="M26 13v-2h13v2h2v13h-2v2H26v-2h-2V13h2Z" />
            <path fill="#000" d="m28.397 21.06 2.132-2.843 2.133 2.843h-4.265Z" />
            <path
                fill="#000"
                d="m31.24 21.06 3.199-4.265 3.198 4.265H31.24ZM47.613 32.64l-2.544-.847a.5.5 0 0 0-.632.632l.848 2.544a.5.5 0 0 0 .903.099l.507-.846a.5.5 0 0 1 .172-.171l.845-.507a.5.5 0 0 0-.099-.903Z"
            />
            <path stroke="#000" strokeLinecap="square" strokeLinejoin="round" strokeWidth={2} d="M65 39.036H2.23" />
        </svg>
    );
}
UseThemHereIcon.displayName = "UseThemHereIcon";
