import { encodeForRenderingApis } from "@shared/utils/DocumentStorage";
import { SceneConfig } from "@shared/utils/Scene";

/*
These utility functions are lifted from PURCS repo and slimmed down to serve Studio's basic needs
of generating previews without scenes as a fallback in case PURCS goes down.
*/

const MAX_SCENE_WIDTH = 1360;

const constructInstructionsUri = (previewInstructionsUri: string, shouldIgnoreProjection: boolean) => {
    const ignoreProjectionQueryParam = shouldIgnoreProjection ? "&ignoreProjection=true" : "";

    return `${previewInstructionsUri}${ignoreProjectionQueryParam}`;
};

export interface GenerateRenderingUrlParams {
    previewInstructionsUri?: string;
    shouldIgnoreProjection: boolean;
    sceneUrl: string;
    width: number;
}

function generateRenderingUrl(params: GenerateRenderingUrlParams) {
    const { previewInstructionsUri, shouldIgnoreProjection, sceneUrl, width } = params;

    try {
        const returnUrl = new URL(`${RENDERING_SERVICE_URL}/v2/vp/preview`);

        if (width) {
            returnUrl.searchParams.set("width", width.toString());
        }
        if (previewInstructionsUri) {
            returnUrl.searchParams.set(
                "instructions_uri",
                constructInstructionsUri(previewInstructionsUri, shouldIgnoreProjection)
            );
        }
        if (sceneUrl) {
            returnUrl.searchParams.set("scene", sceneUrl);
        }

        return returnUrl.toString();
    } catch (error) {
        throw error;
    }
}

interface RenderingOptions {
    width: number;
    previewInstructionsUri?: string;
}

interface ConvertSceneUrlToViewParams {
    renderingOptions: RenderingOptions;
    sceneType: string;
    transientSceneUrl: string;
    name: string;
}

export type ViewScene = {
    id: null;
    _links: {
        self: {
            href: string;
        };
        content: {
            href: string;
        };
    };
};

export type View = {
    sceneType: string;
    _embedded: { scene: ViewScene };
    _links: { image: { href: string; title: string } };
    name: string;
};

export function convertSceneUrlToView({
    renderingOptions,
    sceneType,
    transientSceneUrl,
    name
}: ConvertSceneUrlToViewParams): View {
    const imageUrl = generateRenderingUrl({
        shouldIgnoreProjection: true, // forces rendering to ignore projection
        previewInstructionsUri: renderingOptions.previewInstructionsUri,
        sceneUrl: transientSceneUrl,
        width: renderingOptions.width
    });

    return {
        sceneType,
        _embedded: {
            scene: {
                id: null,
                _links: {
                    self: { href: transientSceneUrl },
                    content: { href: transientSceneUrl }
                }
            }
        },
        _links: {
            image: {
                href: imageUrl,
                title: name
            }
        },
        name
    };
}

export async function buildTransientSceneUrl(
    page: number,
    productHeight: number,
    productWidth: number,
    sceneWidth: number
) {
    // Cap the max width of the scene
    const derivedWidth = Math.min(sceneWidth, MAX_SCENE_WIDTH);

    const sceneObject = {
        width: derivedWidth,
        height: Math.round(derivedWidth * (productHeight / productWidth)),
        page: page + 1
    };

    const base64Encoded = await encodeForRenderingApis(sceneObject);

    const sceneUrl = `${TRANSIENT_SCENE_SERVICE_URL}/v3/transient?data=${encodeURIComponent(base64Encoded)}`;
    return sceneUrl;
}

/**
 * This function checks if we're using an underlay Scene or a View type returned by PURCS, or a string (we can get a string from useGenerateFlexibilityPreviewUrl)
 */
export function isView(sceneOrView: SceneConfig | View | string): sceneOrView is View {
    return (sceneOrView as View)._links !== undefined;
}

export function isScene(sceneOrView: SceneConfig | View | string): sceneOrView is SceneConfig {
    return (sceneOrView as Scene).url !== undefined;
}
