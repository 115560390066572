import React from "react";
import {
    StrokeColorIcon,
    TextColorIcon,
    TableColorIcon,
    BucketColorIcon,
    ShapeFillColorIcon
} from "@shared/features/StudioChrome";
import { ColorIconType } from "./colorTypes";

/**
 * Return the icon that matches the provided enum
 * @param icons icon provider
 * @param icon enum referencing which icon to use
 */
export function getIcon(icon: ColorIconType | undefined) {
    switch (icon) {
        case ColorIconType.StrokeColorIcon:
            return <StrokeColorIcon />;
        case ColorIconType.ShapeFillColorIcon:
            return <ShapeFillColorIcon />;
        case ColorIconType.TableColorIcon:
            return <TableColorIcon />;
        case ColorIconType.TextColorIcon:
            return <TextColorIcon />;
        case ColorIconType.BucketColorIcon:
        default:
            return <BucketColorIcon aria-hidden={true} />;
    }
}
