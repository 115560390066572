import { useCallback, useMemo } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useCookie } from "@shared/features/WebBrowser";
import {
    BackgroundColorIcon,
    BucketColorIcon,
    ChangeTemplateIcon,
    FlexibilityIcon,
    GraphicsIcon,
    MoreOptionsIcon,
    QRCodeIcon,
    TablesIcon,
    TeamsIcon,
    ImageIcon,
    TextIcon,
    AITemplatesIcon,
    IdeaPanelIcon
} from "@shared/features/StudioChrome";
import { useActiveCanvas } from "@designer-suite";
import { useInitIcons } from "@shared/features/IconAssetLibrary";
import { useTrackEvents, useTrackingClient } from "@shared/features/Tracking";
import { setShowTeamsGuideModal, useAppDispatch, useAppSelector } from "@shared/redux";
import { useTemplateColorVariations } from "@shared/features/Templates";
import { STUDIO_TRACKING_EVENTS, Events } from "@shared/utils/Tracking";
import { useDesignRequirementsContext, useHasSubstrateColor } from "@shared/features/Product";
import { useStudioFlexibility, useActiveFlexibilityOptions } from "@shared/features/Flexibility";
import { DialogType } from "@shared/features/ActiveDialog";
import { useShowChangeTemplate } from "@shared/features/ChangeTemplate";
import { useDesignDialog } from "@shared/features/DesignPanel";
import { useProcessType } from "@utilities";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { getCanvasOrdinal } from "@five/utilities/canvasUtilities";
import { useToolFlags } from "@shared/features/StudioConfiguration";
import { useIsTeamsEnabledDefault } from "@shared/features/StudioBootstrap/useIsTeamsEnabledDefault";
import { useAITemplateTracking } from "@five/components/Panels/AITemplates/useAITemplateTracking";
import { useIsTeamsEnabled } from "./useIsTeamsEnabled";
import { useEnableBackgroundColor } from "./useEnableBackgroundColor";
import { useIsAITemplatesEnabled } from "./useIsAITemplatesEnabled";
import { TemplatePanelContentTypes, useTemplatePanel } from "../components/Panels/TemplatePanel/TemplatePanelProvider";
import { GenerateIdeasPanelMessages } from "../components/Panels/GenerateIdeas";
import { useTriggerCanvasChange } from "./useTriggerCanvasChange";
import { useIdeaTabEnabled } from "./useIdeaTabEnabled";

export interface Option {
    dataTestId?: string;
    label: string;
    contentType: DialogType;
    disabled: boolean;
    available: boolean;
    icon: any;
    renderEventDetail?: string;
    clickEventType?: string;
    onClick?: () => void;
}

const messages = defineMessages({
    templates: {
        id: "studio.components.addSheet.templates.label",
        defaultMessage: "Templates",
        description: {
            note: "Label for the add sheet templates button"
        }
    },
    text: {
        id: "studio.components.addSheet.text.label",
        defaultMessage: "Text",
        description: {
            note: "Label for the add sheet text button"
        }
    },
    images: {
        id: "studio.components.addSheet.images.label",
        defaultMessage: "Images",
        description: {
            note: "Label for the add sheet images button"
        }
    },
    shapes: {
        id: "studio.components.addSheet.shapes.label",
        defaultMessage: "Shapes",
        description: {
            note: "Label for the add sheet Shapes button"
        }
    },
    tables: {
        id: "studio.components.addSheet.tables.label",
        defaultMessage: "Tables",
        description: {
            note: "Label for the add sheet tables button"
        }
    },
    qrCodes: {
        id: "studio.components.addSheet.qrCodes.label",
        defaultMessage: "QR Codes",
        description: {
            note: "Label for the add sheet qrCodes button"
        }
    },
    preview: {
        id: "studio.components.addSheet.preview.label",
        defaultMessage: "Preview",
        description: {
            note: "Label for the add sheet preview button"
        }
    },
    guides: {
        id: "studio.components.addSheet.guides.label",
        defaultMessage: "Guides",
        description: {
            note: "Label for the add sheet guides button"
        }
    },
    substrateColor: {
        id: "studio.components.addSheet.substrateColor.label",
        defaultMessage: "Color",
        description: {
            note: "Label for the substrate color selector button"
        }
    },
    groupsAndTeams: {
        id: "studio.components.addSheet.groupsAndTeams.label",
        defaultMessage: "Names",
        description: {
            note: "Label for the groups and teams button"
        }
    },
    backgroundColor: {
        id: "studio.components.sidebar.backgroundColor.label",
        defaultMessage: "Background",
        description: {
            note: "Label for the background color button"
        }
    },
    flexibility: {
        id: "studio.components.sidebar.flexibility.label",
        defaultMessage: "Product Options",
        description: {
            note: "Label for the product options button"
        }
    },
    more: {
        id: "studio.components.sidebar.more.label",
        defaultMessage: "More",
        description: {
            note: "Label for the more button"
        }
    },
    aiTemplates: {
        id: "studio.components.sidebar.aiTemplates.label",
        defaultMessage: "Designs",
        description: {
            note: "Label for the PPAG AI Templates button"
        }
    }
});

const TEAMS_GUIDE_MODAL_COOKIE_NAME = "studio-teams-guide-modal-seen";

export function useMobileSidebarOptions() {
    const dispatch = useAppDispatch();
    const initIcons = useInitIcons();
    const showBackgroundColorButton = useEnableBackgroundColor();
    const changeTemplate = useShowChangeTemplate();
    const processType = useProcessType();
    const { shouldShowQRCodes, shouldShowImages, shouldShowGraphics, shouldShowTables } = useToolFlags(processType);
    const hasSubstrateColors = useHasSubstrateColor();
    const { hasTemplateColors } = useTemplateColorVariations();
    const isTeamsEnabled = useIsTeamsEnabled() || false;

    const showDesignTemplatesPanel = useIdeaTabEnabled();
    const isAITemplatesEnabled = useIsAITemplatesEnabled() || false;
    const shouldShowAITemplatesPanel = !showDesignTemplatesPanel && isAITemplatesEnabled;

    const { isMileStone1Enabled, isMileStone2Enabled } = useStudioFlexibility();
    const { isAnyEnabled } = useActiveFlexibilityOptions();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { isCanvasBlank } = useDesignDialog();
    const { t } = useTranslationSSR();
    const { jumpToInStack } = useTemplatePanel();
    const disableOnBlank = !easelLoaded || isCanvasBlank;
    const { trackEvent } = useTrackEvents();
    const trackingClient = useTrackingClient();
    const mobileTemplatesEnabled = useIsFlagEnabled("mobileTemplates");
    const canvas = useActiveCanvas();
    const canvasOrdinal = canvas && getCanvasOrdinal(canvas);
    const triggerCanvasChange = useTriggerCanvasChange();
    const designRequirements = useDesignRequirementsContext();
    const isTeamsEnabledDefault = useIsTeamsEnabledDefault();
    const { tracking } = useAITemplateTracking();

    const { cookieValue, setCookieValue } = useCookie(TEAMS_GUIDE_MODAL_COOKIE_NAME, () => ({ expires: 21 }));

    const setShowTeamsGuideModalOnFirstClick = useCallback(
        (payload: boolean) => {
            if (cookieValue !== "true") {
                trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.SHOW_TEAMS_EXPERIENCE_GUIDE_MODAL });
                setCookieValue("true");
                dispatch(setShowTeamsGuideModal(payload));
            }
        },
        [cookieValue, dispatch, setCookieValue, trackEvent]
    );

    const colorButtonData = useMemo(() => {
        if (!easelLoaded) {
            return { clickEventDetailType: "", renderEventDetail: "" };
        }
        if (hasSubstrateColors && hasTemplateColors) {
            return {
                clickEventDetailType: "templateAndMaterialColor",
                renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_TEMPLATE_COLOR_BUTTON_BOTH_TEMPLATE_AND_MATERIAL
            };
        }
        if (hasSubstrateColors) {
            return {
                clickEventDetailType: "materialColor"
            };
        }
        return {
            clickEventDetailType: "templateColor",
            renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_TEMPLATE_COLOR_BUTTON_ONLY_TEMPLATE
        };
    }, [easelLoaded, hasSubstrateColors, hasTemplateColors]);

    const showColorsPanel = useMemo(() => {
        return hasSubstrateColors || hasTemplateColors; // Will expand on this later to show has templateColors
    }, [hasSubstrateColors, hasTemplateColors]);
    const showFlexibility = isMileStone2Enabled && isAnyEnabled;

    const onShapeClick = useCallback(() => {
        initIcons();
    }, [initIcons]);

    const onAITemplatesClick = useCallback(() => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: tracking.openPanel,
            label: tracking.label
        });
    }, [tracking.label, tracking.openPanel, trackingClient]);

    const templateOption = useCallback(
        () => ({
            label: t(messages.templates.id),
            contentType: DialogType.Template,
            disabled: disableOnBlank,
            icon: ChangeTemplateIcon,
            available: (changeTemplate && !isMileStone1Enabled && mobileTemplatesEnabled) || false,
            onClick: () => {
                if (canvas && designRequirements && canvasOrdinal !== 1) {
                    triggerCanvasChange(designRequirements.getDefaultPanelName());
                }
                jumpToInStack(TemplatePanelContentTypes.Main);
            }
        }),
        [
            t,
            disableOnBlank,
            changeTemplate,
            isMileStone1Enabled,
            mobileTemplatesEnabled,
            canvas,
            designRequirements,
            canvasOrdinal,
            jumpToInStack,
            triggerCanvasChange
        ]
    );

    const allSideBarOptions = useMemo(() => {
        const options: Option[] = [
            templateOption(),
            {
                dataTestId: "TextPanel",
                label: t(messages.text.id),
                contentType: DialogType.Text,
                disabled: disableOnBlank,
                icon: TextIcon,
                available: true
            },
            {
                dataTestId: "GroupsAndTeamsPanel",
                label: t(messages.groupsAndTeams.id),
                contentType: DialogType.GroupsAndTeams,
                disabled: disableOnBlank,
                onClick: () => setShowTeamsGuideModalOnFirstClick(true),
                icon: TeamsIcon,
                available: isTeamsEnabled
            },
            {
                dataTestId: "aiTemplatesPanelMobile",
                label: t(messages.aiTemplates.id),
                contentType: DialogType.AITemplates,
                disabled: disableOnBlank,
                icon: AITemplatesIcon,
                available: shouldShowAITemplatesPanel,
                onClick: onAITemplatesClick
            },
            {
                label: t(GenerateIdeasPanelMessages.title.id),
                contentType: DialogType.IdeaPanel,
                disabled: disableOnBlank,
                icon: IdeaPanelIcon,
                available: showDesignTemplatesPanel
            },
            {
                dataTestId: "ImagePanel",
                label: t(messages.images.id),
                contentType: DialogType.Images,
                disabled: disableOnBlank,
                icon: ImageIcon,
                available: shouldShowImages
            },
            {
                dataTestId: "GraphicsPanel",
                label: t(messages.shapes.id),
                contentType: DialogType.Graphics,
                disabled: disableOnBlank,
                icon: GraphicsIcon,
                onClick: onShapeClick,
                available: shouldShowGraphics
            },
            {
                label: t(messages.substrateColor.id),
                contentType: DialogType.Color,
                disabled: disableOnBlank,
                icon: BucketColorIcon,
                renderEventDetail: colorButtonData.renderEventDetail,
                clickEventType: colorButtonData.clickEventDetailType,
                available: showColorsPanel
            },
            {
                dataTestId: "TablesPanel",
                label: t(messages.tables.id),
                contentType: DialogType.Tables,
                disabled: disableOnBlank,
                icon: TablesIcon,
                available: shouldShowTables
            },
            {
                dataTestId: "QRCodesPanel",
                label: t(messages.qrCodes.id),
                contentType: DialogType.QRCodes,
                disabled: disableOnBlank,
                icon: QRCodeIcon,
                available: shouldShowQRCodes
            },
            {
                dataTestId: "BackgroundPanel",
                label: t(messages.backgroundColor.id),
                contentType: DialogType.BackgroundColorPicker,
                disabled: disableOnBlank,
                icon: BackgroundColorIcon,
                renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_BACKGROUND_COLOR_BUTTON,
                available: showBackgroundColorButton
            },
            {
                label: t(messages.templates.id),
                contentType: DialogType.ChangeTemplate,
                disabled: disableOnBlank,
                icon: ChangeTemplateIcon,
                renderEventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE_BUTTON,
                available: (changeTemplate && isMileStone1Enabled && !showDesignTemplatesPanel) || false
            },
            {
                label: t(messages.flexibility.id),
                contentType: DialogType.Flexibility,
                disabled: disableOnBlank,
                icon: FlexibilityIcon,
                renderEventDetail: STUDIO_TRACKING_EVENTS.SHOW_PRODUCT_OPTIONS_BUTTON,
                available: showFlexibility
            }
        ];
        return options;
    }, [
        templateOption,
        t,
        disableOnBlank,
        shouldShowAITemplatesPanel,
        onAITemplatesClick,
        shouldShowImages,
        onShapeClick,
        shouldShowGraphics,
        colorButtonData.renderEventDetail,
        colorButtonData.clickEventDetailType,
        showColorsPanel,
        shouldShowTables,
        shouldShowQRCodes,
        isTeamsEnabled,
        showBackgroundColorButton,
        changeTemplate,
        isMileStone1Enabled,
        showFlexibility,
        setShowTeamsGuideModalOnFirstClick,
        showDesignTemplatesPanel
    ]);

    const maxIconCount = isMileStone2Enabled ? 4 : allSideBarOptions.length;

    return useMemo(() => {
        const availableOptions = allSideBarOptions.filter((option: Option) => option.available);
        let sideBarOptions: Option[] = availableOptions;
        let moreOptions: Option[] = [];
        if (maxIconCount !== allSideBarOptions.length) {
            sideBarOptions = availableOptions.slice(0, maxIconCount);
            moreOptions = availableOptions.slice(maxIconCount);
            if (moreOptions.length > 0) {
                sideBarOptions.push({
                    label: t(messages.more.id),
                    contentType: DialogType.MobileMore,
                    disabled: disableOnBlank,
                    icon: MoreOptionsIcon,
                    available: true
                });
            }
        }

        let defaultDialogType;
        if (isTeamsEnabled && isTeamsEnabledDefault) {
            defaultDialogType = DialogType.GroupsAndTeams;
        }

        return {
            sideBarOptions,
            moreOptions,
            defaultDialogType
        };
    }, [maxIconCount, disableOnBlank, allSideBarOptions, t, isTeamsEnabled, isTeamsEnabledDefault]);
}

