/**
 * Component to display a list of all items on the current canvas
 *  in descending ZOrder (background is bottom of the list) and allow drag
 *  & drop re-order, lock/unlock, selection, and delete for each item.
 */
import { defineMessages } from "@vp/i18n-helper";
import React, { PropsWithChildren } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export const layersPanelMessages = defineMessages({
    layersPanelHeader: {
        id: "studio.components.panels.studiopanels.layersHeader",
        defaultMessage: "Layers",
        description: {
            note: "Header for Tool for changing the z-index of items via drag & drop"
        }
    },
    empty: {
        id: "easel.ui.layers.empty",
        defaultMessage: "No items found",
        description: {
            note: "Text to show when no items are present"
        }
    }
});

interface LayersPanelProps extends PropsWithChildren<{}> {
    onDragEnd: (result: any) => void;
}

export function LayersPanel({ onDragEnd, children }: LayersPanelProps) {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

LayersPanel.displayName = "LayersPanel";
