import React from "react";
import { useActiveCanvas } from "@designer-suite";
import { FullBleedPremiumFinishPanel } from "@shared/features/PremiumFinish";
import { useFullBleedPremiumFinish } from "../../../hooks/useFullBleedPremiumFinish";

export function FullBleedPremiumFinishPanelStudio5() {
    const canvas = useActiveCanvas();
    const { enabledCanvasFinish, isButtonVisible } = useFullBleedPremiumFinish();
    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;

    const panelId = canvas && canvas._canvasViewModel.model.id;

    if (!isButtonVisible || !finishType) {
        return null;
    }

    return <FullBleedPremiumFinishPanel finishType={finishType} panelId={panelId} />;
}
FullBleedPremiumFinishPanelStudio5.displayName = "FullBleedPremiumFinishPanelStudio5";
