// extracted by mini-css-extract-plugin
export var container = "FullBleedPremiumFinishPanel-module--container--b622c";
export var editButton = "FullBleedPremiumFinishPanel-module--editButton--939a1";
export var icon = "FullBleedPremiumFinishPanel-module--icon--b567a";
export var imageWrapper = "FullBleedPremiumFinishPanel-module--imageWrapper--f9ee2";
export var instructions = "FullBleedPremiumFinishPanel-module--instructions--8b8bf";
export var instructionsText = "FullBleedPremiumFinishPanel-module--instructionsText--29edb";
export var label = "FullBleedPremiumFinishPanel-module--label--f10e2";
export var stickyBar = "FullBleedPremiumFinishPanel-module--stickyBar--7ce1d";
export var textArea = "FullBleedPremiumFinishPanel-module--textArea--410a6";
export var textOption = "FullBleedPremiumFinishPanel-module--textOption--e7967";