import React, { PropsWithChildren } from "react";
import * as styles from "./ItemContent.module.scss";

interface ItemContentProps extends PropsWithChildren<{}> {}

export const ItemContent = ({ children }: ItemContentProps) => {
    return <div className={styles.itemContent}>{children}</div>;
};

ItemContent.displayName = "ItemContent";
