import React, { forwardRef, Ref } from "react";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { TableOverlayCanvasDecorator } from "@presentational";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { useAppSelector } from "@shared/redux";
import { useContextualToolbar } from "../../../easel/designer-suite/ContextualToolbarProvider";
import { OnboardingToast } from "../Toasts/OnboardingToast";
import { ContextualTextEditorContainer } from "./ContextualTextEditorContainer";
import { FullBleedPremiumFinishButton } from "../Redesign/FullBleedPremiumFinish/FullBleedPremiumFinishButton";
import { PremiumFinishButton } from "../Redesign/PremiumFinish/PremiumFinishButton";

const StudioContextualToolbarFloating = loadable(
    () => loadableRetry(() => import("../../../easel/designer-suite/ContextualToolbarFloating")),
    {
        resolveComponent: components => components.ContextualToolbarFloating
    }
);

export const EaselContentsDesktop = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const productDataLoadSuccessful = useAppSelector(state => state.productDataLoadSuccessful);
    const { currentActiveDialog } = useActiveDialog();
    const { activeInputField } = useContextualToolbar();

    return (
        <>
            {/* don't delete, premium-finish-button-container is required to open premium finish modal in desktop */}
            <div className="premium-finish-button-container">
                <PremiumFinishButton />
                <FullBleedPremiumFinishButton />
            </div>

            <OnboardingToast />
            <div ref={ref} className="contextual-container" data-testid="contextual-toolbar">
                {productDataLoadSuccessful && (currentActiveDialog !== DialogType.Text || !activeInputField) && (
                    <StudioContextualToolbarFloating />
                )}
            </div>
            <TableOverlayCanvasDecorator />
            <ContextualTextEditorContainer />
        </>
    );
});

EaselContentsDesktop.displayName = "EaselContentsDesktop";
