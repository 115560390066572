/* eslint-disable prefer-const */
import qs from "qs";
import { getDocument } from "@shared/utils/DocumentStorage";
import type { DSS } from "@vp/types-ddif";
import { newRelicWrapper, handleError, ERROR_CODES } from "@shared/utils/Errors";
import { isShowWarningsMode, isStaging, isLocalHost, isBranch } from "@shared/utils/Debug";
import { isCareAgent } from "@shared/utils/Care";
import { getQueryParams, buildHistoryUrl } from "@shared/utils/WebBrowser";
import { convertDocumentSourceType, DocumentSourceType } from "@shared/utils/DSS";
import { CalciferEaselConfig, StudioConfig, getStudioConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { getDomainForLocale } from "@shared/utils/LiveSite";
import { migrateAltDocIdToWork, getWorkEntity, getWorkEntityFromSession, WorkEntity } from "@shared/utils/Work";
import { transferResources } from "@shared/utils/Ownership";
import { Identity } from "@shared/utils/Identity";
import {
    Store,
    AppDispatch,
    setWarnings,
    SetDefaultStatePayloadType,
    setProductKey,
    setCustomerSelectedProductOptions,
    setQuantity,
    setMpvId,
    setLocale,
    setStudioSelectedProductOptions,
    setIsDesignAssistantTabEnabled
} from "@shared/redux";
import { getMPVId, getProductKey } from "@shared/utils/Product";
import { ProductGroupConfiguration } from "@shared/utils/StudioConfiguration";
import { batch } from "react-redux";
import { setProductVersion } from "@shared/redux/slices/productVersion";
import { documentDimensionsFailure } from "./prepareStudioCimDoc/designDocumentUtilities";

async function shouldUserLogIn(error: Error, identity: Identity) {
    const workPermissionsError = new RegExp(`20-403`);
    return !identity.isSignedIn && error.message.toString().match(workPermissionsError);
}

export interface InitializationQueryParamOverrides {
    key?: string;
    mpvId?: string;
    // The customer selected options
    selectedOptions?: Record<string, string>;
    studioSelectedOptions?: Record<string, string>;
    qty?: string | object;
    workId?: string;
    documentUrl?: string;
    template?: string;
    fullBleedElected?: boolean;
    productVersion?: string;
}

interface InitializeStudioConfigV2Input {
    identity: Identity;
    auth: any;
    locale: string;
    dispatch: AppDispatch;
    project?: WorkEntity;
    queryParamOverrides?: InitializationQueryParamOverrides;
}

interface InitializeStudioConfigV2Output {
    initialState: SetDefaultStatePayloadType;
    initialEaselConfig: CalciferEaselConfig;
}

export const initializeStudioConfigV2 = async (
    { identity, auth, locale, dispatch, project, queryParamOverrides }: InitializeStudioConfigV2Input,
    throwError = false
): Promise<InitializeStudioConfigV2Output | null> => {
    let {
        key: productKey,
        mpvId,
        // The customer selected options
        selectedOptions,
        qty,
        workId,
        documentUrl,
        template,
        documentRevisionUrl,
        fullBleedElected,
        productVersion,
        alt_doc_id: altDocId,
        studioSelectedOptions
    } = queryParamOverrides || getQueryParams();
    const { owner } = getQueryParams();
    const authToken = auth.getToken();
    let quantity: number = 0;
    switch (typeof qty) {
        case "object":
            quantity = Object.keys(qty).reduce((sum, size) => sum + qty[size], 0);
            break;
        case "string":
            quantity = parseInt(qty, 10);
            if (!Number.isInteger(quantity)) {
                quantity = 0;
            }
            break;
        default:
            if (Number.isInteger(qty)) {
                quantity = qty;
            }
            break;
    }
    let quantityPerSize = JSON.stringify(qty || 0);

    // fullBleedElected is a boolean from queryParamOverrides or a string from getQueryParams
    const isFullBleed = typeof fullBleedElected === "string" ? fullBleedElected === "true" : fullBleedElected;
    let studioSelectedProductOptions = studioSelectedOptions;
    let customerSelectedProductOptions = selectedOptions;
    let initialState = null;
    let initialEaselConfig = null;
    let productName = null;
    let workEntity = project || null;
    let productDataLoadSuccessful = false;
    let scenesConfiguration;
    let views = null;
    let designDocument = null;
    let workTemplateToken = null;
    let isFullBleedDocument = false;
    let workRevisionId = null;
    let workName = null;
    let workLastSaved = null;
    let studioConfiguration = {} as StudioConfig["studioConfiguration"];
    let productGroupConfiguration = {} as ProductGroupConfiguration;
    let mcpSku = null;
    let mcpVersion = null;
    let designs = 0;
    let isDesignAssistantTabEnabled = false;

    newRelicWrapper.logPageAction("studio-loading-initstate-start");

    try {
        if (!workId && !productKey && !mpvId && !workEntity) {
            if (altDocId) {
                if (!identity.isSignedIn) {
                    newRelicWrapper.logPageAction("studio-loading-forcelogin-altdocid");
                    auth.signIn();
                    return null;
                }
                workId = await migrateAltDocIdToWork(owner, authToken, locale, altDocId);
                if (workId) {
                    newRelicWrapper.logPageAction("studio-loading-found-migrated-work");
                    window.history.pushState("update-workId", "Title", buildHistoryUrl({ workId }));
                    // monolith urls can contain the template parameter
                    template = null;
                }
            }

            if (!workId) {
                throw new Error(
                    JSON.stringify({
                        errorMessage: "No Product Data Provided In URL",
                        errorCodeStack: `${ENTITY_CODE}-${ERROR_CODES.NO_PRODUCT_DATA}`
                    })
                );
            }
        }
        // transfer resources before getting work entity
        // TODO: Also check here if the workId is not equal to the workEntity.workId?
        if (workId && !workEntity) {
            await transferResources(identity, auth.getToken());
            workEntity = getWorkEntityFromSession(workId);

            // check to make sure the current user owns the work, if not force them to log in
            const currentUserID = identity.isSignedIn
                ? identity.shopperId || identity.cimpressADFSUserId
                : identity.anonymousUserId;
            if (workEntity && workEntity.ownerId !== currentUserID && !isCareAgent()) {
                auth.signIn();
                return null;
            }

            if (!workEntity) {
                workEntity = await getWorkEntity(auth.getToken(), identity, workId);
            }
        }

        if (workEntity) {
            // Prevents mpv from replacing these user selected options
            customerSelectedProductOptions =
                workEntity.merchandising.merchandisingSelections || customerSelectedProductOptions;
            try {
                studioSelectedProductOptions = JSON.parse(workEntity.design.metadata.studioSelectedProductOptions);
                // eslint-disable-next-line no-empty
            } catch {}
            productKey =
                workEntity.product?.key ||
                workEntity.design.metadata.productKey ||
                workEntity.resources?.productKey ||
                productKey;
            productVersion = workEntity.product?.version || workEntity.design.metadata.productVersion || productVersion;
            quantity = workEntity.merchandising.quantity || quantity;
            quantityPerSize = workEntity.resources?.qty || quantityPerSize;
            documentUrl = workEntity.design.designUrl || workEntity.design.metadata.udsDocumentUrl || documentUrl;
            documentRevisionUrl = workEntity.design.designUrl || documentRevisionUrl;
            workRevisionId = workEntity.workRevisionId || workRevisionId;
            workName = workEntity.workName || workName;
            workLastSaved = workEntity.modified || workLastSaved;
            const { showDesignAssistantTab } = workEntity.design.metadata;
            isDesignAssistantTabEnabled = showDesignAssistantTab ? JSON.parse(showDesignAssistantTab) : false;

            // figure out mpvId so that it gets recorded in newRelic if Calcifer call fails
            if (workEntity.merchandising.mpvUrl) {
                mpvId = getMPVId(workEntity.merchandising.mpvUrl, mpvId);
            }
            newRelicWrapper.updateCustomAttributes({
                productKey,
                productVersion,
                selectedProductOptions: customerSelectedProductOptions,
                quantity,
                quantityPerSize,
                mpvId,
                workId
            });
        }

        if (!productKey && mpvId) {
            // set this for NR + analytics logging
            (async () => {
                const coreProductKey = await getProductKey(mpvId, locale);
                newRelicWrapper.updateCustomAttributes({
                    productKey: coreProductKey
                });
                dispatch(setProductKey(coreProductKey));
            })();
        }

        // quickly set some state so that we can log this info if calcifer fails
        batch(() => {
            dispatch(setProductKey(productKey));
            productVersion && dispatch(setProductVersion(parseInt(productVersion, 10)));
            dispatch(setCustomerSelectedProductOptions(customerSelectedProductOptions));
            dispatch(setStudioSelectedProductOptions(studioSelectedProductOptions || customerSelectedProductOptions));
            dispatch(setQuantity({ quantity, quantityPerSize }));
            dispatch(setMpvId(mpvId));
            dispatch(setLocale(locale));
            dispatch(setIsDesignAssistantTabEnabled(isDesignAssistantTabEnabled));
        });

        // this handles loading documents either via work or via query parameter
        if (!documentRevisionUrl && documentUrl) {
            documentRevisionUrl = documentUrl;
        }

        // strip off revisions since we save to the document url
        if (documentUrl && documentUrl.includes("/revision")) {
            const ind = documentUrl.indexOf("/revision");
            documentUrl = documentUrl.substring(0, ind);
        }

        const existingDesignDocumentPromise = documentRevisionUrl ? getDocument(documentRevisionUrl, auth) : undefined;

        let initialCalciferError;
        let calciferStudioConfig;
        try {
            calciferStudioConfig = await getStudioConfigFromCalciferV2(
                productKey,
                mpvId,
                productVersion,
                customerSelectedProductOptions,
                studioSelectedProductOptions,
                quantity,
                locale,
                workEntity && !mpvId ? workEntity.merchandising.mpvUrl : null,
                isFullBleed,
                template,
                documentRevisionUrl
            );
        } catch (e) {
            initialCalciferError = e;
        }

        const existingDesignDocument = await existingDesignDocumentPromise;

        // if we received an error from calcifer
        // or if the views differ greatly from the design document
        // then try running calcifer without providing a product key, falling back to the MPV.
        // in some cases product keys in the work may be invalid
        // Basically in this case, instead of showing the user an error, lets try one more thing to get this session to load
        if (
            mpvId &&
            (initialCalciferError ||
                (existingDesignDocument &&
                    calciferStudioConfig &&
                    documentDimensionsFailure(
                        existingDesignDocument,
                        MAX_PANEL_ADJUSTMENT_PERCENTAGE,
                        calciferStudioConfig.designViews.designViews
                    )))
        ) {
            try {
                const anotherCalciferConfig = await getStudioConfigFromCalciferV2(
                    undefined,
                    mpvId,
                    undefined,
                    customerSelectedProductOptions,
                    studioSelectedProductOptions,
                    quantity,
                    locale,
                    workEntity && !mpvId ? workEntity.merchandising.mpvUrl : null,
                    isFullBleed,
                    template,
                    documentRevisionUrl
                );
                calciferStudioConfig = anotherCalciferConfig;
            } catch (e) {
                // the fallback didn't work.  swallow this exception, we'll do one last check for locale mismatch after this
            }
        }

        // if we received an error or a warning when loading calcifer
        // either could be due to pricing issues
        if (initialCalciferError || (calciferStudioConfig?.warnings?.length ?? 0) > 0) {
            // assuming the locale is in the path, not the query string
            const workLocale =
                workEntity?.merchandising?.mpvUrl &&
                workEntity.merchandising.mpvUrl
                    .toLowerCase()
                    .split("/")
                    .find(section => /^[a-z]{2}-[a-z]{2}$/.test(section));
            // if the locale from the work does not match the current locale, assume the pricing issues were due to locale mismatch
            if (
                workEntity &&
                workLocale &&
                workLocale.toLowerCase() !== locale.toLowerCase() &&
                // don't get into weird redirect loops
                !document?.referrer?.includes("/studio")
            ) {
                const correctDomain = await getDomainForLocale(workLocale);
                newRelicWrapper.logPageAction("studio-loading-locale-redirect", {
                    newLocale: workLocale,
                    correctDomain,
                    editUrl: workEntity.design?.editUrl
                });
                if (correctDomain && workEntity.design?.editUrl) {
                    // get the current parameters so we don't lose them
                    const splitEditUrl = workEntity.design.editUrl.split("?");
                    const existingParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                    const workEditParams = qs.parse(splitEditUrl.length > 1 ? splitEditUrl[1] : "", {
                        ignoreQueryPrefix: true
                    });
                    const params = qs.stringify({
                        ...existingParams,
                        ...workEditParams
                    });
                    const newUrl = `https://${correctDomain}${splitEditUrl[0]}?${params}`;
                    if (isStaging() || isLocalHost() || isBranch()) {
                        // eslint-disable-next-line no-console
                        console.log(`If on prod, would have redirected to ${newUrl} because of locale mismatch`);
                    } else {
                        window.location.href = newUrl;
                        return null;
                    }
                }
            }
        }

        // If we dont have a calcifer config and we did not redirect to the correct locale throw the initial exception now
        if (initialCalciferError && !calciferStudioConfig) {
            throw initialCalciferError;
        }

        if (!calciferStudioConfig) {
            throw Error("Calcifer studio config not loaded");
        }

        ({
            mpvId,
            productVersion,
            productName,
            quantity,
            studioSelectedProductOptions,
            productKey,
            scenesConfiguration,
            views,
            designDocument,
            customerSelectedProductOptions,
            studioConfiguration,
            productGroupConfiguration,
            mcpSku,
            mcpVersion,
            designs
        } = {
            mpvId: calciferStudioConfig.mpvId,
            productVersion: calciferStudioConfig.productVersion,
            productName: calciferStudioConfig.productName,
            quantity: calciferStudioConfig.quantity,
            studioSelectedProductOptions: calciferStudioConfig.selectedOptions,
            productKey: calciferStudioConfig.productKey,
            scenesConfiguration: calciferStudioConfig.scenesConfiguration,
            views: calciferStudioConfig.designViews.designViews,
            designDocument: calciferStudioConfig.designDocument,
            customerSelectedProductOptions: calciferStudioConfig.customerSelectedOptions,
            studioConfiguration: calciferStudioConfig.studioConfiguration,
            productGroupConfiguration: calciferStudioConfig.productGroupConfiguration,
            mcpSku: calciferStudioConfig.mcpSku,
            mcpVersion: calciferStudioConfig.mcpVersion,
            designs: calciferStudioConfig.designs
        });

        if (existingDesignDocument) {
            // set the document in the initial easel config so we don't try to fetch it again later
            initialEaselConfig = {
                cimDocOverride: existingDesignDocument
            };

            const panelSources = existingDesignDocument.metadata?.documentSources?.panels as DSS.DocumentPanelSource[];
            const frontPanelId = existingDesignDocument.document.panels[0].id;
            const frontPanelSource = panelSources && panelSources.find(panelSource => panelSource.id === frontPanelId);

            workTemplateToken =
                frontPanelSource?.source === convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN)
                    ? frontPanelSource.data
                    : null;

            // determine whether or not the document in a work entity is a fullbleed document so we don't show the test
            // experience later in the flow
            isFullBleedDocument = frontPanelSource?.source === convertDocumentSourceType(DocumentSourceType.FULLBLEED);
        }

        if (isShowWarningsMode() && calciferStudioConfig.warnings?.length > 0) {
            Store.dispatch(setWarnings(calciferStudioConfig.warnings));
        }

        productDataLoadSuccessful = true;
        newRelicWrapper.logPageAction("studio-loading-initstate-finish");
    } catch (err) {
        if (await shouldUserLogIn(err, identity)) {
            newRelicWrapper.logPageAction("studio-loading-forcelogin-work");
            auth.signIn();
        } else if (throwError) {
            throw err;
        } else {
            handleError(err, ERROR_CODES.START_STUDIO, true);
        }
    } finally {
        initialState = {
            mpvId: mpvId || null,
            locale,
            productName: productName || "",
            quantity,
            quantityPerSize,
            studioSelectedProductOptions: studioSelectedProductOptions || customerSelectedProductOptions || {},
            customerSelectedProductOptions: customerSelectedProductOptions || {},
            productKey: productKey || "",
            scenesConfiguration,
            template: template || workTemplateToken || null,
            workId: workId || null,
            workRevisionId: workRevisionId || null,
            owner: owner || "",
            udsDocument: { url: documentUrl || null },
            documentRevisionUrl: documentRevisionUrl || null,
            productDataLoadAttempted: true,
            productDataLoadSuccessful,
            isFullBleed: isFullBleed || isFullBleedDocument,
            productVersion: productVersion || null,
            workName: workName || null,
            workLastSaved: workLastSaved || null,
            studioConfiguration: studioConfiguration || {},
            productGroupConfiguration: productGroupConfiguration || {},
            mcpSku: mcpSku || "",
            mcpVersion: mcpVersion || 0,
            hasDesigns: designs > 0 || false,
            isDesignAssistantTabEnabled
        };
        initialEaselConfig = Object.assign({}, initialEaselConfig, {
            designSpecification: { views, designDocument }
        });
    }

    return { initialState, initialEaselConfig: initialEaselConfig as CalciferEaselConfig };
};
