import { useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { mapDesignAttributeToProductOption, mapDesignAttributeValueToProductOptionValue } from "@shared/utils/Product";
import { useCartContext } from "@shared/features/Cart";
import { useGetDecorationTechnology } from "@shared/features/Product";
import { DecorationTechnology } from "@shared/utils/CimDoc";
import { useCompatibleFlexibilityDesignAttributes } from "../common/hooks/useCompatibleFlexibilityDesignAttributes";
import { FlexibilityDesignAttributes } from "../constants";
import { useDesignVariations } from "../common/hooks/useDesignVariations";

export const useSizeCompatibleFlexibilityDesignAttributes = () => {
    const { isItemInCart } = useCartContext();
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const designAttributes = useCompatibleFlexibilityDesignAttributes(FlexibilityDesignAttributes.Size);
    const productOptionName = mapDesignAttributeToProductOption(
        designAttributeMappings,
        FlexibilityDesignAttributes.Size
    );
    const { designVariations } = useDesignVariations(productOptionName);
    const { showChangeSize } = useAppSelector(state => state.studioConfiguration);
    const template = useAppSelector(state => state.template);
    const decorationTechnology = useGetDecorationTechnology();

    const sizeAttributes = useMemo(() => {
        if (isItemInCart || !showChangeSize || decorationTechnology !== DecorationTechnology.OffsetOrDigital) {
            return [];
        }
        if (template) {
            if (designVariations.length < 1) {
                return [];
            }

            const filteredDesignOptions =
                designAttributes?.filter(designAttribute => {
                    const productOptionValue = mapDesignAttributeValueToProductOptionValue(
                        designAttributeMappings,
                        FlexibilityDesignAttributes.Size,
                        designAttribute
                    );
                    return designVariations.find(
                        (designVariation: any) =>
                            designVariation.fullProductOptions[productOptionName] === productOptionValue
                    );
                }) || [];
            return filteredDesignOptions;
        }
        if (!designAttributes || designAttributes.length <= 1) {
            return [];
        }
        return designAttributes;
    }, [
        decorationTechnology,
        designAttributeMappings,
        designAttributes,
        designVariations,
        isItemInCart,
        productOptionName,
        showChangeSize,
        template
    ]);
    return sizeAttributes;
};
