/* eslint-disable max-len */
import React from "react";

export function TemplateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M13.3805 14H2.61948C2.26978 14 2 13.7302 2 13.3905V2.61948C2 2.27977 2.27977 2 2.61948 2H13.3805C13.7202 2 14 2.27977 14 2.61948V13.3805C14 13.7302 13.7202 14 13.3805 14Z"
                stroke="currentColor"
                strokeMiterlimit="10"
            />
            <path d="M8 2V14" stroke="currentColor" strokeMiterlimit="10" />
            <path d="M14 8H8" stroke="currentColor" strokeMiterlimit="10" />
        </svg>
    );
}
TemplateIcon.displayName = "TemplateIcon";
