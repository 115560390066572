export interface Hsl {
    h: number;
    s: number;
    l: number;
}

export interface Rgb {
    r: number;
    g: number;
    b: number;
}

export interface Rgba extends Rgb {
    a?: number;
}

export enum ColorIconType {
    TextColorIcon = "TextColorIcon",
    StrokeColorIcon = "StrokeColorIcon",
    ShapeFillColorIcon = "ShapeFillColorIcon",
    TableColorIcon = "TableColorIcon",
    BucketColorIcon = "BucketColorIcon"
}
