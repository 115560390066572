import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { PreviewButton } from "./PreviewButton";

export function PreviewButtonWithSkeleton({ className }: { className?: string }) {
    const isSmall = useStudioLayout();

    return (
        <StudioIsInteractiveSwitch>
            <PreviewButton className={className} />
            <Skeleton height={isSmall ? "12vw" : 50} style={{ borderRadius: "25px" }} />
        </StudioIsInteractiveSwitch>
    );
}

PreviewButtonWithSkeleton.displayName = "PreviewButtonWithSkeleton";
