import React, { useCallback } from "react";

/**
 * Provide a callback for keyboard handling
 * Default usage is intended for when you want to have
 * "Enter" or " " invoke the passed in onClick handler
 * @param action Handler to invoke
 * @param keys Keyboard keys that should trigger the action
 */
export function useOnKeyDown(action: () => void, keys = ["Enter", " "]) {
    return useCallback(
        (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
            const { key } = e;

            if (key && keys.includes(key)) {
                action();
                e.preventDefault();
                e.stopPropagation();
            }
        },
        [action, keys]
    );
}
