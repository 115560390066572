import React, { forwardRef, Ref } from "react";
import { MobileDockedTools } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { isDebugMode } from "@shared/utils/Debug";
import { GlobalToolbar } from "../Redesign/GlobalToolbar";
import StudioFiveDebugTools from "../Debug/StudioFiveDebugTools";

export const EaselContentsTopContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const { isSmall } = useStudioLayout();

    return (
        <div ref={ref} className="top-container">
            {isDebugMode() && <StudioFiveDebugTools />}
            <GlobalToolbar />
            {isSmall && <MobileDockedTools />}
        </div>
    );
});

EaselContentsTopContainer.displayName = "EaselContentsTopContainer";
