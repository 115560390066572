import React, { useEffect, useRef, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import { useActiveCanvas } from "@designer-suite";
import classNames from "classnames";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import { useIsMailingPostCard } from "@shared/features/MailingServices";
import { useMousePositionOnUSPSArea } from "../../hooks/useMousePositionOnUSPSArea";
import { mailingServicesMessages } from "./messages";
import "./mailingServicesUSPSTooltip.scss";

export const MailingServicesUSPSTooltip = ({ canvasWidth }: { canvasWidth: number }) => {
    const { t } = useTranslationSSR();
    const canvas = useActiveCanvas();
    const {
        mousePosition: { offsetX, offsetY },
        isMouseOnUSPSArea
    } = useMousePositionOnUSPSArea();
    const ref = useRef<HTMLDivElement>(null);
    const isMailingPostCard = useIsMailingPostCard();
    const [toolTipWidth, setToolTipWidth] = useState(0);
    const [addAdditionalSpaceFromCanvas, setAddAdditionalSpaceFromCanvas] = useState(0);

    useEffect(() => {
        if (!ref.current || !canvas || !canvasWidth) {
            return;
        }
        setToolTipWidth(ref.current.offsetWidth);
        const width = canvas.mmDimensions.width * canvas._canvasViewModel.get("zoomFactor");
        const addAdditionalSpaceFromCanvas = (canvasWidth - width) / 2;
        setAddAdditionalSpaceFromCanvas(addAdditionalSpaceFromCanvas);
    }, [ref, canvas, canvasWidth]);

    return (
        <>
            {isMailingPostCard && (
                <DesktopExperience>
                    <div
                        ref={ref}
                        className={classNames("mailing-services-tooltip", "swan-p-4", {
                            "show-tooltip": isMouseOnUSPSArea
                        })}
                        style={{ top: offsetY, right: offsetX + addAdditionalSpaceFromCanvas - toolTipWidth }}
                    >
                        <Typography>{t(mailingServicesMessages.USPSOverlayTooltip.id)}</Typography>
                    </div>
                </DesktopExperience>
            )}
        </>
    );
};

MailingServicesUSPSTooltip.displayName = "MailingServicesUSPSTooltip";
