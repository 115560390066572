import React from "react";
import { Button } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useIsEmbroidery } from "@shared/features/Embroidery";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { addWordArt } from "./AddItemReference";
import "./addWordArtButton.scss";

const messages = defineMessages({
    addWordArt: {
        id: "easel.ui.addWordArtButton.buttonText",
        defaultMessage: "Add Word Art",
        description: {
            note: "Button for user to add word art, which is stylized text"
        }
    },
    placeholderText: {
        id: "easel.ui.addItemReference.addWordArt.placeholderText",
        defaultMessage: "Type text here",
        description: {
            note: "Prompt for user to enter text"
        }
    }
});

export function AddWordArtButton(props: React.ComponentProps<typeof Button>) {
    const { skin, ...rest } = props;
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const isEmbroidery = useIsEmbroidery();
    const handleClick = async () => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_WORD_ART_FIELD_ADD, label: "WordArtField" });
        addWordArt(t(messages.placeholderText.id), { fontFamily: isEmbroidery ? "Arial" : "Arimo" });
    };

    return (
        <Button onClick={handleClick} id="easel-add-word-art" width="full-width" skin={skin || "secondary"} {...rest}>
            {t(messages.addWordArt.id)}
        </Button>
    );
}
AddWordArtButton.displayName = "AddWordArtButton";
