import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector, useAppDispatch, setDefaultState, setIsProductSwitched } from "@shared/redux";
import type { CalciferEaselConfig } from "@shared/utils/Calcifer";
import { newRelicWrapper } from "@shared/utils/Errors";
import { useState, useEffect } from "react";
import { initializeStudioConfigV2 } from "./initializeStudioConfigV2";

export function useInitializeProductData() {
    const isProductSwitched = useAppSelector(state => state.isProductSwitched);
    const productDataLoadAttempted = useAppSelector(state => state.productDataLoadAttempted);
    const locale = useAppSelector(state => state.locale);
    const dispatch = useAppDispatch();

    const [callingCalcifer, setCallingCalcifer] = useState(false);
    const [calciferEaselConfig, setCalciferEaselConfig] = useState<CalciferEaselConfig>();

    const { isIdentityInitialized, identity, auth } = useIdentityContext();

    useEffect(() => {
        // calcifer requires auth to be initialized
        if (!isIdentityInitialized) {
            return;
        }
        async function initProductDataLoad() {
            const studioConfig = await initializeStudioConfigV2({
                identity,
                auth,
                locale,
                dispatch
            });
            if (studioConfig) {
                dispatch(
                    setDefaultState({
                        ...studioConfig.initialState
                    })
                );
                setCalciferEaselConfig(studioConfig.initialEaselConfig);
                newRelicWrapper.updateCustomAttributes(studioConfig.initialState);
            }
        }

        if ((!productDataLoadAttempted && !callingCalcifer) || isProductSwitched) {
            setCallingCalcifer(true);
            initProductDataLoad();
            dispatch(setIsProductSwitched(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIdentityInitialized, dispatch, auth, locale, productDataLoadAttempted, isProductSwitched]);

    return calciferEaselConfig;
}
