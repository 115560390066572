import React from "react";
import { Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { CounterIcon } from "@shared/features/StudioChrome";
import * as styles from "./CounterButton.module.scss";

const messages = defineMessages({
    countButtonLabel: {
        id: "easel.components.counter.additionalSidesLabel",
        defaultMessage: "[[count]] additional sides",
        description: {
            note: "Aria label for a button to choose another canvas to edit"
        }
    }
});
interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
    count?: number;
}

export function CounterButton(props: Props) {
    const { onClick, count = 3 } = props;
    const { t } = useTranslationSSR();

    return (
        <Typography fontSize="-1">
            <button
                className={styles.sideCountButton}
                onClick={onClick}
                aria-label={t(messages.countButtonLabel.id, { count })}
            >
                <div className={styles.sideCountLabel}>{count}</div>
                <div className={styles.sideCountIcon}>
                    <CounterIcon />
                </div>
            </button>
        </Typography>
    );
}

CounterButton.displayName = "CounterButton";
