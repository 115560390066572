import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStockImages } from "@design-stack-vista/image-library-react";
import { Typography, SelectionSet, SelectionSetInput, SelectionSetLabel } from "@vp/swan";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";

export enum People {
    Any = "any",
    PeopleOnly = "peopleOnly",
    ExcludePeople = "excludePeople",
    NoFaces = "noFaces"
}

interface Props {
    performSearchForFilter: (filterOptions: string) => void;
}

export const ImageSearchPeopleFilter = ({ performSearchForFilter }: Props) => {
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const { currentFilterOptions } = useStockImages();
    const filterOptions = JSON.parse(currentFilterOptions || "{}");
    const initialPeople = filterOptions?.people || People.Any;

    const handlePeopleSelection = (selection: string) => {
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_FILTER_SELECT_PEOPLE,
            extraData: () => ({
                selection
            })
        });
        if (selection === People.Any && filterOptions?.people) {
            delete filterOptions.people;
        } else {
            filterOptions.people = selection;
        }
        if (Object.keys(filterOptions).length !== 0) {
            performSearchForFilter(JSON.stringify(filterOptions));
        } else {
            performSearchForFilter("");
        }
    };

    return (
        <>
            <Typography fontSize="1" fontWeight="bold" marginBottom={2} marginTop={3}>
                {`${t(imageLibraryPanelMessages.peopleHeading.id)}`}
            </Typography>
            <SelectionSet
                skin="simple-column"
                variant="single-select"
                selectedValue={initialPeople}
                defaultSelectedValue={initialPeople}
                onSelectedValueChange={handlePeopleSelection}
            >
                <SelectionSetInput value={People.Any}>
                    <SelectionSetLabel m={1}>
                        <Typography fontSize="1" marginLeft={1}>
                            {`${t(imageLibraryPanelMessages.peopleOptionAny.id)}`}
                        </Typography>
                    </SelectionSetLabel>
                </SelectionSetInput>
                <SelectionSetInput value={People.PeopleOnly}>
                    <SelectionSetLabel m={1}>
                        <Typography fontSize="1" marginLeft={1}>
                            {`${t(imageLibraryPanelMessages.peopleOptionPeopleOnly.id)}`}
                        </Typography>
                    </SelectionSetLabel>
                </SelectionSetInput>
                <SelectionSetInput value={People.ExcludePeople}>
                    <SelectionSetLabel m={1}>
                        <Typography fontSize="1" marginLeft={1}>
                            {`${t(imageLibraryPanelMessages.peopleOptionExcludePeople.id)}`}
                        </Typography>
                    </SelectionSetLabel>
                </SelectionSetInput>
                <SelectionSetInput value={People.NoFaces}>
                    <SelectionSetLabel m={1}>
                        <Typography fontSize="1" marginLeft={1}>
                            {`${t(imageLibraryPanelMessages.peopleOptionNoFace.id)}`}
                        </Typography>
                    </SelectionSetLabel>
                </SelectionSetInput>
            </SelectionSet>
        </>
    );
};
ImageSearchPeopleFilter.displayName = "ImageSearchPeopleFilter";
