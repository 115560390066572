import React from "react";
import { Button, SearchIcon, SearchResetIcon } from "@shared/features/StudioChrome";

import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { TextInput, TextInputWithButtonInset } from "@vp/swan";
import * as styles from "./IconSearchBar.module.scss";

type Props = {
    className?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onClearSearch: () => void;
    onSearch: () => void;
    searchTerm: string;
};

const messages = defineMessages({
    studioPanelShapesIconsSearchPlaceholder: {
        id: "studio.components.panels.studioPanels.shapesPanelIconsSearchPlaceholder",
        defaultMessage: "Search over 3 million icons",
        description: {
            note: "Shapes and icons panel search field placeholder text."
        }
    },
    iconsSearchResetButtonIconLabel: {
        id: "studio.components.panels.studioPanels.iconsSearchResetButtonIconLabel",
        defaultMessage: "Clear Search",
        description: {
            note: "Label for shapes and icons panel reset search field"
        }
    },
    iconsSearchButtonIconLabel: {
        id: "studio.components.panels.studioPanels.iconsSearchButtonIconLabel",
        defaultMessage: "Icons Search",
        description: {
            note: "Label for shapes and icons panel search field"
        }
    }
});

export const IconSearchBar = (props: Props) => {
    const { className, onChange, onClearSearch, onSearch, searchTerm } = props;
    const { t } = useTranslationSSR();

    return (
        <TextInputWithButtonInset skin="searchbar" mb={1}>
            <TextInput
                value={searchTerm}
                onChange={onChange}
                placeholder={t(messages.studioPanelShapesIconsSearchPlaceholder.id)}
                title={t(messages.studioPanelShapesIconsSearchPlaceholder.id)}
                onKeyPress={(event: { key: string }) => {
                    if (event.key === "Enter") onSearch();
                }}
                className={styles.searchInput}
            />
            {searchTerm && (
                <Button
                    aria-label={t(messages.iconsSearchResetButtonIconLabel.id)}
                    onClick={onClearSearch}
                    className={className}
                >
                    <SearchResetIcon className={styles.searchResetIcon} />
                </Button>
            )}
            <Button
                aria-label={t(messages.iconsSearchButtonIconLabel.id)}
                onClick={onSearch}
                className={styles.searchIconButton}
            >
                <SearchIcon />
            </Button>
        </TextInputWithButtonInset>
    );
};

IconSearchBar.displayName = "IconSearchBar";
