import { useCallback, useEffect, useState, useRef } from "react";
import debounce from "lodash/debounce";

const rates = [300, 200, 100];
let rate = rates[0];

const calculateInterval = (currentCount: number) => {
    const rateChangeThresholdMS = 4000;
    const tier2 = rateChangeThresholdMS / rates[0];
    const tier3 = tier2 + rateChangeThresholdMS / rates[1];

    if (currentCount >= tier3) {
        // eslint-disable-next-line prefer-destructuring
        rate = rates[2];
    } else if (currentCount >= tier2) {
        // eslint-disable-next-line prefer-destructuring
        rate = rates[1];
    } else {
        // eslint-disable-next-line prefer-destructuring
        rate = rates[0];
    }
};

interface UseFontSizeSelectorConfig {
    /** The smallest font size allowed */
    min: number;

    /** The largest font size allowed */
    max: number;

    onChange: (value: number) => void;

    /**
     * The font size currently set by the component using this selector
     */
    initialFontSize: number;

    onFontSizeUp?: () => void;

    onFontSizeDown?: () => void;
}
export function useFontSizeSelector(config: UseFontSizeSelectorConfig) {
    const { onChange, min, max, initialFontSize, onFontSizeUp, onFontSizeDown } = config;

    const intervalRef = useRef<null | number>(null);
    const [, setCurrentSize] = useState(initialFontSize);
    const [, setCounter] = useState(0);

    useEffect(() => {
        setCurrentSize(initialFontSize);
    }, [initialFontSize]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(
        debounce((value: number) => {
            onChange(value);
            setCounter(count => {
                calculateInterval(count);
                return count + 1;
            });
        }, 100),
        [onChange]
    );

    const timerUpdateFontSize = useCallback(
        (amount: number) => {
            setCurrentSize((prevCounter: number) => {
                if (prevCounter + amount >= min && prevCounter + amount <= max) {
                    const newSize = prevCounter + amount;
                    debouncedOnChange(newSize);
                    return newSize;
                }
                return prevCounter;
            });
            intervalRef.current = window.setTimeout(() => timerUpdateFontSize(amount), rate);
        },
        [debouncedOnChange, max, min]
    );

    const startCounter = useCallback(
        (amount: number) => {
            if (intervalRef.current) return;

            timerUpdateFontSize(amount);
        },
        [timerUpdateFontSize]
    );

    const stopCounter = useCallback(
        (amount: number) => {
            if (intervalRef.current) {
                amount > 0 ? onFontSizeUp?.() : onFontSizeDown?.();
                clearTimeout(intervalRef.current);
                intervalRef.current = null;
            }
            // eslint-disable-next-line prefer-destructuring
            rate = rates[0];
            setCounter(0);
        },
        [onFontSizeUp, onFontSizeDown]
    );

    return {
        startCounter,
        stopCounter
    };
}
