import React, { useEffect, useRef } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Status, useIcons } from "@design-stack-vista/image-library-react";
import { useTrackEvents } from "@shared/features/Tracking";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { STUDIO_TRACKING_EVENTS, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { useDecorationTechnology } from "@utilities";
import { DEFAULT_SEARCH_TERM } from "@shared/features/UploadsAndAssets";
import { HorizontalDivider } from "@shared/features/StudioChrome";

import { IconsPaginator, IconsPanelContainer, useIconsSearch } from "@shared/features/IconAssetLibrary";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { IconSelectionSet } from "./IconSelectionSet";
import ShapesPanel from "../ShapesPanel";
import { useElementsPanel } from "../../../../xerox/features/Elements/ElementsPanelProvider";

const ICONS_PAGE_SIZE = 72;

export const iconsPanelMessages = defineMessages({
    studioPanelImagesIconsSearchPlaceholder: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsSearchPlaceholder",
        defaultMessage: "Search icons"
    },
    studioPanelShapesIconsSearchPlaceholder: {
        id: "studio.components.panels.studioPanels.shapesPanelIconsSearchPlaceholder",
        defaultMessage: "Search over 3 million icons"
    },
    studioPanelImagesIconsError: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsErrorMessage",
        defaultMessage: `We couldn't load the icons - please try again.`
    },
    studioPanelImagesIconsNoResults: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsNoResultsMessage",
        defaultMessage: "Sorry, we didn’t find any relevant icons. Please try another keyword."
    },
    studioPanelImagesIconsNoMoreIcon: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsNoMoreIconsMessage",
        defaultMessage: "Sorry, there are no more icons."
    },
    studioPanelImagesIconsLoaderLabel: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsLoader",
        defaultMessage: "loading"
    },
    studioPanelImagesIconsSelectionAriaLabel: {
        id: "studio.components.panels.studioPanels.imagesPanelIconsSelectionAriaLabel",
        defaultMessage: "Select an icon"
    }
});

type IconsPanelProps = {
    /**
     * A callback to be executed when an upload is added to the canvas
     * */
    onUploadAdded?: () => void;

    /**
     * Custom Classname
     * */
    className?: string;
};

export default function IconsPanel({ className, onUploadAdded }: IconsPanelProps) {
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const { imageResults, activePage, status, performSearch } = useIcons();
    const { searchTerm } = useIconsSearch();
    const startTime = useRef<number | null>(null);
    const decorationTechnology = useDecorationTechnology();
    const isEmbroidery = decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY;
    const { currentActiveDialog } = useActiveDialog();
    const { currentElementsPanelSearchTerm, icons } = useElementsPanel();
    const isElementsPanel = currentActiveDialog === DialogType.Elements;
    const searchTermToUse = isElementsPanel ? currentElementsPanelSearchTerm : searchTerm;

    /* used for tracking user interaction events */
    useEffect(() => {
        if (activePage > 1 && startTime.current !== null) {
            const endTime = performance.now();
            const timeElapsed = endTime - startTime.current;
            fireUserInteractionTrackingEvent("Load New Icons Page", timeElapsed);
            startTime.current = null;
        }
    }, [activePage]);

    const handlePreviousClick = () => {
        if (activePage > 1) {
            trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ICON_RESULTS_PREVIOUS_PAGE, label: "Image" });
            performSearch(searchTermToUse || DEFAULT_SEARCH_TERM, activePage - 1);
        }
    };

    const handleNextClick = () => {
        if (status !== Status.Error && status !== Status.Loading) {
            startTime.current = performance.now();
            trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ICON_RESULTS_NEXT_PAGE, label: "Image" });
            performSearch(searchTermToUse || DEFAULT_SEARCH_TERM, activePage + 1);
        }
    };

    // hide pagination for Elements panel when there are no search results
    const hidePaginationForElements =
        isElementsPanel && icons.length === 0 && currentElementsPanelSearchTerm.length > 0;

    return (
        <IconsPanelContainer searchTerm={searchTerm} className={className}>
            {!isEmbroidery && !searchTerm && !isElementsPanel && !currentElementsPanelSearchTerm && (
                <>
                    <ShapesPanel />
                    <HorizontalDivider className="shapes-icon-divider" />
                </>
            )}
            <IconSelectionSet
                icons={imageResults}
                searchStatus={status}
                showNoMoreIconsNotice={activePage > 1 && imageResults.length === 0}
                ariaLabel={t(iconsPanelMessages.studioPanelImagesIconsSelectionAriaLabel.id)}
                onDone={onUploadAdded}
                activePage={activePage}
            />
            {!hidePaginationForElements && (
                <IconsPaginator
                    selectedPage={activePage}
                    handlePreviousClick={handlePreviousClick}
                    handleNextClick={handleNextClick}
                    noMoreResults={status === "error" || status === "loading" || imageResults?.length < ICONS_PAGE_SIZE}
                />
            )}
        </IconsPanelContainer>
    );
}

IconsPanel.displayName = "IconsPanel";
