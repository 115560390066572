import React from "react";
import { generateFontIntervals } from "@design-stack-ct/utility-core";
import { getTrackingDataForSelection } from "@utilities";
import { useDesigner, useSelection } from "@designer-suite";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { FontSizeDropdown } from "@shared/features/ContextualToolbar";

const NUMBER_OF_FONT_SIZES = 24; // the total number of font sizes to include in the dropdown
interface Props {
    minFontSize: number;
    maxFontSize: number;
    onChange: (value: number) => void;
    className?: string;
}

export function FontSizeDropdownStudio5(props: Props) {
    const { minFontSize, maxFontSize, onChange, className } = props;
    const designer = useDesigner();
    const selection = useSelection();

    const handleChange = (value: number) => {
        designer?.eventBus.trigger(
            STUDIO_TRACKING_EVENTS.SIZE_DROPDOWN_SELECTION,
            getTrackingDataForSelection(selection)
        );
        onChange(value);
    };

    const fontSizes = generateFontIntervals(NUMBER_OF_FONT_SIZES, minFontSize, maxFontSize);

    return <FontSizeDropdown fontSizes={fontSizes} className={className} onChange={handleChange} />;
}
FontSizeDropdownStudio5.displayName = "FontSizeDropdownStudio5";
