/* eslint-disable max-len */
import React from "react";
import { withPrefix } from "gatsby";

export function TeamsGuideIconStep3(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 161 187" fill="none" {...props}>
            <image xlinkHref={`${ASSET_PREFIX + withPrefix(`/teamsImages/step3.png`)}`} height="187" />
        </svg>
    );
}
TeamsGuideIconStep3.displayName = "TeamsGuideIconStep3";
