import { useActiveFlexibilityOptions, FlexibilityDesignAttributes } from "@shared/features/Flexibility";
import { ResizeDesignIcon } from "@shared/features/StudioChrome/Icons/ResizeDesignIcon";
import { useAppSelector } from "@shared/redux";
import { Button } from "@vp/swan";
import classNames from "classnames";
import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";

const messages = defineMessages({
    resizeButton: {
        id: "studio.features.resize.buttonText",
        defaultMessage: "Resize",
        description: {
            note: "Text to be used for resize button"
        }
    }
});

type ResizeButtonProps = {
    skin?: "inline" | "standard";
    className?: "string";
};

export const ResizeButton = ({ skin = "standard", className }: ResizeButtonProps) => {
    const { setDisplayedDesignAttributeName, isDesignAttributeActive } = useActiveFlexibilityOptions();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { t } = useTranslationSSR();
    const isSizeAttributeActive = isDesignAttributeActive(FlexibilityDesignAttributes.Size);

    const handleClick = () => {
        setDisplayedDesignAttributeName(FlexibilityDesignAttributes.Size);
    };

    // If we cann't resize, then hide the button
    if (!isSizeAttributeActive) {
        return null;
    }

    return skin === "inline" ? (
        <Button className={classNames(className)} onClick={handleClick} skin={"unstyled"} disabled={!easelLoaded}>
            <ResizeDesignIcon aria-hidden={true} />
            {t(messages.resizeButton.id)}
        </Button>
    ) : (
        <Button
            className={classNames(className)}
            onClick={() => {
                // TODO-xerox-rollout
                // Only allow clicking resize if easel is already loaded
                // Don't show as disabled because it causes a layout shift
                // Ideally, the resize popup should show a loading spinner and handle the case of easel not loaded
                // Refactor this before rolling out
                easelLoaded && handleClick();
            }}
            skin="secondary"
            size="mini"
            paddingLeft="6"
            paddingRight="6"
            marginLeft="2"
            marginRight="2"
        >
            {t(messages.resizeButton.id)}
        </Button>
    );
};

ResizeButton.displayName = "ResizeButton";
