import { Button, ControlIconToIconMap, Icon } from "@vp/swan";
import React, { useCallback } from "react";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setIsPremiumFinishModalOpen } from "@shared/redux";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { useActiveFlexibilityOptions, FlexibilityDesignAttributes } from "@shared/features/Flexibility";

export const PremiumFinishBackButton = () => {
    const premiumFinishModalOpenContext = useAppSelector(state => state.premiumFinishModalOpenContext);
    const { setCurrentActiveDialog } = useActiveDialog();
    const dispatch = useAppDispatch();
    const { setDisplayedDesignAttributeName } = useActiveFlexibilityOptions();

    const goBackToFinishOptions = useCallback(() => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_BACK_TO_CHANGE_PREMIUM_FINISH_MODAL,
            label: "clicked back to return to the change product options modal"
        } as any);
        dispatch(setIsPremiumFinishModalOpen({ isPremiumFinishModalOpen: false }));
        setCurrentActiveDialog(DialogType.None);
        setDisplayedDesignAttributeName(FlexibilityDesignAttributes.PremiumFinish);
    }, [dispatch, setCurrentActiveDialog, setDisplayedDesignAttributeName]);

    if (!premiumFinishModalOpenContext) {
        return null;
    }

    return (
        <Button className="premium-finish-back-button" onClick={goBackToFinishOptions} iconPosition="left" size="mini">
            {/* @ts-ignore */}
            <Icon {...ControlIconToIconMap["arrow-l"]} />
            Back to finish options
        </Button>
    );
};

PremiumFinishBackButton.displayName = "PremiumFinishBackButton";
