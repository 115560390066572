import { useCallback } from "react";
import { useDesigner } from "@easel";
import { useAppDispatch, setShowValidations, showStudioErrorDialog } from "@shared/redux";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { getActiveCanvasId } from "../utilities/canvasUtilities";
import { useValidations } from "./Validation/ValidationProvider";
import { useDesignErrors } from "../hooks/useDesignErrors";
import { checkIssuesInCanvasById, getFirstCanvasOrdinalWithIssues } from "./Validation/ValidationUtils";
import { useTriggerCanvasChange } from "../hooks/useTriggerCanvasChange";

export function useCheckValidationsForNextStep(buttonRef?: React.MutableRefObject<any>) {
    const { isSmartValidationsEnabled, elementToFocusOnCloseRef } = useSmartValidations();
    const { validationCount, containsErrors, hasPanelBeenOpen, canvasValidations } = useValidations();
    const designer = useDesigner();
    const triggerCanvasChange = useTriggerCanvasChange();
    const designRequirements = useDesignRequirementsContext();
    const dispatch = useAppDispatch();
    const errors = useDesignErrors();

    return useCallback(() => {
        // Check if SmartValidationPanel should be opened
        // - If there is any warnings and panel has not been open previously
        // - If there is any errors
        if (isSmartValidationsEnabled && ((!hasPanelBeenOpen && validationCount) || containsErrors)) {
            elementToFocusOnCloseRef.current = buttonRef?.current;

            // If active canvas does not have issues open the first panel with issues
            if (!checkIssuesInCanvasById(canvasValidations, getActiveCanvasId())) {
                const canvasOrdinal = getFirstCanvasOrdinalWithIssues(designer);
                if (canvasOrdinal && designRequirements) {
                    triggerCanvasChange(designRequirements?.panels[canvasOrdinal - 1].name);
                }
            }
            dispatch(setShowValidations({ showPanel: true, isContinueToCheckout: true }));
            return false;
        }
        if (errors.length) {
            dispatch(showStudioErrorDialog(true));
            return false;
        }
        return true;
    }, [
        buttonRef,
        canvasValidations,
        containsErrors,
        designRequirements,
        designer,
        dispatch,
        elementToFocusOnCloseRef,
        errors.length,
        hasPanelBeenOpen,
        isSmartValidationsEnabled,
        triggerCanvasChange,
        validationCount
    ]);
}
