import { setCurrentTemplateColor, useAppDispatch } from "@shared/redux";
import { useEffect } from "react";
import { useTemplateColorVariations } from "../Templates";

export function useGetCurrentTemplateColor() {
    const dispatch = useAppDispatch();
    const { colorSwatches, activeTemplate } = useTemplateColorVariations();

    useEffect(() => {
        if (colorSwatches?.length === 0 || !activeTemplate) {
            return;
        }
        const currentColor = colorSwatches.find(swatch => swatch.designId === activeTemplate)?.color;
        dispatch(setCurrentTemplateColor(currentColor));
    }, [activeTemplate, colorSwatches, dispatch]);
}
