import { newRelicWrapper } from "@shared/utils/Errors";
import { isImageUnreplacedPlaceholder } from "@utilities";
import type { Designer } from "src/easel/designer-suite/@types/designer";

// when studio receives documents where user uploads have been added by something other than designer
// the designer metadata such as file type and analysis is missing
// this prevents us from preforming crispify, detecting resolution issues, changes recolorization behavior, etc
// this function backfills that information for any user uploads missing that data
export async function backfillUploadMetadata(designer: Designer) {
    const itemPromises: Promise<void>[] = [];
    designer.api.design.canvases.forEach(canvas => {
        itemPromises.push(
            ...canvas.items
                .filter(
                    item =>
                        // only care about images
                        item.itemType === "IMAGE" &&
                        // don't care about unreplaced image placeholders
                        !isImageUnreplacedPlaceholder(item) &&
                        // definitely don't show anything for restricted items
                        !item._itemViewModel.model.get("restricted") &&
                        // if it already has a mimetype we're probably fine
                        !(item as ImageItem).mimeType &&
                        // bit of a hack, but we only care about uploads on the new platform
                        // those should be in the designtechprod tenant
                        (item as ImageItem).previewUrl?.toLowerCase().includes("tenant=designtechprod")
                )
                .map(async (image: ImageItem) => {
                    // fetch metadata for upload
                    try {
                        const imageURL = image.previewUrl.replace(/\/(original|preview)/, "/metadata");
                        const response = await fetch(imageURL);
                        const metadata = await response.json();
                        // set the file type so we can crispify etc
                        image._itemViewModel.model.set(
                            "fileType",
                            metadata.originalFileContentType || metadata.previewFileContentType
                        );
                        // set the analysis so that recolorization works as expected
                        image._itemViewModel.model.set("analysis", {
                            isLogo: metadata.analysisIsLogo,
                            isPhoto: metadata.analysisIsPhoto,
                            isVector: metadata.analysisIsVector,
                            lineartness: metadata.analysisLineartness
                        });
                        // set the original url - IPA uses this for stuff like background removal candidate
                        image._itemViewModel.model.set(
                            "originalUrl",
                            image.previewUrl.replace(/\/preview/, "/original")
                        );
                        // now that we've provided the above information, refresh whether this is a background removal candidate
                        image._itemViewModel
                            .canProcessApply("backgroundRemove")
                            .then((isBackgroundRemoveCandidate: boolean) =>
                                image._itemViewModel.set({ isBackgroundRemoveCandidate })
                            );

                        // to get an idea of how often we actually trigger this to backfill info
                        newRelicWrapper.logPageAction("upload-metadata-backfill-success", {
                            previewUrl: image.previewUrl
                        });
                    } catch (e) {
                        // just log, don't throw.  this error isn't a blocker in studio
                        newRelicWrapper.logPageAction("upload-metadata-backfill-failed", {
                            previewUrl: image.previewUrl,
                            ...e
                        });
                    }
                })
        );
    });
    return Promise.all(itemPromises);
}
