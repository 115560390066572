import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button, Typography } from "@vp/swan";
import { useSave, type SaveDesignWithSaveDocumentOmitted } from "@shared/features/Save";
import { FolderIcon } from "@shared/features/StudioChrome";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setTrackModalTransitionEvent, setShowMyProjects } from "@shared/redux";
import { DialogType, useActiveDialog } from "../ActiveDialog";
import { messages } from "./messages";
import * as styles from "./MyProjectsButton.module.scss";

interface Props {
    className?: string;
    hideIcon?: boolean;
    enableModalTransitionEvent?: boolean;
    onPanelOpen?: () => void;
    saveDesign: SaveDesignWithSaveDocumentOmitted;
}

export function MyProjectsButton({
    className,
    hideIcon,
    enableModalTransitionEvent = false,
    saveDesign,
    onPanelOpen
}: Props) {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const loginReason = useAppSelector(state => state.loginReason);
    const showMyProjects = useAppSelector(state => state.showMyProjects.show);
    const trackModalTransitionEvent = useAppSelector(state => state.trackModalTransitionEvent);
    const startTimeRef = useRef<number>(0);
    const setStartTime = (startTime: number) => {
        startTimeRef.current = startTime;
    };
    const { setCurrentActiveDialog } = useActiveDialog();
    const { isXerox } = useXerox();

    const save = useSave({
        onSaveCallback: () => {
            dispatch(setShowMyProjects({ show: true, startTime: startTimeRef.current }));
            dispatch(
                setTrackModalTransitionEvent({
                    ...trackModalTransitionEvent,
                    isEventActive: enableModalTransitionEvent
                })
            );
        },
        hideSaveToast: true,
        allowAnonymousUser: true,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_MY_PROJECTS,
        saveDesign
    });

    useEffect(() => {
        if (loginReason === "myProjectsPanel" && !showMyProjects) {
            dispatch(setShowMyProjects({ show: true }));
            dispatch(
                setTrackModalTransitionEvent({
                    ...trackModalTransitionEvent,
                    isEventActive: enableModalTransitionEvent
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginReason]);

    const handleOpen = () => {
        isXerox && setCurrentActiveDialog(DialogType.MyProjects);
        onPanelOpen && onPanelOpen();
        dispatch(
            setTrackModalTransitionEvent({
                ...trackModalTransitionEvent,
                isEventActive: enableModalTransitionEvent
            })
        );
        const startTime = performance.now();
        setStartTime(startTime);
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MY_PROJECTS,
            label: "My Projects Button"
        });
        save();
    };

    return (
        <Button skin="unstyled" className={classNames(styles.button, className)} onClick={handleOpen}>
            <span className={styles.buttonText}>
                {!hideIcon ? <FolderIcon aria-hidden={true} /> : null}
                <Typography className="button-label">{t(messages.myProjectsLink.id)}</Typography>
            </span>
        </Button>
    );
}

MyProjectsButton.displayName = "MyProjectsButton";
