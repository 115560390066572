import React, { ReactNode } from "react";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { useIsStudioLiveEnabled } from "@five/utilities/useIsStudioLiveEnabled";
import { StudioDesignerLiveContextProvider } from "./StudioDesignerLiveContextProvider";

const LivePresenceBorderWrapper = loadable<{ color: string }, any>(() => loadableRetry(() => import("@vp/dragon")), {
    resolveComponent: components => components.LivePresenceBorderWrapper
});

interface Props {
    children: ReactNode;
}

export const StudioLiveLocaleRestrictionProvider = ({ children }: Props) => {
    const studioLiveEnabled = useIsStudioLiveEnabled();
    return (
        <StudioDesignerLiveContextProvider studioLiveEnabled={studioLiveEnabled}>
            {studioLiveEnabled && <LivePresenceBorderWrapper color="#0A8525" />}
            {children}
        </StudioDesignerLiveContextProvider>
    );
};

StudioLiveLocaleRestrictionProvider.displayName = "StudioLiveLocaleRestrictionProvider";
