import {
    Issue,
    MagnifyingGlassIcon,
    NoValidationsIcon,
    Panel,
    Severity,
    Validation,
    ValidationGroup,
    ValidationType
} from "@design-stack-vista/smart-validations-ui";
import type { DSS } from "@vp/types-ddif";
import { getActiveCanvasIndex, getItemType } from "@designer-suite";
import * as turf from "@turf/turf";
import { getDesignDocumentFromDesigner } from "@utilities";
import type { Designer } from "src/easel/designer-suite/@types/designer";
import { type DefaultTFunction } from "@vp/i18n-helper";
import { getCanvasOrdinal, scrollToItemOnCanvas } from "../../utilities/canvasUtilities";
import messages from "./messages";
import { Vertices } from "./PointUtilities";
import type { StudioValidationRecord } from "./StudioValidationTypes";
import { SmartFixActionOrigin, SmartValidationEvents, trackSmartValidationEvent } from "./trackingClient";
import { getValidationDictionaryInfo, getValidationGroupDictionaryInfo, ValidationName } from "./ValidationDictionary";
import { getValidationInfo } from "./ValidationMetadata";
import { CanvasValidationRecord } from "./ValidationProvider";

interface convertStudioValidationToSmartValidationParams {
    studioValidation: DSS.StudioValidation;
    onSelect?: () => void;
    onHover?: () => void;
    onUnhover?: () => void;
    setLastSmartFixedItem: (validation: DSS.StudioValidation) => void;
    canvasIndex: number;
    dismissValidation?: (Validation: DSS.StudioValidation) => void;
    designer: Designer;
    t: DefaultTFunction;
}

function convertMarginsToBounds(margins: SafetyMargins, dimensions: Dimensions) {
    return [
        { x: `${margins.left}mm`, y: `${margins.top}mm` },
        { x: `${dimensions.width - margins.right}mm`, y: `${margins.top}mm` },
        { x: `${dimensions.width - margins.right}mm`, y: `${dimensions.height - margins.bottom}mm` },
        { x: `${margins.left}mm`, y: `${dimensions.height - margins.bottom}mm` }
    ];
}

export const convertDesignerValidation = (designerValidation: any): DSS.StudioValidation => {
    const { itemId, canvasId } = designerValidation.get("data");
    const validationName = designerValidation.get("name");
    const severity = designerValidation.get("severity");

    return {
        id: designerValidation.get("id"),
        itemId,
        canvasId,
        validationName,
        severity
    };
};

/**
 * @NOTE
 * SmartFix button is put on hold
 * Remove `isActionButtonEnabled` from the actionButton variable to enable it
 */
const isActionButtonEnabled = false;

// Builds either a smartFix button or an undo button
const buildActionButton = (
    studioValidation: DSS.StudioValidation,
    designer: Designer,
    setLastSmartFixedItem: (studioValidation: DSS.StudioValidation) => void,
    t: DefaultTFunction,
    smartFixFn?: (props?: any) => any,
    origin: SmartFixActionOrigin = SmartFixActionOrigin.Panel
) => {
    return {
        text: studioValidation.smartFixed
            ? t(messages.validationBrickButtonUndo.id)
            : t(messages.validationBrickButtonFix.id),
        onClick: studioValidation.smartFixed
            ? () => {
                  designer?.eventBus.trigger(designer.eventBus.events.undo);
                  // Track undo action
                  const { severity, itemId, validationName: validationType, canvasId } = studioValidation;
                  const itemModel = designer.api.design.canvases
                      .find(c => c.id === canvasId)!
                      .items.find(item => item.id === itemId)!._itemViewModel.model;
                  const itemType = getItemType(itemModel.attributes);
                  trackSmartValidationEvent(SmartValidationEvents.SmartFixUndone, {
                      extraData: { validationType, severity, itemType, itemId }
                  });
              }
            : async () => {
                  setLastSmartFixedItem(studioValidation);
                  if (smartFixFn) {
                      const cimDoc = getDesignDocumentFromDesigner(false);
                      const canvasViewModel = designer.documentRepository.getCanvasViewModel(studioValidation.canvasId);
                      const panelId = canvasViewModel.model.get("id");
                      const itemViewModel = canvasViewModel.itemViewModels.find(
                          ({ id }) => id === studioValidation.itemId
                      );
                      const itemId = itemViewModel?.model.get("id");
                      const canvasDimensions = {
                          width: canvasViewModel.model.get("width"),
                          height: canvasViewModel.model.get("height")
                      };

                      const bleedMargin = canvasViewModel?.get("bleedMargin");
                      const safetyMargin = canvasViewModel?.get("safetyMargin");

                      let bounds;
                      switch (studioValidation.validationName) {
                          case ValidationName.outsideBounds:
                              bounds = bleedMargin ? convertMarginsToBounds(bleedMargin, canvasDimensions) : undefined;
                              break;
                          case ValidationName.outsideMargins:
                              bounds = safetyMargin
                                  ? convertMarginsToBounds(safetyMargin, canvasDimensions)
                                  : undefined;
                              break;
                          default:
                              break;
                      }

                      const smartFix = await smartFixFn({
                          cimDoc,
                          panelId,
                          itemId,
                          bounds,
                          textPlaceholderText: t("easel.designer.translation.text.defaultPlaceholder")
                      });

                      if (smartFix.isFixNeeded) {
                          designer.api.design.updateItem(studioValidation.itemId, mutableItem => {
                              Object.entries(smartFix.fix).forEach(([key, value]) => {
                                  const modelKeys = { x: "left", y: "top", width: "width", height: "height" };
                                  // TODO: When smart fix fn is done, test below, not sure if every propery should be updated in _itemViewMode.model
                                  mutableItem._itemViewModel.model.set(modelKeys[key], value);
                              });
                              // Track smart fix applied
                              const itemType = getItemType(mutableItem._itemViewModel.model.attributes);
                              const { severity, validationName: validationType } = studioValidation;
                              trackSmartValidationEvent(SmartValidationEvents.SmartFixApplied, {
                                  extraData: {
                                      trigger: origin,
                                      validationType,
                                      severity,
                                      itemType,
                                      itemId
                                  }
                              });
                          });
                      }
                  }
              }
    };
};
export const convertStudioValidationToSmartValidation = ({
    studioValidation,
    onSelect,
    onHover,
    onUnhover,
    setLastSmartFixedItem,
    canvasIndex,
    dismissValidation,
    designer,
    t
}: convertStudioValidationToSmartValidationParams) => {
    const { itemId, severity, validationName } = studioValidation;
    const itemModel = designer.api.design.canvases[canvasIndex].items.find(item => {
        return item.id === itemId;
    })?._itemViewModel.model;
    const itemType = itemModel ? getItemType(itemModel.attributes) : undefined;

    const validationInfo = getValidationDictionaryInfo(studioValidation, itemType, t);
    const actionButton =
        validationInfo.smartFix &&
        isActionButtonEnabled &&
        buildActionButton(studioValidation, designer, setLastSmartFixedItem, t, validationInfo.smartFix);
    let validationActionType: ValidationType;

    if (severity === Severity.ERROR) {
        validationActionType = {
            severity,
            actionButton
        };
    } else {
        validationActionType = {
            severity,
            actionButton,
            secondaryButton: studioValidation.smartFixed
                ? undefined
                : {
                      text: t(messages.validationBrickButtonDismiss.id),
                      onClick: () => {
                          if (dismissValidation) {
                              dismissValidation(studioValidation);
                              trackSmartValidationEvent(SmartValidationEvents.AlertDismissed, {
                                  extraData: {
                                      validationType: studioValidation.validationName,
                                      severity,
                                      itemType: itemType || "",
                                      itemId
                                  }
                              });
                              if (onUnhover) {
                                  onUnhover();
                              }
                          }
                      }
                  },
            // TODO: rename to `dismissing` here and on modify `ValidationBrick` to use it
            dismissed: studioValidation.dismissing
        };
    }

    const validation: Validation = {
        itemId,
        validation: validationName,
        type: validationActionType,
        smartFixed: studioValidation.smartFixed,
        description: validationInfo.message,
        onSmartValidationBrickClick: onSelect,
        onSmartValidationBrickHover: onHover,
        onSmartValidationBrickUnhover: onUnhover
    };
    return validation;
};

export function brickOnSelect(itemId: string, designer: Designer, itemCanvasOrdinal: number) {
    const itemCanvasIndex = itemCanvasOrdinal - 1;

    scrollToItemOnCanvas(itemId);
    const item = designer.api.design.canvases[itemCanvasIndex].items.find(item => item.id === itemId);
    item && designer.selectionManager.select([item._itemViewModel]);
}

function brickOnHover(itemId: string, canvasIndex: number, designer: Designer) {
    const item = designer.api.design.canvases[canvasIndex].items.find(item => item.id === itemId);
    item?._itemViewModel.set("over", true);
    if (item) {
        scrollToItemOnCanvas(itemId);
    }
}

export function brickOnUnhover(itemId: string, canvasIndex: number, designer: Designer) {
    const item = designer.api.design.canvases[canvasIndex].items.find(item => item.id === itemId);
    item?._itemViewModel.unset("over");
}

export const constructValidationPanels = (
    designer: Designer,
    validations: Record<string, StudioValidationRecord>,
    triggerCanvasChange: (canvasName: string) => void,
    updateSmartFixedItems: (studioValidation: DSS.StudioValidation) => void,
    dismissValidation: (studioValidation: DSS.StudioValidation) => void,
    canvasSelectorPreviews: { src: string }[] | undefined,
    t: DefaultTFunction
) => {
    if (!designer) {
        return [];
    }

    const { canvases } = designer.api.design;
    const panels = canvases.map((canvas, canvasIndex) => {
        const canvasId = canvas.id;
        const canvasOrdinal = getCanvasOrdinal(canvas);
        const validationGroups: ValidationGroup[] = [];
        let validationsCount = 0;
        let switchToPanelButton;
        let noValidationsData;
        if (canvas._canvasViewModel.get("active")) {
            const canvasValidations = validations[canvasId];
            if (canvasValidations) {
                Object.entries(canvasValidations).forEach(([key, value]) => {
                    if (value.length > 0) {
                        const validations: Validation[] = [];
                        value.forEach(validation => {
                            if (!validation.dismissed) {
                                const smartValidation = convertStudioValidationToSmartValidation({
                                    studioValidation: validation,
                                    onSelect: () => brickOnSelect(validation.itemId, designer, canvasOrdinal),
                                    onHover: () => brickOnHover(validation.itemId, canvasIndex, designer),
                                    onUnhover: () => brickOnUnhover(validation.itemId, canvasIndex, designer),
                                    setLastSmartFixedItem: () => updateSmartFixedItems(validation),
                                    canvasIndex,
                                    dismissValidation: () => dismissValidation(validation),
                                    designer,
                                    t
                                });
                                validations.push(smartValidation);
                            }
                        });

                        const validationGroupInfo = getValidationGroupDictionaryInfo(
                            ValidationName[key],
                            value[0].severity,
                            t
                        );

                        if (validations.length > 0) {
                            validationGroups.push({
                                title: validationGroupInfo.title,
                                description: "",
                                validations,
                                tooltipText: validationGroupInfo.itemMessage
                            });
                        }
                    }
                });
            }
            const flattenedValidations = Object.values(validationGroups).flatMap(
                (f: ValidationGroup) => f.validations
            ) as unknown as DSS.StudioValidation[];
            const hasSmartFixedItem = flattenedValidations.some(validation => validation.smartFixed);
            validationsCount = hasSmartFixedItem ? flattenedValidations.length - 1 : flattenedValidations.length;
            if (validationsCount === 0) {
                noValidationsData = {
                    title: t(messages.noPanelValidationsTitle.id),
                    subtitle: t(messages.noPanelValidationsSubtitle.id),
                    icon: NoValidationsIcon()
                };
            }
        } else {
            const info = getValidationInfo(canvas);
            validationsCount += info ? info.count : 0;

            if (validationsCount === 0) {
                return null;
            }

            switchToPanelButton = {
                text: t(messages.showIssuesButton.id),
                icon: MagnifyingGlassIcon(),
                onClick: () => triggerCanvasChange(canvas.name)
            };
        }
        const subtitle =
            validationsCount === 1
                ? t(messages.issuesToReviewSubtitleSingular.id)
                : t(messages.issuesToReviewSubtitlePlural.id, { count: validationsCount });

        if (canvasSelectorPreviews?.length === 0) return null;

        const panel: Panel = {
            title: canvas.name,
            subtitle,
            validationGroups,
            panelId: canvasId,
            validationsCount,
            switchToPanelButton,
            noValidationsData,
            previewUrl: canvasSelectorPreviews?.[canvasIndex].src
        };
        return panel;
    });
    return panels.filter(panel => panel !== null) as Panel[];
};

export const findPolygonIntersections = (
    itemPoints: turf.helpers.Position[],
    canvasPoints: turf.helpers.Position[]
) => {
    const poly1 = turf.polygon([itemPoints]);
    const poly2 = turf.polygon([canvasPoints]);
    const booleanContainsResult = turf.booleanContains(poly1, poly2);
    if (booleanContainsResult) {
        return null;
    }
    const intersectionResult = turf.intersect(poly1, poly2);
    if (!intersectionResult) {
        return null;
    }
    return intersectionResult.geometry.coordinates[0].map(coord => ({ x: coord[0] as number, y: coord[1] as number }));
};

export const getPositions = (verticies: Vertices) => {
    const { topLeft, topRight, bottomRight, bottomLeft } = verticies;
    return [topLeft, topRight, bottomRight, bottomLeft, topLeft].map(pt =>
        Object.values(pt)
    ) as turf.helpers.Position[];
};

interface ConvertStudioValidationToBrickValidationProp {
    studioValidation: DSS.StudioValidation;
    gotoPanelButton: JSX.Element;
    updateSmartFixedItems: (studioValidation: DSS.StudioValidation) => void;
    designer: Designer;
    onSelect?: () => void;
    onHover?: () => void;
    onUnhover?: () => void;
    t: DefaultTFunction;
}

// Creates a smart validation brick for use on canvas
export const convertStudioValidationToBrickValidation = ({
    studioValidation,
    gotoPanelButton,
    updateSmartFixedItems,
    designer,
    onSelect,
    onHover,
    onUnhover,
    t
}: ConvertStudioValidationToBrickValidationProp): Validation => {
    const canvasIndex = getActiveCanvasIndex(designer);
    const itemModel = designer.api.design.canvases[canvasIndex].items.find(item => {
        return item.id === studioValidation.itemId;
    })?._itemViewModel.model;
    const itemType = itemModel ? getItemType(itemModel.attributes) : undefined;

    const validationInfo = getValidationDictionaryInfo(studioValidation, itemType, t);

    const actionButton =
        validationInfo.smartFix &&
        isActionButtonEnabled &&
        buildActionButton(
            studioValidation,
            designer,
            updateSmartFixedItems,
            t,
            validationInfo.smartFix,
            SmartFixActionOrigin.Popover
        );

    const validationActionType: ValidationType = {
        severity: studioValidation.severity,
        actionButton,
        secondaryButton: gotoPanelButton
    };

    return {
        itemId: studioValidation.itemId,
        validation: studioValidation.validationName,
        type: validationActionType,
        description: validationInfo.message,
        onSmartValidationBrickClick: onSelect,
        onSmartValidationBrickHover: onHover,
        onSmartValidationBrickUnhover: onUnhover
    };
};

/** Flatten validations to find next selectable item in canvas specified in itemId */
export function getNextItemValidation(panels: Panel[], itemId: string): Validation | undefined {
    const flatValidations = panels.flatMap((panel: Panel) =>
        !!panel.panelId && itemId.includes(panel.panelId)
            ? panel.validationGroups.flatMap((group: ValidationGroup) => group.validations)
            : []
    );
    const nextItemValidation =
        flatValidations.find((validation: Validation) => validation.itemId === itemId) || flatValidations[0];
    return nextItemValidation;
}

export function issuesReducer(validation: DSS.StudioValidation, t: DefaultTFunction, issue?: Issue): Issue {
    const { validationName, severity } = validation;
    if (!issue) {
        const validationGroupInfo = getValidationGroupDictionaryInfo(ValidationName[validationName], severity, t);
        return {
            severity,
            label: validationGroupInfo.title,
            info: validationGroupInfo.itemMessage ?? "",
            count: 1
        };
    }
    return {
        ...issue,
        count: issue.count + 1
    };
}

export function getFirstCanvasOrdinalWithIssues(designer: Designer | undefined) {
    const canvas = designer?.api.design.canvases.find(canvas => getValidationInfo(canvas)?.count);
    return canvas && getCanvasOrdinal(canvas);
}

export function checkIssuesInCanvasById(canvasValidations: CanvasValidationRecord, id: string) {
    // Warnings are removed from canvasValidations on canvas change, without errors in other canvases, there will be no canvas in the data structure
    if (!Object.keys(canvasValidations).length) return false;
    // Canvas with errors are kept on canvasValidations after canvas change, we check if the id passed in the function is present in the data structure
    return Object.keys(canvasValidations).some(canvasId => canvasId.includes(id));
}
