import { useIsFlagEnabled } from "@shared/features/Flags";
import { useAppSelector } from "@shared/redux";

export function useIsStudioLiveEnabled() {
    const locale = useAppSelector(state => state.locale);
    const isFlagEnabled = useIsFlagEnabled("studioLive");
    const enabledForLocale =
        STUDIO_LIVE_ENABLED_LOCALES && STUDIO_LIVE_ENABLED_LOCALES.some(l => l.toLowerCase() === locale.toLowerCase());

    return isFlagEnabled || enabledForLocale;
}
