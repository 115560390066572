import { defineMessages } from "@vp/i18n-helper";

export const canvasSelectorMessages = defineMessages({
    sideselectorAdd: {
        id: "studio.components.design.sideselector.add.title",
        defaultMessage: "Add the [[sideName]]",
        description: {
            note: "Button that appears in side selection that is for up-selling an additional side (Back side, Inside, ...)"
        }
    },
    sideselectorChange: {
        id: "studio.components.design.sideselector.change",
        defaultMessage: "Change",
        description: {
            note: "Link that appears below a sides name that opens a dialog to select different template"
        }
    },
    designPanelPreview: {
        id: "studio.components.design.preview",
        defaultMessage: "Canvas selector preview image",
        description: {
            note: "Descriptive alt label for preview of canvas Image"
        }
    },
    canvasSelectorPanelAriaLabel: {
        id: "studio.components.design.panel.tile.selection.canvasSelectorPanelAriaLabel",
        defaultMessage: "Canvas selector modal",
        description: {
            note: "aria label for canvas selector modal"
        }
    },
    canvasSelectionClose: {
        id: "studio.components.canvas.selection.close",
        defaultMessage: "Close",
        description: {
            note: "accessability text for an x button for closing a dialog for selecting a side"
        }
    },
    designSideSelectionTitle: {
        id: "studio.components.design.dialog.side.selection.title",
        defaultMessage: "Select a side to edit",
        description: {
            note: "Dialog title shown for a mobile dialog for selecting / adding a side"
        }
    }
});
