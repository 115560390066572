import React, { useEffect, useMemo } from "react";
import { PanelContent } from "@shared/features/StudioChrome";
import { ConfirmationModal } from "@shared/features/ChangeTemplate";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useActiveCanvas } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { TemplatePanelTitle } from "./TemplatePanelTitle";
import { TemplatePanelContentOptions } from "./TemplatePanelContentOptions";
import { TemplatePanelContentMain } from "./TemplatePanelContentMain";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";
import { TemplatePanelContentRecent } from "./TemplatePanelContentRecent";
import { useOnDesignTileClick } from "./useOnDesignTileClick";

import "./templatePanel.scss";

const panels: Record<TemplatePanelContentTypes, JSX.Element> = {
    [TemplatePanelContentTypes.Main]: <TemplatePanelContentMain />,
    [TemplatePanelContentTypes.Color]: <TemplatePanelContentOptions />,
    [TemplatePanelContentTypes.Recent]: <TemplatePanelContentRecent />
};

export const TemplatePanel = () => {
    const { stack, confirmationModalData, setConfirmationModalData, selectedDesign, currentTemplateColorSwatch } =
        useTemplatePanel();
    const currentColorSwatch = useMemo(
        () =>
            selectedDesign?.colorSwatches.find(
                ({ color, designId }) =>
                    designId === currentTemplateColorSwatch?.designId && color === currentTemplateColorSwatch?.color
            ),
        [currentTemplateColorSwatch, selectedDesign]
    );
    const { onConfirmationClick } = useOnDesignTileClick(currentColorSwatch ? "template color" : undefined);
    const current = stack[stack.length - 1];
    const designRequirements = useDesignRequirementsContext();
    const canvas = useActiveCanvas();
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { isSmall } = useStudioLayout();

    // Switching it to text when not on the default panel and only when on the template panel
    // This is so you can't start on the template panel on a non-default panel
    useEffect(() => {
        if (
            canvas &&
            designRequirements?.numberOfPanels &&
            canvas.name !== designRequirements?.getDefaultPanelName() &&
            currentActiveDialog === DialogType.Template
        ) {
            if (isSmall) {
                setCurrentActiveDialog(DialogType.None);
            } else {
                setCurrentActiveDialog(DialogType.Text);
            }
        }
    }, [canvas, currentActiveDialog, designRequirements, isSmall, setCurrentActiveDialog]);

    return (
        <>
            <PanelContent className="studio-template-panel-content">
                <TemplatePanelTitle />
                {panels[current]}
            </PanelContent>
            <ConfirmationModal
                isOpen={!!confirmationModalData}
                designDocument={confirmationModalData?.designDocument}
                onRequestDismiss={() => setConfirmationModalData(undefined)}
                onAccept={() => {
                    onConfirmationClick(confirmationModalData!);
                }}
            />
        </>
    );
};

TemplatePanel.displayName = "TemplatePanel";
