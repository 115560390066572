import { useState, useCallback, useEffect, useRef } from "react";
import { useIcons } from "@design-stack-vista/image-library-react";
import { DEFAULT_SEARCH_TERM } from "@shared/features/UploadsAndAssets";
import once from "lodash/once";
import { fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";

const fireUserInteractionOnce = once(fireUserInteractionTrackingEvent);

/**
 * A hook that returns a callback to initialize icons for the Icons Panel
 * with default icon results when the user opens the icons tab
 * */
export const useInitIcons = () => {
    const { performSearch, imageResults } = useIcons();
    const [isIconsInitialized, setIsIconsInitialized] = useState(false);
    const startTime = useRef(0);

    /* used for user interaction tracking */
    useEffect(() => {
        if (imageResults.length && isIconsInitialized) {
            const endTime = performance.now();
            const timeElapsed = endTime - startTime.current;
            fireUserInteractionOnce("Load Initial Icons Panel", timeElapsed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageResults]); // only track initial loading, when we get imageResults the first time

    const handleInitIcons = useCallback(() => {
        if (!isIconsInitialized) {
            startTime.current = performance.now();
            performSearch(DEFAULT_SEARCH_TERM, 1);
            setIsIconsInitialized(true);
        }
    }, [isIconsInitialized, performSearch]);

    return handleInitIcons;
};
