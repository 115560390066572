import { selectedItemsAreOfTypes } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { type Font } from "@designer-suite";
import { MAX_FONT_SIZE_DECIMALS } from "@shared/features/ContextualToolbar";
import type { Designer, ItemSelection } from "../../@types/designer";

export function getMinMaxFontSizeInterval(
    designer: Designer,
    canvas: Canvas,
    defaultFontConfig: FontSizeConfig,
    fonts: Font[],
    selectedFontFamily: string
) {
    const selectedFont = fonts.find(font => font.family === selectedFontFamily);
    let { minLimit, maxLimit, intervals } = defaultFontConfig;
    const { minInterval } = defaultFontConfig;
    const maxInterval = defaultFontConfig.maxInterval(canvas.mmDimensions);

    maxLimit = maxInterval;
    minLimit = minInterval;

    if (selectedFontFamily && selectedFont && selectedFont.fontFamilyOverride) {
        minLimit = Math.max(selectedFont.fontFamilyOverride.minLimit, minLimit);
        // eslint-disable-next-line prefer-destructuring
        maxLimit = selectedFont.fontFamilyOverride.maxLimit;
        // eslint-disable-next-line prefer-destructuring
        intervals = selectedFont.fontFamilyOverride.intervals;
    }
    const fontIntervalForFontFamily = designer.FontSizeManager.getFontIntervalsForFontFamily(
        canvas.mmDimensions,
        selectedFontFamily
    );

    minLimit = Math.max(minLimit, fontIntervalForFontFamily.minLimit);

    return { minLimit: Math.trunc(minLimit), maxLimit: Math.ceil(maxLimit), intervals };
}

export function multipleFontSizeExist(selectedFontSizes: number[]): boolean {
    return selectedFontSizes.length > 1;
}

export function getIsFontSelectorCompatible(
    designer: Designer | undefined,
    selection: ItemSelection
): designer is Designer {
    return designer !== undefined && selectedItemsAreOfTypes(selection, [ItemTypes.TEXT]);
}

const FONT_SIZE_ROUNDING = Math.pow(10, MAX_FONT_SIZE_DECIMALS);

export function truncatedFontSize(fontSize: number) {
    // rounding font because templates sometimes have 7.995, which we can just round up to 8
    return Math.round(fontSize * FONT_SIZE_ROUNDING) / FONT_SIZE_ROUNDING;
}
