import React, { useEffect, useState } from "react";
import { Link, AlertBoxDismissButton, AlertBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { isCareAgent } from "@shared/utils/Care";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { useAppSelector, useAppDispatch, resetAlerts } from "@shared/redux";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./Toast.module.scss";

const messages = defineMessages({
    upsellMessageForBackSide: {
        id: "studio.components.Toast.upsellMessageForBackSide",
        defaultMessage: "Back design has been added for [[pricing]]",
        description: {
            note: "This message is displayed when a backside design is added"
        }
    },
    upsellMessageForBlankBackSide: {
        id: "studio.components.Toast.upsellMessageForBlankBackSide",
        defaultMessage: "Back design has been removed for [[pricing]]",
        description: {
            note: "This message is displayed when a backside design is removed"
        }
    },
    upsellMessageForDefault: {
        id: "studio.components.Toast.upsellMessageForDefault",
        defaultMessage: "Design has been added for [[pricing]]",
        description: {
            note: "This message is displayed when a new side design is added"
        }
    },
    upsellMessageForBlankDefault: {
        id: "studio.components.Toast.upsellMessageForBlankDefault",
        defaultMessage: "Design has been removed for [[pricing]]",
        description: {
            note: "This message is displayed when a new side design is removed"
        }
    },
    significantAdjustedDimensions: {
        id: "studio.components.Toast.significantAdjustedDimensions",
        defaultMessage:
            "We upgraded this product and had to resize your image to fit. Please make sure the image looks just right.",
        description: {
            note: "This message is displayed when a design has been automatically resized due to a change to the underlying product."
        }
    },
    designSupportCareContact: {
        id: "studio.components.Toast.contactCare",
        defaultMessage: "For design support, please contact us",
        description: {
            note: `This bit of text is meant to be added to the end of alerts that we want to allow customers to contact care agents for
                design support.`
        }
    },
    closeButton: {
        id: "studio.components.Toast.closeButton",
        defaultMessage: "Close alert message",
        description: {
            note: "Accessibility label for the close button on the error toast"
        }
    },
    mailingServicesAdded: {
        id: "studio.components.Toast.mailingServicesAdded",
        defaultMessage: "Mailing services have been added.",
        description: {
            note: "alert that appears when user opts in for mailing services by clicking continue button from mailing service confimation modal."
        }
    },
    mailingServicesRemoved: {
        id: "studio.components.Toast.mailingServicesRemoved",
        defaultMessage: "Mailing services have been removed.",
        description: {
            note: "alert that appears when user opts out of mailing services by clicking removing mailing service from mailing service sidebar panel."
        }
    },
    changeProductSizeToastTitle: {
        id: "studio.components.Toast.changeProductSizeToastTitle",
        defaultMessage: `Product size changed to [[currentProductSize]]`,
        description: {
            note: "Text for change product size toast"
        }
    },
    changeSizeToastWithPrice: {
        id: "studio.components.Toast.changeSizeToastWithPrice",
        defaultMessage: `Product size changed to [[Size]] for [[price]]`,
        description: {
            note: "Text for change product size toast with price info"
        }
    },
    changeFinishToastWithPrice: {
        id: "studio.components.Toast.changeFinishToastWithPrice",
        defaultMessage: `[[Finish]] selected for [[price]]`,
        description: {
            note: "Text for change product finish toast with price info"
        }
    },
    changeTrimToastWithPrice: {
        id: "studio.components.Toast.changeTrimToastWithPrice",
        defaultMessage: `[[Trim]] selected for [[price]]`,
        description: {
            note: "Text for change product trim toast with price info"
        }
    },
    changeSizeToast: {
        id: "studio.components.Toast.changeSizeToast",
        defaultMessage: `Product size changed to [[Size]]`,
        description: {
            note: "Text for change product size toast"
        }
    },
    changeFinishToast: {
        id: "studio.components.Toast.changeFinishToast",
        defaultMessage: `You've selected [[Finish]]`,
        description: {
            note: "Text for change product finish toast"
        }
    },
    changeTrimToast: {
        id: "studio.components.Toast.changeTrimToast",
        defaultMessage: `You've selected [[Trim]]`,
        description: {
            note: "Text for change product trim toast"
        }
    },
    FileTypeNotSupported: {
        id: "studio.components.upload.FileTypeNotSupported",
        defaultMessage:
            "Oops! It looks like you uploaded a file that we do not support. You can upload files with the following extensions: [[fileExtensions]]",
        description: {
            note: "Alert that pops up when the user uploads an invalid file type"
        }
    },
    panelsModifiedByHistoryToast: {
        id: "studio.components.Toast.panelsModifiedByHistoryToast",
        defaultMessage:
            "Your last action affected items in design areas that are currently not visible. Please review the changes to ensure they are as intended.",
        description: {
            note: "Alert that pops up when items not on the currently active canvas were changed"
        }
    },
    activePanelChangedByHistoryToast: {
        id: "studio.components.Toast.activePanelChangedByHistoryToast",
        defaultMessage:
            "Your last action affected a different design area. We've switched your view to this design area for you to review the changes.",
        description: {
            note: "Alert that pops up when the active canvas was changed by undo/redo"
        }
    },
    copyPasteToInvalidDecoTech: {
        id: "studio.components.Toast.copyPasteToInvalidDecoTech",
        defaultMessage: "Sorry, this copy action is not currently available.",
        description: {
            note: "alert that appears when user attempts to copy and paste an item that is incompatible with their current product."
        }
    }
});

/**
 *  A generic toast for normal info and alerts for the user. Displayed with a text color of `#fff` on a background of `#000`
 *
 *  **How to show new toast :**
 *
 *  dispatch `setAlerts` or `resetAlerts` to clear or add a new alert
 *
 *  **Shape** :
 *  ```javascript
 *  {
 *       alerts: [
            {
                key: `the key to the trasaltion id`,
                variables: {
                    pricing: `any string corresponding to the variables`
                }
            }
    ]}
```
    Example: Search for `studio.components.Toast.upsellMessageForBlankBackSide`
 *
 */
export function AlertToast() {
    const { t } = useTranslationSSR();
    const alerts = useAppSelector(state => state.alerts);
    const hideAlertToast = useAppSelector(state => state.hideAlertToast);
    const dispatch = useAppDispatch();
    const { careUrl } = usePageContext();
    const [render, setRender] = useState(false);
    const [show, setShow] = useState(false);
    const advancedTools = useIsFlagEnabled("advancedTools");
    const showCareMessages = isCareAgent() || advancedTools;

    useEffect(() => {
        let returnFn;
        if (alerts.length > 0) {
            setShow(true);
            setRender(true);

            if (hideAlertToast) {
                const closeAlertToast = setTimeout(() => {
                    dispatch(resetAlerts());
                }, 5000);
                returnFn = () => clearTimeout(closeAlertToast);
            }
        }
        return returnFn;
    }, [alerts, hideAlertToast, dispatch]);

    const onAnimationEnd = () => {
        if (!render) {
            dispatch(resetAlerts());
            setShow(false);
        }
    };

    const getContactCareMessage = () => {
        return (
            <div>
                <Link href={careUrl}>{t(messages.designSupportCareContact.id)}</Link>.
            </div>
        );
    };
    return (
        <>
            {alerts.map(alert => (
                <AlertBox
                    key={alert.key}
                    dismissed={!show}
                    onRequestDismiss={() => setRender(false)}
                    onAnimationEnd={onAnimationEnd}
                    className={render ? styles.fadeIn : styles.fadeOut}
                    skin={alert?.skin}
                >
                    <div className={styles.toastMessageContainer}>
                        <div>
                            {alert.message ? alert.message : t(alert.key, alert.variables || {})}
                            {alert.includeCareLink && getContactCareMessage()}
                        </div>
                        {showCareMessages && alert.careOnlyMessage && (
                            <div>
                                <br />
                                <p dangerouslySetInnerHTML={{ __html: alert.careOnlyMessage }}></p>
                            </div>
                        )}
                    </div>
                    <AlertBoxDismissButton visuallyHiddenLabel={t(messages.closeButton.id)} />
                </AlertBox>
            ))}
        </>
    );
}
AlertToast.displayName = "AlertToast";
