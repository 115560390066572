import React, { useEffect } from "react";
import { H6, Divider, SelectionSet, SelectionSetInput, SelectionSetLabel } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useUploads, useSimpleSelection, useHandleImagePlacement } from "@designer-suite";
import { isImageUnreplacedPlaceholder, isLocked } from "@utilities";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "@shared/redux";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { RedesignedUploadsPanelMessages, AcceptedUploadFormats } from "@shared/features/UploadsAndAssets";
import { PlacementStrategy } from "../UploadStrategies";
import { UploadButton } from "../UploadButton";
import { useUploadCompletionEvent } from "../../../hooks/useUploadCompletionEvent";
import { UploadThumbnailsPanelRedesign as UploadThumbnailsPanel } from "./UploadThumbnailsPanel";
import { useHideUsedUploads } from "../HideUploadsProvider";
import { DraggableUploadsPanelProvider } from "../DraggableUploadsPanelProvider";
import { useUploadsContext } from "../../../designer/UploadsProvider";
import { DeleteImageModal } from "./DeleteImageModal";
import "./uploadsPanel.scss";

interface Props {
    previousImagesHeader?: React.ReactFragment;
    /**
     * An object with an onClick and onUpload behavior specified
     * Determines the strategy used for placing images on tap and on upload completed
     * */
    placementStrategy?: PlacementStrategy;

    /**
     * A callback to be executed when an upload is added to the canvas
     * */
    onUploadAdded?: () => void;

    /**
     * Optional. For enabling login on the panel
     * */
    loginButton?: React.ReactFragment;

    /**
     * Optional. Boolean to control whether or not to open this when a placeholder has been selected
     * */
    openOnPlaceholderSelected?: boolean;

    /**
     * Optional. Class name for the div
     */
    className?: string;

    /**
     * Callback for how the user wants to open this panel
     */
    openPanel?: () => void;

    allowHiding?: boolean;

    isDraggable?: boolean;

    columnCount?: number;

    isReplacePanel?: boolean;
    ignoreUpload?: boolean;
    /*
     * Optional. Boolean to check if parent is ImageUploadPanel not the fullBleed modal
     */
    isImageUpload?: boolean;

    sideName?: string;
}

const skeletonPreviewsCount = 6;

function UploadsPanelRedesign({
    previousImagesHeader,
    placementStrategy,
    onUploadAdded,
    loginButton,
    openPanel,
    className,
    openOnPlaceholderSelected = false,
    allowHiding = true,
    isDraggable = false,
    columnCount = 3,
    isReplacePanel = false,
    ignoreUpload = false,
    isImageUpload,
    sideName
}: Props) {
    const { onImageClicked, onImageDoubleClicked } = useHandleImagePlacement({
        placementStrategy,
        onUploadAdded
    });
    const { currentActiveDialog } = useActiveDialog();
    const userUploads = useUploads();
    const { t } = useTranslationSSR();
    const isReplaceOpenOnCanvasChange = useAppSelector(state => state.isReplaceOpenOnCanvasChange);
    const isUploadModalAutoLoaded = useAppSelector(state => state.isUploadModalAutoLoaded);
    const addRemoveSelection = useSimpleSelection("add remove");
    // ignoreUploads is a hack to fix a bug on mobile where a placeholder was replaced and the image was
    // then duplicated on the canvas. This happens because UploadsPanelRedesign is present in the ReplacePanel
    // and the ImagePanel, causing useUploadCompletionEvent to fire twice.
    // TODO: Fix this. useUploadCompletionEvent should not be firing twice. DT-5897
    const ignoreUploads =
        ((currentActiveDialog === DialogType.ImagePlaceholderTools || currentActiveDialog === DialogType.ImageTools) &&
            !isReplacePanel) ||
        ignoreUpload;
    useUploadCompletionEvent({
        ignoreUploads,
        placementStrategy,
        onUploadAdded,
        isUploadModalAutoLoaded
    });
    const { hideUsedUploads, setHideUsedUploads } = useHideUsedUploads();
    const { uploadsInitialized } = useUploadsContext();

    useEffect(() => {
        // We need to check to make sure there is only one element selected,
        // and that the element is an image that is a placeholder and that has not been replaced.
        if (
            addRemoveSelection.length === 1 &&
            isImageUnreplacedPlaceholder(addRemoveSelection[0]) &&
            openOnPlaceholderSelected === true &&
            openPanel &&
            isLocked(addRemoveSelection[0])
        ) {
            openPanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addRemoveSelection]);

    const uploadButtonText =
        !isReplaceOpenOnCanvasChange || isImageUpload
            ? t(RedesignedUploadsPanelMessages.uploadButton.id)
            : t(RedesignedUploadsPanelMessages.uploadImageButton.id);

    return (
        <>
            <DraggableUploadsPanelProvider isDraggable={isDraggable}>
                <div className={className}>
                    {!isReplacePanel && <AcceptedUploadFormats isImageUpload={isImageUpload} showTitle />}
                    <UploadButton
                        isUploadModalAutoLoaded={isUploadModalAutoLoaded}
                        isImageUpload={isImageUpload}
                        aria-describedby="upload-image-allowed-formats"
                    >
                        {uploadButtonText}
                    </UploadButton>
                    {userUploads.length > 0 && <Divider mb={4} />}
                    {previousImagesHeader}
                    {uploadsInitialized && allowHiding && (
                        <SelectionSet
                            mt={4}
                            mb={3}
                            variant="multi-select"
                            skin="simple-column"
                            onSelectedValuesChange={newSelectedValues => setHideUsedUploads(newSelectedValues.hideUsed)}
                            selectedValues={{ hideUsed: hideUsedUploads }}
                        >
                            <SelectionSetInput value="hideUsed">
                                <SelectionSetLabel className="hide-used middle-align-checkbox-text">
                                    {t(RedesignedUploadsPanelMessages.hideUsedPhotos.id)}
                                </SelectionSetLabel>
                            </SelectionSetInput>
                        </SelectionSet>
                    )}
                    {userUploads.length > 0 && (
                        <>
                            <H6
                                className="recent-upload-title-text"
                                textColor="dark-grey"
                                fontSize="-1"
                                marginBottom={2}
                            >
                                {t(RedesignedUploadsPanelMessages.recentlyUploaded.id)}
                            </H6>
                            <UploadThumbnailsPanel
                                className="dcl-thumb-list"
                                userUploads={userUploads}
                                onUploadClicked={onImageClicked}
                                onUploadDoubleClicked={onImageDoubleClicked}
                                columnCount={columnCount}
                                allowHiding={allowHiding}
                                isReplacePanel={isReplacePanel}
                                isUploadModalAutoLoaded={isUploadModalAutoLoaded}
                            />
                        </>
                    )}
                    {!uploadsInitialized && (
                        <div className="dcl-thumb-list" data-dcl-prevent-canvas-items-deselection>
                            {[...Array(skeletonPreviewsCount)].map((_n, i) => (
                                <div className="skeleton-upload-preview-desktop" key={`skeleton-upload${i}`}>
                                    <Skeleton width={"100%"} height={80} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {loginButton}
            </DraggableUploadsPanelProvider>
            <DeleteImageModal />
        </>
    );
}
UploadsPanelRedesign.displayName = "UploadsPanelRedesign";

export { UploadsPanelRedesign };
