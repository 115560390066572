import { useActiveCanvas } from "@designer-suite";
import { useAvailablePremiumFinishesOnCanvas } from "@shared/features/PremiumFinish";

/**
 * A hook that provides information about premium finishes
 */
export function useStudio5AvailablePremiumFinishesOnCanvas() {
    const activeCanvas = useActiveCanvas();

    return useAvailablePremiumFinishesOnCanvas(activeCanvas?.name);
}
