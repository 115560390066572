import React, { useEffect, useState } from "react";
import Gallery from "react-photo-gallery";
import { useDesigner } from "@designer-suite";
import { Status, useStockImages } from "@design-stack-vista/image-library-react";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { useTranslationSSR, Trans } from "@vp/i18n-helper";
import { Typography, Divider } from "@vp/swan";
import { createGalleryItems, DiscoveryPhotoItem } from "./ImagePanelUtils";
import { renderImage } from "./ImageRenderer";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";

type Props = {
    searchTerm: string;
    setGalleryItemsCount: (count: number, hasMore: boolean) => void;
    setPeformNextSearch: (next: () => void) => void;
    onDone?: () => void;
};

export const ImageSecondGallery = (props: Props) => {
    const { searchTerm, onDone, setGalleryItemsCount, setPeformNextSearch } = props;
    const { status, imageItems, performInitialSearch, hasMoreItems, performNextSearch, currentSearchTerm } =
        useStockImages();
    const [galleryImages, setGalleryImages] = useState<DiscoveryPhotoItem[]>([]);
    const designer = useDesigner();
    const { t } = useTranslationSSR();

    useEffect(() => {
        setPeformNextSearch(() => () => {
            performNextSearch();
        });
    }, [performNextSearch, setPeformNextSearch]);

    useEffect(() => {
        if (!designer || status !== Status.Idle) {
            return;
        }
        let newItems: DiscoveryPhotoItem[] = [];
        if (imageItems?.length) {
            newItems = createGalleryItems(imageItems, designer, onDone);
        }
        setGalleryImages(newItems);
        setGalleryItemsCount(newItems.length, hasMoreItems);
    }, [designer, onDone, imageItems, status, hasMoreItems, setGalleryItemsCount]);

    useEffect(() => {
        if (currentSearchTerm !== searchTerm) {
            performInitialSearch(searchTerm, "");
        }
    }, [performInitialSearch, searchTerm, currentSearchTerm]);
    if (galleryImages.length === 0 && !hasMoreItems) {
        return null;
    }
    return (
        <>
            <Divider mt={5} mb={5} />
            <Typography my={4} textAlign="center" fontSize="1">
                <Trans
                    t={t}
                    i18nKey={imageLibraryPanelMessages.unfilteredResults.id}
                    components={{ bold: <strong /> }}
                    values={{ term: currentSearchTerm }}
                />
            </Typography>
            {galleryImages.length === 0 ? (
                <LoadingSpinner className="my-3" variant={SpinnerVariant.Large} centering />
            ) : (
                <Gallery photos={galleryImages} direction={"column"} columns={2} renderImage={renderImage} margin={5} />
            )}
        </>
    );
};

ImageSecondGallery.displayName = "ImageSecondGallery";
