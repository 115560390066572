import { useStudioFlexibility } from "@shared/features/Flexibility";
import React, { useContext, createContext, ReactNode, useMemo, useState, useEffect } from "react";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { getDifferentialPricing, Pricing, formatPricingData } from "@shared/utils/Pricing";
import { useAppSelector } from "@shared/redux";
import { MappedProductOption, mapProductOptionsToDesignAttributes } from "@shared/utils/DesignAttributes";

type ContextData = {
    pricing: Pricing | undefined;
};

type Props = {
    children: ReactNode | ReactNode[];
};

const Context = createContext<ContextData | undefined>(undefined);

export const usePricing = () => {
    const result = useContext(Context);
    if (!result) {
        throw Error("Please call this within a PricingProvider");
    }
    return result;
};

const { Provider } = Context;

export function PricingProvider(props: Props) {
    const { children } = props;
    const pricingInitialized = useAppSelector(state => state.pricingInitialized);
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const productKey = useAppSelector(state => state.productKey);
    const quantity = useAppSelector(state => state.quantity);
    const productVersion = useAppSelector(state => state.productVersion);
    const compatibleProductOptions = useAppSelector(state => state.compatibleProductOptions);
    const [pricing, setPricing] = useState<Pricing | undefined>();
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isPricingEnabled = isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled;

    // create choice groups for all design attributes
    const choiceGroups = useMemo(() => {
        let choiceGroups: Record<string, Record<string, string>> | undefined;
        if (designAttributeMappings && compatibleProductOptions) {
            const productOptions = mapProductOptionsToDesignAttributes(
                designAttributeMappings,
                compatibleProductOptions
            );

            choiceGroups = productOptions.reduce(
                (accumulator: Record<string, Record<string, string>>, currentValue: MappedProductOption) => {
                    const { designAttributeName, designAttributeValue, productOptionName, productOptionValue } =
                        currentValue;
                    let choiceGroupKey: string;
                    if (designAttributeName) {
                        choiceGroupKey = `${designAttributeName}-${designAttributeValue}`;
                    } else {
                        choiceGroupKey = `${productOptionName}-${productOptionValue}`;
                    }
                    return { ...accumulator, [choiceGroupKey]: { [productOptionName]: productOptionValue } };
                },
                {}
            );
        }
        return choiceGroups;
    }, [designAttributeMappings, compatibleProductOptions]);

    // loadPricing again whenever studio selected value changes
    useEffect(() => {
        if (
            pricingInitialized &&
            isPricingEnabled &&
            designAttributeMappings &&
            designAttributeMappings.length > 0 &&
            choiceGroups &&
            Object.keys(choiceGroups)?.length > 0
        ) {
            (async () => {
                try {
                    if (productVersion === null) {
                        throw Error("Product version is not defined");
                    }
                    const differentialPricingData = await getDifferentialPricing(
                        productKey,
                        studioSelectedProductOptions,
                        quantity,
                        choiceGroups ?? {},
                        productVersion,
                    );

                    const formattedPricing = formatPricingData(differentialPricingData);
                    setPricing(formattedPricing);
                } catch (e) {
                    setPricing(undefined);
                    handleError(e, ERROR_CODES.SURFACE_UPSELL_PRICING);
                }
            })();
        }
    }, [
        productKey,
        quantity,
        choiceGroups,
        productVersion,
        studioSelectedProductOptions,
        designAttributeMappings,
        isPricingEnabled,
        pricingInitialized
    ]);

    const contextObject = useMemo(
        () => ({
            pricing
        }),
        [pricing]
    );

    return <Provider value={contextObject}>{children}</Provider>;
}

PricingProvider.displayName = "PricingProvider";
