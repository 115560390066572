import React from "react";
import { ColorSwatch, ColorSwatches, ColorSwatchesMore } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import type { Design } from "@shared/utils/Gallery";
import { i18nColorName, isValidHex, isValidRGB } from "@design-stack-vista/i18n-color-name";
import { useAppSelector } from "../../../../shared/redux";

const maxColorSwatches = 4;
interface Props {
    design: Design;
}
const messages = defineMessages({
    defaultColor: {
        id: "studio.components.template.panel.color.filter.defaultText",
        defaultMessage: "Color Option",
        description: {
            note: "default label for color swatch"
        }
    }
});

export const TemplatePanelDesignAvailableColors = (props: Props) => {
    const {
        design: { colorSwatches }
    } = props;
    const locale = useAppSelector(state => state.locale);
    const remainder = colorSwatches.length - maxColorSwatches;
    const { t } = useTranslationSSR();
    return (
        <ColorSwatches>
            {colorSwatches.slice(0, maxColorSwatches).map(colorSwatch => {
                const derivedColorName =
                    isValidHex(colorSwatch.color) || isValidRGB(colorSwatch.color)
                        ? i18nColorName(colorSwatch.color, { culture: locale, colorPalette: "gallery" })
                        : t(messages.defaultColor.id);
                return (
                    <ColorSwatch
                        key={`${colorSwatch.designId}-${colorSwatch.color}`}
                        primaryColor={colorSwatch.color}
                        accessibleText={derivedColorName}
                        title={derivedColorName}
                    />
                );
            })}
            {remainder > 0 && <ColorSwatchesMore>{`+${remainder}`}</ColorSwatchesMore>}
        </ColorSwatches>
    );
};

TemplatePanelDesignAvailableColors.displayName = "TemplatePanelDesignAvailableColors";
