import React, { useCallback, HTMLProps } from "react";
import classNames from "classnames";
import { useOnKeyDown } from "@shared/features/Accessibility";
import { useTrackEvents } from "@shared/features/Tracking";
import { columnIndexToLetters } from "@shared/utils/Tables";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTableOverlayControls } from "../../TableOverlayControlsProvider";
import { SubselectionType, useTableSubselection } from "../../TableSubselectionProvider";
import { ColumnResizer } from "../columnResizer";

import * as styles from "./OffsetBarColumn.module.scss";

interface OffsetBarColumnProps extends HTMLProps<HTMLDivElement> {
    columnIndex: number;
}

export function OffsetBarColumn({ columnIndex, ...rest }: OffsetBarColumnProps) {
    const { relativeColumnWidths } = useTableOverlayControls();
    const { selection, updateSelection } = useTableSubselection();
    const { trackEvent } = useTrackEvents();

    const handleFocus = useCallback(() => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_TABLE_OVERLAY_COLUMN });
        updateSelection({ ...selection, columnIndex, rowIndex: -1, type: SubselectionType.Column });
    }, [columnIndex, selection, updateSelection, trackEvent]);

    const onKeyDown = useOnKeyDown(handleFocus);
    const isSelected = selection.type === SubselectionType.Column && selection.columnIndex === columnIndex;

    return (
        <div
            {...rest}
            className={classNames(
                "offset-bar-columns",
                {
                    "offset-bar-column-item-selected": isSelected
                },
                styles.offsetBarColumn,
                {
                    [styles.columnSelected]: isSelected
                }
            )}
            onFocus={handleFocus}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
            aria-pressed={isSelected}
        >
            <div className={classNames("offset-bar-columns-letter", styles.columnLetter)}>
                <div>{columnIndexToLetters(columnIndex)}</div>
            </div>
            {isSelected && columnIndex !== relativeColumnWidths.length - 1 && (
                <ColumnResizer columnIndex={columnIndex} />
            )}
        </div>
    );
}

OffsetBarColumn.displayName = "OffsetBarColumn";
