import { FC } from "react";
import { createPortal } from "react-dom";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { useAppSelector } from "@shared/redux";

/**
 * Creates a portal on the "dcl-canvas__overlay" layer so that the GRID can be
 *   SHOWN along-side other overlays
 * This needs to use a portal because the area is largely managed by BACKBONE
 *   from cimpress-designer
 */
export const GridPortal: FC = ({ children }) => {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const showGridlines = useAppSelector(state => state.showGridlines);

    if (!designer || !canvas || !showGridlines) {
        return null;
    }

    // Get the index of the current canvas
    const canvasIndex = canvas._canvasViewModel.get("ordinal") - 1;

    const container = document.getElementsByClassName("dcl-canvas__overlay")[canvasIndex];

    if (!container) {
        return null;
    }

    // Portal appends elements to the existing container instead of replacing it
    return createPortal(children, container);
};
GridPortal.displayName = "GridPortal";
