/* eslint-disable max-len */
import React from "react";

export function ResizeDesignIcon() {
    return (
        <svg viewBox="0 0 16 17" fill="none">
            <path
                d="M2.16667 1H13.8333C14.2113 1 14.5 1.29749 14.5 1.64286V15.3571C14.5 15.7025 14.2113 16 13.8333 16H2.16667C1.78871 16 1.5 15.7025 1.5 15.3571V1.64286C1.5 1.29749 1.78871 1 2.16667 1Z"
                stroke="currentColor"
                strokeOpacity="0.9"
            />
            <path
                d="M8.08509 8.41807C8.13746 8.47269 8.20183 8.5 8.27819 8.5C8.35674 8.5 8.4222 8.47269 8.47456 8.41807L10.5085 6.39L11.0747 6.95694C11.1183 7.00282 11.1652 7.02576 11.2154 7.02576C11.2678 7.02576 11.3147 7.00828 11.3562 6.97333C11.3976 6.93619 11.4271 6.88485 11.4445 6.8193L11.9911 4.78748C12.0064 4.72849 12.002 4.67606 11.978 4.63018C11.954 4.58211 11.9169 4.54606 11.8667 4.52203C11.8165 4.498 11.7631 4.49363 11.7064 4.50892L9.68046 5.0562C9.61719 5.0715 9.567 5.0999 9.52991 5.14141C9.495 5.18292 9.47754 5.2288 9.47754 5.27905C9.47754 5.3293 9.49936 5.37845 9.543 5.42652L10.1157 5.99674L8.08509 8.02482C8.02836 8.07943 8 8.14498 8 8.22144C8.00218 8.29791 8.03055 8.36345 8.08509 8.41807Z"
                fill="currentColor"
            />
            <path
                d="M7.91491 8.58193C7.86254 8.52731 7.79817 8.5 7.72181 8.5C7.64326 8.5 7.5778 8.52731 7.52544 8.58193L5.49151 10.61L4.92531 10.0431C4.88167 9.99718 4.83476 9.97424 4.78457 9.97424C4.73221 9.97424 4.6853 9.99172 4.64384 10.0267C4.60239 10.0638 4.57293 10.1152 4.55547 10.1807L4.00891 12.2125C3.99364 12.2715 3.998 12.3239 4.022 12.3698C4.046 12.4179 4.08309 12.4539 4.13328 12.478C4.18346 12.502 4.23692 12.5064 4.29365 12.4911L6.31954 11.9438C6.38281 11.9285 6.433 11.9001 6.47009 11.8586C6.505 11.8171 6.52246 11.7712 6.52246 11.721C6.52246 11.6707 6.50064 11.6215 6.457 11.5735L5.88425 11.0033L7.91491 8.97518C7.97164 8.92057 8 8.85502 8 8.77856C7.99782 8.70209 7.96945 8.63655 7.91491 8.58193Z"
                fill="currentColor"
            />
        </svg>
    );
}
ResizeDesignIcon.displayName = "ResizeDesignIcon";
