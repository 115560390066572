/* eslint-disable max-len */
import React from "react";

export const FolderIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 16 11" fill="none" {...props}>
            <path
                d="M13.25 3.682h.293a1 1 0 01.934 1.36l-1.855 4.817a1 1 0 01-.934.641H2.457a1 1 0 01-.934-1.36l1.855-4.817a1 1 0 01.934-.641h8.938zm0 0V2.409a1 1 0 00-1-1H5.8a1 1 0 01-.72-.306L4.795.807A1 1 0 004.075.5H2.437a1 1 0 00-1 1v8.09"
                stroke="currentColor"
            />
        </svg>
    );
};
FolderIcon.displayName = "FolderIcon";
