import qs from "qs";
import { tryFetch } from "@shared/utils/Network";
import type { MCP } from "@vp/types-ddif";

export interface Surface {
    id: string;
    name: string;
    processType: string;
    colorType: string;
    widthCm: number;
    heightCm: number;
    location: { name: string; description: string };
    docAdditionalData: {
        masks: MCP.SurfaceSpecificationModelsV3CalculatedPathGroup[];
        guides: MCP.SurfaceSpecificationModelsV3CalculatedPathGroup[];
        engravingColor: string;
    };
    spotColorSet: {
        description: string;
        totalMinSpotColors: number;
        totalMaxSpotColors: number;
        spotColorGroups: {
            description: string;
            minNumberSpotColors: number;
            maxNumberSpotColors: number;
            availableSpotColorsUrl: string;
            availableSpotColors: { name: string }[];
        }[];
    };
    ornamentSet: {
        description: string;
        ornamentGroups: { ornamentType: string; positions: { leftCm: number; topCm: number }[] }[];
    };
}

export interface SurfaceGroup {
    id: string;
    surfaces: Surface[];
    description: string;
    prepressUrl: string;
    prepressParametersUrl: string;
}

export interface ProductSurfaceDimensionsResult {
    mcpSku: string;
    surfaceGroups: SurfaceGroup[];
}

export async function getProductSurfaceDimensions(
    productKey: string,
    productVersion: number,
    productOptions: Record<string, string>,
    authToken: string
): Promise<ProductSurfaceDimensionsResult> {
    const surfacesQueryString = qs.stringify({
        optionSelections: productOptions,
        requestor: REQUESTOR
    });
    const url = `${SURFACE_PRODUCT_URL}/surfaces/${productKey}/${productVersion}?${surfacesQueryString}`;
    const response = await tryFetch({
        url,
        options: {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "uploadPrintClient:getProductSurfaceDimensions",
        friendlyDescription: "get product dimensions from product key"
    });
    return response;
}
