import React from "react";
import { Button } from "@shared/features/StudioChrome";
import { ColorIconType } from "@presentational";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useDesigner, useSelection, useRichTextAttribute, useActiveCanvas } from "@designer-suite";
import { getPremiumFinishes, itemHasPremiumFinish, selectedItemsAreOfTypes, wordArtFilter } from "@utilities";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { getIcon } from "../../../presentational/ColorPicker/getIcon";
import "./WordArtAndTextColorPicker.scss";
import { getIsColorpickerCompatible } from "../../color/colorUtil";
import type { Designer, ItemSelection } from "../../@types/designer";

export const colorPickerMessages = defineMessages({
    textColorPicker: {
        id: "easel.ui.wordArtAndTextColorPicker.textColorPicker",
        defaultMessage: "Text Color",
        description: {
            note: "Label and title for a button that allows for changing text colors"
        }
    }
});

export function getIsTextColorPickerCompatible(
    designer: Designer | undefined,
    selection: ItemSelection
): designer is Designer {
    return getIsColorpickerCompatible(designer, selection) && selectedItemsAreOfTypes(selection, [ItemTypes.TEXT]);
}

export function shouldTextColorPickerBeDisabled(selection: ItemSelection, canvas: Canvas | undefined) {
    const isRaisedFoil =
        selection[0] &&
        itemHasPremiumFinish(selection[0]) &&
        canvas &&
        getPremiumFinishes(canvas).some(finish => finish.color.includes("RaisedFoil"));
    return isRaisedFoil;
}

export const listenEvents = "model:change:content";

export function WordArtAndTextColorPicker() {
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const selection = useSelection(`model:change:data ${listenEvents}`);
    const activeCanvas = useActiveCanvas();
    const { wordArtItems, otherItems } = wordArtFilter(designer, selection);
    const textColorValue = useRichTextAttribute("common", "color", "");
    const { setCurrentActiveDialog } = useActiveDialog();

    if (
        (!wordArtItems.length && !otherItems.length) ||
        !getIsTextColorPickerCompatible(designer, otherItems) ||
        (!wordArtItems.length && !textColorValue)
    ) {
        return null;
    }

    const colorIcon = getIcon(ColorIconType.TextColorIcon);

    return (
        <Button
            onClick={() => {
                setCurrentActiveDialog(DialogType.TextColorPicker);
            }}
            isSelected={false}
            isDisabled={shouldTextColorPickerBeDisabled(selection, activeCanvas)}
            className="colorpicker-button"
            title={t(colorPickerMessages.textColorPicker.id)}
            aria-label={t(colorPickerMessages.textColorPicker.id)}
        >
            {colorIcon}
        </Button>
    );
}

WordArtAndTextColorPicker.displayName = "WordArtAndTextColorPicker";
