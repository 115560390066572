/* eslint-disable no-underscore-dangle */
// Disabled this lint check for file as the per line lint disable was creating circular formating rule conflicts
import { useEffect } from "react";
import { useDesigner } from "@designer-suite";
import { isWordArt } from "@utilities";
import { ShapeTypes } from "@cimpress-technology/svg-path";
import { useAppSelector } from "@shared/redux";

export const imageCropEvent = "image:cropped";
export const autoRotationEvent = "auto-rotation";

/**
 * Calculates the center point Y value of an object on the canvas
 * @param top The top of the object on the canvas
 * @param height The height of the object on the canvas
 * @returns {number} Center Point Y
 */
function calculateCenterPointY(top: number, height: number): number {
    return top + height / 2;
}

export function usePresentationFolderDropzone() {
    const designer = useDesigner();
    const { flipLineLocation } = useAppSelector(state => state.studioConfiguration);

    useEffect(() => {
        if (!designer || !flipLineLocation) {
            return;
        }

        const currentThreshold = flipLineLocation;

        /**
         * Rotates an item 180 degrees if it's not 90 or 270
         * @param {ItemViewModel} itemViewModel Item view model of an item on the canvas
         */
        function rotateItem(itemViewModel: ItemViewModel) {
            if (!designer) return;

            const rotation = itemViewModel.model.get("rotation");
            const isArrowShape =
                itemViewModel.get("module") === "ShapeViewModel" &&
                itemViewModel.model.get("studioMetadata")?.shapeMetadata?.type === ShapeTypes.Arrow;
            if (
                (!isArrowShape && (rotation === 90 || rotation === 270)) ||
                (isArrowShape && (rotation === 0 || rotation === 180))
            ) {
                return;
            }

            // Using the updateItem ensures that the change is registered with undo/redo
            designer.api.design.updateItem(itemViewModel.id, (mutableItem: MutableItem) => {
                mutableItem._itemViewModel.model.set("rotation", (rotation + 180) % 360);
                const itemType = isWordArt(mutableItem) ? "WORDART" : mutableItem.itemType;
                designer.eventBus.trigger(autoRotationEvent, itemType);
            });
        }

        /**
         * Rotates currently selected item if center point is below threshold.
         * Intented for events that occur on single items (add image, rotation)
         * @param {ItemViewModel} itemViewModel Item view model of an item on the canvas
         */
        function rotateIfNeeded(itemViewModel: ItemViewModel) {
            const currentCenterPointY = calculateCenterPointY(
                itemViewModel.model.get("top"),
                itemViewModel.model.get("height")
            );
            if (currentThreshold && currentCenterPointY >= currentThreshold) {
                rotateItem(itemViewModel);
            }
        }

        /**
         * When an item or items are added to canvas, this will loop through all items added and
         * pass the itemViewModel to the rotateIfNeeded function
         */
        const updateRotationOfAddedItem = (event: EventData) => {
            const { items } = event;

            if (items.length > 0) {
                items.forEach(item => {
                    const itemViewModel = item._itemViewModel;
                    rotateIfNeeded(itemViewModel);
                });
            }
        };

        /**
         * Check if an item passes through the drop zone y threshold
         * @param {ItemViewModel} itemViewModel Item view model of an item on the canvas
         * @param {number} threshold This is the y value that divides the two dropzones
         * @returns {boolean} True if dropzone has changed, otherwise false
         */
        function dropZoneChanged(itemViewModel: ItemViewModel, threshold: number): boolean {
            // The center point is determined by getting the top value of the item view model and adding half its height
            const currentCenterPointY = calculateCenterPointY(
                itemViewModel.model.get("top"),
                itemViewModel.model.get("height")
            );

            const previousTop =
                itemViewModel.model._previousAttributes.top === undefined
                    ? 0
                    : itemViewModel.model._previousAttributes.top;

            const previousHeight =
                itemViewModel.model._previousAttributes.height === undefined
                    ? 0
                    : itemViewModel.model._previousAttributes.height;

            const prevCenterPointY = calculateCenterPointY(previousTop, previousHeight);
            return (
                (currentCenterPointY >= threshold && prevCenterPointY < threshold) ||
                (currentCenterPointY < threshold && prevCenterPointY >= threshold)
            );
        }

        /**
         * Iterates through item view models and applies rotation if drop zone changes on drag stop & resize
         * @param {[ItemViewModel]} event An array of item view models of the selection of dropped/resized items
         */
        function updateRotationOnApplicableItems(event: ItemViewModel[]) {
            event.forEach(item => {
                if (dropZoneChanged(item, currentThreshold)) {
                    rotateItem(item);
                }
            });
        }
        designer.eventBus.on(designer.eventBus.events.dragStop, updateRotationOnApplicableItems);
        designer.eventBus.on(designer.eventBus.events.resizeStop, updateRotationOnApplicableItems);
        designer.eventBus.on(imageCropEvent, rotateIfNeeded);
        const unsubscribeAdd = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_ADDED,
            updateRotationOfAddedItem
        );

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(designer.eventBus.events.dragStop, updateRotationOnApplicableItems);
            designer.eventBus.off(designer.eventBus.events.resizeStop, updateRotationOnApplicableItems);
            designer.eventBus.off(imageCropEvent, rotateIfNeeded);
            unsubscribeAdd();
        };
    }, [designer, flipLineLocation]);
}
