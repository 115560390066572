import { useHotkeys } from "react-hotkeys-hook";
import debounce from "lodash/debounce";
import { keyboardShortcutEvents, useOSBasedHotKeys } from "@shared/features/KeyboardShortcuts";

type EventToCallbacks = {
    keyboardShortcutEvents?: Map<string, (arg0: any) => any>;
};
declare type AvailableTags = "INPUT" | "TEXTAREA" | "SELECT";

const enableOnTags: AvailableTags[] = ["INPUT", "TEXTAREA", "SELECT"];
const options = {
    enabled: true,
    enableOnTags
};

export type keyPressProps = {
    eventName: string;
    event?: Event;
};
const DEBOUNCE_DELAY = 100;
export const useAttachHotKeys = (
    handleHotKeyPress: (props: keyPressProps) => any,
    registeredFunctions: EventToCallbacks
) => {
    const getOSBasedHotKeys = useOSBasedHotKeys();
    const debouncedHandleHotKeyPress = debounce(handleHotKeyPress, DEBOUNCE_DELAY);

    const handlerWithPreventDefault = (e: Event, eventName: string) => {
        e.preventDefault();
        debouncedHandleHotKeyPress({ eventName });
    };

    // increase-font-size
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.increaseFont),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.increaseFont),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // decrease-font-size
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.decreaseFont),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.decreaseFont),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // zoom to fit
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.zoomToFit),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.zoomToFit),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // zoom in
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.zoomIn),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.zoomIn),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // canvas zoom out
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.zoomOut),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.zoomOut),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // bold
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.bold),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.bold),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // italics
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.italics),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.italics),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // add-text
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.addText),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.addText),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // underline
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.underline),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.underline),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // duplicate
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.duplicate),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.duplicate),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // add rectangle
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.addRectangle),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.addRectangle),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // add line
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.addLine),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.addLine),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // add circle
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.addCircle),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.addCircle),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // lock-unlock
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.toggleItemLock),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.toggleItemLock),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // open-font-browser
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.toggleFontBrowser),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.toggleFontBrowser),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // arrange Forward
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.arrangeForward),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.arrangeForward),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // arrange Backward
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.arrangeBackward),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.arrangeBackward),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // arrange to Front
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.arrangeToFront),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.arrangeToFront),
        options,
        [handleHotKeyPress, registeredFunctions]
    );

    // arrange to Back
    useHotkeys(
        getOSBasedHotKeys(keyboardShortcutEvents.arrangeToBack),
        e => handlerWithPreventDefault(e, keyboardShortcutEvents.arrangeToBack),
        options,
        [handleHotKeyPress, registeredFunctions]
    );
};
