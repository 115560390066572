import React, { useEffect } from "react";
import classNames from "classnames";
import { EASEL_EVENTS } from "@utilities";
import { Button, Icon } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useDesigner, useMobileText } from "@designer-suite";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";

import "./addbutton.scss";

const messages = defineMessages({
    addButton: {
        id: "studio.buttons.addButton",
        defaultMessage: "Add an element",
        description: {
            note: "alt text for a + button that allows the user to add elements to their design"
        }
    }
});

export const showButton = (currentActiveDialog: DialogType) => {
    switch (currentActiveDialog) {
        case DialogType.ImagePlaceholderTools:
        case DialogType.ImageTools:
        case DialogType.QRCodesTools:
        case DialogType.TablesTools:
        case DialogType.TextTools:
        case DialogType.ShapeTools:
        case DialogType.WordArtTools:
        case DialogType.TeamsTools:
        case DialogType.ItemReferenceTools:
            return true;
        default:
            return false;
    }
};

export const AddButton = () => {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const { isTextInputFocused } = useMobileText();
    const {
        currentActiveDialog,
        setCurrentActiveDialog,
        addButtonOffset: bottom,
        setAddButtonOffset
    } = useActiveDialog();
    const { trackEvent } = useTrackEvents();

    useEffect(() => {
        if (!designer) {
            return;
        }
        const { eventBus } = designer;
        const {
            events: { select, cutItems, undo, redo }
        } = eventBus;
        const resetToNone = () => {
            // Reset this for animation
            setAddButtonOffset(-80);
            setCurrentActiveDialog(DialogType.None);
        };

        const onSelect = (items: any) => {
            if (items.length === 0) {
                resetToNone();
            }
        };

        const onHistory = () => {
            if (designer.selectionManager.models.length === 0) {
                resetToNone();
            }
        };

        eventBus.on(select, onSelect);
        eventBus.on(EASEL_EVENTS.DELETED_CANVAS_ITEM, resetToNone);
        eventBus.on(cutItems, resetToNone);
        eventBus.on(undo, onHistory);
        eventBus.on(redo, onHistory);
        // eslint-disable-next-line consistent-return
        return () => {
            eventBus.off(select, onSelect);
            eventBus.off(EASEL_EVENTS.DELETED_CANVAS_ITEM, resetToNone);
            eventBus.off(cutItems, resetToNone);
            eventBus.off(undo, onHistory);
            eventBus.off(redo, onHistory);
        };
    }, [designer, setCurrentActiveDialog, setAddButtonOffset]);

    const hideAddButton = !showButton(currentActiveDialog) || isTextInputFocused;

    const onClick = () => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ADD_BUTTON });
        designer?.selectionManager.select([]);
        setCurrentActiveDialog(DialogType.None);
    };

    return (
        <Button
            className={classNames("add-button", hideAddButton ? "add-button-hidden" : "")}
            onClick={onClick}
            style={{
                bottom: `${currentActiveDialog !== DialogType.None ? bottom : -80}px`
            }}
            skin="primary"
            buttonShape="round"
            data-dcl-prevent-canvas-items-deselection
            aria-label={t(messages.addButton.id)}
            data-testid="add-content-button"
        >
            <Icon skin="white" iconType="plus" />
        </Button>
    );
};

AddButton.displayName = "AddButton";
