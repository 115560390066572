import React, { useEffect, useState, useRef } from "react";
// For some reason the non-lodash version doesn't work here.
// eslint-disable-next-line you-dont-need-lodash-underscore/throttle
import throttle from "lodash/throttle";
import { Hsl } from "@presentational";

import { drawImage, getHslForImage } from "./colorUtil";

function getImageAsPromise(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        // data URLs are never cross origin. If we set crossOrigin for these
        // it breaks in iOS.
        if (!url.includes("data:")) {
            image.crossOrigin = "Anonymous";
        }
        image.onload = () => resolve(image);
        image.onerror = () => reject(image);
        image.src = url;
    });
}

const throttledDrawImage = throttle(drawImage, 150);

interface Props {
    imageViewModel: ImageViewModel;
    hueOffset: number;
    hueMultiplier: number;
    saturationMultiplier: number;
    lightnessMultiplier: number;
}

export function RecolorizerFilter({
    imageViewModel,
    hueOffset,
    hueMultiplier,
    saturationMultiplier,
    lightnessMultiplier
}: Props) {
    const modificationCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const visibleCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [hslValues, setHslValues] = useState<Hsl[]>([]);

    if (!modificationCanvasRef.current) {
        modificationCanvasRef.current = document.createElement("canvas");
    }

    if (!visibleCanvasRef.current) {
        const canvasId = `${imageViewModel.id}-temp-recolorization-canvas`;
        visibleCanvasRef.current = document.getElementById(canvasId) as HTMLCanvasElement;

        if (!visibleCanvasRef.current) {
            visibleCanvasRef.current = document.createElement("canvas");
            visibleCanvasRef.current.id = canvasId;

            // If Photon is enabled, add this after photon
            const photonElement = document.getElementById(`photon-container-${imageViewModel.id}`);
            if (photonElement) {
                photonElement.insertAdjacentElement("afterend", visibleCanvasRef.current);
            } else {
                const imageElement = document.getElementById(`${imageViewModel.id}`);
                if (imageElement) {
                    imageElement.insertAdjacentElement("afterend", visibleCanvasRef.current);
                }
            }
        }

        visibleCanvasRef.current.style.zIndex = imageViewModel.model.get("zIndex");
        visibleCanvasRef.current.style.left = `${imageViewModel.get("previewPosition").left}px`;
        visibleCanvasRef.current.style.top = `${imageViewModel.get("previewPosition").top}px`;
        visibleCanvasRef.current.style.transform = `rotate(${360 - imageViewModel.get("rotation")}deg)`;
        visibleCanvasRef.current.style.width = `${imageViewModel.get("handleSize").width}px`;
        visibleCanvasRef.current.style.height = `${imageViewModel.get("handleSize").height}px`;
        visibleCanvasRef.current.style.position = "absolute";
    }

    useEffect(() => {
        if (imageViewModel) {
            imageViewModel.get("urlWithoutColorAdjustment").then((url: string) =>
                getImageAsPromise(url).then(image => {
                    if (modificationCanvasRef.current && visibleCanvasRef.current) {
                        const modificationCanvas = modificationCanvasRef.current;
                        const visibleCanvas = visibleCanvasRef.current;
                        const context = modificationCanvas.getContext("2d")!;
                        const { width, height } = image;
                        modificationCanvas.width = width;
                        modificationCanvas.height = height;
                        visibleCanvas.width = width;
                        visibleCanvas.height = height;

                        context.drawImage(image, 0, 0);

                        const pixels = context.getImageData(0, 0, width, height).data;
                        const hsl = getHslForImage(pixels);
                        setHslValues(hsl);
                    }
                })
            );
        }
    }, [imageViewModel]);

    useEffect(() => {
        if (modificationCanvasRef.current && visibleCanvasRef.current && hslValues.length !== 0) {
            visibleCanvasRef.current.style.display = "initial";
            throttledDrawImage(
                modificationCanvasRef.current,
                visibleCanvasRef.current,
                hslValues,
                hueOffset,
                hueMultiplier,
                saturationMultiplier,
                lightnessMultiplier
            );
        }

        return () => {
            if (visibleCanvasRef.current) {
                visibleCanvasRef.current.style.display = "none";
            }
        };
    }, [hslValues, hueOffset, hueMultiplier, saturationMultiplier, lightnessMultiplier]);

    return <></>;
}

RecolorizerFilter.displayName = "RecolorizerFilter";
