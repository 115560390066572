import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { formatUpload, validateAssetAndRetrieveDimensions } from "@shared/features/UploadsAndAssets";
import { UnitlessDimensions } from "@design-stack-ct/utility-core";
import { Designer } from "../../../../../easel/designer-suite/@types/designer";
import { PlacementStrategyCallback } from "../../../../../easel";

const getImageData = (asset: VistaAsset) => {
    const { data } = asset;
    if (data?.info?.image?.format === "pdf" || data?.info?.image?.format === "unknown") {
        return {
            isLogo: data?.properties.analysisIsLogo as string,
            isPhoto: data?.properties.analysisIsPhoto as string,
            isVector: data?.properties.analysisIsVector as string,
            lineartness: data?.properties.analysisLineartness as string
        };
    }
    return {
        isLogo: (data?.properties.analysisIsLogo as string) || (data?.info?.image?.isLogo ? "True" : "False"),
        isPhoto: (data?.properties.analysisIsPhoto as string) || (data?.info?.image?.isPhoto ? "True" : "False"),
        isVector: (data?.properties.analysisIsVector as string) || (data?.info?.image?.isVector ? "True" : "False"),
        // the linenartness has to be a string
        lineartness: (data?.properties.analysisLineartness as string) || `${data?.info?.image?.lineartness}`
    };
};

export interface AddImageCallToActionParams {
    designer?: Designer;
    asset: VistaAsset;
    pageNumber: number;
    callback: PlacementStrategyCallback;
    image?: CanvasItem;
    mmPosition?: CanvasPosition;
}

const createModel = (params: AddImageCallToActionParams, dimensions: UnitlessDimensions) => {
    const { asset, pageNumber, designer } = params;
    if (!designer) {
        return undefined;
    }
    const { data } = asset;

    const imageData = getImageData(asset);
    const analysisIsLogo = imageData.isLogo;
    const analysisIsPhoto = imageData.isPhoto;
    const analysisIsVector = imageData.isVector;
    const analysisLineartness = imageData.lineartness;

    const uploadedFileName = (data?.properties.uploadedFileName as string) || data?.info?.storage?.fileName;
    const originalFileContentType =
        (data?.properties.thumbFileContentType as string) || data?.info?.storage?.contentType;
    const originalFileSize = (data?.properties.originalFileSize as number) || data?.info?.storage?.fileSizeBytes;

    const convertedData = new Date(0);
    convertedData.setMilliseconds(data?.createdMs || 0);
    const processingTimeEnd = (data?.properties.processingTimeEnd as string) || convertedData.toISOString();

    const urlOptions = pageNumber !== 1 ? { pageNum: pageNumber } : undefined;

    const formattedUpload = {
        ...formatUpload({
            ...data?.properties,
            pageNumber,
            originalFileContentType,
            processingTimeEnd,
            originalFileSize,
            uploadedFileName,
            analysisIsLogo,
            analysisIsPhoto,
            analysisIsVector,
            analysisLineartness,
            uploadId: data?.id,
            requestId: data?.id,
            url: asset.getUrl(),
            printUrl: asset.print.getUrl(),
            previewUrl: asset.webPreview.getUrl(urlOptions),
            printPixelHeight: dimensions.height,
            printPixelWidth: dimensions.width
        }),
        asset,
        previousUpload: true,
        createdMs: data?.createdMs
    };
    const model = designer.uploadManager.createModels(formattedUpload);
    return model;
};

const addImageToCanvas = (params: AddImageCallToActionParams, dimensions: UnitlessDimensions) => {
    const { callback, image, mmPosition } = params;
    const model = createModel(params, dimensions);
    if (model) {
        const pages = model.get("pages");
        const page = pages.at(0);
        const selection = image ? [image] : undefined;
        callback?.({ upload: model, page, selection, mmPosition });
    }
};

export const addImageCallToAction = async (params: AddImageCallToActionParams) => {
    const { asset, pageNumber } = params;

    const dimensions = await validateAssetAndRetrieveDimensions(asset, pageNumber);

    addImageToCanvas(params, dimensions);
};
