import { Button } from "@vp/swan";
import { CheckmarkIcon } from "@shared/features/StudioChrome";
import React from "react";
import { WesSortOptions } from "@shared/utils/Work";

interface Props {
    className: string;
    value: WesSortOptions;
    title: string;
    selectedOption: string;
    onClick: (value: WesSortOptions, title: string) => void;
}

export function MyProjectsSortDropdownOption({ className, value, title, selectedOption, onClick }: Props) {
    const isSelected = value === selectedOption;
    return (
        <Button
            className={className}
            skin="unstyled"
            onClick={() => onClick(value, title)}
            role="radio"
            aria-checked={isSelected}
        >
            {title}
            {isSelected && <CheckmarkIcon />}
        </Button>
    );
}
MyProjectsSortDropdownOption.displayName = "MyProjectsSortDropdownContentOption";
