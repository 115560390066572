import React, { forwardRef, Ref, useCallback, useMemo } from "react";
import { useStudioLayout, BOTTOM_BAR } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { useActiveCanvas } from "@designer-suite";
import { getDesignDocumentFromDesigner } from "@utilities";
import { useTriggerCanvasChange } from "src/studioFive/hooks/useTriggerCanvasChange";
import { CanvasSelectorMini } from "@shared/features/CanvasSelectors";
import BottomBar from "../BottomBar";
import { MobileSidebar } from "../Sidebar";

export const EaselContentsBottomContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const { isSmall, isMedium } = useStudioLayout();
    const multiPanelDesign = useAppSelector(state => state.designDocumentHasMultiplePanels);
    const activeCanvas = useActiveCanvas();

    const minHeight = useMemo(() => {
        if (isSmall) {
            // These values are the height of the canvas selector and mobile sidebar
            return multiPanelDesign
                ? BOTTOM_BAR.MOBILE.MIN_HEIGHT_WITH_MULTI_PANEL
                : BOTTOM_BAR.MOBILE.MIN_HEIGHT_WITHOUT_MULTI_PANEL;
        }
        if (isMedium) {
            // These values the mini canvas selector's 38px height + 3px top padding + 3px bottom padding + 10px bottom margin (this is the extra 54px)
            // The 52px is the space allocated for the bottom bar.
            return multiPanelDesign
                ? BOTTOM_BAR.TABLET.MIN_HEIGHT_WITH_MULTI_PANEL
                : BOTTOM_BAR.TABLET.MIN_HEIGHT_WITHOUT_MULTI_PANEL;
        }
        return undefined;
    }, [isSmall, isMedium, multiPanelDesign]);

    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(false), []);
    const triggerCanvasChange = useTriggerCanvasChange();

    return (
        <div ref={ref} style={{ minHeight }} className="bottom-container">
            <CanvasSelectorMini
                activeCanvasName={activeCanvas?.name}
                getDocument={getDocument}
                triggerCanvasChange={triggerCanvasChange}
            />
            {isSmall ? (
                <div className="mobile-sidebar-container">
                    <MobileSidebar />
                </div>
            ) : (
                <BottomBar />
            )}
        </div>
    );
});

EaselContentsBottomContainer.displayName = "EaselContentsBottomContainer";
