import { getItemStudioMetadataProperty, isCalendarGrid } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import React from "react";
import { useDesigner } from "../designer/DesignerProvider";
import { useDocumentItems } from "../designer/useDocumentItems";
import { updateSelectedItems } from "../util";
import { CalendarYearSelector } from "./CalendarYearSelector";
import type { ItemSelection } from "../@types/designer";

function isYearField(item: CanvasItem) {
    return getItemStudioMetadataProperty(item, "template")?.purpose === "year";
}

export function shouldShowCalendarGridYearSelector(items: ItemSelection) {
    const calendarGridsExist = items.some(isCalendarGrid);
    return calendarGridsExist;
}

interface ItemCalendarGridYearSelectorProps {
    className?: string;
}

/**
 * This dropdown changes the calendar year for calendar products
 */
export function ItemCalendarGridYearSelector({ className }: ItemCalendarGridYearSelectorProps) {
    const designer = useDesigner();
    const items = useDocumentItems(true);
    const calendarGrid = items.find(isCalendarGrid);

    const onYearChange = (year: number) => {
        updateSelectedItems(
            designer,
            items.filter(item => isCalendarGrid(item) || isYearField(item)),
            (item: MutableItem) => {
                if (isCalendarGrid(item)) {
                    item.setData({
                        ...(item as MutableReferenceItem).data,
                        year
                    });
                } else if (isYearField(item)) {
                    if (item.itemType === ItemTypes.TEXT) {
                        item._itemViewModel.model.set("content", [
                            {
                                ...item._itemViewModel.model.get("content")[0],
                                content: year
                            }
                        ]);
                    } else if (item.itemType === ItemTypes.ITEM_REFERENCE) {
                        item.setData({
                            ...(item as MutableReferenceItem).data,
                            content: year
                        });
                    }
                }
            }
        );
    };
    if (!calendarGrid) {
        return null;
    }
    return (
        <CalendarYearSelector
            className={className}
            // @ts-ignore
            selectedYear={parseInt(calendarGrid.data.year, 10)}
            onYearChange={onYearChange}
        />
    );
}
ItemCalendarGridYearSelector.displayName = "ItemCalendarGridYearSelector";
