import { useAppSelector } from "@shared/redux";
import { useCallback } from "react";

export function useHasAvailableUpsell() {
    const surfaceUpsells = useAppSelector(state => state.surfaceUpsells);

    return useCallback(
        (name: string) => {
            const surfaceUpsell = surfaceUpsells[name];
            return !!surfaceUpsell;
        },
        [surfaceUpsells]
    );
}
