import { useDesigner, useSelection } from "@designer-suite";
import React, { createContext, ReactNode, useState, useMemo, useContext, useEffect } from "react";
import { getSelectedItemTypes } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useAppSelector } from "@shared/redux";
import type { Designer } from "src/easel/designer-suite/@types/designer";
import { useStylesInUse } from "../../designer-suite/hooks/useStylesInUse";

interface RecentFontsProviderValue {
    recentFonts: string[];
    loadedDocumentFonts: string[];
    updateRecentFonts: (font?: string) => void;
    lastSelectedFont?: string;
    updateLastSelectedFont: (font: string) => void;
}

export const RecentFontsContext = createContext<RecentFontsProviderValue | undefined>(undefined);

export const useRecentFontsContext = () => {
    const result = useContext(RecentFontsContext);
    if (!result) {
        throw Error("Missing context.  This must be called within a RecentFontsProvider");
    }
    return result;
};

export const getMostUsedFonts = (designer: Designer | undefined, limit: number | null = 3) => {
    let mostUsedFonts;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { textAttributes, wordArtAttributes, tableAttributes } = useStylesInUse(designer?.api.design.canvases || []);

    const textFontCount = textAttributes.getCount("fontFamily");
    const wordArtFontCount = wordArtAttributes.getCount("fontFamily");
    const tableFontCount = tableAttributes.getCount("fontFamily");

    const totalFontCount = textFontCount || {};
    const addFontsToTotal = (fontsToAdd: { [key: string]: number }) => {
        Object.keys(fontsToAdd).forEach(fontToAdd => {
            totalFontCount[fontToAdd] = (totalFontCount[fontToAdd] || 0) + fontsToAdd[fontToAdd];
        });
    };
    if (wordArtFontCount) {
        addFontsToTotal(wordArtFontCount);
    }
    if (tableFontCount) {
        addFontsToTotal(tableFontCount);
    }

    const sortedFonts = Object.keys(totalFontCount).sort(
        (fontA, fontB) => totalFontCount[fontB] - totalFontCount[fontA]
    );
    if (limit) {
        mostUsedFonts = sortedFonts.slice(0, limit);
    } else {
        mostUsedFonts = sortedFonts;
    }

    return mostUsedFonts;
};

interface Props {
    /* Any components that need fonts should be wrapped here */
    children: ReactNode;
}
export const RecentFontsProvider = ({ children }: Props) => {
    const designer = useDesigner();
    const selection = useSelection();
    const [recentFonts, setRecentFonts] = useState<string[]>([]);
    const [loadedDocumentFonts, setLoadedDocumentFonts] = useState<string[]>([]);
    const [lastSelectedFont, setLastSelectedFont] = useState<string>();
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    useEffect(() => {
        if (easelLoaded) {
            const mostUsedFonts = getMostUsedFonts(designer);
            const loadedDocumentFonts = getMostUsedFonts(designer, null);
            setRecentFonts(mostUsedFonts);
            setLoadedDocumentFonts(loadedDocumentFonts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [easelLoaded]);

    useEffect(() => {
        const selectionType = getSelectedItemTypes(selection);

        if (
            selection.length > 0 &&
            recentFonts.length === 0 &&
            [ItemTypes.TEXT, ItemTypes.TABLE, ItemTypes.WORDART].includes(selectionType[0])
        ) {
            setRecentFonts(getMostUsedFonts(designer));
        } else if (lastSelectedFont) {
            setRecentFonts([lastSelectedFont, ...recentFonts.filter(e => e !== lastSelectedFont)].slice(0, 3));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    const contextObject = useMemo(
        () => ({
            updateRecentFonts: (font?: string) => {
                if (font) {
                    setRecentFonts(current => {
                        return [font, ...current.filter(e => e !== font)].slice(0, 3);
                    });
                }
            },
            recentFonts,
            updateLastSelectedFont: (font: string) => {
                setLastSelectedFont(font);
            },
            lastSelectedFont,
            loadedDocumentFonts
        }),
        [lastSelectedFont, recentFonts, loadedDocumentFonts]
    );

    return <RecentFontsContext.Provider value={contextObject}>{children}</RecentFontsContext.Provider>;
};

RecentFontsProvider.displayName = "RecentFontsProvider";
