import { DialogType } from "@shared/features/ActiveDialog";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";

export function useClassifyPanel() {
    const classifyPanel = (item: ItemViewModel): DialogType => {
        switch (item.get("module")) {
            case "TextFieldViewModel":
                return DialogType.Text;
            // If we plan to use this functionality for icons, this will need to be updated
            case "UploadedImageViewModel":
                return DialogType.Images;
            case "LineViewModel":
            case "ShapeViewModel":
                return DialogType.Graphics;
            case "ItemReferenceViewModel":
                // Will need to change as other item ref types are added
                if (item.model.get("type") === ItemReferenceTypes.WORD_ART) {
                    return DialogType.Text;
                }
                if (item.model.get("type") === ItemReferenceTypes.TABLE) {
                    return DialogType.Tables;
                }
                if (item.model.get("type") === ItemReferenceTypes.QR_CODE) {
                    return DialogType.QRCodes;
                }
                break;
            default:
                return DialogType.None;
        }
        return DialogType.None;
    };

    return {
        classifyPanel
    };
}
