import { useMemo } from "react";
import { useDocumentImages } from "../../../../../easel/designer-suite/PanelComponents/Uploads/useDocumentImages";

/**
 * Returns a list of assets/page numbers that are used on the canvas.
 * @returns object with a list of asset id's and page numbers
 */
export function useAssetsOnCanvas() {
    const documentImages = useDocumentImages(false);

    const usedAssets = useMemo(() => {
        const usedAssetsRecord: Record<string, number[]> = {};
        documentImages.forEach(canvasItem => {
            const {
                _itemViewModel: { model }
            } = canvasItem;
            const id = model.get("requestId");
            const pageNumber = model.get("pageNumber");

            if (usedAssetsRecord[id]) {
                if (!usedAssetsRecord[id].includes(pageNumber)) {
                    usedAssetsRecord[id].push(pageNumber);
                }
            } else {
                usedAssetsRecord[id] = [pageNumber];
            }
        });
        const data = Object.entries(usedAssetsRecord).map(([assetId, pages]) => ({ assetId, pages }));

        return data;
    }, [documentImages]);

    return { usedAssets };
}
