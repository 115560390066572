export const countDecimals = (text: string) => {
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf("e-") > -1) {
        const [, trail] = text.split("e-");
        const deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (text.includes(".")) {
        return text.split(".")[1].length || 0;
    }
    return 0;
};
