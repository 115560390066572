import React from "react";
import { BreadcrumbList, BreadcrumbsContainer, Button, Icon } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";
import { TemplatePanelTitleItem } from "./TemplatePanelTitleItem";

const messages = defineMessages({
    templatePanelContentBackButton: {
        id: "studio.panels.template.backButton",
        defaultMessage: "Back",
        description: {
            note: "text for accessibility for the back button in the template panel"
        }
    }
});

export const TemplatePanelTitle = () => {
    const { stack, removeFromStack } = useTemplatePanel();
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    return (
        <BreadcrumbsContainer>
            {stack.length !== 1 && (
                <Button
                    skin="unstyled"
                    mr={2}
                    pb={!isSmall ? 1 : undefined}
                    className="studio-template-panel-content-back-button"
                    aria-label={t(messages.templatePanelContentBackButton.id)}
                    onClick={() => {
                        removeFromStack();
                    }}
                >
                    <Icon iconType="chevronLeft" aria-hidden />
                </Button>
            )}
            <BreadcrumbList>
                {stack.slice(-2).map((panelType: TemplatePanelContentTypes) => {
                    return <TemplatePanelTitleItem key={panelType} panelType={panelType} />;
                })}
            </BreadcrumbList>
        </BreadcrumbsContainer>
    );
};

TemplatePanelTitle.displayName = "TemplatePanelTitle";
