import React, { useCallback } from "react";
import { RenderImageProps } from "react-photo-gallery";
import classNames from "classnames";
import { UploadStrategies, useHandleImagePlacement } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import {
    ElementsPanelContentTypes,
    SIMTAG_PREFIX,
    updateRecentlyUsedElements,
    useElementsPanel
} from "@five/xerox/features/Elements";
import { useTrackAutoPlacement } from "@five/components/Panels/Images/useTrackAutoPlacement";
import { useAutoRemoveBackground } from "../../../hooks/useAutoRemoveBackground";
import { DP_IMAGE_PREFIX, updateRecentlyUsedList } from "./ImagePanelUtils";
import { ImageThumbnail } from "./ImageThumbnail";

const trackImageAddedClick = (id: string) => {
    if (id.startsWith(SIMTAG_PREFIX)) {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CLIPART,
            label: "Click clipart"
        });
    }
    if (id.startsWith(DP_IMAGE_PREFIX)) {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_IMAGE,
            label: "Click image"
        });
    }
};

const trackImageAddedDrag = (id: string) => {
    if (id.startsWith(SIMTAG_PREFIX)) {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.DRAG_CLIPART_ADD,
            label: "Drag clipart"
        });
    }
    if (id.startsWith(DP_IMAGE_PREFIX)) {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.DRAG_IMAGE_ADD,
            label: "Drag image"
        });
    }
};

export const renderImage = (props: RenderImageProps) => {
    // @ts-ignore
    return <ImageRenderer key={props.photo?.upload?.cid} {...props} />;
};

export const ImageRenderer = (props: RenderImageProps) => {
    const {
        left,
        top,
        // @ts-ignore
        photo: { src, id, width, height, upload, svgUrl, imageSource, onDone }
    } = props;

    const { isSmall } = useStudioLayout();
    const toggleBackground = useAutoRemoveBackground();
    const { isXerox } = useXerox();
    const { currentActiveDialog } = useActiveDialog();
    const { stack } = useElementsPanel();
    const onTrackEvent = useTrackAutoPlacement();

    const updateRecentsForCBElements = useCallback(() => {
        const uploadHeight = upload.attributes.pages.models[0].get("height");
        const uploadWidth = upload.attributes.pages.models[0].get("width");
        const printPixelHeight = typeof uploadHeight === "number" ? uploadHeight : parseInt(uploadHeight, 10);
        const printPixelWidth = typeof uploadWidth === "number" ? uploadWidth : parseInt(uploadWidth, 10);
        // update recents for competitive baselining elements panel
        updateRecentlyUsedElements({
            id,
            src,
            width,
            height,
            previewUrl: src,
            svgUrl,
            printUrl: upload.get("printUrl"),
            printPixelHeight,
            printPixelWidth,
            imageSource
        });
    }, [height, id, imageSource, src, svgUrl, upload, width]);

    const onUploadAdded = useCallback(() => {
        if (isXerox) {
            updateRecentsForCBElements();
        }
        if (upload.id.startsWith(DP_IMAGE_PREFIX)) {
            updateRecentlyUsedList(upload.id);
        }
        if (isSmall && onDone) {
            onDone();
        }
        trackImageAddedClick(upload.id);
    }, [isXerox, isSmall, onDone, updateRecentsForCBElements, upload]);

    const { onImageClicked, onImageDoubleClicked } = useHandleImagePlacement({
        placementStrategy: isSmall ? UploadStrategies.AutofillAndPlace : UploadStrategies.AutofillSinglePlaceholder,
        onUploadAdded
    });

    const onStop = useCallback(() => {
        if (isXerox) {
            updateRecentsForCBElements();
        }
        toggleBackground(); // remove background when dragging from discovery panel
        trackImageAddedDrag(upload.id);
    }, [isXerox, toggleBackground, updateRecentsForCBElements, upload]);

    // only show expanded preview for Elements Panel drilled in views
    const showExpandedPreview =
        currentActiveDialog === DialogType.Images || (currentActiveDialog === DialogType.Elements && stack.length > 1);
    const currentStack = stack[stack.length - 1];
    const isRecentElements =
        currentActiveDialog === DialogType.Elements && currentStack === ElementsPanelContentTypes.Recent;

    const incompatibleXeroxAspectRatio = width / height > 1.5 && isXerox;

    return (
        <ImageThumbnail
            imageUrl={src}
            svgUrl={svgUrl}
            style={{
                left,
                top,
                width,
                height
            }}
            key={`image-${upload.cid}}`}
            upload={upload}
            onClick={props => {
                onTrackEvent();
                onImageClicked(props);
            }}
            onDoubleClick={props => {
                onTrackEvent();
                onImageDoubleClicked(props);
            }}
            showPreviewButton={!isSmall && showExpandedPreview}
            onStop={onStop}
            width={width}
            height={height}
            className={classNames(
                incompatibleXeroxAspectRatio && "incompatibleAspectRatio",
                isRecentElements && {
                    "image-thumbnail-recent-elements": true,
                    "recent-image": imageSource === "DepositPhotos",
                    "recent-clipart": !imageSource
                }
            )}
            updateRecentElements={updateRecentsForCBElements}
        />
    );
};

ImageRenderer.displayName = "ImageRenderer";
