import React from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import {
    DraggableArrow,
    DraggableDoubleArrow,
    DraggableEllipse,
    DraggableLine,
    DraggablePolygon,
    DraggableRectangle,
    DraggableStarBurst,
    DraggableTriangle
} from "../Shapes";

type Props = {
    className?: string;
};

export default function ShapesPanel({ className }: Props) {
    const numberOfSkeletons = 3;

    return (
        <>
            <StudioIsInteractiveSwitch>
                <div className={classNames("shape-icon-thumbnails", className)}>
                    <DraggableRectangle />
                    <DraggableEllipse />
                    <DraggableTriangle />
                    <DraggablePolygon />
                    <DraggableLine />
                    <DraggableArrow />
                    <DraggableDoubleArrow />
                    <DraggableStarBurst />
                </div>
                <>
                    {[...Array(numberOfSkeletons)].map((_n, i) => (
                        <div key={`shape-skeleton${i}`}>
                            <Skeleton width={"100%"} height={66} />
                        </div>
                    ))}
                </>
            </StudioIsInteractiveSwitch>
        </>
    );
}

ShapesPanel.displayName = "ShapesPanel";
