import { ImageItem } from "@design-stack-vista/image-library-react";
import { update, get, keys, set } from "idb-keyval";
import { v4 as uuidv4 } from "uuid";
import { getQueryParams, windowExists } from "@shared/utils/WebBrowser";
import { formatUpload, UploadTypes } from "@shared/features/UploadsAndAssets";
import type { StudioMetadataItem } from "@shared/utils/Metadata";
import type { Designer } from "src/easel/designer-suite/@types/designer";

const host = getQueryParams().imageLibraryUrl || IMAGE_LIBRARY_SERVICE_URL;

export type DiscoveryPhotoItem = {
    src: string;
    width: number;
    height: number;
    onDone?: () => void;
    upload: any;
    key: string;
};

export const DP_IMAGE_PREFIX = "DP_";
export const NP_IMAGE_PREFIX = "NP_";
export const NOUN_PROJECT_SOURCE = "NounProject";

export const constructNounProjectMetadata = (sourceId: string | undefined): StudioMetadataItem => ({
    thirdPartyUploadInfo: {
        uploadType: UploadTypes.ICON,
        source: NOUN_PROJECT_SOURCE,
        sourceId
    }
});

const imageType = {
    DepositPhotos: {
        prefix: DP_IMAGE_PREFIX,
        urlPath: "image"
    },
    NounProject: {
        prefix: NP_IMAGE_PREFIX,
        urlPath: "icon"
    }
};

export const createModel = (imageResult: ImageItem, designer: Designer) => {
    if (!designer) {
        return undefined;
    }
    const { prefix, urlPath } = imageType[imageResult.imageSource];
    const uploadId = `${prefix}${imageResult.id}_${uuidv4()}`;
    const url = `${host}/v1/${urlPath}/${imageResult.id}?requestor=studio`;
    const fileType = `${imageResult.type}/${imageResult.extension}`;
    const upload = {
        ...imageResult,
        originalFileContentType: fileType,
        printUrl: url,
        uploadId,
        url,
        status: "50",
        analysisIsLogo: "False",
        analysisIsPhoto: "True",
        analysisIsVector: "False",
        analysisLineartness: "1",
        printPixelHeight: (imageResult.height ? parseInt(imageResult.height, 10) : 0).toString(),
        printPixelWidth: (imageResult.width ? parseInt(imageResult.width, 10) : 0).toString()
    };
    const formatedUpload = formatUpload(upload);
    const model = designer.uploadManager.createModels(formatedUpload);
    return model;
};

export const createGalleryItems = (
    results: ImageItem[],
    designer: Designer,
    onDone?: () => void
): DiscoveryPhotoItem[] => {
    return (
        results
            .filter((img: ImageItem) => img.width && img.height)
            // remove duplicate ids
            .filter((img, index, imageItems) => imageItems.findIndex(img2 => img2.id === img.id) === index)
            .map((img: ImageItem) => {
                const width = img.width ? parseInt(img.width, 10) : 0;
                const height = img.height ? parseInt(img.height, 10) : 0;
                const value: DiscoveryPhotoItem = {
                    src: img.previewUrl,
                    width,
                    height,
                    upload: createModel(img, designer),
                    onDone,
                    key: img.id
                };
                return value;
            })
    );
};

export type CalculatePanelHeightParams = {
    isFullHeight: boolean;
    isMobile: boolean;
    isDefaultPanel?: boolean;
    isRecentlyUsed?: boolean;
};

export const calculatePanelHeight = ({
    isFullHeight,
    isMobile,
    isDefaultPanel,
    isRecentlyUsed
}: CalculatePanelHeightParams) => {
    // The 157 takes into account the items on top of the discoverpanel.
    // (Tabs, search... etc) The 64 px is the margins.

    let retHeight = 40 + 64 + 157;

    if (isRecentlyUsed) {
        retHeight -= 28;
    }

    if (isMobile) {
        retHeight = 40;
        if (isDefaultPanel) {
            retHeight += 64;
        }
        if (!isFullHeight) {
            retHeight += 250;
        }
    }
    return retHeight;
};

const MAX_IMAGES_TO_KEEP = 200;

function addToArrayStart<T>(arr: T[] | undefined, entry: T, maxStorage?: number) {
    if (arr) {
        if (!arr.includes(entry)) {
            arr.unshift(entry);
            if (maxStorage && arr.length > maxStorage) {
                return arr.slice(arr.length - maxStorage, arr.length);
            }
        }
        return arr;
    }
    return [entry];
}

const RECENTLY_USED_IMAGES_KEY = "recentlyUsedImages";
const RECENTLY_SEARCHED_KEY = "recentlySearched";

// When an an images is used on the canvas add it to the list (or create the list if it doesn't exist
export const updateRecentlyUsedList = (idStr: string) => {
    if (!idStr?.includes(DP_IMAGE_PREFIX)) {
        return;
    }
    const id = parseInt(idStr.replace(DP_IMAGE_PREFIX, ""), 10);
    update(RECENTLY_USED_IMAGES_KEY, val => addToArrayStart(val, id, MAX_IMAGES_TO_KEEP));
};

// Get a comma separated list of recently used images
export const getRecentlyUsedImages = async () => {
    const previous = await get(RECENTLY_USED_IMAGES_KEY);
    return previous ? previous.join() : undefined;
};

// This function checks idb to see if the key is present
export const getShowRecentlyUsedImages = async () => {
    if (!windowExists()) {
        return false;
    }
    const keyList = await keys();
    return keyList.includes(RECENTLY_USED_IMAGES_KEY);
};

/**
 * Updates recently searched terms in idb with new input array
 * @param arr Array of recently searched terms
 */
export const updateRecentlySearchTerm = (arr: string[]): void => {
    if (!arr) {
        return;
    }
    set(RECENTLY_SEARCHED_KEY, arr);
};

/**
 * Function returns a promise that resolves into an array of recently searched terms (string)
 * @returns Promise<string[]>
 */
export const getRecentlySearchedTerms = () => {
    return get(RECENTLY_SEARCHED_KEY);
};

const checkOutOfBounds = (mousePos: CanvasPosition, canvasAttributes: Rectangle) => {
    const leftBorder = mousePos.left - canvasAttributes.left < 0;
    const topBorder = mousePos.top - canvasAttributes.top < 0;
    const rightBorder = mousePos.left - Math.round(canvasAttributes.left) >= canvasAttributes.width;
    const bottomBorder = mousePos.top - Math.round(canvasAttributes.top) >= canvasAttributes.height;

    return leftBorder || topBorder || rightBorder || bottomBorder;
};

export const isPointOnCanvas = (mousePos: CanvasPosition, designer: Designer) => {
    if (designer) {
        const canvasViewModel = designer.documentRepository.getActiveCanvasViewModel();
        const canvasElement = document.getElementById(canvasViewModel.id);
        const rect = canvasElement?.getBoundingClientRect();

        const canvasAttributes = {
            left: rect?.left || 0,
            top: rect?.top || 0,
            height: rect?.height || 0,
            width: rect?.width || 0
        };
        if (checkOutOfBounds(mousePos, canvasAttributes)) {
            return false;
        }
    }
    return true;
};
