import React, { ReactNode, useCallback, useMemo } from "react";
import {
    LocalizationProvider,
    UploadManagerProvider,
    useUploadConfiguration
} from "@design-stack-vista/upload-components";
import { setAlerts, useAppDispatch } from "@shared/redux";

import { useTranslationSSR } from "@vp/i18n-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { observer } from "mobx-react-lite";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { SubBrands, Tenants, type VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { handleError, newRelicWrapper } from "@shared/utils/Errors";
import { SingleColorImageModal } from "@easel";
import {
    UploadsPanelMessages,
    RedesignedUploadsPanelMessages,
    useUploadComponents,
    CustomUploadEvents,
    useVerifySherbertFolderExistence
} from "@shared/features/UploadsAndAssets";
import { getImageInfoByClassification, ImageInfo } from "@design-stack-ct/assets-sdk";
import { Events, fireGenericTrackingEvent } from "@shared/utils/Tracking";
import { useTrackAutoPlacement } from "@five/components/Panels/Images/useTrackAutoPlacement";
import { SherbertUploadsManager } from "./SherbertUploadsManager";
import { CustomDragLayer } from "./DragAndDrop/CustomDragLayer";
import { DropZone } from "./DragAndDrop/DropZone";

interface Props {
    children: ReactNode | ReactNode[];
}

const errorCode = 106;

const onUploadStartEvent = (assetPromises: Promise<VistaAsset | void>[], fileblobs: File[]) => {
    const promises = fileblobs.map(file => {
        try {
            return getImageInfoByClassification(file);
        } catch {
            return undefined;
        }
    });
    Promise.all(promises).then(infoList => {
        infoList.forEach((info, index) => {
            const metadata = extractUploadMetadata(fileblobs[index], info);
            fireGenericTrackingEvent({
                event: Events.StudioDiagnostic,
                eventDetail: "Upload Started",
                label: "Image",
                extraData: () => {
                    return metadata;
                }
            });
            newRelicWrapper.logPageAction("studio-onUpload", {
                ...metadata,
                fileName: metadata.name,
                clientsideProcessed: Boolean(info)
            });
        });
    });
};

function extractUploadMetadata(file: File, info?: ImageInfo) {
    return {
        fileType: file.type,
        // id: data.attributes?.id,  We don't have an id yet.
        name: file.name,
        size: file.size,
        clientInitiator: "studio",
        isLogo: !!info?.isLogo,
        isPhoto: !!info?.isPhoto,
        isVector: !!info?.isVector,
        lineartness: info?.lineartness
    };
}

export const SherbertUploadsWrapper = observer((props: Props) => {
    const { children } = props;
    const { auth, identity, isIdentityInitialized } = useIdentityContext();
    const uploadComponents = useUploadComponents();
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const onTrackEvent = useTrackAutoPlacement();

    const authProvider = useMemo(
        () => ({
            getAuthHeaders: async () => {
                if (!isIdentityInitialized) {
                    newRelicWrapper.noticeError("getAuthHeaders called before identity initialized");
                }
                return {
                    Authorization: `Bearer ${auth.getToken()}`
                };
            }
        }),
        [auth, isIdentityInitialized]
    );
    const {
        supportedFileTypes: { fileExtensionsAsString }
    } = useUploadConfiguration();
    const ownerId = getQueryParams().owner;
    const { isCareAgent } = identity;

    const onError = useCallback(
        (e: Error) => {
            if (e.name === "FileTypeNotSupportedError") {
                dispatch(
                    setAlerts({
                        alerts: [
                            {
                                key: "studio.components.upload.FileTypeNotSupported",
                                variables: {
                                    fileExtensions: fileExtensionsAsString
                                },
                                skin: "error"
                            }
                        ]
                    })
                );
            } else {
                handleError(e, errorCode, false, true, false);
            }
        },
        [dispatch, fileExtensionsAsString]
    );

    useVerifySherbertFolderExistence(Tenants.VistaPrint);

    if (!isIdentityInitialized) {
        return <>{children}</>;
    }

    return (
        <UploadManagerProvider
            authProvider={authProvider}
            subBrand={SubBrands.VistaPrint}
            experience="studio"
            writeTenant={Tenants.VistaPrint}
            onError={onError}
            onUpload={(assetPromises, fileblobs) => {
                onTrackEvent(fileblobs.length);
                onUploadStartEvent(assetPromises, fileblobs);
            }}
            // Using a limit that is both divisible by 2 and 3 makes it so regardless of
            //  if 2 or 3 columns is used it should complete the columns in a regular way
            // 30 is intended as a number which should have more images than most people can
            //  show on screen initially.
            fetchLimit={uploadComponents ? 30 : 500}
            // This loads before we redirect to force non-logged-in agents to login
            // That spams sherbert with bad folder retrieval/creation calls
            // So don't provide the folder name (which is only used for querying uploads for other users) unless we're sure this is an agent
            folderName={isCareAgent ? ownerId : undefined}
        >
            {/* We don't want to use the Uploads manager at all if we're using the upload components */}
            {!uploadComponents && <SherbertUploadsManager />}
            {uploadComponents && <CustomUploadEvents />}
            <LocalizationProvider
                localizedValues={{
                    deletingLabel: t(RedesignedUploadsPanelMessages.deleting.id),
                    deleteButtonTitle: t(UploadsPanelMessages.deleteButton.id),
                    assetInUseLabel: t(RedesignedUploadsPanelMessages.inUseLabel.id),
                    loadingLabel: t(RedesignedUploadsPanelMessages.loading.id),
                    uploadingLabel: t(UploadsPanelMessages.uploading.id),
                    deleteConfirmationKeys: {
                        closeButton: t(RedesignedUploadsPanelMessages.closeButton.id),
                        dialogTitle: t(RedesignedUploadsPanelMessages.modalDialogTitle.id),
                        dialogMessage: t(RedesignedUploadsPanelMessages.modalDialogBody.id),
                        cancelButton: t(RedesignedUploadsPanelMessages.cancelButton.id),
                        confirmButton: t(RedesignedUploadsPanelMessages.confirmButton.id)
                    }
                }}
            >
                <DndProvider backend={HTML5Backend}>
                    <CustomDragLayer />
                    <DropZone>
                        {children}
                        <SingleColorImageModal />
                    </DropZone>
                </DndProvider>
            </LocalizationProvider>
        </UploadManagerProvider>
    );
});

SherbertUploadsWrapper.displayName = "SherbertUploadsWrapper";
