/* eslint-disable max-len */
import React from "react";

export const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 16 17" fill="none" {...props}>
            <rect x={3.5} y={3.37891} width={7} height={9} rx={0.5} fill="none" stroke="currentColor" />
            <rect x={5.5} y={5.37891} width={7} height={9} rx={0.5} fill="none" stroke="currentColor" />
        </svg>
    );
};
CopyIcon.displayName = "CopyIcon";
