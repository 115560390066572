import classNames from "classnames";
import React, { Ref } from "react";
import * as styles from "./ImageThumbnailContainer.module.scss";

export const ImageThumbnailContainer = React.forwardRef(
    (props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
        const { children, className, onClick, onDoubleClick, onKeyDown } = props;

        return (
            <div
                className={classNames("image-thumbnail-container", styles.imageThumbnailContainer, className)}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                tabIndex={0}
                onKeyDown={onKeyDown}
                role="button"
            >
                {children}
            </div>
        );
    }
);

ImageThumbnailContainer.displayName = "ImageThumbnailContainer";
