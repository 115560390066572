import { useCanvasValidations } from "@shared/features/Validations";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import type { GetDocument } from "@shared/utils/CimDoc";
import { useCallback } from "react";
import { PreviewUrl } from "@shared/features/Previews";
import { CUSTOM_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import { useSelectCanvasOnChoice } from "./useSelectCanvasOnChoice";
import { DesignPanelType, useDesignDialog } from "../DesignDialogProvider";

interface Props {
    activeCanvasName: string | undefined;
    getDocument: GetDocument;
    triggerCanvasChange: TriggerCanvasChange;
    fromNextStep?: boolean;
}

export function useOnCanvasClick({ activeCanvasName, getDocument, triggerCanvasChange, fromNextStep }: Props) {
    const onSelectCanvas = useSelectCanvasOnChoice(getDocument);
    const { validateCanvasErrors } = useCanvasValidations();
    const { setDesignPanelType, setDialogCanvas, getHasDialogBeenSeen } = useDesignDialog();

    return useCallback(
        async (canvasData: PreviewUrl, onSelection?: () => void) => {
            const newCanvasName = canvasData.name;
            if (activeCanvasName === newCanvasName) {
                return;
            }

            if (validateCanvasErrors()) {
                triggerCanvasChange(newCanvasName);
                const [canvasOption, designDocument] = await Promise.all([
                    onSelectCanvas(newCanvasName),
                    getDocument()
                ]);

                const newPanel = designDocument.document.panels.find(panel => panel.name === newCanvasName);
                const newPanelIndex = designDocument.document.panels.findIndex(panel => panel.name === newCanvasName);
                const safeCanvasOption = canvasOption === undefined || canvasOption === CUSTOM_SELECTED_TEMPLATE;
                const hasItems =
                    newPanel?.images?.length ||
                    newPanel?.itemReferences?.length ||
                    newPanel?.textAreas?.length ||
                    newPanel?.shapes?.length;
                if (
                    newPanelIndex > 0 &&
                    newPanel?.colorMode !== "blank" &&
                    safeCanvasOption &&
                    !hasItems &&
                    !getHasDialogBeenSeen(newCanvasName)
                ) {
                    setDialogCanvas(canvasData);
                    if (fromNextStep) {
                        setDesignPanelType(DesignPanelType.ChangeAutoShowFromNext);
                    } else {
                        setDesignPanelType(DesignPanelType.ChangeAutoShow);
                    }
                }

                onSelection?.();
            }
        },
        [
            activeCanvasName,
            fromNextStep,
            getDocument,
            getHasDialogBeenSeen,
            onSelectCanvas,
            setDesignPanelType,
            setDialogCanvas,
            triggerCanvasChange,
            validateCanvasErrors
        ]
    );
}
