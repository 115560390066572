import React from "react";
import { DraggableShape } from "./DraggableShape";
import * as styles from "./DraggableLine.module.scss";

const shapes = [{ module: "Line" }];

export const DraggableLine = () => {
    return (
        <DraggableShape shapes={shapes} className={styles.draggableLine}>
            <svg>
                <line x1="0" x2="85%" y1="75%" y2="70%" />
            </svg>
        </DraggableShape>
    );
};

DraggableLine.displayName = "DraggableLine";
