import { useCallback, useMemo, useState } from "react";
import { useAppSelector, useAppDispatch, setAlerts, resetAlerts } from "@shared/redux";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { tryFetch } from "@shared/utils/Network";
import { Tenants, VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { generateQrUrl, GenerateQRUrlReturnType } from "@prepress/qrcode-upload-manager";
import { useCanvasSelectorPreviews } from "@shared/features/CanvasSelectors/hooks/useCanvasSelectorPreviews";
import { STUDIO_TRACKING_EVENTS, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";

type Props = {
    refreshAssetsCallback: () => void;
    assetStore: any;
    activeCanvasName?: string | undefined;
};

type QrCodeData = {
    qrCode: string;
    expiry: string;
};

const messages = defineMessages({
    uploadSuccessAlert: {
        id: "editorUI.uploads.uploadSuccessAlert",
        defaultMessage: "photos from phone successfully added",
        description: {
            note: "Toast that appears after retriving new photos from mobile upload"
        }
    }
});

export function useQRUrlGenerator({ refreshAssetsCallback, assetStore, activeCanvasName }: Props) {
    const { auth } = useIdentityContext();
    const dispatch = useAppDispatch();
    const canvasSelectorPreviewsData = useCanvasSelectorPreviews();

    const { customerSelectedProductOptions, mpvId, workName, productName, productKey, productVersion, locale } =
        useAppSelector(state => ({
            customerSelectedProductOptions: state.customerSelectedProductOptions,
            mpvId: state.mpvId,
            workName: state.workName,
            productName: state.productName,
            productKey: state.productKey,
            productVersion: state.productVersion,
            locale: state.locale
        }));

    const { t } = useTranslationSSR();

    const [qrCodeData, setQrCodeData] = useState<QrCodeData | undefined>(undefined);
    const [assetsRefreshed, setAssetsRefreshed] = useState<boolean>(false);

    const onQrcodeExpired = useCallback(() => {
        setQrCodeData(undefined);
    }, [setQrCodeData]);
    const authProvider = useMemo(
        () => ({
            getAuthHeaders: async () => ({
                Authorization: `Bearer ${auth.getToken()}`
            })
        }),
        [auth]
    );

    const onAssetUpdate = useCallback(
        assets => {
            const localAssetsIds: string[] = assetStore.assets.map((localAsset: VistaAsset) => localAsset?.data?.id);
            const newAssetsAvailable = assets.some((asset: VistaAsset) => {
                return !localAssetsIds.includes(asset?.data?.id || "");
            });
            if (newAssetsAvailable) {
                refreshAssetsCallback();
                if (assets.length > 0) {
                    activeCanvasName &&
                        fireDesignToolTrackingEvent({
                            eventDetail: STUDIO_TRACKING_EVENTS.QR_MOBILE_UPLOAD_COMPLETE.replace(
                                "[CanvasSide]",
                                activeCanvasName
                            ).replace("[Number of Images Uploaded]", `${assets.length}`),
                            label: "General"
                        } as any);
                    dispatch(
                        setAlerts({
                            alerts: [
                                {
                                    message: `${assets.length} ${t(messages.uploadSuccessAlert.id)}`,
                                    skin: "positive",
                                    key: ""
                                }
                            ]
                        })
                    );
                    setAssetsRefreshed(true);
                    setTimeout(() => {
                        dispatch(resetAlerts());
                        setAssetsRefreshed(false);
                    }, 5000);
                }
            }
        },
        [assetStore, dispatch, refreshAssetsCallback, t, activeCanvasName]
    );

    const formatQrUrl = useCallback(
        async ({ presignedUrlData }) => {
            const urlParams = new URLSearchParams(window.location.search);
            const previewImage = canvasSelectorPreviewsData?.canvasSelectorUrls?.[0]?.src;
            const metadata = {
                previewImage,
                productName: workName || productName || mpvId || urlParams.get("mpvId"),
                productSize: customerSelectedProductOptions.Size,
                side: activeCanvasName || "",
                key: productKey,
                version: productVersion
            };
            const qrUrlParams = new URLSearchParams({
                uploadUrl: presignedUrlData._links.self.href,
                expiresOn: presignedUrlData.policy.expiresOn,
                metadata: JSON.stringify(metadata)
            }).toString();
            let qrUrl = "";
            /*
            US EN-US - https://www.vistaprint.com/qlp/upload/
            US ES-US - https://www.vistaprint.com/es/qlp/upload/
            CA EN-CA - https://www.vistaprint.ca/qlp/upload/
            CA FR-CA - https://www.vistaprint.ca/fr/qlp/upload/
            */
            switch (locale) {
                case "es-us":
                    qrUrl = `https://www.vistaprint.com/es/qlp/upload/?${qrUrlParams}`;
                    break;
                case "es-ca":
                    qrUrl = `https://www.vistaprint.ca/qlp/upload/?${qrUrlParams}`;
                    break;
                    break;
                case "fr-ca":
                    qrUrl = `https://www.vistaprint.ca/fr/qlp/upload/?${qrUrlParams}`;
                    break;
                default:
                    qrUrl = `https://www.vistaprint.com/qlp/upload/?${qrUrlParams}`;
                    break;
            }

            try {
                const urlToken = Math.random().toString(36).substring(2, 10);
                await tryFetch({
                    url: `https://api.cork.cimpress.cloud/cimlink/v1/urls/${urlToken}`,
                    options: {
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${auth.getToken()}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ destination: qrUrl })
                    }
                });
                return Promise.resolve(`https://cim.link/${urlToken}`);
            } catch (e) {
                return Promise.resolve(qrUrl);
            }
        },
        [
            auth,
            canvasSelectorPreviewsData?.canvasSelectorUrls,
            customerSelectedProductOptions.Size,
            mpvId,
            productName,
            workName,
            activeCanvasName,
            productKey,
            productVersion,
            locale
        ]
    );

    const requestGenerateQR = useCallback(() => {
        generateQrUrl({
            assetStore,
            assetStoreParams: { authProvider, tenant: Tenants.VistaPrint },
            onAssetUpdate,
            baseUrl: "",
            // urlLife:"PT30S",
            onExpired: onQrcodeExpired,
            formatQrUrl
        }).then(({ qrcode, expiry }: GenerateQRUrlReturnType) => {
            setQrCodeData({ qrCode: qrcode, expiry });
        });
    }, [assetStore, authProvider, onAssetUpdate, onQrcodeExpired, formatQrUrl]);

    return useMemo(
        () => ({
            requestGenerateQR,
            qrCodeData,
            assetsRefreshed
        }),
        [qrCodeData, requestGenerateQR, assetsRefreshed]
    );
}
