/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect, useMemo, useContext } from "react";
import type { ExtendedSurfaceUpsell } from "@shared/redux";
import { useAppSelector } from "@shared/redux";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { DesignRequirements, isBlankAndHasNoUpsells, useDesignRequirementsContext } from "@shared/features/Product";
import type { DSS } from "@vp/types-ddif";
import produce from "immer";
import {
    type View,
    getAllViews,
    FAKE_INSTRUCTIONS,
    DESIGN_PREVIEW_WIDTH,
    filterViews,
    getTemporaryPreviewInstructionsUrl,
    getPreviews,
    TRANSIENT_PREVIEW_WIDTH
} from "@shared/utils/Previews";
import type { GetAltText, PreviewUrl } from "./previewsContextTypes";

interface PreviewContextType {
    canvasSelectorUrls: PreviewUrl[];
    previewUrls: PreviewUrl[];
    purcsTransientUrls: View[] | undefined;
    previewInstructionsUrl: string;
    getAltTextForPreview: GetAltText;
}

export async function getDocumentForPreviews(
    isPremiumFinishModalopen: boolean,
    getDocument: () => Promise<DSS.DesignDocument>,
    defaultPlaceholderText: string
) {
    // preview mode just adds
    const designDocument = await getDocument();

    return produce(designDocument, draftCimdoc => {
        if (!isPremiumFinishModalopen) {
            // remove this - its not needed and just inflates size
            // eslint-disable-next-line no-param-reassign
            delete draftCimdoc.metadata;
        } else {
            // eslint-disable-next-line no-param-reassign
            draftCimdoc = getDocWithPlaceHolder(draftCimdoc, defaultPlaceholderText);
        }
        // remove this to get rid of surface checks on the mcp side
        // eslint-disable-next-line no-param-reassign
        delete draftCimdoc.sku;
    });
}

function getDocWithPlaceHolder(designDocument: DSS.DesignDocument, defaultPlaceholderText: string) {
    return produce(designDocument, draft => {
        draft.document.panels.forEach(panel => {
            if (panel.itemReferences) {
                panel.itemReferences.forEach(reference => {
                    if (reference.data && !reference.data.content) {
                        const metadata = (designDocument.metadata?.template ?? []).find(
                            templateItem => templateItem.id === reference.id
                        );
                        if (metadata && metadata.placeholder) {
                            // eslint-disable-next-line no-param-reassign
                            reference.data.content = metadata.placeholder;
                        }
                    }
                });
            }
            if (panel.textAreas) {
                panel.textAreas.forEach(textArea => {
                    if (textArea.content && !textArea.content[0].content) {
                        const metadata = (designDocument.metadata?.template ?? []).find(
                            templateItem => templateItem.id === textArea.id
                        );
                        if (metadata) {
                            if (!metadata.placeholder || metadata.placeholder === "defaultPlaceholder") {
                                metadata.placeholder = defaultPlaceholderText;
                            }

                            if (typeof metadata.placeholder === "string") {
                                // eslint-disable-next-line no-param-reassign
                                textArea.content[0].content = metadata.placeholder;
                            }
                        }
                    }
                });
            }
        });
    });
}

export const PreviewsContext = React.createContext<PreviewContextType>({
    canvasSelectorUrls: [],
    previewUrls: [],
    purcsTransientUrls: [],
    previewInstructionsUrl: "",
    getAltTextForPreview: () => null
});

/**
 * @returns {{canvasSelectorUrls: any[], previewUrls: any[], purcsTransientUrls: any[], previewInstructionsUrl: string}}
 */
export const usePreviewsContext = () => {
    return useContext(PreviewsContext);
};

function filterUndesignableCanvases(
    surfaceUpsells: Record<string, ExtendedSurfaceUpsell>,
    preview: PreviewUrl,
    designRequirements: DesignRequirements
) {
    if (!surfaceUpsells) {
        return true;
    }
    // if the preview is undefined we can't use it
    if (!preview) {
        return false;
    }
    const panel = designRequirements.panels.find(
        panel => preview.title === panel.locationName || preview.name === panel.name
    );
    // if we're unable to find the canvas just assume its ok to show
    if (!panel) return true;
    return !isBlankAndHasNoUpsells(panel, surfaceUpsells);
}

interface Props {
    designDocument?: DSS.DesignDocument;
    getAltTextForPreview: GetAltText;
}

export const PreviewsProvider = ({
    children,
    designDocument,
    getAltTextForPreview
}: React.PropsWithChildren<Props>) => {
    const productKey = useAppSelector(state => state.productKey);
    const isPremiumFinishModalopen = useAppSelector(state => state.isPremiumFinishModalOpen);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const productDataLoadSuccessful = useAppSelector(state => state.productDataLoadSuccessful);
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const surfaceUpsells = useAppSelector(state => state.surfaceUpsells);
    const locale = useAppSelector(state => state.locale);
    const [purcsPreviewUrls, setPurcsPreviewUrls] = useState([]);
    const [purcsTransientUrls, setPurcsTransientUrls] = useState<View[] | undefined>(undefined);
    const [purcsHighQualityTransientUrls, setPurcsHighQualityTransientUrls] = useState<View[] | undefined>(undefined);
    const [previewUrls, setPreviewUrls] = useState<PreviewUrl[]>([]);
    const [canvasSelectorUrls, setCanvasSelectorUrls] = useState<PreviewUrl[]>([]);
    const [previewInstructionsUrl, setPreviewInstructionsUrl] = useState("");
    const { shouldUseTransientSceneFallback } = useAppSelector(state => state.studioConfiguration);
    const { auth } = useIdentityContext();
    const designRequirements = useDesignRequirementsContext();

    useEffect(() => {
        if (productKey && studioSelectedProductOptions && productDataLoadSuccessful) {
            (async () => {
                const reviewUrlsPromises = getAllViews({
                    purpose: "design.studio.review",
                    previewInstructionsUri: FAKE_INSTRUCTIONS,
                    locale
                });

                const transientUrlsPromise = getAllViews({
                    purpose: "design.studio.transient",
                    previewInstructionsUri: FAKE_INSTRUCTIONS,
                    locale,
                    width: TRANSIENT_PREVIEW_WIDTH // transient urls are only used in canvas selectors or template previews, they can be smaller
                });

                const hqTransientUrlsPromise = getAllViews({
                    purpose: "design.studio.highQualityTransient",
                    previewInstructionsUri: FAKE_INSTRUCTIONS,
                    locale,
                    width: DESIGN_PREVIEW_WIDTH
                });

                const [reviewUrls, transientUrls, hqTransientUrls] = await Promise.all([
                    reviewUrlsPromises,
                    transientUrlsPromise,
                    hqTransientUrlsPromise
                ]);

                // transient scenes have a dropshadow and border which we can't disable
                // so just add this hack to request a png so that the border is transparent
                transientUrls.views.forEach((scene: View) => {
                    // eslint-disable-next-line no-param-reassign
                    scene._links.image.href += "&format=png&category=studio";
                });
                // transient scenes have a dropshadow and border which we can't disable
                // so just add this hack to request a png so that the border is transparent
                hqTransientUrls.views.forEach((scene: View) => {
                    // eslint-disable-next-line no-param-reassign
                    scene._links.image.href += "&format=png&category=studio";
                });
                reviewUrls.views.forEach((scene: View) => {
                    // eslint-disable-next-line no-param-reassign
                    scene._links.image.href += "&category=studio";
                });

                setPurcsHighQualityTransientUrls(hqTransientUrls.views);
                setPurcsTransientUrls(transientUrls.views);
                setPurcsPreviewUrls(reviewUrls.views);
            })();
        }
    }, [productKey, studioSelectedProductOptions, productDataLoadSuccessful, locale]);

    useEffect(() => {
        (async () => {
            if (designDocument && designRequirements) {
                const authToken = auth.getToken();

                const temporaryPreviewInstructionsUrl = await getTemporaryPreviewInstructionsUrl({
                    designDocument,
                    authToken
                });

                setPreviewInstructionsUrl(temporaryPreviewInstructionsUrl);

                // eventually we'd not use designer previews at all
                // purcs would have to return a full set of previews
                const designerPreviewUrls = purcsTransientUrls
                    ? (await getPreviews(designDocument, purcsTransientUrls, authToken)).map((preview, i) => ({
                          title: designRequirements.panels[i].locationName,
                          name: designRequirements.panels[i].name,
                          src: preview
                      }))
                    : [];

                // Save canvas urls before preview scenes are added
                setCanvasSelectorUrls([...designerPreviewUrls]);

                /* Start building design previews array */
                const filteredViews = filterViews(
                    purcsPreviewUrls,
                    temporaryPreviewInstructionsUrl,
                    DESIGN_PREVIEW_WIDTH
                );

                // Order the list of previewURLs based on the canvases
                const labeledFilteredViews = filteredViews.reduce((acc, view) => {
                    return { ...acc, [view.title.toLowerCase()]: view };
                }, {});
                const canvasOrder = designRequirements.panels.map(panel => ({
                    title: panel.locationName.toLowerCase(),
                    name: panel.name.toLowerCase()
                }));
                const orderedFilteredViews = canvasOrder.map(canvasInfo => {
                    return labeledFilteredViews[canvasInfo.title] || labeledFilteredViews[canvasInfo.name];
                });
                const numberOfMatchedScenes = orderedFilteredViews.filter(x => !!x).length;

                if (purcsHighQualityTransientUrls && (shouldUseTransientSceneFallback || numberOfMatchedScenes === 0)) {
                    // If we only have 1 canvas and at least the first filtered view has an associated image than use it
                    // Eg: PRD-TFCG8A3T wrap around mug
                    // we don't gain anything by matching our 1 canvas to 1-many scenes
                    if (purcsHighQualityTransientUrls?.length === 1 && filteredViews[0]?.src) {
                        setPreviewUrls(
                            filteredViews.filter(view =>
                                filterUndesignableCanvases(surfaceUpsells, view, designRequirements)
                            )
                        );
                        return;
                    }
                    // if the scenes from purcs matched all the canvases we have no need to run this logic
                    // use the ordered views so they match the canvas
                    if (purcsHighQualityTransientUrls?.length === numberOfMatchedScenes && numberOfMatchedScenes > 0) {
                        setPreviewUrls(
                            orderedFilteredViews.filter(view =>
                                filterUndesignableCanvases(surfaceUpsells, view, designRequirements)
                            )
                        );
                        return;
                    }
                    // Purcs probably gave us a full complement of views but we couldn't match them all to canvases
                    // just use what purcs gave us, otherwise we end up with duplicate scenes
                    if (
                        purcsHighQualityTransientUrls?.length === filteredViews.length &&
                        numberOfMatchedScenes < filteredViews.length &&
                        filteredViews.length > 0
                    ) {
                        setPreviewUrls(
                            filteredViews.filter(view =>
                                filterUndesignableCanvases(surfaceUpsells, view, designRequirements)
                            )
                        );
                        return;
                    }

                    /* Fallback logic for design preview without scenes (or incomplete scenes) */
                    const designerHighQualityPreviewUrls = (
                        purcsHighQualityTransientUrls &&
                        (await getPreviews(designDocument, purcsHighQualityTransientUrls, authToken))
                    ).map((preview, i) => ({
                        title: designRequirements.panels[i].locationName,
                        name: designRequirements.panels[i].name,
                        src: preview,
                        srcSet: ""
                    }));

                    // Override designer preview URLs to match these previewUrls
                    for (let i = 0; i < designerHighQualityPreviewUrls.length; i++) {
                        if (i < orderedFilteredViews.length && orderedFilteredViews[i]) {
                            designerHighQualityPreviewUrls[i] = orderedFilteredViews[i];
                        }
                        // Note: We can't fallback on to anything here because the preview order might not match the document panel order
                        // Eg: PRD-E2HUSSDN Holiday Cards [Front, Inside, Back] on document vs [Front, Back, Inside] from PURCS
                    }

                    filteredViews.forEach(view => {
                        if (!designerHighQualityPreviewUrls.some(previewUrl => previewUrl.srcSet === view.srcSet)) {
                            designerHighQualityPreviewUrls.push(view);
                        }
                    });

                    setPreviewUrls(
                        designerHighQualityPreviewUrls.filter(view =>
                            filterUndesignableCanvases(surfaceUpsells, view, designRequirements)
                        )
                    );
                } else {
                    setPreviewUrls(
                        filteredViews.filter(view =>
                            filterUndesignableCanvases(surfaceUpsells, view, designRequirements)
                        )
                    );
                }
            }
        })();
    }, [
        purcsPreviewUrls,
        purcsTransientUrls,
        purcsHighQualityTransientUrls,
        easelLoaded,
        isPremiumFinishModalopen,
        surfaceUpsells,
        designDocument,
        designRequirements,
        auth,
        shouldUseTransientSceneFallback
    ]);

    useEffect(() => {
        if (isPremiumFinishModalopen) {
            setPreviewInstructionsUrl("");
        }
    }, [isPremiumFinishModalopen]);

    const contextObject = useMemo(
        () => ({
            previewUrls,
            canvasSelectorUrls,
            previewInstructionsUrl,
            purcsTransientUrls,
            getAltTextForPreview
        }),
        [previewUrls, canvasSelectorUrls, previewInstructionsUrl, purcsTransientUrls, getAltTextForPreview]
    );

    return <PreviewsContext.Provider value={contextObject}>{children}</PreviewsContext.Provider>;
};

PreviewsProvider.displayName = "PreviewsProvider";
