// This is borrowed from Studio4.  The experience there is decent and replicating that seems like a good start

import type { Designer } from "../../@types/designer";
import type { Crop, CropAttributes } from "../../@types/imagesCrop";

/**
 * Locked cropping.
 * This should maintain the height and width of the model.
 */
function lockedCropApply(viewModel: ItemViewModel, newCrop: Crop, attributes: CropAttributes): void {
    const { model } = viewModel;

    const newAttributes = {
        crop: newCrop
    };

    model.set({ ...newAttributes, ...attributes });
}

function generateCrop(dimensions: Dimensions, newCrop: Crop): Dimensions {
    return {
        height: dimensions.height * (1 - (newCrop.top + newCrop.bottom)),
        width: dimensions.width * (1 - (newCrop.left + newCrop.right))
    };
}

// adjusting top and left by checking top/left values combined with height and width
function positionImageWithinCanvas(
    topLeft: CanvasPosition,
    scaledDimensions: Dimensions,
    canvasDimensions: Dimensions
): CanvasPosition {
    const newTopLeft = {
        top: topLeft.top < 0 ? 0 : topLeft.top,
        left: topLeft.left < 0 ? 0 : topLeft.left
    };

    const right = canvasDimensions.width - (scaledDimensions.width + newTopLeft.left);
    newTopLeft.left = right < 0 ? newTopLeft.left + right : newTopLeft.left;

    const bottom = canvasDimensions.height - (scaledDimensions.height + newTopLeft.top);
    newTopLeft.top = bottom < 0 ? newTopLeft.top + bottom : newTopLeft.top;

    return newTopLeft;
}

function centerResizedImage(model: ItemModel, imageDimensions: Dimensions): CanvasPosition {
    const containerDimensions = {
        height: model.get("height"),
        width: model.get("width")
    };
    const deltaWidth = imageDimensions.width - containerDimensions.width;
    const deltaHeight = imageDimensions.height - containerDimensions.height;
    return {
        top: model.get("top") - deltaHeight / 2,
        left: model.get("left") - deltaWidth / 2
    };
}

// hypotenuse scaling uses the hypotenuse of the two dimensions to calculate a scale factor
function hypotenuseScaling(oldDimensions: Dimensions, newDimensions: Dimensions): Dimensions {
    const oldHypotenuse = Math.sqrt(
        oldDimensions.width * oldDimensions.width + oldDimensions.height * oldDimensions.height
    );
    const newHypotenuse = Math.sqrt(
        newDimensions.width * newDimensions.width + newDimensions.height * newDimensions.height
    );

    const scaleFactor = newHypotenuse / oldHypotenuse;
    return {
        height: newDimensions.height / scaleFactor,
        width: newDimensions.width / scaleFactor
    };
}

function fitToRectangleScaling(oldDimensions: Dimensions, newDimensions: Dimensions): Dimensions {
    const newAspectRatio = newDimensions.width / newDimensions.height;
    if (newAspectRatio > 1) {
        return {
            width: oldDimensions.width,
            height: oldDimensions.width * (1 / newAspectRatio)
        };
    }
    return {
        height: oldDimensions.height,
        width: oldDimensions.height * newAspectRatio
    };
}

function scaleRectangle(model: ItemModel, newDimensions: Dimensions, designer: Designer) {
    const modelDimensions = {
        height: model.get("height"),
        width: model.get("width")
    };

    // first we need to check if we can rotate the image
    // by keeping the size of the rotated image similar
    let scaledDimensions = hypotenuseScaling(modelDimensions, newDimensions);

    const activeCanvasViewModel = designer.documentRepository.getActiveCanvas();
    const canvasDimensions = {
        height: activeCanvasViewModel.get("height"),
        width: activeCanvasViewModel.get("width")
    };

    const adjustedTopLeft = centerResizedImage(model, scaledDimensions);

    // check to see if we can fit the rotated image in our canvas
    if (scaledDimensions.height < canvasDimensions.height && scaledDimensions.width < canvasDimensions.width) {
        // this method prevents the rotated image from overflowing
        const readjustedTopleft = positionImageWithinCanvas(adjustedTopLeft, scaledDimensions, canvasDimensions);

        return {
            scaledDimensions,
            adjustedTopLeft: readjustedTopleft
        };
    }

    // else if we can't fit the image, then scale it down so it does fit
    scaledDimensions = fitToRectangleScaling(canvasDimensions, newDimensions);
    const readjustedTopLeft = centerResizedImage(model, scaledDimensions);
    return {
        scaledDimensions,
        adjustedTopLeft: readjustedTopLeft
    };
}

/**
 * Unlocked cropping resizes the image. The height and width of the
 * model is not maintained.
 */
function unlockedCropApply(
    viewModel: ItemViewModel,
    newCrop: Crop,
    attributes: CropAttributes,
    designer: Designer
): void {
    const { model } = viewModel;

    // get the original image size
    const oldCrop = model.get("crop");
    const oldCropFactor = {
        height: oldCrop.top + oldCrop.bottom,
        width: oldCrop.left + oldCrop.right
    };
    // reverse the application of crop
    const uncroppedDimensions = {
        height: model.get("height") / (1 - oldCropFactor.height),
        width: model.get("width") / (1 - oldCropFactor.width)
    };

    const croppedDimensions = generateCrop(uncroppedDimensions, newCrop);

    const result = scaleRectangle(model, croppedDimensions, designer);

    const newAttributes = {
        crop: newCrop,
        height: result.scaledDimensions.height,
        width: result.scaledDimensions.width,
        // i'm not sure what these do, but designer sets them
        initialHeight: result.scaledDimensions.height,
        initialWidth: result.scaledDimensions.width,
        top: result.adjustedTopLeft.top,
        left: result.adjustedTopLeft.left
    };

    model.set({ ...newAttributes, ...attributes });
}

export function cropImage(
    viewModel: ItemViewModel,
    crop: Crop,
    lockAspectRatio: boolean,
    attributes: CropAttributes,
    designer: Designer
) {
    const newCrop = { ...crop };

    if (lockAspectRatio) {
        lockedCropApply(viewModel, newCrop, attributes);
    } else {
        unlockedCropApply(viewModel, newCrop, attributes, designer);
    }
}
