import React from "react";
import loadable from "@loadable/component";
import { layersPanelMessages } from "@shared/features/AdvancedLayerTool";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { loadableRetry } from "@shared/utils/Network";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";

const LayersPanelContent = loadable(
    () => loadableRetry(() => import("../../../../easel/designer-suite/AdvancedLayerTool/LayerContentPanel")),
    {
        resolveComponent: components => components.LayerContentPanel
    }
);

export function LayersPanel() {
    const { currentActiveDialog } = useActiveDialog();
    const { t } = useTranslationSSR();

    return (
        <PanelContent className="studio-image-panel-content">
            <PanelTitle>{t(layersPanelMessages.layersPanelHeader.id)}</PanelTitle>
            {/* Layers can make things slower for users. Don't incure the drag on
                user performance unless the panel is actually being shown */}
            {DialogType.Layers === currentActiveDialog && <LayersPanelContent />}
        </PanelContent>
    );
}

LayersPanel.displayName = "LayersPanel";
