import React, { PropsWithChildren, useContext } from "react";
import { UnitlessDimensions } from "@design-stack-ct/utility-core";

interface TableOverlayControls {
    relativeColumnWidths: number[];
    relativeRowHeights: number[];
    fontSizeInPx?: number;
    tableDimensionsInPx: UnitlessDimensions;
    onColumnResize: (columnIndex: number, percentDelta: number) => void;
}

const TableOverlayControlsContext = React.createContext<TableOverlayControls | undefined>(undefined);

export function useTableOverlayControls(): TableOverlayControls {
    const controls = useContext(TableOverlayControlsContext);
    if (!controls) {
        throw new Error("Cannot use useTableOverlayControls hook without TableOverlayControlsProvider");
    }

    return controls;
}

export function TableOverlayControlsProvider({ children, ...tableControls }: PropsWithChildren<TableOverlayControls>) {
    const { Provider } = TableOverlayControlsContext;

    return <Provider value={tableControls}>{children}</Provider>;
}

TableOverlayControlsProvider.displayName = "TableOverlayControlsProvider";
