import React, { ReactNode, useEffect, useState } from "react";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { useAppSelector, useAppDispatch, setIsPremiumFinishModalOpen } from "@shared/redux";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { DesignErrorModalDialog } from "@shared/features/SystemMessages";

interface Props {
    /** Text for the header, otherwise default will be shown */
    headerText?: string | ReactNode;
    /** Content or text for the body, otherwise default will be shown  */
    bodyText?: string | ReactNode;
    /** Text for the button, otherwise default will be shown  */
    buttonText?: string;
    className?: string;
    /**
     * Whether to show the modal or not
     * @default false
     */
    show: boolean;
    /** Optional callback when modal closes */
    onClose?: () => void;
    /** Whether to show close button.  true by default */
    showClose?: boolean;
    /** Whether it has Premium Finishes in Current Canvas. false by default */
    hasPremiumFinishesCurrentCanvas: boolean;
}

export function DesignErrorModal({
    headerText,
    bodyText,
    buttonText,
    show,
    onClose,
    className,
    showClose = true,
    hasPremiumFinishesCurrentCanvas = false
}: Props) {
    const { isSmall } = useStudioLayout();
    const dispatch = useAppDispatch();
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const { setCurrentActiveDialog } = useActiveDialog();
    const [isOpen, setIsOpen] = useState(false);

    function openPremiumFinishModal() {
        if (isFullBleed) {
            setCurrentActiveDialog(DialogType.FullBleedPremiumFinish, true);
            onClose && onClose();
        } else {
            dispatch(setIsPremiumFinishModalOpen({ isPremiumFinishModalOpen: true }));
        }
        onClickClose();
    }

    useEffect(() => {
        setIsOpen(show);
    }, [setIsOpen, show]);

    const onClickClose = () => {
        if (onClose) {
            onClose();
        }
        setIsOpen(false);
    };

    return (
        <DesignErrorModalDialog
            headerText={headerText}
            buttonText={buttonText}
            className={className}
            bodyText={bodyText}
            onClickClose={onClickClose}
            openPremiumFinishModal={openPremiumFinishModal}
            isOpen={isOpen}
            isSmall={isSmall}
            showClose={showClose}
            hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
        />
    );
}
DesignErrorModal.displayName = "DesignErrorModal";
