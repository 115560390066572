import React from "react";
import { ThemeProvider, Theme } from "@presentational";
import type { DecoTechFontFamilies } from "@shared/utils/Fonts";
import { Props as DesignerProviderProps, DesignerProvider } from "./designer/DesignerProvider";
import { FontProvider } from "./designer/FontProvider";

interface Props extends DesignerProviderProps {
    theme?: Theme;
    fonts: DecoTechFontFamilies;
    errorCallBack?: (error: { errorMessage: string; errorCodeStack: string }) => void;
}

/**
 * A wrapper around all the providers that are offered/used within the Designer Suite repository.
 * This is useful if you are looking for single top level wrapper for everything.
 */
export function DesignerSuiteProvider({
    autoLoadDesigner,
    loadedDesigner,
    theme,
    fonts,
    children,
    options,
    onStart,
    errorCallBack
}: Props) {
    return (
        <DesignerProvider
            autoLoadDesigner={autoLoadDesigner}
            loadedDesigner={loadedDesigner}
            options={options}
            onStart={onStart}
            errorCallBack={errorCallBack}
        >
            <FontProvider fontCategories={fonts}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </FontProvider>
        </DesignerProvider>
    );
}
DesignerSuiteProvider.displayName = "DesignerSuiteProvider";
