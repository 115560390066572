import { useContext } from "react";
import { UploadQRABcontext } from "./UploadQRABProvider";

export function useUploadQRABTest() {
    const result = useContext(UploadQRABcontext);
    if (!result) {
        throw Error("Missing context.  This must be called within a UploadQRABProvider");
    }
    return result;
}
