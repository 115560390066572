import {
    type TemplateType,
    type AppDispatch,
    Store,
    setCurrency,
    loadSurfaceUpsells,
    surfaceUpsellsLoaded
} from "@shared/redux";
import { type SurfaceUpsell } from "@shared/utils/Calcifer";
import { convertDocumentSourceType, DocumentSourceType } from "@shared/utils/DSS";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { getDifferentialPricing, isVatInclusive } from "@shared/utils/Pricing";
import {
    BLANK_SELECTED_TEMPLATE,
    CUSTOM_SELECTED_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE,
    DUPLICATE_FIRST_PANEL_TEMPLATE
} from "@shared/utils/Templates";
import type { DSS } from "@vp/types-ddif";
import keyBy from "lodash/keyBy";
import once from "lodash/once";

async function getPricing(
    productKey: string,
    quantity: number,
    productVersion: number,
    filteredSelectedProductOptions: { [x: string]: string },
    choiceGroups: {},
    dispatchPricingInfo: (currency: any) => void
) {
    try {
        const differentialPricingData = await getDifferentialPricing(
            productKey,
            filteredSelectedProductOptions,
            quantity,
            choiceGroups,
            productVersion
        );
        dispatchPricingInfo(differentialPricingData.currency);

        const priceToUse = isVatInclusive() ? "taxed" : "untaxed";

        return Object.entries(differentialPricingData.choiceGroups).reduce((accumulator, choice) => {
            accumulator[choice[0]] = {
                differentialDiscountPrice: choice[1].differentialDiscountPrice[priceToUse],
                differentialListPrice: choice[1].differentialListPrice[priceToUse]
            };
            return accumulator;
        }, {});
    } catch (e) {
        handleError(e, ERROR_CODES.SURFACE_UPSELL_PRICING);
        return undefined;
    }
}

function determineSelectedTemplate(
    panelIsBlank: boolean,
    upsellPanelSource: DSS.DocumentPanelSource | undefined
): { selectedTemplate: TemplateType; upsellOffered?: boolean } {
    if (panelIsBlank) {
        return { selectedTemplate: BLANK_SELECTED_TEMPLATE };
    }
    if (upsellPanelSource) {
        switch (upsellPanelSource.source) {
            case convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN):
                return { selectedTemplate: upsellPanelSource.data as TemplateType };
            case convertDocumentSourceType(DocumentSourceType.EMPTY):
                return { selectedTemplate: CUSTOM_SELECTED_TEMPLATE };
            case convertDocumentSourceType(DocumentSourceType.LEGACY):
                // if a legacy template was selected, we can assume they've been offered this on the old platform
                // no need to offer it again
                return { selectedTemplate: CUSTOM_SELECTED_TEMPLATE, upsellOffered: true };
            case convertDocumentSourceType(DocumentSourceType.FULLBLEED):
                return { selectedTemplate: FULLBLEED_SELECTED_TEMPLATE };
            case convertDocumentSourceType(DocumentSourceType.DUPLICATE):
                return { selectedTemplate: DUPLICATE_FIRST_PANEL_TEMPLATE };
            default:
                return { selectedTemplate: BLANK_SELECTED_TEMPLATE };
        }
    } else {
        return { selectedTemplate: CUSTOM_SELECTED_TEMPLATE };
    }
}

export const getSurfaceUpsellsAndPricing = (
    document: DSS.DesignDocument,
    generatedSurfaceUpsells: SurfaceUpsell[] | undefined,
    studioSelectedProductOptions: Record<string, string>,
    productKey: string,
    productVersion: number
) => {
    return async (dispatch: AppDispatch, getState: typeof Store.getState) => {
        const { metadata } = document;

        const documentSurfaceUpsells = metadata ? metadata.surfaceUpsells : undefined;
        const dispatchPricingInfo = once(currency => {
            dispatch(setCurrency(currency));
        });

        // Be careful what state is used here as it may be out of date
        const { quantity, surfaceUpsells: existingSurfaceUpsells } = getState();

        const surfaceUpsells = generatedSurfaceUpsells?.map(generatedUpsell => ({
            ...generatedUpsell,
            upsellOffered:
                documentSurfaceUpsells?.find(
                    documentSurfaceUpsell => documentSurfaceUpsell.panelName === generatedUpsell.panelName
                )?.upsellOffered || false
        }));

        // don't dispatch this if we already have upsells in redux as we'd just be sending bad data
        if (!existingSurfaceUpsells) {
            // immediately dispatch this info so that the modal doesn't show and the panel can update
            // pricing can take a while & and no need to hold everything up waiting for it
            dispatch(loadSurfaceUpsells(surfaceUpsells ? keyBy(surfaceUpsells, upsell => upsell.panelName) : {}));
        }

        try {
            if (surfaceUpsells && surfaceUpsells.length > 0) {
                const pricedSurfaceUpsells = await Promise.all(
                    surfaceUpsells.map(async surfaceUpsell => {
                        if (surfaceUpsell.optionName) {
                            const { colorOption, blankOption } = surfaceUpsell;
                            if (colorOption === undefined || blankOption === undefined) {
                                throw Error("Color or blank option are not defined");
                            }
                            const choiceGroups = {
                                [colorOption]: {
                                    [surfaceUpsell.optionName]: colorOption
                                },
                                [blankOption]: {
                                    [surfaceUpsell.optionName]: blankOption
                                }
                            };
                            // get the lowest overall price, so remove the current option
                            const filteredSelectedProductOptions = { ...studioSelectedProductOptions };
                            if (filteredSelectedProductOptions[surfaceUpsell.optionName]) {
                                delete filteredSelectedProductOptions[surfaceUpsell.optionName];
                            }

                            const pricing = await getPricing(
                                productKey,
                                quantity,
                                productVersion,
                                filteredSelectedProductOptions,
                                choiceGroups,
                                dispatchPricingInfo
                            );

                            // the selectedTemplate won't exist if the document was created in the control experience for the backside test
                            // we populate it here so that if a customer loads a work with a V1 document, the test experience isn't degraded
                            let upsellPanelSource = document.metadata?.documentSources?.panels.find(
                                panel => panel.id === surfaceUpsell.panelId
                            );

                            // This is to prevent backsides from disappearing in imported legacy documents before the panel.id was fixed
                            // in the import tool on 6/30/21.
                            if (!upsellPanelSource) {
                                let documentPanelsIndex = document.document.panels.findIndex(
                                    panel => panel.id === surfaceUpsell.panelId
                                );

                                if (documentPanelsIndex === -1) {
                                    documentPanelsIndex = document.document.panels.findIndex(
                                        panel => panel.name === surfaceUpsell.panelName
                                    );
                                }
                                if (
                                    documentPanelsIndex >= 0 &&
                                    document.metadata?.documentSources?.panels[documentPanelsIndex]?.source ===
                                        convertDocumentSourceType(DocumentSourceType.LEGACY)
                                ) {
                                    upsellPanelSource = document.metadata?.documentSources?.panels[documentPanelsIndex];
                                }
                            }

                            const panelIsBlank =
                                studioSelectedProductOptions[surfaceUpsell.optionName] === surfaceUpsell.blankOption;

                            const result = determineSelectedTemplate(panelIsBlank, upsellPanelSource);
                            const { selectedTemplate } = result;

                            return {
                                upsellOffered: surfaceUpsell.upsellOffered,
                                optionName: surfaceUpsell.optionName,
                                colorOption: surfaceUpsell.colorOption,
                                blankOption: surfaceUpsell.blankOption,
                                panelId: surfaceUpsell.panelId,
                                panelName: surfaceUpsell.panelName,
                                selectedTemplate,
                                pricing
                            };
                        }

                        return surfaceUpsell;
                    })
                );
                // now that we have pricing, update again
                dispatch(
                    loadSurfaceUpsells(surfaceUpsells ? keyBy(pricedSurfaceUpsells, upsell => upsell.panelName) : {})
                );
            }
        } catch (e) {
            handleError(e, ERROR_CODES.SURFACE_UPSELL_LOAD);
        } finally {
            dispatch(surfaceUpsellsLoaded());
        }
    };
};
