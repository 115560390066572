import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { SliderRow, SliderType } from "@shared/features/ColorPicker";
import { HSL } from "@design-stack-ct/utility-core";
import * as styles from "./HslRecolorizationSliders.module.scss";

const messages = defineMessages({
    hueLabel: {
        id: "easel.designerSuite.hslRecolorization.hueLabel",
        defaultMessage: "Color",
        description: {}
    },
    saturationLabel: {
        id: "easel.designerSuite.hslRecolorization.saturationLabel",
        defaultMessage: "Saturation",
        description: {}
    },
    lightnessLabel: {
        id: "easel.designerSuite.hslRecolorization.lightnessLabel",
        defaultMessage: "Brightness",
        description: {}
    },
    resetButton: {
        id: "easel.designerSuite.hslRecolorization.resetButton",
        defaultMessage: "Reset",
        description: {}
    },
    applyButton: {
        id: "easel.designerSuite.hslRecolorization.applyButton",
        defaultMessage: "Apply",
        description: {}
    },
    hueResetButtonAriaLabel: {
        id: "easel.designerSuite.hslRecolorization.hueResetButtonAriaLabel",
        defaultMessage: "Reset color",
        description: {}
    },
    saturationResetButtonAriaLabel: {
        id: "easel.designerSuite.hslRecolorization.saturationResetButtonAriaLabel",
        defaultMessage: "Reset saturation",
        description: {}
    },
    lightnessResetButtonAriaLabel: {
        id: "easel.designerSuite.hslRecolorization.lightnessResetButtonAriaLabel",
        defaultMessage: "Reset brightness",
        description: {}
    },
    editImageHueLabel: {
        id: "easel.designerSuite.hslRecolorization.editImageHueLabel",
        defaultMessage: "Hue"
    },
    editImageHueResetButtonAriaLabel: {
        id: "easel.designerSuite.hslRecolorization.editImageHueResetButtonAriaLabel",
        defaultMessage: "Reset hue"
    },
    editImageLightnessLabel: {
        id: "easel.designerSuite.hslRecolorization.editImageLightnessLabel",
        defaultMessage: "Contrast"
    },
    editImageLightnessResetButtonAriaLabel: {
        id: "easel.designerSuite.hslRecolorization.editImageLightnessResetButtonAriaLabel",
        defaultMessage: "Reset contrast"
    }
});

interface HslRecolorizationSlidersProps {
    value: HSL;
    onChange: (value: HSL) => void;
    onReset?: (type: string) => void;
    setSliding?: (arg: boolean, sliderType: SliderType) => void;
    handleCommit?: () => void;
    className?: string;
    hslMaxValues?: HSL;
}

const noop = () => {};

export function HslRecolorizationSliders({
    value,
    className,
    hslMaxValues,
    onChange = noop,
    onReset = noop,
    setSliding = noop,
    handleCommit = noop
}: HslRecolorizationSlidersProps) {
    const { t } = useTranslationSSR();
    const hueChange = (newHue: number) => {
        setSliding(true, "hue");
        onChange({ ...value, h: newHue });
    };
    const { isXerox } = useXerox();

    const saturationChange = (newSaturation: number) => {
        setSliding(true, "saturation");
        onChange({ ...value, s: newSaturation });
    };

    const lightnessChange = (newLightness: number) => {
        setSliding(true, "lightness");
        onChange({ ...value, l: newLightness });
    };

    const handleOnRelease = (hslType: SliderType) => {
        setSliding(false, hslType);
        handleCommit();
    };

    return (
        <div
            className={classNames(
                styles.easelHslrecolorizationContainer,
                "easel-hslrecolorization-container",
                className
            )}
        >
            <SliderRow
                label={isXerox ? t(messages.editImageHueLabel.id) : t(messages.hueLabel.id)}
                resetAriaLabel={
                    isXerox ? t(messages.editImageHueResetButtonAriaLabel.id) : t(messages.hueResetButtonAriaLabel.id)
                }
                min={0}
                // 360 = 0, so instead of providing a max of 360 we provide a smidgen below
                // this prevents the slider from resetting to 0 when the user drags it all the way to the right
                max={hslMaxValues?.h || 359.9}
                value={value.h}
                onChange={hueChange}
                onReset={() => onReset("hueOffset")}
                sliderBackground="linear-gradient(to right, #f00 0%, #ff0 16.666%, #0f0 33.333%, #0ff 50%, #00f 66.666%, #f0f 83.333%, #f00 100%)"
                onRelease={() => handleOnRelease("hue")}
                handleStyle={{
                    background: `hsl(${value.h},100%,50%)`
                }}
                tickMark={false}
                startSwatchColor="#f00"
                endSwatchColor="#f00"
            />
            <SliderRow
                label={t(messages.saturationLabel.id)}
                resetAriaLabel={t(messages.saturationResetButtonAriaLabel.id)}
                min={0}
                max={hslMaxValues?.s || 2}
                value={value.s}
                onChange={saturationChange}
                onReset={() => onReset("saturationMultiplier")}
                sliderBackground={`linear-gradient(to right, hsl(${value.h}, 0%, 50%), hsl(${value.h}, 100%, 50%))`}
                onRelease={() => handleOnRelease("saturation")}
                handleStyle={{
                    background: `hsl(${value.h},${value.s * 50}%,50%)`
                }}
                tickMark
                startSwatchColor={`hsl(${value.h}, 0%, 50%)`}
                endSwatchColor={`hsl(${value.h}, 100%, 50%)`}
            />
            <SliderRow
                label={isXerox ? t(messages.editImageLightnessLabel.id) : t(messages.lightnessLabel.id)}
                resetAriaLabel={
                    isXerox
                        ? t(messages.editImageLightnessResetButtonAriaLabel.id)
                        : t(messages.lightnessResetButtonAriaLabel.id)
                }
                min={0}
                max={hslMaxValues?.l || 2}
                value={value.l}
                onChange={lightnessChange}
                onReset={() => onReset("lightnessMultiplier")}
                sliderBackground={`linear-gradient(to right, hsl(0, 0%, 0%), hsl(0, 0%, 50%), hsl(0, 0%, 100%))`}
                onRelease={() => handleOnRelease("lightness")}
                handleStyle={{
                    background: `hsl(0,0%,${value.l * 50}%)`
                }}
                tickMark
                startSwatchColor="hsl(0, 0%, 0%)"
                endSwatchColor="hsl(0, 0%, 100%)"
            />
        </div>
    );
}
HslRecolorizationSliders.displayName = "HslRecolorizationSliders";
