/**
Provider KeyboardShortcutsProvider
* */
import React, { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { useTrackEvents } from "@shared/features/Tracking";
import { useAttachHotKeys, keyPressProps } from "./useAttachHotKeys";
import {
    HotKeysToEventMap,
    keyboardShortcutEvents,
    keyboardShortcutsConfig as defaultKeyboardShortcutsConfig
} from "./keyboardShortcutEvents";
import { isEventInConfig } from "./util";

type EventToCallbacks = {
    keyboardShortcutEvents?: Map<string, (arg0: any) => any>;
};

interface KeyboardShortcutsContext {
    registeredFunctions: EventToCallbacks; // {"increase-font" : Map<fn , fnValue>}
    setRegisteredFunctions: (arg: any) => any;
    keyboardShortcutsConfig?: { mac?: HotKeysToEventMap; windows?: HotKeysToEventMap }; // configure hot keys and events that you want to enable shortcut for.
    allowedEvents: { macEventsSet: Set<string>; windowsEventsSet: Set<string> }; // set of events based on config that are allowed.
    isKeyboardShortcutsGuideModalOpen: boolean;
    setIsKeyboardShortcutsGuideModalOpen: (value: boolean) => void;
    selectedOS: string;
    setSelectedOS: (value: string) => void;
}
interface shortcutProviderProps {
    children: ReactNode | ReactNode[];
    keyboardShortcutsConfig?: {
        mac?: { [key: string]: keyboardShortcutEvents };
        windows?: { [key: string]: keyboardShortcutEvents };
    };
    /**
     * Studio 5 (default = true) attaches default hotkeys here, but Studio 6 (false) doesn't do it here
     */
    attachDefaultHotkeys?: boolean;
}

const initContext = {
    registeredFunctions: {},
    setRegisteredFunctions: () => null,
    keyboardShortcutsConfig: { mac: {}, windows: {} },
    allowedEvents: { macEventsSet: new Set<string>(), windowsEventsSet: new Set<string>() },
    isKeyboardShortcutsGuideModalOpen: false,
    setIsKeyboardShortcutsGuideModalOpen: (value: boolean) => null,
    selectedOS: "",
    setSelectedOS: (value: string) => null
};
const context = React.createContext<KeyboardShortcutsContext | undefined>(initContext);

export const useKeyboardShortcutContext = () => {
    const result = useContext(context);
    if (!result) {
        throw Error("Please call this within KeyboardShortcutContext");
    }
    return result;
};

/**
 * returns a handler that can be used to execute events based on eventName.
 *
 */
const useHotKeyPressHandler = (registeredFunctions: EventToCallbacks) => {
    const { trackEvent } = useTrackEvents();
    const handler = useCallback(
        ({ eventName, event }: keyPressProps) => {
            event?.preventDefault();
            trackEvent({ eventDetail: `${eventName}`, label: "KeyboardShortcut" });
            const registeredCallbacksMap = eventName && registeredFunctions?.[eventName];
            registeredCallbacksMap &&
                [...registeredCallbacksMap.values()].forEach((cb: any) => {
                    cb?.();
                });
        },
        [registeredFunctions, trackEvent]
    );
    return handler;
};
/**
 * use 'on' function to pass in callback and an event name that will be registered with this provider.
 * 'off' is used to detach a callback from the list to callbacks to be executed on an event.
 */
export const useKeyboardShortcuts = () => {
    const { setRegisteredFunctions, allowedEvents } = useKeyboardShortcutContext();
    return {
        on: useCallback(
            (eventName: keyboardShortcutEvents, cb: (args?: any) => any) => {
                if (!isEventInConfig(eventName, allowedEvents)) {
                    return;
                }
                setRegisteredFunctions((registeredFunctions: any) => {
                    if (registeredFunctions[eventName]) {
                        const updatedCallbacksMap = registeredFunctions[eventName];
                        updatedCallbacksMap.set(cb, cb);
                        return { ...registeredFunctions, [eventName]: updatedCallbacksMap };
                    }
                    const callbackMap = new Map();
                    callbackMap.set(cb, cb);
                    const eventToCallbackMap = { [eventName]: callbackMap };
                    return { ...registeredFunctions, ...eventToCallbackMap };
                });
            },
            [setRegisteredFunctions, allowedEvents]
        ),
        off: useCallback(
            (eventName: keyboardShortcutEvents, cb: (args?: any) => any) => {
                if (!isEventInConfig(eventName, allowedEvents)) {
                    return;
                }
                setRegisteredFunctions((registeredFunctions: any) => {
                    if (registeredFunctions[eventName]) {
                        const updatedCallbacksMap = registeredFunctions[eventName];
                        updatedCallbacksMap.delete(cb, cb);
                        return { ...registeredFunctions, [eventName]: updatedCallbacksMap };
                    }
                    return registeredFunctions;
                });
            },
            [setRegisteredFunctions, allowedEvents]
        )
    };
};

export const KeyboardShortcutsProvider = ({
    children,
    keyboardShortcutsConfig = defaultKeyboardShortcutsConfig,
    attachDefaultHotkeys = true
}: shortcutProviderProps) => {
    const { Provider } = context;
    const [registeredFunctions, setRegisteredFunctions] = useState<EventToCallbacks>({}); //  {eventEnum: Map<fn, fnValue>};

    const handleHotKeyPress = useHotKeyPressHandler(registeredFunctions);
    const [isKeyboardShortcutsGuideModalOpen, setIsKeyboardShortcutsGuideModalOpen] = useState(false);
    const [selectedOS, setSelectedOS] = useState("");

    const allowedEvents = useMemo(() => {
        const macEventsSet = new Set<string>(Object.values(keyboardShortcutsConfig?.mac || {}));
        const windowsEventsSet = new Set<string>(Object.values(keyboardShortcutsConfig?.windows || {}));
        return {
            macEventsSet,
            windowsEventsSet
        };
    }, [keyboardShortcutsConfig]);

    // Studio 5 attaches these default keyboard shortcuts, but Studio 6 doesn't do it here.
    // Even this hook is called conditionally, it is used in completely different solutions (gatsby pages) S5 vs S6,
    // inside which it doesn't change, so we can ignore it
    // eslint-disable-next-line react-hooks/rules-of-hooks
    attachDefaultHotkeys && useAttachHotKeys(handleHotKeyPress, registeredFunctions);

    const contextValue = useMemo(() => {
        return {
            registeredFunctions,
            setRegisteredFunctions,
            keyboardShortcutsConfig,
            allowedEvents,
            isKeyboardShortcutsGuideModalOpen,
            setIsKeyboardShortcutsGuideModalOpen,
            selectedOS,
            setSelectedOS
        };
    }, [
        registeredFunctions,
        keyboardShortcutsConfig,
        allowedEvents,
        setRegisteredFunctions,
        isKeyboardShortcutsGuideModalOpen,
        setIsKeyboardShortcutsGuideModalOpen,
        selectedOS,
        setSelectedOS
    ]);

    return <Provider value={contextValue}>{children}</Provider>;
};

KeyboardShortcutsProvider.displayName = "KeyboardShortcutsProvider";
