import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import React, { ReactNode, useContext, useState, createContext, useEffect } from "react";

type ContextualToolbarData = {
    activeInputField: number;
    setActiveInputField: (v: number) => void;
};

const contextualToolbarContext = createContext<ContextualToolbarData>({
    activeInputField: 0,
    setActiveInputField: () => {}
});

export function useContextualToolbar() {
    return useContext(contextualToolbarContext);
}

type Props = {
    children: ReactNode | ReactNode[];
};

export function ContextualToolbarProvider({ children }: Props) {
    const [activeInputField, setActiveInputField] = useState(0);
    const { currentActiveDialog } = useActiveDialog();

    useEffect(() => {
        if (currentActiveDialog !== DialogType.Text) {
            setActiveInputField(0);
        }
    }, [currentActiveDialog]);

    const { Provider } = contextualToolbarContext;

    return <Provider value={{ activeInputField, setActiveInputField }}>{children}</Provider>;
}
ContextualToolbarProvider.displayName = "ContextualToolbarProvider";
