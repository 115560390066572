import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import { useAITemplates } from "./AITemplatesProvider";

export function AITemplatesPanelErrors() {
    const { t } = useTranslationSSR();
    const { isSearchTextLimitExceeded, hasSearchError, aiTemplatesPanelMessages } = useAITemplates();

    return (
        <>
            {isSearchTextLimitExceeded && (
                <>
                    <Typography
                        textAlign="center"
                        fontSize="1"
                        fontWeight="bold"
                        paddingTop={2}
                        role="alert"
                        data-testid="aiTemplatesSearchExceeded"
                    >
                        {t(aiTemplatesPanelMessages.noMatch.id)}
                    </Typography>
                    <Typography
                        mt={1}
                        textAlign="center"
                        textSize={7}
                        className="no-search-result-description"
                        role="alert"
                    >
                        {t(aiTemplatesPanelMessages.exceedSearchText.id)}
                    </Typography>
                </>
            )}
            {hasSearchError && (
                <Typography
                    textColor="error"
                    fontSize="1"
                    textAlign="center"
                    fontWeight="bold"
                    paddingTop={2}
                    role="alert"
                    data-testid="aiTemplatesSearchFailed"
                >
                    {t(aiTemplatesPanelMessages.failedSearch.id)}
                </Typography>
            )}
        </>
    );
}

AITemplatesPanelErrors.displayName = "AITemplatesPanelErrors";
