import { useAsyncEffect } from "@design-stack-ct/utility-react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { newRelicWrapper } from "@shared/utils/Errors";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { fetchFolderIdForShopper } from "./clients/sherbertFolderClient";

export function useVerifySherbertFolderExistence(tenant: string) {
    const { auth, identity, isIdentityInitialized } = useIdentityContext();
    const currentUserName = identity.shopperId || identity.anonymousUserId || identity.cimpressADFSUserId;

    useAsyncEffect(helpers => {
        if (isIdentityInitialized) {
            try {
                // When owner is provided and the user is an agent (which is generally when owner is used)
                // UC does its own folder check so we don't need to duplicate that
                if (!getQueryParams().owner) {
                    fetchFolderIdForShopper({ shopperId: currentUserName, tenant, authToken: auth.getToken(), abortSignal: helpers.abortSignal });
                }
            } catch (e) {
                newRelicWrapper.noticeError(`Failed to verify folder for user: ${e?.message || e}`, { identityCanonicalID: currentUserName })
            }
        }
    }, [auth, currentUserName, isIdentityInitialized, tenant])
}
