import { useEffect } from "react";
import { useAppSelector } from "@shared/redux";

export function useGuidesVisibility() {
    const showRulers = useAppSelector(state => state.showRulers);
    const showSafetyArea = useAppSelector(state => state.showSafetyArea);
    const showBleed = useAppSelector(state => state.showBleed);
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);

    // This hook manipulates some cimpress-designer dom elements via jquery
    // Typescript ignoring jquery because we don't have types for jquery logic

    useEffect(() => {
        // @ts-ignore
        if (!easelLoaded || !window || !window.$) {
            return;
        }
        // @ts-ignore
        window.$(".dcl-canvas-dimensions").toggle(showRulers);
    }, [showRulers, easelLoaded, studioSelectedProductOptions]);

    useEffect(() => {
        // @ts-ignore
        if (!easelLoaded || !window || !window.$) {
            return;
        }
        // @ts-ignore
        window.$(".dcl-canvas__info-modal--safety").toggle(showSafetyArea);
        // @ts-ignore
        window.$(".dcl-canvas-margins__svg-safe").toggle(showSafetyArea);
        // This fix relies on safety & bleed currently being toggled together
        // Fixes a problem that the labels are re-apearing during resize
        // @ts-ignore
        window.$(".dcl-canvas-info").toggle(showSafetyArea);
    }, [showSafetyArea, easelLoaded, studioSelectedProductOptions]);

    useEffect(() => {
        // @ts-ignore
        if (!easelLoaded || !window || !window.$) {
            return;
        }
        // @ts-ignore
        window.$(".dcl-canvas__info-modal--bleed").toggle(showBleed);
        // @ts-ignore
        window.$(".dcl-canvas-margins__svg-bleed").toggle(showBleed);
    }, [showBleed, easelLoaded, studioSelectedProductOptions]);
}
