import React, { HTMLProps, useRef, useState, useLayoutEffect, MutableRefObject, forwardRef, useEffect } from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, IconChevronLeft, IconChevronRight } from "@vp/swan";
import * as styles from "./ElementsScroller.module.scss";

const messages = defineMessages({
    scrollRight: {
        id: "studio.features.elements.scrollRight",
        defaultMessage: "Scroll right",
        description: {
            note: "Aria label for button to scroll right on row of elements"
        }
    },
    scrollLeft: {
        id: "studio.features.elements.scrollLeft",
        defaultMessage: "Scroll left",
        description: {
            note: "Aria label for button to scroll left on row of elements"
        }
    }
});

interface Props extends HTMLProps<HTMLDivElement> {
    scrollSnap?: boolean;
}

export const ElementsScroller = forwardRef(
    ({ children, className, scrollSnap, ...restOfProps }: Props, ref: MutableRefObject<HTMLDivElement>) => {
        const containerRef = useRef<HTMLDivElement | null>(null);
        const contentsRef = useRef<HTMLDivElement | null>(null);
        const leftButtonRef = useRef<HTMLButtonElement | null>(null);
        const rightButtonRef = useRef<HTMLButtonElement | null>(null);
        const [showLeft, setShowLeft] = useState<boolean>(false);
        const [showRight, setShowRight] = useState<boolean>(false);
        const { t } = useTranslationSSR();

        // we're storing these widths to state instead of relying on refs because it was causing some issues
        // when changing between selected items. re-mounting didn't always pick up the updated refs.
        const [containerWidth, setContainerWidth] = useState<number>(0);
        const [contentsWidth, setContentsWidth] = useState<number>(0);

        useLayoutEffect(() => {
            setContainerWidth(containerRef.current?.clientWidth || 0);
            // on load determine if there's enough content to show the right scroll button
            setShowRight(containerWidth < contentsWidth);
        }, [containerWidth, contentsWidth]);

        const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
            event.persist();
            setShowLeft(event.currentTarget.scrollLeft > 0);
            setShowRight(event.currentTarget.scrollLeft + event.currentTarget.clientWidth + 1 < contentsWidth);
        };

        // this isn't inlined so that containerRef is mutable without casting it.
        const setContainerRef = (current: HTMLDivElement | null) => {
            containerRef.current = current;
            setContainerWidth(current?.clientWidth || 0);
            if (ref && current) {
                // eslint-disable-next-line no-param-reassign
                ref.current = current;
            }
        };

        const setContentsRef = (current: HTMLDivElement | null) => {
            contentsRef.current = current;
            setContentsWidth(current?.scrollWidth || 0);
            if (ref && current) {
                // eslint-disable-next-line no-param-reassign
                ref.current = current;
            }
        };

        const handleScroll = (moveToRight: boolean) => {
            if (containerRef.current) {
                const scroll = containerWidth;

                containerRef.current.scrollBy({
                    top: 0,
                    left: (moveToRight ? 1 : -1) * scroll,
                    behavior: "smooth"
                });
            }
        };

        useEffect(() => {
            // handle focus when the right arrow button is removed and is the focused element
            if (!showRight && document.activeElement === rightButtonRef.current) {
                // move focus to the last child of the scroller contents
                const lastChild = contentsRef.current?.lastElementChild as HTMLElement;
                lastChild?.focus();
            }
        }, [showRight]);

        useEffect(() => {
            // handle focus when the left arrow button is removed and is the focused element
            if (!showLeft && document.activeElement === leftButtonRef.current) {
                // move focus to the first child of the scroller contents
                const firstChild = contentsRef.current?.firstElementChild as HTMLElement;
                firstChild?.focus();
            }
        }, [showLeft]);

        return (
            <div className={styles.elementsScroller}>
                <div
                    ref={setContainerRef}
                    className={classNames(styles.elementsScrollContainer, {
                        [styles.elementsScrollContainerSnap]: scrollSnap
                    })}
                    onScroll={onScroll}
                >
                    {/* gradient that also extends the clickable area around scroll button */}
                    {/* skip in tab order since scroll button already receives focus */}
                    <Button
                        skin="unstyled"
                        tabIndex={-1}
                        className={classNames(styles.elementsScrollIndicator, styles.elementsScrollIndicatorLeft, {
                            [styles.elementsScrollIndicatorShow]: showLeft
                        })}
                        onClick={() => handleScroll(false)}
                        aria-label={t(messages.scrollLeft.id)}
                    />
                    <Button
                        skin="unstyled"
                        tabIndex={-1}
                        className={classNames(styles.elementsScrollIndicator, styles.elementsScrollIndicatorRight, {
                            [styles.elementsScrollIndicatorShow]: showRight
                        })}
                        onClick={() => handleScroll(true)}
                        aria-label={t(messages.scrollRight.id)}
                    />

                    {/* scroll buttons */}
                    <Button
                        ref={leftButtonRef}
                        buttonShape="round"
                        className={classNames(styles.elementsScrollButton, styles.elementsScrollButtonLeft, {
                            [styles.elementsScrollButtonShow]: showLeft
                        })}
                        onClick={() => handleScroll(false)}
                        aria-label={t(messages.scrollLeft.id)}
                    >
                        <IconChevronLeft />
                    </Button>
                    <Button
                        ref={rightButtonRef}
                        buttonShape="round"
                        className={classNames(styles.elementsScrollButton, styles.elementsScrollButtonRight, {
                            [styles.elementsScrollButtonShow]: showRight
                        })}
                        onClick={() => handleScroll(true)}
                        aria-label={t(messages.scrollRight.id)}
                    >
                        <IconChevronRight />
                    </Button>

                    <div
                        ref={setContentsRef}
                        className={classNames(styles.elementsScrollContent, className)}
                        data-dcl-prevent-canvas-items-deselection
                        {...restOfProps}
                    >
                        {children}
                    </div>
                </div>
            </div>
        );
    }
);

ElementsScroller.displayName = "ElementsScroller";
