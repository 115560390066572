import { useEffect, useState } from "react";
import { useActiveCanvas, useDocumentItems, useDesigner, useSelection } from "@designer-suite";
import {
    itemHasPremiumFinish,
    hasPremiumFinishes,
    itemFetchingPremiumFinish,
    getPremiumFinishes
} from "../utils-premium-finishes";

/**
 * A hook that provides the items on the active canvas that have a premium finish applied or are fetching one
 *
 * @returns {
 *   premiumFinishItems: [] - array of canvas items
 *   fetchingPremiumFinishItems: [] - array of canvas items
 * }
 */
export function useActiveCanvasPremiumFinishItems() {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const allCanvasDocumentItems = useDocumentItems(true);
    const supportedFinishes = getPremiumFinishes(canvas);
    const isRaisedFoil = supportedFinishes.some(finish => finish.color.includes("RaisedFoil"));
    const selectionEvents = [
        "model:change:premiumFinish",
        "model:change:premiumFinishMaskPreviewUrl",
        "change:fetchingPremiumFinishOverlay",
        "model:change:overprints",
        "model:change:data"
    ];
    if (isRaisedFoil) {
        selectionEvents.push("model:change:content");
    }
    const selection = useSelection(selectionEvents.join(" "));
    const [itemsWithPremiumFinish, setItemsWithPremiumFinish] = useState({
        premiumFinishItems: [] as CanvasItem[],
        fetchingPremiumFinishItems: [] as CanvasItem[]
    });
    useEffect(() => {
        if (!designer) return;
        const updatedItems = { premiumFinishItems: [] as CanvasItem[], fetchingPremiumFinishItems: [] as CanvasItem[] };
        if (!hasPremiumFinishes(canvas)) {
            setItemsWithPremiumFinish(updatedItems);
        } else {
            allCanvasDocumentItems.forEach(item => {
                if (itemHasPremiumFinish(item)) updatedItems.premiumFinishItems.push(item);
                if (itemFetchingPremiumFinish(item)) updatedItems.fetchingPremiumFinishItems.push(item);
            });
            setItemsWithPremiumFinish(updatedItems);
        }
    }, [designer, canvas, allCanvasDocumentItems, selection]);

    return itemsWithPremiumFinish;
}
