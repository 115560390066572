import React, { useState, useEffect } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesigner } from "@easel";
import { calcMessageReadingTime, AutoRotatedToastBox } from "@shared/features/SystemMessages";
import { chooseMessageType, messages } from "@shared/features/SystemMessages/Toasts/AutoRotatedToastBox";
import { ItemTypes } from "@shared/utils/StudioConfiguration";

const AUTO_ROTATE_EVENT = "auto-rotation";

export default function AutoRotatedToast() {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const [show, setShow] = useState(false);
    const [render, setRender] = useState(false);
    const [toastMessage, setToastMessage] = useState(messages.autoRotatedRefrenceMessage.id);

    useEffect(() => {
        let returnFn;
        if (render === true) {
            const timer = setTimeout(() => {
                setRender(false);
            }, calcMessageReadingTime(t(toastMessage)));
            returnFn = () => clearTimeout(timer);
        }
        return returnFn;
    }, [render, setRender, toastMessage, t]);

    useEffect(() => {
        if (!designer) {
            return;
        }

        const showToast = (rotatedItemType: ItemTypes) => {
            setShow(true);
            setRender(true);
            setToastMessage(chooseMessageType(rotatedItemType));
        };
        designer.eventBus.on(AUTO_ROTATE_EVENT, showToast);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(AUTO_ROTATE_EVENT, showToast);
        };
    }, [designer]);

    const onAnimationEnd = () => {
        if (!render) {
            setShow(false);
        }
    };

    return (
        <AutoRotatedToastBox
            show={show}
            render={render}
            setRender={setRender}
            toastMessage={t(toastMessage)}
            onAnimationEnd={onAnimationEnd}
        />
    );
}

AutoRotatedToast.displayName = "AutoRotatedToast";
