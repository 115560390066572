import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton, BucketColorIcon } from "@shared/features/StudioChrome";
import { Box } from "@vp/swan";
import { HSL } from "@design-stack-ct/utility-core";
import { SliderType } from "@shared/features/ColorPicker";
import { HslRecolorizationSliders } from "./HslRecolorizationSliders";

const recolorizeMessages = defineMessages({
    recolorizeButtonLabel: {
        id: "easel.designersuite.color.recolorizeButtonLabel",
        defaultMessage: "Color"
    }
});

interface HslRecolorizationExpandingButtonProps {
    value: HSL;
    onChange: (value: HSL) => void;
    displayType: "iconOnly" | "textOnly" | "both";
    onReset?: (type: string) => void;
    setSliding?: (arg: boolean, sliderType: SliderType) => void;
    handleCommit?: () => void;
    className?: string;
    buttonClassname?: string;
    hslMaxValues?: HSL;
    isDisabled?: boolean;
}

export function HslRecolorizationExpandingButton({
    className,
    buttonClassname,
    displayType,
    isDisabled,
    ...rest
}: HslRecolorizationExpandingButtonProps) {
    const { t } = useTranslationSSR();

    return (
        <ExpandingButton
            className={className}
            buttonClassName={buttonClassname}
            showArrow={false}
            title={t(recolorizeMessages.recolorizeButtonLabel.id)}
            displayType="singlerow"
            content={
                <Box p={3}>
                    <HslRecolorizationSliders {...rest} />
                </Box>
            }
            isDisabled={isDisabled}
        >
            {(displayType === "iconOnly" || displayType === "both") && <BucketColorIcon aria-hidden={true} />}
            {(displayType === "textOnly" || displayType === "both") && (
                <div className="dcl-ctx-button-label">{t(recolorizeMessages.recolorizeButtonLabel.id)}</div>
            )}
        </ExpandingButton>
    );
}
HslRecolorizationExpandingButton.displayName = "HslRecolorizationExpandingButton";
