import { useState, useEffect } from "react";
import { useActiveCanvas, useSelection } from "@designer-suite";
import { teamsPlaceholderFilter } from "@utilities";

function getWidthPercent(items: CanvasItem, canvas: Canvas) {
    return (
        items.mmDimensions &&
        canvas.mmDimensions &&
        Math.round((items.mmDimensions.width * 100) / canvas.mmDimensions.width)
    );
}

export function useTeamsFontSize() {
    const canvas = useActiveCanvas();
    const selection = useSelection("model:change:width");
    const { teamsPlaceholderItems } = teamsPlaceholderFilter(selection);
    const [current, onChange] = useState<number>(0);

    const widthPercent = teamsPlaceholderItems[0] && canvas && getWidthPercent(teamsPlaceholderItems[0], canvas);

    useEffect(() => {
        if (widthPercent) {
            onChange(widthPercent);
        }
    }, [widthPercent]);

    return { current, onChange };
}
