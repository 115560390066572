/* eslint-disable max-len */
import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

export function MinimizeIcon(props: Props) {
    return (
        <svg {...props} viewBox="0 0 16 16" fill="none">
            <line
                x1="4.5"
                y1="7.83398"
                x2="11.5"
                y2="7.83398"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}
MinimizeIcon.displayName = "MinimizeIcon";
