import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { useCallback } from "react";
import { triggerCanvasChange } from "../utilities/canvasUtilities";

export function useTriggerCanvasChange(): TriggerCanvasChange {
    const { isSmall } = useStudioLayout();

    return useCallback((activeCanvasName: string) => triggerCanvasChange(activeCanvasName, isSmall), [isSmall]);
}
