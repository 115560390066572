import { update, get } from "idb-keyval";
import { formatUpload, Upload } from "@shared/features/UploadsAndAssets";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { Designer } from "../../../../easel/designer-suite/@types/designer";
import { DP_IMAGE_PREFIX, NP_IMAGE_PREFIX } from "../../../components/Panels/Images/ImagePanelUtils";

const host = getQueryParams().imageLibraryUrl || IMAGE_LIBRARY_SERVICE_URL;
const RECENTLY_USED_ELEMENTS_KEY = "recentlyUsedElements";
const MAX_IMAGES_TO_KEEP = 100;

export const SIMTAG_PREFIX = "SIMTAG_";

const imageType = {
    DepositPhotos: {
        prefix: DP_IMAGE_PREFIX,
        urlPath: "image"
    },
    NounProject: {
        prefix: NP_IMAGE_PREFIX,
        urlPath: "icon"
    }
};

// combines ImageItem and ClipartItem
export interface ElementItem {
    id: string;
    src?: string;
    width: string | number;
    height: string | number;
    previewUrl: string;
    printUrl?: string;
    printPixelHeight?: number;
    printPixelWidth?: number;
    svgUrl?: string;
    imageSource?: string;
    type?: string;
    extension?: string;
}

// Element item with upload: designer modeled upload
export interface ElementUploadItem {
    id: string;
    src: string;
    width: number;
    height: number;
    upload: Upload;
    svgUrl?: string;
    imageSource?: string;
    type?: string;
    extension?: string;
    key: string;
    component?: JSX.Element;
}

const addObjectToArrayStart = (arr: ElementItem[] | undefined, entry: ElementItem, maxStorage?: number) => {
    if (arr) {
        if (!arr.find(item => item.id === entry.id && item.imageSource === entry.imageSource)) {
            arr.unshift(entry);
            if (maxStorage && arr.length > maxStorage) {
                return arr.slice(arr.length - maxStorage, arr.length);
            }
        }
        return arr;
    }
    return [entry];
};

export const updateRecentlyUsedElements = async (image: ElementItem) => {
    const { id, src, width, height, svgUrl, previewUrl, printUrl, printPixelHeight, printPixelWidth, imageSource } =
        image;
    const entry = {
        id,
        src,
        previewUrl,
        width,
        height,
        svgUrl,
        printUrl,
        printPixelHeight,
        printPixelWidth,
        imageSource
    };

    update(RECENTLY_USED_ELEMENTS_KEY, val => addObjectToArrayStart(val, entry, MAX_IMAGES_TO_KEEP));
};
export const getRecentlyUsedElements = async () => {
    const clipart = await get(RECENTLY_USED_ELEMENTS_KEY);
    return clipart || undefined;
};

const createDesignerModel = (imageResult: ElementItem, designer: Designer) => {
    if (!designer) {
        return undefined;
    }
    const imageId = imageResult.imageSource
        ? `${imageType[imageResult.imageSource].prefix}${imageResult.id}`
        : `${SIMTAG_PREFIX}${imageResult.id}`;

    if (imageId.startsWith(DP_IMAGE_PREFIX)) {
        const { urlPath } = imageResult.imageSource && imageType[imageResult.imageSource];
        const url = `${host}/v1/${urlPath}/${imageResult.id}?requestor=studio`;
        const fileType = `${imageResult.type}/${imageResult.extension}`;
        const upload = {
            ...imageResult,
            originalFileContentType: fileType,
            printUrl: url,
            uploadId: imageId,
            url,
            status: "50",
            analysisIsLogo: "False",
            analysisIsPhoto: "True",
            analysisIsVector: "False",
            analysisLineartness: "1",
            printPixelHeight: imageResult.height,
            printPixelWidth: imageResult.width
        };

        const formatedUpload = formatUpload(upload);
        const model = designer.uploadManager.createModels(formatedUpload);
        return model;
    }
    if (imageId?.startsWith(SIMTAG_PREFIX)) {
        const extension = imageResult.extension || "png";
        const type = imageResult.type || "image";
        const upload = {
            ...imageResult,
            originalFileContentType: `${type}/${extension}`,
            previewUrl: imageResult.svgUrl,
            uploadId: imageId,
            url: imageResult.printUrl,
            status: "50",
            analysisIsLogo: "False",
            analysisIsPhoto: "True",
            analysisIsVector: "False",
            analysisLineartness: "1",
            extension,
            type
        };
        const formatedUpload = formatUpload(upload);
        const model = designer.uploadManager.createModels(formatedUpload);
        return model;
    }
    if (imageId.startsWith(NP_IMAGE_PREFIX)) {
        const { urlPath } = imageResult.imageSource && imageType[imageResult.imageSource];
        const url = `${host}/v1/${urlPath}/${imageResult.id}?requestor=studio`;
        const extension = imageResult.extension || "png";
        const type = imageResult.type || "image";
        const fileType = `${type}/${extension}`;
        const upload = {
            ...imageResult,
            originalFileContentType: fileType,
            printUrl: url,
            uploadId: imageId,
            url,
            status: "50",
            analysisIsLogo: "False",
            analysisIsPhoto: "True",
            analysisIsVector: "False",
            analysisLineartness: "1",
            printPixelHeight: imageResult.height,
            printPixelWidth: imageResult.width
        };

        const formatedUpload = formatUpload(upload);
        const model = designer.uploadManager.createModels(formatedUpload);
        return model;
    }
    return undefined;
};

export const createDesignerModeledElements = (results: ElementItem[], designer: Designer): ElementUploadItem[] => {
    return results
        .filter((img: ElementItem) => img.width && img.height)
        .map((img: ElementItem) => {
            const width = typeof img.width === "number" ? img.width : parseInt(img.width, 10);
            const height = typeof img.height === "number" ? img.height : parseInt(img.height, 10);
            const value: ElementUploadItem = {
                id: img.id,
                src: img.previewUrl,
                width,
                height,
                upload: createDesignerModel(img, designer),
                svgUrl: img.svgUrl,
                imageSource: img.imageSource,
                key: img.id
            };
            return value;
        });
};
