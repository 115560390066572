import { useEffect, useState } from "react";

export enum OSName {
    mac = "mac",
    windows = "windows"
}

export function usePlatformName() {
    const [OS, setOS] = useState(OSName.windows);

    useEffect(() => {
        let platform;
        if (typeof window === "undefined") {
            // In gatsby window is not available during build times and throws error.
            platform = OSName.windows;
        }
        // UserAgentData is experimental so not included in the default typings
        platform = window?.navigator?.platform || (window.navigator as any)?.userAgentData?.platform;
        if (platform?.toLowerCase().search(OSName.mac) !== -1) {
            platform = OSName.mac;
        } else {
            platform = OSName.windows;
        }

        setOS(platform);
    }, []);
    return { OS };
}
