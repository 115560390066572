import React from "react";
import { Button, Icon } from "@vp/swan";
import * as styles from "./ElementsRowArrowButton.module.scss";

export const ElementsRowArrowButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <Button
            className={styles.elementsRowArrowButton}
            skin="primary"
            buttonShape="round"
            size="mini"
            marginLeft={4}
            {...props}
        >
            <Icon iconType="arrowRight" />
        </Button>
    );
};

ElementsRowArrowButton.displayName = "ElementsRowArrowButton";
