import React, { useEffect, useRef, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton
} from "@vp/swan";
import { useDesigner } from "@designer-suite";
import { FinishMessages, PremiumFinishWarning, SUPPORTED_FINISHES } from "@shared/features/PremiumFinish";
import classNames from "classnames";
import { useAppSelector, useAppDispatch, setIsPremiumFinishModalOpen } from "@shared/redux";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { useStudioFlexibility } from "@shared/features/Flexibility";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { useActiveDialog } from "@shared/features/ActiveDialog";
import { PremiumFinishPreview } from "@shared/features/Previews";
import { PremiumFinishSelection } from "./PremiumFinishSelection";
import messages from "./messages";
import { useSidebarNavConfiguration } from "../FloatingSidebar/useSidebarNavConfiguration";
import { useActiveCanvas } from "../../../../easel";
import { usePremiumFinishWarning } from "./usePremiumFinishFilter";

export function PremiumFinishModal() {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const { enabledCanvasFinish } = useStudio5AvailablePremiumFinishesOnCanvas();
    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;
    const isOpen = useAppSelector(state => state.isPremiumFinishModalOpen);
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const modalDialogContentRef = useRef<HTMLDivElement>(null);
    const premiumFinishPreviewRef = useRef<HTMLDivElement>(null);
    const [isPremiumFinishPreviewSticky, setPremiumFinishPreviewSticky] = useState(false);
    const { isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isFlexibilityFeatureEnabled = isMileStone2Enabled || isMileStone3Enabled;
    const showPreviewState = useAppSelector(state => state.previewOpen);
    const { setCurrentActiveDialog } = useActiveDialog();
    const { defaultPanelDialogType } = useSidebarNavConfiguration();
    const { isXerox } = useXerox();
    const activeCanvas = useActiveCanvas();
    const warning = usePremiumFinishWarning();

    const onClose = () => {
        dispatch(setIsPremiumFinishModalOpen({ isPremiumFinishModalOpen: false }));

        // If in xerox mode, then also reopen the default left panel
        // Otherwise there would be a blank left panel area
        if (isXerox && defaultPanelDialogType) {
            setCurrentActiveDialog(defaultPanelDialogType);
        }
    };

    useEffect(() => {
        const updateStickyBehavior = () => {
            const premiumFinishPreviewDistanceFromTop = premiumFinishPreviewRef.current?.getBoundingClientRect()?.top;
            if (premiumFinishPreviewDistanceFromTop === 0) {
                setPremiumFinishPreviewSticky(true);
            } else {
                setPremiumFinishPreviewSticky(false);
            }
        };

        let refValue: HTMLDivElement;
        if (modalDialogContentRef?.current) {
            refValue = modalDialogContentRef.current;
            modalDialogContentRef.current?.addEventListener("scroll", updateStickyBehavior, false);
            return function cleanup() {
                refValue?.removeEventListener("scroll", updateStickyBehavior, false);
            };
        }
        return () => {};
    }, [modalDialogContentRef, premiumFinishPreviewRef]);

    if (!(designer && finishType && SUPPORTED_FINISHES.includes(finishType) && !isFullBleed)) {
        return null;
    }

    return (
        <LegacyModalDialog
            className={classNames("premium-finish-modal-dialog", {
                "flexibility-feat-enabled": isFlexibilityFeatureEnabled,
                "premium-finish-modal-dialog--disabled": showPreviewState
            })}
            isOpen={isOpen}
            onRequestDismiss={onClose}
            variant={isFlexibilityFeatureEnabled ? "panel-right" : undefined}
            takeOver={!isFlexibilityFeatureEnabled}
        >
            <LegacyModalDialogContent
                ref={modalDialogContentRef}
                fullBleed={true}
                aria-label={t(messages.ariaModalLabel.id)}
            >
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.ariaCloseLabel.id)} />
                <LegacyModalDialogBody>
                    <PremiumFinishWarning
                        className="hide-large-warning"
                        showWarning={warning}
                        message={t(messages.errorMessage.id, { finishType: t(FinishMessages[finishType].id) })}
                    />
                    <PremiumFinishPreview
                        ref={premiumFinishPreviewRef}
                        isPremiumFinishPreviewSticky={isPremiumFinishPreviewSticky}
                        activeCanvasName={activeCanvas?.name}
                    />
                    <PremiumFinishSelection finishType={finishType} onClose={onClose} />
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

PremiumFinishModal.displayName = "PremiumFinishModal";
