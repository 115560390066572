import { defineMessages } from "@vp/i18n-helper";

export default defineMessages({
    // Item Types
    "itemTypes-text": {
        id: "studio.itemTypes.text",
        defaultMessage: "text",
        description: { note: "Studio text item type" }
    },
    "itemTypes-image": {
        id: "studio.itemTypes.image",
        defaultMessage: "image",
        description: { note: "Studio image item type" }
    },
    "itemTypes-shape": {
        id: "studio.itemTypes.shape",
        defaultMessage: "shape",
        description: { note: "Studio shape item type" }
    },
    "itemTypes-table": {
        id: "studio.itemTypes.table",
        defaultMessage: "table",
        description: { note: "Studio table item type" }
    },
    "itemTypes-qrcode": {
        id: "studio.itemTypes.qrcode",
        defaultMessage: "QR code",
        description: { note: "Studio QR code type" }
    },
    "itemTypes-item": {
        id: "studio.itemTypes.item",
        defaultMessage: "item",
        description: { note: "Generic item type to use when it is unknown" }
    },

    // Validations
    "betweenBoundaries-warning-title": {
        id: "studio.validations.betweenBoundaries.warning.title",
        defaultMessage: "White border around the edge",
        description: { note: "Warning validation group description for items that are between two boundaries" }
    },
    "betweenBoundaries-warning-message": {
        id: "studio.validations.betweenBoundaries.warning.itemMessage",
        defaultMessage: "If the white border is not part of your design, extend the [[itemType]] to the Bleed line.",
        description: { note: "Warning messaging for when an item is between two boundaries" }
    },
    "betweenBoundaries-warning-tooltip": {
        id: "studio.validations.betweenBoundaries.warning.tooltip",
        defaultMessage:
            "Stretching your design to the Bleed line guarantees that you won't end up with white edges/borders on your printed product.",
        description: { note: "More extensive warning messaging for when an item is between two boundaries" }
    },

    "imageResolution-warning-title": {
        id: "studio.validations.imageResolution.warning.title",
        defaultMessage: "Low image resolution",
        description: { note: "Warning validation group description for images that have low resolution" }
    },
    "imageResolution-warning-message": {
        id: "studio.validations.imageResolution.warning.itemMessage",
        defaultMessage:
            "This image isn’t clear enough to print at this resolution. Please replace it with a higher resolution image or scale it down.",
        description: { note: "Warning messaging for images that aren't that sharp but are printable" }
    },
    "imageResolution-warning-tooltip": {
        id: "studio.validations.imageResolution.warning.tooltip",
        defaultMessage:
            // eslint-disable-next-line max-len
            "Resolution is the degree of detail visible in an image. High resolution means there are many dots per inch. Low resolution means fewer dots per inch, and poorer quality. For VistaPrint products, aim for at least 300 dpi at the desired image size unless the product indicates otherwise.",
        description: { note: "More extensive warning messaging for images that aren't that sharp but are printable" }
    },

    "imageResolution-error-title": {
        id: "studio.validations.imageResolution.error.title",
        defaultMessage: "Low image resolution – unable to print",
        description: { note: "Error validation group description for images that have low resolution" }
    },
    "imageResolution-error-message": {
        id: "studio.validations.imageResolution.error.itemMessage",
        defaultMessage:
            "This image isn’t clear enough to print at this resolution. Please replace it with a higher resolution image or scale it down.",
        description: { note: "Error messaging for images that aren't that sharp enough to print" }
    },
    "imageResolution-error-tooltip": {
        id: "studio.validations.imageResolution.error.tooltip",
        defaultMessage:
            // eslint-disable-next-line max-len
            "Resolution is the degree of detail visible in an image. High resolution means there are many dots per inch. Low resolution means fewer dots per inch, and poorer quality. For VistaPrint products, aim for at least 300 dpi at the desired image size unless the product indicates otherwise.",
        description: { note: "More extensive error messaging for images that aren't that sharp enough to print" }
    },

    "outsideBounds-warning-title": {
        id: "studio.validations.outsideBounds.warning.title",
        defaultMessage: "Outside the Bleed line",
        description: {
            note: "Warning validation group description for items that are outside of the safety area"
        }
    },
    "outsideBounds-warning-message": {
        id: "studio.validations.outsideBounds.warning.itemMessage",
        defaultMessage: "To avoid this [[itemType]] cutting off during printing, move it inside the Bleed line.",
        description: { note: "Warning messaging for when an item is outside of the safety area" }
    },
    "outsideBounds-warning-tooltip": {
        id: "studio.validations.outsideBounds.warning.tooltip",
        defaultMessage:
            // eslint-disable-next-line max-len
            "Objects outside the Safety Area may get cut off during the trimming process. For best results, keep text and important parts of your photos, logos and designs within the Safety Area.",
        description: { note: "More extensive warning messaging for when an item is outside of the safety area" }
    },
    "outsideBounds-error-title": {
        id: "studio.validations.outsideBounds.error.title",
        defaultMessage: "Outside the Bleed line",
        description: {
            note: "Error validation group description for items that are outside of the safety area"
        }
    },
    "outsideBounds-error-message": {
        id: "studio.validations.outsideBounds.error.itemMessage",
        defaultMessage: "To make sure your design embroiders properly, move the [[itemType]] inside the Bleed line.",
        description: { note: "Error messaging for when an item is outside of the safety area" }
    },
    "outsideBounds-error-tooltip": {
        id: "studio.validations.outsideBounds.error.tooltip",
        defaultMessage:
            "Objects outside the Safety Area can't be printed. For best results, keep text and other important parts of your design within the Safety Area.",
        description: { note: "More extensive error messaging for when an item is outside of the safety area" }
    },

    "outsideMargins-warning-title": {
        id: "studio.validations.outsideMargins.warning.title",
        defaultMessage: "Outside the Safety Area",
        description: { note: "Warning validation group description for items that are outside of the margin" }
    },
    "outsideMargins-warning-message": {
        id: "studio.validations.outsideMargins.warning.itemMessage",
        defaultMessage: "To avoid this [[itemType]] cutting off during printing, move it inside the Safety Area.",
        description: { note: "Warning messaging for when an item is outside of the margin" }
    },
    "outsideMargins-error-title": {
        id: "studio.validations.outsideMargins.error.title",
        defaultMessage: "Outside the Safety Area",
        description: { note: "Error validation group description for items that are outside of the margin" }
    },
    "outsideMargins-error-message": {
        id: "studio.validations.outsideMargins.error.itemMessage",
        defaultMessage: "To make sure your design embroiders properly, move the [[itemType]] into the Safety Area.",
        description: { note: "Error messaging for when an item is outside of the margin" }
    },

    "overlap-warning-title": {
        id: "studio.validations.overlap.warning.title",
        defaultMessage: "Overlapping with another object",
        description: { note: "Warning validation group description for text that are overlapping" }
    },
    "overlap-warning-message": {
        id: "studio.validations.overlap.warning.itemMessage",
        defaultMessage:
            "To make sure your design prints correctly, move the [[itemType]] so it’s not overlapping another object.",
        description: { note: "Warning messaging for when a text is overlapping another object" }
    },

    "overlap-warning-tooltip": {
        id: "studio.validations.overlap.warning.tooltip",
        defaultMessage:
            "It’s important that none of the text is overlapping with another object or text in your design. This makes sure that all text is easy to read.",
        description: { note: "More extensive warning messaging for when a text is overlapping another object" }
    },

    "overlap-error-title": {
        id: "studio.validations.overlap.error.title",
        defaultMessage: "Overlapping with another object – unable to print",
        description: { note: "Error validation group description for items that are overlapping" }
    },
    "overlap-error-message": {
        id: "studio.validations.overlap.error.itemMessage",
        defaultMessage:
            "To make sure your design prints correctly, move the [[itemType]] so it’s not overlapping another object.",
        description: { note: "Error messaging for when an object is overlapping another object" }
    },

    "overlap-error-tooltip": {
        id: "studio.validations.overlap.error.tooltip",
        defaultMessage:
            "It’s important that none of the text is overlapping with another object or text in your design. This makes sure that all text is easy to read.",
        description: { note: "More extensive error messaging for when an object is overlapping another object" }
    },

    unknownValidationTitle: {
        id: "studio.validations.unknownvalidation.title",
        defaultMessage: "Unknown validation",
        description: { note: "Error validation title for unknown validation" }
    },
    unknownValidationMessage: {
        id: "studio.validations.unknownvalidation.message",
        defaultMessage: "An unknown validation occurred",
        description: { note: "Error validation description for unknown validation" }
    }
});
