import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Box, Typography } from "@vp/swan";
import { ImageRecolorizationTool } from "../../../../easel/designer-suite/ToolbarComponents/Buttons/ImageRecolorizationTool";
import * as styles from "./EditImageColor.module.scss";

const messages = defineMessages({
    adjustColor: {
        id: "studio.panel.editImage.adjustColor",
        defaultMessage: "Adjust color",
        description: {
            note: "Label for a section of image tools that allow color changes"
        }
    },
    iconColor: {
        id: "studio.panel.editImage.iconColor",
        defaultMessage: "Icon color",
        description: {
            note: "Label for a section of icon tools that allow color changes"
        }
    }
});

export function EditImageColor({ isIcon }: { isIcon: boolean }) {
    const { t } = useTranslationSSR();

    return (
        <Box marginTop={3}>
            <Typography fontSize={1} fontWeight="bold" marginBottom={4}>
                {isIcon ? t(messages.iconColor.id) : t(messages.adjustColor.id)}
            </Typography>
            <ImageRecolorizationTool contentOnly displayType="both" className={styles.editImageColorRecolorization} />
        </Box>
    );
}
EditImageColor.displayName = "EditImageColor";
