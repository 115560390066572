import React from "react";
import { PolygonShape, ShapeTypes } from "@cimpress-technology/svg-path";
import { DraggableCurve } from "./DraggableCurve";

const metadata: PolygonShape = {
    type: ShapeTypes.Polygon,
    numberOfSides: 5,
    borderRadius: 0
};

export const DraggablePolygon = () => {
    return <DraggableCurve metadata={metadata} />;
};

DraggablePolygon.displayName = "DraggablePolygon";
