import React from "react";
import { isSafari } from "react-device-detect";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { FlexBox, Divider, FieldSet, FieldSetLegend } from "@vp/swan";
import { useStudioLayout, DesktopExperience } from "@shared/features/ResponsiveDesign";
import {
    useAppSelector,
    useAppDispatch,
    setShowRulers,
    setShowSafetyArea,
    setShowBleed,
    setShowGridlines,
    setPremiumFinishToggle,
    setShowDesignIssues
} from "@shared/redux";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { useStudioFlexibility } from "@shared/features/Flexibility";
import { ViewToggleRow } from "@shared/features/ViewPanel";
import { KeyboardShortcutsButton } from "@shared/features/KeyboardShortcuts";
import { useIsPremiumFinishProduct } from "../Redesign/PremiumFinish/usePremiumFinishFilter";
import * as styles from "./ViewPanelContent.module.scss";

const messages = defineMessages({
    rulers: {
        id: "studio.ui.component.guidesPanelContent.rulers",
        defaultMessage: "Rulers",
        description: {
            note: "Label for rulers toggle"
        }
    },
    rulersDescription: {
        id: "studio.ui.component.guidesPanelContent.rulersDescription",
        defaultMessage: "This is the size of your design.",
        description: {
            note: "Description for rulers toggle"
        }
    },
    bleedSafety: {
        id: "studio.ui.component.guidesPanelContent.bleedSafety",
        defaultMessage: "Safety area & Bleed",
        description: {
            note: "Label for bleed & safety area toggle"
        }
    },
    bleedSafetyDescription: {
        id: "studio.ui.component.guidesPanelContent.bleedSafetyDescription",
        defaultMessage: "Text and images should fit inside, anything outside the line will get cut.",
        description: {
            note: "Description for bleed & safety area toggle"
        }
    },
    grids: {
        id: "studio.ui.component.guidesPanelContent.grids",
        defaultMessage: "Grids",
        description: {
            note: "Label for grids toggle"
        }
    },
    gridLinesDescription: {
        id: "studio.ui.component.guidesPanelContent.gridLinesDescription",
        defaultMessage: "Used to align your design.",
        description: {
            note: "Description for grid lines toggle"
        }
    },
    printFinishToggle: {
        id: "studio.ui.component.guidesPanelContent.printFinishToggle",
        defaultMessage: "Show print finishes",
        description: {
            note: "Label for toggling print finishes"
        }
    },
    printFinishToggleContent: {
        id: "studio.ui.component.guidesPanelContent.printFinishToggleContent",
        defaultMessage: "Show how premium finishes look while you are editing your design.",
        description: {
            note: "Description for premium finish toggle"
        }
    },
    viewPanel: {
        id: "studio.ui.component.guidesPanel.viewPanel",
        defaultMessage: "View",
        description: {
            note: "Title for the view panel"
        }
    },
    designIssues: {
        id: "studio.ui.component.guidesPanelContent.designIssues",
        defaultMessage: "Design Issues",
        description: {
            note: "Label for Design Issues toggle"
        }
    },
    designIssuesContent: {
        id: "studio.ui.component.guidesPanelContent.designIssuesContent",
        defaultMessage: "Show issues with my designs that need my attention.",
        description: {
            note: "Description for Design Issues toggle"
        }
    }
});

export function ViewPanelContent() {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();

    const showRulers = useAppSelector(state => state.showRulers);
    const showSafetyArea = useAppSelector(state => state.showSafetyArea);
    const showBleed = useAppSelector(state => state.showBleed);
    const showGridlines = useAppSelector(state => state.showGridlines);
    const showDesignIssues = useAppSelector(state => state.showDesignIssues);
    const showPremiumFinishToggle = useIsPremiumFinishProduct();
    const isPremiumFinishToggleOn = useAppSelector(state => state.isPremiumFinishToggleOn);
    const { isSmartValidationsEnabled } = useSmartValidations();
    const { isSmall } = useStudioLayout();

    const { isMileStone1Enabled } = useStudioFlexibility();
    const isRulerEnabled = isSmall ? false : !isMileStone1Enabled;

    return (
        <FlexBox className={styles.guidePanelContent} flexDirection="column">
            <FieldSet>
                <FieldSetLegend visuallyHidden>{t(messages.viewPanel.id)}</FieldSetLegend>
                {isSmartValidationsEnabled && (
                    <ViewToggleRow
                        label={t(messages.designIssues.id)}
                        labelId="designIssues"
                        activated={showDesignIssues}
                        onRequestActivatedChange={activated => dispatch(setShowDesignIssues(activated))}
                        description={t(messages.designIssuesContent.id)}
                    />
                )}

                {showPremiumFinishToggle && !isSafari && (
                    <ViewToggleRow
                        label={t(messages.printFinishToggle.id)}
                        labelId="premiumFinishToggle"
                        activated={isPremiumFinishToggleOn}
                        onRequestActivatedChange={activated => dispatch(setPremiumFinishToggle(activated))}
                        description={t(messages.printFinishToggleContent.id)}
                    />
                )}
                <ViewToggleRow
                    label={t(messages.grids.id)}
                    labelId="gridsLabel"
                    activated={showGridlines}
                    onRequestActivatedChange={activated => dispatch(setShowGridlines(activated))}
                    description={t(messages.gridLinesDescription.id)}
                />
                {isRulerEnabled && (
                    <ViewToggleRow
                        label={t(messages.rulers.id)}
                        labelId="rulersLabel"
                        activated={showRulers}
                        onRequestActivatedChange={activated => dispatch(setShowRulers(activated))}
                        description={t(messages.rulersDescription.id)}
                    />
                )}
                <ViewToggleRow
                    label={t(messages.bleedSafety.id)}
                    labelId="safetyAndBleedAreaLabel"
                    activated={showSafetyArea && showBleed}
                    onRequestActivatedChange={activated => {
                        dispatch(setShowSafetyArea(activated));
                        dispatch(setShowBleed(activated));
                    }}
                    description={t(messages.bleedSafetyDescription.id)}
                />
            </FieldSet>
            <DesktopExperience>
                <div>
                    <Divider marginTop={5} marginBottom={4} />
                    <KeyboardShortcutsButton />
                </div>
            </DesktopExperience>
        </FlexBox>
    );
}
ViewPanelContent.displayName = "ViewPanelContent";
