import React, { ReactNode } from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useDocumentItems } from "./designer/useDocumentItems";
import {
    ItemCalendarGridYearSelector,
    shouldShowCalendarGridYearSelector
} from "./dropdowns/ItemCalendarGridYearSelector";

interface Props {
    /** A function that given an Item resolves the component to show for that Item */
    resolveComponent: (item: CanvasItem) => ReactNode | null;

    /**
     * Only show items that are on the active canvas
     * @default true
     * */
    onlyActiveCanvas?: boolean;

    /** Styles to be applied to this component */
    className?: string;
}

/**
 * An Item List component that will list out the components for every item on the document
 */

export function ItemList({ resolveComponent, onlyActiveCanvas = true, className }: Props) {
    const { isSmall } = useStudioLayout();
    const items = useDocumentItems(onlyActiveCanvas);
    const showCalendarYear = shouldShowCalendarGridYearSelector(items);

    return (
        <div className={className}>
            {showCalendarYear && <ItemCalendarGridYearSelector className={isSmall ? "swan-mb-6" : "swan-mt-5"} />}
            {items.map((item: CanvasItem) => item && <div key={item.id}>{resolveComponent(item)}</div>)}
        </div>
    );
}
ItemList.displayName = "ItemList";
