import React, { useRef, useState } from "react";
import {
    Button,
    SearchFilterIcon,
    SearchFilterSelectedIcon,
    SearchIcon,
    SearchResetIcon
} from "@shared/features/StudioChrome";

import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { TextInput, TextInputWithButtonInset } from "@vp/swan";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTemplatePanel } from "./TemplatePanelProvider";
import { TemplateSearchFiltersPanel } from "./TemplateSearchFiltersPanel";

const messages = defineMessages({
    templatePanelSearchInputText: {
        id: "studio.panels.template.main.searchInput.text",
        defaultMessage: "Search all templates",
        description: {
            note: "Default text for the template panel search input"
        }
    },
    templatePanelSearchInputReset: {
        id: "studio.panels.template.main.searchInput.reset",
        defaultMessage: "Reset Search",
        description: {
            note: "Accessibility label for the template panel search input reset"
        }
    },
    templatePanelSearchInputButton: {
        id: "studio.panels.template.main.searchInput.button",
        defaultMessage: "Search templates",
        description: {
            note: "Accessibility label for the template panel search button"
        }
    },
    templatePanelSearchFilterButtonLabel: {
        id: "studio.panels.template.main.searchInput.filterButton.label",
        defaultMessage: "Filter templates",
        description: {
            note: "Label for a filter template search button"
        }
    }
});

type Props = {
    className?: string;
};

export const TemplateSearchBar = (props: Props) => {
    const { className } = props;
    const { t } = useTranslationSSR();
    const {
        isFilterPanelOpen,
        setIsFilterPanelOpen,
        anyFiltersSelected,
        changeTemplateSearchTerm,
        setChangeTemplateSearchTerm
    } = useTemplatePanel();
    const [searchTerm, setSearchTerm] = useState(changeTemplateSearchTerm);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const resetSearchInputField = () => {
        setSearchTerm("");
        setChangeTemplateSearchTerm(undefined);
    };

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { value } = event.target;
        setSearchTerm(value);
    };

    const handleSearch = () => {
        if (searchTerm) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CHANGE_TEMPLATE_SEARCH,
                label: "Change template search",
                extraData: () => ({ searchTerm })
            });
            setChangeTemplateSearchTerm(searchTerm);
        } else {
            resetSearchInputField();
        }
    };
    return (
        <TextInputWithButtonInset skin="searchbar" mb={4} className={className}>
            <TextInput
                value={searchTerm}
                onChange={handleOnChange}
                placeholder={t(messages.templatePanelSearchInputText.id)}
                title={t(messages.templatePanelSearchInputText.id)}
                onKeyPress={(event: { key: string }) => {
                    if (event.key === "Enter") handleSearch();
                }}
                type="search"
                className="search-input search-input-text"
            />
            <Button
                ref={buttonRef}
                onClick={() => {
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.CLICK_TEMPLATE_FILTERS_TOGGLE,
                        label: "Click template panel filters toggle"
                    });
                    setIsFilterPanelOpen(current => !current);
                }}
                className="template-panel-filters-button filters-button"
                isSelected={isFilterPanelOpen}
                aria-label={t(messages.templatePanelSearchFilterButtonLabel.id)}
            >
                {anyFiltersSelected ? (
                    <SearchFilterSelectedIcon className="filters-button-selected-icon" />
                ) : (
                    <SearchFilterIcon className="filters-button-icon" />
                )}
            </Button>
            {searchTerm && (
                <Button
                    className="template-panel-search-reset-button"
                    onClick={resetSearchInputField}
                    aria-label={t(messages.templatePanelSearchInputReset.id)}
                >
                    <SearchResetIcon className="search-reset-icon" />
                </Button>
            )}
            <TemplateSearchFiltersPanel buttonRef={buttonRef} />
            <Button
                onClick={() => handleSearch()}
                className="search-template-button"
                aria-label={t(messages.templatePanelSearchInputButton.id)}
            >
                <SearchIcon />
            </Button>
        </TextInputWithButtonInset>
    );
};

TemplateSearchBar.displayName = "TemplateSearchBar";
