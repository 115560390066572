import { useCallback } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { newRelicWrapper, handleError, ERROR_CODES } from "@shared/utils/Errors";
import { addQueryParam } from "@shared/utils/WebBrowser";
import {
    useAppSelector,
    useAppDispatch,
    setSaveSuccess,
    hideLoader,
    showSaveLoader,
    setForceWorkRecovery
} from "@shared/redux";
import { useCartContext } from "@shared/features/Cart";
import { type SaveDesignParams } from "@shared/utils/Save";
import { type WorkEntity } from "@shared/utils/Work";
import { updateCart } from "@shared/utils/Cart";

const MIN_SAVE_TIME = 1000;

const messages = defineMessages({
    savingYourDesign: {
        id: "studio.components.spinners.savingYourDesign",
        defaultMessage: "Saving your design...",
        description: {
            note: "Spinner text shown when entire studio screen is blocked due to saving the project"
        }
    }
});

export type SaveDesignWithSaveDocumentOmitted = (params: Omit<SaveDesignParams, "saveDocument">) => Promise<WorkEntity>;
export type Save = (workName?: string) => Promise<{ success: true; work: WorkEntity } | { success: false }>;

export interface UseSaveParams {
    onSaveCallback?: () => void;
    allowAnonymousUser?: boolean;
    hideSaveToast?: boolean;
    showLoader?: boolean;
    trackingEventData?: string;
    saveDesign: SaveDesignWithSaveDocumentOmitted;
}

export const useSave = ({
    onSaveCallback,
    allowAnonymousUser = false,
    hideSaveToast = false,
    showLoader = true,
    trackingEventData = "Studio project saved",
    saveDesign
}: UseSaveParams) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const { identity, auth } = useIdentityContext();
    const { isItemInCart } = useCartContext();
    const locale = useAppSelector(state => state.locale);
    const isQuantityPageEnabled = useAppSelector(state => state.isQuantityPageEnabled);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const mpvId = useAppSelector(state => state.mpvId);

    return useCallback<Save>(
        async (workName?: string) => {
            const saveTiming = newRelicWrapper.newTimingEvent("saveButtonClickTiming");

            const initiateSaveStart = () => {
                saveTiming.start();
                if (showLoader) dispatch(showSaveLoader(t(messages.savingYourDesign.id)));
                dispatch(setSaveSuccess(null));
            };

            const endSave = async (saveStatus: boolean, work?: WorkEntity) => {
                if (isItemInCart) {
                    const isConnectedCard = studioSelectedProductOptions.Connection === "NFC";
                    try {
                        if (!work) {
                            throw Error("Work is not set");
                        }
                        await updateCart({
                            authToken: auth.getToken(),
                            locale,
                            work,
                            shopperId: identity.shopperId,
                            anonymousId: identity.anonymousUserId,
                            isQuantityPageEnabled,
                            isConnectedCard,
                            studioSelectedProductOptions,
                            mpvId
                        });
                    } catch (error) {
                        handleError(error, ERROR_CODES.ADD_TO_CART_ERROR);
                    }
                }
                if (showLoader) dispatch(hideLoader());
                dispatch(setSaveSuccess({ saveSuccess: saveStatus, hideSaveToast }));
                saveTiming.end();
                if (onSaveCallback) {
                    onSaveCallback();
                }
            };

            const handleSaveResult = ({
                saveStatus,
                work,
                error
            }: {
                saveStatus: boolean;
                work?: WorkEntity;
                error?: Error;
            }) => {
                // Ensure save spinner always displays for 1 second to avoid a flash during quick saves
                const currentDuration = saveTiming.status();
                if (currentDuration < MIN_SAVE_TIME) {
                    setTimeout(() => {
                        endSave(saveStatus, work);
                    }, MIN_SAVE_TIME - currentDuration);
                } else {
                    endSave(saveStatus, work);
                }
                if (error) {
                    handleSaveError(error);
                    handleError(error, ERROR_CODES.SAVE_DOCUMENT);
                }
            };

            const handleSaveError = (error: any) => {
                const errorString = error.errorCodeStack || error.message || error;
                if (typeof errorString === "string" && errorString.includes("39-401")) {
                    const authIdentity = auth.getIdentity();
                    newRelicWrapper.logPageAction("UDS-401", {
                        "auth/isSignedIn": authIdentity.isSignedIn,
                        "identity/isSignedIn": identity.isSignedIn
                    });
                    dispatch(setForceWorkRecovery(true));
                }
            };

            initiateSaveStart();
            try {
                const work = await saveDesign({
                    authToken: auth.getToken(),
                    identity,
                    newWorkName: workName,
                    savedProjectTrackingEventData: trackingEventData
                });

                if (!identity.isSignedIn) {
                    if (!allowAnonymousUser) {
                        window.history.replaceState(
                            "update-workId",
                            "Title",
                            addQueryParam(window.location.href, "saveToast", "true")
                        );
                        auth.signIn();
                    } else {
                        handleSaveResult({ saveStatus: true, work });
                    }
                } else {
                    handleSaveResult({ saveStatus: true, work });
                }

                return { success: true, work };
            } catch (error) {
                handleSaveResult({ saveStatus: false, error });

                return { success: false };
            }
        },
        [
            dispatch,
            isItemInCart,
            hideSaveToast,
            onSaveCallback,
            isQuantityPageEnabled,
            studioSelectedProductOptions,
            auth,
            locale,
            identity,
            allowAnonymousUser,
            t,
            showLoader,
            trackingEventData,
            saveDesign,
            mpvId
        ]
    );
};
