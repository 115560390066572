import { useEffect, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesigner } from "@designer-suite";
import { Errors, buildModalValidationErrorMessage } from "@shared/features/Validations";
import "./useValidationErrorsIcon.scss";

/**
 * Get Validations errors in active canvas from cimpress designer manager
 * @param activeCanvasId From designer property in window object, used as a dependenciy to force the useEffect rerun.
 * @returns
 */
export function useValidationErrors(activeCanvasId: string | undefined) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const [validationErrors, setValidationErrors] = useState<Errors[]>([]);

    useEffect(() => {
        function getValidationErrorMessage() {
            if (!designer) return;

            const currentvalidationErrors: ItemViewModel[] = [
                ...designer.validationManager.where({ severity: "error" })
            ].filter(item => {
                // @ts-ignore For some reason this data is a different type than a normal ItemViewModel
                const itemCanvasId = item.attributes.data?.canvasId;
                if (activeCanvasId) {
                    return itemCanvasId.includes(activeCanvasId);
                }

                // Fallback in case activeCanvasId is undefined
                const [activeCanvas] = designer.api.design.canvases.filter(canvas =>
                    canvas._canvasViewModel.get("active")
                );

                // Returns all error in case active canvas is undefined
                return activeCanvas ? itemCanvasId === activeCanvas.id : true;
            });
            const updatedErrorMessage: Errors[] = [];

            if (currentvalidationErrors.length) {
                updatedErrorMessage.push(buildModalValidationErrorMessage(t));
            }
            setValidationErrors(updatedErrorMessage);
        }

        getValidationErrorMessage();

        if (designer) {
            designer.validationManager.on("add", getValidationErrorMessage);
            designer.validationManager.on("change", getValidationErrorMessage);
            designer.validationManager.on("remove", getValidationErrorMessage);
        }
        return () => {
            if (designer) {
                designer.validationManager.off("add", getValidationErrorMessage);
                designer.validationManager.off("change", getValidationErrorMessage);
                designer.validationManager.off("remove", getValidationErrorMessage);
            }
        };
    }, [activeCanvasId, designer, t]);

    return validationErrors;
}
