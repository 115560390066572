import { useMemo } from "react";
import { keyboardShortcutEvents, keyboardShortcutsConfig } from "./keyboardShortcutEvents";
import { usePlatformName } from "./usePlatformName";

/**
 * creates a reverse map of keyboardshortcut config. takes in an event and
 * returns the associated keys based on client platform.
 */
export function useOSBasedHotKeys() {
    const { OS } = usePlatformName();

    return useMemo(() => {
        const map = {};
        for (const [key, value] of Object.entries(keyboardShortcutsConfig?.mac)) {
            map[value] = { mac: key };
        }
        for (const [key, value] of Object.entries(keyboardShortcutsConfig?.windows)) {
            map[value] = { ...map[value], windows: key };
        }
        return (event: keyboardShortcutEvents) => map[event][OS];
    }, [OS]);
}
