/* eslint-disable no-underscore-dangle */
import qs from "qs";
import { tryFetch } from "@shared/utils/Network";
import { removeQueryParam } from "@shared/utils/WebBrowser";
import { V2 } from "@shared/utils/DSS";
import { Store } from "@shared/redux";
import { buildTransientSceneUrl, convertSceneUrlToView, View } from "../purcsFallbackUtilities";

/* Purcs is acronym for Product Unified Rendering Context Service
 *  This service will serve scene-related assets based on selected inputs
 */
const purcsEntityCode = 18;

// we have to send some sort of instructions to purcs so that it can determine whether to add the
// ignoreProjection parameter.  just send a fake one that we can replace later.
// This is replaced in filterViews.  An example of usage is in PreviewsContext.
export const FAKE_INSTRUCTIONS = "fakeinstructions";
export const DESIGN_PREVIEW_WIDTH = 1360;

export async function getDefaultViewURL(previewInstructionsUri: string, purpose: string, width: number) {
    const currentState = Store.getState();
    const requestParams = {
        purpose: encodeURIComponent(purpose),
        width: encodeURIComponent(width),
        previewInstructionsUri: `${previewInstructionsUri}&surfaceOrdinals=1`,
        optionSelections: JSON.stringify(currentState.studioSelectedProductOptions),
        productVersion: currentState.productVersion,
        requester: REQUESTOR
    };

    const queryString = qs.stringify(requestParams);
    const url = `${PURCS_URL}/${encodeURIComponent(SCENE_SERVICE_TENANT_ID)}/${encodeURIComponent(
        currentState.locale
    )}/product/${encodeURIComponent(currentState.productKey)}/defaultViewImage?${queryString}`;
    return url;
}

type GetAllViewsParams = {
    purpose: string;
    locale: string;
    width?: number;
    previewInstructionsUri?: string;
    studioSelectedProductOptions?: Record<string, string>;
    product?: { key: string; version?: number };
};

// TODO: (DX-426) figure out a better way to provide a default width
export async function getAllViews({
    purpose,
    locale,
    width = 1360,
    previewInstructionsUri,
    studioSelectedProductOptions,
    product
}: GetAllViewsParams) {
    const currentState = Store.getState();
    const requestParams = {
        purpose: encodeURIComponent(purpose),
        width: previewInstructionsUri && encodeURIComponent(width),
        previewInstructionsUri,
        optionSelections: JSON.stringify(studioSelectedProductOptions || currentState.studioSelectedProductOptions),
        productVersion: product ? product.version : currentState.productVersion
    };
    const productKey = product ? product.key : currentState.productKey;

    const queryString = qs.stringify(requestParams);
    const url = `${PURCS_URL}/${encodeURIComponent(SCENE_SERVICE_TENANT_ID)}/${encodeURIComponent(
        currentState.locale
    )}/product/${encodeURIComponent(productKey)}/allViews?${queryString}`;

    try {
        const result = await tryFetch({
            url,
            options: {
                headers: {
                    From: REQUESTOR
                }
            },
            moduleFunction: "purcsClient:getAllViews",
            friendlyDescription: "retrieve scenes",
            entityCode: purcsEntityCode,
            retryCount: 3
        });
        return result;
    } catch {
        const { productVersion } = currentState;
        if (productVersion === null) {
            throw Error("Product version is not defined");
        }
        const product = await V2.getDesignViewsForProduct(
            currentState.productKey,
            productVersion,
            currentState.studioSelectedProductOptions,
            locale
        );
        const result = [];

        for (let i = 0; i < product.designViews.length; i++) {
            const view = product.designViews[i];
            // eslint-disable-next-line no-await-in-loop
            const transientSceneUrl = await buildTransientSceneUrl(i, view.heightCm, view.widthCm, width);
            result.push(
                convertSceneUrlToView({
                    renderingOptions: {
                        previewInstructionsUri,
                        width
                    },
                    sceneType: "NoScene",
                    transientSceneUrl,
                    name: view.name
                })
            );
        }

        return { views: result };
    }
}

function buildSrcSet(baseUrl: string, width: number) {
    return [1, 2, 3].map(dpr => `${baseUrl}&width=${dpr * width} ${dpr}x`).join(", ");
}

export function filterViews(views: View[], previewInstructionsUri: string, width = 600) {
    return views.map(view => {
        let builtUrl = removeQueryParam(view._links.image.href, "width");

        // see example in PreviewsContext.js
        if (builtUrl.includes(FAKE_INSTRUCTIONS)) {
            builtUrl = builtUrl.replace(FAKE_INSTRUCTIONS, encodeURIComponent(previewInstructionsUri));
        } else if (!builtUrl.includes("instructions_uri")) {
            builtUrl = `${builtUrl}&instructions_uri=${encodeURIComponent(previewInstructionsUri)}`;
        }
        const urlWithWidth = `${builtUrl}&width=${width}`;

        return {
            title: view._links.image.title || view.name,
            name: view.name,
            src: urlWithWidth,
            srcSet: buildSrcSet(builtUrl, width)
        };
    });
}
