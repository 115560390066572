import React from "react";
import {
    Typography,
    Icon,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    FluidImage,
    Carousel,
    CarouselSlide
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    StudioPanelDesignRequirements,
    useDesignRequirementsContext,
    useNeedAddButtonContext
} from "@shared/features/Product";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { GetDocument } from "@shared/utils/CimDoc";
import { useCanvasSelectorPreviews } from "./hooks/useCanvasSelectorPreviews";
import { canvasSelectorMessages } from "./canvasSelectorMessages";
import { CanvasTileButton } from "./CanvasTileButton";
import { useOnAddClick, useOnCanvasClick } from "../DesignPanel";
import * as styles from "./CanvasSelectorPanel.module.scss";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    activeCanvasName: string;
    getDocument: GetDocument;
    triggerCanvasChange: TriggerCanvasChange;
};

export function CanvasSelectorPanel(props: Props) {
    const { isOpen, onClose, activeCanvasName, getDocument, triggerCanvasChange } = props;
    const canvasSelectorPreviewsData = useCanvasSelectorPreviews();
    const needAddButton = useNeedAddButtonContext();
    const onAddClick = useOnAddClick();
    const onCanvasClick = useOnCanvasClick({ activeCanvasName, getDocument, triggerCanvasChange });
    const { isSmall } = useStudioLayout();
    const designRequirements = useDesignRequirementsContext();

    const { t } = useTranslationSSR();

    if (!canvasSelectorPreviewsData || !designRequirements) {
        return null;
    }

    const { canvasSelectorUrls } = canvasSelectorPreviewsData;

    const slides = designRequirements.panels.map((panel: StudioPanelDesignRequirements, panelIndex: number) => {
        if (!canvasSelectorUrls[panelIndex]) {
            return null;
        }
        const canvasData = canvasSelectorUrls[panelIndex];
        const { name, title, src } = canvasData;

        const heightRatio = (panel.height / panel.width) * 100;

        return (
            <CarouselSlide key={`carousel-slide-${panelIndex}`}>
                {needAddButton(panel, name) ? (
                    <CanvasTileButton
                        isActive={panel.name === activeCanvasName}
                        className={styles.addButton}
                        ariaLabel={title}
                        onClick={() => onAddClick(canvasData, onClose)}
                    >
                        <div className={styles.addIcon} style={{ paddingBottom: `${heightRatio}%` }}>
                            <Icon skin="standard" iconType="plus" />
                        </div>
                        <Typography
                            fontWeight="bold"
                            fontSize="-1"
                            textAlign="center"
                            className={styles.tileButtonLabel}
                        >
                            {t(canvasSelectorMessages.sideselectorAdd.id, { sideName: title })}
                        </Typography>
                    </CanvasTileButton>
                ) : (
                    <CanvasTileButton
                        isActive={panel.name === activeCanvasName}
                        ariaLabel={title}
                        onClick={() => onCanvasClick(canvasData, onClose)}
                        className={styles.canvasButton}
                    >
                        <FluidImage
                            className={styles.canvasImage}
                            alt={t(canvasSelectorMessages.designPanelPreview.id)}
                            src={src}
                        />
                        <Typography
                            fontWeight="bold"
                            fontSize="-1"
                            textAlign="center"
                            className={styles.tileButtonLabel}
                        >
                            {title}
                        </Typography>
                    </CanvasTileButton>
                )}
            </CarouselSlide>
        );
    });

    return (
        <LegacyModalDialog
            className="canvas-selector-panel"
            isOpen={isOpen}
            variant="panel-bottom"
            onRequestDismiss={onClose}
        >
            <LegacyModalDialogContent
                className="modal-dialog-content mobile-panel-content canvas-selector-panel-contents"
                padding={4}
                data-dcl-prevent-canvas-items-deselection
                aria-label={t(canvasSelectorMessages.canvasSelectorPanelAriaLabel.id)}
                fullBleed
            >
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(canvasSelectorMessages.canvasSelectionClose.id)} />
                <LegacyModalDialogHeader className="canvas-selector-panel-header" marginBottom={0}>
                    <LegacyModalDialogTitle marginBottom={1}>
                        {t(canvasSelectorMessages.designSideSelectionTitle.id)}
                    </LegacyModalDialogTitle>
                </LegacyModalDialogHeader>
                <LegacyModalDialogBody padding={4}>
                    <Carousel
                        gridGutters
                        infinite={false}
                        progressIndicator={"dots"}
                        slidesToShow={isSmall ? 2 : 4}
                        arrows={false}
                        // TODO-atom what text to use?
                        accessibleTextForPrevious={""}
                        accessibleTextForNext={""}
                        accessibleTextForDots={[]}
                    >
                        {slides}
                    </Carousel>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
CanvasSelectorPanel.displayName = "CanvasSelectorPanel";
