import { useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useDesigner } from "@designer-suite";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { setSelectedAITemplateReportingData, useAppDispatch, useAppSelector } from "@shared/redux";
import { ImagemindReportingInput } from "@shared/redux/slices/selectedAITemplateReportingData";
import { reportAITemplateUsage } from "@five/clients/imagemindClient";

/*
    This hook tracks when the user deletes the image part of an AI template that currently exists 
    on the canvas and then sends a feedback call with the corresponding data.
*/
export function useTrackAITemplateDeleted() {
    const { auth } = useIdentityContext();
    const authToken = auth?.getToken();
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const selectedAITemplateReportingData = useAppSelector(state => state.selectedAITemplateReportingData);

    useEffect(() => {
        if (!designer) return;

        const handleAITemplateDeleted = (event: EventData) => {
            if (
                event.items.some(item => {
                    return (
                        item.itemType === "IMAGE" &&
                        item._itemViewModel.model.attributes.studioMetadata?.analysis?.createdBy === "AIR Team"
                    );
                })
            ) {
                if (
                    selectedAITemplateReportingData &&
                    selectedAITemplateReportingData?.input?.feedback?.userActivity?.assetsClicked?.length > 0
                ) {
                    const removedAITemplateId =
                        selectedAITemplateReportingData.input.feedback.userActivity.assetsClicked[0];
                    const removedAITemplateReportingData: ImagemindReportingInput = cloneDeep(
                        selectedAITemplateReportingData
                    );
                    removedAITemplateReportingData.input.feedback.userActivity.assetsRemoved = [removedAITemplateId];
                    removedAITemplateReportingData.input.feedback.userActivity.assetsClicked = [];
                    removedAITemplateReportingData.input.feedback.userActivity.numberOfAssetsViewed = 0;
                    reportAITemplateUsage(removedAITemplateReportingData, authToken);
                    dispatch(setSelectedAITemplateReportingData({} as ImagemindReportingInput));
                }
            }
        };

        const unsubscribeDelete = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_DELETED,
            handleAITemplateDeleted
        );

        /* eslint-disable consistent-return */
        return () => {
            unsubscribeDelete();
        };
    }, [authToken, designer, dispatch, selectedAITemplateReportingData]);
}
