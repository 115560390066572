import React, { useRef, RefObject, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDesigner } from "@easel";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { TextList, useDocumentItems } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { PanelContent, PanelDescription, PanelTitle, StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import { shouldOpenPanel, useActiveDialog } from "@shared/features/ActiveDialog";
import { useClassifyPanel } from "../../../hooks/useClassifyPanel";
import { TextButtons } from "./TextButtons";
import "./textPanel.scss";

const messages = defineMessages({
    textInstructions: {
        id: "studio.components.panels.textpanel.instructions",
        defaultMessage: "Edit your text below, or click on the field you'd like to edit directly on your design."
    },
    title: {
        id: "studio.components.panels.textpanel.title",
        defaultMessage: "Text"
    }
});

interface TextPanelProps extends React.HTMLAttributes<HTMLButtonElement> {
    showDeleteButtons?: boolean;
    onDone?: () => void;
    className?: string;
    textButtonRef?: RefObject<HTMLButtonElement>;
}
export function TextPanel({ showDeleteButtons = true, textButtonRef, onDone, ...textButtonProps }: TextPanelProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const textPanelRef = useRef<HTMLElement>(null);
    const textPanelContentRef = useRef<HTMLDivElement>(null);
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { isSmall } = useStudioLayout();
    const { classifyPanel } = useClassifyPanel();
    const items = useDocumentItems(true);
    const numberOfTextItems = items.filter(
        item => item.itemType === ItemTypes.TEXT || item.itemType === ItemTypes.ITEM_REFERENCE
    ).length;

    // open sidebar panel on selecting elements on the designer.
    useEffect(() => {
        if (!designer) {
            return;
        }

        const openPanel = (selectedItem: any) => {
            if (selectedItem.models.length > 0) {
                const selectedContentType = classifyPanel(selectedItem.models[0]);

                if (shouldOpenPanel(currentActiveDialog, selectedContentType, isSmall)) {
                    setCurrentActiveDialog(selectedContentType);
                    textButtonRef?.current?.focus();
                }
            }
        };

        designer.selectionManager.on("select", openPanel);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.selectionManager.off("select", openPanel);
        };
    }, [designer, setCurrentActiveDialog, currentActiveDialog, textButtonRef, isSmall, classifyPanel]);

    const numberOfPlaceholders = 3;

    return (
        <>
            <PanelContent ref={textPanelRef} className="studio-text-panel-content">
                <div ref={textPanelContentRef}>
                    <PanelTitle data-testid="textPanelTitle">{t(messages.title.id)}</PanelTitle>
                    {(!isSmall || numberOfTextItems === 0) && (
                        <PanelDescription>{t(messages.textInstructions.id)}</PanelDescription>
                    )}
                    <StudioIsInteractiveSwitch>
                        <div className="studio-text-panel-list">
                            <TextList showDeleteButtons={showDeleteButtons} />
                        </div>
                        <>
                            {[...Array(numberOfPlaceholders)].map((_n, i) => (
                                <div key={`text-skeleton${i}`} className="easel-components-item-row-editor-container">
                                    <Skeleton width={"100%"} height={50} />
                                </div>
                            ))}
                        </>
                    </StudioIsInteractiveSwitch>
                </div>
            </PanelContent>
            <TextButtons
                textPanelRef={textPanelRef}
                textPanelContentRef={textPanelContentRef}
                numberOfTextItems={numberOfTextItems}
                onDone={onDone}
                {...textButtonProps}
            />
        </>
    );
}

TextPanel.displayName = "TextPanel";
