import React, { useState } from "react";
import classNames from "classnames";
import { Button, MinimizeIcon, MaximizeIcon } from "@shared/features/StudioChrome";

import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";

import "./numberIncrementer.scss";

const messages = defineMessages({
    minimizeValueLabel: {
        id: "easel.components.numberIncreamenter.minimizeValueLabel",
        defaultMessage: "Minimize number",
        description: {
            note: "Label for a minimize number button"
        }
    },
    maximizeValueLabel: {
        id: "easel.components.numberIncreamenter.maximizeValueLabel",
        defaultMessage: "Maximize number",
        description: {
            note: "Label for a maximize number button"
        }
    }
});

enum operationType {
    minimize,
    maximize
}
interface Props {
    /**
     * Counter start number.
     * @default 1
     * */
    startNumber?: number;
    handleCounter: (counterValue: number) => void;
    ariaLabel: string;
}

export const NumberIncrementer = ({ handleCounter, startNumber = 1, ariaLabel }: Props) => {
    const [counterValue, setCounterValue] = useState(startNumber);
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();

    const handleBtnClick = (operation: operationType) => {
        if (operation === operationType.minimize) {
            if (counterValue > 1) {
                setCounterValue(prevCounterValue => prevCounterValue - 1);
                handleCounter(counterValue - 1);
            }
        } else {
            setCounterValue(prevCounterValue => prevCounterValue + 1);
            handleCounter(counterValue + 1);
        }
    };

    return (
        <div className="number-incrementer" role="group" aria-label={ariaLabel}>
            <Button
                className={classNames("decrement-btn", {
                    "incrementer-btn-disabled": !isSmall && counterValue === 1
                })}
                onClick={() => {
                    handleBtnClick(operationType.minimize);
                }}
                aria-label={t(messages.minimizeValueLabel.id)}
            >
                <MinimizeIcon />
            </Button>
            <span>{counterValue}</span>
            <Button
                className="increment-btn"
                onClick={() => {
                    handleBtnClick(operationType.maximize);
                }}
                aria-label={t(messages.maximizeValueLabel.id)}
            >
                <MaximizeIcon />
            </Button>
        </div>
    );
};

NumberIncrementer.displayName = "NumberIncrementer";
