import { useSwitchProducts } from "@five/components/contexts/SwitchProductsContext";
import { LoadNewDesignForSubstrateChange } from "@shared/features/ChangeTemplate";
import { hideLoader, showLoader, useAppDispatch, useAppSelector } from "@shared/redux";
import { getDesignDocumentFromDesigner } from "@utilities";
import { useCallback } from "react";

export function useLoadNewDesignForSubstrateChange() {
    const dispatch = useAppDispatch();
    const { switchProduct } = useSwitchProducts();
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const locale = useAppSelector(state => state.locale);
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const quantity = useAppSelector(state => state.quantity);

    return useCallback<LoadNewDesignForSubstrateChange>(
        async props => {
            const { substrateColorOptionName, newSubstrateColor } = props;
            dispatch(showLoader());
            await switchProduct({
                productKey,
                productVersion: productVersion!,
                locale,
                quantity,
                customerSelectedProductOptions: {
                    ...customerSelectedProductOptions,
                    [substrateColorOptionName]: newSubstrateColor
                },
                targetDocument: getDesignDocumentFromDesigner(false)
            });
            dispatch(hideLoader());
        },
        [customerSelectedProductOptions, dispatch, locale, productKey, productVersion, quantity, switchProduct]
    );
}
