import React, { HTMLProps } from "react";
import classNames from "classnames";
import { SubselectionType, useTableSubselection } from "../../TableSubselectionProvider";

import * as styles from "./OffsetBarOverlayItem.module.scss";

interface OffsetBarOverlayItemProps extends HTMLProps<HTMLDivElement> {
    index: number;
    type: SubselectionType;
}

export function OffsetBarOverlayItem({ index, type, ...rest }: OffsetBarOverlayItemProps) {
    const { selection } = useTableSubselection();

    let selectedIndex;

    if (type === SubselectionType.Column) {
        selectedIndex = selection.columnIndex;
    } else if (type === SubselectionType.Row) {
        selectedIndex = selection.rowIndex;
    }

    const isSelected = type === selection.type && index === selectedIndex;

    return (
        <div
            {...rest}
            className={classNames(
                `${type}-overlay-items-item`,
                {
                    [`${type}-overlay-items-item-selected`]: isSelected
                },
                {
                    [styles.rowOverlayItem]: type === SubselectionType.Row,
                    [styles.columnOverlayItem]: type === SubselectionType.Column,
                    [styles.overlayItemSelected]: isSelected
                }
            )}
            key={index}
        ></div>
    );
}

OffsetBarOverlayItem.displayName = "OffsetBarOverlayItem";
