import { retry } from "@shared/utils/Network";
import { LiveSiteServiceClient } from "@vp/live-site-service-client";

interface LiveSiteResults {
    tenant: {
        [key: string]: {
            sites: {
                [key: string]: {
                    businessOwner: string;
                    canonicalDomain: string;
                    country: string;
                    primaryLanguage: string;
                    supportedLanguages: string[];
                };
            };
        };
    };
}

export async function getDomainForLocale(locale: string) {
    const lowerCasedLocale = locale.toLowerCase();
    const liveSites = (await retry(() => new LiveSiteServiceClient().getLiveSites(), {
        name: "LiveSiteServiceClient:getLiveSites"
    })) as LiveSiteResults;
    const sites = liveSites?.tenant?.vistaprint?.sites;
    if (sites) {
        return Object.values(sites).find(site =>
            site.supportedLanguages?.map(lang => lang.toLowerCase()).includes(lowerCasedLocale)
        )?.canonicalDomain;
    }
    return undefined;
}
