import React, { HTMLProps, Ref, forwardRef } from "react";
import classnames from "classnames";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./LeftSidebar.module.scss";

interface SidebarButtonContainerProps extends HTMLProps<HTMLDivElement> {
    /**
     * Decides whether the buttons should be in a visible state
     */
    visible: boolean;
}

export const SidebarButtonContainer = forwardRef(
    ({ children, className, visible, ...rest }: SidebarButtonContainerProps, ref: Ref<HTMLDivElement>) => {
        return (
            <div
                {...rest}
                className={classnames(styles.sidebarButtons, { [styles.sidebarButtonsShown]: visible }, className)}
                role="tablist"
                data-testid="sidebar-tablist"
                ref={ref}
            >
                {children}
            </div>
        );
    }
);

SidebarButtonContainer.displayName = "SidebarButtonContainer";
