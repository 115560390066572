import React, { useState } from "react";
import { Box, Icon } from "@vp/swan";
import classNames from "classnames";
import * as styles from "./ContextualTooltip.module.scss";

interface Props {
    tooltipContent: string;
}

export function ContextualTooltip({ tooltipContent }: Props) {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [skipFirstTap, setSkipFirstTap] = useState(true);

    const handleButtonClick = () => {
        if (!skipFirstTap) {
            setTooltipVisible(prev => !prev);
        }
    };

    const onMouseEnter = () => {
        setTooltipVisible(true);
        // skip the first click on tablet mode
        if (skipFirstTap) {
            setTimeout(() => {
                setSkipFirstTap(false);
            }, 10);
        }
    };

    const onMouseLeave = () => {
        setTooltipVisible(false);
    };

    return (
        <>
            <Box
                data-dcl-prevent-canvas-items-deselection
                className={classNames("contextual-tooltip-container", styles.contextualTooltipContainer)}
            >
                <button
                    className={classNames("contextual-tooltip-button", styles.contextualTooltipButton)}
                    onClick={handleButtonClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <Icon iconType="info" skin="brand-blue" size="32p" />
                </button>
                {isTooltipVisible && (
                    <Box className={classNames("contextual-tooltip", styles.contextualTooltip)} role="alert">
                        {tooltipContent}
                    </Box>
                )}
            </Box>
        </>
    );
}

ContextualTooltip.displayName = "ContextualTooltip";
