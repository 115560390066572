import measurement from "measurement";
import type { DSS, MCP } from "@vp/types-ddif";
import { newRelicWrapper } from "@shared/utils/Errors";
import { generateDocumentMetadata } from "@shared/utils/Tracking";
import { V2 } from "@shared/utils/DSS";
import { DesignSpecification } from "@shared/utils/Calcifer";
import type { StudioConfiguration } from "@shared/utils/StudioConfiguration";
import { Store, setAlerts } from "@shared/redux";
import type { Panel } from "@design-stack-ct/cdif-types";
import { upgradeCimDoc } from "@design-stack-ct/cimdoc-state-manager";
import { hydratePlaceholdersIntoCimdoc } from "@design-stack-vista/studio-document-metadata-management";
import cloneDeep from "lodash/cloneDeep";
import { importItemReferenceArrows } from "./shapeImportUtilities";
import {
    fixFillColorUndefined,
    fixTableUndefinedColor,
    updateCimdocForRaisedFoilColor,
    replaceChooseForMeInstructions,
    fixBackgroundFromTemplate,
    updateCimDocMinMaxFontSizes,
    updateCimDocImagesCrop
} from "./cimdocEditingClient";
import { fixDocumentSources } from "./fixDocumentSources";

const mf = new measurement.Factory();

function getViewDimensionsInCm(view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface) {
    const width = view.widthCm;
    const height = view.heightCm;

    return {
        width,
        height
    };
}

/**
 * Return an object with the difference in cm of a panel's and view's width and height
 */
function getPanelAndViewDimensionDifferenceInCm(
    panel: Panel,
    view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface
) {
    const panelWidthCm = mf.measurement(panel.width).getValue("cm");
    const panelHeightCm = mf.measurement(panel.height).getValue("cm");
    const viewDimensions = getViewDimensionsInCm(view);

    return {
        width: Math.abs(panelWidthCm - viewDimensions.width),
        height: Math.abs(panelHeightCm - viewDimensions.height)
    };
}

/**
 * Determine whether or not a panel's width and height are within a specified
 * percentage of the size of a corresponding view's dimensions.
 */
function panelDimensionsWithinPercentage(
    panel: Panel,
    view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface,
    percentage: number
) {
    const differences = getPanelAndViewDimensionDifferenceInCm(panel, view);
    const viewDimensions = getViewDimensionsInCm(view);

    return (
        differences.width <= (percentage / 100) * viewDimensions.width &&
        differences.height <= (percentage / 100) * viewDimensions.height
    );
}

/**
 * Determine whether or not a panel's dimensions match the dimensions of a design view.
 */
function panelAndViewDimensionsMatch(panel: Panel, view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface) {
    const differences = getPanelAndViewDimensionDifferenceInCm(panel, view);

    // don't care if they are .00000004 different because of float nonsense
    const widthIsClose = differences.width < 0.001;
    const heightIsClose = differences.height < 0.001;

    return widthIsClose && heightIsClose;
}

/**
 * Determine whether a document should be adjusted. This function returns true if
 * both of the following conditions are met:
 *  1) One or more document panels has different dimensions from those of its corresponding design view.
 *  2) The dimensions of any mismatched panels differ by no more than the specified percentage relative
 *     to the corresponding design views.
 *  3) Document has different number of panels than the surface
 */
export function shouldAdjustDocument(
    designDocument: DSS.DesignDocument,
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[]
) {
    if (!designDocument.document || !designDocument.document.panels) {
        throw Error("shouldAdjustDocument: design document does not contain document panels");
    }

    if (designDocument.document.panels.length !== views.length) {
        return true;
    }

    const documentPanels = designDocument.document.panels;
    let documentHasDimensionMismatch = false;

    documentPanels.forEach((panel, index) => {
        if (!panelAndViewDimensionsMatch(panel, views[index])) {
            documentHasDimensionMismatch = true;
        }
    });

    return documentHasDimensionMismatch;
}

/**
 * Determines whether the document panels and surfaces have a dimension mismatch greater than DSS can handle
 */
export function documentDimensionsFailure(
    designDocument: DSS.DesignDocument,
    maxPanelAdjustmentPercentage: number,
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[]
) {
    if (!designDocument.document || !designDocument.document.panels) {
        throw Error("documentDimensionsFailure: design document does not contain document panels");
    }

    const documentPanels = designDocument.document.panels;
    let documentHasDimensionMismatch = false;

    documentPanels.forEach((panel, index) => {
        if (!panelAndViewDimensionsMatch(panel, views[index])) {
            documentHasDimensionMismatch = true;
        }
    });

    return (
        documentHasDimensionMismatch &&
        documentPanels.some(
            (panel, index) => !panelDimensionsWithinPercentage(panel, views[index], maxPanelAdjustmentPercentage)
        )
    );
}

/**
 * Determines whether the document panels should to be updated to match the surfaces
 */
function shouldUpdatePanels(panels: Panel[], views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[]) {
    return panels.some((panel, index) => {
        const view = views[index];
        if (view) {
            return panel.id !== view.id || panel.name !== view.name || !panel.colorMode;
        }
        return false;
    });
}

/**
 * Updates document panels to match the surface ids and names
 */
export function updatePanels(
    cimDoc: DSS.DesignDocument,
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[],
    designDocument: DSS.DesignDocument
) {
    const updatedCimDoc = cimDoc;

    const viewNames = views.map(s => s.name?.toLowerCase());
    const panelNames = cimDoc.document.panels.map(p => p.name?.toLowerCase());
    const nameMatches = viewNames.map(viewName => panelNames.indexOf(viewName)).filter(value => value >= 0);

    if (nameMatches.length === views.length) {
        // names match, just out of order (happen with folded products sometimes, you get front/back/inside)
        const newPanels = viewNames.map(viewName => {
            const view = views.find(view => view.name.toLowerCase() === viewName)!;
            const panel = cimDoc.document.panels.find(panel => panel.name?.toLowerCase() === viewName)!;
            const designDocumentColorMode = designDocument.document.panels.find(p => p.name === view.name)!.colorMode;
            // need to update document source, though they don't need to be in order
            const panelSource = updatedCimDoc.metadata?.documentSources?.panels.find(
                panelSource => panelSource.id === panel.id
            );
            if (panelSource) {
                panelSource.id = view.id;
            }
            return { ...panel, id: view.id, name: view.name, colorMode: designDocumentColorMode };
        });
        updatedCimDoc.document.panels = newPanels;
    } else {
        // just try to fix via index comparison
        updatedCimDoc.document.panels = cimDoc.document.panels.map((panel, index) => {
            const view = views[index];
            const designDocumentColorMode = designDocument.document.panels[index].colorMode;
            return view ? { ...panel, id: view.id, name: view.name, colorMode: designDocumentColorMode } : panel;
        });
        if (updatedCimDoc.metadata?.documentSources?.panels) {
            updatedCimDoc.metadata.documentSources.panels = cimDoc.metadata!.documentSources!.panels.map(
                (panel, index) => {
                    return { ...panel, id: views[index].id };
                }
            );
        }
    }
    return updatedCimDoc;
}

export async function prepareStudioCimDoc(
    cimDoc: DSS.DesignDocument,
    designSpecification: DesignSpecification,
    productKey: string,
    productVersion: number,
    studioSelectedProductOptions: Record<string, string>,
    locale: string,
    useDraggablePlaceholders: boolean,
    studioConfiguration?: StudioConfiguration
) {
    let updatedCimDoc = cloneDeep(cimDoc);
    if (shouldUpdatePanels(cimDoc.document.panels, designSpecification.views)) {
        updatedCimDoc = updatePanels(cimDoc, designSpecification.views, designSpecification.designDocument);
    }

    if (cimDoc.version === undefined) {
        updatedCimDoc = await V2.upgradeV1DesignDocument(updatedCimDoc);
    }

    // Check whether any of the document's panel dimensions vary from the corresponding design
    // view, and adjust the dimensions if needed (up to the max adjustment percentage).
    const maxPanelAdjustmentPercentage = MAX_PANEL_ADJUSTMENT_PERCENTAGE;

    if (updatedCimDoc && shouldAdjustDocument(updatedCimDoc, designSpecification.views)) {
        const tolerancePercent = 1000000;

        updatedCimDoc = await V2.updateDocumentDimensions(
            productKey,
            productVersion!,
            studioSelectedProductOptions,
            updatedCimDoc,
            tolerancePercent,
            locale
        );

        const showAlert = cimDoc.document.panels.some(
            (panel, index) =>
                !panelDimensionsWithinPercentage(panel, designSpecification.views[index], maxPanelAdjustmentPercentage)
        );

        if (showAlert) {
            // Figure out what has changed
            let changes = "";
            cimDoc.document.panels.forEach((panel, index) => {
                const view = designSpecification.views[index];
                const viewDimensions = getViewDimensionsInCm(view);
                const difference = getPanelAndViewDimensionDifferenceInCm(panel, view);

                if (difference.width > (maxPanelAdjustmentPercentage / 100) * viewDimensions.width) {
                    changes += `<li>${panel.name} panel width adjusted from
                        ${
                            Math.round((mf.measurement(panel.width).getValue("cm") + Number.EPSILON) * 1000) / 1000
                        } cm to ${viewDimensions.width} cm.</li>`;
                }
                if (difference.height > (maxPanelAdjustmentPercentage / 100) * viewDimensions.height) {
                    changes += `<li>${panel.name} panel height adjusted from
                        ${
                            Math.round((mf.measurement(panel.height).getValue("cm") + Number.EPSILON) * 1000) / 1000
                        } cm to ${viewDimensions.height} cm.</li>`;
                }
            });

            // Only show CARE agents what has changed if it is larger than the default acceptable threshold
            Store.dispatch(
                setAlerts({
                    alerts: [
                        {
                            key: "studio.components.Toast.significantAdjustedDimensions",
                            includeCareLink: true,
                            careOnlyMessage: changes
                        }
                    ]
                })
            );
        }
    }

    if (cimDoc && updatedCimDoc && cimDoc.projectionId !== updatedCimDoc.projectionId) {
        newRelicWrapper.logPageAction("studio-projection-updated", {
            oldProjectionId: cimDoc.projectionId || "",
            newProjectionId: updatedCimDoc.projectionId || ""
        });
    }

    importItemReferenceArrows(updatedCimDoc);

    updateCimdocForRaisedFoilColor(updatedCimDoc);

    fixTableUndefinedColor(updatedCimDoc);

    fixFillColorUndefined(updatedCimDoc);

    generateDocumentMetadata(updatedCimDoc);

    replaceChooseForMeInstructions(updatedCimDoc);

    fixDocumentSources(updatedCimDoc);

    hydratePlaceholdersIntoCimdoc(updatedCimDoc, useDraggablePlaceholders);

    // hydrate above uses immer which freezes the cimdoc
    updatedCimDoc = cloneDeep(updatedCimDoc);

    fixBackgroundFromTemplate(updatedCimDoc);

    const upgradedCimdoc = cloneDeep(upgradeCimDoc(updatedCimDoc));

    updateCimDocMinMaxFontSizes(upgradedCimdoc, studioConfiguration?.minFontSize);

    updateCimDocImagesCrop(upgradedCimdoc);

    return upgradedCimdoc;
}
