import { tableFilter } from "@utilities";
import { SubselectionType, useTableSubselection } from "@shared/features/Tables";
import type { ItemSelection } from "../@types/designer";

// This hook will filter the selection to one table if the table is the only thing selected
// it will also filter out the table if the table itself is not the subselection
export const useTableSelectionFilter = (
    selection: ItemSelection,
    subselectionType: SubselectionType = SubselectionType.None
) => {
    // Filter on the items in the selection to two differ lists.
    const { tableItems, otherItems } = tableFilter(selection);

    const {
        selection: { type: tableSubselectionType }
    } = useTableSubselection();

    // If there is more than one table
    // OR there are non table items selected
    // OR the subselection of table is anything other than the table itself, return nothing is selected
    if (!tableItems.length || otherItems.length || tableSubselectionType !== subselectionType) {
        return { tableItems: [], otherItems: [] };
    }

    return { tableItems, otherItems };
};
