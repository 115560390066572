import React from "react";
import { withPrefix } from "gatsby";
import { Box, Divider, FlexBox, Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { KnockoutButton, SharpenTool, useDesigner, useSelection } from "@designer-suite";
import * as styles from "./EditImageTools.module.scss";

const messages = defineMessages({
    specialTools: {
        id: "studio.panel.editImage.specialTools",
        defaultMessage: "Special tools",
        description: {
            note: "Label for a section of image tools that allow background removal and sharpening"
        }
    }
});

export function EditImageTools() {
    const { t } = useTranslationSSR();
    const selection = useSelection("change:isBackgroundRemoveCandidate");
    const designer = useDesigner();
    const item = selection[0] as ImageItem;

    if (!designer || !item) {
        return null;
    }

    return (
        <Box marginTop={3} marginBottom={6}>
            <Typography fontSize={1} fontWeight="bold" marginBottom={4}>
                {t(messages.specialTools.id)}
            </Typography>
            <FlexBox>
                <img
                    className={styles.editImageToolsImage}
                    src={ASSET_PREFIX + withPrefix(`/editImagePanel/remove-bg.png`)}
                    alt=""
                />
                <KnockoutButton useToggle className={styles.editImageToolsToggle} />
            </FlexBox>
            <Divider marginY={3} />
            <FlexBox>
                <img
                    className={styles.editImageToolsImage}
                    src={ASSET_PREFIX + withPrefix(`/editImagePanel/enhance.png`)}
                    alt=""
                />
                <SharpenTool alwaysShow useToggle className={styles.editImageToolsToggle} />
            </FlexBox>
        </Box>
    );
}
EditImageTools.displayName = "EditImageTools";
