import qs from "qs";
import type { DSS } from "@vp/types-ddif";
import { tryFetch } from "@shared/utils/Network";
import { getStudioUniqueSessionId } from "@shared/utils/Tracking";
import { Store } from "@shared/redux";

const host = CUSTOMIZATION_TRANSFER_URL;
const entityCode = 103;

// eslint-disable-next-line import/prefer-default-export
export async function getTransferedDocument(
    sourceDocument: string,
    targetDocument: string
): Promise<DSS.DesignDocument> {
    return tryFetch({
        url: `${host}api/v1/TransferCustomizations`,
        options: {
            method: "POST",
            body: JSON.stringify({
                sourceDocumentUrl: sourceDocument,
                targetDocumentUrl: targetDocument,
                requester: REQUESTOR
            }),
            headers: {
                "Content-Type": "application/json",
                "x-caller-id": REQUESTOR,
                "x-correlation-id": getStudioUniqueSessionId()
            }
        },
        moduleFunction: "customizationTransferClient:getTransferedDocument",
        friendlyDescription: "transfer customizations from one document to another",
        entityCode
    });
}

export async function getStyleTransferredDocument(
    sourceTemplateToken: string,
    targetDocumentUrl: string
): Promise<DSS.DesignDocument> {
    const templateRealizationHost = "https://prod.templaterealization.content.cimpress.io/api/v2";
    const { locale } = Store.getState();
    const requestParams = {
        sourceDocumentUrl: `${templateRealizationHost}/templates/${sourceTemplateToken}/cultures/${locale}/editDocument`,
        targetDocumentUrl,
        requester: REQUESTOR
    };
    const queryString = qs.stringify(requestParams);
    const path = `TransferStyle?${queryString}`;

    return tryFetch({
        url: `${host}api/v1/${path}`,
        options: {
            method: "GET"
        },
        moduleFunction: "customizationTransferClient:getStyleTransferredDocument",
        friendlyDescription: "transfer style from one document to another",
        entityCode
    });
}

export async function getResizedDocumentbyDesignViews(
    documentUrl: string,
    designViewsUrl: string
): Promise<DSS.DesignDocument> {
    return tryFetch({
        url: `${host}api/v1/Vista/Resize`,
        options: {
            method: "POST",
            body: JSON.stringify({
                requester: REQUESTOR,
                documentUrl,
                designViewsUrl
            }),
            headers: {
                "Content-Type": "application/json",
                "x-caller-id": REQUESTOR,
                "x-correlation-id": getStudioUniqueSessionId()
            }
        },
        moduleFunction: "designSpecificationsClient:getResizedDocumentbyDesignViews",
        friendlyDescription: "retrieve resized document by surface",
        entityCode
    });
}
