export const STUDIO_DIAGNOSTIC_EVENT = "Studio Diagnostic";

export const STUDIO_DIAGNOSTIC_EVENTS = {
    CLIPBOARD: {
        eventLabel: "Clipboard",
        events: {
            CUT: "Cut Items",
            COPY: "Copy Items",
            PASTE: "Paste Items"
        }
    },
    ZOOM: {
        eventLabel: "Zoom",
        events: {
            ZOOM_IN: "Zoom In",
            ZOOM_OUT: "Zoom Out",
            ZOOM_RESET: "Zoom Reset"
        }
    },
    ITEM: {
        eventLabel: "Item",
        events: {
            SELECT_ALL: "Select All",
            DELETE: "DELETE"
        }
    },
    SHAPE: {
        eventLabel: "Shape",
        events: {
            RE_ADD: "Re-Add Shape",
            ADD: "Add Shape"
        }
    },
    IMAGE: {
        eventLabel: "Image",
        events: {
            ADD_ICON: "Add Icon",
            ADD_IMAGE: "Add Image",
            REPLACE_IMAGE: "Replace Image"
        }
    },
    TEXT: {
        eventLabel: "TextField",
        events: {
            ADD_TEXT: "Text Field Insert",
            ADD_WORD_ART: "Text Field Insert (Word Art)"
        }
    },
    HISTORY: {
        eventLabel: "History",
        events: {
            UNDO: "Undo",
            REDO: "Redo"
        }
    },
    EYEDROPPER: {
        eventLabel: "EyeDropper",
        events: {
            START: "Eye Dropper Start",
            END: "Eye Dropper End"
        }
    }
};
