import { useState } from "react";
import { useAsync } from "react-async-hook";
import { getDocument } from "@shared/utils/DocumentStorage";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import type { StudioConfiguration } from "@shared/utils/StudioConfiguration";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import type { DSS } from "@vp/types-ddif";
import { newRelicWrapper, handleError, ERROR_CODES } from "@shared/utils/Errors";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { getImageUploadUrl } from "@shared/features/UploadsAndAssets";
import { V2 } from "@shared/utils/DSS";
import { useAppSelector } from "@shared/redux";
import { EaselSceneConfiguration } from "@shared/utils/Scene";
import type { CalciferEaselConfig, DesignSpecification } from "@shared/utils/Calcifer";
import { prepareStudioCimDoc } from "./prepareStudioCimDoc/designDocumentUtilities";
import { useInitializePricing } from "./useInitializePricing";
import { useInitializeProductData } from "./useInitializeProductData";

// To support both S5 and S6
export interface SimplifiedEaselConfig {
    designViews: DSS.DesignView[];
    cimDoc: DSS.DesignDocument;
    easelScenesConfiguration?: EaselSceneConfiguration | null;
    activeDesignPanelId?: string;
    studioConfiguration: StudioConfiguration;
}

export interface EaselConfig extends SimplifiedEaselConfig {
    designerAPIKey: string;
    merchantID: string;
    udsTenant: string;
    isMobile: boolean;
    isTablet: boolean;
    uploadUrl: any;
    editorMode: string;
    locale: string;
    productOptions: Record<string, string>;
    productKey: string;
    designerConfigOverrides: (designerConfig: Configuration) => Configuration;
}

interface CimDocResolutionParams {
    calciferEaselConfig: CalciferEaselConfig;
    productVersion: number;
    productKey: string;
    studioSelectedProductOptions: Record<string, string>;
    isFullBleed: boolean;
    locale: string;
    template: string | null;
    documentRevisionUrl: string | null;
    authToken: string;
    useDraggablePlaceholders: boolean;
    studioConfiguration?: StudioConfiguration;
}

export async function resolveCimDocAndViews({
    calciferEaselConfig,
    productVersion,
    productKey,
    studioSelectedProductOptions,
    isFullBleed,
    locale,
    template,
    documentRevisionUrl,
    authToken,
    useDraggablePlaceholders,
    studioConfiguration
}: CimDocResolutionParams): Promise<{ designSpecification: DesignSpecification; cimDoc: DSS.DesignDocument }> {
    const { dssUrl } = getQueryParams();
    const { designSpecification } = calciferEaselConfig;
    if (dssUrl) {
        // eslint-disable-next-line no-param-reassign
        designSpecification.views = (
            await V2.getDesignViewsForProduct(productKey, productVersion, studioSelectedProductOptions, locale)
        ).designViews;
        // eslint-disable-next-line no-param-reassign
        designSpecification.designDocument = isFullBleed
            ? await V2.getDesignDocForFullbleed(productKey, productVersion, studioSelectedProductOptions, locale)
            : await V2.getDesignDocFromTemplateToken(
                  productKey,
                  productVersion,
                  studioSelectedProductOptions,
                  template ?? "",
                  locale
              );
    }

    const cimDocSessionStorage = window.sessionStorage.getItem("cimDocSessionStorage");
    let cimDoc;
    if (cimDocSessionStorage) {
        cimDoc = JSON.parse(cimDocSessionStorage);
        window.sessionStorage.removeItem("cimDocSessionStorage");
    } else if (calciferEaselConfig.cimDocOverride) {
        // eslint-disable-next-line prefer-destructuring
        cimDoc = calciferEaselConfig.cimDocOverride;
    } else if (documentRevisionUrl && authToken) {
        cimDoc = await getDocument(documentRevisionUrl, authToken);
    } else {
        cimDoc = designSpecification.designDocument;
    }

    if (productVersion === null) {
        throw Error("Product version is not defined");
    }
    const preparedCimDoc = await prepareStudioCimDoc(
        cimDoc,
        designSpecification,
        productKey,
        productVersion,
        studioSelectedProductOptions,
        locale,
        useDraggablePlaceholders,
        studioConfiguration
    );

    return { designSpecification, cimDoc: preparedCimDoc };
}

export function useEaselConfig() {
    const [config, setConfig] = useState<EaselConfig>();

    /**
     * Note: useSelector uses strict object reference equality to determine if
     * components should re-render instead of shallow object comparison.
     * Split this up so that it doesn't return a different object literal each time
     * Read: https://thoughtbot.com/blog/using-redux-with-react-hooks#useselector-gotchas
     *
     * If any of this state changes, we need to update the configuration we pass into Easel
     */
    const locale = useAppSelector(state => state.locale);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const productKey = useAppSelector(state => state.productKey);
    const studioConfiguration = useAppSelector(state => state.studioConfiguration);
    const productVersion = useAppSelector(state => state.productVersion);
    const easelScenesConfiguration = useAppSelector(state => state.scenesConfiguration);
    const template = useAppSelector(state => state.template);
    const documentRevisionUrl = useAppSelector(state => state.documentRevisionUrl);
    const productDataLoadSuccessful = useAppSelector(state => state.productDataLoadSuccessful);

    const [uploadUrl, setUploadUrl] = useState(null);

    const { isIdentityInitialized, auth } = useIdentityContext();
    const { isSmall, isMedium } = useStudioLayout();

    const { fullBleedElected } = getQueryParams();

    useInitializePricing();

    const calciferEaselConfig = useInitializeProductData();

    useAsync(async () => {
        if (isIdentityInitialized && !uploadUrl) {
            try {
                const authToken = auth.getToken();

                setUploadUrl(await getImageUploadUrl(authToken));
            } catch (e) {
                if (!auth.isSignedIn()) {
                    auth.signIn();
                } else {
                    handleError(e, ERROR_CODES.EASEL_CONFIG_ASYNC, true);
                }
            }
        }
    }, [isIdentityInitialized]);

    useAsync(async () => {
        if (productDataLoadSuccessful && calciferEaselConfig && uploadUrl && productVersion) {
            try {
                // This was doing !!fullBleedElected. Pulling from getQueryParams it's a string and !!string is true.
                const isFullBleed = fullBleedElected === "true";
                const authToken = auth.getToken();

                const { designSpecification, cimDoc } = await resolveCimDocAndViews({
                    calciferEaselConfig,
                    productKey,
                    productVersion,
                    studioSelectedProductOptions,
                    isFullBleed,
                    locale,
                    template,
                    documentRevisionUrl,
                    authToken,
                    useDraggablePlaceholders: !isSmall,
                    studioConfiguration
                });

                const newConfig = {
                    designerAPIKey: DESIGNER_API_KEY,
                    merchantID: MERCHANT_ID,
                    udsTenant: UDS_TENANT,
                    designViews: designSpecification.views,
                    isMobile: isSmall,
                    isTablet: isMedium,
                    uploadUrl,
                    cimDoc,
                    editorMode: "customerDesigner",
                    easelScenesConfiguration,
                    locale: locale.toLowerCase(),
                    productOptions: studioSelectedProductOptions,
                    productKey,
                    studioConfiguration,
                    designerConfigOverrides: (designerConfig: Configuration) => {
                        const newDesignerConfig = cloneDeep(designerConfig);

                        set(newDesignerConfig, "features.rasterPreviewScaling.enabled", true);
                        set(newDesignerConfig, "ui.zoomStrategy.enableOnlyZoomActiveCanvas", true);
                        const { minFontSize } = studioConfiguration;
                        if (minFontSize) {
                            Object.entries(minFontSize).forEach(([key, value]) => {
                                set(newDesignerConfig, `core.fonts.byProcessType.${key}.minLimit`, value);
                            });
                        }
                        set(newDesignerConfig, "ui.zoomStrategy.resizeEnabled", true);

                        return newDesignerConfig;
                    }
                };

                newRelicWrapper.logPageAction("studio-loading-easel-config");
                setConfig(newConfig);
            } catch (e) {
                handleError(e, ERROR_CODES.EASEL_CONFIG, true);
            }
        }
    }, [productDataLoadSuccessful, calciferEaselConfig, uploadUrl]);

    return config;
}
