import { RootState, AppDispatch, Store, setSelectedAndCompatibleProductOptions } from "@shared/redux";
import { WritableDraft } from "immer/dist/internal";
import cloneDeep from "lodash/cloneDeep";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

/**
 * Map and set valid studio and customer selected options
 * @param {Object} newSelectedOptionsMap a map of valid selected options key value pairs
 * @returns the new studio and customer selected options for immediate use
 */
export const setNewStudioAndCustomerSelectedOptions = (
    newSelectedOptionsMap: Record<string, string>
): ThunkAction<
    {
        newStudioSelectedProductOptions: Record<string, string>;
        newCustomerSelectedProductOptions: WritableDraft<Record<string, string>>;
        dispatchNewProductOptions: () => void;
    },
    RootState,
    unknown,
    AnyAction
> => {
    return (dispatch: AppDispatch, getState: typeof Store.getState) => {
        const { studioSelectedProductOptions, customerSelectedProductOptions, compatibleProductOptions } = getState();
        // Clone selected options as to not mutate redux state
        const newStudioSelectedProductOptions = cloneDeep(studioSelectedProductOptions);
        const newCustomerSelectedProductOptions = cloneDeep(customerSelectedProductOptions);

        Object.keys(newSelectedOptionsMap).forEach(selectedOptionName => {
            newStudioSelectedProductOptions[selectedOptionName] = newSelectedOptionsMap[selectedOptionName];
            newCustomerSelectedProductOptions[selectedOptionName] = newSelectedOptionsMap[selectedOptionName];
        });

        return {
            newStudioSelectedProductOptions,
            newCustomerSelectedProductOptions,
            dispatchNewProductOptions: () => {
                dispatch(
                    setSelectedAndCompatibleProductOptions({
                        studioSelectedProductOptions: newStudioSelectedProductOptions,
                        customerSelectedProductOptions: newCustomerSelectedProductOptions,
                        compatibleOptions: compatibleProductOptions
                    })
                );
            }
        };
    };
};
