import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { InvertColorsIcon } from "@shared/features/StudioChrome";
import { singleColorModalMessages } from "./singleColorMessages";
import "./invertColorsButton.scss";

interface Props {
    /** For overriding styles */
    className?: string;
    /** On click event handler */
    onClick: () => void;
    /** Whether the button should be disabled */
    disabled: boolean;
}

export function InvertColorsButton({ className, onClick, disabled = false }: Props) {
    const { t } = useTranslationSSR();

    return (
        <Button
            data-testid="invertColorsButton"
            data-translationid={singleColorModalMessages.invertColors.id}
            onClick={onClick}
            size="mini"
            skin="secondary"
            className={className}
            disabled={disabled}
        >
            <InvertColorsIcon className="invert-colors-button-icon" />
            {t(singleColorModalMessages.invertColors.id)}
        </Button>
    );
}

InvertColorsButton.displayName = "InvertColorsButton";
