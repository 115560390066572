import React, { ReactNode } from "react";
import { UploadDropZone } from "@design-stack-vista/upload-components";
import { observer } from "mobx-react-lite";
import { useAppSelector } from "@shared/redux";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { useUploadComponents } from "@shared/features/UploadsAndAssets";
import { useHandleImagePlacement, UploadStrategies } from "../../../easel";
import { useUploadCompletionEvent } from "../../../easel/designer-suite/hooks/useUploadCompletionEvent";
import { useUploadToDesignerSuccessCallback } from "../Panels/Images/DrawerPanel/useUploadToDesignerSuccessCallback";

interface DropZoneProps {
    children: ReactNode | ReactNode[];
}

export const DropZone = observer((props: DropZoneProps) => {
    const { children } = props;
    const uploadComponents = useUploadComponents();
    const showReplaceImageModal = useAppSelector(state => state.showReplaceImageModal);
    const { isSmall, isMedium } = useStudioLayout();
    const { onImageUploaded } = useHandleImagePlacement({
        placementStrategy: UploadStrategies.AutofillSinglePlaceholder
    });
    const { currentActiveDialog } = useActiveDialog();
    // In both cases below, we don't want the upload to happen if the images tab is open
    // or if the replace panel is open.
    const ignoreUploads = showReplaceImageModal || currentActiveDialog === DialogType.Images;

    // This hook is for when we are not using the new upload components
    // It will ignore the uploads if the new components are in use.
    // When we roll out the new components, this will get removed.
    useUploadCompletionEvent({
        ignoreUploads: uploadComponents || ignoreUploads,
        placementStrategy: UploadStrategies.AutofillSinglePlaceholder
    });

    // This affect is added, but only used if the upload components are in use
    useUploadToDesignerSuccessCallback(!ignoreUploads && uploadComponents ? onImageUploaded : undefined);

    return (
        <UploadDropZone
            // Disable click and keydown behavior
            noClick={true}
            noKeyboard={true}
            disabled={!uploadComponents || isSmall || isMedium}
        >
            {children}
        </UploadDropZone>
    );
});

DropZone.displayName = "DropZone";
