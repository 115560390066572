import React from "react";
import { useDecorationTechnology } from "@utilities";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import classNames from "classnames";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import "./shapesIconsPanel.scss";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { ShapesIconsPanelContents } from "./ShapesIconsPanelContents";

type Props = {
    onDone?: () => void;
};

export const shapesIconsMessages = defineMessages({
    shapesIconsPanelTitle: {
        id: "studio.components.redesign.panels.shapesIconsPanel.title",
        defaultMessage: "Shapes & Icons",
        description: {
            note: "Title for Shapes & Icons"
        }
    },
    shapesIconsPanelInfo: {
        id: "studio.components.redesign.panels.shapesIconsPanel.info",
        defaultMessage: "Add shapes or search over 3 million icons.",
        description: {
            note: "Information for the graphics panel"
        }
    },
    shapesPanelTabTitle: {
        id: "studio.components.redesign.panels.shapesPanel.title",
        defaultMessage: "Shapes",
        description: {
            note: "Tab Title for shapes panel"
        }
    },
    iconPanelTabTitle: {
        id: "studio.components.panels.studiopanels.iconHeader",
        defaultMessage: "Icons",
        description: {
            note: "Tab Title for icons panel"
        }
    }
});

export function ShapesIconsPanel({ onDone }: Props) {
    const { t } = useTranslationSSR();

    const decorationTechnology = useDecorationTechnology();
    const isEmbroidery = decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY;

    return (
        <PanelContent className={classNames("studio-shapes-icons-panel-content")}>
            <PanelTitle>
                {t(
                    isEmbroidery
                        ? shapesIconsMessages.iconPanelTabTitle.id
                        : shapesIconsMessages.shapesIconsPanelTitle.id
                )}
            </PanelTitle>
            <ShapesIconsPanelContents onDone={onDone} />
        </PanelContent>
    );
}
ShapesIconsPanel.displayName = "ShapesIconsPanel";
