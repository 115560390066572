import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { Button } from "@vp/swan";
import React from "react";

interface Props extends React.ComponentProps<typeof Button> {
    onClick?: (event: React.MouseEvent) => void;
}

export const UploadQRButton = ({ children, onClick, ...rest }: Props) => {
    const { isSmall } = useStudioLayout();

    const sizeVarientOfButton = isSmall ? "mini" : "standard";

    const onButtonClick = (event: React.MouseEvent) => {
        onClick && onClick(event);
    };
    return (
        <div className="image-panel-upload-button-container">
            <Button
                onClick={onButtonClick}
                width="full-width"
                size={sizeVarientOfButton}
                skin="secondary"
                marginBottom={isSmall ? 5 : 4}
                style={{ minHeight: "40px" }}
                {...rest}
            >
                {children}
            </Button>
        </div>
    );
};

UploadQRButton.displayName = "UploadQRButton";
