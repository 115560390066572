import { tryFetch } from "@shared/utils/Network";
import { sleep } from "@shared/utils/StandardLib";

const entityCode = 14;

function postWorkMigration(owner: string, authToken: string, locale: string, altDocId: string) {
    const url = `${DOCUMENT_GATEWAY_URL}/api/v1/documents/locale/${locale}/legacy/${altDocId}?${
        owner ? `owner=${owner}&` : ""
    }saveWork=true&useImportStatus=true&requestor=studio`;
    return tryFetch({
        url,
        options: {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workMigrationClient:postWorkMigration",
        friendlyDescription: "migrate legacy work",
        entityCode
    });
}

export async function migrateAltDocIdToWork(
    owner: string,
    authToken: string,
    locale: string,
    altDocId: string
): Promise<string | null> {
    // run this 10 times, taking a bit over 5 seconds, before giving up
    let loop = 10;
    // eslint-disable-next-line no-constant-condition
    while (loop > 0) {
        // eslint-disable-next-line no-await-in-loop
        const migrationResponse = await postWorkMigration(owner, authToken, locale, altDocId);
        if (migrationResponse.workId) {
            return migrationResponse.workId;
        }
        // eslint-disable-next-line no-await-in-loop
        await sleep(500);
        loop -= 1;
    }
    return null;
}
