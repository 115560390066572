import { useEffect } from "react";
import { useDesigner, useSelection } from "@designer-suite";
import { useScreenClass } from "@vp/swan";
import { STRETCH_HANDLE_BAR } from "../utilities/constants";

function addClass(className: string, classNameToBeAdded: string) {
    document.querySelectorAll<HTMLElement>(className).forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.classList.add(classNameToBeAdded);
    });
}

function removeClass(className: string, classNameToBeAdded: string) {
    document.querySelectorAll<HTMLElement>(className).forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.classList.remove(classNameToBeAdded);
    });
}

function innerHtml(className: string, html: string) {
    document.querySelectorAll<HTMLElement>(className).forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.innerHTML = html;
    });
}

type Params = {
    id: string;
    dimensions: { width: number; height: number };
};

// eslint-disable-next-line func-names
const setResizeHandleDimensions = function ({ id, dimensions }: Params, screenClass: string) {
    const controlId = `${id}-control`;
    let horiZontalClass = "no-hor-w-h";
    let verticalClass = "no-ver-w-h";

    // remove transparent bg for corner resize
    removeClass(
        `#${controlId} .dcl-ui-resizable-ne,#${controlId} .dcl-ui-resizable-se,#${controlId} .dcl-ui-resizable-sw`,
        "transparent"
    );

    if (dimensions.width >= STRETCH_HANDLE_BAR.MAX_WIDTH) {
        horiZontalClass = "hor-max-w";
    } else if (dimensions.width < STRETCH_HANDLE_BAR.MAX_WIDTH && dimensions.width >= STRETCH_HANDLE_BAR.MIN_WIDTH) {
        horiZontalClass = "hor-min-w";
    }

    if (dimensions.height >= STRETCH_HANDLE_BAR.MAX_HEIGHT) {
        verticalClass = "ver-max-h";
    } else if (
        dimensions.height < STRETCH_HANDLE_BAR.MAX_HEIGHT &&
        dimensions.height >= STRETCH_HANDLE_BAR.MIN_HEIGHT
    ) {
        verticalClass = "ver-min-h";
    } else if (screenClass === "xs" && dimensions.height < STRETCH_HANDLE_BAR.MIN_HEIGHT) {
        horiZontalClass = "hor-min-xs-w";
        verticalClass = "ver-min-xs-h";
        addClass(
            `#${controlId} .dcl-ui-resizable-ne,#${controlId} .dcl-ui-resizable-se,#${controlId} .dcl-ui-resizable-sw`,
            "transparent"
        );
    }

    // add stretch handle width height based on dimension
    innerHtml(
        `#${controlId} .dcl-ui-resizable-s,#${controlId} .dcl-ui-resizable-n`,
        `<div class="handle-bar ${horiZontalClass}"></div>`
    );
    innerHtml(
        `#${controlId} .dcl-ui-resizable-w,#${controlId} .dcl-ui-resizable-e`,
        `<div class="handle-bar ${verticalClass}"></div>`
    );
};

export function useResizeItems() {
    const designer = useDesigner();
    const screenClass = useScreenClass();
    const selection = useSelection(
        // eslint-disable-next-line max-len
        "change:handleBoundingBox change:selecting change:rotating change:resizing change:dragging change:previewSize change:relativePreviewPosition change:selected model:change:isAspectRatioLocked"
    );

    useEffect(() => {
        if (!designer || !selection.length) {
            return;
        }
        const dimensions = selection[0].pxDimensions;
        const id = selection[0]._itemViewModel.get("id");
        if (dimensions && id) {
            setResizeHandleDimensions({ id, dimensions }, screenClass);
        }
    }, [screenClass, designer, selection]);
}
