import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { RedoIcon } from "@shared/features/StudioChrome";
import { Typography } from "@vp/swan";

const messages = defineMessages({
    redo: {
        id: "studio.components.sidebar.redo.label",
        defaultMessage: "Redo",
        description: {
            note: "Label for the sidebar redo button"
        }
    }
});

type Props = {
    className?: string;
    showLabel?: boolean;
    disabled?: boolean;
    onClick: () => void;
};

export const RedoButton = (props: Props) => {
    const { onClick, showLabel, className, disabled, ...rest } = props;
    const { t } = useTranslationSSR();

    return (
        <button
            aria-label={!showLabel ? t(messages.redo.id) : undefined}
            onClick={onClick}
            disabled={disabled}
            className={classNames("redo-button", className)}
            {...rest}
        >
            <RedoIcon aria-hidden="true" />
            {showLabel && (
                <Typography fontWeight="bold" className="button-label" fontSize="-1">
                    {t(messages.redo.id)}
                </Typography>
            )}
        </button>
    );
};

RedoButton.displayName = "RedoButton";
