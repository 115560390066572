import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Link } from "@vp/swan";
import { KeyboardIcon } from "@shared/features/StudioChrome";
import { useKeyboardShortcutContext } from "./KeyboardShortcutsContext";
import * as styles from "./KeyboardShortcutsButton.module.scss";

const messages = defineMessages({
    keyboardShortcutsLinkButton: {
        id: "studio.components.keyboardShorcuts.keyboardShortcutsLinkButton",
        defaultMessage: "See keyboard shortcuts",
        description: {
            note: "text for view modal keyboard shortcuts button"
        }
    }
});

export function KeyboardShortcutsButton() {
    const { t } = useTranslationSSR();
    const { setIsKeyboardShortcutsGuideModalOpen } = useKeyboardShortcutContext();

    function onClick() {
        setIsKeyboardShortcutsGuideModalOpen(true);
    }

    return (
        <div className={styles.keybordShortcutsButton}>
            <span className={styles.keyboardShortcutsIcon}>
                <KeyboardIcon aria-hidden="true" />
            </span>
            <Link component="button" onClick={onClick} className={styles.keyboardShortcutsLabel}>
                {t(messages.keyboardShortcutsLinkButton.id)}
            </Link>
        </div>
    );
}

KeyboardShortcutsButton.displayName = "KeyboardShortcutsButton";
