import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Typography,
    Button,
    Box,
    FlexBox,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogNav,
    Link
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout, DesktopExperience, MobileExperience } from "@shared/features/ResponsiveDesign";
import { useFileMethods } from "@design-stack-vista/upload-components";
import { isSingleColorCanvas, getTrackingDataForSelection } from "@utilities";
import { convertToRgb, isRgb, type SelectableColor } from "@shared/features/ColorPicker";
import once from "lodash/once";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useAppSelector, useAppDispatch, showSingleColorImageModal } from "@shared/redux";
import { ERROR_CODES, handleError } from "@shared/utils/Errors";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { RGB, rgb2hex } from "@design-stack-ct/utility-core";
import { singleColorModalMessages } from "./singleColorMessages";
import { useDesigner } from "../../designer/DesignerProvider";
import { directImageReplacementEvent } from "../../PanelComponents/Uploads/UploadStrategies";
import { useSelection } from "../../designer/useSelection";
import { SingleColorImagePreview } from "./SingleColorImagePreview";
import { SingleColorImageSlider } from "./SingleColorImageSlider";
import { DEFAULT_THRESHOLD, createVector } from "./clientsideThresholdImageUtil";
import { DesktopSingleColorPalette } from "./DesktopSingleColorPalette";
import { getColorPalette, itemIsImageUnreplacedPlaceholder, itemIsIcon, imageIsIcon } from "../../util";
import { InvertColorsButton } from "./InvertColorsButton";
import { MobileSingleColorPalette } from "./MobileSingleColorPalette";
import { useClientSideThresholding } from "./useClientSideThresholding";
import "./singleColorImageColorModal.scss";

const trackDesignToolUsage = (trigger: string) => {
    fireDesignToolTrackingEvent({
        eventDetail: STUDIO_TRACKING_EVENTS.SINGLE_COLOR_IMAGE_MODAL_AUTO_OPEN,
        // @ts-ignore
        label: "Single color image modal automatically opened",
        // @ts-ignore
        extraData: () => ({ trigger })
    });
};

export function SingleColorImageModal() {
    const dispatch = useAppDispatch();
    const designer = useDesigner();
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();
    const isOpen = useAppSelector(state => state.singleColorImageModalOpen);
    const isSingleColor = isSingleColorCanvas(designer);

    const selection = useSelection("change:colors");
    const [originalThreshold, setOriginalThreshold] = useState<number>();
    const [color, setColor] = useState<ColorSpecification | undefined>(undefined);
    const [threshold, setThreshold] = useState<number>(DEFAULT_THRESHOLD);
    const [paletteColors, setPaletteColors] = useState<SelectableColor[]>([]);
    const [inverted, setInverted] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isReplacingImage, setIsReplacingImage] = useState(false);
    const [isNewImage, setIsNewImage] = useState(false);
    const [startTime, setStartTime] = useState<number | undefined>();
    const imageCanvasRef = useRef<HTMLCanvasElement>(null);
    const { uploadFile } = useFileMethods();
    useClientSideThresholding();

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        const itemViewModel = selection[0]?._itemViewModel;
        const itemModel = itemViewModel?.model;
        const image = itemModel?.get("image");
        const startTime = performance.now();

        setStartTime(startTime);
        if (isOpen && (!image || isReplacingImage)) {
            setIsNewImage(true);
        }
        // only call this once when the modal opens
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const canChangeColor = selection[0]?._itemViewModel.model.attributes.constraints?.canAlterColor;
    const showColorPicker = canChangeColor && color && paletteColors.length > 1;

    const currentColor = color
        ? paletteColors.find(pColor => pColor.value.includes(color.color) || pColor.value.includes(color.hex))
        : undefined;

    const handleClose = () => {
        dispatch(showSingleColorImageModal(false));
        setImageLoaded(false);
        setColor(undefined);
        setIsReplacingImage(false);
        setIsNewImage(false);
    };

    const needsVectorization = () => {
        const itemViewModel = selection[0]?._itemViewModel;
        const itemModel = itemViewModel.model;
        return originalThreshold !== threshold || itemModel.get("inverted") !== inverted;
    };

    const handleApply = async () => {
        const item = selection[0];
        const itemViewModel = item?._itemViewModel;
        const canvas = imageCanvasRef.current;
        const rgbColor = color?.rgb;
        if (canvas && rgbColor && needsVectorization()) {
            itemViewModel.set("imageProcessingInProgress", true);
            try {
                const startTime = performance.now();
                await createVector(canvas, threshold, async svgPath => {
                    const blob = new Blob([svgPath], { type: "image/svg+xml" });
                    const file = new File([blob], "singlecolorimage.svg", { type: "image/svg+xml" });
                    const asset = await uploadFile(file, { hidden: true });
                    if (asset) {
                        await asset.presign();
                        const originalUrl = asset.getUrl({ includeSignature: true });
                        const previewUrl = originalUrl;
                        const printUrl = originalUrl;
                        designer?.commandDispatcher.changeAttributes({
                            viewModel: itemViewModel,
                            attributes: { threshold, inverted, previewUrl, printUrl, specialProcessing: [] }
                        });
                        const endTime = performance.now();
                        const timeElapsed = endTime - startTime;
                        fireUserInteractionTrackingEvent("Single color image apply changes", timeElapsed);
                        itemViewModel.unset("imageProcessingInProgress");
                    }
                    designer?.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_SINGLE_COLOR_IMAGE_APPLY_BUTTON,
                        getTrackingDataForSelection(selection)
                    );
                });
                applyColor();
            } catch (error) {
                handleError(error, ERROR_CODES.SINGLE_COLOR_IMAGE_THRESHOLDING);
                // delete the image if thresholding is unsuccessful
                designer?.api.design.updateItem(item.id, mutableItem => {
                    mutableItem.remove();
                });
            }
        }
        handleClose();
    };

    useEffect(() => {
        if (designer && isOpen && paletteColors?.length === 0) {
            const colors = getColorPalette(designer);
            setPaletteColors(colors);
        }
    }, [designer, isOpen, paletteColors]);

    useEffect(() => {
        if (!designer) return;
        if (!isSingleColor) return;
        const isImage = (eventData: EventData) => {
            const item = eventData.items[0];
            if (
                item.itemType === ItemTypes.IMAGE &&
                !itemIsIcon(item) &&
                !itemIsImageUnreplacedPlaceholder(item) &&
                !item._itemViewModel.model.attributes.threshold
            ) {
                // when an image is double clicked to add, but doesn't replace a placeholder,
                // it is not automatically selected
                designer?.selectionManager.select([item._itemViewModel]);
                trackDesignToolUsage("addImage");
                dispatch(showSingleColorImageModal(true));
            }
        };
        designer.eventBus.on(designer.eventBus.events.replaceImage, ({ image }: any) => {
            if (imageIsIcon(image)) return;

            setIsReplacingImage(true);
            trackDesignToolUsage("replaceImage");
            dispatch(showSingleColorImageModal(true));
        });
        const unsubscribeAdd = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, isImage);
        designer.eventBus.on(directImageReplacementEvent, ({ image }: any) => {
            if (imageIsIcon(image)) return;

            // isReplacingImage keeps track of when an image is replaced in order to update the preview image
            // there's no way to know that the image has changed once the modal is open
            setIsReplacingImage(true);

            trackDesignToolUsage("directImageReplacement");
            dispatch(showSingleColorImageModal(true));
        });

        // eslint-disable-next-line consistent-return
        return () => {
            unsubscribeAdd();
            designer.eventBus.off(directImageReplacementEvent);
            designer.eventBus.off(designer.eventBus.events.replaceImage);
        };
    }, [designer, dispatch, isSingleColor]);

    const updateThreshold = (threshold: number) => {
        if (threshold !== undefined) {
            setThreshold(threshold);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleThresholdRelease = useCallback(
        once(() => {
            designer?.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.UPDATE_SINGLE_COLOR_THRESHOLD_SLIDER,
                getTrackingDataForSelection(selection)
            );
        }),
        [designer?.eventBus, selection]
    );

    const updateInverted = () => {
        setInverted(currentInversion => !currentInversion);
        designer?.eventBus.trigger(
            STUDIO_TRACKING_EVENTS.CLICK_SINGLE_COLOR_IMAGE_INVERT_COLORS_BUTTON,
            getTrackingDataForSelection(selection)
        );
    };

    const updateColor = (color: string | RGB) => {
        const paletteColor = isRgb(color)
            ? paletteColors.find(pColor => pColor.cssBackground === rgb2hex(color))
            : paletteColors.find(pColor => pColor.value === color);

        if (paletteColor) {
            // update color for image preview and store in state
            const rgb = convertToRgb(paletteColor.cssBackground);
            const hex = rgb2hex(rgb);
            // rgb needed for image preview
            setColor({ color: paletteColor.value, ordinal: 0, rgb, hex });
            designer?.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.CLICK_SINGLE_COLOR_IMAGE_UPDATE_COLOR,
                getTrackingDataForSelection(selection)
            );
        }
    };

    const applyColor = () => {
        if (color) {
            const itemViewModel = selection[0]?._itemViewModel;
            // get all models on cavas from parent
            const allItemViewModels = itemViewModel.parent.itemViewModels;
            const currentColor = itemViewModel.model.get("colorOverrides")[0];
            // update color for all models on canvas
            designer?.commandDispatcher.changeColors({
                viewModels: allItemViewModels,
                colorChanges: [{ key: "color", newColor: color.color, currentColor }]
            });
        }
    };

    useEffect(() => {
        if (isOpen && selection.length && !color) {
            const itemViewModel = selection[0]?._itemViewModel;
            const itemModel = itemViewModel.model;

            const originalThreshold = itemModel.get("threshold");
            const itemViewModelOriginalColor = itemViewModel.get("colors")[0];
            const inverted = itemModel.get("inverted");
            updateThreshold(originalThreshold);
            setOriginalThreshold(originalThreshold);
            setColor(itemViewModelOriginalColor);
            setInverted(inverted);
        }
    }, [selection, isOpen, color]);

    return (
        <LegacyModalDialog
            isOpen={isOpen}
            onRequestDismiss={handleClose}
            className="single-color-image-color-modal"
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent aria-label={t(singleColorModalMessages.modalContentAriaLabel.id)}>
                <LegacyModalDialogNav>
                    <LegacyModalDialogHeader>
                        <Typography textSize={isSmall ? 6 : 5} textAlign={isSmall ? "left" : "center"}>
                            {t(singleColorModalMessages.modalTitle.id)}
                        </Typography>
                    </LegacyModalDialogHeader>
                    <DesktopExperience>
                        <LegacyModalDialogCloseButton
                            visuallyHiddenLabel={t(singleColorModalMessages.closeModalAriaLabel.id)}
                        />
                    </DesktopExperience>
                </LegacyModalDialogNav>
                {!isSmall && <Divider />}
                <LegacyModalDialogBody>
                    <DesktopExperience>
                        <Typography
                            fontSize="2"
                            className="single-color-image-color-modal__instructional-text"
                            textAlign={"center"}
                        >
                            {t(singleColorModalMessages.contrastSlider.id)}
                        </Typography>
                    </DesktopExperience>
                    <SingleColorImagePreview
                        threshold={threshold}
                        updateThreshold={updateThreshold}
                        color={color?.rgb}
                        inverted={inverted}
                        imageLoaded={imageLoaded}
                        setImageLoaded={setImageLoaded}
                        showBackground={showColorPicker}
                        isReplacingImage={isReplacingImage}
                        isNewImage={isNewImage}
                        ref={imageCanvasRef}
                        startTime={startTime}
                    />
                    <FlexBox alignItems="center" justifyContent="center" marginTop={{ xs: 8 }}>
                        {showColorPicker && !isSmall && (
                            <Box marginRight={2}>
                                <DesktopSingleColorPalette
                                    paletteColors={paletteColors}
                                    currentColor={currentColor}
                                    onChange={updateColor}
                                    disabled={!imageLoaded}
                                />
                            </Box>
                        )}
                        {(!showColorPicker || !isSmall) && (
                            <InvertColorsButton
                                onClick={updateInverted}
                                className="single-color-image-color-modal__invert-colors-button"
                                disabled={!imageLoaded}
                            />
                        )}
                    </FlexBox>
                    <MobileExperience>
                        <Typography
                            fontSize="-1"
                            className="single-color-image-color-modal__instructional-text"
                            textAlign={"center"}
                        >
                            {t(singleColorModalMessages.contrastSlider.id)}
                        </Typography>
                    </MobileExperience>
                    <SingleColorImageSlider
                        threshold={threshold}
                        updateThreshold={updateThreshold}
                        onRelease={handleThresholdRelease}
                        disabled={!imageLoaded}
                    />
                    <Divider />
                    {isSmall && showColorPicker && (
                        <Box className="single-color-image-color-modal__mobile-color-tools-container">
                            <Link
                                component="button"
                                onClick={updateInverted}
                                skin="standard"
                                className="single-color-image-color-modal__invert-colors-link"
                            >
                                {t(singleColorModalMessages.invertColors.id)}
                            </Link>
                            <MobileSingleColorPalette
                                paletteColors={paletteColors}
                                currentColor={currentColor}
                                onChange={updateColor}
                            />
                        </Box>
                    )}
                    <FlexBox
                        className="single-color-image-color-modal__container"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            size="mini"
                            width={isSmall ? "full-width" : "standard"}
                            className="single-color-image-color-modal__button"
                            onClick={handleClose}
                        >
                            <Typography fontSize="-1">{t(singleColorModalMessages.cancel.id)}</Typography>
                        </Button>
                        <Button
                            skin="primary"
                            size="mini"
                            width={isSmall ? "full-width" : "standard"}
                            className="single-color-image-color-modal__button"
                            onClick={handleApply}
                        >
                            <Typography fontSize="-1">{t(singleColorModalMessages.apply.id)}</Typography>
                        </Button>
                    </FlexBox>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

SingleColorImageModal.displayName = "SingleColorImageModal";
