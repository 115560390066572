import React, { PointerEvent, useCallback, useMemo, useEffect } from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useEventCallback } from "@design-stack-ct/utility-react";
import { useTableOverlayControls } from "../../TableOverlayControlsProvider";
import { SubselectionType, useTableSubselection } from "../../TableSubselectionProvider";

import * as styles from "./CellOverlay.module.scss";

const messages = defineMessages({
    buttonTitle: { id: "easel.designerSuite.editTableCell.label", defaultMessage: "Edit Table Cell", description: {} }
});

interface CellOverlayProps {
    onPointerDown?: (event: PointerEvent) => void;
}

export function CellOverlay({ onPointerDown }: CellOverlayProps) {
    const { relativeRowHeights: rowSizes, relativeColumnWidths: columnSizes, fontSizeInPx } = useTableOverlayControls();
    const { selection, updateSelection } = useTableSubselection();
    const { trackEvent } = useTrackEvents();
    const { t } = useTranslationSSR();

    const numRows = rowSizes.length;
    const numCols = columnSizes.length;
    const { rowIndex: selectedRowIndex, columnIndex: selectedColumnIndex } = selection;
    const fontSize = fontSizeInPx ? `${fontSizeInPx}px` : "inherit";

    const refs = useMemo(
        () => rowSizes.map(() => columnSizes.map(() => React.createRef<HTMLDivElement>())),
        [rowSizes, columnSizes]
    );

    const callback = useCallback(
        (columnIndex: number, rowIndex: number, ref: any) => {
            trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_TABLE_OVERLAY_CELL });
            updateSelection({ ...selection, columnIndex, rowIndex, type: SubselectionType.Cell, ref });
        },
        [selection, updateSelection, trackEvent]
    );

    const onKeyDown = useEventCallback(
        (event: any) => {
            if (
                event.key === "ArrowRight" ||
                event.key === "ArrowLeft" ||
                event.key === "ArrowUp" ||
                event.key === "ArrowDown"
            ) {
                if (document.activeElement?.classList.contains("table-cell-overlay-cell-container")) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                setTimeout(() => {
                    if (!document.activeElement?.classList.contains("table-cell-overlay-cell-container")) {
                        return;
                    }

                    let currentRowIndex = selectedRowIndex;
                    let currentColIndex = selectedColumnIndex;

                    if (event.key === "ArrowDown") {
                        if (selectedColumnIndex + 1 === numCols && selectedRowIndex + 1 === numRows) {
                            return;
                        }

                        if (numRows === selectedRowIndex + 1 && numCols > selectedColumnIndex + 1) {
                            currentColIndex = selectedColumnIndex + 1;
                            currentRowIndex = 0;
                        } else if (numRows >= selectedRowIndex + 1) {
                            currentRowIndex = selectedRowIndex + 1;
                        }
                    }

                    if (event.key === "ArrowUp") {
                        if (selectedColumnIndex === 0 && selectedRowIndex === 0) {
                            return;
                        }

                        if (selectedRowIndex === 0 && selectedColumnIndex > 0) {
                            currentRowIndex = numRows - 1;
                            currentColIndex = selectedColumnIndex - 1;
                        }
                        if (selectedRowIndex - 1 >= 0) {
                            currentRowIndex = selectedRowIndex - 1;
                        }
                    }

                    if (event.key === "ArrowRight") {
                        if (selectedColumnIndex + 1 === numCols && selectedRowIndex + 1 === numRows) {
                            return;
                        }

                        if (numCols > selectedColumnIndex + 1) {
                            currentColIndex = selectedColumnIndex + 1;
                        } else if (numCols === selectedColumnIndex + 1) {
                            currentColIndex = 0;
                            if (numRows >= selectedRowIndex + 1) {
                                currentRowIndex = selectedRowIndex + 1;
                            }
                        }
                    }

                    if (event.key === "ArrowLeft") {
                        if (selectedColumnIndex === 0 && selectedRowIndex === 0) {
                            return;
                        }

                        if (selectedColumnIndex - 1 >= 0) {
                            currentColIndex = selectedColumnIndex - 1;
                        } else if (selectedColumnIndex === 0) {
                            currentColIndex = selectedColumnIndex + 1;
                            if (selectedRowIndex - 1 >= 0) {
                                currentRowIndex = selectedRowIndex - 1;
                            }
                        }
                    }

                    callback(currentColIndex, currentRowIndex, refs[currentRowIndex][selectedColumnIndex].current);
                }, 0);
            }
        },
        [selectedRowIndex, selectedColumnIndex]
    );

    // by default Studio takes control of the keydown event to control the movement of active component,
    // in order to override that behaviour by checking the active element keydown listener is added
    useEffect(() => {
        if (document.activeElement?.classList.contains("table-cell-overlay-cell-container")) {
            document.body.addEventListener("keydown", onKeyDown, false);

            if (selectedRowIndex >= 0 && selectedColumnIndex >= 0) {
                refs[selectedRowIndex][selectedColumnIndex].current?.focus();
            }
        } else {
            document.body.removeEventListener("keydown", onKeyDown, false);
        }

        return () => {
            document.body.removeEventListener("keydown", onKeyDown, false);
        };
    }, [onKeyDown, refs, selectedRowIndex, selectedColumnIndex]);

    return (
        <div
            className={classNames("table-cell-overlay", styles.cellOverlay)}
            style={{ fontSize }}
            onPointerDown={onPointerDown}
        >
            {rowSizes.map((rowSize, rowIndex) => (
                <div
                    className={classNames("table-cell-overlay-row-container", styles.rowContainer)}
                    style={{ height: `${rowSize}%` }}
                    key={`cell-row-${rowIndex}`}
                >
                    {columnSizes.map((columnSize, columnIndex) => {
                        const isSelected =
                            selection.type === SubselectionType.Cell &&
                            selection.columnIndex === columnIndex &&
                            selection.rowIndex === rowIndex;

                        const onClick = (e: any) => {
                            callback(columnIndex, rowIndex, refs[rowIndex][columnIndex].current);
                        };

                        return (
                            <div
                                className={classNames(
                                    "table-cell-overlay-cell-container",
                                    {
                                        "table-cell-overlay-cell-container-selected": isSelected
                                    },
                                    styles.cellContainer,
                                    {
                                        [styles.cellContainerSelected]: isSelected
                                    }
                                )}
                                style={{ width: `${columnSize}%` }}
                                key={`cell-column-${columnIndex}`}
                                onFocus={onClick}
                                role="button"
                                tabIndex={0}
                                ref={refs[rowIndex][columnIndex]}
                                aria-label={t(messages.buttonTitle.id)}
                                aria-pressed={isSelected}
                            />
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

CellOverlay.displayName = "CellOverlay";
