import React, { ReactNode, useEffect, useState } from "react";
import { RichTextField, RichTextRef } from "./RichTextField";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /** What item this richTextField is hooked up to */
    item: TextItem;
    /** For overriding styles */
    className?: string;
    /** React component to render at right of the textfield */
    icon?: ReactNode;
    /** Whether or not to select the item on focus */
    selectItemOnFocus?: boolean;
    /** For tracking usage of left panel editing */
    leftPanel?: boolean;
    fieldNumber?: string;
    richTextRef?: React.RefObject<RichTextRef>;
}

export const RichTextFieldWrapper = (props: Props) => {
    const { richTextRef, ...rest } = props;
    // we need to unmount/mount the rich text field if chat loads
    const [showRichText, setShowRichText] = useState(true);

    useEffect(() => {
        const respondToChat = () => setShowRichText(false);
        document.addEventListener("ChatScriptsLoaded", respondToChat);
        return () => document.removeEventListener("ChatScriptsLoaded", respondToChat);
    }, []);

    useEffect(() => {
        if (!showRichText) {
            setShowRichText(true);
        }
    }, [showRichText]);

    if (!showRichText) {
        return null;
    }

    return <RichTextField {...rest} ref={richTextRef} />;
};
RichTextFieldWrapper.displayName = "RichTextFieldWrapper";
