import { useEffect } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { newRelicWrapper } from "@shared/utils/Errors";
import { isDebugMode } from "@shared/utils/Debug";
import { useAppSelector } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";
import { Save } from "./useSave";

/**
 * Utilizes browser visibilitychange to trigger an autosave. This event can trigger
 * when navigating away from a tab or window on desktop or switching away from the browser on mobile devices.
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
 *
 * useBeforeUnloadSaveWarning's beforeunload event will fire prior to visibilitychange or popstate
 */
export function useVisibilityAutoSaveEffect(save: Save) {
    const status = useAppSelector(state => state.saveStatus);
    const workId = useAppSelector(state => state.workId);

    const { identity } = useIdentityContext();

    // Forcing the saves
    useEffect(() => {
        if (!document || !window) {
            return () => {};
        }
        // Tab becoming non-visible
        const onVisibilityChange = () => {
            // * don't autosave for anonymous users - they often are unable to access their works when the anonymous token expires
            // * autosaving for care agents may accidentally save a user's design when unintended
            if (
                status === SAVE_STATUS.CAN_BE_SAVED &&
                document.visibilityState === "hidden" &&
                identity.isSignedIn &&
                !identity.isCareAgent &&
                !isDebugMode()
            ) {
                newRelicWrapper.logPageAction("pageVisiblityAutoSave", { saveTrigger: "visibilitychange" });
                save();
            }
        };

        // Browser navigation like "Back"
        const onPopState = () => {
            // * don't autosave for anonymous users - they often are unable to access their works when the anonymous token expires
            // * in this case only autosave if we already have a work id.  Prevents race conditions with other save events
            // plus we likely only have state to pop if we previously saved already
            // * autosaving for care agents may accidentally save a user's design when unintended
            if (
                status === SAVE_STATUS.CAN_BE_SAVED &&
                identity.isSignedIn &&
                workId &&
                !identity.isCareAgent &&
                !isDebugMode()
            ) {
                newRelicWrapper.logPageAction("pageVisiblityAutoSave", { saveTrigger: "popState" });
                save();
            }
        };

        // The third parameter is in theory optional and defaults to false, but some browsers may throw
        // errors if not present. The false allows normal event bubbling to occur.
        window.addEventListener("visibilitychange", onVisibilityChange, false);
        window.addEventListener("popstate", onPopState);

        return () => {
            window.removeEventListener("visibilitychange", onVisibilityChange);
            window.removeEventListener("popstate", onPopState);
        };
    }, [save, status, identity.isSignedIn, identity.isCareAgent, workId]);
}
