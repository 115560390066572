import React from "react";
import { MailingIcon, MailingServiceActiveIcon, GenericHeaderButton } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useCartContext } from "@shared/features/Cart";
import {
    useMailingServicesContext,
    useIsCompatiblePostCard,
    useIsNonMailingPostCard
} from "@shared/features/MailingServices";
import { mailingServicesMessages } from "./messages";

interface Props {
    className?: string;
}

export const MailingButton = ({ className }: Props) => {
    const { t } = useTranslationSSR();
    const { setIsMailingServicesFAQModalOpen, setIsMailingServicesModalOpen } = useMailingServicesContext();
    const isNonMailingServicePostcard = useIsNonMailingPostCard();
    const isCompatiblePostCard = useIsCompatiblePostCard();
    const mailingIcon = isNonMailingServicePostcard ? (
        <MailingIcon aria-hidden="true" />
    ) : (
        <MailingServiceActiveIcon aria-hidden="true" />
    );
    const labelContent = t(mailingServicesMessages.mailingButton.id);
    const { isItemInCart } = useCartContext();

    const openMailingServicesFAQModal = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_MAILING_SERVICES_BUTTON,
            label: "Open mailing services faq modal"
        } as any);
        setIsMailingServicesFAQModalOpen(true);
    };

    const openMailingServicesOptionsModal = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_NON_MAILING_SERVICES_BUTTON,
            label: "Open mailing services options modal"
        } as any);
        setIsMailingServicesModalOpen(true);
    };

    return (
        <>
            {isCompatiblePostCard && !isItemInCart && (
                <GenericHeaderButton
                    className={className}
                    icon={mailingIcon}
                    label={labelContent}
                    onClick={
                        isNonMailingServicePostcard ? openMailingServicesOptionsModal : openMailingServicesFAQModal
                    }
                />
            )}
        </>
    );
};

MailingButton.displayName = "MailingButton";
