import { CMYK } from "@design-stack-ct/utility-core";
import { Rgb, Rgba, Hsl } from "./colorTypes";

export function rgbToCmyk({ r: red, g: green, b: blue }: Rgb | Rgba): CMYK {
    const r = red / 255;
    const g = green / 255;
    const b = blue / 255;

    const k = Math.min(1 - r, 1 - g, 1 - b);
    const c = (1 - r - k) / (1 - k) || 0;
    const m = (1 - g - k) / (1 - k) || 0;
    const y = (1 - b - k) / (1 - k) || 0;

    return { c: Math.round(c * 100), m: Math.round(m * 100), y: Math.round(y * 100), k: Math.round(k * 100) };
}

export function rgbToHsl({ r: red, g: green, b: blue }: Rgb | Rgba): Hsl {
    let h;
    let s;
    const r = red / 255;
    const g = green / 255;
    const b = blue / 255;
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const l = (max + min) / 2;

    if (min === max) {
        s = 0;
    } else {
        s = l < 0.5 ? (max - min) / (max + min) : (max - min) / (2 - max - min);
    }

    if (min === max) {
        h = 0;
    } else if (r === max) {
        h = (g - b) / (max - min);
    } else if (g === max) {
        h = 2 + (b - r) / (max - min);
    } else {
        h = 4 + (r - g) / (max - min);
    }

    h *= 60;

    if (h < 0) {
        h += 360;
    }
    return { h, s, l };
}

export function rgbToHex({ r, g, b }: Rgb) {
    const red = `${r < 16 ? "0" : ""}${r.toString(16)}`;
    const green = `${g < 16 ? "0" : ""}${g.toString(16)}`;
    const blue = `${b < 16 ? "0" : ""}${b.toString(16)}`;
    return `#${red}${green}${blue}`.toUpperCase();
}

const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export function hexToRgb(hex: string) {
    const match = hex.match(hexRegex);
    if (!match) {
        return null;
    }
    const hexValue = hex.replace("#", "");
    return {
        r: parseInt(hexValue[0] + hexValue[hexValue[3] ? 1 : 0], 16),
        g: parseInt(hexValue[hexValue[3] ? 2 : 1] + (hexValue[3] || hexValue[1]), 16),
        b: parseInt((hexValue[4] || hexValue[2]) + (hexValue[5] || hexValue[2]), 16)
    };
}

export function cmykToRgb({ c: cyan, m: magenta, y: yellow, k: black }: CMYK): Rgb {
    const c = cyan / 100;
    const m = magenta / 100;
    const y = yellow / 100;
    const k = black / 100;

    const r = 1 - Math.min(1, c * (1 - k) + k);
    const g = 1 - Math.min(1, m * (1 - k) + k);
    const b = 1 - Math.min(1, y * (1 - k) + k);

    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

function getRgbPrime(h: number, c: number, x: number) {
    if (h >= 0 && h < 60) {
        return {
            rPrime: c,
            gPrime: x,
            bPrime: 0
        };
    }
    if (h < 120) {
        return {
            rPrime: x,
            gPrime: c,
            bPrime: 0
        };
    }
    if (h < 180) {
        return {
            rPrime: 0,
            gPrime: c,
            bPrime: x
        };
    }
    if (h < 240) {
        return {
            rPrime: 0,
            gPrime: x,
            bPrime: c
        };
    }
    if (h < 300) {
        return {
            rPrime: x,
            gPrime: 0,
            bPrime: c
        };
    }

    // h < 360
    return {
        rPrime: c,
        gPrime: 0,
        bPrime: x
    };
}

export function hslToRgb({ h: hue, s: saturation, l: lightness }: Hsl): Rgb {
    const c = (1 - Math.abs(2 * lightness - 1)) * saturation;
    const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
    const m = lightness - c / 2;

    const { rPrime, gPrime, bPrime } = getRgbPrime(hue, c, x);
    return {
        r: Math.round((rPrime + m) * 255),
        g: Math.round((gPrime + m) * 255),
        b: Math.round((bPrime + m) * 255)
    };
}
