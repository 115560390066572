import { useDrawerThumbnailAsset } from "@design-stack-vista/upload-components";
import { AssetStatusType, VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import React, { ReactNode, useCallback, useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DraggableTypes } from "./Constants";

interface DraggableUploadImageProps {
    children: ReactNode | ReactNode[];
    isPageInMultiBox?: boolean;
    isDraggingAllowed?: boolean;
}

export const getNumPages = (asset: VistaAsset) => {
    return asset.data?.info?.image?.format === "pdf" ? asset.data?.info.image.numPages ?? 1 : 1;
};

export const DraggableUploadedImage = (props: DraggableUploadImageProps) => {
    const { children, isPageInMultiBox } = props;
    const {
        asset,
        asset: { status },
        pageNumber
    } = useDrawerThumbnailAsset();
    const { isSmall } = useStudioLayout();

    const canIBeDragged = useCallback(() => {
        if (isSmall) {
            return false;
        }
        if (status.type !== AssetStatusType.Prepared) {
            return false;
        }
        if (isPageInMultiBox) {
            return true;
        }
        if (getNumPages(asset) > 1) {
            return false;
        }
        return true;
    }, [isSmall, isPageInMultiBox, asset, status]);

    const [, drag, preview] = useDrag({
        // what type of item this to determine if a drop target accepts it
        type: DraggableTypes.UploadedImage,
        // data of the item to be available to the drop methods
        item: { asset, pageNumber },
        canDrag: canIBeDragged()
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return <div ref={drag}>{children}</div>;
};

DraggableUploadedImage.displayName = "DraggableUploadedImage";
