import { useDesigner } from "@designer-suite";
import { useHasSubstrateColor } from "@shared/features/Product";
import { checkForProcessing, useDecorationTechnology, waitForProcessing } from "@utilities";
import { useState, useEffect } from "react";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";

const WAIT_FOR_EMBROIDERY_IMAGE_PROCESSING_TIMEOUT = 60000;

export const useProcessingEmbroideryImage = () => {
    const designer = useDesigner();
    const hasSubstrateColors = useHasSubstrateColor();
    const decorationTechnology = useDecorationTechnology();
    const isEmbroidery = decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY;

    const [processingEmbroideryImage, setProcessingEmbroideryImage] = useState(false);
    /* wait for embroidery image to process in order to hide product color button and prevent bug */
    useEffect(() => {
        const embroideryImageAdded = (eventData: EventData) => {
            if (eventData.items[0].itemType === ItemTypes.IMAGE) {
                setProcessingEmbroideryImage(true);
            }
        };
        if (designer && hasSubstrateColors && isEmbroidery) {
            designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, embroideryImageAdded);
        }
    }, [designer, hasSubstrateColors, isEmbroidery]);

    useEffect(() => {
        if (processingEmbroideryImage && hasSubstrateColors && isEmbroidery) {
            const waitForImageProcessing = async () => {
                if (await checkForProcessing()) {
                    await waitForProcessing(WAIT_FOR_EMBROIDERY_IMAGE_PROCESSING_TIMEOUT);
                }
                setProcessingEmbroideryImage(false);
            };
            waitForImageProcessing();
        }
    }, [hasSubstrateColors, isEmbroidery, processingEmbroideryImage]);

    return processingEmbroideryImage;
};
