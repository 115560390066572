import React, { FC, ReactNode, useCallback, useEffect } from "react";
import classNames from "classnames";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { Button } from "@shared/features/StudioChrome";
import { STUDIO_TRACKING_EVENTS, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";

type Props = {
    contentType: DialogType;
    disabled?: boolean;
    onClick?: () => void;
    dataTestId?: string;
    children: ReactNode | ReactNode[];
    renderEventDetail?: string;
    clickEventType?: string;
};

export const MobileSidebarButton: FC<Props> = props => {
    const {
        children,
        contentType,
        disabled,
        dataTestId,
        onClick: onButtonClick,
        renderEventDetail,
        clickEventType
    } = props;
    const { trackEvent } = useTrackEvents();
    const { setCurrentActiveDialog } = useActiveDialog();

    const onClick = useCallback(() => {
        trackEvent({
            eventDetail: `${STUDIO_TRACKING_EVENTS.CLICK_SIDEBAR_BUTTON}${clickEventType || contentType}`,
            label: `click mobile sidebar ${clickEventType || contentType} button`
        });
        setCurrentActiveDialog(contentType);
        onButtonClick && onButtonClick();
    }, [trackEvent, clickEventType, contentType, setCurrentActiveDialog, onButtonClick]);

    useEffect(() => {
        if (renderEventDetail) {
            fireDesignToolTrackingEvent({
                eventDetail: renderEventDetail,
                label: `show mobile sidebar ${clickEventType || contentType} button`
            });
        }
    }, [clickEventType, contentType, renderEventDetail]);

    return (
        <Button
            className={classNames("mobile-sidebar-button", disabled ? "mobile-sidebar-button-disabled" : "")}
            onClick={() => onClick()}
            disabled={disabled}
            data-testid={dataTestId}
        >
            {children}
        </Button>
    );
};

MobileSidebarButton.displayName = "MobileSidebarButton";
