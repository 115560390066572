import React, { useEffect, useState } from "react";
import { useSelection } from "@designer-suite";
import { useAppSelector } from "@shared/redux";
import { useCookie } from "@shared/features/WebBrowser";
import { OnboardingToastBox } from "@shared/features/SystemMessages/";
import { useStudioLayout } from "@shared/features/ResponsiveDesign/hooks/useStudioLayout";
import { useClientRect } from "@shared/features/ResponsiveDesign";
import { useLayoutManager } from "../StudioLayout/LayoutManager";
import "./toast.scss";

const COOKIE_NAME = "studio-onboarding-toast-seen";

export function OnboardingToast() {
    const { topRect, canvasRef } = useLayoutManager();
    const canvasRect = useClientRect(canvasRef);
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const [renderOnCanvas, setRenderOnCanvas] = useState(true);
    const { cookieValue, setCookieValue } = useCookie(COOKIE_NAME, () => ({
        expires: 21
    }));
    const [show, setShow] = useState(cookieValue !== "true");

    const selection = useSelection();
    const { isSmall } = useStudioLayout();

    const centerX = canvasRect.x + canvasRect.width / 2;
    const centerY = canvasRect.y + canvasRect.height / 2 - topRect.height;

    useEffect(() => {
        if (selection.length) {
            setRenderOnCanvas(false);
        }
    }, [selection]);

    useEffect(() => {
        if (easelLoaded) {
            setCookieValue("true");
        }
    }, [easelLoaded, setCookieValue]);

    useEffect(() => {
        let returnFn;
        if (show && easelLoaded) {
            const timer = setTimeout(() => {
                setRenderOnCanvas(false);
            }, 10000);
            returnFn = () => clearTimeout(timer);
        }
        return returnFn;
    }, [show, easelLoaded]);

    useEffect(() => {
        let returnFn;
        if (!renderOnCanvas) {
            const timer = setTimeout(() => {
                setShow(false);
            }, 500);
            returnFn = () => clearTimeout(timer);
        }
        return returnFn;
    }, [renderOnCanvas]);

    if (isSmall || !easelLoaded) {
        return null;
    }

    return <OnboardingToastBox show={show} render={renderOnCanvas} centerX={centerX} centerY={centerY} />;
}
OnboardingToast.displayName = "OnboardingToast";
