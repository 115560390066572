import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, FlexBox, AlertBox, className } from "@vp/swan";
import * as styles from "./OnboardingToastBox.module.scss";

const messages = defineMessages({
    onLoadMessage: {
        id: "studio.components.Toast.onLoadMessage",
        defaultMessage: "Click on any element to start editing",
        description: {
            note: "label for the message on the tool bar info toast"
        }
    },
    closeButton: {
        id: "studio.components.Toast.contextualToolbarToastCloseButton",
        defaultMessage: "Close contextual toolbar message",
        description: {
            note: "message for close button on the tool bar info toast"
        }
    }
});

type Props = {
    show: boolean;
    render: boolean;
    centerX: number;
    centerY: number;
};

export function OnboardingToastBox({ show, render, centerX, centerY }: Props) {
    const { t } = useTranslationSSR();
    return (
        <>
            {show ? (
                <div
                    className={className("onboarding-toast-container", styles.onboardingToastContainer)}
                    style={
                        render
                            ? { transform: `translate(${centerX}px, ${centerY}px)` }
                            : { transform: `translate(50%, 0)` }
                    }
                >
                    <FlexBox
                        alignItems="center"
                        className={classNames(className("onboarding-toast", styles.onboardingToast), {
                            "onboarding-toast-fadeout": !render
                        })}
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div
                            className={classNames("onboarding-toast-circle", {
                                "onboarding-toast__circle-fadeout": !render
                            })}
                        />
                        <AlertBox marginLeft={2} className={classNames({ "studio-alert-box-fadeout": !render })}>
                            {t(messages.onLoadMessage.id)}
                        </AlertBox>
                    </FlexBox>
                </div>
            ) : (
                <Typography className={"canvas-instructional-message"} style={{ left: "50%" }}>
                    {t(messages.onLoadMessage.id)}
                </Typography>
            )}
        </>
    );
}

OnboardingToastBox.displayName = "OnboardingToastBox";
