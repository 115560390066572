import { UnitlessDimensions, getImageAsPromise } from "@design-stack-ct/utility-core";
import { AssetStatusType, VistaAsset } from "@design-stack-vista/vista-assets-sdk";

export function getUploadDimensions(upload: VistaAsset): UnitlessDimensions {
    const { data } = upload;

    // This is a duplicate check (also caught in validateAssetAndRetrieveDimensions) just to make TS happy with us retrieving dimensions below
    if (data?.info?.image?.format === "unknown") {
        throw new Error("Asset has unknown format");
    }

    let width = data?.properties.printPixelWidth as number;
    if (data?.info?.image?.format !== "pdf" && !width) {
        width = data?.info?.image?.width || 0;
    }

    let height = data?.properties.printPixelHeight as number;
    if (data?.info?.image?.format !== "pdf" && !height) {
        height = data?.info?.image?.height || 0;
    }

    return { width, height };
}

export async function validateAssetAndRetrieveDimensions(
    asset: VistaAsset,
    pageNumber: number = 0
): Promise<UnitlessDimensions> {
    if (asset.status.type !== AssetStatusType.Prepared) {
        throw new Error("Asset not prepared");
    }

    if (!asset.data?.info?.image) {
        throw new Error("Asset ImageInfo not available");
    }

    if (asset.data?.info?.image?.format === "unknown") {
        throw new Error("Asset has unknown format");
    }

    await asset.presign();

    let dimensions: UnitlessDimensions;
    if (asset.data?.info?.image?.format === "pdf") {
        const info = await asset.getPageImageInfo({ pageNo: pageNumber });
        if (info.isError) {
            throw new Error(`Page in error for asset: ${info.error || "unknown"}`);
        }
        const { width, height } = info as UnitlessDimensions;
        dimensions = { width, height };
    } else {
        if (asset.data?.info.image?.isError) {
            // @ts-expect-error error exists on the object (or can) but the types are wrong
            throw new Error(asset.data.info.image.error || "Unknown Error");
        }
        dimensions = getUploadDimensions(asset);
    }

    // This is to stop any bad documents from getting created if preview generation were to fail
    // ex. nonembedded fonts pdf
    await getImageAsPromise(asset.webPreview.getUrl()).catch(e => {
        throw new Error("Unable to retrieve image from asset webPreview Url");
    });

    if (window.isNaN(dimensions.height) || window.isNaN(dimensions.width) || !dimensions.width || !dimensions.height) {
        throw new Error("Unable to retrieve valid height/width for asset");
    }

    return dimensions;
}
