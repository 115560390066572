export const COLOR_PICKER_PANEL_TYPES = {
    BACKGROUND_COLOR: "backgroundColor",
    TEXT_COLOR: "textColor",
    SHAPE_FILL_COLOR: "shapeFillColor",
    SHAPE_OUTLINE_COLOR: "shapeOutlineColor",
    TABLE_BACKGROUND_COLOR: "tableBackgroundColor",
    TABLE_TEXT_COLOR: "tableTextColor",
    ICON_COLOR: "iconColor",
    TEAMS_TEXT_COLOR: "teamsTextColor"
};
