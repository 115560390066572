import { useLayoutEffect } from "react";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { PlacementStrategy } from "../PanelComponents/Uploads/UploadStrategies";
import { useHandleImagePlacement } from "./useHandleImagePlacement";
import type { Upload } from "../@types/upload";

interface UploadCompletionBehavior {
    placementStrategy?: PlacementStrategy;
    /** Optional callback to be executed on completion of onUpload */
    onUploadAdded?: () => void;
    ignoreUploads: boolean;
    isUploadModalAutoLoaded?: boolean;
}

/**
 * A hook that provides the ability to interact with an upload once the upload has been processed
 * @returns void
 */
export function useUploadCompletionEvent({
    ignoreUploads,
    isUploadModalAutoLoaded,
    placementStrategy,
    onUploadAdded
}: UploadCompletionBehavior) {
    const { onImageUploaded } = useHandleImagePlacement({
        placementStrategy,
        onUploadAdded
    });

    const designer = useDesigner();
    const canvas = useActiveCanvas();
    useLayoutEffect(() => {
        if (!designer || ignoreUploads) {
            return;
        }
        function placeNewUpload(upload: Upload) {
            // This is to appease the capricious typescript gods who don't understand scope as well as we do.
            if (!designer || ignoreUploads) {
                return;
            }
            onImageUploaded({ upload, page: upload.get("pages").models[0] });
        }

        // If we get an error, just do what we do when we have an upload added
        function onError({ type }: { type: string }) {
            if (type === "error" && onUploadAdded) {
                onUploadAdded();
            }
        }

        function onUploadComplete() {
            isUploadModalAutoLoaded &&
                canvas &&
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.UPLOAD_COMPLETE.replace("[CanvasSide]", canvas.name),
                    label: "General"
                } as any);
        }

        designer.eventBus.listenTo(
            designer.eventBus,
            designer.eventBus.events.uploadProcessingComplete,
            placeNewUpload
        );

        designer.eventBus.on(designer.eventBus.events.createToastNotification, onError);

        designer.eventBus.on(designer.eventBus.events.uploadComplete, onUploadComplete);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.stopListening(
                designer.eventBus,
                designer.eventBus.events.uploadProcessingComplete,
                placeNewUpload
            );
            designer.eventBus.off(designer.eventBus.events.createToastNotification, onError);
            designer.eventBus.off(designer.eventBus.events.uploadComplete, onUploadComplete);
        };
    }, [designer, onUploadAdded, ignoreUploads, onImageUploaded, isUploadModalAutoLoaded, canvas]);
}
