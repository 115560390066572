import { useDesigner } from "@designer-suite";
import throttle from "lodash/throttle";
import memoize from "lodash/memoize";
import { useEffect } from "react";
import { useAppDispatch, autoClosableAlert, setAlerts, AlertSkin } from "@shared/redux";

type alertSeverity = "error" | "warning" | "information" | "success";
/**
 *
 * @param alertSeverity
 *   error: 'error',
 *   warning: 'warning',
 *   information: 'information',
 *   success: 'success',
 */
const mapAlertSeverityToSkin = (alertSeverity: alertSeverity): AlertSkin => {
    switch (alertSeverity) {
        case "error":
            return "error";
        case "warning":
            return "warning";
        case "information":
            return "standard";
        case "success":
            return "positive";
        default:
            return "standard";
    }
};

export const usePipeDesignerToastMessages = () => {
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!designer) {
            return () => {};
        }
        const forwardToast = (toast: any) => {
            let toastMessage = toast.message;
            if (toastMessage.el) {
                toastMessage = toastMessage.el.innerText;
            }
            const forwardFunction = memoizedToast({ ...toast, message: toastMessage });
            forwardFunction();
        };
        /**
         * memoize so that the debounce happens per toast message/type combination.  We don't need 500 toasts of the same thing.
         * This multi toast situation happens with multiple page pdfs each throwing a failure message.
         */
        const memoizedToast = memoize(
            toast => {
                return throttle(
                    () => {
                        if (toast.type !== "error") {
                            dispatch(autoClosableAlert());
                        }
                        dispatch(
                            setAlerts({
                                alerts: [
                                    {
                                        key: `${toast.message}`,
                                        skin: mapAlertSeverityToSkin(toast.type),
                                        message: toast.message
                                    }
                                ]
                            })
                        );
                    },
                    3000,
                    { trailing: false }
                );
            },
            toast => `${toast.message}${toast.type}`
        );
        designer.eventBus.on(designer.eventBus.events.createToastNotification, forwardToast);

        return () => {
            designer.eventBus.off(designer.eventBus.events.createToastNotification, forwardToast);
        };
    }, [designer, dispatch]);
};
