// When making changes to this file, make sure to update the studio error codes mapping page on confluence
// https://vistaprint.atlassian.net/wiki/spaces/DTT/pages/83001969/Error+Code+Mappings+in+Studio

const STUDIO_LIVE_ENTITY_CODE = 50;

const studioLiveErrorCodes = {
    START_CHAT: 211,
    START_STUDIO_LIVE_SESSION: 212,
    SIGN_INTO_MULTIPLAYER: 213,
    END_STUDIO_LIVE_SESSION: 214,
    SYNC_STUDIO_LIVE_STATE: 215,
    BROADCAST_CANVAS_CHANGE: 216,
    NOT_AUTHORIZED_TO_JOIN_SESSION: 217,
    PUSH_WORK_REVISION: 218,
    APPLY_WORK_REVISION: 219,
    SUBSCRIBE_DESIGNER_CHANGES: 220,
    SEND_DESIGNER_EVENT: 221,
    CHECK_IF_STUDIO_LIVE_SESSION_EXISTS: 222,
    CHECK_IF_AUTHORIZED_FOR_STUDIO_LIVE_SESSION: 223,
    APPLY_DESIGNER_CHANGES: 224
};

const topLevelStudioLiveErrorCodes = {
    START_STUDIO_LIVE: 225,
    STUDIO_LIVE_SESSION: 226,
    END_STUDIO_LIVE: 227,
    CREATE_STUDIO_LIVE_SESSION: 228,
    JOIN_STUDIO_LIVE_SESSION: 229
};

export { studioLiveErrorCodes, topLevelStudioLiveErrorCodes, STUDIO_LIVE_ENTITY_CODE };
