import { useCallback } from "react";
import {
    getMinMaxFontSizeInterval,
    multipleFontSizeExist,
    truncatedFontSize,
    useActiveCanvas,
    useDesigner,
    useFonts
} from "@designer-suite";
import { useDecorationTechnology } from "@utilities";

function getFontSizeValue(selectedFontSizes: number[], differentFontSizesPresent: boolean): number {
    const selectedFontSize = differentFontSizesPresent ? Math.min(...selectedFontSizes) : selectedFontSizes[0];
    const fontSize = truncatedFontSize(selectedFontSize);

    return fontSize;
}

export function useRichTextSet() {
    const designer = useDesigner();
    const fonts = useFonts();
    const decorationTechnology = useDecorationTechnology();
    const canvas = useActiveCanvas();

    const setWithSideEffects = useCallback(
        (viewModels: ItemViewModel[], attributeName: string, value: string | number | boolean) => {
            if (!designer) {
                return;
            }
            const viewModelFontSizes = designer.richTextManager.getAllAttributes(viewModels).fontSize || [];

            const defaultFontConfig = designer?.clients?.font?.fontSizeConfig[decorationTechnology];
            const differentFontSizesPresent = multipleFontSizeExist(viewModelFontSizes);
            if (attributeName === "fontFamily") {
                if (!canvas || !defaultFontConfig) {
                    return;
                }
                const fontSize = getFontSizeValue(viewModelFontSizes, differentFontSizesPresent);
                const { minLimit, maxLimit } = getMinMaxFontSizeInterval(
                    designer,
                    canvas,
                    defaultFontConfig,
                    fonts,
                    value as string
                );
                // Note that this is only missed when designer does not have an activeRichTextEditor
                // this is due to our slightly unorthidox use of richTextEditors
                // this update creates an additional history entry.  Due to that it is important it come FIRST to prevent
                // a user from undoing important business logic
                // while there is a potential overlap case that would affect this in the inverse, none of the data currently allows that
                // It is important to consider the atomicity of any side effects and if necessary implement control over history to merge changes
                // into a singular history entry
                if (fontSize < minLimit || fontSize > maxLimit) {
                    designer.richTextManager.setAttribute(
                        viewModels,
                        "fontSize",
                        Math.min(maxLimit, Math.max(fontSize, minLimit))
                    );
                }
            }
            designer.richTextManager.setAttribute(viewModels, attributeName, value);
        },
        [canvas, decorationTechnology, designer, fonts]
    );

    return {
        setWithSideEffects
    };
}
