import { useDesigner } from "@designer-suite";
import { DesignRequirementsProvider } from "@shared/features/Product";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

export function Studio5DesignRequirementsProvider({ children }: { children: ReactNode | ReactNode[] }) {
    const designer = useDesigner();
    const [canvases, setCanvases] = useState<Canvas[]>();

    useEffect(() => {
        if (!designer) return;
        const updateCanvas = () => {
            if (designer.api.design.canvases.length > 0) {
                setCanvases(designer.api.design.canvases);
            }
        };

        designer.eventBus.on(designer.eventBus.events.productLoaded, updateCanvas);
        updateCanvas();

        /* eslint-disable consistent-return */
        return () => {
            designer.eventBus.off(designer.eventBus.events.productLoaded, updateCanvas);
        };
    }, [designer]);

    const studioDesignRequirements = useMemo(() => {
        if (!canvases) return { panels: [] };
        return {
            panels: canvases.map(canvas => ({
                id: canvas._canvasViewModel.model.get("id"),
                name: canvas.name,
                width: canvas.mmDimensions.width,
                height: canvas.mmDimensions.height,
                decorationTechnology: canvas.processType,
                locationName: canvas._canvasViewModel.model.get("location"),
                colorMode: canvas._canvasViewModel.model.get("colorMode"),
                availablePremiumFinishes:
                    canvas._canvasViewModel
                        .get("availablePremiumFinishes")
                        .map((finish: { color: string }) => finish.color) ?? []
            }))
        };
    }, [canvases]);

    return (
        <DesignRequirementsProvider studioDesignRequirements={studioDesignRequirements}>
            {children}
        </DesignRequirementsProvider>
    );
}

Studio5DesignRequirementsProvider.displayName = "Studio5DesignRequirementsProvider";
