import React, { HTMLProps, useRef, useState, useLayoutEffect, MutableRefObject, forwardRef } from "react";
import classNames from "classnames";
import * as styles from "./HorizontalScroller.module.scss";

interface HorizontalScrollerProps extends HTMLProps<HTMLDivElement> {
    isDarkToolbar?: boolean;
}

// This is basically a scrollable div with indicators when scrolling is possible
// This is similar to the SheetHorizontalScroller except that component includes buttons which allow scrolling (like a carousel)
export const HorizontalScroller = forwardRef(
    (
        { children, className, isDarkToolbar = false, ...restOfProps }: HorizontalScrollerProps,
        ref: MutableRefObject<HTMLDivElement>
    ) => {
        const containerRef = useRef<HTMLDivElement | null>(null);
        const [showLeft, setShowLeft] = useState<boolean>(false);
        const [showRight, setShowRight] = useState<boolean>(false);

        // we're storing these widths to state instead of relying on refs because it was causing some issues
        // when changing between selected items. re-mounting didn't always pick up the updated refs.
        const [containerWidth, setContainerWidth] = useState<number>(0);
        const [contentsWidth, setContentsWidth] = useState<number>(0);

        useLayoutEffect(() => {
            // on load determine if there's enough content to show the right scroll button
            setShowRight(containerWidth < contentsWidth);
        }, [containerWidth, contentsWidth]);

        const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
            setShowLeft(event.currentTarget.scrollLeft > 0);
            setShowRight(event.currentTarget.scrollLeft + event.currentTarget.clientWidth < contentsWidth);
        };

        // this isn't inlined so that containerRef is mutable without casting it.
        const setContainerRef = (current: HTMLDivElement | null) => {
            containerRef.current = current;
            setContainerWidth(current?.clientWidth || 0);
            if (ref && current) {
                // eslint-disable-next-line no-param-reassign
                ref.current = current;
            }
        };

        return (
            <div
                ref={setContainerRef}
                className={classNames("simple-horizontal-scroller-container", styles.scrollerContainer, className)}
                onScroll={onScroll}
            >
                <div
                    className={classNames(
                        "horizontal-scroll-indicator",
                        "left-horizontal-scroll-indicator",
                        styles.scrollIndicator,
                        styles.leftScrollIndicator,
                        {
                            [styles.darkToolbarScrollIndicator]: isDarkToolbar
                        },
                        {
                            [styles.leftDarkToolbarScrollIndicator]: isDarkToolbar
                        },
                        {
                            "horizontal-scroll-show": showLeft,
                            [styles.shouldShow]: showLeft
                        }
                    )}
                ></div>
                <div
                    className={classNames(
                        "horizontal-scroll-indicator",
                        "right-horizontal-scroll-indicator",
                        styles.scrollIndicator,
                        styles.rightScrollIndicator,
                        {
                            [styles.darkToolbarScrollIndicator]: isDarkToolbar
                        },
                        {
                            "horizontal-scroll-show": showRight,
                            [styles.shouldShow]: showRight
                        }
                    )}
                ></div>
                <div
                    ref={current => {
                        setContentsWidth(current?.scrollWidth || 0);
                    }}
                    className={"simple-horizontal-scroller"}
                    data-dcl-prevent-canvas-items-deselection
                    {...restOfProps}
                >
                    {children}
                </div>
            </div>
        );
    }
);

HorizontalScroller.displayName = "SimpleHorizontalScroller";
