import React, { useEffect, useState } from "react";
import { Typography, Link, AlertBoxDismissButton, AlertBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { newRelicWrapper } from "@shared/utils/Errors";
import { getStudioUniqueSessionId } from "@shared/utils/Tracking";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { useAppSelector, useAppDispatch, setError } from "@shared/redux";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./Toast.module.scss";

const messages = defineMessages({
    toastErrorMessage: {
        id: "studio.components.Toast.toastErrorMessage",
        defaultMessage: "Sorry - something went wrong. Please refresh your browser. If you need assistance, "
    },
    toastErrorCode: {
        id: "studio.components.Toast.toastErrorCode",
        defaultMessage: "Error Code: [[errorCode]]"
    },
    contactUs: {
        id: "studio.components.Toast.contactUs",
        defaultMessage: "give us a call",
        description: {
            note: "The 'g' in give us a call is intentionally lowercase. This bit of text is meant to be a link that will be added inside other sentences."
        }
    },
    closeButton: {
        id: "studio.components.Toast.closeButton",
        defaultMessage: "Close error message",
        description: {
            note: "Accessibility label for the close button on the error toast"
        }
    }
});

export function ErrorToast() {
    const { t } = useTranslationSSR();
    const { careUrl } = usePageContext();
    const errorMessage = useAppSelector(state => state.errorMessage);
    const errorCode = useAppSelector(state => state.errorCode);
    const showErrorPage = useAppSelector(state => state.showErrorPage);
    const hideErrorToast = useAppSelector(state => state.hideErrorToast);
    const dispatch = useAppDispatch();
    const [render, setRender] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (errorCode && !showErrorPage && !hideErrorToast) {
            setShow(true);
            setRender(true);
            newRelicWrapper.logPageAction("studio-toast", { errorCode, errorMessage });
        }
    }, [errorCode, errorMessage, showErrorPage, hideErrorToast]);

    const onAnimationEnd = () => {
        if (!render) {
            dispatch(setError({}));
            setShow(false);
        }
    };

    if (showErrorPage || !show) {
        return null;
    }

    return (
        <AlertBox
            skin="error"
            dismissed={!show}
            onRequestDismiss={() => setRender(false)}
            onAnimationEnd={onAnimationEnd}
            className={render ? styles.fadeIn : styles.fadeOut}
            data-testid="errorToast"
        >
            <div className={styles.toastMessageContainer}>
                <Typography>
                    {t(messages.toastErrorMessage.id)}
                    <Link href={careUrl}>{t(messages.contactUs.id)}</Link>.
                </Typography>
                {errorCode && (
                    <Typography mt={2}>
                        {`${t(messages.toastErrorCode.id, { errorCode })}    `}
                        {`S:${getStudioUniqueSessionId()}`}
                    </Typography>
                )}
            </div>
            <AlertBoxDismissButton visuallyHiddenLabel={t(messages.closeButton.id)} />
        </AlertBox>
    );
}
ErrorToast.displayName = "ErrorToast";
