import React from "react";
import classNames from "classnames";
import { Box, Divider, BoxProps } from "@vp/swan";
import { CSSTransition } from "react-transition-group";
import { RedoButton, UndoButton } from "@shared/features/DesignTools";
import * as styles from "./HistoryToolsSmallScreen.module.scss";

type HistoryToolsSmallScreenProps = BoxProps & {
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    transitionIn: boolean;
    className?: string;
};

export const HistoryToolsSmallScreen = ({
    undo,
    redo,
    canUndo,
    canRedo,
    transitionIn,
    className,
    ...rest
}: HistoryToolsSmallScreenProps) => {
    return (
        <CSSTransition
            in={transitionIn}
            timeout={200}
            classNames={{
                enterActive: styles.historyToolsSmallScreenEnterActive
            }}
        >
            <Box
                className={classNames(styles.undoRedoButtonBar, className, {
                    [styles.undoOnly]: !canRedo,
                    [styles.hidden]: !canRedo && !canUndo
                })}
                {...rest}
                data-dcl-prevent-canvas-items-deselection
            >
                <UndoButton className={styles.undoButton} onClick={undo} disabled={!canUndo} showLabel={false} />

                <Divider
                    className={classNames(styles.buttonBarDivider, { [styles.buttonBarDividerUndoOnly]: !canRedo })}
                />

                <RedoButton className={styles.redoButton} onClick={redo} disabled={!canRedo} showLabel={false} />
            </Box>
        </CSSTransition>
    );
};

HistoryToolsSmallScreen.displayName = "HistoryToolsSmallScreen";
