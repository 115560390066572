import React from "react";
import { Typography, Column, GridContainer, Row, BasicResponsiveImage } from "@vp/swan";
import { useOnKeyDown } from "@shared/features/Accessibility";
import { withPrefix } from "gatsby";

export interface TeamsPlaceholderButtonConfig {
    buttonTitle: string;
    buttonAccessibilityContent: string;
    buttonDetails: string;
    buttonImageSrc: string;
    placeholderKey: string;
    placeholderContent: string;
    placeholderType: TeamsPlaceholderType;
    trackingEvent: string;
}

interface Props {
    /** Team button config */
    buttonConfig: TeamsPlaceholderButtonConfig;
    onClick: () => void;
}

export function TeamsPlaceholderButton({ buttonConfig, onClick }: Props) {
    return (
        <GridContainer
            onClick={onClick}
            onKeyDown={useOnKeyDown(onClick)}
            role="button"
            tabIndex={0}
            title={buttonConfig.buttonTitle}
            aria-label={buttonConfig.buttonAccessibilityContent}
            key={buttonConfig.buttonTitle}
        >
            <Row>
                <Column span={4} spanXs={4} padding={0} className="teams-buttons-icon">
                    <BasicResponsiveImage
                        src={`${ASSET_PREFIX + withPrefix(buttonConfig.buttonImageSrc)}`}
                        alt={buttonConfig.buttonAccessibilityContent}
                        aspectRatio={0.8}
                    />
                </Column>
                <Column span={8} spanXs={8} paddingLeft={5} paddingRight={3} paddingTop={1}>
                    <Row>
                        <Typography textAlign="left" fontSize="xm2" fontWeight="bold" pt={1}>
                            {buttonConfig.buttonTitle}
                        </Typography>
                    </Row>
                    <Row>
                        <Typography textAlign="left" fontSize="xm2" textColor="dark-grey" pt={1}>
                            {buttonConfig.buttonDetails}
                        </Typography>
                    </Row>
                </Column>
            </Row>
        </GridContainer>
    );
}

TeamsPlaceholderButton.displayName = "TeamsPlaceholderButton";
