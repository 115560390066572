import { useState, useEffect } from "react";
import { addModelsToSelection } from "../util";
import { useDesigner } from "./DesignerProvider";
import { useSelection } from "./useSelection";

/**
 * Listens to the richTextManager for changes to a specific attribute and updates when necessary
 * @param type
 * @param attribute
 * @param defaultValue
 */
export function useRichTextAttribute<T>(type: "common" | "all", attribute: string, defaultValue: T) {
    const designer = useDesigner();
    const selection = useSelection("model:change:content item:focus");

    const [attributeValue, setAttributeValue] = useState<T>(defaultValue);

    useEffect(() => {
        if (!designer || !attribute || !selection.length) {
            return undefined;
        }

        const { richTextManager } = designer;

        function updateAttributeValue() {
            const textItems = selection.filter(item => item.itemType === "TEXT");
            const models = addModelsToSelection(textItems);
            const currentValue =
                type === "common"
                    ? richTextManager.getCommonAttributes(models)[attribute]
                    : richTextManager.getAllAttributes(models)[attribute];

            setAttributeValue(currentValue || defaultValue);
        }

        const eventName = `change:${type}Attributes:${attribute}`;
        richTextManager.on(eventName, updateAttributeValue);
        richTextManager.on(`selection-change`, updateAttributeValue);

        // initially update the attribute value
        updateAttributeValue();

        return () => {
            richTextManager.off(eventName, updateAttributeValue);
            richTextManager.off(`selection-change`, updateAttributeValue);
        };
    }, [designer, type, attribute, selection, defaultValue]);

    return attributeValue;
}
