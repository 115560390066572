import React, { forwardRef, Ref } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { BreadcrumbList, BreadcrumbsContainer, Button, Icon } from "@vp/swan";
import { ElementsPanelContentTypes, useElementsPanel } from "./ElementsPanelProvider";
import { ElementsPanelTitleItem } from "./ElementsPanelTitleItem";
import * as styles from "./ElementsPanelTitle.module.scss";

const messages = defineMessages({
    elementsPanelContentBackButton: {
        id: "studio.features.elements.backButton",
        defaultMessage: "Back",
        description: {
            note: "Label for the back button in the elements panel"
        }
    }
});

export const ElementsPanelTitle = forwardRef((_props, ref: Ref<HTMLButtonElement>) => {
    const { t } = useTranslationSSR();
    const { stack, removeFromStack } = useElementsPanel();

    return (
        <BreadcrumbsContainer className={styles.breadcrumbs} mb={2}>
            {stack.length > 1 && (
                <Button
                    skin="unstyled"
                    mr={2}
                    pb={1}
                    className={styles.backButton}
                    aria-label={t(messages.elementsPanelContentBackButton.id)}
                    onClick={() => {
                        removeFromStack();
                    }}
                    ref={ref}
                >
                    <Icon iconType="chevronLeft" aria-hidden />
                </Button>
            )}
            <BreadcrumbList>
                {stack.map((panelType: ElementsPanelContentTypes) => {
                    return <ElementsPanelTitleItem key={panelType} panelType={panelType} />;
                })}
            </BreadcrumbList>
        </BreadcrumbsContainer>
    );
});

ElementsPanelTitle.displayName = "ElementsPanelTitle";
