import React, { ChangeEvent, useLayoutEffect, useRef, useState } from "react";

import { withPrefix } from "gatsby";

import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    SelectedCheckIcon,
    PanelContent,
    PanelDescription,
    StickyActionBar,
    RenameProjectIcon
} from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Button, FlexBox, SelectionSet, SelectionSetInput, SelectionSetLabel, TextArea } from "@vp/swan";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import {
    PF_INSTRUCTIONS_TYPE,
    FinishMessages,
    PremiumFinishSelectionSet,
    PremiumFinishSelectionOption
} from "@shared/features/PremiumFinish";
import { useAppSelector, useAppDispatch, patchReviewInstructions, setSaveStatus } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";
import * as styles from "./FullBleedPremiumFinishPanel.module.scss";

export interface FullBleedPremiumFinishPanelProps {
    finishType: string;
    panelId: string | undefined;
}

export function FullBleedPremiumFinishPanel({ finishType, panelId }: FullBleedPremiumFinishPanelProps) {
    const { isSmall } = useStudioLayout();
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();

    const reviewInstructions = useAppSelector(state => state.reviewInstructions);

    const panelFullBleedInstructions = reviewInstructions.find(
        instr => instr.instructionType === PF_INSTRUCTIONS_TYPE && instr.panelId === panelId
    ) ?? {
        panelId,
        instructionType: PF_INSTRUCTIONS_TYPE,
        finishOption: null,
        optionalNotes: ""
    };

    const [finishOption, setFinishOption] = useState(panelFullBleedInstructions?.finishOption);
    const [optionalNotes, setOptionalNotes] = useState(panelFullBleedInstructions?.optionalNotes);
    const [updateButtonEnabled, setUpdateButtonEnabled] = useState(false);
    const [showInstructions, setShowInstructions] = useState(!!finishOption);
    const [editingInstructions, setEditingInstructions] = useState(optionalNotes === "");

    const panelContentRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const stickyBarRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const textAreaMaxHeight = isSmall ? 96 : 108; // allow a maximum of 4 rows of text
        if (textareaRef.current) {
            textareaRef.current.style.height = "inherit";
            textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, textAreaMaxHeight)}px`;
        }
        if (isSmall && stickyBarRef.current && panelContentRef.current) {
            const stickyBarHeight = stickyBarRef.current.scrollHeight;
            panelContentRef.current.style.minHeight = `calc(100vh - 68px - ${stickyBarHeight}px)`; // 68px is the sheet header height
        }
    }, [optionalNotes, isSmall, editingInstructions, showInstructions]);

    const save = () => {
        setUpdateButtonEnabled(false);
        if (optionalNotes) setEditingInstructions(false);
        const updatedFullBleedInstructions = {
            ...panelFullBleedInstructions,
            finishOption,
            optionalNotes
        };

        dispatch(patchReviewInstructions(updatedFullBleedInstructions));
        dispatch(setSaveStatus(SAVE_STATUS.CAN_BE_SAVED));

        fireDesignToolTrackingEvent({
            eventDetail: "Fullbleed Premium Finish Used",
            // @ts-ignore
            label: "General",
            // @ts-ignore
            extraData: () => ({
                finishOption,
                optionalNotes
            })
        });
    };

    return (
        <>
            <PanelContent ref={panelContentRef} className={styles.container}>
                <Typography textSize={isSmall ? 3 : 4} fontWeight="bold" pb={3}>
                    {t(FinishMessages[`modalTitle${finishType}`].id)}
                </Typography>
                <PanelDescription>
                    <Typography mb={4} pb={2} fontSize="1" as="span">
                        {t(FinishMessages[`modalSubline${finishType}`].id)}
                    </Typography>
                </PanelDescription>
                <SelectionSet
                    variant="single-select"
                    selectedValue={finishOption}
                    onSelectedValueChange={newSelectedValue => {
                        setFinishOption(newSelectedValue);
                        setUpdateButtonEnabled(true);
                        setShowInstructions(true);
                    }}
                >
                    {PremiumFinishSelectionSet[finishType].map((userFinishOption: PremiumFinishSelectionOption) => (
                        <SelectionSetInput
                            value={userFinishOption.option}
                            key={userFinishOption.option}
                            aria-label={userFinishOption.option}
                        >
                            <SelectionSetLabel>
                                <div
                                    className={styles.imageWrapper}
                                    style={{
                                        // @ts-ignore
                                        backgroundImage: `url(${
                                            ASSET_PREFIX +
                                            withPrefix(`/fullBleedPremiumFinishModal/${userFinishOption.img}`)
                                        })`
                                    }}
                                ></div>
                                <div className={styles.textOption}>
                                    <FlexBox justifyContent="space-between" marginTop={1} marginRight={1}>
                                        <Typography fontWeight="bold" fontSize="1">
                                            {t(userFinishOption.title)}
                                        </Typography>
                                        <SelectedCheckIcon className={styles.icon} />
                                    </FlexBox>
                                    <Typography textColor="dark-grey" fontSize="-1">
                                        {t(userFinishOption.text)}
                                    </Typography>
                                </div>
                            </SelectionSetLabel>
                        </SelectionSetInput>
                    ))}
                </SelectionSet>
            </PanelContent>
            <StickyActionBar ref={stickyBarRef} className={styles.stickyBar}>
                {showInstructions ? (
                    <div className={styles.instructions}>
                        <FlexBox justifyContent="space-between" marginBottom={2} className={styles.label}>
                            <Typography fontWeight="bold">{t(FinishMessages.instructions.id)}</Typography>
                            {!editingInstructions && (
                                <Button
                                    skin="unstyled"
                                    className={styles.editButton}
                                    size="mini"
                                    onClick={() => {
                                        setEditingInstructions(true);
                                        setUpdateButtonEnabled(true);
                                    }}
                                >
                                    <RenameProjectIcon aria-hidden={true} />
                                    <Typography>{t(FinishMessages.instructionsEdit.id)}</Typography>
                                </Button>
                            )}
                        </FlexBox>
                        {editingInstructions ? (
                            <TextArea
                                ref={textareaRef}
                                className={styles.textArea}
                                rows={1}
                                value={optionalNotes}
                                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                    setOptionalNotes(event.target.value);
                                    setUpdateButtonEnabled(true);
                                }}
                                placeholder={t(FinishMessages.instructionsPlaceholder.id)}
                            />
                        ) : (
                            <Typography textColor="dark-grey" marginBottom={3} className={styles.instructionsText}>
                                {optionalNotes}
                            </Typography>
                        )}
                    </div>
                ) : null}
                <Button
                    skin="primary"
                    width="full-width"
                    size="mini"
                    marginTop={3}
                    onClick={save}
                    disabled={!updateButtonEnabled}
                >
                    {t(FinishMessages.cta.id)}
                </Button>
            </StickyActionBar>
        </>
    );
}
FullBleedPremiumFinishPanel.displayName = "FullBleedPremiumFinishPanel";
