import { useEffect } from "react";
import { useDesigner } from "@designer-suite";

/**
 * A hook that sets up a callback that prevents adding items to blank canvases
 * when the productLoaded event occurs.
 */
export function usePreventItemAdditionToBlankCanvas() {
    const designer = useDesigner();
    useEffect(() => {
        if (!designer) {
            return;
        }

        function restrictBlankCanvases(): void {
            if (designer) {
                designer.documentRepository.getAllCanvasViewModels().forEach(canvasViewModel => {
                    const canvas = canvasViewModel.model;
                    if (canvas) {
                        if (canvas.get("colorMode") === "blank") {
                            canvas.set({ addRestrict: true });
                        } else {
                            canvas.set({ addRestrict: false });
                        }
                    }
                });
            }
        }

        restrictBlankCanvases();
        designer.eventBus.on(designer.eventBus.events.productLoaded, restrictBlankCanvases);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(designer.eventBus.events.productLoaded, restrictBlankCanvases);
        };
    }, [designer]);
}
