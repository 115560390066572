import React from "react";

export function MoreOptionsIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 13 3" fill="currentColor" {...props}>
            <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
            <circle cx="6.5" cy="1.5" r="1.5" fill="currentColor" />
            <circle cx="11.5" cy="1.5" r="1.5" fill="currentColor" />
        </svg>
    );
}
MoreOptionsIcon.displayName = "MoreOptionsIcon";
