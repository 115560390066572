export function mapFontSize(contents: any[]) {
    contents.forEach((item: any) => {
        if (item.fontSize) {
            // eslint-disable-next-line no-param-reassign
            item.fontSize = parseFloat(item.fontSize);
        }
        if (item.type === "list" || item.type === "listItem") {
            mapFontSize(item.content);
        }
    });
    return contents;
}
