import { useEffect } from "react";
import { useDesigner } from "@designer-suite";
import { cropImage } from "../../ToolbarComponents/crop/cropImageCommand";
import type { Crop, CropAttributes } from "../../@types/imagesCrop";

/**
 * A hook that sets up a callback that unlocks a replacement image for a placeholder
 * when the item:replaceimage event occurs.
 */
export function useCropOverride() {
    const designer = useDesigner();

    useEffect(() => {
        if (designer) {
            const easelCropImage = designer.commands.cropImage.extend({
                apply({
                    crop,
                    viewModel,
                    lockAspectRatio,
                    attributes
                }: {
                    crop: Crop;
                    viewModel: ItemViewModel;
                    attributes: CropAttributes;
                    lockAspectRatio: boolean;
                }) {
                    cropImage(viewModel, crop, lockAspectRatio, attributes, designer);
                }
            });
            designer.commandDispatcher.subscribe({ easelCropImage });
        }
    }, [designer]);
}
