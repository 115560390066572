import classNames from "classnames";
import React from "react";
import { Slider, BasicSliderProps } from "@shared/features/ColorPicker";

export interface HueColorSliderProps extends BasicSliderProps {
    value: number;
}

export function HueColorSlider(props: HueColorSliderProps) {
    const { className, ...rest } = props;

    return (
        <Slider
            className={classNames(className, "hue-color-slider")}
            max={360}
            barStyle={{
                background: `linear-gradient(to right, #f00 0%, #ff0 16.666%, #0f0 33.333%, #0ff 50%, #00f 66.666%, #f0f 83.333%, #f00 100%)`
            }}
            startSwatchColor="#f00"
            endSwatchColor="#f00"
            {...rest}
        />
    );
}

HueColorSlider.displayName = "HueColorSlider";
