import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Link, Button } from "@vp/swan";
import { useTrackEvents } from "@shared/features/Tracking";
import { useAppDispatch, setShowTeamsGuideModal } from "@shared/redux";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { PanelContent, PanelDescription, PanelTitle } from "@shared/features/StudioChrome";
import { TeamsPlaceholderButtons } from "./Redesign/TeamsPlaceholderButtons";
import { TeamsGuideModal } from "../Modals/TeamsGuideModal";
import "./groupsAndTeamsPanel.scss";

const messages = defineMessages({
    teamsPanelTitle: {
        id: "studio.ui.component.teamsPanel.title",
        defaultMessage: "Make each one unique!",
        description: {
            note: "Title for the groups and teams panel"
        }
    },
    teamsPanelInfo: {
        id: "studio.ui.component.teamsPanel.info",
        defaultMessage:
            "Pick a placeholder and adjust its style, color, and position. In the next step you will add details for each person!",
        description: {
            note: "Information for the groups and teams panel"
        }
    },
    teamsPanelInstruction: {
        id: "studio.ui.component.teamsPanel.instruction",
        defaultMessage: "How to use placeholders",
        description: {
            note: "Instructions link to open pop-up modal for teams experience tutorial"
        }
    }
});

export function GroupsAndTeamsPanel() {
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();

    const openTeamsGuideModal = () => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.SHOW_TEAMS_EXPERIENCE_GUIDE_MODAL });
        dispatch(setShowTeamsGuideModal(true));
    };

    return (
        <PanelContent className="studio-groups-and-teams-panel-content">
            <PanelTitle>{t(messages.teamsPanelTitle.id)}</PanelTitle>
            <PanelDescription>{t(messages.teamsPanelInfo.id)}</PanelDescription>
            <TeamsPlaceholderButtons />
            <Link
                skin="cta"
                style={{ marginTop: "10px" }}
                render={p => (
                    <Button skin="link" className={p.className} style={p.style} onClick={openTeamsGuideModal}>
                        {p.children}
                    </Button>
                )}
            >
                {t(messages.teamsPanelInstruction.id)}
            </Link>
            <TeamsGuideModal />
        </PanelContent>
    );
}
GroupsAndTeamsPanel.displayName = "GroupsAndTeamsPanel";
