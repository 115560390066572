import { useEffect } from "react";
import { useDesigner } from "@designer-suite";
import { isInViewport } from "@utilities";

/**
 * A hook that scrolls to the first item added to canvas whenever new items are added to canvas
 */
export function useScrollToItemAdded() {
    const designer = useDesigner();

    useEffect(() => {
        let unsubscribeAdd: () => void;
        if (designer) {
            const scrollToItem = (event: EventData) => {
                const { items } = event;
                // Only scroll to the first item so the screen doesn't bounce around
                const id = `${items[0].id}-handle`;
                const element = document.getElementById(id);
                if (element && !isInViewport(element)) {
                    element.scrollIntoView();
                }
            };

            unsubscribeAdd = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, scrollToItem);
        }
        return () => {
            unsubscribeAdd && unsubscribeAdd();
        };
    }, [designer]);
}
