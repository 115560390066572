import React, { useRef } from "react";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useBeforeAfterHydrationCheck, useStudioLayout } from "@shared/features/ResponsiveDesign";
import { SkipToMainLink } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import {
    XeroxTopContainer,
    XeroxLeftContainer,
    XeroxBottomContainer,
    XeroxFloatingToolsContainer
} from "@five/xerox/features/StudioLayout";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import LoadingImagePreview from "../LoadingImagePreview";
import { StudioGlobalProvider } from "../StudioGlobalProvider";
import { Canvas } from "./Canvas";
import { LayoutManager } from "./LayoutManager";
import { EaselContentsTopContainer } from "./EaselContentsTopContainer";
import { EaselContentsLeftContainer } from "./EaselContentsLeftContainer";
import { EaselContentsRightContainer } from "./EaselContentsRightContainer";
import { EaselContentsBottomContainer } from "./EaselContentsBottomContainer";
import { EaselContentsDesktop } from "./EaselContentsDesktop";
import { EaselContentsMobile } from "./EaselContentsMobile";
import { ToastContainer } from "../Toasts/ToastContainer";
import { EaselContentsSetup } from "./EaselContentsSetup";
import "./easelContents.scss";

const EaselContentsDialogs = loadable<{}, { EaselContentsDialogs: React.ComponentType<{}> }>(
    () => loadableRetry(() => import("./EaselContentsDialogs")),
    {
        resolveComponent: components => components.EaselContentsDialogs
    }
);

const messages = defineMessages({
    trackingLabel: {
        id: "studio.components.skipLink.trackingLabel",
        defaultMessage: "Skip to main content",
        description: {
            note: "Skip to main will be tracked"
        }
    }
});

export const EaselContents = () => {
    const isAfterHydration = useBeforeAfterHydrationCheck();
    const leftRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const topRef = useRef<HTMLDivElement>(null);
    const contextualRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);
    const { isSmall } = useStudioLayout();

    const { t } = useTranslationSSR();

    // If in xerox mode, then show the xeroxLayout
    const { isXerox } = useXerox();
    const isXeroxLayout = isXerox && !isSmall;
    const isXeroxFlipAnimated = useIsFlagEnabled("xerox-flip");

    return (
        <StudioGlobalProvider>
            <LayoutManager
                leftRef={leftRef}
                rightRef={rightRef}
                bottomRef={bottomRef}
                topRef={topRef}
                contextualRef={contextualRef}
                canvasRef={canvasRef}
                className={classNames({
                    xerox: isXerox,
                    "xerox-flip": isXeroxFlipAnimated
                })}
            >
                <SkipToMainLink
                    skipToElementId="left-sidebar"
                    onClick={() =>
                        fireDesignToolTrackingEvent({
                            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SKIP_TO_MAIN,
                            label: t(messages.trackingLabel.id)
                        })
                    }
                />
                {/* If using the xerox layout, use the xerox components */}
                {isXeroxLayout ? (
                    <>
                        <XeroxTopContainer ref={topRef} />
                        <XeroxLeftContainer ref={leftRef} />
                        <XeroxBottomContainer ref={bottomRef} />
                        <XeroxFloatingToolsContainer ref={contextualRef} />
                    </>
                ) : (
                    <>
                        <EaselContentsTopContainer ref={topRef} />
                        <EaselContentsLeftContainer ref={leftRef} />
                        <EaselContentsBottomContainer ref={bottomRef} />
                        {!isSmall ? <EaselContentsDesktop ref={contextualRef} /> : <EaselContentsMobile />}
                        <ToastContainer />
                    </>
                )}

                <LoadingImagePreview />
                <Canvas ref={canvasRef} />

                <EaselContentsRightContainer ref={rightRef} />
                <EaselContentsSetup />
                {isAfterHydration && <EaselContentsDialogs />}
            </LayoutManager>
        </StudioGlobalProvider>
    );
};

EaselContents.displayName = "EaselContents";
