import { useEffect } from "react";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { applyTextPremiumFinish, applyWordArtPremiumFinish, getPremiumFinishes, isWordArt } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";

/**
 * A hook that enables premium finishes on text and word art by default when it's first added
 */
export function useDefaultTextPremiumFinish() {
    const designer = useDesigner();
    const canvas = useActiveCanvas();

    useEffect(() => {
        if (!designer || !canvas) {
            return;
        }
        // adding a check to enable premium finish only for 'front' canvas for a premium finish supported document.
        const isActiveCanvasFrontCanvas = canvas?._canvasViewModel?.attributes?.ordinal === 1;
        const enablePremiumFinish = (event: EventData) => {
            const { items } = event;
            const premiumFinishAvailable = canvas && isActiveCanvasFrontCanvas && getPremiumFinishes(canvas).length > 0;

            if (premiumFinishAvailable) {
                items.forEach(item => {
                    if (item.itemType === ItemTypes.TEXT) {
                        applyTextPremiumFinish(designer, canvas, [item], false);
                    } else if (isWordArt(item)) {
                        applyWordArtPremiumFinish(designer, canvas, [item]);
                    }
                });
            }
        };

        const unsubscribeAdd = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_ADDED,
            enablePremiumFinish
        );

        /* eslint-disable consistent-return */
        return () => {
            unsubscribeAdd();
        };
    }, [designer, canvas]);
}
