import React, { useState } from "react";
import { P, Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useUploadConfiguration } from "@design-stack-vista/upload-components";
import { useUploadComponents } from "./useUploadComponents";
import { DownChevronIcon } from "../StudioChrome";
import * as styles from "./AcceptedUploadFormatsExperiment.module.scss";

interface Props {
    isImageUpload?: boolean;
    showTitle?: boolean;
}

const messages = defineMessages({
    imagePanelAcceptedFormats: {
        id: "studio.components.panels.studiopanels.acceptedFormats",
        defaultMessage: "Accepted Image formats",
        description: {
            note: "Accepted formats title"
        }
    }
});

export function AcceptedUploadFormatsExperiment({ isImageUpload, showTitle }: Props) {
    const { isSmall } = useStudioLayout();
    const uploadComponents = useUploadComponents();
    const { t } = useTranslationSSR();
    const [hideFormats, setHideFormats] = useState<boolean>(true);

    const {
        supportedFileTypes: { fileExtensionsAsString }
    } = useUploadConfiguration();

    const marginForText = isSmall ? 5 : 6;
    const acceptedFormatsStr = uploadComponents
        ? fileExtensionsAsString
        : ".jpg, .jpeg, .bmp, .png, .gif, .heic, .tif, .tiff, .pdf, .psd, .ai, .ait, .ppt, .pptx.";
    return (
        <>
            {showTitle && (
                <Typography
                    fontSize="1"
                    mb={isSmall ? 2 : 0}
                    fontWeight={isSmall ? "bold" : "normal"}
                    textAlign={"center"}
                    onClick={() => {
                        setHideFormats(prev => !prev);
                    }}
                    role="button"
                >
                    {t(messages.imagePanelAcceptedFormats.id)}{" "}
                    <DownChevronIcon
                        width={21}
                        height={21}
                        className={[styles.chevronIcon, hideFormats ? styles.closed : ""].join(" ")}
                        style={{ marginTop: "5px" }}
                        role="button"
                    />
                </Typography>
            )}
            <P
                mt={0}
                mb={!isImageUpload ? marginForText : 4}
                textColor="dark-grey"
                fontSize={isImageUpload ? -2 : -1}
                className={hideFormats ? styles.formatTextsClosed : styles.formatTexts}
            >
                {acceptedFormatsStr}
            </P>
        </>
    );
}
AcceptedUploadFormatsExperiment.displayName = "AcceptedUploadFormats";
