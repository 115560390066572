import { useCallback } from "react";
import { useAppSelector } from "@shared/redux";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import {
    PlacementStrategy,
    PlacementStrategyCallback,
    PlacementStrategyParams,
    UploadStrategies
} from "../PanelComponents/Uploads/UploadStrategies";

type Params = {
    placementStrategy?: PlacementStrategy;
    onUploadAdded?: () => void;
    maxPercentOfCanvas?: number;
};

export const useHandleImagePlacement = ({ placementStrategy, onUploadAdded, maxPercentOfCanvas }: Params) => {
    const { onClick, onDoubleClick, onUpload } = placementStrategy || UploadStrategies.PlaceOnCanvas;
    const designer = useDesigner();
    const selection = useSelection();
    const { autoRemoveBackground } = useAppSelector(state => state.studioConfiguration);

    const imagePlacementAction = useCallback(
        (params: PlacementStrategyParams, callback?: PlacementStrategyCallback) => {
            if (!designer) {
                return;
            }
            if (!callback) {
                return;
            }
            if (callback) {
                const callbackParms = {
                    ...params,
                    maxPercentOfCanvas,
                    designer,
                    selection: params.selection || selection
                };
                callback(callbackParms);
            }
            onUploadAdded?.();
            if (autoRemoveBackground) {
                let unsubscribeImageAdded: () => void;
                const imageAdded = (event: EventData) => {
                    if (unsubscribeImageAdded) {
                        unsubscribeImageAdded();
                    }
                    const itemViewModel = event.items[0]._itemViewModel as ImageViewModel;
                    if (
                        autoRemoveBackground &&
                        itemViewModel?.model.attributes.studioMetadata?.thirdPartyUploadInfo?.uploadType !== "Icon" &&
                        !itemViewModel?.get("processesInProgress")?.includes("backgroundRemove")
                    ) {
                        itemViewModel?.toggleProcess("backgroundRemove");
                    }
                };
                unsubscribeImageAdded = designer.api.events.subscribe(
                    designer.api.events.eventTypes.ITEMS_CHANGED,
                    imageAdded
                );
            }
        },
        [designer, onUploadAdded, autoRemoveBackground, maxPercentOfCanvas, selection]
    );
    const onImageClicked: PlacementStrategyCallback = useCallback(
        (params: PlacementStrategyParams) => {
            imagePlacementAction(params, onClick);
        },
        [imagePlacementAction, onClick]
    );

    const onImageDoubleClicked: PlacementStrategyCallback = useCallback(
        (params: PlacementStrategyParams) => {
            imagePlacementAction(params, onDoubleClick);
        },
        [imagePlacementAction, onDoubleClick]
    );

    const onImageUploaded: PlacementStrategyCallback = useCallback(
        (params: PlacementStrategyParams) => {
            imagePlacementAction(params, onUpload);
        },
        [imagePlacementAction, onUpload]
    );

    const onImageDropped: PlacementStrategyCallback = useCallback(
        (params: PlacementStrategyParams) => {
            imagePlacementAction(params, onClick);
        },
        [imagePlacementAction, onClick]
    );
    return { onImageClicked, onImageDoubleClicked, onImageUploaded, onImageDropped };
};
