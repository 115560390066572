import type { DSS } from "@vp/types-ddif";
import { newRelicWrapper, handleError, ERROR_CODES } from "@shared/utils/Errors";
import { getQueryParams, removeQueryParam } from "@shared/utils/WebBrowser";
import { firePerformanceTrackingEvent, fireLoginTrackingEvent } from "@shared/utils/Tracking";
import { useAppDispatch, easelFinishedLoading, setSaveSuccess, setLoginReason } from "@shared/redux";
import type { Designer } from "src/easel/designer-suite/@types/designer";
import { backfillUploadMetadata } from "../utilities/uploadMetadataUtilities";
import setupEventTracking from "../tracking/SetupTracking";

export const useOnEaselStart = () => {
    const dispatch = useAppDispatch();

    async function onEaselStart(designDocumentInConfig: DSS.DesignDocument | undefined, designer: Designer) {
        const { saveToast, loginReason } = getQueryParams();
        try {
            backfillUploadMetadata(designer);

            newRelicWrapper.setLoadFinished();

            firePerformanceTrackingEvent({
                eventDetail: "Studio Loading Finished",
                label: "Studio Loading Finished"
            });

            newRelicWrapper.logPageAction("studio-loading-finished");

            dispatch(easelFinishedLoading());

            setupEventTracking();

            if (saveToast) {
                dispatch(setSaveSuccess({ saveSuccess: true }));
                window.history.replaceState(null, "Title", removeQueryParam(window.location.href, "saveToast"));
            }
            if (loginReason) {
                dispatch(setLoginReason(loginReason));
                fireLoginTrackingEvent({
                    eventDetail: "loginSuccessful",
                    extraData: () => ({
                        loginReason: getQueryParams().loginReason
                    })
                });
                window.history.replaceState(null, "Title", removeQueryParam(window.location.href, "loginReason"));
            }
        } catch (e) {
            handleError(e, ERROR_CODES.START_DESIGNER);
        }
    }

    return {
        onEaselStart
    };
};
