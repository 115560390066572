import { useTranslationSSR } from "@vp/i18n-helper";
import React, { useRef } from "react";
import { ExpandingButton } from "@shared/features/StudioChrome";
import type { ExpandingButtonHandles } from "@shared/features/StudioChrome";
import { ColorSwatch, type SelectableColor, ColorPalette } from "@shared/features/ColorPicker";
import { singleColorModalMessages } from "./singleColorMessages";
import "./desktopSingleColorPalette.scss";

interface Props {
    paletteColors: SelectableColor[];
    currentColor?: SelectableColor;
    onChange: (color: string) => void;
    disabled?: boolean;
}

export function DesktopSingleColorPalette({ paletteColors, currentColor, onChange, disabled }: Props) {
    const { t } = useTranslationSSR();
    const buttonRef = useRef<ExpandingButtonHandles>(null);

    if (!currentColor) {
        return null;
    }

    const handleColorSelection = (color: string) => {
        onChange(color);
        if (buttonRef.current) {
            buttonRef.current.close();
        }
    };

    return (
        <ExpandingButton
            className="desktop-single-color-palette__current-color"
            content={
                <ColorPalette
                    paletteColors={paletteColors}
                    onSelectColor={handleColorSelection}
                    selectedColor={currentColor.value}
                />
            }
            showArrow={true}
            overridePopperAlignment={undefined}
            overridePopperPlacement="top"
            title={t(singleColorModalMessages.chooseColor.id)}
            ref={buttonRef}
            isDisabled={disabled}
        >
            <ColorSwatch
                component="span"
                className="desktop-single-color-palette__color-swatch"
                color={currentColor}
                toggleColor={() => {}}
            />
        </ExpandingButton>
    );
}

DesktopSingleColorPalette.displayName = "DesktopSingleColorPalette";
