import { useFileMethods } from "@design-stack-vista/upload-components";
import React, { useCallback } from "react";
import { useUploadsContext } from "../../../..";
import { DeleteButtonProps, UploadDeleteButton } from "./UploadDeleteButton";

export const ImageDeleteButton = (props: DeleteButtonProps) => {
    const { deleteFile } = useFileMethods();
    const { deleteUpload } = useUploadsContext();
    const onDelete = useCallback(
        (model: any) => {
            const asset = model.get("asset");
            if (asset) {
                deleteFile(asset);
            } else {
                deleteUpload(model.get("originalUrl"));
            }
        },
        [deleteFile, deleteUpload]
    );
    return <UploadDeleteButton onDelete={onDelete} {...props} />;
};

ImageDeleteButton.displayName = "ImageDeleteButton";
