import React, { Ref, useCallback } from "react";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useTrackEvents } from "@shared/features/Tracking";
import { useAppSelector, useAppDispatch, setShowValidations } from "@shared/redux";
import { SidebarButton, SidebarButtonProps } from "@shared/features/StudioChrome";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";

export interface SidebarPanelButtonProps extends SidebarButtonProps {
    contentType: DialogType;
    // Optional callback to be invoked on click of sidebar button
    onClickCB?: () => void;
    panelname?: string;
    clickEventDetailType?: string;
}

export const SidebarPanelButton = React.forwardRef((props: SidebarPanelButtonProps, ref: Ref<HTMLButtonElement>) => {
    const dispatch = useAppDispatch();
    const isSmartValidationsOpen = useAppSelector(state => state.showValidations.showPanel);
    const { isSmartValidationsEnabled } = useSmartValidations();

    const { contentType, onClickCB, clickEventDetailType, ...rest } = props;
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { trackEvent } = useTrackEvents();
    const { isMedium } = useStudioLayout();

    const onClick = useCallback(
        (type: DialogType) => {
            trackEvent({
                eventDetail: `${STUDIO_TRACKING_EVENTS.CLICK_SIDEBAR_BUTTON}${clickEventDetailType || type}`,
                label: "General"
            });
            onClickCB && onClickCB();
            setCurrentActiveDialog(current => (current !== type || !isMedium ? type : DialogType.None));
            // close right SmartValidationsPanel when left panel opens
            if (isSmartValidationsOpen && isSmartValidationsEnabled) {
                dispatch(setShowValidations({ showPanel: false }));
            }
        },
        [
            trackEvent,
            clickEventDetailType,
            onClickCB,
            setCurrentActiveDialog,
            isMedium,
            dispatch,
            isSmartValidationsOpen,
            isSmartValidationsEnabled
        ]
    );

    return (
        <SidebarButton
            {...rest}
            ref={ref}
            isSelected={currentActiveDialog === contentType}
            aria-selected={currentActiveDialog === contentType}
            tabIndex={currentActiveDialog === contentType ? 0 : -1}
            role="tab"
            id={`tab-${contentType}`}
            aria-controls={`sidebar-panel-${contentType}`}
            onClick={() => onClick(contentType)}
        />
    );
});

SidebarPanelButton.displayName = "SidebarPanelButton";
