import React, { forwardRef, RefObject, useCallback, useEffect, useState } from "react";
import { Typography, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { getImageAsPromise } from "@design-stack-ct/utility-core";
import { Rgb } from "@presentational";
import { fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, showSingleColorImageModal } from "@shared/redux";
import { LoadingSpinner } from "@shared/features/StudioChrome";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { DEFAULT_THRESHOLD, drawSingleColor } from "./clientsideThresholdImageUtil";
import { useSelection } from "../../designer/useSelection";
import { singleColorModalMessages } from "./singleColorMessages";
import { useDesigner } from "../../designer/DesignerProvider";
import { getPreviewBackgroundStyle, isPdfFileType, isHeicFileType } from "../../util";
import { useClientSideThresholding } from "./useClientSideThresholding";

interface Props {
    threshold: number | undefined;
    updateThreshold: (threshold: number) => void;
    color: Rgb | undefined;
    inverted: boolean;
    imageLoaded: boolean;
    setImageLoaded: (imageLoaded: boolean) => void;
    showBackground: boolean | undefined;
    isReplacingImage: boolean;
    isNewImage: boolean;
    startTime: number | undefined;
}

export const SingleColorImagePreview = forwardRef((props: Props, imageCanvasRef: RefObject<HTMLCanvasElement>) => {
    const {
        threshold,
        updateThreshold,
        color,
        inverted,
        imageLoaded,
        setImageLoaded,
        showBackground,
        isReplacingImage,
        isNewImage,
        startTime
    } = props;
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(state => state.singleColorImageModalOpen);
    const [previewImageUrl, setPreviewImageUrl] = useState();
    const [imageWasReplaced, setImageWasReplaced] = useState(false);
    const selection = useSelection("model:change:originalUrl");
    const [background, setBackground] = useState({ backgroundImage: "", backgroundSize: "", backgroundPosition: "" });
    const [previewImageHeight, setPreviewImageHeight] = useState(0);
    const [previewImageWidth, setPreviewImageWidth] = useState(0);
    const clientsideThreshold = useClientSideThresholding();

    const imageContainerRef = useCallback(node => {
        if (node !== null) {
            setPreviewImageHeight(node.getBoundingClientRect().height);
            setPreviewImageWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const drawThresholdResult = useCallback(() => {
        if (!threshold) {
            return;
        }
        const item = selection[0];
        if (!item) return;
        const { model } = item._itemViewModel;
        const image = model?.get("image");

        const canvas = imageCanvasRef?.current;
        if (canvas && image) {
            drawSingleColor(canvas, color, threshold, inverted, image);
        } else if (canvas && !image && !isReplacingImage) {
            // handle images on previously saved designs
            const url =
                isPdfFileType(model?.get("fileType")) ||
                isHeicFileType(model?.get("fileType")) ||
                !model?.get("fileType")
                    ? model?.get("previewUrl")
                    : model?.get("originalUrl");

            getImageAsPromise(url)
                .then(image => {
                    model.set("image", image);
                    drawSingleColor(canvas, color, threshold, inverted, image);
                })
                .catch(error => {
                    let loggableError = error;
                    if (!error.message) {
                        loggableError = new Error("Error getting image");
                    }
                    handleError(loggableError, ERROR_CODES.SINGLE_COLOR_IMAGE_THRESHOLDING);
                    // close the modal if an error occurs
                    dispatch(showSingleColorImageModal(false));
                    // delete the image if thresholding is unsuccessful
                    designer?.api.design.updateItem(item.id, mutableItem => {
                        mutableItem?.remove();
                    });
                });
        } else if (canvas && !image && isReplacingImage && !item._itemViewModel?.get("imageProcessingInProgress")) {
            // handle replacing image
            clientsideThreshold(item);
        }
    }, [
        threshold,
        selection,
        imageCanvasRef,
        isReplacingImage,
        color,
        inverted,
        dispatch,
        designer?.api.design,
        clientsideThreshold
    ]);

    useEffect(() => {
        if (imageLoaded) {
            drawThresholdResult();
        }
    }, [designer?.api.design, dispatch, drawThresholdResult, imageLoaded, selection]);

    useEffect(() => {
        if (!isOpen || !designer || !showBackground || !imageLoaded) {
            return;
        }
        const underlayScene = designer.documentRepository?.getActiveCanvasViewModel()?.getUnderlayScene();
        if (underlayScene) {
            const background = getPreviewBackgroundStyle(underlayScene, previewImageWidth, previewImageHeight);
            setBackground(background);
        }
    }, [previewImageWidth, previewImageHeight, designer, isOpen, showBackground, imageLoaded]);

    useEffect(() => {
        const model = selection[0]?._itemViewModel.model;
        const originalUrl = model?.get("originalUrl");
        const previewUrl = model?.get("previewUrl");
        const hasImage = model?.has("image");

        if (isReplacingImage && hasImage && isNewImage && !imageWasReplaced) {
            // if we're replacing an image, the modal first opens with the original image
            // once we get the new image, we continue to draw it on the canvas below
            setImageWasReplaced(true);
            return;
        }
        if (isOpen && !imageLoaded && (originalUrl || previewUrl) && color) {
            updateThreshold(model.get("threshold") || DEFAULT_THRESHOLD);

            if (startTime) {
                const endTime = performance.now();
                const timeElapsed = endTime - startTime;
                if (isNewImage) {
                    fireUserInteractionTrackingEvent(
                        "Initial single color image preview load - newly added image",
                        timeElapsed
                    );
                } else if (hasImage) {
                    fireUserInteractionTrackingEvent(
                        "Initial single color image preview load - previously added image",
                        timeElapsed
                    );
                }
            }
            setImageLoaded(true);
        }
    }, [
        isOpen,
        updateThreshold,
        selection,
        setImageLoaded,
        isReplacingImage,
        dispatch,
        color,
        drawThresholdResult,
        imageLoaded,
        imageWasReplaced,
        isNewImage,
        startTime
    ]);

    useEffect(() => {
        const getImageUrl = async () => {
            const imageUrl = await selection[0]?._itemViewModel.get("url");
            setPreviewImageUrl(imageUrl);
        };

        if (isOpen) {
            getImageUrl();
        }
    }, [selection, isOpen]);

    return (
        <div className="single-color-image-preview" ref={imageContainerRef} style={background}>
            <canvas className="single-color-image-preview__image" ref={imageCanvasRef} />
            {!imageLoaded ? (
                <div className="single-color-image-preview__loading-container">
                    <img
                        className="single-color-image-preview__image"
                        src={previewImageUrl}
                        alt={t(singleColorModalMessages.previewColor.id)}
                    />
                    <FlexBox
                        className="single-color-image-preview__loading"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <LoadingSpinner className="single-color-image-preview__loading-spinner" />
                        <Typography fontSize="-1" fontWeight="bold" marginTop={4}>
                            {t(singleColorModalMessages.invertColorsLoading.id)}
                        </Typography>
                    </FlexBox>
                </div>
            ) : null}
        </div>
    );
});

SingleColorImagePreview.displayName = "SingleColorImagePreview";
