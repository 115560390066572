import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Typography, TextInput } from "@vp/swan";
import { CMYK } from "@design-stack-ct/utility-core";
import { ColorSpace, CMYKColorSlider } from "@shared/features/ColorPicker";
import * as styles from "./CustomCmykColorPicker.module.scss";

interface Props {
    /** Currently selected color */
    value?: CMYK;
    /** Function called when a slider has been updated */
    onChange: (newValue: CMYK, colorSpace: ColorSpace) => void;
    /** Optional class names to add to component */
    className?: string;
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
}

interface ColorValueTextFieldProps {
    colorValue: number;
    onChange: (newValue: number) => void;
}

function ColorValueTextField({ colorValue, onChange }: ColorValueTextFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = Number(e.currentTarget.value);
        if (Number.isNaN(input)) {
            return;
        }
        if (input > 100) {
            return;
        }
        onChange(input);
    };
    return (
        <div className={styles.cmykPercentageContainer}>
            <TextInput
                value={colorValue}
                onChange={handleChange}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                    event.target.select();
                }}
            />
            <span className={styles.cmykPercentSign}>%</span>
        </div>
    );
}

export function CustomCmykColorPicker({
    onChange = () => {},
    value = { c: 0, m: 0, y: 0, k: 0 },
    className,
    trackUsage
}: Props) {
    const [currentValue, setCurrentValue] = useState(value);

    const onSliderChange = (newCmky: CMYK) => {
        setCurrentValue(newCmky);
        onChange(newCmky, ColorSpace.CMYK);
        if (trackUsage) {
            trackUsage("CMYK", newCmky.toString());
        }
    };

    const handleCyanChange = (cyan: number) => {
        const newValue = { ...currentValue, c: Math.round(cyan), a: undefined };
        onSliderChange(newValue);
    };

    const handleMagentaChange = (magenta: number) => {
        const newValue = { ...currentValue, m: Math.round(magenta), a: undefined };
        onSliderChange(newValue);
    };

    const handleYellowChange = (yellow: number) => {
        const newValue = { ...currentValue, y: Math.round(yellow), a: undefined };
        onSliderChange(newValue);
    };

    const handleBlackChange = (black: number) => {
        const newValue = { ...currentValue, k: Math.round(black), a: undefined };
        onSliderChange(newValue);
    };

    useEffect(() => {
        if (value) {
            setCurrentValue(value);
        }
    }, [value]);

    return (
        <div className={classNames(styles.customCmykColorPickerContainer, className)}>
            <div className={styles.customCmykColorPicker}>
                <div className={styles.cmykColorEditor}>
                    <Typography fontWeight="bold">C</Typography>
                    <CMYKColorSlider
                        onChange={handleCyanChange}
                        cmykAttribute="c"
                        cmykColor={currentValue}
                        className="color-slider"
                    />
                    <ColorValueTextField colorValue={currentValue.c} onChange={handleCyanChange} />
                </div>
                <div className={styles.cmykColorEditor}>
                    <Typography fontWeight="bold">M</Typography>
                    <CMYKColorSlider
                        onChange={handleMagentaChange}
                        cmykAttribute="m"
                        cmykColor={currentValue}
                        className="color-slider"
                    />
                    <ColorValueTextField colorValue={currentValue.m} onChange={handleMagentaChange} />
                </div>
                <div className={styles.cmykColorEditor}>
                    <Typography fontWeight="bold">Y</Typography>
                    <CMYKColorSlider
                        onChange={handleYellowChange}
                        cmykAttribute="y"
                        cmykColor={currentValue}
                        className="color-slider"
                    />
                    <ColorValueTextField colorValue={currentValue.y} onChange={handleYellowChange} />
                </div>
                <div className={styles.cmykColorEditor}>
                    <Typography fontWeight="bold">K</Typography>
                    <CMYKColorSlider
                        onChange={handleBlackChange}
                        cmykAttribute="k"
                        cmykColor={currentValue}
                        className="color-slider"
                    />
                    <ColorValueTextField colorValue={currentValue.k} onChange={handleBlackChange} />
                </div>
            </div>
        </div>
    );
}

CustomCmykColorPicker.displayName = "CustomCmykColorPicker";
ColorValueTextField.displayName = "ColorValueTextField";
