import React, { useState, useEffect, forwardRef, Ref } from "react";
import classNames from "classnames";
import { SubselectionType, useTableSubselection } from "@shared/features/Tables";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useValidations } from "../../studioFive/components/Validation/ValidationProvider";
import { SelectedItemToolBox } from "./SelectedItemToolBox";
import { useSelection } from "./designer/useSelection";
import { SelectedRichTextField } from "./SelectedRichTextField";
import { TableTextField } from "./TableTextField";
import { SelectedWordArtField } from "./SelectedWordArtField";
import "./contextualEditor.scss";
import { DEFAULT_MIN_DISTANCE_TO_ITEMS } from "../utilities/constants";
import { SelectedTeamsPlaceholder } from "./SelectedTeamsPlaceholder";

interface Props {
    autoFocus?: boolean;
    autoPosition?: boolean;
    autoMaxTop?: boolean;
    maxTop?: number;
    shouldToggleTextEditor?: boolean;
    toolbarId: string;
}
/**
 * This component is the text editor that appears above the selected text item on desktop, and above the canvas on mobile
 * This is different than the text editing that is available in the text panel
 */
export const ContextualTextEditor = forwardRef(
    (
        {
            autoFocus = true,
            shouldToggleTextEditor = true,
            autoMaxTop = false,
            autoPosition = false,
            maxTop = 0,
            toolbarId
        }: Props,
        ref: Ref<HTMLInputElement>
    ) => {
        const { itemValidations } = useValidations();
        const DEFAULT_AUTO_MAX_TOP_VALUE = 0;
        const selection = useSelection();
        const [autoMaxTopValue, setAutoMaxTopValue] = useState(DEFAULT_AUTO_MAX_TOP_VALUE);
        const {
            selection: { type: tableSubSelection }
        } = useTableSubselection();

        useEffect(() => {
            if (!autoMaxTop) return;

            if (typeof window === "undefined" || !document) {
                return;
            }

            const toolbar = document.getElementById(toolbarId);
            if (toolbar && toolbar.offsetTop && toolbar.offsetHeight) {
                setAutoMaxTopValue(toolbar.offsetTop + toolbar.offsetHeight);
            }

            const handleScroll = () => {
                setAutoMaxTopValue(autoMaxTopValue + window.scrollY);
            };
            window.addEventListener("scroll", handleScroll, { passive: true });
            // eslint-disable-next-line consistent-return
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }, [autoMaxTopValue, toolbarId, autoMaxTop]);

        // Don't render the contextual text editor if no items are selected
        if (selection.length !== 1) {
            return null;
        }

        const item: ReferenceItem | TextItem = selection[0];
        const validationCount = itemValidations[item.id]?.length || 0;

        const resolveReferenceItem = (
            referenceItem: ReferenceItem
        ): { component: JSX.Element | undefined; minDistanceToItems?: number } => {
            switch (referenceItem.type) {
                // Render G&T tooltip the same way as contextual text editor on canvas above the placeholder
                case ItemReferenceTypes.TEAMS_NAME:
                    return {
                        component: <SelectedTeamsPlaceholder item={referenceItem as TextPlaceholderItem} />
                    };
                case ItemReferenceTypes.WORD_ART:
                    return {
                        component: <SelectedWordArtField item={referenceItem as WordArtItem} selectOnFocus={true} />
                    };
                case ItemReferenceTypes.TABLE:
                    if (tableSubSelection === SubselectionType.Cell) {
                        return {
                            component: (
                                <TableTextField
                                    item={referenceItem as TableItem}
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus={autoFocus}
                                    selectOnFocus={true}
                                />
                            ),
                            minDistanceToItems: 50
                        };
                    }
                    break;
                default:
                    break;
            }
            return { component: undefined };
        };

        const resolveItem = (): { component: JSX.Element | undefined; minDistanceToItems?: number } => {
            switch (item.itemType) {
                case ItemTypes.TEXT:
                    return {
                        component: <SelectedRichTextField />
                    };
                case ItemTypes.ITEM_REFERENCE:
                    return resolveReferenceItem(item as ReferenceItem);
                default:
                    return { component: undefined };
            }
        };

        const { component, minDistanceToItems } = resolveItem();
        // Don't render the contextual text editor if text isn't being selected
        if (!component) {
            return null;
        }

        let newMaxTop = maxTop;
        if (!maxTop && autoMaxTopValue) newMaxTop = autoMaxTopValue;
        else if (maxTop) newMaxTop += minDistanceToItems || DEFAULT_MIN_DISTANCE_TO_ITEMS;

        return (
            <SelectedItemToolBox
                className={classNames("easel-selectedItemToolBox")}
                maxTop={newMaxTop}
                autoPosition={autoPosition}
                shouldToggleToolbox={shouldToggleTextEditor}
                minDistanceToItems={minDistanceToItems}
                offsetForValidation={validationCount > 0}
            >
                <div id="easel-contextual-editor" data-block-outside-panel-click-to-close ref={ref}>
                    {component}
                </div>
            </SelectedItemToolBox>
        );
    }
);
ContextualTextEditor.displayName = "ContextualTextEditor";
