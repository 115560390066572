import { useCallback } from "react";
import { useDesigner } from "@designer-suite";
import { useAppSelector } from "@shared/redux";

export function useAutoRemoveBackground() {
    const { autoRemoveBackground } = useAppSelector(state => state.studioConfiguration);
    const designer = useDesigner();

    const toggleBackground = useCallback(() => {
        if (!designer || !autoRemoveBackground) {
            return;
        }
        let unsubscribeImageChanged: () => void;
        const imageAdded = (event: EventData) => {
            unsubscribeImageChanged?.();

            const itemViewModel = event.items[0]._itemViewModel as ImageViewModel;
            itemViewModel?.toggleProcess("backgroundRemove");
        };
        unsubscribeImageChanged = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_CHANGED,
            imageAdded
        );
    }, [autoRemoveBackground, designer]);

    return toggleBackground;
}
