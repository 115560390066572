/**
 * A component that adds a premium finish display on items with finishes
 *
 *
 * Limitations:
 *   - Only looks for finishes on first canvas
 *   - Uses the first finish available (doesn't work for products with multiple finishes)
 */

import React from "react";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { useActiveCanvasPremiumFinishItems, useFinishType } from "@utilities";
import { SUPPORTED_FINISHES } from "@shared/features/PremiumFinish";
import { useAppSelector } from "@shared/redux";
import { ItemLayerPortal } from "./ItemLayerPortal";
import { FinishItemPreviewProps } from "./FinishItemPreview";

const FinishProvider = loadable(() => loadableRetry(() => import("./finishes/FinishProvider")), {
    resolveComponent: components => components.FinishProvider
});
const FinishItemPreview = loadable(() => loadableRetry(() => import("./FinishItemPreview")), {
    resolveComponent: components => components.FinishItemPreview as React.FC<FinishItemPreviewProps>
});

const PremiumFinishPreviewLayer = () => {
    // Check whether finish is enabled based on the toggle, active canvas,
    const isPremiumFinishToggleOn = useAppSelector(state => state.isPremiumFinishToggleOn);
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const finish = useFinishType();
    const { premiumFinishItems } = useActiveCanvasPremiumFinishItems();
    const hasFinishItems = premiumFinishItems.length > 0;
    const isSupported = finish && SUPPORTED_FINISHES.includes(finish) && !isFullBleed;
    const finishEnabled = isSupported && hasFinishItems && isPremiumFinishToggleOn;

    // Render the Finish Item Preview inside of the Item Layer
    return (
        <ItemLayerPortal>
            {finishEnabled && (
                <FinishProvider>
                    {premiumFinishItems.map((premiumFinishItem: CanvasItem) => (
                        <FinishItemPreview item={premiumFinishItem} key={premiumFinishItem.id} />
                    ))}
                </FinishProvider>
            )}
        </ItemLayerPortal>
    );
};
PremiumFinishPreviewLayer.displayName = "PremiumFinishPreviewLayer";

export default PremiumFinishPreviewLayer;
