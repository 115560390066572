import React, { ReactNode, useContext, useMemo, useState } from "react";

import type { Page } from "src/easel/designer-suite/@types/page";
// @note this will need to be fixed once we migrate to Sherbert
// eslint-disable-next-line import/no-restricted-paths
import type { Upload } from "src/easel/designer-suite/@types/upload";

interface ImageGalleryPreview {
    upload: Upload;
    page: Page;
    previewUrl: string;
}
interface Data {
    imageGalleryPreviewData?: ImageGalleryPreview;
    setImageGalleryPreviewData: React.Dispatch<React.SetStateAction<ImageGalleryPreview | undefined>>;
}

const context = React.createContext<Data>({ imageGalleryPreviewData: undefined, setImageGalleryPreviewData: () => {} });
const { Provider } = context;

export function useImageGalleryPreview() {
    return useContext(context);
}

interface Props {
    children: ReactNode | ReactNode;
}

/**
 * Due to the nature of the contents of upload and page, redux toolkit couldn't serialzie them. This was causing infinite loops.
 * This provider takes the place of that state.
 *
 */
export function ImageGalleryPreviewProvider(props: Props) {
    const { children } = props;
    const [imageGalleryPreviewData, setImageGalleryPreviewData] = useState<ImageGalleryPreview | undefined>(undefined);

    const value = useMemo(
        () => ({
            imageGalleryPreviewData,
            setImageGalleryPreviewData
        }),
        [imageGalleryPreviewData]
    );

    return <Provider value={value}>{children}</Provider>;
}

ImageGalleryPreviewProvider.displayName = "ImageGalleryPreviewProvider";
