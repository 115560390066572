import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "@shared/redux";
import { type Design, getDesignVariation } from "@shared/utils/Gallery";

export function useDesignVariations(designVariationOptions: string) {
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const primaryTemplateToken = useAppSelector(state => state.secondaryConfig)?.primaryTemplateToken;
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const template = useAppSelector(state => state.template);
    const [designVariations, setDesignVariations] = useState<Design[]>([]);
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    const getDesignVariations = useCallback(
        (templateToken: string) => {
            if (!productKey || !customerSelectedProductOptions || !locale || !easelLoaded) {
                return;
            }

            const newCustomerSelectedProductOptions = JSON.parse(JSON.stringify(customerSelectedProductOptions));
            if (newCustomerSelectedProductOptions?.[designVariationOptions]) {
                delete newCustomerSelectedProductOptions?.[designVariationOptions];
            }
            getDesignVariation({
                key: productKey,
                studioSelectedProductOptions: newCustomerSelectedProductOptions,
                templateToken,
                locale,
                variableOptions: designVariationOptions
            }).then(setDesignVariations);
        },
        [easelLoaded, customerSelectedProductOptions, designVariationOptions, locale, productKey]
    );

    useEffect(() => {
        if (!template) {
            return;
        }
        getDesignVariations(primaryTemplateToken || template);
    }, [primaryTemplateToken, template, getDesignVariations]);

    return { designVariations };
}

useDesignVariations.displayName = "useDesignVariations";
