/* eslint-disable max-len */
import React from "react";

export function PremiumFinishPlaceholderWarningIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="23" height="23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.6 1.57c.4-.7 1.4-.7 1.8 0l8.15 14.11c.4.7-.1 1.57-.9 1.57H3.35c-.8 0-1.3-.87-.9-1.57l8.14-14.11Z"
                fill="#E8B738"
            />
            <path
                d="M11.47 14.5a.93.93 0 1 0 0-1.87c-.51 0-.94.41-.94.94 0 .52.43.93.94.93Zm-.48-3.48a.49.49 0 0 0 .98 0l.15-3.35a.64.64 0 1 0-1.28 0l.15 3.35Z"
                fill="#fff"
            />
        </svg>
    );
}
PremiumFinishPlaceholderWarningIcon.displayName = "PremiumFinishPlaceholderWarningIcon";
