import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Icon, Button, Box, BoxProps } from "@vp/swan";
import classNames from "classnames";
import * as styles from "./StandardSheetHeader.module.scss";

const messages = defineMessages({
    closeButtonAccessibilityLabel: {
        id: "easel.ui.sheet.standardSheetHeader.closeButtonAccessibilityLabel",
        defaultMessage: "Close",
        description: {
            note: "text for accessibility for the closes button"
        }
    },
    backButtonAccessibilityLabel: {
        id: "easel.ui.sheet.standardSheetHeader.backButtonAccessibilityLabel",
        defaultMessage: "Back",
        description: {
            note: "text for accessibility for the back button"
        }
    }
});

type Props = BoxProps & {
    /** Optional title for the sheet, that will be centered */
    sheetTitle?: string;

    /** Optional Action the back button should take */
    onBackClick?: () => void;

    /** Optional Action the close button should take */
    onCloseClick?: () => void;
};

export function StandardSheetHeader({ className, onBackClick, onCloseClick, sheetTitle, ...restOfProps }: Props) {
    const { t } = useTranslationSSR();
    return (
        <Box className={classNames(styles.standardSheetHeader, className)} {...restOfProps}>
            {onBackClick && (
                <Button
                    className={styles.standardSheetHeaderBack}
                    skin="unstyled"
                    data-testid="sheet-back-button"
                    onClick={onBackClick}
                >
                    <Icon
                        className={styles.standardSheetHeaderIcon}
                        iconType="chevronLeft"
                        aria-label={t(messages.backButtonAccessibilityLabel.id)}
                    />
                </Button>
            )}
            {sheetTitle && (
                <Typography
                    className={styles.standardSheetHeaderTitle}
                    fontSize="1"
                    fontWeight="bold"
                    textAlign="center"
                >
                    {sheetTitle}
                </Typography>
            )}
            {!onBackClick && onCloseClick && (
                <Button
                    className={styles.standardSheetHeaderClose}
                    skin="unstyled"
                    onClick={onCloseClick}
                    data-testid="mobileSheetCloseButton"
                >
                    <Icon
                        className={styles.standardSheetHeaderIcon}
                        iconType="close"
                        alt={t(messages.closeButtonAccessibilityLabel.id)}
                        fontSize="4"
                    />
                </Button>
            )}
        </Box>
    );
}
StandardSheetHeader.displayName = "StandardSheetHeader";
