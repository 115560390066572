import React from "react";
import classNames from "classnames";
import Gallery, { RenderImageProps } from "react-photo-gallery";
import { InfiniteScrollList } from "@shared/features/StudioChrome";
import { ImageRenderer } from "@five/components/Panels/Images/ImageRenderer";
import { IconThumbnail } from "@five/components/Panels/Images/IconThumbnail";
import { useElementsPanel } from "./ElementsPanelProvider";
import * as styles from "./ElementsPanelContentRecents.module.scss";

export const ElementsPanelContentRecents = () => {
    const { recentlyUsedItems } = useElementsPanel();

    const renderRecentImages = (props: RenderImageProps) => {
        const {
            left,
            top,
            // @ts-ignore
            photo: { src, id, key, width, height, upload, imageSource }
        } = props;
        if (imageSource === "NounProject") {
            return (
                <IconThumbnail
                    key={key}
                    id={id}
                    src={src}
                    width={width}
                    height={height}
                    // @ts-ignore
                    upload={upload}
                    imageSource={imageSource}
                    style={{ left, top, width, height }}
                    className={classNames("image-thumbnail-recent-elements", "recent-shape")}
                />
            );
        }

        // @ts-ignore
        return <ImageRenderer key={props.photo?.upload?.cid} {...props} />;
    };

    return (
        <InfiniteScrollList
            count={recentlyUsedItems.length}
            id="elements-recents-gallery"
            className={styles.recentsGallery}
        >
            <Gallery
                photos={recentlyUsedItems}
                direction={"column"}
                columns={2}
                margin={5}
                renderImage={renderRecentImages}
            />
        </InfiniteScrollList>
    );
};

ElementsPanelContentRecents.displayName = "ElementsPanelContentRecents";
