import { defineMessages } from "@vp/i18n-helper";

export const projectNamingRelatedMessages = defineMessages({
    projectName: {
        id: "studio.components.globalToolbar.projectName",
        defaultMessage: "My [[productname]] Design",
        description: {
            note: "Default name to use for a project"
        }
    },
    appendToName: {
        id: "studio.duplicateDesignDialog.appendToName",
        defaultMessage: "Copy",
        description: {
            note: "Text to append to a duplicated design name"
        }
    }
});
