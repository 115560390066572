import { addQueryStringData } from "@shared/utils/WebBrowser";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { REQUEST_SPRITE_HEIGHT_IN_PX, DISPLAY_SPRITE_HEIGHT_IN_PX } from "@shared/utils/Fonts";
import { FontSpriteSheet } from "./fontTypes";

const BASE_URL = "https://cdn.misc-rendering.documents.cimpress.io";
const FONT_HEIGHT_RATIO_REQUEST_TO_DISPLAY = REQUEST_SPRITE_HEIGHT_IN_PX / DISPLAY_SPRITE_HEIGHT_IN_PX;
const REQUEST_CHARLIMIT = 2048;

function getImageAsPromise(url: string) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        // data URLs are never cross origin. If we set crossOrigin for these
        // it breaks in iOS.
        if (!url.includes("data:")) {
            image.crossOrigin = "Anonymous";
        }
        image.onload = () => resolve(image);
        image.onerror = () => reject(image);
        image.src = url;
    });
}

function getFontSpriteSheetUrl(baseUrl: string, allNameOverrides: { defaultName: string; newName: string }[]) {
    /**
     * @summary If there are font name overrides that are included in the chunkedFontNames,
     * this will create a url string with the font name overrides appended.
     * Otherwise it creates a string to retrieve the font sprite sheet
     * @param {string[]} chunkedFontNames
     * @returns {FontSpriteSheetUrl}
     */
    return function getSpriteSheetUrl(chunkedFontNames: string[]) {
        const nameOverrides = allNameOverrides
            .filter(override => chunkedFontNames.includes(override.defaultName))
            .reduce((allOverrides, override) => {
                allOverrides[`nameOverrides.${override.defaultName}`] = override.newName; // eslint-disable-line
                return allOverrides;
            }, {});
        return addQueryStringData(baseUrl, { fontFamilies: JSON.stringify(chunkedFontNames), ...nameOverrides });
    };
}

interface SpriteSheetReference {
    url: string;
    fontNames: string[];
}

function getSpriteSheetReferences(baseUrl: string, fontNames: string[]) {
    let chunkedFontNames = [] as string[];
    const getSpriteSheetUrl = getFontSpriteSheetUrl(baseUrl, []); // TODO accept nameOverrides

    return fontNames.reduce<SpriteSheetReference[]>((spriteSheetReferences, fontName, index) => {
        const urlWithoutCurrentFont = getSpriteSheetUrl(chunkedFontNames);
        let urlWithCurrentFont = getSpriteSheetUrl([...chunkedFontNames, fontName]);
        const requestIsTooLong = urlWithCurrentFont.length > REQUEST_CHARLIMIT;
        const isLastFontName = index === fontNames.length - 1;

        if (requestIsTooLong) {
            spriteSheetReferences.push({ url: urlWithoutCurrentFont, fontNames: chunkedFontNames });
            chunkedFontNames = [fontName];
            urlWithCurrentFont = getSpriteSheetUrl([...chunkedFontNames]);
        } else {
            chunkedFontNames.push(fontName);
        }

        if (isLastFontName) {
            spriteSheetReferences.push({ url: urlWithCurrentFont, fontNames: chunkedFontNames });
        }

        return spriteSheetReferences;
    }, []);
}

function getBlobForUrl(url: string) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener("load", function loadCallback() {
            if (this.status === 200) {
                resolve({ response: xhr.response, xhr });
            } else {
                reject({ xhr }); // eslint-disable-line
            }
        });
        xhr.addEventListener("error", () => {
            reject({ xhr }); // eslint-disable-line
        });
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.send();
    });
}

// IE10 does not support drawing cross origin images on a canvas.
// All of this nonsense with XMLHttpRequest, blobs, and object URLs
// fixes that issue.
function getObjectUrlForImage(url: string) {
    return getBlobForUrl(url).then(({ response, xhr }) => {
        const objectUrl = URL.createObjectURL(response);
        return { objectUrl, xhr };
    });
}

function getFontSpriteSheet(url: string, fontNames: string[]) {
    return (
        getObjectUrlForImage(url)
            .then(({ objectUrl }) => getImageAsPromise(objectUrl))
            .then((image: HTMLImageElement) => {
                const height = image.naturalHeight / fontNames.length;

                /** @type {FontSpriteSheet} */
                const fontSpriteSheet = { url, sprites: {} };

                fontNames.forEach((name, index) => {
                    fontSpriteSheet.sprites[name] = {
                        height: height / FONT_HEIGHT_RATIO_REQUEST_TO_DISPLAY,
                        width: image.naturalWidth / FONT_HEIGHT_RATIO_REQUEST_TO_DISPLAY,
                        offset: { x: 0, y: (height * index) / FONT_HEIGHT_RATIO_REQUEST_TO_DISPLAY },
                        backgroundSize: `${image.naturalWidth / FONT_HEIGHT_RATIO_REQUEST_TO_DISPLAY}px ${
                            image.naturalHeight / FONT_HEIGHT_RATIO_REQUEST_TO_DISPLAY
                        }px`
                    };
                });
                URL.revokeObjectURL(image.src);
                return fontSpriteSheet;
            })
            // do nothing for errors. things will be fine without a sprite sheet
            .catch(() => {})
    );
}

export function getSpriteSheet(
    supportedFonts: string[],
    fontRepositoryUrl: string,
    decorationTechnology: DecorationTechnologiesSimple
) {
    const fontSpriteSheetUrl = addQueryStringData(`${BASE_URL}/fontsprite`, {
        fontRepositoryUrl,
        technology: decorationTechnology,
        fontSize: REQUEST_SPRITE_HEIGHT_IN_PX
    });

    const spriteSheetReferences = getSpriteSheetReferences(fontSpriteSheetUrl, supportedFonts);

    return Promise.all(spriteSheetReferences.map(({ url, fontNames }) => getFontSpriteSheet(url, fontNames)))
        .then(spriteSheets =>
            spriteSheets.reduce<FontSpriteSheet>((acc, curr) => {
                if (!curr) {
                    return acc;
                }

                Object.keys(curr.sprites).forEach(name => {
                    const currentSprite = curr.sprites[name];
                    acc[name] = {
                        height: currentSprite.height,
                        width: currentSprite.width,
                        offset: currentSprite.offset,
                        backgroundSize: currentSprite.backgroundSize,
                        url: curr.url
                    };
                });

                return acc;
            }, {})
        )
        .catch(() => ({} as FontSpriteSheet));
}
