import React from "react";
import { useActiveCanvas } from "@designer-suite";
import { selectedItemsAreOfTypes } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { SingleColorImageColorPicker } from "./SingleColorImageColorPicker";
import { HslRecolorization } from "../../color/HslRecolorization";
import { useSelection } from "../../designer/useSelection";
import { itemHasPremiumFinish, getPremiumFinishes } from "../../../utilities/utils-premium-finishes";
import { itemIsImageUnreplacedPlaceholder, getDecorationTechnologyForCanvas } from "../../util";
import { useDesigner } from "../../designer/DesignerProvider";
import type { Designer, ItemSelection } from "../../@types/designer";

export function getIsRecolorizationCompatible(
    designer: Designer | undefined,
    selection: ItemSelection,
    canvas: Canvas | undefined
): designer is Designer {
    const isRaisedFoil =
        selection[0] &&
        itemHasPremiumFinish(selection[0]) &&
        canvas &&
        getPremiumFinishes(canvas).some(finish => finish.color.includes("RaisedFoil"));
    return (
        selection.length === 1 &&
        !!canvas &&
        !!designer &&
        getDecorationTechnologyForCanvas(designer, canvas) === DecorationTechnologiesSimple.PRINT &&
        selectedItemsAreOfTypes(selection, [ItemTypes.IMAGE]) &&
        !itemIsImageUnreplacedPlaceholder(selection[0]) &&
        !isRaisedFoil &&
        !selection[0]._itemViewModel.get("onSingleColorCanvas")
    );
}

interface ImageRecolorizationProps {
    /**
     * display just the content instead of the expanding button (for sheet usage)
     * @default false
     */
    contentOnly?: boolean;
    /** Whether the button should return the icon, text, or both  */
    displayType: "iconOnly" | "textOnly" | "both";
    className?: string;
}

export function ImageRecolorizationTool({
    contentOnly = false,
    displayType,
    className = "expanding-tool-icon"
}: ImageRecolorizationProps) {
    const designer = useDesigner();
    const selection = useSelection("change:isOneColor");
    const canvas = useActiveCanvas();

    if (!getIsRecolorizationCompatible(designer, selection, canvas)) {
        return null;
    }
    const isSingleColor = selection[0]._itemViewModel.get("isOneColor");

    if (isSingleColor === undefined) {
        return null;
    }

    return (
        <>
            {isSingleColor ? (
                <SingleColorImageColorPicker contentOnly={contentOnly} className={className} />
            ) : (
                <HslRecolorization contentOnly={contentOnly} displayType={displayType} className={className} />
            )}
        </>
    );
}
ImageRecolorizationTool.displayName = "ImageRecolorizationTool";
