import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    Icon,
    Box,
    FlexBox,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogNav,
    FluidImage,
    SquareImageContainer
} from "@vp/swan";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout, DesktopExperience, MobileExperience } from "@shared/features/ResponsiveDesign";
import { applyPlaceholderTextToDocument, defaultDocumentText } from "@shared/utils/CimDoc";
import type { DSS } from "@vp/types-ddif";
import { getTransientPreviewURL } from "@shared/utils/Previews";
import { changeTemplateMessages } from "./messages";
import * as styles from "./ConfirmationModal.module.scss";

interface ConfirmationModalProps {
    isOpen: boolean;
    onRequestDismiss: (changeTemplateInitiated?: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
    onAccept: () => void;
    designDocument?: DSS.DesignDocument;
}

export function ConfirmationModal({ isOpen, onRequestDismiss, onAccept, designDocument }: ConfirmationModalProps) {
    const { t } = useTranslationSSR();
    const { isSmall, isMedium } = useStudioLayout();
    const { auth } = useIdentityContext();
    const [previewUrl, setPreviewUrl] = useState<string | undefined>();

    useEffect(() => {
        if (designDocument) {
            (async () => {
                const authToken = auth.getToken();
                setPreviewUrl(
                    await getTransientPreviewURL({
                        designDocument: applyPlaceholderTextToDocument(
                            designDocument,
                            t(defaultDocumentText.textualPlaceholders.id)
                        ),
                        width: 600,
                        page: 1,
                        authToken
                    })
                );
            })();
        }
    }, [designDocument, auth, t]);

    return (
        <LegacyModalDialog
            isOpen={isOpen}
            onRequestDismiss={() => {
                onRequestDismiss();
                setPreviewUrl(undefined);
            }}
            className={styles.modal}
            variant={isSmall ? "panel-bottom" : "standard"}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent aria-label={t(changeTemplateMessages.desktopModalHeader.id)} fullBleed={true}>
                <MobileExperience>
                    <LegacyModalDialogNav>
                        <Icon iconType="chevronLeft" onClick={onRequestDismiss} />
                        <LegacyModalDialogHeader ml={4}>
                            <Typography fontSize="3" fontWeight="bold">
                                {t(changeTemplateMessages.changeTemplateConfirmationModalMobileTitle.id)}
                            </Typography>
                        </LegacyModalDialogHeader>
                        <LegacyModalDialogCloseButton
                            visuallyHiddenLabel={t(changeTemplateMessages.closeModal.id)}
                            className={styles.closeButton}
                        />
                    </LegacyModalDialogNav>
                </MobileExperience>
                <DesktopExperience>
                    <LegacyModalDialogCloseButton
                        visuallyHiddenLabel={t(changeTemplateMessages.closeModal.id)}
                        className={styles.closeButton}
                    />
                </DesktopExperience>
                <LegacyModalDialogBody>
                    <FlexBox className={styles.content}>
                        <MobileExperience>
                            <Box className={styles.mobileCTA}>
                                <Button
                                    skin="primary"
                                    size="mini"
                                    width="full-width"
                                    onClick={() => {
                                        onAccept();
                                        onRequestDismiss();
                                    }}
                                >
                                    {t(changeTemplateMessages.changeTemplateConfirmationModalButton.id)}
                                </Button>
                            </Box>
                        </MobileExperience>
                        <Box className={styles.preview}>
                            {isSmall ? (
                                <SquareImageContainer>
                                    <FluidImage
                                        className={styles.previewImage}
                                        alt={t(changeTemplateMessages.changeTemplateConfirmationImage.id)}
                                        src={previewUrl}
                                    />
                                </SquareImageContainer>
                            ) : (
                                <FluidImage
                                    className={styles.previewImage}
                                    alt={t(changeTemplateMessages.changeTemplateConfirmationImage.id)}
                                    src={previewUrl}
                                />
                            )}
                        </Box>
                        <Box className={styles.message} px={isSmall ? 5 : 7} py={isSmall ? 0 : 7} mb={isSmall ? 5 : 0}>
                            <Typography
                                textSize={isSmall ? 2 : 3}
                                fontWeight="bold"
                                mt={isSmall || isMedium ? 0 : 6}
                                mb={isMedium ? 5 : 4}
                            >
                                {t(changeTemplateMessages.changeTemplateConfirmationModalHeader.id)}
                            </Typography>
                            <Typography mr={7}>
                                {t(changeTemplateMessages.changeTemplateConfirmationModalBody.id)}
                            </Typography>
                            <DesktopExperience>
                                <Button
                                    mt={7}
                                    skin="primary"
                                    onClick={() => {
                                        onAccept();
                                        onRequestDismiss();
                                    }}
                                >
                                    {t(changeTemplateMessages.changeTemplateConfirmationModalButton.id)}
                                </Button>
                            </DesktopExperience>
                        </Box>
                    </FlexBox>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
ConfirmationModal.displayName = "ConfirmationModal";
