import { useIsFlagEnabled } from "@shared/features/Flags";
import { useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { usePreviewsContext } from "@shared/features/Previews";
import {
    useStudioFlexibility,
    useActiveFlexibilityOptions,
    FlexibilityDesignAttributes
} from "@shared/features/Flexibility";
import { useAvailablePremiumFinishesForSurfaces } from "@shared/features/PremiumFinish";

export function useIsVortexEnabled() {
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const { displayedDesignAttributeName } = useActiveFlexibilityOptions();
    const { useReactVortexPreview } = useAppSelector(state => state.studioConfiguration);
    const { hasPremiumFinishesAvailable } = useAvailablePremiumFinishesForSurfaces();
    const { previewInstructionsUrl } = usePreviewsContext();
    const templateAvailableFinishes = useAppSelector(state => state.secondaryConfig?.templateAvailableFinishes);
    // this boolean is to check whether or not we want to enable vortex on the PF flextibility panel.
    const anyAvailableFinishes =
        isMileStone2Enabled &&
        displayedDesignAttributeName === FlexibilityDesignAttributes.PremiumFinish &&
        templateAvailableFinishes &&
        templateAvailableFinishes.length > 0;

    const isFlexibilityFeaturesEnabled =
        (isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled) &&
        displayedDesignAttributeName !== FlexibilityDesignAttributes.Size;

    const forceUseVortex = useIsFlagEnabled("forceUseVortex");

    const enableVortex = useMemo(
        () =>
            (hasPremiumFinishesAvailable ||
                useReactVortexPreview ||
                anyAvailableFinishes ||
                isFlexibilityFeaturesEnabled ||
                forceUseVortex) &&
            previewInstructionsUrl,
        [
            hasPremiumFinishesAvailable,
            previewInstructionsUrl,
            useReactVortexPreview,
            anyAvailableFinishes,
            isFlexibilityFeaturesEnabled,
            forceUseVortex
        ]
    );

    return enableVortex;
}
