import React from "react";
import { DraggableShape } from "./DraggableShape";

const shapes = [{ module: "Ellipse" }];
export const DraggableEllipse = () => {
    return (
        <DraggableShape shapes={shapes}>
            <svg>
                <circle cx="50%" cy="50%" r="48%" />
            </svg>
        </DraggableShape>
    );
};

DraggableEllipse.displayName = "DraggableEllipse";
