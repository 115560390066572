import React, { ReactNode, useContext, useState, createContext } from "react";

type HideUseUploadsData = {
    hideUsedUploads: boolean;
    setHideUsedUploads: (v: boolean) => void;
};

const hideUsedUploadsContext = createContext<HideUseUploadsData>({
    hideUsedUploads: false,
    setHideUsedUploads: () => {}
});

export function useHideUsedUploads() {
    return useContext(hideUsedUploadsContext);
}

type Props = {
    children: ReactNode | ReactNode[];
};

export function HideUploadsProvider({ children }: Props) {
    // Holds the object with all the current uploads progresses
    const [hideUsedUploads, setHideUsedUploads] = useState(false);

    const { Provider } = hideUsedUploadsContext;

    const setHideUploads = (hideUploads: boolean) => {
        setHideUsedUploads(hideUploads);
    };

    return <Provider value={{ hideUsedUploads, setHideUsedUploads: setHideUploads }}>{children}</Provider>;
}
HideUploadsProvider.displayName = "HideUploadsProvider";
