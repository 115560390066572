import { useEffect, useState } from "react";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import {
    isLegacyUserUploadItem,
    isPremiumFinishImageButtonCompatibleForSelection,
    isSherbertUpload,
    isUserUploadV1Item,
    isWordArt,
    itemHasPremiumFinish,
    useActiveCanvasPremiumFinishItems,
    useStudio5AvailablePremiumFinishesOnCanvas
} from "@utilities";
import { SUPPORTED_FINISHES } from "@shared/features/PremiumFinish";
import { useAppSelector } from "@shared/redux";

export function isValidTextItem(item: CanvasItem): boolean {
    return ((item.itemType === ItemTypes.TEXT && (item as TextItem).value.join(" ").trim()) ||
        (isWordArt(item) && (item as WordArtItem).data.content)) as boolean;
}

export function isPlaceholderText(item: CanvasItem): boolean {
    return ((item.itemType === ItemTypes.TEXT && (item as TextItem).value.join(" ") === "") ||
        (isWordArt(item) && (item as WordArtItem).data.content === "")) as boolean;
}

export function isValidTextItemWithPlaceHolder(item: CanvasItem): boolean {
    return item.itemType === ItemTypes.TEXT || isWordArt(item);
}

function isImageItem(item: CanvasItem): item is ImageItem {
    return item.itemType === ItemTypes.IMAGE;
}

// This could be a type guard (item is ImageItem) but that would imply anything that returns false
// is NOT an ImageItem, which is not techincally correct.
export function isValidImageItem(item: CanvasItem): boolean {
    return (
        isImageItem(item) && (isPremiumFinishImageButtonCompatibleForSelection([item]) || itemHasPremiumFinish(item))
    );
}

export function isUserUploadedImage(item: CanvasItem) {
    return isLegacyUserUploadItem(item) || isUserUploadV1Item(item) || isSherbertUpload(item);
}

export function getValidPremiumFinishTexts(items: Array<CanvasItem>) {
    return items.filter(item => isValidTextItem(item));
}

export function isPlaceholderTextPresent(items: Array<CanvasItem>) {
    return items.find(item => isPlaceholderText(item));
}

export function getValidPremiumFinishTextsWithPlaceHolder(items: Array<CanvasItem>) {
    return items.filter(item => isValidTextItemWithPlaceHolder(item));
}

export function getValidPremiumFinishImages(items: Array<CanvasItem>) {
    return items.filter(item => isValidImageItem(item)) as ImageItem[];
}

export function useIsPremiumFinishProduct() {
    const { enabledCanvasFinish } = useStudio5AvailablePremiumFinishesOnCanvas();
    const designer = useDesigner();
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;
    const [isPremiumFinish, setIsPremiumFinish] = useState<Boolean>(false);

    useEffect(() => {
        const isPremiumFinish = Boolean(
            designer && finishType && SUPPORTED_FINISHES.includes(finishType) && !isFullBleed
        );
        setIsPremiumFinish(isPremiumFinish);
    }, [designer, finishType, isFullBleed, setIsPremiumFinish]);
    return isPremiumFinish;
}

function getNonBackgroundImages(items: Array<ImageItem>, canvas: Canvas) {
    // If we don't have any items, just return an empty array.
    // Otherwise the reduce below will complain
    if (!items.length) {
        return items;
    }
    const { width, height } = canvas.mmDimensions;
    let backGroundImage = null;
    let nonBackgroundImages = items;

    const lowestZindexImage = items.reduce((prev, curr) => {
        const prevZindex = prev._itemViewModel.model.get("zIndex");
        const curZindex = curr._itemViewModel.model.get("zIndex");
        return prevZindex < curZindex ? prev : curr;
    });

    const { width: widthL, height: heightL } = lowestZindexImage.mmDimensions;

    if (width === Math.round(widthL) && height === Math.round(heightL)) {
        backGroundImage = lowestZindexImage;
    }

    if (backGroundImage) {
        nonBackgroundImages = items.filter(item => {
            return item.id !== lowestZindexImage.id;
        });
    }

    return nonBackgroundImages;
}

export function useTemplatePremiumFinishImages(items: Array<CanvasItem>) {
    const canvas = useActiveCanvas();
    const [nonBgImages, setNonBgImages] = useState<Array<ImageItem>>([]);

    useEffect(() => {
        if (!canvas) {
            return;
        }
        const images = items.filter(
            item => isImageItem(item) && !isUserUploadedImage(item) && !itemHasPremiumFinish(item)
        ) as ImageItem[];
        const nonBackgroundImages = getNonBackgroundImages(images, canvas);
        setNonBgImages(nonBackgroundImages);
    }, [canvas, items]);

    return nonBgImages;
}

export function usePremiumFinishWarning() {
    const { premiumFinishItems } = useActiveCanvasPremiumFinishItems();
    const filteredItems = premiumFinishItems.filter(
        (item: CanvasItem) => isValidTextItemWithPlaceHolder(item) || isValidImageItem(item)
    );
    return filteredItems.length === 0;
}
