import React from "react";
import { Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages, Trans } from "@vp/i18n-helper";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { Design } from "@shared/utils/Gallery";
import { TemplatePanelDesignAvailableColors } from "./TemplatePanelAvailableColors";
import { TemplatePanelDesignTile } from "./TemplatePanelDesignTile";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";
import { useOnDesignTileClick } from "./useOnDesignTileClick";

const messages = defineMessages({
    templateGalleryNoResults: {
        id: "studio.panels.template.main.gallery.noResults",
        defaultMessage: `Sorry, we couldn't find a match for <bold>"[[term]]"</bold>.`,
        description: {
            note: "Text shown when the search results are empty"
        }
    },
    templateGalleryNoFilteredResults: {
        id: "studio.panels.template.main.gallery.noFilteredResults",
        defaultMessage: `Sorry, we couldn't find a match with your applied filters.`,
        description: {
            note: "Text shown when the applied filters results in no templates"
        }
    },
    templateLabel: {
        id: "studio.panels.template.main.gallery.templateLabel",
        defaultMessage: `Select template`,
        description: {
            note: "Alternative aria text for when no template description is available"
        }
    }
});

export const TemplatePanelGallery = () => {
    const {
        currentDesign,
        designs,
        addToStack,
        setSelectedDesign,
        changeTemplateSearchTerm,
        loading,
        anyFiltersSelected
    } = useTemplatePanel();
    const { onDesignTileClick } = useOnDesignTileClick();
    const { t } = useTranslationSSR();

    if (designs.length === 0) {
        if (loading) {
            return <LoadingSpinner className="my-3" variant={SpinnerVariant.Large} centering />;
        }

        if (changeTemplateSearchTerm) {
            return (
                <Typography textAlign="center" fontSize="1">
                    <Trans
                        t={t}
                        i18nKey={messages.templateGalleryNoResults.id}
                        components={{ bold: <strong /> }}
                        values={{ term: changeTemplateSearchTerm }}
                    />
                </Typography>
            );
        }
        if (anyFiltersSelected) {
            return (
                <Typography textAlign="center" fontSize="1">
                    <Trans t={t} i18nKey={messages.templateGalleryNoFilteredResults.id} />
                </Typography>
            );
        }
        return <LoadingSpinner className="my-3" variant={SpinnerVariant.Large} centering />;
    }
    const onClick = (design: Design) => {
        if (design.colorSwatches.length) {
            addToStack(TemplatePanelContentTypes.Color);
            setSelectedDesign(design);
        } else {
            onDesignTileClick(design);
        }
    };

    return (
        <div className="template-panel-gallery">
            {designs
                .filter(design => design.designId !== currentDesign?.designId)
                .map((design, index) => (
                    <button
                        className="template-panel-gallery-tile"
                        key={`${design.designId}-tile-${index}`}
                        onClick={() => onClick(design)}
                        aria-label={design?.altText ?? t(messages.templateLabel.id)}
                    >
                        <TemplatePanelDesignTile design={design} />
                        <TemplatePanelDesignAvailableColors design={design} />
                    </button>
                ))}
        </div>
    );
};

TemplatePanelGallery.displayName = "TemplatePanelGallery";
