import React from "react";
import { Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";
import { TemplatePanelDesignAvailableColors } from "./TemplatePanelAvailableColors";
import { TemplatePanelImage } from "./TemplatePanelImage";

const messages = defineMessages({
    templatePanelContentMainCurrentTemplatesViewAll: {
        id: "studio.panels.template.main.currentTemplate.viewAll",
        defaultMessage: "View color options",
        description: {
            note: "Label for the template panel main content current template view all"
        }
    },
    templatePanelContentMainCurrentTemplatesNoViewAll: {
        id: "studio.panels.template.main.currentTemplate.noViewAll",
        defaultMessage: "No color options available",
        description: {
            note: "Aria label for button when no alternative colors available"
        }
    }
});

export const CurrentTemplateDesignTile = () => {
    const { addToStack, currentDesign, currentTemplateColorSwatch, setSelectedDesign } = useTemplatePanel();
    const { t } = useTranslationSSR();
    const onClick = () => {
        if (!currentTemplateColorSwatch) {
            return;
        }
        addToStack(TemplatePanelContentTypes.Color);
        setSelectedDesign(currentDesign);
    };
    const { isSmall } = useStudioLayout();
    if (!currentDesign) {
        return null;
    }

    const label = currentTemplateColorSwatch
        ? t(messages.templatePanelContentMainCurrentTemplatesViewAll.id)
        : t(messages.templatePanelContentMainCurrentTemplatesNoViewAll.id);
    // Per design, the entire container was meant to be clickable.
    return (
        <button
            className="template-panel-current-container"
            onClick={onClick}
            aria-label={label}
            disabled={!currentTemplateColorSwatch}
        >
            <TemplatePanelImage
                design={currentTemplateColorSwatch || currentDesign}
                width={isSmall ? 173 : 120}
                className={"template-panel-current-container-image"}
            />
            {currentTemplateColorSwatch && (
                <div className="template-panel-current-container-colors">
                    <TemplatePanelDesignAvailableColors design={currentDesign} />
                    <Typography textAlign="left" fontSize={-1} mt={2} style={{ textDecoration: "underline" }}>
                        {t(messages.templatePanelContentMainCurrentTemplatesViewAll.id)}
                    </Typography>
                </div>
            )}
        </button>
    );
};

CurrentTemplateDesignTile.displayName = "CurrentTemplateDesignTile";
