/* eslint-disable max-len */
import React from "react";

export function TeamsGuideLine(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 200 2" fill="none" {...props}>
            <path d="M0 1L459 0.99996" stroke="black" strokeWidth="2" />
        </svg>
    );
}
TeamsGuideLine.displayName = "TeamsGuideLine";
