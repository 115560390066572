import { useActiveCanvas } from "@designer-suite";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { useTrackEvents } from "@shared/features/Tracking";
import { useAppDispatch, useAppSelector, setShowValidations } from "@shared/redux";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import classNames from "classnames";
import React, { ReactNode, useCallback, useEffect } from "react";
import * as style from "./SideBarNavButton.module.scss";

export type SideBarNavButtonProps = {
    messageId: string;
    icon: ReactNode;
    contentType: DialogType;
    disabled?: boolean;
    onClickCB?: () => void;
    clickEventDetailType?: string;
    renderEventDetail?: string;
};

export const SideBarNavButton = ({
    messageId,
    icon,
    contentType,
    onClickCB,
    clickEventDetailType,
    renderEventDetail
}: SideBarNavButtonProps) => {
    const dispatch = useAppDispatch();
    const isSmartValidationsOpen = useAppSelector(state => state.showValidations.showPanel);
    const { isSmartValidationsEnabled } = useSmartValidations();
    const { t } = useTranslationSSR();

    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { trackEvent } = useTrackEvents();
    const { isMedium } = useStudioLayout();
    const canvas = useActiveCanvas();

    const isActive = currentActiveDialog === contentType;

    // Track when the nav button is rendered
    useEffect(() => {
        if (renderEventDetail) {
            fireDesignToolTrackingEvent({
                eventDetail: renderEventDetail,
                label: `show sidebar ${clickEventDetailType || contentType} button`
            });
        }
    }, [contentType, renderEventDetail, clickEventDetailType]);

    // Track when the button is clicked
    const onClick = useCallback(() => {
        trackEvent({
            eventDetail: `${STUDIO_TRACKING_EVENTS.CLICK_SIDEBAR_BUTTON}${clickEventDetailType || contentType}`,
            label: "General",
            extraData: () => ({
                activeCanvas: canvas?.name
            })
        });
        onClickCB && onClickCB();
        setCurrentActiveDialog(current => (current !== contentType || !isMedium ? contentType : DialogType.None));
        // close right SmartValidationsPanel when left panel opens
        if (isSmartValidationsOpen && isSmartValidationsEnabled) {
            dispatch(setShowValidations({ showPanel: false }));
        }
    }, [
        trackEvent,
        clickEventDetailType,
        contentType,
        onClickCB,
        setCurrentActiveDialog,
        isSmartValidationsOpen,
        isSmartValidationsEnabled,
        canvas,
        isMedium,
        dispatch
    ]);

    return (
        <button
            className={style.root}
            onClick={onClick}
            role="tab"
            aria-controls={`side-bar-panel-${contentType}`}
            aria-selected={isActive}
        >
            <div className={classNames(style.iconContainer, { [style.active]: isActive })}>
                <div className={classNames(style.icon)}>{icon}</div>
            </div>
            <Typography textSize={7} fontWeight={isActive ? "bold" : "normal"}>
                {t(messageId)}
            </Typography>
        </button>
    );
};

SideBarNavButton.displayName = "SideBarNavButton";
