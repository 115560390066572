import React, { useState } from "react";
import { Typography, Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { duplicateDesign } from "@shared/utils/Save";
import { duplicateProjectName } from "@shared/utils/Work";
import { DuplicateProjectIcon } from "@shared/features/StudioChrome";
import { Project } from "./types";
import { messages as myProjectsMessages } from "./messages";
import { projectNamingRelatedMessages } from "./projectNamingRelatedMessages";
import * as styles from "./MyProjectsDuplicateButton.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    project: Project;
    prependProject: (project: Project) => void;
    studioEditable: boolean;
    setFocusedProject: (project: Project | null) => void;
    showMyProjectsErrorToast: (message: string, errorCode: string) => void;
    clearMyProjectsErrorToast: () => void;
}

export function MyProjectsDuplicateButton(props: Props) {
    const {
        project,
        prependProject,
        studioEditable,
        setFocusedProject,
        showMyProjectsErrorToast,
        clearMyProjectsErrorToast,
        ...rest
    } = props;

    const [isDuplicating, setIsDuplicating] = useState(false);
    const { t } = useTranslationSSR();
    const { auth } = useIdentityContext();
    const productname = useAppSelector(state => state.productName);
    const projectName = project.workName;
    const title = t(myProjectsMessages.myProjectsDuplicateProjectButtonTitle.id, { projectName });
    const handleDuplication = async () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_DUPLICATE_MY_PROJECTS,
            label: "My Projects Duplicate Button"
        });
        clearMyProjectsErrorToast();

        try {
            setFocusedProject(null);
            setIsDuplicating(true);
            const authToken = auth.getToken();
            let newName = "";
            if (project.workName && project.workName.length > 0) {
                newName = duplicateProjectName(project.workName, `${t(projectNamingRelatedMessages.appendToName.id)}`);
            } else {
                newName = t(projectNamingRelatedMessages.projectName.id, { productname });
            }
            const duplicatedProject = await duplicateDesign(project.workId, UDS_TENANT, newName, authToken);
            prependProject(duplicatedProject);
        } catch (err) {
            handleError(err, ERROR_CODES.DUPLICATE_PROJECT, false, true, true);
            showMyProjectsErrorToast(myProjectsMessages.myProjectsDuplicateError.id, "15-22");
        } finally {
            setIsDuplicating(false);
        }
    };

    return (
        <Button
            className={styles.button}
            skin="unstyled"
            disabled={isDuplicating || !studioEditable}
            title={title}
            aria-label={title}
            onClick={handleDuplication}
            {...rest}
            mr={5}
        >
            <DuplicateProjectIcon aria-hidden={true} />
            <Typography marginLeft={1} fontSize="-1">
                {t(myProjectsMessages.myProjectsDuplicateProject.id)}
            </Typography>
        </Button>
    );
}

MyProjectsDuplicateButton.displayName = "MyProjectsDuplicateButton";
