/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}

export function RedoIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M10.7174 10.0922C10.6717 10.1428 10.6173 10.183 10.5573 10.2104C10.4974 10.2378 10.433 10.2519 10.368 10.2519C10.303 10.2519 10.2387 10.2378 10.1787 10.2104C10.1188 10.183 10.0644 10.1428 10.0187 10.0922C9.92825 9.98831 9.87919 9.84942 9.88228 9.706C9.88537 9.56258 9.94036 9.42637 10.0352 9.32731L12.0522 7.2276H5.13101C4.56284 7.2276 4.01795 7.47466 3.61619 7.91444C3.21444 8.35422 2.98874 8.95069 2.98874 9.57264C2.98874 10.1946 3.21444 10.791 3.61619 11.2308C4.01795 11.6706 4.56284 11.9177 5.13101 11.9177C5.26212 11.9177 5.38787 11.9747 5.48058 12.0762C5.57329 12.1777 5.62538 12.3153 5.62538 12.4588C5.62538 12.6024 5.57329 12.74 5.48058 12.8415C5.38787 12.943 5.26212 13 5.13101 13C4.30061 13 3.50423 12.6389 2.91705 11.9962C2.32987 11.3534 2 10.4816 2 9.57264C2 8.66364 2.32987 7.79188 2.91705 7.14913C3.50423 6.50637 4.30061 6.14527 5.13101 6.14527H12.0884L10.0286 3.91207C9.94075 3.80995 9.89258 3.67449 9.89422 3.53426C9.89585 3.39402 9.94716 3.25995 10.0373 3.16031C10.1275 3.06067 10.2495 3.00325 10.3776 3.00013C10.5057 2.99702 10.6299 3.04847 10.724 3.14362L13.6408 6.28598L14 6.67561L10.7174 10.0922Z"
                fill="currentColor"
            />
        </svg>
    );
}
RedoIcon.displayName = "RedoIcon";
