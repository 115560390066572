import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import Skeleton from "react-loading-skeleton";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useActiveCanvas, useDesigner, useSelection } from "@designer-suite";
import { clamp } from "@design-stack-ct/utility-core";
import { ZoomDropdownTool, ZoomSliderTool } from "@shared/features/DesignTools";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import { useZoomKeyboardShortcuts } from "./useZoomKeyboardShortcuts";
import { useZoomManager } from "./ZoomManagerContext";

import "./zoomToolbar.scss";

const messages = defineMessages({
    sliderAltText: {
        id: "easel.designerSuite.zoomToolbar.slider",
        defaultMessage: "Zoom in/out",
        description: {
            note: "alt text for the zoom slider"
        }
    },
    zoomDropdownLabel: {
        id: "easel.ui.zoomDropdown.label",
        defaultMessage: "Zoom Percentage Scale",
        description: {
            note: "Label for the zoom dropdown"
        }
    }
});

const ZOOM_DROPDOWN_OPTIONS = [300, 200, 150, 100, 75, 50, 25];
const SLIDER_RANGE_MIN = 25;
const SLIDER_RANGE_MAX = 300;

interface Props {
    className?: string;
}

export function ZoomToolbar({ className }: Props) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const activeCanvas = useActiveCanvas("change:currentZoom");
    const selection = useSelection();
    const currentZoomFactor = activeCanvas?._canvasViewModel.get("currentZoom");
    const { canvasZoomFactor, setCanvasZoomScale, setCanvasZoomFactor } = useZoomManager();
    const [rangeValue, setRangeValue] = useState(0);
    const zoomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Fixes a problem where zoom keeps focus on selection on the canvas
        zoomRef?.current?.blur();
    }, [selection]);

    useEffect(() => {
        // Fixes a problem where zoom keeps focus on selection on the canvas
        //  Tried to use a ref to accomplish this, but it wasn't working
        const elements = document?.getElementsByClassName("zoom-toolbar-thumb");
        if (elements?.length > 0) {
            (elements[0] as HTMLDivElement).blur();
        }
    }, [selection]);

    const handleZoomSet = (zoomNumber: number, last: boolean) => {
        // Only trigger designer zoom change on last interaction and reset canvasScale.
        if (last) {
            setCanvasZoomScale(1);
            designer?.eventBus.trigger(designer.eventBus.events.zoomSet, zoomNumber / 100);
        } else {
            // Triggers a change in the canvas scale to zoom.
            const deltaScale = zoomNumber / 100 - currentZoomFactor;
            setCanvasZoomScale(deltaScale / currentZoomFactor + 1);
            setCanvasZoomFactor(zoomNumber / 100);
        }
    };

    const handleZoomSetDropdown = (currentZoom: string) => {
        const zoomNumber = Number(currentZoom);
        setCanvasZoomFactor(zoomNumber / 100);
        designer?.eventBus.trigger(designer.eventBus.events.zoomSet, zoomNumber / 100);
    };

    const dropdownValue = Math.round(canvasZoomFactor * 100);

    useZoomKeyboardShortcuts();

    useEffect(() => {
        setRangeValue(canvasZoomFactor * 100);
    }, [canvasZoomFactor]);

    useEffect(() => {
        setCanvasZoomFactor(currentZoomFactor);
    }, [currentZoomFactor, setCanvasZoomFactor]);

    return (
        <div className={classnames(className)}>
            <div className="zoom-toolbar">
                <StudioIsInteractiveSwitch>
                    <>
                        <ZoomSliderTool
                            values={[clamp(+rangeValue, SLIDER_RANGE_MIN, SLIDER_RANGE_MAX)]}
                            currentValue={+rangeValue}
                            minZoom={SLIDER_RANGE_MIN}
                            maxZoom={SLIDER_RANGE_MAX}
                            onChange={values => {
                                handleZoomSet(values[0], false);
                            }}
                            onRelease={values => {
                                handleZoomSet(values[0], true);
                            }}
                            altText={t(messages.sliderAltText.id)}
                        />
                        <ZoomDropdownTool
                            id="zoom-toolbar-dropdown"
                            value={dropdownValue}
                            options={ZOOM_DROPDOWN_OPTIONS}
                            onChange={handleZoomSetDropdown}
                            label={t(messages.zoomDropdownLabel.id)}
                            ref={zoomRef}
                        />
                    </>
                    <Skeleton className="zoom-toolbar-skeleton" height="18px" />
                </StudioIsInteractiveSwitch>
            </div>
        </div>
    );
}

ZoomToolbar.displayName = "ZoomToolbar";
