/* eslint-disable max-len */
import React from "react";

export function ImageIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <rect
                    x="3"
                    y="3"
                    width="20"
                    height="20"
                    rx="1.42857"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.49901 7.7692C6.2655 7.76982 6.07665 7.95952 6.07706 8.19303C6.07748 8.42654 6.267 8.61556 6.50051 8.61535C6.73402 8.61515 6.92321 8.42579 6.92321 8.19228C6.92321 7.95862 6.7338 7.7692 6.50014 7.7692"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.6152 17.923L15.249 11.3388C15.0935 11.1065 14.8332 10.9662 14.5537 10.9639C14.2741 10.9616 14.0116 11.0978 13.8523 11.3275L11.6016 14.5429L10.206 13.4271C10.0208 13.2792 9.78201 13.2158 9.54782 13.2522C9.31363 13.2887 9.10546 13.4217 8.97397 13.6189L6.10156 17.923H19.6152Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
ImageIcon.displayName = "ImageIcon";
