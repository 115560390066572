import React, { useEffect, useState } from "react";
import { Typography, AlertBoxDismissButton, AlertBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { calcMessageReadingTime } from "@shared/features/SystemMessages";
import { isShowWarningsMode } from "@shared/utils/Debug";
import { useAppSelector, useAppDispatch, resetWarnings } from "@shared/redux";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./Toast.module.scss";

const messages = defineMessages({
    closeButton: {
        id: "studio.components.Toast.closeButton",
        defaultMessage: "Close error message",
        description: {
            note: "Accessibility label for the close button on the error toast"
        }
    }
});

export function WarningToast() {
    const { t } = useTranslationSSR();
    const warnings = useAppSelector(state => state.warnings);
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(false);
    const [render, setRender] = useState(false);

    useEffect(() => {
        let returnFn;
        if (warnings.length > 0) {
            const timer = setTimeout(() => {
                setRender(false);
            }, calcMessageReadingTime(warnings?.join("")));
            returnFn = () => clearTimeout(timer);
        }
        return returnFn;
    }, [warnings, dispatch]);

    useEffect(() => {
        if (isShowWarningsMode() && warnings.length > 0) {
            setRender(true);
            setShow(true);
        }
    }, [warnings]);

    const onAnimationEnd = () => {
        if (!render) {
            dispatch(resetWarnings());
            setShow(false);
        }
    };

    const displayedWarnings = warnings.map((warning, index) => <li key={index}>{warning}</li>);

    if (!show) {
        return null;
    }

    return (
        <AlertBox
            skin="error"
            dismissed={!show}
            onRequestDismiss={() => setRender(false)}
            onAnimationEnd={onAnimationEnd}
            className={render ? styles.fadeIn : styles.fadeOut}
        >
            <div className={styles.toastMessageContainer}>
                <Typography fontWeight="bold">Warning(s):</Typography>
                {displayedWarnings}
            </div>
            <AlertBoxDismissButton visuallyHiddenLabel={t(messages.closeButton.id)} />
        </AlertBox>
    );
}
WarningToast.displayName = "WarningToast";
