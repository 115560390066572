import { useDesigner } from "@designer-suite";
import { useAppSelector } from "@shared/redux";
import { getDesignDocumentFromDesigner } from "@utilities";
import { useTranslationSSR } from "@vp/i18n-helper";
import type { DSS } from "@vp/types-ddif";
import debounce from "lodash/debounce";
import React, { useState, useEffect } from "react";
import { getDocumentForPreviews, PreviewsProvider } from "@shared/features/Previews";
import { defaultDocumentText } from "@shared/utils/CimDoc";
import { useGetAltTextForPreviews } from "@five/hooks/useGetAltTextForPreviews";

export function Studio5PreviewsContextAdapter({ children }: React.PropsWithChildren<{}>) {
    const [designDocument, setDesignDocument] = useState<DSS.DesignDocument>();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const isPremiumFinishModalopen = useAppSelector(state => state.isPremiumFinishModalOpen);
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const getAltTextForPreview = useGetAltTextForPreviews();

    useEffect(() => {
        if (designer && easelLoaded) {
            (async () =>
                setDesignDocument(
                    await getDocumentForPreviews(
                        isPremiumFinishModalopen,
                        async () => getDesignDocumentFromDesigner(),
                        t(defaultDocumentText.textualPlaceholders.id)
                    )
                ))();
            const debouncedUpdate = debounce(
                async () =>
                    setDesignDocument(
                        await getDocumentForPreviews(
                            isPremiumFinishModalopen,
                            async () => getDesignDocumentFromDesigner(),
                            t(defaultDocumentText.textualPlaceholders.id)
                        )
                    ),
                200
            );

            // This works better than the public event bus events - both in terms of frequency +
            // picking up changes that are relevant to update the preview for
            designer.eventBus.on(designer.eventBus.events.historyChanged, debouncedUpdate);

            //  When an image is finishing processing we want to update the preview as well
            designer.eventBus.on(designer.eventBus.events.imageProcessingUpdateModel, debouncedUpdate);

            // eslint-disable-next-line consistent-return
            return () => {
                // cancel any future updates as well as unsubscribing
                debouncedUpdate.cancel();
                designer.eventBus.off(designer.eventBus.events.historyChanged, debouncedUpdate);
                designer.eventBus.off(designer.eventBus.events.imageProcessingUpdateModel, debouncedUpdate);
            };
        }
        return () => {};
    }, [designer, easelLoaded, isPremiumFinishModalopen, t]);

    return (
        <PreviewsProvider designDocument={designDocument} getAltTextForPreview={getAltTextForPreview}>
            {children}
        </PreviewsProvider>
    );
}

Studio5PreviewsContextAdapter.displayName = "Studio5PreviewsContextAdapter";
