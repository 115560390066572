import { getSecondaryCalciferData } from "@shared/features/StudioBootstrap";
import { useAppSelector, setScenesConfiguration, useAppDispatch } from "@shared/redux";
import { getStudioConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import type { LoadNewDesignForApplyOption } from "@shared/utils/DesignPanel";
import { switchContext } from "@utilities";
import { useCallback } from "react";

export function useLoadNewDesignForApplyOptionStudio5(): LoadNewDesignForApplyOption {
    const dispatch = useAppDispatch();
    const { useColorGenericBacksides } = useAppSelector(state => state.studioConfiguration) ?? false;

    return useCallback<LoadNewDesignForApplyOption>(
        async ({
            productKey,
            productVersion,
            studioSelectedOptions,
            customerSelectedOptions,
            locale,
            getDocument,
            template,
            isFullBleed,
            quantity,
            mpvId
        }) => {
            const [newDocument, productConfigs] = await Promise.all([
                getDocument(),
                getStudioConfigFromCalciferV2(
                    productKey,
                    mpvId,
                    productVersion,
                    customerSelectedOptions,
                    studioSelectedOptions,
                    quantity,
                    locale,
                    null,
                    isFullBleed,
                    template
                )
            ]);

            await getSecondaryCalciferData({
                designDocument: newDocument,
                productKey,
                productVersion: productVersion!,
                studioSelectedProductOptions: studioSelectedOptions,
                template,
                isFullBleed,
                locale,
                isColorMatchingBacksideEnabled: useColorGenericBacksides,
                dispatch
            });

            const { designViews, scenesConfiguration: easelScenesConfiguration, studioConfiguration } = productConfigs;

            await switchContext({
                productKey,
                productOptions: studioSelectedOptions,
                designDocument: newDocument,
                views: designViews.designViews,
                studioConfiguration,
                easelScenesConfiguration
            });

            dispatch(setScenesConfiguration(easelScenesConfiguration));
        },
        [dispatch, useColorGenericBacksides]
    );
}
