import classnames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { MobileDock, MobileDockInputWrapper } from "@design-stack-vista/studio-chrome";
import { Button, Box } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { blurMobileDock, focusMobileDock, TableFontSizeSelector, TextFontSizeSelector } from "@designer-suite";
import { getSelectedItemTypes, getTrackingDataForSelection } from "@utilities";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import WordArtFontSizeButtonContainer from "../ToolbarComponents/WordArtFontSize/WordArtFontSizeButtonContainer";
import TeamsFontSizeButtonContainer from "../ToolbarComponents/TeamsFontSize/TeamsFontSizeButtonContainer";
import { ContextualTextEditor } from "../ContextualTextEditor";
import { useMobileText } from "./MobileTextProvider";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { useActiveCanvas } from "../designer/useActiveCanvas";
import { getTopItemPageBoundingBox } from "../util";
import type { ItemSelection } from "../@types/designer";
import "./MobileDockedTools.scss";

const messages = defineMessages({
    done: {
        id: "easel.ui.mobiletexteditor.done",
        defaultMessage: "Done"
    }
});

interface Props {
    className?: string;
}

function shouldShow(contentType: string, animatingOut: boolean) {
    switch (contentType) {
        case DialogType.TextInput:
            return !animatingOut;
        case DialogType.FontSizeSelector:
            return true;
        default:
            return false;
    }
}

export function MobileDockedTools({ className }: Props) {
    const selection = useSelection("change:selecting");
    const canvas = useActiveCanvas();
    const designer = useDesigner();
    const [firstClick, setFirstClick] = useState(true);
    const { t } = useTranslationSSR();
    const [height, setHeight] = useState(0);
    const mobileDockBoxRef = useRef<HTMLDivElement>(null);

    const { setIsTextInputFocused, isTextInputFocused, animatingOut, setAnimatingOut } = useMobileText();

    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();

    const isVisible = shouldShow(currentActiveDialog, animatingOut);

    const openToolSheet = useCallback(
        (selection?: ItemSelection) => {
            setAnimatingOut(true);
            setIsTextInputFocused(false);
            blurMobileDock();
            const selectionType = selection ? getSelectedItemTypes(selection)[0] : null;
            switch (selectionType) {
                case ItemTypes.TEXT:
                    setCurrentActiveDialog(DialogType.TextTools);
                    break;
                case ItemTypes.WORDART:
                    setCurrentActiveDialog(DialogType.WordArtTools);
                    break;
                case ItemTypes.TABLE:
                    setCurrentActiveDialog(DialogType.TablesTools);
                    break;
                default:
                    setCurrentActiveDialog(DialogType.None);
                    break;
            }
            setTimeout(() => {
                setHeight(0);
                setIsTextInputFocused(false);
                setAnimatingOut(false);
            }, 200);
        },
        [setCurrentActiveDialog, setIsTextInputFocused, setAnimatingOut]
    );

    const onDone = useCallback(() => {
        openToolSheet(selection);
    }, [openToolSheet, selection]);

    const onInputBlur = () => {
        setIsTextInputFocused(false);
    };

    useEffect(() => {
        let resizeObserver: ResizeObserver;
        if (mobileDockBoxRef.current && isVisible) {
            resizeObserver = new ResizeObserver(entries => {
                const element = entries.find(
                    entry => entry.target === mobileDockBoxRef?.current?.getElementsByClassName("studio-mobile-dock")[0]
                );
                if (element) {
                    setHeight(element.target.getBoundingClientRect().height);
                }
            });
            resizeObserver.observe(mobileDockBoxRef.current.getElementsByClassName("studio-mobile-dock")[0]);
        } else {
            setHeight(0);
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [mobileDockBoxRef, isVisible]);

    useEffect(() => {
        if (selection.length === 0 && currentActiveDialog === DialogType.TextInput) {
            onDone();
        }
    }, [selection, setIsTextInputFocused, onDone, currentActiveDialog]);

    useEffect(() => {
        if (isTextInputFocused) {
            document.body.setAttribute("data-dcl-prevent-canvas-items-deselection", "");
        } else {
            document.body.removeAttribute("data-dcl-prevent-canvas-items-deselection");
        }
    }, [isTextInputFocused]);

    // This scrolls the page up if the text editor is going to cover up the text being edited
    useEffect(() => {
        // The positions of the selection and text editor. Used for preventing overlap
        const itemBox = canvas ? getTopItemPageBoundingBox(selection, canvas) : null;
        const mobileDockPosition = document.querySelector("div.studio-mobile-dock")?.getBoundingClientRect().bottom;
        if (
            canvas &&
            itemBox &&
            mobileDockPosition &&
            shouldShow(currentActiveDialog, animatingOut) &&
            selection.length > 0 &&
            mobileDockPosition + window.scrollY > itemBox.top
        ) {
            window.scrollBy({
                top: itemBox.top - (window.scrollY + mobileDockPosition + 10),
                left: 0,
                behavior: "smooth"
            });
        }
    }, [canvas, currentActiveDialog, selection, animatingOut]);

    useEffect(() => {
        if (!isTextInputFocused && currentActiveDialog === DialogType.TextInput && !animatingOut) {
            onDone();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTextInputFocused, onDone]);

    useEffect(() => {
        if (!designer || !selection.length || !canvas) {
            setFirstClick(true);
            return;
        }
        const selecting = !!selection[0]._itemViewModel.get("selecting");
        const dragging = !!selection[0]._itemViewModel.get("dragging");
        if (
            // This will update when the user taps and lets go of the item unless the item was dragged
            !selecting &&
            !dragging &&
            !firstClick
        ) {
            switch (currentActiveDialog) {
                case DialogType.TextTools:
                case DialogType.WordArtTools:
                    designer.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_ITEM_EDIT_TEXT,
                        getTrackingDataForSelection(selection)
                    );
                    focusMobileDock();
                    setCurrentActiveDialog(DialogType.TextInput);
                    setIsTextInputFocused(true);
                    break;
                case DialogType.TextInput:
                    openToolSheet(selection);
                    break;
                default:
                    break;
            }
        }
        if (
            !selecting &&
            (currentActiveDialog === DialogType.TextTools || currentActiveDialog === DialogType.WordArtTools)
        ) {
            setFirstClick(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    function getTool() {
        if (currentActiveDialog === DialogType.FontSizeSelector) {
            switch (getSelectedItemTypes(selection)[0]) {
                case ItemTypes.TEXT:
                    return <TextFontSizeSelector mobileDock expandable={false} />;
                case ItemTypes.WORDART:
                    return <WordArtFontSizeButtonContainer mobileDock expandable={false} />;
                case ItemTypes.TABLE:
                    return <TableFontSizeSelector mobileDock expandable={false} />;
                case ItemTypes.TEAMS:
                    return <TeamsFontSizeButtonContainer mobileDock expandable={false} />;
                default:
                    return <></>;
            }
        } else if (currentActiveDialog === DialogType.TextInput) {
            return (
                <ContextualTextEditor
                    autoMaxTop={false}
                    toolbarId="studio-contextual-toolbar-container"
                    autoPosition={false}
                    shouldToggleTextEditor={false}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={true}
                />
            );
        }
        return <></>;
    }

    return (
        <div className="studio-mobile-dock-container" style={{ height }}>
            <div ref={mobileDockBoxRef}>
                <MobileDock
                    data-dcl-prevent-canvas-items-deselection
                    className={classnames("studio-mobile-dock", className, {
                        "studio-mobile-dock-focused": isTextInputFocused
                    })}
                    visible={isVisible}
                    position="top"
                >
                    <Box
                        className={classnames("studio-mobile-dock-items", {
                            "studio-mobile-dock-text-input": currentActiveDialog === DialogType.TextInput
                        })}
                    >
                        <MobileDockInputWrapper onBlur={onInputBlur} onIosKeyboardDone={onDone}>
                            {getTool()}
                        </MobileDockInputWrapper>
                        {/* Need the disabled so not focusable and screenreaders ignore it */}
                        <Button
                            className={classnames("easel-text-done", {
                                hidden: !isVisible
                            })}
                            onClick={onDone}
                            disabled={!isVisible}
                        >
                            {t(messages.done.id)}
                        </Button>
                    </Box>
                </MobileDock>
            </div>
        </div>
    );
}

MobileDockedTools.displayName = "MobileDockedTools";
