import React from "react";
import { FlexBox, H6, Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { InfiniteScrollList } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { TemplatePanelGallery } from "./TemplatePanelGallery";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";
import { CurrentTemplateDesignTile } from "./CurrentTemplateDesignTile";
import { RecentTemplates } from "./RecentTemplates";
import { TemplateSearchBar } from "./TemplatePanelSearchBar";

const messages = defineMessages({
    templatePanelContentMainCurrentTemplates: {
        id: "studio.panels.template.main.currentTemplate",
        defaultMessage: "Current Template",
        description: {
            note: "Sub header for the template panel main content current template"
        }
    },
    templatePanelContentMainRecentTemplates: {
        id: "studio.panels.template.main.RecentTemplate",
        defaultMessage: "Recent Templates",
        description: {
            note: "Sub header for the template panel main content recent template"
        }
    },
    templatePanelContentMainAllTemplates: {
        id: "studio.panels.template.main.allTemplates",
        defaultMessage: "All Templates",
        description: {
            note: "Sub header for the template panel main content all templates"
        }
    },
    templatePanelContentViewAll: {
        id: "studio.panels.template.main.viewAll",
        defaultMessage: "View All",
        description: {
            note: "text that will display on the view all button"
        }
    }
});

export const TemplatePanelContentMain = () => {
    const { t } = useTranslationSSR();
    const {
        designs,
        hasMore,
        performNextSearch,
        currentDesign,
        recentlyUsedTemplates,
        addToStack,
        changeTemplateSearchTerm,
        anyFiltersSelected
    } = useTemplatePanel();
    const hideCurrentAndRecent = changeTemplateSearchTerm || anyFiltersSelected;
    return (
        <>
            <TemplateSearchBar className="template-search-bar" />
            <InfiniteScrollList
                hasMore={hasMore}
                onNext={() => performNextSearch()}
                count={designs.length}
                id="template-panel-scroll-list"
                disableAutoSearch
                spinnerClassName="template-panel-scroll-list-spinner"
            >
                {!hideCurrentAndRecent && (
                    <div>
                        {currentDesign && (
                            <>
                                <H6 mb={2}>{t(messages.templatePanelContentMainCurrentTemplates.id)}</H6>
                                <CurrentTemplateDesignTile />
                            </>
                        )}
                        {recentlyUsedTemplates.length !== 0 && (
                            <div className="template-panel-recently-used-carousel-container">
                                <FlexBox justifyContent="space-between" alignItems="baseline">
                                    <H6>{t(messages.templatePanelContentMainRecentTemplates.id)}</H6>
                                    <button
                                        className="template-panel-recent-view-all"
                                        onClick={() => {
                                            fireDesignToolTrackingEvent({
                                                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_RECENTLY_USED_VIEW_ALL,
                                                label: "Click template panel recently used view all",
                                                extraData: () => ({
                                                    recentlyUsedTemplates: recentlyUsedTemplates.length
                                                })
                                            });
                                            addToStack(TemplatePanelContentTypes.Recent);
                                        }}
                                    >
                                        <Typography fontSize={-1} style={{ textDecoration: "underline" }}>
                                            {t(messages.templatePanelContentViewAll.id)}
                                        </Typography>
                                    </button>
                                </FlexBox>
                                <RecentTemplates />
                            </div>
                        )}
                        {(currentDesign || recentlyUsedTemplates.length !== 0) && (
                            <H6 mb={2}>{t(messages.templatePanelContentMainAllTemplates.id)}</H6>
                        )}
                    </div>
                )}
                <TemplatePanelGallery />
            </InfiniteScrollList>
        </>
    );
};

TemplatePanelContentMain.displayName = "TemplatePanelContentMain";
