import { defineMessages } from "@vp/i18n-helper";

export const fontSizeMessages = defineMessages({
    fontSizeSelectorTitle: {
        id: "easel.designerSuite.fontSizeSelector.title",
        defaultMessage: "Font Size",
        description: {}
    },
    increaseFontSizeLabel: {
        id: "easel.ui.fontSize.increaseLabel",
        defaultMessage: "Increase font size",
        description: {
            note: "Aria label for a button to increase font size (+)"
        }
    },
    decreaseFontSizeLabel: {
        id: "easel.ui.fontSize.decreaseLabel",
        defaultMessage: "Decrease font size",
        description: {
            note: "Aria label for a button to decrease font size (-)"
        }
    },
    fontSizeDone: {
        id: "easel.ui.fontSize.done",
        defaultMessage: "Done"
    },
    fontSizeText: {
        id: "easel.ui.fontSize.size",
        defaultMessage: "Size",
        description: {
            note: "Label for a font size component"
        }
    },
    invalidFontSize: {
        id: "easel.ui.fontSize.invalid",
        defaultMessage: "Invalid font size",
        description: {
            note: "Tooltip when the user has entered an invalid font size"
        }
    }
});
