import { useEffect, useState } from "react";
import { useDesigner } from "@designer-suite";
import type { Upload } from "../../@types/upload";

const sortByCreation = (a: any, b: any) => {
    // we want all of the new uploads to be sorted first, then all the old ones
    const aIsPrevious = a.get("previousUpload");
    const bIsPrevious = b.get("previousUpload");
    if (aIsPrevious !== bIsPrevious) {
        if (!aIsPrevious) {
            return -1;
        }
        if (!bIsPrevious) {
            return 1;
        }
    }
    // Then we want to sort by creation time
    const aCreatedMs = a.get("createdMs");
    const bCreatedMs = b.get("createdMs");

    // In the off chance they are both equal (which could happen for new uploads) don't change their order
    if (aCreatedMs !== bCreatedMs) {
        return aCreatedMs < bCreatedMs ? 1 : -1;
    }
    return 0;
};

function useUploads() {
    const [userUploads, setUserUploads] = useState<Upload[]>([]);
    const designer = useDesigner();

    useEffect(() => {
        if (!designer) {
            return;
        }
        const updateUploadList = () => {
            if (!designer) {
                return;
            }
            const userUploadPages: Upload[] = [];
            designer.uploadManager.forEach((upload: Upload) => {
                // Anything that loaded while the uploads are initialize should be added to the front of the list
                // Previous uploads are added at the end of the list. (As they come back previous upload most recent to least)
                // So the list will look like [new uploads, most recent to the least] then [previous uploads from most recent to least]
                // If it's a previous upload, meaning it came from the uploads server, push it towards the end
                // Otherwise push it on the front of the list.
                if (upload.get("previousUpload")) {
                    userUploadPages.push(upload);
                } else {
                    userUploadPages.unshift(upload);
                }
            });
            userUploadPages.sort(sortByCreation);
            setUserUploads(userUploadPages);
        };
        function onAddUpload(upload: Upload) {
            upload.get("pages").on("add", updateUploadList);
            updateUploadList();
        }
        designer.uploadManager.on("add", onAddUpload);
        designer.uploadManager.forEach((upload: Upload) => {
            upload.get("pages").on("add", updateUploadList);
        });
        designer.uploadManager.on("remove", updateUploadList);
        // This is an event easel is invoking on the upload manager when easel removes a page
        designer.uploadManager.on("pageRemoved", updateUploadList);
        updateUploadList();
        // eslint-disable-next-line consistent-return
        return () => {
            designer.uploadManager.off("add", onAddUpload);
            designer.uploadManager.off("remove", updateUploadList);
            designer.uploadManager.off("pageRemoved", updateUploadList);
        };
    }, [designer]);

    return userUploads;
}

useUploads.displayName = "useUploads";

export { useUploads };
