import { useState, useEffect } from "react";
import { getPremiumFinishes, itemHasPremiumFinishSpotColor, useActiveCanvas, useDesigner } from "@designer-suite";
import {
    applyWordArtPremiumFinish,
    removeWordArtPremiumFinish,
    wordartHasSpotColor,
    updateSelectedItems,
    wordArtFilter,
    getTrackingDataForSelection,
    applyTextPremiumFinish
} from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import type { Designer, ItemSelection } from "../../designer-suite/@types/designer";

function onApply(
    designer: Designer,
    canvas: Canvas | undefined,
    selection: CanvasItem[],
    hasPremiumFinishSpotcolor: boolean
) {
    applyTextPremiumFinish(designer, canvas, selection, hasPremiumFinishSpotcolor);
}

function textHasSpotColor(selection: CanvasItem[]) {
    return !!selection.length && selection.every(itemHasPremiumFinishSpotColor);
}

type Props = {
    selection: ItemSelection;
};

export function usePremiumFinishWordArtAndTextToggle({ selection }: Props) {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const { wordArtItems, otherItems } = wordArtFilter(designer, selection);
    const [hasFinish, setHasFinish] = useState<boolean>(false);
    const hasTextItems = otherItems && otherItems.length > 0;
    const hasWordArtItems = wordArtItems && wordArtItems.length > 0;
    const { setCurrentActiveDialog, currentActiveDialog } = useActiveDialog();

    useEffect(() => {
        if (hasTextItems && hasWordArtItems) {
            setHasFinish(textHasSpotColor(otherItems) && wordartHasSpotColor(wordArtItems));
        } else if (hasTextItems) {
            setHasFinish(textHasSpotColor(otherItems));
        } else {
            setHasFinish(wordartHasSpotColor(wordArtItems));
        }
    }, [hasTextItems, hasWordArtItems, wordArtItems, otherItems]);

    function onChange() {
        if (!designer || !canvas) {
            return;
        }
        designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_PREMIUM_FINISH, {
            ...getTrackingDataForSelection(selection),
            finishApplied: !hasFinish
        });

        // Close the color panel if its open and this is raised foil
        // it shouldn't be open if we are removing the PF so this is a no-op
        const { color } = getPremiumFinishes(canvas)[0];
        if (currentActiveDialog === DialogType.TextColorPicker && color.toLowerCase().includes("raisedfoil")) {
            setCurrentActiveDialog(DialogType.Text);
        }

        if (hasTextItems) {
            // Assumes a non empty set of items
            onApply(designer, canvas, otherItems, hasFinish);
        }

        // Apply the change to Word Art - invert from current value
        if (hasWordArtItems) {
            if (hasFinish) {
                updateSelectedItems(designer, wordArtItems, removeWordArtPremiumFinish());
            } else {
                applyWordArtPremiumFinish(designer, canvas, wordArtItems);
            }
        }
    }

    return { hasFinish, onChange };
}
