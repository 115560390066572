import { useTrackEvents } from "@shared/features/Tracking";
import { useCookie } from "@shared/features/WebBrowser";
import { setShowTeamsGuideModal, useAppDispatch } from "@shared/redux";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

const TEAMS_GUIDE_MODAL_COOKIE_NAME = "studio-teams-guide-modal-seen";

export const useShowTeamsModalCallback = () => {
    const dispatch = useAppDispatch();
    const { cookieValue, setCookieValue } = useCookie(TEAMS_GUIDE_MODAL_COOKIE_NAME, () => ({
        expires: 21
    }));
    const { trackEvent } = useTrackEvents();

    function setShowTeamsGuideModalOnFirstClick() {
        if (cookieValue !== "true") {
            trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.SHOW_TEAMS_EXPERIENCE_GUIDE_MODAL });
            setCookieValue("true");
            dispatch(setShowTeamsGuideModal(true));
        }
    }

    return setShowTeamsGuideModalOnFirstClick;
};
