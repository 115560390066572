import React from "react";
import classNames from "classnames";
import { RenderImageProps } from "react-photo-gallery";
import { Status } from "@design-stack-vista/image-library-react";
import { Button, FlexBox, Typography, Box } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { renderImage } from "../../../components/Panels/Images/ImageRenderer";
import { ElementsPanelContentTypes, useElementsPanel } from "./ElementsPanelProvider";
import { IconThumbnail } from "../../../components/Panels/Images/IconThumbnail";
import { ElementsScroller } from "./ElementsScroller";
import { ElementsRowArrowButton } from "./ElementsRowArrowButton";
import { ElementUploadItem } from "./ElementsPanelUtils";
import * as styles from "./ElementsRow.module.scss";

const messages = defineMessages({
    viewAll: {
        id: "studio.features.elements.viewAll",
        defaultMessage: "View all",
        description: {
            note: "Text that will show on a button to view all elements"
        }
    },
    viewAllShapes: {
        id: "studio.features.elements.viewAllShapes",
        defaultMessage: "View all shapes",
        description: {
            note: "Aria label for button to view all shapes"
        }
    },
    viewAllIcons: {
        id: "studio.features.elements.viewAllIcons",
        defaultMessage: "View all icons",
        description: {
            note: "Aria label for button to view all icons"
        }
    },
    viewAllImages: {
        id: "studio.features.elements.viewAllImages",
        defaultMessage: "View all images",
        description: {
            note: "Aria label for button to view all images"
        }
    },
    viewAllClipart: {
        id: "studio.features.elements.viewAllClipart",
        defaultMessage: "View all clipart",
        description: {
            note: "Aria label for button to view all clipart"
        }
    },
    error: {
        id: "studio.features.elements.error",
        defaultMessage: `We couldn't load these elements - please try again.`,
        description: {
            note: "Message shown when there is an error loading elements"
        }
    },
    shapesRowTitle: {
        id: "studio.features.elements.shapesRowTitle",
        defaultMessage: "Shapes",
        description: {
            note: "Title for the shapes row in the elements panel"
        }
    },
    iconsRowTitle: {
        id: "studio.features.elements.iconsRowTitle",
        defaultMessage: "Icons",
        description: {
            note: "Title for the icons row in the elements panel"
        }
    },
    imagesRowTitle: {
        id: "studio.features.elements.imagesRowTitle",
        defaultMessage: "Images",
        description: {
            note: "Title for the images row of the elements panel"
        }
    },
    clipartRowTitle: {
        id: "studio.features.elements.clipartRowTitle",
        defaultMessage: "Clipart",
        description: {
            note: "Title for the clipart section of the elements panel"
        }
    },
    recentlyUsedRowTitle: {
        id: "studio.features.elements.recentlyUsedRowTitle",
        defaultMessage: "Recently used",
        description: {
            note: "Title for the recently used images section of the elements panel"
        }
    }
});

const ariaLabel = {
    [ElementsPanelContentTypes.Shapes]: messages.viewAllShapes.id,
    [ElementsPanelContentTypes.Icons]: messages.viewAllIcons.id,
    [ElementsPanelContentTypes.Images]: messages.viewAllImages.id,
    [ElementsPanelContentTypes.Clipart]: messages.viewAllClipart.id
};

const rowTitle = {
    [ElementsPanelContentTypes.Shapes]: messages.shapesRowTitle.id,
    [ElementsPanelContentTypes.Icons]: messages.iconsRowTitle.id,
    [ElementsPanelContentTypes.Images]: messages.imagesRowTitle.id,
    [ElementsPanelContentTypes.Clipart]: messages.clipartRowTitle.id,
    [ElementsPanelContentTypes.Recent]: messages.recentlyUsedRowTitle.id
};

type ElementsRowProps = {
    contentType: ElementsPanelContentTypes;
    items: Partial<ElementUploadItem>[];
    status: Status;
};

export const ElementsRow = (props: ElementsRowProps) => {
    const { addToStack, currentElementsPanelSearchTerm } = useElementsPanel();
    const { contentType, items, status } = props;
    const { t } = useTranslationSSR();

    const handleViewAllClick = (contentType: ElementsPanelContentTypes) => {
        addToStack(contentType);
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ELEMENTS_VIEW_ALL,
            label: `Click view all: ${contentType}`
        });
    };

    const renderItems = () => {
        return (
            <>
                {items.map((item, index) => {
                    const { key, id, src, width, height, upload, imageSource, svgUrl, type, extension, component } =
                        item;

                    if (component) {
                        return component;
                    }
                    if (item.imageSource === "NounProject") {
                        const iconSrc = src!;
                        return (
                            <IconThumbnail
                                key={key}
                                id={key}
                                src={iconSrc}
                                width={width}
                                height={height}
                                // @ts-ignore
                                upload={upload}
                                imageSource={imageSource}
                                className={classNames({
                                    [styles.recentIcons]: contentType === ElementsPanelContentTypes.Recent
                                })}
                            />
                        );
                    }

                    const imageWidth = width!;
                    const imageHeight = height!;

                    const aspectRatio = imageWidth / imageHeight;
                    const targetHeight = 80;
                    const calculatedWidth = targetHeight * aspectRatio;

                    const itemImageProps = {
                        index,
                        direction: "row",
                        onClick: null,
                        photo: {
                            id,
                            src,
                            width: calculatedWidth,
                            height: imageHeight < targetHeight ? imageHeight : targetHeight,
                            upload,
                            svgUrl,
                            imageSource,
                            type,
                            extension
                        }
                    } as RenderImageProps;
                    return renderImage(itemImageProps);
                })}

                {items.length >= 5 && (
                    <ElementsRowArrowButton
                        onClick={() => handleViewAllClick(contentType)}
                        aria-label={t(ariaLabel[contentType])}
                    />
                )}
            </>
        );
    };

    // don't show the row if there are no search results for this element type
    if (status === Status.Idle && items.length === 0 && currentElementsPanelSearchTerm) {
        return null;
    }

    return (
        <Box mb={5}>
            <FlexBox justifyContent="space-between">
                <Typography fontSize={1} fontWeight="bold">
                    {t(rowTitle[contentType])}
                </Typography>
                {status !== Status.Error && (
                    // TODO: Replace this loading state with SkeletonText when we upgrade SWAN
                    <Button
                        skin="link"
                        className={classNames(styles.elementsRowButton, {
                            "swan-bgc-loading-shimmer": status === Status.Loading,
                            [styles.textLoading]: status === Status.Loading
                        })}
                        fontSize={-1}
                        onClick={() => handleViewAllClick(contentType)}
                        aria-label={t(ariaLabel[contentType])}
                        disabled={status === Status.Loading}
                    >
                        {t(messages.viewAll.id)}
                    </Button>
                )}
            </FlexBox>
            <ElementsScroller
                className={classNames(styles.elementsRowItems, {
                    [styles.shapesThumbnails]:
                        contentType === ElementsPanelContentTypes.Shapes ||
                        contentType === ElementsPanelContentTypes.Icons,
                    [styles.recentlyUsed]: contentType === ElementsPanelContentTypes.Recent
                })}
            >
                {status === Status.Idle && items.length > 0 && renderItems()}
                {status === Status.Loading && (
                    <div
                        className={classNames("swan-bgc-loading-shimmer", styles.elementsSkeleton, {
                            [styles.iconSkeleton]:
                                contentType === ElementsPanelContentTypes.Shapes ||
                                contentType === ElementsPanelContentTypes.Icons
                        })}
                    />
                )}
                {status === Status.Error && (
                    <Typography textAlign="center" fontSize="1" paddingTop={3} paddingX={6}>
                        {t(messages.error.id)}
                    </Typography>
                )}
            </ElementsScroller>
        </Box>
    );
};

ElementsRow.displayName = "ElementsRow";
