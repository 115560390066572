import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { DraggableUploadsPanelProvider } from "@designer-suite";
import { PanelContent } from "@shared/features/StudioChrome";
import { ElementsPanelContentTypes, useElementsPanel } from "./ElementsPanelProvider";
import { ElementsPanelContentMain } from "./ElementsPanelContentMain";
import { ElementsPanelContentClipart } from "./ElementsPanelContentClipart";
import { ElementsPanelContentImages } from "./ElementsPanelContentImages";
import { ElementsPanelContentShapes } from "./ElementsPanelContentShapes";
import { ElementsPanelContentRecents } from "./ElementsPanelContentRecents";
import { ElementsPanelTitle } from "./ElementsPanelTitle";
import { ElementsSearchBar } from "./ElementsSearchBar";
import * as styles from "./ElementsPanel.module.scss";

const panels: Record<ElementsPanelContentTypes, JSX.Element> = {
    [ElementsPanelContentTypes.Main]: <ElementsPanelContentMain />,
    [ElementsPanelContentTypes.Shapes]: <ElementsPanelContentShapes />,
    [ElementsPanelContentTypes.Icons]: <ElementsPanelContentShapes />,
    [ElementsPanelContentTypes.Images]: <ElementsPanelContentImages />,
    [ElementsPanelContentTypes.Clipart]: <ElementsPanelContentClipart />,
    [ElementsPanelContentTypes.Recent]: <ElementsPanelContentRecents />
};

export const ElementsPanel = () => {
    const { stack } = useElementsPanel();
    const current = stack[stack.length - 1];
    const backButtonRef = useRef<HTMLButtonElement>(null);
    const searchButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        // handle focus when moving between views
        if (stack.length > 1) {
            backButtonRef.current?.focus();
        } else {
            searchButtonRef.current?.focus();
        }
    }, [stack]);

    const isRecentlyUsedPanel = current === ElementsPanelContentTypes.Recent;

    return (
        <DraggableUploadsPanelProvider isDraggable={!isMobile}>
            <PanelContent className={styles.elementsPanelContent}>
                <ElementsPanelTitle ref={backButtonRef} />
                {!isRecentlyUsedPanel && <ElementsSearchBar contentType={current} ref={searchButtonRef} />}
                {panels[current]}
            </PanelContent>
        </DraggableUploadsPanelProvider>
    );
};

ElementsPanel.displayName = "ElementsPanel";
