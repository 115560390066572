import { FC } from "react";
import { createPortal } from "react-dom";

/**
 * Creates a portal on the dcl-items layer so that the finishes can be rendered along-side the items
 * This needs to use a portal because dcl-items is managed by Backbone
 */
export const ItemLayerPortal: FC = ({ children }) => {
    const container = document.getElementsByClassName("dcl-items")[0];

    if (!container) {
        return null;
    }

    // Portal appends elements to the existing container instead of replacing it
    return createPortal(children, container);
};
ItemLayerPortal.displayName = "ItemLayerPortal";
