import debounce from "lodash/debounce";

import {
    STUDIO_TRACKING_EVENTS,
    fireDesignToolTrackingEvent,
    fireGenericTrackingEvent,
    firePerformanceTrackingEvent,
    STUDIO_DIAGNOSTIC_EVENT
} from "@shared/utils/Tracking";

import designerApiEvents from "./designerApiEvents";
import events from "./events";

const DEBOUNCE_WAIT_MILLISECONDS = 5000;

// skipGenericEventArray is used to skip any generic tracking setup below so they can be customized.
export default function setupEventTracking(
    setupUniqueTrackingEvents?: () => void,
    skipGenericEventArray: string[] = []
) {
    const genericTrackingEvents = events();
    const apiEvents = designerApiEvents(window.designer!);

    genericTrackingEvents.forEach(event => {
        window.designer!.eventBus.on(
            event.designerEvent,
            debounce((data, ...rest) => {
                if (
                    !skipGenericEventArray.includes(event.eventName) &&
                    !skipGenericEventArray.includes(event.designerEvent)
                ) {
                    fireGenericTrackingEvent({
                        event: STUDIO_DIAGNOSTIC_EVENT,
                        eventDetail: event.eventName,
                        label: event.labelName,
                        extraData: () => event.processData(data, rest)
                    });
                }
            }, DEBOUNCE_WAIT_MILLISECONDS)
        );
    });
    apiEvents.forEach(event =>
        window.designer!.api.events.subscribe(event.eventType, evt =>
            evt.items
                .filter(item => item.itemType === event.itemType)
                .forEach(filteredItem => {
                    fireGenericTrackingEvent({
                        event: STUDIO_DIAGNOSTIC_EVENT,
                        ...event.getEventProperties(filteredItem)
                    });
                })
        )
    );

    Object.values(STUDIO_TRACKING_EVENTS).forEach(easelEvent => {
        if (!easelEvent.includes(STUDIO_TRACKING_EVENTS.TIMING_ENGINE_START)) {
            window.designer!.eventBus.on(easelEvent, (eventData: EventData) => {
                fireDesignToolTrackingEvent({
                    eventDetail: easelEvent,
                    extraData: () => ({ ...eventData })
                });
            });
        } else {
            window.designer!.eventBus.on(easelEvent, (eventData: EventData) => {
                firePerformanceTrackingEvent({
                    eventDetail: easelEvent,
                    label: "cimpress-designer engine start-up time",
                    extraData: () => ({ ...eventData })
                });
            });
        }
    });
    if (setupUniqueTrackingEvents) {
        setupUniqueTrackingEvents();
    }
}
