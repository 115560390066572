import React from "react";
import { Typography, Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Project } from "./types";
import { DeleteIcon } from "./icons/DeleteIcon";
import { messages } from "./messages";
import * as styles from "./MyProjectsDeleteButton.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    project: Project;
    removeProject: (project: Project) => void;
    studioEditable: boolean;
    setFocusedProject: (project: Project | null) => void;
    showMyProjectsErrorToast: (message: string, errorCode: string) => void;
    clearMyProjectsErrorToast: () => void;
    showDeleteConfirmation: (project: Project) => void;
}

export function MyProjectsDeleteButton(props: Props) {
    const { project, setFocusedProject, showDeleteConfirmation } = props;
    const { t } = useTranslationSSR();
    const projectName = project.workName;
    const title = t(messages.myProjectsDeleteButtonTitle.id, { projectName });

    const handleClick = () => {
        setFocusedProject(null);
        showDeleteConfirmation(project);
    };

    return (
        <Button
            className={styles.button}
            skin={"unstyled"}
            buttonShape="round"
            onClick={handleClick}
            title={title}
            aria-label={title}
        >
            <DeleteIcon aria-hidden={true} />
            <Typography marginLeft={1} fontSize="-1">
                {t(messages.myProjectsDelete.id)}
            </Typography>
        </Button>
    );
}

MyProjectsDeleteButton.displayName = "MyProjectsDeleteButton";
