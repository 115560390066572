import React from "react";
import { ScreenClassProvider } from "@vp/swan";
import { defaultTheme } from "@presentational";
import { ActiveDialogProvider } from "@shared/features/ActiveDialog";
import { TableSubselectionProvider } from "@shared/features/Tables";
import { getFontCategories } from "@shared/utils/Fonts";
import { DesignerSuiteProvider } from "./DesignerSuiteProvider";
import { EmbroideryUploadWarningModal } from "./Modals/EmbroideryUploadWarningModal";
import { HideUploadsProvider } from "./PanelComponents/Uploads/HideUploadsProvider";
import { UploadsProgressProvider } from "./PanelComponents/Uploads/UploadsProgressProvider";
import { ContextualToolbarProvider } from "./ContextualToolbarProvider";

export default function EaselProvider({
    designerOptions,
    onStart,
    children,
    errorCallBack
}: {
    designerOptions?: Options;
    onStart?: () => void;
    children?: React.ReactNode;
    errorCallBack?: (error: { errorMessage: string; errorCodeStack: string }) => void;
}) {
    const providedFonts = getFontCategories();

    return (
        <DesignerSuiteProvider
            autoLoadDesigner
            options={designerOptions}
            theme={defaultTheme}
            onStart={onStart}
            fonts={providedFonts}
            errorCallBack={errorCallBack}
        >
            <HideUploadsProvider>
                <UploadsProgressProvider>
                    <ScreenClassProvider>
                        <TableSubselectionProvider>
                            <ActiveDialogProvider>
                                <ContextualToolbarProvider>
                                    {children}
                                    <EmbroideryUploadWarningModal />
                                </ContextualToolbarProvider>
                            </ActiveDialogProvider>
                        </TableSubselectionProvider>
                    </ScreenClassProvider>
                </UploadsProgressProvider>
            </HideUploadsProvider>
        </DesignerSuiteProvider>
    );
}

EaselProvider.displayName = "EaselProvider";
