import qs from "qs";
import { tryFetch } from "@shared/utils/Network";
import { type Design } from "./types";

const host = GALLERY_CONTENT;
/**
 * Get design variations
 * @param {object} productOptions Selected options
 * @returns designVariations
 */
export async function getDesignVariation({
    key,
    studioSelectedProductOptions,
    templateToken,
    variableOptions,
    locale,
    includePreview,
    includeColorSwatches
}: {
    key: string;
    studioSelectedProductOptions: Record<string, string>;
    templateToken: string;
    variableOptions: string[] | string;
    locale: string;
    includePreview?: boolean;
    includeColorSwatches?: boolean;
}): Promise<Design[]> {
    const queryString = qs.stringify({
        productKey: key,
        selectedOptions: studioSelectedProductOptions,
        variableOptions,
        requestor: REQUESTOR,
        includePreview,
        includeColorSwatches
    });
    const {
        results: { content }
    } = await tryFetch({
        url: `${host}/Designs/${templateToken}/Culture/${locale.toLowerCase()}/DesignVariations?${queryString}`,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        },
        friendlyDescription: "get design variation"
    });

    return content;
}
