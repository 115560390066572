import React, { useContext, useState, createContext, useEffect, ReactNode } from "react";
import { useDesigner } from "@designer-suite";
import { uploadStatusTypes } from "./uploadStatusTypes";
import type { Page } from "../../@types/page";
import type { Upload } from "../../@types/upload";

type ProgressData = {
    uploadsProgress: {};
    processingProgress: {};
};

const uploadProgressContext = createContext<ProgressData>({ uploadsProgress: {}, processingProgress: {} });

export function useUploadsProgress() {
    return useContext(uploadProgressContext);
}

interface Props {
    /* Any components that need upload progress should be wrapped here */
    children: ReactNode | ReactNode[];
}

const countComplete = (upload: Upload) => {
    return upload
        .get("pages")
        .reduce(
            (count: number, page: Page) =>
                count + (page.get("status") === uploadStatusTypes.postProcessingComplete ? 1 : 0),
            0
        );
};

export function UploadsProgressProvider({ children }: Props) {
    // Holds the object with all the current uploads progresses
    const [uploadsProgress, setUploadsProgress] = useState({});
    const [processingProgress, setProcessingProgress] = useState({});
    const designer = useDesigner();

    useEffect(() => {
        if (!designer) {
            return;
        }

        const onAddUpload = (uploadAdded: Upload) => {
            const updatePercent = () => {
                designer.eventBus.trigger("updateProcessingPercentage", uploadAdded);
            };

            uploadAdded.get("pages").on("add", (newPage: Page) => {
                newPage.on("change:status", updatePercent);
                updatePercent();
            });
            uploadAdded.get("pages").on("remove", updatePercent);
            uploadAdded.get("pages").forEach((page: Page) => {
                page.on("change:status", updatePercent);
            });
        };

        designer.uploadManager.on("add", onAddUpload);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.uploadManager.off("add", onAddUpload);
        };
    }, [designer]);

    useEffect(() => {
        if (!designer) return;

        const progressUpload = ({ percentComplete, id }: { percentComplete: number; id: string }) => {
            if (percentComplete === uploadsProgress[id]) {
                return;
            }
            const newProgress = { ...uploadsProgress, [id]: percentComplete };
            setUploadsProgress(newProgress);
        };

        const progressProcessing = (upload: Upload) => {
            const completedPages = countComplete(upload);
            const totalPages = upload.get("pages").length;
            const percentComplete = Math.round((completedPages * 100) / totalPages);

            if (upload.id === undefined || percentComplete === processingProgress[upload.id]) {
                return;
            }
            const newProgress = { ...processingProgress, [upload.id]: percentComplete };
            setProcessingProgress(newProgress);
        };

        designer.eventBus.on(designer.eventBus.events.uploadProgress, progressUpload);
        designer.eventBus.on("updateProcessingPercentage", progressProcessing);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(designer.eventBus.events.uploadProgress, progressUpload);
            designer.eventBus.off("updateProcessingPercentage", progressProcessing);
        };
    }, [designer, uploadsProgress, processingProgress]);

    const { Provider } = uploadProgressContext;

    return <Provider value={{ uploadsProgress, processingProgress }}>{children}</Provider>;
}
UploadsProgressProvider.displayName = "UploadsProgressProvider";
