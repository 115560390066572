import React from "react";
import { PreviewCacher } from "@shared/features/Previews";
import { useTrackAITemplateDeleted } from "@five/hooks/useTrackAITemplateDeleted";
import { useUploadFallback } from "../../hooks/useUploadFallback";
import { usePipeDesignerToastMessages } from "../../hooks/usePipeDesignerToastMessages";
import { useReportTemplateValidations } from "../../hooks/useReportTemplateValidations";
import { useUpdateSaveStatus } from "../../hooks/useUpdateSaveStatus";
import { useBackgroundColorSelection } from "../../hooks/useBackgroundColorSelection";

export const EaselContentsSetup = () => {
    useUploadFallback();
    usePipeDesignerToastMessages();
    useReportTemplateValidations();
    useUpdateSaveStatus();
    useBackgroundColorSelection();
    useTrackAITemplateDeleted();

    return <PreviewCacher />;
};

EaselContentsSetup.displayName = "EaselContentsSetup";
