import React, { useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Typography, Button, Icon, FlexBox, FluidImage, SquareImageContainer, TextArea } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getAllViews, filterViews, FAKE_INSTRUCTIONS } from "@shared/utils/Previews";
import { useAppSelector, useAppDispatch, showEditInCartModal } from "@shared/redux";
import { EDIT_FROM_CART_MODAL_TYPE } from "@shared/utils/Cart";
import { patchWorkName } from "@shared/utils/Work";
import { useCartContext } from "@shared/features/Cart";
import { useLastSavedText } from "@shared/features/Save";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import classNames from "classnames";
import { useAsyncEffect } from "@design-stack-ct/utility-react";
import { messages } from "./messages";
import { MyProjectsDuplicateButton } from "./MyProjectsDuplicateButton";
import { MyProjectsEditButton } from "./MyProjectsEditButton";
import type { LoadProjectCallback, Project } from "./types";
import { MyProjectsRenameButton } from "./MyProjectsRenameButton";
import { MyProjectsDeleteButton } from "./MyProjectsDeleteButton";
import * as styles from "./MyProjectsCard.module.scss";

interface Props {
    project: Project;
    prependProject: (project: Project) => void;
    removeProject: (project: Project) => void;
    setFocusedProject: (project: Project | null) => void;
    onClose: () => void;
    isFocusedProject: boolean;
    showMyProjectsErrorToast: (message: string, errorCode: string) => void;
    clearMyProjectsErrorToast: () => void;
    showDeleteConfirmation: (project: Project) => void;
    loadProject: LoadProjectCallback;
}
export function MyProjectsCard({
    project,
    prependProject,
    removeProject,
    onClose,
    setFocusedProject,
    isFocusedProject,
    showMyProjectsErrorToast,
    clearMyProjectsErrorToast,
    showDeleteConfirmation
}: Props) {
    const { t } = useTranslationSSR();
    const { auth } = useIdentityContext();
    const locale = useAppSelector(state => state.locale);
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const [image, setImage] = useState<string>();
    const [isRenaming, setIsRenaming] = useState(false);
    const [renameValue, setRenameValue] = useState(project.workName);
    const [titleHeight, setTitleHeight] = useState(0);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const titleRef = useRef<HTMLButtonElement>(null);
    const localizedModifiedDate = useLastSavedText(project.modified, true);
    const { isXerox } = useXerox();

    const renameProject = async () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CONFIRM_RENAME_MY_PROJECTS,
            label: "My Projects Rename Confirm Button"
        });

        try {
            if (!renameValue.length) {
                setRenameValue(project.workName);
                return;
            }
            if (renameValue !== project.workName) {
                const authToken = auth.getToken();
                await patchWorkName(project.workId, authToken, renameValue);
                // eslint-disable-next-line no-param-reassign
                project.workName = renameValue;
            }
        } catch {
            setRenameValue(project.workName);
        } finally {
            setIsRenaming(false);
            setFocusedProject(null);
        }
    };

    const handleRenameButtonClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_RENAME_MY_PROJECTS,
            label: "My Projects Rename Edit Button"
        });
        setFocusedProject(project);
        setIsRenaming(true);
    };

    const handleRenameCancelClick = () => {
        setIsRenaming(false);
        setRenameValue(project.workName);
        setFocusedProject(null);
    };

    useEffect(() => {
        if (!isFocusedProject && isRenaming) {
            setIsRenaming(false);
            setRenameValue(project.workName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusedProject]);

    const scrollHeight = inputRef?.current?.scrollHeight;
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.style.height = `1px`;
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [renameValue, inputRef, scrollHeight, isRenaming]);

    useEffect(() => {
        if (isRenaming && inputRef && inputRef.current) {
            inputRef.current.focus();
            const { length } = inputRef.current.value;
            inputRef.current.setSelectionRange(length, length);
        }
    }, [isRenaming, inputRef]);

    useEffect(() => {
        if (titleRef && titleRef.current && !isRenaming) {
            setTitleHeight(titleRef.current.scrollHeight);
        }
    }, [titleRef, isRenaming]);

    useAsyncEffect(
        helpers => {
            (async () => {
                const {
                    product,
                    design: { displayUrl },
                    merchandising: { merchandisingSelections }
                } = project;
                const purcsResponse = await getAllViews({
                    purpose: "design.studio.transient",
                    previewInstructionsUri: FAKE_INSTRUCTIONS,
                    width: 400,
                    studioSelectedProductOptions: merchandisingSelections,
                    product,
                    locale
                });
                if (purcsResponse.views.length) {
                    const filteredViews = filterViews(purcsResponse.views, displayUrl);
                    helpers.runIfMounted(() => setImage(filteredViews[0].src));
                }
            })();
        },
        [project, locale]
    );

    const switchProject = async () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_EDIT_MY_PROJECTS,
            label: "My Projects Edit Button"
        });

        // all projects navigate to `/studio` because studio5 doesn't know about rolled out product categories
        window.open(project.design.editUrl, "_blank");
    };

    const handleEditClick = async () => {
        if (isItemInCart) {
            onClose();
            dispatch(
                showEditInCartModal({
                    showModal: true,
                    modalType: EDIT_FROM_CART_MODAL_TYPE.SWITCH_PROJECT,
                    callback: switchProject
                })
            );
        } else {
            switchProject();
        }
    };

    return (
        <FlexBox
            alignItems="center"
            className={classNames(styles.card, {
                [styles.leftPanelCard]: isXerox
            })}
            data-item="true"
            id={project.workId}
        >
            <Button
                className={styles.projectTile}
                skin="unstyled"
                onClick={handleEditClick}
                title={t(messages.myProjectsEditProject.id)}
                tabIndex={-1}
            >
                <SquareImageContainer>
                    {image ? <FluidImage src={image} /> : <Skeleton height={"100%"} />}
                </SquareImageContainer>
            </Button>
            <div className={styles.projectInfo}>
                <FlexBox alignItems="flex-end" justifyContent="space-between" className={styles.projectTitleContainer}>
                    <div className={styles.projectTitle}>
                        {isRenaming ? (
                            <div className={styles.projectTitleInputWrapper}>
                                <TextArea
                                    className="text-size-7"
                                    value={renameValue}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setRenameValue(e.target.value);
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                                        if (e.key === "Enter" || e.keyCode === 13) {
                                            e.preventDefault();
                                            renameProject();
                                        }
                                    }}
                                    maxLength={255}
                                    ref={inputRef}
                                    style={project.workName === renameValue ? { height: titleHeight } : {}}
                                />
                            </div>
                        ) : (
                            <MyProjectsEditButton
                                project={project}
                                studioEditable={false}
                                handleEditClick={handleEditClick}
                                ref={titleRef}
                            />
                        )}
                    </div>
                    <div className={classNames({ [styles.editIcon]: isXerox })}>
                        {isRenaming ? (
                            <>
                                <Icon marginRight={1} iconType="check" onClick={renameProject} />
                                <Icon iconType="close" onClick={handleRenameCancelClick} />
                            </>
                        ) : (
                            <MyProjectsRenameButton project={project} onClick={handleRenameButtonClick} />
                        )}
                    </div>
                </FlexBox>
                <Typography textColor="dark-grey" fontSize="-1" marginBottom={3}>
                    {localizedModifiedDate}
                </Typography>
                <FlexBox>
                    <MyProjectsDuplicateButton
                        studioEditable={false}
                        project={project}
                        prependProject={prependProject}
                        setFocusedProject={setFocusedProject}
                        showMyProjectsErrorToast={showMyProjectsErrorToast}
                        clearMyProjectsErrorToast={clearMyProjectsErrorToast}
                    />
                    <MyProjectsDeleteButton
                        studioEditable={false}
                        project={project}
                        removeProject={removeProject}
                        setFocusedProject={setFocusedProject}
                        showMyProjectsErrorToast={showMyProjectsErrorToast}
                        clearMyProjectsErrorToast={clearMyProjectsErrorToast}
                        showDeleteConfirmation={showDeleteConfirmation}
                    />
                </FlexBox>
            </div>
        </FlexBox>
    );
}

MyProjectsCard.displayName = "MyProjectsCard";
