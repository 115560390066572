import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { Button } from "@shared/features/StudioChrome";
import { Typography, Button as SwanButton } from "@vp/swan";
import classNames from "classnames";
import { DesktopExperience, MobileExperience } from "@shared/features/ResponsiveDesign";
import * as styles from "./GenericHeaderButton.module.scss";

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
    label: string;
    icon: React.ReactNode;
    skin?: "standard" | "mini";
}

export const GenericHeaderButton = forwardRef<HTMLButtonElement, Props>(
    ({ icon, label, className, skin = "standard", ...rest }, ref) => {
        return (
            <>
                <DesktopExperience>
                    <SwanButton
                        className={classNames(styles.genericHeaderButton, className)}
                        ref={ref}
                        size={skin}
                        {...rest}
                    >
                        {skin === "standard" && <span className={styles.centeredIcon}>{icon}</span>}
                        <Typography className={styles.desktopButtonLabel}>{label}</Typography>
                    </SwanButton>
                </DesktopExperience>
                <MobileExperience>
                    <Button className={classNames(styles.genericHeaderButton, className)} {...rest}>
                        {icon}
                        <Typography className="button-label">{label}</Typography>
                    </Button>
                </MobileExperience>
            </>
        );
    }
);

GenericHeaderButton.displayName = "GenericHeaderButton";
