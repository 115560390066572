import React from "react";
import { useStudioLayout } from "../hooks/useStudioLayout";

/**
 * Used to wrap controls that should only appear for desktop
 * Note: desktop includes 'tablet' screensizes
 */
export const DesktopExperience = ({ children }: React.PropsWithChildren<{}>) => {
    const { isSmall } = useStudioLayout();

    return !isSmall ? <>{children}</> : null;
};
DesktopExperience.displayName = "DesktopExperience";
