export enum DialogType {
    // Sidebar and sheet panels
    None = "none",
    Text = "text",
    Images = "images",
    Graphics = "graphics",
    Elements = "elements",
    QRCodes = "qrCodes",
    Tables = "tables",
    Preview = "preview",
    Layers = "layers",
    Guides = "guides",
    File = "file",
    Color = "Color",
    GroupsAndTeams = "groupsAndTeams",
    PremiumFinishSelectedOptions = "PremiumFinishSelectedOptions",
    PremiumFinishes = "premiumFinishes",
    FullBleedPremiumFinish = "fullBleedPremiumFinish",
    ChangeTemplate = "changeTemplate",
    Filters = "filters",
    BackgroundColorPicker = "backgroundColor",
    Flexibility = "flexibility",
    MobileMore = "mobileMore",
    MyProjects = "MyProjectsPanel",
    AITemplates = "aiDesignTemplates",
    IdeaPanel = "GenerateIdeasPanel",

    // Selected Item type Panels
    ImagePlaceholderTools = "ImagePlaceholderTools",
    ImageTools = "ImageTools",
    QRCodesTools = "QRCodesTools",
    QRCodesEditor = "QRCodesEditor",
    TablesTools = "TablesTools",
    TextTools = "TextTools",
    ShapeTools = "ShapeTools",
    WordArtTools = "WordArtTools",
    ItemReferenceTools = "ItemReferenceTools",
    TeamsTools = "TeamsTools",
    Template = "Template",

    // Common Tools
    JoystickTools = "JoystickTools",
    ArrangeTools = "ArrangeTools",
    RotateTools = "RotateTools",
    AlignTool = "AlignTool",
    ColorTools = "ColorTools",
    TextColorPicker = "TextColorPicker",
    FontSelectorTools = "FontSelectorTools",
    FontSizeSelector = "FontSizeSelector",
    TextInput = "TextInput",
    AlignmentTool = "AlignmentTool",
    OpacityTool = "OpacityTool",

    // Table Tools
    TableFontColorPicker = "TableFontColorPicker",
    TableBackgroundColorPicker = "TableBackgroundColorPicker",
    DesignSelectorTools = "DesignSelectorTools",

    // Shape Tools
    FillColorPicker = "FillColorPicker",
    StrokeColorPicker = "StrokeColorPicker",
    StrokeWidthSelector = "StrokeWidthSelector",
    EditShapes = "EditShapes",

    // Image Tools
    SingleColorImageColorPicker = "SingleColorImageColorPicker",
    HSLColorImageColorPicker = "HSLColorImageColorPicker",
    EditImage = "EditImage",

    // Word Art Tools
    CurveTools = "CurveTools",
    WordArtColorPicker = "WordArtColorPicker",

    // Text tools
    ListTools = "ListTools",

    // Teams tools
    TeamsFontColorPicker = "TeamsFontColorPicker"
}
