import React, { forwardRef, useEffect, useState, Ref, useRef, useCallback } from "react";
import { get, set } from "idb-keyval";
import {
    Button,
    LegacyCombobox,
    LegacyComboboxInput,
    LegacyComboboxList,
    LegacyComboboxOption,
    LegacyComboboxPopover,
    LegacyComboboxPopoverTitle,
    IconSearch
} from "@vp/swan";
import {
    Button as EaselButton,
    SearchFilterIcon,
    SearchFilterSelectedIcon,
    SearchResetIcon
} from "@shared/features/StudioChrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { imageLibraryPanelMessages } from "@five/components/Panels/Images/imageLibraryMessages";
import { ImageSearchFiltersPanel } from "@five/components/Panels/Images/ImageSearchFiltersPanel";
import { setShowImageSearchFiltersPanel, useAppDispatch, useAppSelector } from "@shared/redux";
import { useTrackEvents } from "@shared/features/Tracking";
import classNames from "classnames";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { DEFAULT_SEARCH_ELEMENTS, ElementsPanelContentTypes, useElementsPanel } from "./ElementsPanelProvider";
import * as styles from "./ElementsSearchBar.module.scss";

const messages = defineMessages({
    searchAllPlaceholder: {
        id: "studio.features.elements.searchPlaceholder",
        defaultMessage: "Search all graphics",
        description: {
            note: "Placeholder text shown in the search text field, before a user starts to type"
        }
    },
    searchImagesPlaceholder: {
        id: "studio.features.elements.searchImagesPlaceholder",
        defaultMessage: "Search for photos...",
        description: {
            note: "Placeholder text shown in the search text field, before a user starts to type"
        }
    },
    searchShapesPlaceholder: {
        id: "studio.features.elements.searchShapesPlaceholder",
        defaultMessage: "Search for icons and shapes...",
        description: {
            note: "Placeholder text shown in the search text field, before a user starts to type"
        }
    },
    searchClipartPlaceholder: {
        id: "studio.features.elements.searchClipartPlaceholder",
        defaultMessage: "Search for clipart...",
        description: {
            note: "Placeholder text shown in the search text field, before a user starts to type"
        }
    },
    searchButtonElements: {
        id: "studio.features.elements.searchButtonElements",
        defaultMessage: "Search all graphics",
        description: {
            note: "Label for the search button for all elements"
        }
    },
    searchButtonImages: {
        id: "studio.features.elements.searchButtonImages",
        defaultMessage: "Search images",
        description: {
            note: "Label for the search button for images"
        }
    },
    searchButtonShapes: {
        id: "studio.features.elements.searchButtonShapes",
        defaultMessage: "Search shapes",
        description: {
            note: "Label for the search button for shapes"
        }
    },
    searchButtonClipart: {
        id: "studio.features.elements.searchButtonClipart",
        defaultMessage: "Search clipart",
        description: {
            note: "Label for the search button for clipart"
        }
    },
    resetSearchButton: {
        id: "studio.features.elements.resetSearchButton",
        defaultMessage: "Reset search",
        description: {
            note: "Label for the button to reset search"
        }
    },
    recentSearchHeading: {
        id: "studio.features.elements.recentSearch",
        defaultMessage: "Recent searches:",
        description: {
            note: "Title of recent search term list"
        }
    }
});

const MAX_SEARCH_TERMS_TO_KEEP = 100;
const RECENTLY_SEARCHED_ELEMENTS_KEY = "recentlySearchedElements";

const updateSearchTermList = (arr: string[], value: string) => {
    if (!arr) {
        return [value];
    }
    if (arr.includes(value)) {
        return arr;
    }
    arr.unshift(value);
    if (MAX_SEARCH_TERMS_TO_KEEP && arr.length > MAX_SEARCH_TERMS_TO_KEEP) {
        return arr.slice(arr.length - MAX_SEARCH_TERMS_TO_KEEP, arr.length);
    }
    return arr;
};

/**
 * Updates recently searched terms in idb with new input array
 * @param arr Array of recently searched terms
 */
const updateRecentSearchTerms = (arr: string[]): void => {
    if (!arr) {
        return;
    }
    set(RECENTLY_SEARCHED_ELEMENTS_KEY, arr);
};

/**
 * Function returns a promise that resolves into an array of recently searched terms (string)
 * @returns Promise<string[]>
 */
const getRecentSearchTerms = () => {
    return get(RECENTLY_SEARCHED_ELEMENTS_KEY);
};

interface ElementsSearchBarProps {
    contentType: string;
}

const ariaLabel = {
    [ElementsPanelContentTypes.Main]: messages.searchButtonElements.id,
    [ElementsPanelContentTypes.Shapes]: messages.searchButtonShapes.id, // This is the same as the next as they are the same panel
    [ElementsPanelContentTypes.Icons]: messages.searchButtonShapes.id,
    [ElementsPanelContentTypes.Images]: messages.searchButtonImages.id,
    [ElementsPanelContentTypes.Clipart]: messages.searchButtonClipart.id
};

const placeHolderText = {
    [ElementsPanelContentTypes.Main]: messages.searchAllPlaceholder.id,
    [ElementsPanelContentTypes.Shapes]: messages.searchShapesPlaceholder.id,
    [ElementsPanelContentTypes.Icons]: messages.searchShapesPlaceholder.id,
    [ElementsPanelContentTypes.Images]: messages.searchImagesPlaceholder.id,
    [ElementsPanelContentTypes.Clipart]: messages.searchClipartPlaceholder.id
};

export const ElementsSearchBar = forwardRef(({ contentType }: ElementsSearchBarProps, ref: Ref<HTMLButtonElement>) => {
    const { t } = useTranslationSSR();
    const { searchTerm, setSearchTerm, performSearchElements, currentFilterOptions, stack } = useElementsPanel();
    const [recentlySearchedTerms, setRecentlySearchedTerms] = useState<string[]>([]);
    const panelOpen = useAppSelector(state => state.showImageSearchFiltersPanel);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dispatch = useAppDispatch();
    const { trackEvent } = useTrackEvents();
    const { isXerox } = useXerox();

    const handleOnChange = (event: { target: HTMLInputElement }) => {
        const { value } = event.target;
        setSearchTerm(value);
    };

    const handleSearch = (term?: string) => {
        // term is used onSelect in the combobox
        // otherwise can use the searchTerm
        const newSearchTerm = term || searchTerm;
        performSearchElements(newSearchTerm);

        const updatedSearchTerms = updateSearchTermList(recentlySearchedTerms, searchTerm);
        setRecentlySearchedTerms(updatedSearchTerms);
        updateRecentSearchTerms(updatedSearchTerms);

        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.SEARCH_ELEMENTS,
            label: `Search elements: ${contentType}`,
            extraData: () => ({
                searchTerm: newSearchTerm
            })
        });
    };

    const handleResetSearch = () => {
        performSearchElements(DEFAULT_SEARCH_ELEMENTS);
    };

    const toggleFilterPanel = useCallback(() => {
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_OPEN_FILTERS,
            extraData: () => ({
                openingPanel: !panelOpen
            })
        });
        dispatch(setShowImageSearchFiltersPanel(!panelOpen));
    }, [dispatch, panelOpen, trackEvent]);

    useEffect(() => {
        getRecentSearchTerms().then(result => {
            result && setRecentlySearchedTerms(result);
        });
    }, []);

    const filteredRecentlySearchedTerms = recentlySearchedTerms.filter(term => term.includes(searchTerm));
    const showRecentlySearchedTerms =
        filteredRecentlySearchedTerms.length > 1 ||
        (filteredRecentlySearchedTerms.length === 1 && filteredRecentlySearchedTerms[0] !== searchTerm);

    const showImageFilters = stack[stack.length - 1] === ElementsPanelContentTypes.Images && isXerox;

    return (
        <LegacyCombobox
            openOnFocus
            className={styles.searchBar}
            aria-labelledby={t(placeHolderText[contentType])}
            onSelect={value => {
                setSearchTerm(value);
                handleSearch(value);
            }}
        >
            <Button
                skin="unstyled"
                className={styles.searchIcon}
                onClick={() => handleSearch()}
                aria-label={t(ariaLabel[contentType])}
                ref={ref}
            >
                <IconSearch />
            </Button>
            <LegacyComboboxInput
                className={styles.searchBarInput}
                value={searchTerm}
                onChange={handleOnChange}
                title={t(placeHolderText[contentType])}
                placeholder={t(placeHolderText[contentType])}
                onKeyPress={(event: { key: string }) => {
                    if (event.key === "Enter") handleSearch();
                }}
            />
            {searchTerm && (
                <Button
                    skin="unstyled"
                    className={classNames(styles.resetSearch, {
                        [styles.resetSearchWithImageFilters]: showImageFilters
                    })}
                    onClick={handleResetSearch}
                    aria-label={t(messages.resetSearchButton.id)}
                >
                    <SearchResetIcon />
                </Button>
            )}
            {showImageFilters && (
                <>
                    <EaselButton
                        ref={buttonRef}
                        onClick={toggleFilterPanel}
                        className={styles.imageFiltersButton}
                        isSelected={panelOpen}
                        aria-label={t(imageLibraryPanelMessages.imageSearchFilterButtonLabel.id)}
                    >
                        {currentFilterOptions ? (
                            <SearchFilterSelectedIcon className={styles.filtersButtonSelectedIcon} />
                        ) : (
                            <SearchFilterIcon className={styles.filtersButtonIcon} />
                        )}
                    </EaselButton>
                    <ImageSearchFiltersPanel
                        buttonRef={buttonRef}
                        arrowClassName={styles.filtersLeftArrow}
                        panelClassName={styles.filtersImagePanel}
                        performSearchForFilter={(filterOptions: string) =>
                            performSearchElements(searchTerm, filterOptions)
                        }
                    />
                </>
            )}
            {showRecentlySearchedTerms && (
                <LegacyComboboxPopover className={styles.searchPopover} data-dcl-prevent-canvas-items-deselection>
                    <LegacyComboboxPopoverTitle>{t(messages.recentSearchHeading.id)}</LegacyComboboxPopoverTitle>
                    <LegacyComboboxList>
                        {filteredRecentlySearchedTerms.map((term: string) => {
                            return <LegacyComboboxOption value={term} key={`${term}-option`} />;
                        })}
                    </LegacyComboboxList>
                </LegacyComboboxPopover>
            )}
        </LegacyCombobox>
    );
});

ElementsSearchBar.displayName = "ElementsSearchBar";
