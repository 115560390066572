import { useDesignRequirementsContext } from "@shared/features/Product";
import { getQueryParams } from "@shared/utils/WebBrowser";

export const useStartingCanvas = (): number => {
    const designRequirements = useDesignRequirementsContext();
    const { startingCanvas } = getQueryParams();
    if (!designRequirements) {
        return 1;
    }
    const panelLength = designRequirements.panels.length;
    if (!startingCanvas || startingCanvas <= 0 || startingCanvas > panelLength) {
        return 1;
    }
    return parseInt(startingCanvas, 10);
};
