import { ColorRestrictions } from "@design-stack-ct/cdif-color-sdk";
import type { BaseDesignRequirements, PanelDesignRequirements } from "@design-stack-ct/interactive-design-engine-core";
import React, { ReactNode, useContext, useMemo } from "react";

export interface StudioPanelDesignRequirements extends PanelDesignRequirements {
    colorMode: "color" | "blank" | "monochrome" | "grayscale";
    colorRestrictions?: ColorRestrictions;
    // this is the translated name we often show in studio
    locationName: string;
    availablePremiumFinishes: string[];
    availablePantoneColors?: string[];
}

export interface DesignRequirements extends BaseDesignRequirements {
    panels: StudioPanelDesignRequirements[];
}

interface DesignRequirementsContext {
    numberOfPanels: number;
    numberOfDesignablePanels: number;
    isEveryPanelBlank: boolean;
    firstFullColorCanvasIndex: number;

    getPanelByName: (name: string) => StudioPanelDesignRequirements | undefined;
    getPanelIndexByName: (name: string) => number;
    getDefaultPanelName: () => string;
    getPanelById: (id: string) => StudioPanelDesignRequirements | undefined;

    panels: StudioPanelDesignRequirements[];
}

const designRequirementsContext = React.createContext<DesignRequirementsContext | undefined>(undefined);

export function useDesignRequirementsContext() {
    if (designRequirementsContext === undefined) {
        throw new Error("useDesignRequirementsContext must be used within a DesignRequirementsProvider");
    }
    return useContext(designRequirementsContext);
}

export function DesignRequirementsProvider({
    children,
    studioDesignRequirements = { panels: [] }
}: {
    children: ReactNode | ReactNode[];
    studioDesignRequirements?: DesignRequirements;
}) {
    const contextObject = useMemo(() => {
        return {
            numberOfPanels: studioDesignRequirements.panels.length,
            numberOfDesignablePanels: studioDesignRequirements.panels.filter(panel => panel.colorMode !== "blank")
                .length,
            isEveryPanelBlank: studioDesignRequirements.panels.every(panel => panel.colorMode === "blank"),
            firstFullColorCanvasIndex: studioDesignRequirements.panels.findIndex(panel => panel.colorMode !== "blank"),

            getPanelByName: (name: string) =>
                studioDesignRequirements.panels.find(panel => panel.name.toLowerCase() === name.toLowerCase()),
            getPanelIndexByName: (name: string) =>
                studioDesignRequirements.panels.findIndex(panel => panel.name.toLowerCase() === name.toLowerCase()),
            getDefaultPanelName: () => studioDesignRequirements.panels[0].name,
            getPanelById: (id: string) => studioDesignRequirements.panels.find(panel => panel.id === id),

            panels: studioDesignRequirements.panels
        };
    }, [studioDesignRequirements]);

    return <designRequirementsContext.Provider value={contextObject}>{children}</designRequirementsContext.Provider>;
}

DesignRequirementsProvider.displayName = "DesignRequirementsProvider";
