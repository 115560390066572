import { useSidebarNavConfiguration } from "@five/components/Redesign/FloatingSidebar/useSidebarNavConfiguration";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { usePrevious } from "@design-stack-ct/utility-react";
import { useState, useEffect } from "react";

export const useSideBarNavIndex = (dialogType: DialogType) => {
    const { sidebarPanelButtonConfiguration } = useSidebarNavConfiguration();
    return sidebarPanelButtonConfiguration
        .flat()
        .filter(button => button.rendered)
        .findIndex(button => button.contentType === dialogType);
};

export const useSideBarPanelAnimationClassName = (styles: { up: string; down: string }) => {
    const { currentActiveDialog } = useActiveDialog();
    const previousActiveDialog = usePrevious(currentActiveDialog) || currentActiveDialog;
    const currentActiveDialogIndex = useSideBarNavIndex(currentActiveDialog);
    const previousActiveDialogIndex = useSideBarNavIndex(previousActiveDialog);

    const [animationClassName, setAnimationClassName] = useState<string>();

    useEffect(() => {
        if (currentActiveDialogIndex !== previousActiveDialogIndex) {
            setAnimationClassName(previousActiveDialogIndex > currentActiveDialogIndex ? styles.up : styles.down);
            // Reset the animation so that it can be applied again
            setTimeout(() => {
                setAnimationClassName(undefined);
            }, 300);
        }
    }, [currentActiveDialogIndex, previousActiveDialogIndex, styles.up, styles.down]);

    return animationClassName;
};
