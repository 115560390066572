import {
    getTableBackgroundColor,
    getTableFontColor,
    getTableFontFamily,
    getTableFontSize,
    getTableFontStyle,
    getTableStyleId
} from "@utilities";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { getDocumentItems } from "../util";

export class AttributeCount {
    counts: {
        [attributeKey: string]: { [attributeValue: string]: number };
    };

    constructor() {
        this.counts = {};
    }

    getCount(key: string): { [key: string]: number } {
        return this.counts[key];
    }

    addCount(key: string, value: string | number) {
        // if we should come in without a key or value, then just drop out as we don't have anything valuable to contribute.
        if (!key || !value) {
            return;
        }
        if (!this.counts[key]) {
            this.counts[key] = {};
        }
        if (!this.counts[key][value]) {
            this.counts[key][value] = 1;
        } else {
            this.counts[key][value] += 1;
        }
    }
}

const addTextFontCount = (textAttributes: AttributeCount, content: any) => {
    content.forEach((textContent: any) => {
        if (Array.isArray(textContent.content)) {
            addTextFontCount(textAttributes, textContent.content);
        } else {
            textAttributes.addCount("fontFamily", textContent.fontFamily);
        }
    });
};

export const useStylesInUse = (canvases: Canvas[]) => {
    const tableAttributes = new AttributeCount();
    const textAttributes = new AttributeCount();
    const wordArtAttributes = new AttributeCount();
    const itemsOnDocument = getDocumentItems(canvases);

    itemsOnDocument.forEach((item: CanvasItem) => {
        if (item.itemType === ItemTypes.ITEM_REFERENCE) {
            const { type } = item as ReferenceItem;
            if (type === ItemReferenceTypes.TABLE) {
                const table = item as TableItem;
                const fontSize = getTableFontSize(table.data);
                const styleId = getTableStyleId(table.data);
                const fontFamily = getTableFontFamily(table.data);
                const fontColor = getTableFontColor(table.data);
                const fontStyle = getTableFontStyle(table.data);
                const backgroundColor = getTableBackgroundColor(table.data);
                if (styleId) {
                    tableAttributes.addCount("styleId", styleId);
                }
                if (fontSize) {
                    tableAttributes.addCount("fontSize", parseFloat(fontSize));
                }
                if (fontFamily) {
                    tableAttributes.addCount("fontFamily", fontFamily);
                }
                if (fontColor) {
                    tableAttributes.addCount("fontColor", fontColor);
                }
                if (backgroundColor) {
                    tableAttributes.addCount("backgroundColor", backgroundColor);
                }
                if (fontStyle) {
                    tableAttributes.addCount("fontStyle", fontStyle);
                }
            } else if (type === ItemReferenceTypes.WORD_ART) {
                const {
                    data: { color, fontFamily, fontStyle }
                } = item as WordArtItem;
                if (color) {
                    wordArtAttributes.addCount("fontColor", color);
                }
                wordArtAttributes.addCount("fontFamily", fontFamily);
                wordArtAttributes.addCount("fontStyle", fontStyle);
            }
        } else if (item.itemType === ItemTypes.TEXT) {
            const { color, ptSize, isBold, isItalic, content } = item as TextItem;
            addTextFontCount(textAttributes, content);
            textAttributes.addCount("fontColor", color);
            textAttributes.addCount("fontSize", ptSize);
            textAttributes.addCount(
                "fontStyle",
                [isBold ? "bold" : "normal", isItalic ? "italic" : "normal"].join(",")
            );
        }
    });

    return { textAttributes, tableAttributes, wordArtAttributes };
};
