/**
 * @param {*} currentProjectName current name of project
 * @param {*} textToAppend localized text that is appended to the project name (Copy)
 * @returns a new name for a duplicated project, with - Copy, - Copy 2, - Copy 3, etc appended to it
 */
export const duplicateProjectName = (currentProjectName: string, textToAppend: string) => {
    if (currentProjectName.endsWith(textToAppend)) {
        return `${currentProjectName} 2`;
    }

    if (currentProjectName.match(`${textToAppend} [0-9]+$`)) {
        const count = currentProjectName.match("[0-9]+$");
        if (count) {
            const currentCount = parseInt(count[0], 10);
            const incrementedWorkName = `${currentProjectName.substr(0, count.index)}${currentCount + 1}`;
            return incrementedWorkName;
        }
    }

    return `${currentProjectName} - ${textToAppend}`;
};

export function saveWorkInSession(work: { workId: any }) {
    if (window && window.sessionStorage && work && work.workId) {
        sessionStorage.setItem(`workId:${work.workId}`, JSON.stringify({ work, timestamp: Date.now() }));
    }
}
