import React, { useState, ChangeEvent, useCallback, useEffect, FormEvent } from "react";
import debounce from "lodash/debounce";
import classnames from "classnames";
import { useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { countDecimals } from "./countDecimals";
import { MAX_FONT_SIZE_DECIMALS } from "./fontInputConsts";
import { fontSizeMessages } from "./fontSizeMessages";
import * as styles from "./FontSizeInput.module.scss";

export interface FontSizeInputProps {
    min: number;
    max: number;
    initialValue: number;
    /** When a user picks a value call this method
     * User is responsible for connecting this up to a Store / something external that updates the selected parameter
     */
    onChange: (value: number) => void;

    /** For overriding styles */
    className?: string;

    autoFocus?: boolean;

    isDisabled?: boolean;

    /**
     * A custom function that is called whenever the `value` is outside
     * The range of `min` and `max`. This allows you to effectively override
     * what is a "valid" input.
     * @note if `value` is within `min` and `max` (thus is "valid"), this function
     * is not called.
     *
     * @param value current value
     * @returns {boolean}
     */
    detectValidInput?: (value: number) => boolean;
    mobileDock?: boolean;
}

export function FontSizeInput(props: FontSizeInputProps) {
    const { min, max, initialValue, onChange, className, autoFocus, detectValidInput, isDisabled, mobileDock } = props;
    const { t } = useTranslationSSR();
    const [hasValidInput, setValidInput] = useState(true);
    // We're storing currentValue as a string because there's a React bug where comparing numbers with leading 0's is equal to the
    // same number without leading 0, so the UI won't remove leading 0's if a user deletes the size completely and starts typing a number
    // https://github.com/facebook/react/issues/11021
    const [value, setValue] = useState(initialValue.toString());

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetValidInput = useCallback(
        debounce(validInput => {
            setValidInput(validInput);
            if (!validInput) {
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.ENTER_INVALID_FONT_SIZE,
                    // @ts-ignore
                    label: "Invalid font size entered"
                });
            }
        }, 500),
        [setValidInput]
    );

    const trackMouseEnter = () => {
        if (!hasValidInput) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.HOVER_INVALID_FONT_SIZE,
                // @ts-ignore
                label: "Hover on invalid font size"
            });
        }
    };

    const trackMouseLeave = () => {
        if (!hasValidInput) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.LEAVE_INVALID_FONT_SIZE,
                // @ts-ignore
                label: "Leave invalid font size"
            });
        }
    };

    const checkValidity = useCallback(
        (value: number) => {
            const validInput = (value >= min && value <= max) || detectValidInput?.(value);
            debouncedSetValidInput(validInput);
            return validInput;
        },
        [max, min, detectValidInput, debouncedSetValidInput]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedInputChange = useCallback(debounce(onChange, 200), [onChange]);

    useEffect(() => {
        if (initialValue !== +value) {
            setValue(initialValue.toString());
            checkValidity(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, checkValidity]);

    return (
        <div className={classnames("easel-editsliderrow-inputcontainer", className, styles.mobileDockInputContainer)}>
            <input
                aria-label={t(fontSizeMessages.fontSizeSelectorTitle.id)}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                value={value}
                type="number"
                pattern="[0-9]*"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (countDecimals(event.target.value) <= MAX_FONT_SIZE_DECIMALS) {
                        setValue(event.target.value.replace(/^0+/, ""));
                        const numericValue = +event.target.value;
                        if (checkValidity(numericValue)) {
                            debouncedInputChange(numericValue);
                        }
                    }
                }}
                onClick={(e: FormEvent<HTMLElement>) => {
                    const target = e.target as HTMLInputElement;
                    target.select();
                }}
                className={classnames("easel-editsliderrow-input", {
                    "easel-editsliderrow-invalidinput": !hasValidInput,
                    [styles.mobileDockInput]: mobileDock
                })}
                onMouseLeave={trackMouseLeave}
                onMouseEnter={trackMouseEnter}
                disabled={isDisabled}
            />
        </div>
    );
}
FontSizeInput.displayName = "FontSizeInput";
