import { useCallback, useEffect } from "react";
import { getColorPalette, isHeicFileType, isPdfFileType, useDesigner } from "@designer-suite";
import { getImageAsPromise } from "@design-stack-ct/utility-core";
import { useFileMethods } from "@design-stack-vista/upload-components";
import { DEFAULT_RGB } from "@shared/features/ColorPicker";
import { ERROR_CODES, handleError } from "@shared/utils/Errors";
import { showSingleColorImageModal, useAppDispatch } from "@shared/redux";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { isSingleColorCanvas } from "@utilities";
import { searchForSimilarColor } from "../../color/colorUtil";
import {
    drawSingleColor,
    createVector,
    DEFAULT_THRESHOLD,
    drawImageOnCanvas,
    getColor
} from "./clientsideThresholdImageUtil";

export function useClientSideThresholding() {
    const designer = useDesigner();
    const { uploadFile } = useFileMethods();
    const dispatch = useAppDispatch();

    const clientsideThreshold = useCallback(
        item => {
            const itemViewModel = item._itemViewModel;
            const canvasViewModel = itemViewModel.parent;
            const canvasIsRestricted = canvasViewModel.get("maxSpotColors") === 1;

            if (!canvasIsRestricted || !designer) {
                return;
            }

            const itemModel = itemViewModel.model;
            const url =
                isPdfFileType(itemModel?.get("fileType")) || isHeicFileType(itemModel?.get("fileType"))
                    ? itemModel?.get("previewUrl")
                    : itemModel?.get("originalUrl");

            if (!url) {
                return;
            }
            itemViewModel.set("vectorizing", true);
            itemViewModel.set("imageProcessingInProgress", true);
            const canvas = document.createElement("canvas");
            const threshold = itemModel.get("threshold") || DEFAULT_THRESHOLD;
            getImageAsPromise(url)
                .then(image => {
                    if (canvas && image) {
                        let color: Color | undefined;
                        const canvasViewModel = itemViewModel.parent;
                        const canvasColor = canvasViewModel.getColorIfCanvasIsRestricted();
                        if (canvasColor) {
                            // set color to canvas color if one exists
                            color = canvasColor;
                        } else {
                            // otherwise get color from image
                            const imgData = drawImageOnCanvas(image, canvas);
                            const imgColor = getColor(imgData);
                            const imgRgb = { r: imgColor[0], g: imgColor[1], b: imgColor[2] };
                            const availablePantoneColors = itemViewModel.get("availablePantoneColors");
                            const colorPalette = getColorPalette(designer);
                            color = searchForSimilarColor(imgRgb, availablePantoneColors);
                            const paletteColor = colorPalette.find(pColor => pColor.cssBackground === color?.hex);
                            if (!paletteColor) {
                                color = availablePantoneColors.find((pColor: Color) => pColor.color === "White");
                            }
                        }
                        const colorOverrides = [{ color: color?.toString(), ordinal: 0 }];
                        itemModel.set({ colorOverrides });
                        drawSingleColor(canvas, DEFAULT_RGB, threshold, false, image);
                        itemModel.set("image", image);
                    }

                    createVector(canvas, threshold, async svgPath => {
                        const blob = new Blob([svgPath], { type: "image/svg+xml" });
                        const file = new File([blob], "singlecolorimage.svg", { type: "image/svg+xml" });
                        const asset = await uploadFile(file, { hidden: true });
                        if (asset) {
                            await asset.presign();
                            const originalUrl = asset.getUrl({ includeSignature: true });
                            const previewUrl = originalUrl;
                            const printUrl = originalUrl;
                            designer?.commandDispatcher.changeAttributes({
                                viewModel: itemViewModel,
                                attributes: {
                                    previewUrl,
                                    printUrl,
                                    threshold,
                                    inverted: false,
                                    specialProcessing: []
                                }
                            });
                            itemViewModel.set("vectorizing", false);
                            itemViewModel.unset("imageProcessingInProgress");
                        }
                    });
                })
                .catch(error => {
                    let loggableError = error;
                    if (!error.message) {
                        loggableError = new Error("Error thresholding image");
                    }
                    handleError(loggableError, ERROR_CODES.SINGLE_COLOR_IMAGE_THRESHOLDING);
                    // close the modal if an error occurs
                    dispatch(showSingleColorImageModal(false));
                    // delete the image if thresholding is unsuccessful
                    designer?.api.design.updateItem(item.id, mutableItem => {
                        mutableItem.remove();
                    });
                });
        },
        [designer, dispatch, uploadFile]
    );

    useEffect(() => {
        if (!designer) {
            return;
        }
        const isSingleColor = isSingleColorCanvas(designer);
        if (!isSingleColor) {
            return;
        }

        const imageAdded = (event: EventData) => {
            event.items.forEach(item => {
                if (item.itemType !== ItemTypes.IMAGE) return;

                const itemViewModel = item._itemViewModel;
                if (
                    !itemViewModel.model?.get("image") &&
                    // prevent thresholding images again when loading a saved document
                    !itemViewModel.model?.get("colorOverrides").length &&
                    !itemViewModel.get("imageProcessingInProgress")
                ) {
                    try {
                        clientsideThreshold(item);
                    } catch (error) {
                        handleError(error, ERROR_CODES.SINGLE_COLOR_IMAGE_THRESHOLDING);
                        // close the modal if an error occurs
                        dispatch(showSingleColorImageModal(false));
                        // delete the image if thresholding is unsuccessful
                        designer.api.design.updateItem(item.id, mutableItem => {
                            mutableItem.remove();
                        });
                    }
                }
            });
        };

        const unsubscribeImageAdded = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_ADDED,
            imageAdded
        );

        // handles replacing image placeholder
        const unsubscribeImageChanged = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_CHANGED,
            imageAdded
        );

        // eslint-disable-next-line consistent-return
        return () => {
            unsubscribeImageAdded();
            unsubscribeImageChanged();
        };
    }, [clientsideThreshold, designer, dispatch]);

    return clientsideThreshold;
}
