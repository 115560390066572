import React from "react";
import { Button } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector, useAppDispatch, setShowSaveAs } from "@shared/redux";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { RenameProjectIcon } from "@shared/features/StudioChrome";
import { projectNamingRelatedMessages } from "@shared/features/MyProjects";
import { Save, SaveDesignWithSaveDocumentOmitted, UseSaveParams } from "./useSave";

export const renamingMessages = defineMessages({
    rename: {
        id: "studio.components.globalToolbar.rename",
        defaultMessage: "Rename",
        description: {
            note: "Label for button to rename a project from the project dropdown"
        }
    }
});

interface Props {
    className?: string;
    saveDesign: SaveDesignWithSaveDocumentOmitted;
    useSave: (props: Omit<UseSaveParams, "saveDesign">) => Save;
}

export function RenameProjectButton({ className, saveDesign, useSave }: Props) {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const isAnonymousUser = useIsAnonymousUser();
    const { auth, identity } = useIdentityContext();
    const workId = useAppSelector(state => state.workId);
    const productname = useAppSelector(state => state.productName);
    const save = useSave({
        onSaveCallback: () =>
            dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: false, isRenamingProject: true })),
        hideSaveToast: true,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_RENAME_PROJECT
    });

    const handleClick = () => {
        const defaultProjectName = t(projectNamingRelatedMessages.projectName.id, { productname });
        if (workId) {
            dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: false, isRenamingProject: true }));
        } else if (isAnonymousUser) {
            saveDesign({
                authToken: auth.getToken(),
                identity,
                newWorkName: defaultProjectName,
                savedProjectTrackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_RENAME_PROJECT
            }).then(() => {
                dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: false, isRenamingProject: true }));
            });
        } else {
            save(defaultProjectName);
        }
    };

    return (
        <Button className={className} skin="unstyled" onClick={handleClick}>
            <RenameProjectIcon aria-hidden={true} />
            {t(renamingMessages.rename.id)}
        </Button>
    );
}

RenameProjectButton.displayName = "RenameProjectButton";
