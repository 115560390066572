import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useClickOutside } from "@design-stack-ct/utility-react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { HamburgerIcon } from "@shared/features/StudioChrome";
import * as styles from "./FileButton.module.scss";

const messages = defineMessages({
    fileButton: {
        id: "studio.myProjects.fileButton",
        defaultMessage: "Open the menu",
        description: {
            note: "alt text for hamburger button that opens file menu"
        }
    }
});

export const FileButton = ({ className }: React.HTMLProps<HTMLDivElement>) => {
    const { t } = useTranslationSSR();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { setCurrentActiveDialog } = useActiveDialog();
    const [isClicked, setIsClicked] = useState(false);
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    useClickOutside(
        { elementRef: buttonRef },
        async () => {
            setIsClicked(false);
        },
        []
    );

    const handleClick = () => {
        setIsClicked(!isClicked);
        if (!isClicked) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_FILE_BUTTON,
                label: "File Button"
            });
            setCurrentActiveDialog(DialogType.File);
        }
    };

    return (
        <button
            disabled={!easelLoaded}
            onClick={handleClick}
            className={classNames(styles.fileButton, className)}
            aria-label={t(messages.fileButton.id)}
            ref={buttonRef}
            data-testid="fileButton"
        >
            <HamburgerIcon className={styles.fileFolderIcon} aria-hidden="true" />
        </button>
    );
};

FileButton.displayName = "FileButton";
