import React, { forwardRef, Ref } from "react";
import { ZoomToolbar } from "@easel";
import { FlexBox, PipeSeparator } from "@vp/swan";
import { ViewPanel } from "@five/components/Panels";
import { FlexSpacer } from "src/shared/xerox/features/FlexSpacer";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { useAppSelector } from "@shared/redux";
import * as style from "./XeroxBottomContainer.module.scss";

const DesignHelp = loadable(() => loadableRetry(() => import("@five/components/DesignHelp")));

export const XeroxBottomContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    return (
        <FlexBox flexDirection="row" alignItems="center" ref={ref} className={style.container}>
            <ViewPanel />
            <PipeSeparator />
            <ZoomToolbar className={style.zoomToolbarOverride} />

            <FlexSpacer />

            {easelLoaded && <DesignHelp className={style.designHelpOverride} />}
        </FlexBox>
    );
});

XeroxBottomContainer.displayName = "XeroxBottomContainer";
