import React, { forwardRef, Ref } from "react";
import * as styles from "./XeroxLeftContainer.module.scss";
import { SideBar } from "../SideBar";

export const XeroxLeftContainer = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    return (
        <div ref={ref} className={styles.container}>
            <SideBar />
        </div>
    );
});

XeroxLeftContainer.displayName = "XeroxLeftContainer";
