/* eslint-disable max-len */
import React from "react";

export function SheetHorizontalScrollLeftButton(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 10 17" fill="none" {...props}>
            <path
                d="M9.00002 1L1.50001 8.5C1.50001 8.5 6.14287 13.1429 9.00001 16"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
SheetHorizontalScrollLeftButton.displayName = "SheetHorizontalScrollLeftButton";
