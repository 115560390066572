import React, { Fragment } from "react";
import { BackgroundRemovalIcon, ToolbarButton } from "@shared/features/StudioChrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";

const messages = defineMessages({
    isKnockedOutLabel: {
        id: "easel.designerSuite.knockoutButton.isKnockedOutLabel",
        defaultMessage: "Restore Background",
        description: {}
    },
    isNotKnockedOutLabel: {
        id: "easel.designerSuite.knockoutButton.isNotKnockedOutLabel",
        defaultMessage: "Remove Background",
        description: {}
    },
    notEligibleForKnockout: {
        id: "easel.designerSuite.knockoutButton.notEligibleForKnockout",
        defaultMessage:
            "Background removal is not possible for this image. " +
            "Please ensure that the image is a JPG or PNG, does not use the CMYK format, and has a background to remove.",
        description: {}
    }
});

interface RemoveBackgroundButtonProps extends React.ComponentProps<typeof ToolbarButton> {
    showIcon?: boolean;
    isBackgroundRemoved: boolean;
    isDisabled: boolean;
}

export function RemoveBackgroundButton({
    showIcon,
    className,
    isBackgroundRemoved,
    isDisabled,
    ...rest
}: RemoveBackgroundButtonProps) {
    const { t } = useTranslationSSR();
    const addBackgroundText = t(messages.isKnockedOutLabel.id);
    const removeBackgroundText = t(messages.isNotKnockedOutLabel.id);
    const removeBackgroundImpossibleText = t(messages.notEligibleForKnockout.id);
    let title = isBackgroundRemoved ? addBackgroundText : removeBackgroundText;

    if (isDisabled) {
        title = removeBackgroundImpossibleText;
    }

    return (
        <ToolbarButton className={className} {...rest} isDisabled={isDisabled} title={title}>
            {isBackgroundRemoved ? (
                <Fragment>
                    {showIcon && <BackgroundRemovalIcon />}
                    <div className="dcl-ctx-button-label">{addBackgroundText}</div>
                </Fragment>
            ) : (
                <Fragment>
                    {showIcon && <BackgroundRemovalIcon aria-hidden={true} />}
                    <div className="dcl-ctx-button-label">{removeBackgroundText}</div>
                </Fragment>
            )}
        </ToolbarButton>
    );
}
RemoveBackgroundButton.displayName = "RemoveBackgroundButton";
