import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { Link } from "@vp/swan";
import { UploadsPanelMessages } from "@shared/features/UploadsAndAssets";
import isEqual from "lodash/isEqual";
import {
    useAppSelector,
    useAppDispatch,
    setShowDeleteImageModal,
    setDeleteImageData,
    clearDeleteImageData,
    setConfirmImageDeletion
} from "@shared/redux";
import type { Page } from "../../../@types/page";
import { useDesigner } from "../../../designer/DesignerProvider";
import { useUploadsContext } from "../../../designer/UploadsProvider";
import { UploadDeleteIcon } from "./Icons";

export interface DeleteButtonProps {
    uploadName?: string;
    uploadId?: string;
    page?: Page;
}

interface Props extends DeleteButtonProps {
    onDelete?: (model: any) => void;
}

const UploadDeleteButton = (props: Props) => {
    const { uploadName, uploadId, page, onDelete } = props;
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const { deleteUpload } = useUploadsContext();
    const dispatch = useAppDispatch();
    const deleteImageData = useAppSelector(state => state.deleteImageData);
    const confirmImageDeletion = useAppSelector(state => state.confirmImageDeletion);
    const handleOpenModal = (evt: React.MouseEvent) => {
        evt.stopPropagation();
        dispatch(setDeleteImageData({ uploadName, uploadId, page }));
        dispatch(setShowDeleteImageModal(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function onClick() {
        if (!designer || typeof window === "undefined" || !localStorage) {
            return;
        }

        // Remove the backbone model in cimpress designer so other initializeUploads does not pick it up again
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const deletingModel = designer.uploadManager.models.find((model: any) => {
            return model.get("id") === uploadId;
        });
        if (page) {
            const modelPages = deletingModel.get("pages");
            modelPages.remove(page);
            if (modelPages.length === 0) {
                designer.uploadManager.remove(deletingModel);
                deletingModel?.destroy();
            } else {
                // Making use of the uploadManager to pass the event along
                designer.uploadManager.trigger("pageRemoved");
            }
        } else {
            designer.uploadManager.remove(deletingModel);
            deletingModel?.destroy();
        }

        designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_IMAGEPANEL_DELETE);
        if (onDelete) {
            onDelete(deletingModel);
        } else {
            deletingModel && deleteUpload(deletingModel.get("originalUrl"));
        }
        dispatch(setShowDeleteImageModal(false));
        dispatch(clearDeleteImageData());
        dispatch(setConfirmImageDeletion(false));
    }

    if (confirmImageDeletion && isEqual({ uploadName, uploadId, page }, deleteImageData)) {
        onClick();
    }

    return (
        <>
            <div className="dcl-thumb__delete-button">
                <Link
                    component="button"
                    onClick={handleOpenModal}
                    className="dcl-thumb__actions__icon"
                    aria-label={`${t(UploadsPanelMessages.deleteButton.id)} ${uploadName}`}
                >
                    <UploadDeleteIcon role="img" />
                </Link>
            </div>

            <Link component="button" className="uploads_panel__delete-button" onClick={handleOpenModal}>
                {t(UploadsPanelMessages.deleteButton.id)} <span className="swan-visually-hidden">{uploadName}</span>
            </Link>
        </>
    );
};

UploadDeleteButton.displayName = "UploadDeleteButton";
export { UploadDeleteButton };
