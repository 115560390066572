import React from "react";

export function SearchResetIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" fill="none" {...props}>
            <circle cx="10" cy="10" r="10" fill="#666666" />
            <path d="M6.00018 14.0001L14.0002 6.00003" stroke="white" strokeWidth="1.2" />
            <path d="M14.0001 14.0001L6.00015 6.00003" stroke="white" strokeWidth="1.2" />
        </svg>
    );
}
SearchResetIcon.displayName = "SearchResetIcon";
