import React from "react";
import {
    FontSizeSelectorStudio5,
    useActiveCanvas,
    useFonts,
    getMinMaxFontSizeInterval,
    getIsFontSelectorCompatible,
    multipleFontSizeExist,
    truncatedFontSize
} from "@designer-suite";
import { useDecorationTechnology } from "@utilities";
import { addModelsToSelection } from "../util";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { useRichTextAttribute } from "../designer/useRichTextAttribute";
import { useRichTextSet } from "../designer/useRichTextSet";

interface FontSizeProps {
    /** For overriding styles */
    className?: string;

    /** Whether to use an expandable or just the content */
    expandable?: boolean;

    //* * Is this the docked mobile tool */
    mobileDock?: boolean;
}

function getFontSizeValue(selectedFontSizes: number[], differentFontSizesPresent: boolean): number {
    const selectedFontSize = differentFontSizesPresent ? Math.min(...selectedFontSizes) : selectedFontSizes[0];
    const fontSize = truncatedFontSize(selectedFontSize);

    return fontSize;
}

const defaultArray = [] as number[];

// when sheetUpdates1C is rolled out, stop treating font size value as a string - just a number
export function TextFontSizeSelector({ className, expandable = true, mobileDock = false }: FontSizeProps) {
    const designer = useDesigner();
    const fonts = useFonts();
    const decorationTechnology = useDecorationTechnology();
    const canvas = useActiveCanvas();
    const selection = useSelection("model:change:fontSize model:change:content");
    const selectedFontSizes = useRichTextAttribute<number[]>("all", "fontSize", defaultArray);
    const selectedFontFamily = useRichTextAttribute<string>("common", "fontFamily", "");
    const { setWithSideEffects } = useRichTextSet();

    if (
        !selection.length ||
        !getIsFontSelectorCompatible(designer, selection) ||
        !selectedFontSizes ||
        !canvas ||
        !selectedFontFamily
    ) {
        return null;
    }
    const defaultFontConfig = designer.clients?.font?.fontSizeConfig[decorationTechnology];
    const differentFontSizesPresent = multipleFontSizeExist(selectedFontSizes);

    const { minLimit, maxLimit } = getMinMaxFontSizeInterval(
        designer,
        canvas,
        defaultFontConfig,
        fonts,
        selectedFontFamily
    );

    // Set fontSize dropdown selection label and value
    const fontSize = getFontSizeValue(selectedFontSizes, differentFontSizesPresent);

    const onChange = (value: number) => {
        setWithSideEffects(addModelsToSelection(selection), "fontSize", value);
    };

    return (
        <FontSizeSelectorStudio5
            onChange={onChange}
            initialFontSize={fontSize}
            min={minLimit}
            max={maxLimit}
            expandable={expandable}
            mobileDock={mobileDock}
            className={className}
        />
    );
}
TextFontSizeSelector.displayName = "TextFontSizeSelector";
