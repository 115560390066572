import React, { useRef, useEffect, ReactNode } from "react";
import { useDesigner } from "./designer/DesignerProvider";
import "./selectedRichTextField.scss";
import { WordArtField } from "./TextList/WordArtField";

interface Props {
    /** React component to render at right of the textfield */
    icon?: ReactNode;
    /** canvas item being edited */
    item: WordArtItem;
    /** whether to focus the item when the text field is focused */
    selectOnFocus: boolean;
}

/**
 * A simple wrapper for the contextual wordart field
 */
export function SelectedWordArtField({ item, icon, selectOnFocus = false }: Props) {
    const designer = useDesigner();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (!designer) {
            return undefined;
        }

        const insertTextHandler = (e: KeyboardEvent) => {
            if (inputRef.current) {
                e.preventDefault();
                inputRef.current.focus();
                inputRef.current.value += String.fromCharCode(e.which);
            }
        };

        designer.eventBus.on(designer.eventBus.events.insertText, insertTextHandler);
        return () => designer.eventBus.off(designer.eventBus.events.insertText, insertTextHandler);
    }, [designer, inputRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    return <WordArtField item={item} icon={icon} inputRef={inputRef} selectOnFocus={selectOnFocus} />;
}
SelectedWordArtField.displayName = "SelectedWordArtField";
