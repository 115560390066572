import { updateSelectedItems, pxToMm, isSingleColorCanvas, isImageUnreplacedPlaceholder } from "@utilities";
import { fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { StudioMetadataItem } from "@shared/utils/Metadata";
import {
    DEFAULT_IMAGE_MIN_WIDTH,
    DEFAULT_IMAGE_MIN_HEIGHT
} from "../../../utilities/designerConfiguration/DefaultConfig.config";
import {
    NP_IMAGE_PREFIX,
    constructNounProjectMetadata
} from "../../../../studioFive/components/Panels/Images/ImagePanelUtils";
import type { Page, PhysicalDimensions } from "../../@types/page";
import type { Upload } from "../../@types/upload";
import type { Designer, ItemSelection } from "../../@types/designer";

export interface PlacementStrategyParams {
    upload: Upload;
    page: Page;
    designer?: Designer;
    maxPercentOfCanvas?: number;
    selection?: ItemSelection;
    mmPosition?: CanvasPosition;
}

export type PlacementStrategyCallback = (params: PlacementStrategyParams) => void;

export interface PlacementStrategy {
    onClick?: PlacementStrategyCallback;
    onDoubleClick?: PlacementStrategyCallback;
    onUpload?: PlacementStrategyCallback;
}

const PROCESSES_IN_PROGRESS = "processesInProgress";
const UPLOADING_IMAGE = "uploadingImage";
const IMAGE_PROCESSING_IN_PROGRESS = "imageProcessingInProgress";
export const directImageReplacementEvent = "Studio:directImageReplacement";

/**
 * This interface is the converted user page that the UploadStrategies uses.  I tried to eliminate this interface
 * but it got too difficult to manage.
 */
interface FormattedUserPage {
    pageNumber: number;
    pixelDimensions: Dimensions;
    previewUrl: string;
    printUrl: string;
    pdfHighResImageUrl: string;
    physicalDimensions?: PhysicalDimensions;
    xtiUrl?: string;
    analysis?: {
        isPhoto: string;
        isVector: string;
        isLogo: string;
        lineartness: string;
    };
    isSegmented?: boolean;
}

/**
 * This interface is the converted user upload that the UploadStrategies uses.  I tried to eliminate this interface
 * but it got too difficult to manage.
 */

interface FormattedUserUpload {
    id: string;
    fileType?: string;
    fileName: string;
    fileSize?: unknown;
    fileUrl: string;
    originalUrl?: string;
    mimeType: string;
    printUrl: string;
    pages: FormattedUserPage[];
    status?: number;
}

/**
 * This type was lifted from designer code to allow us to create a legacy image like they do for add image.
 */
type LegacyImage = {
    requestId?: string;
    fileType?: string;
    originalUrl?: string;
    pageNumber: number;
    previewUrl: string;
    printUrl?: string;
    status?: number;
    pdfHighResImageUrl?: string;
    analysis?: any;
    width?: number;
    height?: number;
    physicalDimensions?: PhysicalDimensions;
    xtiUrl?: string;
    isSegmented?: boolean;
};

/*
 *   Check to see if the upload has a preview url - sometimes uploads that are still being processed do not
 *   and trying to add them to the canvas will result in a designer error being displayed to the user
 */
function uploadPlacementEarlyExit(upload: Upload | FormattedUserUpload) {
    return !upload?.pages?.[0]?.previewUrl;
}

/*
 * Check if an image's initial pixel dimensions take up more than the specified max percent of a canvas
 * (whichever side takes up a larger percent of the canvas) and returns a resized value if it needs to be resized
 */
export function updatedInitialSize(designer: Designer | undefined, page: Page, maxPercentOfCanvas?: number) {
    if (!maxPercentOfCanvas || !designer) {
        return undefined;
    }
    const canvas = designer.documentRepository.getActiveCanvas();
    const canvasHeight = canvas.get("height");
    const canvasWidth = canvas.get("width");
    const originalMmHeight = pxToMm(page.get("height"));
    const originalMmWidth = pxToMm(page.get("width"));

    let resizedMmHeight;
    let resizedMmWidth;

    // Resize if image width or height is greater than the max percent of canvas in either dimension
    if (originalMmHeight > canvasHeight * maxPercentOfCanvas || originalMmWidth > canvasWidth * maxPercentOfCanvas) {
        // Which dimension does the icon take up a bigger % of
        const largerDimensionPercentSide =
            originalMmHeight / canvasHeight > originalMmWidth / canvasWidth ? "height" : "width";

        if (largerDimensionPercentSide === "height") {
            resizedMmHeight = Math.max(canvasHeight * maxPercentOfCanvas, DEFAULT_IMAGE_MIN_HEIGHT);
            const scaleFactor = resizedMmHeight / originalMmHeight;
            resizedMmWidth = originalMmWidth * scaleFactor;
        } else {
            resizedMmWidth = Math.max(canvasWidth * maxPercentOfCanvas, DEFAULT_IMAGE_MIN_WIDTH);
            const scaleFactor = resizedMmWidth / originalMmWidth;
            resizedMmHeight = originalMmHeight * scaleFactor;
        }
        return { width: resizedMmWidth, height: resizedMmHeight };
    }
    return undefined;
}

/**
 * This function is almost a duplicate from th one in designer. I copied it here so i can have more control over how the image is created in designer.
 * Designer doesn't allow us to add arbitray information to the image (like the studio metadata)
 * @param upload formattedUpload data
 * @param pageNumber page number to be used.
 * @returns createdImageData
 */
const buildLegacyImage = (
    upload: FormattedUserUpload,
    pageNumber: number,
    singleColorCanvas: boolean | undefined
): LegacyImage => {
    const page = upload.pages.find(p => p.pageNumber === pageNumber);
    if (!page) {
        throw Error("The provided page does not exist on the provided upload");
    }

    // skip designer processing for images but not icons on single color canvas
    const isSegmented = singleColorCanvas && !upload.id.startsWith(NP_IMAGE_PREFIX);

    const image: LegacyImage = {
        requestId: upload.id,
        fileType: upload.mimeType || upload.fileType,
        originalUrl: upload.fileUrl || upload.originalUrl,
        pageNumber: page.pageNumber,
        previewUrl: page.previewUrl,
        printUrl: page.printUrl,
        status: upload.status,
        pdfHighResImageUrl: page.pdfHighResImageUrl,
        analysis: page.analysis,
        xtiUrl: page.xtiUrl,
        isSegmented
    };

    if (page.physicalDimensions) {
        // setting width and height only because create image uses it to determine aspect ratio
        image.width = page.physicalDimensions.width;
        image.height = page.physicalDimensions.height;
        image.physicalDimensions = page.physicalDimensions;
    }

    if (page.pixelDimensions) {
        image.width = page.pixelDimensions.width;
        image.height = page.pixelDimensions.height;
    }

    return image;
};

/**
 *  This function will add an image to the canvas.  I copied this from designer because designer doesn't allow for extra data to be added to images
 * like the studio metadata
 * @param upload Upload data.
 * @param pageNumber page number of the image
 * @param mmPosition position of the image
 * @param mmDimensions size of the image
 * @param designer
 * @param metadata studio metadata to add to the image
 */
function addImage(
    upload: FormattedUserUpload,
    pageNumber: number,
    mmPosition: CanvasPosition | undefined,
    mmDimensions: Dimensions | undefined,
    designer: Designer,
    metadata: StudioMetadataItem | undefined
) {
    const singleColorCanvas = isSingleColorCanvas(designer);
    const image = buildLegacyImage(upload, pageNumber, singleColorCanvas);

    const attributes = {
        top: mmPosition?.top,
        left: mmPosition?.left,
        width: mmDimensions?.width,
        height: mmDimensions?.height,
        studioMetadata: metadata
    };

    const addImageOptions = {
        attributes,
        image,
        viewModel: designer.documentRepository.getActiveCanvasViewModel()
    };

    designer.eventBus.trigger(designer.eventBus.events.addImage, addImageOptions);
}

/**
 * This function will construct studio metadata for icons. We can expland this for image library images in the future
 * @param upload Upload data
 * @returns constructed studio metadata
 */
const constructImageMetadata = (upload: FormattedUserUpload): StudioMetadataItem | undefined => {
    if (upload && upload.id && upload.id.startsWith(NP_IMAGE_PREFIX)) {
        return constructNounProjectMetadata(upload.id.replace(NP_IMAGE_PREFIX, ""));
    }
    return undefined;
};

/**
 * Converts and upload to the formatted upload. (Which is the format upload strategies us)
 * @param upload Cimpress designer formatted upload to convert
 * @param page Cimpress designer formatted page to convert
 * @returns converted upload
 */
function formattedUserUpload(upload: Upload, page: Page, singleColorCanvas: boolean | undefined): FormattedUserUpload {
    // skip designer processing for images but not icons on single color canvas
    const isSegmented = singleColorCanvas && !upload.get("id").startsWith(NP_IMAGE_PREFIX);
    return {
        id: upload.get("id"),
        fileName: upload.get("fileName"),
        fileSize: undefined,
        fileUrl: upload.get("originalUrl"),
        mimeType: upload.get("fileType"),
        printUrl: upload.get("printUrl"),
        status: page.get("status"),
        pages: [
            {
                analysis: page.get("analysis"),
                pageNumber: page.get("pageNumber"),
                pixelDimensions: { width: page.get("width"), height: page.get("height") },
                previewUrl: page.get("previewUrl"),
                printUrl: page.get("printUrl"),
                pdfHighResImageUrl: page.get("pdfHighResImageUrl"),
                xtiUrl: page.get("xtiUrl"),
                isSegmented
            }
        ]
    };
}

/**
 * Get the active canvas ordinal from designer
 * @param designer
 * @returns ordinal
 */
const activeCanvasIndex = (designer: Designer) => designer.documentRepository.getActiveCanvas().get("ordinal") - 1;

/**
 * Start the process of uploading an image to the uploads server. This is called when you start to drag an icon onto the canvas, and after an icon has been clicked on.
 * @param itemViewModel itemViewModel for the image we are uploading
 * @param url image url
 * @param designer
 */
export const startUploadingImage = (itemViewModel: ItemViewModel, url: string, designer: Designer) => {
    // Get the current in progress processes (We do all this to show the spinner, and block saving)
    const inProgress = itemViewModel.get(PROCESSES_IN_PROGRESS);

    // Construct a new one with anything there before
    const newProcesses = [...inProgress, UPLOADING_IMAGE];

    // Set the processes
    itemViewModel.set(PROCESSES_IN_PROGRESS, newProcesses);

    // Set the number of processess
    itemViewModel.set(IMAGE_PROCESSING_IN_PROGRESS, newProcesses.length);

    // Upload!
    designer.api.uploads.uploadFromUrl({ url }).then(upload => {
        // When done remove the processes and reset the count so the spinner goes away (and we can now save)
        const inProgress = itemViewModel.get(PROCESSES_IN_PROGRESS);
        inProgress.splice(inProgress.indexOf(UPLOADING_IMAGE), 1);
        itemViewModel.set(PROCESSES_IN_PROGRESS, inProgress);
        itemViewModel.set(IMAGE_PROCESSING_IN_PROGRESS, inProgress.length);

        // Se the data returned from the upload in the image
        itemViewModel.model.set({
            ...upload.pages[0],
            originalUrl: upload.fileUrl
        });
    });
};

interface PlaceUploadParams {
    upload: FormattedUserUpload;
    pageNumber: number;
    designer?: Designer;
    mmPosition?: CanvasPosition;
    mmInitialSize?: Dimensions;
    startUpload?: boolean;
}

/**
 * Place the upload on the canvas from a default click
 * If startupload is true, it will start the process to upload the image to the upload server
 */
const placeUpload = ({ upload, pageNumber, designer, mmPosition, mmInitialSize, startUpload }: PlaceUploadParams) => {
    const startTime = performance.now();

    if (uploadPlacementEarlyExit(upload) || !designer) {
        return;
    }

    const metadata = constructImageMetadata(upload);
    addImage(upload, pageNumber, mmPosition, mmInitialSize, designer, metadata);

    let unsubscribeImageAdded: () => void;
    const logTiming = (event: EventData) => {
        if (unsubscribeImageAdded) {
            unsubscribeImageAdded();
        }

        const itemViewModel = event.items[0]._itemViewModel;
        if (upload.id === itemViewModel.model.get("requestId")) {
            if (startUpload) {
                startUploadingImage(itemViewModel, upload.fileUrl, designer);
            }

            const endTime = performance.now();
            fireUserInteractionTrackingEvent("Add Image To Canvas", endTime - startTime, {
                uploadStrategy: "placeUpload"
            });
        }
    };
    unsubscribeImageAdded = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, logTiming);
};

interface FillAndPlaceUploadParams {
    upload: FormattedUserUpload;
    pageNumber: number;
    designer?: Designer;
    addImageIfNoPlaceholders: boolean;
    mmPosition?: CanvasPosition;
    mmInitialSize?: Dimensions;
}

/**
 * This function fill a placeholder image or place the image on the panel if none exists
 */
const fillAndPlaceUpload = ({
    upload,
    pageNumber,
    designer,
    addImageIfNoPlaceholders,
    mmPosition,
    mmInitialSize
}: FillAndPlaceUploadParams) => {
    const startTime = performance.now();

    if (uploadPlacementEarlyExit(upload) || !designer) {
        return;
    }
    const canvasIndex = activeCanvasIndex(designer);
    const canvas = designer.api.design.canvases[canvasIndex];
    const placeholderCount = canvas.items.filter(isImageUnreplacedPlaceholder).length;

    const placeholders = canvas.items.filter(isImageUnreplacedPlaceholder);
    if (placeholders.length === 0 && addImageIfNoPlaceholders) {
        const metadata = constructImageMetadata(upload);
        addImage(upload, pageNumber, mmPosition, mmInitialSize, designer, metadata);
    } else {
        designer.api.design.update((canvases: Canvas[]) => {
            const canvas = canvases[canvasIndex];
            const placeholders = canvas.items.filter(isImageUnreplacedPlaceholder);
            if (placeholders.length > 0) {
                const thing = placeholders[0] as ImageItem;

                thing.replaceImage({ upload, pageNumber });

                designer.eventBus.trigger(directImageReplacementEvent, { items: [thing] });
                thing._itemViewModel.model.set("locked", false);

                const metadata = constructImageMetadata(upload);
                if (metadata) {
                    thing._itemViewModel.model.set("studioMetadata", metadata);
                }
            }
        });
    }
    // Only add add the listener to track timing when an image is actually added to canvas
    // Without this check, when a user uploads an image but it's not auto-added to canvas, the listener is mistakenly added
    if (placeholderCount === 1 || addImageIfNoPlaceholders) {
        let unsubscribeImageAdded: () => void;
        const logTiming = (event: EventData) => {
            if (unsubscribeImageAdded) {
                unsubscribeImageAdded();
            }

            if (upload.id === event.items[0]._itemViewModel.model.get("requestId")) {
                const endTime = performance.now();
                fireUserInteractionTrackingEvent("Add Image To Canvas", endTime - startTime, {
                    uploadStrategy: "fillAndPlaceUpload",
                    replacedPlaceholder: placeholderCount === 1
                });
            }
        };
        unsubscribeImageAdded = designer.api.events.subscribe(
            placeholderCount === 1
                ? designer.api.events.eventTypes.ITEMS_CHANGED
                : designer.api.events.eventTypes.ITEMS_ADDED,
            logTiming
        );
    }
};

interface ReplaceSelectedImageParams {
    upload: FormattedUserUpload;
    pageNumber: number;
    designer?: Designer;
    selection?: ItemSelection;
}
/**
 * This function will replace selected image with a new one
 */
const replaceSelectedImage = ({ upload, pageNumber, designer, selection }: ReplaceSelectedImageParams) => {
    const startTime = performance.now();

    if (uploadPlacementEarlyExit(upload) || !designer) {
        return;
    }
    updateSelectedItems(designer, selection, item => {
        if (item.itemType === "IMAGE") {
            const imageItem = item as ImageItem;
            imageItem.replaceImage({ upload, pageNumber });
            designer.eventBus.trigger(directImageReplacementEvent, { items: [imageItem] });
            const { model } = imageItem._itemViewModel;

            if (model.get("placeholder") && model.get("locked")) {
                model.set("locked", false);

                // Cimpress Designer is expected to update the placeholderReplaced attribute in response
                // to replaceImage. However, in some cases it does not update as expected, requiring us
                // to perform the state update from Easel here to ensure correct execution of the
                // ReplaceImageButton component's logic checking whether the placeholder has been replaced.
                // Without this, isImageUnreplacedPlaceholder returns false incorrectly, resulting in
                // a spurious opening of the image upload modal after the user replaces an image.
                model.set("placeholderReplaced", true);
            }
        }
    });
    let unsubscribeImageAdded: () => void;
    const logTiming = (event: EventData) => {
        if (unsubscribeImageAdded) {
            unsubscribeImageAdded();
        }

        if (upload.id === event.items[0]._itemViewModel.model.get("requestId")) {
            const endTime = performance.now();
            fireUserInteractionTrackingEvent("Add Image To Canvas", endTime - startTime, {
                uploadStrategy: "replaceSelectedImage"
            });
        }
    };
    unsubscribeImageAdded = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_CHANGED, logTiming);
};

const formatFillAndPlaceUpload = (addImageIfNoPlaceholders: boolean, params: PlacementStrategyParams) => {
    const { upload: uploadData, page, maxPercentOfCanvas, designer, mmPosition } = params;
    const isSegmented = isSingleColorCanvas(designer);
    const upload = formattedUserUpload(uploadData, page, isSegmented);
    const mmInitialSize = updatedInitialSize(designer, page, maxPercentOfCanvas);
    fillAndPlaceUpload({
        upload,
        pageNumber: page.get("pageNumber"),
        designer,
        addImageIfNoPlaceholders,
        mmInitialSize,
        mmPosition
    });
};

const formatAndPlaceUpload = (startUpload: boolean, params: PlacementStrategyParams) => {
    const { upload: uploadData, page, designer, maxPercentOfCanvas, mmPosition } = params;
    const isSegmented = isSingleColorCanvas(designer);
    const upload = formattedUserUpload(uploadData, page, isSegmented);
    const mmInitialSize = updatedInitialSize(designer, page, maxPercentOfCanvas);
    placeUpload({ upload, pageNumber: page.get("pageNumber"), designer, mmInitialSize, startUpload, mmPosition });
};

const formatAndReplaceUpload = (params: PlacementStrategyParams) => {
    const { upload: uploadData, page, designer, selection } = params;
    const isSegmented = isSingleColorCanvas(designer);
    const upload = formattedUserUpload(uploadData, page, isSegmented);
    replaceSelectedImage({ upload, pageNumber: page.get("pageNumber"), designer, selection });
};

interface Strategies {
    AutofillAndPlace: PlacementStrategy;
    AutofillSinglePlaceholder: PlacementStrategy;
    ReplaceSelected: PlacementStrategy;
    PlaceOnCanvasAndUpload: PlacementStrategy;
    PlaceOnCanvas: PlacementStrategy;
}

export const UploadStrategies: Strategies = {
    AutofillAndPlace: {
        onClick: (params: PlacementStrategyParams) => {
            formatFillAndPlaceUpload(true, params);
        },
        onUpload: (params: PlacementStrategyParams) => {
            formatFillAndPlaceUpload(true, params);
        }
    },
    AutofillSinglePlaceholder: {
        onUpload: (params: PlacementStrategyParams) => {
            formatFillAndPlaceUpload(true, params);
        },
        onDoubleClick: (params: PlacementStrategyParams) => {
            formatFillAndPlaceUpload(true, params);
        },
        onClick: (params: PlacementStrategyParams) => {
            formatFillAndPlaceUpload(true, params);
        }
    },
    ReplaceSelected: {
        onClick: (params: PlacementStrategyParams) => {
            formatAndReplaceUpload(params);
        },
        onUpload: (params: PlacementStrategyParams) => {
            formatAndReplaceUpload(params);
        }
    },
    PlaceOnCanvasAndUpload: {
        onClick: (params: PlacementStrategyParams) => {
            formatAndPlaceUpload(true, params);
        }
    },
    PlaceOnCanvas: {
        onClick: (params: PlacementStrategyParams) => {
            formatAndPlaceUpload(false, params);
        }
    }
};
