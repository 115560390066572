import { useIdeaTabEnabled } from "@five/hooks/useIdeaTabEnabled";
import { AITEmplates } from "./AITemplatesConstant";
import { GenerateIdeas } from "../GenerateIdeas/GenerateIdeasConstant";

export const useAITemplateTracking = () => {
    const showIdeasPanel = useIdeaTabEnabled();
    const tracking = showIdeasPanel ? GenerateIdeas : AITEmplates;

    return { tracking };
};
