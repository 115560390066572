import React, { useEffect, useRef, useState } from "react";
import once from "lodash/once";
import { fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import type { Upload } from "../../../@types/upload";
import { UploadMultipageThumbnail } from "./UploadMultipageThumbnail";
import { UploadMultipageBox } from "./UploadMultipageBox";
import { UploadThumbnail } from "./UploadThumbnail";
import { useDocumentUploads } from "../useDocumentUploads";
import { useDocumentImages } from "../useDocumentImages";
import "./uploadsPanel.scss";
import { PlacementStrategyCallback } from "../UploadStrategies";

type Props = {
    userUploads: Upload[];
    onUploadClicked?: PlacementStrategyCallback;
    onUploadDoubleClicked?: PlacementStrategyCallback;
    className: string;
    columnCount: number;
    allowHiding: boolean;
    isReplacePanel?: boolean;
    isUploadModalAutoLoaded?: boolean;
};

type UploadThumbnailChild = {
    type: string;
    upload: Upload;
    className?: string;
};

const fireUserInteractionOnce = once(fireUserInteractionTrackingEvent);

const UploadThumbnailsPanelRedesign = (props: Props) => {
    const {
        userUploads,
        onUploadClicked,
        onUploadDoubleClicked,
        className,
        columnCount,
        allowHiding,
        isUploadModalAutoLoaded
    } = props;
    const [multiId, setMultiId] = useState<string>();
    const [uploadThumbnails, setUploadThumbnails] = useState<UploadThumbnailChild[]>([]);
    const documentImages = useDocumentImages();
    const { shouldPagesBeShown, isPageOnDocument } = useDocumentUploads(documentImages);

    const startTime = useRef(0);
    const loadedImagesCount = useRef(0);

    useEffect(() => {
        startTime.current = performance.now();
        const newChildren: UploadThumbnailChild[] = [];
        let multiIdIndex = -1;
        let index = 0;
        userUploads.forEach((upload: Upload) => {
            const pages = upload.get("pages");
            // Look at all the pages in the image.
            // If they are all used and the hide flag is turned on skip the image completely
            // For multipage images, if any of the pages are not used, and the flag is turned on we want to show it.
            if (shouldPagesBeShown(pages) || !allowHiding) {
                if (pages.length > 1) {
                    if (upload.id === multiId) {
                        multiIdIndex = index;
                    }
                    newChildren.push({ upload, type: "multi-page-thumbnail" });
                } else {
                    newChildren.push({ upload, type: "single-page-thumbnail" });
                }
                index += 1;
            }
        });

        if (multiIdIndex !== -1) {
            const { upload } = newChildren[multiIdIndex];
            const remainder = multiIdIndex % columnCount;
            newChildren.splice(multiIdIndex + (columnCount - remainder), 0, {
                upload,
                type: "multi-page-box",
                className: `easel-thumbnail-box-${columnCount}-column-${remainder}`
            });
        }

        setUploadThumbnails(newChildren);
    }, [userUploads, multiId, columnCount, shouldPagesBeShown, allowHiding]);

    function toggleMultiId(id: string | undefined) {
        if (id === multiId) {
            setMultiId(undefined);
        } else {
            setMultiId(id);
        }
    }

    function onImageLoaded() {
        loadedImagesCount.current += 1;
        if (startTime && uploadThumbnails.length > 0 && loadedImagesCount.current === uploadThumbnails.length) {
            const endTime = performance.now();
            fireUserInteractionOnce("Load Images", endTime - startTime.current, {
                imagesLoaded: uploadThumbnails.length,
                panel: props.isReplacePanel ? "Replace Images Panel" : "Images Panel"
            });
        }
    }

    function resolveComponent(child: UploadThumbnailChild) {
        const {
            type,
            upload,
            upload: { id },
            className: childClass
        } = child;
        const pages = upload.get("pages");
        if (!pages || !pages.at(0)) {
            return null;
        }
        const page = pages.at(0);
        switch (type) {
            case "multi-page-thumbnail":
                return (
                    <UploadMultipageThumbnail
                        upload={upload}
                        onClick={() => toggleMultiId(id)}
                        key={`multipage-${id}`}
                        expanded={multiId === id}
                        onImageLoaded={onImageLoaded}
                    />
                );
            case "multi-page-box":
                return (
                    <UploadMultipageBox
                        onUploadClicked={onUploadClicked}
                        onUploadDoubleClicked={onUploadDoubleClicked}
                        upload={upload}
                        key={`multibox-${id}`}
                        className={childClass}
                        isPageOnDocument={isPageOnDocument}
                        shouldPagesBeShown={shouldPagesBeShown}
                        allowHiding={allowHiding}
                        onImageLoaded={onImageLoaded}
                    />
                );
            default:
                return (
                    <UploadThumbnail
                        page={page}
                        upload={upload}
                        onClick={onUploadClicked}
                        onDoubleClick={onUploadDoubleClicked}
                        key={`thumbnail-${page.cid}`}
                        uploadUsage={isPageOnDocument(page)}
                        onImageLoaded={onImageLoaded}
                        isUploadModalAutoLoaded={isUploadModalAutoLoaded}
                    />
                );
        }
    }

    resolveComponent.displayName = "resolveComponent";

    return (
        <div className={className} data-dcl-prevent-canvas-items-deselection>
            {uploadThumbnails.map(resolveComponent)}
        </div>
    );
};

UploadThumbnailsPanelRedesign.displayName = "UploadThumbnailsPanelRedesign";

export { UploadThumbnailsPanelRedesign };
