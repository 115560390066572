import { useAppSelector } from "@shared/redux";
import { Typography } from "@vp/swan";
import React from "react";

export const ProjectName = () => {
    const workName = useAppSelector(state => state.workName);
    const productName = useAppSelector(state => state.productName);

    const nameToShow = workName || productName;

    return <Typography fontSize={-1}>{nameToShow}</Typography>;
};

ProjectName.displayName = "ProjectName";
