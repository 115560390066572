import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ToolbarButton, BucketColorIcon } from "@shared/features/StudioChrome";
import classNames from "classnames";
import type { SimpleButtonProps } from "../../PresentationalToolbarItems/types";
import * as styles from "./SingleColorImageColorPickerButton.module.scss";

const recolorizeMessages = defineMessages({
    recolorizeButtonLabel: {
        id: "easel.designersuite.color.recolorizeButtonLabel",
        defaultMessage: "Color"
    }
});

interface SingleColorImageColorPickerButtonProps extends SimpleButtonProps {
    isSelected: boolean;
}

export function SingleColorImageColorPickerButton({
    className,
    showIcon = true,
    isSelected = false,
    ...rest
}: SingleColorImageColorPickerButtonProps) {
    const { t } = useTranslationSSR();

    return (
        <ToolbarButton className={classNames(styles.colorPickerButton)} isSelected={isSelected} {...rest}>
            {showIcon && <BucketColorIcon aria-hidden={true} />}
            <div className={classNames(styles.colorPickerLabel)}>{t(recolorizeMessages.recolorizeButtonLabel.id)}</div>
        </ToolbarButton>
    );
}
SingleColorImageColorPickerButton.displayName = "SingleColorImageColorPickerButton";
