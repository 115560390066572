import React from "react";
import { ItemContentInformation } from "@designer-suite";

type Props = {
    item: CanvasItem;
    children: any;
};

export function PremiumFinishSelectionItem({ item, children }: Props) {
    return (
        <>
            <ItemContentInformation item={item} />
            {children}
        </>
    );
}

PremiumFinishSelectionItem.displayName = "PremiumFinishSelectionItem";
