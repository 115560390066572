import React, { useEffect, useState } from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { SelectedCheckIcon } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import type { Page } from "src/easel/designer-suite/@types/page";
import type { Upload } from "src/easel/designer-suite/@types/upload";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useAutoRemoveBackground } from "../../../../../studioFive/hooks/useAutoRemoveBackground";
import { useActiveCanvas } from "../../../designer/useActiveCanvas";
import { UploadImage } from "../UploadImage";
import UploadStatus from "../UploadStatus";
import { useUploadStatusStyle } from "../useUploadStatusStyle";
import { uploadStatusTypes } from "../uploadStatusTypes";
import { UploadUsage } from "../useDocumentUploads";
import { ImagePremiumFinishIcon } from "./Icons";
import { ImageDeleteButton } from "./ImageDeleteButton";
import { PlacementStrategyCallback } from "../UploadStrategies";

interface Props {
    // Upload as presented in designer.uploadManager
    page: Page;
    upload: Upload;
    onClick?: PlacementStrategyCallback;
    onDoubleClick?: PlacementStrategyCallback;
    uploadUsage: UploadUsage;
    isMultiPage?: boolean;
    isUploadModalAutoLoaded?: boolean;
    onImageLoaded?: () => void;
}

function UploadThumbnail(props: Props) {
    const {
        page,
        upload,
        onClick,
        onDoubleClick,
        uploadUsage: { hasPremiumFinish, isOnPage },
        isMultiPage = false,
        isUploadModalAutoLoaded
    } = props;
    const [uploadStatus, setUploadStatus] = useState(page.get("status"));
    const uploadId = upload.id;
    const { isSmall } = useStudioLayout();
    const canvas = useActiveCanvas();
    const activeCanvasName = canvas && canvas.name;
    const toggleBackground = useAutoRemoveBackground();
    const designRequirements = useDesignRequirementsContext();

    const activePanelId = activeCanvasName ? designRequirements?.getPanelByName(activeCanvasName)?.id : undefined;

    const { style, statusText } = useUploadStatusStyle(
        uploadStatus === uploadStatusTypes.postProcessingComplete,
        uploadStatus
    );

    useEffect(() => {
        const onChangeStatus = (pageModel: Page) => {
            setUploadStatus(pageModel.get("status"));
        };

        page.on("change:status", onChangeStatus);
        return () => {
            page.off("change:status", onChangeStatus);
        };
    }, [page]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { key } = event;
        if (key && (key === "Enter" || key === " ")) {
            if (onClick) {
                onClick({ upload, page });
            } else if (onDoubleClick) {
                onDoubleClick({ upload, page });
            }
        }
    };

    const processingImgClass = `easel-image-thumbnail mobile-image-container dcl-thumb dcl-thumb--upload ${style}`;

    return (
        <div
            className={processingImgClass}
            onClick={() => {
                isUploadModalAutoLoaded &&
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.CLICK_RECENTLY_UPLOADED_IMAGE.replace(
                            "[CanvasSide]",
                            activeCanvasName || ""
                        ),
                        label: "General",
                        extraData: () => ({
                            productSurfaceId: activePanelId,
                            activeCanvas: activeCanvasName
                        })
                    } as any);
                onClick?.({ upload, page });
            }}
            onDoubleClick={() => onDoubleClick?.({ upload, page })}
            tabIndex={0}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => onKeyDown(event)}
            role="button"
        >
            <UploadStatus upload={upload} statusText={statusText} showPercentageComplete={!isMultiPage} />
            <UploadImage
                className="dcl-thumb__image"
                key={`upload-image-${page.cid}}`}
                page={page}
                upload={upload}
                onImageLoaded={props.onImageLoaded}
                onStop={toggleBackground} // remove background when dragging user upload
            />
            {isOnPage && <SelectedCheckIcon className="upload-check-icon" role="img" />}
            {hasPremiumFinish && isSmall && <ImagePremiumFinishIcon className="image-premium-finish-icon" />}
            <ImageDeleteButton uploadId={uploadId} uploadName={upload.get("fileName")} page={page} />
        </div>
    );
}

UploadThumbnail.displayName = "UploadThumbnail";

export { UploadThumbnail };
