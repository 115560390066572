import { type ExtendedSurfaceUpsell, useAppSelector } from "@shared/redux";
import { useCallback } from "react";
import { StudioPanelDesignRequirements } from "../DesignRequirementsProvider";

export function isBlankAndHasNoUpsells(
    panel: StudioPanelDesignRequirements,
    surfaceUpsells: Record<string, ExtendedSurfaceUpsell>
) {
    const isCanvasBlank = panel.colorMode === "blank";
    return isCanvasBlank && !surfaceUpsells[panel.name];
}

export function useIsBlankAndHasNoUpsells() {
    const surfaceUpsells = useAppSelector(state => state.surfaceUpsells);

    return useCallback(
        (panel: StudioPanelDesignRequirements) => {
            return isBlankAndHasNoUpsells(panel, surfaceUpsells);
        },
        [surfaceUpsells]
    );
}
