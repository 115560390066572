import React from "react";
import classNames from "classnames";
import { Typography, Divider } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { ColorSwatch } from "./ColorSwatch";
import { ColorSpace, SelectableColor } from "../types";
import { colorToString, convertToRgb, convertToSelectableColor, parseColor } from "../utilities";
import * as styles from "./ColorPalette.module.scss";

const messages = defineMessages({
    recentColors: {
        id: "easel.components.colorpicker.recentColors",
        defaultMessage: "Recent colors",
        description: {
            note: "Label for recently used colors"
        }
    }
});

interface Props {
    /** Selected color */
    selectedColor?: string;
    /** List of recommended colors that are selectable */
    recentColors?: SelectableColor[];
    /** List of standard colors that are selectable */
    paletteColors: SelectableColor[];
    /** Function called when a color has been selected */
    onSelectColor(color: any, colorSpace: ColorSpace): void;
    /** Optional class names to add to component */
    className?: string;
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
}

export function ColorPalette({
    selectedColor = "",
    onSelectColor,
    recentColors = [],
    paletteColors,
    className,
    trackUsage
}: Props) {
    const { t } = useTranslationSSR();

    const toggleColor = (color: SelectableColor) => {
        const parsedColor = parseColor(color.value);
        if (parsedColor) {
            onSelectColor(parsedColor.value, parsedColor.colorSpace);
            if (trackUsage) {
                trackUsage("ColorPalette", color.toString());
            }
        }
    };

    const showRecentColors = recentColors.length > 0;

    return (
        <div className={className}>
            {showRecentColors && (
                <Typography
                    fontSize="-1"
                    textColor="dark-grey"
                    fontWeight="bold"
                    className={classNames(styles.recentColorsTitle, "easel-color-palette__recent-colors-title")}
                >
                    {`${t(messages.recentColors.id)}`}
                </Typography>
            )}
            <div className={styles.colorPaletteContent}>
                {showRecentColors && (
                    <>
                        <div className={styles.recentColors}>
                            {recentColors.map(color => (
                                <ColorSwatch
                                    key={color.value}
                                    color={color}
                                    isSelected={
                                        color.value === selectedColor ||
                                        color.value === colorToString(convertToRgb(selectedColor)) ||
                                        color.cssBackground === convertToSelectableColor(selectedColor).cssBackground
                                    }
                                    toggleColor={toggleColor}
                                />
                            ))}
                        </div>
                        <Divider className={styles.paletteDivider} />
                    </>
                )}

                <div
                    className={classNames(styles.paletteColors, {
                        // switch the flow to columns only if the color palette is completely full
                        // to order the standard colors correctly and avoid weird wrapping when it is not full
                        [styles.paletteColorsColumns]: paletteColors.length === 28
                    })}
                >
                    {paletteColors.map(color => (
                        <ColorSwatch
                            key={color.value}
                            color={color}
                            isSelected={
                                color.value === selectedColor ||
                                color.value === colorToString(convertToRgb(selectedColor)) ||
                                color.cssBackground === convertToSelectableColor(selectedColor).cssBackground
                            }
                            toggleColor={toggleColor}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
ColorPalette.displayName = "ColorPalette";
