import React, { Children, FC, useEffect, useRef, ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import classNames from "classnames";

type Props = {
    id: string;
    className?: string;
    onNext?: () => void;
    hasMore?: boolean;
    count?: number;
    children?: ReactNode | ReactNode[];
    disableAutoSearch?: boolean;
    spinnerClassName?: string;
};

export const InfiniteScrollList: FC<Props> = props => {
    const {
        children,
        id,
        className,
        onNext,
        hasMore = false,
        count,
        disableAutoSearch = false,
        spinnerClassName
    } = props;
    const infiniteScrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (disableAutoSearch) {
            return;
        }
        if (infiniteScrollContainerRef?.current) {
            const hasVerticalScrollbar =
                infiniteScrollContainerRef.current?.scrollHeight > infiniteScrollContainerRef.current?.clientHeight;
            if (!hasVerticalScrollbar) {
                onNext?.();
            }
        }
    }, [infiniteScrollContainerRef, onNext, disableAutoSearch]);

    return (
        <div
            data-testid="infiniteScrollList"
            ref={infiniteScrollContainerRef}
            className={className}
            id={id}
            data-dcl-prevent-canvas-items-deselection
        >
            <InfiniteScroll
                dataLength={count || Children.count(children)}
                next={() => onNext?.()}
                hasMore={hasMore}
                loader={
                    <LoadingSpinner
                        className={classNames("my-3", spinnerClassName)}
                        variant={SpinnerVariant.Large}
                        centering
                    />
                }
                scrollableTarget={id}
            >
                {children}
            </InfiniteScroll>
        </div>
    );
};

InfiniteScrollList.displayName = "InfiniteScrollList";
