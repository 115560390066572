import React, { useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { PremiumFinishPlaceholderWarningIcon } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { FinishMessages } from "../messages";
import * as styles from "./PremiumFinishPlaceholderWarning.module.scss";

export function PremiumFinishPlaceholderWarning() {
    const { t } = useTranslationSSR();
    const [warning, setWarning] = useState<boolean>(false);
    const { isSmall } = useStudioLayout();

    return (
        <div
            onMouseOver={() => setWarning(true)}
            onMouseEnter={isSmall ? () => setWarning(true) : undefined}
            onFocus={() => setWarning(true)}
            onMouseLeave={() => setWarning(false)}
            className={styles.container}
            role="button"
            tabIndex={0}
        >
            <PremiumFinishPlaceholderWarningIcon className={styles.icon} />
            {warning && <div className={styles.tooltip}>{t(FinishMessages.placeholderWarningMessage.id)}</div>}
        </div>
    );
}

PremiumFinishPlaceholderWarning.displayName = "PremiumFinishPlaceholderWarning";
