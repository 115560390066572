/* eslint-disable max-len */
import React from "react";

function ImagePremiumFinishIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 30 30" fill="none" {...props}>
            <circle cx="15" cy="15" r="15" fill="white" />
            <path
                d="M7 13.054C11.5714 13.054 12.9429 9.01802 12.9429 7C13.4 11.3243 15.2286 12.6216 18.8857 13.054C14.3143 13.4865 12.9429 17.0901 12.9429 19.1081C12.9429 14.3514 8.98095 13.1982 7 13.054Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M15.6858 19.1082C18.499 19.1082 19.3429 16.5136 19.3429 15.2163C19.6243 17.9962 20.7495 18.8302 23.0001 19.1082C20.1869 19.3862 19.3429 21.7028 19.3429 23.0001C19.3429 19.9422 16.9048 19.2009 15.6858 19.1082Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
        </svg>
    );
}
ImagePremiumFinishIcon.displayName = "ImagePremiumFinishIcon";

export { ImagePremiumFinishIcon };
