import { useDesigner } from "@designer-suite";
import { useLoadNewProductForFlexibility } from "@five/hooks/useLoadNewProductForFlexibility";
import { ActiveFlexibilityProvider } from "@shared/features/Flexibility";
import { useAppSelector } from "@shared/redux";
import { getDesignDocumentFromDesigner } from "@utilities";
import type { DSS } from "@vp/types-ddif";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";

export function ActiveFlexibilityProviderStudio5({ children }: React.PropsWithChildren<{}>) {
    const [designDocument, setDesignDocument] = useState<DSS.DesignDocument>();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const designer = useDesigner();
    const loadNewProductForFlexibility = useLoadNewProductForFlexibility();
    const getDocumentWithPlaceholders = useCallback(async () => getDesignDocumentFromDesigner(false), []);

    useEffect(() => {
        if (designer && easelLoaded) {
            setDesignDocument(getDesignDocumentFromDesigner(false));
            const debouncedUpdate = debounce(() => setDesignDocument(getDesignDocumentFromDesigner(false)), 200);

            // This works better than the public event bus events - both in terms of frequency +
            // picking up changes that are relevant to update the preview for
            designer.eventBus.on(designer.eventBus.events.historyChanged, debouncedUpdate);

            //  When an image is finishing processing we want to update the preview as well
            designer.eventBus.on(designer.eventBus.events.imageProcessingUpdateModel, debouncedUpdate);

            // eslint-disable-next-line consistent-return
            return () => {
                // cancel any future updates as well as unsubscribing
                debouncedUpdate.cancel();
                designer.eventBus.off(designer.eventBus.events.historyChanged, debouncedUpdate);
                designer.eventBus.off(designer.eventBus.events.imageProcessingUpdateModel, debouncedUpdate);
            };
        }
        return () => {};
    }, [designer, easelLoaded]);

    return (
        <ActiveFlexibilityProvider
            debouncedDesignDocument={designDocument}
            getDocument={getDocumentWithPlaceholders}
            loadNewProductForFlexibility={loadNewProductForFlexibility}
        >
            {children}
        </ActiveFlexibilityProvider>
    );
}

ActiveFlexibilityProviderStudio5.displayName = "ActiveFlexibilityProviderStudio5";
