import React, { useCallback, useRef } from "react";
import debounce from "lodash/debounce";
import { NextStepButton, type NextStepButtonProps, useHandleNextStepClick } from "@shared/features/GlobalHeader";
import { useActiveCanvas } from "@designer-suite";
import { useTriggerCanvasChange } from "@five/hooks/useTriggerCanvasChange";
import { getDesignDocumentFromDesigner } from "@utilities";
import { saveStudio5Design } from "@five/clients/saveClient";
import { useCheckValidationsForNextStep } from "./nextStepHelpers";

export function NextStepButtonStudio5(props: Omit<NextStepButtonProps, "onClick">) {
    const buttonRef = useRef(null);
    const activeCanvas = useActiveCanvas();
    const triggerCanvasChange = useTriggerCanvasChange();
    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(), []);

    const checkValidations = useCheckValidationsForNextStep(buttonRef);
    const handleNextClick = useHandleNextStepClick({
        checkValidations,
        activeCanvasName: activeCanvas?.name,
        triggerCanvasChange,
        getDocument,
        save: saveStudio5Design
    });
    const debouncedHandleNextClick = debounce(handleNextClick, 250);

    return <NextStepButton {...props} onClick={debouncedHandleNextClick} ref={buttonRef} />;
}
NextStepButtonStudio5.displayName = "NextStepButtonStudio5";
