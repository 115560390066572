import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { convertToHsl, isRgb, isCmyk } from "@shared/features/ColorPicker";
import { useDesigner, useSelection } from "@designer-suite";
import { updateSelectedItems, getTrackingDataForImageSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { Hsl, Rgb, useRecentColorsContext } from "@presentational";
import { RGB, CMYK } from "@design-stack-ct/utility-core";
import { areCustomColorsAllowed, getColorPalette } from "../../../../easel/designer-suite/util";
import { COLOR_PICKER_PANEL_TYPES } from "../ColorPicker/colorPickerPanelTypes";
import { ColorPickerPanelWrapper } from "../ColorPicker/ColorPickerPanelWrapper";

const messages = defineMessages({
    title: {
        id: "studio.components.panels.singleColorImageColorpickerPanel.title",
        defaultMessage: "Icon color",
        description: {
            note: "This is the title for a panel that allows the user to change the color of a single color image"
        }
    }
});

interface SingleColorImageColorPickerProps {
    /**
     * display just the content instead of the expanding button (for sheet usage)
     * @default false
     */
    contentOnly?: boolean;
    /**
     * allow custom colors (for single color images)
     * @default true
     */
    allowCustom?: boolean;
    className?: string;
}

export function SingleColorImageColorPickerPanel({
    className = "",
    contentOnly = false,
    allowCustom = true
}: SingleColorImageColorPickerProps) {
    const designer = useDesigner();
    const selection = useSelection("model:change:colorAdjustment");
    const { t } = useTranslationSSR();
    const { recentColors } = useRecentColorsContext();

    if (!selection.length || !designer) {
        return null;
    }

    const paletteColors = getColorPalette(designer, selection[0]);
    const value = selection[0]._itemViewModel.model.get("lastChosenColor");

    function trackColorChange(color: RGB | CMYK | string, isEyeDropper: boolean) {
        if (isEyeDropper) {
            designer?.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.IMAGE_EYEDROPPER_APPLIED,
                getTrackingDataForImageSelection(selection)
            );
        } else if (isRgb(color)) {
            designer?.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.IMAGE_RGB_APPLIED,
                getTrackingDataForImageSelection(selection)
            );
        } else if (isCmyk(color)) {
            designer?.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.IMAGE_CMYK_APPLIED,
                getTrackingDataForImageSelection(selection)
            );
        } else {
            designer?.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.IMAGE_COLORTILE_APPLIED,
                getTrackingDataForImageSelection(selection)
            );
        }
    }

    function onChange(color: Rgb | CMYK | string, isEyeDropper = false) {
        const hsl = convertToHsl(color);
        const { h, s, l } = hsl as Hsl;

        const colorAdjustment = {
            hueMultiplier: 0,
            hueOffset: h,
            saturationMultiplier: 0,
            saturationOffset: s,
            lightnessMultiplier: 0,
            lightnessOffset: l
        };

        updateSelectedItems(designer, selection, item => {
            item._itemViewModel.model.set("colorAdjustment", colorAdjustment);
            item._itemViewModel.model.set("lastChosenColor", color);
        });
        trackColorChange(color, isEyeDropper);
    }

    const allowCustomColors = allowCustom && areCustomColorsAllowed(designer, selection[0]);

    const eyeDropperConfig = {
        isCompatibleWithSelection: () => {
            return true;
        },
        listenEvents: "model:change:data",
        buttonSize: "mini" as const,
        onClick: onChange
    };

    return contentOnly ? (
        <ColorPickerPanelWrapper
            value={value}
            recentColors={recentColors}
            paletteColors={paletteColors}
            onChange={onChange}
            allowCustom={allowCustomColors}
            allowTransparency={false}
            eyeDropperConfig={eyeDropperConfig}
            panelType={COLOR_PICKER_PANEL_TYPES.BACKGROUND_COLOR}
            className={className}
        />
    ) : (
        <PanelContent className="studio-color-panel-content" data-dcl-prevent-canvas-items-deselection>
            <DesktopExperience>
                <div className="color-panel__sticky-nav">
                    <PanelTitle>{t(messages.title.id)}</PanelTitle>
                </div>
            </DesktopExperience>
            <div className="studio-single-color-image-color-picker">
                <ColorPickerPanelWrapper
                    value={value}
                    recentColors={recentColors}
                    paletteColors={paletteColors}
                    onChange={onChange}
                    allowCustom={allowCustomColors}
                    allowTransparency={false}
                    eyeDropperConfig={eyeDropperConfig}
                    panelType={COLOR_PICKER_PANEL_TYPES.ICON_COLOR}
                />
            </div>
        </PanelContent>
    );
}

SingleColorImageColorPickerPanel.displayName = "SingleColorImageColorPickerPanel";
