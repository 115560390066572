import React from "react";
import { useRecentColorsContext } from "@presentational";
import { useDesigner, useSelection, useTableSelectionFilter } from "@designer-suite";
import { useTrackEvents } from "@shared/features/Tracking";
import {
    updateSelectedItems,
    getReferenceData,
    updateTableBackgroundColor,
    isTablesOnly,
    getTableBackgroundColor
} from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { colorToString } from "@shared/features/ColorPicker";
import { areCustomColorsAllowed, getColorPalette } from "../../../../easel/designer-suite/util";
import { getSelectionsTableColor } from "../../../utilities/colorPickerUtilities";
import { ColorPickerPanelWrapper } from "../ColorPicker/ColorPickerPanelWrapper";
import { COLOR_PICKER_PANEL_TYPES } from "../ColorPicker/colorPickerPanelTypes";

const messages = defineMessages({
    textColorTitle: {
        id: "studio.components.panels.tableBackgroundColorpickerPanel.title",
        defaultMessage: "Table background color",
        description: {
            note: "This is the title for a panel that allows the user to change their table's background color"
        }
    }
});

function getTableColor(table: Item) {
    return getTableBackgroundColor(getReferenceData(table)) || "";
}

// Has a baked in assumption that tables will not be present on single color products

export function TableBackgroundColorPickerPanel({ allowCustom = true, contentOnly = false }) {
    const designer = useDesigner();
    const selection = useSelection(`model:change:data`);
    const { tableItems, otherItems } = useTableSelectionFilter(selection);
    const { trackEvent } = useTrackEvents();
    const { recentColors } = useRecentColorsContext();
    const { t } = useTranslationSSR();

    if (!designer || !tableItems.length || otherItems.length) {
        return null;
    }

    const value = getSelectionsTableColor(designer, tableItems, getTableColor);
    // selection is used to look up current canvas - doesn't matter what the type is
    const paletteColors = getColorPalette(designer, selection[0]);

    function onChange(newValue: string) {
        if (!designer) {
            return;
        }
        const newColor = colorToString(newValue);
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TABLE_COLOR });
        updateSelectedItems(designer, tableItems, table => updateTableBackgroundColor(table, newColor));
    }

    const allowCustomColors = allowCustom && areCustomColorsAllowed(designer, selection[0]);

    const eyeDropperConfig = {
        isCompatibleWithSelection: isTablesOnly,
        listenEvents: "model:change:data",
        buttonSize: "mini" as const,
        onClick: onChange
    };

    return contentOnly ? (
        <ColorPickerPanelWrapper
            value={value}
            recentColors={recentColors}
            paletteColors={paletteColors}
            onChange={onChange}
            allowCustom={allowCustomColors}
            eyeDropperConfig={eyeDropperConfig}
            panelType={COLOR_PICKER_PANEL_TYPES.TABLE_BACKGROUND_COLOR}
        />
    ) : (
        <PanelContent className="studio-color-panel-content" data-dcl-prevent-canvas-items-deselection>
            <DesktopExperience>
                <div className="color-panel__sticky-nav">
                    <PanelTitle>{t(messages.textColorTitle.id)}</PanelTitle>
                </div>
            </DesktopExperience>
            <div className="studio-wordart-text-color-picker">
                <ColorPickerPanelWrapper
                    value={value}
                    recentColors={recentColors}
                    paletteColors={paletteColors}
                    onChange={onChange}
                    allowCustom={allowCustomColors}
                    eyeDropperConfig={eyeDropperConfig}
                    panelType={COLOR_PICKER_PANEL_TYPES.TABLE_BACKGROUND_COLOR}
                />
            </div>
        </PanelContent>
    );
}

TableBackgroundColorPickerPanel.displayName = "TableBackgroundColorPickerPanel";
