import React from "react";
import { Breadcrumb } from "@vp/swan";
import { useOnKeyDown } from "@shared/features/Accessibility";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";

interface Props {
    panelType: TemplatePanelContentTypes;
}

const messages = defineMessages({
    mainTitle: {
        id: "studio.components.template.panel.main.title",
        defaultMessage: "Templates",
        description: {
            note: "Templates main panel title"
        }
    },
    optionsTitle: {
        id: "studio.components.template.panel.options.title",
        defaultMessage: "Options",
        description: {
            note: "Templates options panel title"
        }
    },
    recentTitle: {
        id: "studio.components.template.panel.recent.title",
        defaultMessage: "Recent",
        description: {
            note: "Templates recently used panel title"
        }
    }
});

const titleIds: Record<TemplatePanelContentTypes, string> = {
    [TemplatePanelContentTypes.Main]: messages.mainTitle.id,
    [TemplatePanelContentTypes.Color]: messages.optionsTitle.id,
    [TemplatePanelContentTypes.Recent]: messages.recentTitle.id
};

export const TemplatePanelTitleItem = (props: Props) => {
    const { panelType } = props;
    const { stack, jumpToInStack } = useTemplatePanel();
    const onClick = () => {
        jumpToInStack(panelType);
    };
    const { t } = useTranslationSSR();
    const onKeyDown = useOnKeyDown(onClick);
    const breadcrumbCurrent = panelType !== TemplatePanelContentTypes.Main && stack.length > 1;
    return (
        <Breadcrumb className={classNames({ "breadcrumb-current": breadcrumbCurrent })} key={panelType}>
            <span
                className={"template-panel-title"}
                tabIndex={-1}
                role="button"
                onKeyDown={onKeyDown}
                onClick={onClick}
            >
                {t(titleIds[panelType])}
            </span>
        </Breadcrumb>
    );
};

TemplatePanelTitleItem.displayName = "TemplatePanelTitleItem";
