import React from "react";
import { Design } from "@shared/utils/Gallery";
import { TemplatePanelContentTypes, useTemplatePanel } from "./TemplatePanelProvider";
import { useOnDesignTileClick } from "./useOnDesignTileClick";
import { TemplatePanelDesignTile } from "./TemplatePanelDesignTile";
import { TemplatePanelDesignAvailableColors } from "./TemplatePanelAvailableColors";

export const TemplatePanelContentRecent = () => {
    const { recentlyUsedTemplates, addToStack, setSelectedDesign } = useTemplatePanel();
    const { onDesignTileClick } = useOnDesignTileClick();
    const onClick = (design: Design) => {
        if (design.colorSwatches.length) {
            addToStack(TemplatePanelContentTypes.Color);
            setSelectedDesign(design);
        } else {
            onDesignTileClick(design);
        }
    };
    return (
        <div className="recent-template-gallery">
            {recentlyUsedTemplates.map(design => {
                return (
                    <button
                        className="template-panel-gallery-tile"
                        key={`${design.designId}-tile`}
                        onClick={() => onClick(design)}
                    >
                        <TemplatePanelDesignTile design={design} />
                        <TemplatePanelDesignAvailableColors design={design} />
                    </button>
                );
            })}
        </div>
    );
};

TemplatePanelContentRecent.displayName = "TemplatePanelContentRecent";
