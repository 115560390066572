import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useDesigner, focusMobileDock } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import classNames from "classnames";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { defaultDocumentText } from "@shared/utils/CimDoc";
import { useMobileText } from "../MobileTextEditor/MobileTextProvider";
import "./wordArtField.scss";

const messages = defineMessages({
    wordArtLabel: {
        id: "easel.components.wordartfield.wordArtLabel",
        defaultMessage: "Word Art Text Field"
    },
    wordArtLabelWithIndex: {
        id: "easel.components.wordartfield.wordArtLabelWithIndex",
        defaultMessage: "Word Art Text Field [[index]]"
    }
});

interface WordArtFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    item: WordArtItem;
    icon: React.ReactNode;
    selectOnFocus?: boolean;
    inputRef?: React.RefObject<HTMLTextAreaElement> | null;
    /** For tracking usage of left panel editing */
    leftPanel?: boolean;
    fieldNumber?: string;
}

export function WordArtField({
    item,
    icon,
    selectOnFocus = false,
    inputRef: propsInputRef = null,
    leftPanel,
    fieldNumber = ""
}: WordArtFieldProps) {
    const { t } = useTranslationSSR();
    const [value, setValue] = useState(item.data.content);
    const designer = useDesigner();
    const { setIsTextInputFocused } = useMobileText();
    const { isSmall } = useStudioLayout();
    const { setCurrentActiveDialog } = useActiveDialog();
    const ownRef = useRef<HTMLTextAreaElement>(null);
    const inputRef = propsInputRef || ownRef;
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();

    // Update the value whenever the model updates
    useEffect(() => {
        const updateValue = (model: Model, data: WordArtData) => {
            setValue(data.content);
        };

        // if the selection changes via the left panel the state won't be updated and this component will show the old data
        // so check here to see if something else changed the content
        if (value !== item.data.content) {
            updateValue(item._itemViewModel.model, item.data);
        }
        item._itemViewModel.model.on("change:data", updateValue);
        return () => {
            item._itemViewModel.model.off("change:data", updateValue);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    // Update the model when the value changes

    const onChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // wordart does not support newlines
        const content = event.target.value.replace(/\n/, "");
        if (!designer) {
            return;
        }

        designer.api.design.updateItem(item.id, mutableItem => {
            mutableItem.setData({
                ...item.data,
                content
            });
        });
    };

    // Select the item when the textField is focused
    const onFocus = () => {
        if (!selectOnFocus || !designer || !designer.selectionManager) {
            return;
        }

        !isSmall && designer.selectionManager.select([item._itemViewModel]);
        setIsTextInputFocused(true);
    };

    const onBlur = useCallback(() => {
        item._itemViewModel.unset("over");
        setIsTextInputFocused(false);
    }, [item._itemViewModel, setIsTextInputFocused]);

    const scrollHeight = inputRef?.current?.scrollHeight;
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.style.height = "1px";
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [value, inputRef, scrollHeight]);

    const placeholder =
        item.data.content.length > 0 || !item.templateMetaData
            ? t(defaultDocumentText.textualPlaceholders.id)
            : item.templateMetaData.placeholder;
    const disabled = item._itemViewModel.model.get("locked");

    const onClickHandle = () => {
        if (designer) {
            const { selectionManager } = designer;
            selectionManager.select([item._itemViewModel]);
            focusMobileDock();
            setIsTextInputFocused(true);
            setCurrentActiveDialog(DialogType.TextInput);
        }
    };
    const title = fieldNumber
        ? t(messages.wordArtLabelWithIndex.id, { index: fieldNumber })
        : t(messages.wordArtLabel.id);
    if (isSmall && leftPanel) {
        return (
            <>
                <button
                    className="dscl-rich-text-field studio-rich-text-field"
                    aria-label={title}
                    title={title}
                    onClick={onClickHandle}
                >
                    <div
                        className={classNames("dscl-rich-text-field__editor", {
                            "is-placeholder": !value,
                            "swan-textarea dscl-rich-text-field__editor backside-canvas":
                                hasPremiumFinishesCurrentCanvas
                        })}
                    >
                        {value || placeholder}
                    </div>
                </button>
                {icon}
            </>
        );
    }

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                className="dscl-rich-text-field studio-rich-text-field"
                onClick={() => {
                    inputRef?.current?.focus();
                }}
                data-dcl-prevent-canvas-items-deselection
            >
                <textarea
                    className={classNames("dscl-rich-text-field__editor", {
                        "dscl-rich-text-field__editor backside-canvas": hasPremiumFinishesCurrentCanvas
                    })}
                    data-dcl-prevent-canvas-items-deselection
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChangeValue}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    ref={inputRef}
                    aria-label={title}
                />
                {icon}
            </div>
        </>
    );
}
WordArtField.displayName = "WordArtField";
