import React from "react";

import { DialogType } from "@shared/features/ActiveDialog";
import { AITemplatesProvider } from "./AITemplatesProvider";

import "./aiTemplatesPanel.scss";
import { AITemplatesPanel } from "./AITemplatesPanel";

export interface AITemplatesPanelProps {
    onDone?: () => void;
    fullHeight?: boolean;
}

export function AITemplatesPanelContainer({ onDone, fullHeight = false }: AITemplatesPanelProps) {
    return (
        <AITemplatesProvider type={DialogType.AITemplates}>
            <AITemplatesPanel onDone={onDone} fullHeight={fullHeight} />
        </AITemplatesProvider>
    );
}

AITemplatesPanelContainer.displayName = "AITemplatesPanelContainer";
