import isEqual from "lodash/isEqual";
import { getTransientDocumentUrl } from "@shared/utils/DocumentStorage";
import type { DSS } from "@vp/types-ddif";
import cloneDeep from "lodash/cloneDeep";
import { newRelicWrapper } from "@shared/utils/Errors";
import { getStudioUniqueSessionId } from "@shared/utils/Tracking";
import { convertDocumentSourceType, DocumentSourceType, generateDesignViewsURL, V2 } from "@shared/utils/DSS";
import { getSecondaryConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { findSourcesForNewTemplate } from "@shared/utils/Templates";

import { loopOverItems } from "@shared/utils/CimDoc";
import { findRgbDifference, RGB, hex2rgb, rgb2hex } from "@design-stack-ct/utility-core";
import { getMatchingDesigns } from "./clients/designTransformationCatalogClient";
import { realizeToken } from "./clients/designTransformationRealizationClient";
import { cleanseCimDocForTransformation, transferTemplates, transferUserAddedItems } from "./utilities";
import { getResizedDocumentbyDesignViews, getTransferedDocument } from "./clients/customizationTransferClient";
import { extractEditedTextPlaceholders, hydratePreviouslyEditedTextPlaceholders } from "./contentPreservationUtilities";
import { type ProductSpecificationsResponse } from "./clients/targetSpecificationClient";

export function cleanBlankPanels(designDocument: DSS.DesignDocument) {
    designDocument.document.panels.forEach(panel => {
        if (panel?.colorMode === "blank") {
            // eslint-disable-next-line no-param-reassign
            delete panel.textAreas;
            // eslint-disable-next-line no-param-reassign
            delete panel.itemReferences;
            // eslint-disable-next-line no-param-reassign
            delete panel.images;
            // eslint-disable-next-line no-param-reassign
            delete panel.shapes;
        }
    });
}

function updatePanelIdsToMatchSurface(targetDocument: DSS.DesignDocument, targetSpecifications: any) {
    targetDocument.document.panels.forEach((panel: any) => {
        const tssPanel = targetSpecifications.data.targetSpecifications.panels.find(
            (tp: any) => tp.name === panel.name
        );
        const panelSource = targetDocument.metadata?.documentSources?.panels.find(
            (ds: DSS.DocumentPanelSource) => ds.id === panel.id
        );
        if (panelSource) {
            panelSource.id = tssPanel.id;
        }
        // eslint-disable-next-line no-param-reassign
        panel.id = tssPanel.id;
    });
}

type CopyDesignProps = {
    designDocument: DSS.DesignDocument;
    locale: string;
    authToken: string;
    matcher?: string;
    targetSpecifications: ProductSpecificationsResponse;
};

export async function getTransformedDocumentForCopyDesign({
    designDocument,
    locale,
    authToken,
    matcher = "Template Matcher",
    targetSpecifications
}: CopyDesignProps) {
    let targetDocument = null;
    let templateSource = null;

    const { projectionId } = targetSpecifications.data.targetSpecifications;
    // Fetches existing document

    // Generates document URL from scaled document
    const transientDocumentUrl = await getTransientDocumentUrl(designDocument, authToken);
    // Fetches best matches from DTC
    const availableMatches = await getMatchingDesigns(locale, transientDocumentUrl, targetSpecifications);

    // Filters for highest quality (in case we want to enhance copy logic)
    // const highestQuality = dtcResponse.sort((prev: any, current: any) =>
    //     prev.quality > current.quality ? -1 : 1
    // )[0];

    // Pulls source matcher from best matches
    const sourceMatcher = availableMatches
        .filter((match: any) => match.metadata.matcherId.includes(matcher))
        ?.sort((a: any, b: any) => b.quality - a.quality)
        .find(() => true);

    if (!sourceMatcher) {
        return { targetDocument, templateSource };
    }

    // Realizes response token to a new document
    targetDocument = await realizeToken(sourceMatcher.designTransformationTokenBase64);

    // Work around fix for projections incorrectly being carried over
    //  The consistent recreate case was going from a product with a projection to one without
    if (!projectionId) {
        // delete can be run against non-existant properties, so don't need to check existance
        delete targetDocument.projectionId;
    } else {
        // May not be needed, but just making doubly sure the right projection is applied
        targetDocument.projectionId = projectionId;
    }

    templateSource = targetDocument.metadata.documentSources.panels.find(
        (docSource: any) => docSource.source === "TEMPLATE_TOKEN"
    );

    // If a template doc, see if a equivelent target template can be put in as a source for Front
    //  This makes the offer of a backside include a template that is sized correctly
    if (templateSource) {
        // Sort by quality and see if there is a template match
        const sortedMatches = availableMatches.sort((a: any, b: any) => a.quality - b.quality);
        // If there is a match, it will be the highest quality
        const templateMatcher = sortedMatches.find((match: any) =>
            match.metadata.matcherId.includes("Template Matcher")
        );
        if (templateMatcher) {
            const templateDoc = await realizeToken(templateMatcher.designTransformationTokenBase64);
            transferTemplates(targetDocument, templateDoc);
        }
    }

    // DTR 'resets' the panel ids to start from 0 so I need to update them and update the related document source
    updatePanelIdsToMatchSurface(targetDocument, targetSpecifications);

    // This is a bit hacky. This prevents the backside/ additional side from being blanked out
    //  in the new document when you switch to the that side in studio
    if (targetDocument.document.panels.length > 1) {
        const upsells: any[] = [];
        targetDocument.document.panels.forEach((panel: any, index: number) => {
            if (index > 0) {
                upsells.push({ upsellOffered: true, panelName: panel.name });
            }
        });
        targetDocument.metadata.surfaceUpsells = upsells;
    }

    if (designDocument && matcher === "Template Matcher") {
        templateSource = sourceMatcher.tokenId;
        targetDocument.document.panels = transferUserAddedItems(targetDocument, designDocument);
    }
    return { targetDocument, templateSource };
}

/* eslint-disable no-param-reassign */
const removeNullImageOverlays = (image: any) => {
    if (!image.overlays) {
        return;
    }
    const newOverlays = image.overlays.filter((overlay: any) => {
        return overlay;
    });
    if (newOverlays.length) {
        image.overlays = newOverlays;
    } else {
        delete image.overlays;
    }
};
/* eslint-enable no-param-reassign */

export const cleanTransferredDocument = (sourceDocument: any) => {
    const targetDocument = cloneDeep(sourceDocument);
    const { images } = targetDocument.document.panels[0];
    loopOverItems(targetDocument, images, "", removeNullImageOverlays);
    return targetDocument;
};

type TransformedProps = {
    originalDocument: DSS.DesignDocument;
    productKey: string;
    productVersion: number;
    selectedOptions: any;
    authToken: string;
    documentSources: any;
    sourceDocument: any;
    locale: string;
    isColorMatchingBacksideEnabled: boolean;
};

export async function getTransformedDocument({
    originalDocument,
    productKey,
    productVersion,
    selectedOptions,
    authToken,
    documentSources,
    sourceDocument,
    locale,
    isColorMatchingBacksideEnabled
}: TransformedProps) {
    try {
        const { targetDocument } = await getTargetDocument({
            productKey,
            productVersion,
            selectedOptions,
            authToken,
            documentSources,
            locale
        });
        const resultDoc = await getTransferedDocument(sourceDocument, targetDocument);

        return {
            targetDocument: cleanTransferredDocument(resultDoc),
            templateSource: resultDoc.metadata?.documentSources?.panels[0]?.data
        };
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
        const selectedProductTemplateId = getOriginalPrimaryTemplate({ designDocument: originalDocument });
        newRelicWrapper.noticeError(new Error("Expected Size failed to transform"), {
            targetProductKey: productKey,
            targetDocumentSources: documentSources,
            targetTemplateId: selectedProductTemplateId,
            targetselectedOptions: selectedOptions
        });
        return { targetDocument: null, templateSource: null };
    }
}

type ChangeTemplateProps = {
    locale: string;
    productKey: string;
    productVersion: number;
    selections: any;
    authToken: string;
    template: string;
    sourceDocument: DSS.DesignDocument;
    isColorMatchingBacksideEnabled: boolean;
};

export async function getTransformedDocumentForChangeTemplate({
    locale,
    productKey,
    productVersion,
    selections,
    template,
    authToken,
    sourceDocument,
    isColorMatchingBacksideEnabled
}: ChangeTemplateProps) {
    // We only need the calcifer response if we have templates on panels other than the front
    const checkForOtherTemplates = sourceDocument.metadata?.documentSources?.panels
        .filter(panelSource => panelSource.id !== sourceDocument.document.panels[0].id)
        .some(panelSource => panelSource.source === convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN));
    const [calciferResponse, sourceDocumentURL] = await Promise.all([
        checkForOtherTemplates
            ? getSecondaryConfigFromCalciferV2(
                  productKey,
                  productVersion,
                  selections,
                  locale,
                  false,
                  template,
                  isColorMatchingBacksideEnabled
              )
            : undefined,
        getTransientDocumentUrl(sourceDocument, authToken)
    ]);

    // remove any previous matching metadata
    cleanseCimDocForTransformation(sourceDocument);

    // extract text from placeholders so we don't save it while switching between multiple templates
    extractEditedTextPlaceholders(sourceDocument);

    // Figure out if we need to update the backside templates
    const documentSources = findSourcesForNewTemplate(sourceDocument, template, calciferResponse);

    // The "duplicate" source screws everything up.  We'll treat it as empty
    const duplicateSources = [] as DSS.DocumentPanelSource[];
    const cleansedDocumentSources = documentSources.map(panelSource => {
        if (panelSource.source === convertDocumentSourceType(DocumentSourceType.DUPLICATE)) {
            duplicateSources.push(panelSource);
            return {
                id: panelSource.id,
                data: "",
                source: convertDocumentSourceType(DocumentSourceType.EMPTY)
            };
        }
        return panelSource;
    });

    const { targetDocument } = await getTransformedDocument({
        originalDocument: sourceDocument,
        productKey,
        selectedOptions: selections,
        authToken,
        productVersion,
        documentSources: {
            panels: cleansedDocumentSources
        },
        sourceDocument: sourceDocumentURL,
        locale,
        isColorMatchingBacksideEnabled
    });

    if (!targetDocument) {
        throw new Error("No target document received");
    }

    // reapply the duplicate sources
    duplicateSources.forEach(dupSource => {
        const newSource = targetDocument.metadata?.documentSources?.panels?.find(
            (panel: any) => panel.id === dupSource.id
        );
        if (newSource) {
            newSource.source = convertDocumentSourceType(DocumentSourceType.DUPLICATE);
        }
    });

    // if we had a edited text placeholder in a previous template we can fill it in
    hydratePreviouslyEditedTextPlaceholders(targetDocument);

    return targetDocument;
}

type SourceDocumentProps = {
    designDocument: any;
    designVariations: Array<any>;
    selectedOptions: any;
    authToken: string;
    locale: string;
    currentTemplateColor: string;
};

function searchForSimilarColor(targetColor: RGB, colors: RGB[]) {
    let bestMatch: { color: RGB; colorDifference: number } | undefined;

    colors.forEach(color => {
        const colorDifference = findRgbDifference(targetColor, color);

        if (!bestMatch || bestMatch.colorDifference > colorDifference) {
            bestMatch = { colorDifference, color };
        }
    });

    return bestMatch?.color;
}

export const findClosestDesign = (currentColor: string, colorSwatches: any[]) => {
    if (!colorSwatches.length) {
        return null;
    }

    const currentColorSwatchRgb = hex2rgb(currentColor);
    const pallette = colorSwatches.map(color => hex2rgb(color.color));
    const closestColor = rgb2hex(searchForSimilarColor(currentColorSwatchRgb, pallette)!);
    return colorSwatches.find(color => color.color === closestColor);
};

export const findClosestDesignForVariations = (
    designVariations: any,
    selectedOptions: any,
    currentTemplateColor: any
) => {
    const matchingTargetDesign = designVariations?.find((variation: { fullProductOptions: object }) =>
        isEqual(variation.fullProductOptions, selectedOptions)
    );

    if (!matchingTargetDesign) {
        return undefined;
    }

    if (currentTemplateColor) {
        const closestColorSwatch = findClosestDesign(currentTemplateColor, designVariations[0].colorSwatches);
        if (closestColorSwatch) {
            return closestColorSwatch.previewInfo.templateToken;
        }
    }
    return matchingTargetDesign.templateToken;
};

export async function getSourceDocument({
    locale,
    designDocument,
    designVariations,
    selectedOptions,
    authToken,
    currentTemplateColor
}: SourceDocumentProps) {
    const newSources = designDocument.metadata.documentSources;
    const sourceDocument = await getTransientDocumentUrl(designDocument, authToken);
    return { sourceDocument, documentSources: newSources };
}

type TargetDocumentProps = {
    productKey: string;
    productVersion: string | number;
    selectedOptions: any;
    documentSources: any;
    authToken: string;
    locale: string;
};

export async function getTargetDocument({
    productKey,
    productVersion,
    selectedOptions,
    documentSources,
    authToken,
    locale
}: TargetDocumentProps) {
    let designDoc;
    const blankDocument = await V2.getDesignDocFromTemplateToken(
        productKey,
        productVersion,
        selectedOptions,
        "",
        locale
    );

    const duplicateDocumentSourcePanel = documentSources.panels.filter(
        (panelSource: DSS.DocumentPanelSource) =>
            panelSource.source === convertDocumentSourceType(DocumentSourceType.DUPLICATE)
    );

    if (duplicateDocumentSourcePanel.length > 0) {
        const nonDuplicatePanels = documentSources.panels.filter(
            (panelSource: DSS.DocumentPanelSource) =>
                panelSource.source !== convertDocumentSourceType(DocumentSourceType.DUPLICATE)
        );
        const designDocFront = await V2.getDesignDocumentWithSources(
            productKey,
            productVersion,
            selectedOptions,
            blankDocument,
            { ...documentSources, panels: nonDuplicatePanels },
            undefined,
            [],
            getStudioUniqueSessionId(),
            undefined,
            locale
        );

        designDoc = await V2.getDesignDocumentWithSources(
            productKey,
            productVersion,
            selectedOptions,
            designDocFront,
            { ...documentSources, panels: duplicateDocumentSourcePanel },
            undefined,
            [],
            getStudioUniqueSessionId(),
            undefined,
            locale
        );
    } else {
        designDoc = await V2.getDesignDocumentWithSources(
            productKey,
            productVersion,
            selectedOptions,
            blankDocument,
            documentSources,
            undefined,
            [],
            getStudioUniqueSessionId(),
            undefined,
            locale
        );
    }

    const targetDocument = await getTransientDocumentUrl(designDoc, authToken);

    return { designDoc, targetDocument };
}

type ResizeDocumentProps = {
    productKey: string;
    productVersion: number;
    selectedOptions: any;
    designDocument: any;
    authToken: string;
    locale: string;
    cimDocUrl?: string;
};

export async function getResizedDocument({
    designDocument,
    productKey,
    productVersion,
    selectedOptions,
    authToken,
    locale,
    cimDocUrl
}: ResizeDocumentProps) {
    const targetDoc = cimDocUrl ?? (await getTransientDocumentUrl(designDocument, authToken));
    const designViewsURL = generateDesignViewsURL(productKey, productVersion, selectedOptions, locale);
    const resizedDocumentSources = await getResizedDocumentbyDesignViews(targetDoc, designViewsURL);
    return resizedDocumentSources;
}

function isFullBleedProductUntouched(designDocument: any) {
    return designDocument?.metadata?.template?.some(({ id, placeholder, placeholderReplaced, placeholderUrl }: any) => {
        if (id === "Front" || id === "Back") {
            return false;
        }

        if (placeholder === true && placeholderUrl && placeholderReplaced !== true) {
            return false;
        }

        if (placeholder === true && placeholderUrl && placeholderReplaced === true) {
            return true;
        }

        if (placeholder !== true) {
            return true;
        }

        return false;
    });
}

type TransformedDocumentFullBleedProps = {
    productKey: string;
    productVersion: number;
    selectedOptions: Record<string, any>;
    designDocument: any;
    authToken: string;
    locale: string;
};

async function getTransformedDocumentForFullBleed({
    designDocument,
    productKey,
    productVersion,
    selectedOptions,
    authToken,
    locale
}: TransformedDocumentFullBleedProps) {
    let targetDocument;
    const isFullbleedChanged = isFullBleedProductUntouched(designDocument);
    if (!isFullbleedChanged) {
        const { documentSources } = designDocument.metadata;
        const { designDoc } = await getTargetDocument({
            productKey,
            productVersion,
            selectedOptions,
            documentSources,
            authToken,
            locale
        });
        targetDocument = designDoc;
    } else {
        targetDocument = await getResizedDocument({
            designDocument,
            authToken,
            productKey,
            productVersion,
            selectedOptions,
            locale
        });
    }
    return { targetDocument, templateSource: null };
}

type ResizeTransformedDocumentProps = {
    productKey: string;
    productVersion: number;
    selectedOptions: Record<string, any>;
    originalDocument: any;
    authToken: string;
    locale: string;
    designVariations: Array<any>;
    currentTemplateColor: string;
    isColorMatchingBacksideEnabled: boolean;
};

export async function getResizedTransformedDocument({
    originalDocument,
    productKey,
    productVersion,
    selectedOptions,
    authToken,
    locale,
    designVariations,
    currentTemplateColor,
    isColorMatchingBacksideEnabled
}: ResizeTransformedDocumentProps) {
    if (!designVariations?.length) {
        const transformedDocument = await getTransformedDocumentForFullBleed({
            designDocument: originalDocument,
            productKey,
            productVersion,
            selectedOptions,
            authToken,
            locale
        });
        return transformedDocument;
    }

    const resizedDocumentSources = await getResizedDocument({
        designDocument: originalDocument,
        authToken,
        productKey,
        productVersion,
        selectedOptions,
        locale
    });

    const { sourceDocument, documentSources } = await getSourceDocument({
        locale,
        designDocument: resizedDocumentSources,
        designVariations,
        selectedOptions,
        authToken,
        currentTemplateColor
    });

    const document = await getTransformedDocument({
        originalDocument,
        productKey,
        productVersion,
        selectedOptions,
        authToken,
        documentSources,
        sourceDocument,
        locale,
        isColorMatchingBacksideEnabled
    });

    return document;
}

function getOriginalPrimaryTemplate({ designDocument }: { designDocument: any }) {
    const primaryDocumentSource = designDocument?.metadata?.documentSources?.panels?.find(
        (source: { id: string }) => source.id === designDocument?.document?.panels[0].id
    );
    return primaryDocumentSource?.data;
}
