import React, { ReactNode } from "react";
import { type DefaultTFunction, defineMessages } from "@vp/i18n-helper";
import { ErrorIcon } from "../StudioChrome";

export const validationErrorMessage = defineMessages({
    header: {
        id: "easel.ui.validationErrorMessages.header",
        defaultMessage: "Needs Attention"
    },
    body: {
        id: "easel.ui.validationErrorMessage.body",
        defaultMessage: "We've identified design issues - you'll need to fix them before you continue.",
        description: {
            note: "A paragraph displayed in a modal asking users to review issues related to validation errors"
        }
    },
    cta: {
        id: "easel.ui.validationErrorMessages.cta",
        defaultMessage: "Review Issues"
    }
});

export type Errors = {
    header?: string | ReactNode;
    body?: string | ReactNode;
    cta?: string;
    ctaMobile?: string;
};

export const buildModalValidationErrorMessage = (translateSSR: DefaultTFunction): Errors => ({
    header: (
        <>
            {translateSSR(validationErrorMessage.header.id)} <ErrorIcon className="error_icon" />
        </>
    ),
    body: translateSSR(validationErrorMessage.body.id),
    cta: translateSSR(validationErrorMessage.cta.id)
});
