import { useEffect } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector, useAppDispatch } from "@shared/redux";
import { usePreviewsContext } from "@shared/features/Previews";
import { prefetchMatchingPreviews } from "./prefetchMatchingTemplates";

export function useFetchMatchingTemplates() {
    const dispatch = useAppDispatch();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const secondaryConfig = useAppSelector(state => state.secondaryConfig);
    const template = useAppSelector(state => state.template);

    const { purcsTransientUrls } = usePreviewsContext();
    const { auth } = useIdentityContext();

    useEffect(() => {
        if (secondaryConfig?.templates && easelLoaded && purcsTransientUrls) {
            dispatch(
                prefetchMatchingPreviews(
                    secondaryConfig.templates,
                    secondaryConfig.surfaceUpsells,
                    auth.getToken(),
                    purcsTransientUrls
                )
            );
        }
    }, [purcsTransientUrls, secondaryConfig, auth, dispatch, easelLoaded, template]);
}
