import React, { useEffect } from "react";
import { HorizontalScroller } from "@shared/features/StudioChrome";
import { useAppSelector } from "@shared/redux";
import classNames from "classnames";
import "./mobilesidebar.scss";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { MobileSidebarButton } from "./MobileSidebarButton";
import { useMobileSidebarOptions, Option } from "../../hooks/useMobileSidebarOptions";

const showSidebar = (currentActiveDialog: DialogType) => {
    switch (currentActiveDialog) {
        case DialogType.Text:
        case DialogType.Images:
        case DialogType.Graphics:
        case DialogType.Tables:
        case DialogType.QRCodes:
        case DialogType.Color:
        case DialogType.Guides:
        case DialogType.File:
        case DialogType.None:
        case DialogType.GroupsAndTeams:
        case DialogType.BackgroundColorPicker:
        case DialogType.AITemplates:
        case DialogType.IdeaPanel:
            return true;
        default:
            return false;
    }
};

export const MobileSidebar = () => {
    const { sideBarOptions = [], defaultDialogType } = useMobileSidebarOptions();
    const { currentActiveDialog, addButtonOffset: bottom, setCurrentActiveDialog } = useActiveDialog();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const hideSidebar = !showSidebar(currentActiveDialog);

    let style = {};
    if (hideSidebar) {
        style = { "--addButtonBottom": `${bottom}px` };
    }

    useEffect(() => {
        if (defaultDialogType) {
            setCurrentActiveDialog(defaultDialogType);
        }
    }, [defaultDialogType, setCurrentActiveDialog]);

    return (
        <div
            className={classNames("mobile-sidebar", {
                "mobile-sidebar-shown": easelLoaded,
                "mobile-sidebar-hidden": hideSidebar
            })}
            style={style}
        >
            <HorizontalScroller>
                <div className="mobile-sidebar-buttons" data-testid="mobileToolbar">
                    {sideBarOptions.map((option: Option, index) => {
                        return (
                            <MobileSidebarButton key={index} {...option}>
                                <option.icon aria-hidden="true" />
                                <label>{option.label}</label>
                            </MobileSidebarButton>
                        );
                    })}
                </div>
            </HorizontalScroller>
        </div>
    );
};

MobileSidebar.displayName = "MobileSidebar";
