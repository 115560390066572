import React from "react";
import classnames from "classnames";
import { ToggleSwitch, ToggleSwitchOnLabel, ToggleSwitchOffLabel } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FinishMessages } from "../messages";
import * as styles from "./PremiumFinishToggleSwitch.module.scss";

type Props = {
    onRequestActivatedChange: () => void;
    activated: boolean;
    disabled?: boolean;
};

export function PremiumFinishToggleSwitch({ onRequestActivatedChange, activated, disabled }: Props) {
    const { t } = useTranslationSSR();

    return (
        <ToggleSwitch
            className={classnames(styles.toggleSwitch, { [styles.disabled]: disabled })}
            hideText
            activated={activated}
            // Can't use the disabled attribute here because it would break the tooltip that shows when hovering over the disabled switch
            aria-disabled={disabled}
            onRequestActivatedChange={!disabled ? onRequestActivatedChange : undefined}
        >
            <ToggleSwitchOnLabel>{t(FinishMessages.toggleOn.id)}</ToggleSwitchOnLabel>
            <ToggleSwitchOffLabel>{t(FinishMessages.toggleOff.id)}</ToggleSwitchOffLabel>
        </ToggleSwitch>
    );
}

PremiumFinishToggleSwitch.displayName = "PremiumFinishToggleSwitch";
