import React, { PropsWithChildren, useContext, useState } from "react";

export enum SubselectionType {
    Row = "row",
    Column = "column",
    Cell = "cell",
    None = "none"
}

interface Selection {
    rowIndex: number;
    columnIndex: number;
    type: SubselectionType;
    ref: any;
}

interface TableSubselection {
    selection: Selection;
    updateSelection: (updatedSelection: Selection) => void;
}

export const initialSelection: Selection = {
    type: SubselectionType.None,
    rowIndex: -1,
    columnIndex: -1,
    ref: {}
};

const TableSubselectionContext = React.createContext<TableSubselection>({
    selection: initialSelection,
    updateSelection: () => {}
});

export function useTableSubselection(): TableSubselection {
    return useContext(TableSubselectionContext);
}

export function TableSubselectionProvider({ children }: PropsWithChildren<{}>) {
    const [selection, setSelection] = useState(initialSelection);

    const { Provider } = TableSubselectionContext;

    return (
        <Provider
            value={{
                selection,
                updateSelection: setSelection
            }}
        >
            {children}
        </Provider>
    );
}

TableSubselectionProvider.displayName = "TableSubselectionProvider";
