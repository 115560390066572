/* eslint-disable max-len */
import React from "react";

export function ScanQRCodeIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 73" fill="none" {...props}>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M21.079 64.174v0a.74.74 0 0 1 .728.75.74.74 0 0 1-.728.75v0a.74.74 0 0 1-.728-.75v0a.74.74 0 0 1 .728-.75"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M7.98 70.922c-3.216 0-5.823-2.686-5.823-6v-57c0-3.314 2.607-6 5.822-6h26.199c3.215 0 5.822 2.686 5.822 6v57c0 3.314-2.607 6-5.822 6H7.979Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M40 58.922H2.157M40 13.922H2.157"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M16.712 7.923h8.733"
            />
            <rect width={19.822} height={19.8} x={11.168} y={25.321} stroke="#000" strokeWidth={1.5} rx={1} />
            <path
                stroke="#000"
                strokeLinejoin="round"
                d="M34.954 23.52v-5.4h-5.136M12.7 18.12H7.564v5.4M7.563 48.72v5.4H12.7M29.819 54.12h5.135v-5.4"
            />
            <path
                fill="#000"
                d="M12.97 27.12h3.604v3.6H12.97zM25.584 27.12h3.604v3.6h-3.604zM12.97 39.719h3.604v3.6H12.97zM18.376 34.321h1.802v1.8h-1.802zM16.573 36.119h1.802v1.8h-1.802z"
            />
            <path
                fill="#000"
                d="M20.177 32.522h1.802v1.8h-1.802zM21.98 30.719h1.802v1.8H21.98zM12.97 34.321h1.802v1.8H12.97z"
            />
            <path
                fill="#000"
                d="M14.771 32.522h1.802v1.8h-1.802zM20.177 37.92h1.802v1.8h-1.802zM18.376 28.921h1.802v1.8h-1.802zM21.98 34.321h1.802v1.8H21.98zM27.386 32.522h-1.802v1.8h1.802zM29.188 34.32h-1.802v1.8h1.802zM27.386 37.92h-1.802v1.8h1.802zM25.584 39.719h-1.802v1.8h1.802zM20.177 39.719h-1.802v1.8h1.802zM20.177 41.52h-1.802v1.8h1.802zM16.573 36.12h-1.802v1.8h1.802zM21.98 27.12h-1.802v1.8h1.802zM23.782 28.92H21.98v1.8h1.802zM29.188 41.52h-1.802v1.8h1.802zM23.782 41.52H21.98v1.8h1.802zM25.584 36.12h-1.802v1.8h1.802zM25.584 34.32h-1.802v1.8h1.802z"
            />
        </svg>
    );
}
ScanQRCodeIcon.displayName = "ScanQRCodeIcon";
