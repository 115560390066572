import { useActiveCanvas, useSelection } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useEffect, useState } from "react";
import { windowExists } from "@shared/utils/WebBrowser";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { useToolFlags } from "@shared/features/StudioConfiguration";
import { useProcessType } from "@utilities";

const getCurrentCanvasElement = () => {
    if (!windowExists()) {
        return undefined;
    }
    const visibleCanvases = Array.from(
        document.getElementsByClassName("dcl-targets dcl-canvas__element-wrapper")
    ).filter(element => element.clientWidth > 0);
    return visibleCanvases.length > 0 && (visibleCanvases[0] as HTMLElement);
};

const getBackgroundColorPanelElement = () => {
    if (!windowExists()) {
        return undefined;
    }
    const colorPanel = document.getElementsByClassName("panel-backgroundColor");
    return colorPanel.length > 0 && (colorPanel[0] as HTMLElement);
};

export function useBackgroundColorSelection() {
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const isSmartValidationsPanelOpen = useAppSelector(state => state.showValidations.showPanel);
    const { setCurrentActiveDialog, currentActiveDialog } = useActiveDialog();
    const [canvasSelected, setCanvasSelected] = useState(false);
    const activeCanvas = useActiveCanvas();
    const selection = useSelection();
    const { isSmall } = useStudioLayout();
    const processType = useProcessType();

    const { shouldShowBackgroundColor: backgroundSelectionEnabled } = useToolFlags(processType);

    // handles the blue 'selected' border for the canvas
    useEffect(() => {
        const currentCanvas = getCurrentCanvasElement();
        if (currentCanvas && backgroundSelectionEnabled) {
            if (canvasSelected) {
                currentCanvas.style.border = "2px solid #1EAAEB";
            } else {
                currentCanvas.style.border = "";
            }
        }
    }, [canvasSelected, backgroundSelectionEnabled, activeCanvas]);

    // handles clicks on the canvas to 'select' it
    useEffect(() => {
        const currentCanvas = getCurrentCanvasElement();
        // Disable for mobile until we can figure out how to prevent clicking on canvas with item selected
        // from opening panel on mobile
        if (!easelLoaded || !currentCanvas || isSmall || selection.length > 0 || !backgroundSelectionEnabled) {
            return () => {};
        }

        const colorPanel = getBackgroundColorPanelElement();

        const switchToBackgroundColor = () => {
            if (!isSmartValidationsPanelOpen) {
                setCurrentActiveDialog(DialogType.BackgroundColorPicker);
                setCanvasSelected(true);
            }
        };

        const clearMouseUp = () => {
            currentCanvas.removeEventListener("mouseup", switchToBackgroundColor);
        };

        const start = () => {
            currentCanvas.addEventListener("mouseup", switchToBackgroundColor);
            // abandon if we're dragging.  We might select something.
            currentCanvas.addEventListener("mousemove", clearMouseUp);

            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CANVAS_BACKGROUND_COLOR,
                label: `Click canvas to open background color picker`
            });
        };

        // this doesn't fire if we clicked on an item, only the blank canvas
        currentCanvas.addEventListener("mousedown", start);
        if (colorPanel) {
            // as soon as the user clicks on the background color panel select the canvas
            // otherwise they could be dragging a slider and the canvas is not "selected" yet
            colorPanel.addEventListener("mousedown", switchToBackgroundColor);
        }
        return () => {
            currentCanvas.removeEventListener("mousedown", start);
            currentCanvas.removeEventListener("mouseup", switchToBackgroundColor);
            currentCanvas.removeEventListener("mousemove", clearMouseUp);
            if (colorPanel) {
                colorPanel.removeEventListener("mousedown", switchToBackgroundColor);
            }
        };
    }, [
        easelLoaded,
        selection.length,
        setCurrentActiveDialog,
        activeCanvas,
        backgroundSelectionEnabled,
        currentActiveDialog,
        isSmall,
        isSmartValidationsPanelOpen
    ]);

    // canvas deselection hook #1
    // we deselect if
    // - a different panel is opened
    // - an item on the canvas is selected
    useEffect(() => {
        if (canvasSelected && backgroundSelectionEnabled) {
            if (selection.length > 0 || currentActiveDialog !== DialogType.BackgroundColorPicker) {
                setCanvasSelected(false);
            }
        }
    }, [canvasSelected, currentActiveDialog, selection.length, backgroundSelectionEnabled]);

    // canvas deselection hook #2
    // - the user clicks outside of the canvas or the background color panel
    useEffect(() => {
        const currentCanvas = getCurrentCanvasElement();
        if (currentCanvas && canvasSelected && backgroundSelectionEnabled) {
            const colorPanel = getBackgroundColorPanelElement();
            const handleClickOutside = (event: Event) => {
                if (
                    !currentCanvas.contains(event.target as Node) &&
                    colorPanel &&
                    !colorPanel.contains(event.target as Node)
                ) {
                    setCanvasSelected(false);
                }
            };

            document.addEventListener("click", handleClickOutside, true);
            return () => document.removeEventListener("click", handleClickOutside, true);
        }
        return () => {};
    }, [activeCanvas, canvasSelected, backgroundSelectionEnabled]);
}
