import { LegacyModalDialogContent, type LegacyModalDialogContentProps } from "@vp/swan";
import classNames from "classnames";
import React from "react";
import * as styles from "./StudioModalDialogContent.module.scss";

export const StudioModalDialogContent = (props: LegacyModalDialogContentProps) => {
    const { children, className, ...rest } = props;
    return (
        <LegacyModalDialogContent
            className={classNames(className, styles.panelContent)}
            {...rest}
            data-dcl-prevent-canvas-items-deselection
        >
            {children}
        </LegacyModalDialogContent>
    );
};

StudioModalDialogContent.displayName = "StudioModalDialogContent";
