import React, { Ref } from "react";
import { FlexBox } from "@vp/swan";
import classNames from "classnames";
import * as styles from "./StickyActionBar.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
}
export const StickyActionBar = React.forwardRef(({ children, className, ...rest }: Props, ref: Ref<HTMLDivElement>) => {
    return (
        <FlexBox ref={ref} className={classNames(styles.stickyActionBar, className)} {...rest}>
            {children}
        </FlexBox>
    );
});

StickyActionBar.displayName = "StickyActionBar";
