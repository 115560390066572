import React, { useEffect, useState } from "react";
import { GetSvg, Shape } from "@cimpress-technology/svg-path";
import { useDesigner } from "@designer-suite";
import { SHAPE_SIZE_FACTOR, DEFAULT_SHAPE_COLOR, getFormattedShape } from "@utilities";
import { DraggableShape } from "./DraggableShape";

interface Props {
    metadata: Shape;
    rotationAngle?: number;
    scaleX?: number;
    scaleY?: number;
}

export const DraggableCurve = (props: Props) => {
    const { metadata, rotationAngle = 0, scaleX = 1, scaleY = 1, ...rest } = props;
    const [maxSize, setMaxSize] = useState(0);
    const [shapes, setShapes] = useState<any>([]);
    const designer = useDesigner();

    const svg = GetSvg(
        metadata,
        {
            fill: DEFAULT_SHAPE_COLOR,
            stroke: { width: 0, color: DEFAULT_SHAPE_COLOR }
        },
        "0 0 200 200"
    );

    useEffect(() => {
        if (!designer) {
            return;
        }
        // Calculate the min width and height of the canvas safe area.
        // This will be the size of the shape when dragged and added to the canvas.
        const canvasViewModel = designer.documentRepository.getActiveCanvasViewModel();
        if (canvasViewModel) {
            const { width, height } = canvasViewModel.get("safeArea");
            setMaxSize(Math.min(width, height) * SHAPE_SIZE_FACTOR);
        }
    }, [designer]);

    useEffect(() => {
        const calculateCurves = async () => {
            getFormattedShape(svg, rotationAngle).then(smartShapes => {
                const calculatedCurves = smartShapes.map((scaledShape: any) => {
                    // We need to calculate the width and height of the shape.
                    // This width and height is based on the smaller side of the dimensions of the safeArea of the canvas
                    // times the shapeFactor. (Which is a constant)
                    // We then calculate the ratio of the width and height.
                    // The shape's dimensions based on the greater side of the original shape scaled down to canvas maxSize.
                    // Then the other side is proportional to the original size.

                    const { width: shapeWidthInMM, height: shapeHeightInMM } = scaledShape;
                    const shapeWidth = parseFloat(shapeWidthInMM);
                    const shapeHeight = parseFloat(shapeHeightInMM);

                    // Calculate the aspect ratios of the width and height.
                    // If the aspect ratio is > 1, we want to limit it to 1 as we don't want that side to change
                    const widthRatio = Math.max(shapeHeight / shapeWidth, 1);
                    const heightRatio = Math.max(shapeWidth / shapeHeight, 1);

                    // Calculate the new width and height.  We want to use the aspect ratios to maintain the original proprtions of the shape
                    const width = (maxSize / widthRatio) * scaleX;
                    const height = (maxSize / heightRatio) * scaleY;
                    return {
                        ...scaledShape,
                        isAspectRatioLocked: false,
                        fillColor: `rgb(${DEFAULT_SHAPE_COLOR})`,
                        strokeWidth: "0",
                        lockOverrides: {},
                        width,
                        height,
                        studioMetadata: { shapeMetadata: metadata, defaultShapeMetadata: metadata }
                    };
                });
                setShapes(calculatedCurves);
            });
        };
        calculateCurves();
    }, [maxSize, svg, rotationAngle, metadata, scaleX, scaleY]);
    return (
        <DraggableShape shapes={shapes} metadata={metadata} {...rest}>
            <img
                src={`data:image/svg+xml,${encodeURIComponent(svg)}`}
                alt="arrow"
                style={{ transform: `rotate(${-rotationAngle}deg) scale(${scaleX} , ${scaleY})` }}
            />
        </DraggableShape>
    );
};

DraggableCurve.displayName = "DraggableCurve";
