import React from "react";
import { Typography, ToggleSwitch, FlexBox, ToggleSwitchOnLabel, ToggleSwitchOffLabel } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import classnames from "classnames";
import * as styles from "./ViewToggleRow.module.scss";

const messages = defineMessages({
    on: {
        id: "studio.ui.component.guidesPanelContent.on",
        defaultMessage: "On",
        description: {
            note: "Toggle switched On"
        }
    },
    off: {
        id: "studio.ui.component.guidesPanelContent.off",
        defaultMessage: "Off",
        description: {
            note: "Toggle switched Off"
        }
    }
});

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    labelId: string;
    activated: boolean;
    onRequestActivatedChange?: (activated: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    description: string;
}

export function ViewToggleRow(props: Props) {
    const { label, labelId, activated, onRequestActivatedChange, description, className, ...rest } = props;
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const labelSize = isSmall ? 6 : 5;
    const descriptionSize = isSmall ? 7 : 6;
    const toggleStyle = isSmall ? "mini" : "standard";
    const descriptionId = `${labelId}description`;

    return (
        <>
            <FlexBox
                alignItems="center"
                marginTop={5}
                marginX={0}
                marginBottom={0}
                className={classnames(className, styles.guideToggleRow)}
                {...rest}
            >
                <Typography id={labelId} marginRight="auto" textSize={labelSize}>
                    {label}
                </Typography>
                <ToggleSwitch
                    size={toggleStyle}
                    hideText
                    activated={activated}
                    onRequestActivatedChange={onRequestActivatedChange}
                    aria-labelledby={labelId}
                    aria-describedby={descriptionId}
                >
                    <ToggleSwitchOnLabel>{t(messages.on.id)}</ToggleSwitchOnLabel>
                    <ToggleSwitchOffLabel>{t(messages.off.id)}</ToggleSwitchOffLabel>
                </ToggleSwitch>
            </FlexBox>
            <Typography id={descriptionId} textColor="dark-grey" textSize={descriptionSize}>
                {description}
            </Typography>
        </>
    );
}
ViewToggleRow.displayName = "ViewToggleRow";
