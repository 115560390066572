import React, { FC, useEffect, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { FlexBox } from "@vp/swan";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { usePrevious } from "@design-stack-ct/utility-react";
import { VerticalCanvasCarouselButton } from "./VerticalCanvasCarouselButton";
import * as styles from "./VerticalCanvasCarousel.module.scss";

const messages = defineMessages({
    nextPageButtonLabel: {
        id: "easel.components.designSelectorContainer.nextPageButtonLabel",
        defaultMessage: "Show next design page"
    },
    previousPageButtonLabel: {
        id: "easel.components.designSelectorContainer.previousPageButtonLabel",
        defaultMessage: "Show previous design page"
    }
});

type Props = {
    className: string;
    modelsToShow: number;
    modelsToScroll?: number;
    sideBySideArrows?: boolean;
    activeCanvasName?: string;
};

export function getValidChildren(children: React.ReactNode) {
    return React.Children.toArray(children).filter(child => React.isValidElement(child)) as React.ReactElement[];
}

const getElementChildrenHeight = (element: HTMLElement | undefined | null, startIndex?: number, endIndex?: number) => {
    if (!element) {
        return 0;
    }
    return [].slice
        .call(element.children, startIndex, endIndex)
        .reduce((acc: number, child: HTMLElement) => acc + child.clientHeight, 0);
};

export const VerticalCanvasCarousel: FC<Props> = props => {
    const { activeCanvasName, className, children, modelsToShow, modelsToScroll = 1, sideBySideArrows = false } = props;
    const { t } = useTranslationSSR();
    const designRequirements = useDesignRequirementsContext();

    const [page, setPage] = useState(0);
    const [paginationWrapperElement, setPaginationWrapperElement] = useState<HTMLDivElement | null>(null);
    const [paginationContainerHeight, setPaginationContainerHeight] = useState(0);
    const [paginationWrapperTranslation, setPaginationWrapperTranslation] = useState(0);

    const handlePreviousPageClick = () => setPage(p => p - 1);
    const handleNextPageClick = () => setPage(p => p + 1);

    const childCount = getValidChildren(children).length;
    const previousActiveCanvasName = usePrevious(activeCanvasName);

    const paginatedModels = Math.min(modelsToShow + page * modelsToScroll, childCount);
    const newModels = Math.max(paginatedModels - modelsToShow, 0);
    const showPreviousPaginationButton = paginatedModels > modelsToShow;
    const showNextPaginationButton = paginatedModels < childCount;

    const paginationContainerStyle = { height: paginationContainerHeight };
    const paginationWrapperStyle = { transform: `translateY(${paginationWrapperTranslation}px)` };

    useEffect(() => {
        if (activeCanvasName && designRequirements && activeCanvasName !== previousActiveCanvasName) {
            const selectedCanvasIndex = designRequirements.getPanelIndexByName(activeCanvasName);
            if (selectedCanvasIndex < page || selectedCanvasIndex >= page + modelsToShow) {
                setPage(selectedCanvasIndex);
            }
        }
    }, [activeCanvasName, page, modelsToShow, designRequirements, previousActiveCanvasName]);

    useEffect(() => {
        let resizeObserver: ResizeObserver;
        if (paginationWrapperElement) {
            resizeObserver = new ResizeObserver(() => {
                const newPaginationContainerHeight = getElementChildrenHeight(
                    paginationWrapperElement,
                    newModels,
                    paginatedModels
                );
                const lastPaginatedModelsHeight = getElementChildrenHeight(paginationWrapperElement, 0, newModels);
                setPaginationContainerHeight(newPaginationContainerHeight);
                setPaginationWrapperTranslation(lastPaginatedModelsHeight * -1);
            });
            resizeObserver.observe(paginationWrapperElement);
        }

        return () => {
            if (paginationWrapperElement) {
                resizeObserver.unobserve(paginationWrapperElement);
            }
        };
    }, [paginationWrapperElement, newModels, paginatedModels]);

    return (
        <div className={className} role="radiogroup">
            {showPreviousPaginationButton && !sideBySideArrows && (
                <VerticalCanvasCarouselButton
                    className={styles.isPrevious}
                    iconType="caretUp"
                    onClick={handlePreviousPageClick}
                    label={t(messages.previousPageButtonLabel.id)}
                />
            )}

            {showPreviousPaginationButton || showNextPaginationButton ? (
                <div className={styles.paginationContainer} style={paginationContainerStyle}>
                    <div
                        ref={setPaginationWrapperElement}
                        style={paginationWrapperStyle}
                        className={styles.paginationWrapper}
                    >
                        {children}
                    </div>
                </div>
            ) : (
                children
            )}
            {showNextPaginationButton && !sideBySideArrows && (
                <VerticalCanvasCarouselButton
                    className={styles.isNext}
                    iconType="caretDown"
                    onClick={handleNextPageClick}
                    label={t(messages.nextPageButtonLabel.id)}
                />
            )}
            {sideBySideArrows && (showNextPaginationButton || showPreviousPaginationButton) && (
                <FlexBox>
                    <VerticalCanvasCarouselButton
                        className={styles.isLeft}
                        iconType="caretUp"
                        onClick={handlePreviousPageClick}
                        label={t(messages.previousPageButtonLabel.id)}
                        isDisabled={!showPreviousPaginationButton}
                    />
                    <VerticalCanvasCarouselButton
                        className=""
                        iconType="caretDown"
                        onClick={handleNextPageClick}
                        label={t(messages.nextPageButtonLabel.id)}
                        isDisabled={!showNextPaginationButton}
                    />
                </FlexBox>
            )}
        </div>
    );
};

VerticalCanvasCarousel.displayName = "VerticalCarousel";
