import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { StandardSheetHeader, StickyActionBar, useClickOutside } from "@shared/features/StudioChrome";
import { Accordion, Button } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Filter } from "@design-stack-vista/gallery-ui";
import { AttributeFilter, CategoryFilter } from "@shared/utils/Gallery";
import classNames from "classnames";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTemplatePanel } from "./TemplatePanelProvider";
import { TemplatePanelClearFiltersButton } from "./TemplatePanelClearFiltersButton";
import { TemplatePanelColorFilters } from "./TemplatePanelColorFilter";

type Props = {
    buttonRef: React.RefObject<HTMLButtonElement>;
};

const messages = defineMessages({
    applyFilter: {
        id: "studio.components.template.panel.applyFilter",
        defaultMessage: "Apply filters",
        description: {
            note: "Closes filter dialog with current filter applied in the template panel"
        }
    },
    filterSheetTitle: {
        id: "studio.components.template.panel.filterSheet.title",
        defaultMessage: "Filters",
        description: {
            note: "Title for mobile template filter sheet"
        }
    }
});

export const TEMPLATE_SEARCH_FILTER_PORTAL_ID = "floating-template-filter-portal";

export const TemplateSearchFiltersPanel = (props: Props) => {
    const { buttonRef } = props;
    const { t } = useTranslationSSR();
    const { isFilterPanelOpen, setIsFilterPanelOpen, filters, setSelectedFilters, selectedFilters } =
        useTemplatePanel();
    const contentRef = useRef<HTMLDivElement>(null);
    const [expandedCollapsibles, setExpandedCollapsibles] = useState<Record<string, boolean>>({});
    const { isXerox } = useXerox();
    const { isSmall } = useStudioLayout();

    useClickOutside(
        [contentRef, buttonRef],
        async () => {
            if (isFilterPanelOpen) {
                setIsFilterPanelOpen(false);
            }
        },
        [isFilterPanelOpen]
    );

    useEffect(() => {
        if (!filters) {
            return;
        }
        const newExpandedCollapsibles = {};
        filters.forEach(filter => {
            newExpandedCollapsibles[filter.name] = !filter.collapsed;
        });
        setExpandedCollapsibles(newExpandedCollapsibles);
    }, [filters]);

    const onClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_TEMPLATE_FILTERS_APPLY,
            label: "Click template panel filters apply"
        });
        // just closes filters panel (since filter is supposed to be already applied)
        setIsFilterPanelOpen(false);
    };

    const handleElement = document.getElementById(TEMPLATE_SEARCH_FILTER_PORTAL_ID);

    const onExpandChange = (collapsibleId: string, expanded: boolean) => {
        setExpandedCollapsibles(current => ({ ...current, [collapsibleId]: expanded }));
    };

    if (!isFilterPanelOpen || !handleElement || !filters) {
        return null;
    }

    const filtersWithoutColor = filters.filter(f => f.name !== "color");

    return (
        <>
            {ReactDOM.createPortal(
                <div className={classNames({ "floating-template-panel-filter-container": !isXerox || isSmall })}>
                    {!isSmall && <div className="template-panel-arrow-left arrow-left" />}
                    <div className="template-panel-filter-panel" ref={contentRef}>
                        {isSmall && (
                            <StandardSheetHeader
                                sheetTitle={t(messages.filterSheetTitle.id)}
                                onBackClick={() => {
                                    setIsFilterPanelOpen(false);
                                }}
                            />
                        )}
                        <div className="template-panel-filter-panel-content">
                            <TemplatePanelClearFiltersButton />
                            <TemplatePanelColorFilters />
                            <Accordion
                                expandedCollapsibles={expandedCollapsibles}
                                onRequestExpandedChange={onExpandChange}
                            >
                                {filtersWithoutColor.map(f => {
                                    const filterIdentifier =
                                        (f as CategoryFilter).categoryId || (f as AttributeFilter).id;
                                    return (
                                        <Filter
                                            filter={f}
                                            key={f.name}
                                            // @ts-ignore Check with gallery ui package owners
                                            onSelectChange={(selectedValues: any) => {
                                                fireDesignToolTrackingEvent({
                                                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_GALLERY_FILTER,
                                                    label: "Gallery filter selected",
                                                    extraData: () => ({ filterIdentifier: f.title })
                                                });
                                                setSelectedFilters(selectedValues, filterIdentifier);
                                            }}
                                            selected={selectedFilters[filterIdentifier] || null} // set to null to clear filters
                                        />
                                    );
                                })}
                            </Accordion>
                            <StickyActionBar className="template-filter-panel-sticky-bar">
                                <Button onClick={onClick} skin="primary" width="full-width" size="mini">
                                    {t(messages.applyFilter.id)}
                                </Button>
                            </StickyActionBar>
                        </div>
                    </div>
                </div>,
                handleElement
            )}
        </>
    );
};

TemplateSearchFiltersPanel.displayName = "TemplateSearchFiltersPanel";
