import { useEffect } from "react";
import { useDesigner } from "@designer-suite";
import { useAppDispatch, setSaveStatus, setShouldSaveOnChange } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";

export const useUpdateSaveStatus = () => {
    const designer = useDesigner();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!designer) {
            return () => {};
        }

        const handleCommandExecuted = () => {
            dispatch(setShouldSaveOnChange(true));
            dispatch(setSaveStatus(SAVE_STATUS.CAN_BE_SAVED));
        };

        const handleProductLoaded = () => {
            dispatch(setShouldSaveOnChange(false));
            dispatch(setSaveStatus(SAVE_STATUS.CAN_BE_SAVED));
        };

        const commandUnsubscribe = designer.api.events.subscribe(
            designer.api.events.eventTypes.COMMAND_EXECUTED,
            handleCommandExecuted
        );

        designer.eventBus.on(designer.eventBus.events.productLoaded, handleProductLoaded);
        return () => {
            commandUnsubscribe();
            designer.eventBus.off(designer.eventBus.events.productLoaded, handleProductLoaded);
        };
    }, [designer, dispatch]);
};
