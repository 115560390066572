import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { isCmyka, NO_COLOR_VALUE, parseColor } from "@shared/features/ColorPicker";
import { getActiveCanvasIndex, getColorPalette, useActiveCanvas, useDesigner } from "@designer-suite";
import { getCurrentBackgroundColor, getCurrentBackgroundModel } from "@utilities";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { useRecentColorsContext } from "@presentational";
import { CMYK, HSL, HSV, RGB } from "@design-stack-ct/utility-core";
import { ColorPickerPanelWrapper } from "../ColorPicker/ColorPickerPanelWrapper";
import { COLOR_PICKER_PANEL_TYPES } from "../ColorPicker/colorPickerPanelTypes";
import "./colorPanel.scss";

const messages = defineMessages({
    title: {
        id: "studio.components.panels.backgroundcolorpickerpanel.title",
        defaultMessage: "Background color"
    }
});

export function BackgroundColorPanel() {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const activeCanvas = useActiveCanvas();
    const { recentColors } = useRecentColorsContext();
    const [currentColor, setCurrentColor] = useState<string | CMYK | RGB | HSV | HSL | undefined>(undefined);
    const [currentCanvasId, setCurrentCanvasId] = useState(activeCanvas?.id);

    const updateCurrentColor = useCallback(() => {
        if (!activeCanvas) {
            return;
        }
        const currentColorObj = getCurrentBackgroundColor(activeCanvas._canvasViewModel.model);
        setCurrentColor(currentColorObj ? parseColor(currentColorObj)?.value : NO_COLOR_VALUE);
    }, [activeCanvas]);

    useEffect(() => {
        if (!designer || !activeCanvas) {
            return;
        }

        updateCurrentColor();
        setCurrentCanvasId(activeCanvas.id);
    }, [activeCanvas, designer, updateCurrentColor]);

    const updateBackgroundColor = useCallback(
        (newColor: string | RGB | CMYK) => {
            if (!designer || !activeCanvas) {
                return;
            }
            const parsedColor = parseColor(newColor);
            if (parsedColor) {
                const { value: color, colorSpace: format } = parsedColor;
                const currentCanvasIndex = getActiveCanvasIndex(designer);

                // If "No Color" option is selected, we need to remove the background item altogether
                // otherwise Designer will set it to CMYK(0,0,0,0) which is white
                if (isCmyka(color) && color.a === 0) {
                    // Only do this if the current option isn't already "No Color" or there will be no item to remove
                    if (currentColor !== NO_COLOR_VALUE) {
                        const item = getCurrentBackgroundModel(activeCanvas._canvasViewModel.model);
                        if (item) {
                            // Need to get the itemViewModel in order to remove it
                            const itemViewModel = activeCanvas._canvasViewModel.itemViewModels.find(
                                ivm => ivm.model.get("id") === item.id
                            );

                            itemViewModel &&
                                designer.api.design.updateItem(itemViewModel.id, mutableItem => {
                                    mutableItem.remove();
                                });
                        }
                    }
                } else {
                    designer.api.design.update(canvases => {
                        canvases[currentCanvasIndex].changeBackgroundColor({ color, format });
                    });
                }
                setCurrentColor(color);
            }
        },
        [activeCanvas, currentColor, designer]
    );

    if (!designer) {
        return null;
    }

    const paletteColors = getColorPalette(designer);

    const eyeDropperConfig = {
        isCompatibleWithSelection: () => {
            return true;
        },
        listenEvents: "model:change:data",
        buttonSize: "mini" as const,
        onClick: updateBackgroundColor
    };

    return (
        <PanelContent className="studio-color-panel-content" data-dcl-prevent-canvas-items-deselection>
            <DesktopExperience>
                <div className="color-panel__sticky-nav">
                    <PanelTitle>{t(messages.title.id)}</PanelTitle>
                </div>
            </DesktopExperience>
            <div className="studio-background-color-picker">
                <ColorPickerPanelWrapper
                    value={currentColor}
                    recentColors={recentColors}
                    paletteColors={paletteColors}
                    onChange={updateBackgroundColor}
                    allowCustom={true}
                    allowTransparency={true}
                    eyeDropperConfig={eyeDropperConfig}
                    selectionId={currentCanvasId}
                    panelType={COLOR_PICKER_PANEL_TYPES.BACKGROUND_COLOR}
                    updateCurrentColor={updateCurrentColor}
                />
            </div>
        </PanelContent>
    );
}
BackgroundColorPanel.displayName = "BackgroundColorPanel";
