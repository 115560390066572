import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";

const messages = defineMessages({
    savedToday: {
        id: "studio.hooks.useLastSavedText.savedToday",
        defaultMessage: "Saved today at [[datetime]]",
        description: {
            note: "Text that will show the time that a project was last saved today"
        }
    },
    lastSavedTodayForSaveChanges: {
        id: "studio.hooks.useLastSavedText.lastSavedTodayForSaveChanges",
        defaultMessage: "Last saved today at [[datetime]]",
        description: {
            note: "Text that will show the time that a project was last saved today for save changes"
        }
    },
    savedYesterday: {
        id: "studio.hooks.useLastSavedText.savedYesterday",
        defaultMessage: "Saved yesterday at [[datetime]]",
        description: {
            note: "Text that will show the time that a project was last saved yesterday"
        }
    },
    lastSavedYesterdayForSaveChanges: {
        id: "studio.hooks.useLastSavedText.lastSavedYesterdayForSaveChanges",
        defaultMessage: "Last saved yesterday at [[datetime]]",
        description: {
            note: "Text that will show the time that a project was last saved yesterday for save changes"
        }
    },
    savedOn: {
        id: "studio.hooks.useLastSavedText.savedOn",
        defaultMessage: "Saved on [[datetime]]",
        description: {
            note: "Text that will show the date the project was last saved on for all changes are saved button"
        }
    },
    lastSavedOnForSaveChanges: {
        id: "studio.hooks.useLastSavedText.lastSavedOnForSaveChanges",
        defaultMessage: "Last saved on [[datetime]]",
        description: {
            note: "Text that will show the date the project was last saved on for save changes button"
        }
    },
    editedToday: {
        id: "studio.hooks.useLastSavedText.editedToday",
        defaultMessage: "Edited today at [[datetime]]",
        description: {
            note: "Text that will show the time that a project was last edited today"
        }
    },
    editedYesterday: {
        id: "studio.hooks.useLastSavedText.editedYesterday",
        defaultMessage: "Edited yesterday at [[datetime]]",
        description: {
            note: "Text that will show the time that a project was last edited yesterday"
        }
    },
    editedOn: {
        id: "studio.hooks.useLastSavedText.editedOn",
        defaultMessage: "Edited on [[datetime]]",
        description: {
            note: "Text that will show the date the project was last edited"
        }
    }
});

const millisecondsInADay = 86400000;

export function useLastSavedText(lastSaved: string | null, showEditedText?: boolean) {
    const locale = useAppSelector(state => state.locale);
    const { t } = useTranslationSSR();
    const saveStatus = useAppSelector(state => state.saveStatus);

    const lastSavedText = useMemo<string | null>(() => {
        if (!lastSaved) {
            return null;
        }

        // work dates are in UTC but don't include the timezone (for now).  Add that if missing.
        const lastSavedLocal: Date = new Date(
            `${lastSaved[lastSaved.length - 1] === "Z" ? lastSaved : `${lastSaved}Z`}`
        );

        const todayLocal = new Date(Date.now());

        // if today
        if (todayLocal.toDateString() === lastSavedLocal.toDateString()) {
            const accessibleTodaysSavedMessage =
                saveStatus === SAVE_STATUS.CAN_BE_SAVED
                    ? messages.lastSavedTodayForSaveChanges.id
                    : messages.savedToday.id;
            return t(showEditedText ? messages.editedToday.id : accessibleTodaysSavedMessage, {
                datetime: lastSavedLocal.toLocaleTimeString(locale ?? undefined, { hour: "numeric", minute: "numeric" })
            });
        }

        const yesterdayLocal = new Date(Date.now() - millisecondsInADay);

        // if yesterday
        if (yesterdayLocal.toDateString() === lastSavedLocal.toDateString()) {
            const accessibleYesterdaysSavedMessage =
                saveStatus === SAVE_STATUS.CAN_BE_SAVED
                    ? messages.lastSavedYesterdayForSaveChanges.id
                    : messages.savedYesterday.id;
            return t(showEditedText ? messages.editedYesterday.id : accessibleYesterdaysSavedMessage, {
                datetime: lastSavedLocal.toLocaleTimeString(locale ?? undefined, { hour: "numeric", minute: "numeric" })
            });
        }

        const accessibleTextMessage =
            saveStatus === SAVE_STATUS.CAN_BE_SAVED ? messages.lastSavedOnForSaveChanges.id : messages.savedOn.id;

        return t(showEditedText ? messages.editedOn.id : accessibleTextMessage, {
            datetime: lastSavedLocal.toLocaleDateString(locale ?? undefined)
        });
    }, [lastSaved, locale, t, showEditedText, saveStatus]);

    return lastSavedText;
}
