import { useMemo } from "react";
import { getPossibleActiveCanvasId } from "@five/utilities/canvasUtilities";
import { usePremiumFinishErrors } from "./usePremiumFinishErrors";
import { useValidationErrors } from "../../easel/utilities/hooks";

export function useDesignErrors() {
    const premiumFinishErrors = usePremiumFinishErrors();
    const validationErrors = useValidationErrors(getPossibleActiveCanvasId());

    const designErrors = useMemo(() => {
        return [...premiumFinishErrors, ...validationErrors];
    }, [validationErrors, premiumFinishErrors]);
    return designErrors;
}
