import React, { useContext, useState, createContext, useMemo, useCallback, ReactNode, useEffect } from "react";
import { useAppSelector } from "@shared/redux";
import {
    getGalleryName,
    type ColorSwatch,
    type Design,
    getGalleryContent,
    getDesignVariation,
    CategoryFilter,
    AttributeFilter
} from "@shared/utils/Gallery";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import {
    useGallery,
    TemplatePaginationActionTypes,
    ChangeTemplateFilterActionTypes
} from "@shared/features/ChangeTemplate";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { DSS } from "@vp/types-ddif";
import {
    getRecentlyUsedTemplates,
    updateRecentlyUsedTemplatesList,
    RecentlyUsedTemplateData,
    addTemplateToRecent
} from "./TemplatePanelUtils";

export enum TemplatePanelContentTypes {
    Main = "main",
    Color = "color",
    Recent = "recent"
}
export interface ConfirmationModalData {
    selectedDesign: Design;
    selectedTemplateToken: string;
    designDocument: DSS.DesignDocument;
    selectedColorSwatchDesignId: string;
}

type SelectedFitlerValues = Record<string | number, any>;
type Data = {
    stack: TemplatePanelContentTypes[];
    addToStack: (panelContentType: TemplatePanelContentTypes) => void;
    jumpToInStack: (panelContentType: TemplatePanelContentTypes) => void;
    removeFromStack: () => void;
    designs: Design[];
    hasMore: boolean;
    performNextSearch: () => void;
    selectedDesign: Design | undefined;
    setSelectedDesign: React.Dispatch<React.SetStateAction<Design | undefined>>;
    currentTemplateColorSwatch: ColorSwatch | undefined;
    currentDesign: Design | undefined;
    confirmationModalData: ConfirmationModalData | undefined;
    setConfirmationModalData: React.Dispatch<React.SetStateAction<ConfirmationModalData | undefined>>;
    storeRecentlyUsedTemplate: (design: Design, selectedColorSwatchDesignId: string) => void;
    recentlyUsedTemplates: Design[];
    changeTemplateSearchTerm: string | undefined;
    setChangeTemplateSearchTerm: React.Dispatch<React.SetStateAction<string | undefined>>;
    filters: (CategoryFilter | AttributeFilter)[] | undefined;
    isFilterPanelOpen: boolean;
    setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedFilters: SelectedFitlerValues;
    setSelectedFilters: (selectedValues: any, filterIdentifier: any) => void;
    loading: boolean;
    anyFiltersSelected: boolean;
    clearSelectedFilters: () => void;
};

const context = createContext<Data | undefined>(undefined);
const { Provider } = context;

export function useTemplatePanel() {
    const result = useContext(context);
    if (!result) {
        throw Error("No Content found");
    }
    return result;
}

interface TemplatePanelProviderProps {
    children: ReactNode | ReactNode[];
}

const compareElements = (a: Design, b: Design) => a.designId === b.designId;

export const TemplatePanelProvider = ({ children }: TemplatePanelProviderProps) => {
    const [stack, setStack] = useState<TemplatePanelContentTypes[]>([TemplatePanelContentTypes.Main]);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const mpvId = useAppSelector(state => state.mpvId);
    const locale = useAppSelector(state => state.locale);
    const template = useAppSelector(state => state.template);
    const [galleryName, setGalleryName] = useState("");
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const [selectedDesign, setSelectedDesign] = useState<Design | undefined>(undefined);
    const [confirmationModalData, setConfirmationModalData] = useState<ConfirmationModalData | undefined>(undefined);
    const [recentlyUsedTemplates, setRecentlyUsedTemplates] = useState<Design[] | undefined>(undefined);
    const { isXerox } = useXerox();
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const mobileTemplatesEnabled = useIsFlagEnabled("mobileTemplates");
    const isLive = isXerox || mobileTemplatesEnabled;
    const {
        allDesigns: designs,
        changeTemplateSearchTerm,
        dispatchPagination,
        dispatchTemplateFilters,
        filters,
        hasMore,
        loading,
        templateFilters: selectedFilters,
        updateSearchTerm: setChangeTemplateSearchTerm,
        currentTemplateColorSwatch,
        setCurrentTemplateColorSwatch,
        currentDesign,
        setCurrentDesign
    } = useGallery();

    const storeRecentlyUsedTemplate = useCallback(
        (design: Design, selectedColorSwatchDesignId: string) => {
            if (!productVersion) {
                return;
            }
            updateRecentlyUsedTemplatesList(
                {
                    designId: design.designId,
                    selectedColorSwatchDesignId
                },
                productKey,
                productVersion
            );
            setRecentlyUsedTemplates(current => {
                return addTemplateToRecent(current, design, compareElements);
            });
        },
        [productKey, productVersion]
    );

    const addToStack = useCallback(
        (panelContentType: TemplatePanelContentTypes) =>
            setStack(current => {
                return [...current, panelContentType];
            }),
        []
    );

    const jumpToInStack = useCallback((panelContentType: TemplatePanelContentTypes) => {
        setStack(current => {
            const index = current.findIndex(panel => panel === panelContentType);
            return current.slice(0, index + 1);
        });
    }, []);

    const removeFromStack = useCallback(() => {
        setStack(current => {
            return current.slice(0, -1);
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (!locale || !mpvId || !productKey || !isLive) {
                return;
            }
            const galleryName = await getGalleryName(productKey, mpvId, locale);
            setGalleryName(galleryName ?? "");
        })();
    }, [locale, mpvId, productKey, isLive]);

    // If loaded with a template fetch current template data
    useEffect(() => {
        const getCurrentAndRecent = async () => {
            if (
                !locale ||
                !studioSelectedProductOptions ||
                !template ||
                !productKey ||
                !productVersion ||
                !isLive ||
                !mpvId ||
                !galleryName
            ) {
                return;
            }
            const res = await getDesignVariation({
                key: productKey,
                studioSelectedProductOptions,
                variableOptions: [],
                templateToken: template,
                locale,
                includeColorSwatches: true,
                includePreview: true
            });
            const currentDesign = res[0] as Design | undefined;
            if (!currentDesign) {
                return;
            }
            const currentColorSwatch = currentDesign?.colorSwatches.find(
                ({ previewInfo }: ColorSwatch) => previewInfo.templateToken === template
            );
            setCurrentTemplateColorSwatch(currentColorSwatch);
            setCurrentDesign(currentDesign);

            const recentTemplates: RecentlyUsedTemplateData[] = await getRecentlyUsedTemplates(
                productKey,
                productVersion
            );
            if (recentTemplates) {
                const designIds: string[] = recentTemplates.map(({ designId }: RecentlyUsedTemplateData) => designId);
                const { content } = await getGalleryContent({
                    galleryName,
                    locale,
                    mpvId,
                    studioSelectedProductOptions,
                    designIds
                });
                const orderedContent: Design[] = designIds
                    .map(designId => content.find((design: Design) => design.designId === designId))
                    .filter(content => content)
                    .map((content: Required<Design>) => {
                        const recentTemplateData = recentTemplates.find(
                            ({ designId }: RecentlyUsedTemplateData) => designId === content.designId
                        );
                        return {
                            ...content,
                            selectedColorSwatchDesignId:
                                recentTemplateData?.selectedColorSwatchDesignId || content.selectedColorSwatchDesignId
                        };
                    });
                setRecentlyUsedTemplates(orderedContent);
            }
            storeRecentlyUsedTemplate(currentDesign, currentColorSwatch?.designId || currentDesign.designId);
        };
        getCurrentAndRecent();
    }, [
        locale,
        productKey,
        productVersion,
        studioSelectedProductOptions,
        template,
        isLive,
        galleryName,
        mpvId,
        storeRecentlyUsedTemplate,
        setCurrentTemplateColorSwatch,
        setCurrentDesign
    ]);

    const performNextSearch = useCallback(() => {
        if (!hasMore) {
            return;
        }
        dispatchPagination({ type: TemplatePaginationActionTypes.NEXT_PAGE });
    }, [hasMore, dispatchPagination]);

    const filteredRecentTemplates = useMemo(
        () => recentlyUsedTemplates?.filter(design => design.designId !== currentDesign?.designId),
        [currentDesign, recentlyUsedTemplates]
    );

    const anyFiltersSelected = useMemo(() => Object.entries(selectedFilters).length > 0, [selectedFilters]);

    const setSelectedFilters = useCallback(
        (selectedValues: any, filterIdentifier: string | number) => {
            dispatchTemplateFilters({
                type: ChangeTemplateFilterActionTypes.SET_FILTERS,
                payload: { [filterIdentifier]: selectedValues }
            });
            // reset pagination
        },
        [dispatchTemplateFilters]
    );

    const clearSelectedFilters = useCallback(() => {
        dispatchTemplateFilters({
            type: ChangeTemplateFilterActionTypes.CLEAR_FILTERS
        });
    }, [dispatchTemplateFilters]);

    const value = useMemo(() => {
        return {
            stack,
            addToStack,
            jumpToInStack,
            removeFromStack,
            designs,
            performNextSearch,
            hasMore,
            selectedDesign,
            setSelectedDesign,
            currentTemplateColorSwatch,
            currentDesign,
            confirmationModalData,
            setConfirmationModalData,
            storeRecentlyUsedTemplate,
            recentlyUsedTemplates: filteredRecentTemplates || [],
            changeTemplateSearchTerm,
            setChangeTemplateSearchTerm,
            filters,
            isFilterPanelOpen,
            setIsFilterPanelOpen,
            selectedFilters,
            setSelectedFilters,
            loading,
            anyFiltersSelected,
            clearSelectedFilters
        };
    }, [
        stack,
        addToStack,
        jumpToInStack,
        removeFromStack,
        designs,
        performNextSearch,
        hasMore,
        selectedDesign,
        currentTemplateColorSwatch,
        currentDesign,
        confirmationModalData,
        storeRecentlyUsedTemplate,
        filteredRecentTemplates,
        changeTemplateSearchTerm,
        setChangeTemplateSearchTerm,
        filters,
        isFilterPanelOpen,
        selectedFilters,
        setSelectedFilters,
        loading,
        anyFiltersSelected,
        clearSelectedFilters
    ]);

    return <Provider value={value}>{children}</Provider>;
};
TemplatePanelProvider.displayName = "TemplatePanelProvider";
