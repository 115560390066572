import type { Designer } from "src/easel/designer-suite/@types/designer";

// Generally return empty string for color when select includes multiple different colors
export function getSelectionsTableColor(
    designer: Designer,
    tableItems: Item[],
    getTableColor: (table: Item) => string
) {
    if (!designer || !tableItems || tableItems.length === 0) {
        return "";
    }

    const firstItemColor = getTableColor(tableItems[0]);
    const allEqual = tableItems.every(item => getTableColor(item) === firstItemColor);
    return allEqual ? firstItemColor : "";
}
