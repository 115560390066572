import qs from "qs";
import { tryFetch } from "@shared/utils/Network";

const host = TEAMS_PRODUCT_VERIFIER_URL;
const entityCode = 55;

export async function verifyTeamsProduct(
    productId: string,
    productVersion: number | undefined | null,
    culture: string,
    selectedOptions: Record<string, string>
): Promise<boolean> {
    const query = qs.stringify({
        requestor: REQUESTOR,
        productVersion,
        selectedOptions: JSON.stringify(selectedOptions)
    });
    const response = await tryFetch({
        url: `${host}/v0/verifyTeamsProduct/vistaprint/${culture}/${productId}?${query}`,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "teamsProductVerifierClient:verifyTeamsProduct",
        friendlyDescription: "Verify whether the product is a Groups and Teams item",
        entityCode
    });
    return response.isTeamsProduct;
}
