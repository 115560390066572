import React, { useCallback } from "react";
import { UploadStrategies, UploadsPanelRedesign as UploadsPanel } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";

interface ImagePanelProps {
    onDone?: () => void;
}

export const ImageUploadPanel = ({ onDone }: ImagePanelProps) => {
    const { isSmall } = useStudioLayout();
    const showReplaceImageModal = useAppSelector(state => state.showReplaceImageModal);

    const onUploadAdded = useCallback(() => {
        if (isSmall && onDone) {
            onDone();
        }
    }, [isSmall, onDone]);

    return (
        <>
            <UploadsPanel
                className="image-panel-refresh"
                placementStrategy={
                    isSmall ? UploadStrategies.AutofillAndPlace : UploadStrategies.AutofillSinglePlaceholder
                }
                onUploadAdded={onUploadAdded}
                key="ImagePanel"
                loginButton={undefined}
                allowHiding={false}
                isDraggable={!isSmall}
                ignoreUpload={showReplaceImageModal}
                isImageUpload={true}
            />
        </>
    );
};

ImageUploadPanel.displayName = "ImageUploadPanel";
