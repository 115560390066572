import { useAppSelector } from "@shared/redux";
import { isFlagEnabled } from "@shared/utils/Flags";
import { useIsSingleColor } from "../../shared/features/SingleColor";

export function useIsAITemplatesEnabled() {
    const { hasAiTemplatesEnabled } = useAppSelector(state => state.productGroupConfiguration);
    const locale = useAppSelector(state => state.locale);
    const isSingleColor = useIsSingleColor();

    if (isFlagEnabled("aiTemplates")) return true;

    // AI template supports full color design in all locales
    if (hasAiTemplatesEnabled && !isSingleColor) return true;

    // AI template supports single color design only in English locales
    if (hasAiTemplatesEnabled && isSingleColor && locale.toLowerCase().startsWith("en")) return true;

    return false;
}
