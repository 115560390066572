import React, { useContext, createContext, useMemo, FC, SetStateAction, Dispatch, useState } from "react";

type Data = {
    searchTerm: string;
    setSearchTerm: Dispatch<SetStateAction<string>>;
};

const context = createContext<Data | undefined>(undefined);

export function useIconsSearch() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a IconsSearchProvider");
    }
    return result;
}

export const IconsSearchProvider: FC = ({ children }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    const { Provider } = context;

    const contextObject = useMemo(() => ({ searchTerm, setSearchTerm }), [searchTerm, setSearchTerm]);

    return <Provider value={contextObject}>{children}</Provider>;
};
IconsSearchProvider.displayName = "IconsSearchProvider";
