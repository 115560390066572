import React, { useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { isCareAgent } from "@shared/utils/Care";
import { PremiumFinishIcon } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { getPremiumFinishes, useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { FinishMessages } from "@shared/features/PremiumFinish";
import classNames from "classnames";
import { useAppSelector, useAppDispatch, setIsPremiumFinishModalOpen, Payload } from "@shared/redux";
import { useActiveCanvas } from "../../designer/useActiveCanvas";
import "./premiumFinishTextEditorIcon.scss";

const messages = defineMessages({
    enableFoilFinish: {
        id: "easel.ui.enableFoilFinish",
        defaultMessage: "Click to enable [[finishType]]",
        description: {
            note: "Shows when a premium finish is applied or removed, on the PremiumFinishIcon"
        }
    },
    disableFoilFinish: {
        id: "easel.ui.disableFoilFinish",
        defaultMessage: "Click to disable [[finishType]]",
        description: {
            note: "Shows when a premium finish is applied or removed, on the PremiumFinishIcon"
        }
    }
});

interface Props<T> {
    item: T;
    usePremiumFinish: (item: T) => boolean;
}

export function PremiumFinishWordArtAndTextEditorIcon<T>({ item, usePremiumFinish }: Props<T>) {
    const canvas = useActiveCanvas();
    const premiumFinish = usePremiumFinish(item);
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const [message, setMessage] = useState(false);
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();
    const dispatch = useAppDispatch();
    if (!canvas || (isFullBleed && !isCareAgent())) {
        return null;
    }
    function setShowModal(payload: Payload) {
        dispatch(setIsPremiumFinishModalOpen(payload));
    }
    const premiumFinishes = getPremiumFinishes(canvas);
    if (premiumFinishes.length === 0) {
        return null;
    }

    const { color: finishType } = premiumFinishes[0];
    // Values expected to be one of ['Metallic', 'RaisedInk', 'RaisedFoilGold', 'RaisedFoilSilver', 'RaisedFoilGlitterSilver']
    const finishIsOnMessage = t(FinishMessages[`${finishType}IsOn`].id);
    const finishName = t(FinishMessages[finishType].id);

    return (
        <>
            {hasPremiumFinishesCurrentCanvas && (
                <div
                    className={classNames("easel-premium-finish-text-editor-icon-container", {
                        "easel-premium-finish-icon-apply-color": premiumFinish
                    })}
                    data-dcl-prevent-canvas-items-deselection
                >
                    <PremiumFinishIcon
                        className="PremiumFinishIcon"
                        aria-label={finishIsOnMessage}
                        onClick={() => setShowModal({ isPremiumFinishModalOpen: true })}
                        onMouseOver={() => setMessage(true)}
                        onFocus={() => setMessage(true)}
                        onMouseLeave={() => setMessage(false)}
                    />
                    {message && !isSmall && (
                        <div className="premium-finish-apply-status__message_enable_disable">
                            {premiumFinish
                                ? t(messages.disableFoilFinish.id, { finishType: finishName })
                                : t(messages.enableFoilFinish.id, { finishType: finishName })}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
PremiumFinishWordArtAndTextEditorIcon.displayName = "PremiumFinishWordArtAndTextEditorIcon";
