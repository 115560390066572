import { useState, useEffect } from "react";
import { getDocumentItems } from "../util";
import { useDesigner } from "./DesignerProvider";
import { useActiveCanvas } from "./useActiveCanvas";
import type { ItemSelection } from "../@types/designer";

/**
 * Returns an array of document items
 * @param activeCanvasOnly whether to return items for the whole document or the active canvas only
 */
export function useDocumentItems(activeCanvasOnly: boolean) {
    const designer = useDesigner();
    const activeCanvas = useActiveCanvas();
    const [items, setItems] = useState<ItemSelection>([]);

    useEffect(() => {
        if (!designer) return;
        const updateItems = () => {
            const canvases = activeCanvasOnly && activeCanvas ? [activeCanvas] : designer.api.design.canvases;
            setItems(getDocumentItems(canvases));
        };
        updateItems();
        const unsubscribeAdd = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, updateItems);
        const unsubscribeChanged = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_CHANGED,
            updateItems
        );
        const unsubscribeDelete = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_DELETED,
            updateItems
        );

        /* eslint-disable consistent-return */
        return () => {
            unsubscribeAdd();
            unsubscribeChanged();
            unsubscribeDelete();
        };
    }, [designer, activeCanvas, activeCanvasOnly]);

    return items;
}
