import React, { useCallback, useEffect } from "react";
import { getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useDesigner, useSelection } from "@designer-suite";
import once from "lodash/once";
import {
    useFontSizeSelector,
    FontSizeSelectorMobileDock,
    FontSizeSelectorDefault
} from "@shared/features/ContextualToolbar";
import { keyboardShortcutEvents, useKeyboardShortcuts } from "@shared/features/KeyboardShortcuts";
import { FontSizeDropdownStudio5 } from "../ToolbarComponents/FontSize/FontSizeDropdownStudio5";
import { FontSizeInputStudio5 } from "../ToolbarComponents/FontSize/FontSizeInputStudio5";

type FontSizeSelectorStudio5Props = {
    /** If this is the docked version of the mobile tool */
    mobileDock?: boolean;

    /**
     * Whether to use an expandable or just the content
     */
    expandable?: boolean;

    /**
     * For overriding styles
     */
    className?: string;

    onChange: (value: number) => void;
} & Omit<React.ComponentProps<typeof FontSizeSelectorMobileDock>, "startCounter" | "stopCounter">;

export function FontSizeSelectorStudio5(props: FontSizeSelectorStudio5Props) {
    const { className, expandable = true, mobileDock = false, min, max, onChange, initialFontSize } = props;
    const designer = useDesigner();
    const selection = useSelection();
    const { on, off } = useKeyboardShortcuts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const trackFontSizeUp = useCallback(
        once(() => {
            designer?.eventBus.trigger(STUDIO_TRACKING_EVENTS.FONT_SIZE_INCREMENT_UP, {
                ...getTrackingDataForSelection(selection)
            });
        }),
        [designer?.eventBus]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const trackFontSizeDown = useCallback(
        once(() => {
            designer?.eventBus.trigger(STUDIO_TRACKING_EVENTS.FONT_SIZE_INCREMENT_DOWN, {
                ...getTrackingDataForSelection(selection)
            });
        }),
        [designer?.eventBus]
    );

    const { startCounter, stopCounter } = useFontSizeSelector({
        onChange,
        min,
        max,
        initialFontSize,
        onFontSizeUp: trackFontSizeUp,
        onFontSizeDown: trackFontSizeDown
    });

    useEffect(() => {
        const increaseFont = () => {
            startCounter(1);
            stopCounter(1);
        };
        const decreaseFont = () => {
            startCounter(-1);
            stopCounter(-1);
        };

        on(keyboardShortcutEvents.increaseFont, increaseFont);
        on(keyboardShortcutEvents.decreaseFont, decreaseFont);
        return () => {
            off(keyboardShortcutEvents.increaseFont, increaseFont);
            off(keyboardShortcutEvents.decreaseFont, decreaseFont);
        };
    }, [on, off, startCounter, stopCounter]);

    if (mobileDock) {
        return (
            <FontSizeSelectorMobileDock
                startCounter={startCounter}
                stopCounter={stopCounter}
                initialFontSize={initialFontSize}
                min={min}
                max={max}
            >
                <FontSizeInputStudio5
                    min={min}
                    max={max}
                    current={initialFontSize}
                    onChange={onChange}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={true}
                />
            </FontSizeSelectorMobileDock>
        );
    }

    return (
        <FontSizeSelectorDefault
            startCounter={startCounter}
            stopCounter={stopCounter}
            initialFontSize={initialFontSize}
            min={min}
            max={max}
            expandable={expandable}
            content={
                <FontSizeDropdownStudio5
                    onChange={onChange}
                    minFontSize={min}
                    maxFontSize={max}
                    className={className}
                />
            }
        >
            <FontSizeInputStudio5 min={min} max={max} current={initialFontSize} onChange={onChange} />
        </FontSizeSelectorDefault>
    );
}
FontSizeSelectorStudio5.displayName = "FontSizeSelector";
