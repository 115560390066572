import { windowExists } from "@shared/utils/WebBrowser";
import { isInViewport } from "@utilities";

/**
 * Get the active canvas
 */
export function getActiveCanvas() {
    return window.designer?.documentRepository?.getActiveCanvas();
}

/**
 * Get the active canvas ID if designer is defined in window
 */
export function getPossibleActiveCanvasId() {
    return windowExists() ? window.designer?.documentRepository?.getActiveCanvas()?.get("id") : undefined;
}

/**
 * Get the ordinal of the active canvas
 */
export function getActiveCanvasOrdinal() {
    return getActiveCanvas()?.get("ordinal");
}

/**
 * Get the id of the active canvas
 */
export function getActiveCanvasId() {
    return getActiveCanvas()?.get("id");
}

/**
 * Get the ordinal of the canvas
 * @returns the matching canvas ordinal or undefined
 */
export function getCanvasOrdinal(canvas: Canvas) {
    return (
        // eslint-disable-next-line no-underscore-dangle
        canvas._canvasViewModel.get("ordinal")
    );
}

/**
 * Tell designer to change the active canvas
 * @param {*} newCanvasName - the canvas name to make active
 * @param {*} isMobile - is mobile screensize
 */
export function triggerCanvasChange(newCanvasName: string, isMobile: boolean) {
    if (!window.designer) {
        return;
    }

    const canvasViewModel = window.designer!.documentRepository.getActiveCanvas()?._canvasViewModel;
    if (canvasViewModel?.get("active")) {
        return;
    }

    if (!isMobile) {
        // The way the new canvas area works is not fully compatible with the current canvas switching flow.
        // We receive the previously selected canvas as active during the switch flow before the newly
        // selected one renders. If we zoomed in on the previous canvas, the new one would render with
        // the wrong 100 % size. We force the zoom 100 % before switching to avoid it.
        window.designer.eventBus.trigger(window.designer.eventBus.events.zoomSet, 1);
    }

    const newCanvasIndex = window.designer!.api.design.canvases.findIndex(canvas => canvas.name === newCanvasName);

    // designer actually wants an ordinal, not a canvas index.  Ordinals are 1-based
    const newCanvasOrdinal = newCanvasIndex + 1;
    window.designer.eventBus.trigger(window.designer.eventBus.events.manageCanvases, {
        enabledCanvases: [newCanvasOrdinal],
        visibleCanvases: [newCanvasOrdinal],
        newCanvasOrdinal
    });
}

export function scrollToItemOnCanvas(
    itemId: string,
    scrollOptions: ScrollIntoViewOptions = { behavior: "smooth", block: "center", inline: "center" }
) {
    const id = `${itemId}-control`;
    const element = document.getElementById(id);
    if (element && !isInViewport(element)) {
        element.scrollIntoView(scrollOptions);
    }
}
