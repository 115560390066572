import React from "react";
import classNames from "classnames";
import { Breadcrumb } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useOnKeyDown } from "@shared/features/Accessibility";
import { ElementsPanelContentTypes, panelTitle, useElementsPanel } from "./ElementsPanelProvider";
import * as styles from "./ElementsPanelTitleItem.module.scss";

interface Props {
    panelType: ElementsPanelContentTypes;
}
export const ElementsPanelTitleItem = (props: Props) => {
    const { panelType } = props;
    const { stack, jumpToInStack } = useElementsPanel();
    const onClick = () => {
        jumpToInStack(panelType);
    };
    const onKeyDown = useOnKeyDown(onClick);
    const { t } = useTranslationSSR();

    const showCurrentStyles = panelType !== ElementsPanelContentTypes.Main && stack.length > 1;
    const isClickable = panelType === ElementsPanelContentTypes.Main && stack.length > 1;

    return (
        <Breadcrumb key={panelType} className={classNames({ [styles.breadcrumbCurrent]: showCurrentStyles })}>
            {isClickable ? (
                <span
                    className={styles.elementsPanelTitle}
                    tabIndex={0}
                    role="button"
                    onKeyDown={onKeyDown}
                    onClick={onClick}
                >
                    {t(panelTitle[panelType])}
                </span>
            ) : (
                <span className={styles.elementsPanelTitle}>{t(panelTitle[panelType])}</span>
            )}
        </Breadcrumb>
    );
};

ElementsPanelTitleItem.displayName = "ElementsPanelTitleItem";
