import React from "react";
import { getTrackingDataForImageSelection, selectedItemsAreOfTypes } from "@utilities";
import { KnockoutButton as KnockoutButtonComponent } from "@presentational";
import { STUDIO_TRACKING_EVENTS, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { EditImageToggle, ToolType } from "@five/xerox/features/EditImage/EditImageToggle";
import { useSelection } from "../designer/useSelection";
import { useDesigner } from "../designer/DesignerProvider";
import { itemIsIcon, itemIsImageUnreplacedPlaceholder, itemIsBackgroundRemovable } from "../util";

interface Props {
    /** For overriding styles */
    className?: string;
    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
    useToggle?: boolean;
}

export function KnockoutButton({ className = "tool-icon", showIcon, useToggle }: Props) {
    const designer = useDesigner();
    const selection = useSelection(
        "model:change:appliedProcesses change:processesInProgress change:isBackgroundRemoveCandidate"
    );

    const item = selection[0] as ImageItem;

    if (
        designer === undefined ||
        selection.length !== 1 ||
        itemIsImageUnreplacedPlaceholder(item) ||
        !selectedItemsAreOfTypes(selection, [ItemTypes.IMAGE]) ||
        (itemIsIcon(item) && !useToggle) // show for icons when using toggle
    ) {
        return null;
    }

    const disabled = !itemIsBackgroundRemovable(item);

    const isBackgroundRemoved = item._itemViewModel.isProcessApplied("backgroundRemove");
    const isBackgroundRemoveInProgress = item._itemViewModel.isProcessRunning("backgroundRemove");

    const onClick = () => {
        if (isBackgroundRemoved) {
            designer.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.CLICK_BACKGROUND_RESTORE,
                getTrackingDataForImageSelection(selection)
            );
        } else {
            designer.eventBus.trigger(
                STUDIO_TRACKING_EVENTS.CLICK_BACKGROUND_REMOVE,
                getTrackingDataForImageSelection(selection)
            );
            const startTime = performance.now();
            item._itemViewModel.once("model:change:appliedProcesses", e => {
                if (e.isProcessApplied("backgroundRemove")) {
                    const timeToProcess = performance.now() - startTime;
                    // don't want to track re-knockout, so if it happens too quick ignore it
                    if (timeToProcess > 500) {
                        const dimensions = item._itemViewModel.model.get("naturalDimensions");
                        fireUserInteractionTrackingEvent("Remove Image Background", timeToProcess, {
                            imageResolution: `${dimensions?.width}x${dimensions?.height}`
                        });
                    }
                }
            });
        }
        item._itemViewModel.toggleProcess("backgroundRemove");
    };

    return useToggle ? (
        <EditImageToggle
            toolType={ToolType.RemoveBackground}
            className={className}
            onClick={onClick}
            disabled={disabled}
            isActivated={isBackgroundRemoved}
            isLoading={isBackgroundRemoveInProgress}
        />
    ) : (
        <KnockoutButtonComponent
            className={className}
            onClick={onClick}
            disabled={disabled}
            isBackgroundRemoved={isBackgroundRemoved}
            isLoading={isBackgroundRemoveInProgress}
            showIcon={showIcon}
        />
    );
}
KnockoutButton.displayName = "KnockoutButton";
