import { defineMessages } from "@vp/i18n-helper";

export const savingMessages = defineMessages({
    appendToName: {
        id: "studio.duplicateDesignDialog.appendToName",
        defaultMessage: "Copy",
        description: {
            note: "Text to append to a duplicated design name"
        }
    }
});
