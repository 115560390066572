import React, { useEffect, useState } from "react";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { Typography, Icon, Link, AlertBoxDismissButton, AlertBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector, useAppDispatch, setSaveSuccess, setShowMyProjects } from "@shared/redux";
import { TOAST_NOTIFICATION_TIME_MS } from "../../UploadsAndAssets";
// eslint-disable-next-line @synbydesign-eslint/restrict-asset-import
import * as styles from "./Toast.module.scss";

const messages = defineMessages({
    myProjectsSaveConfirmation: {
        id: "studio.components.Toast.myProjectsSaveConfirmation",
        defaultMessage: "Saved to My Projects",
        description: {
            note: "Confirmation message that confirm a document was saved to My Projects"
        }
    },
    closeButton: {
        id: "studio.components.Toast.saveSuccessCloseButton",
        defaultMessage: "Close message",
        description: {
            note: "Accessibility label for the close button on the save toast"
        }
    }
});

export function SaveSuccessToast() {
    const { t } = useTranslationSSR();
    const saveSuccess = useAppSelector(state => state.saveSuccess);
    const hideSaveToast = useAppSelector(state => state.hideSaveToast);
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(false);
    const { isXerox } = useXerox();
    const { setCurrentActiveDialog } = useActiveDialog();

    useEffect(() => {
        let returnFn;
        if (saveSuccess && !hideSaveToast) {
            setShow(true);
            const timer = setTimeout(() => {
                dispatch(setSaveSuccess(null));
            }, TOAST_NOTIFICATION_TIME_MS);
            returnFn = () => clearTimeout(timer);
        }
        return returnFn;
    }, [dispatch, saveSuccess, hideSaveToast]);

    const onAnimationEnd = () => {
        if (!saveSuccess) {
            setShow(false);
        }
    };

    const handleClick = () => {
        dispatch(setShowMyProjects({ show: true }));
        dispatch(setSaveSuccess(null));
        isXerox && setCurrentActiveDialog(DialogType.MyProjects);
    };

    return show && !hideSaveToast ? (
        <AlertBox
            skin="positive"
            dismissed={!show}
            onRequestDismiss={() => dispatch(setSaveSuccess(null))}
            onAnimationEnd={onAnimationEnd}
            className={saveSuccess ? styles.fadeIn : styles.fadeOut}
        >
            <Link component="button" className={styles.toastMessageContainer} skin="unstyled" onClick={handleClick}>
                <Typography>
                    {t(messages.myProjectsSaveConfirmation.id)}
                    <Icon skin="white" iconType="chevronRight" pl={1} />
                </Typography>
            </Link>
            <AlertBoxDismissButton visuallyHiddenLabel={t(messages.closeButton.id)} />
        </AlertBox>
    ) : null;
}
SaveSuccessToast.displayName = "SaveSuccessToast";
