import React, { useMemo } from "react";
import { Typography, Divider } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { DesignColorSwatchPicker, ColorSwatchPicker } from "@design-stack-vista/gallery-ui";
import { PanelContent, PanelDescription, PanelTitle } from "@shared/features/StudioChrome";
import { useTemplateColorVariations } from "@shared/features/Templates";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import {
    useAppSelector,
    useAppDispatch,
    setCurrentTemplateColor,
    setLoadingMessage,
    resetQuantity
} from "@shared/redux";
import { useHasSubstrateColor } from "@shared/features/Product";
import type { DSS } from "@vp/types-ddif";
import type { Save } from "@shared/features/Save";
import classNames from "classnames";
import { changeTemplateColorMessages } from "./changeTemplateColorMessages";
import * as styles from "./ChangeTemplateAndSubstrateColorPanel.module.scss";
import { type LoadNewDesign } from "../changeTemplateClient";
import { useChangeTemplate } from "../useChangeTemplate";

export type ColorWithStock = {
    primaryColor: string;
    secondaryColor?: string;
    title: string;
    disabled: boolean;
};

type TemplateColorTrackingData = {
    currentTemplateColor: string;
    newTemplateColor: string;
    currentTemplate: string | undefined | null;
    newTemplate?: string;
};

type SubstrateColorTrackingData = {
    currentSubstrateColor: string | undefined;
    newSubstrateColor: string;
    activeTemplate: string | undefined | null;
};

interface LoadNewDesignForSubstrateChangeProps {
    substrateColorOptionName: string;
    newSubstrateColor: string;
}

export type LoadNewDesignForSubstrateChange = (props: LoadNewDesignForSubstrateChangeProps) => Promise<void>;

interface Props {
    getDocument: () => Promise<DSS.DesignDocument>;
    loadNewDesignForTemplateChange: LoadNewDesign;
    loadNewDesignForSubstrateChange: LoadNewDesignForSubstrateChange;
    save: Save;
}

export function ChangeTemplateAndSubstrateColorPanel({
    getDocument,
    loadNewDesignForTemplateChange,
    loadNewDesignForSubstrateChange,
    save
}: Props) {
    const { t } = useTranslationSSR();
    const locale = useAppSelector(state => state.locale);
    const hasSubstrateColors = useHasSubstrateColor();
    const { hasTemplateColors, colorSwatches, activeTemplate } = useTemplateColorVariations();
    const { changeTemplate, generateNewDocument } = useChangeTemplate({
        eventType: "template color",
        getDocument,
        loadNewDesign: loadNewDesignForTemplateChange,
        save
    });
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const quantity = useAppSelector(state => state.quantity);
    const isQuantityPageEnabled = useAppSelector(state => state.isQuantityPageEnabled);
    const dispatch = useAppDispatch();

    const { substrateColorsWithStockInfo, substrateColorOptionName } = useAppSelector(
        state => state.compatibleColorsWithStockInfo
    );
    const selectedColor = substrateColorOptionName ? studioSelectedProductOptions[substrateColorOptionName] : undefined;

    const trackTemplateDesignColorChange = (data: TemplateColorTrackingData) => {
        fireDesignToolTrackingEvent({
            eventDetail: hasSubstrateColors
                ? STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE_DESIGN_COLOR_BOTH_TEMPLATE_AND_MATERIAL
                : STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE_DESIGN_COLOR_TEMPLATE_ONLY,
            label: "Change the template design color",
            extraData: () => data
        });
    };

    const trackMaterialColorChange = (data: SubstrateColorTrackingData) => {
        fireDesignToolTrackingEvent({
            eventDetail: hasTemplateColors
                ? STUDIO_TRACKING_EVENTS.CLICK_CHANGE_MATERIAL_COLOR_BOTH_TEMPLATE_AND_MATERIAL
                : STUDIO_TRACKING_EVENTS.CLICK_CHANGE_MATERIAL_COLOR_MATERIAL_ONLY,
            label: "Change the material color",
            extraData: () => data
        });
    };

    const changeTemplateColor = async (design: { designId: string; color?: string } | undefined) => {
        const startTime = performance.now();
        if (!design) {
            return;
        }
        const templateToken = design.designId;
        const { designDocument } = await generateNewDocument(templateToken, studioSelectedProductOptions, true);
        if (designDocument) {
            const currentTemplateColor = colorSwatches.find(swatch => swatch.designId === activeTemplate)?.color || "";
            dispatch(setCurrentTemplateColor(design.color));
            trackTemplateDesignColorChange({
                currentTemplateColor,
                newTemplateColor: design.color || "",
                currentTemplate: activeTemplate,
                newTemplate: templateToken
            });
            await changeTemplate(templateToken, studioSelectedProductOptions, designDocument);
            const endTime = performance.now();
            fireUserInteractionTrackingEvent(`ChangeTemplateColor`, endTime - startTime);
        }
    };

    const changeSubstrate = async (newSubstrateColor: string) => {
        const startTime = performance.now();
        dispatch(setLoadingMessage(t(changeTemplateColorMessages.updating.id)));
        trackMaterialColorChange({
            currentSubstrateColor: selectedColor,
            newSubstrateColor,
            activeTemplate
        });
        if (!substrateColorOptionName) {
            throw Error("Attempting to change substrate without a substrateColorOptionName set");
        }
        await loadNewDesignForSubstrateChange({
            substrateColorOptionName,
            newSubstrateColor
        });
        // reset quantity when substrate color changes
        if (isQuantityPageEnabled && quantity > 0) {
            dispatch(resetQuantity());
        }
        const endTime = performance.now();
        fireUserInteractionTrackingEvent(`ChangeSubstrate`, endTime - startTime);
    };

    const panelTitle = useMemo(() => {
        if (hasSubstrateColors && hasTemplateColors) {
            return changeTemplateColorMessages.changeColorPanelTitle.id;
        }
        if (hasSubstrateColors) {
            return changeTemplateColorMessages.changeMaterialColorPanelTitle.id;
        }

        return changeTemplateColorMessages.changeTemplateColorPanelTitle.id;
    }, [hasSubstrateColors, hasTemplateColors]);

    return (
        <PanelContent className={styles.panel}>
            <PanelTitle>{t(panelTitle)}</PanelTitle>
            {hasSubstrateColors && hasTemplateColors && (
                <>
                    <PanelDescription>{t(changeTemplateColorMessages.changeColorDescriptionText.id)}</PanelDescription>
                    <Divider marginTop={4} marginBottom={3} />
                </>
            )}
            {hasTemplateColors && activeTemplate && (
                <>
                    {hasSubstrateColors && <Typography>{t(changeTemplateColorMessages.designTitle.id)}</Typography>}
                    <DesignColorSwatchPicker
                        className={classNames(styles.colorPicker, styles.designColorPicker)}
                        designColorSwatches={colorSwatches}
                        colorSwatchesProps={{
                            sizeVariant: "super"
                        }}
                        selectedDesignId={activeTemplate}
                        onDesignSelectionChange={changeTemplateColor}
                        collapse={false}
                        maxColorSwatches={6}
                        locale={locale}
                        defaultColorLabel={t(changeTemplateColorMessages.defaultChangeColorOptionLabel.id)}
                    />
                </>
            )}
            {hasSubstrateColors && hasTemplateColors && <Divider marginY={3} />}
            {hasSubstrateColors && substrateColorsWithStockInfo && selectedColor && (
                <>
                    {hasTemplateColors && (
                        <Typography mb={4}>{t(changeTemplateColorMessages.materialTitle.id)}</Typography>
                    )}
                    {!hasTemplateColors && substrateColorsWithStockInfo[selectedColor] && (
                        <Typography fontSize="1" className={styles.selectedColorLabel}>
                            {t(changeTemplateColorMessages.substrateColorPanelSelectedColorLabel.id)}
                            <span className={styles.selectedColorValue}>
                                {substrateColorsWithStockInfo[selectedColor].title ||
                                    t(changeTemplateColorMessages.substrateColorPanelDefaultSelectedColor.id)}
                            </span>
                        </Typography>
                    )}
                    <ColorSwatchPicker
                        className={styles.colorPicker}
                        colorSwatches={substrateColorsWithStockInfo}
                        colorSwatchesProps={{
                            sizeVariant: "super"
                        }}
                        selectedColor={selectedColor}
                        onColorSwatchChange={changeSubstrate}
                        collapse={false}
                        maxColorSwatches={6}
                        locale={locale}
                        defaultColorLabel={t(changeTemplateColorMessages.defaultChangeColorOptionLabel.id)}
                    />
                </>
            )}
        </PanelContent>
    );
}
ChangeTemplateAndSubstrateColorPanel.displayName = "ChangeTemplateAndSubstrateColorPanel";
