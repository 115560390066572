import React from "react";
import { Typography, Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { RedesignedUploadsPanelMessages } from "@shared/features/UploadsAndAssets";
import { useUploadQRABTest } from "@shared/features/QRUrlUploads";

function UploadLoginButton({ onClick }: { onClick: (experiemnt: boolean) => void }) {
    const { t } = useTranslationSSR();
    const { isQRCodeImageUploadABEnabled } = useUploadQRABTest();
    return (
        <div className="upload-login-button">
            <Typography textAlign="center" fontWeight="normal" fontSize="-1" mb={2}>
                {isQRCodeImageUploadABEnabled ? (
                    <>
                        <Button
                            onClick={() => {
                                onClick(true);
                            }}
                            data-translationid={RedesignedUploadsPanelMessages.login.id}
                            skin="link"
                            style={{ textDecoration: "underline" }}
                        >
                            {t(RedesignedUploadsPanelMessages.login.id)}
                        </Button>
                    </>
                ) : (
                    t(RedesignedUploadsPanelMessages.login.id)
                )}
                {` ${t(RedesignedUploadsPanelMessages.previousUploads.id)}`}
            </Typography>
            {isQRCodeImageUploadABEnabled ? null : (
                <Button
                    onClick={() => onClick(false)}
                    data-translationid={RedesignedUploadsPanelMessages.login.id}
                    skin="secondary"
                    width="full-width"
                    size="mini"
                >
                    {t(RedesignedUploadsPanelMessages.login.id)}
                </Button>
            )}
        </div>
    );
}

UploadLoginButton.displayName = "UploadLoginButton";

export { UploadLoginButton };
