export const BOTTOM_BAR = {
    MOBILE: {
        MIN_HEIGHT_WITH_MULTI_PANEL: "131px",
        MIN_HEIGHT_WITHOUT_MULTI_PANEL: "65px"
    },
    TABLET: {
        MIN_HEIGHT_WITH_MULTI_PANEL: "106px",
        MIN_HEIGHT_WITHOUT_MULTI_PANEL: "52px"
    }
};
