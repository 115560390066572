import React from "react";
import { useRecentColorsContext } from "@presentational";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import type { Designer, ItemSelection } from "src/easel/designer-suite/@types/designer";
import { PanelContent, PanelTitle } from "@shared/features/StudioChrome";
import { CMYK, RGB } from "@design-stack-ct/utility-core";
import { ColorSpace } from "@shared/features/ColorPicker";
import { useDesigner } from "../../../../easel/designer-suite/designer/DesignerProvider";
import { useSelection } from "../../../../easel/designer-suite/designer/useSelection";
import { getColorPalette, getCurrentCanvas, areCustomColorsAllowed } from "../../../../easel/designer-suite/util";
import { ColorPickerPanelWrapper } from "./ColorPickerPanelWrapper";

const COLOR_OVERRIDES = "colorOverrides";
const STROKE_COLOR = "strokeColor";

function onApply(
    designer: Designer | undefined,
    selection: ItemSelection,
    oldValue: RGB | CMYK | string | undefined,
    newValue: RGB | CMYK | string,
    itemAttributeKey: string
) {
    if (designer) {
        const viewModels = selection.map(item => item._itemViewModel);
        // @ts-ignore I am faking a backbone collection by adding a first function
        viewModels.first = () => viewModels[0];
        designer.commandDispatcher.changeColors({
            viewModels,
            colorChanges: [{ key: itemAttributeKey, newColor: newValue, currentColor: oldValue }]
        });
    }
}

function getFirstColor(designer: Designer, selection: ItemSelection, itemAttributeKey: string) {
    if (itemAttributeKey === COLOR_OVERRIDES) {
        const color =
            (selection[0] as ImageItem)._itemViewModel.model.get(itemAttributeKey) &&
            (selection[0] as ImageItem)._itemViewModel.model.get(itemAttributeKey)[0];

        if (color.format === ColorSpace.Pantone) {
            return `pantone(${color.color})`;
        }

        return color.color;
    }

    const color = (selection[0] as ShapeItem)[itemAttributeKey];
    return color?.color || color;
}

export function getCurrentColor(designer: Designer, selection: ItemSelection, itemAttributeKey: string) {
    const color = getFirstColor(designer, selection, itemAttributeKey);
    if (itemAttributeKey === COLOR_OVERRIDES) {
        const isItemColor = selection.every((item: ImageItem) => {
            const colorOverrides =
                item._itemViewModel.model.get(itemAttributeKey) && item._itemViewModel.model.get(itemAttributeKey)[0];

            const includesPantone = colorOverrides.color.includes(ColorSpace.Pantone);
            return includesPantone ? colorOverrides.color === color : `pantone(${colorOverrides.color})` === color;
        });
        return isItemColor ? color : undefined;
    }
    return selection.every((item: ShapeItem) => item[itemAttributeKey] === color) ? color : undefined;
}

function baseIsCompatible(designer: Designer | undefined, selection: ItemSelection): designer is Designer {
    if (designer === undefined) {
        return false;
    }
    const currentCanvas = getCurrentCanvas(designer, selection[0]);
    return currentCanvas && currentCanvas.processType !== "laserEngraving";
}

const getListenEvents = (key: string) => `model:change:${key}`;

interface Props {
    /** The key of the boolean attribute that is defined on the public api */
    itemAttributeKey: string;
    /** A boolean that says if lines are compatible
     * @default true
     */
    getIsCompatible(designer: Designer | undefined, selection: ItemSelection): boolean;
    /** For overriding styles */
    className?: string;
    /**
     * Button title
     * @default 'Color'
     */
    title: string;
    /** Whether the button should always be shown, even if it is disabled.
     * @default false
     */
    alwaysShow?: boolean;
    /** Whether to only return the color picker content
     * @default false
     */
    contentOnly?: boolean;

    /**
     * Show the custom color picker (wheel/sliders).
     * @default true
     */
    allowCustom?: boolean;

    /**
     * Show no color option
     * @default false
     */
    allowTransparency?: boolean;

    apply?: (
        designer: Designer | undefined,
        selection: ItemSelection,
        oldValue: RGB | CMYK | string | undefined,
        newValue: RGB | CMYK | string,
        itemAttributeKey: string
    ) => void;

    /** Panel/modal type for tracking */
    panelType?: string;
}

/**
 * Base color picker
 */
export function ColorPicker({
    itemAttributeKey,
    getIsCompatible,
    className,
    alwaysShow,
    contentOnly,
    allowCustom = true,
    allowTransparency,
    title = "Color",
    apply = undefined,
    panelType
}: Props) {
    const designer = useDesigner();
    const selection = useSelection(getListenEvents(itemAttributeKey));
    const { recentColors } = useRecentColorsContext();

    if (!selection.length || !designer) {
        return null;
    }

    const canPickColor = getIsCompatible(designer, selection);

    if (!alwaysShow && !canPickColor) {
        return null;
    }

    const value = getCurrentColor(designer, selection, itemAttributeKey);
    const paletteColors = getColorPalette(designer, selection[0]);

    function onChange(newValue: string | RGB | CMYK) {
        if (designer) {
            // Modifying stroke width to 1 on change of stroke color if the stroke width was 0 for a visible change to user.
            if (itemAttributeKey === STROKE_COLOR && selection.length === 1) {
                const item = selection[0]._itemViewModel.model;
                const currentStrokeWidth = parseInt(item.get("strokeWidth"), 10);
                if (currentStrokeWidth === 0) {
                    item.set("strokeWidth", 1);
                }
            }
            const applyFunc = apply || onApply;
            applyFunc(
                designer,
                selection,
                getFirstColor(designer, selection, itemAttributeKey),
                newValue,
                itemAttributeKey
            );
        }
    }

    const allowCustomColors = allowCustom && areCustomColorsAllowed(designer, selection[0]);

    const eyeDropperConfig = {
        isCompatibleWithSelection: () => {
            return allowCustomColors;
        },
        listenEvents: "model:change:data",
        buttonSize: "mini" as const,
        onClick: onChange
    };

    return contentOnly ? (
        <ColorPickerPanelWrapper
            value={value}
            recentColors={recentColors}
            paletteColors={paletteColors}
            onChange={onChange}
            allowCustom={allowCustomColors}
            eyeDropperConfig={eyeDropperConfig}
            panelType={panelType}
        />
    ) : (
        <PanelContent className="studio-color-panel-content" data-dcl-prevent-canvas-items-deselection>
            <DesktopExperience>
                <div className="color-panel__sticky-nav">
                    <PanelTitle>{title}</PanelTitle>
                </div>
            </DesktopExperience>
            <div className={className}>
                <ColorPickerPanelWrapper
                    value={value}
                    recentColors={recentColors}
                    paletteColors={paletteColors}
                    onChange={onChange}
                    allowCustom={allowCustomColors}
                    eyeDropperConfig={eyeDropperConfig}
                    allowTransparency={allowTransparency}
                    panelType={panelType}
                />
            </div>
        </PanelContent>
    );
}
ColorPicker.displayName = "ColorPicker";

ColorPicker.getListenEvents = getListenEvents;
ColorPicker.getIsCompatible = baseIsCompatible;
ColorPicker.getFirstColor = getFirstColor;
ColorPicker.getCurrentColor = getCurrentColor;
ColorPicker.onApply = onApply;
