import React, { useCallback } from "react";
import { type ColorSwatch } from "@shared/utils/Gallery";
import { FluidImage } from "@vp/swan";
import { useAppSelector } from "@shared/redux";
import { i18nColorName } from "@design-stack-vista/i18n-color-name";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useOnDesignTileClick } from "./useOnDesignTileClick";
import { SelectedCheckIcon } from "../../../../shared/features/StudioChrome";
import { TemplateSelectButton } from "./TemplateSelectButton";
import { useTemplatePanel } from "./TemplatePanelProvider";

interface Props {
    colorSwatch: ColorSwatch;
    isCurrentColor?: boolean;
    eventType?: string;
}

export const TemplatePanelContentOption = (props: Props) => {
    const { colorSwatch, isCurrentColor, eventType } = props;
    const { selectedDesign } = useTemplatePanel();
    const {
        previewUrls: { size2x: previewUrl }
    } = colorSwatch;
    const { onDesignTileClick } = useOnDesignTileClick(eventType);
    const locale = useAppSelector(state => state.locale);
    const colorName: string = i18nColorName(colorSwatch?.color ?? "", { culture: locale, colorPalette: "gallery" });
    const { isSmall } = useStudioLayout();

    const onClick = useCallback(
        (colorSwatch: ColorSwatch) => {
            if (!selectedDesign) {
                return;
            }
            onDesignTileClick(selectedDesign, colorSwatch);
        },
        [onDesignTileClick, selectedDesign]
    );

    const label = colorSwatch?.altText ?? colorName;
    return (
        <button
            className="template-panel-contents-options-preview"
            onClick={() => onClick(colorSwatch)}
            aria-label={label}
        >
            {isCurrentColor ? (
                <SelectedCheckIcon className="template-panel-contents-options-preview-selected-icon" />
            ) : (
                !isSmall && <TemplateSelectButton />
            )}
            <FluidImage srcSet={previewUrl} onClick={() => onClick(colorSwatch)} alt={label} />
        </button>
    );
};

TemplatePanelContentOption.displayName = "TemplatePanelContentOption";
