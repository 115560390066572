import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStockImages } from "@design-stack-vista/image-library-react";
import { Typography, FlexBox, SelectionSet, SelectionSetInput, SelectionSetLabel } from "@vp/swan";
import { LandscapeFilterIcon, PortraitFilterIcon, SquareFilterIcon } from "@shared/features/StudioChrome";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";

export enum Orientation {
    Any = "any",
    Horizontal = "horizontal",
    Vertical = "vertical",
    Square = "square"
}

interface Props {
    performSearchForFilter: (filterOptions: string) => void;
}

export const ImageSearchOrientationFilter = ({ performSearchForFilter }: Props) => {
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const { currentFilterOptions } = useStockImages();
    const filterOptions = JSON.parse(currentFilterOptions || "{}");
    const initialOrientation = filterOptions?.orientation || Orientation.Any;

    const handleOrientationSelection = (selection: string) => {
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_LIB_FILTER_SELECT_ORIENTATION,
            extraData: () => ({
                selection
            })
        });
        if (selection === Orientation.Any && filterOptions?.orientation) {
            delete filterOptions.orientation;
        } else {
            filterOptions.orientation = selection;
        }
        if (Object.keys(filterOptions).length !== 0) {
            performSearchForFilter(JSON.stringify(filterOptions));
        } else {
            performSearchForFilter("");
        }
    };

    return (
        <>
            <Typography fontSize="1" fontWeight="bold" marginBottom={2} marginTop={3}>
                {`${t(imageLibraryPanelMessages.orientationHeading.id)}`}
            </Typography>
            <SelectionSet
                skin="simple-column"
                variant="single-select"
                selectedValue={initialOrientation}
                defaultSelectedValue={initialOrientation}
                onSelectedValueChange={handleOrientationSelection}
            >
                <SelectionSetInput value={Orientation.Any}>
                    <SelectionSetLabel m={1}>
                        <Typography fontSize="1" marginLeft={1}>
                            {`${t(imageLibraryPanelMessages.orientationOptionAny.id)}`}
                        </Typography>
                    </SelectionSetLabel>
                </SelectionSetInput>
                <SelectionSetInput value={Orientation.Horizontal}>
                    <SelectionSetLabel m={1}>
                        <FlexBox flexDirection="row" justifyContent="left" alignItems="center" marginLeft={1}>
                            <LandscapeFilterIcon height={12} className="swan-mr-2" />
                            <Typography fontSize="1">
                                {`${t(imageLibraryPanelMessages.orientationOptionLandscape.id)}`}
                            </Typography>
                        </FlexBox>
                    </SelectionSetLabel>
                </SelectionSetInput>
                <SelectionSetInput value={Orientation.Vertical}>
                    <SelectionSetLabel m={1}>
                        <FlexBox flexDirection="row" justifyContent="left" alignItems="center" marginLeft={1}>
                            <PortraitFilterIcon height={18} width={18} className="swan-mr-2" />
                            <Typography fontSize="1">
                                {`${t(imageLibraryPanelMessages.orientationOptionPortrait.id)}`}
                            </Typography>
                        </FlexBox>
                    </SelectionSetLabel>
                </SelectionSetInput>
                <SelectionSetInput value={Orientation.Square}>
                    <SelectionSetLabel m={1}>
                        <FlexBox flexDirection="row" justifyContent="left" alignItems="center" marginLeft={1}>
                            <SquareFilterIcon height={18} className="swan-mr-2" />
                            <Typography fontSize="1">
                                {`${t(imageLibraryPanelMessages.orientationOptionSquare.id)}`}
                            </Typography>
                        </FlexBox>
                    </SelectionSetLabel>
                </SelectionSetInput>
            </SelectionSet>
        </>
    );
};
ImageSearchOrientationFilter.displayName = "ImageSearchOrientationFilter";
