import React from "react";
import classNames from "classnames";
import { Button, ValidationIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import { useAppDispatch, setShowValidations } from "@shared/redux";
import { useValidations } from "./ValidationProvider";
import "./smartValidationButton.scss";
import messages from "./messages";

interface Props {
    className?: string;
}

export const SmartValidationsButtonMobile = ({ className }: Props) => {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const { validationCount, containsErrors } = useValidations();

    const suffix = containsErrors ? "error" : "warning";

    const onClick = () => {
        dispatch(setShowValidations({ showPanel: true }));
    };

    if (validationCount <= 0) {
        return null;
    }

    return (
        <Button className={classNames("smart-validation-button", className)} onClick={onClick}>
            <ValidationIcon className={`validation-badge-icon validation-badge-icon-${suffix}`} />
            <Typography className="button-label">
                {validationCount === 1
                    ? t(messages.mobileButtonTitleSingular.id)
                    : t(messages.mobileButtonTitlePlural.id, { count: validationCount })}
            </Typography>
        </Button>
    );
};

SmartValidationsButtonMobile.displayName = "SmartValidationsButtonMobile";
