import { useCallback } from "react";
import { useAppSelector } from "@shared/redux";
import {
    mapDesignAttributeToProductOption,
    mapDesignAttributeValueToProductOptionValue,
    ProductAttributesKeyAndValues,
    ProductAttributeValue
} from "@shared/utils/Product";

export const useFlexibilityMerchandisingData = () => {
    const keyAndValues = useAppSelector(state => state.productAttributeAndValues);
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);

    const getFlexibilityMerchandisingData = useCallback(
        (designAttributeName: string, designAttributeValue: string): ProductAttributeValue | undefined => {
            const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
            const productOptionValue = mapDesignAttributeValueToProductOptionValue(
                designAttributeMappings,
                designAttributeName,
                designAttributeValue
            );
            const kvPair =
                Array.isArray(keyAndValues) &&
                keyAndValues.length !== 0 &&
                keyAndValues.find(
                    (keyAndValue: ProductAttributesKeyAndValues) => keyAndValue.key.key === productOptionName
                );
            if (kvPair) {
                return kvPair.value.find((value: ProductAttributeValue) => value.key === productOptionValue);
            }
            return undefined;
        },
        [designAttributeMappings, keyAndValues]
    );

    return getFlexibilityMerchandisingData;
};
