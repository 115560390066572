import React, { useState, useEffect } from "react";
import { HistoryToolsSmallScreen } from "@shared/features/DesignTools";
import useUndoRedo from "./useUndoRedo";

export const UndoRedoButtonBarMobile = () => {
    const { undo, redo, undoDisabled, redoDisabled } = useUndoRedo();
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        setInProp(!(undoDisabled && redoDisabled));
    }, [undoDisabled, redoDisabled]);

    return (
        <HistoryToolsSmallScreen
            undo={undo}
            redo={redo}
            canRedo={!redoDisabled}
            canUndo={!undoDisabled}
            transitionIn={inProp}
        />
    );
};

UndoRedoButtonBarMobile.displayName = "UndoRedoButtonBarMobile";
