import React from "react";
import classNames from "classnames";
import { FluidImage } from "@vp/swan";
import { ColorSwatch, Design } from "@shared/utils/Gallery";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";

interface Props {
    design: Design | ColorSwatch;
    width: number;
    animateOnHover?: boolean;
    className?: string;
}

export const TemplatePanelImage = (props: Props) => {
    const { design, width, animateOnHover, className } = props;
    const { isSmall } = useStudioLayout();
    const {
        previewUrls: { size2x: previewUrl },
        previewInfo: { aspectRatio, scenes }
    } = design;

    // Scenes may contain a gallery object
    // @ts-ignore
    const aspectRatioToUse = scenes?.gallery?.aspectRatio || aspectRatio;

    const height = width / aspectRatioToUse;

    return (
        <div
            className={classNames(className, { "template-design-tile-hover": animateOnHover && !isSmall })}
            style={{ width: `${width}px`, height: `${height}px` }}
        >
            <FluidImage srcSet={previewUrl} alt={design?.altText} />
        </div>
    );
};

TemplatePanelImage.displayName = "TemplatePanelImage";
