import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FinishMessages } from "../messages";
import * as styles from "./PremiumFinishUpdatingLabel.module.scss";

export function PremiumFinishUpdatingLabel() {
    const { t } = useTranslationSSR();

    return <div className={styles.label}>{t(FinishMessages.updating.id)}</div>;
}

PremiumFinishUpdatingLabel.displayName = "PremiumFinishUpdatingLabel";
