import React, { useCallback, useLayoutEffect, RefObject, useState, useRef } from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { AddWordArtButton } from "@easel";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { isCareAgent } from "@shared/utils/Care";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { hasAdvancedTools } from "@utilities";
import { useDocumentItems } from "@designer-suite";
import { useAppSelector } from "@shared/redux";
import { StickyActionBar, StudioIsInteractiveSwitch } from "@shared/features/StudioChrome";
import { useActiveDialog } from "@shared/features/ActiveDialog";
import { STUDIO_TRACKING_EVENTS, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import useAddText from "../../../hooks/useAddText";
import "./textPanel.scss";

const messages = defineMessages({
    addNewTextButton: {
        id: "studio.components.panels.textpanel.addNewText",
        defaultMessage: "New text field"
    },
    doneButton: {
        id: "studio.components.panels.textpanel.doneButton",
        defaultMessage: "Done"
    }
});

interface TextButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    showDeleteButtons?: boolean;
    onDone?: () => void;
    className?: string;
    textPanelRef?: RefObject<HTMLElement>;
    textPanelContentRef?: RefObject<HTMLElement>;
    numberOfTextItems: number;
}

export function TextButtons({
    textPanelRef,
    textPanelContentRef,
    onDone,
    numberOfTextItems,
    ...textButtonProps
}: TextButtonProps) {
    const { t } = useTranslationSSR();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const hasAddWordArtButton = isCareAgent() || hasAdvancedTools();
    const [showBoxShadow, setShowBoxShadow] = useState(false);
    const items = useDocumentItems(true);
    const { isSmall } = useStudioLayout();
    const { currentActiveDialog } = useActiveDialog();
    const { addText } = useAddText();

    const stickyBarRef = useRef<HTMLDivElement>(null);
    const stickyBarHeight = stickyBarRef?.current?.offsetHeight || 0;
    const panelHeight = (textPanelRef?.current?.closest("[data-panel]") as HTMLElement)?.offsetHeight || 0;
    // finds if the bottom action bar will be sticky or not based on the height
    // of content it loads.
    const calcStickyBehavior = useCallback(() => {
        let textPanelHeight = window.innerHeight - 251; // 251px comes from the height of header and actionbar.
        if (!isSmall) {
            textPanelHeight = panelHeight - stickyBarHeight;
        }
        if (isSmall) {
            textPanelHeight = textPanelRef?.current?.clientHeight || 0;
        }
        const textPanelContentheight = isSmall
            ? textPanelContentRef?.current?.clientHeight || 0
            : textPanelRef?.current?.clientHeight || 0;

        setShowBoxShadow(textPanelContentheight > textPanelHeight);
    }, [textPanelRef, textPanelContentRef, setShowBoxShadow, panelHeight, stickyBarHeight, isSmall]);

    useLayoutEffect(calcStickyBehavior, [textPanelRef, easelLoaded, items, currentActiveDialog, calcStickyBehavior]);

    const onClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_TEXT_FIELD_ADD,
            label: "Click add text"
        });

        addText(onDone);
    }, [onDone, addText]);

    return (
        <StudioIsInteractiveSwitch>
            <StickyActionBar
                ref={stickyBarRef}
                className={classNames("redesign-text-panel-buttons", { hasBoxShadow: showBoxShadow })}
            >
                <Button
                    data-testid="addNewTextButton"
                    onClick={onClick}
                    data-translationid={t(messages.addNewTextButton.id)}
                    skin="primary"
                    width="full-width"
                    size="mini"
                    {...textButtonProps}
                >
                    {t(messages.addNewTextButton.id)}
                </Button>
                {hasAddWordArtButton && <AddWordArtButton skin="primary" size="mini" ml={3} />}
            </StickyActionBar>
            <div className="redesign-text-panel-skeleton-buttons">
                <Skeleton width={"90%"} height={60} style={{ borderRadius: "110px" }} />
            </div>
        </StudioIsInteractiveSwitch>
    );
}

TextButtons.displayName = "TextButtons";
