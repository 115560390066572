import { defineMessages } from "@vp/i18n-helper";

const messages = defineMessages({
    validationsPanelTitle: {
        id: "studio.components.smartvalidations.validationspanel.title",
        defaultMessage: "Let's check your design",
        description: {
            note: "Title for validations modal to show all existing design issues. This is when a user opens the panel on their own."
        }
    },
    validationsPanelSubTitle: {
        id: "studio.components.smartvalidations.validationspanel.subtitle",
        defaultMessage: "Review the orange suggestions and fix all the red issues.",
        description: {
            note: "Subtitle for validations modal. This is when a user opens the panel on their own."
        }
    },
    validationsPanelTitleReviewStepValidationsOnly: {
        id: "studio.components.smartvalidations.validationspanel.reviewstep.validationsonly.title",
        defaultMessage: "Review suggestions",
        description: {
            note: "Title for validations modal to show all existing design issues and there are only warnings. This is when a user goes through the review steps."
        }
    },
    validationsPanelSubTitleReviewStepValidationsOnly: {
        id: "studio.components.smartvalidations.validationspanel.reviewstep.validationsonly.subtitle",
        defaultMessage: "We found possible design issues that need your attention.",
        description: {
            note: "Subtitle for validations modal and there are only warnings. This is when a user goes through the review steps."
        }
    },
    validationsPanelTitleReviewStepWithErrors: {
        id: "studio.components.smartvalidations.validationspanel.reviewstep.witherrors.title",
        defaultMessage: "Let's check your design",
        description: {
            note: "Title for validations modal to show all existing design issues but there is at least one error. This is when a user opens the panel on their own"
        }
    },
    validationsPanelSubTitleReviewStepWithErrors: {
        id: "studio.components.smartvalidations.validationspanel.reviewstep.witherrors.subtitle",
        defaultMessage: "There are design issues that must be fixed to proceed.",
        description: {
            note: "Subtitle for validations modal but there is at least one error. This is when a user goes through the review steps."
        }
    },
    closeModalAriaLabel: {
        id: "studio.components.smartvalidations.validationspanel.closeModalAriaLabel",
        defaultMessage: "Close modal",
        description: {
            note: "Label for the close modal button"
        }
    },
    desktopButtonTitle: {
        id: "studio.components.smartvalidations.desktopButtonTitle",
        defaultMessage: "Issues",
        description: {
            note: "Text for validations button"
        }
    },
    desktopButtonPopoverTitle: {
        id: "studio.components.smartvalidations.desktopButtonPopoverTitle",
        defaultMessage: "Design issues",
        description: {
            note: "Text for validations button popover title"
        }
    },
    desktopButtonPopoverDescription: {
        id: "studio.components.smartvalidations.desktopButtonPopoverDescription",
        defaultMessage: "We found some issues that could affect how your design prints.",
        description: {
            note: "Text for validations button popover description"
        }
    },
    desktopButtonPopoverButtonTitle: {
        id: "studio.components.smartvalidations.desktopButtonPopoverButtonTitle",
        defaultMessage: "See design issues",
        description: {
            note: "Text for validations button popover button"
        }
    },
    mobileButtonTitleSingular: {
        id: "studio.components.smartvalidations.mobileButtonTitleSingular",
        defaultMessage: "1 design issue to review",
        description: {
            note: "Text for validations button when only 1 issue"
        }
    },
    mobileButtonTitlePlural: {
        id: "studio.components.smartvalidations.mobileButtonTitlePlural",
        defaultMessage: "[[count]] design issues to review",
        description: {
            note: "Text for validations button when more than 1 issue"
        }
    },
    showIssuesButton: {
        id: "studio.components.smartvalidations.showIssuesButton",
        defaultMessage: "Show issues on design",
        description: {
            note: "Text for button to show issues on another design canvas"
        }
    },
    issuesToReviewSubtitleSingular: {
        id: "studio.components.smartvalidations.issuesToReviewSubtitleSingular",
        defaultMessage: "1 issue to review",
        description: {
            note: "Subtitle for canvas when only 1 issue"
        }
    },
    issuesToReviewSubtitlePlural: {
        id: "studio.components.smartvalidations.issuesToReviewSubtitlePlural",
        defaultMessage: "[[count]] issues to review",
        description: {
            note: "Subtitle for canvas when more than 1 issue"
        }
    },
    validationBrickButtonFix: {
        id: "studio.components.smartvalidations.brickbutton.fix",
        defaultMessage: "Fix for me",
        description: {
            note: "Button on the validation brick that a user clicks to have Studio fix a design for them automatically"
        }
    },
    validationBrickButtonDismiss: {
        id: "studio.components.smartvalidations.brickbutton.dismiss",
        defaultMessage: "Dismiss",
        description: {
            note: "Button on the validation brick that a user clicks to dismiss a validation"
        }
    },
    validationBrickButtonUndo: {
        id: "studio.components.smartvalidations.brickbutton.undo",
        defaultMessage: "Undo",
        description: {
            note: "Button for a validation brick that a user clicks to undo a smart fix"
        }
    },
    validationBrickButtonShowAllIssues: {
        id: "studio.components.smartvalidations.brickbutton.showAllIssues",
        defaultMessage: "Show all issues",
        description: {
            note: "Button on the validation brick that a user clicks to open validation panel"
        }
    },
    noPanelValidationsTitle: {
        id: "studio.components.smartvalidations.noPanelValidationsTitle",
        defaultMessage: "No issues on this side.",
        description: {
            note: "Title when there are no issues on a canvas"
        }
    },
    noPanelValidationsSubtitle: {
        id: "studio.components.smartvalidations.noPanelValidationsSubtitle",
        defaultMessage: "Open other sides to fix possible issues.",
        description: {
            note: "Subtitle when there are no issues on a canvas"
        }
    },
    noValidationsTitle: {
        id: "studio.components.smartvalidations.noValidationsTitle",
        defaultMessage: "Looking good!",
        description: {
            note: "Message to show when there are no issues identified on the design"
        }
    },
    noValidationsSubtitle: {
        id: "studio.components.smartvalidations.noValidationsSubtitle",
        defaultMessage: "You've fixed every issue.",
        description: {
            note: "Subtitle to show when there are no issues identified on the design"
        }
    },
    noValidationsButton: {
        id: "studio.components.smartvalidations.noValidationsButton",
        defaultMessage: "Done",
        description: {
            note: "Message to display on button to close panel when there are no issues"
        }
    },
    continueToCheckoutButton: {
        id: "studio.components.smartvalidations.continueToCheckoutButton",
        defaultMessage: "Continue to checkout",
        description: {
            note: "Message to display on button to continue to checkout after fixing issues"
        }
    },
    careMessagingTitle: {
        id: "studio.components.smartvalidations.careMessagingTitle",
        defaultMessage: "Need help fixing these design issues?",
        description: {
            note: "Title for contacting CARE for help in validations panel"
        }
    },
    careMessagingButton: {
        id: "studio.components.smartvalidations.careMessagingButton",
        defaultMessage: "Chat with a Specialist",
        description: {
            note: "Button text for contacting CARE for help in validations panel"
        }
    },
    dismissAllButton: {
        id: "studio.components.smartvalidations.dismissAllButton",
        defaultMessage: "Dismiss all issues",
        description: {
            note: "Message to display on button to dismiss all warnings"
        }
    },
    continueButton: {
        id: "studio.components.smartvalidations.continueButton",
        defaultMessage: "Continue",
        description: {
            note: "Message to display on button to continue"
        }
    },
    loaderOverlayDescription: {
        id: "studio.components.smartvalidations.loaderOverlay.description",
        defaultMessage: "Updating design issues...",
        description: {
            note: "Message to display while component is updating with a loader display"
        }
    },
    popoverTitle: {
        id: "studio.components.header.validationsPopover.title",
        defaultMessage: "Design issues found",
        description: {
            note: "Title in a popover that appears from the header of Studio when there are multiple validations"
        }
    },
    popoverDescription: {
        id: "studio.components.header.validationsPopover.description",
        defaultMessage: "We noticed some issues that could affect how your design prints.",
        description: {
            note: "Description in a popover that appears from from the header of Studio when there are multiple validations"
        }
    },
    popoverButton: {
        id: "studio.components.header.validationsPopover.button",
        defaultMessage: "See design issues",
        description: {
            note: "Button text opening the smart validation panel from a popover in Studio header"
        }
    }
});

export default messages;
