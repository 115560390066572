import { useCompetitiveBaselining } from "@shared/features/CompetitiveBaselining";
import { useIsFlagEnabled } from "@shared/features/Flags";

export function useXerox() {
    const isXeroxEnabled = useIsFlagEnabled("xerox");
    const {
        isCompetitiveBaseliningEnabled,
        isCompetitiveBaseliningEnabledOnTop,
        isCompetitiveBaseliningEnabledOnBottom
    } = useCompetitiveBaselining();

    return {
        isXerox: isXeroxEnabled || isCompetitiveBaseliningEnabled,
        isXeroxOnTop: isXeroxEnabled || isCompetitiveBaseliningEnabledOnTop,
        isXeroxOnBottom: isXeroxEnabled || isCompetitiveBaseliningEnabledOnBottom
    };
}
