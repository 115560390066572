import React, { createContext, useMemo, useState, useCallback, useContext, FC } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import type { DSS } from "@vp/types-ddif";
import { useDesigner } from "@designer-suite";
import { isSingleColorCanvas } from "@utilities";
import {
    hideLoader,
    showLoader,
    useAppDispatch,
    useAppSelector,
    setSelectedAITemplateReportingData
} from "@shared/redux";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { getStudioUniqueSessionId } from "@shared/utils/Tracking";
import {
    AITemplatesColorType,
    AITemplatesUseCase,
    ImagemindReportingInput
} from "@shared/redux/slices/selectedAITemplateReportingData";
import { DialogType } from "@shared/features/ActiveDialog";
import { useIdeaTabEnabled } from "@five/hooks/useIdeaTabEnabled";
import { mapDesignAttributeToProductOption } from "@shared/utils/Product";
import { TranslationMessages } from "src/easel/i18n/localizationUtilities";
import { getWorkEntity } from "@shared/utils/Work";
import { generateDesignViewsURL } from "@shared/utils/DSS";
import { generateDesigns } from "@five/clients/artworkGeneration";
import { getAITemplates, reportAITemplateUsage } from "../../../clients/imagemindClient";
import { useSwitchProducts } from "../../contexts/SwitchProductsContext";
import { aiTemplatesPanelMessages as designTemplatesPanelMessages } from "./aiTemplatesPanelMessages";
import { GenerateIdeasPanelMessages } from "../GenerateIdeas/generateIdeasPanelMessages";

export type SimtagSearchInput = {
    input: {
        text: string;
        mode: string;
        storageId: string;
        topK: number;
        colorType: "singleColor" | "multiColor";
        hero: boolean;
        locale: string;
        targetLocale: string;
    };
};

export type SimtagSearchResult = {
    id: string;
    metadata: {
        previewUrl: string;
        cimDocUrl: string;
        documentReference: string;
    };
    confidence: number;
};

export type AITemplate = {
    id: string;
    metadata: {
        previewUrl: string;
        cimDocUrl: string;
        documentReference: string;
        document?: DSS.DesignDocument;
    };
    confidence: number;
    decoTech: AITemplatesColorType;
    jobId: string;
    keyword: string;
    isRecentlyUsed?: boolean;
};

export type AITemplatePreview = {
    key: string;
    src: string;
    alt: string;
    height: number;
    width: number;
};

export type IAITemplatesContext = {
    addNewRecentlyUsedTemplate: (newTemplate: AITemplate) => void;
    aiTemplates: AITemplate[];
    areAITemplatesLoading: boolean;
    changeDocument: (
        transformedDocument: DSS.DesignDocument | undefined,
        productKey: string,
        locale: string,
        productVersion: number | null,
        quantity: number,
        studioSelectedProductOptions: Record<string, string> | undefined,
        onDone?: () => void
    ) => void;
    currentlySelectedTemplate: AITemplate | undefined;
    setCurrentlySelectedTemplate: (currentTemplate: AITemplate | undefined) => void;
    documentForSelectedContent: DSS.DesignDocument | undefined;
    setDocumentForSelectedContent: (document: DSS.DesignDocument | undefined) => void;
    hasSearchError: boolean;
    isConfirmationModalOpen: boolean;
    setIsConfirmationModalOpen: (isOpen: boolean) => void;
    isSearchTextLimitExceeded: boolean;
    loadRecentlyUsedTemplates: () => void;
    recentlyUsedTemplates: AITemplate[];
    searchTerm: string;
    setSearchTerm: (newSearchTerm: string) => void;
    searchSimtagAITemplates: (term: string, isDefault: boolean) => void;
    setIsSearchTextLimitExceeded: (isExceeded: boolean) => void;
    showSearchLimitErrorState: () => void;
    getNextGalleryBatch: () => void;
    getGalleryImages: () => AITemplatePreview[];
    hasGalleryImages: boolean;
    hasMoreGalleryImages: boolean;
    gallerySize: number;
    latestSearchTemplateReportingData: ImagemindReportingInput | undefined;
    reportClickedTemplate: (aiTemplate: AITemplate) => void;
    defaultTemplatesTextSearch: string;
    aiTemplatesPanelMessages: TranslationMessages;
};

const PANEL_TEXT = {
    [DialogType.IdeaPanel]: GenerateIdeasPanelMessages,
    [DialogType.AITemplates]: designTemplatesPanelMessages
};
export const AITemplatesContext = createContext({} as IAITemplatesContext);

interface AITemplatesProviderProps {
    type: DialogType;
}

export const AITemplatesProvider: FC<AITemplatesProviderProps> = ({ children, type }) => {
    const showIdeaTab = useIdeaTabEnabled();
    const defaultTemplatesTextSearch = showIdeaTab ? "" : "heroTemplates";
    const contextSearchMode = "contextSearch";
    const searchTemplatesStorageId = "35abfb03cdbddbbbec8ce722f573ee6b";
    const recentTemplateStorageKey = showIdeaTab ? "recentIdeasTemplatesStorage" : "recentTemplatesStorage";
    const templatesUseCase: AITemplatesUseCase = "promotionalProducts";
    const sessionId = getStudioUniqueSessionId();
    const galleryShowSize = 24;
    const galleryRequestSize = 480;
    const SubstrateColorAttributeKey = "Substrate Color";
    const DEFAULT_CAM_COLLECTION = ["Hero"];

    const { auth, identity } = useIdentityContext();
    const authToken = auth.getToken();
    const { switchProduct } = useSwitchProducts();
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const decoTech = isSingleColorCanvas(designer) ? "singleColor" : "multiColor";
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const workId = useAppSelector(state => state.workId);
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const [aiTemplates, setAITemplates] = useState<AITemplate[]>([]);
    const [areAITemplatesLoading, setAreAITemplatesLoading] = useState(false);
    const [currentlySelectedTemplate, setCurrentlySelectedTemplate] = useState<AITemplate>();
    const [documentForSelectedContent, setDocumentForSelectedContent] = useState<DSS.DesignDocument>();
    const [galleryImages, setGalleryImages] = useState<AITemplatePreview[]>([]);
    const [galleryReturnIndex, setGalleryReturnIndex] = useState(galleryShowSize);
    const [allRecentlyUsedTemplates, setAllRecentlyUsedTemplates] = useState<AITemplate[]>([]);
    const [recentlyUsedTemplates, setRecentlyUsedTemplates] = useState<AITemplate[]>([]);
    const [hasSearchError, setHasSearchError] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isSearchTextLimitExceeded, setIsSearchTextLimitExceeded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [latestSearchTemplateReportingData, setLatestSearchTemplateReportingData] =
        useState<ImagemindReportingInput>();

    // Simtag API does not support locales "nb-no", "da-dk", "sv-se", "fi-fi", "pt-pt" yet, so setting target locales to english taglines
    const aiTemplatesTargetLocale = ["nb-no", "da-dk", "sv-se", "fi-fi", "pt-pt"].includes(locale.toLowerCase())
        ? "en-gb"
        : locale;

    const aiTemplatesPanelMessages = PANEL_TEXT[type];

    const changeDocument = useCallback(
        async (
            transformedDocument: DSS.DesignDocument,
            productKey: string,
            locale: string,
            productVersion: number | null,
            quantity: number,
            studioSelectedProductOptions: Record<string, string> | undefined,
            onDone?: () => void
        ) => {
            try {
                dispatch(showLoader(`${t(aiTemplatesPanelMessages.loadAITemplateOnProduct.id)}`));
                const newProductOptions = {
                    productKey,
                    locale,
                    productVersion: productVersion ?? undefined,
                    quantity,
                    customerSelectedProductOptions: studioSelectedProductOptions,
                    template: undefined,
                    targetDocument: transformedDocument,
                    saveWork: showIdeaTab
                };
                await Promise.resolve(switchProduct(newProductOptions));
            } catch (e) {
                handleError(e, ERROR_CODES.AI_TEMPLATES_USE_TEMPLATE);
            } finally {
                dispatch(hideLoader());
            }
            if (onDone) onDone();
        },
        // Remove override if there is unexpected/unwanted behavior when one of the dependencies change
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, switchProduct]
    );

    const getGalleryImages = useCallback(() => {
        return galleryImages.slice(0, galleryReturnIndex);
    }, [galleryReturnIndex, galleryImages]);

    const loadRecentlyUsedTemplates = useCallback(() => {
        const recentTemplates: AITemplate[] = localStorage
            ? JSON.parse(localStorage.getItem(recentTemplateStorageKey) as string)
            : null;
        if (recentTemplates !== null) {
            const filteredTemplatesByDecoTech = recentTemplates.filter(template => template.decoTech === decoTech);
            setRecentlyUsedTemplates(filteredTemplatesByDecoTech);
            setAllRecentlyUsedTemplates(recentTemplates);
        }
    }, [decoTech, recentTemplateStorageKey]);

    const updateRecentlyUsedTemplates = useCallback((newTemplate: AITemplate, templatesList: AITemplate[]) => {
        const uniqueRecentTemplates = templatesList.filter(template => template.id !== newTemplate.id);
        return [newTemplate, ...uniqueRecentTemplates];
    }, []);

    const addNewRecentlyUsedTemplate = useCallback(
        (newTemplate: AITemplate) => {
            const updatedRecentTemplates = updateRecentlyUsedTemplates(newTemplate, recentlyUsedTemplates);
            setRecentlyUsedTemplates(updatedRecentTemplates);
            try {
                if (localStorage) {
                    const allUpdatedRecentTemplates = updateRecentlyUsedTemplates(
                        newTemplate,
                        allRecentlyUsedTemplates
                    );
                    setAllRecentlyUsedTemplates(allUpdatedRecentTemplates);
                    localStorage.setItem(recentTemplateStorageKey, JSON.stringify(allUpdatedRecentTemplates));
                }
            } catch (err) {
                // iPad Safari Private Mode treats local storage as effectively read-only and this throws an exception.
                // For the current session everything works as expected,
                // but the data is not updated between sessions.
            }
        },
        [allRecentlyUsedTemplates, recentTemplateStorageKey, recentlyUsedTemplates, updateRecentlyUsedTemplates]
    );

    const searchSimtagAITemplates = useCallback(
        async (submittedSearchTerm: string, isDefault: boolean) => {
            setHasSearchError(false);
            setGalleryImages([]);
            setAreAITemplatesLoading(true);
            try {
                let searchedDesigns;
                let selectedDesignVariationId = "";
                if (type && type === DialogType.IdeaPanel && workId && identity) {
                    const work = await getWorkEntity(authToken, identity, workId);
                    const {
                        merchandising: { merchandisingSelections },
                        product: { key, version }
                    } = work;
                    const ideaPanelQueryParams = JSON.parse(work.design.metadata.queryParams)?.[0] || {};

                    const { primaryText, secondaryText, textColor, themeQuery, useImage, selectedDesign } =
                        ideaPanelQueryParams;

                    selectedDesignVariationId = selectedDesign?.designVariationId;

                    if (themeQuery && !submittedSearchTerm) {
                        setSearchTerm(themeQuery);
                    }

                    const inputQuery = submittedSearchTerm || themeQuery;
                    const productOptionName = mapDesignAttributeToProductOption(
                        designAttributeMappings,
                        SubstrateColorAttributeKey
                    );

                    const generateDesignPayload = {
                        inspireGeneratePayload: {
                            themeQuery: inputQuery,
                            texts: [
                                {
                                    content: primaryText,
                                    type: "primary",
                                    isPlaceholder:
                                        primaryText === t(aiTemplatesPanelMessages.primaryTextFormPlaceholder.id)
                                },
                                {
                                    content: secondaryText,
                                    type: "secondary",
                                    isPlaceholder:
                                        secondaryText === t(aiTemplatesPanelMessages.secondaryTextFormPlaceholder.id)
                                }
                            ],
                            surfaceSpecificationUrl: generateDesignViewsURL(
                                key,
                                version,
                                merchandisingSelections,
                                locale
                            ),
                            substrateColor: merchandisingSelections[productOptionName],
                            useImage,
                            ...(textColor && {
                                colorPalette: {
                                    primary: textColor,
                                    secondary: textColor
                                }
                            }),
                            ...(!inputQuery && {
                                generateOptions: {
                                    iconCollections: DEFAULT_CAM_COLLECTION
                                }
                            })
                        },
                        locale
                    };

                    searchedDesigns = await generateDesigns(generateDesignPayload);
                } else {
                    const aiTemplateSearchCriteria: SimtagSearchInput = {
                        input: {
                            text: submittedSearchTerm,
                            mode: contextSearchMode,
                            storageId: searchTemplatesStorageId,
                            topK: galleryRequestSize,
                            colorType: decoTech,
                            hero: isDefault,
                            locale,
                            targetLocale: aiTemplatesTargetLocale
                        }
                    };
                    searchedDesigns = await getAITemplates(authToken, aiTemplateSearchCriteria);
                }

                const { id, results } = searchedDesigns;
                const mappedAITemplates = results.map((simtagResult: SimtagSearchResult) => {
                    return {
                        ...simtagResult,
                        decoTech,
                        jobId: id,
                        keyword: submittedSearchTerm
                    };
                });

                const mappedGalleryImages = mappedAITemplates.map((aiTemplate: AITemplate, index: number) => {
                    return {
                        key: aiTemplate.id,
                        src: aiTemplate.metadata.previewUrl,
                        alt: `'${submittedSearchTerm}' ${t(
                            aiTemplatesPanelMessages.aiTemplatesImageSearchAltText.id
                        )} ${index + 1}`,
                        height: 1,
                        width: 1,
                        "data-testid": `${submittedSearchTerm}${index + 1}`
                    };
                });

                const templateReportingData: ImagemindReportingInput = {
                    input: {
                        id,
                        feedback: {
                            storageId: searchTemplatesStorageId,
                            colorType: decoTech,
                            locale,
                            hero: isDefault,
                            usecase: templatesUseCase,
                            searchedKeyword: submittedSearchTerm,
                            recentTemplate: false,
                            productKey,
                            pageId: REQUESTOR,
                            userId: sessionId,
                            userActivity: {
                                assetsClicked: [],
                                selectedAssetsForNextPage: [],
                                numberOfAssetsViewed: galleryShowSize,
                                assetsRemoved: []
                            }
                        }
                    }
                };

                if (selectedDesignVariationId) {
                    const selectedDesign = mappedAITemplates.find(
                        (aiTemplate: AITemplate) => aiTemplate.id === selectedDesignVariationId
                    );
                    selectedDesign && addNewRecentlyUsedTemplate(selectedDesign);
                }

                setLatestSearchTemplateReportingData(templateReportingData);
                setAITemplates(mappedAITemplates);
                setGalleryImages(mappedGalleryImages);
                setGalleryReturnIndex(galleryShowSize);
            } catch (err) {
                if (!isDefault) {
                    setHasSearchError(true);
                }
            }

            setAreAITemplatesLoading(false);
        },
        // Remove override if there is unexpected/unwanted behavior when one of the dependencies change
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authToken, workId, identity, t, designAttributeMappings]
    );

    const getNextGalleryBatch = useCallback(() => {
        if (galleryReturnIndex >= galleryRequestSize || areAITemplatesLoading) return;

        const updatedGalleryReturnIndex = galleryReturnIndex + galleryShowSize;
        if (latestSearchTemplateReportingData) {
            const updatedTemplateReportingData = cloneDeep(latestSearchTemplateReportingData);
            updatedTemplateReportingData.input.feedback.userActivity.numberOfAssetsViewed = updatedGalleryReturnIndex;
            setLatestSearchTemplateReportingData(updatedTemplateReportingData);
        }

        setGalleryReturnIndex(updatedGalleryReturnIndex);
    }, [areAITemplatesLoading, galleryReturnIndex, latestSearchTemplateReportingData]);

    const showSearchLimitErrorState = useCallback(() => {
        setGalleryImages([]);
        setHasSearchError(false);
        setIsSearchTextLimitExceeded(true);
    }, []);

    const reportClickedTemplate = useCallback(
        (aiTemplate: AITemplate) => {
            if (latestSearchTemplateReportingData) {
                const clickedAITemplateReportingData: ImagemindReportingInput = cloneDeep(
                    latestSearchTemplateReportingData
                );
                clickedAITemplateReportingData.input.feedback.userActivity.assetsClicked = [aiTemplate.id];

                if (aiTemplate.isRecentlyUsed) {
                    // Update search information to match the selected recently used template
                    clickedAITemplateReportingData.input.id = aiTemplate.jobId ?? "recent-template-no-job-id";
                    clickedAITemplateReportingData.input.feedback.hero =
                        defaultTemplatesTextSearch === aiTemplate.keyword;
                    clickedAITemplateReportingData.input.feedback.searchedKeyword = aiTemplate.keyword ?? undefined;
                    clickedAITemplateReportingData.input.feedback.recentTemplate = true;
                }

                dispatch(setSelectedAITemplateReportingData(clickedAITemplateReportingData));
                reportAITemplateUsage(clickedAITemplateReportingData, authToken);
            }
        },
        [authToken, dispatch, latestSearchTemplateReportingData, defaultTemplatesTextSearch]
    );

    const contextObject = useMemo(
        () => ({
            addNewRecentlyUsedTemplate,
            aiTemplates,
            areAITemplatesLoading,
            changeDocument,
            currentlySelectedTemplate,
            setCurrentlySelectedTemplate,
            documentForSelectedContent,
            setDocumentForSelectedContent,
            hasSearchError,
            isConfirmationModalOpen,
            setIsConfirmationModalOpen,
            isSearchTextLimitExceeded,
            loadRecentlyUsedTemplates,
            recentlyUsedTemplates,
            searchTerm,
            setSearchTerm,
            searchSimtagAITemplates,
            setIsSearchTextLimitExceeded,
            showSearchLimitErrorState,
            getNextGalleryBatch,
            getGalleryImages,
            hasGalleryImages: galleryImages.length > 0,
            hasMoreGalleryImages: galleryReturnIndex < aiTemplates.length,
            gallerySize: galleryReturnIndex,
            latestSearchTemplateReportingData,
            reportClickedTemplate,
            defaultTemplatesTextSearch,
            aiTemplatesPanelMessages
        }),
        [
            addNewRecentlyUsedTemplate,
            aiTemplates,
            areAITemplatesLoading,
            changeDocument,
            currentlySelectedTemplate,
            setCurrentlySelectedTemplate,
            documentForSelectedContent,
            setDocumentForSelectedContent,
            galleryImages,
            hasSearchError,
            isConfirmationModalOpen,
            setIsConfirmationModalOpen,
            isSearchTextLimitExceeded,
            loadRecentlyUsedTemplates,
            recentlyUsedTemplates,
            searchTerm,
            setSearchTerm,
            searchSimtagAITemplates,
            setIsSearchTextLimitExceeded,
            showSearchLimitErrorState,
            getNextGalleryBatch,
            getGalleryImages,
            galleryReturnIndex,
            latestSearchTemplateReportingData,
            reportClickedTemplate,
            defaultTemplatesTextSearch,
            aiTemplatesPanelMessages
        ]
    );

    return <AITemplatesContext.Provider value={contextObject}>{children}</AITemplatesContext.Provider>;
};

export const useAITemplates = () => {
    return useContext(AITemplatesContext);
};

AITemplatesProvider.displayName = "AITemplatesProvider";
