import React, { useContext, useEffect, useState } from "react";

import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

import { ENTITY_CODE, errorCodes } from "../../utilities/ErrorCodes";
import DesignerScripts from "./DesignerScripts";
import type { Designer } from "../@types/designer";

export interface Props {
    /** Whether or not the provider should load designer automatically
     * @default false
     */
    autoLoadDesigner?: boolean;
    /**
     * If autoLoadDesigner is false, designer should loaded externally and be passed in here
     */
    loadedDesigner?: Designer; // eslint-disable-line
    /**
     * If autoLoadDesigner is true, these options will be used to start designer
     */
    options?: Options;
    /**
     * All designer components should be nested underneath this provider
     */
    children: React.ReactNode;
    /**
     * Callback handler when Designer has started
     */
    onStart?: () => void;
    /**
     * Callback handler when Designer has errors
     */
    errorCallBack?: (error: { errorMessage: string; errorCodeStack: string }) => void;
}

const designerContext = React.createContext<Designer | undefined>(undefined);

export function useDesigner() {
    return useContext(designerContext);
}

/* eslint-disable max-len */
/**
 * The Provider of the designer instance. There should only ever be one instance of this component.
 */
export function DesignerProvider({
    children,
    loadedDesigner,
    autoLoadDesigner,
    options,
    onStart,
    errorCallBack
}: Props) {
    const [designer, setDesigner] = useState<Designer | undefined>(undefined);
    const [designerScriptLoaded, setDesignerScriptLoaded] = useState(false);
    const [designerStarting, setDesignerStarting] = useState(false);

    useEffect(() => {
        if (!autoLoadDesigner) {
            setDesigner(loadedDesigner);
        }
    }, [loadedDesigner, autoLoadDesigner]);

    useEffect(() => {
        let time: number;

        function startSuccess() {
            setDesigner(window.designer);
            onStart && onStart();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function startFailure(e: any) {
            // sometimes designer throws Error objects, sometimes it throws random stuff
            let errorString = e.toString();
            if (errorString.includes("[object")) {
                errorString = JSON.stringify(e);
            }
            const err = {
                ...e,
                errorMessage: `An error occurred while starting Designer: ${errorString}`,
                errorCodeStack: `${ENTITY_CODE}-${errorCodes.START_DESIGNER}`
            };
            if (!err.stack) {
                err.stack = e.stack;
            }
            if (errorCallBack) {
                errorCallBack(err);
            }
        }
        function startDesigner() {
            setDesignerStarting(true);
            window.designer!.eventBus.once(window.designer!.eventBus.events.engineBeforeStart, () => {
                time = performance.now();
            });
            window.designer!.eventBus.once(window.designer!.eventBus.events.engineStarted, () => {
                const data = {
                    engineStartEllapse: Math.round(performance.now() - time)
                };
                // Sometimes the logger hasn't initialized yet. Delay sending event to log.
                setTimeout(() => {
                    window.designer!.eventBus.trigger(STUDIO_TRACKING_EVENTS.TIMING_ENGINE_START, data);
                }, 5000);
            });
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            window.designer!.start(options!).then(startSuccess, startFailure);
        }

        // If the designer script is loaded and there are options passed in,
        // then go ahead and start it if it wasn't already in progress
        if (typeof window !== "undefined" && options && designerScriptLoaded && !designerStarting) {
            startDesigner();
        }
    }, [options, designerStarting, onStart, designerScriptLoaded, errorCallBack]);

    const { Provider } = designerContext;

    return (
        <Provider value={designer}>
            {autoLoadDesigner && (
                <DesignerScripts
                    onReady={() => {
                        setDesignerScriptLoaded(true);
                    }}
                />
            )}
            {children}
        </Provider>
    );
}
DesignerProvider.displayName = "DesignerProvider";
