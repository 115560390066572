export const filterColors = [
    { hex: "#FFFFFF", name: "white" },
    { hex: "#A1A4A3", name: "grey" },
    { hex: "#000000", name: "black" },
    { hex: "#A37063", name: "brown" },
    { hex: "#FC9303", name: "orange" },
    { hex: "#FCE903", name: "yellow" },
    { hex: "#49B675", name: "green" },
    { hex: "#2A00FF", name: "blue" },
    { hex: "#7F00FF", name: "violet" },
    { hex: "#FFC0CB", name: "pink" },
    { hex: "#FD3838", name: "red" }
];
