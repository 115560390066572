import { useAppSelector } from "@shared/redux";
import { useCartContext } from "@shared/features/Cart";

export function useShowChangeTemplate() {
    const { showChangeTemplate } = useAppSelector(state => state.studioConfiguration);
    const hasDesigns = useAppSelector(state => state.hasDesigns);
    const { isItemInCart } = useCartContext();

    return showChangeTemplate && !(isItemInCart || isItemInCart === null) && hasDesigns;
}
