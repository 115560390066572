export enum uploadStatusTypes {
    fileTransferStarted = 1,
    fileTransferCompleted = 2,
    pdfInspection = 3,
    fileAnalysis = 4,
    pdfPagesCreated = 5,
    creatingPdfPreviews = 6,
    pdfPreviewsCreated = 7,
    prepareImage = 8,
    digitizingImage = 9,
    postProcessingComplete = 50
}
