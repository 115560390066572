import { useCallback } from "react";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getDesignDocumentFromDesigner } from "@utilities";
import { ColorSwatch, Design } from "@shared/utils/Gallery";
import { useChangeTemplate } from "@shared/features/ChangeTemplate";
import { useHistory } from "@easel";
import { useLoadNewDesignForChangeTemplate } from "../../../hooks/useLoadNewDesignForChangeTemplate";
import { useStudio5Save } from "../../../hooks/useStudio5Save";
import { ConfirmationModalData, useTemplatePanel } from "./TemplatePanelProvider";

export const useOnDesignTileClick = (eventType?: string) => {
    const { undoDisabled } = useHistory();
    const { currentTemplateColorSwatch, setConfirmationModalData, storeRecentlyUsedTemplate } = useTemplatePanel();
    const loadNewDesign = useLoadNewDesignForChangeTemplate();
    const saveForChangeTemplate = useStudio5Save({
        allowAnonymousUser: true,
        hideSaveToast: true,
        showLoader: false,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_CHANGE_TEMPLATE
    });
    const { changeTemplate, generateNewDocument } = useChangeTemplate({
        loadNewDesign,
        getDocument: useCallback(async () => getDesignDocumentFromDesigner(false), []),
        save: saveForChangeTemplate,
        eventType
    });
    const onDesignTileClick = async (selectedDesign: Design, selectedColorSwatch?: ColorSwatch) => {
        if (!selectedDesign) {
            return;
        }
        const selectedTemplateSource = selectedColorSwatch || selectedDesign;
        const selectedTemplateToken = selectedTemplateSource.previewInfo.templateToken;
        // We need to make sure that the color swatches are defined before checking
        // We ran into a case where if both of them are undefined, then it wouldn't change as it thought we were on the same designId.
        if (
            selectedColorSwatch &&
            currentTemplateColorSwatch &&
            selectedColorSwatch.designId === currentTemplateColorSwatch.designId
        ) {
            return;
        }
        const { designDocument } = await generateNewDocument(
            selectedTemplateToken,
            selectedDesign.fullProductOptions,
            true
        );
        if (designDocument) {
            if (undoDisabled) {
                storeRecentlyUsedTemplate(
                    selectedDesign,
                    currentTemplateColorSwatch?.designId || selectedDesign.designId
                );
                changeTemplate(selectedTemplateToken, selectedDesign.fullProductOptions, designDocument);
            } else {
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.SHOW_TEMPLATE_PANEL_CONFIRMATION_DIALOG,
                    label: "Template panel confirmation dialog shown"
                });
                setConfirmationModalData({
                    selectedDesign,
                    selectedTemplateToken,
                    designDocument,
                    selectedColorSwatchDesignId: currentTemplateColorSwatch?.designId || selectedDesign.designId
                });
            }
        }
    };

    const onConfirmationClick = (confirmationModalData: ConfirmationModalData) => {
        const { selectedDesign, selectedTemplateToken, designDocument, selectedColorSwatchDesignId } =
            confirmationModalData!;
        if (selectedDesign && designDocument) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE_CONFIRMATION,
                label: "Click change template confirmation"
            });
            storeRecentlyUsedTemplate(selectedDesign, selectedColorSwatchDesignId);
            changeTemplate(selectedTemplateToken, selectedDesign.fullProductOptions, designDocument);
        }
    };
    return { onDesignTileClick, onConfirmationClick };
};
