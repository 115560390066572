import { useState, useEffect } from "react";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { getPremiumFinishes, hasPremiumFinishes } from "../utilities";

interface PremiumFinishData {
    /** true if the document supports premium finishes, false otherwise */
    hasPremiumFinishesAvailable: boolean;
    /** array with available finishes */
    availableFinishes: string[];
}

const defaultPremiumFinishData: PremiumFinishData = {
    hasPremiumFinishesAvailable: false,
    availableFinishes: []
};

export function useAvailablePremiumFinishesForSurfaces() {
    const [finishes, setFinishes] = useState(defaultPremiumFinishData);
    const designRequirements = useDesignRequirementsContext();

    useEffect(() => {
        if (!designRequirements) return;
        const firstCanvas = designRequirements.panels[0];

        if (!firstCanvas) return;

        const hasPremiumFinishesAvailable = hasPremiumFinishes(firstCanvas);
        const availableFinishes = hasPremiumFinishesAvailable ? getPremiumFinishes(firstCanvas) : [];
        const newData = {
            hasPremiumFinishesAvailable,
            availableFinishes
        };
        setFinishes(newData);
    }, [designRequirements]);

    return finishes;
}
