import React, { useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useDesigner } from "./designer/DesignerProvider";
import "./selectedRichTextField.scss";
import { ContextualTooltip } from "../../shared/features/ContextualTooltip/ContextualTooltip";

interface Props {
    /** canvas item being edited */
    item: TextPlaceholderItem;
}

const messages = defineMessages({
    textPlaceholderTooltip: {
        id: "studio.components.panels.groupsAndTeamsPanel.textPlaceholderTooltip",
        defaultMessage:
            "This is a placeholder for you to position on the design. You will add details for each person on the next page.",
        description: {
            note: "Tooltip for text placeholder"
        }
    }
});

/**
 * A simple wrapper for the contextual tooltip originated from SelectedWordArtField
 */
export function SelectedTeamsPlaceholder({ item }: Props) {
    const designer = useDesigner();
    const { t } = useTranslationSSR();

    useEffect(() => {
        if (!designer) {
            return undefined;
        }
        designer.eventBus.on(designer.eventBus.events.insertText);
        return () => designer.eventBus.off(designer.eventBus.events.insertText);
    }, [designer]); // eslint-disable-line react-hooks/exhaustive-deps

    const getTooltipContent = (placeholderType: TeamsPlaceholderType) => {
        switch (placeholderType) {
            case "Name":
            case "Number":
            case "Role":
            case "Text":
            default:
                return t(messages.textPlaceholderTooltip.id);
        }
    };

    return <ContextualTooltip tooltipContent={getTooltipContent(item.data.placeholderType)} />;
}
SelectedTeamsPlaceholder.displayName = "SelectedTeamsPlaceholder";
