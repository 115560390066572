// This code has been generated via svgr
/* eslint-disable max-len */
import React from "react";

export function DownChevronIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M4 6L8 10C8 10 10.4762 7.52381 12 6"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
DownChevronIcon.displayName = "DownChevronIcon";
