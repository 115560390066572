import React, { forwardRef, Ref } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, FlexBox } from "@vp/swan";
import { CheckmarkIcon } from "@shared/features/StudioChrome";
import classNames from "classnames";
import { useAppSelector } from "@shared/redux";
import { useLastSavedText } from "@shared/features/Save";
import * as styles from "./SaveStatusTooltip.module.scss";

export const messages = defineMessages({
    savedDescription: {
        id: "studio.components.globalToolbar.savedDescription",
        defaultMessage: "Your design was saved in My Projects for easy access.",
        description: {
            note: "Description to indicate where a project was saved"
        }
    }
});

interface Props {
    popperStyles: React.CSSProperties;
    popperAttributes?: Record<string, string>;
    isVisible: boolean;
}

export const SaveStatusTooltip = forwardRef(
    ({ popperStyles, popperAttributes, isVisible }: Props, ref: Ref<HTMLDivElement>) => {
        const { t } = useTranslationSSR();
        const workLastSaved = useAppSelector(state => state.workLastSaved);
        const lastSavedText = useLastSavedText(workLastSaved);

        return lastSavedText ? (
            <FlexBox
                flexDirection="column"
                ref={ref}
                style={popperStyles}
                {...popperAttributes}
                className={classNames(styles.container, { visible: isVisible })}
                role="tooltip"
                id="save-changes-tooltip"
            >
                <FlexBox alignItems="center">
                    <CheckmarkIcon className={styles.check} />
                    <Typography fontSize="-1" className={styles.text}>
                        {lastSavedText}
                    </Typography>
                </FlexBox>
                <div className={styles.divider} />
                <Typography fontSize="-1" className={styles.text}>
                    {t(messages.savedDescription.id)}
                </Typography>
            </FlexBox>
        ) : null;
    }
);

SaveStatusTooltip.displayName = "SaveStatusTooltip";
