import React, { useState, useEffect, useRef } from "react";
import cloneDeep from "lodash/cloneDeep";
import { updateTableItemData as updateDataInTableItem } from "@utilities";
import { useTableSubselection } from "@shared/features/Tables";
import { useDesigner } from "./designer/DesignerProvider";
import { useMobileText } from "./MobileTextEditor/MobileTextProvider";
import "./TextList/richTextField.scss";

interface Props {
    item: TableItem;
    autoFocus: boolean;
    selectOnFocus: boolean;
}

export function TableTextField({ item, selectOnFocus = false }: Props) {
    const designer = useDesigner();
    const {
        selection: { columnIndex, rowIndex, ref }
    } = useTableSubselection();
    const [value, setValue] = useState(item.data.rows[rowIndex].cells[columnIndex].text);
    const { setIsTextInputFocused } = useMobileText();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (!designer) {
            return undefined;
        }

        const insertTextHandler = (e: KeyboardEvent) => {
            if (inputRef.current) {
                e.preventDefault();
                inputRef.current?.focus();
                inputRef.current.value += String.fromCharCode(e.which);

                const newData = cloneDeep(item.data);
                newData.rows[rowIndex].cells[columnIndex].text = inputRef.current.value;

                designer.api.design.updateItem<TableItem>(item.id, mutableItem => {
                    updateDataInTableItem(mutableItem, newData);
                });
                setValue(inputRef.current.value);
            }
        };

        designer.eventBus.on(designer.eventBus.events.insertText, insertTextHandler);
        return () => designer.eventBus.off(designer.eventBus.events.insertText, insertTextHandler);
    }, [designer, inputRef.current, rowIndex, columnIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setValue(item.data.rows[rowIndex].cells[columnIndex].text);
    }, [item, rowIndex, columnIndex]);

    // Update the model when the value changes
    const onChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!designer) {
            return;
        }
        // Designer requires a deep clone in order to detect changes
        const newData = cloneDeep(item.data);
        newData.rows[rowIndex].cells[columnIndex].text = event.target.value;

        designer.api.design.updateItem<TableItem>(item.id, mutableItem => {
            updateDataInTableItem(mutableItem, newData);
        });
        setValue(event.target.value);
    };

    // Select the item when the textField is focused
    const onFocus = () => {
        if (!selectOnFocus || !designer || !designer.selectionManager) {
            return;
        }
        setIsTextInputFocused(true);
        designer.selectionManager.select([item._itemViewModel]);
    };

    const scrollHeight = inputRef?.current?.scrollHeight;
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.style.height = "1px";
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [value, inputRef, scrollHeight]);

    const disabled = item._itemViewModel.model.get("locked");

    return (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
        <div
            className="dscl-rich-text-field easel-rich-text-field"
            onClick={() => {
                inputRef?.current?.focus();
            }}
            data-dcl-prevent-canvas-items-deselection
        >
            <textarea
                className="dscl-rich-text-field__editor"
                data-dcl-prevent-canvas-items-deselection
                onFocus={onFocus}
                onBlur={() => {
                    ref.focus();
                    setIsTextInputFocused(false);
                }}
                onChange={onChangeValue}
                disabled={disabled}
                value={value}
                ref={inputRef}
            />
        </div>
    );
}
TableTextField.displayName = "TableTextField";
