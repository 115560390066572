import React from "react";
import classnames from "classnames";
import { FlexBox } from "@vp/swan";
import { CSSTransition } from "react-transition-group";
import { RedoButton } from "./RedoButton";
import { UndoButton } from "./UndoButton";

import * as styles from "./HistoryTools.module.scss";

interface HistoryToolsProps {
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    transitionIn: boolean;
    transitionRedoIn: boolean;
    className?: string;
}

export const HistoryTools = ({
    undo,
    redo,
    canUndo,
    canRedo,
    transitionIn,
    transitionRedoIn,
    className
}: HistoryToolsProps) => {
    return (
        <CSSTransition in={transitionIn} timeout={200} classNames={classnames(className, "history-tools")}>
            <FlexBox
                justifyContent="center"
                alignItems="center"
                className={classnames(className, "history-tools")}
                style={!canRedo && !canUndo ? { display: "none" } : {}}
                data-dcl-prevent-canvas-items-deselection
            >
                <UndoButton onClick={undo} disabled={!canUndo} showLabel={true} className={styles.historyButton} />
                <CSSTransition in={transitionRedoIn} timeout={200} classNames={classnames("redo-section")}>
                    <FlexBox
                        className={classnames("redo-section")}
                        justifyContent="center"
                        alignItems="center"
                        style={!canRedo ? { width: 0 } : {}}
                    >
                        <div className={styles.buttonBarDivider} />
                        <RedoButton
                            onClick={redo}
                            disabled={!canRedo}
                            showLabel={true}
                            className={styles.historyButton}
                        />
                    </FlexBox>
                </CSSTransition>
            </FlexBox>
        </CSSTransition>
    );
};

HistoryTools.displayName = "HistoryTools";
