import React from "react";
import classNames from "classnames";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { useActiveCanvas } from "@easel";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { PremiumFinishIcon } from "@shared/features/StudioChrome";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { PF_INSTRUCTIONS_TYPE, FINISH_OPTION, FinishMessages } from "@shared/features/PremiumFinish";
import { DesignErrorModal } from "@shared/features/Modals";
import { useAppSelector, useAppDispatch, setShowFullBleedPremiumFinishErrorModal } from "@shared/redux";
import { MobileExperience } from "@shared/features/ResponsiveDesign";
import { useFullBleedPremiumFinish } from "../../../hooks/useFullBleedPremiumFinish";

const titleMessages = {
    [FINISH_OPTION.TextOnly]: FinishMessages.textOnlyTitle,
    [FINISH_OPTION.ImageOnly]: FinishMessages.imageOnlyTitle,
    [FINISH_OPTION.TextAndImage]: FinishMessages.textAndImageTitle
};

export function FullBleedPremiumFinishButton({ className = "" }) {
    const { t } = useTranslationSSR();
    const { isButtonVisible, enabledCanvasFinish } = useFullBleedPremiumFinish();
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();
    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;
    const canvas = useActiveCanvas();
    const dispatch = useAppDispatch();
    const { setCurrentActiveDialog } = useActiveDialog();

    const panelId = canvas && canvas._canvasViewModel.model.id;
    const reviewInstructions = useAppSelector(state => state.reviewInstructions);
    const showModal = useAppSelector(state => state.showFullBleedPremiumFinishErrorModal.showModal);

    const panelFullBleedInstructions = reviewInstructions.find(
        instr => instr.instructionType === PF_INSTRUCTIONS_TYPE && instr.panelId === panelId
    ) || {
        panelId,
        instructionType: PF_INSTRUCTIONS_TYPE,
        finishOption: null,
        optionalNotes: ""
    };

    if (!isButtonVisible) {
        return null;
    }

    const onClickOpen = () => {
        setCurrentActiveDialog(DialogType.FullBleedPremiumFinish);
    };

    const onClickClose = () => {
        dispatch(setShowFullBleedPremiumFinishErrorModal(false));
    };

    return (
        <div className={classNames("premium-finish-button", className)}>
            <MobileExperience>
                <Button skin="secondary" onClick={onClickOpen}>
                    <PremiumFinishIcon className="premium-finish-button-icon" />
                    {panelFullBleedInstructions.finishOption
                        ? `${t(FinishMessages.applyFinish.id)}: ${t(
                              titleMessages[panelFullBleedInstructions.finishOption].id
                          )}`
                        : t(FinishMessages.applyFinish.id)}
                </Button>
            </MobileExperience>
            <DesignErrorModal
                onClose={onClickClose}
                show={showModal}
                className="fullbleed-premium-finish-prompt"
                headerText={t(FinishMessages.errorModalHeader.id, {
                    finishType: finishType && t(FinishMessages[finishType].id)
                })}
                bodyText={t(FinishMessages.errorModalBody.id, {
                    finishType: finishType && t(FinishMessages[finishType].id)
                })}
                showClose={false}
                hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
            />
        </div>
    );
}
FullBleedPremiumFinishButton.displayName = "FullBleedPremiumFinishButton";
