import React, { FormEvent, useMemo, useState } from "react";
import { Dropdown, DropdownOption } from "@vp/swan";

interface CalendarYearSelectorProps {
    className?: string;
    selectedYear?: number;
    onYearChange: (year: number) => void;
}

/**
 * This dropdown changes the calendar year for calendar products
 */
export function CalendarYearSelector({ className, selectedYear, onYearChange }: CalendarYearSelectorProps) {
    const currentYear = new Date().getFullYear();
    const [selectedValue, setSelectedValue] = useState(selectedYear || currentYear);
    const memoizedYearOptions = useMemo(() => {
        const yearOptions = [currentYear, currentYear + 1, currentYear + 2, currentYear + 3];
        if (selectedYear && !yearOptions.includes(selectedYear)) {
            yearOptions.push(selectedYear);
            yearOptions.sort();
        }
        return yearOptions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dropdown
            value={selectedValue}
            onChange={(event: FormEvent<HTMLElement>) => {
                // @ts-ignore
                setSelectedValue(event.target.value);
                // @ts-ignore
                onYearChange(event.target.value);
            }}
            size="mini"
            fullWidth
            className={className}
            data-dcl-prevent-canvas-items-deselection
        >
            {memoizedYearOptions.map(year => (
                <DropdownOption key={year} value={year}>
                    {year}
                </DropdownOption>
            ))}
        </Dropdown>
    );
}
CalendarYearSelector.displayName = "CalendarYearSelector";
