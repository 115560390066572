import React from "react";
import { ColorSwatch, ColorSwatches, SelectionSet, SelectionSetInput, SelectionSetLabel, Typography } from "@vp/swan";
import { AttributeFilter, CategoryFilter } from "@shared/utils/Gallery";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTemplatePanel } from "./TemplatePanelProvider";

export function TemplatePanelColorFilters() {
    const { filters, setSelectedFilters, selectedFilters } = useTemplatePanel();
    const colorFilter = filters?.find(f => f.name === "color");

    if (!colorFilter) {
        return null;
    }
    const filterIdentifier = (colorFilter as CategoryFilter).categoryId || (colorFilter as AttributeFilter).id;
    const currentValues = selectedFilters[filterIdentifier];

    return (
        <div className="template-panel-color-filter">
            <Typography fontSize={1} mb={2}>
                {colorFilter.title}
            </Typography>
            <SelectionSet
                selectedValues={currentValues ? (currentValues as { [value: string]: boolean }) : {}}
                variant="multi-select"
                onSelectedValuesChange={selectedValues => {
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.CLICK_GALLERY_FILTER,
                        label: "Gallery filter selected",
                        extraData: () => ({ filterIdentifier: colorFilter.title })
                    });
                    setSelectedFilters(selectedValues, filterIdentifier);
                }}
            >
                <ColorSwatches>
                    {colorFilter.options.map(o => {
                        const color = o.otherProperties?.drawingColor;

                        return (
                            <SelectionSetInput disabled={o.disabled} key={o.value} value={o.value}>
                                <SelectionSetLabel>
                                    <ColorSwatch primaryColor={color} title={o.title} />
                                </SelectionSetLabel>
                            </SelectionSetInput>
                        );
                    })}
                </ColorSwatches>
            </SelectionSet>
        </div>
    );
}

TemplatePanelColorFilters.displayName = "TemplatePanelColorFilters";
