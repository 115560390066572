import qs from "qs";
import type { DSS, MCP, DTeC } from "@vp/types-ddif";
import { tryFetch } from "@shared/utils/Network";
import { getQueryParams } from "@shared/utils/WebBrowser";
import type { StudioConfiguration, ProductGroupConfiguration } from "@shared/utils/StudioConfiguration";
import type { EaselSceneConfiguration } from "@shared/utils/Scene";
import type { TemplateColorVariation } from "@shared/utils/Templates";
import { getCountry } from "@shared/utils/i18n";
import type { CompatibleOptionsEntry, ProductAttributesKeyAndValues, ProductOption } from "@shared/utils/Product";

// Interfaces for return values are taken from the source code
// to studio-calcifer

const host = getQueryParams().calciferUrl || STUDIO_CALCIFER_URL;
const { templateLocale } = getQueryParams();
const entityCode = 40;

function removeNullQueryStringParams<T extends {}>(params: T): Partial<T> {
    const newParams = params;
    Object.keys(newParams).forEach(key =>
        newParams[key] === undefined || newParams[key] === null ? delete newParams[key] : {}
    );
    return newParams;
}

interface DesignViews {
    designViews: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[];
}

export interface StudioConfig {
    mpvId: string | null;
    productVersion: number;
    productName: string;
    quantity: number;
    selectedOptions: Record<string, string>;
    customerSelectedOptions: Record<string, string>;
    productKey: string | null;
    scenesConfiguration: EaselSceneConfiguration;
    designViews: DesignViews;
    designDocument: DSS.DesignDocument;
    warnings: string[];
    studioConfiguration: StudioConfiguration;
    productGroupConfiguration: ProductGroupConfiguration;
    mcpSku: string;
    mcpVersion: number;
    designs: number;
}

export async function getStudioConfigFromCalciferV2(
    productKey: string | undefined | null,
    mpvId: string | undefined | null,
    productVersion: number | undefined | null,
    customerSelectedProductOptions: Record<string, string> | undefined,
    studioSelectedProductOptions: Record<string, string> | undefined,
    qty: number | null,
    locale: string,
    merchandisingMpvUrl: string | undefined | null,
    isFullBleed: boolean,
    template: string | undefined | null,
    documentRevisionUrl?: string | undefined | null
): Promise<StudioConfig> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        mpvId,
        productVersion,
        selectedOptions: JSON.stringify(customerSelectedProductOptions),
        studioSelectedProductOptions: JSON.stringify(studioSelectedProductOptions),
        qty: qty ? qty.toString() : null,
        market: getCountry(locale),
        locale,
        merchandisingMpvUrl,
        requestor: REQUESTOR,
        isFullBleed,
        template: isFullBleed ? null : template,
        templateLocale,
        documentUrl: documentRevisionUrl
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/initializedStudioConfig/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getStudioConfigFromCalciferV2",
        friendlyDescription: "retrieve studio configuration from calcifer",
        entityCode
    });

    return initializedConfig;
}

export interface SurfaceUpsell {
    panelName: string;
    panelId: string;
    optionName: string;
    blankOption?: string;
    colorOption?: string;
    templateToken?: string;
    ensemblePanelType?: string;
}

export type TemplateInfo = DTeC.EnsembleTemplateDto & { panelName?: string };

export interface StudioSecondaryConfig {
    templates: TemplateInfo[];
    surfaceUpsells?: SurfaceUpsell[];
    warnings: string[];
    designVariations: any[];
    templateColorVariations: TemplateColorVariation[];
    templateAvailableFinishes: DTeC.FinishType[];
    primaryTemplateToken: string;
    isQuantityPageEnabled: boolean;
    designAttributes: ProductOption[];
    compatibleOptions: CompatibleOptionsEntry[];
    productAttributeAndValues: ProductAttributesKeyAndValues[];
}

export async function getSecondaryConfigFromCalciferV2(
    productKey: string,
    productVersion: string | number | undefined,
    studioSelectedProductOptions: Record<string, string> | undefined,
    locale: string,
    isFullBleed: boolean,
    template: string | undefined | null,
    useColorMatchedTemplates: boolean
): Promise<StudioSecondaryConfig> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        productVersion,
        selectedOptions: JSON.stringify(studioSelectedProductOptions),
        locale,
        requestor: REQUESTOR,
        isFullBleed,
        template: isFullBleed ? null : template,
        templateLocale,
        useColorMatchedTemplates
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/secondaryStudioConfig/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getSecondaryConfigFromCalciferV2",
        friendlyDescription: "retrieve secondary studio configuration from calcifer",
        entityCode
    });
    return initializedConfig;
}

interface ProductEntry {
    name: string;
    coreProductId: string;
    mpvId: string;
}

export async function getProductsFromCalcifer(locale: string): Promise<ProductEntry[]> {
    const requestParams = removeNullQueryStringParams({
        locale,
        requestor: REQUESTOR,
        templateLocale
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/products/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getProductsFromCalcifer",
        friendlyDescription: "retrieve the list of valid products for a locale from calcifer",
        entityCode
    });

    return initializedConfig;
}

interface ProductDefaults {
    productKey: string;
    productVersion: any;
    selectedOptions: Record<string, string>;
    quantity: number;
}

export async function getProductDefaultsFromCalcifer(productKey: string, locale: string): Promise<ProductDefaults> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        locale,
        requestor: REQUESTOR,
        templateLocale
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/productDefaults/?${queryString}`;
    const defaults = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getProductDefaultsFromCalcifer",
        friendlyDescription: "retrieve a set of product defaults for a product & locale from calcifer",
        entityCode
    });

    return defaults;
}

interface CompatibleColorsWithStockInfo {
    [key: string]: {
        primaryColor: string;
        secondaryColor?: string;
        title: string;
        disabled: boolean;
    };
}

export async function getSubstrateColorsWithStockInfoFromCalciferV2(
    mpvId: string,
    locale: string,
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string> | undefined,
    productOptionName: string
): Promise<CompatibleColorsWithStockInfo> {
    const requestParams = removeNullQueryStringParams({
        mpvId,
        locale,
        productKey,
        productVersion,
        selectedOptions,
        requestor: REQUESTOR,
        templateLocale,
        productOptionName
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/substrateColorsWithStockInfo?${queryString}`;
    const substrateColorsWithStockInfo = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getSubstrateColorsWithStockInfoFromCalciferV2",
        friendlyDescription: "retrieve substrate colors with stock info for a mpv",
        entityCode
    });

    return substrateColorsWithStockInfo;
}

export interface TaxAwarePrice {
    taxed: number;
    untaxed: number;
}

interface PriceBreakdownEntry {
    name: string;
    value: string;
    listPrice: TaxAwarePrice;
    discountPrice: TaxAwarePrice;
}

interface PriceValuesWithBreakdown {
    totalListPrice: TaxAwarePrice;
    totalDiscountedPrice: TaxAwarePrice;
    unitListPrice: TaxAwarePrice;
    unitDiscountedPrice: TaxAwarePrice;
    baseListPrice: TaxAwarePrice;
    baseDiscountPrice: TaxAwarePrice;
    breakdown?: PriceBreakdownEntry[];
    baseAmountSaved?: TaxAwarePrice;
    basePercentageOff?: number;
    totalAmountSaved?: TaxAwarePrice;
    totalPercentageOff?: number;
    taxRate?: number;
    additionalFees?: any;
    shippingPriceStatus: "NotRequested" | "Success" | "NotFound" | "UnknownIssue" | "Misconfigured";
    shippingListPrice?: TaxAwarePrice;
    shippingDiscountedPrice?: TaxAwarePrice;
    freeShippingDetails?: {
        orderThreshold: number;
        amountToFree: number;
        includesTax: boolean;
    };
    shippingDiscountSources?: any[];
}

export interface PriceQuantityData {
    currency: string;
    fractionDigits: number;
    estimatedPrices: {
        [quantity: string]: PriceValuesWithBreakdown;
    };
}

export async function getProductQuantityWithPricing(
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string> | undefined,
    locale: string
): Promise<PriceQuantityData> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        productVersion,
        selectedOptions,
        market: getCountry(locale),
        merchantId: MERCHANT_ID
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/productQuantityWithPricing?${queryString}`;
    const productQuantitiesWithPrices = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getProductQuantityWithPricingFromCalciferV2",
        friendlyDescription: "retrieve product quantities with estimated price, discount",
        entityCode
    });

    return productQuantitiesWithPrices;
}
