import React, { ReactNode } from "react";
import { P } from "@vp/swan";

type Props = {
    children: ReactNode | ReactNode[];
};

export const PanelDescription = (props: Props) => {
    const { children, ...rest } = props;
    return (
        <P fontSize="1" textColor="dark-grey" my={0} {...rest}>
            {children}
        </P>
    );
};
PanelDescription.displayName = "PanelDescription";
