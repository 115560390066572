import { useDesigner } from "@designer-suite";
import type { LoadNewProductForFlexibility, LoadNewProductForFlexibilityProps } from "@shared/features/Flexibility";
import { useAppDispatch, setIsProductSwitched, setDefaultState } from "@shared/redux";
import { addQueryParams, getQueryParams } from "@shared/utils/WebBrowser";
import { getStudioConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { switchContext } from "@utilities";
import { useCallback } from "react";
import { useStudio5Save } from "./useStudio5Save";

export function useLoadNewProductForFlexibility() {
    const dispatch = useAppDispatch();
    const designer = useDesigner();
    const save = useStudio5Save({
        onSaveCallback: () => {
            dispatch(setIsProductSwitched(true));
        },
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_SWITCH_PRODUCTS
    });

    return useCallback<LoadNewProductForFlexibility>(
        async ({
            productKey,
            locale,
            productVersion,
            quantity: qty,
            customerSelectedProductOptions,
            template: newtemplateToken,
            targetDocument,
            saveWork
        }: LoadNewProductForFlexibilityProps) => {
            if (!designer) {
                return;
            }

            const productConfigs = await getStudioConfigFromCalciferV2(
                productKey,
                undefined,
                productVersion,
                customerSelectedProductOptions,
                undefined,
                qty,
                locale,
                null,
                false,
                newtemplateToken
            );

            const {
                designViews,
                mpvId,
                customerSelectedOptions,
                selectedOptions,
                scenesConfiguration: easelScenesConfiguration,
                studioConfiguration,
                designs
            } = productConfigs;

            await switchContext({
                locale,
                productKey,
                productOptions: selectedOptions,
                designDocument: targetDocument,
                views: designViews.designViews,
                easelScenesConfiguration,
                studioConfiguration,
                template: newtemplateToken || undefined
            });

            dispatch(
                setDefaultState({
                    productKey,
                    productVersion,
                    customerSelectedProductOptions: customerSelectedOptions || {},
                    studioSelectedProductOptions: selectedOptions || {},
                    template: newtemplateToken || undefined,
                    studioConfiguration,
                    hasDesigns: designs > 0
                })
            );

            if (!saveWork || !getQueryParams().workId) {
                window.history.replaceState(
                    "update-url",
                    "",
                    addQueryParams(window.location.href, {
                        key: productKey,
                        productVersion,
                        selectedOptions: JSON.stringify(selectedOptions),
                        mpvId,
                        template: newtemplateToken || undefined,
                        workId: undefined
                    })
                );
                dispatch(setIsProductSwitched(true));
            } else {
                save();
            }
        },
        [designer, dispatch, save]
    );
}
