import React, { ReactNode } from "react";
import { Typography } from "@vp/swan";
import { ViewIcon, ExpandingButton } from "@shared/features/StudioChrome";
import { useTrackEvents } from "@shared/features/Tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

import * as styles from "./ViewButton.module.scss";

interface ViewButtonProps {
    content: ReactNode;
    title: string;
}

export const ViewButton = ({ content, title }: ViewButtonProps) => {
    const { trackEvent } = useTrackEvents();

    return (
        <ExpandingButton
            className="guides-button-container"
            title={title}
            content={content}
            onOpen={() => {
                trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_VIEW_BUTTON });
            }}
            isDisabled={false}
            showArrow={false}
            displayType="singlerow"
            showCloseButton={true}
        >
            <ViewIcon aria-hidden={true} className={styles.viewIcon} />
            <Typography fontSize="-1" className={styles.guidesButtonLabel}>
                {title}
            </Typography>
        </ExpandingButton>
    );
};
ViewButton.displayName = "ViewButton";
