import React from "react";
import classNames from "classnames";
import {
    Typography,
    Button,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogButtons,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter
} from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    useAppSelector,
    useAppDispatch,
    clearDeleteImageData,
    setConfirmImageDeletion,
    setShowDeleteImageModal
} from "@shared/redux";
import "./deleteImageModal.scss";

const messages = defineMessages({
    closeButton: {
        id: "easel.components.dialog.deleteImageDialog.closeButton",
        defaultMessage: "Close Delete Image Dialog"
    },
    modalDialogTitle: {
        id: "easel.components.dialog.deleteImageDialog.modalDialogTitle",
        defaultMessage: "Delete Image"
    },
    modalDialogBody: {
        id: "easel.components.dialog.deleteImageDialog.modalDialogBody",
        defaultMessage: "Are you sure you want to delete this image?"
    },
    cancelButton: {
        id: "easel.components.dialog.deleteImageDialog.cancelButton",
        defaultMessage: "Cancel"
    },
    confirmButton: {
        id: "easel.components.dialog.deleteImageDialog.confirmButton",
        defaultMessage: "Confirm"
    }
});

export const DeleteImageModal = () => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const dispatch = useAppDispatch();
    const showDeleteImageModal = useAppSelector(state => state.showDeleteImageModal);
    const showReplaceImageModal = useAppSelector(state => state.showReplaceImageModal);
    const handleCloseModal = () => {
        dispatch(setShowDeleteImageModal(false));
        dispatch(clearDeleteImageData());
        dispatch(setConfirmImageDeletion(false));
    };
    const onConfirm = () => {
        dispatch(setConfirmImageDeletion(true));
    };
    return (
        <>
            <LegacyModalDialog
                isOpen={showDeleteImageModal}
                onRequestDismiss={handleCloseModal}
                variant={isSmall ? "panel-bottom" : "standard"}
                className={classNames("delete-image-dialog", {
                    "light-back-modal": showReplaceImageModal
                })}
                data-dcl-prevent-canvas-items-deselection
            >
                <LegacyModalDialogContent
                    className="delete-image-dialog-content"
                    aria-label={t(messages.modalDialogTitle.id)}
                >
                    <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.closeButton.id)} />
                    <LegacyModalDialogHeader className="m-0 mb-4">
                        <LegacyModalDialogTitle>
                            <Typography fontSize={4}>{t(messages.modalDialogTitle.id)}</Typography>
                        </LegacyModalDialogTitle>
                    </LegacyModalDialogHeader>
                    <LegacyModalDialogBody className="mb-6">
                        <Typography fontSize={3}>{t(messages.modalDialogBody.id)}</Typography>
                    </LegacyModalDialogBody>
                    <LegacyModalDialogFooter>
                        <LegacyModalDialogButtons>
                            <Button skin="secondary" size="mini" onClick={handleCloseModal} className="py-3 px-5 m-0">
                                <Typography fontSize={1}>{t(messages.cancelButton.id)}</Typography>
                            </Button>
                            <Button skin="primary" size="mini" onClick={onConfirm} className="py-3 px-5 m-0 ml-7">
                                <Typography fontSize={1}>{t(messages.confirmButton.id)}</Typography>
                            </Button>
                        </LegacyModalDialogButtons>
                    </LegacyModalDialogFooter>
                </LegacyModalDialogContent>
            </LegacyModalDialog>
        </>
    );
};

DeleteImageModal.displayName = "DeleteImageModal";
