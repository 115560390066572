import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { setPricingInitialized, useAppDispatch, useAppSelector } from "@shared/redux";
import { initPricingContextModule } from "@shared/utils/Pricing";
import { useEffect } from "react";

export function useInitializePricing() {
    const { isIdentityInitialized } = useIdentityContext();
    const locale = useAppSelector(state => state.locale);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isIdentityInitialized) {
            (async () => {
                await initPricingContextModule(locale);
                dispatch(setPricingInitialized());
            })();
        }
    }, [dispatch, isIdentityInitialized, locale]);
}
