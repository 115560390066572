import { useCallback } from "react";
import { Shape } from "@cimpress-technology/svg-path";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { useInitIcons } from "@shared/features/IconAssetLibrary";
import { useTrackEvents } from "@shared/features/Tracking";
import { SHAPE_SIZE_FACTOR } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import type { DesignerShape } from "src/easel/designer-suite/@types/shapes";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { getRandomPositionInArea } from "../components/Panels/Shapes/DraggableShape";

interface AddShapeOptions {
    shapes: DesignerShape[];
    metadata?: Shape;
}

interface Options {
    openGraphicsPanel?: boolean;
    shouldInitIcons?: boolean;
}

export const SHAPES = {
    RECTANGLE_SHAPE: [{ module: "Rectangle" }],
    LINE_SHAPE: [{ module: "Line" }],
    ELLIPSE_SHAPE: [{ module: "Ellipse" }]
};

export function useAddShape(options?: Options) {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const { trackEvent } = useTrackEvents();
    const { setCurrentActiveDialog } = useActiveDialog();
    const initIcons = useInitIcons();
    const { openGraphicsPanel, shouldInitIcons } = Object.assign({}, options);

    const addShape = useCallback(
        (addShapeOptions: AddShapeOptions) => {
            if (!designer || !canvas) {
                return;
            }
            const { shapes, metadata } = addShapeOptions;
            const { mmDimensions } = canvas;
            const canvasViewModel = designer.documentRepository.getActiveCanvasViewModel();
            const { width: safeAreaWidth, height: safeAreaHeight } = canvasViewModel.get("safeArea");
            const maxSize = Math.min(safeAreaWidth, safeAreaHeight) * SHAPE_SIZE_FACTOR;
            const attributes = getRandomPositionInArea(mmDimensions, {
                width: maxSize,
                height: maxSize
            });
            designer.commandDispatcher.createShape({
                shapes: shapes.map((shape: DesignerShape) => ({ ...shape, ...attributes }))
            });
            const data = {
                shapeType: metadata ? metadata.type : shapes[0].module,
                // @ts-ignore will go away with design stack
                rotation: shapes[0].rotation,
                metadata
            };
            if (openGraphicsPanel) {
                setCurrentActiveDialog(DialogType.Graphics);
            }
            shouldInitIcons && initIcons?.();
            trackEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SHAPE,
                label: "Shapes",
                extraData: () => data
            });
        },
        [designer, canvas, openGraphicsPanel, shouldInitIcons, initIcons, trackEvent, setCurrentActiveDialog]
    );
    return { addShape };
}
