import { useCallback } from "react";
import { DesignTileCallback } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@shared/utils/DSS";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";
import { fireUserInteractionTrackingEvent } from "../../../utils/Tracking";

const generateFireInteractionTimedEvent = (dialogCanvasName: string) => {
    const startTime = performance.now();
    return () =>
        fireUserInteractionTrackingEvent(`Remove Design`, performance.now() - startTime, {
            side: dialogCanvasName
        });
};

export const useSelectBlankCallback = (deleting?: boolean) => {
    const { getDocument, loadNewDesign, fireInteractionTimedEvent, dialogCanvasName } = useDesignDialog();
    const eventToFire = deleting ? generateFireInteractionTimedEvent(dialogCanvasName) : fireInteractionTimedEvent;
    /**
     * This function is a callback that when called, will convert the panel with panelName to a blank canvas.
     * @param panelName - panel to be converted
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel) NOTE: For blank, this probably doesn't do nothing
     * @returns callback
     */
    return useCallback<DesignTileCallback>(
        ({ panelName, resetting }) => {
            return async (dispatch: any, getState: any) => {
                const { surfaceUpsells } = getState();
                const surfaceUpsell = surfaceUpsells[panelName];
                return applyDesignOption({
                    dispatch,
                    getState,
                    panelName,
                    resetting,
                    newDocumentSourceType: documentSourceTypeMap.NONE,
                    newDocumentSourceData: "",
                    newOptions: surfaceUpsell
                        ? {
                              [surfaceUpsell.optionName]: surfaceUpsell.blankOption
                          }
                        : undefined,
                    fireInteractionTimedEvent: eventToFire,
                    existingDocument: await getDocument(),
                    loadNewDesign
                });
            };
        },
        [eventToFire, getDocument, loadNewDesign]
    );
};
