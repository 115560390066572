import { useEffect } from "react";
import { useDesigner } from "@designer-suite";
import { updateItemStudioMetadataProperty } from "@utilities";
import { useTempStudioMetadataContext } from "@easel";
/**
 * A hook that stores metadata through the StudioMetadata provider whenever an item is added
 */
export function useItemsAttributesMetadata() {
    const designer = useDesigner();
    const { getTempStudioMetadata } = useTempStudioMetadataContext();

    useEffect(() => {
        if (!designer) {
            return;
        }

        const addMetadata = (event: EventData) => {
            const { items } = event;
            items.forEach(item => {
                // Icons metadata
                const uploadId = item._itemViewModel.model.get("requestId");
                const metadata = getTempStudioMetadata(uploadId);
                if (metadata && metadata.thirdPartyUploadInfo) {
                    updateItemStudioMetadataProperty(item, "thirdPartyUploadInfo", metadata.thirdPartyUploadInfo);
                }

                // Image analysis
                const { analysis } = item._itemViewModel.model.attributes;
                if (analysis) {
                    updateItemStudioMetadataProperty(item, "analysis", analysis);
                }
            });
        };

        const unsubscribeAdd = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, addMetadata);
        const unsubscribeChanged = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_CHANGED,
            addMetadata
        );

        /* eslint-disable consistent-return */
        return () => {
            unsubscribeAdd();
            unsubscribeChanged();
        };
    }, [designer, getTempStudioMetadata]);
}
